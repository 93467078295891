<!-- <mat-spinner class="labadmin-spinner" *ngIf="sourceApp =='VitalDx' && showLabAdminLoader === 'show' && !templateData"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp === 'VitalDx' && showLabAdminLoader === 'show' && !templateData">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>


<div> 

    <div *ngIf="templateData" class="col-lg-12 p-0 m-md-1 m-lg-0">
        <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="gridData" [context]="'Sendout Reasons'"
            [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="true" [hideExport]="exportBtn"
            [hideCopy]="true" [hideDelete]="deleteBtn"
            (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [templateData]="templateData"
            [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
	        (deleteTemplateClicked)="deleteTemplate($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true"
            (emitFilters)="emitFilters($event)" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
            (sortingChanged)="getAllSendoutReasons()" (sortCompleted)="createActivityTrackerForSort($event)">
        </app-templates-listing>
    </div>
    <!-- <div *ngIf="noDataFound" class="col-lg-12 p-0 m-md-1 m-lg-0">
        <div class="align-center  mt-4">
            <div class="nodata-design font-size">No Data Found</div>
        </div>
    </div> -->
</div>
