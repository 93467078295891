//Edit ,Add and Listing
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjInput from '@grapecity/wijmo.input';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { VitalAdminTabService } from 'src/app/client/tab.service';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { UserSession } from 'src/app/core/services/user.session';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { MainCardModel } from '../../DbModel/MainCardModel';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { cloneDeep } from 'lodash';
import { format, parse } from 'date-fns';
import { Subscription, interval, lastValueFrom } from 'rxjs';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';


declare var window: any;
declare var $: any;
@Component({
  selector: 'app-allusers',
  templateUrl: './allusers.component.html',
  styleUrls: ['./allusers.component.scss'],
  providers: [DatePipe],

})

export class AllUsersComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  public DeploymentKeys = sessionStorage.getItem('deploymentKey');
  public associationData;
  public rolesdata;
  public btnExport: boolean = true;
  public btnCreate: boolean = true;
  public btnEdit: boolean = true;
  public btnDelete: boolean = true;
  public button: any[];
  public facilityRolesdata: any;
  DeleteRole = false;
  showOrgData = false;
  NoDeploymentKeyAlert = false;
  highlightNotes = true;
  NoOrgData = false;
  ShowFrmAddUserDetails = false;
  showAccounts = false;
  showRoles = false;
  associatedAccountNames: string[];
  public holdReleaseDuration = "0";
  public EditholdReleaseDuration;
  public DiagICDcodeReminder: boolean;
  public EditDiagICDcodeReminder: boolean;
  public isICDdisplay: boolean;
  public UserType = "";
  public IsOrderingFacility: boolean;
  public EPGorgType = "";
  public ArrEPGOrgType;
  public Editpassword;
  public userID = 0;
  public userCommonKey;
  public createUserid = sessionStorage.getItem('Userid');
  myRolesArray = [];
  public ArrOrgType = [];
  public ifNoError = true;
  public ArrThresholdDuration = [];
  public sheetHeader
  public ArrSavedRoles = [];
  public IsMedicalDirector: boolean = false;
  public showInvalidData: boolean = false;
  public invalidData;
  public showInvalidColumns: boolean = false;
  public ifBulkUpload: boolean = false;
  public IsMedDirectorExistsForCommon: boolean = false;
  public hidIsCytoSelected: boolean = false;
  public hidIsCytoSupervisiorSelected = false;
  public hidIsChecked = false;
  public hidPrimaryRole = "";
  public hidIsPathlogistSelected: boolean = false;
  public hidIsMedicalDirector = false;
  public hidIsPhysicianSelected = false;
  public CommonKey = "";
  public workBook: any;
  public excelDataArray = [];
  public sheetsToSelect = [];
  public AllFields = [];
  public MandatoryFields = [{}]
  public UserUpdatFields = [];
  showPaginationMainGrid: boolean = false;
  public ifUserDelete = false;
  ShoHoldReleaseDuration = false;
  ShowMedicalDirector = false;
  Licensure_count: number = 1;
  public userType: string;
  Licensure_array = [1];
  StateList: any[];
  editStateList: any[];
  public labRolesData: any;
  StateDiscription = "";
  HRD_KValue: any = "";
  MD_KValue: any = "";
  public workbookData: any;
  ShowLicensureDetails: any = false;
  loginNameExists: boolean = false;
  loginNamesList: any;
  alertOnSave1: boolean = false;
  alertOnSave2: boolean = false;
  //alertOnSave3: boolean = false;
  alertOnSave4: boolean = false;
  alertOnSave5: boolean = false;
  alertOnSave6: boolean = false;
  alertOnSaveCommon: boolean = false;
  @ViewChild('flexGrid', { static: true }) flexGrid: wjGrid.FlexGrid;
  @ViewChild('selectionMode', { static: true }) selectionMode: wjInput.ComboBox;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  public gridheader = [
    'createdby',
    'createddate',
    'modifiedby',
    'modifieddate',
    'LoginType',
    'AllowLogin',
    'MigrationStatus',
    'approvePasswordReset',
    'fileRegPassword',
    'fileaccountlockedout',
    'ustatus',
    'phone',
    'LastLoggedInDate',
    'email',
    'rolename',
    'FormattedDisplayName',
    'loginname',
    'userid',
    'isaccountlockedout'
  ];

  @Input()
  public templateData: any;
  public SubMenuCardModel;
  public usersData;
  public gridarray;
  public usersdeatils;
  public resdata;
  public showDelete: boolean = true;
  public gridExcelDisplay: boolean = false;
  public gridArray = [];
  public gridExcelWidth: number = 0;
  columns;
  public menuType = "";
  public SelectedMenuModel;
  public GenericModalClass = "modal";
  public gridDisplay = false;
  allUserTitle: boolean = true;
  gridData: CollectionView;
  public gridEmpty;
  public ifLabEntity = false;
  public isFacilityContext:boolean = false;
  gridwidth: number = 0;
  // deploymentCheck: boolean = false;

  @ViewChild('chkrole') role_Chk_click: ElementRef<HTMLElement>;

  showfrmInp_AllowReviewPathologist: boolean = false;
  showfrmInp_AllowtoeditAccession: boolean = false;
  showfrmInp_EditSite: boolean = false;
  showfrmInp_AllowPathologistDiagnosisTemplate: boolean = false;
  showfrmInp_Notifyusers: boolean = true;
  showfrmInp_DisplayFormatReports: boolean = true;
  SelectedOrgID: any = "";
  hidlicensure: string;
  showfrmInp_Threshold: boolean = false;
  showfrmDrp_ThresholdDuration: boolean = false;
  showfrmInp_PopulateDiag: boolean = false;
  ClickFrom: string = "UI";
  alertOnSave7: boolean = false;
  CommonAlertOnSave: string = "";
  adduserform: boolean = false;
  detailsDisplay: boolean = false;
  dataArray: any[];
  editorgid: string;
  edituserid: any;
  context: string;
  ViewDisplay: boolean = false;
  usertitle: any;
  FormattedDisplayName: any;
  userdisplayname: any;
  userLoginType: any;
  usernpi: any;
  restusersstatus: any;
  useraliasName: any;
  userExternalUserGUID: any;
  modifieddate: any;
  createddate: any;
  unlockdata: any;
  actiondata: any;
  passwordGen: string;
  ifPasswordClicked: boolean;
  ifResetPasswordClicked: boolean = false;
  ifPasswordGenerated: boolean;
  loginname: any;
  ifUserStatus: boolean;
  statusloginname: any;
  userStatus: any;
  statususerid: any;
  checked: boolean;
  msg: string;
  initChecked: boolean;
  lockoutloginname: any;
  downloadResultname: any;
  ifUnlockClicked: boolean;
  card: any;
  msg1: string;
  usersstate: any;
  EditCommonKey: any;
  alertOnSave8: boolean;
  addloginname: any;
  editloginname: any;
  btnunlock: boolean;
  btninactiveuser: boolean;
  btndelete: boolean = true;
  btnregeneratepassword: boolean;
  Address: any;
  tempExport: any[];
  rolename: any;
  public ifStatusDeleted: boolean;
  cardtype: string;
  btnApproveRequest: boolean;
  searchValue: any;
  approveResponse: any;
  firstName: any;
  lastname: any;
  facilityaddress: any;
  requestID: any;
  requestedDate: any;
  requestedIP: any;
  successIP: any;
  showSearch: boolean;
  showMsg: boolean;
  ifApproveRequest: boolean;
  loginnames: any;
  txtMsg: any;
  lastnames: any;
  btnapprovepasswordrequest: boolean;
  btnUpload: boolean = false;
  htnapproved: boolean = true;
  organizationLoginType: any;
  selector: Selector;
  selectedItems: any = [];
  selectedUsers: any = [];
  btnmigrateuser: boolean;
  btnmigratemultipleusers: boolean;
  alertOnSave10: boolean = false;
  public postUpload: boolean = false;
  public postDownload: boolean = false;
  activeStatus: any;
  fromComponent = "allusers"
  rolelistcount: any;
  rolelablistcount: any;
  rolelablistcytcount: any;
  rolelablistcyscount: any;
  LoginTypesflag: boolean = false;
  passwordvalue: string;
  valueloginname: any;
  passworddata: string;
  iflogindeatils: boolean;
  bigFileName: string;
  currentOrganizationId: any;
  counts = -1;
  HRD_value: number = 0;
  saveandcreateflag: boolean = false;
  saveandcloseflag: boolean = false;
  allowLogin: any;
  editMode: boolean = false;
  organizationID: any;
  checklock: boolean;
  deploymentKey: string = sessionStorage.getItem('deploymentKey');

  CreateLive: boolean;
  arrayData: any = [];
  keyvalues: any = [];
  array: any = [];
  userAuthStatus: any;
  emailUpdated: any;
  LogName: any;
  UserName: any;
  dataItem: any;
  gridsArray: any;
  organizationguid: any;
  userloginname: any
  hiddenColumns: any = [];
  DepartmentList: any = [];
  activityEntity: any;
  oldValue: any;
  selectedType: any='';
  distinctRoleInfo: any=[];
  btnpopulateASCCases: boolean=false;
  btnpopulateCaseList: boolean=false;
  uploadedFilename: any;
  uploadedbase64Data: string;
  constructor(private injectorServiceGridContainer: InjectorService, public vitalHttpServices: VitalHttpServices,
    private DatabaseService: VitalHttpServices,
    private excelService: ExcelService,
    private clipboard: Clipboard,
    public DataShare: DataShareService,
    private _snackbar: MatSnackBar,
    public activityService: ActivityTrackerService,
    private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: UntypedFormBuilder,
    private commonService: CommonService, private VitalAdminTabService: VitalAdminTabService, public MainCardModel: MainCardModel, private usersession: UserSession, private activatedroute: ActivatedRoute, private router: Router,
    public _snackBar: MatSnackBar, public datepipe: DatePipe, private dialog: MatDialog, private dateAdapter: DateAdapter<Date>) {
    super(injectorServiceGridContainer);
    this.frm_AddUsergroup.patchValue({
      frmDeploymentKey: "none"
    });
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      DataShare
    );
  }

  async ngOnInit() {
    let _this = this;
    _this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': "Users" }] })
    _this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    await _this.getOrganizationLoginType();
    _this.organizationID = this.templateData.secondarykeys.OrganizationId
    _this.addGridData();
    _this.getButtondetails();
    if (sessionStorage.getItem('search_context').toLowerCase() == "lab") {
      _this.ifLabEntity = true;
    }
    if (sessionStorage.getItem('selectedContext').toLowerCase() == "facility") {
      _this.isFacilityContext = true;
    }
  }

  async ngOnChanges() {
    this.ifBulkUpload = false;
    await this.getOrganizationLoginType();
    this.organizationID = this.templateData.secondarykeys.OrganizationId
    this.addGridData();
    this.getButtondetails();
    if (sessionStorage.getItem('search_context').toLowerCase() == "lab") {
      this.ifLabEntity = true;
    }
    if (sessionStorage.getItem('selectedContext').toLowerCase() == "facility") {
      this.isFacilityContext = true;
    }
  }

  zip_pattern = "/^\d{5}([\-]\d{4})?$/";
  frm_AddUsergroup = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    frmOrganization: ['', Validators.required],
    frmAccountName: '',
    frmInp_title: 'Dr.',
    frmInp_Address1: '',
    frmInp_FirstName: ['', Validators.required],
    frmInp_Address2: '',
    frmInp_LastName: ['', Validators.required],
    frmInp_Street: '',
    frmInp_MiddleInitial: '',
    frmInp_City: '',
    frmInp_Qualification: '',
    frmInp_State: 'Select',
    frmInp_LoginName: ['', Validators.required],
    frmInp_Zip: ['', [Validators.required, Validators.pattern(this.zip_pattern)]],
    frmInp_Gender: "1",
    frmInp_Country: '',
    frmInp_NPI: ['UNKNOWN', Validators.required],
    frmInp_Phone: ['', [Validators.pattern(/\d{3}[\-]?\d{3}[\-]?\d{4}(x\d{10}?)?$/)]],
    frmInp_DisplayFormatWebpage: "F L D",
    frmInp_Fax: ['', [Validators.pattern(/(\d{3}[\.]?\d{3}[\.]?\d{4},?$){1,3}/)]],
    frmInp_DisplayFormatReports: "S. F L D",
    frmInp_Email: ['', Validators.required],
    frmInp_SSN: '',
    frmInp_Status: "Active",
    frmInp_UserCode: '',
    frmInp_UPIN: '',
    frmInp_ShowMyCaseOnly: false,
    frmInp_ExternalUserGUID: '',
    //frmInp_Region: ['', Validators.required],
    frmInp_US: false,
    frmInp_ST: false,
    frmInp_AllowReviewPathologist: false,
    frmInp_AllowtoeditAccession: false,
    frmInp_Threshold: '',
    frmInp_EditSite: false,
    frmDrp_ThresholdDuration: '',
    frmInp_AllowPathologistDiagnosisTemplate: false,
    frmInp_PopulateDiag: false,
    frmInp_Notifyusers: true,
    frmArruserRoles: this._fb.array([]),
    frmArruserAccounts: this._fb.array([]),
    frmDrp_OrgSendforReview: 'true',
    frmDrp_OrgManages: 'true',
    frmDrp_OrgWorks: 'true',
    frmchk_OrgLocation: false,
    formLicensure: this._fb.array([]),
    frmLblchk_OrgLocation: '',
    frmChk_RoleCheckboxValidator: false,
    frmInp_AllowLogin: true,
    frmInp_WorkGroup: []
  });
  frm_AddUserSelectOrgGroup = this._fb.group({
    frmDrpOrgSearch: ['', Validators.required],
    frmOrgSearchInpTxt: ['', Validators.required]
  });

  //edit from-group
  frm_EditUsers = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    frmOrganization: ['', Validators.required],
    frmAccountName: '',
    frmInp_title: '',
    frmInp_Address1: '',
    frmInp_FirstName: ['', Validators.required],
    frmInp_Address2: '',
    frmInp_LastName: ['', Validators.required],
    frmInp_Street: '',
    frmInp_MiddleInitial: '',
    frmInp_City: '',
    frmInp_Qualification: '',
    frmEditInp_State: '',
    frmInp_LoginName: ['', Validators.required],
    frmInp_Zip: ['', [Validators.required, Validators.pattern(this.zip_pattern)]],
    frmInp_Gender: "1",
    frmInp_Country: '',
    frmInp_NPI: ['', Validators.required],
    frmInp_Phone: ['', [Validators.pattern(/\d{3}[\-]?\d{3}[\-]?\d{4}(x\d{10}?)?$/)]],
    frmInp_DisplayFormatWebpage: "",
    frmInp_Fax: ['', [Validators.pattern(/(\d{3}[\.]?\d{3}[\.]?\d{4},?$){1,3}/)]],
    frmInp_DisplayFormatReports: "",
    frmInp_Email: ['', Validators.required],
    frmInp_SSN: '',
    frmInp_Status: "",
    frmInp_UserCode: '',
    frmInp_UPIN: '',
    frmInp_ShowMyCaseOnly: '',
    frmInp_ExternalUserGUID: '',
    // frmInp_Region: ['', Validators.required],
    frmInp_US: false,
    frmInp_ST: false,
    frmInp_AllowReviewPathologist: false,
    frmInp_AllowtoeditAccession: true,
    frmInp_Threshold: '',
    frmInp_EditSite: true,
    frmDrp_ThresholdDuration: '',
    frmInp_AllowPathologistDiagnosisTemplate: false,
    frmInp_PopulateDiag: false,
    frmInp_Notifyusers: true,
    frmArrEditRoles: this._fb.array([]),
    frmArrEditAccounts: this._fb.array([]),
    frmDrp_OrgSendforReview: 'true',
    frmDrp_OrgManages: 'true',
    frmDrp_OrgWorks: 'true',
    frmchk_OrgLocation: false,
    formLicensure: this._fb.array([]),
    frmLblchk_OrgLocation: '',
    frmChk_RoleCheckboxValidator: false,
    frmInp_AllowLogin: true,
    frmInp_WorkGroup: []
  });

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.vaservices.mainQueryList[mainQueryIndex].Query;
    return query;
  }
  data: any = ["First Last", "First Last Qualification", "Last, First", "Last, First Middle.", "Last, First Qualification", "Title. First Last", "Title. First Last Qualification", "Title. Last, First", "Title. Last, First Middle.", "Title. Last, First Qualification"]

  removeCommasBetweenString(inputArray: string[]): any {
    inputArray.map((str): any => {
      let splitString: any
      splitString = str.replace(/,/g, '')
      this.array.push(splitString)
    });
  }

  //#region listing users
  //Add Users Grid
  addGridData() {
    if(this.organizationLoginType.toLowerCase()!="email"){
      this.columns = [
       // new ColDef('LoginType', 'Login Approach'),
        new ColDef('approvePasswordReset', 'Approve Password'),
        new ColDef('fileRegPassword', 'Reg Password'),
        new ColDef('fileaccountlockedout', 'lock out'),
        new ColDef('phone', 'Phone'),
        new ColDef('LastLoggedInDate', 'Last Logged In Date'),
        new ColDef('rolename', 'Role'),
        new ColDef('ustatus', 'Status'),
        new ColDef('FormattedDisplayName', 'Display Name'),
        new ColDef('email', 'Email'),
        new ColDef('loginname', 'Login ID'),
        new ColDef('userid', 'user id'),
        new ColDef('AllowLogin', 'Allow Login'),
        new ColDef('createdby', 'createdby'),
        new ColDef('createddate', 'createddate'),
        new ColDef('modifiedby', 'modifiedby'),
        new ColDef('modifieddate', 'modifieddate'),
        new ColDef('DepartmentName', 'DepartmentName')
      ];
    }
    else{
      this.columns = [
        new ColDef('LoginType', 'Login Approach'),
        new ColDef('MigrationStatus', 'Migration Status'),
        new ColDef('approvePasswordReset', 'Approve Password'),
        new ColDef('fileRegPassword', 'Reg Password'),
        new ColDef('fileaccountlockedout', 'lock out'),
        new ColDef('phone', 'Phone'),
        new ColDef('LastLoggedInDate', 'Last Logged In Date'),
        new ColDef('rolename', 'Role'),
        new ColDef('FormattedDisplayName', 'Display Name'),
        new ColDef('ustatus', 'Status'),
        new ColDef('email', 'Email'),
        new ColDef('userid', 'user id'),
        new ColDef('AllowLogin', 'Allow Login'),
        new ColDef('createdby', 'createdby'),
        new ColDef('createddate', 'createddate'),
        new ColDef('modifiedby', 'modifiedby'),
        new ColDef('modifieddate', 'modifieddate'),
        new ColDef('DepartmentName', 'DepartmentName')
      ];
    }

    // this.SearchOrg();
    if (this.templateData.submenuData != null) {
      if (this.usersData == null) {
        this.usersData = this.templateData.submenuData;
      }
      this.gridMigrateStatus()
      this.usersData = this.gridsArray
      this.actiondata = this.usersData;
      this.tempExport = [...this.actiondata]
      let gridarray = [];
      let primary = {};
      if (this.usersData) {
        if (this.usersData.length > 0) {
          for (let i = 0; i < this.usersData.length; i++) {
            primary = {};
            for (let [key, value] of Object.entries(this.usersData[i])) {
              for (let j = 0; j < this.gridheader.length; j++) {
                if (key === this.gridheader[j]) {
                  if (key != 'AllowLogin') {
                    if (key == 'isaccountlockedout') {
                      if (!value || value == false || value == 'Not Specified') {
                        value = 0
                      }
                      else {
                        value = 1
                      }
                      primary[key] = value;
                    }
                    else if (value == '' || value == null) {
                      value = 'Not Specified';
                      primary[key] = value;
                    } else {
                      if (key == 'LastLoggedInDate') {
                        value = this.matchCreatedDate(this.usersData[i].createddate, value);
                        primary[key] = (value && value.toString().length > 0 && value != 'NA') ? parse(value?.toString().replace(' (EST)', ''), 'MM/dd/yyyy hh:mm:ss a', new Date()) : null;
                      }
                      else {
                        primary[key] = value;
                      }
                    }
                  }
                  else {
                    if (value == null || value == 1) {
                      primary[key] = true;
                    }
                    else {
                      primary[key] = false;
                    }
                  }
                }
              }
            }

            gridarray.push(primary);
            this.gridDisplay = true;
            this.gridEmpty = false;

          }
        } else {
          this.gridData = new CollectionView(gridarray, { pageSize: 10 });


          this.gridwidth = 0;
          this.gridDisplay = false;
          this.gridEmpty = true;
        }
        if (gridarray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 500 * ((this.gridheader.length - 1) + 37);
        if (this.gridwidth > 1300) {
          this.gridwidth = 665;
        }
      } else {
        this.gridEmpty = true;
        this.gridDisplay = false;
      }
    } else {
      this.gridEmpty = true;
      this.gridDisplay = false;
    }
    //removing the validation for thx and thxvar logintype
    // this.deploymentCheck = false
  }

  async getOrganizationLoginType() {
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id') };
    let query = this.vitalHttpServices.GetQuery("getorganizationname");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    try {
      let res = await this.vitalHttpServices.GetData(queryResult).toPromise();
      if (res && !res.errors) {
        this.organizationLoginType = (res.data.submenuData[0].LoginType == null || res.data.submenuData[0].LoginType == '' || res.data.submenuData[0].LoginType?.toString().toLowerCase() == 'loginname') ? 'LoginName' : 'Email';
      }
    }
    catch {
      console.error('Error in fetching Orgnaization login type');
    }
  }

  //Tool tip for Grid
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });

    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      let index = -1
      index = e._p._cols.findIndex(col => col.visible && col._binding._path && col._binding._path == 'LastLoggedInDate')
      if (index != -1 && index == e.col) {
        let cellData = s.getCellData(e.row, e.col);
        tt.setTooltip(e.cell, `${(cellData instanceof Date && !isNaN(cellData.valueOf())) ? format(cellData, 'MM/dd/yyyy hh:mm:ss a') : cellData}`);
      }
    });

    if (this.organizationLoginType?.toString().toLowerCase() == 'email') {
      this.selector = new Selector(grid, {
        itemChecked: () => {
          this.selectedItems = grid.rows.filter((r) => r.isSelected);
          this.selectedUsers = [];
          for (
            let i = 0;
            i < this.selectedItems.length;
            i++
          ) {
            this.selectedUsers.push(
              {
                UserID: this.selectedItems[i]._data.userid,
                LoginType: this.selectedItems[i]._data.LoginType,
                Email: this.selectedItems[i]._data.email,
                OrganizationID: sessionStorage.getItem('org_id'),
                loginname: this.selectedItems[i]._data.loginname,
                Status: this.selectedItems[i]._data.ustatus,
              }
            );
          }
        },
      });
    }
    else {
      grid.headersVisibility = HeadersVisibility.Column
    }
  }

  //Export data
  exportExcel(flex) {
    let file_name = sessionStorage.getItem('locationName');
    if (file_name === 'undefined' || file_name === null) {
      file_name = sessionStorage.getItem('Org_Name');
      if (file_name === 'undefined') {
        file_name = sessionStorage.getItem('LabName');
      }
      if (file_name == 'undefined') {
        file_name = sessionStorage.getItem('FlabName');
      }
    }
    var filename = 'Users_';
    if (file_name != 'undefined' && file_name) {
      filename = filename + file_name;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    const view = flex.collectionView;
    filename = filename + this.organizationID.toString() + ".xlsx";
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_'
      if (fileName.length > 31) {
        filename = fileName.split('_')[0] + '.xlsx';
      } else {
        if (this.organizationID) {
          filename = fileName + this.organizationID.toString() + '.xlsx';
        }
        else {
          filename = fileName.split('_')[0] + '.xlsx';
        }
      }
    }
    let oldPgSize = view.pageSize;
   let oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    let id = sessionStorage.getItem('AccountID');
    this.ngxService.start();
    this.vitalHttpServices.GetUserslistExport(id).subscribe((ResData: any) => {
      this.ngxService.stop();
      if (!ResData?.errors) {
        let roleData = JSON.parse(ResData?.content);
        var ws = XLSX.utils.json_to_sheet(roleData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "" + 'Users');
        XLSX.writeFile(wb, filename);
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      }
    }, (error) => {
      this.ngxService.stop();
      console.error(error);
    })

  }
  //After refreshAdd value to grid

  bulkMigrateUsers(grid) {
    if (this.organizationLoginType?.toString().toLowerCase() != 'email') {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "This group does not support Email based login for the Users. Please contact support team.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      })
      return;
    }
    if (this.selectedUsers.length < 1) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "Please select at least one user to migrate.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      })
      return
    }
    if (this.selectedUsers.some(va => !va.Email || va.Email.toString().toLowerCase().trim() == 'not specified')) {
      let userList = this.selectedUsers.filter(va => !va.Email || va.Email.toString().toLowerCase().trim() == 'not specified')
      let noEmail = []
      userList.forEach(va => noEmail.push(va.loginname))
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "These users do not have an email assigned to them! Try again after assigning emails. Email not assigned to : " + noEmail.join(','), alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      })
      return
    }
    if (this.selectedUsers.some(va => !va.Status || va.Status.toString().toLowerCase().trim() != 'active')) {
      let userList = this.selectedUsers.filter(va => !va.Status || va.Status.toString().toLowerCase().trim() != 'active')
      let userStatus = []
      userList.forEach(va => userStatus.push(va.loginname))
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "These users are inactive or deleted : " + userStatus.join(','), alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      })
      return
    }
    if (this.selectedUsers.some(va => va.LoginType && va.LoginType.toString().toLowerCase().trim() == 'email')) {
      let userList = this.selectedUsers.filter(va => va.LoginType && va.LoginType.toString().toLowerCase().trim() == 'email')
      let alreadyMigrated = []
      userList.forEach(va => alreadyMigrated.push(va.loginname))
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "These user(s) have already been migrated. User(s) : " + alreadyMigrated.join(','), alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      })
      return
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '500px',
      data: { header: "Migrate User", message: "An invite Email will be sent to the users. Please click on 'Yes' to continue.", alert: "", continue: "Yes", cancel: "No", useRaisedButton: true }
    });
    return dialogRef.afterClosed().toPromise().then((result) => {
      if (result) {
        if (this.selectedUsers.length > 0) {
          this.validateUserData(this.selectedUsers, grid);
        }
      }
    });
  }


  refreshAddGridData() {
    if (this.usersData.length > 0) {
      if (this.usersData == null) {
        this.usersData = this.templateData.submenuData;
      }
      this.gridMigrateStatus()
      this.usersData = this.gridsArray
      this.actiondata = this.usersData;
      this.tempExport = [...this.actiondata]
      let gridarray = [];
      let primary = {};
      if (this.usersData) {
        if (this.usersData.length > 0) {
          for (let i = 0; i < this.usersData.length; i++) {
            primary = {};

            for (let [key, value] of Object.entries(this.usersData[i])) {
              for (let j = 0; j < this.gridheader.length; j++) {
                if (key === this.gridheader[j]) {
                  if (value == '' || value == null) {
                    value = 'Not Specified';
                    primary[key] = value;
                  } else {
                    if (key == 'LastLoggedInDate') {
                      value = this.matchCreatedDate(this.usersData[i].createddate, value);
                      primary[key] = (value && value.toString().length > 0 && value != 'NA') ? (parse(value?.toString().replace(' (EST)', ''), 'MM/dd/yyyy hh:mm:ss a', new Date())) : null;
                    }
                    else {
                      primary[key] = value;
                    }
                  }
                }
              }
            }
            gridarray.push(primary);
            this.gridDisplay = true;
            this.gridEmpty = false;
          }
        } else {
          this.gridData = new CollectionView(gridarray, { pageSize: 10 });

          this.gridwidth = 0;
          this.gridDisplay = false;
          this.gridEmpty = true;
        }
        if (gridarray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        // this.gridwidth = 500 * ((this.gridheader.length - 1) + 37);
        this.gridwidth = 500 * ((this.gridheader.length - 1) + 37);
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      } else {
        this.gridEmpty = true;
        this.gridDisplay = false;
      }

    } else {
      this.gridEmpty = true;
      this.gridDisplay = false;
    }
  }
  //After upadte or add grid refresh
  refreshgriddata() {
    let id = sessionStorage.getItem('AccountID');
    let cards = sessionStorage.getItem('Contexttype');
    this.ngxService.start();
    this.vitalHttpServices.GetUserslist(id, cards,this.deploymentKey).subscribe((ResData) => {
      if (!ResData?.errors) {
        let roledata = ResData;
        this.usersData = roledata;
        this.gridMigrateStatus()
        this.usersData = this.gridsArray
        this.adduserform = false;
        this.ViewDisplay = false;
        this.detailsDisplay = false;
        this.resetAddUserDetails();
        this.refreshAddGridData();
      }
      this.ngxService.stop();
    }, (error) => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //refresh after update
  refreshEditdata() {
    let id = sessionStorage.getItem('AccountID');
    let cards = sessionStorage.getItem('Contexttype');
    this.ngxService.start();
    this.vitalHttpServices.GetUserslist(id, cards,this.deploymentKey).subscribe((ResData) => {
      if (!ResData?.errors) {
        let roledata = ResData;
        this.usersData = roledata;
        this.gridMigrateStatus()
        this.usersData = this.gridsArray;
        this.actiondata = this.usersData;
        this.adduserform = false;
        this.ViewDisplay = false;
        this.detailsDisplay = false;
        if (this.usersData.length > 0)
          this.gridEditView();
        else
          this.refreshgriddata();
      }
      this.ngxService.stop();
    }, (error) => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //after add go to view page
  refreshAddData() {
    let id = sessionStorage.getItem('AccountID');
    let cards = sessionStorage.getItem('Contexttype');
    this.ngxService.start();
    this.vitalHttpServices.GetUserslist(id, cards,this.deploymentKey).subscribe((ResData) => {
      if (!ResData?.errors) {
        this.usersData = ResData;
        this.gridMigrateStatus()
        this.usersData = this.gridsArray;
        this.actiondata = this.usersData;
        this.adduserform = false;
        this.ViewDisplay = false;
        this.detailsDisplay = false;
        if (this.usersData.length > 0)
          this.gridEditView();
        else
          this.refreshgriddata();
      }
      this.ngxService.stop();
    }, (error) => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion


  //#region on view
  //on click on grid
  onClick(grid, e) {
    let hti = grid.hitTest(e);
    if (hti.panel === grid.cells) {
      this.ngxService.start();
      let row = grid.rows[hti.row];
      let dataItem = row.dataItem;
      this.getOrganizationLoginType();
      if (dataItem == null) {
        this.ngxService.stop();
        return;
      }
      else {
        this.gridDisplay = false;
        this.ViewDisplay = true;
        this.edituserid = dataItem.userid;
        this.resdata = this.usersData.filter(
          data => data.userid == this.edituserid
        );
        // this.userdisplayname = this.resdata[0].loginname;
        // if (!this.deploymentCheck) {
        this.userLoginType = this.resdata[0].LoginType;
        // }
        this.usernpi = this.resdata[0].npi;
        this.userCommonKey = this.resdata[0].CommonKey;
        this.usersAuthStatus(dataItem.userid, this.userCommonKey);
        this.activityEntity.entityId = this.resdata[0].CommonKey;
        this.activityService.setActivitySession(this.activityEntity);
        this.restusersstatus = this.resdata[0].ustatus;
        this.checklock = this.resdata[0].isaccountlockedout == "Not Specified" ? false : this.resdata[0].isaccountlockedout
        this.DepartmentList = [];
        if (this.resdata[0].DepartmentName) {
          this.DepartmentList = this.resdata[0]?.DepartmentName?.split(',');
        }
        Object.keys(this.resdata[0]).forEach(key => {
          if (!this.resdata[0][key] || this.resdata[0][key] == ' '
            || this.resdata[0][key] == '') {
            this.resdata[0][key] = 'Not Specified';
          }
        });
        if (this.resdata[0].address2 == 'Not Specified') {
          this.Address = this.resdata[0].address1;
        }
        else if (this.resdata[0].address1 == 'Not Specified') {
          this.Address = this.resdata[0].address2;
        }
        else {
          this.Address = this.resdata[0].address1 + ', ' + this.resdata[0].address2;
        }
        this.usertitle = this.resdata[0].title;
        this.useraliasName = this.resdata[0].AliasName;
        this.allowLogin = this.resdata[0].AllowLogin == 1 ? "True" : "False";
        this.userExternalUserGUID = this.resdata[0].ExternalUserGUID;
        this.FormattedDisplayName = this.resdata[0].FormattedDisplayName.split(',');
        this.createddate = this.resdata[0].createddate;
        this.modifieddate = this.resdata[0].modifieddate;
        if (this.resdata[0].loginname) {
          if ((['verified', 'autoinitiated', 'autoreinitiated'].includes(this.userAuthStatus?.toString().toLowerCase())) && this.VitalAdminTabService.tabs[0].tabData.mainCard.Group_Login_Type?.toString().toLowerCase() == 'email') {
            this.userdisplayname = this.resdata[0].email
            this.LogName=this.userdisplayname
          }
          else {
            this.userdisplayname = this.LogName
          }
        }
        if (this.restusersstatus.toLowerCase() == "deleted") {
          this.ifStatusDeleted = true;
        } else {
          this.ifStatusDeleted = false;
        }
        this.ngxService.stop();
      }
      this.ngxService.stop();
    }

  }
  //After edit  add successfully
  gridEditView() {
    this.gridDisplay = false;
    this.gridEmpty = false;
    // this.loginname = this.editloginname;
    this.resdata = this.usersData.filter(
      data => data.CommonKey == (this.EditCommonKey ? this.EditCommonKey : this.CommonKey)
    );
    if (this.resdata && this.resdata.length > 0) {
      this.ViewDisplay = true;
      this.edituserid = this.resdata[0].userid;
      this.usersAuthStatus(this.edituserid,this.resdata[0].CommonKey);
      // this.userdisplayname = this.resdata[0].loginname;
     
      this.allowLogin = this.resdata[0].AllowLogin == 1 ? "True" : "False";
      this.usernpi = this.resdata[0].npi;
      this.restusersstatus = this.resdata[0].ustatus;
      this.checklock = this.resdata[0].isaccountlockedout == "Not Specified" ? false : this.resdata[0].isaccountlockedout
      if (this.resdata[0].rolename) {
        this.myRolesArray = this.resdata[0].rolename.split(',');
      }
      Object.keys(this.resdata[0]).forEach(key => {
        if (!this.resdata[0][key] || this.resdata[0][key] == ' '
          || this.resdata[0][key] == '') {
          this.resdata[0][key] = 'Not Specified';
        }
      });
      if (this.resdata[0].address2 == 'Not Specified') {
        this.Address = this.resdata[0].address1;
      }
      else if (this.resdata[0].address1 == 'Not Specified') {
        this.Address = this.resdata[0].address2;
      }
      else {
        this.Address = this.resdata[0].address1 + ', ' + this.resdata[0].address2;
      }
      // if (!this.deploymentCheck) {
      this.userLoginType = this.resdata[0].LoginType;
      // }
      this.usertitle = this.resdata[0].title;
      this.useraliasName = this.resdata[0].AliasName;
      this.userExternalUserGUID = this.resdata[0].ExternalUserGUID;
      this.FormattedDisplayName = this.resdata[0].FormattedDisplayName.split(',');
      this.createddate = this.resdata[0].createddate;
      this.modifieddate = this.resdata[0].modifieddate;
      this.DepartmentList = [];
      if (this.resdata[0]?.DepartmentName) {
        this.DepartmentList = this.resdata[0]?.DepartmentName?.split(',');
      }
      if (this.resdata[0].loginname) {
        if ((['verified', 'autoinitiated', 'autoreinitiated'].includes(this.userAuthStatus?.toString().toLowerCase())) && this.VitalAdminTabService.tabs[0].tabData.mainCard.Group_Login_Type?.toString().toLowerCase() == 'email') {
          this.userdisplayname = this.resdata[0].email
          this.LogName=this.userdisplayname
        }
        else {
          this.userdisplayname = this.LogName
        }
      }
    }
    else {
      this.refreshgriddata();
    }

    this.ngxService.stop();
  }

  usersAuthStatus(userId,commonkey) {
    let queryVariable = { "userid": userId.toString(),"Commonkey":commonkey };
    let query = this.vitalHttpServices.GetQuery("getUserInfofromuserid");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
      if (res?.data?.submenuData || res?.data?.RoleInfo) {
        this.userAuthStatus = res.data.submenuData[0]?.UserAuthStatus
        this.LogName = res.data.submenuData[0]?.UpdatedLoginName
        this.UserName = res.data.submenuData[0]?.UserName
        this.organizationguid = res.data.submenuData[0]?.OrganizationGUID
        this.userloginname = res.data.submenuData[0]?.loginname
        this.distinctRoleInfo = Array.from(
          new Map(res?.data?.RoleInfo.map(item => [item.rolename, item])).values()
      );
      this.myRolesArray = [...new Set(this.distinctRoleInfo.filter(item => item.STATUS === 1).map(item => item.rolename) )];
      }
    }, error => {
      console.error(error);
    })
  }

  deleteUser(key) {
    var obj =
    {
      "loginName": this.userloginname,
      "commonkey": this.resdata[0].CommonKey,
      "Deletekey": key
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        header: 'Delete User',

        message: '',
        alert: (key && key.toString().toLowerCase().trim() == 'deleteassociation') ? 'This action will remove the user associations and delete the user permanently. Do you want to proceed?' : 'Are you sure you want to delete the user ' + this.LogName + ' ?',
        continue: 'Yes',
        cancel: 'No',
        useRaisedButton: true
      },
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          this.ngxService.start();
          this.vaservices.DeleteUser(obj).subscribe(res => {
            let data: any = res;
            if (!data.error) {
              if (data.Success) {
                if (this.fromComponent == "allusers") {
                  this.ngxService.stop();
                  this._snackbar.open("User " + this.LogName + " deleted successfully", "Success");
                  this.activityEntity.entityId = data?.commonKey;
                  this.activityService.setActivitySession(this.activityEntity);
                  this.commonService.createActivityObject(obj['commonKey'], obj['loginName'], 'Users', 'Delete', '', '', '', {}, '', { 'User': { oldValue: obj['loginName'], newValue: obj['loginName'] } });
                  this.refreshgriddata();

                }
                // else {
                //   this.ngxService.stop();
                //   this._snackbar.open("User " + this.userData.loginname + " deleted successfully", "Success");
                //   this.detailsDisplay = false;
                //   this.UserPersonalInfoComponent.update(this.EditCommonKey);
                // }

              }
            }
          }, error => {
            console.error(error);
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", "Failed");
          });
        }
      });
  }
  //after added then go to view
  gridAddView() {
    this.gridDisplay = false;
    this.ViewDisplay = true;
    this.gridEmpty = false;
    this.loginname = this.addloginname;
    this.resdata = this.usersData.filter(
      data => data.loginname == this.loginname
    );
    if (this.resdata && this.resdata.length > 0) {
      this.edituserid = this.resdata[0].userid;
      // this.userdisplayname = this.resdata[0].loginname;
      if (this.resdata[0].loginname) {
        if ((['verified', 'autoinitiated', 'autoreinitiated'].includes(this.userAuthStatus?.toString().toLowerCase())) && this.VitalAdminTabService.tabs[0].tabData.mainCard.Group_Login_Type?.toString().toLowerCase() == 'email') {
          this.userdisplayname = this.resdata[0].email
          this.LogName=this.userdisplayname
        }
        else {
          this.userdisplayname = this.LogName
        }
      }
      this.userCommonKey = this.resdata[0].CommonKey;// This Common Key added by Sripathi Y (3277) To delete User Role Fix after Create button
      this.usernpi = this.resdata[0].npi;
      this.restusersstatus = this.resdata[0].ustatus;
      this.checklock = this.resdata[0].isaccountlockedout == "Not Specified" ? false : this.resdata[0].isaccountlockedout
      this.allowLogin = this.resdata[0].AllowLogin == null || this.resdata[0].AllowLogin == 1 ? 'True' : 'False'
      if (this.resdata[0].rolename) {
        this.myRolesArray = this.resdata[0].rolename.split(',');
      }
      Object.keys(this.resdata[0]).forEach(key => {
        if (!this.resdata[0][key] || this.resdata[0][key] == ' '
          || this.resdata[0][key] == '') {
          this.resdata[0][key] = 'Not Specified';
        }
      });
      if (this.resdata[0].address2 == 'Not Specified') {
        this.Address = this.resdata[0].address1;
      }
      else if (this.resdata[0].address1 == 'Not Specified') {
        this.Address = this.resdata[0].address2;
      }
      else {
        this.Address = this.resdata[0].address1 + ', ' + this.resdata[0].address2;
      }
      // if (!this.deploymentCheck) {
      this.userLoginType = this.resdata[0].LoginType;
      // }
      this.usertitle = this.resdata[0].title;
      this.useraliasName = this.resdata[0].AliasName;
      this.userExternalUserGUID = this.resdata[0].ExternalUserGUID;
      this.FormattedDisplayName = this.resdata[0].FormattedDisplayName.split(',');
      this.createddate = this.resdata[0].createddate;
      this.modifieddate = this.resdata[0].modifieddate;
      this.DepartmentList = [];
      if (this.resdata[0]?.DepartmentName) {
        this.DepartmentList = this.resdata[0]?.DepartmentName?.split(',');
      }
    }
    else {
      this.gridDisplay = true;
      this.ViewDisplay = false;
      this.gridEmpty = false;
    }
    this.ngxService.stop();
  }
  editBack() {
    this.detailsDisplay = false;
    this.ViewDisplay = true;
    this.resdata = this.usersData.filter(
      data => data.userid == this.edituserid
    );
    // this.userdisplayname = this.resdata[0].loginname;
    if (this.resdata[0].loginname) {
      if ((['verified', 'autoinitiated', 'autoreinitiated'].includes(this.userAuthStatus?.toString().toLowerCase())) && this.VitalAdminTabService.tabs[0].tabData.mainCard.Group_Login_Type?.toString().toLowerCase() == 'email') {
        this.userdisplayname = this.resdata[0].email
        this.LogName=this.userdisplayname
      }
      else {
        this.userdisplayname = this.LogName
      }
    }
    this.usernpi = this.resdata[0].npi;
    this.restusersstatus = this.resdata[0].ustatus;
    this.checklock = this.resdata[0].isaccountlockedout == "Not Specified" ? false : this.resdata[0].isaccountlockedout
    if (this.resdata[0].rolename) {
      this.myRolesArray = this.resdata[0].rolename.split(',');
    }
    Object.keys(this.resdata[0]).forEach(key => {
      if (!this.resdata[0][key] || this.resdata[0][key] == ' '
        || this.resdata[0][key] == '') {
        this.resdata[0][key] = 'Not Specified';
      }
    });
    this.usertitle = this.resdata[0].title;
    this.useraliasName = this.resdata[0].AliasName;
    this.userExternalUserGUID = this.resdata[0].ExternalUserGUID;
    this.FormattedDisplayName = this.resdata[0].FormattedDisplayName.split(',');
    this.createddate = this.resdata[0].createddate;
    this.modifieddate = this.resdata[0].modifieddate;
    this.DepartmentList = [];
    if (this.resdata[0]?.DepartmentName) {
      this.DepartmentList = this.resdata[0]?.DepartmentName?.split(',');
    }
  }

  exportData() {
    let LabName = sessionStorage.getItem("LabName")
    if (LabName == 'undefined') {
      LabName = sessionStorage.getItem('Org_Name');
      if (LabName == 'undefined') {
        LabName = sessionStorage.getItem('FlabName');
      }
    }
    var file_name = 'Users_';
    if (LabName != 'undefined' && LabName) {
      file_name = file_name + LabName;
      if (file_name.length > 31) {
        let fileName = file_name.split('_')[0] + '_';
        let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = file_name.split('_')[0].replace(' ', '') + '_';
          file_name = fileName + orgName + '_';
        }
        else {
          file_name = fileName + orgName + '_';
        }
      }
      else {
        file_name = file_name + '_';
      }
    }
    let commonKey = this.resdata[0]?.CommonKey || null;
    let id = !commonKey ? this.resdata[0]?.userid || null : null;
    this.ngxService.start();
    this.vitalHttpServices.GetUserDetailsExport(commonKey, id).subscribe((resData: any) => {
      this.ngxService.stop();
      if (!resData.errors) {
        let roleData = JSON.parse(resData?.content);
        let filename = file_name + '_' + this.organizationID.toString() + ".xlsx";
        var ws = XLSX.utils.json_to_sheet(roleData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "" + 'Users');
        XLSX.writeFile(wb, filename);
      }
    }, (error) => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  // compare old and new values
  compareTwoObjects(newObj, oldobject) {
    let changedAttrValues = {};
    let jsonFinalData = {}
    for (let [key, value] of Object.entries(this.commonService.compare(newObj, oldobject))) {
      changedAttrValues['oldValue'] = (oldobject[key] != null) ? oldobject[key].toString() : "";
      changedAttrValues['newValue'] = (value != null) ? value.toString() : "";
      jsonFinalData[key] = {
        oldValue: changedAttrValues['oldValue'],
        newValue: changedAttrValues['newValue']
      };
      changedAttrValues = {};
    }
    return jsonFinalData;
  }

  // unwanted fields will be removed
  getRedefinedObject(finalJson: any) {
    // mapping object
    const mappingObj = {
      title: 'Title',
      fname: 'First Name',
      lname: 'Last Name',
      midInitial: 'Mid Initial',
      loginName: 'Login Name',
      eduQual: 'Education Qualification',
      gender: 'Gender',
      npi: 'NPI',
      address1: 'Address 1',
      address2: 'Address 2',
      street: 'Street',
      city: 'Çity',
      state: 'State',
      zip: 'Zip',
      country: 'Country',
      phone: 'Phone',
      fax: 'Fax',
      email: 'Email',
      status: 'Status',
      rolename: 'Role Name',
      userNamedisplayFormat: 'Name Display Format',
      userNamedescriptionFormat: 'Description Display Format',
      usernameReportformat: 'Reports Display Format',
      password: 'Password',
      defaultPage: 'Default Page',
      ssn: 'SSN',
      upin: 'UPIN',
      userCode: 'User Code',
      commonKey: 'Common Key',
      isAccLockedOut: 'Is Acc Locked Out',
      allowPathologistReview: 'Allow Pathologist Review',
      isModifyTechnicalDataAccession: 'Is Modify Technical Data Accession',
      modifyTechnicalData: 'Modify Technical Data',
      roleList: 'Role List',
      isPrimaryRoleChanged: 'Is Primary Role Changed',
      manages: 'Manages',
      sendForreview: 'Send For Review',
      accountList: 'Account List',
      pathReadingLoc: 'Path Reading Loc',
      newRolesAdded: 'New Roles Added',
      medicalDirector: 'Medical Director',
      organizationID: 'Organization ID',
      primaryLocation: 'Primary Location',
      showMyCaseOnly: 'Show My Case Only',
      alowPathologistDiagnosisTemplate: 'Allow Pathologist Diagnosis Template',
      diagICDcodeReminder: 'Diag ICD code Reminder',
      autoPopulateDiagICD9Codes: 'Auto Populate Diag ICD9 Codes',
      EnableSignOutCorrection: 'Enable SignOut Correction',
      IsPathologistPrivileges: 'Is Pathologist Privileges',
      region: 'Region',
      externalUserGUID: 'External User GUID',
      userlicensure: 'User Licensure',
      holdReleaseDuration: 'Hold Release Duration',
      threshold: 'Threshold',
      HoldReleaseDurationInfo: 'Hold Release Duration Info',
      allowLogin: 'allowLogin',
      readcomputeon: 'Read Compute On',
      reviewthreshold: 'Review Threshold',
      reviewduration: 'Review Duration',
      reviewcomputeon: 'Review Compute On',
      screeningthreshold: 'Screen ingthreshold',
      screeningduration: 'Screening Duration',
      screeningcomputeon: 'Screening Compute On',
      createdby: 'Created By',
      userid: 'User Id',
      modifiedby: 'Modified By',
      loggedInUserId: 'LoggedIn User Id',
      entityType: 'Entity Type',
      entityID: 'Entity ID',
      actionperformed: 'Action Performed',
      actiondetails: 'Action Details',
      auditDate: 'Audit Date',
      reviewData: 'Review Data',
      RoleData: 'Role Data',
      npiVerifiedByUser: 'NPI Verified By User',
      mdmproviderid: 'MDM Provider Id',
      sessiondata: 'Session Data',
      workgroup: 'Workgroup'
    }

    let keystobeDeleted = [
      'sessiondata'
      , 'reviewData'
      , 'actionperformed'
      , 'entityType'
      , 'modifiedby'
      , 'createdby'
      , 'allowLogin'
      , 'RoleData',
      , 'userid'
      , 'HoldReleaseDurationInfo'
      , 'password'
      , 'rolename'
      , 'auditDate'
    ]

    keystobeDeleted.forEach(key => {
      delete finalJson[key];
    });

    // mapping object
    for (let key in finalJson) {
      // Get the new key from mappedObject
      let newKey = mappingObj[key];

      if (newKey) {
        // Assign the value to the new key
        finalJson[newKey] = finalJson[key];

        // Delete the old key
        delete finalJson[key];
      }
    }
  }

  statusChange(key) {
    let _this = this;
    this.ifUserStatus = false;
    this.ifUserDelete = false;
    if (key == 'status') {
      if (this.userStatus?.toString().toLowerCase() == 'inactive') {
        this.userStatus = 'active';

      } else {
        this.userStatus = 'Inactive';
      }
    } else if (key.toLowerCase() == 'deleted') {
      this.userStatus = 'Deleted';
    }

    var obj =
    {
      "loginName": this.userloginname,
      "userId": this.statususerid,
      "status": this.userStatus,
      "modifiedby": sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
    }
    this.vitalHttpServices.ChangeUserStatus(obj).subscribe(resp => {
      let data: any = resp;
      if (!data.error) {
        this.ngxService.stop();
        if (data.Success) {
          if (key == 'status') {
            this._snackbar.open("Status updated successfully for " + this.userloginname, "Success");
            this.refreshEditdata();
          } else if (key == 'deleted') {

            let finalJson = this.compareTwoObjects(data, _this.oldValue);
            this.activityEntity.entityId = data?.commonKey;
            this.activityService.setActivitySession(this.activityEntity);
            this.getRedefinedObject(finalJson);
            this.commonService.createActivityObject(data?.commonKey, data?.loginName, 'Users', 'Delete', '', '', '', {}, '', finalJson);
            this._snackbar.open("User " + this.userloginname + " deleted successfully", "Success");
            this.refreshgriddata();
            this.gridDisplay = true;
            this.ViewDisplay = false;
            this.detailsDisplay = false;
            this.adduserform = false;
          }

        } else {
          this.userStatus = this.ifUserStatus;
          this._snackbar.open("An error occurred while processing your request", "Failed");
          return false;
        }
      }
      else {

        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error)
    });
  }

  changeuserstatus(key) {
    if (this.btnDelete) {
      return;
    }
    this.deleteUser(key)
  }

  changestatus(grid, key) {
    let dataItem = grid;
    if (dataItem == null) {
      return;
    }
    else {
      this.unlockdata = this.actiondata.filter(
        data => data.userid == this.edituserid
      );
      // this.statusloginname = this.unlockdata[0].loginname;
      // this.userStatus = this.unlockdata[0].ustatus;
      // this.statususerid = this.unlockdata[0].userid;
      // this.restusersstatus = this.unlockdata[0].ustatus;
      // && this.unlockdata[0].status.toString().toLowerCase() == 'Active'
      if (this.unlockdata[0].status) {
        this.statusloginname = this.userloginname;
        this.userStatus = this.unlockdata[0].status;
        this.statususerid = this.unlockdata[0].userid;
        this.restusersstatus = this.unlockdata[0].status;
      }
      else if (this.unlockdata[0].ustatus) {
        this.statusloginname = this.userloginname;
        this.userStatus = this.unlockdata[0].ustatus;
        this.statususerid = this.unlockdata[0].userid;
        this.restusersstatus = this.unlockdata[0].ustatus;
      }
      if (key == 'status') {
        if (this.userStatus) {
          if (this.userStatus?.toString().toLowerCase() == 'inactive') {
            this.checked = false;
            this.initChecked = false;
            this.msg = "Activate"
          } else {
            this.checked = true;
            this.initChecked = true;
            this.msg = "Deactivate"
          }
          if (this.msg == "Active") {
            this.msg1 = "Deactivate"
          }
          else {
            this.msg1 = "Activate"
          }

        }
        this.ifUserStatus = true;
      }
      else if (key.toLowerCase() == 'deleted') {
        this.ifUserDelete = true;
      }


    }
  }

  regeneratePassword(grid) {
    this.unlockdata = null;
    let dataItem = grid;
    if (dataItem == null) {
      return;
    }
    else {
      this.unlockdata = this.actiondata.filter(
        data => data.userid == this.edituserid
      );

      this.loginname = this.userloginname;
      this.ifPasswordClicked = true;
    }
  }

  regeneratePasswordPopUp() {
    this.ngxService.start();
    let modifiedBy = sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100;
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    this.vitalHttpServices.GetResetPassword(this.loginname, modifiedBy, depKey).subscribe(data => {
      if (data != 'null') {
        this.ngxService.stop();
        this.passwordGen = data[0];
        this.ifPasswordClicked = false;
        this.ifPasswordGenerated = true;
      }
      else {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error)
    });
  }

  // to send a email
  toSendEmail(Email) {
    this.ifResetPasswordClicked = false;
    let obj = {
      email: Email,
    }
    this.ngxService.start();
    if (this.restusersstatus?.toString().toLowerCase() != 'active') {
      this.ngxService.stop();
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '250px',
        data: { header: "Alert!", message: "This user is inactive or has been deleted!", alert: "", continue: "OK", cancel: "dontshow" }
      });
      return;
    }
    if (this.resdata[0].email && this.resdata[0].email != 'Not Specified') {
      this.vitalHttpServices.sendResetPwdLink(obj).subscribe(result => {
        this.ngxService.stop();
        if (result.Success) {
          result.Message = JSON.parse(result.Message);
          result.Message = result.Message.email ? result.Message.message + " " + result.Message.email : result.Message.message;
          let dialogRef = this.dialog.open(ConfirmComponent, {
            disableClose: true,
            width: '500px',
            data: { header: "Reset Password", message: result.Message, alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
          });
          return;
        }
        else {
          this._snackBar.open(result.Message, 'Close');
        }
      }, error => {
        this.ngxService.stop();
        this._snackBar.open('Something went wrong.Please try again', 'Close');
      })
    }
    else {
      this.ngxService.stop();
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Reset Password", message: "", alert: "This user does not have an email id. Please assign an email and try again!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
      });
    }
  }

  lockOutButton() {
    if ((this.btninactiveuser || !this.checklock)) {
      return 'unlock-bg-color';
    }
    return

  }

  unlockUserPopUp() {
    this.ngxService.start();
    let modifiedby = sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
    this.vitalHttpServices.UnlockUser(this.lockoutloginname, this.edituserid, modifiedby).subscribe(data => {
      if (!data.error) {
        this.ngxService.stop();
        this.ifUnlockClicked = false;
        if (data.Success) {
          this.checklock = false;
          this._snackbar.open(data.Message, "Success");
          this.ngxService.start();
          let cardtype = 'Users';
          this.MainCardModel.GetData(this.edituserid, cardtype, cardtype, 0).subscribe(result => {
            if (!result.error) {
              let UnlockData: any = result.data.Card;
              var unlockDataIndex = UnlockData.findIndex(x => x.Login_Name == this.lockoutloginname);
              if (unlockDataIndex != -1)
                this.actiondata.Locked = UnlockData[unlockDataIndex].Locked;
            } else {
              this._snackbar.open("An error occurred while processing your request", "Failed");
            }
            this.ngxService.stop();
          }, error => {
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", "Failed");
            console.error(error);
          });
        } else {
          this.ngxService.stop();
          this._snackbar.open(data.Message, "Failed");
        }
      } else {

        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  //Account lock
  lockout(grid) {
    this.unlockdata = null;
    let dataItem = grid;
    if (dataItem == null) {
      return;
    }
    else {
      this.unlockdata = this.actiondata.filter(
        data => data.userid == this.edituserid
      );
      this.lockoutloginname = this.userloginname;

      this.ifUnlockClicked = true

    }
  }

  //Approve Request
  approveRequest(data) {
    if (this.organizationLoginType?.toString().toLowerCase() == 'email' && this.userLoginType?.toString().toLowerCase() == 'email' && this.resdata[0].email?.toString().toLowerCase().includes("starmarkit.com") || this.resdata[0].email?.toString().toLowerCase().includes("vitalaxis.com")) {
      this.ngxService.stop();
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Alert!", message: "This user should use their domain password to login.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return;
    }
    this.unlockdata = null;
    let dataItem = data;
    if (dataItem == null) {
      return;
    }
    else {
      dataItem = this.actiondata.filter(
        data => data.userid == this.edituserid
      );
      this.ifApproveRequest = true;
    }

    let queryVariable;
    this.ngxService.start();
    if (this.searchValue != undefined) {
      queryVariable = { "requestId": this.searchValue };
    } else if (this.searchValue == undefined) {
      queryVariable = { "loginname": dataItem[0].loginname, "userId": dataItem[0].userId };
    }
    else {
      queryVariable = { "loginname": dataItem[0].loginname, "userId": dataItem[0].userId, "requestId": this.searchValue };
    }
    let query = this.vitalHttpServices.GetQuery("approverequestpassword");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      if (res.data.submenuData.length > 0) {
        this.htnapproved = false;
        this.approveResponse = res.data.submenuData[0];
        this.loginnames = this.approveResponse.loginname === null ? dataItem[0].loginname : this.approveResponse.loginname;;
        this.firstName = this.approveResponse.FirstName;
        this.lastnames = this.approveResponse.LastName;
        this.facilityaddress = this.approveResponse.FacilityAddress;
        this.requestID = this.approveResponse.RequestID;
        this.requestedDate = this.approveResponse.RequestedDate;
        this.requestedIP = this.approveResponse.RequestedIP;
        this.successIP = this.approveResponse.SuccessIP;
        if (this.approveResponse.RequestID != undefined) {
          this.showSearch = false;
          this.htnapproved = false;
        } else {
          this.showSearch = true;
          this.htnapproved = true;
        }
      } else {
        this.showSearch = true;
        this.htnapproved = true;
        if (this.searchValue != undefined) {
          this.showMsg = true;
          this.htnapproved = true;
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  closeApprovalPopup() {
    this.ifApproveRequest = false;
    this.searchValue = undefined;
    this.firstName = "";
    this.lastnames = "";
    this.requestedIP = "";
    this.requestedDate = "";
    this.successIP = "";
    this.facilityaddress = "";
    this.loginnames = "";
    this.requestID = undefined;
    this.showSearch = true;
    this.showMsg = false;
  }

  requestApproval(type) {
    if (this.searchValue != undefined || this.requestID != undefined) {
      let reqId = this.requestID == undefined ? this.searchValue : this.requestID;
      if (reqId.match('RID')) {
        let loggedIn_userID = sessionStorage.getItem('Userid');
        this.ngxService.start();
        var obj = {
          "loginName": this.loginnames,
          "status": type,
          "requestid": this.requestID == undefined ? this.searchValue : this.requestID,
          "actionby": loggedIn_userID != "" ? loggedIn_userID : "-100",
        }
        this.vitalHttpServices.ApproveRequestPassword(obj).subscribe(returnres => {
          let res = JSON.stringify(returnres);
          let cc = JSON.parse(res);
          if (cc.Success) {
            this.txtMsg = cc.Message;
            this._snackbar.open(this.txtMsg)
            this.ngxService.stop();
            this.ifApproveRequest = false;
            this.closeApprovalPopup();
          } else {
            this._snackbar.open(cc.Message, "Failed");
            this.ngxService.stop();
          }
        }, error => {

          console.error(error);
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        });
      }
      else {
        this._snackbar.open("Please enter a valid Request ID", "Alert");
        return false;
      }
    }
    else {
      this._snackbar.open("Please enter Request ID", "Alert");
      return false;
    }
  }

  onsearchapproveRequest() {
    this.ifApproveRequest = true;
    let queryVariable;
    this.ngxService.start();
    if (this.searchValue != undefined) {
      queryVariable = { "requestId": this.searchValue };
    } else if (this.searchValue == undefined) {
      return;
    }
    let reqId = this.searchValue;
    if (reqId.match('RID')) {
      let query = this.vitalHttpServices.GetQuery("approverequestpassword");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vitalHttpServices.GetData(queryResult).subscribe(res => {
        this.ngxService.stop();
        if (res.data.submenuData.length > 0) {
          this.htnapproved = false;
          this.approveResponse = res.data.submenuData[0];
          this.loginnames = this.approveResponse.loginname === null ? this.resdata[0].loginname : this.approveResponse.loginname;
          this.firstName = this.approveResponse.FirstName;
          this.lastnames = this.approveResponse.LastName;
          this.facilityaddress = this.approveResponse.FacilityAddress;
          this.requestID = this.approveResponse.RequestID;
          this.requestedDate = this.approveResponse.RequestedDate;
          this.requestedIP = this.approveResponse.RequestedIP;
          this.successIP = this.approveResponse.SuccessIP;
          if (this.approveResponse.RequestID != undefined) {
            this.showSearch = false;
            this.htnapproved = false;
          } else {
            this.showSearch = true;
            this.htnapproved = true;
          }
        } else {
          this.showSearch = true;
          if (this.searchValue != undefined) {
            this.showMsg = true;
            this.htnapproved = true;
          }
        }
      }, error => {

        console.error(error);
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      });
    }
    else {
      this.ngxService.stop();
      this._snackbar.open("Please enter a valid Request ID", "Alert");
      return false;
    }
  }
  //#endregion

  //#region
  //Migrate user to Email
  migrateUser(userID, grid) {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (re.test(this.resdata[0].email) == false) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "Invalid user Email ID", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return false;
    }
    if (this.resdata[0]?.ustatus?.toString().toLowerCase() == 'inactive') {
      this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "", alert: "This user is inactive. Please activate the user to migrate", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
      });
      return;
    }
    else {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "An Email with invitation link will be sent to the user. Do you want to proceed?", alert: "", continue: "Yes", cancel: "No", useRaisedButton: true }
      });
      return dialogRef.afterClosed().toPromise().then((result) => {
        if (result) {
          if (this.resdata[0].email && this.resdata[0].email != 'Not Specified') {
            let requestData = [];
            let requestJson = {
              UserID: userID,
              OrganizationID: Number(sessionStorage.getItem('org_id')),
              Email: this.resdata[0].email,
              slno: 1
            }
            requestData.push(requestJson);
            this.validateUserData(requestData, grid);
          }
          else {
            this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '500px',
              data: { header: "Migrate User", message: "", alert: "This user does not have an email id. Please assign an email and try again!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
            });
          }
        }
      });
    }
  }
  //#endregion

  validateUserData(requestData, grid) {
    this.ngxService.start();
    this.vitalHttpServices.validateMigrateUser(requestData, this.deploymentKey).subscribe(async result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (requestData.length == 1) {
          if (result.length >= 1) {
            if (result[0]) {

              let warningMessage = result[0].StatusMessage;
              if (warningMessage.toString().toLowerCase().includes("duplicate")) {
                let userName = ""
                if (result[0].StatusMessage.toString().toLowerCase().includes("duplicate")) {
                  userName += result[0].LoginName
                }
                userName = userName.toString().substring(0, userName.length - 1);
                this.dialog.open(ConfirmComponent, {
                  disableClose: true,
                  width: '500px',
                  data: { header: "Migrate User", message: "", alert: "User(s) with the same email already exist in this group.User(s) : " + userName, continue: 'OK', cancel: "dontshow", useRaisedButton: true }
                });
              }
              else if (warningMessage.toString().toLowerCase().includes("login type is not email")) {
                this.dialog.open(ConfirmComponent, {
                  disableClose: true,
                  width: '500px',
                  data: { header: "Migrate User", message: "", alert: "This group does not support Email based login for the Users. Please contact support team.", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
                });
              }
              else if (warningMessage.toString().toLowerCase().includes("user does not exist")) {
                this.dialog.open(ConfirmComponent, {
                  disableClose: true,
                  width: '500px',
                  data: { header: "Migrate User", message: "", alert: "This user does not exist!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
                });
              }
              else if (warningMessage.toString().toLowerCase().includes("group does not exist")) {
                this.dialog.open(ConfirmComponent, {
                  disableClose: true,
                  width: '500px',
                  data: { header: "Migrate User", message: "", alert: "This group does not exist!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
                });
              }
            }
            else {
              await this.confirmMigration(requestData, this.deploymentKey);
            }
          }
          else {
            await this.confirmMigration(requestData, this.deploymentKey);
          }

        }
        else {
          let warningCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (result[i].StatusMessage.toString().toLowerCase().includes('group login type is not email!')) {
              warningCount += 1

            }
            if (result[i].StatusMessage.toString().toLowerCase().includes('duplicate')) {
              let userName = ""
              let currentUserLoginName = "";
              if (result.length > 0) {
                if (result[0].StatusMessage.toString().toLowerCase().includes("duplicate")) {
                  userName += result[0].LoginName
                  currentUserLoginName += result[0].UserLoginName
                }
              }
              this.dialog.open(ConfirmComponent, {
                disableClose: true,
                width: '500px',
                data: { header: "Migrate User", message: "", alert: "The same email as user : " + currentUserLoginName + " already exists in this group for user : " + userName, continue: 'OK', cancel: "dontshow", useRaisedButton: true }
              });
              return
            }
          }
          if (warningCount > 0) {
            this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '500px',
              data: { header: "Migrate User", message: "", alert: "This group does not support Email based login for the Users. Please contact support team.", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
            });
            this.refreshgriddata();
          }
          else {
            this.dialog.open(ConfirmComponent, {
              disableClose: true,
              width: '500px',
              data: { header: "Migrate User", message: "", alert: "An email invite has been sent to the selected users.", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
            });
            let info = `Migrate User - Bulk email migrations have been initiated`
            let entitykey = requestData[0].UserID;
            this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': 'Users' }] })
            this.commonService.auditDetails(entitykey, '', [], requestData, 'Migrate', this.templateData, '', info);
            this.refreshgriddata();
          }
        }
      }
    }, error => {
      console.error(error);
    });
  }


  editMethod() {
    this.ngxService.start("1");
    //this.editSavedRoleList(this.edituserid.toString(), sessionStorage.getItem('org_id'));
    let queryVariable = { "accid": this.edituserid.toString() };
    let query = this.GetQuery('userpersonalinfo');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      this.usersdeatils = Resdata.data.submenuData[0];
      this.usersdeatils.AllowLogin = this.allowLogin && this.allowLogin.toString().toLowerCase().trim() == "true" ? true : false;
      this.editloginname = this.usersdeatils.loginname
      this.ViewDisplay = false;
      this.editMode = true;
      this.activityEntity.entityId = Resdata?.data?.submenuData[0]?.commonKey;
      this.activityService.setActivitySession(this.activityEntity);
      // this.detailsDisplay = true;
      this.adduserform = true;
      this.ngxService.stop("1");
    },
      error => {
        console.error(error);
        this.ngxService.stop("1");
      });

  }

  //#region Add
  SearchOrg() {
    this.ngxService.start();
    this.context = sessionStorage.getItem('search_context');
    let searchDrpValue;
    let query;
    let depKey: string = this.DeploymentKeys;
    let queryResult: any;
    let queryVariable;
    let searchInpValue;
    let queryString: string = "";
    searchDrpValue = 'Group ID';
    searchInpValue = sessionStorage.getItem('org_id');
    query = this.GetQuery('adduserfilterorg');
    this.card = this.context;
    this.vaservices.deploymentKey = depKey.toLowerCase();
    sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
    switch (searchDrpValue) {
      case 'Group ID':
        queryString = "organizationid >= \"1\":decimal and organizationid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      case 'Location ID':
        queryString = "accountid >= \"1\":decimal and accountid in \"" + searchInpValue + "\"";
        queryVariable = { "filterParam": queryString };
        break;
      default:
        queryVariable = '';
        break;
    }
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        this.templateData = res.data.Organizations_list;
        if (this.templateData[0].LoginType != null) {
          this.LoginTypesflag = this.templateData[0].LoginType.toLowerCase() != 'email' ? true : false;
        }
        else {
          this.LoginTypesflag = true;
        }
        if (this.templateData == undefined) {
          this.templateData = res.data.Accounts_list;
        }
        if (this.templateData.length > 0) {
          this.showOrgData = true;
          this.NoOrgData = false;
        }
        else {
          this.templateData = []
          this.showOrgData = false;
          this.NoOrgData = true;
        }
      }
      else {
        this.templateData = [];
        this.showOrgData = true;
        this.NoOrgData = false;
        this.ngxService.stop();
      }
      this.setOrganization(searchInpValue)
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.templateData = [];
      this.showOrgData = true;
      this.NoOrgData = false;
      this.ngxService.stop();
    });
  }
  setOrganization(OrgValue) {
    this.frm_AddUsergroup.patchValue({
      frmOrganization: OrgValue,
    });
    this.SelectedOrgID = OrgValue;
    this.showAddUserDetailsFunc(OrgValue);
  }
  resetAddUserDetails() {
    this.associationData = "";
    this.rolesdata = "";

    this.showAccounts = false;
    this.showRoles = false;
    this.associatedAccountNames = [];

    this.holdReleaseDuration = "5";
    this.DiagICDcodeReminder = false;
    this.isICDdisplay = false;
    this.UserType = "";
    this.IsOrderingFacility = false;
    this.EPGorgType = "";
    this.ArrEPGOrgType = "";
    this.userID = 0;
    this.ArrOrgType = [];
    // this.associationtype;
    this.ArrThresholdDuration = [];
    this.ArrSavedRoles = [];
    // public ArrIsMedicalRolePresent = [];
    this.IsMedicalDirector = false;
    this.IsMedDirectorExistsForCommon = false;
    this.hidIsCytoSelected = false;
    this.hidIsCytoSupervisiorSelected = false;
    this.hidIsChecked = false;
    this.hidPrimaryRole = "";
    this.hidIsPathlogistSelected = false;
    this.hidIsMedicalDirector = false;

    this.ShowMedicalDirector = false;
    this.ShoHoldReleaseDuration = false;

    this.hidIsPhysicianSelected = false;
    this.CommonKey = "";

    this.StateDiscription = "";

    this.StateList = [];
    this.HRD_KValue = "";
    this.MD_KValue = "";
    this.ShowLicensureDetails = false;
    this.alertOnSave1 = false;
    this.alertOnSave2 = false;
    //this.alertOnSave3 = false;
    this.alertOnSave4 = false;
    this.alertOnSave5 = false;
    // this.alertOnSave6 = false;
    this.alertOnSaveCommon = false;
    this.CommonAlertOnSave = "";
    this.ClickFrom = "UI";

    // this.RoleCheckboxValidator = false;

    this.SelectedOrgID = "";

    this.showfrmInp_AllowReviewPathologist = false;
    this.showfrmInp_AllowtoeditAccession = false;
    this.showfrmInp_EditSite = false;
    this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
    this.showfrmInp_Notifyusers = false;
    this.showfrmInp_DisplayFormatReports = true;
    this.hidlicensure = "";
    this.showfrmInp_Threshold = false;
    this.showfrmDrp_ThresholdDuration = false;
    this.showfrmInp_PopulateDiag = false;

    // this.frm_AddUsergroup.reset();
    //do not reset here as org and deployment values will be lost
    this.frm_AddUsergroup.patchValue(
      {
        frmInp_title: 'Dr.',
        frmInp_Gender: "1",
        frmInp_DisplayFormatWebpage: "F L D",
        frmInp_DisplayFormatReports: "S. F L D",
        frmInp_Status: "Active",

        frmchk_Location: false,
        frmInp_ShowMyCaseOnly: false,
        frmInp_AllowtoeditAccession: false,
        frmInp_EditSite: false,
        frmInp_AllowPathologistDiagnosisTemplate: false,
        frmInp_Notifyusers: false,
        frmInp_LoginName: '',

        frmArruserAccounts: [],
        frmArruserRoles: [],
        frmInp_US: false,
        frmInp_ST: false,
        //rest form controls are reset here
        frmAccountName: '',
        frmInp_Address1: '',
        frmInp_FirstName: '',
        frmInp_Address2: '',
        frmInp_LastName: '',
        frmInp_Street: '',
        frmInp_MiddleInitial: '',
        frmInp_City: '',
        frmInp_Qualification: '',
        frmInp_State: 'Select',
        frmInp_Zip: '',
        frmInp_Country: '',
        frmInp_NPI: 'UNKNOWN',
        frmInp_Phone: '',
        frmInp_Fax: '',
        frmInp_Email: '',
        frmInp_SSN: '',
        frmInp_UserCode: '',
        frmInp_UPIN: '',
        frmInp_ExternalUserGUID: '',
        // frmInp_Region: '',
        fromInp_ST: false,
        frmInp_AllowReviewPathologist: false,
        frmInp_Threshold: '',
        frmDrp_ThresholdDuration: '',
        frmInp_PopulateDiag: false,
        frmDrp_OrgSendforReview: 'true',
        frmDrp_OrgManages: 'true',
        frmDrp_OrgWorks: 'true',
        frmchk_OrgLocation: false,
        // formLicensure: this._fb.array([]),
        frmLblchk_OrgLocation: '',
        frmChk_RoleCheckboxValidator: false,
        frmInp_AllowLogin: false,
        frmInp_WorkGroup: []
      });
  }
  onChangeDep(deviceValue) {
    this.frm_AddUsergroup.patchValue({
      frmOrganization: "",
      frmDeploymentKey: deviceValue
    });
    this.NoDeploymentKeyAlert = false;
    this.ShowFrmAddUserDetails = false;
  }
  getAssociationTypes(accIDs, OrgId) {
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('getassociationtypeforaccid');
    let i, Qstr = "";
    for (i = 0; i < accIDs.length; i++) {
      if (i == accIDs.length - 1) {
        Qstr += "accountid = \"" + accIDs[i] + "\"";
      }
      else {
        Qstr += "accountid = \"" + accIDs[i] + "\" or ";
      }
    }
    queryVariable = { "accid": Qstr };
    let associationTypes: any[];
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.associationData = res.data.vwGetAssociationtypeByOrgID_list;
          associationTypes = [];
          this.associationData.forEach(element => {
            if (associationTypes.indexOf(element.associationtype) == -1) {
              associationTypes.push(element.associationtype)
            }
          });
          for (i = 0; i < associationTypes.length; i++) {
            if (associationTypes[i] === 'Both') {
              this.cardtype = 'Both';
              sessionStorage.setItem('Contexttype', this.cardtype);
            }
            if (associationTypes[i].toLowerCase() === 'laboratory' || associationTypes[i].toLowerCase() === 'pathologists') {
              let cardtype = 'Lab';
              sessionStorage.setItem('Contexttype', cardtype);
            }
            else if (associationTypes[i].toLowerCase() === 'ordering facility') {
              let cardtype = 'facility';
              sessionStorage.setItem('Contextype', cardtype);
            }
          }

          this.getRoles(associationTypes, OrgId);
        }
        else {
          this.associationData = "";
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }
  getRoles(AssociationTypes, OrgId) {
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    for (i = 0; i < AssociationTypes.length; i++) {
      if (i == AssociationTypes.length - 1) {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\"";
      }
      else {
        Qstr += "associationtype = \"" + AssociationTypes[i] + "\" or ";
      }
    }
    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {

        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.rolesdata = [];
          this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.rolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.rolesdata = rolesarray;

          (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();

          let k;
          for (k = 0; k < this.rolesdata.length; k++) {
            let frm;
            frm as UntypedFormGroup;
            frm = "userRole_" + k;
            let IsPathologistActive: boolean = false;
            if (this.rolesdata[k].rolename == "Medical Staff")
              this.rolesdata[k].rolename = "Medical Assistant";
            else if (this.rolesdata[k].rolename == "Transcriptionist")
              this.rolesdata[k].rolename = "Pathologist Assistant";
            if (this.rolesdata[k].rolename == "Pathologist") {
              this.ShowMedicalDirector = true;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new UntypedFormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new UntypedFormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new UntypedFormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new UntypedFormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmChk_IsMedicalDirector: new UntypedFormControl({ value: false, disabled: false }),
                frmLbl_userID: new UntypedFormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new UntypedFormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new UntypedFormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: new UntypedFormControl({ value: '0', disabled: true })
              });
            }
            else if (this.rolesdata[k].rolename.toLowerCase() == "lab manager") {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = true;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new UntypedFormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new UntypedFormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new UntypedFormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new UntypedFormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmInp_RoleHoldreleaseDuration: new UntypedFormControl({ value: '0', disabled: true }),
                frmLbl_userID: new UntypedFormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new UntypedFormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new UntypedFormControl({ value: '', disabled: true }),
                frmChk_IsMedicalDirector: new UntypedFormControl({ value: false, disabled: true })
              });
            }
            else {
              this.ShowMedicalDirector = false;
              this.ShoHoldReleaseDuration = false;
              frm = this._fb.group({
                frmChk_UserRoles_RoleName: new UntypedFormControl({ value: false, disabled: false }),
                frmLblChk_UserRoles_RoleName: new UntypedFormControl(this.rolesdata[k].rolename),
                frmRd_UserRoles_RoleName: new UntypedFormControl({ value: '', disabled: true }),
                frmlbl_DisplayName: new UntypedFormControl({ value: this.rolesdata[k].displayname, disabled: true }),
                frmLbl_userID: new UntypedFormControl({ value: '', disabled: true }),
                frmLbl_usrStatus: new UntypedFormControl({ value: '', disabled: true }),
                frmImg_UserIDnUserStatus: new UntypedFormControl({ value: '', disabled: true }),
                frmInp_RoleHoldreleaseDuration: new UntypedFormControl({ value: '0', disabled: true }),
                frmChk_IsMedicalDirector: new UntypedFormControl({ value: false, disabled: true })
              });
            }

            this.ArrSavedRoles.forEach((e, row_index) => {
              if (frm.controls.frmlbl_DisplayName.value == e.rolename) {
                frm.controls.frmChk_UserRoles_RoleName.value = true;
                frm.controls.frmChk_UserRoles_RoleName.disable();
                frm.controls.frmRd_UserRoles_RoleName.value = '';
                frm.controls.frmRd_UserRoles_RoleName.enable();

                if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist") {
                  this.hidIsCytoSelected = true
                }
                if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "cytotechnologist supervisor") {
                  this.hidIsCytoSupervisiorSelected = true
                }
                if ((e.HoldReleaseDuration.toString() != "" && e.HoldReleaseDuration.toString() != null)) {
                  if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                    if (frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "lab manager" || frm.controls.frmLblChk_UserRoles_RoleName.value.toLowerCase() == "pathologist") {
                    }
                  }
                }
                if (e.IsPrimary == true) {
                  this.hidPrimaryRole = e.rolename;
                  frm.controls.frmRd_UserRoles_RoleName.value = frm.controls.frmLblChk_UserRoles_RoleName.value;
                }
                if (frm.controls.frmChk_UserRoles_RoleName.value == true) {
                  frm.controls.frmLbl_userID.value = e.userid;
                  if (e.Status == true) {
                    frm.controls.frmLbl_usrStatus.value = e.usrStatus;
                    frm.controls.frmImg_UserIDnUserStatus.src = 'activestatus';
                    frm.controls.frmImg_UserIDnUserStatus.title = 'Active';
                  }
                  else {
                    frm.controls.frmLbl_usrStatus.value = e.usrStatus;
                    frm.controls.frmImg_UserIDnUserStatus.src = 'inactivestatus';
                    frm.controls.frmImg_UserIDnUserStatus.title = 'InActive';
                  }
                }
              }
              if (e.rolename == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                this.frm_AddUsergroup.patchValue(
                  {
                    frmInp_AllowtoeditAccession: e.IsModifyTechnicalDataAccession as boolean,
                    frmInp_EditSite: e.ModifyTechnicalData as boolean,
                    frmInp_AllowReviewPathologist: e.ReviewPathCanSignout as boolean
                  });

                if (e.DiagICDcodeReminder == null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility as string != "True") {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if ((e.rolename == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) || e.rolename == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
                if (e.DiagICDcodeReminder as string != "" && e.DiagICDcodeReminder as string != null) {
                  // if (this.UserType == "Laboratories" && this.IsOrderingFacility != "True") {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: this.DiagICDcodeReminder as boolean
                    }
                  );
                }
                else {
                  this.frm_AddUsergroup.patchValue(
                    {
                      frmInp_Notifyusers: e.DiagICDcodeReminder as boolean
                    }
                  );
                }
              }
              if (e.rolename == "Pathologist") {
                frm.controls.IsPathologistActive.value = e.STATUS as boolean;
                frm.controls.frmChk_IsMedicalDirector.value = e.isMedicalDirector as boolean;
              }
            });

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSelected = true;
              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Cytotechnologist Supervisor" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsCytoSupervisiorSelected = true;
              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }
            let isPatholigistEnabled = false;

            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" && frm.controls.frmChk_UserRoles_RoleName.value == true) {
              this.hidIsPathlogistSelected = true;
              isPatholigistEnabled = true;
              this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
              this.showfrmInp_AllowReviewPathologist = true;
              this.ShowLicensureDetails = true;

              this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
              this.showfrmInp_AllowtoeditAccession = true;
              this.showfrmInp_Threshold = true;

              this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
              this.showfrmInp_EditSite = true;
              this.showfrmDrp_ThresholdDuration = true;

              this.frm_AddUsergroup.controls['frmChk_IsMedicalDirector'].enable();

              this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
              this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
              this.showfrmInp_PopulateDiag = true;

              this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
            }
            /*Flag as Medical Director*/
            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist") {
              if (this.IsMedicalDirector == true && this.IsMedDirectorExistsForCommon == false)
                this.hidIsMedicalDirector = true;
              if ((this.IsMedicalDirector == true && this.IsMedDirectorExistsForCommon == false) || !IsPathologistActive) {
                frm.controls.frmChk_IsMedicalDirector.disable();
              }
              else {
                frm.controls.frmChk_IsMedicalDirector.enable();
              }
            }
            if (frm.controls.frmLblChk_UserRoles_RoleName.value == "Pathologist" || frm.controls.frmLblChk_UserRoles_RoleName.value == "Physician") {

              this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
            }
            if (isPatholigistEnabled) {
              let k;
              for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {

                (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
              }
              this.ShowSendforReview(true);
            }
            (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
          }
          this.ShowSendforReview(false);

          //to display hold release duration and  medical director
          this.MD_KValue = -1;
          this.HRD_KValue = -1;
          for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
            if (this.HRD_KValue == -1 && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmInp_RoleHoldreleaseDuration').enabled == true) {
              this.HRD_KValue = k;
            }
            if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'lab manager') {
              this.rolelablistcount = k

            }
            if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist') {
              this.rolelablistcytcount = k

            }
            if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value.toLowerCase() == 'cytotechnologist supervisor') {
              this.rolelablistcyscount = k

            }

            if (this.MD_KValue == -1 && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_IsMedicalDirector').enabled == true) {
              this.MD_KValue = k;
            }

            if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmLblChk_UserRoles_RoleName').value == 'Pathologist') {
              this.rolelistcount = k;
              if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
                this.MD_KValue = k;
                if (this.EPGorgType == 'Pathologists') {
                  this.ShowLicensureDetails = true;
                }
                else {
                  this.ShowLicensureDetails = false;
                }
              } else {
                this.ShowLicensureDetails = false;
              }
            } else {
              this.ShowLicensureDetails = false;
            }
          }
          this.showRoles = true;
        }
      }
      else {
        console.error(res.error);
        this.rolesdata = [];
        let k;
        for (k = 0; k < this.rolesdata.length; k++) {
          this.ShowMedicalDirector = false;
          this.ShoHoldReleaseDuration = false;
          let frm;
          frm as UntypedFormGroup;
          frm = "userRole_" + k;
          frm = this._fb.group({
            frmChk_UserRoles_RoleName: [{ value: false, disabled: false }],
            frmLblChk_UserRoles_RoleName: new UntypedFormControl(''),
            frmRd_UserRoles_RoleName: [{ value: '', disabled: false }],
            frmlbl_DisplayName: ''

          });
          (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).clear();
          (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).push(frm);
        }
        // this.showAccounts = false;
        this.showRoles = false;
      }
      this.getIsMedicalDirectorvalue();
      //this.getIsMedicalRolePresent(OrgId);
    }, error => {
      console.error(error);
    });

  }
  ShowSendforReview(show: boolean) {
    if (show) {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].enable();
      let k;
      for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {

        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].enable();
      }
    }
    else {
      this.frm_AddUsergroup.controls['frmDrp_OrgSendforReview'].disable();
      let k;
      for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_SendforReview'].disable();
      }
    }
  }
  ToggleMainChkBxForRoleName(event) {
    let k;
    for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
          {
            frmChk_UserRoles_RoleName: event.target.checked,
            frmRd_UserRoles_RoleName: ''
          });
      }
      else if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false
        && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
          {
            frmRd_UserRoles_RoleName: ''
          });
      }

      if (event.target.checked) {
        //when checked =  true
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].enable();
      }
      else {
        //when checked =  false
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false
          && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].enable();
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
        else if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).controls['frmRd_UserRoles_RoleName'].disable();
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
    }
  }
  TogglePrimaryRole(event, index) {
    let k;
    for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; k++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == true) {
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {

              frmRd_UserRoles_RoleName: ''
            });
        }
      }
      else if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').enabled == false && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).get('frmChk_UserRoles_RoleName').value == true) {
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(k)).patchValue(
            {
              frmRd_UserRoles_RoleName: ''
            });
        }
      }
    }
    event.target.checked = true;
  }
  showAddUserDetailsFunc(OrgId) {
    OrgId = sessionStorage.getItem('org_id');
    this.ShowFrmAddUserDetails = true;
    this.getHoldReleaseDuration(OrgId);
  }
  getHoldReleaseDuration(OrgID) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserholdreleaseduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "filterparameter": "organizationid > \"1\" and organizationid = \"" + OrgID + "\"" }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.holdReleaseDuration = result.data.submenudata[0].HoldReleaseDuration;
            this.EditholdReleaseDuration = this.holdReleaseDuration;
            this.DiagICDcodeReminder = result.data.submenudata[0].DiagICDcodeReminder;
            this.EditDiagICDcodeReminder = this.DiagICDcodeReminder;
            if (this.holdReleaseDuration == null) {
              this.holdReleaseDuration = "5";
            }
            if (this.DiagICDcodeReminder == null) {
              this.DiagICDcodeReminder = false;
            }
            this.isICDdisplay = this.DiagICDcodeReminder;
          }
          else {
            this.holdReleaseDuration = "5";
            this.DiagICDcodeReminder = false;
          }
        }
        else {
          this.holdReleaseDuration = "5";
          this.DiagICDcodeReminder = false;
        }
      }
      this.getGetOrganizationType(OrgID);
    }, error => {
      console.info(error);
    });
  }
  getGetOrganizationType(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetorgtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrOrgType = result.data.submenudata;
            this.UserType = this.ArrOrgType[0].associationtype;
            if (this.ArrOrgType[0].IsListedAsOF.toLowerCase == "true") {
              this.IsOrderingFacility = true;
            }
            else {
              this.IsOrderingFacility = false;
            }
          }
          else {
            this.UserType = "";
            this.IsOrderingFacility = false;
          }
        }
        else {
          this.UserType = "";
          this.IsOrderingFacility = false;
        }
      }
      if (this.UserType == "Laboratories" && this.IsOrderingFacility != true) {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true,
          frmInp_Notifyusers: this.isICDdisplay,
          frmInp_ExternalUserGUID: this.generateNewGuid()
        });
      }
      else {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: false,
          frmInp_EditSite: false,
          frmInp_Notifyusers: this.isICDdisplay,
          frmInp_ExternalUserGUID: this.generateNewGuid()
        });
      }
      this.getGetEPGOrganizationType(OrgId);

    }, error => {
      console.info(error);
    });
  }
  getGetEPGOrganizationType(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetepgorgtype");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.ArrEPGOrgType = result.data.submenudata[0].associationtype;
            if (this.ArrEPGOrgType != "") {
              this.EPGorgType = this.ArrEPGOrgType;
            }
            else {
              this.EPGorgType = "";
            }
          } else {
            this.EPGorgType = "";
          }
        } else {
          this.EPGorgType = "";
        }
      }
      this.getThresholdDuration(OrgId);
    }, error => {
      console.info(error);
    });
  }
  getThresholdDuration(OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "adduserthresholdduration");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != null && result.data.submenudata != undefined) {
          if (result.data.submenudata.length > 0) {
            this.ArrThresholdDuration = result.data.submenudata;
            this.frm_AddUsergroup.patchValue({
              frmDrp_ThresholdDuration: "Select"
            });
          }
          else {
            this.ArrThresholdDuration = [];
          }
        }
        else {
          this.ArrThresholdDuration = [];
        }
      }
      this.getSavedRoleList(0, OrgId); // userid for add user is 0 by default
    }, error => {
      console.info(error);
    });
  }
  getSavedRoleList(userid, OrgId) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.CommonKey = result.data.submenudata[0].CommonKey;
            if (this.CommonKey == null) {
              this.CommonKey = "";
            }
          }
          else {
            this.CommonKey = "";
          }
        }
        else {
          this.CommonKey = "";
        }
        //get getSavedRoleList
        drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetsavedrole");
        drpqueryRequest = {
          "OperationName": null,
          "Query": drpquery[0].Query,
          "Variables": { "Commonkey": this.CommonKey }
        };
        this.vaservices.GetData(drpqueryRequest).subscribe(result => {
          if (!result.errors) {
            if (result.data.submenudata != undefined && result.data.submenudata != null) {
              if (result.data.submenudata.length > 0) {
                this.ArrSavedRoles = result.data.submenudata
                this.ArrSavedRoles.forEach(element => {
                  element.IsPrimary = element.IsPrimary == null ? false : element.IsPrimary;
                  element.status = element.status == 1 ? true : false;
                  element.ModifyTechnicalData = element.ModifyTechnicalData == null ? false : element.ModifyTechnicalData;
                  element.IsModifyTechnicalDataAccession = element.IsModifyTechnicalDataAccession == null ? false : element.IsModifyTechnicalDataAccession;
                  element.ReviewPathCanSignout = element.ReviewPathCanSignout == null ? false : element.ReviewPathCanSignout;
                  element.isMedicalDirector = element.isMedicalDirector == null ? false : element.isMedicalDirector;
                  element.IsUserLevelDiagnosisTemplateEnabled = element.IsUserLevelDiagnosisTemplateEnabled == null ? false : element.IsUserLevelDiagnosisTemplateEnabled;
                  element.ShowMyCaseOnly = element.ShowMyCaseOnly == null ? false : element.ShowMyCaseOnly;
                  element.DiagICDcodeReminder = element.DiagICDcodeReminder == null ? false : element.DiagICDcodeReminder;
                });
                let i;
                for (i = 0; i < this.ArrSavedRoles.length; i++) {
                  if (this.ArrSavedRoles[i].rolename === 'Pathologist') {
                    this.frm_AddUsergroup.patchValue(
                      {
                        frmInp_AllowPathologistDiagnosisTemplate: this.ArrSavedRoles[i].IsUserLevelDiagnosisTemplateEnabled,
                        frmInp_ShowMyCaseOnly: this.ArrSavedRoles[i].ShowMyCaseOnly
                      });
                    break;
                  }
                }
              }
            }
          }
          this.getLocationDetails(OrgId);
        }, error => {
          console.info(error);
        });
      }
    }, error => {
      console.info(error);
    });
  }
  getLocationDetails(OrgIDValue: string) {
    let queryResult: any;
    let queryVariable;
    let query = this.GetQuery('adduserlocations');
    queryVariable = { "orgid": OrgIDValue };
    let accIds: any[];
    //let labname = sessionStorage.getItem('LabName');
    // let flabname = sessionStorage.getItem('FlabName');
    queryResult = this.commonService.GetCardRequest(queryVariable, query);

    this.vaservices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        // let loctaiondta=res.data.vwGetAccountAssociationForUser_list
        // this.frm_AddUserSelectOrgGroup.reset();
        //to get one location
        // if (this.context.toLowerCase() == 'lab') {
        //   if (labname === 'undefined') {
        //     labname = sessionStorage.getItem('Org_Name');
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name === labname
        //     );
        //   }
        //   else {
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name === labname
        //     );
        //     if (this.templateData.length === 0) {
        //       let labname = sessionStorage.getItem('locationName');
        //       this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //         data => data.Display_Name === labname
        //       );
        //     }
        //   }
        // }

        // else if (this.context.toLowerCase() == 'facility' || this.context!) {
        //   if (flabname === 'undefined') {
        //     let fname = sessionStorage.getItem('Org_Name');
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name === fname
        //     );
        //   }
        //   else {
        //     this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //       data => data.Display_Name == flabname
        //     );
        //     if (this.templateData.length === 0) {
        //       let fname = sessionStorage.getItem('locationName');
        //       this.templateData = res.data.vwGetAccountAssociationForUser_list.filter(
        //         data => data.Display_Name === fname
        //       );
        //     }
        //   }
        // }
        //multiple location
        this.templateData = res.data.vwGetAccountAssociationForUser_list;
        if (this.templateData.length > 0) {
          // this.templateData = this.templateData;
          this.showAccounts = true;
          (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).clear();
          let m;
          for (m = 0; m < this.templateData.length; m++) {
            let frmAccounts;
            frmAccounts as UntypedFormGroup;
            frmAccounts = "userLocations_" + m;
            if (this.templateData[m].Account_ID == sessionStorage.getItem('AccountID')) {
              frmAccounts = this._fb.group({
                frmchk_Location: new UntypedFormControl(true),
                frmLblchk_Location: new UntypedFormControl(this.templateData[m].Account_ID),
                frmDrp_Works: new UntypedFormControl('true'),
                frmDrp_Manages: new UntypedFormControl('true'),
                frmDrp_SendforReview: new UntypedFormControl('true'),
                frmLbl_Location: new UntypedFormControl(this.templateData[m].Display_Name)
              });

            }
            else {
              frmAccounts = this._fb.group({
                frmchk_Location: new UntypedFormControl(false),
                frmLblchk_Location: new UntypedFormControl(this.templateData[m].Account_ID),
                frmDrp_Works: new UntypedFormControl('true'),
                frmDrp_Manages: new UntypedFormControl('true'),
                frmDrp_SendforReview: new UntypedFormControl('true'),
                frmLbl_Location: new UntypedFormControl(this.templateData[m].Display_Name)
              });
            }
            (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).push(frmAccounts);
          }
          if (this.templateData.length === 1) {
            this.frm_AddUsergroup.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: true
            });
          }
          else {
            this.frm_AddUsergroup.patchValue({
              frmDrp_OrgSendforReview: 'true',
              frmDrp_OrgManages: 'true',
              frmDrp_OrgWorks: 'true',
              frmchk_OrgLocation: false
            });
          }


          accIds = [];
          this.templateData.forEach(element => {
            accIds.push(element.Account_ID);
          });
          this.getAssociationTypes(accIds, OrgIDValue);
        }
        else {
          this.templateData = "";
          this.showAccounts = false;
        }
      }
      else {
        console.error(res.error);
      }
    }, error => {
      console.error(error);
    });
  }
  getLicensureDetails() {
    // if (this.templateData.data.submenuData.length > 0) {
    // call set change status dropdown
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "licensurestates");
    // sessionStorage.setItem( 'deploymentKey', this.vitalHttpServices.deploymentKey);
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        // this.frm_AddUsergroup.value.formLicensure.value.frmDrp_LicsenceStates = "";
        this.frm_AddUsergroup.patchValue(
          {
            frmInp_State: "Select"
          });

        this.ShowLicensureDetails = true;

        this.StateList = [];
        this.StateList = result.data.submenudata;
        this.StateDiscription = this.StateList[0].LookUpValue + " (" + this.StateList[0].Description + ")";

        (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).clear();
        let frm;
        frm as UntypedFormGroup;
        frm = this._fb.group({
          frmDrp_LicsenceStates: new UntypedFormControl("Select"),
          frmDrp_LicsenceDate: ""
        });

        (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
        this.ShowLicensureDetails = false;
        this.frm_AddUsergroup.patchValue({

          frmInp_State: "Select"
        });

      }
    }, error => {
      console.info(error);
    });
    // }
  }
  Licensure_array_Func() {
    let flag = false;
    if (this.ShowLicensureDetails == false) {
      flag = true;
      this.ShowLicensureDetails = true;
    }
    if ((<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')) == undefined || (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).length <= 0) {
      let frm;
      frm as UntypedFormGroup;
      frm = this._fb.group({
        frmDrp_LicsenceStates: new UntypedFormControl("Select"),
        frmDrp_LicsenceDate: ""
      });

      (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
      if (flag == true) {
        this.ShowLicensureDetails = false;
        flag = false;
      }
      return this.frm_AddUsergroup.controls.formLicensure.value;
    }
    if (flag == true) {
      this.ShowLicensureDetails = false;
      flag = false;
    }
    return this.frm_AddUsergroup.controls.formLicensure.value;

    // *ngFor="let userLicensure of frm_AddUsergroup.controls.formLicensure.controls; let userLicensure_i = index"
    // return this.Licensure_array;
  }
  licensure(value: string, index: number) {
    if (this.ShowLicensureDetails == true) {
      if (value == "add") {
        const frm = this._fb.group({
          frmDrp_LicsenceStates: new UntypedFormControl("Select"),
          frmDrp_LicsenceDate: ""
        });

        (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).push(frm);
      }
      else if (value == "delete") {
        if ((<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).length > 1) {
          (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).removeAt(index);
        }
      }
    }
  }
  generateNewGuid() {
    var d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  onChangefrmDrp_OrgWorks(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_Works: eventValue
        });
    }
  }
  onChangefrmDrp_OrgManages(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_Manages: eventValue
        });
    }
  }
  onChangefrmDrp_OrgSendforReview(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = 'true';
    }
    let m;
    for (m = 0; m < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmDrp_SendforReview: eventValue
        });
    }
  }
  onChangefrmchk_OrgLocation(eventValue) {
    if (eventValue == null && eventValue == undefined) {
      eventValue = true;
    }
    let m;
    for (m = 0; m < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).patchValue(
        {
          frmchk_Location: eventValue
        });
    }
  }
  getCommonKey(userid) {
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "userid": userid.toString() }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            return result.data.submenudata[0].CommonKey
          }
          return "";
        }
        return "";
      }
    }, error => {
      console.info(error);
    });
  }

  getIsMedicalRolePresent(OrgId) {
    // List < MedicalDirectorAttributes > IsMedicalRolePresent = oUser.CheckIsMedicalDirectorRolePresent(_OrganizationID, _UserID);
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetismedicaldirector");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "orgid": OrgId }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenudata != undefined && result.data.submenudata != null) {
          if (result.data.submenudata.length > 0) {
            this.IsMedicalDirector = true;
          }
          else {
            this.IsMedicalDirector = false;
          }
          // IsMedDirectorExistsForCommon
        }
        else {
          this.IsMedicalDirector = false;
        }
      }
      // let commonkey = this.getCommonKey(0);
      var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusergetcommonkey");
      let drpqueryRequest: any = {
        "OperationName": null,
        "Query": drpquery[0].Query,
        "Variables": { "userid": "0" }
      };
      this.vaservices.GetData(drpqueryRequest).subscribe(result => {
        if (!result.errors) {
          if (result.data.submenudata != undefined && result.data.submenudata != null) {
            if (result.data.submenudata.length > 0) {
              this.CommonKey = result.data.submenudata[0].CommonKey
            }
            else {
              this.CommonKey = "";
            }
          }
          else {
            this.CommonKey = "";
          }
          let commonkey = this.CommonKey;
          if (commonkey != undefined && commonkey != null) {
            // this.getIsMedDirectorForUserID(0);
          }
          else {
            //  this.getIsMedDirectorForCommonKey(commonkey);
            this.getLicensureDetails();
          }
          this.getLicensureDetails();
        }
      }, error => {
        console.info(error);
      });
    }, error => {
      console.info(error);
    });

  }
  doEnableRadioBtnForRoleNameChk(userRl, i, event) {
    if (this.counts == -1) {
      if (event.target.checked == true) {
        this.counts = i;
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value,
            frmChk_UserRoles_RoleName: event.target.checked
          });
      }
    }
    else if (event.target.checked == true) {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].enable();
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }
    if (this.counts == i) {
      if (event.target.checked == false) {
        this.counts = -1;
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: event.target.checked
          });

      }
      else if (event.target.checked == false) {
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: event.target.checked
          });
      }
    }
    else if (event.target.checked == false) {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].disable();
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).patchValue(
        {
          frmRd_UserRoles_RoleName: '',
          frmChk_UserRoles_RoleName: event.target.checked
        });
    }
  }
  enableRadioBtnForRoleNameChk(userRl, i, event) {
    this.doEnableRadioBtnForRoleNameChk(userRl, i, event);
    this.RoleCheckboxValidation();
    if (this.ClickFrom === 'UI') {
      this.funcToggle(i);
    }
  }
  funcToggle(index) {
    let m;
    for (m = 0; m < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; m++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Pathologist') {
          this.hidIsPathlogistSelected = true;
        }
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist') {
          this.hidIsCytoSelected = true;
        }
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Cytotechnologist Supervisor') {
          this.hidIsCytoSupervisiorSelected = true;
        }
        if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmLblChk_UserRoles_RoleName'].value == 'Physician') {
          this.hidIsPhysicianSelected = true;
        }
        else {
          this.hidIsPhysicianSelected = false;
        }
      }
    }
    if (((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'lab manager' &&
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
      this.HRD_KValue = this.rolelablistcount;
      this.HRD_value = -1;
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
        {
          frmInp_RoleHoldreleaseDuration: '0'
        });
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
    }
    else {
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
    }

    if (((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        if (this.EPGorgType == 'Pathologists') {
          this.ShowLicensureDetails = true;
        }
        else {
          this.ShowLicensureDetails = false;
        }
        this.hidIsPathlogistSelected = true;
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
        if (this.hidIsMedicalDirector == true) {
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
        }
        else {
          this.MD_KValue = this.rolelistcount;
          this.HRD_KValue = this.rolelistcount;
          this.HRD_value = -1;
          if (this.hidIsChecked == true) {
            this.hidIsChecked = false;
            (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
          }
          else {
            (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].enable();
          }
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].enable();
          // //(<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();

          // // this.ClickFrom = "TS";
          // // let el: HTMLElement = this.role_Chk_click.nativeElement;
          // // el.click();
          // // this.ClickFrom = "UI";

          // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].enable();
          (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
            {
              //frmRd_UserRoles_RoleName: 'Pathologists',
              // frmChk_UserRoles_RoleName: true,
              frmChk_IsMedicalDirector: this.hidIsChecked,
              frmInp_RoleHoldreleaseDuration: '0'
            });

          this.RoleCheckboxValidation();
        }
      }
      else {
        this.ShowLicensureDetails = false;
        this.hidIsPathlogistSelected = false;
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].enable();
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_IsMedicalDirector'].disable();
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();

        this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].disable();
        this.ShowLicensureDetails = false;
        this.showfrmInp_AllowReviewPathologist = false;

        this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].disable();
        this.showfrmInp_AllowtoeditAccession = false;
        this.showfrmInp_Threshold = false;

        this.frm_AddUsergroup.controls['frmInp_EditSite'].disable();
        this.showfrmInp_EditSite = false;
        this.showfrmDrp_ThresholdDuration = false;

        this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
        this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
        this.showfrmInp_PopulateDiag = false;

        if (this.hidIsCytoSelected == false || this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }

        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmRd_UserRoles_RoleName'].disable();
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          {
            frmRd_UserRoles_RoleName: '',
            frmChk_UserRoles_RoleName: false
          });

        return;
      }
      this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
      this.showfrmInp_AllowReviewPathologist = true;
      this.ShowLicensureDetails = true;
      this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
      this.showfrmInp_AllowtoeditAccession = true;
      this.showfrmInp_Threshold = true;
      this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
      this.showfrmInp_EditSite = true;
      this.showfrmDrp_ThresholdDuration = true;
      this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
      this.showfrmInp_Notifyusers = true;
      if (this.UserType == "Laboratories" && this.IsOrderingFacility == true) {
        this.frm_AddUsergroup.patchValue({
          frmInp_AllowtoeditAccession: true,
          frmInp_EditSite: true
        });
      }
    }
    else if (((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist') {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcytcount;
        this.HRD_value = -1;
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSelected = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else if (((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'cytotechnologist supervisor') {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmChk_UserRoles_RoleName'].value == true) {
        this.HRD_KValue = this.rolelablistcyscount;
        this.HRD_value = -1;
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).patchValue(
          {
            frmInp_RoleHoldreleaseDuration: '0'
          });
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].enable();
        this.hidIsCytoSupervisiorSelected = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      else {
        (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
        this.hidIsCytoSupervisiorSelected = false;
        if (this.hidIsPathlogistSelected == false && this.hidIsCytoSelected == false && this.hidIsCytoSupervisiorSelected == false) {
          this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
          this.showfrmInp_Notifyusers = false;
        }
        return;
      }
    }
    else {
      if (((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'physician'
        || ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmLblChk_UserRoles_RoleName'].value as string).toLowerCase().trim() == 'pathologist') {
        this.frm_AddUsergroup.controls['frmInp_NPI'].enable();
      }
      // this.HRD_value=0;
      // (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(index)).controls['frmInp_RoleHoldreleaseDuration'].disable();
      this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].disable();
      this.showfrmInp_AllowReviewPathologist = false;
      this.ShowLicensureDetails = false;
      this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].disable();
      this.showfrmInp_AllowtoeditAccession = false;
      this.showfrmInp_Threshold = false;
      this.frm_AddUsergroup.controls['frmInp_EditSite'].disable();
      this.showfrmInp_EditSite = false;
      this.showfrmDrp_ThresholdDuration = false;
      this.frm_AddUsergroup.controls['frmInp_Notifyusers'].disable();
      this.showfrmInp_Notifyusers = false;
      this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].disable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = false;
      this.showfrmInp_PopulateDiag = false;
      if (this.hidIsPathlogistSelected == true) {
        this.frm_AddUsergroup.controls['frmInp_AllowReviewPathologist'].enable();
        this.showfrmInp_AllowReviewPathologist = true;
        this.ShowLicensureDetails = true;
        this.frm_AddUsergroup.controls['frmInp_AllowtoeditAccession'].enable();
        this.showfrmInp_AllowtoeditAccession = true;
        this.showfrmInp_Threshold = true;
        this.frm_AddUsergroup.controls['frmInp_EditSite'].enable();
        this.showfrmInp_EditSite = true;
        this.showfrmDrp_ThresholdDuration = true;
        this.frm_AddUsergroup.controls['frmInp_Notifyusers'].enable();
        this.showfrmInp_Notifyusers = true;
      }
      this.showfrmInp_Notifyusers = true;
      this.frm_AddUsergroup.controls['frmInp_AllowPathologistDiagnosisTemplate'].enable();
      this.showfrmInp_AllowPathologistDiagnosisTemplate = true;
      this.showfrmInp_PopulateDiag = true;
    }
    if (this.hidIsPathlogistSelected == true) {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_AddUsergroup.controls['frmInp_DisplayFormatReports'].enable();
    }
    else {
      this.showfrmInp_DisplayFormatReports = true;
      this.frm_AddUsergroup.controls['frmInp_DisplayFormatReports'].disable();
    }
  }
  RoleCheckboxValidation() {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length;

    for (m = 0; m < count; m++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == false) {
        count_false++;
      }
      else if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(m)).controls['frmChk_UserRoles_RoleName'].value == true) {
        count_true++;
      }
    }
    if (count_true === count) {
      // this.RoleCheckboxValidator = true;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: true
      });
    }
    else if (count_false === count) {
      // this.RoleCheckboxValidator = false;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
    else {
      // this.RoleCheckboxValidator = false;
      this.frm_AddUsergroup.patchValue({
        frmChk_RoleCheckboxValidator: false
      });
    }
  }

  isValidDate(dtvalue) {
    dtvalue = this.datepipe.transform(dtvalue, 'MM/dd/yyyy');
    var RegExPattern = /^(?=\d)(?:(?:(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2}))($|\ (?=\d)))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\ [AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    var validDate = false;
    if ((RegExPattern.test(dtvalue)) && (dtvalue.value != '')) {
      var index = dtvalue.lastIndexOf("/");
      var temp = dtvalue.substring(index + 1);
      if (temp.length == 4)
        validDate = true;
      else
        validDate = false;
    }
    else if (dtvalue == '') {
      validDate = true;
    }
    else
      validDate = false;
    return validDate;
  }

  checkEmail(allowLogin, email) {
    if (allowLogin) {
      return email == '' ? true : false
    }
    else {
      return false
    }
  }


  fnValidate() {
    this.clearAlertsOnSave();
    let form = this.frm_AddUsergroup.value;


    if (this.organizationLoginType === 'LoginName') {
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || form.frmInp_LoginName == "" || (form.frmInp_ST == false && form.frmInp_US == false) || form.frmInp_NPI == "") {
        this.alertOnSave1 = true;
        return false;
      }
      else {
        this.alertOnSave1 = false;
      }
    }
    else {
      if (form.frmInp_FirstName == "" || form.frmInp_LastName == "" || (form.frmInp_ST == false && form.frmInp_US == false) || form.frmInp_NPI == "") {
        this.alertOnSave1 = true;
        return false;
      }
      else {
        this.alertOnSave1 = false;
      }
    }

    if (this.organizationLoginType?.toString().toLowerCase() == 'email' && this.frm_AddUsergroup.value.frmInp_AllowLogin) {
      if (form.frmInp_Email == "") {
        this.alertOnSave1 = true;
      }
    }

    //*Please Enter valid Region!!

    //LoginName Availability check
    // this.CheckLoginNameAvailability(this.frm_AddUsergroup.value.frmInp_LoginName);

    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "addusercheckloginname");
    this.addloginname = form.frmInp_LoginName;
    let drpqueryRequest = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "LoginName": form.frmInp_LoginName }
    };
    this.vaservices.GetData(drpqueryRequest).subscribe(result => {
      if (!result.errors) {
        if (result.data.submenuData != undefined && result.data.submenuData != null) {
          if (result.data.submenuData.length > 0) {
            this.loginNamesList = result.data.submenuData;
            this.loginNameExists = false;
            this.alertOnSave7 = false;

            let num: number;
            for (num = 0; num < this.loginNamesList.length; num++) {
              if (this.loginNamesList[num].userid != null && this.loginNamesList[num].userid != "" && this.loginNamesList[num].userid != 0) {
                this.loginNameExists = true;
                this.alertOnSave7 = true;
                // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Login Name Exists! Enter new Login Name!!";
                break;
              }
            }
          }
          else {
            this.loginNameExists = false;

            this.alertOnSave7 = false;
            // return false;
          }
        }
        else {
          this.loginNameExists = false;
          this.alertOnSave7 = false;
          // return false;
        }
        if (!this.alertOnSave7) {
          //further validations

          //*Please select at least one Location!!
          let m;
          let count: number = 0;
          for (m = 0; m < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; m++) {
            if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).controls['frmchk_Location'].value) {
              count++;
            }
          }

          if (count == 0) {
            this.alertOnSave2 = true;
            return false;
          }
          else if (count > 0) {
            this.alertOnSave2 = false;
          }

          //*Please Enter valid Zip!!
          // this.alertOnSave3 = false;
          // if (form.frmInp_Zip != "") {
          //   var zip = /^\d{5}([\-]\d{4})?$/;
          //   if (zip.test(form.frmInp_Zip) == false) {
          //     this.alertOnSave3 = true;
          //     this.frm_AddUsergroup.patchValue(
          //       {
          //         frmInp_Zip: ""
          //       });
          //     return false;
          //   }
          //   else {
          //     this.alertOnSave3 = false;
          //   }
          // }

          //*Please Enter valid Email!!
          this.alertOnSave4 = false;
          //this.emailcheck(form.frmInp_Email);
          if (form.frmInp_Email !=
            "") {
            var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (re.test(form.frmInp_Email) == false) {
              this.alertOnSave4 = true;
              this.frm_AddUsergroup.patchValue(
                {
                  frmInp_Email: ""
                });
              return false;
            }
            else {
              this.alertOnSave4 = false;
            }
          }
          // this.emailcheck(form.frmInp_Email);

          //validate fax number
          this.alertOnSave5 = true;
          // this.alertOnSave5 = false;
          // if (form.frmInp_Fax != "") {
          //   var faxvalue = form.frmInp_Fax.split(',');
          //   for (let i = 0; i < faxvalue.length; i++) {
          //     if (!this.validateFax(faxvalue[i])) {
          //       this.alertOnSave5 = true;
          //       return false;
          //     }
          //   }
          // }
          //validate npi number
          this.alertOnSave6 = false;
          if (form.frmInp_NPI != "" && form.frmInp_NPI.toLowerCase() != "unknown") {
            // var value = {
            //   "npi": form.frmInp_NPI,
            // }
            // this.vitalHttpServices.NpiValidation(value).subscribe((Info) => {
            if (form.frmInp_NPI.length != 10) {
              this.alertOnSave6 = true;
              return false;
            }
            else {
              this.alertOnSave6 = false;
              var fnValidate_RoleSelected = false;
              var fnValidate_primarySelected = false;
              var fnValidate_HoldDurationTime = "0";
              var fnValidate_isPrimarybelongsToSelectedRole = false;
              var fnValidate_isLabManager = false;
              var fnValidate_iscytotechnologist = false;
              var fnValidate_iscyt = false;
              var fnValidate_isPathologist = false;

              let mn;
              for (mn = 0; mn < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; mn++) {
                var fnValidate_chkrole = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
                var fnValidate_rdnPrimaryRole = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value;
                var fnValidate_txtHoldduration = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

                if (fnValidate_chkrole == true) {
                  fnValidate_RoleSelected = true;
                }
                if (fnValidate_rdnPrimaryRole != "") {
                  fnValidate_primarySelected = true;
                }
                if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
                  fnValidate_isPrimarybelongsToSelectedRole = true;
                }
                if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
                  && fnValidate_chkrole == true) {
                  fnValidate_isLabManager = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                }
                if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
                  && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                  fnValidate_isPathologist = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                }
                if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
                  && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                  fnValidate_iscyt = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                }
                if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
                  && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                  fnValidate_iscytotechnologist = true;
                  fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
                }
              }

              if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime.toString() == '')
                || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime.toString() == '')) {
                this.alertOnSaveCommon = true;
                // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Please enter Lab Manager Hold duration time in minutes (0-99)!!";
                this.CommonAlertOnSave = "*Please enter hold duration time in minutes (0-60)";
                return false;
              }

              if (fnValidate_RoleSelected == false) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = '*Please select at least one Role!!';
                return false;
              }

              if (fnValidate_primarySelected == false) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = '*Please select primary role!!';
                return false;
              }

              if (fnValidate_isPrimarybelongsToSelectedRole == false) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Primary role selected is invalid!!";
                return false;
              }

              var fnValidate_chkAccountcount: number = 0;
              var fnValidate_cnt: number = 0;
              let k;
              for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                  fnValidate_chkAccountcount++;
                  var fnValidate_drpwrktext = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                  if (fnValidate_drpwrktext == "true" && fnValidate_isPathologist == true) {
                    fnValidate_cnt++;
                  }
                }
              }
              if (this.hidIsPathlogistSelected == true) {


                var fnValidate_drpWorksSelected = 'false';
                for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                  if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                    fnValidate_drpWorksSelected = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                  }
                  if (fnValidate_drpWorksSelected == 'true') {
                    break;
                  }
                }

                if (fnValidate_drpWorksSelected == 'false') {
                  // && fnValidate_drpOrgWorksSelected == 'false'
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Atleast one location can be marked as true!!";
                  return false;
                }
              }

              if (fnValidate_chkAccountcount == 0) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Please select at least one Location!!";
                return false;
              }
              if (fnValidate_cnt > 1) {
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Only one location can be marked as true!!";
                return false;
              }

              if (this.hidIsPathlogistSelected == true) {
                var IsMedicalDirectorExists_isMedicalDirector = false;
                var IsMedicalDirectorExists_status;
                var IsMedicalDirectorExists_userName;
                var IsMedicalDirectorExists_commonKey;
                var IsMedicalDirectorExists_userID;

                //IsMedicalDirectorExists
                this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").subscribe(data => {
                  // var jsonResult: any = JSON.stringify(data);
                  // jsonResult = JSON.parse(jsonResult);
                  var jsonResult: any = data;
                  if (jsonResult != undefined && jsonResult != null) {
                    if (jsonResult.length > 0) {
                      IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
                      IsMedicalDirectorExists_status = jsonResult[0].Status;
                      IsMedicalDirectorExists_userID = jsonResult[0].UserID;
                      IsMedicalDirectorExists_userName = jsonResult[0].UserName;
                      IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
                    }
                    else {
                      IsMedicalDirectorExists_isMedicalDirector = false;
                      IsMedicalDirectorExists_status = "";
                      IsMedicalDirectorExists_userID = "";
                      IsMedicalDirectorExists_userName = "";
                      IsMedicalDirectorExists_commonKey = "";
                    }
                  }
                  else {
                    IsMedicalDirectorExists_isMedicalDirector = false;
                    IsMedicalDirectorExists_status = "";
                    IsMedicalDirectorExists_userID = "";
                    IsMedicalDirectorExists_userName = "";
                    IsMedicalDirectorExists_commonKey = "";
                  }

                  if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true) {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Medical Director already exist!!";
                    return false;
                  }
                  if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true
                    && IsMedicalDirectorExists_commonKey != this.CommonKey) {
                    if (IsMedicalDirectorExists_status == "active") {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "*Medical Director already exist!!";
                      return false;
                    }
                    else if (IsMedicalDirectorExists_status == "inactive") {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "*There can be only one Medical Director for an group!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your group. Please unselect the Medical Director option to proceed further!!";
                      return false;
                    }
                  }

                  var stateID;
                  // var licensure1;
                  var confirmexpdate;
                  var alldata = [];
                  var StateLists = [];
                  var DuplicateStateCount = 0;
                  var i = 0;
                  // var stateval = "";
                  var DateValueCount = 0;
                  // var Datetextbox;

                  if (this.hidIsPathlogistSelected == true) {
                    for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).length; k++) {
                      var PathLic_Statefullname: string = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value;
                      var PathLic_Expirydate = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
                      var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
                      if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
                        return true;
                      }
                      if (alldata.length == i) {
                        // alldata[i] = this.pathLicesData();
                        alldata[i] = {
                          id: "",
                          State: "",
                          Expirydate: "",
                          Statefullname: "",
                        };
                        alldata[i].id = JSON.stringify(i + 1);
                        alldata[i].State = PathLic_State;
                        alldata[i].Expirydate = PathLic_Expirydate;
                        alldata[i].Statefullname = PathLic_Statefullname;

                        if (alldata[i].Expirydate == "") {
                          confirmexpdate = true;
                        }
                        if (alldata[i].State != "") {
                          if (StateLists.indexOf(alldata[i].State) === -1) {
                            StateLists.push(alldata[i].State);
                          }
                          else {
                            DuplicateStateCount++;
                            return false;
                          }
                        }
                      }
                      i++;
                    }
                    this.hidlicensure = JSON.stringify(alldata);
                    if (DuplicateStateCount > 0) {
                      alert("The state has been selected multiple times. Please select some other state or delete the state.");
                      document.getElementById(stateID).focus();
                      return false;
                    }
                    if (DateValueCount > 0) {
                      this.alertOnSaveCommon = true;
                      this.CommonAlertOnSave = "Please enter a valid Expirydate!!";
                      return false;
                    }
                    //licensure details in json object format
                    this.hidlicensure = JSON.stringify(alldata);

                    //begin save by forming save json to send to API
                    let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
                    let query = this.vitalHttpServices.GetQuery("checkUserDuplicateValues");
                    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
                      if (!res.errors) {
                        if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                          let dialogRef = this.dialog.open(ConfirmComponent, {
                            disableClose: true,
                            width: '500px',
                            data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
                          });
                        }
                        else {
                          var usersData_Jsonformat = this.generateSaveJson();
                          this.alertOnSave10 = false;
                          this.SaveUserData(usersData_Jsonformat);
                        }
                      }
                    }, error => {
                      console.error(error);
                    })
                  }
                }, error => {
                  console.error(error)
                });
              }
              else {
                //begin save by forming save json to send to API
                let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
                let query = this.vitalHttpServices.GetQuery("checkUserDuplicateValues");
                let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                this.vitalHttpServices.GetData(queryResult).subscribe(res => {
                  if (!res.errors) {
                    if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                      let dialogRef = this.dialog.open(ConfirmComponent, {
                        disableClose: true,
                        width: '500px',
                        data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
                      });
                    }
                    else {
                      this.alertOnSave10 = false;
                      var usersData_Jsonformat = this.generateSaveJson();
                      this.SaveUserData(usersData_Jsonformat);
                    }
                  }
                }, error => {
                  console.error(error);
                })
              }
            }
            // }, error => {
            //   console.info(error);
            //   this.alertOnSave6 = true;
            // });
          }
          else {
            this.alertOnSave6 = false;
            var fnValidate_RoleSelected = false;
            var fnValidate_primarySelected = false;
            var fnValidate_HoldDurationTime = "0";
            var fnValidate_isPrimarybelongsToSelectedRole = false;
            var fnValidate_isLabManager = false;
            var fnValidate_iscytotechnologist = false;
            var fnValidate_iscyt = false;
            var fnValidate_isPathologist = false;

            let mn;
            for (mn = 0; mn < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; mn++) {
              var fnValidate_chkrole = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value;
              var fnValidate_rdnPrimaryRole = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmRd_UserRoles_RoleName'].value;
              var fnValidate_txtHoldduration = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmInp_RoleHoldreleaseDuration'].value;

              if (fnValidate_chkrole == true) {
                fnValidate_RoleSelected = true;
              }
              if (fnValidate_rdnPrimaryRole != "") {
                fnValidate_primarySelected = true;
              }
              if (fnValidate_chkrole == true && fnValidate_rdnPrimaryRole != "") {
                fnValidate_isPrimarybelongsToSelectedRole = true;
              }
              if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Lab Manager'
                && fnValidate_chkrole == true) {
                fnValidate_isLabManager = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
              if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim() == 'Pathologist'
                && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_isPathologist = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
              if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist supervisor"
                && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_iscyt = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
              if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmLblChk_UserRoles_RoleName'].value.trim().toLowerCase() == "cytotechnologist"
                && (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(mn)).controls['frmChk_UserRoles_RoleName'].value == true) {
                fnValidate_iscytotechnologist = true;
                fnValidate_HoldDurationTime = fnValidate_txtHoldduration.toString();
              }
            }

            if ((fnValidate_isLabManager == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_isPathologist == true && fnValidate_HoldDurationTime.toString() == '')
              || (fnValidate_iscytotechnologist == true && fnValidate_HoldDurationTime.toString() == '') || (fnValidate_iscyt == true && fnValidate_HoldDurationTime.toString() == '')) {
              this.alertOnSaveCommon = true;
              // (<HTMLElement>document.getElementById('commonAlertOnSave')).innerHTML = "*Please enter Lab Manager Hold duration time in minutes (0-99)!!";
              this.CommonAlertOnSave = "Please enter hold duration time in minutes";
              return false;
            }

            if (fnValidate_RoleSelected == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = 'Please select at least one Role!!';
              return false;
            }

            if (fnValidate_primarySelected == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = '*Please select primary role!!';
              return false;
            }

            if (fnValidate_isPrimarybelongsToSelectedRole == false) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Primary role selected is invalid!!";
              return false;
            }

            var fnValidate_chkAccountcount: number = 0;
            var fnValidate_cnt: number = 0;
            let k;
            for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
              if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                fnValidate_chkAccountcount++;
                var fnValidate_drpwrktext = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                if (fnValidate_drpwrktext == "true" && fnValidate_isPathologist == true) {
                  fnValidate_cnt++;
                }
              }
            }
            if (this.hidIsPathlogistSelected == true) {


              var fnValidate_drpWorksSelected = 'false';
              for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; k++) {
                if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmchk_Location'].value == true) {
                  fnValidate_drpWorksSelected = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(k)).controls['frmDrp_Works'].value;
                }
                if (fnValidate_drpWorksSelected == 'true') {
                  break;
                }
              }

              if (fnValidate_drpWorksSelected == 'false') {
                // && fnValidate_drpOrgWorksSelected == 'false'
                this.alertOnSaveCommon = true;
                this.CommonAlertOnSave = "*Atleast one location can be marked as true!!";
                return false;
              }
            }

            if (fnValidate_chkAccountcount == 0) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Please select at least one Location!!";
              return false;
            }
            if (fnValidate_cnt > 1) {
              this.alertOnSaveCommon = true;
              this.CommonAlertOnSave = "*Only one location can be marked as true!!";
              return false;
            }

            if (this.hidIsPathlogistSelected == true) {
              var IsMedicalDirectorExists_isMedicalDirector = false;
              var IsMedicalDirectorExists_status;
              var IsMedicalDirectorExists_userName;
              var IsMedicalDirectorExists_commonKey;
              var IsMedicalDirectorExists_userID;

              //IsMedicalDirectorExists
              this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").subscribe(data => {
                // var jsonResult: any = JSON.stringify(data);
                // jsonResult = JSON.parse(jsonResult);
                var jsonResult: any = data;
                if (jsonResult != undefined && jsonResult != null) {
                  if (jsonResult.length > 0) {
                    IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
                    IsMedicalDirectorExists_status = jsonResult[0].Status;
                    IsMedicalDirectorExists_userID = jsonResult[0].UserID;
                    IsMedicalDirectorExists_userName = jsonResult[0].UserName;
                    IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
                  }
                  else {
                    IsMedicalDirectorExists_isMedicalDirector = false;
                    IsMedicalDirectorExists_status = "";
                    IsMedicalDirectorExists_userID = "";
                    IsMedicalDirectorExists_userName = "";
                    IsMedicalDirectorExists_commonKey = "";
                  }
                }
                else {
                  IsMedicalDirectorExists_isMedicalDirector = false;
                  IsMedicalDirectorExists_status = "";
                  IsMedicalDirectorExists_userID = "";
                  IsMedicalDirectorExists_userName = "";
                  IsMedicalDirectorExists_commonKey = "";
                }

                if (IsMedicalDirectorExists_isMedicalDirector == true && this.hidIsChecked == true) {
                  this.alertOnSaveCommon = true;
                  this.CommonAlertOnSave = "*Medical Director already exist!!";
                  return false;
                }
                if (IsMedicalDirectorExists_isMedicalDirector && this.hidIsChecked == true
                  && IsMedicalDirectorExists_commonKey != this.CommonKey) {
                  if (IsMedicalDirectorExists_status == "active") {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*Medical Director already exist!!";
                    return false;
                  }
                  else if (IsMedicalDirectorExists_status == "inactive") {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "*There can be only one Medical Director for an group!. " + IsMedicalDirectorExists_userName + " is already associated as Medical Director for your group. Please unselect the Medical Director option to proceed further!!";
                    return false;
                  }
                }

                var stateID;
                // var licensure1;
                var confirmexpdate;
                var alldata = [];
                var StateLists = [];
                var DuplicateStateCount = 0;
                var i = 0;
                // var stateval = "";
                var DateValueCount = 0;
                // var Datetextbox;

                if (this.hidIsPathlogistSelected == true) {
                  for (k = 0; k < (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).length; k++) {
                    var PathLic_Statefullname: string = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceStates'].value;
                    var PathLic_Expirydate = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).at(k)).controls['frmDrp_LicsenceDate'].value;
                    var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
                    if ((PathLic_Statefullname == "" && PathLic_Expirydate == "") || (PathLic_Statefullname == "" && PathLic_Expirydate != "")) {
                      return true;
                    }
                    if (alldata.length == i) {
                      // alldata[i] = this.pathLicesData();
                      alldata[i] = {
                        id: "",
                        State: "",
                        Expirydate: "",
                        Statefullname: "",
                      };
                      alldata[i].id = JSON.stringify(i + 1);
                      alldata[i].State = PathLic_State;
                      alldata[i].Expirydate = PathLic_Expirydate;
                      alldata[i].Statefullname = PathLic_Statefullname;

                      if (alldata[i].Expirydate == "") {
                        confirmexpdate = true;
                      }
                      if (alldata[i].State != "") {
                        if (StateLists.indexOf(alldata[i].State) === -1) {
                          StateLists.push(alldata[i].State);
                        }
                        else {
                          DuplicateStateCount++;
                          return false;
                        }
                      }
                    }
                    i++;
                  }
                  this.hidlicensure = JSON.stringify(alldata);
                  if (DuplicateStateCount > 0) {
                    alert("The state has been selected multiple times. Please select some other state or delete the state.");
                    document.getElementById(stateID).focus();
                    return false;
                  }
                  if (DateValueCount > 0) {
                    this.alertOnSaveCommon = true;
                    this.CommonAlertOnSave = "Please enter a valid Expirydate!!";
                    return false;
                  }
                  //licensure details in json object format
                  this.hidlicensure = JSON.stringify(alldata);

                  //begin save by forming save json to send to API
                  let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
                  let query = this.vitalHttpServices.GetQuery("checkUserDuplicateValues");
                  let queryResult = this.commonService.GetCardRequest(queryVariable, query);
                  this.vitalHttpServices.GetData(queryResult).subscribe(res => {
                    if (!res.errors) {
                      if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                        let dialogRef = this.dialog.open(ConfirmComponent, {
                          disableClose: true,
                          width: '500px',
                          data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
                        });
                      }
                      else {
                        var usersData_Jsonformat = this.generateSaveJson();
                        this.alertOnSave10 = false;
                        this.SaveUserData(usersData_Jsonformat);
                      }
                    }
                  }, error => {
                    console.error(error);
                  })
                }
              }, error => {
                console.error(error)
              });
            }
            else {
              //begin save by forming save json to send to API
              let queryVariable = { "commonkey": null, "Orgid": sessionStorage.getItem('org_id'), "Email": form.frmInp_Email };
              let query = this.vitalHttpServices.GetQuery("checkUserDuplicateValues");
              let queryResult = this.commonService.GetCardRequest(queryVariable, query);
              this.vitalHttpServices.GetData(queryResult).subscribe(res => {
                if (!res.errors) {
                  if (res.data.submenuData.length > 0 && form.frmInp_Email) {
                    let dialogRef = this.dialog.open(ConfirmComponent, {
                      disableClose: true,
                      width: '500px',
                      data: { header: "Add user", message: "A user with the same email already exists. Try again with another email id.", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
                    });
                  }
                  else {
                    this.alertOnSave10 = false;
                    var usersData_Jsonformat = this.generateSaveJson();
                    this.SaveUserData(usersData_Jsonformat);
                  }
                }
              }, error => {
                console.error(error);
              })
            }
          }
        }
      }
    }, error => {
      console.info(error);
      this.loginNameExists = false;
      this.alertOnSave7 = false;
    });

  }

  clearAlertsOnSave() {
    this.alertOnSave1 = false;
    this.alertOnSave2 = false;
    //this.alertOnSave3 = false;
    this.alertOnSave4 = false;
    this.alertOnSave5 = false;
    this.alertOnSave6 = false;
    this.alertOnSave7 = false;
    this.alertOnSave10 = false;
    this.alertOnSaveCommon = false;
    this.ifUserStatus = false;
    this.userStatus = false;
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9 -.]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  GenerateLoginID(event) {
    var loginID = "";
    var firstname: string = this.frm_AddUsergroup.value.frmInp_FirstName;
    var lastname: string = this.frm_AddUsergroup.value.frmInp_LastName;

    if (firstname.trim() != "")
      loginID = firstname.substring(0, 1);

    if (lastname.trim() != "")
      loginID += lastname;

    //to make sure loginID length should not exceed 25
    if (loginID.length > 20)
      loginID = loginID.substring(0, 20);


    if (loginID != '')
      loginID += Math.floor((Math.random() * 1000) + 1)
    if (this.frm_AddUsergroup.value.frmInp_LoginName == '') {
      this.frm_AddUsergroup.patchValue({
        frmInp_LoginName: loginID
      });
    }
  }


  onChangefrmchk_Location(event_checked) {
    let m, count_true: number = 0, count_false: number = 0;
    let count: number = (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length;

    for (m = 0; m < count; m++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).controls['frmchk_Location'].value == false) {
        count_false++;
      }
      else if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(m)).controls['frmchk_Location'].value == true) {
        count_true++;
      }
    }

    if (count_true === count) {
      this.frm_AddUsergroup.patchValue({
        frmchk_OrgLocation: true
      });
    }
    else if (count_false === count) {
      this.frm_AddUsergroup.patchValue({
        frmchk_OrgLocation: false
      });
    }
    else {
      this.frm_AddUsergroup.patchValue({
        frmchk_OrgLocation: false
      });
    }
  }

  //#region validate empty data
  checkEmpties(data) {
    if (this.organizationLoginType?.toString().toLowerCase() == 'loginname') {
      if ((data.frmInp_ST === false && data.frmInp_US === false) || data.frmInp_LoginName === "" || data.frmInp_LastName === "" || data.frmInp_FirstName === "" || data.frmInp_NPI === "") {
        return true;
      }
      return false;
    } else {
      if ((data.frmInp_ST === false && data.frmInp_US === false) || data.frmInp_LastName === "" || data.frmInp_FirstName === "" || this.checkEmail(data.frmInp_AllowLogin, data.frmInp_Email)) {
        return true;
      }
      return false;
    }

  }
  //#endregion

  get formcontrol() {
    return this.frm_AddUsergroup.controls;
  }

  validateForm(data: any) {
    if (this.checkEmpties(data)) {
      this._snackbar.open('Please enter all the required fields before saving!', 'Close');
      return;
    }
  }

  SaveAndClose(adduserData) {
    this.validateForm(adduserData)
    this.saveandcloseflag = true;
    this.fnValidate();
  }

  SaveAndCreate(adduserData) {
    this.validateForm(adduserData)
    this.saveandcreateflag = true;
    this.fnValidate();
    // this.SearchOrg();
    // this.adduserform = true;
    // this.ViewDisplay=false;
    //this.resetAddUserDetails();
    //this.SelectedModal(adduserData)
  }
  generateSaveJson() {
    let form = this.frm_AddUsergroup.value;
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    let roleList = "";
    let selected_primaryrole = "";
    let holdReleaseDurationvalue = "";
    let holdselected_primaryrole = "";
    //"Lab Manager,Claim tracker";
    for (let i = 0; i < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).length; i++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmChk_UserRoles_RoleName'].value == true) {
        roleList += (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
        holdReleaseDurationvalue += (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value + ",";
      }
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmRd_UserRoles_RoleName'].value != "") {
        selected_primaryrole = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
        holdselected_primaryrole = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmInp_RoleHoldreleaseDuration'].value + '|' + (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(i)).controls['frmLblChk_UserRoles_RoleName'].value;
      }
    }
    //holdduration list
    let arraylist1: string[];
    arraylist1 = holdReleaseDurationvalue.split(',');
    let listrole1: string[];
    listrole1 = arraylist1.filter(item => item !== holdselected_primaryrole)
    let role1 = String(listrole1);
    holdReleaseDurationvalue = holdselected_primaryrole + ',' + role1;
    holdReleaseDurationvalue = holdReleaseDurationvalue.substring(0, holdReleaseDurationvalue.length - 1);
    //role list
    let arrayrole: string[];
    arrayrole = roleList.split(',');
    let listrole: string[];
    listrole = arrayrole.filter(item => item !== selected_primaryrole)
    let role = String(listrole);
    roleList = selected_primaryrole + ',' + role;
    roleList = roleList.substring(0, roleList.length - 1);
    let review_data = [];
    let accountList = "";
    // "5:1,6:1,20029:0,26112:0,26275:0";
    for (let j = 0; j < (<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).length; j++) {
      if ((<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value as boolean == true) {
        accountList += (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":1,";
      }
      else {
        accountList += (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":0,";
      }
      // accountList += (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value + ":" +
      //   (<FormGroup>(<FormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmchk_Location'].value + ",";

      //review data
      let obj = {
        "userID": loggedIn_userID != "" ? loggedIn_userID : "-100",
        "AccountID": (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmLblchk_Location'].value,
        "sendforreview": (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_SendforReview'].value == "true" ? true : false,
        "works": (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Works'].value == "true" ? true : false,
        "manages": (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserAccounts')).at(j)).controls['frmDrp_Manages'].value == "true" ? true : false,
        "RoleName": selected_primaryrole,
        "primaryrole": selected_primaryrole != "" ? true : false,
        "CanPathologistModifyAccession": form.frmInp_AllowtoeditAccession != "" ? true : false,
        "CanPathologistModify": form.frmInp_EditSite != "" ? true : false,
        "ModifiedBy": loggedIn_userID != "" ? loggedIn_userID : "-100"
      };
      review_data.push(obj);
    }
    accountList = accountList.substring(0, accountList.length - 1);

    //check if user is medical director
    let MD_val = false;
    if (this.MD_KValue != -1 &&
      (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(this.MD_KValue)).controls['frmChk_IsMedicalDirector'].value == true) {
      MD_val = true;
    }

    //check for hold release duration value
    let HD_val: number = 0;
    if (this.HRD_KValue != -1) {
      let user_hd_val = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('frmArruserRoles')).at(this.HRD_KValue))?.controls['frmInp_RoleHoldreleaseDuration'].value;
      if (user_hd_val != null && user_hd_val != "") {
        HD_val = parseInt(user_hd_val);
      }
    }

    //convert threshold value to number
    let threshold_value: number = 0;
    if (form.frmInp_Threshold != null && form.frmInp_Threshold != "") {
      threshold_value = parseInt(form.frmInp_Threshold);
    }

    //check if primary role has changed
    let isPrimaryRoleChanged = false;
    if (this.hidPrimaryRole != "" && selected_primaryrole != "" && selected_primaryrole != this.hidPrimaryRole) {
      isPrimaryRoleChanged = true;
    }

    //get licensure details
    let lic_details: string = "";
    if (this.hidIsPathlogistSelected) {
      lic_details = "<ArrayOfPathologistLicensure>";
      for (let a = 0; a < (<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).length; a++) {
        var PathLic_Statefullname: string = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceStates'].value;
        var PathLic_Expirydate = (<UntypedFormGroup>(<UntypedFormArray>this.frm_AddUsergroup.get('formLicensure')).at(a)).controls['frmDrp_LicsenceDate'].value;
        var PathLic_State: string = PathLic_Statefullname.substring(0, PathLic_Statefullname.indexOf(' '));
        PathLic_State = PathLic_State === 'Select' ? 'null' : PathLic_State;
        PathLic_Expirydate = this.datepipe.transform(PathLic_Expirydate, 'MM/dd/yyyy');

        //build xml string
        lic_details += "  <PathologistLicensure>";
        lic_details += "  <id>" + (a + 1) + "</id>";
        lic_details += "  <state>" + PathLic_State + "</state>";
        lic_details += "  <Expirydate>" + PathLic_Expirydate + "</Expirydate>";
        lic_details += "  <Statefullname>" + PathLic_Statefullname + "</Statefullname>";
        lic_details += "  </PathologistLicensure>";
      }
      lic_details += "  </ArrayOfPathologistLicensure>";
    }
    let orgid = sessionStorage.getItem('org_id')
    var reg = null;
    if (form.frmInp_US && form.frmInp_ST) {
      reg = 'US,ST'
    }
    else if (form.frmInp_ST) {
      reg = 'ST'
    }
    else if (form.frmInp_US) {
      reg = 'US'
    }
    this.valueloginname = form.frmInp_LoginName;
    this.passwordvalue = "va2010"
    var sendJson = {
      "title": form.frmInp_title,
      "fname": form.frmInp_FirstName,
      "lname": form.frmInp_LastName,
      "midInitial": form.frmInp_MiddleInitial,
      "loginName": form.frmInp_LoginName,
      "eduQual": form.frmInp_Qualification,
      "gender": form.frmInp_Gender == "1" ? true : false,
      "npi": form.frmInp_NPI,
      "address1": form.frmInp_Address1,
      "address2": form.frmInp_Address2,
      "street": form.frmInp_Street,
      "city": form.frmInp_City,
      "state": form.frmInp_State === "Select" ? "null" : form.frmInp_State,
      "zip": form.frmInp_Zip,
      "country": form.frmInp_Country,
      "phone": this.commonService.changeAdminContactPhone(form?.frmInp_Phone),
      "fax": this.commonService.changeAdminFaxNumber(form?.frmInp_Fax),
      "email": form.frmInp_Email,
      "status": form.frmInp_Status,
      "rolename": "",
      "userNamedisplayFormat": form.frmInp_DisplayFormatWebpage,
      "usernameReportformat": form.frmInp_DisplayFormatReports == null ? form.frmInp_DisplayFormatReports = 'S. F L D' : form.frmInp_DisplayFormatReports = 'S. F L D',
      "password": "8clpNzfFAebd6l/nd2R0BQ==",
      "defaultPage": "VAPreferences.aspx",
      "ssn": form.frmInp_SSN,
      "upin": form.frmInp_UPIN,
      "userCode": form.frmInp_UserCode,
      "sign": "",
      "commonKey": form.frmInp_ExternalUserGUID,
      "isAccLockedOut": false,
      "allowPathologistReview": form.frmInp_AllowReviewPathologist == null ? false : form.frmInp_AllowReviewPathologist as boolean,
      "isModifyTechnicalDataAccession": form.frmInp_AllowtoeditAccession == null ? false : form.frmInp_AllowtoeditAccession as boolean,
      "modifyTechnicalData": form.frmInp_EditSite == null ? false : form.frmInp_EditSite as boolean,
      "roleList": roleList,
      "isPrimaryRoleChanged": isPrimaryRoleChanged,
      "works": form.frmDrp_OrgWorks == 'true' ? true : false,
      "manages": form.frmDrp_OrgManages == 'true' ? true : false,
      "sendForreview": form.frmDrp_OrgSendforReview == 'true' ? true : false,
      "accountList": accountList,
      "pathReadingLoc": "5",
      "newRolesAdded": false,
      "medicalDirector": MD_val as boolean,
      "associateAccID": null,
      "deassociateAccID": null,
      "organizationID": orgid,
      "primaryLocation": 0,
      "showMyCaseOnly": form.frmInp_ShowMyCaseOnly == null ? false : form.frmInp_ShowMyCaseOnly as boolean,
      "alowPathologistDiagnosisTemplate": form.frmInp_AllowPathologistDiagnosisTemplate == null ? false : form.frmInp_AllowPathologistDiagnosisTemplate as boolean,
      "diagICDcodeReminder": form.frmInp_Notifyusers == null ? false : form.frmInp_Notifyusers as boolean,
      "autoPopulateDiagICD9Codes": form.frmInp_PopulateDiag == null ? false : form.frmInp_PopulateDiag as boolean,
      "region": reg,
      "associationType": null,
      "externalUserGUID": form.frmInp_ExternalUserGUID,
      "userlicensure": lic_details,
      "holdReleaseDuration": HD_val,
      "threshold": threshold_value,
      "HoldReleaseDurationInfo": holdReleaseDurationvalue,
      "allowLogin": form.frmInp_AllowLogin == null ? true : form.frmInp_AllowLogin,
      "thresholdDuration": form.frmDrp_ThresholdDuration,
      "createdby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100',
      "userid": "0",
      "modifiedby": "0",
      "loggedInUserId": loggedIn_userID != "" ? loggedIn_userID : "-100",
      "entityType": "AddNewUser",
      "entityID": "",
      "actionperformed": "New User Created",
      "actiondetails": "",
      "auditDate": new Date(),
      "EntityData": null,
      "reviewData": review_data
    };
    if (this.organizationLoginType?.toString().toLowerCase().trim() != 'email') {
      sendJson["allowLogin"] = true;
    }
    return sendJson;

  }
  SaveUserData(data) {
    this.ngxService.start("1");
    this.vaservices.SaveAddUserToOrg(data).subscribe(result => {
      if (!result.errors) {
        this.counts = -1;
        if (result.Success == false) {
          this._snackBar.open(result.Message, 'Close');
          this.resetAddUserDetails();
          this.adduserform = true;
          // this.refreshgriddata();
          //this.adduserform = true;
          this.SearchOrg();
          // this.refreshgriddata();
          this.ngxService.stop("1");
        }
        else {
          if (this.LoginTypesflag == true) {
            this._snackBar.open(result.Message, 'Success');
            if (this.saveandcreateflag === true) {
              this.ngxService.stop("1");
              this.resetAddUserDetails();
              this.loginDeatilsData();
              this.SelectedModal("AddUser");
            }
            else if (this.saveandcloseflag === true) {
              this.ngxService.stop("1");
              this.refreshAddData();
              this.loginDeatilsData();
            }
          }
          else {
            if (this.saveandcreateflag === true) {
              this.ngxService.stop("1");
              this.organizationLoginType?.toString().match(/email/i) && data.email ? this._snackBar.open("Invitation sent successfully", 'Success') : this._snackbar.open(result.Message, "Close");
              this.resetAddUserDetails();
              if (result.Success === true && !(this.organizationLoginType.toString().match(/email/i) && data.email)) {
                this.loginDeatilsData();
              }
              this.SelectedModal("AddUser");
            }
            else if (this.saveandcloseflag === true) {
              this.organizationLoginType?.toString().match(/email/i) && data.email ? this._snackBar.open("Invitation sent successfully", 'Success') : this._snackbar.open(result.Message, "Close");
              this.refreshAddData();
              if (result.Success === true && !(this.organizationLoginType?.toString().match(/email/i) && data.email)) {
                this.loginDeatilsData();
              }
              this.ngxService.stop("1");
            }

          }
        }


      }
    }, error => {
      this._snackBar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
    });
  }

  checkingFlags() {
    if (this.saveandcreateflag === true) {
      this.iflogindeatils = true;
    }
    else if (this.saveandcloseflag === true) {
      this.iflogindeatils = false;
    }
    else {
      this.iflogindeatils = false;
    }
    this.refreshAddData();
  }

  async loginDeatilsData() {
    this.ngxService.start("logindetails");
    let modifiedBy = sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
    let depKey = this.DeploymentKeys ? this.DeploymentKeys : sessionStorage.getItem('deploymentKey');

    try {
      let data = await this.vaservices.GetResetPassword(this.valueloginname, modifiedBy, depKey).toPromise();

      if (data != 'null') {
        this.passworddata = data[0];
        this.iflogindeatils = true;
        this.counts = -1;
        let LoggedInUserRole = sessionStorage.getItem('RoleName');
      }
      else {
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
      this.ngxService.stop("logindetails");
    }
    catch {
      this.ngxService.stop("logindetails");
      console.error('User Reset password failed');
    }
  }
  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open("Copied Successfully", "Close");
  }
  back() {
    this.counts = -1;
    if (this.usersData == null) {
      this.refreshgriddata();
      this.resetAddUserDetails();
      this.gridDisplay = false;
      this.detailsDisplay = false;
      this.ViewDisplay = false;
      this.adduserform = false;
      this.clearAlertsOnSave();
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
    }
    else {
      this.refreshgriddata();
      this.resetAddUserDetails();
      this.gridDisplay = true;
      this.detailsDisplay = false;
      this.ViewDisplay = false;
      this.adduserform = false;
      this.clearAlertsOnSave();
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
    }
  }

  getpercentage(pixel: number) {
    var screenWidth = window.screen.width;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 8
    }
    return per;
  }

  SelectedModal(e) {
    this.selectedType = e
    this.saveandcreateflag = false;
    this.saveandcloseflag = false;
    // this.frm_AddUsergroup.patchValue({
    //   frmInp_AllowLogin: this.organizationLoginType == 'Email' ? true : false
    // })
    this.clearAlertsOnSave();
    // this.SearchOrg();
    this.editMode = false;
    this.adduserform = true;
  }

  close(key) {
    this.ifUserStatus = false;
    this.ifUserDelete = false;
    if (key == 'status') {
      if (this.userStatus.toLowerCase() == 'inactive') {
        this.checked = false;
      } else {
        setTimeout(() => {
          this.checked = true;
        })

      }
    }
  }
  getIsMedicalDirectorvalue() {
    var IsMedicalDirectorExists_isMedicalDirector = false;
    var IsMedicalDirectorExists_status;
    var IsMedicalDirectorExists_userName;
    var IsMedicalDirectorExists_commonKey;
    var IsMedicalDirectorExists_userID;
    this.vaservices.GetIsMedicalDirectorExists(this.SelectedOrgID.toString(), "0").subscribe(data => {
      // var jsonResult: any = JSON.stringify(data);
      // jsonResult = JSON.parse(jsonResult);
      var jsonResult: any = data;
      if (jsonResult != undefined && jsonResult != null) {
        if (jsonResult.length > 0) {
          IsMedicalDirectorExists_isMedicalDirector = jsonResult[0].IsMedicalDirector;
          IsMedicalDirectorExists_status = jsonResult[0].Status;
          IsMedicalDirectorExists_userID = jsonResult[0].UserID;
          IsMedicalDirectorExists_userName = jsonResult[0].UserName;
          IsMedicalDirectorExists_commonKey = jsonResult[0].CommonKey;
        }
        else {
          IsMedicalDirectorExists_isMedicalDirector = false;
          IsMedicalDirectorExists_status = "";
          IsMedicalDirectorExists_userID = "";
          IsMedicalDirectorExists_userName = "";
          IsMedicalDirectorExists_commonKey = "";
        }
      }
      else {
        IsMedicalDirectorExists_isMedicalDirector = false;
        IsMedicalDirectorExists_status = "";
        IsMedicalDirectorExists_userID = "";
        IsMedicalDirectorExists_userName = "";
        IsMedicalDirectorExists_commonKey = "";
      }

      if (IsMedicalDirectorExists_isMedicalDirector == true) {
        this.hidIsChecked = true;
      }
      else {
        this.hidIsChecked = false;
      }

      this.getLicensureDetails();
    }, error => {
      console.error(error);
    });
  }

  //MedicalDirectorCheck
  MedicalDirectorCheck(event, index) {
    if (event.target.checked == true)
      this.hidIsChecked = true;
    else
      this.hidIsChecked = false;
  }

  //#endregion

  //#region For RBAC

  getButtondetails() {
    this.getButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe((data) => {
    //   if (data) {
    //     this.(data);
    //   }
    // });
  }



  getButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.btnExport = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Create':
          this.btnCreate = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Edit':
          this.btnEdit = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Delete':
          this.btnDelete = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Unlock/Lock User":
          this.btnunlock = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "InActive user":
          this.btninactiveuser = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Regenerate password":
          this.btnregeneratepassword = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Approve password request":
          this.btnapprovepasswordrequest = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Migrate user":
          this.btnmigrateuser = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Migrate multiple users":
          this.btnmigratemultipleusers = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Upload":
          this.btnUpload = seletedMenuPermissions[i].IsPermitted === 'true' ? true : false;
          break;
        case "DeleteRole":
          this.DeleteRole = seletedMenuPermissions[i].IsPermitted === 'true' ? true : false;
          break;
        case "CreateLive":
          this.CreateLive = seletedMenuPermissions[i].IsPermitted === 'true' ? true : false;
          break;
        case "PopulateASCCases":
         this.btnpopulateASCCases = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "PopulateCaseList":
          this.btnpopulateCaseList = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
      }
    }
    if (this.commonService.isLiveAccount) {
      if (this.CreateLive) {
        this.btnUpload = true;
      }
      else {
        this.btnUpload = false;
      }

    }
  }
  public updateFields = [];
  public insertFields = [];

  bulkUpload() {
    this.gridExcelWidth = 0;
    this.gridExcelDisplay = false;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    this.gridDisplay = false;
    this.gridEmpty = false;
    this.ViewDisplay = false;
    this.detailsDisplay = false;
    this.ifBulkUpload = true;
    this.gridExcelDisplay
    this.adduserform = false;
    this.getLabRoles();
    this.getFacilityRoles();
    let queryVariable = { tablename: 'Users' };
    let query = this.SubMenuCardModel.GetQuery("getBulkUpdateTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let allFields = {}
          let res = data.data.submenudata;
          let ignoreColumns = ['commonkey', 'isprimary', 'externaluserguid', 'isprimaryrole', 'loginname', 'departmentids'];
          for (let i = 0; i < res.length; i++) {
            if (!ignoreColumns.includes(res[i].allowedcolumn.toString().toLowerCase().trim())) {
              this.updateFields.push(res[i].allowedcolumn);
            }
          }
          for (let i = 0; i < res.length; i++) {
            if (res[i].allowedcolumn != 'externaluserguid'
              && res[i].allowedcolumn != 'DisplayMostUsedDxTemplates'
              && res[i].allowedcolumn != 'DisplayMostUsedDxTemplatesCount'
              && res[i].allowedcolumn != 'AllowEditingAdequacyCodes'
              && res[i].allowedcolumn != 'IsUserLevelAdequacyCodeEnabled'
              && res[i].allowedcolumn != 'AllowEditinguserlevelAdequacyCodes'
              && res[i].allowedcolumn != 'AllowEditingCaseCommentsTemplates'
              && res[i].allowedcolumn != 'IsUserLevelCaseCommentsTemplateEnabled'
              && res[i].allowedcolumn != 'AllowEditingDiagnosisTemplates'
              && res[i].allowedcolumn != 'AllowEditingUserTemplates'
              && res[i].allowedcolumn != 'AllowEditinguserlevelCaseComments'
              && res[i].allowedcolumn != 'IsUserLevelDiagnosisTemplateEnabled'
              && res[i].allowedcolumn != 'AllowEditingUserTemplates'
              && res[i].allowedcolumn != 'initials'
              && res[i].allowedcolumn != 'commonkey'
              && res[i].allowedcolumn != 'isprimary') {
              this.insertFields.push(res[i].allowedcolumn);
              this.arrayData = ['firstname', 'lastname', 'npi', 'status', 'title', 'account', 'readinglocation', 'isprimaryrole', 'role', 'email', 'loginname', 'region', 'allowlogin', 'usernamegrossformat', 'usernamereportformat', 'usernamedisplayformat', 'departmentIds'];
              res[i].allowedcolumn = res[i].allowedcolumn.toLowerCase()
              if (this.arrayData.includes(res[i].allowedcolumn || res[i].allowedcolumn.toLowerCase() == 'isprimaryrole')) {
                this.MandatoryFields.push(res[i].allowedcolumn);

              }
            }
          }
          let colsarray = [];
          this.insertFields.forEach(element => {
            if (colsarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < colsarray.length; kk++) {
                let col: string = element;
                if (colsarray[kk] === col) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                colsarray.push(element);
              }
            }
            else {
              colsarray.push(element);
            }
          });
          this.insertFields = colsarray;
        }
      }
    }, error => {
      console.error(error);
    });
  }



  downloadMandatoryFieldsforFacility() {
    this.removeCommasBetweenString(this.data);
    this.keyvalues = this.array.map((value) => ({ name: value }))
    this.userType = 'users';
    let bigData = [];
    let finalArrData = [];
    let ifOFFounnd = false;
    if(!this.isFacilityContext){
    for (var i = 0; i < this.MandatoryFields.length; i++) {
      if (this.MandatoryFields[i] == 'orderingfacility') {
        ifOFFounnd = true;
        break;
      }
    }

    let arrlen = this.MandatoryFields.length + 1;
    if (!ifOFFounnd) {
      this.MandatoryFields.push('orderingfacility');
    }
  }
    let arr = this.MandatoryFields;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == 'userid') {
        arr.splice(i, 1);
        break;
      }
    }
    this.MandatoryFields = arr;

    for (let i = 0; i < this.MandatoryFields.length; i++) {
      if (JSON.stringify(this.MandatoryFields[i]) !== "{}") {
        let primary = {};
        if (this.MandatoryFields[i] == 'title') {
          primary =
            { header: this.MandatoryFields[i], value: [{ name: "Dr." }, { name: "Prof." }, { name: "Mr." }, { name: "Miss." }, { name: "Ms." }, { name: "Mrs." }] }

        }
        // else if (this.insertFields[i] == 'role') {
        //   primary =
        //     { header: this.insertFields[i], value: [{ name: "Claim Tracker" }, { name: "Logistics Personnel" }, { name: "Medical Staff" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Physician" }, { name: "Practice Manager" }, { name: "Referring Physician" }, { name: "User Administrator" }] }

        // }
        else if (this.MandatoryFields[i] == 'gender') {
          primary =
          {
            header: this.MandatoryFields[i], value: [{ name: "Male" }, { name: "Female" }]
          }
        }
        else if (this.MandatoryFields[i] == 'status') {
          primary =
          {
            header: this.MandatoryFields[i], value: [{ name: "Active" }, { name: "InActive" }]
          }

        }
        else if (this.MandatoryFields[i] == 'isprimaryrole') {
          let namearr = [];
          for (var j = 0; j < this.facilityRolesdata.length; j++) {
            let name = {};
            name = {
              "name": this.facilityRolesdata[j].rolename
            }
            namearr.push(name);
          }
          primary =
          {
            header: this.MandatoryFields[i], value: namearr
          }
        }
        else if (this.MandatoryFields[i] == 'usernamedisplayformat' || this.MandatoryFields[i] == 'usernamegrossformat' || this.MandatoryFields[i] == 'usernamereportformat') {
          primary =
            { header: this.MandatoryFields[i], value: this.keyvalues }
        }
        else if (this.MandatoryFields[i] != 'allowlogin') {
          primary =
            { header: this.MandatoryFields[i], value: "" }
        }
        if (this.MandatoryFields[i] != 'allowlogin') {
          bigData.push(primary);
        }

      }
    }
    bigData.push({ header: 'allowlogin', value: [{ name: "True" }, { name: "False" }] })

    var finalData = {
      "name": "Users",
      "values": bigData
    }
    finalArrData.push(finalData);

    let sampleValues = cloneDeep(bigData);
    sampleValues.forEach(va => {
      if (va.header.toString().toLowerCase().trim() == 'account') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'email') {
        va.value = 'userabc@email.com'
      }
      else if (va.header.toString().toLowerCase().trim() == 'firstname') {
        va.value = 'Donald'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isprimaryrole') {
        va.value = 'Physician'
      }
      else if (va.header.toString().toLowerCase().trim() == 'lastname') {
        va.value = 'Robertson'
      }
      else if (va.header.toString().toLowerCase().trim() == 'loginname') {
        va.value = 'DRobertson1298'
      }
      else if (va.header.toString().toLowerCase().trim() == 'npi') {
        va.value = 'UNKNOWN'
      }
      else if (va.header.toString().toLowerCase().trim() == 'readinglocation') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'region') {
        va.value = 'US'
      }
      else if (va.header.toString().toLowerCase().trim() == 'role') {
        va.value = 'Medical Staff;Claim Tracker'
      }
      else if (va.header.toString().toLowerCase().trim() == 'status') {
        va.value = 'Active'
      }
      else if (va.header.toString().toLowerCase().trim() == 'title') {
        va.value = 'Mr.'
      }
      else if (va.header.toString().toLowerCase().trim() == 'orderingfacility' && !this.isFacilityContext) {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'allowlogin') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usernamedisplayformat' || va.header.toString().toLowerCase().trim() == 'usernamegrossformat' || va.header.toString().toLowerCase().trim() == 'usernamereportformat') {
        va.value = 'First Last'
      }
    })

    var sampledata = {
      "name": "sampledata",
      "values": sampleValues
    }
    finalArrData.push(sampledata)

    this.workbookData = this.transform(finalArrData)
    this.downloadResultname = 'Users_' + 'Minimal Fields_For_Facility_' + this.organizationID.toString();
    this.exportAsXLSX(this.downloadResultname);
  }

  downloadMandatoryFieldsforLab() {
    this.removeCommasBetweenString(this.data);
    this.keyvalues = this.array.map((value) => ({ name: value }))
    this.userType = 'labusers';
    let ifOFFounnd = false;
    for (var i = 0; i < this.MandatoryFields.length; i++) {
      if (this.MandatoryFields[i] == 'orderingfacility') {
        this.MandatoryFields.splice(i, 1);
        break;
      }
    }


    for (var i = 0; i < this.MandatoryFields.length; i++) {
      if (this.MandatoryFields[i] == 'userid') {
        this.MandatoryFields.splice(i, 1);
        break;
      }
    }

    let bigData = [];


    for (let i = 0; i < this.MandatoryFields.length; i++) {
      if (JSON.stringify(this.MandatoryFields[i]) !== "{}") {
        let primary = {};
        if (this.MandatoryFields[i] == 'title') {
          primary =
            { header: this.MandatoryFields[i], value: [{ name: "Dr." }, { name: "Prof." }, { name: "Mr." }, { name: "Miss." }, { name: "Ms." }, { name: "Mrs." }] }

        }
        // else if (this.insertFields[i] == 'role') {
        //   primary =
        //     // { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }

        //     { header: this.insertFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Technician" }, { name: "User Administrator" }] }

        // }
        else if (this.MandatoryFields[i] == 'gender') {
          primary =
          {
            header: this.MandatoryFields[i], value: [{ name: "Male" }, { name: "Female" }]
          }

        }
        else if (this.MandatoryFields[i] == 'status') {
          primary =
          {
            header: this.MandatoryFields[i], value: [{ name: "Active" }, { name: "InActive" }]
          }

        }
        else if (this.MandatoryFields[i] == 'isprimaryrole') {
          let namearr = [];
          var len;
          if (this.labRolesData.length > 15) {
            len = 15
          } else {
            len = this.labRolesData.length
          }
          for (var j = 0; j < len; j++) {
            let name = {};
            name = {
              "name": this.labRolesData[j].rolename
            }
            namearr.push(name);
          }
          primary =
            { header: this.MandatoryFields[i], value: namearr }
        }
        else if (this.MandatoryFields[i] == 'usernamedisplayformat' || this.MandatoryFields[i] == 'usernamegrossformat' || this.MandatoryFields[i] == 'usernamereportformat') {
          primary =
            { header: this.MandatoryFields[i], value: this.keyvalues }
        }
        else if (this.MandatoryFields[i] != 'allowlogin') {
          primary =
            { header: this.MandatoryFields[i], value: '' }
        }
        if (this.MandatoryFields[i] != 'allowlogin') {
          bigData.push(primary);
        }
      }
    }
    bigData.push({ header: 'allowlogin', value: [{ name: "True" }, { name: "False" }] })
    let tstdata = [];


    var finalData = {
      "name": "Users",
      "values": bigData
    }


    let sampleValues = cloneDeep(bigData);
    sampleValues.forEach(va => {
      if (va.header.toString().toLowerCase().trim() == 'account') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'email') {
        va.value = 'userabc@email.com'
      }
      else if (va.header.toString().toLowerCase().trim() == 'firstname') {
        va.value = 'Donald'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isprimaryrole') {
        va.value = 'Lab Manager'
      }
      else if (va.header.toString().toLowerCase().trim() == 'lastname') {
        va.value = 'Robertson'
      }
      else if (va.header.toString().toLowerCase().trim() == 'loginname') {
        va.value = 'DRobertson1298'
      }
      else if (va.header.toString().toLowerCase().trim() == 'npi') {
        va.value = 'UNKNOWN'
      }
      else if (va.header.toString().toLowerCase().trim() == 'readinglocation') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'region') {
        va.value = 'US'
      }
      else if (va.header.toString().toLowerCase().trim() == 'role') {
        va.value = 'Lab Administrator;Claim Tracker'
      }
      else if (va.header.toString().toLowerCase().trim() == 'status') {
        va.value = 'Active'
      }
      else if (va.header.toString().toLowerCase().trim() == 'title') {
        va.value = 'Mr.'
      }
      else if (va.header.toString().toLowerCase().trim() == 'orderingfacility') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'allowlogin') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usernamedisplayformat' || va.header.toString().toLowerCase().trim() == 'usernamegrossformat' || va.header.toString().toLowerCase().trim() == 'usernamereportformat') {
        va.value = 'First Last'
      }
    })

    var sampledata = {
      "name": "sampledata",
      "values": sampleValues
    }

    let finalArrData = [];
    finalArrData.push(finalData);
    finalArrData.push(sampledata);
    this.workbookData = this.transform(finalArrData);
    this.downloadResultname = 'Users_' + 'Minimal Fields_For_Lab_' + this.organizationID.toString();
    this.exportAsXLSX(this.downloadResultname);
  }

  downloadUploadUsersforFacility() {
    this.removeCommasBetweenString(this.data);
    this.keyvalues = this.array.map((value) => ({ name: value }))
    this.userType = 'users';
    let bigData = [];
    let finalArrData = [];
    let ifOFFounnd = false;
    if(!this.isFacilityContext){
    for (var i = 0; i < this.insertFields.length; i++) {
      if (this.insertFields[i] == 'orderingfacility') {
        ifOFFounnd = true;
        break;
      }
    }

    let arrlen = this.insertFields.length + 1;
    if (!ifOFFounnd) {
      this.insertFields.push('orderingfacility');
    }
  }
    let arr = this.insertFields;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == 'userid') {
        arr.splice(i, 1);
        break;
      }
    }
    this.insertFields = arr;

    for (let i = 0; i < this.insertFields.length; i++) {
      let primary = {};
      if (this.insertFields[i] == 'title') {
        primary =
          { header: this.insertFields[i], value: [{ name: "Dr." }, { name: "Prof." }, { name: "Mr." }, { name: "Miss." }, { name: "Ms." }, { name: "Mrs." }] }

      }
      // else if (this.insertFields[i] == 'role') {
      //   primary =
      //     { header: this.insertFields[i], value: [{ name: "Claim Tracker" }, { name: "Logistics Personnel" }, { name: "Medical Staff" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Physician" }, { name: "Practice Manager" }, { name: "Referring Physician" }, { name: "User Administrator" }] }

      // }
      else if (this.insertFields[i] == 'gender') {
        primary =
        {
          header: this.insertFields[i], value: [{ name: "Male" }, { name: "Female" }]
        }

      }
      else if (this.insertFields[i] == 'status') {
        primary =
        {
          header: this.insertFields[i], value: [{ name: "Active" }, { name: "InActive" }]
        }

      }
      else if (this.insertFields[i] == 'isPrimaryRole') {
        let namearr = [];
        for (var j = 0; j < this.facilityRolesdata.length; j++) {
          let name = {};
          name = {
            "name": this.facilityRolesdata[j].rolename
          }
          namearr.push(name);
        }
        primary =
        {
          header: this.insertFields[i], value: namearr
        }
      }
      else if (this.insertFields[i] == 'usernamedisplayformat' || this.insertFields[i].toString().toLowerCase().trim() == 'usernamegrossformat' || this.insertFields[i] == 'usernamereportformat') {
        primary =
          { header: this.insertFields[i].toString().toLowerCase().trim(), value: this.keyvalues }
      }
      else if (this.insertFields[i] != 'allowlogin') {
        primary =
          { header: this.insertFields[i], value: "" }
      }
      if (this.insertFields[i] != 'allowlogin') {
        bigData.push(primary);
      }
    }
    bigData.push({ header: 'allowlogin', value: [{ name: "True" }, { name: "False" }] })

    let sortKeys = ['account', 'title', 'firstname', 'lastname', 'email', 'gender', 'login', 'role'];

    let sortArr = [];

    for (var i = 0; i < sortKeys.length; i++) {
      let tstobj = {};
      tstobj = {
        header: sortKeys[i], value: "",
      }
      const addObjectResource = Object.assign(sortArr, tstobj);
      sortArr.push(addObjectResource);
    }
    var finalData = {
      "name": "Users",
      "values": bigData
    }


    let sampleValues = cloneDeep(bigData);
    sampleValues.forEach(va => {
      if (va.header.toString().toLowerCase().trim() == 'account') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'email') {
        va.value = 'userabc@email.com'
      }
      else if (va.header.toString().toLowerCase().trim() == 'firstname') {
        va.value = 'Donald'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isprimaryrole') {
        va.value = 'Physician'
      }
      else if (va.header.toString().toLowerCase().trim() == 'lastname') {
        va.value = 'Robertson'
      }
      else if (va.header.toString().toLowerCase().trim() == 'loginname') {
        va.value = 'DRobertson1298'
      }
      else if (va.header.toString().toLowerCase().trim() == 'npi') {
        va.value = 'UNKNOWN'
      }
      else if (va.header.toString().toLowerCase().trim() == 'readinglocation') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'region') {
        va.value = 'US'
      }
      else if (va.header.toString().toLowerCase().trim() == 'role') {
        va.value = 'Medical Staff;Claim Tracker'
      }
      else if (va.header.toString().toLowerCase().trim() == 'status') {
        va.value = 'Active'
      }
      else if (va.header.toString().toLowerCase().trim() == 'title') {
        va.value = 'Mr.'
      }
      else if (va.header.toString().toLowerCase().trim() == 'orderingfacility' && !this.isFacilityContext) {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'allowlogin') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'address1') {
        va.value = '#1 Queens street'
      }
      else if (va.header.toString().toLowerCase().trim() == 'address2') {
        va.value = '#1 Queens street'
      }
      else if (va.header.toString().toLowerCase().trim() == 'city') {
        va.value = 'Oregon'
      }
      else if (va.header.toString().toLowerCase().trim() == 'eduqualification') {
        va.value = 'MD, PhD,'
      }
      else if (va.header.toString().toLowerCase().trim() == 'fax') {
        va.value = '123123'
      }
      else if (va.header.toString().toLowerCase().trim() == 'gender') {
        va.value = 'Male'
      }
      else if (va.header.toString().toLowerCase().trim() == 'licensure') {
        va.value = '<ArrayOfPathologistLicensure>    <PathologistLicensure>      <id>1</id>      <state>TX</state>      <Expirydate>11/30/2021</Expirydate>      <Statefullname>TX (Texas)</Statefullname>    </PathologistLicensure>  </ArrayOfPathologistLicensure>'
      }
      else if (va.header.toString().toLowerCase().trim() == 'middleinitials') {
        va.value = 'D'
      }
      else if (va.header.toString().toLowerCase().trim() == 'phone') {
        va.value = '7896541230'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usercode') {
        va.value = 'SaDellaportaRef22747'
      }
      else if (va.header.toString().toLowerCase().trim() == 'zip') {
        va.value = '01757-2825'
      }
      else if (va.header.toString().toLowerCase().trim() == 'state') {
        va.value = 'NY (New York)'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usernamedisplayformat' || va.header.toString().toLowerCase().trim() == 'usernamegrossformat' || va.header.toString().toLowerCase().trim() == 'usernamereportformat') {
        va.value = 'First Last'
      }
      else if (va.header?.toString().toLowerCase().trim() == 'departmentids') {
        va.value = '5,6'
      }
    })

    var sampledata = {
      "name": "sampledata",
      "values": sampleValues
    }
    finalArrData.push(finalData)
    finalArrData.push(sampledata);

    this.workbookData = this.transform(finalArrData)
    this.downloadResultname = 'Users_' + 'All Fields_For_Facility_' + this.organizationID.toString();
    this.exportAsXLSX(this.downloadResultname);
  }

  downloadUploadUsersForlab() {
    this.removeCommasBetweenString(this.data);
    this.keyvalues = this.array.map((value) => ({ name: value }))
    this.userType = 'labusers';
    let ifOFFounnd = false;
    for (var i = 0; i < this.insertFields.length; i++) {
      if (this.insertFields[i] == 'orderingfacility') {
        this.insertFields.splice(i, 1);
        break;
      }
    }

    for (var i = 0; i < this.insertFields.length; i++) {
      if (this.insertFields[i] == 'userid') {
        this.insertFields.splice(i, 1);
        break;
      }
    }
    let bigData = [];
    for (let i = 0; i < this.insertFields.length; i++) {
      let primary = {};
      if (this.insertFields[i] == 'title') {
        primary =
          { header: this.insertFields[i], value: [{ name: "Dr." }, { name: "Prof." }, { name: "Mr." }, { name: "Miss." }, { name: "Ms." }, { name: "Mrs." }] }
      }
      // else if (this.insertFields[i] == 'role') {
      //   primary =
      //     // { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }

      //     { header: this.insertFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Technician" }, { name: "User Administrator" }] }

      // }
      else if (this.insertFields[i] == 'gender') {
        primary =
        {
          header: this.insertFields[i], value: [{ name: "Male" }, { name: "Female" }]
        }
      }
      else if (this.insertFields[i] == 'status') {
        primary =
        {
          header: this.insertFields[i], value: [{ name: "Active" }, { name: "InActive" }]
        }
      }
      else if (this.insertFields[i] == 'isPrimaryRole') {
        let namearr = [];
        let len;
        if (this.labRolesData.length > 15) {
          len = 15
        } else {
          len = this.labRolesData.length;
        }
        for (var j = 0; j < len; j++) {
          let name = {};
          name = {
            "name": this.labRolesData[j].rolename
          }
          namearr.push(name);
        }
        primary =
          { header: this.insertFields[i], value: namearr }
      }
      else if (this.insertFields[i] == 'usernamedisplayformat' || this.insertFields[i].toString().toLowerCase().trim() == 'usernamegrossformat' || this.insertFields[i] == 'usernamereportformat') {
        primary =
          { header: this.insertFields[i].toString().toLowerCase().trim(), value: this.keyvalues }
      }
      else if (this.insertFields[i] != 'allowlogin') {
        primary =
          { header: this.insertFields[i], value: '' }
      }
      if (this.insertFields[i] != 'allowlogin') {
        bigData.push(primary);
      }
    }
    bigData.push({ header: 'allowlogin', value: [{ name: "True" }, { name: "False" }] })
    let tstdata = [];
    var finalData = {
      "name": "Users",
      "values": bigData
    }
    let finalArrData = [];

    let sampleValues = cloneDeep(bigData);
    sampleValues.forEach(va => {
      if (va.header.toString().toLowerCase().trim() == 'account') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'email') {
        va.value = 'userabc@email.com'
      }
      else if (va.header.toString().toLowerCase().trim() == 'firstname') {
        va.value = 'Donald'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isprimaryrole') {
        va.value = 'Lab Manager'
      }
      else if (va.header.toString().toLowerCase().trim() == 'lastname') {
        va.value = 'Robertson'
      }
      else if (va.header.toString().toLowerCase().trim() == 'loginname') {
        va.value = 'DRobertson1298'
      }
      else if (va.header.toString().toLowerCase().trim() == 'npi') {
        va.value = 'UNKNOWN'
      }
      else if (va.header.toString().toLowerCase().trim() == 'readinglocation') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'region') {
        va.value = 'US'
      }
      else if (va.header.toString().toLowerCase().trim() == 'role') {
        va.value = 'Lab Administrator;Claim Tracker'
      }
      else if (va.header.toString().toLowerCase().trim() == 'status') {
        va.value = 'Active'
      }
      else if (va.header.toString().toLowerCase().trim() == 'title') {
        va.value = 'Mr.'
      }
      else if (va.header.toString().toLowerCase().trim() == 'orderingfacility') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'allowlogin') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'address1') {
        va.value = '#1 Queens street'
      }
      else if (va.header.toString().toLowerCase().trim() == 'address2') {
        va.value = '#1 Queens street'
      }
      else if (va.header.toString().toLowerCase().trim() == 'city') {
        va.value = 'Oregon'
      }
      else if (va.header.toString().toLowerCase().trim() == 'eduqualification') {
        va.value = 'MD, PhD,'
      }
      else if (va.header.toString().toLowerCase().trim() == 'fax') {
        va.value = '123123'
      }
      else if (va.header.toString().toLowerCase().trim() == 'gender') {
        va.value = 'Male'
      }
      else if (va.header.toString().toLowerCase().trim() == 'licensure') {
        va.value = '<ArrayOfPathologistLicensure>    <PathologistLicensure>      <id>1</id>      <state>TX</state>      <Expirydate>11/30/2021</Expirydate>      <Statefullname>TX (Texas)</Statefullname>    </PathologistLicensure>  </ArrayOfPathologistLicensure>'
      }
      else if (va.header.toString().toLowerCase().trim() == 'middleinitials') {
        va.value = 'D'
      }
      else if (va.header.toString().toLowerCase().trim() == 'phone') {
        va.value = '7896541230'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usercode') {
        va.value = 'SaDellaportaRef22747'
      }
      else if (va.header.toString().toLowerCase().trim() == 'zip') {
        va.value = '01757-2825'
      }
      else if (va.header.toString().toLowerCase().trim() == 'state') {
        va.value = 'NY (New York)'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usernamedisplayformat' || va.header.toString().toLowerCase().trim() == 'usernamegrossformat' || va.header.toString().toLowerCase().trim() == 'usernamereportformat') {
        va.value = 'First Last'
      }
      else if (va.header?.toString().toLowerCase().trim() == 'departmentids') {
        va.value = '5,6'
      }
    })

    var sampledata = {
      "name": "sampledata",
      "values": sampleValues
    }
    finalArrData.push(finalData);
    finalArrData.push(sampledata);

    this.workbookData = this.transform(finalArrData);
    this.downloadResultname = 'Users_' + 'All Fields_For_Lab_' + this.organizationID.toString();
    this.exportAsXLSX(this.downloadResultname);
  }

  downloadUpdateUsers() {
    this.removeCommasBetweenString(this.data);
    this.keyvalues = this.array.map((value) => ({ name: value }))
    let bigData = [];
    this.userType = '';
    let ifUserFound = false;
    for (var i = 0; i < this.updateFields.length; i++) {
      if (this.updateFields[i].allowed == 'orderingfacility') {
        this.updateFields.splice(i, 1);
        break;
      }
    }
    for (var i = 0; i < this.updateFields.length; i++) {
      if (this.updateFields[i] == 'roles' || this.updateFields[i] == 'role') {
        this.updateFields.splice(i, 1);
        break;
      }
    }
    for (var i = 0; i < this.updateFields.length; i++) {
      if (this.updateFields[i] == 'account') {
        this.updateFields.splice(i, 1);
        break;
      }
    }
    for (var i = 0; i < this.updateFields.length; i++) {
      if (this.updateFields[i] == 'readinglocation') {
        this.updateFields.splice(i, 1);
        break;
      }
    }


    for (var i = 0; i < this.updateFields.length; i++) {
      if (this.updateFields[i] == 'userid') {
        ifUserFound = false;
        break;
      }
    }
    let arr = this.updateFields.length + 1;
    if (!ifUserFound) {
      this.updateFields.push('userid');
    }


    for (let i = 0; i < this.updateFields.length; i++) {
      let primary = {};
      if (this.updateFields[i] == 'title') {
        primary =
          { header: this.updateFields[i], value: [{ name: "Dr." }, { name: "Prof." }, { name: "Mr." }, { name: "Miss." }, { name: "Ms." }, { name: "Mrs." }] }
      }
      else if (this.updateFields[i] == 'DisplayMostUsedDxTemplates') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'AllowEditingAdequacyCodes') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'IsUserLevelAdequacyCodeEnabled') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'AllowEditinguserlevelAdequacyCodes') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'AllowEditingCaseCommentsTemplates') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'AllowEditinguserlevelCaseComments') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'AllowEditingUserTemplates') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'IsUserLevelDiagnosisTemplateEnabled') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'IsUserLevelCaseCommentsTemplateEnabled') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }
      else if (this.updateFields[i] == 'AllowEditingDiagnosisTemplates') {
        primary =
          //    { header: this.AllFields[i], value: [{ name: "Claim Tracker" }, { name: "Courier" }, { name: "Customer Service" }, { name: "Cytotechnologist" }, { name: "Transcriptionist" }, { name: "Courier" }, { name: "Cytotechnologist Supervisor" }, { name: "Lab Administrator" }, { name: "Lab Director" }, { name: "Lab Manager" }, { name: "Logistics Executive" }, { name: "Pathologist" }, { name: "Transcriptionist" }, { name: "Sales Executive" }, { name: "Screening Technologist" }, { name: "Technician" }, { name: "User Administrator" }] }
          { header: this.updateFields[i], value: [{ name: "True" }, { name: "False" }] }

      }

      else if (this.updateFields[i] == 'gender') {
        primary =
        {
          header: this.updateFields[i], value: [{ name: "Male" }, { name: "Female" }]
        }

      }
      else if (this.updateFields[i] == 'status') {
        primary =
        {
          header: this.updateFields[i], value: [{ name: "Active" }, { name: "InActive" }]
        }

      }
      else if (this.updateFields[i].toString().toLowerCase().trim() == 'usernamedisplayformat' || this.updateFields[i].toString().toLowerCase().trim() == 'usernamegrossformat' || this.updateFields[i].toString().toLowerCase().trim() == 'usernamereportformat') {
        primary =
          { header: this.updateFields[i].toString().toLowerCase().trim(), value: this.keyvalues }
      }
      else if (this.updateFields[i] != 'allowlogin') {
        primary =
          { header: this.updateFields[i], value: '' }
      }
      if (this.updateFields[i] != 'allowlogin') {
        bigData.push(primary);
      }
    }
    bigData.push({ header: 'allowlogin', value: [{ name: "True" }, { name: "False" }] })
    var finalData = {
      "name": "Users",
      "values": bigData
    }
    let finalArrData = [];

    let sampleValues = cloneDeep(bigData);
    sampleValues.forEach(va => {
      if (va.header.toString().toLowerCase().trim() == 'account') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'email') {
        va.value = 'userabc@email.com'
      }
      else if (va.header.toString().toLowerCase().trim() == 'firstname') {
        va.value = 'Donald'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isprimaryrole') {
        va.value = 'Pathologist'
      }
      else if (va.header.toString().toLowerCase().trim() == 'lastname') {
        va.value = 'Robertson'
      }
      else if (va.header.toString().toLowerCase().trim() == 'loginname') {
        va.value = 'DRobertson1298'
      }
      else if (va.header.toString().toLowerCase().trim() == 'npi') {
        va.value = 'UNKNOWN'
      }
      else if (va.header.toString().toLowerCase().trim() == 'readinglocation') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'region') {
        va.value = 'US'
      }
      else if (va.header.toString().toLowerCase().trim() == 'role') {
        va.value = 'Pathologist;Pathologist Assistant'
      }
      else if (va.header.toString().toLowerCase().trim() == 'status') {
        va.value = 'Active'
      }
      else if (va.header.toString().toLowerCase().trim() == 'title') {
        va.value = 'Mr.'
      }
      else if (va.header.toString().toLowerCase().trim() == 'orderingfacility') {
        va.value = 'ACC specimen'
      }
      else if (va.header.toString().toLowerCase().trim() == 'allowlogin') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'address1') {
        va.value = '#1 Queens street'
      }
      else if (va.header.toString().toLowerCase().trim() == 'address2') {
        va.value = '#1 Queens street'
      }
      else if (va.header.toString().toLowerCase().trim() == 'city') {
        va.value = 'Oregon'
      }
      else if (va.header.toString().toLowerCase().trim() == 'eduqualification') {
        va.value = 'MD, PhD,'
      }
      else if (va.header.toString().toLowerCase().trim() == 'fax') {
        va.value = '123123'
      }
      else if (va.header.toString().toLowerCase().trim() == 'gender') {
        va.value = 'Male'
      }
      else if (va.header.toString().toLowerCase().trim() == 'licensure') {
        va.value = '<ArrayOfPathologistLicensure>    <PathologistLicensure>      <id>1</id>      <state>TX</state>      <Expirydate>11/30/2021</Expirydate>      <Statefullname>TX (Texas)</Statefullname>    </PathologistLicensure>  </ArrayOfPathologistLicensure>'
      }
      else if (va.header.toString().toLowerCase().trim() == 'middleinitials') {
        va.value = 'D'
      }
      else if (va.header.toString().toLowerCase().trim() == 'phone') {
        va.value = '7896541230'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usercode') {
        va.value = 'SaDellaportaRef22747'
      }
      else if (va.header.toString().toLowerCase().trim() == 'zip') {
        va.value = '01757-2825'
      }
      else if (va.header.toString().toLowerCase().trim() == 'state') {
        va.value = 'NY (New York)'
      }
      else if (va.header.toString().toLowerCase().trim() == 'alloweditingadequacycodes') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'alloweditingcasecommentstemplates') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'alloweditingdiagnosistemplates') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'alloweditinguserleveladequacycodes') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'alloweditinguserlevelcasecomments') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'alloweditingusertemplates') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'displaymostuseddxtemplates') {
        va.value = '0'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isuserleveladequacycodeenabled') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isuserlevelcasecommentstemplateenabled') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'isuserleveldiagnosistemplateenabled') {
        va.value = 'True'
      }
      else if (va.header.toString().toLowerCase().trim() == 'usernamedisplayformat' || va.header.toString().toLowerCase().trim() == 'usernamegrossformat' || va.header.toString().toLowerCase().trim() == 'usernamereportformat') {
        va.value = 'First Last'
      }
      else if (va.header.toString().toLowerCase().trim() == 'initials') {
        va.value = 'F'
      }
      else if (va.header.toString().toLowerCase().trim() == 'displaymostuseddxtemplatescount') {
        va.value = '0'
      }
    })

    var sampledata = {
      "name": "sampledata",
      "values": sampleValues
    }
    finalArrData.push(finalData);
    finalArrData.push(sampledata);

    this.workbookData = this.transform(finalArrData);
    this.downloadResultname = 'Users_' + 'Bulk Update_' + this.organizationID.toString();
    this.exportAsXLSX(this.downloadResultname);
  }

  onFileDropped($e) {
    this.onFileChange($e);
  }

  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    this.uploadedFilename = structuredClone(filename);
    let splitarry = filename.split('.');
    this.bigFileName = splitarry[0];
    if (splitarry[1].toUpperCase() !== 'XLSX' && splitarry[1].toUpperCase() !== 'XLS' && splitarry[1].toUpperCase() !== 'XLSM') {
      this._snackbar.open('Please upload an Excel file only.', 'Close');
    } else {
      reader.onload = (event) => {
        const dataUrl = event.target.result as string;
        this.uploadedbase64Data = dataUrl.split(',')[1];
        const binaryData = atob(this.uploadedbase64Data);
        this.workBook = XLSX.read(binaryData, { type: 'binary' });

        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() !== 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() === 'users');
            this.sheetsToSelect.length === 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          } else {
            this.convertToJson(this.sheetsToSelect[0]);
          }
        }
      };
      reader.readAsDataURL(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  // Close Modal


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  /* var sd= XLSX.utils.sheet_to_json(ws,{header:1,defval:""}); */
  /* var sheetdata = sheetjs_cleanEmptyRows(sd) */
  sheetjs_cleanEmptyRows(sd) {
    const data = []

    for (var row = 0; row < sd.length; row++) {

      var i = sd[row].length;
      var j = 0;

      for (var cell = 0; cell < sd[row].length; cell++) {

        if (sd[row][cell].length == 0) { j++ }
      }
      if (j < i) {
        data.push(sd[row]);
      }

    }
    return data;
  }
  //Method to validate sheet data.
  async convertToJson(sheetname) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: "", raw: false })
    let data = [];
    for (let row = 0; row < tempExcelArr.length; row++) {
      for (let [key, value] of Object.entries(tempExcelArr[row])) {
        if (value != "") {
          data.push(tempExcelArr[row]);
          break;
        }
      }
    }

    tempExcelArr = data;
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (key.toString().toLowerCase() == 'allowlogin' && (value == null || value == undefined || value == '')) {
            value = "true"
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      blankrows: false
    })
    temp[0].reverse();
    temp[0].push("Notes");
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.commonService.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', tablename: 'users' });
      i++;
    });
    sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'users',
      context: 'OfflineBulkUpload',
    };
    let thresholdCount
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult).toPromise().then((Resdata) => {
      thresholdCount = JSON.parse(Resdata.data.submenuData[0].Items_JSON).ThresholdCount;
    }, error => {
      console.error(error);
    })

    //offline process starts here
    if (this.excelDataArray.length >= thresholdCount) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        autoFocus: false,
        width: '500px',
        data: { header: "", message: "Data exceeds more than "+thresholdCount+", this process is going to be done in offline!", alert: '',action:'Do continue the process even if any validation fails.', continue: "Continue", cancel: "Cancel" }
      });
     return dialogRef
        .afterClosed().toPromise().then
        ((result) => {
          if (result) {
        this.ngxService.start();
        let attachments = [{
          "id": "",
          "name": this.uploadedFilename,
          "source": this.uploadedbase64Data
            }]
        this.vaservices.uploadFile(attachments)
          .subscribe((res) => {
            if (!res.error) {
                  let inputJson =
                  {
                "deploymentKey": this.deploymentKey,
                "jobType": this.uploadedFilename?.toLowerCase().includes("update") ? 'update' : 'upload',
                    "inputParameters": JSON.stringify({"sourceOrgId": sessionStorage.getItem('org_id'), "createdBy": sessionStorage.getItem("Userid"), "groupname": sessionStorage.getItem('Org_Name'), "username" : localStorage.getItem("user_name") }),
                "emailTo": localStorage.getItem("UserEmail"),
                "createdBy": localStorage.getItem("UserEmail"),
                "groupname": sessionStorage.getItem('Org_Name'),
                "featurename": this.templateData?.menuURL,
                "azurefileid": res?.content[0]?.id,
                "additionalinformation": JSON.stringify({
                  "loggedinuserid": sessionStorage.getItem("Userid"),
                  "type": "labuser",
                  "organizationid": sessionStorage.getItem('org_id'),
                  "action": this.excelDataArray.some(ele => 'userid' in ele) ? 'update' : 'insert'
                })
              };
                  this.vaservices.saveVitalAdminScheduledJobDataRepo(inputJson,'configdb').toPromise().then(response=> {
                if (!response.error) {
                  this.refreshGrid();
                    }else{
                  this._snackbar.open("Offline process job creation", 'Failed');
                }
              });
              this.ngxService.stop();
            } else {
              this._snackbar.open("File upload process", 'Failed');
            }
          });
      } else {
        this._snackbar.open("Upload process has been cancelled", 'Failed');
        this.ngxService.stop();
      }
        });
    } else {
      this.getUserEmails();
    }
  }

  rename(arr, newname, oldname) { // function to rename on button click
    arr = arr.map(function (obj) {
      obj[newname] = obj[oldname]; // Assign new key
      delete obj[oldname]; // Delete old key
      return obj;
    });
  }

  //fetch existing emails from database
  getUserEmails() {
    this.validateExcelData(this.excelDataArray);
  }

  // Validate Excel
  validateExcelData(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    this.gridDisplay = false;
    dataArray = this.commonService.convertObjKeysToLower(excelArr);
    let arr = [];
    this.actionType = dataArray.some(ele => 'userid' in ele) ? 'update' : 'insert';

    for (var i = 0; i < dataArray.length; i++) {
      let primary = {};
      for (let [key, value] of Object.entries(dataArray[i])) {
        if (key == 'loginname') {
          if (this.actionType == 'insert') {
            if (!value || value == "null") {
              var frstchar = dataArray[i].firstname.charAt(0);;
              value = frstchar + dataArray[i].lastname;
              primary[key] = value;
            }
          } else {
            primary[key] = value;
          }
        }
        if (!value || value == "null") {
          value = null;
          primary[key] = value;
        } else {
          primary[key] = value;
        }

        // if (key == "readinglocation") {
        //   if (!value || value == "null") {
        //     let str;
        //     str = dataArray[i].account;
        //     str = str.split(";");
        //     if (str.length == 1) {
        //       value = str[0];
        //     } else {
        //       if (this.actionType == 'insert') {
        //         value = sessionStorage.getItem('LabName');
        //       }
        //     }
        //     primary[key] = value;
        //   } else {
        //     primary[key] = value;
        //   }
        // }

        if (key == "allowlogin") {
          if (!value || value == "null") {
            let str;
            str = false;
            if (str.length == 1) {
              value = str[0];
            }
            primary[key] = value;
          } else {
            primary[key] = value;
          }
        }

        if (key == "npi") {
          if (this.actionType == 'insert') {
            if (!value || value == "null") {
              value = "unknown";
              primary[key] = value;
            } else {
              primary[key] = value;
            }
          } else {
            primary[key] = value;
          }
        }
        if (key == "userid") {
          if (!value || value == "null") {
            value = null;
            primary[key] = value;
          } else {
            primary[key] = value;
          }
        }
      }
      arr.push(primary)
    }
    dataArray = arr.map(x => ({ ...x, ValidationMessage: "", ValidationResponse: "" }));
    this.userType = dataArray.some(x => 'orderingfacility' in x) ? 'users' : 'labusers';
    for (let i = 0; i < dataArray.length; i++) {
      if (!dataArray[i].hasOwnProperty('email')) {
        dataArray[i].email = 'not set';  
      }
    }
    if(this.isFacilityContext){
      this.userType = 'users';
      dataArray = dataArray.map(x => ({ ...x, orderingfacility: sessionStorage.getItem('Org_Name') }));
    }
        let action = dataArray.some(x => 'userid' in x) ? 'update' : 'insert';
    let additionalInfo = {
      "type": this.userType,
      "organizationid": Number(sessionStorage.getItem('org_id')),
      "action": action
    }
    var obj = {
      "organizationid": sessionStorage.getItem('org_id'),
      "type": this.userType,
      "importBulkUser": dataArray,
      "action": action,
      "additionalInfo" : additionalInfo
    }
    this.vitalHttpServices.ValidateBulkUserData(obj,this.deploymentKey).subscribe(async res => {
      this.ngxService.stop();
      let result = JSON.parse(res.content);
      if (result) {
        if (!result.errors) {
          if (result.Respose.length > 0) {
            this.ngxService.stop();
            // let query = this.GetQuery('userpersonalinfo');
            // let userEmail: any = [];
            // for (let i = 0; i < dataArray.length; i++) {
            //   if (dataArray[i].userid) {
            //     let queryVariable = { "accid": dataArray[i].userid.toString() };
            //     let queryResult = this.commonService.GetCardRequest(queryVariable, query);
            //     let userData = await this.vitalHttpServices.GetData(queryResult).toPromise()
            //     if (userData.data.submenuData.length > 0) {
            //       userEmail.push({ userid: dataArray[i].userid, email: userData.data.submenuData[0].email })
            //     }
            //   }
            // }
            result = result.Respose.sort((a,b)=> (a.slno - b.slno));
            dataArray = dataArray.sort((a,b)=> (a.slno - b.slno));
            for (var i = 0; i < result.length; i++) {
              dataArray[i].ValidationMessage = result[i].ValidationMessage;
              dataArray[i].ValidationResponse = result[i].ValidationMessage;
              if (dataArray[i].ValidationResponse == 'Success') {
                result[i].ValidationMessage = 'Valid';
              }
              if (!dataArray[i].userid) {
                if ((dataArray[i].allowlogin != '0' && dataArray[i].allowlogin != 'FALSE') && this.organizationLoginType == 'Email') {
                  if (!dataArray[i].email && dataArray[i].ValidationResponse == 'Success') {
                    if (!result[i].ValidationMessage.match(/Email ID cannot be empty/i)) {
                      result[i].ValidationMessage = 'Email ID cannot be empty.';
                    }
                  }
                }
              }
              // else {
              //   if (this.organizationLoginType == 'Email' && userEmail.some(va => va.userid == dataArray[i].userid && !va.email) && (dataArray[i].allowlogin != '0' && dataArray[i].allowlogin != 'FALSE') && (dataArray[i].email == null || dataArray[i].email == '')) {
              //     if (!result[i].ValidationMessage.match(/Email ID cannot be empty/i)) {
              //       result[i].ValidationMessage = 'Email ID cannot be empty.'
              //     }
              //   }
              // }
              result[i].ValidationMessage = this.handleSSN(result[i].ValidationMessage, dataArray[i])
              dataArray[i].notes = result[i].ValidationMessage;
            }
            this.ngxService.stop();
            this.AddExcelGridData(dataArray);
          }
          else {
            this._snackbar.open("Please provide valid data", 'Failed');
          }
        }
      } else {
        this.ngxService.stop();
        this._snackbar.open("Please provide valid data", 'Failed');
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    });
  }

  renameFormatAfterUpload(data) {
    let arrayValues: any = [];
    if (this.ifBulkUpload) {
      if (data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let primary = {};
            for (let [key, value] of Object.entries(data[i])) {
              if (key == 'usernamedisplayformat' || key == 'usernamegrossformat' || key == 'usernamereportformat') {
                switch (value) {
                  case "F L": value = "First Last"
                    break;
                  case "F L D": value = "First Last Qualification"
                    break;
                  case "L, F": value = "Last First"
                    break;
                  case "L, F M.": value = "Last First Middle."
                    break;
                  case "L, F D": value = "Last First Qualification"
                    break;
                  case "S. F L": value = "Title. First Last"
                    break;
                  case "S. F L D": value = "Title. First Last Qualification"
                    break;
                  case "S. L, F": value = "Title. Last First"
                    break;
                  case "S. L, F M.": value = "Title. Last First Middle."
                    break;
                  case "S. L, F D": value = "Title. Last First Qualification"
                    break;
                }
                primary[key] = value;
              }
              primary[key] = value;
            }
            arrayValues.push(primary);
          }
          data = arrayValues;
        }
      }
    }
    return data;
  }
  renameFormatWhileUpload(data) {
    let arrayValues: any = [];
    if (this.ifBulkUpload) {
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (key == 'usernamedisplayformat' || key == 'usernamegrossformat' || key == 'usernamereportformat') {
              switch (value) {
                case "First Last": value = "F L"
                  break;
                case "First Last Qualification": value = "F L D"
                  break;
                case "Last First": value = "L, F"
                  break;
                case "Last First Middle.": value = "L, F M."
                  break;
                case "Last First Qualification": value = "L, F D"
                  break;
                case "Title. First Last": value = "S. F L"
                  break;
                case "Title. First Last Qualification": value = "S. F L D"
                  break;
                case "Title. Last First": value = "S. L, F"
                  break;
                case "Title. Last First Middle.": value = "S. L, F M."
                  break;
                case "Title. Last First Qualification": value = "S. L, F D"
                  break;
              }
              primary[key] = value;
            }
            primary[key] = value;
          }
          arrayValues.push(primary);
        }
        this.excelDataArray = arrayValues;
      }
    }
  }

  handleSSN(message, data) {
    if (message === 'Valid') {
      if (data.ssn && data.ssn.length > 0) {
        if (!/^\d+$/g.test(data.ssn.toString().trim()))
          message = 'SSN number should be numerical'
        else if (data.ssn.length > 9)
          message = 'SSN number should not be more than 9 digits'
      }
    }
    return message
  }

  async fetchUserEmail(userid) {
    let queryVariable = { "accid": userid.toString() };
    let query = this.GetQuery('userpersonalinfo');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let userEmail
    await this.vitalHttpServices.GetData(queryResult).toPromise().then(data => {
      userEmail = data.data.submenuData[0].email
    });
    return userEmail
  }

  refreshGrid() {
    this.removeGrid();
    this.refreshgriddata();
    this.back();
    this.ifBulkUpload = false;
  }

  removeGrid() {
    this.gridExcelWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.showDelete = true;
  }

  uploadUserData() {
    let oldarr = [];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      this.renameFormatWhileUpload(this.excelDataArray);
      oldarr = this.excelDataArray;
      let finalArr = [];
      finalArr = this.excelDataArray.filter(x => x.notes == 'Valid');
      if (finalArr.length > 0) {
        this.excelDataArray = finalArr;
        var loggeduserid;
        if (sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined || sessionStorage.getItem('Userid') == null) {
          loggeduserid = "-100";
        } else {
          loggeduserid = sessionStorage.getItem('Userid')
        }
        if (this.actionType == 'update') {
          for (var i = 0; i < this.excelDataArray.length; i++) {
            if (this.excelDataArray[i].gender == 'Male') {
              this.excelDataArray[i].gender = '1';

            } else if (this.excelDataArray[i].gender == 'Female') {
              this.excelDataArray[i].gender = '0';
            }
          }
        }
        for (var i = 0; i < this.excelDataArray.length; i++) {
          if (!this.excelDataArray[i].allowlogin) {
            this.excelDataArray[i].allowlogin = false;
          }

          if (this.excelDataArray[i].allowlogin.toString().toLowerCase().trim() == 'false') {
            this.excelDataArray[i].allowlogin = false;

          } else if (this.excelDataArray[i].allowlogin.toString().toLowerCase().trim() == 'true') {
            this.excelDataArray[i].allowlogin = true;
          }
        }

        for (var i = 0; i < finalArr.length; i++) {
          if (finalArr[i].role) {
            if (finalArr[i].role != finalArr[i].isprimaryrole) {
              finalArr[i].role = finalArr[i].role + ';' + finalArr[i].isprimaryrole;
            }
          } else {
            finalArr[i].role = finalArr[i].isprimaryrole;
          }
        }
        var additionalInfo = {          
            "loggedinuserid" : loggeduserid,
            "type" : this.userType,
            "organizationid": sessionStorage.getItem('org_id'),
            "password" : "", 
        }
        var obj = {
          "importBulkUser": this.excelDataArray,
          "additionalInfo" : JSON.stringify(additionalInfo), 
          "loggedinuserid": loggeduserid,
          "type": this.userType,
          "organizationid": sessionStorage.getItem('org_id')
        }
        this.ngxService.start();
        this.vaservices.InsertBulkUserData(obj,this.deploymentKey).subscribe(res => {
          if (!res.error) {
            if (res.content != "") {
              let data = JSON.parse(res.content);
              let resultData= JSON.parse(data?.Response)
              if (resultData[0]?.RESULT == 1) {
                const columnsToDelete = ['slno', 'ValidationMessage', 'ValidationResponse', 'notes', 'tablename'];
                const filteredImportBulkUser = obj.importBulkUser.map(item => {
                  return Object.keys(item)
                    .filter(key => !columnsToDelete.includes(key))
                    .reduce((filteredObj, key) => {
                      filteredObj[key] = item[key];
                      return filteredObj;
                    }, {});
                });
                this.commonService.auditDetails('', '', [], filteredImportBulkUser, this.actionType == 'insert' ? 'Upload' : 'Bulk Update', this.templateData, '');
                this.ngxService.stop();
                if (this.actionType == 'insert') {
                  this._snackBar.open("User(s) Created Successfully!", "Success" ,{ panelClass: ['custom-snackbar'] } );
                } else {
                  this._snackBar.open("User(s) Updated Successfully!", "Success", { panelClass: ['custom-snackbar'] } );
                }

                this.postUpload = true;
                this.showDelete = false;

                oldarr = this.commonService.convertObjKeysToLower(oldarr);
                for (var i = 0; i < oldarr.length; i++) {
                  for (let j = 0; j < resultData?.length; j++) {
                    if (oldarr[i].slno == resultData[j].slno) {
                      if (this.actionType != 'insert') {
                        if (oldarr[i].notes == 'Valid') {
                          oldarr[i]['Status'] = 'Success';
                          oldarr[i]['notes'] = 'Updated Successfully';

                        }
                        else {
                          oldarr[i]['Status'] = 'Ignored';
                        }
                        continue;
                      }
                      if (resultData[0] && resultData[0].RESULT && oldarr[i].notes == 'Valid') {
                        oldarr[i]['Status'] = 'Success';
                        oldarr[i]['notes'] = 'Created Successfully';
                        oldarr[i]['status'] = resultData[i].isactive;
                      } else {
                        oldarr[i]['Status'] = 'Ignored';
                        oldarr[i]['notes'] = oldarr[i]['notes'] != 'Valid' ? oldarr[i]['notes'] : 'User with the same email or login name already exists';
                      }
                    }
                  }
                }
                this.excelDataArray = oldarr;
                this.AddExcelGridData(oldarr);
              } else {
                this.ngxService.stop();
                this._snackbar.open("An error occurred while processing your request", 'Failed');
              }
            }
            else {
              this.ngxService.stop();
              this._snackbar.open("An error occurred while processing your request", 'Failed');
            }
          } else {
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", 'Failed');
          }
        }, error => {
          this.postUpload = false;
          this.ngxService.stop();
          this._snackbar.open("Please check the Notes column and enter valid data!", 'Failed');
        });
      } else {
        this.postUpload = false;
        this.ngxService.stop();
        this._snackbar.open("Please check the Notes column and enter valid data!", 'Failed');
      }
    }
  }

  // Export Grid data
  ExportExcel(flex) {
    let excel = [];
    let data = flex;
    data.rows.find(e => {
      if (e._data["userid"]) {
        delete e._data["role"];
      }
      delete e._data["validationresponse"];
      delete e._data["validationmessage"];
      delete e._data["tablename"];
      excel.push(e._data);
    });
    let filename = this.bigFileName + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'users');
    XLSX.writeFile(wb, filename);
  }

  //#regin to show notes
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null' || value == 'Special characters found in login name'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('Email ID cannot be empty.')
          || value.toString().includes('for another user.') || value.toString().includes('First Name cannot be empty.')
          || value.toString().includes('Last Name cannot be empty.') || value.toString().includes('Title cannot be empty.') || value.toString().includes('Title cannot be empty.')
          || value.toString().includes('NPI length should be 10 digits.') || value.toString().includes('User code already exists. No action will be performed on this user.') || value.toString().includes('The Provided login name is already used.')
          || value.toString().includes('No Associated roles are provided for the user.') || value.toString().includes('Primary role cannot be empty.') || value.toString().includes('These Roles are not available - ') || value.toString().includes('does not exist')
          || value.toString().includes('These Locations does not belong to the ordering facility - ') || value.toString().includes('Reading location does not exist - ') || value.toString().includes('Invalid region .') || value.toString().includes('Reading Location should contain only one location.')
          || value.toString().includes('Email ID is not in correct format.') || value.toString().includes('Please enter valid date for licensure for Pathologist role.') || value.toString().includes('Please enter a valid Fax') || value.toString().includes('For the Pathologist/Cytotechnologist/Cytotechnologist Supervisor roles reading location is required')
          || value.toString().includes('Please enter a valid Fax') || value.toString().includes('Please enter valid userid') || value.toString().includes('Primary role and Rolename is same .')
          || value.toString().includes('Roles does not belong to the Laboratory.') || value.toString().includes('Roles does not belong to the Ordering Facility.') || value.toString().includes('Please enter unique userids')
          || value.toString().includes('User code already exists. No action will be performed on this user.') || value.toString().includes('The Provided login name is already used.') || value.toString().includes('Email ID cannot be empty.')
          || value.toString().includes('NPI cannot contain characters.') || value.toString().includes('NPI length should be 10 digits.') || value.toString().includes('DisplayMostUsedDxTemplatesCount cannot contain characters.') || value.toString().includes('SSN number should not be more than 9 digits')
          || value.toString().includes('SSN number should be numerical') || value.toString().includes('User with the same email or login name already exists') || value.toString().includes('Physician role cannot be created for an ACS location')
          || value.toString().includes('User with same Loginname must have same details except Account')
          || value.toString().includes('Multiple Organizations does not create user')
          || value.toString().includes('Reading Location Should be same')
          || value.toString().includes('is mandatory') 
          || value.toString().includes('Email ID can not be modified as it is a Verified Auth account')) {
          wjCore.toggleClass(e.cell, 'error-msg', true);
        }
        else if (value == 'Valid' || value == 'Success' || value == 'Created Successfully' || value == 'Updated Successfully') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
      }
    }
  }

  public actionType;
  // Grid Data
  AddExcelGridData(data) {
    this.ngxService.start();
    this.sheetHeader;
    this.gridDisplay = false
    this.gridExcelDisplay = true;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        data = this.renameFormatAfterUpload(data);
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          for (let [key, value] of Object.entries(data[i])) {
            //if npi is empty or null set it as UNKNOWN
            if (key.toString().toLowerCase() == 'npi') {
              if (this.actionType == 'insert') {
                if (value === null || value === 'null' || value === undefined || value === "") {
                  primary[key] = 'unknown';
                } else {
                  primary[key] = value;
                }
              } else {
                primary[key] = value;
              }
            }
            //if login name is null concatenate first name and last name
            if (key.toString().toLowerCase() == 'loginname') {
              if (this.actionType == 'insert') {
                if (value === null || value === 'null' || value === undefined || value === "") {
                  var frstchar = data[i].firstname.charAt(0)
                  value = frstchar + data[i].lastname;
                  primary[key] = value;
                } else {
                  primary[key] = value;
                }
              } else {
                primary[key] = value;
              }
            }

            //if accounts value has single value then by default reading location should be set
            // if (key.toString().toLowerCase() == 'readinglocation') {
            //   if (value === null || value === 'null' || value === undefined || value === "" || value === 0) {
            //     if (data[i].account != undefined && data[i].account != null && data[i].account != '') {
            //       let arr = data[i].account.split(";");
            //       if (arr.length == 1) {
            //         primary[key] = arr[0];
            //       } else {
            //         primary[key] = sessionStorage.getItem('LabName');
            //       }
            //     }
            //   } else {
            //     primary[key] = value;
            //   }
            // } else {
            //   primary[key] = value;
            // }

            if (key.toString().toLowerCase() == 'userid') {
              if (value === null || value === 'null' || value === undefined || value === "" || value === 0) {
                value = null;
              }
              else {
                primary[key] = value;
              }
            }

            if (key.toString().toLowerCase() != 'loginname' && key.toString().toLowerCase() != 'npi' && key.toString().toLowerCase() != 'userid') {
              primary[key] = value;
            }

            if (key.toString().toLowerCase() == 'role') {
              if (!value || value === null || value.toString().toLowerCase().trim() === 'null' || value === "" || (!/\S/.test(value.toString()))) {
                mandatoryFieldEmpty = true;
                primary[key] = value;
              } else if (value == 'pathologist' || value == 'Pathologist') { // if role has pathologist value then reading location should be mandatory
                if (data[i].readinglocation == null || data[i].readinglocation.toString().toLowerCase().trim() == 'null' || (!/\S/.test(data[i].readinglocation.toString()))) {
                  mandatoryFieldEmpty = true;
                  primary[key] = value;
                }
              }
            }
            this.excelDataArray[i]["notes"] = data[i].notes;
            primary['notes'] = data[i].notes;
          }
          this.gridArray.push(primary);
        }
        this.excelDataArray = this.gridArray;
        this.gridData = new CollectionView(this.gridArray, { pageSize: 10 });
        if (this.gridArray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.gridExcelWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridExcelWidth > 1300) {
          this.gridExcelWidth = 1300;
        }
      }
    }
    this.ngxService.stop();
    return false;
  }

  getFacilityRoles() {
    let queryResult: any;
    let queryVariable;
    let card;
    card = 'Ordering Facility';
    let query = this.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    Qstr += "associationtype = \"" + card + "\"";

    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.facilityRolesdata = [];
          this.facilityRolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.facilityRolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.facilityRolesdata = rolesarray;
        }
      }
      else {
        console.error(res.error);
      }
    });
  }

  getLabRoles() {
    let queryResult: any;
    let queryVariable;
    let card;
    card = 'laboratory';
    let query = this.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    Qstr += "associationtype = \"" + card + "\"";

    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {

        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.labRolesData = [];
          this.labRolesData = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.labRolesData.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.labRolesData = rolesarray;
        }
      }
      else {
        console.error(res.error);
      }
    });
  }

  // Delete Row
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }

  transform(data) {
    let noOfRowaToGenerate = 200;
    return data.map(({ name, values }) => {
      if (name == 'sampledata') {
        noOfRowaToGenerate = 1;
      }
      const headers = values.reduce((prev, next) =>
      ({
        ...prev, [next.header]: Array.isArray
          (next.value) ? next.value.map(({ name }) => name) : next.value
      }), {})
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers)
      }
    })
  }

  exportAsXLSX(name): void {
    this.excelService.exportAsExcelFile(this.workbookData, name);
  }

  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  async resetUserPasswordByEmail() {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (re.test(this.resdata[0].email) == false && this.resdata[0].email?.toString().toLowerCase().trim() == 'not specified') {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Reset Password", message: "Invalid user Email ID", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return false;
    }
    if (this.restusersstatus?.toString().toLowerCase() != 'active') {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Reset Password", message: "This user is inactive or has been deleted!", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return false;
    }
    else {
      this.toSendEmail(this.resdata[0].email)
    }
  }

  checkSelectedUsers() {
    return this.selectedItems && this.selectedItems.length > 0
  }

  async confirmMigration(requestData, depKey) {
    this.ngxService.start();
    await this.vitalHttpServices.migrateUser(requestData, depKey).toPromise().then(result => {
      if (result && result.length == requestData.length && result.every(va => va.StatusMessage?.toString().match(/invite mail sent/i))) {
        this.btnmigrateuser = false;
        let email = requestData[0].Email;
        if (requestData[0].loginname) {
          let entitykey = this.userCommonKey;
          let value = { 'Email Status': { "newValue": result[0].StatusMessage + ' ' + '(' + email + ')' } };
          let transactionid = this.commonService.generateGuid();
          let info = `Migrate User - "${requestData[0].loginname}" email migration has been initiated`
          this.activityService.setActivitySession({ 'entityId': entitykey, 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': 'Users' }] })
          this.commonService.createActivityObject(entitykey, requestData[0].loginname, 'Users', 'Create', {}, {}, transactionid, '', {}, value, info);
        }
        else {
          let info = `Migrate User - "${this.resdata[0].loginname}" email migration has been initiated`
          let value = { 'Email Status': { "newValue": result[0].StatusMessage + ' ' + '(' + email + ')' } };
          let transactionid = this.commonService.generateGuid();
          let entitykey = this.userCommonKey;
          this.activityService.setActivitySession({ 'entityId': entitykey, 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': 'Users' }] })
          this.commonService.createActivityObject(entitykey, this.resdata[0].loginname, 'Users', 'Create', {}, {}, transactionid, '', {}, value, info);
        }
        this._snackbar.open('Invite email sent successfully', 'Close');
      }

      else {
        this._snackbar.open('User migration failed', 'Close');
        this.btnmigrateuser = false;
      }
    }, error => {
      this._snackbar.open('User migration failed', 'Close');
      this.btnmigrateuser = false;
    });
    this.ngxService.stop();
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': 'Users' }] })
  }

  matchCreatedDate(createddate, LastLoggedInDate) {
    let colonIndexCD = createddate.lastIndexOf(':');
    let colonIndexLLD = LastLoggedInDate.lastIndexOf(':');
    if (colonIndexLLD != -1 && colonIndexCD != -1) {
      return (createddate.substring(0, colonIndexLLD) == LastLoggedInDate.substring(0, colonIndexLLD)) ? 'NA' : LastLoggedInDate
    }
    return LastLoggedInDate;
  }

  setCommonKey(event) {
    if (event && event.editMode) {
      this.EditCommonKey = event.key;
      this.CommonKey = null;
      this.refreshEditdata();
    }
    else {
      this.CommonKey = event.key;
      this.EditCommonKey = null;
      this.refreshAddData();
    }
  }

  //#endregion
  async validationEmail(email: string) {
    let isValid = false;
    let result: any = await lastValueFrom(
      this.vitalHttpServices.getAllowedDomain(this.organizationguid)
    );
    if (result) {
      if (result?.toString().includes(email)) {
        isValid = true;
      }
      isValid = false;
    }
    return isValid
  }

  shouldHideColumn(columnname: string): boolean {
    this.hiddenColumns = ['Last Logged In Date', 'user id', 'Allow Login', 'Approve Password', 'lock out', 'Reg Password', 'createdby', 'createddate', 'modifiedby', 'modifieddate', 'DepartmentName']
    return !this.hiddenColumns?.toString().toLowerCase().includes(columnname?.toString().toLowerCase());
  }

  gridMigrateStatus() {
    this.gridsArray = []
    for (let i = 0; i < this.usersData?.length; i++) {
      if (['autoreinitiated', 'autoinitiated', 'verified'].includes(this.usersData[i]['MigrationStatus']?.toString().toLowerCase())) {
        this.usersData[i]['loginname'] = this.usersData[i]['email'];
      }
      let primary = {};
      for (const [key, value] of Object.entries(this.usersData[i])) {
        if (key.toString().toLowerCase() == 'migrationstatus') {
          if (['initiated', 'autoinitiated'].includes(value?.toString().toLowerCase())) {
            primary[key] = 'Initiated';
          }
          else if (['reinitiated', 'autoreinitiated'].includes(value?.toString().toLowerCase())) {
            primary[key] = 'Re-Initiated';
          }
          else if (['verified'].includes(value?.toString().toLowerCase())) {
            primary[key] = 'Successful'
          }
          else {
            primary[key] = value;
          }
        }
        else {
          primary[key] = value;
        }
      }
      this.gridsArray.push(primary);
    }
  }


  PopulateCaseList() {
    const filteredUserIds = this.distinctRoleInfo.filter(item => !/Physician|Pathologist/i.test(item.rolename)) .map(item => item.userid);
    let loggedIn_userID = filteredUserIds[0]
    let userDetails =
    {
      loginName: this.LogName,
      loggedInUser: loggedIn_userID,
      OrganizationId: ""
    }
    this.ngxService.start();
    this.vitalHttpServices.PopulateCaseList(userDetails).subscribe(res => {
      this.ngxService.stop();
      if (res.Success) {
        this._snackbar.open(res.Message, "Success");
      }
      else {
        this._snackbar.open(res.Message, "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }

  PopulateASCCases() {
    const filteredUserIds = this.distinctRoleInfo
    .filter(item => /Physician/i.test(item.rolename)) // Exclude specific roles
    .map(item => item.userid);
    if (filteredUserIds.length > 0) {
    let loggedIn_userID = filteredUserIds
    let userDetails =
    {
      loginName: this.LogName,
      loggedInUser: loggedIn_userID,
      OrganizationId: ""
    }
    this.ngxService.start();
    this.vitalHttpServices.PopulateASCCases(userDetails).subscribe(res => {
      this.ngxService.stop();
      if (res.Success) {
        this._snackbar.open(res.Message, "Success");
      }
      else {
        this._snackbar.open(res.Message, "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });}
    else{
      this._snackbar.open("Populate ACS Cases can be done only Physician", "Failed");
    }
  }
   showMenuRelatedFlags() {
    let dialogRef = this.dialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: 'Users',
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
