import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatOption, ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { empty } from 'rxjs';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { MatSelect } from '@angular/material/select';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';


declare var $: any;

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-manage-screening-types',
  templateUrl: './manage-screening-types.component.html',
  styleUrls: ['./manage-screening-types.component.scss']
})

export class ManageScreeningTypesComponent implements OnInit {
  SubMenuCardModel: SubMenuCardModel;
  destDeployment: string = '';
  postUpload: boolean;
  selector: Selector;
  selectedData: any = [];
  @Input() templateData: any;
  gridData: wjcCore.CollectionView<any>;
  noDataFound: boolean = false;
  gridShow: boolean;
  gridheader: any;
  gridHeaderList: any;
  listedGridHeader: any = [];
  listHeaderbackup: any = [];
  enableInsert: boolean;
  saveOrEditCompleted: any;
  selectedrowData: any;
  searchTestResult: any[];
  testList = [];
  enableEdit: boolean;
  searchResult: any[];
  gridwidth: number;
  showPaginationMainGrid: boolean = false;
  organizationList: any;
  orgList: any = [];
  copyDataClicked: boolean;
  DeploymentKeys: string[] = [];
  excelDataArray: any;
  copyFlag: any;
  uploadClicked: boolean;
  bulkUpload: boolean;
  allFields: any = [{}];
  mandatoryFields = [{}];
  updateFields = [{}];
  workBook: any;
  sheetHeader: any;
  deleteBtn: boolean;
  noData: boolean;
  showDelete: boolean;
  sheetsToSelect: any[];
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  showInvalidColumns: boolean;
  invalidColumns: any;
  selectedCopyData: any = [];
  id: any;
  checkStatus: boolean = false;

  task: Task = {
    color: 'primary',
  };
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  removeBtn: boolean;
  testFilterList: [];
  testList1: any;
  procedureList: string;
  procList = [];
  searchProcedureResult: any[];
  procedurenamelist: any;
  enableActive: boolean = true;
  auditableColumns: any;
  activityEntity: any;
  editedObj: any;
  collectionMethodList: any;
  validateExtn: boolean = false;
  @ViewChild('matSelectOptionExn') matSelectOptionExn: MatSelect;
  allSelectedExnProcedure: boolean;
  isPapCasetype: boolean = false;
  olddata: any;
  filterdata: any=[];

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: UntypedFormBuilder,
    public tabService: VitalAdminTabService,
    private templateHeaders: TemplateHeadersService,
    public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public commonService: CommonService,
    public activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }
  ngOnInit(): void {
    // this.getListTests();
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.getScreeningTypelist();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.noDataFound = true;
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    this.getAuditableDetails(this.templateData.menuURL);
    this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    if (['gyn pap', 'anal pap'].includes(this.templateData.secondarykeys.casetype.toString().toLowerCase())) {
      this.isPapCasetype = true;
    }
  }
  // Audit
  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = ''
    msgActivityTracker = (info == 'updated') ? 'Updated' : (info == 'saved') ? 'Added' : (info == 'copied') ? 'Copy from other entity' : 'Bulk Upload';

    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj.attributeid, "Screening Type", 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
      this.ngxService.stop();
    }
    else {
      /*New records*/
      this.ngxService.start();
      let orgid = sessionStorage.getItem('org_id');
      if (!newObjArray.length) {
        this.commonService.createActivityTracker(msgActivityTracker, orgid, 'Screening Type ', 'Audit', newObjArray, {});
      }
      else {
        for (let i = 0; i < newObjArray.length; i++) {
          this.commonService.createActivityTracker(msgActivityTracker, orgid, 'Screening Type ', 'Audit', newObjArray[i], {});
        }
      }
    }
    this.ngxService.stop();

  }
  //RBAC audit
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  // Export grid
  ExportExcel(gridData) {
    let excel = [];
    let flex = gridData;
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = flex.rows;
    flex.rows.find((e: any): any => {
      let primary = {}
      delete e._data['Action'];
      delete e._data['casetype'];
      delete e._data['organizationid'];
      delete e._data['tablename'];
      delete e._data['copyflag'];
      delete e._data['slno'];
      // delete e._data['status'];
      // delete e._data['notes'];
      for (let [key, value] of Object.entries(e._data)) {
        // if (key == 'status') {
        //   primary['Status'] = value
        // }
        // if (key == 'notes') {
        //   primary['Notes'] = value
        // }
        primary[key] = value;

      }
      excel.push(primary);
    });
    let filename = 'Screening Type_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Screening types');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }
  // Form
  addScreeningType = this._fb.group({
    AttributeId: '',
    Name: ['', Validators.required],
    Procedure: ['', Validators.required],
    TestName: ['', Validators.required],
    CPTCode: ['', Validators.required],
    Sequence: '',
    IsDefault: true,
    IsActive: true,
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
    frmOrganization: [''],
    frmOrgID: [''],
    frmcpyasinactive: false,
    frmExnprocedure: ['']
  });

  //allows only number
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  // Grid
  initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  // List of data in main grid
  getScreeningTypelist() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getallscreeningtypes');
    queryVariable = {
      organizationid: sessionStorage.getItem('org_id').toString(),
      casetype: this.templateData.secondarykeys.casetype.toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView([]);
        }
        this.ngxService.stop();
      },
      error => {
        this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }
  //method to add data in the grid
  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;

    if(this.templateData?.secondarykeys?.casetype?.toString().toLowerCase() === 'anal pap' || this.templateData?.secondarykeys?.casetype?.toString()?.toLowerCase() === 'gyn pap'){
      for (let i = 0; i < this.gridHeaderList?.length; i++) {
        if (this.gridHeaderList[i]?.templateName == 'Screening Types PAP') {
          this.listedGridHeader = this.gridHeaderList[i]?.headers;
          this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
        }
      }
    }
    else{
      for (let i = 0; i < this.gridHeaderList.length; i++) {
        if (this.gridHeaderList[i].templateName == 'Screening Types') {
          this.listedGridHeader = this.gridHeaderList[i].headers;
          this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
        }
      }
    }
    let gridarray = [];
    this.gridheader = [
      'SequenceOrder',
      'AttributeName',
      'TestName',
      'AttributeId',
      'IsDefault',
      'CreatedBy',
      'ModifiedBy',
      'CPTCodes',
      'ModifiedDate',
      'CreatedDate',
      'IsActive',
      'Procedure',
      'DisplayName',
      'CaseType'
    ]
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (['AttributeName', 'TestName'].includes(key)) {
          primary[key] = value || 'Not Specified';
          }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }

    gridarray?.sort((a, b) => {
      const seqA = isNaN(Number(a.SequenceOrder)) ? Number.MAX_SAFE_INTEGER : Number(a.SequenceOrder);
      const seqB = isNaN(Number(b.SequenceOrder)) ? Number.MAX_SAFE_INTEGER : Number(b.SequenceOrder);
      return seqA - seqB;
    });
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
    return gridarray;
  }


  // back button
  backSelect() {
    this.addScreeningType.controls.frmOrganization.enable()
    this.addScreeningType.patchValue({
      Name: '',
      Procedure: '',
      TestName: '',
      CPTCode: '',
      Sequence: '',
      IsDefault: false,
      IsActive: true,
      frmOrganization: '',
      frmOrgID: '',
      frmExnprocedure: ''
    });
    this.searchTestResult = [];
    this.noData = false;
    this.copyDataClicked = false;
    this.uploadClicked = false;
    this.enableInsert = false;
    this.enableEdit = false;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.bulkUpload = false
    this.searchResult = [];
    this.gridShow = false;
    this.getScreeningTypelist();
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.resetFormErrors();
    this.enableActive = true;
  }
  // grid remove button
  gridRemove() {
    this.checkStatus = false;
    this.postUpload = false;
    this.showInvalidColumns = false;
    this.noData = false;
    this.noDataFound = false;
    this.enableActive = true;
    this.searchResult = []
    this.gridwidth = 0
    this.addScreeningType.controls.frmOrganization.enable()
    this.addScreeningType.patchValue({
      frmOrganization: '',
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase()
    })
    this.gridData = new CollectionView([]);
    this.selectedCopyData = 0
    this.selectedData = []
    this.approveDisable();
  }
  //form reset method
  resetFormErrors() {
    Object.keys(this.addScreeningType.controls).forEach(key => {
      this.addScreeningType.controls[key].setErrors(null)
    });
  }

  //method for bulk update
  bulkEditScreenType(data) {
    var copyObj = data;
    for (let i = 0; i < copyObj.length; i++) {
      delete copyObj[i]["tablename"]
      delete copyObj[i]["resultmessage"]
    }
    this.ngxService.start();
    this.VitalHttpServices.updateScreeningType(copyObj, this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          // this.auditDetails([], copyObj, 'saved');
          this.commonService.auditDetails('attributeid', 'attributename', [], [copyObj], 'Edit', this.templateData, this.auditableColumns);
          this.ngxService.stop();
          for (let i = 0; i < result.length; i++) {
            this.excelDataArray[i]["resultmessage"] = result[i]["NoteMessage"];
            if (result[i]["NoteMessage"].match(/ exists/)) {
              this.excelDataArray[i]["result"] = result[i]["status"];
            }
            else {
              this.excelDataArray[i]["result"] = result[i]["status"]
            }
          }
          this.postUpload = true;
        }
      },
      error => {
        this._snackbar.open('Error updating Screening Type', 'Close');
        this.ngxService.stop();
        console.error(error);
      }
    );
  }
  //method to load copy screen
  loadCopyScreen() {
    if (!this.copyBtn) {
      this.enableInsert = false;
      this.enableEdit = false;
      this.noDataFound = false;
      this.copyDataClicked = true;
      this.gridShow = false;
      this.gridwidth = 0;
      this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
      this.addScreeningType.patchValue({
        Procedure: '',
        frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      })
      this.getListOrg();
    }
  }
  //Deployment details in the copy from Entity
  onChangeDeployment(e, depKey) {
    this.searchResult = []
    this.addScreeningType.patchValue({
      frmOrganization: '',
      frmOrgID: '',
      frmcpyasinactive: false
    })
    this.postUpload = false;
    this.gridwidth = 0;
    if (e.source.selected) {
      this.addScreeningType.patchValue({
        frmDepKey: depKey,
        frmOrganization: '',
        frmOrgID: '',
        frmcpyasinactive: false
      });
      this.getListOrg();
      this.addScreeningType.controls.frmOrganization.enable();
    }
  }
  //get data in copy from Entity
  getListOrg() {
    let queryVariable = {status:'active'};
    let query = this.SubMenuCardModel.GetQuery("orgtype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.addScreeningType.value.frmDepKey).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        // if (data.data.Organizations_list && data.data.Organizations_list.length > 0) {
        //   this.organizationList = data.data.Organizations_list;
        if (data.data.submenuData.length > 0) {
          this.filterdata = data.data.submenuData
          this.organizationList = this.filterdata.filter(o => o.orgtype.toLowerCase() != 'ordering facilities')
        }
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }

  //filter org details based on casetype
  filterOrgName() {
    let orgSuggestion = [];
    this.orgList.foreach(va => va.orgSuggestion.push(va.orgname));
    orgSuggestion = [...new Set(orgSuggestion)].sort();
    return orgSuggestion;
  }
  //Org data suggestion based on number or alphabet
  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = [];
    this.selectedData = [];
    if (value === '') {
      this.searchResult = this.organizationList.filter(x => x.orgname).sort((a, b) => a.orgname?.toString().toLowerCase() < b.orgname?.toString().toLowerCase() ? -1 : a.orgname?.toString().toLowerCase() > b.orgname?.toString().toLowerCase() ? 1 : 0)
      return (this.searchResult = this.searchResult.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.orgname != null) {
            return series.orgname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.orgid != null) {
          return series.orgid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.addScreeningType.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.orgname != null) {
        return series.orgid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
    this.resetFormErrors()
  }

  //sample data for excel
  sampleDataAllFields = [];
  sampleDataMandatoryFields = [];
  smapleDataUpdateFields = [{
    AttributeId: 'Attribute Id or number'
    , CPTCodes: 'Number'
    , IsActive: '1 or 0'
    , IsDefault: '1 or 0'
  }]

  //Upload screen
  loadUploadScreen() {
    if (!this.uploadBtn) {
      this.noDataFound = false;
      this.uploadClicked = true;
      this.copyDataClicked = false;
      this.enableInsert = false;
      this.gridShow = false;
      this.bulkUpload = true;
      this.gridwidth = 0
      let queryVariable = { tablename: 'OrganizationAttributes', identity: "false", nullable: "false" };
      let query = this.SubMenuCardModel.GetQuery("getTableColumns");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.VitalHttpServices.GetData(queryResult).subscribe(data => {
        if (!data.errors) {
          if (data) {
            let allFields = {}
            allFields['CollectionMethod'] = null
            allFields['TestName'] = null
            allFields['CPTCodes'] = null
            allFields['IsDefault'] = null
            for (let i = 0; i < data.data.allFields.length; i++) {
              allFields[data.data.allFields[i]['Column']] = null
            }
            this.allFields[0] = allFields
            delete this.allFields[0]["AttributeType"];
            delete this.allFields[0]["SequenceOrder"];
            delete this.allFields[0]["AttributeContext"];
            delete this.allFields[0]["CreatedBy"];
            delete this.allFields[0]["CreatedDate"];
            delete this.allFields[0]["ModifiedBy"];
            delete this.allFields[0]["ModifiedDate"];
            delete this.allFields[0]["Organizationid"];
            delete this.allFields[0]["AttributeContext"];
            delete this.allFields[0]["OrganizationAttributeGUID"];
            delete this.allFields[0]["AttributeContextId"];
            delete this.allFields[0]["AttributeDescription"];
            delete this.allFields[0]["SourceGUID"];
            // for (let item of data.data.allFields) {
            //   if (item.Column != 'OrganizationId' && item.Column != 'CaseType') {
            //     delete item["AttributeType"]
            //     delete item.Column["AttributeContext"]
            //     this.allFields[0][item.Column] = '';
            //   }
            // }
            this.mandatoryFields[0]["CollectionMethod"] = null
            this.mandatoryFields[0]["AttributeName"] = null
            this.mandatoryFields[0]["TestName"] = null
            this.mandatoryFields[0]["CPTCodes"] = null
            this.mandatoryFields[0]["IsDefault"] = null
            // this.mandatoryFields[0]["IsActive"] = ''
            this.updateFields[0]["AttributeId"] = null
            this.updateFields[0]["CPTCodes"] = null
            this.updateFields[0]["IsActive"] = null
            this.updateFields[0]["IsDefault"] = null
            if(!this.isPapCasetype)
            {
              delete this.allFields[0]["CollectionMethod"];
              delete this.mandatoryFields[0]["CollectionMethod"];
            }
          }
        }
      }, error => {
        console.error(error);
      });
    }
  }
  //Upload file
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationattributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  // validation in grid
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    if(!this.copyFlag){
      dataArray = dataArray.map(item => {
        const { resultmessage, collectionmethod, ...rest } = item;
        return {
          ...rest,
          extractionprocedurename: collectionmethod,
        };
      });
    }else{
      dataArray = dataArray.map(item => {
        const { resultmessage,sequenceorder, ...rest } = item;
        return {
          ...rest, sequenceorder: null
        }
      });
    }
    // if(dataArray.length > 0 && dataArray.some(x => x.attributeid))
    // {
    //   this.sheetHeader = this.sheetHeader.filter(x=> x != 'ExtractionProcedureName')
    // }
    dataArray = (this.isPapCasetype && this.copyDataClicked) ? this.manageKeys(dataArray) : dataArray;
    this.VitalHttpServices.validateScreeningType(dataArray, this.destDeployment).subscribe(result => {
      if (!result.errors) {
        this.noData = false
        if (result.length > 0) {
          dataArray = dataArray.map(item => {
            const { extractionprocedurename, ...rest } = item;
            return {
              ...rest,
              collectionmethod: extractionprocedurename,
            };
          });
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.slno === e.slno) {
                e.resultmessage = r.NoteMessage;
              }
            });
          });
          this.AddGridData(dataArray, 1);
          this.ngxService.stop();
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
      this.noData = true;
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.error(error);
    });
  }
  //Append validation notes in the grid
  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value === '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
              else if (copyfrmorg && key == 'attributeid') {
                value = null;
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("ResultMessage")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      if (this.isPapCasetype && tempExcelArr.some(x => !x.attributeid && !x.AttributeId) && !temp.some(z => z?.toString().toLowerCase() == 'collectionmethod')) {
        temp.push('CollectionMethod')
      }
      temp.push("ResultMessage")
      this.sheetHeader = temp.filter(x => x?.toString().toLowerCase() != 'procedure');
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []

    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, resultmessage: '', tablename: 'OrganizationAttributes', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), casetype: this.templateData.cardtype.toString(), createdby: (sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '') ? -100 : sessionStorage.getItem("Userid") });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  // Close Modal

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //add grid in upload and copy screen
  AddGridData(data, hitCount) {
    this.ngxService.start()
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["result"] || this.excelDataArray[i]["result"] == '') {
        this.excelDataArray[i]["result"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (key == 'sequence') {
                if (value == 0) {
                  if (primary['resultmessage'] != 'Mandatory field is missing!' && primary['resultmessage'] != 'Null values exist!') {
                    primary['resultmessage'] = 'Zero is not allowed for Sequence';
                  }
                }
                else {
                  let pattern = /[0-9]+/
                  if (primary['resultmessage'] != 'Mandatory field is missing!' && primary['resultmessage'] != 'Null values exist!') {
                    primary['resultmessage'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['resultmessage'];
                  }
                }
              }
              if (value && value.toString().match(/null/i) && key != 'attributeid') {
                value = "NULL"
                primary['resultmessage'] = 'Null values exist!'
              }
              if (key == 'attributename' && (!value || value.toString() == '')) {
                value = ''
                primary['resultmessage'] = 'Mandatory field is missing!'
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        const defaultCount = (gridArray || []).filter(item => item.isdefault === 1).length;


      // If there are more than one default, update notes and increment errorCount
      if (defaultCount > 1) {
        // Update the excelDataArray and set displayorder
        gridArray = (gridArray || []).map((item, i) => {
          if (item.isdefault === 1) {
            // Set displayorder to -1
            item.sequenceorder = -1;
          }
          return item; // Return the updated item
        });

        gridArray.forEach((item, i) => {
          if (item.isdefault === 1) {
            gridArray[i]["notes"] = 'Multiple default values found'; // Update notes
          }
        });
      }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if (!this.copyDataClicked) {
          this.selectedData = gridArray;
        }
        this.gridData = new CollectionView(gridArray);
        this.gridwidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      this.ngxService.stop()
    }
  }
  //get data for the second time in copy from Entity (PATCHING)
  selectedCopyDataOrganization(event, data) {
    this.selectedData = [];
    if (event.source.selected ) {
      setTimeout(() => {
      this.addScreeningType.patchValue({
        frmOrgID: data.orgid,
        frmOrganization: data.orgname
      })
      this.getDataToCopy();
    }, 500);
    }
  }

  //Copy data from one org to another
  getDataToCopy() {
    if (typeof (this.addScreeningType.value.frmOrgID) == 'object') {
      let frmorgid = null
      frmorgid = this.addScreeningType.value.frmOrgID
      this.addScreeningType.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { "casetype": this.templateData.secondarykeys.casetype.toString(), "organizationid": this.addScreeningType.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getallscreeningtypescopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.addScreeningType.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.checkStatus = false;
        data.data.submenuData.forEach(va => {
          delete va.AttributeId
          delete va.CaseType
          if (this.copyDataClicked) {
            delete va.Sequence
          }
          if (va.IsActive.toLowerCase() == "yes") {
            va.IsActive = true
          }
          else if (va.IsActive.toLowerCase() == "no") {
            va.IsActive = false
          }
          if (va.IsDefault.toLowerCase() == "yes") {
            va.IsDefault = true
          }
          else if (va.IsDefault.toLowerCase() == "no") {
            va.IsDefault = false;
          }
          // va.IsDefault.toLowerCase() == "yes" ? true : false;
        })
        let copyData = data.data.submenuData;
        if (copyData.length == 0) {
          this.gridwidth = 0;
          this.noData = true;
          return
        }
        this.gridwidth = 1;
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  // validation while copying
  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        primary[key] = value;
      }
      // primary['resultmessage'] = "";
      primary['slno'] = i + 1;
      primary['tablename'] = "organizationattributes";
      primary['organizationid'] = this.templateData.secondarykeys.OrganizationId.toString();
      primary['casetype'] = this.templateData.cardtype.toString();
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }

  //Upload templates
  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    this.sampleDataAllFields = [{
      CollectionMethod: 'Conventional Smear'
      , TestName: 'Test Name or SampleTest'
      , CPTCodes: 'Number', IsDefault: '1 or 0'
      , Name: 'Attribute Name or Adequate'
      , IsActive: '1 or 0'
      , AttributeValue: 'Attribute Value or number'
      , AttributeServiceType: 'Service Type or newid'
      , AttributeContextId2: 'Attribute Context Id 2 or number'
      , AttributeContextId3: 'Attribute Context Id 3 or number'
    }]
    this.sampleDataMandatoryFields = [{
      CollectionMethod: 'Conventional Smear'
      , Name: 'Attribute Name or Adequate'
      , TestName: 'Test Name or SampleTest'
      , CPTCodes: 'Number'
      , IsDefault: '1 or 0'
    }]
    if (!this.isPapCasetype) {
      delete this.sampleDataAllFields[0]["CollectionMethod"]
      delete this.sampleDataMandatoryFields[0]["CollectionMethod"]
    }
    if (type == 'allFieldsCheck') {
      filename = 'Screening Type_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'Screening Type');
      ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'mandatoryFieldsCheck') {
      filename = 'Screening Type_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.mandatoryFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'Screening Type');
      ws = XLSX.utils.json_to_sheet(this.sampleDataMandatoryFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'updateCheck') {
      filename = 'Screening Type_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx'
      excel = this.updateFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'Screening Type');
      ws = XLSX.utils.json_to_sheet(this.smapleDataUpdateFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }

  }
  //message validation color
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('exists') || value.toString().includes('Mandatory') || value.toString().includes('default')|| value.toString().includes('null') || value.toString().toLowerCase().includes('invalid')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value.toString().includes('Valid') || value.toString().includes('Successfully') || value.toString().includes('success') || value.toString().includes('approval')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  //approve disable flag
  approveDisable(isBulk?: Boolean) {
    let data;
    if (this.copyDataClicked && this.selectedData.length == 0 && !isBulk) {
      return true
    }
    else if (!this.copyDataClicked && isBulk && this.excelDataArray.some(x => x.resultmessage == 'Mandatory field(s) is missing' || x.resultmessage == 'Mandatory field is missing!')) {
      return true
    }
    return data;
  }
  //method for remove the data
  deleteScreeningType(data) {
    let deleteObj = {
      'organizationid':sessionStorage.getItem('org_id').toString(),
      'casetype':this.templateData.secondarykeys.casetype.toString(),
      'attributeid':data.rowData.AttributeId
    }
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      autoFocus: false,
      width: '360px',
      panelClass: 'admin-custom-popup',
      data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
    })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {

        this.VitalHttpServices.DeleteScreeningType(deleteObj, this.destDeployment).subscribe(
          result => {
            if (result) {
              this._snackbar.open('Data deleted successfully', 'Close');
              this.commonService.auditDetails('AttributeId', 'Procedure', [data.rowData], [{}], 'Delete', this.templateData, this.auditableColumns);
              this.getScreeningTypelist();
            }
          },
          error => {
            console.error('Error deleting item:', error);
          }
        );
      }
    })
  }
  //approve button
  approveScreeningType(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    this.excelDataArray = this.excelDataArray?.map(item => {
      const {collectionmethod, ...rest } = item;
      return {
        ...rest,
        extractionprocedurename: collectionmethod,
      };
    });
    this.excelDataArray = (this.excelDataArray || []).map(item => {
      if (item.isdefault === 1) {
        return { ...item, sequenceorder: -1 };
      }
      return item;
    });
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["resultmessage"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["resultmessage"].toString().match(/approval/i) && this.excelDataArray[i]["resultmessage"].toString().match(/already exists/i)) {
            errorcount++
          }
          else if(this.excelDataArray[i]["resultmessage"].toString().match(/default data/i)){
            errorcount++
          }

        }
      }
      if (!this.copyDataClicked && this.isPapCasetype && this.excelDataArray.some(x => x["resultmessage"] && (x["resultmessage"].toString().match(/invalid/i) || x["resultmessage"].toString().match(/already exists/i)))) {
        this._snackbar.open("Some of the rows have issues please fix them to proceed", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
              this.excelDataArray[i][key] = null;
              this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1
            }
            else {
              this.excelDataArray[i][key] = value?.toString();
            }
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData?.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]?._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if(this.copyFlag){
        selectedData = selectedData?.map(item => {
          const {collectionmethod,sequenceorder, ...rest } = item;
          return {
            ...rest,
            extractionprocedurename: collectionmethod,
            sequenceorder: null
          };
        });
      }
      // if (selectedData.some(va => !va.testname || !va.attributename)) {
      //   this._snackbar.open('Some of the mandatory data is missing!', 'Close');
      //   return
      // }
      if (this.isPapCasetype && selectedData.some(x => x["resultmessage"] && (x["resultmessage"].toString().match(/invalid/i) || x["resultmessage"].toString().match(/already exists/i) || x["resultmessage"].toString().match(/missing/i)))) {
        this._snackbar.open("Some of the rows have issues please fix them to proceed", "Close");
        return
      }
      if (copyData) {
        this.enableActive = false;
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.selectedCopyData[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1
          if ([false,"false"].includes(this.selectedCopyData[i]["isdefault"])) {
            this.selectedCopyData[i]["isdefault"] = 0;
          }
          else {
            this.selectedCopyData[i]["isdefault"] = 1;
          }

          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray
      let context = copyData ? null : ''
      if (!sendjson[0].attributeid || copyData && this.bulkUpload) {
        this.addUploadScreeningType(dataArray, sendjson, this.bulkUpload, context)
      }
      else {
        this.bulkEditScreenType(sendjson)
      }
    }
  }

  // new code
  recieveObjFromListing(object: any) {
    object.newData.SequenceOrder = object?.newData?.IsDefault == 1 ? -1 : object?.newData?.SequenceOrder;
    const newObj = {};
    Object.entries(object.newData).forEach(([key, value]) => {
      newObj[key.toLowerCase()] = value;
    });

    const oldObj = {};
    Object.entries(object.oldData).forEach(([key, value]) => {
      oldObj[key.toLowerCase()] = value;
    });
    if (newObj['action'] == 'Edit') {
      newObj['attributeid'] = oldObj['attributeid']
      delete newObj['action']
      delete newObj['procedure']
      newObj['extractionprocedurename'] = this.isPapCasetype ? newObj['attributename']?.toString() : null,
      newObj['attributetype'] = 'ScreeningType'
      this.EditScreeningTypeTemplate(newObj,oldObj)
    }
    else {
      delete newObj['action']
      delete newObj['modifiedby']
      newObj['extractionprocedurename'] = this.isPapCasetype ? newObj['attributename']?.toString() : null,
      newObj['attributename']=!this.isPapCasetype?newObj['procedure']?.toString():newObj['procedure']
        this.InsertScreeningTypeTemplate(newObj)
    }
  }
  InsertScreeningTypeTemplate(saveData) {
    let caseType =  saveData['casetype']
    if (saveData['isdefault'] === 1) {

      const defaultValue = this.gridData?._pgView?.find(
        procedure => procedure.CaseType === caseType && procedure.IsDefault === 'Yes'
      );

      if (defaultValue) {
        this.ngxService.stop();
        const dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '500px',
          data: {
            header: '',
            message: `"${defaultValue.DisplayName}" is already marked as the default. Are you sure you want to set this record as the new default?`,
            alert: 'Note: previous record will be unmarked',
            continue: "Yes",
            cancel: "No"
          }
        });

        return dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            this.insertScreeningType(saveData);
          } else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            return false;
          }
        }).catch(error => {
          console.error(error);
        });
      }
      else {
        this.insertScreeningType(saveData);
      }
    }
    else {
      this.insertScreeningType(saveData);
    }
  }
  //method for inserting
  insertScreeningType(data) {
    this.ngxService.start();
    this.VitalHttpServices.InsertScreeningtype([data], this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          this.ngxService.stop();
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          if (result[0].NoteMessage.toString().match(/Duplicate values found/i)) {
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          if (result[0].status.toString().match(/failure/i)) {
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          data.isactive = data.isactive == 1 ? 'Active' : 'Inactive'
          data.isdefault = data.isdefault == 1 ? 'Yes' : 'No'
          this._snackbar.open(result[0].NoteMessage, 'Close');
          this.commonService.auditDetails('AttributeId', 'attributename', result, [data], 'Create', this.templateData, this.auditableColumns);
          this.getScreeningTypelist();
          if (data == 'close') {
            this.enableInsert = false;
            this.gridShow = true;
            this.getScreeningTypelist();
            return
          }
          else {
            this.addScreeningType.reset()
            this.enableInsert = true;
            this.resetFormErrors();
            this.addScreeningType.patchValue({
              IsActive: true
            });
          }
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );

  }

  EditScreeningTypeTemplate(saveData, oldData) {
    let caseType =  this.templateData?.secondarykeys?.casetype;
    if (saveData['isdefault'] === 1) {

      const defaultValue = this.gridData?._pgView?.find(
        procedure => procedure.CaseType === caseType && procedure.IsDefault === 'Yes' && saveData.attributeid != procedure.AttributeId
      );

      if (defaultValue) {
        this.ngxService.stop();
        const dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '500px',
          data: {
            header: '',
            message: `"${defaultValue.DisplayName}" is already marked as the default. Are you sure you want to set this record as the new default?`,
            alert: 'Note: previous record will be unmarked',
            continue: "Yes",
            cancel: "No"
          }
        });

        return dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            this.editScreeningType(saveData, oldData);
          } else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            return false;
          }
        }).catch(error => {
          console.error(error);
        });
      }
      else {
        this.editScreeningType(saveData, oldData);
      }
    }
    else {
      this.editScreeningType(saveData, oldData);
    }
  }
  //edit menthod
  editScreeningType(newData,oldData) {
    this.ngxService.start();
    this.VitalHttpServices.updateScreeningType([newData], this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          this.ngxService.stop();
          if (result[0].status.toString().match(/failure/i)) {
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          newData.isactive = newData.isactive == 1 ? 'Active' : 'Inactive'
          newData.isdefault = newData.isdefault == 1 ? 'Yes' : 'No'
          this.commonService.auditDetails('attributeid', 'procedure',[oldData], [newData], 'Edit', this.templateData, this.auditableColumns);
          this._snackbar.open("Screening type updated successfully", 'Close');
          this.backSelect();
        }
      },
      error => {
        this._snackbar.open('Error updating Screening Type', 'Close');
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  //method for bulk upload
  addUploadScreeningType(dataArray: object[], sendjson, bulkUpload: boolean, context?) {
    this.ngxService.start();
    this.VitalHttpServices.InsertScreeningtype(sendjson,sessionStorage.getItem('deploymentKey').toUpperCase()).subscribe(result => {
      this.noDataFound = false;
      this.ngxService.stop();
      if (!result.errors) {
        // this.auditDetails([], sendjson, 'copied');
        this.noData = false;
        // this.addScreeningType.controls.frmOrganization.disable()
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.slno.toString() === e.slno.toString()) {
              e.result = r.status
              e.resultmessage = r.NoteMessage;
            }
          });
        });
        dataArray.forEach((va: any) => {
          if (va.resultmessage.toString().toLowerCase().match(/already exists/i)) {
            va.result = 'Ignored'
          }
        })
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          this.showDelete = false;
          if (this.copyDataClicked && this.postUpload) {
            this.addScreeningType.controls.frmOrganization.disable()
          }
          let uploadresult = this.convertObjKeysToLower(dataArray);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + '(s) already exist!', 'Close') }
            else { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exists!', 'Close'); }
            return;
          }
          else {
            uploadresult = uploadresult.map(item => {
              const { extractionprocedurename, ...rest } = item;
              return {
                ...rest,
                collectionmethod: extractionprocedurename
              };
            });
            this.AddGridData(uploadresult, 1);
            this.commonService.auditDetails('attributeid', 'attributename', [], uploadresult, bulkUpload ? 'Upload' : 'Copy', this.templateData, this.auditableColumns);
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      this.checkStatus = true;
      console.error(error)
    })
  }
  //delete row in the grid in upload
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }
  //flag for copy as inactive
  checkValue(event) {
    if (event.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
      this.isPapCasetype ? this.auditableColumns['extractionprocedurename'] = "Collection Method" : null
    })
  }

  async getCollectionMethods() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getcollectionmethods');
    queryVariable = {
      orgid: sessionStorage.getItem('org_id').toString(),
      casetype: this.templateData.secondarykeys.casetype.toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.collectionMethodList = data.data.submenuDataExtnProcedures || [];
      }
    }, error => {
      console.error(error);
    })
  }

  filterExtnProcedure(value) {
    if (value == '') {
      return !this.collectionMethodList ? [] : this.collectionMethodList;
    }
    for (let i = 0; i < this.collectionMethodList.length; i++) {
      if (this.collectionMethodList[i].extnprocedurename.toString().toLowerCase() === value.toLowerCase()) {
        this.validateExtn = false;
        break
      } else {
        this.validateExtn = true
      }
    }
    return this.collectionMethodList.filter(va => va.extnprocedurename.toString().toLowerCase().includes(value.toLowerCase()));
  }

  clearSearch(event, dtype) {
    event.stopPropagation();
    if (dtype === 'Extn') {
      this.addScreeningType.patchValue({
        frmExnprocedure: ''
      })
    }
  }

  optionClick(dtype) {
    if (dtype == 'Extn') {
      let newStatus1 = true;
      this.matSelectOptionExn.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus1 = false;
        }
      });
      this.allSelectedExnProcedure = newStatus1;
    }
  }

  toggleAllSelection(dtype) {
    if (dtype === 'Extn') {
      if (this.allSelectedExnProcedure) {
        this.matSelectOptionExn.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionExn.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }

  setValidator()
  {
    if(this.isPapCasetype)
    {
      this.addScreeningType.controls["frmExnprocedure"].setValidators([Validators.required]);
      this.addScreeningType.controls["frmExnprocedure"].updateValueAndValidity();
    }
  }

  manageKeys(excelArr: any)
  {
    return (excelArr.length > 0) ? excelArr.map(x => ({...x, extractionprocedurename: x['attributename'], attributename : x['procedure']})) : []
  }

  trimFieldValue(controlName: any) {
    this.commonService.trimFieldValue(this.addScreeningType, controlName, this.addScreeningType.value.frmOrganization)
  }

  createActivityTrackerForSort($event: any) {

    this.commonService.auditDetails('', '', [], $event, 'Re-Order', this.templateData, '');

  }

  shouldHideColumn(columnname: string): boolean {
    let hiddenColumns = ['organizationid', 'AttributeId', 'notes', 'slno', 'tablename', 'casetype','sequenceorder']
    return !hiddenColumns?.toString()?.toLowerCase()?.includes(columnname?.toString()?.toLowerCase());
  }
}
