<!-- <app-spinner-loader *ngIf="data.sourceApp == 'VitalDx' && showLabAdminLoader "></app-spinner-loader> -->
<section>

    <div class="p-2 mt-2 m-1 manage-by-user-heading align-items-center">
        <div class="mt-1" ><strong class="heading-border">
                Manage Custom
                Listing by User</strong>
        </div>
        <app-search-box class="ml-2" className="m-1" placeHolder="Search User Names, Roles, Work Groups"
            (enteredValue)="searchSubject.next({searchtext:$event})"></app-search-box>
        <app-button buttonclass="secondary" [isDisabled]="disableButtonOnActon" (click)="onNoClick(true)">Return</app-button>
        <app-button buttonclass="primary" [isDisabled]="disableButtonOnActon  || TemplateUsers.length === 0" (click)="onSave()">Save</app-button>
    </div>

    <ng-container *ngIf="usersList$|async as users;else loader">
        <div class="mb-1">
            <div class="manage-by-user-header">
                <div class="manage-by-user-table-header m-1 ml-3">
                    <div id="sequence">Seq</div>
                    <div class="col" id="UserName">UserName</div>
                    <div class="col" id="Role">Role</div>
                    <div class="col" id="Work Groups">Work Groups</div>
                    <div id="accessibility" class="margin-r">Accessibility</div>
                </div>
            </div>
            <div class="manage-by-user-table-body item mt-2">
                <div class="each-item mb-2 m-1 ml-3 align-items-center" *ngFor="let item of users let i = index">
                    <div>{{i + 1}}</div>
                    <div class=" ellipsis" [matTooltip]="tooTipText" #username (mouseover)="toolTipshow(username)">
                        {{item.username}}</div>
                    <div class=" ellipsis col" [matTooltip]="tooTipText" #rolenameEle
                        (mouseover)="toolTipshow(rolenameEle)">
                        {{item.rolename}}</div>
                    <div class=" ellipsis col ml-1" #departmentIds (mouseover)="toolTipshow(departmentIds)"
                        [matTooltip]="tooTipText">
                        <span *ngIf="item.departmentIds === null" class="ml-4">-</span>
                        <span *ngIf="!item.departmentIds === null">{{item.departmentIds}}</span>
                    </div>
                    <div id="accessibility">
                        <!-- <span [id]="'Accessibility'+i"> {{item.isCustomlistingEnabled ? 'User Level' : 'Group Level'
                            }}:</span> -->
                        <Span class="ml-2"><app-toggle-switch type="secondary" labelActivetext="User Level:" labelInactivetext="Group Level:" (clicked)="setFormArray($event,item,i)"
                                [isChecked]="item.isCustomlistingEnabled" [isDisable]="item.isCustomlistingEnabled">
                            </app-toggle-switch></Span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="users.length < 0">
            <div class="col-sm-12 text-center mt-4">
                <span class="w-50 text-center mt-4" class="nodata-design">
                    No Data Found
                </span>
            </div>
        </div>
    </ng-container>
    <ng-template #loader>
        <app-shimmerui [line]="7" *ngIf="data.sourceApp == 'VitalDx' && isDataLoading"></app-shimmerui>
        <mat-spinner class="labadmin-spinner" *ngIf="data.sourceApp == 'VitalDx' && showLabAdminLoader"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner>
    </ng-template>
</section>
