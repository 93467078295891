<div class="labadmin_module">
  <nav class="navbar vla-navbar navbar-expand-lg navbar-light bg-light " *ngIf="!(fullScreenMode$ | async)">
    <div class="row">
      <div class="col-sm-3 d-flex my-auto">
        <img src="../../assets/images_vla/VitalDx-With-Name.svg" style="width: 33%;">
      </div>
      <div class="col-sm-5 p-0 d-flex justify-content-center align-items-center">
        <div style="width: 99%; display: inline-block; margin-right: 2%">
          <div class="search-control-wrap d-flex">
            <div>
              <div class="input-group-btn dropdown entity-menu" id="smallentity" click-stop-propagation>
                <div class="entity-dropdown">
                  <button type="button" class="menu_btn" mat-button data-toggle="dropdown" id="dropdownMenu1"
                    aria-haspopup="true" aria-expanded="false">
                    <span class="entity-list">{{dropdownValue}}</span>
                    <!-- <em class="fa fa-chevron-down" *ngIf="!showDrop"></em> -->
                    <em class="fa fa-angle-down down-btn-align"></em>
                  </button>
                  <ul class="dropdown-menu" role="menu" >
                    <li *ngFor="let entity of EntityList;let index=index">
                        <button class="role" (click)="OnChangeEntry(entity.label)">
                          <strong>{{ entity.label }}</strong>
                        </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <div class="search-clients">
              <mat-chip-list #chipList class ="chip-width-set">
                <mat-chip selected *ngFor="let searchcriteria of searchCriteria" (removed)="remove(searchcriteria)" required>
                  {{searchcriteria}}
                  <mat-icon matChipRemove>cancel </mat-icon>
                </mat-chip>
                <input matInput class="col-sm-6 search-border" name="queryvalue" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="add($event)" [(ngModel)]="queryvalue" (keyup)="filterCriteria($event)" />
              </mat-chip-list>
            </div> -->
            <div class="search-clients d-flex">
              <input type="text" name="queryvalue" [(ngModel)]="queryvalue" class="border-0 adm-top-search-control" #globalSearch
                (keyup)="onChangeSearchInput($event)" autocomplete="off">
              <em class="material-icons close-wrap" *ngIf="queryvalue !== null && queryvalue !== ''"
                (click)="clearSearch()">close</em>
              <button class="search-la-icon" (click)="onSearchInputChange()">
                <em class="fa fa-search"></em>
              </button>
            </div>
            <!-- <div class="search-button">

            </div> -->
          </div>
          <!-- <div *ngIf="showCritria">
            <div *ngFor="let criteria of clientSearchCriteria">
              <div (click)="selectingCriteria(criteria)">{{criteria}}</div>
            </div>
          </div> -->

        </div>
      </div>
      <div class="col-sm-4 p-0 d-flex justify-content-end">
        <div class="d-flex w-100">
          <div class="text-right user-role-container ">
            <div class="text-white user-name font-weight-bold"> {{labAdminService.formattedDisplayName}}</div>
            <div class="outerWrapper" style="display: flex;justify-content: end;">
              <div
                [ngStyle]="{'max-width':(userRoles != null && userRoles.length > 1)?'calc(100vw - 85vw)':'calc(100vw - 80vw)'}"
                class="text-white role-text" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)"
                #isElipsed>
                {{userInfo.defaultRole}} <span class="vdm-text-highlight ">({{ userInfo.orgName | slice:0:37 }}{{
                  userInfo?.orgName?.length > 37 ? '...' : '' }})</span><!--container-->
              </div>
            </div>
            <!-- <div class="text-white role-text"> {{userInfo.defaultRole}}
              <span class="vdm-text-highlight " matTooltip="{{userInfo.orgName}}">({{ userInfo.orgName | slice:0:37 }}{{ userInfo?.orgName?.length > 37 ? '...' : '' }})</span>
            </div> -->
          </div>
          <div class="border-role-diff"></div>
          <div class="d-flex justify-content-around"
            [ngStyle]="{'width':(userRoles != null && userRoles.length > 1)?'53%':'30%'}">
            <div *ngIf="userRoles != null && userRoles.length > 1" class="input-group-btn dropdown" id="entityDrpdwn"
              click-stop-propagation>
              <img role="button" data-toggle="dropdown" matTooltip="Switch Role"
                src="../../assets/images_vla/Role-Switch.svg" alt="switch_role" class="mx-1 cursor-pointer" width="17">
              <ul class="dropdown-menu" role="menu">
                <li *ngFor="let role of userRoles;let index=index">
                  <button class="role" [disabled]="role.roleDisplayName === userInfo.defaultRole"  [ngClass]="{'defaultRole': role.roleDisplayName === userInfo.defaultRole}"
                      (click)="changeRole(role)">
                      <strong>{{role.roleDisplayName }}</strong>
                    </button>

                </li>
              </ul>
            </div>
            <div *ngIf="isSwitchOrg && userAssociatedOrg != null && userAssociatedOrg.length > 1" class="input-group-btn dropdown" id="entityDrpdwn"
              click-stop-propagation>
              <img role="button" data-toggle="dropdown" matTooltip="Switch Org"
                src="../../assets/images_vla/SwitchOrganization.svg" alt="switch_org" class="mx-1 cursor-pointer" width="17">
              <ul class="dropdown-menu" role="menu">
                <li *ngFor="let org of userAssociatedOrg;let index=index">
                  <button class="role" [disabled]="org.AssociatedOrgName === userInfo.orgName"  [ngClass]="{'defaultRole': org.AssociatedOrgName === userInfo.orgName}"
                      (click)="switchOrg(org)">
                      <strong>{{org.AssociatedOrgName}}</strong>
                    </button>

                </li>
              </ul>
            </div>
            <!-- <img role="button" src="../../assets/images_vla/Launch Applications.svg" alt="switch_role" width="18"
              class="mx-1 cursor-pointer">
            <img src="../../assets/images_vla/Notifications.svg" alt="switch_role" width="15"
              class="mx-1 cursor-pointer"> -->
              <div class="menu-btn">
              <img role="button" matTooltip="Settings" src="../../assets/images_vla/User Settings.svg"
                (click)="selectedMenu(settingsObject)" alt="switch_role" width="16" class="mx-1 cursor-pointer">
            </div>
            <div class="menu-btn">
              <img role="button" matTooltip="Help" src="../../assets/images_vla/Preferences.svg" alt="switch_role"
                width="10" class="mx-1 cursor-pointer" (click)="selectedMenu(help)" >
            </div>
            <div class="menu-btn">
              <img role="button" matTooltip="Logout" src="../../assets/images_vla/Logout.svg" alt="switch_role"
                width="18" class="mx-1 cursor-pointer" (click)="logOut()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="container-fluid px-0">
    <div class="d-flex" style=" height: 100vh;">
      <!-- Sidebar -->
      <nav class="sidebar px-1" *ngIf="!(isCreationModeEnabled$ | async) && !(fullScreenMode$ | async) && showMenu">
        <div class="sidebar-sticky">
          <ul class="nav flex-column text-center">
            <li class="nav-item" *ngFor="let item of menuList" (click)="selectedMenu(item)"
              [ngStyle]="{'pointer-events': selectedRoute == item.routerLink ? 'none' : ''}">
              <a [ngClass]="selectedRoute == item.routerLink ? 'nav-link  active':'nav-link ' ">
                <i [ngClass]="'fa'+' '+item.icon +' '+'block menu-icon'" aria-hidden="true"
                  *ngIf="item.iconType =='fa'"></i>
                <mat-icon *ngIf="item.iconType =='mat'" class="block menu-icon">{{item.icon}}</mat-icon>
                <span *ngIf="item.iconType =='svg'" class="imgdata"></span>
                <div class="menu-name">{{item.Htext}}</div>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- /Sidebar -->
      <div class="px-4 py-2 w-100" *ngIf="labAdminService.showIntigrationLoader">
        <app-shimmerui [line] = "8"></app-shimmerui>
      </div>
      <!-- Page Content -->
      <main role="main" class="col px-0" style="overflow: hidden;">
        <!-- Your page content goes here -->
        <!-- <div class="col-sm-11"> -->
        <router-outlet></router-outlet>
        <!-- </div>
        <div class="col-sm-1">
          <div class="trackerDiv"  (click)="openActivityTracker()">
            <em class="fa fa-angle-up popout" *ngIf="isOver"></em>
            <button class="trackerButton"
            [class.mat-elevation-z2]="!isOver"[class.mat-elevation-z8]="isOver"  (mouseover)="isOver = true" (mouseleave)="isOver = false">
            <span>Activity Tracker</span>
            </button>
          </div>
          <lib-activity-tracker-lib *ngIf="openActivityTkr" [trackerInput]="trackerInput" [openActivityDialog]="true" [activityPath]="activityPath" (closeActivityDialog)="openActivityTkr = false" [apiUrl]="trackerApiUrl"></lib-activity-tracker-lib>
        </div> -->
        <!-- <router-outlet (activate)="creationActive = true" (deactivate)="creationActive = false" name="createOrEdit"></router-outlet> -->
      </main>
      <!-- /Page Content -->
    </div>
  </div>
</div>
