<div mat-dialog-title class="bulk-upload-header">
    <span>{{config.header}}</span>
    <img class="ml-auto" [autofocus]="true" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content [class]=" fromManageLots ? 'bulk-upload-lots' : 'bulk-upload'">
    <div class="lots-form" *ngIf="fromManageLots">
        <div class="form-container" [formGroup]="lotExcelGenerationForm">
            <app-custom-select labelValue="Case Type" formcontrolname="caseType" defaultOption="Select Case Type"
                dropDownKeyProp="CaseTypeDisplayName" dropDownValueProp="Casetype" [dropDownValues]="config?.caseTypes"></app-custom-select>
            <div>
                <app-custom-dropdown-checkbox [isAllOptionShown]="false" [listData]="Panels"  [isDisabled]="!Panels?.length"  class="mb-3" [isFormValid]="true" [labelValue]="'Panel'"
                [selectionModel]="panelSelectionModel"  dropDownKeyProp="ProcedureName" dropDownValueProp="OrderCodeID"    placeholder="Select Panel" [required]="true"></app-custom-dropdown-checkbox>
            </div>
            <div>
                <app-custom-select labelValue="Deviation Type" formcontrolname="deviationType" maxLength="3" 
                    defaultOption="" dropDownKeyProp="type" dropDownValueProp="type" [dropDownValues]="DeviationType"></app-custom-select>
                <div class="d-flex mt-2 justify-content-end">
                    <span class="secondary-text">Set Different Min and Max Deviation</span>
                    <app-toggle-switch class="ml-2 toggle-size" type="admin-primary"
                        formcontrolname="setDiffMinAndMax"></app-toggle-switch>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center w-100 justify-content-center">
            <app-button buttonclass="success" customClass="admin" [class]="fromManageLots ? 'bulk-upload-btn-lots' : 'ml-auto bulk-upload-btn mr-1'" [isDisabled]="!panelSelectionModel.selected.length || !lotExcelGeneration.caseType || !lotExcelGeneration.deviationType" (click)="generateAndDownloadTemplate()" >Download Template</app-button>
        </div>
    </div>
    <div *ngIf="!uploadedFile; else showFile" class="py-4 drop-border" (appFileDragDrop)="onDrop($event)"
        (click)="triggerFileInput.click()">
        <input #triggerFileInput type="file" accept=".xlsm" (change)="onFileSelected($event)" style="display: none" />
        <div class="upload-icon-text">
            <img class="mr-2" src="/assets/images_vla/Upload.svg" alt="upload">
            <span>UPLOAD TEMPLATE</span>
        </div>
        <span class="upload-help-text">Click to Upload or Drag Drop the Template File</span>
    </div>
    <ng-template #showFile>
        <div class="d-grid align-items-center justify-content-center">
            <div class="py-3 show-uploaded-file">
                <img src="/assets/images_vla/ExcelIcon_for_Bulk_Upload.svg" alt="bulk upload icon" width="50px">
                <div class="file-name">
                    <span appTooltipEllipsis>{{uploadedFile?.name}}</span>
                    <pre>Size: {{uploadedFile?.calculatedSize}}</pre>
                </div>
                <div class="delete-selected-file" (click)="clearSelection()">
                    <img src="/assets/images_vla/Delete.svg" matTooltip="Delete" alt="delete">
                </div>
            </div>
            <div class="mt-2" *ngIf="errorMessage">
                <span class="bulk-upload-error-message">{{errorMessage}}</span>
            </div>
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="mb-3 mt-1 mx-2 d-flex align-items-center w-100" [class.justify-content-center]="fromManageLots">
        <div class="ml-1" *ngIf="!fromManageLots">
            <img class="template-xl mr-2" src="/assets/icons/lab_users_icons/excel_upload_white.svg" alt="Excel">
            <a class="download-template-text" (click)="downloadSampleTemplate()">Click to Download Template</a>
        </div>
        <app-button buttonclass="primary" customClass="admin"
            [class]="fromManageLots ? 'bulk-upload-btn-lots' : 'ml-auto bulk-upload-btn mr-1'"
            [isDisabled]="errorMessage || !uploadedFile" (click)="onUploadClick()">Upload</app-button>
    </div>
</mat-dialog-actions>
<div *ngIf="showLoader" class="default-loader">
    <mat-spinner class="labadmin-spinner" [diameter]="50"></mat-spinner>
</div>