<div class="flags-size">
  <div class="flag-header-height">
    <h1 mat-dialog-title class="p-0">{{data.header}} Settings</h1>
  </div>
  <div class="flags-Height mt-3">
    <div *ngFor="let item of flags" class="ml-2 mr-1 my-1">
      <mat-card class="p-2 flagCard">
        <div class="row col-sm-12 p-0">
          <div class="col-sm-9 pl-2">
            <div class="col-sm-12">
              <mat-card-title class="flagTitle">{{item.content[0].Name}} ({{item.FlagName}})</mat-card-title>
            </div>
            <div class="col-sm-12">
              <mat-card-subtitle class="flagSubtitle">{{item.content[0].Description}}
                {{item.fieldValue.AttributeFields[0]?.configinstruction}}</mat-card-subtitle>
            </div>
          </div>
          <div class="col-sm-3 p-0">
            <div class="location-settings col-sm-12" *ngIf="item.content[0].actualscope?.toLowerCase() =='both'">
                <mat-form-field class="col-sm-12 p-0 viewSelectContainer" appearance="outline">
                  <mat-label class="flag-lable-color">Select Scope</mat-label>
                  <!-- <ng-container *ngIf="item.content[0].scope == 2"> -->
                    <!-- {{typeof(item.content[0].scope)}} -->
                    <mat-select [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
                      [(ngModel)]="item.content[0].scope" #dynamicFields>
                      <mat-option [value]="'3'" (click)="changeScope('3',item.FlagName)">
                        <div class="truncate-text">This Location</div>
                      </mat-option>
                      <mat-option [value]="'2'" (click)="changeScope('2',item.FlagName)">
                        <div class="truncate-text">All locations</div>
                      </mat-option>
                    </mat-select>
                  <!-- </ng-container> -->
                </mat-form-field>          
            </div>  
            <div *ngIf="item.fieldValue.AttributeFields[0].datatype?.toLowerCase() == 'toggle'"
              class="col-sm-12  d-flex justify-content-end">
              <label class="ct-tag-toggle ml-2">   
                <ng-container *ngIf="item.content[0].scope == 3">
                  <input type="checkbox" [ngStyle]="{'opacity': checkDisable('This_Location_Value_1', item) ? opacity : 0.5}" [(ngModel)]="item.content[0].This_Location_Value_1" [ngModelOptions]="{standalone: true}"
                    (change)="onSliderChange('This_Location_Value_1',item.FlagName)" [disabled]="checkDisable('This_Location_Value_1',item)" />
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 2">                 
                  <input type="checkbox" [ngStyle]="{'opacity': checkDisable('All_Locations_Value_1', item) ? opacity : 0.5}" [(ngModel)]="item.content[0].All_Locations_Value_1" [ngModelOptions]="{standalone: true}"
                    (change)="onSliderChange('All_Locations_Value_1',item.FlagName)" [disabled]="checkDisable('All_Locations_Value_1',item)" />
                </ng-container>
                <span class="slider" [ngStyle]="{'opacity': (checkDisable('This_Location_Value_1', item) || checkDisable('All_Locations_Value_1', item)) ? 0.5 : 1}"></span>
              </label>
            </div>

            <div *ngIf="item.fieldValue.AttributeFields[0].datatype?.toLowerCase() == 'free text'"
             class='viewInputContainer col-sm-12'>
              <mat-form-field class="col-sm-12 p-0 valuedField" appearance="outline">
                <mat-label class="flag-lable-color"> {{item.fieldValue.AttributeFields[0].fieldname}} </mat-label>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <input #dynamicFields trim matInput [(ngModel)]="item.content[0].All_Locations_Value_1" [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null"
                  [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('All_Locations_Value_1',item)" />
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <!-- {{item.fieldValue.AttributeFields[0]?.maxcharactercount}} -->
                  <input #dynamicFields trim matInput [(ngModel)]="item.content[0].This_Location_Value_1" [maxlength]="item.fieldValue.AttributeFields[0]?.maxcharactercount ? item.fieldValue.AttributeFields[0]?.maxcharactercount : null"
                    [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('This_Location_Value_1',item)" />
                </ng-container>
              </mat-form-field>
            </div>
            <div *ngIf="item.fieldValue.AttributeFields[0].datatype?.toLowerCase() == 'number'"
              class='viewInputContainer col-sm-12'>
              <mat-form-field class="col-sm-12 p-0 valuedField" appearance="outline">
                <mat-label class="flag-lable-color"> {{item.fieldValue.AttributeFields[0].fieldname}} </mat-label>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <input #dynamicFields trim matInput [(ngModel)]="item.content[0].All_Locations_Value_1" 
                  [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('All_Locations_Value_1',item)" (input)="onInputChange($event, item,'All_Locations_Value_1')" type="number" />
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <input #dynamicFields trim matInput [(ngModel)]="item.content[0].This_Location_Value_1" [ngModelOptions]="{standalone: true}"
                    [disabled]="checkDisable('This_Location_Value_1', item)"
                    (input)="onInputChange($event, item, 'This_Location_Value_1')" type="number" />
                </ng-container>
              </mat-form-field>
            </div>

            <div *ngIf="item.fieldValue.AttributeFields[0].datatype?.toLowerCase() == 'dropdown'"
              class="viewAutoCompleteContainer col-sm-12">
              <mat-form-field class="w-100 viewAutoComplete" appearance="outline">
                <mat-label class="flag-lable-color">{{item.fieldValue.AttributeFields[0].fieldname}}</mat-label>
                <em class="fa fa-chevron-down chevron-align"></em>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <input #dynamicFields trim type="text" [(ngModel)]="item.content[0].All_Locations_Value_1"
                    [ngModelOptions]="{standalone: true}" matInput
                    [aria-label]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_1 : item.content[0].This_Location_Value_1"
                    [name]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_1 : item.content[0].This_Location_Value_1"
                    [matAutocomplete]="auto" [disabled]="checkDisable('All_Locations_Value_1',item)"
                    [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null">
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <input #dynamicFields trim type="text" [(ngModel)]="item.content[0].This_Location_Value_1"
                    [ngModelOptions]="{standalone: true}" matInput
                    [aria-label]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_1 : item.content[0].This_Location_Value_1"
                    [name]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_1 : item.content[0].This_Location_Value_1"
                    [matAutocomplete]="auto" [disabled]="checkDisable('This_Location_Value_1',item)"
                    [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null"
                    >
                </ng-container>
                <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                  <mat-option class="mat-opt-align"
                    *ngFor="let autoCompleteOption of filterAutomCompleteOptions(item.content[0].scope == 2 ? item.content[0].All_Locations_Value_1 : item.content[0].This_Location_Value_1,item.fieldValue?.AttributeFields[0]?.datasource)"
                    [value]="autoCompleteOption.displayname" >
                    <div class="truncate-text" [matTooltip]="optionToolTipText" #isOptionElipsed>
                      {{autoCompleteOption.displayname }}
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div *ngIf="item.fieldValue.AttributeFields[0].datatype?.toLowerCase() == 'strict dropdown'"
              class="viewSelectContainer col-sm-12">
              <mat-form-field class="col-sm-12 p-0 viewSelectContainer" appearance="outline">
                <mat-label class="flag-lable-color"> {{item.fieldValue.AttributeFields[0].fieldname}} </mat-label>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <mat-select [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
                    [(ngModel)]="item.content[0].All_Locations_Value_1" #dynamicFields [disabled]="checkDisable('All_Locations_Value_1',item)" >
                    <!-- Loop over the datasource array -->
                    <mat-option *ngFor="let dropdownOptions of item.fieldValue?.AttributeFields[0]?.datasource"
                      [value]="dropdownOptions.value">
                      <div class="truncate-text">
                        {{ dropdownOptions.displayname }}
                      </div>
                    </mat-option>
                  </mat-select>
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <mat-select [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
                    [(ngModel)]="item.content[0].This_Location_Value_1" #dynamicFields [disabled]="checkDisable('This_Location_Value_1',item)" >
                    <!-- Loop over the datasource array -->
                    <mat-option *ngFor="let dropdownOptions of item.fieldValue?.AttributeFields[0]?.datasource"
                      [value]="dropdownOptions.value">
                      <div class="truncate-text">
                        {{ dropdownOptions.displayname }}
                      </div>
                    </mat-option>
                  </mat-select>
                </ng-container>
              </mat-form-field>
            </div>

            <!-- -----------------------------------ext attr value 2---------------------------------------------->
            <div *ngIf="item.fieldValue.AttributeFields[1]?.datatype?.toLowerCase() == 'toggle'"
              class="col-sm-12  d-flex justify-content-end">
              <label class="ct-tag-toggle ml-2">   
                <ng-container *ngIf="item.content[0].scope == 3">
                  <input type="checkbox" [ngStyle]="{'opacity': checkDisable('This_Location_Value_2', item) ? opacity : 0.5}" [(ngModel)]="item.content[0].This_Location_Value_2" [ngModelOptions]="{standalone: true}"
                    (change)="onSliderChange('This_Location_Value_2',item.FlagName)" [disabled]="checkDisable('This_Location_Value_2',item)" />
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 2">                 
                  <input type="checkbox" [ngStyle]="{'opacity': checkDisable('All_Locations_Value_2', item) ? opacity : 0.5}" [(ngModel)]="item.content[0].All_Locations_Value_2" [ngModelOptions]="{standalone: true}"
                    (change)="onSliderChange('All_Locations_Value_2',item.FlagName)" [disabled]="checkDisable('All_Locations_Value_2',item)" />
                </ng-container>
                <span class="slider" [ngStyle]="{'opacity': (checkDisable('This_Location_Value_2', item) || checkDisable('All_Locations_Value_2', item)) ? 0.5 : 1}"></span>
              </label>
            </div>

            <div *ngIf="item.fieldValue.AttributeFields[1]?.datatype?.toLowerCase() == 'free text'"
              class='viewInputContainer col-sm-12'>
              <mat-form-field class="col-sm-12 p-0 valuedField" appearance="outline">
                <mat-label class="flag-lable-color"> {{item.fieldValue.AttributeFields[1]?.fieldname}} </mat-label>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <input #dynamicFields trim matInput [(ngModel)]="item.content[0].All_Locations_Value_2" [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null"
                    [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('All_Locations_Value_2',item)" />
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <input #dynamicFields trim matInput [(ngModel)]="item.content[0].This_Location_Value_2" [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null"
                    [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('This_Location_Value_2',item)" />
                </ng-container>
              </mat-form-field>
            </div>
            <div *ngIf="item.fieldValue.AttributeFields[1]?.datatype?.toLowerCase() == 'number'"
            class="viewInputContainer col-sm-12">
            <mat-form-field class="col-sm-12 p-0 valuedField" appearance="outline">
              <mat-label class="flag-lable-color"> {{item.fieldValue.AttributeFields[1]?.fieldname}} </mat-label>
              <ng-container *ngIf="item.content[0].scope == 2">
                <input #dynamicFields trim matInput [(ngModel)]="item.content[0].All_Locations_Value_2" 
                  [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('All_Locations_Value_2',item)"(input)="onInputChange($event, item,'All_Locations_Value_2')" type="text" />
              </ng-container>
              <ng-container *ngIf="item.content[0].scope == 3">
                <input #dynamicFields trim matInput [(ngModel)]="item.content[0].This_Location_Value_2" 
                  [ngModelOptions]="{standalone: true}" [disabled]="checkDisable('This_Location_Value_2',item)"(input)="onInputChange($event, item,'This_Location_Value_2')" type="text"/>
              </ng-container>
            </mat-form-field>
          </div>
            

            <div *ngIf="item.fieldValue.AttributeFields[1]?.datatype?.toLowerCase() == 'dropdown'"
              class="viewAutoCompleteContainer col-sm-12">
              <mat-form-field class="w-100 viewAutoComplete col-sm-12" appearance="outline">
                <mat-label class="flag-lable-color">{{item.fieldValue.AttributeFields[1]?.fieldname}}</mat-label>
                <em class="fa fa-chevron-down chevron-align"></em>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <input #dynamicFields trim type="text" [(ngModel)]="item.content[0].All_Locations_Value_2"
                    [ngModelOptions]="{standalone: true}" matInput
                    [aria-label]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_2 : item.content[0].This_Location_Value_2"
                    [name]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_2 : item.content[0].This_Location_Value_2"
                    [matAutocomplete]="auto" [disabled]="checkDisable('All_Locations_Value_2',item)"
                    [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null">
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <input #dynamicFields trim type="text" [(ngModel)]="item.content[0].This_Location_Value_2"
                    [ngModelOptions]="{standalone: true}" matInput
                    [aria-label]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_2 : item.content[0].This_Location_Value_2"
                    [name]="item.content[0].scope == 2 ? item.content[0].All_Locations_Value_2 : item.content[0].This_Location_Value_2"
                    [matAutocomplete]="auto" [disabled]="checkDisable('This_Location_Value_2',item)"
                    [maxlength]="item.fieldValue?.AttributeFields[0]?.maxcharactercount ? item.fieldValue?.AttributeFields[0]?.maxcharactercount : null">
                </ng-container>
                <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                  <mat-option class="mat-opt-align"
                    *ngFor="let autoCompleteOption of filterAutomCompleteOptions(item.content[0].scope === 2 ? item.content[0].All_Locations_Value_2 : item.content[0].This_Location_Value_2,item.fieldValue?.AttributeFields[0]?.datasource)"
                    [value]="autoCompleteOption.displayname">
                    <div class="truncate-text" [matTooltip]="optionToolTipText" #isOptionElipsed>
                      {{autoCompleteOption.displayname }}
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div *ngIf="item.fieldValue.AttributeFields[1]?.datatype?.toLowerCase() == 'strict dropdown'"
              class="viewSelectContainer col-sm-12">
              <mat-form-field class="col-sm-12 p-0 viewSelectContainer" appearance="outline">
                <mat-label class="flag-lable-color"> {{item.fieldValue.AttributeFields[1]?.fieldname}} </mat-label>
                <ng-container *ngIf="item.content[0].scope == 2">
                  <mat-select [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
                    [(ngModel)]="item.content[0].All_Locations_Value_2" #dynamicFields [disabled]="checkDisable('All_Locations_Value_2',item)">
                    <!-- Loop over the datasource array -->
                    <mat-option *ngFor="let dropdownOptions of item.fieldValue?.AttributeFields[0]?.datasource"
                      [value]="dropdownOptions.value">
                      <div class="truncate-text">
                        {{ dropdownOptions.displayname }}
                      </div>
                    </mat-option>
                  </mat-select>
                </ng-container>
                <ng-container *ngIf="item.content[0].scope == 3">
                  <mat-select [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
                    [(ngModel)]="item.content[0].This_Location_Value_2" #dynamicFields [disabled]="checkDisable('This_Location_Value_2',item)">
                    <!-- Loop over the datasource array -->
                    <mat-option *ngFor="let dropdownOptions of item.fieldValue?.AttributeFields[0]?.datasource"
                      [value]="dropdownOptions.value">
                      <div class="truncate-text">
                        {{ dropdownOptions.displayname }}
                      </div>
                    </mat-option>
                  </mat-select>
                </ng-container>
              </mat-form-field>
            </div>
            <!-- <div *ngIf="item.fieldValue.AttributeFields[0]?.configinstruction" class="col-sm-5 mt-2">
              <div class="col-sm-12">
                <P class="note-mark text-xs font-weight-bold ">
                  <img alt="help" class="mr-1 mb-1" src="../../../assets/icons/help.svg" width="15px" height="auto">
                  <span class="intstMsg"> {{item.fieldValue.AttributeFields[0]?.configinstruction}}</span>
                </P>
              </div>
            </div> -->
          </div>
          
        </div>
        <mat-card-content class="p-0 col-sm-3">
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngFor="let item of wronglyConfiguredFlags" class="ml-2 mr-1 my-1">
      <mat-card class="p-2 wrongFlagCard">
        <div class="row col-sm-12 p-0">
          <div class="col-sm-9 pl-2">
            <div class="col-sm-12">
              <mat-card-title class="flagTitle">{{item.name}} ({{item.FlagName}})</mat-card-title>
            </div>
            <div class="col-sm-12">
              <mat-card-subtitle class="wrongFlagSubtitle">Flag Configuration is not set up correctly. Please review the flag details in 'Manage Configurations'</mat-card-subtitle>
            </div>
          </div>          
        </div>
        <mat-card-content class="p-0 col-sm-3">
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="action-buttons pt-2">
    <!-- <a (click) = "resetFlags()"> Reset</a> -->
    <a  *ngIf="checkAnyChangesToFlags()" class="reset-btn mr-2" (click)="resetFlags()" >Reset</a>
    <button class="cancel-btn" (click)="cancelFlags()">Cancel</button>
    <button class="save-btn" (click)="saveFlags()">Save</button>    
  </div>
</div>