import { createAction, props } from '@ngrx/store';
import { changeListPageView } from '../../components/share/model/store.models';

export const decrement = createAction('[Counter Component] Decrement');
export const addNabvTab = createAction('[Counter Component] Reset',props<{value: string}>());
export const changeDetailsView=createAction('[Counter Component ] change details view',props<{URL:string,showDetails: boolean}>());
export const enableListView=createAction('[Counter Component ] Enable List Page ',props<{URL: string,reload?:boolean}>());
export const disableListView=createAction('[Counter Component ] Disable List Page',props<{URL: string}>());
export const setReloadingPage=createAction('[Counter Component ] Set loading page ',props<{URL: string,reload:boolean}>());
export const toggleReloadPage=createAction('[Counter Component ] Toggles reloading of page  ',props<{URL: string}>());

