import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-template-reorder',
  templateUrl: './template-reorder.component.html',
  styleUrls: ['./template-reorder.component.scss']
})
export class TemplateReorderComponent implements OnInit, OnChanges {

  finalSortedArray: any = [];
  scrollHistory: number = 0;
  sortModeEnabled: boolean = true;
  columnDisplayName: any;
  sequneceProperty: any;
  templateNameProperty: any;
  recordId: any;
  templateNameToBeSent: any;
  saveClicked = false;

  @Input() sortSaveOrEditCompleted: any;
  @Input() isAdmin3: any;

  sortTemplateMapping = {
    'Grossing Templates': {
      columnDisplayName: 'Template Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'TemplateName',
      recordId: 'OrgGrossTempID',
      templateNameToBeSent: 'GrossingTemplates'
    },
    'Extraction Procedures': {
      columnDisplayName: 'Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'ExtnProcedureName',
      recordId: 'extnId',
      templateNameToBeSent: 'ExtractionProcedure'
    },
    'Clinical Information': {
      columnDisplayName: 'Attribute Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'Attribute_Name',
      recordId: 'Attribute_ID',
      templateNameToBeSent: 'ClinicalInformation'
    },
    'Screening Comments': {
      columnDisplayName: 'Template Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'TemplateName',
      recordId: 'Id',
      templateNameToBeSent: 'ScreeningComments'
    },
    'Case Comments': {
      columnDisplayName: 'Template Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'TemplateName',
      recordId: 'Id',
      templateNameToBeSent: 'CaseComments'
    },
    'Adequacy Codes': {
      columnDisplayName: 'Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'Name',
      recordId: 'Id',
      templateNameToBeSent: 'AdequacyCodes'
    },
    'Rejection Templates': {
      columnDisplayName: 'Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'AttributeName',
      recordId: 'AttributeId',
      templateNameToBeSent: 'RejectionTemplate'
    },
    'Sendout Reasons': {
      columnDisplayName: 'Name',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'TemplateName',
      recordId: 'Id',
      templateNameToBeSent: 'SendoutReasons'
    }
    ,
    'Worklists': {
      columnDisplayName: 'WorkLists',
      sequneceProperty: 'Sequence',
      templateNameProperty: 'DisplayName',
      recordId: 'Id',
      templateNameToBeSent: 'Worklists'
    },
    'Screening Types': {
      columnDisplayName: 'TestName(Name)(CPTCodes)',
      sequneceProperty: 'SequenceOrder',
      templateNameProperty: 'DisplayName',
      recordId: 'AttributeId',
      templateNameToBeSent: 'ScreeningTypes'
    },
  }

  ngOnInit() {
    this.columnDisplayName = this.sortTemplateMapping[this.fileName]['columnDisplayName'];
    this.sequneceProperty = this.sortTemplateMapping[this.fileName]['sequneceProperty'];
    this.templateNameProperty = this.sortTemplateMapping[this.fileName]['templateNameProperty'];
    this.recordId = this.sortTemplateMapping[this.fileName]['recordId'];
    this.templateNameToBeSent = this.sortTemplateMapping[this.fileName]['templateNameToBeSent'];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.sortSaveOrEditCompleted && changes?.sortSaveOrEditCompleted?.currentValue !== changes?.sortSaveOrEditCompleted?.previousValue) {
      if(this.sortSaveOrEditCompleted !== 'failed' || this.sortSaveOrEditCompleted !== 'error') {
        this.saveClicked = false;
      } 
    }
  }

  sortedOrder: any = 'UNS';
  @Input() sortableGridData: any = [];
  @Input() fileName: any = '';


  @Output() returnList = new EventEmitter<any>();
  @Output() saveReOrdering = new EventEmitter<any>();

  onScroll(e: any) {
    this.scrollHistory = e.target.scrollTop
  }

  onDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container && event.previousIndex != event.currentIndex) {
      const prevIndex = event.previousIndex;
      const currIndex = event.currentIndex;

      if (prevIndex !== currIndex) {
        moveItemInArray(this.sortableGridData, prevIndex, currIndex);
        this.adjustSequences(prevIndex, currIndex);
      }
    }
  }
  adjustSequences(prevIndex: number, currIndex: number) {
    // Just recalculating the sequence for the entire list based on index
    this.sortableGridData.forEach((item, index) => {
      const newSequence = index + 1;
  
      // Only push to the finalSortedArray if the sequence has changed
      if (item[this.sequneceProperty] !== newSequence) {
        let temObj = {
          sequence: newSequence,
          id: item[this.recordId],
          template: this.templateNameToBeSent,
          modifiedby: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
        };
  
        // Update the sequence property
        item[this.sequneceProperty] = newSequence;
  
        // Check if the item already exists in finalSortedArray
        const existingItem = this.finalSortedArray.find(
          (finalItem: any) => finalItem?.id === item[this.recordId]
        );
  
        if (existingItem) {
          existingItem.sequence = newSequence;
        } else {
          this.finalSortedArray.push(temObj);
        }
      }
    });
  }
  
  

  returnToList() {
    this.returnList.emit();
  }

  saveReorder() {
    this.saveClicked = true;
    this.saveReOrdering.emit(this.finalSortedArray);
  }


  sequenceTracking(type: any) {
    this.sortedOrder = type;
    if (type === 'ASC') {
      this.sortableGridData = this.sortableGridData?.sort((a, b) => a?.[this.templateNameProperty]?.localeCompare(b?.[this.templateNameProperty]));
    } else if (type === 'DESC') {
      this.sortableGridData = this.sortableGridData?.sort((a, b) => b?.[this.templateNameProperty]?.localeCompare(a?.[this.templateNameProperty]));
    }
    this.finalSortedArray = [];

    this.sortableGridData.forEach((element: any, index: any) => {
      if(!element['Sequence'])
        element['SequenceOrder'] = index + 1;
      else
      element['Sequence'] = index + 1;
    });

    this.finalSortedArray = this.sortableGridData.map((item: any, index: any) => ({
      sequence: item?.[this.sequneceProperty],  // Assuming sequence is a placeholder, modify as needed
      id: item?.[this.recordId],
      template: this.templateNameToBeSent,
      modifiedby: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
    }));
  }

}
