import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MattabnavComponent } from '../mattabnav/mattabnav.component';
import { SubsidemenuComponent } from '../subsidemenu/subsidemenu.component';
import { TabnavComponent } from '../tabnav/tabnav.component';
import { AppMaterialModule } from 'src/app/appmaterial.module';
import { RouterModule } from '@angular/router';
import { TemplatesListingComponent } from 'src/app/templates-listing/templates-listing.component';
import { TemplatesViewComponent } from 'src/app/base/popup/templates-view/templates-view.component';
import { searchFilterPipetemplate } from 'src/app/core/utility/tripfilter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilteredSelectorComponent } from '../filtered-selector/filtered-selector.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { InstructionsEditorComponent } from './components/instructions-editor/instructions-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SurgicenterAssociationComponent } from '../surgicenter-association/surgicenter-association.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { sharedModule } from 'src/app/shared/shared.module';
import { NpiComponent } from './components/npi/npi.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LabusersgridComponent } from '../../lab/labusers/labusersgrid/labusersgrid.component';
import { CustomAbbreviationsComponent } from 'src/app/client/Templates/custom-abbreviations/custom-abbreviations.component';
import { ManageGrossTemplatesComponent } from '../../../client/Templates/manage-gross-templates/manage-gross-templates.component';
import { ManageAutoAssignmentRulesComponent } from 'src/app/client/Templates/manage-auto-assignment-rules/manage-auto-assignment-rules.component';
import { CommonOptionsComponent } from 'src/app/client/Templates/common-options/common-options.component';
import { ManageRejectionCommentsComponent } from '../../../client/Templates/manage-rejection-comments/manage-rejection-comments.component';
import { DeletionTemplatesComponent } from 'src/app/client/Templates/deletion-templates/deletion-templates.component';
import { AdequacyCodesComponent } from 'src/app/client/Templates/adequacy-codes/adequacy-codes.component';
import { CaseCommentsComponent } from 'src/app/client/Templates/case-comments-component/case-comments.component';
import { MicroscopicCodesComponent } from 'src/app/client/Templates/microscopic-codes/microscopic-codes.component';
import { ScreeningCommentsComponent } from 'src/app/client/Templates/screening-comments/screening-comments.component';
import { DiagnosisReferencesComponent } from 'src/app/client/Templates/diagnosis-references/diagnosis-references.component';
import { SequesterTemplatesComponent } from 'src/app/client/Templates/sequester-templates/sequester-templates.component';
import { ReviseTemplatesComponent } from 'src/app/client/Templates/revise-templates/revise-templates.component';
import { ManageCategoriesComponent } from '../../../client/Templates/manage-categories/manage-categories.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { FaxComponent } from './components/fax/fax.component';
import { FormatDatePipe } from './pipes/formate-date/format-date.pipe';
import { ViewAttachmentComponent } from './components/view-attachment/view-attachment.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TooltipEllipsisDirective } from './directives/tooltip-ellipsis.directive';
import { ManagePasswordPolicyComponent } from 'src/app/client/Templates/ManagePasswordPolicy/manage-password-policy.component';
import { ManageCustomListingComponent } from 'src/app/client/Templates/manage-custom-listing/manage-custom-listing.component';
// import * as wjcCore from '@grapecity/wijmo';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { NewBulkUploadTestsComponent } from 'src/app/client/Templates/new-bulk-upload-tests/new-bulk-upload-tests.component';
import { UserBulkUploadComponent } from './components/user-bulk-upload/user-bulk-upload.component';

import { ManageReflexRulesComponent } from 'src/app/client/Templates/manage-reflex-rules/manage-reflex-rules.component';
import { NotAvailableDirective } from 'src/app/core/utility/not-available.directive';
import { TubetypeTemplatesComponent } from 'src/app/client/Templates/tubetype-templates/tubetype-templates.component';
import { ManagePathologistPrivilegeComponent } from 'src/app/client/Templates/manage-pathologist-privilege/manage-pathologist-privilege.component';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { NomenclatureTemplatesComponent } from 'src/app/client/Templates/nomenclature-templates/nomenclature-templates.component';
import { CorelationTemplatesComponent } from 'src/app/client/Templates/corelation-templates/corelation-templates.component';
import { ShortenTextPipe } from '../../../core/utility/shortentext.pipe';
import { StripHtmlPipe } from 'src/app/core/utility/striphtml.pipe';
import { BulkUploadPopUpComponent } from './components/bulk-upload-pop-up/bulk-upload-pop-up.component';
import { NgxEditorModule } from 'ngx-editor';
import { SendoutReasonsComponent } from '../../../client/Templates/sendout-reasons/sendout-reasons.component';
import { HtmlFilterPipe } from 'src/app/core/utility/htmlfilter.pipe';
import { NewDiagnosisTemplatesComponent } from 'src/app/client/Templates/new-diagnosis-templates/new-diagnosis-templates.component';
import { CommonBulkFunctionsComponent } from 'src/app/commoncomponents/common-bulk-functions/common-bulk-functions.component'; import { AdminContactInfoPopUpComponent } from './components/admin-contact-info-pop-up/admin-contact-info-pop-up.component';
import { ConfigFieldsPopupComponent } from 'src/app/base/popup/config-fields-popup/config-fields-popup.component';
import { ManageCollectionMethodsComponent } from 'src/app/client/Templates/manage-collection-methods/manage-collection-methods.component';
import {ManageSitesComponent} from 'src/app/client/Templates/manage-sites/manage-sites.component'
import { ApprovePasswordComponent } from './components/approve-password/approve-password.component';
import {TemplatesListing2Component} from 'src/app/commoncomponents/templates-listing2/templates-listing2.component'
import {ManageSiteOrdersComponent} from 'src/app/client/Templates/manage-site-orders/manage-site-orders.component'
import {ManageSiteFormatComponent} from 'src/app/client/Templates/manage-site-format/manage-site-format.component'
import { DetailstabsComponent } from '../detailstabs/detailstabs.component';
import { LocationPreferencesComponent } from '../location-preferences/location-preferences.component';
import { UserDetailsComponent } from '../userdetails/userdetails.component';
import { CommondetailsComponent } from '../commondetails/commondetails.component';
import { ClientLocationDetailsComponent } from '../../clients/client-location-details/client-location-details.component';
import { InfodetailsComponent } from '../infodetails/infodetails.component';
import { InfopreferenceComponent } from '../infopreference/infopreference.component';
import { InfonpiComponent } from '../infonpi/infonpi.component';
import { InfoassociationComponent } from '../infoassociation/infoassociation.component';
import { InfoattachmentComponent } from 'src/app/infoattachment/infoattachment.component';
import { InfofaxComponent } from 'src/app/infofax/infofax.component';
import { LocationInfoDetailsComponent } from '../location-info-details/location-info-details.component';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';

import { ManageworklistComponent } from '../../../manageworklist/manageworklist.component';
import { CassetteTemplatesComponent } from 'src/app/client/Templates/cassette-templates/cassette-templates.component';
import { ManageCustomFieldsComponent } from 'src/app/client/Templates/manage-custom-fields/manage-custom-fields.component';
import { HotkeyConfigurationComponent} from 'src/app/client/Templates/hotkey-configuration/hotkey-configuration.component'
import { TemplateReorderComponent } from 'src/app/commoncomponents/template-reorder/template-reorder.component';
import { CustomDatetimeComponent } from './components/custom-datetime/custom-datetime.component';
import { DistributeEmailComponent } from './components/distribute-email/distribute-email.component';
import { ShimmerModule } from 'src/app/shimmer/shimmer.module';
import { StatisticMasterDataComponent } from 'src/app/client/Templates/statistic-master-data/statistic-master-data.component';
import { PathologistmanagenmentComponent } from '../../lab/pathologistmanagenment/pathologistmanagenment/pathologistmanagenment.component';
import { CustomDropdownCheckboxComponent } from './components/custom-dropdown-checkbox/custom-dropdown-checkbox.component';
import { DisplaySelectedValueDirective } from './directives/display-selected-value.directive';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { CustomInputChipComponent } from './components/custom-input-chip/custom-input-chip.component';
import { ReplaceStringPipe } from './pipes/replace-string.pipe';
import { DndDirective } from 'src/app/core/utility/dnd.directive';

@NgModule({
  declarations: [
    MattabnavComponent,
    SubsidemenuComponent,
    TabnavComponent,
    TemplatesListingComponent,
    TemplatesListing2Component,
    TemplatesViewComponent,
    searchFilterPipetemplate,
    FilteredSelectorComponent,
    CustomInputComponent,
    CustomSelectComponent,
    NpiComponent,
    SurgicenterAssociationComponent,
    FileDragDropDirective,
    InstructionsEditorComponent,
    LabusersgridComponent,
    AttachmentsComponent,
    FaxComponent,
    FormatDatePipe,
    ViewAttachmentComponent,
    ManageGrossTemplatesComponent,
    ManageAutoAssignmentRulesComponent,
    CommonOptionsComponent,
    ManageRejectionCommentsComponent,
    DeletionTemplatesComponent,
    CustomAbbreviationsComponent,
    AdequacyCodesComponent,
    CaseCommentsComponent,
    MicroscopicCodesComponent,
    ScreeningCommentsComponent,
    DiagnosisReferencesComponent,
    SequesterTemplatesComponent,
    ReviseTemplatesComponent,
    ManageCategoriesComponent,
    ManagePasswordPolicyComponent,
    ManageCustomListingComponent,
    NewBulkUploadTestsComponent,
    TooltipEllipsisDirective,
    UserBulkUploadComponent,
    ManageReflexRulesComponent,
    NotAvailableDirective,
    TubetypeTemplatesComponent,
    ManagePathologistPrivilegeComponent,
    ConfirmLabadminComponent,
    NomenclatureTemplatesComponent,
    CorelationTemplatesComponent,
    ShortenTextPipe,
    StripHtmlPipe,
    BulkUploadPopUpComponent,
    SendoutReasonsComponent,
    HtmlFilterPipe,
    NewDiagnosisTemplatesComponent,
    CommonBulkFunctionsComponent,
    AdminContactInfoPopUpComponent,
    ConfigFieldsPopupComponent,
    ManageCollectionMethodsComponent,
    ManageSitesComponent,
    ApprovePasswordComponent,
    ManageSiteOrdersComponent,
    ManageSiteFormatComponent,
    DetailstabsComponent,
    UserDetailsComponent,
    CommondetailsComponent,
    ClientLocationDetailsComponent,
    InfodetailsComponent,
    InfonpiComponent,
    InfopreferenceComponent,
    ManageCustomFieldsComponent,
    InfoassociationComponent,
    InfoattachmentComponent,
    InfofaxComponent,
    LocationInfoDetailsComponent,
    LocationPreferencesComponent,
    MenuFlagsConfigurationComponent,
    ManageworklistComponent,
    CassetteTemplatesComponent,
    HotkeyConfigurationComponent,
    TemplateReorderComponent,
    CustomDatetimeComponent,
    DistributeEmailComponent,
    StatisticMasterDataComponent,
    PathologistmanagenmentComponent,
    CustomDropdownCheckboxComponent,
    DisplaySelectedValueDirective,
    HighlightTextPipe,
    CustomInputChipComponent,
    ReplaceStringPipe,
    DndDirective
  ],
  exports: [
    MattabnavComponent,
    SubsidemenuComponent,
    TabnavComponent,
    TemplatesListingComponent,
    TemplatesListing2Component,
    TemplatesViewComponent,
    ManageGrossTemplatesComponent,
    ManageAutoAssignmentRulesComponent,
    FilteredSelectorComponent,
    CustomInputComponent,
    CustomSelectComponent,
    SurgicenterAssociationComponent,
    FileDragDropDirective,
    sharedModule,
    InstructionsEditorComponent,
    searchFilterPipetemplate,
    LabusersgridComponent,
    AttachmentsComponent,
    FormatDatePipe,
    ManageRejectionCommentsComponent,
    DeletionTemplatesComponent,
    CustomAbbreviationsComponent,
    AdequacyCodesComponent,
    CaseCommentsComponent,
    MicroscopicCodesComponent,
    ScreeningCommentsComponent,
    DiagnosisReferencesComponent,
    SequesterTemplatesComponent,
    ReviseTemplatesComponent,
    ManageCategoriesComponent,
    CommonOptionsComponent,
    ManagePasswordPolicyComponent,
    TooltipEllipsisDirective,
    ManageCustomListingComponent,
    ManageReflexRulesComponent,
    NotAvailableDirective,
    TubetypeTemplatesComponent,
    UserBulkUploadComponent,
    CommonOptionsComponent,
    ManagePasswordPolicyComponent,
    ManageCustomListingComponent,
    ManagePathologistPrivilegeComponent,
    ConfirmLabadminComponent,
    NomenclatureTemplatesComponent,
    CorelationTemplatesComponent,
    NewBulkUploadTestsComponent,
    ShortenTextPipe,
    NgxEditorModule,
    StripHtmlPipe,
    SendoutReasonsComponent,
    HtmlFilterPipe,
    NewDiagnosisTemplatesComponent,
    CommonBulkFunctionsComponent,
    ConfigFieldsPopupComponent,
    ManageCollectionMethodsComponent,
    ManageSitesComponent,
    ManageSiteOrdersComponent,
    ManageSiteFormatComponent,
    DetailstabsComponent,
    CommondetailsComponent,
    UserDetailsComponent,
    ClientLocationDetailsComponent,
    CassetteTemplatesComponent,
    ManageCustomFieldsComponent,
    MenuFlagsConfigurationComponent,
    ManageworklistComponent,
    CassetteTemplatesComponent,
    TemplateReorderComponent,
    DistributeEmailComponent,
    HotkeyConfigurationComponent,
    StatisticMasterDataComponent,
    PathologistmanagenmentComponent,
    CustomDropdownCheckboxComponent,
    DisplaySelectedValueDirective,
    HighlightTextPipe,
    CustomInputChipComponent,
    ReplaceStringPipe,
    DndDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    AppMaterialModule,
    sharedModule,
    RouterModule.forChild([]),
    AngularEditorModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxExtendedPdfViewerModule,
    sharedModule,
    WjGridFilterModule,
    WjInputModule,
    WjCoreModule,
    WjGridModule,
    ShimmerModule,
    NgxEditorModule,
  ],
  providers: [provideNgxMask(), SubsidemenuComponent],
})
export class ShareModule { }
