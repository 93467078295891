import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { customListing } from 'src/app/client/Templates/manage-custom-listing/manage-custom-listing.component';
import { openDialogIgnoreKeysActionBtn } from 'src/app/client/Templates/OrgCompareActionBtn/OrgCompareActionBtn.component';
import { environment } from '../../../environments/environment';
import { CardQueries } from '../../model/card-query';
import { UserSession } from './user.session';
@Injectable({
  providedIn: 'root',
})

export class VitalHttpServices {
  public result: any;
  public mainQueryList: MainQuery[] = [];
  public mainQuery: string;
  public vitalDXUrls: string = '';
  public reportlinks: string = '';
  public DeploymentKeys;
  public Additionaldeployment;
  public mutationQueryList: MainQuery[] = [];
  public deploymentKey: string = '';
  public accountCaseTypeId: string = '';
  public locationDropdownValues: any;
  public locationAssociationTypeList: any;
  public locationServiceList: any;
  _router;
  public LoginapiURL: string = environment.loginapiURL;
  public BridgeApiURL: string = environment.VitalBridgeApiURL;
  //public apiURL: string = "http://localhost:5005/api";
  public apiURL: string = environment.elasticapiURL;
  public SubmenuAction: any;
  public CasemenuAction: any
  public Action: any;
  public reporturl = environment.ReportUrl;
  public baseUrl2 = environment.baseUrl2;
  public allowAllUsersRole = environment.DefaultRole;
  private loginPopup: any;
  public UserMenuAction: any;
  public SsoType: string = 'SoloLaunch';
  public sssodata: any;
  public auditUsers:any;
  public OrgaAppUrl : any="";
  public Templatedata : any=[];
  public labTemplateData :any='';
  public CaseTypeDisplayName : any="";

  //public url : string =  environment.localUrl;
  isFunctionAPI: boolean = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  options = {
    headers: this.httpHeaders
  };
  private subject = new Subject<any>();
  casetypeList: any;
  Admin20: any;

  logOut:any;
  reporbuilderlaunch: any;
  vitalInsights: any;
  interfacelaunchurl: any;
  checkDeploymentLevelAdmin: boolean=false;
  patientUrl: any;
  instrumentUrl: any;

  constructor(
    public httpClient: HttpClient,
    public usersession: UserSession,
    private ngxService: NgxUiLoaderService,
    private _snackbar: MatSnackBar,
    private router: Router,
    public OrgComparedialog: MatDialog,
    public cookieService: CookieService
  ) {
    this._router = this.router;
  }

  async getAllQueries() {
    let query = new CardQueries().mainQuery;
    this.mainQuery = query;

    this._router = this.router;
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: null,
    };
    //this.ngxService.start();
    this.GetData(queryRequest).subscribe((result) => {
      if (!result.errors) {
        this.ngxService.stop();
        this.mainQueryList = result.data.Card;
        sessionStorage.setItem('GraphqlMainQuery', btoa(JSON.stringify(this.mainQueryList)));

      } else {
        this.ngxService.stop();
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      }
    }, (error) => {
      this.ngxService.stop();
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      console.error(error);
    }
    );
  }

  async getURLs() {
    const _this=this;
    _this.ngxService.start();
    await this.GetAllUrls().toPromise().then(
      (result:any) => {
      _this.ngxService.stop();
      if (!result.errors) {
        if(this.SsoType == 'SoloLaunch'){
          _this.DeploymentKeys = result.DeploymentKeys;
        }
        _this.vitalDXUrls = result.vitalDXUrls;
        _this.reportlinks = result.reportlinks;
        _this.Admin20 = result.Admin20;
        _this.logOut=result.logOutReportBuilder;
        _this.reporbuilderlaunch=result.ReportBuilderLaunch;
        _this.vitalInsights=result.VitalInsights;
        _this.interfacelaunchurl=result.InterfaceLanunch;
        _this.checkDeploymentLevelAdmin=result.DeploymentLevelAdmin?.toLowerCase()=='true' ? true :false;
        _this.patientUrl = result.PatientUrl;
        _this.instrumentUrl = result.InstrumentUrl;
      } else {
        _this._snackbar.open('An error occurred while processing your request', 'Failed');
      }
      }
  ).catch((error)=>{
    _this.ngxService.stop();
      console.error(error)
      _this._snackbar.open('An error occurred while processing your request', 'Failed');
  })
  }
 //check audit user is there or not If it's not there create a new one
  checkAuditUsers(UserEmail){
  this.ngxService.start();
   this.checkAdiutUserlist(UserEmail).subscribe((AuditUserlist => {
   this.ngxService.stop();
   if (AuditUserlist) {
    this.auditUsers = AuditUserlist;

   } else {
     this._snackbar.open('An error occurred while processing your request', 'Failed');
   }
 }), error => {
   this.ngxService.stop();
   console.error(error);
   this._snackbar.open('An error occurred while processing your request', 'Failed');
 })
}

  checkAdiutUserlist(UserEmail){
  return this.httpClient.get(
    `${this.baseUrl2}api/angularurl/CheckAuditUser?email=${UserEmail}`,
    { params: { 'Conn': 'config' } }
  );
 }
  GetDxurlBasedOnDeployment(deployid) {
    let deployment = deployid.toLowerCase();
    let deployUrl;
    var obj = Object.entries(this.vitalDXUrls);
    for (var i = 0; i < obj.length; i++) {
      let str = obj[i].toString();
      var arr = str.split(',');
      var dep = arr[0].toLowerCase();
      if (dep == deployment) {
        deployUrl = arr[1];
        break;
      }
    }

    return deployUrl;
  }



  GetReportLinksBasedOnDeployment(deployid) {
    let deployment = deployid.toLowerCase();
    let deployUrl;
    var obj = Object.entries(this.reportlinks);
    for (var i = 0; i < obj.length; i++) {
      let str = obj[i].toString();
      var arr = str.split(',');
      var dep = arr[0].toLowerCase();
      if (dep == deployment) {
        deployUrl = arr[1];
        break;
      }
    }
    return deployUrl;
  }

  //assigning the value to subject
  setConflictData(data: any) {
    this.subject.next({ data: data });
  }

  //retreiving the value
  getConflictData(): Observable<any> {
    return this.subject.asObservable();
  }

  GetData(queryRequest: any, dbName?: string): Observable<any> {
    if (sessionStorage.getItem('deploymentKey') !== 'configdb') {
      sessionStorage.setItem('deploymentKey',
        this.deploymentKey.trim() == '' ? sessionStorage.getItem('deploymentKey') : this.deploymentKey.trim());
      sessionStorage.setItem('DeploymentKey',
        this.deploymentKey.trim() == '' ? sessionStorage.getItem('deploymentKey') : this.deploymentKey.trim());
    }
    if (queryRequest && queryRequest.Query && queryRequest.Query != "") {
      if (queryRequest.Variables != undefined) {
        if (queryRequest.Variables.CardName != undefined) {
          if (
            queryRequest.Variables.CardName.toLowerCase() == 'users' ||
            queryRequest.Variables.CardName.toLowerCase() == 'physician' ||
            queryRequest.Variables.CardName.toLowerCase() == 'pathologist')
            this.usersession.query = queryRequest;
        }
      }
    }
    let url: string = environment.serverURL;
    if (dbName)
      return this.httpClient.post<any>(url, queryRequest, { params: { 'Conn': dbName } });
    else
      return this.httpClient.post<any>(url, queryRequest);
  }

  public GetconfigQuery() {
    let query: string = this.mainQueryList.filter(x => x.Keyword == "GetVitalAdminUserRoles")[0].Query.toString();
    return query;
  }
  GetDxUseridBasedOnDeployment(/*deployment*/) {
    var query = this.mainQueryList.filter(x => x.Keyword == "GetUserRoleDetails");
    let queryRequest: any = {
      "OperationName": null,
      "Query": query[0].Query,
      "Variables": { "email": localStorage.getItem("UserEmail") }
    };
    return queryRequest;
  }

  GetIsMedicalDirectorExists(OrganizationId, UserId) {
    return this.httpClient.get<string>(
      `${this.baseUrl2}api/user/IsMedicalDirectorExists?OrganizationId=` +
      OrganizationId + '&iUserId=' + UserId);
  }

  GetResetPassword(loginId, modifiedby, dbName) {
    return this.httpClient.get<string>(
      `${this.baseUrl2}api/user/resetpassword?loginid=` + loginId + '&ModifiedBy=' + modifiedby,
      { params: { 'Conn': dbName } }
    );
  }

  GetAllUrls() {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/angularurl/GetAllAngularURLList`, { params: { 'Conn': 'configdb' } }
    );
  }
  Encrypt(deploymentKey,userEmail,organizationId,accountId) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/reportbuilder/Encrypt?deploymentKey=`+ deploymentKey + '&organizationId=' + organizationId + '&emailid='  + userEmail + '&accountID='+ accountId, { params: { 'Conn': deploymentKey } }    );
  }

  EncryptInsigths(UserEmail){
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/insightlaunch/Encrypt?email=`+ UserEmail
    );
  }

  public UnlockUser(loginId, UserId, modifiedBy) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/unlockaccount?loginid=` +
      loginId +
      '&userid=' +
      UserId
      + '&modifiedby='
      + modifiedBy,
      null
    );
  }

  migrateUser(requestData, dbName) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/user/UserMigration`, requestData, { params: { 'Conn': dbName } });
  }

  validateMigrateUser(requestData, dbName) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/user/ValidateMigration`, requestData, { params: { 'Conn': dbName } });
  }

  // to send a password link
  sendResetPwdLink(data) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/user/resetpasswordlink`, data)
  }

  public ApproveRequestPassword(obj) {
    return this.httpClient.post(`${this.baseUrl2}api/user/ApproveRequest`, obj);
  }

  getAllowedDomain(guid){
    return this.httpClient.get<any>(`${this.baseUrl2}api/user/GetAllowedDomain?organizationguid=${guid}`)
  }
  exportLab(casetype, org_id, accountid) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/ImpExpJson/export?casetype=${casetype}&organizationID=${org_id}&accountid=${accountid}`
    );
  }
  public ImportLab(Data: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl2}api/ImpExpJson/import`, Data);
  }

  grossTemplateAction(Data: any) {
    return this.httpClient.post(`${this.baseUrl2}api/grosstemplates/action`, Data);
  }

  bulkUploadTests(testDataArray,depKey?) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUploadTests`, testDataArray
      , { params: { 'Conn': depKey } }
    );
  }

  updateTests(testDataArray,depKey? : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UpdateAccountProcedure`, testDataArray
      , { params: { 'Conn': depKey } }
    );
  }

  // Cron Validation
  cronValidation(obj) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/CronValidation/Validation`, obj);
  }

  public AddIPAddress(IpData: any, dbName: string): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl2}api/UserIP/PostUserIPAddress`,
      IpData,
      { params: { 'Conn': dbName } }
    );
  }
  // public UpdateHL7LogStatus(jsondata, dbName: string) {
  //   return this.httpClient.post(
  //     `${this.baseUrl2}api/HL7Log/updatestatus/`,
  //     jsondata,
  //     { params: { 'Conn': dbName } }
  //   );
  // }

  public ConfigureVitalDesktop(jsondata, dbName: string) {
    return this.httpClient.post(
      `${this.baseUrl2}api/VDID/configurevitaldekstop/`, jsondata, { params: { 'Conn': dbName } });
  }
  public Authentication(obj) {
    return this.httpClient.post(`${this.LoginapiURL}Login`, obj);
  }

  public Renew() {
    return this.httpClient.post(`${this.LoginapiURL}renew`, '');
  }
  public getADUserRoles(data) {
    let httpOptions: any = {
      withCredentials: true
    }
    return this.httpClient.get<any>(`${this.LoginapiURL}getADUserRoles?user=` + data, httpOptions);
  }

  public logoutAPI() {
    return this.httpClient.get<any>(`${this.LoginapiURL}logout`);
  }

  public reportBuliderLogut()
  {
    return this.httpClient.get<any>(`${this.logOut}`);
  }
  public logout() {
    this.logoutAPI().subscribe(resData => {
      if (!resData.errors) {
        if (resData.statusCode == 200) {
          let userid = localStorage.getItem("UserEmail");
          if (userid || userid != "") {
            localStorage.clear();
            sessionStorage.clear();
          }
          this.cookieService.deleteAll('/', environment.domain);
          this.usersession.timeOut = 0;
          this.logoutFusionAuth();
          return;
        }
      }
      this._snackbar.open('An error occurred while processing your request', 'Failed')
    }, error => {

      console.error(error);
      window.location.href = environment.client;
    })
  }

  logoutFusionAuth() {
    //add the client to fusion auth to logout
    window.location.href = environment.fusionauth + "logout?client_id=" + environment.clientid + "&post_logout_redirect_uri=" + environment.client;

    // window.location.href = environment.fusionauth + "logout?client_id=" + environment.clientid + "&post_logout_redirect_uri=" + environment.client + "%23/login";
    //window.location.href = environment.client;
    //this.router.navigate(['/login']);
  }

  getIPAddress() {
    return this.httpClient.get(environment.IPAddressURL);
  }

  logintoVO(payload) {
    return this.httpClient.post<any>(
      `${this.apiURL}api/LoginAuth/AuthenticateUser`, payload
    );
  }



  public EntityDetails(): Observable<any> {
    sessionStorage.setItem('deploymentKey', this.deploymentKey.trim());
    if (this.isFunctionAPI) {
      return this.httpClient.get(
        `${this.apiURL}/GetEntityDetails?code=IWjPEwBJKd9yIHSBIUev8xQQsBa1B0lsinGa7MWuvlkKytTcyElwJQ==`, { params: { 'Conn': 'configdb' } }
      );
    } else {
      //call to pass through NRecoGraphQL API
      return this.httpClient.get(`${this.baseUrl2}api/elasticsearch/GetEntityDetails`);
      //return this.httpClient.get(`${this.apiURL}/GetEntityDetails`);
    }
  }

  //api endpoint not found
  public ContinueSearch(ContinuationSearchToken: any) {
    if (this.isFunctionAPI)
      return this.httpClient.post(
        `${this.apiURL}/ContinueSearch?code=4gGMyuYAOLxq8mFn4TXZIm8noLMA9bD4f6F7enXGAaZnawzoK8WaDg==`,
        ContinuationSearchToken
      );
    else
      return this.httpClient.post(`${this.apiURL}/ContinueSearch/`, ContinuationSearchToken
      );
  }

  //api endpoint not found
  public SearchEntityNew(Keyword: string, SearchDetails: any): Observable<any> {
    if (this.isFunctionAPI)
      return this.httpClient.post(
        `${this.apiURL}/Entity/` +
        Keyword +
        '?code=7X6/uBpvREomCfoLPKhc2oiecOfOE2CJKN1RcLIxl41SJj7PonlP4w==',
        SearchDetails
      );
    else
      return this.httpClient.post(
        `${this.apiURL}/Entity/` + Keyword,
        SearchDetails
      );
  }

  public SearchEntity(Keyword: string, SearchDetails: any): Observable<any> {
    if (this.isFunctionAPI)
      return this.httpClient.post(
        `${this.apiURL}/GetEntityResults/` +
        Keyword +
        '?code=7X6/uBpvREomCfoLPKhc2oiecOfOE2CJKN1RcLIxl41SJj7PonlP4w==',
        SearchDetails
      );
    else
      return this.httpClient.post(
        //call to pass through NRecoGraphQL API
        `${this.baseUrl2}api/elasticsearch/GetEntityResults/`,
        SearchDetails);
    /*return this.httpClient.post(
      `${this.apiURL}/GetEntityResults/`,
      SearchDetails
    );*/
  }

  ChangeUserStatus(obj) {
    return this.httpClient.post(
      `${this.baseUrl2}api/user/userStatusUpdate/`,
      obj
    );
  }

  // vitalBridge methods
  //Login API method
  VitalBridgeLogin(obj) {
    return this.httpClient.post(`${this.BridgeApiURL}api/login`, obj);
  }

  // getDashboard method
  VitalBridgeDashboardDetails(entityid) {
    return this.httpClient.get<any>(
      `${this.BridgeApiURL}api/getdashboardetailsaccid?entityid=${entityid}`
    );
  }
  //getuserslist
  GetUserslist(id, card, dbname? : string ) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/user/GetUsersListController?id=${id}&CardType=${card}`, { params: { 'Conn': dbname } }
    );
  }

  //export user list by account Id
  GetUserslistExport(id) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/user/GetUsersListExport?id=${id}`
    );
  }

  //export user details by common key
  GetUserDetailsExport(commonKey?, id?) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/user/GetUserDetailsExport?commonKey=${commonKey}&id=${id}`
    );
  }

  //UserUpdate
  UpdateAdminUser(id) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/user/UpdateAdminUser?id=${id}`
    );
  }
  // getEntity method
  VitalBridgeEntityDetails(params) {
    return this.httpClient.get<any>(
      `${this.BridgeApiURL}api/agentsearch?type=&index=${params.index}&size=${params.size}&agentid=&entityid=${params.entityid}&orgid=`
    );
  }

  //downloadreportTemplate
  DownloadExistTemplate(filepath) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReportGenerate/Readfile`,
      filepath
    );
  }

  //getreporttempatename
  getReportTemplateName(obj,dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReportGenerate/GetTemplateName`,
      obj,{ params: { 'Conn': dbName }}
    );
  }

  //downloadreportTemplate
  ApplyTempToDB(fileDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReportGenerate/ApplyNewTemplate`,
      fileDetails
    );
  }
  //getEncryteddata
  GetEncryptedData(caseid) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/ReportGenerate/GetEncryptedData?caseid=${caseid}`
    );
  }

  RecordAudit(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/audit/caseaudit`, obj
    );
  }

  GetExecuteQueryDetails(obj, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ExecuteSql/PostExecuteQuery`,
      obj,
      { params: { 'Conn': dbName } }
    );
  }

  getCasetypeDetails(organizationid, accountid, dbName?) {
    let object = { "organizationid": organizationid, "accountid": accountid }
    return this.httpClient.post<any>(`${this.baseUrl2}api/addorg/GetCaseTypesList`, object,{ params: { 'Conn': dbName } });
  }


  getNewFacilityCasetypeDetails(accIDlist) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/addFacility/GetCaseTypesList?accIdList=${accIDlist}`);
  }

  createNewFacility(data) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/addFacility/GetAddNewOf`, data);
  }

  validateLocationCode(data,dbName:string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/validatelocations`, data,{ params: { 'Conn': dbName } });
  }

  setupNewEntities(data) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/setupNewEntities/GetSetupNewEntities`, data);
  }

  GetUserRoleInfo(Id, Email, cardType) {
    if (sessionStorage.getItem('deploymentKey') !== 'configdb') {
      sessionStorage.setItem('deploymentKey',
        this.deploymentKey.trim() == ''
          ? sessionStorage.getItem('deploymentKey')
          : this.deploymentKey.trim()
      );
      sessionStorage.setItem(
        'DeploymentKey',
        this.deploymentKey.trim() == ''
          ? sessionStorage.getItem('deploymentKey')
          : this.deploymentKey.trim()
      );
    }
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/user/GetRolePermission?Id=${Id}&UserEmail=${Email}&CardType=${cardType}`);
  }
  //execute server obj query
  GetExecuteSrvObjDetails(obj, dbName: string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ExecuteSql/PostExecuteSrvObj`, obj, { params: { 'Conn': dbName } });
  }

  //previewFile
  previewExistingFile(formdata) {
    //sessionStorage.setItem( 'deploymentKey',this.deploymentKey);
    return this.httpClient.post<any>(
      `${this.reporturl}api/ReportGenerate/v2/GenerateReport`,
      formdata
    );
  }
  uploadGitFileToServer(formdata) {
    //sessionStorage.setItem( 'deploymentKey',this.deploymentKey);
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReportGenerate/UploadToServer`,
      formdata
    );
  }
  AddTemplates(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/addtemplates`,
      obj
    );
  }
  UpdateTemplate(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/updatetemplate`,
      obj
    );
  }

  DeleteTemplate(tempId) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/vdtemplate/deletetemplate?templateid=${tempId}`
    );
  }

  DeleteUser(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/userDelete`, obj
    );
  }

  //save add user details to org
  SaveAddUserToOrg(userDetails, deploymentKey?) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/SaveUser`,
      userDetails, { params: { 'Conn': deploymentKey } }
    );
  }

  PopulateCaseList(userDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/PopulateCaseList`,
      userDetails
    );
  }

  PopulateASCCases(userDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/PopulateASCCases`,
      userDetails
    );
  }
  DeleteRevisionReason(obj, dbName: string) {
    return this.httpClient.post<string>(
      `${this.baseUrl2}api/revisionreason/deletereason`,
      obj,
      { params: { 'Conn': dbName } }
    );
  }

  genereateJSON(AccDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/compare/GenereateJSON`,
      AccDetails
    );
  }

  updateMailRecipient(UpdateMailRecipientDetails, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MailRecipients/UpdateMailRecipients`,
      UpdateMailRecipientDetails,
      { params: { 'Conn': dbName } }
    );
  }

  saveMailRecipient(SaveMailRecipientDetails, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MailRecipients/SaveMailRecipients`,
      SaveMailRecipientDetails,
      { params: { 'Conn': dbName } }
    );
  }

  ConvertByte(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/ConvertByte`,
      Data
    );
  }

  GetSignFile(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/GetSignFile`,
      Data
    );
  }

  ConfirmUploadSign(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/UploadUserSignature`,
      Data
    );
  }

  GetLogoFile(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/GetLogoFile`,
      Data
    );
  }

  ConfirmLogoUpload(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/UploadAccountLogo`,
      Data
    );
  }

  RemoveLogo(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/RemoveAccountLogo`,
      Data
    );
  }

  RemoveSign(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UploadImage/RemoveUserSignature`,
      Data
    );
  }

  GetAssociatedOrgGuidForAccId(accid, associationtype) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/VDInstallations/GetAssociatedOrgGuidForAccId?accid=` +
      accid + '&associationtype=' + associationtype
    );
  }

  UpdateOrganizationScheduleReport(reportDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/UpdateSchedulereport`,
      reportDetails
    );
  }

  UpdateOrganizationCustomReport(reportDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/UpdateCustomReport`,
      reportDetails
    );
  }
  AddnewCustomReport(reportDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/InsertCustomReport`,
      reportDetails
    );
  }

  AddnewScheduleReport(reportDetails) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/insertScheduledReport`,
      reportDetails
    );
  }
  // VD edit/update installations
  updateVDInstallations(updatedVitalDesktopData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/VDInstallations/updateVDInstallations`,
      updatedVitalDesktopData
    );
  }

  // VD add/create installations
  addVDInstallation(addVitalDesktopData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/VDInstallations/addVDInstallation`,
      addVitalDesktopData
    );
  }
  QuickCreateLab(labsetupDetails, deployment) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/labsetup/quickcreate`,
      labsetupDetails, { params: { 'Conn': deployment } }
    );
  }
  setupNewLab(labsetupDetails, deployment) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/addorg/AddOrgCaseTypes`, labsetupDetails,
      { params: { 'Conn': deployment } }
    );
  }

  getCasetypePDFData(orgid, accid, Casetype) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/addorg/GetPDFData?OrganizationID=${orgid}&AccountID=${accid}&Casetype=${Casetype}`);
  }
  deAssociateAccounts(Associationid) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/DeAssociateAccounts`,
      Associationid
    );
  }
  associateAccounts(AssociationData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/Associate`,
      AssociationData
    );
  }

  editAssociations(editData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Associations/EditAssociation`,
      editData
    );
  }

  updateassociateAccounts(AssociationData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/UpdateAssociate`,
      AssociationData
    );
  }
  getAssociatedOrganizations(Object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/GetOrganizationDetails`,
      Object
    );
  }

  GetOrgLocations(orgid, accid, associationType) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Location/GetOrgLocations?orgid=${orgid}&accid=${accid}&associationType=${associationType}`
    );
  }
  addOrgLocation(addNewLocationData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/AddLocation`,
      addNewLocationData
    );
  }
  GetAssociatedAccountsForOrg(accid, associationType) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Location/GetAssociatedAccountsForOrg?parentAccid=${accid}&associationType=${associationType}`
    );
  }

  InitiateScheduleReports(dataArray, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/schedulereport/InitiateScheduleReports`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  GetCaseTypesForSetupNewAccount(orgid, associationType, accountID) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Location/GetCaseTypesForSetupNewAccount?orgid=${orgid}&associationType=${associationType}&AccountID=${accountID}`
    );
  }
  AddAssociatedAccountCaseType(AddAssociatedAccountCaseTypeData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/AddAssociatedAccountCaseType`,
      AddAssociatedAccountCaseTypeData
    );
  }
  GetUsersForSetupNewAccount(accid, associationType) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Location/GetUsersForSetupNewAccount?accid=${accid}&associationType=${associationType}`
    );
  }
  SaveAssociatedUsers(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/SaveAssociatedUsers`, obj
    );
  }

  updateCaseComments(caseCommentsData, loggedInUserId : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageCaseComments/UpdateCaseComments?loggedInUserId=${loggedInUserId}`,
      caseCommentsData
    );
  }
  createCaseComments(caseCommentsData, loggedInUserId : string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ManageCaseComments/CreateCaseComments?loggedInUserId=${loggedInUserId}`,
      caseCommentsData);
  }
  getCaseTypeListOfOrg(orgid) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ManageCaseComments/GetCaseTypes`, orgid);
  }
  getCaseTypeSites(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageCaseComments/GetCaseTypesSites`, object
    );
  }
  // getCaseTypeforMicrocodes(orgid) {
  //   return this.httpClient.post<any>(`${this.baseUrl2}api/MicroscopicCodes/GetCaseTypes`, orgid);
  // }

  getAssociatedDataLocation(accId, dep) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Location/GetAssociatedDataLocation?accid=${accId}&deploymentKey=${dep}`
    );
  }

  // Desktop Add & Edit
  // manageVDTemplate(obj) {
  //   return this.httpClient.post<any>(
  //     `${this.baseUrl2}api/vdtemplate/manageVDTemplate`, obj
  //   );
  // }

  // VD template file upload
  UploadFile(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/uploadfile`,
      data
    );
  }

  downloadVDTemplate(filename) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/vdtemplate/DownloadFile?fileName=${filename}`, { responseType: 'blob' as 'json' }
    );
  }

  downloadStatisticsTemplate(obj):any {
    return this.httpClient.post(`${this.baseUrl2}api/Statisticreport/DownloadFile`, obj, {
      responseType: 'blob'
    });
  }

  // VD template delete row
  deleteVDTemplate(array, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/delete`, array,
      { params: { 'Conn': dbName } }
    );
  }

  checkTemplateExists(file) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/checkTemplateExists`, file
    );
  }

  //export-case-details to gendrate DML scripts
  generateDMLScript(name, caseId, isExist, dbName: string, version) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}/api/user/GenerateDMLScript/?name=${name}&Id=${caseId}&ifExists=${isExist}&version=${version}`,
      { params: { 'Conn': dbName } }
    );
  }

  // copy from other Entity
  getOrgDataForCopy(organizationID, CaseType, UserID, tableName, role, siteId, siteName, dbName: string) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/copyorg/GetOrganizationTableDataForCopyFrom?organizationID=` + organizationID + `&CaseType=` + CaseType + `&userId=` + UserID + `&tableName=` + tableName + `&role=` + role + '&siteId=' + siteId + '&siteName=' + siteName,
      { params: { 'Conn': dbName } }

    );
  }

  SaveOrgCopyData(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/copyorg/CopyToOrganization`,
      data
    );
  }

  // GetAssigneeAccountforTC -- To get the accounts for technical only service
  // PathologistAccountsByAccountID -- epg account testing
  // GetAssigneePathologies -- to list the pathologist

  // case level actions -- Service Except TechnicalOnly
  GetCaseLevelAssigneeAccounts(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseActionsController/GetAssigneeAccounts`,
      data
    );
  }

  // case level actions -- Assign to Pathologist
  GetCaseLevelAssignToPathologist(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseActionsController/AssignToPathologist`,
      data
    );
  }

  // case level actions -- Re-Assign to Pathologist
  GetCaseLevelReAssignToPathologist(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseActionsController/Re-AssignToPathologist`,
      data
    );
  }
  //reset the case
  GetCaseReset(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseActionsController/ResetCase`,
      data
    );
  }
  // case level actions -- Un-Assign the Case
  GetCaseLevelUnAssingCase(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseActionsController/UnAssingCase`,
      data
    );
  }

  // case level actions -- To check the Licensure of the Pathologist
  GetCaseLevelLicensurePathologists(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseActionsController/GetLicensurePathologists`,
      data
    );
  }

  updateSequenceCaseProfiles(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseProfileController/DragAndDropCaseProfile`, obj
    );
  }
  UpdateActiveStatusforCaseProfile(obj,dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseProfileController/UpdateCaseProfileStatus`, obj
      ,{ params: { 'Conn': dbName } }

    );
  }
  UpdateCaseProfiledisplyname(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseProfileController/UpdateCaseProfileDisplyname`, obj
    );
  }
  CreateActivityTracker(ActivityTrackerObject) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ActivityTracker/Create`,
      ActivityTrackerObject
    );
  }
  CreateActivity(ActivityTrackerObject) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ActivityTracker/Create`,
      ActivityTrackerObject
    );
  }
  SearchActivityTracker(ActivityTrackerObject) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ActivityTracker/Search`,
      ActivityTrackerObject
    );
  }
  // organizationID, CaseType, AccountID, InputType
  getValuesForInput(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/copyorg/GetValuesForInputTypes`, object, { params: { 'Conn': dbName } }
    );
  }

  SaveDisclaimer(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/SaveDisclaimer`, object
    );
  }

  DeleteDisclaimer(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/DeleteDisclaimer`, object
    );
  }
  UpdateVAEntityAttribute(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageVAEntityAttributes/UpdateVaEntityAttribute`, object,
      { params: { 'Conn': dbName } }
    );
  }


  ManageVAEntityAttribute(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageVAEntityAttributes/ManageFlagVaEntity`, object,
      { params: { 'Conn': dbName } }
    );
  }

  createVAEntityAttribute(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageVAEntityAttributes/CreateVaEntityAttribute`, object,
      { params: { 'Conn': dbName } }
    );
  }

  getVAEntityAttribute(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageVAEntityAttributes/GetVaEntityAttribute`, object,
      { params: { 'Conn': dbName } }
    );
  }
  getOtherVAEntityAttribute(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageVAEntityAttributes/GetOtherVaEntityAttribute`, object,
      { params: { 'Conn': dbName } }
    );
  }
  ValidateTestData(object,depKey? : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateTestData`, object
      , { params: { 'Conn': depKey } }
    );
  }



  ValidateBulkUserData(object,depKey? : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/ValidateBulkUserFormat`, object
      , { params: { 'Conn': depKey } }
    );
  }

  InsertBulkUserData(object, depKey? : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/InsertBulkUsers`, object
      , { params: { 'Conn': depKey } }
    );
  }

  //userstatus update
  Updateuserstatus(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/users/status`, object
    );
  }

  ValidateBulkRuleOuts(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageRuleOuts/ValidateBulkRuleOuts${dbName.match(/thx/i) ? 'p4' : ''}`, object,
      { params: { 'Conn': dbName } }
    )
  }

  InsertRuleOuts(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageRuleOuts/InsertRuleOut${dbName.match(/thx/i) ? 'p4' : ''}`, object,
      { params: { 'Conn': dbName } }
    )
  }
  //update
  UpdateRuleOuts(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageRuleOuts/UpdateRuleOuts`, object,
      { params: { 'Conn': dbName } }
    )
  }
  //delete rule out
  DeleteRuleOuts(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/manageruleouts/delete`, data, { params: { 'Conn': dbName } }
    );
  }

  //validate diagnosis upload
  ValidateBulkUploadDiagnosisTemplatesData(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkUploadDiagnosisTemplatesData`, object
    );
  }
  // validate order and result codes
  ValidateBulkUploadOrderandResultcodes(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkUploadOrderandResultcodes/Validates`, object
    );
  }


  //BulkUploadOrderandresultCodes
  BulkUploadOrderandresultCodes(testDataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUploadOrderandresultCodes/insert`, testDataArray
    );
  }


  //BulkUploadOrderandresultCodes
  BulkUpdateOrderandresultCodes(testDataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUpdateOrderandresultCodes/update`, testDataArray
    );
  }


  //validate diagnosis update
  BulkUpdateDiagnosisTemplates(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUpdateDiagnosisTemplates`, object
    );
  }

  //validate diagnosis update
  ValidateBulkUpdateDiagnosisTemplatesData(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkUpdateDiagnosisTemplatesData`, object
    );
  }

  //validate order and codes update
  ValidateBulkupdateOrderandResultcodes(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkupdateOrderandResultcodes/copy`, object
    );
  }
  //bulkUploadDiagnosisTemplate
  BulkUploadDiagnosisTemplates(testDataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUploadDiagnosisTemplates`, testDataArray
    );
  }
  //Edit DiagnosisTemplates Data
  EditDiagnosisTemplatesData(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/EditDiagnosisTemplatesData`, obj
    );
  }

  //Validate the status for Duplication templates
  ValidateDiagStatus(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateDiagStatus`, obj
    );
  }

  //bulkUploadDiagnosisTemplate
  CopyDiagnosisTemplate(DataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/diagnosistemplate/copy`, DataArray
    );
  }
  //Copy of Orderandresult
  CopyOrderandResult(DataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ordercodeandresultcode/copy`, DataArray
    );
  }

  //Edit DiagnosisTemplates ICDcode Data
  EditDiagnosisTemplatesICDcodeData(obj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/EditDiagnosisTemplatesICDcodeData`, obj
    );
  }

  // //Edit DiagnosisTemplates Site Name
  // EditDiagnosisTemplatesSiteName(obj) {
  //   return this.httpClient.post<any>(
  //     `${this.baseUrl2}api/ValidateBulkUploadCaseComments`, obj
  //   );
  // }

  //validate Bulk Upload Gross Template
  ValidateBulkUploadGrossTemplate(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkUploadGrossTemplate`, object,
      { params: { 'Conn': dbName } }
    );
  }

  // Bulk Upload Gross Template
  BulkUploadGrossTemplate(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUploadGrossTemplate`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  //validate bulkupdate data
  ValidateBUlkUpdateCaseComments(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkUpdateCaseComments`, object,
      { params: { 'Conn': dbName } }
    );
  }
  //validate case comments
  ValidateCaseComments(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateBulkUploadCaseComments`, object,
      { params: { 'Conn': dbName } }
    );
  }
  //upload copy data
  BulkUploadCaseComments(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUploadCaseComments`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  //bulkupdate case comments
  BulkUpdateCaseComments(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/BulkUpdateCaseComments`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }


  ValidateSiteData(siteData: object[]) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Sites/Validate`, siteData
    );
  }

  bulkUploadSites(siteData: object[]) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Sites/BulkAdd`, siteData
    );
  }

  updateSites(sitesData) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Sites/EditSites`, sitesData
    );
  }

  BulkUpdateSites(sitesData, dbName){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Sites/UpdateSites`, sitesData,
      { params: { 'Conn': dbName } }
    );
  }

  GetSubSiteDate(casetype, orgid, dbName) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Sites/GetSubSiteData?casetype=` + casetype + `&organisationid=` + orgid,
      { params: { 'Conn': dbName } }
    );

  }

  ValidateAdequacyData(object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/adequacycodes/validate`, object
    );
  }



  BulkUploadAdequacyCodes(dataArray, loggedInUserId: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/adequacycodes/add?loggedInUserId=${loggedInUserId}`, dataArray
    );
  }

  UpdateAdequacyCodes(dataArray, loggedInUserId: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/adequacycodes/edit?loggedInUserId=${loggedInUserId}`, dataArray
    );
  }


  validateCollectionMethodData(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CollectionMethods/Validate`, dataArray
    );
  }

  validateRevisionReasonData(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReviseTemplateReason/Validate`, dataArray
    );
  }
  validateRevisionNotesData(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReviseTemplateNotes/Validate`, dataArray
    );
  }

  bulkUploadCollectionMethods(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CollectionMethods/BulkAdd`, dataArray
    );
  }
  editCollectionMethod(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CollectionMethods/Edit`, dataArray
    );
  }
  ValidateGrossAttrData(data: object[]) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/GrossAttributes/Validate`, data
    );
  }
  bulkUploadGrossAttributes(Data: object[]) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/GrossAttributes/BulkAdd`, Data
    );
  }
  updateGrossAttributes(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/GrossAttributes/Edit`, Data
    );
  }
  getGrossAttributes(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/GrossAttributes/List`, Data
    );
  }
  getOrganizationAttributes(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/OrgAttributes/OrgAttrList`, Data,
      { params: { 'Conn': dbName } }
    );
  }

  ValidateQualityAttributes(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/qualityattributes/validate`, object,
      { params: { 'Conn': dbName } }
    );
  }

  //SuperAdmin set-config
  uploadUserAccess(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vitaladminusers/insert`, Data, { params: { 'Conn': dbName } }
    );
  }

  UpdateSuperAdmin(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vitaladminusers/update`, Data, { params: { 'Conn': dbName } }
    );
  }

  //RBAC Permission Update
  UpdatePermission(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/adminroles/permissions`, Data, { params: { 'Conn': dbName } }
    );
  }

  //RBAC Add New Role
  InsertNewRole(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/adminroles/insert`, Data, { params: { 'Conn': dbName } }
    );
  }

  //Add new user
  InsertNewUser(Data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/auditusers`, Data);
  }

  //Update role
  UpdateRole(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/adminroles/update`, Data, { params: { 'Conn': dbName } }
    );
  }

  BulkUploadQualityAttributes(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/qualityattributes/add`, object,
      { params: { 'Conn': dbName } }
    )
  }

  BulkUploadFlags(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Flags/insert`, object,
      { params: { 'Conn': dbName } }
    )
  }
  validateBulkAccounts(data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/Validate`, data,
      { params: { 'Conn': dbName } }
    );
  }
  //valiadte Bulk UPADTE OF
  validateBulkUpdateAccounts(data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/Update/Validate`, data,
      { params: { 'Conn': dbName } }
    );
  }
  bulkUploadAccountCreation(Data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/BulkAdd`, Data,
      { params: { 'Conn': dbName } }
    );
  }
  //bulk update OF
  bulkUpdateAccount(Data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/BulkUpdate`, Data,
      { params: { 'Conn': dbName } }
    );
  }

  getOrderCodes(constraints) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/addorg/GetOrderCodesList`, constraints);
  }

  UpdateQualityAttributes(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/qualityattributes/edit`, object,
      { params: { 'Conn': dbName } }
    );
  }

  UpdateFlags(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Flags/update`, object,
      { params: { 'Conn': dbName } }
    );
  }

  BulkUpdateFlags(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Flags/bulkupdate`, object,
      { params: { 'Conn': dbName } }
    );
  }

  //insert requisition profile
  InsertRequisitionProfile(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/RequisitionProfile/Add`, object,
      { params: { 'Conn': dbName } }
    );
  }

  //update requisition profile
  UpdateRequisitionProfile(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/RequisitionProfile/Edit`, object,
      { params: { 'Conn': dbName } }
    );
  }


  // Delete requisition profile
  DeleteRequisitionProfile(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/RequisitionProfile/delete`, data,
      { params: { 'Conn': dbName } }
    );
  }

  InsertDefaultStain(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/defaultstains/add`, object,
      { params: { 'Conn': dbName } }
    );
  }

  UpdateDefaultStain(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/defaultstains/edit`, object,
      { params: { 'Conn': dbName } }
    );
  }

  GetConfigDefaultStain(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/defaultstains/config`, object,
      { params: { 'Conn': dbName } }
    );
  }


  bulkCustomReportValidation(Data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/Validate`, Data,
      { params: { 'Conn': dbName } }
    );
  }
  bulkCustomReportUpload(Data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/BulkAdd`, Data,
      { params: { 'Conn': dbName } }
    );
  }
  updateCustomReportCategory(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/StatisticReport/CategoryAdd`, Data,
      { params: { 'Conn': dbName } }
    );
  }


  UpdateCaseTypeDetails(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Casetype/Edit`, object
    );
  }

  deleteAssociation(id, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Associations/DeleteAssociation`, id,
      { params: { 'Conn': dbName } }
    );
  }
  addOrganizationAttributes(Data: object[], dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/OrganizationAttributes/Add`, Data,
      { params: { 'Conn': dbName } }
    );
  }
  updateOrganizationAttributes(Data, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/OrgAttributes/Edit`, Data,
      { params: { 'Conn': dbName } }
    );
  }

  validateOrgAttributes(dataArray, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/OrgAttributes/Validate`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  getLookUpData(dataSource, organizationid, casetype, accountid, dbName) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/OrgAttributes/GetLookupData?paramslist=` + dataSource + `&paramslist=` + organizationid + `&paramslist=` + casetype + '&paramslist=' + accountid,
      { params: { 'Conn': dbName } }
    )
  }

  editRevTemplates(dataArray,dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReviseTemplate/update`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  deleteRevtemplate(Id, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReviseTemplate/delete`, Id,
      { params: { 'Conn': dbName } }
    );
  }

  deleteRevtemplateNotes(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReviseTemplate/deleteNotes`, obj,
      { params: { 'Conn': dbName } }
    );
  }

  InsertRevReasons(data, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReviseTemplate/insert`, data,
      { params: { 'Conn': dbName } }
    );
  }
  //ScreeningComments
  editScreeningComments(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningComments/edit`, dataArray,
    );
  }
  InsertScreeningComments(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningComments/add`, data
    );
  }
  deletescreeningComments(Id, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningComments/delete`, Id,
      { params: { 'Conn': dbName } }
    );
  }
  validateScreeningCommentsData(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/screeningComments/Validate`, dataArray
    );
  }

  // Start UDF Changes
  UpdateEntities(data) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/addFacility/EditOrderingFacility`, data);
  }

  // Start UDF Changes
  UpdateMandatoryfieldsOnCaseType(obj, dbName) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/SaveMandateField`, obj, { params: { 'Conn': dbName } })
  }

  GetMandatoryfieldsOnCaseType(obj, dbName) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/GetMandateData`, obj, { params: { 'Conn': dbName } });
    //return this.httpClient.get<any>(`${this.baseUrl2}api/GetMandateData?accId=${acid}&caseType=${casetype}`, { params: { 'Conn': dbName } });
  }

  GetMandatoryfields(dbName) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/GetMandateDataForAll`, { params: { 'Conn': dbName } });
  }

  // Start UDF Changes
  ValidateUDFData(UDFData: object[], dbName: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UDF/Validate`, UDFData, { params: { 'Conn': dbName } }
    );
  }

  bulkUploadUDF(UDFData: object[], dbName: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UDF/Add`, UDFData, { params: { 'Conn': dbName } }
    );
  }

  GetUDFParents(data, dbName) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/UDF/GetParents`, data, { params: { 'Conn': dbName } });
  }

  GetUDFDisplayTypes(dbName) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/UDF/GetDisplayTypes`, { params: { 'Conn': dbName } });
  }

  GetUDFRefCodes(orgid, dbName) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/UDF/GetRefCodes?OrganizationId=${orgid}`, { params: { 'Conn': dbName } });
  }

  UpdateUDF(UDFData: object[], dbName: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UDF/Edit`, UDFData, { params: { 'Conn': dbName } }
    );
  }

  getAccountType(UDFData: object, dbName: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/GetLiveOrTestAccount`, UDFData, { params: { 'Conn': dbName } }
    );
  }


  // End UDF Changes

  validateIdentityFormats(data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Identityformat/validate`, data,
      { params: { 'Conn': dbName } }
    );
  }

  bulkUploadIdentityFormats(data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Identityformat/add`, data,
      { params: { 'Conn': dbName } }
    );
  }

  EditIdentityFormats(data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Identityformat/edit`, data,
      { params: { 'Conn': dbName } }
    );
  }

  DeleteIdentityFormats(data: object[], dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Identityformat/delete`, data,
      { params: { 'Conn': dbName } }
    );
  }

  validateMicroCodes(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MicroscopicCodes/Validate`, data,
      { params: { 'Conn': dbName } }
    );
  }

  bulkUploadmicroCodes(data, dbName: string, loggedInUserId: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MicroscopicCodes/Add?loggedInUserId=${loggedInUserId}`, data,
      { params: { 'Conn': dbName } }
    );
  }

  editMicroCodes(data, dbName: string, loggedInUserId: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MicroscopicCodes/Edit?loggedInUserId=${loggedInUserId}`, data,
      { params: { 'Conn': dbName } });

  }

  deleteMicroCodes(data, dbName: string, loggedInUserId: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MicroscopicCodes/delete?loggedInUserId=${loggedInUserId}`, data,
      { params: { 'Conn': dbName } }
    );
  }

  getUserDefaultRoles(data, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UserDefaultRoles/List`, data,
      { params: { 'Conn': dbName } }
    );
  }
  deleteUDF(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UDF/delete`, data,
      { params: { 'Conn': dbName } }
    );
  }

  editUserlevelcomments(data, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageOrgtoUser/Update`, data,
      { params: { 'Conn': dbName } });
  }

  public GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  private OrgDataSource = new BehaviorSubject<any>({});
  SharedOrgCompareData = this.OrgDataSource.asObservable();
  SetOrgData(data: any) {
    this.OrgDataSource.next(data);
  }

  orgCompareBlockLevelMenu: string = '';
  openDialog(): Observable<any> {
    let data = {
      menu: this.orgCompareBlockLevelMenu,
      showIgnore: false,
    };
    const dialogRef = this.OrgComparedialog.open(
      openDialogIgnoreKeysActionBtn,
      {
        data: data,
        disableClose: true,
        hasBackdrop: true,
        autoFocus: false,
      }
    );

    return dialogRef.afterClosed();
  }

  openLoginPopUp() {
    if (!this.loginPopup || (this.loginPopup && this.loginPopup.closed)) {
      let userlogged = localStorage.getItem("UserEmail");
      this.Authentication({ "loginid": userlogged }).subscribe(data => {
        let resdata: any = JSON.parse(JSON.stringify(data));
        if (resdata && resdata["result"] && resdata["result"] != "") {
          if (!this.loginPopup || (this.loginPopup && this.loginPopup.closed)) {
            let height = 500, width = 400;
            let left = (window.screen.width / 2) - ((width / 2) + 10);
            let top = (window.screen.height / 2) - ((height / 2) + 50);

            this.loginPopup = window.open(resdata["result"], "VitalAdmin - Login",
              "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
              + left + ",top=" + top + ",screenX=" + left + ",screenY="
              + top + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");


            if (this.loginPopup) {
              this.loginPopup.onload = function () {
                if (!(this.loginPopup.innerHeight > 0)){
                  alert("A popup blocker may be preventing the application from opening the page. If you have a popup blocker, try disabling it.");}
                else{
                  this.loginPopup.close();
                }
              }

            } else {
              alert("A popup blocker may be preventing the application from opening the page. If you have a popup blocker, try disabling it.");

            }
          }
          else if (this.loginPopup) {
            this.loginPopup.focus();
          }
        }
      });
    }
    else if (this.loginPopup) {
      this.loginPopup.focus();
    }
  }

  ManageRuleOuts(object) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/manageruleouts/action`, object);
  }
  updateRuleOutsSequence(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageRuleOuts/DragNDrop`, dataArray
    );
  }

  //ICD Edit
  UpdateICDCode(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ICDCode/Edit`, Data, { params: { 'Conn': dbName } }

    );
  }
  // Delete ICD code
  DeleteICDCode(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ICDCode/delete`, data, { params: { 'Conn': dbName } }
      // `${this.baseUrl2}api/ICDCode/delete?da=${OrganizationICD9CodeId}`

    );
  }

  // DeleteICDCode(id,dbName:string) {
  //   return this.httpClient.post<any>(
  //     `${this.baseUrl2}api/ICDCode/delete?OrganizationICD9CodeId=${id}`,  { params: { 'Conn': dbName } }
  //   );
  // }
  //Insert ICD code
  InsertICDCode(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ICDCode/Add`, Data, { params: { 'Conn': dbName } }
    );
  }

  //ICD copy validation
  validateICDData(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ICDCode/Validate`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  onSaveEntity(entityobj, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UserDefaultRoles/Edit`, entityobj, { params: { 'Conn': dbName } }
    );
  }

  configureCaseTypes(object, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/CaseTypeConfig`, object, { params: { 'Conn': dbName } }
    );
  }

  activateCaseTypes(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Location/EditCaseType`, data, { params: { 'Conn': dbName } }
    )
  }

  GetCase360Url(obj: any) {
    return this.httpClient.post(
      `${this.baseUrl2}api/LaunchCase360/Launch`, obj);
  }

  InsertScreeningtype(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningTypes/Add`, Data, { params: { 'Conn': dbName } }
    )
  }

  updateScreeningType(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningTypes/Edit`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  DeleteScreeningType(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningTypes/delete`, data, { params: { 'Conn': dbName } }
      // `${this.baseUrl2}api/ICDCode/delete?da=${OrganizationICD9CodeId}`

    );
  }

  validateScreeningType(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ScreeningTypes/Validate`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  validateOrganizationTagsData(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/OrganizationTags/validate`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  InsertOrganizationTags(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organizationtags/add`, Data, { params: { 'Conn': dbName } }
    );
  }

  updateOrganizationTags(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organizationtags/update`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  InsertRejectionComments(Data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/rejectioncomments/Add`, Data, { params: { 'Conn': dbName } }
    )
  }

  updateRejectionComments(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/rejectioncomments/Edit`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  DeleteRejectionComments(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/rejectioncomments/delete`, data, { params: { 'Conn': dbName } }
      // `${this.baseUrl2}api/ICDCode/delete?da=${OrganizationICD9CodeId}`

    );
  }

  validateRejectionComments(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/RejectionComments/Validate`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  getReportBuilderPayload(dbName: string, orgID: string, clientIP: string) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/reportbuilder/getpayload`, { params: { 'Conn': dbName, 'orgID': orgID, 'clientIP': clientIP } },);
  };

  getCustomOrgAttribute(orgid, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/GetCustomOrgAttibutes?orgid=${orgid}`, { params: { 'Conn': dbName } }
    );
  }

  saveCustomOrgvalues(dataArray: any, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AddCustomOrgValues`, dataArray, { params: { 'Conn': dbName } }
    );
  }
  launchAdmin2URL(obj: any) {
    return this.httpClient.post(
      `${this.baseUrl2}api/LaunchAdmin2/Launch`, obj);
  }


  userAssociation(dataObject: any, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/AssociateUser`, dataObject, { params: { 'Conn': dbName } }
    );
  }

  associateSurgi(dataObject: any, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/AssociateSurgi`, dataObject, { params: { 'Conn': dbName } }
    );
  }

  userOutOfOffice(dataObject: any, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/UserOutOfOffice`, dataObject, { params: { 'Conn': dbName } }
    );
  }

  userThresholdDetails(dataObject: any, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/user/userThreshold`, dataObject, { params: { 'Conn': dbName } }
    );
  }

  updateTabIndex(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/TabIndex/Manage`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  validateTabIndex(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/TabIndex/Validate`, dataArray, { params: { 'Conn': dbName } }
    );
  }
  validateTabIndexOrgType(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/TabIndex/ValidateOrgType`, dataArray, { params: { 'conn': dbName } }
    );
  }

  updateSiteOrder(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageSiteOrder/Manage`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  validateSiteOrder(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageSiteOrder/Validate`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  DeleteSiteOrder(data, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageSiteOrder/delete`, data, { params: { 'Conn': dbName } }
    );
  }
  getCasetypesBasedOnRole(rolename, UserId, accountId?) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/templates/GetCasetypes?userid=` +
      UserId + '&Rolename=' + rolename + '&AccountId=' + accountId);
  }
  migrateTemplates(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/migrate/migrateTemplates`, dataArray);
  }
  GetMicroCodes(dataArray, dbname) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MicroscopicCodes/GetMicroCodes`, dataArray, { params: { 'Conn': dbname } });
  }
  InsertAndUpdateOrderingFacilityPasswordDetails(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/OrderingFacilityPasswordDetails`, data,
    )
  }
  InsertAndUpdateOFTimeOutConfigurationDetails(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/TimeOutConfigurationDetails`, data,
    )
  }

  UpdateReflexRuleDetails(ModifiedBy, ModifiedField, data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReflexRuleDetails?ModifiedBy=${ModifiedBy}&ModifiedField=${ModifiedField}`, data,
    )
  }

  loadreflexrulesdetails(ruleId, organizationId, deploymentKey) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/ReflexRuleData?ruleid=${ruleId}&organizationid=${organizationId}&deploymentkey=${deploymentKey}`
    );
  }


  validateDefaultStains(dataArray, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/defaultstains/Validate`, dataArray, { params: { 'Conn': dbName } });
  }


  uploadDefaultStains(dataArray, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/defaultstains/Upload`, dataArray, { params: { 'Conn': dbName } });
  }
  getCasesSearchDropdownDetails(context, orgId, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseSearch/GetDropDownValues?Context=${context}&OrgId=${orgId}`, { params: { 'Conn': dbName } });
  }
  GetFilteredDropDown(Object) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CaseSearch/GetFilteredDropDown`,
      Object
    );
  }

  getDisplayColumns(location: any) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ActivityTracker/GetDisplayColoumns`, location, { params: { 'Conn': 'configdb' } });
  }

  //AutoAssignmentRules
  GetRulelist(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/getautoassignmentrules`, obj, { params: { 'Conn': dbName } });
  }

  DeleteRulelist(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/delete`, obj, { params: { 'Conn': dbName } });
  }

  getAARLookupValueForLabandOF(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/getlabaccountsonorganization`, obj,
      { params: { 'Conn': dbName } });
  }

  getAARLookupValueForCasetypeandInsurance(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/getcasetypeandinsurancedata`, obj,
      { params: { 'Conn': dbName } });
  }



  createOrEditAutoAssgnmentRule(inputArray, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/createoredit`, inputArray,
      { params: { 'Conn': dbName } });
  }

  approveICDCodes(dataArray, dbName: string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ApproveIcdCodes`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  validateDxTemplates(userInputData, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ValidateDxTemplates`, userInputData,
      { params: { 'Conn': dbName } }
    );
  }

  approveDiagnosisTemplates(userInputData, dbName: string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ApproveDxTemplates`, userInputData,
      { params: { 'Conn': dbName } }
    );
  }

  validateICDCodes(dataArray, dbName: string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ValidateIcdCodes`, dataArray,
      { params: { 'Conn': dbName } }
    );
  }

  GetRuleExportList(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/export`, obj, { params: { 'Conn': dbName } });
  }

  getAARLookupValueForReadingLocandAssignees(obj, dbName) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/getassigneelocationdetails`, obj,
      { params: { 'Conn': dbName } });
  }

  UpdateSequence(data : any,depKey?){
  return this.httpClient.post<any>(
    `${this.baseUrl2}api/sequence`, data
    , { params: { 'Conn': depKey } }
  );
  }

  SwapSequence(to: string, data: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/swapSequence/${to}`, data
    );
  }

  SwapSequence2( data: any,depKey? : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/swapSequence`, data
      , { params: { 'Conn': depKey } }
    );
  }

  SetStatus(data : {status : boolean, attrIds : string},depKey){
  return this.httpClient.post<any>(
    `${this.baseUrl2}api/status`, data
    , { params: { 'Conn': depKey } }
  );
  }
  saveOrganizationAttribute(data: any,depKey?) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/orgnaizationAttributes`, data
      , { params: { 'Conn': depKey } }
  );
  }
  uploadFile(attachments) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/ActivityTracker/uploadfile`, attachments
      );
  }
  downloadFile(fileId: any) {
    return this.httpClient.post(`${this.baseUrl2}api/ActivityTracker/downloadpdf`, fileId, { responseType: 'arraybuffer' });
  }

  previewFile(fileId: any, dbname,tempType?) {
    let params: any = {};
  if (dbname) {
    params['Conn'] = dbname;
  }
  if (tempType) {
    params['templateType'] = tempType;
  }
    return this.httpClient.post(`${this.baseUrl2}api/ReportPreview/downloadpdf`, fileId,
      { params: params, responseType: 'arraybuffer' });
  }

  validateVDTemplate(array, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/validate`, array,
      { params: { 'Conn': dbName } }
    );
  }

  addEditVDTemplate(array, dbName: string, srcDbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/vdtemplate/addedit?sourceDeployment=${srcDbName}`, array,
      { params: { 'Conn': dbName } }
    );
  }

  templateManagementCE(data,dbName : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/TemplateManagementController/CreateAndUpdateTemplates`, data
      ,{params : {'Conn' : dbName }}
    );
  }

  saveVitalAdminScheduledJobDataRepo(inputJson,dbName : string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/asyncprocess/insert`, inputJson,
      { params: { 'Conn': dbName } }
    )
  }

 genericBulkUploadandUpdate(inputJson,dbName : string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/bulkupload/genericaddandupdate`, inputJson,
      { params: { 'Conn': dbName } }
    )
  }
  saveTemplateUsers(data: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/manageTemplateUsersCustomListing`, data
    );
  }

  getWorkListData(orgID,role='',level='',tabname='',commonKey='', workListId : string = null){
    return this.httpClient.get<customListing[]>(`${this.baseUrl2}api/worklist?orgID=${orgID}&role=${role}&level=${level}&tabname=${tabname}&commonKey=${commonKey}&workListId=${workListId}`)
  }

  saveCustomlisting(data: any) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/saveCustomListing`, data
    );
  }

  swapCustomListingSequence(swapIds : {customListingSequences : {sourceId : number, targetId : number}[], organizationId : string, level : string, role : string}){
    return this.httpClient.post<any>(`${this.baseUrl2}api/UpdateListingSequenceOrder`, swapIds)
  }
  getPathologistPrivilagesDetail(pathologistId:string,organizationId:string,accountIds:string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/ManagePathologistDetail`,{params:{pathologistId,organizationId,accountIds}})
  }
  getPathologistPrivilagesList(organizationid : any)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/PathPrivilegesList?organizationid=${organizationid}` )
  }
  savePathologistPrivilages(data:any,userId:string,orgId:string)
  {
    return this.httpClient.post<any>(`${this.baseUrl2}api/SaveManagePathologistDetail?userid=${userId}&organizationid=${orgId}`,data)
  }

  templateManagementCESequesterTemplate(data,dbName : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/SequesterReasonsController/CreateAndUpdateSequesterTemplates`, data,
      { params : {'Conn' : dbName }}
    );
  }
  CheckDuplicateEmail(obj, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/CheckDuplicateEmail/AddEdit`, obj,
      { params: { 'Conn': dbName } }
    );
  }

  // dynamic API Fetch method to get all the details based on dynamica form for dropdowns
  getGenericDetailsBasedOnInputs(url: any) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}${url}`);
  }

  templateManagementCECorelationTemplate(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/TemplateManagementController/CreateAndUpdateCorelationTemplates`, data
    );
  }

  templateManagementCENomenclatureTemplate(data) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/TemplateManagementController/CreateAndUpdateNomenclatureTemplates`, data
    );
  }

  addEditdefaultRoles(entityobj) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/UserDefaultRoles/AddEdit`, entityobj, { params: { 'Conn': "configdb" } }
    );
  }

  // workgroups
  workGrouporgDepartments(data: any,dbName:string) {
    return this.httpClient.post<any>
      (`${this.baseUrl2}/api/labadmin/OrganizationDepartments`, data, { params: { 'Conn': dbName } })
  }

  // workgroups save
  workGroupSave(data: any,dbName:string) {
    return this.httpClient.post<any>
      (`${this.baseUrl2}/api/workgroups/saveandupdatedepartment`, data, { params: { 'Conn': dbName } })
  }

  getPathologistLicensureInfo(obj, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/autoassignmentrules/getPathologistLicensureInfo`, obj,
      { params: { 'Conn': dbName } }
    );
  }

  GetCasetypeReportTemplates(dbName) {
    return this.httpClient.get<any>(`${this.baseUrl2}api/ReportGenerate/GetCasetypeTemplate`, { params: { 'Conn': dbName } })
  };
  getTimeZone(userid:string,dbName:string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/getTimeZone?UserId=${userid}`,
      { params: { 'Conn': dbName } }
    );
  }
  bulkUploadFunctionalities(data,dbName){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/generic/bulkuploadandvalidation`,data,
      { params: { 'Conn': dbName } }
    );
  }

  getAssociationsListing(obj, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/AssociatedClientLocations`, obj,
      { params: { 'Conn': dbName } }
    );
  }

  getOrderingFacilitiesCount(obj, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AssociateAccounts/GetOrderingFacilitiesCount`, obj,
      { params: { 'Conn': dbName } }
    );
  }


  getExcelData(formData,dbName: string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/getExcelData`,formData,
      { params: { 'Conn': dbName }}
    );
  }

  reportSignatoriesCE(data,dbName : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReportSignatoriesController/CreateAndUpdateReportSignatories`, data
      ,{params : {'Conn' : dbName }}
    );
  }
  //generic method to

  EncryptUrlPayload(dataArray) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/URLLaunch/Encrypt`, dataArray);
  }

  validateOrganization(inputData, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organization/validate`, inputData,
      { params: { 'Conn': dbName } }
    );
  }

  updateOrganization(inputData, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organization/edit`, inputData,
      { params: { 'Conn': dbName } }
    );
  }

  GetOFLocationOnOrgtype(inputData, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organization/getoflocationonorgtype`, inputData,
      { params: { 'Conn': dbName } }
    );
  }

  GetOFPhysician(inputData, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organization/getofphysicians`, inputData,
      { params: { 'Conn': dbName } }
    );
  }

  getStateAndCity(zipCode: string, dbName: string)
  {
    return this.httpClient.get<any>(`${this.baseUrl2}api/GetCityAndStateFormZipCode?ZipCode=`+zipCode, { params: { 'Conn': dbName } });
  }

  createpayers(Data:any,dbName:string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MangePayers/InsertPayers`, Data, { params: { 'Conn': dbName } }
    );
  }
  manageL2lConfiguration(Data:any,dbName:string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ManageL2l/ManageL2LConfig`, Data, { params: { 'Conn': dbName } }
    );
  }
  migratePayers(Data:any,dbName:string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MangePayers/MigratePayers`, Data, { params: { 'Conn': dbName } }
    );
  }
createCustomFields(Data:any,dbName:string) {
  return this.httpClient.post<any>(
    `${this.baseUrl2}api/MangeCustomFields/InsertCustomFields`, Data, { params: { 'Conn': dbName } }
  );
}
DeleteCustomFields(data,dbName:string) {
  return this.httpClient.post<any>(
    `${this.baseUrl2}api/MangeCustomFields/DeleteCustomFields`, data, { params: { 'Conn': dbName } }
  );
}

  CopyAndGetCustomList(organizationId:any) {
    return this.httpClient.get(
      `${this.baseUrl2}api/CopyAndGetCustomList?OrganizationID=${organizationId}`, {responseType : 'text'}
    );
  }

  GetVAExtAttrValue( EntityID :string,  ExtAttrName :string ,  ExtAttrType :string)
  {
    return this.httpClient.get(`${this.baseUrl2}api/VAExtAttr?EntityID=${EntityID}&ExtAttrName=${ExtAttrName}&ExtAttrType=${ExtAttrType}`,{responseType: 'text' })
  }

  getEncryptedValue(object: {}) {
    return this.httpClient.post(`${this.baseUrl2}api/Location/GetEncryptedValues`, object)
  }

  ValidateBulkUpdateTestData(object,depKey? : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AddTests/ValidateBulkUpdateTestData`, object
      , { params: { 'Conn': depKey } }
    );
  }

  bulkUpdateTests(testDataArray,depKey?) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/AddTests/BulkUpdate`, testDataArray
      , { params: { 'Conn': depKey } }
    );
  }

  AddEditDelClinicalInfoTemplate(object:any,iOper:string,loggedinUserID:number,dbName: string){
    return this.httpClient.post(`${this.baseUrl2}api/AddEditDelTemplate?iOper=${iOper}&loggedinUserID=${loggedinUserID}`,object,{params: { 'Conn': dbName },responseType: 'text'})
   }

  getDeploymentStatus(dataArray, dbName: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/ReportGenerate/DeploymentStatus`, dataArray, { params: { 'Conn': dbName } }
    );
  }

  templateManagementCEWorklist(data,dbName : string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/worklist/updateworklist`, data,
      { params : {'Conn' : dbName }}
    );
  }
  EditOrgACSSearch(obj, depkey: string) {
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/organization/editorgacs`, obj,
      { params: { 'Conn': depkey } }
    );
  }

  reorderTemplatesList(finalObject,dbName : string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/reorder`, finalObject,
      { params: { 'Conn': dbName } }
    );
  }

  manageConfigurations(finalObject,dbName : string) {
    return this.httpClient.post<any>(`${this.baseUrl2}api/manageconfigurations`, finalObject,
      { params: { 'Conn': dbName } }
    );
  }

  getLaboratories(accountIDs, dbName) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Statisticreport/getLabDetails?accountIDs=` +
      accountIDs
    ,{ params: { 'Conn': dbName } });
  }

  getCasetypes(orgID, dbName) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Statisticreport/getCasetypes?orgId=` +
      orgID
    ,{ params: { 'Conn': dbName } });
  }

  getMasterDataToExport(apiStr, OrganizationID, RoleName, Category, UserId, AccID, LabName, MenuText, casetypeList,criteriaObj, dbName) {
    const body = {
      OrganizationID,
      RoleName,
      Category,
      UserId,
      AccID,
      LabName,
      MenuText,
      casetypeList,
      criteriaObj
    };

    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Statisticreport/${apiStr}`,
      body,
      { params: { 'Conn': dbName } }
    );
  }

  refreshMasterDataToExport(UserId, GroupUniqueID, dbName) {
    return this.httpClient.get<any>(
      `${this.baseUrl2}api/Statisticreport/refreshMasterDataToExport?UserId=` +
      UserId + `@GroupUniqueID=` + GroupUniqueID
      , { params: { 'Conn': dbName } });
  }

  downloadReport(groupUniqueId: string, dbName: string) {
    return this.httpClient.get(`${this.baseUrl2}api/Statisticreport/download?groupUniqueId=${groupUniqueId}`, {
      params: { 'Conn': dbName }, responseType: 'blob'
    });
  }

  migrateDiagnosisTemplates(inputJson,dbName : string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/MigrateDiagnosisTemplates`, inputJson,
      { params: { 'Conn': dbName } }
    )
  }

  manageSequence(inputJson,dbName : string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/Sequences/manage`, inputJson,
      { params: { 'Conn': dbName } }
    )
  }

  hotKeyUpsert(obj:any,dbName:string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/hotkey/upsert`, obj, { params: { 'Conn': dbName } }
    );
  }

  siteFormatUpsert(obj:any,dbName:string){
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/identityformat/upsert`, obj, { params: { 'Conn': dbName } }
    );
  }

  getCasesRecentData(orgId:string, caseType:string, siteFormat:number, dbName:string){
    let obj = {
      "orgid":orgId,
      "casetype":caseType,
      "siteFormat":siteFormat,
    }
    return this.httpClient.post<any>(
      `${this.baseUrl2}api/siteformat/recentcase`, obj, { params: { 'Conn': dbName } }
    );
  }

  configureCustomReports(orgid: string, userid: string, dbName: string) {
    const body = {  orgid, userid }
      return this.httpClient.post<any>(
        `${this.baseUrl2}api/Statisticreport/configureReports`,body
      ,{ params: { 'Conn': dbName } });
  }

}

export class MainQuery {
  public Keyword: string = '';
  public Query: string = '';
  public CardType: string = '';
}

