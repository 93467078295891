<!-- <mat-spinner class="labadmin-spinner" *ngIf="sourceApp && !templateData"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp === 'VitalDx' && !templateData">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>
        
       
<div class="row">
    <!-- <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Diagnosis References</strong>
            </h3>
        </div>
    </div> -->
</div>
<div *ngIf="templateData" class="col-lg-12 p-0 m-md-1 m-lg-0">
    <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="gridData" [context]="'Diagnosis References'"
        [hideCreate]="createBtn" [hideUpload]="true" [hideExport]="exportBtn" [hideCopy]="true" [hideDelete]="deleteBtn"
        (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [templateData]="templateData" [hideEdit]="editBtn"
        [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
        (deleteTemplateClicked)="deleteTemplate($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true"
        (emitFilters)="emitFilters($event)" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null">
    </app-templates-listing>
</div>
<!-- <div *ngIf="noDataFound" class="col-lg-12 p-0 m-md-1 m-lg-0">
    <div class="align-center  mt-4">
        <div class="nodata-design font-size">No Data Found</div>
    </div>
</div> -->