<div class="px-4" *ngIf="saveClicked">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>
<div class="mb-1 d-flex create-upload-btn justify-content-between align-items-center headerContainer" style="place-content: flex-end;" *ngIf="!saveClicked">
    <div>
        <div class="row" *ngIf="isAdmin3">
            <div class="col-md-12 p-0">
                <div class="modal-header admin-model-header mt-2 listingModalHeader">
                    <h3 class="modal-title w-100 admin-model-header-txt">
                        <strong class="header-View"> {{ fileName }} </strong>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div>
        <button type="button" class="btn btn-return float-right mr-3 createButton" (click)="saveReorder()"> Save </button>
        <button type="button" class="btn btn-return float-right mr-2 returnButton" (click)="returnToList()"> Return </button>
    </div>
</div>
<div class="content-slab" *ngIf="!saveClicked">
    <span class="ml-3 p-2"></span>
    <span> {{ sortTemplateMapping?.[fileName]?.['columnDisplayName'] }} </span>
    <img *ngIf="sortModeEnabled && sortedOrder === 'DESC'" class="ml-2 cursor" height="17px" matTooltip="Sort"
        matTooltipPosition="right" (click)="sequenceTracking('ASC')" src="/assets/icons/Reorder_Descending.svg"
        alt="Descending">

    <img *ngIf="sortModeEnabled && sortedOrder === 'ASC'" (click)="sequenceTracking('DESC')" matTooltip="Sort"
        matTooltipPosition="right" class="ml-2 cursor" height="17px" src="/assets/icons/Reorder_Asceding.svg"
        alt="Ascending">

    <img *ngIf="sortModeEnabled && sortedOrder === 'UNS'" matTooltip="Sort" matTooltipPosition="right"
        class="ml-2 cursor" height="17px" (click)="sequenceTracking('ASC', sortableGridData.data)"
        src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">
    <!-- <span *ngIf="!sortModeEnabled" class="right-elements pointer" (click)="(!flagsList.isNoDataPresent && flagsList.data.length && !flagsSelectionModel.selected.length && filtercondition.value.status!='inactive')||!editBtn?openPreview():null" [class.disabled]="(flagsList.isNoDataPresent || flagsList.data.length === 0 || flagsSelectionModel.selected.length > 0 || filtercondition.value.status=='inactive')&&editBtn">Preview</span> -->

    <!-- <img *ngIf="!sortModeEnabled&&exportBtn" class="excel" matTooltip="Export" (click)="!flagsList.isNoDataPresent || flagsList.data.length || flagsSelectionModel.selected.length ? exportexcel(flagsList.data):null"  src="/assets/icons/Excel_Download.svg"  alt="Download As Excel" [class.disabled]="flagsList.isNoDataPresent || !flagsList.data.length || flagsSelectionModel.selected.length"> -->
</div>

<div class="colsplit nogrid" *ngIf="!saveClicked">
    <div>
        <div *ngIf="!sortableGridData?.length">
            <div class="col-sm-12 text-center mt-4">
                <div class="col-sm-12 nodata-header-wrap">
                    <span class="col-sm-12 nodata-wrapper"> No Results </span>
                </div>
            </div>
        </div>
        <div class="flag-main" *ngIf="sortableGridData.length > 0" [ngClass]="{'vitalDx': !isAdmin3}">
            <div #scrollableDiv cdkDropList [cdkDropListData]="sortableGridData" class="items-list"
                (cdkDropListDropped)="onDrop($event)" (scroll)="onScroll($event)">
                <div class="items" *ngFor="let item of sortableGridData; let i= index" cdkDragBoundary=".items-list"
                    cdkDragLockAxis="y" cdkDrag>
                    
                    <div [ngClass]="(item?.IsActive === 'Inactive' ||  item?.Active === 'Inactive' || item?.Status === 'Inactive' || item?.payersstatus === 'Inactive') ? 'inactive-class':'active-class'">
                       
                        <div *ngIf="sortModeEnabled" class="drag-handle" cdkDragHandle>
                            <div class="drag-handle" cdkDragHandle>
                                <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                            </div>
                        </div>
                        <span class="ml-1">{{ item?.[templateNameProperty] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>