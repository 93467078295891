<div mat-dialog-title class="confirmHeader p-2 d-flex justify-content-center">
    <img src="../../../../assets/icons/user_icons/warning.svg" alt="Warning" class="material-icons md-48">
    <h1 class="header" *ngIf="data.header && data.header !='Extraction Procedure'">{{data.header}}</h1>
</div>
<span class="migrate_wrap">
    <div mat-dialog-content>
        <span [innerText]="data.message" class="message"></span>
        <h5 *ngIf="data.alert">{{data.alert}}</h5>
        <span class="sub-line" *ngIf="ifExists(data.message)">You cannot revert this action.</span>
        <h6 *ngIf="data.notes">{{data.notes}}</h6>
        <section *ngIf="data.action" class="example-section">
            <mat-checkbox class="example-margin" [color]="'primary'" (change)='onCheckboxClick($event.checked)'> {{data.action}}</mat-checkbox>
        </section>
    </div>
</span>

<div mat-dialog-actions class="confirm_dia d-flex justify-content-center" [ngStyle]="{'justify-content': data.cancel === 'dontshow' ? 'center':''}">

    <app-button *ngIf="data.cancel !== 'dontshow'" buttonclass="secondary" class="mr-2 popup-btn" customClass="admin" mat-dialog-close>{{data.cancel}}</app-button>
    <!-- <button *ngIf="data.cancel !== 'dontshow'" type="button" [mat-dialog-close]="data.animal" cdkFocusInitial
        class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs mr-4 cancelButton">
        {{data.cancel}}
    </button> -->

    <app-button *ngIf="data.header !='Extraction Procedure' && data.continue" buttonclass="primary" class="popup-btn" customClass="admin" [isDisabled]="!isActionChecked && data.message.includes('offline!')"  (click)="onNoClick()">{{data.continue}}</app-button>
    <!-- <button *ngIf="data.header !='Extraction Procedure'" type="submit" class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs ml-3 confirmButton"
        (click)="onNoClick()">
        {{data.continue}}
    </button> -->

    <div *ngIf="data.header == 'Extraction Procedure'" class="d-flex ml-4">
        <mat-icon role="img" class="align-items-center redirect-icon">launch</mat-icon>
        <a  class="redirect-btn mr-3" (click)="onNoClick()">  {{data.continue}}</a>
    </div>
</div>