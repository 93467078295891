import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CollectionView } from '@grapecity/wijmo';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../client/tab.service';
import { VitalHttpServices } from '../core/services/VitalHttpServices';
import { ActivityTrackerService } from '../core/services/activity-tracker.service';
import { SubMenuCardModel } from '../client/DbModel/SubMenuCard/Submenucardmodel';
import { LabadminSessionService } from '../labadmin/services/labadmin-session.service';
import { CommonService } from '../core/services/commonservices';
import { TemplateHeadersService } from '../core/utility/template-headers.service';
import { LabadminService } from '../labadmin/labadmin.service';

@Component({
  selector: 'app-manageworklist',
  templateUrl: './manageworklist.component.html',
  styleUrls: ['./manageworklist.component.scss']
})
export class ManageworklistComponent {
  @Input() templateData: any;
  gridPage:boolean=true
  SubMenuCardModel: any;
  DeploymentKeys: any;
  checkStatus: boolean;
  GridData: any;
  showGrid: boolean;
  gridHeaderList: { templateName: string; headers: { Name: string; type: string; key: string; colWidth: string; }[]; }[];
  listedGridHeader: { Name: string; type: string; key: string; colWidth: string; }[];
  listHeaderbackup: any;
  gridheader: string[];
  gridShowDiv: boolean;
  noDataFound: boolean=false;
  showPaginationMainGrid: boolean;
  selectedCasetype: any;
  dbName:string;
  userSessionDetails: any;
  sourceApp: string='Admin';
  uploadbtnClicked: boolean=false;
  saveOrEditCompleted: any;
  auditableColumns=[]
  activityEntity: {};
  uploadBtn: boolean=false;
  createBtn: boolean=false;
  exportBtn: boolean=false;
  editBtn: boolean=false;
  disableReOrder:boolean=true;

  exportExcelFields = {
    TabName: { displayName: "Tab Name", isDate: false },
    DisplayName: { displayName: "Display Name", isDate: false },
    TabDisplayName: { displayName: "Tab Displayname", isDate: false },
    Role: { displayName: "Role", isDate: false },
    Sequence: { displayName: "Sequence", isDate: false },
    IsDefault: { displayName: "Is Default", isDate: false },
    IsActive: { displayName: "Status", isDate: false },
    Description: { displayName: "Description", isDate: false },
  };


  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public templateHeaders: TemplateHeadersService,
    private activityService: ActivityTrackerService,
    private changeDetectorRef: ChangeDetectorRef, private labAdminSessionService: LabadminSessionService,private labdaminService: LabadminService,private store: Store<{ breadcrum: [] }>,
    ) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }


  async ngOnInit(){
    this.dbName=this.VitalHttpServices.deploymentKey;    
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      this.sourceApp = 'VitalDx';
      await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async (session: any) => {
        await this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
          let menuObj = result[result.length - 1];
          if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
            this.userSessionDetails = session.userDetails
            await this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails);
            this.templateData = this.labdaminService.templateData;
            this.dbName = this.labdaminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', this.dbName);
            await this.getData()
            await this.getAuditableDetails(this.templateData.menuURL);
            this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' }, { 'key': 'Settings', 'value': this.templateData['menuURL'] }] })
            this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
            this.GetButtonAccess(this.labdaminService.subMenuPermissions)
          }
        })
      })
    }
    else {
      await this.getData()
      await this.getAuditableDetails(this.templateData.menuURL);
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
    }
  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Worklists")["ActionButton"];
    } else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL.toLowerCase() == this.templateData.menuURL.toLowerCase())['ActionButton'];
    }

    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getData() {
    let queryVariable = {
      orgid: this.templateData.secondarykeys.OrganizationId.toString()
    };
    let query = this.SubMenuCardModel.GetQuery('getworklistdata');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult,this.dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.checkStatus = false;
          this.templateData.submenuData = data.data.submenuData;
          this.gridPage = true;
          this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.showGrid = false;
          if (!this.templateData?.submenuData?.length) this.GridData = new CollectionView(...[]);
        }
      }
    }, error => {
      console.error(error);
    });
  }


  onSelectionRole(event: any) {
    const submenuDataCopy = JSON.parse(JSON.stringify(this.templateData.submenuData));
    const role = event?.Roles?.object?.Role !=='All Roles'?event?.Roles?.object?.Role: null;
    const tabDisplayName = event?.tabDisplayName?.object?.TabDisplayName !=="All Tab Names" ?event?.tabDisplayName?.object?.TabDisplayName: null;
    const filteredData = submenuDataCopy.filter((item: any) => {
      if (!role && !tabDisplayName) {
        return true;
      }
      if (role && !tabDisplayName) {
        return item.Role.toLowerCase() === role.toLowerCase();
      }
      if (!role && tabDisplayName) {
        return item.TabDisplayName.toLowerCase() === tabDisplayName.toLowerCase();
      }
  
      return item.Role.toLowerCase() === role.toLowerCase() && item.TabDisplayName.toLowerCase() === tabDisplayName.toLowerCase();
    });
    if(role && tabDisplayName){
        this.disableReOrder=false;
    }else{
      this.disableReOrder=true;
    }
    this.addDataToGrid(filteredData)
  }

  worklistStatusUpdate(object) {
    if (object.action === 'edit') {
      object.rowData.Action=object.action;
      let obj = {
        oldData: JSON.parse(JSON.stringify(object.rowData)), // deep copy of rowData
        newData: { ...object.rowData } // shallow copy of rowData
      };
      obj.newData.IsActive = object.event.srcElement.checked ? 1 : 0;
      obj.newData.IsDefault= obj.newData.IsDefault=="Yes"?1:0;
      this.insertOrEditWorklist(obj);
    }
  }
  


  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Worklists') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }
    }
    this.gridPage = true;
    this.gridheader = [
      'Id',
      'OrganizationId',
      'TabDisplayName',
      'Role',
      'DisplayName',
      'IsActive',
      'IsDefault',
      'Description',
      'TabName',
      'Sequence'
    ];
    this.gridheader.reverse();
    let gridarray = []
    let tempcolumns = {}
    this.ngxService.start();
    if (data && data.length>0) {
        for (let i = 0; i < data.length; i++) {
          tempcolumns = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                // if (key === 'Default' && value == "Yes") {
                //   value = true;
                // } else if (key === 'Default' && value == "No") {
                //   value = false;
                // }
                // if (!value) {
                //   value = '';
                // }
                tempcolumns[key] = value?value:''
              }
            }
          }
          gridarray.push(tempcolumns)
        }
         
      
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
        gridarray?.sort((a, b) => {
          const seqA = isNaN(Number(a.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(a.Sequence);
          const seqB = isNaN(Number(b.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(b.Sequence);
          return seqA - seqB;
        });
      }
      else {
        this.ngxService.stop();
        this.gridShowDiv = false;
        this.noDataFound = true;
      }
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.GridData = new CollectionView(gridarray)
      this.showGrid = true

      this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      this.GridData = new CollectionView(...[])
      this.disableReOrder=true;
      this.gridShowDiv = false;
      this.noDataFound = true;
    }
  }

  recieveObjFromListing(object: any) {
    delete object.newData.CaseType
    object.newData = { ...object.newData, ...{ 'Context': 'Worklist' } };
    if (object.newData.Action == 'Edit') {
      object.newData['Id'] = object.oldData['Id']
      this.insertOrEditWorklist(object)
    }
  }


  
  insertOrEditWorklist(data) {
    this.ngxService.start();
    data.newData["ModifiedBy"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
    this.VitalHttpServices.templateManagementCEWorklist(data.newData,this.dbName).subscribe(response => {
      if (response.status == "Success") {
          if (data.newData.Action.toLowerCase() == 'edit') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            data.oldData.IsActive=data.oldData.IsActive=='Yes' || data.oldData.IsActive=='Active' || data.oldData.IsActive==1 ?'Active':'In Active';
            data.newData.IsActive=data.newData.IsActive=='Yes' || data.newData.IsActive=='Active' || data.newData.IsActive==1 ?'Active':'In Active';
            data.oldData.IsDefault=  data.oldData.IsDefault =='Yes' || data.oldData.IsDefault == 1 ?"Yes":"No";
            data.newData.IsDefault=  data.newData.IsDefault =='Yes' || data.newData.IsDefault == 1 ?"Yes":"No";
            this.commonService.auditDetails('Id', 'TabName', [data.oldData], [data.newData], 'Edit', this.templateData, this.auditableColumns);
            this._snackbar.open(response.content[0].NoteMessage, "Close")
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(response.content[0].NoteMessage, "Close")
          }
          this.getData();
          // this.addGridData(this.templateData.submenuData);
        }
      
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open("failed!", "Close")
        this.getData();
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }


  uploadWorkLists(){
    this.gridPage = false;
    this.uploadbtnClicked = true;
  }

  backClicked($event){
    this.getData();
    this.uploadbtnClicked = false;
  }


  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  createActivityTrackerForSort($event: any) {
    if(this.sourceApp !== 'VitalDx') {
      this.commonService.auditDetails('', '', [], $event, 'Re-Order', this.templateData, '');
    }
  }



}
