<div mat-dialog-title class="common-dailog-header mt-2">
    <img mat-dialog-close [src]="icon" alt="cross" />
</div>
<mat-dialog-content class="common-dailog-body">
    <span class="popup-content pt-2">{{config.message}}</span>
    <span class="popup-subcontent">{{config.subMessage}}</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-flex align-items-center w-100 justify-content-center mb-3 action-buttons">
        <app-button buttonclass="secondary"  mat-dialog-close customClass="admin" *ngIf="config.secondaryBtnText">{{config.secondaryBtnText}}</app-button>
        <app-button [buttonclass]="primaryBtnClass" customClass="admin" class="ml-2"  (click)="onPrimaryClick()">{{config.primaryBtnText}}</app-button>
    </div>
</mat-dialog-actions>
