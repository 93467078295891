<div class="row" *ngIf="showHeaderModule && listScreen && isAdmin3">
    <div class="col-md-12 p-0">
        <div class="modal-header admin-model-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View"> {{ listContext==''?filename:listContext }} </strong>
            </h3>
        </div>
    </div>
</div>

<div *ngIf="listScreen && !sortModeEnabled && showListAfterDataLoaded" [ngClass]="{'vitalDx': !isAdmin3, 'autoCompleteList': isAutoCompleteList}">
    <div class="col-sm-12 text-right" style="padding: 0px 6px;"
        *ngIf="!isMandoryFieldAvailable && orginalTemplateCustomObject && !isAdmin3">
        <div class="d-flex justify-content-end align-items-center" style="font-size: 12px !important;">
            <div class="d-flex justify-content-between infoMessage"> <img src="../../assets/icons/tableInfoIcon.svg"
                    class="iconClass mr-2" />
                Case Types are not available to Create/Edit.
            </div>
        </div>
    </div>
    <div class="col-sm-12 common-div d-flex align-items-center">
        <!-- container for tabs -->
        <div class="col-sm-6 px-0 d-flex">
            <button *ngIf="context !== 'Offline Process'" id="All" class="col-sm-2 filterButtons"
                [class.selected]="selectedButton === 'All'" (click)="selectButton('All')" [disabled]="AllCount == 0"
                matTooltip="{{ ('All' + AllCount)?.length + 2 > 13 ? AllCount : ''  }}">All
                ({{AllCount}})
            </button>
            <div class="seperator-filter" *ngIf="isShowActiveTabs || customListActiveTabs">
            </div>
            <button id="Active" class="col-sm-2 filterButtons"
                [ngClass]="(ActiveCount === 0) ? 'disable-btn' : 'button'"
                [class.selected]="selectedButton === 'Active'" (click)="selectButton('Active')"
                [disabled]="ActiveCount === 0" *ngIf="isShowActiveTabs || customListActiveTabs"
                matTooltip="{{ ('Active ' + ActiveCount)?.length + 2 > 13 ? ActiveCount : '' }}">Active
                ({{ActiveCount}})
            </button>

            <button id="InActive" [ngClass]="(InactiveCount === 0) ? 'disable-btn' : 'button'"
                class="m-0 col-sm-2 filterButtons" [class.selected]="selectedButton === 'InActive'"
                (click)="selectButton('InActive')" [disabled]="InactiveCount === 0"
                *ngIf="isShowActiveTabs || customListActiveTabs"
                matTooltip="{{ ('Inactive' + InactiveCount)?.length + 2 > 13 ? InactiveCount : ''  }}">Inactive
                ({{InactiveCount}})
            </button>
            <div *ngIf="context == 'Offline Process'" class="d-flex px-0 pb-2 toggle-switch-container">
                <label for="sequence-format-toggle" class="toggle-switch-slider-label-text"
                    style="margin-top: 16px;">Action:</label>
                <div class="col-sm-4 filter-wrapper p-0">
                    <span>
                        <mat-button-toggle-group #toggleGroup="matButtonToggleGroup" [value]="offlineFilterType"
                            class="mt-2">
                            <mat-button-toggle value="upload" class="p-2" (click)="toggleChange($event,'upload')">
                                Upload
                            </mat-button-toggle>
                            <mat-button-toggle value="update" class="p-2" (click)="toggleChange($event,'update')">
                                Update
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </span>
                </div>
            </div>
            <div class="col-sm-6 ml-2 d-flex" style="height: 24px;"
                [ngStyle]="templateCustomObject?.length ? {'border-left': '2px solid #FB8500'} : null"
                *ngIf="!isUserLevel">
                <ng-container *ngFor="let field of templateCustomObject">
                    <div class="p-0 mr-1" *ngIf="field.dataType === 'staticDropdown'"
                        [ngClass]="field['manageClassName']">
                        <mat-form-field class="col-sm-12 p-0"
                            [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'"
                            appearance="outline">
                            <mat-select placeholder="Select Reason" (selectionChange)="filtersChanged(field, $event)"
                                [(ngModel)]="field.value"
                                [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                                <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                    [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                    <div> {{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']] }}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="p-0" *ngIf="field.dataType === 'dropdown' && !isAdmin3"
                        [ngClass]="field['manageClassName']">
                        <mat-form-field class="col-sm-12 p-0"
                            [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'"
                            appearance="outline">
                            <mat-select [placeholder]="field['properties']?.['placeholder']"
                                (selectionChange)="filtersChanged(field, $event)" [(ngModel)]="field.value"
                                [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                                <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                    [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                    <div class="truncate-text"
                                        matTooltip="{{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']]}}">
                                        {{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']] |
                                        shortentext:field?.properties?.source?.truncateLength }} </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="p-0"
                        *ngIf="field.dataType === 'autocomplete' && (!field.properties.hideInAdmin || !isAdmin3)"
                        [ngClass]="field['manageClassName']">
                        <mat-form-field appearance="outline" class="w-100 bringToTop"
                            [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'"
                            (click)="clearFilterClicked($event, field)">
                            <!-- <mat-label>{{ field['displayName'] }} </mat-label> -->
                            <em class="fa fa-chevron-down chevron-align"
                                [ngClass]="!isAdmin3 ? 'em-LabAdmin' : '' "></em>

                            <!-- <em class="fa fa-times chevron-align mr-2" [ngClass]="!isAdmin3 ? 'em-LabAdmin' : '' " (click)="clearFilterClicked($event, field)" *ngIf="field?.['dummyValue']?.['itemName']"></em> -->
                            <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
                            <input trim type="text" [matTooltip]="field['dummyValue']['itemName']" [(ngModel)]="field['dummyValue']['itemName']" maxlength="200"
                                matInput [aria-label]="field['displayName']" [matAutocomplete]="auto"
                                [ngModelOptions]="{standalone: true}"
                                [placeholder]="field['properties']?.['placeholder']" #autoComopleteText
                                (focus)="clearFilterClicked($event, field)"
                                [ngStyle]="{'text-transform': filename === 'Worklists' ? 'capitalize' : 'none'}">
                            <mat-autocomplete #auto="matAutocomplete"
                                class="customCEOverlayPanelClass listingDropdownPanel"
                                (closed)="autoCompletePanelClosed(field)">
                                <mat-option class="mat-opt-align pl-2"
                                    [ngClass]="{'mdc-list-item--selected':  field['listBackupValue'] === autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] }"
                                    *ngFor="let autoCompleteOption of  filterAutomCompleteOptions(field?.dummyValue['itemName'], field?.properties?.source?.options, field?.properties?.source?.dataValueToDisplay?.[0])"
                                    [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                    (onSelectionChange)="onAutoCompleteSelectionChange($event, field, autoCompleteOption)">
                                    <div class="truncate-text" *ngIf="!field['properties']?.['isPipeEnabled']"
                                        [matTooltip]="optionToolTipText" matTooltipPosition="right"
                                        (mouseenter)="enableToolTip(isOptionElipsed, 'dropdown')" #isOptionElipsed> {{
                                        autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] }}
                                    </div>
                                    <div class="truncate-text" *ngIf="field['properties']?.['isPipeEnabled']"
                                        [matTooltip]="optionToolTipText" matTooltipPosition="right"
                                        (mouseenter)="enableToolTip(isOptionElipsed, 'dropdown')" #isOptionElipsed> {{
                                        autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] | titlecase
                                        }}
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>

            <div class="col-sm-6 ml-2 p-0 d-flex" style="height: 24px;"
                [ngStyle]="templateCustomObject?.length ? {'border-left': '2px solid #FB8500'} : null"
                *ngIf="isUserLevel && caseTypeUserRoles">
                <ng-container *ngIf="caseTypeUserRoles?.length">
                    <mat-form-field appearance="outline" class="listFilterDropdown roleName-font col-sm-8">
                        <mat-select disableOptionCentering [(ngModel)]="selectedRole" placeholder="Select Role"
                            [panelClass]="'customCEOverlayPanelClass'" matTooltip="{{ selectedRole }}">
                            <mat-option (onSelectionChange)="setUserRole($event,user)" value="{{user.DisplayName}}"
                                *ngFor="let user of caseTypeUserRoles" class="dropdown-opt-wrap"
                                matTooltip="{{ user.DisplayName }}">
                                {{user.DisplayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngFor="let field of templateCustomObject">
                    <div class="p-0"
                        *ngIf="field.dataType === 'autocomplete' && (!field.properties.hideInAdmin || !isAdmin3)"
                        [ngClass]="field['manageClassName']">
                        <mat-form-field appearance="outline" class="w-100 bringToTop"
                            [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'"
                            (click)="clearFilterClicked($event, field)">
                            <!-- <mat-label>{{ field['displayName'] }} </mat-label> -->
                            <em class="fa fa-chevron-down chevron-align"
                                [ngClass]="!isAdmin3 ? 'em-LabAdmin' : '' "></em>
                            <input trim type="text" [(ngModel)]="field['dummyValue']['itemName']" maxlength="200"
                                matInput [aria-label]="field['displayName']" [matAutocomplete]="auto"
                                [ngModelOptions]="{standalone: true}"
                                [placeholder]="field['properties']?.['placeholder']" #autoComopleteText
                                (focus)="clearFilterClicked($event, field)"
                                [ngStyle]="{'text-transform': filename === 'Worklists' ? 'capitalize' : 'none'}">
                            <mat-autocomplete #auto="matAutocomplete"
                                class="customCEOverlayPanelClass listingDropdownPanel"
                                (closed)="autoCompletePanelClosed(field)">
                                <mat-option class="mat-opt-align pl-2"
                                    [ngClass]="{'mdc-list-item--selected':  field['listBackupValue'] === autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] }"
                                    *ngFor="let autoCompleteOption of  filterAutomCompleteOptions(field?.dummyValue['itemName'], field?.properties?.source?.options, field?.properties?.source?.dataValueToDisplay?.[0])"
                                    [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                    (onSelectionChange)="onAutoCompleteSelectionChange($event, field, autoCompleteOption)">
                                    <div class="truncate-text" *ngIf="!field['properties']?.['isPipeEnabled']"
                                        [matTooltip]="optionToolTipText" matTooltipPosition="right"
                                        (mouseenter)="enableToolTip(isOptionElipsed, 'dropdown')" #isOptionElipsed> {{
                                        autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] }}
                                    </div>
                                    <div class="truncate-text" *ngIf="field['properties']?.['isPipeEnabled']"
                                        [matTooltip]="optionToolTipText" matTooltipPosition="right"
                                        (mouseenter)="enableToolTip(isOptionElipsed, 'dropdown')" #isOptionElipsed> {{
                                        autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] | titlecase
                                        }}
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </div>


        <!-- container for the search bar and other buttons -->
        <div class="col-sm-6 d-flex p-0 justify-content-end">
            <div class="global-search-panel d-flex p-0 {{ searchBarWidth ? searchBarWidth : 'col-sm-7'  }} ">
                <div style="display: flex; margin-right: 3px;margin-left: 4px;">
                    <mat-icon class="search-icon">search</mat-icon>
                </div>
                <div class="standing-line">
                </div>
                <input type="text" class="search-input"
                    [placeholder]="'Search ' + (addEditContext == '' ? context : addEditContext)"
                    [(ngModel)]="searchText" (keyup)="filtertemplates()">
                <ng-container *ngIf="searchText">
                    <div class="standing-line">
                    </div>
                    <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
                        <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
                    </div>
                </ng-container>
            </div>
            <div class="col-sm-4 m-2 p-0 " *ngIf="context == 'Offline Process'" style="position: relative;">
                <div class="date-range-div">
                    <input type="text" class="daterange-input" [placeholder]="'Select Range'"
                        [(ngModel)]="selectedRange" (click)="showDiv = !showDiv">
                    <em class="fa fa-chevron-down chevron-align pt-2 pr-1"></em>
                </div>
                <div *ngIf="selectedRange.toLowerCase() === 'date range'" class="date-range-section">
                    <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" *ngIf="selectedRange.toLowerCase() === 'date range'" (dateChange)="updateOutput($event)" style="display: none;">
                    <div style="position: absolute;left: -30px">
                      <mat-datepicker *ngIf="selectedRange.toLowerCase() === 'date range'" #picker [panelClass]="'datePickerClass'" >
                      </mat-datepicker>
                    </div>
                </div>
                <div *ngIf="showDiv" class="col-sm-12 range-list p-0 m-0 py-2">
                    <span *ngFor="let range of dateRangeOptions">
                        <div class="cursor date-range-label"
                            [ngClass]="{'selected-range': selectedRange.toLowerCase() === range.label.toLowerCase()}"
                            (click)="onDateRangeChange(range)">
                            <span class="px-2 py-2">{{range.label}}</span>
                        </div>
                    </span>
                </div>
            </div>

            <div *ngIf="commonService.showFlagIcon && showFlagsIcon" class="ml-2">
                <span class="cpy-drg-Icon-globe" (click)="showMenuRelatedFlags()">
                    <img src="/assets/images/FlagIcon.svg" matTooltip="Flags" class="globe_blue" />
                    <img src="/assets/images/FlagIconOnHover.svg" matTooltip="Flags" class="globe_white" />
                </span>
            </div>
            <div *ngIf="(selectedCaseUser == 'Group Case Comments' || selectedCaseUser == 'Group Level' || selectedCaseUser == 'Group ( Group Level )') && !hideManage && !isUserLevel && isAdmin3"
                class="create-upload-btn d-flex ml-2 justify-content-center">
                <div class="create-only" (click)="manageClicked()">
                    Manage
                </div>
            </div>

            <div *ngIf="(selectedCaseUser !== 'Group Case Comments' || selectedCaseUser !== 'All Users'|| selectedCaseUser == 'Group Level' || selectedCaseUser == 'Group ( Group Level )' || isMigrateForPayers == 'Payers') && !hideMigrate && enableMigrate && gridwidth == 0"
                class="create-upload-btn d-flex ml-2 justify-content-center">
                <div class="create-only" matTooltip={{tooltipForMigrate(this.templateData.menuURL)}}
                    (click)="migrateClicked()">
                    Migrate
                </div>
            </div>
            <!-- <div *ngIf="context == 'Sites' && isAdmin3"
                class="site-Order-button ml-2 mb-0">
                <div class="create-only"  (click)="migrateClicked()">
                    Site Order
                </div>
            </div> -->

            <div *ngIf="!hideEdit && ['Grossing Templates','Extraction Procedures','Clinical Information','Screening Types','Case Comments','Adequacy Codes','Rejection Templates','Sendout Reasons','Worklists'].includes(filename)"
                class="ml-2"
                [ngClass]="{'disabledButton': disableSort || searchText || conditionDisableReOrder || GridData?.length <= 1}">
                <span class="cpy-drg-Icon_sort" (click)="sortEnabled()">
                    <img src=".../../../../assets/icons/Reorder Blue.svg" matTooltip="Enable sorting"
                        class="sort_blue" />
                    <img src=".../../../../assets/icons/Reorder White.svg" matTooltip="Enable sorting"
                        class="sort_white" />
                </span>
            </div>

            <div *ngIf="!hideCopy && selectedCaseUser !== 'All Users'" class="ml-2">
                <span class="cpy-drg-Icon-globe" (click)="copyTemplate()">
                    <img src=".../../../../assets/icons/Globe_Blue.svg" matTooltip="Copy" class="globe_blue" />
                    <img src=".../../../../assets/icons/Global_white.svg" matTooltip="Copy" class="globe_white" />
                </span>
            </div>
            <div *ngIf="(!hideCreate || !hideUpload) && isMandoryFieldAvailable"
                class="create-upload-btn d-flex ml-2 justify-content-center" [ngClass]="{'btnDisabled': isBtnDisabled}">
                <div *ngIf="!hideCreate && isMandoryFieldAvailable" class="create-only" (click)="createClicked()">
                    Create
                </div>

                <div class="Standing-line-create" *ngIf="!hideUpload && !hideCreate">
                </div>

                <div *ngIf="!hideUpload" class="upload-icon" (click)="uploadTemplate()"><img
                        src=".../../../../assets/icons/Bulk Upload_White.svg" matTooltip="Upload"
                        style="height: 15px; width: 15px;">

                </div>
            </div>

        </div>
    </div>
    <div *ngIf="note && isAdmin3" class="row col-sm-12 button-field-wrap">
        <div class="float-left">
            <span><strong>{{note}}</strong></span>
        </div>
    </div>
    <hr class="separation-line m-0 p-0">
    <div class="container-fluid list-container col-sm-12 p-0 mt-0 text-center" *ngIf="showListAfterDataLoaded">
        <div class="col-sm-12 header d-flex py-1 mb-1" *ngIf="GridData?.length && !listLoading">
            <div class="col-sm-12 header-wrap">
                <span [ngClass]="header.colWidth" *ngFor="let header of gridHeader">
                    <div class="dataTable">{{header.Name}}</div>
                </span>

                <div class="col-sm-1 pr-0 edit-wraps dataTable">
                    <span *ngIf="!hideExport && GridData?.length > 0" (click)="ExportExcel()"><img
                            src=".../../../../assets/icons/Excel_Download.svg" matTooltip="Export" class="export-icon">
                    </span>
                </div>
            </div>
        </div>

        <div class="container-fluid col-sm-12 header-wrap p-0">
            <app-shimmerui [line]="8" class="mx-4 w-100" *ngIf="listLoading"></app-shimmerui>
            <div class="col-sm-12 p-0" *ngIf="!listLoading">
                <div class="col-sm-12">
                    <span
                        *ngIf="GridData?.length > 0 && (GridData | searchfiltersTemplate: templatefilter)?.length >0 ">
                        <cdk-virtual-scroll-viewport itemSize="15"
                            [ngClass]="(context === 'Manage Configurations' || context === 'Offline Process')?'tableScrollManageConfig cstcdkscroll':'tableScroll cstcdkscroll'"
                            *ngIf="GridData?.length > 0 ">
                            <!-- <div cdkDrag
                    [cdkDragDisabled]="true"
                    [cdkDragBoundary]="'.tableScroll'" > -->
                            <div [ngClass]="(item.IsActive === 'Inactive' || item.status === 'Inactive' || item.isActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive' || item.payersstatus === 'Inactive')?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                                *cdkVirtualFor="let item of GridData | searchfiltersTemplate: templatefilter"
                                (click)="RedirectToView(item, $event)" class="cursor">
                                <div [ngClass]="header.colWidth" class="text-muted   text-ellipsis dataTable" #isElipsed
                                    *ngFor="let header of gridHeader">
                                    <div *ngIf="!['expiry date', 'default', 'external db','send out lab'].includes(header?.Name?.toLowerCase())"
                                        appTooltipEllipsis #isElipsed class="text-muted   text-ellipsis dataTable">
                                        {{ displayedColumns(item,header) | stripHtml }}
                                    </div>
                                    <div
                                        *ngIf="(header?.Name?.toLowerCase() =='external db' || header?.Name?.toLowerCase() =='send out lab') && ['L2L Mapping'].includes(filename)">
                                        <img src="../../assets/icons/{{ item[header.key] ? 'Active_Green.svg' : 'Inactive_Red.svg'}}"
                                            style="width: 15px;position: relative;left: 20%;height: 17px;" />
                                    </div>

                                    <mat-icon
                                        *ngIf="header?.Name?.toLowerCase() =='default' && displayedColumns(item,header)== 'Yes'"
                                        style="position: relative;left: 35%;color: #0B9601; height: 17px;">check</mat-icon>

                                    <span *ngIf="header?.Name?.toLowerCase() =='expiry date'"
                                        [ngClass]="displayedColumns(item,header) == 'Expired' ? 'expiry-date' : ''">
                                        {{ displayedColumns(item,header)}}</span>
                                </div>

                        <div class="col-sm-1 btn-wraps d-flex" style="justify-content: end;">
                            <label class="ct-tag-toggle" *ngIf="isShowToggle" (click)="$event.stopPropagation();">
                                <input type="checkbox"  name="'badge'" (click)="$event.stopPropagation();" (change)="onToggleChange(item,$event)"
                                [checked]="!['Inactive', 'No', false, 0, ''].includes(item.IsActive) && !['Inactive', 'No', false, 0, ''].includes(item.status)">
                                <span class="slider"></span>
                            </label>
                            <mat-icon *ngIf="!hideFormCopy" class="pr-2 actions" matTooltip="Copy"
                                (click)="$event.stopPropagation(); formCopyMethod(item)">content_copy</mat-icon>
                            <mat-icon *ngIf="!hideEdit" class="actions ml-2" matTooltip="Edit"
                                (click)="$event.stopPropagation(); editMethod(item)" [ngClass]="{'btnDisabled': isBtnDisabled}">create</mat-icon>
                            <mat-icon *ngIf="hideEdit && isVeiwPage" class="actions ml-2" matTooltip="View"
                                (click)="$event.stopPropagation(); editMethod(item)">visibility</mat-icon>
                            <mat-icon *ngIf="!hideDelete" class="actions ml-2" matTooltip="Delete"
                                (click)="$event.stopPropagation(); deleteMethod(item)">delete</mat-icon>
                            <mat-icon *ngIf="!hideDowload" class="actions ml-2" (click)="downloadMethod(item)"
                            title="Download">download</mat-icon>
                        </div>
                    </div>
                <!-- </div> -->
                </cdk-virtual-scroll-viewport>
                    </span>
                    <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                        <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
                    </div>
                    <div class="col-sm-12 nodata-header-wrap"
                        *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
                        <span class="col-sm-12 nodata-wrapper"> No Results </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="sortModeEnabled" class="redorderContainer">
    <app-template-reorder [fileName]="filename" [isAdmin3]="isAdmin3" [sortableGridData]="sortableGridData"
        [sortSaveOrEditCompleted]="isSortSaveClicked" (returnList)="returnToList()"
        (saveReOrdering)="saveReorder($event)">
    </app-template-reorder>
</div>





<div *ngIf="!listScreen && !sortModeEnabled" [ngStyle]="{ height: !isAdmin3 ? 'calc(100vh - 150px)' : 'unset' }">
    <app-templates-view [HeaderName]="addEditContext==''?filename:addEditContext" [hideEdit]="hideEdit" [viewRecord]="ViewRowData"
        [templateData]="templateData" [createNewClicked]="createChanged" [editExistingClicked]="editChanged"
        [dynamicFormData]="customisedJsonData" [subMenuCardModel]="subMenuCardModel" [formNoteMessage]="formNoteMessage"
        [selectedListOption]="selectedListOption" [saveOrEditCompleted]="saveOrEditCompleted"
        (saveOrEditEventTriggered)="saveOrEditEventTriggered($event)" (closeViewTemplate)="handleViewScreen($event)"
        [isUserLevel]="isUserLevel" [isAdmin3]="isAdmin3" [enableorDisableSeqOnDefault]="enableorDisableSeqOnDefault"
        (editTemplateClickedFromView)="editTemplateClickedFromView($event)"
        [isHideTopSectionInCreate]="isHideTopSectionInCreate">
    </app-templates-view>
</div>