<!-- <div class="form-floating" >
    <input #ref  [type]="type" class="form-control" id="floatingInput"  [mask]="mask" placeholder="" [formControlName]="formcontrolname"  [prefix]="''"  [ngClass]="validateField == true ? 'validation' : 'my_class2'" [maxLength]="maxLength" [dropSpecialCharacters]="false" [specialCharacters]="['x','.','-','|','+','(',')',' ']" (focusout)="focusout(ref)" (keydown)="focus(ref)"  value="#ff0000" [validation]="false" oninput="this.value = this.value.toUpperCase()">
    <label  for="floatingInput">{{labelValue}}
        <ng-container *ngIf="required">
            <span class="text-danger fw-bold fs-6">*</span>
        </ng-container>
    </label>
    <span *ngIf="validateField && InvalidMessage.length > 0" class="secondaryHeading text-danger valideMessage">
        {{InvalidMessage}}
    </span>
    <ng-content></ng-content>
</div> -->
<div class="input-container" [ngClass]="{'passwordInput': inputType === 'password'}" *ngIf="formcontrolname!=null">
    <div class="input-elements">
      <input #ref [placeholder]="placeholder" [id]="id" [mask]="mask" [type]="type" [formControlName]="formcontrolname"  [prefix]="''"   [class.cform-in-valid]="!isFormValid" [maxLength]="maxLength" [dropSpecialCharacters]="false" [specialCharacters]="['x','.','-','|','+','(',')',' ']" (focusout)="focusout(ref)" (keydown)="focus(ref)"   [validation]="false"   [readonly]="isReadOnly"  
      [attr.autocomplete]="inputType === 'password' ? 'new-password' : null"
      [attr.autocorrect]="inputType === 'password' ? 'off' : null"
      [attr.autocapitalize]="inputType === 'password' ? 'off' : null"/>
      <label>{{labelValue}}<ng-container *ngIf="required">
        <span class="text-danger fw-bold fs-6">*</span>
        </ng-container>
      </label>
      <b *ngIf="subscript!=''">({{subscript}})</b>
      <img  *ngIf="inputType === 'password' && !showPassword" src="/assets/icons/visibility.svg" [matTooltip]="'show'" (click)="togglepassInput('hide');"/>
      <img *ngIf="inputType === 'password' && showPassword" src="/assets/icons/visibility_off.svg" [matTooltip]="'hide'" (click)="togglepassInput('show');"/>
    </div>
</div>
<div class="input-container" *ngIf="formcontrolname==null">
    <div class="input-elements">
  <input #ref  [type]="type" [maxLength]="maxLength"
  [placeholder]="placeholder" [class.cursor-pointer]="isReadOnly" [readonly]="isReadOnly" [class.cform-in-valid]="!isFormValid"
  [value]="selectedValue"  (keydown)="mask!=''?allowNumberfunction($event):null"
  (keyup)="focusoutInput(ref)"  (focus)="onfocus(ref)"
      [disabled]="isDisabled"   />
      <label>{{labelValue}}<ng-container *ngIf="required">
            <span class="text-danger fw-bold fs-6">*</span>
        </ng-container>
    </label>
    </div>
</div>

<!-- <div class="input-container" *ngIf="type.toLowerCase() === 'date'">
    <div class="input-elements">
        <input #ref type="text" [placeholder]="placeholder" [formControlName]="formcontrolname" [matDatepicker]="picker"  [class.cform-in-valid]="!isDateValid"  (keyup)="validateDate(ref)" [readonly]="isReadOnly"  />
          <label>{{labelValue}}
            <ng-container *ngIf="required">
            <span class="text-danger fw-bold fs-6">*</span>
            </ng-container>
        </label>
        <input style="display : none;" type="text" mask="00/00/0000"  [validation]="false"   [dropSpecialCharacters]="false" [specialCharacters]="['/']" [formControlName]="formcontrolname" /> 
        <mat-datepicker panelClass="vdm-custom-datepicker" #picker></mat-datepicker>
        <img class="ml-2 calender-image" src="/assets/icons/Calendar.svg"
            (click)="picker.open()" alt="datepicker" />
        </div>
</div> -->
