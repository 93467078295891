<div [ngStyle]="{'display': gridPage ? 'block' : 'none'}">
    <div class="modal-header admin-model-header">
        <h3 class="mainTitle modal-title w-100 admin-model-header-txt">
            Default Role Configuration
        </h3>

        <span type="button" data-dismiss="modal" (click)="SetConfigClose()" class="mr-2">
            <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                title="Close">
        </span>

    </div>
    <div [ngStyle]="{'display': showGrid ? 'block' : 'none'}" class="col-lg-12 h-70 m-md-1 m-lg-0" style="margin-bottom: 8px !important;">
        <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="gridData" [context]="'Default Role'"
            [hideEdit]="false" [hideCreate]="false" [hideUpload]="true" [hideExport]="false" [hideCopy]="true"
            [hideDelete]="true" (closingListTemplate)="handleListingScreen($event)" (editTemplateClicked)="newEditMethod($event)"
            (CreateTemplateClicked)="newCreateMethod()">
        </app-templates-listing>

        <div class="trackerDiv" *ngIf="activityTracker != 'CASES'" (click)="openActivityTracker()">
            <em class="fa fa-angle-up popout" *ngIf="isOver"></em>
            <button class="trackerButton"
            [class.mat-elevation-z2]="!isOver"[class.mat-elevation-z8]="isOver"  (mouseover)="isOver = true" (mouseleave)="isOver = false">
            <span>Activity Tracker</span>
            </button>
          </div>
          <lib-activity-tracker-lib *ngIf="openActivityTkr" [trackerInput]="trackerInput" [openActivityDialog]="true" [activityPath]="activityPath" (closeActivityDialog)="openActivityTkr = false" [apiUrl]="trackerApiUrl"></lib-activity-tracker-lib>
    </div>
    <!-- <div class="align-center mt-2 mb-2" *ngIf="showGrid && !ViewScreen">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('',[])">
            Create
        </button>
    </div> -->
</div>
<!-- Add/Edit User default roles -->
<div *ngIf="addEditScreen">
    <form [formGroup]="UserDefaultRoleForm" class="UserDefaultRoleForm"  style="height:80vh">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>{{action}}</strong>
                </h3>
            </div>
            <div class="text-wrap-Screening row col-sm-12 p-0 ml-0">
                <div class="row col-sm-12 mt-2">

                    <mat-form-field appearance="outline" class="w-100 col-sm-4 mt-3 emailfield">
                        <mat-label>
                            <strong>
                             Email</strong>
                        </mat-label>
                        <input type="text" maxlength="255" formControlName="frmuseremail" autoComplete='off' matInput
                            aria-label="UserEmail" [readonly]="action=='Edit Default Role' ? true : false">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4 mt-3">
                        <mat-label><strong>Role</strong></mat-label>
                        <mat-select disableOptionCentering formControlName="frmrolename">
                            <mat-option class="mat-opt-align" *ngFor="let role of RoleList"
                                value="{{role}}">
                                {{role}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-2 mt-3">
                        <mat-label><strong>Deployment</strong></mat-label>
                        <mat-select disableOptionCentering formControlName="frmdepkey">
                            <mat-option class="mat-opt-align" *ngFor="let depkey of DeploymentList"
                                value="{{depkey}}">
                                {{depkey}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-2 mt-3">
                        <mat-label><strong>Entity</strong></mat-label>
                        <mat-select disableOptionCentering formControlName="frmentity">
                            <mat-option class="mat-opt-align" *ngFor="let entity of EntityList"
                                value="{{entity}}">
                                {{entity}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                      <section  *ngIf="['edit default role'].includes(action?.toString()?.toLowerCase())" class="example-section col-sm-3 mb-3 pl-4">
                          <mat-checkbox [color]="task.color" class="mt-3 example-margin" formControlName="frmIsActive"
                              (change)="changeplan()"
                              value="{{UserDefaultRoleForm}}.value.frmIsActive">Active</mat-checkbox>
                      </section>

                </div>
            </div>
        </div>

        <div class="row mt-4 ml-1 mr-1">
            <div class="col-sm-3">
                <button mat-raised-button type="button" class="admin-btn-success mr-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button type="submit" mat-raised-button class="admin-btn-success mr-4"
                    (click)="createUserDefaultRoles('close',UserDefaultRoleForm.value)">
                    Save & Close
                </button>
                <button type="submit" *ngIf="action == 'Create Default Role'" mat-raised-button
                    class="admin-btn-success" (click)="createUserDefaultRoles('create',UserDefaultRoleForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>
