<div *ngIf ="gridPage">
    <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="GridData" [context]="'Worklists'"
    [hideEdit]="sourceApp =='VitalDx' ? true : editBtn" [hideCreate]="true" [hideUpload]="uploadBtn" [hideExport]="exportBtn" [hideCopy]="true"
    [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
    [showHeaderModule]="true"  [saveOrEditCompleted]="saveOrEditCompleted"
    [isAdmin3]="sourceApp =='VitalDx'? false : true" 
    (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
    (uploadTemplateEventTriggered)="uploadWorkLists()"
    (emitFilters)="onSelectionRole($event)"
    (editTemplateClicked)="worklistStatusUpdate($event)"
    [isShowToggle]="true && !editBtn"
    [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
    [conditionDisableReOrder]="disableReOrder"
    (sortingChanged)="getData()" (sortCompleted)="createActivityTrackerForSort($event)">
    </app-templates-listing>
</div>

<app-common-bulk-functions *ngIf="uploadbtnClicked" [templateData]="templateData" [uploadClicked]="uploadbtnClicked"
     (uploadBack)=backClicked($event)
    [cardContext]="'fromAccount'" [columnsToRemove]="['accountid']"></app-common-bulk-functions>
