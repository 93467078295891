<!-- <mat-spinner class="labadmin-spinner" *ngIf="sourceApp && !gridShow && !copyDataClicked"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp && !gridShow && !copyDataClicked">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>

<div [ngStyle]="{'display': gridShow ? 'block' : 'none'}">
    <div class="col-lg-12 p-0 m-md-1 m-lg-0">

        <app-templates-listing *ngIf="templateData" [gridHeader]="listedGridHeader" [gridData]="gridData"
            [context]="'Rejection Templates'" [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="sourceApp =='VitalDx' ? true : uploadBtn"
            [hideExport]="exportBtn" [hideCopy]="sourceApp =='VitalDx' ? true : copyBtn" (closingListTemplate)="handleListingScreen($event)"
            [hideDelete]="removeBtn" (editTemplateClicked)="newEditMethod($event)"
            (deleteTemplateClicked)="newDeleteMethod($event)"
            (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [templateData]="templateData"
            [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
            (copyTemplateEventTriggered)="loadCopyScreen()" (uploadTemplateEventTriggered)="loadUploadScreen()"
            [isAdmin3]="sourceApp =='VitalDx'? false : true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
            (sortingChanged)="getAllRejectionComments()" (sortCompleted)="createActivityTrackerForSort($event)">
        </app-templates-listing>
    </div>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Rejection Templates</strong>
        </h3>
    </div>
    <form [formGroup]="addRejectionCommentsForm" class="copy-collection">
        <div class="p-0 ml-0 mt-4">
            <div class="col-sm-12 text-wrap-icdcode">
                <mat-form-field class="col-sm-4 example-additional-selection mt-2 mat-height-wrap" appearance="outline">
                    <mat-label>Deployment</mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label>Group<span class="error-msg">*</span> </mat-label>
                    <input formControlName="frmOrganization" type="text" matInput #searchbar
                        (click)="fetchOrgSeries(addRejectionCommentsForm.value.frmOrganization)"
                        (keyup)="fetchOrgSeries(addRejectionCommentsForm.value.frmOrganization); trimFieldValue('frmOrganization')"
                        [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                title="{{ show.organizationname }} ({{show.organizationid}})" value="{{ show.orgname }}"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)">
                                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                    <button matSuffix mat-icon-button (click)="fetchOrgSeries(addRejectionCommentsForm.value.frmOrganization)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div *ngIf="gridwidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.result=='Success' ?'green':cell.item.result=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.result}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'organizationid') && !(columnname == 'AttributeId') && !(columnname == 'OrganizationId') && !(columnname == 'notes') && !(columnname == 'slno') && !(columnname == 'tablename') && !(columnname == 'casetype')"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <br />
                <div *ngIf="gridwidth > 0 && enableActive" class="row align-center pl-3 mt-2 ml-5">
                    <mat-checkbox [color]="task.color" class="font-style col-sm-2 m-0 p-0 ml-1" [checked]='checkStatus'
                        ng-init='checkStatus' ng-model='checkStatus' (change)='checkValue($event)'>
                        Copy as Inactive
                    </mat-checkbox>
                </div>
                <div class="col-sm-12 pl-0 mt-4">
                    <div *ngIf="gridwidth > 0" class="m-1 p-0">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
                            (click)="gridRemove()">Back</button>
                        <span style="float: right;">
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                                (click)="backSelect()">Cancel</button>
                            <!-- <div *ngIf="!btnDisable"> -->
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                                (click)="backSelect()">Finish</button>
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                                [disabled]="selectedData.length==0"
                                (click)="approveRejectionComment(true)">Approve</button>
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                                (click)="ExportExcel(flexgrid)">Download
                                Result</button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 pl-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0"
                    (click)="backSelect()">Back</button>
            </div>

            <div class="col-sm-12" *ngIf="gridwidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noData">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- Upload  -->
<div *ngIf="uploadClicked" class="my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Upload Rejection Templates</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('mandatoryFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('allFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <!-- <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('updateCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option> -->
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload {{templateData.menuURL}}.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">
            Back
        </button>
    </div>

    <div *ngIf="gridwidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
            [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
            <!-- Status -->
            <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.result=='Success' ?'green':cell.item.result=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.result}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
                [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
                    (click)="gridRemove()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0"
                    (click)="backSelect()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                    (click)="backSelect()">Cancel</button>
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                    [disabled]="approveDisable()" (click)="approveRejectionComment()">Approve</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                    (click)="backSelect()">Finish</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                    (click)="ExportExcel(grid)">Download
                    Result</button>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
