<app-templates-listing *ngIf="templateData && !create && gridShow" [gridHeader]="listedGridHeader"
    [gridData]="L2lgridData" [context]="'L2L Mapping'" [formNoteMessage]="noteMessage" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
    [saveOrEditCompleted]="saveOrEditCompleted" [isShowActiveTabs]=false [hideEdit]="editBtn" 
    [hideDelete]="deleteBtn" [hideCreate]="createBtn" (deleteTemplateClicked)="deleteL2lConfiguration($event)"
    [hideUpload]="!uploadBtn" [hideExport]="exportBtn" [templateData]="templateData" [templateData]="templateData"
    [subMenuCardModel]="SubMenuCardModel"  [showHeaderModule]="true" 
    [gridwidth]="0">
</app-templates-listing>

<!-- <div class="d-flex mt-3" *ngIf="create">
    <div class="col-sm-2 vdm-heading" *ngIf="!editclicked && create">
        <span>Create L2L Mapping</span>
    </div>
    <div class="col-sm-2 vdm-heading" *ngIf="editclicked">
        <span>Edit L2L Mapping</span>
    </div>
    <div class="col-sm-10 button-wrap p-0 mb-2">
        <button class="col-sm-1 btn secondary mr-2" type="button" (click)="returnbutton()">Return</button>
        <button class="col-sm-1 btn primary" (click)="saveL2LConfig(newAction,CreateForm)">Save </button>
    </div>
</div> -->


<!-- <section [formGroup]="CreateForm" class="col-sm-12 p-2 create-wrap" *ngIf="create">
    <div *ngIf="create">

        <div class="col-sm-12 p-0">
            <div class="col-sm-12 btn-wrap p-0">
                <div class="d-flex pr-3">
                    <span class="toggle-font">Send out lab:</span>
                    <div>
                        <app-toggle-switch type="secondary" formcontrolname="sendoutlab" class="pl-2 pr-2">
                        </app-toggle-switch>
                    </div>

                    <div class="d-flex">
                        <span class="toggle-font">External:</span>
                        <app-toggle-switch type="secondary" formcontrolname="external" class="pl-2">
                        </app-toggle-switch>
                    </div>
                </div>
            </div>
            <hr class="custom-hr">
            <div class="col-sm-12 pl-2">
                <div class="row">
                    <app-input-auto-complete labelValue="Source Lab" class="col-sm-3 pr-2 pb-2 pl-2 input-elements"
                        [required]="true" [isFormValid]="!((getters.sourcelab.dirty) && getters.sourcelab.invalid)"
                        [dropDownValue]="sourcelabdropdownvalues" [isReadOnly]='editclicked'
                        [value]="getters.sourcelab.value" (onSelected)="dropdownValues($event,'sourcelab')"
                        value="{{CreateForm.value.sourcelab}}" placeholder="Source Lab"></app-input-auto-complete>
                    <app-input-auto-complete labelValue="Destination Lab" class="col-sm-3 pr-2 pb-2 pl-2 input-elements"
                        [required]="true"
                        [isFormValid]="!((getters.destinationlab.dirty) &&getters.destinationlab.invalid)"
                        [dropDownValue]="destdropdownvalues" [isReadOnly]='editclicked'
                        [value]="getters.destinationlab.value" (onSelected)="dropdownValues($event,'destinationlab')"
                        value="{{CreateForm.value.destinationlab}}"
                        placeholder="Destination Lab"></app-input-auto-complete>
                    <app-custom-input class="col-sm-3 pr-2 pb-2 pl-2 input-elements" formcontrolname="mappingserver"
                        labelValue="Mapping Server"></app-custom-input>
                    <app-custom-input class="col-sm-3 pr-2 pb-2 pl-2 input-elements" formcontrolname="mappingdb"
                        labelValue="Mapping DB"></app-custom-input>
                </div>
            </div>
        </div>
        <div class="col-sm-12" *ngIf="message">
            <div class="row">
                <span class="text-message mb-2">
                    <img src="../../../assets/images/error.svg" class="error-icon" alt="assignment" />The source lab is
                    inactive
                </span>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <app-custom-input class="col-sm-3 pr-2 pb-2 pl-2 input-elements" formcontrolname="destlaburl"
                    labelValue="Destination Lab Url "></app-custom-input>
            </div>
        </div>
    </div>
</section> -->