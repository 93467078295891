export class VDTemplate {
    templateid? : number;
    organizationid? : number;
    context? : string;
    mode? : string;
    contexttype? : string;
    displayname? : string;
    template? : string;
    templatetype? : string;
    modifiedby? : number;
    productname? : string;
    accountid? : number;
    createdby? : number;
}

export class VDLocation {
        accountname?: string;
        accountid?: number;
        displayname?: string;
}

export class GroupCaseType {
    CaseType: string;
    DisplayName: string;
    OrganizationID: string;
    IsCompendium: boolean;
    AccountID: string;
    SequenceOrder: string;
    CAPRegulationFlag: boolean;
    IsAdequacy: boolean;
    CaseMetatype: string;
    Category: string;
    MaxJars: string;
    Gender: string;
    SignOutAsPreliminary: string;
    TemplateId: string;
    IsScreeningEnabled: boolean;
    IsRescreeningEnabled: boolean;
    IsDiagnosisTemplatesEnabledForScreening: boolean;
    isSMECaseType: boolean;
    isOtherSMECaseType: boolean;
    IsActive: boolean;
    FinalizeInProcessing: boolean;
    IsSkipGrossingStatus: boolean;
    OrderCode: string;
    SiteFormat: string;
}