<div *ngIf="!hideuserinfo" class="col-sm-12">
  <div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
      <strong>User Details</strong>
    </h3>
  </div>
  <!-- View userpersonal info part -->
  <div class="mt-3 _scroll">
    <div class="row">
      <div class="col-sm-6">
          <div class="row mt-2">
              <div class="col-sm-4">
                <label scope="row" class="font-size" aria-readonly="true"> VitalAxis Code </label>
              </div>
              <div class="col-sm-1">
                  <img alt="copy" class="copyIcon cursor" (click)="copy(resData.VitalAxis_Code)"  *ngIf="resData.VitalAxis_Code != 'Not Specified'" src="/assets/images/copy.svg" />
              </div>
              <div class=" col-sm-6">
                <span class="label-font">{{resData.VitalAxis_Code}}</span>
              </div>
            </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <label scope="row" class="font-size" aria-readonly="true">Title </label>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.title}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true"> Name </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.FormattedDisplayName}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Middle Initials </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.middleinitials}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true"> Alias Name </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.aliasName}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Login Name </span>
          </div>
          <div class="col-sm-1"
            *ngIf="userdisplayname && userdisplayname.toLowerCase() !== 'not specified' && userdisplayname.toLowerCase() !== 'null'">
            <img alt="copy" class="copyIcon cursor" (click)="copy(userdisplayname)" src="/assets/images/copy.svg" />
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{ userdisplayname && userdisplayname.trim() ? userdisplayname : 'Not Specified' }}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">GUID </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.ExternalUserGUID}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Name Display Format</span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.usernamedisplayformat}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Reports Display Format</span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.usernamereportformat}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">NPI </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.npi}}</span>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">UPIN </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.upin}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">ZIP </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.zip}}</span>
          </div>
        </div>
        <div class="row mt-2" *ngIf="organizationLoginType.toString().toLowerCase() =='email'">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Allow Login </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.AllowLogin == true ? 'True' : 'False'}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Qualification </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.Qualification}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Email </span>
          </div>
          <div *ngIf="resData.email!='Not Specified'" class="col-sm-1">
            <img alt="copy" class="copyIcon cursor" (click)="copy(resData.email)" src="/assets/images/copy.svg" />
          </div>
          <div *ngIf="resData.email==='Not Specified'" class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.email}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Phone </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.phone}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Address </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{ Address }}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Region </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.Region}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Gender </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.gender}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Created By</span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.createdby}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Created Date </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.createddate}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Modified By </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.modifiedby}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Modified Date </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.modifieddate}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Last Logged In Date </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{this.matchCreatedDate(resData.createddate,resData.LastLoggedInDate)}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">SSN </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.ssn}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Fax </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{resData.fax}}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-4">
            <span scope="row" class="font-size" aria-readonly="true">Status </span>
          </div>
          <div class="col-sm-1">
          </div>
          <div class=" col-sm-6">
            <span class="label-font">{{ !tabService.status ? resData.Status : tabService.status }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 mt-2">
        <span scope="row" class="bor-radious" aria-readonly="true">Roles</span>
      </div>
      <mat-chip-list class="col-sm-10 p-0">
        <ng-container *ngIf="myRolesArray.length > 0; else noRoles">
          <mat-chip *ngFor="let role of myRolesArray" [selected]="true" [color]="role.color" class="mr-2 mt-2">
            <span class="label-font">{{ role }}</span>
          </mat-chip>
        </ng-container>
        <ng-template #noRoles>
          <mat-chip color="warn" class="mr-2 mt-2">
            <span class="label-font">All assigned roles are currently disabled</span>
          </mat-chip>
        </ng-template>
      </mat-chip-list>
    </div>
    <div class="row">
        <div class="col-sm-1 mt-2 pt-2">
          <span scope="row" class="font-size" aria-readonly="true">WorkGroup</span>
        </div>
        <mat-chip-list class="col-sm-11 mt-3">
          <ng-container *ngIf="DepartmentList && DepartmentList?.length > 0 && !DepartmentList[0]?.toString().toLowerCase().includes('not specified'); else noDepartments">
            <mat-chip *ngFor="let deptName of  DepartmentList" selected color="chip.color" class="mb-2 mr-2">
              <span class="label-font mt-2 mb-2 mr-2">{{ deptName }}</span>
            </mat-chip>
          </ng-container>
          <ng-template #noDepartments>
            <span class="datafont">Not Specified</span>
          </ng-template>
        </mat-chip-list>
      </div>
  </div>

  <div class="sub-button mt-4">
    <button (click)="exportData()" mat-raised-button class="admin-btn-success mr-4">Export</button>
    <div *ngIf="!ifStatusDeleted">
        <button mat-raised-button [disabled]="hideDeleteBtn" class="admin-btn-success mr-4" (click)=" changeuserstatus('deleted')">Delete</button>
      <button (click)="edit()" [disabled]="hideUpdateBtn" mat-raised-button class="admin-btn-success">Edit</button>
    </div>

  </div>
</div>
<div *ngIf="hiteditmethod">
  <!-- <app-edit-user [userData]="resData" [userLoginType]="resData.LoginType" [organizationLoginType]="organizationLoginType"></app-edit-user> -->
  <app-manage-user [userData]="resData" [editMode]="editMode"
[fromComponent]="'userpersonalinfo'"  [organizationLoginType]="organizationLoginType"  [userLoginType]="resData.LoginType"  [creatLiveFlag]="CreateLive"></app-manage-user>
</div>
