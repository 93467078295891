<div *ngIf="templateData" class="col-lg-12 p-0 m-md-1 m-lg-0">
  <app-templates-listing [gridHeader]="listHeaderbackup" [gridData]="gridData" [context]="'Hot Keys'"
      [hideEdit]="editBtn || !isGynPapEnabled" [hideCreate]="createBtn || !isGynPapEnabled" [hideUpload]="true" [hideExport]="exportBtn" [hideCopy]="true"
      [hideDelete]="deleteBtn" (saveOrEditEventFromListingTriggered)="receiveObjFromListing($event)"
      [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel" (closingListTemplate)="handleListingScreen($event)"
      [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
      (deleteTemplateClicked)="deleteTemplate($event)" (emitFilters)="emitFilters($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true">
  </app-templates-listing>
</div>

<div class="px-4" *ngIf="sourceApp == 'VitalDx'  && !templateData">
  <app-shimmerui [line] = 8></app-shimmerui>
</div>
