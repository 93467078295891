<div class="px-4" *ngIf="sourceApp === 'VitalDx' && (!templateData || !sendGridData)">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>
<div class="container create-btn col-sm-12 align-center mt-4" *ngIf="noRole">
    <div class="col-sm-10 nodata-wrap">
        <div class="nodata-design">Eligible role not found for user</div>
    </div>
</div>
<!-- <div *ngIf="!userFlag && action=='Microscopic Notes'" class="button-field-wrap p-0 m-0 row col-sm-12">
    <div class="column instr-size mb-1 col-sm-12 pr-0 info-wrap">
        <img aria-hidden="true" src="/assets/icons/help.svg" alt="help" width="15px" height="auto"
            class="icon-align" />
        <span class="instr-size mb-1 col-sm-3 pr-0">
            You can select the location or user level templates to view the details in the Grid.
        </span>
    </div>
</div> -->
<div *ngIf="gridScreen">
    <app-templates-listing *ngIf="templateData && refreshRole" [gridHeader]="listedGridHeader" [gridData]="sendGridData"
        [context]="'Microscopic Codes'" [hideEdit]="hideUpdateBtn" [hideCreate]="hideCreateBtn"
        [hideUpload]="sourceApp =='VitalDx' ? true : hideUploadbtn" [hideExport]="hideExportBtn"
        [hideCopy]="sourceApp =='VitalDx' ? true : hideCopyToOrgBtn"
        [hideDelete]="hideDeleteBtn" (closingListTemplate)="handleListingScreen($event)" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [showTopButtons]="true" [isUserLevel]="userFlag"
        [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true" [hideManage]="false"
        (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
        (deleteTemplateClicked)="newDeleteMethod($event)" (copyTemplateEventTriggered)="copyFromOrg()"
        (uploadTemplateEventTriggered)="uploadMicroNotesExcelData()" (emitFilters)="emitFilters($event)"
        (manageEventTriggered)="openUserToggleDialog()" [isUserLevel]="userFlag" [hideMigrate]="hideMigratebtn"
        [enableMigrate]="migrateenable && !isP4Depl" (migrateEventTriggered)="MigrateGLdata()" [caseTypeUserRoles]="userRoles" [selectedRole]="selectedRole"
        (roleChangedEventTriggered)="roleChanged($event)" [gridwidth]="this.templateData.submenuData.length"
        [isAdmin3]="sourceApp =='VitalDx'? false : true" [isP4deployment]="false"
        [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"  [searchBarWidth]="sourceApp =='VitalDx' ? 'col-sm-6' : null">
    </app-templates-listing>
</div>
<div *ngIf="uploadClicked" class="my-micro-form">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Upload Microscopic Notes</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex header-View">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option *ngIf="!userFlag && !userSelected && !isP4Depl " class="temp-font-size"
                    (click)="downloadTemplate('min')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields(Upload)
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('all')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields (Upload)
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('update')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update (Update)
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Microscopic notes.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-3" (click)="userSelected = undefined; refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" (initialized)="initGrid(grid)" [itemsSource]="gridData" #grid
            [headersVisibility]="'Column'" [frozenColumns]="2" (formatItem)="formatItem(grid, $event)">

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            <!-- <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator> -->
        </wj-flex-grid>
        <div class="page-align">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <div class="row pl-3">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-wrap p-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <!-- -->
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        [disabled]="disableApprovebtn()" (click)="uploadMicroscopicData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download Result</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
<div *ngIf="copyfromScreen">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Microscopic Notes</strong>
        </h3>
    </div>
    <form [formGroup]="copyFromOrgFrm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDeplyment">
                    <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Entity<span class="error-msg">*</span></mat-label>
                <em class="fa fa-chevron-down chevron-align" (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"></em>
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                    (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization); trimFieldValue('frmOrganization')" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            *ngIf="show.organizationname.toLowerCase().includes(searchInput.toLowerCase())"
                            title="{{ show.organizationname }} ({{show.organizationid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                            value="{{ show.organizationname }}">
                            <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-sm-4  example-additional-selection" appearance="outline">
                <mat-label>User<span *ngIf="isP4Depl" class="error-msg">*</span></mat-label>
                <em class="fa fa-chevron-down chevron-align" (click)="fetchUserSeries(searchUserInput)"></em>
                <input type="text" matInput formControlName="frmUser" [(ngModel)]="searchUserInput" #searchuserbar
                    (keyup)="fetchUserSeries(searchuserbar.value); trimUserFieldValue('frmUser')"
                    (click)="fetchUserSeries(searchUserInput)" [matAutocomplete]="userauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #userauto="matAutocomplete">
                    <div *ngFor="let user of searchuserlist">
                        <mat-option class="mat-opt-align"
                            *ngIf="user.username.toLowerCase().includes(searchUserInput.toLowerCase())"
                            (onSelectionChange)="selectedCopyDataUser($event, user)"
                            value="{{ user.username }} ({{user.rolename}})"
                            title="{{ user.username }} ({{user.rolename}})">
                            <span>{{ user.username }} ({{user.rolename}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <div *ngIf="gridWidth == 0 && !noDataFound" class="col-sm-12 row align-center mt-2">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="userSelected = undefined; refreshGrid('list')">Back</button>
                <div class="dml-btn">
                    <button mat-raised-button class="admin-btn-success" (click)="getDataToCopy(copyFromOrgFrm)">Get
                        Data</button>
                </div>
            </div>
            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formatItem(flexgrid, $event)">
                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column
                            [visible]="!(columnname == 'accountid') && !(columnname == 'UserId') && !(columnname == 'preferencetype')&& !(columnname=='preferenceid')"
                            [binding]="columnname?.toString().toLowerCase()" [header]="columnname"  [format]="'d'"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <div class="col-sm-12 pl-0 mt-4">

                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid('back')">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="uploadMicroscopicData(true)">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download Result</button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12 mt-4" *ngIf="gridWidth == 0 && noDataFound">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="userSelected = undefined; refreshGrid('list')">Back</button>
                <div class="container create-btn col-sm-12 align-center">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
