import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VDTemplate } from 'src/app/model/vd-template.model';
import { VDLocation } from 'src/app/model/vd-template.model';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { Validators } from 'ngx-editor';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DesktoptemplateComponent } from './../desktoptemplate/desktoptemplate.component';
import { CollectionView } from '@grapecity/wijmo';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../core/services/excel.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-manage-vd-template',
  templateUrl: './manage-vd-template.component.html',
  styleUrls: ['./manage-vd-template.component.scss']
})
export class ManageVdTemplateComponent {
  SubMenuCardModel: SubMenuCardModel;
  @Input() isCopy: Boolean;
  @Input() templateData: [];
  destOrganizationId: string;
  destDeployment: string;
  locationDataList: Array<object> = [];
  DeploymentKeys: [];
  srcDeployment: string;
  organizationList: Array<object> = [];
  filteredSrcGroup: Observable<any[]>;
  filteredDestEntity: Observable<any[]>;
  destOrgName: string;
  gridData: CollectionView<any>;
  excelDataArray: any[];
  postUpload: Boolean;
  selector: Selector;
  selectedItems: any[];
  gridWidth: number = 0;
  sheetHeader: any;
  noDataFound: boolean;
  desEntityObj: { accountname: string; accountid: number; displayname: string; };
  disableGetDataBtn: boolean = true;
  showInvalidColumns: boolean;
  invalidColumns: any;
  sheetsToSelect: any;
  workBook: any;
  postDownload: boolean = false;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  workbookData: any;
  showDelete: boolean;
  productTypeList: any[];
  templateTypeList: any[];
  contextTypeList: any[];
  contextList: any[];
  modeList: any[];
  auditableColumns: any;
  activityEntity: any;
  displayNameList: any[];

  constructor(public ngxService: NgxUiLoaderService, public datePipe: DatePipe, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackBar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService,
    public DesktoptemplateComponent: DesktoptemplateComponent, public excelService: ExcelService,
    public activityService: ActivityTrackerService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  copyFormGroup = this._fb.group({
    frmSrcEntity: ["", Validators.required],
    frmSrcDeployment: "",
    frmSrcGroup: { LoginType: null, organizationid: null, organizationname: null },
    frmDestEntity: [new VDLocation(), Validators.required],
  })

  async ngOnInit() {
    this.destOrganizationId = sessionStorage.getItem('org_id') || "";
    this.destOrgName = sessionStorage.getItem('Org_Name') || "";

    this.destDeployment = sessionStorage.getItem('deploymentKey') || "";
    this.getAuditableDetails(this.templateData['menuURL']);
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);

    this.desEntityObj = {
      accountname: "<<" + this.destOrgName + ">> (All Locations)",
      accountid: -1,
      displayname: "<<" + this.destOrgName + ">> (All Locations)"
    }
    if (this.isCopy) {
      this.resetCopyPage();
      await this.getOrgLocationList(this.destOrganizationId, this.destDeployment)
      await this.getOrgList();

      this.filteredSrcGroup = this.copyFormGroup.controls.frmSrcGroup.valueChanges
        .pipe(
          startWith<any>({ LoginType: null, organizationid: null, organizationname: null }),
          map(val => this.filterData(val, 'SrcEntity', 'organizationname','organizationid'))
        );

      this.filteredDestEntity = this.copyFormGroup.controls.frmDestEntity.valueChanges
        .pipe(
          startWith<any>(new VDLocation()),
          map(val => this.filterData(val, 'DestEntity', 'displayname','accountid'))
        );

      this.copyFormGroup.controls.frmSrcGroup.valueChanges.subscribe(val => {
        if(!val || (val && val?.toString().trim() == ''))
        {
          this.copyFormGroup.patchValue({
            frmSrcGroup: { LoginType: null, organizationid: null, organizationname: null }
          })
        }
        this.resetCopyGrid();
        if (!this.copyFormGroup.controls.frmSrcGroup.value['organizationid'] || (!this.copyFormGroup.controls.frmDestEntity.value['accountid'])) {
          this.disableGetDataBtn = true;
        } else {
          this.disableGetDataBtn = false;
        }
      })

      this.copyFormGroup.controls.frmDestEntity.valueChanges.subscribe(x => {
        if ((!this.copyFormGroup.controls.frmDestEntity.value['accountid']) || (!this.copyFormGroup.controls.frmSrcGroup.value['organizationid'])) {
          this.disableGetDataBtn = true;
          this.resetCopyGrid();
        } else {
          this.disableGetDataBtn = false;
        }
      })
    }
  }

  //#region Filter function common for Source Group & Dest Entity
  filterData(filter: string, type: string, key: string, key1?: string): any[] {
    const dataArray = this.getMasterCopyForDropdown(type) ? this.getMasterCopyForDropdown(type) : [];
    if (dataArray && dataArray.length > 0) {
      filter = filter?.toString().trim();
      if (filter) {
        return dataArray.filter((option) => {
          return (
            key1 ? ((option[key]?.toString().toLowerCase().indexOf(filter?.toString().toLowerCase()) >= 0) ||
            (option[key1]?.toString() == filter?.toString())) 
            : (option[key]?.toString().toLowerCase().indexOf(filter?.toString().toLowerCase()) >= 0)
          );
        }).splice(0, 25);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  //#endregion

  //#region master data
  getMasterCopyForDropdown(type: string) {
    switch (type) {
      case 'SrcEntity': return (this.organizationList && this.organizationList.length > 0) ? this.organizationList : [];
      case 'DestEntity': return (this.locationDataList && this.locationDataList.length > 0) ? this.locationDataList : [];
    }
  }
  //#endregion

  //#region Reset copy form
  resetCopyPage() {
    this.copyFormGroup.patchValue({
      frmSrcEntity: 'Custom Source',
      frmSrcDeployment: this.destDeployment,
      frmSrcGroup: { LoginType: null, organizationid: null, organizationname: null },
      frmDestEntity: this.desEntityObj
    })
  }
  //#endregion

  //#region on Source Entity Change
  onSourceEntityChange(event) {
    if (event.value != 'Custom Source') {
      this.disableGetDataBtn = false;
      let orgid = event.value.toString().match(/Lab/) ? "-100" : "-200";
      this.copyFormGroup.controls["frmSrcDeployment"].patchValue(this.destDeployment);
      this.copyFormGroup.controls["frmSrcDeployment"].updateValueAndValidity();
      this.copyFormGroup.controls["frmSrcGroup"].patchValue({ LoginType: null, organizationid: orgid, organizationname: event.value });
      this.copyFormGroup.controls["frmSrcGroup"].updateValueAndValidity();
    } else {
      this.copyFormGroup.controls["frmSrcDeployment"].patchValue(this.destDeployment);
      this.copyFormGroup.controls["frmSrcDeployment"].updateValueAndValidity();
      this.copyFormGroup.controls["frmSrcGroup"].patchValue({ LoginType: null, organizationid: "", organizationname: "" });
      this.copyFormGroup.controls["frmSrcGroup"].updateValueAndValidity();
    }
    if (!this.copyFormGroup.controls.frmDestEntity.value || !this.copyFormGroup.controls.frmDestEntity.value['accountid']) {
      this.disableGetDataBtn = true;
    }
  }
  //#endregion

  //#region on Source Deployment Change
  onDeploymentChange(event) {
    if (event.source.selected) {
      this.copyFormGroup.patchValue({
        frmSrcGroup: { LoginType: null, organizationid: "", organizationname: "" },
        frmDestEntity: this.desEntityObj
      });
      this.resetCopyGrid();
      this.getOrgList();
      this.disableGetDataBtn = true;
    }
  }
  //#endregion

  //#region Reset Copy grid
  resetCopyGrid() {
    this.showInvalidColumns = false;
    this.noDataFound = false;
    this.gridWidth = 0;
    this.postUpload = false;
    this.selectedItems = [];
    this.excelDataArray = [];
  }
  //#endregion

  //#region Get Locations under dest organization 
  async getOrgLocationList(orgId: string, dbName: string) {
    try {
      let queryVariable = { orgid: orgId }
      let query = this.SubMenuCardModel.GetQuery("getorglocations");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      let data = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise();
      if (data) {
        this.ngxService.stop();
        if (!data.errors) {
          if (data) {
            this.locationDataList = data.data.submenuData || [];
            this.locationDataList?.map(x => ({ ...x, displayname: (x['displayname'] ? x['displayname'] : x['accountname']) }))
              .sort((a, b) => (a['displayname']?.toString().toLowerCase() < b['displayname']?.toString().toLowerCase() ? -1 : 1));
            this.locationDataList.unshift(this.desEntityObj);
          }
        }
      }
    } catch (exception) {
      console.log(exception);
    }
  }
  //#endregion

  //#region Get data to Copy from other entity
  async getData() {
    if (this.copyFormGroup.controls.frmSrcGroup.value) {
      let orgId = this.copyFormGroup.controls.frmSrcGroup.value['organizationid'].toString();
      await this.getTemplateData(orgId, this.copyFormGroup.controls.frmSrcDeployment.value);
    } else {
      this._snackBar.open("Please select Source group", "Close");
      return;
    }
  }
  //#endregion

  //#region get VD Template data based on OrgID and deployment
  async getTemplateData(orgId: string, dbName: string) {
    try {
      let queryVariable = { orgid: orgId }
      let query = this.SubMenuCardModel.GetQuery("getvdtemplates");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      let data = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise();
      this.ngxService.stop();
      if (data) {
        if (!data.errors) {
          const array = data.data.submenuData || [];
          (array.length > 0) ? this.validateVDTemplate(array) : this.noDataFound = true;
        }
      }
    } catch (exception) {
      this.ngxService.stop();
      console.log(exception);
    }
  }
  //#endregion

  //#region Get Organization names list for Copy screen from source deployment
  async getOrgList() {
    try {
      let queryVariable = {};
      let dbName = this.copyFormGroup.controls.frmSrcDeployment.value;
      let query = this.SubMenuCardModel.GetQuery('adduserfilterorg');
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      let data = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise();
      this.ngxService.stop();

      if (!data.errors) {
        if (data.data.Organizations_list && data.data.Organizations_list.length > 0) {
          this.organizationList = data.data.Organizations_list.sort((a, b) => (a['organizationname']?.toString().toLowerCase() < b['organizationname']?.toString().toLowerCase() ? -1 : 1));
        }
      }
    }
    catch (error) {
      this.ngxService.stop();
      console.error(error);
    }
  }
  //#endregion

  //#region returns to main grid page
  returnBack() {
    this.noDataFound = false;
    this.DesktoptemplateComponent.isCopy = false;
    this.DesktoptemplateComponent.isCopyOrBulk = false;
    this.DesktoptemplateComponent.getUpdatedGrid();
    this.DesktoptemplateComponent.goBack();
  }
  //#endregion

  //#region Function to add data to Bulk upload & Copy from other entity grid
  bulkGridData(data) {
    this.ngxService.stop();
    this.gridData = new CollectionView([]);
    if (data) {
      if (data.length > 0) {
        if (this.isCopy) {
          data = this.commonService.convertObjKeysToLower(data);
          this.sheetHeader = Object.keys(data[0]).filter(x => x != 'status' && x != 'notes' && x != 'templateid' && x != 'slno' && x != 'templateid' && x != 'accountid' && x != 'tablename' && x != 'organizationid' && x != 'modifiedby')
          this.sheetHeader.unshift('notes');
          this.postUpload ? this.sheetHeader.unshift('status') : null;
          this.sheetHeader.reverse();
        }
        this.excelDataArray = data;

        if (this.isCopy && this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        } else {
          this.showDelete = (data.length > 0 && !this.isCopy && !this.postUpload) ? true : false;
        }
        this.gridData = new CollectionView(data);

        this.gridWidth = 170 * this.sheetHeader.length + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      } else {
        this._snackBar.open('Please upload valid data', 'Close');
        return
      }
    }
  }
  //#endregion

  //#region selected values from copy page & tooltip for bulk & copy grid
  initGrid(flexgrid) {
    if (flexgrid) {
      this.selectedItems = [];
      // flexgrid.collapseGroupsToLevel(0);
      setTimeout(function () {
        if (this.flexgrid) this.flexgrid.refresh(true);
      });
      if (this.isCopy && !this.postUpload) {
        this.selector = new Selector(flexgrid, {
          itemChecked: () => {
            var va = flexgrid.rows.filter(r => r.isSelected);
            this.selectedItems = va;
          }
        });
      }
      const tt = new wjcCore.Tooltip();
      flexgrid.formatItem.addHandler((s, e) => {
        if (e.panel.cellType !== wjcGrid.CellType.Cell) {
          return;
        }
        if (s.getCellData(e.row, e.col) != null)
          tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      });
    }
  }
  //#endregion

  //#region apply color to the Warning, Error, & Success messages 
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().match(/Invalid/) || value.toString() == 'Mandatory field(s) missing!' || value.toString().match(/already exists!/) || value.toString().match(/Null values exist!/)) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().match(/added/) || value.toString().match(/approval/) || value == 'Success'
          || value.toString().match(/successfully/) || value.toString().match(/On approval/)
          || value.toString().match(/Configured successfully/) || value.toString().match(/approval/)) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }
  //#endregion

  //#region to display value in Entity & Group dropdown
  displayFn(selectedoption) {
    return selectedoption ? (('organizationname' in selectedoption) ? selectedoption.organizationname : selectedoption.displayname) : undefined;
  }
  //#endregion

  //#region Validate VD Template
  async validateVDTemplate(inArray: Array<VDTemplate>) {
    try {
      this.manageUploadKeys(inArray);

      this.ngxService.start();
      let res = await this.VitalHttpServices.validateVDTemplate(this.excelDataArray, this.destDeployment).toPromise();
      this.ngxService.stop();
      if (res.status == 'Success') {
        if (res.content?.length > 0) {
          if (res.content[0]["InvalidColumns"] && res.content[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = res.content[0].InvalidColumns;
          }
          this.excelDataArray.forEach(ele => {
            res.content.find(content => {
              if (content.slno == ele.slno) {
                ele.notes = content.notes;
              }
            })
          })
          this.bulkGridData(this.excelDataArray);
        } else {
          this.bulkGridData([]);
        }
      } else {
        this._snackBar.open("Error occurred while processing your request", "Close");
        return
      }
    } catch (error) {
      this.ngxService.stop();
      console.error(error);
    }
  }
  //#endregion

  //#region Customize the object Keys & Values
  manageUploadKeys(inArray) {
    let count = 1, accountId = null;
    let createdby = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : Number(sessionStorage.getItem("Userid"));
    if (this.isCopy) {
      accountId = this.copyFormGroup.controls.frmDestEntity.value ? Number(this.copyFormGroup.controls.frmDestEntity.value['accountid']) : null
      accountId = accountId == -1 ? null : accountId;
      inArray = inArray.map(({ location, Location, ...rest }) => ({ ...rest }))
    }
    this.excelDataArray = this.commonService.convertObjKeysToLower(inArray);

    this.excelDataArray.find((d): any => {
      Object.assign(d, {
        slno: Number(count), tablename: 'templates', organizationid: Number(this.destOrganizationId),
        accountid: this.isCopy ? accountId : (d.accountid ? Number(d.accountid) : null), modifiedby: createdby, templateid: null, notes: ""
      });
      count++;
    });
  }
  //#endregion

  //#region Validate excel/copy data before approving
  isValid(isCopy?: Boolean) {
    let isValidData = true;
    if (!isCopy) {
      if (this.excelDataArray && this.excelDataArray.length > 0) {
        if ((this.excelDataArray.some(x => !x.notes || (x.notes?.toString().match(/mandatory/i))
          || x.notes.includes('already exists') || (x.notes?.toString().match(/Invalid/i))
          || (x.notes?.toString().match(/already exists/i)) || x.notes.includes('already exists') || x.status == 'Ignored' || x.status == 'Failed'))
          || this.showInvalidColumns) {
          isValidData = false;
        }
      }
    } else {
      if (this.selectedItems && this.selectedItems.length > 0) {
        let data = this.commonService.convertObjKeysToLower(this.selectedItems);
        if ((data.some(x => !x._data.notes || (x._data.notes?.toString().match(/mandatory/i))
          || x._data.notes.includes('already exists') || (x._data.notes?.toString().match(/Invalid/i))
          || (x._data.notes?.toString().match(/already exists/i)) || x._data.notes.includes('already exists') || x._data.status == 'Ignored' || x._data.status == 'Failed'))
        ) {
          isValidData = false;
        }
      }
    }
    return isValidData;
  }
  //#endregion

  //#region Upload selected data in Copy from other entity
  uploadCopyData(flexGird) {
    this.selector = new Selector(flexGird, {
      itemChecked: () => {
        this.selectedItems = flexGird.rows.filter(r => r.isSelected);
      }
    });
    this.excelDataArray = [];
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    this.saveData();
  }
  //#endregion  

  //#region Save Data Insert/Update 
  async saveData() {
    try {
      if (this.isValid()) {
        this.ngxService.start();
        
        let result = await this.VitalHttpServices.addEditVDTemplate(this.excelDataArray, this.destDeployment,this.copyFormGroup?.controls?.frmSrcDeployment?.value).toPromise();
        this.ngxService.stop();
        if (result.status == 'Success') {
          this.postUpload = true;
          if (result.content?.length > 0) {

            this.excelDataArray.forEach(ele => {
              result.content.find(content => {
                if (content.slno == ele.slno) {
                  ele["status"] = content.status;
                  ele["notes"] = content.notes;
                  ele["template"] = content.fileName;
                }
              })
            })
            let str = this.isCopy ? 'Copy' : 'Upload';
            const dataArray = this.excelDataArray.map(({ slno, tablename, notes, status, templateid, ...rest }) => ({ ...rest }))
            this.commonService.auditDetails("templateid", "productname", [], dataArray, str, this.templateData, this.auditableColumns);
            this.bulkGridData(this.excelDataArray);
          } else {
            this.bulkGridData([]);
          }
        } else {
          this._snackBar.open("Error occurred while processing your request", "Failure");
          return;
        }
      } else {
        this._snackBar.open("Please upload the valid data", "Ignored");
        return;
      }
    } catch (exception) {
      this.ngxService.stop();
      console.error(exception);
    }
  }
  //#endregion

  //#region Export uploaded result
  ExportResult(flex) {
    const view = flex.collectionView;
    const grid = flex;
    let oldPgSize = view.pageSize, oldPgIndex = view.pageIndex;
    grid.beginUpdate();
    view.pageSize = 0;
    var wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(grid,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      null,
      saved => {
        view.pageSize = oldPgSize;
        view.sheetName = 'Templates'
        view.moveToPage(oldPgIndex);
        grid.endUpdate();
      },
      null
    );
    wb.sheets[0].name = 'Templates';
    wb.saveAsync("VD Templates_" + this.destOrganizationId.toString() + ".xlsx");
  }
  //#endregion

  onFileDropped($event) {
    this.onFileChange($event);
  }

  //#region Method to handle file drop or selection
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (
      splitarry[1].toUpperCase() != 'XLSX' &&
      splitarry[1].toUpperCase() != 'XLS'
    ) {
      this._snackBar.open('Please upload an excel file only.', 'Close');
    } else {
      reader.onload = event => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sample data');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(
              va => va.toLowerCase() == 'templates'
            );
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          } else {
            this.convertToJson(this.sheetsToSelect[0]);
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = '';
  }
  //#endregion

  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {};
    let tempArray = [];
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    tempExcelArr = tempExcelArr.filter(obj => {
      for (let key in obj) {
        if ((obj[key] !== null) && (obj[key] != 'null') && (obj[key] !== '')) {
          return true; // If any non-null value is found, keep the object
        }
      }
      return false; // If all values are null, discard the object
    });
    if (tempExcelArr.length == 0) {
      this._snackBar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    tempExcelArr.find(r => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
        if (primary[key] == "") {
          primary[key] = null;
        }
      }
      tempArray.push(primary);
      primary = {};
    });
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].reverse();
    temp[0].push("Notes");
    temp[0].push("Status");
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.commonService.convertObjKeysToLower(tempArray);
    this.validateVDTemplate(this.excelDataArray);
  }
  //#endregion

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }
  //#endregion

  //#region to get lookup values from vitaladminattributes table
  async getLookupValues() {
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { "keyword": "AllVDTemplatesDDL", "context": "VDTemplates" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, 'configdb').toPromise().then((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        if (Resdata.data.submenuData[0] && Resdata.data.submenuData[0].Items_JSON) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.productTypeList = [...data.ProductName].sort((a,b) => b-a);
          this.templateTypeList = [...data.TemplateType].sort((a,b) => b-a);
          this.contextTypeList = [...data.ContextType].sort((a,b) => b-a);
          this.contextList = [...data.Context].sort((a,b) => b-a).slice(0,15);
          this.modeList = [...data.Modes].sort((a,b) => b-a);
          this.displayNameList = [...data.DisplayName].sort((a,b) => b-a).slice(0,10);
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion

  //#region Downloads excel bulk upload template with sample data
  async downloadAllFields() {
    let bigData = [];
    let sampleData = [];
    await this.getLookupValues();
    bigData.push({ header: 'DisplayName', value: this.displayNameList }, { header: 'TemplateType', value: this.templateTypeList }, { header: 'Template', value: "" }, { header: 'Context', value: this.contextList })
    bigData.push({ header: 'ContextType', value: this.contextTypeList });
    bigData.push({ header: 'Mode', value: this.modeList });
    bigData.push({ header: 'ProductName', value: this.productTypeList }, { header: 'AccountID', value: "" });

    sampleData.push({ header: 'DisplayName', value: "Lables of Version 6" }, { header: 'TemplateType', value: "printer" }, { header: 'Template', value: "Supp_Dymo_1x1_Jar_03302023.nlbl" }, { header: 'Context', value: "Dymo" })
    sampleData.push({ header: 'ContextType', value: "Jar" }, { header: 'Mode', value: "Installed Printers" }, { header: 'ProductName', value: "VitalDesktop" })
    sampleData.push({ header: 'AccountID', value: "26275" });

    var finalData = {
      "Item": "Templates",
      "values": bigData
    }
    var FinalSampleData = {
      "Item": "Sample Data",
      "values": sampleData
    }
    let finalArrData = [];
    finalArrData.push(finalData);
    finalArrData.push(FinalSampleData)
    let fname = 'VD Templates_All Fields_';
    this.workbookData = this.transform(finalArrData);
    let resName = fname + '_' + sessionStorage.getItem('org_id');
    this.excelService.exportAsExcelFile(this.workbookData, resName);
  }
  //#endregion

  //#region creates work sheets for Sample data & excel upload header
  transform(data) {
    let noOfRowaToGenerate = 200;
    return data.map(({ Item, values }) => {
      if (Item == 'Sample Data') {
        noOfRowaToGenerate = 1;
      }
      const headers = values.reduce((prev, next) =>
      ({
        ...prev, [next.header]: Array.isArray
          (next.value) ? next.value.map(({ Item }) => Item) : next.value
      }), {})
      return {
        workSheet: Item,
        rows: Array(noOfRowaToGenerate).fill(headers)
      }
    })
  }
  //#endregion

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(
      va => va.slno != row.dataItem.slno
    );
    if (grid.rows.length == 0) {
      this.resetCopyGrid();
    }
    this._snackBar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region get auditable columns
  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
  //#endregion
}
