import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { saveAs as importedSaveAs } from "file-saver";
import { FormBuilder, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StaticMessages } from '../../utility/MessageConstants';

@Component({
  selector: 'app-bulk-upload-pop-up',
  templateUrl: './bulk-upload-pop-up.component.html',
  styleUrls: ['./bulk-upload-pop-up.component.scss']
})
export class BulkUploadPopUpComponent implements OnInit {
  errorMessage: string = ''
  uploadedFile : any
  fromManageLots : boolean = false
  panelSelectionModel = new SelectionModel(true, [])
  Panels: any[];
  DeviationType = [{ type: 'Standard Deviation (SD)',value:'standard' }, { type: 'Percentage (%)',value: 'percent'  }]
  showLoader : boolean = false

  constructor(
    private _labAdminService: LabadminService,
    private _dialogRef: MatDialogRef<BulkUploadPopUpComponent>,
    private _fb: FormBuilder,
    private _snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) { }

  lotExcelGenerationForm = this._fb.group({
    caseType : ['', [Validators.required]],
    panel : ['', [Validators.required]],    
    deviationType : ['', [Validators.required]],
    setDiffMinAndMax : [ false ]
  })

  ngOnInit(): void {
    this.fromManageLots = this.config.type === 'lots'
    this.onCaseTypeChange()
    if(this.config?.caseTypes.length == 1)
    {
      this.lotExcelGenerationForm.controls.caseType.patchValue(this.config?.caseTypes[0].Casetype)
    }
  }
  onCaseTypeChange() {
    this.lotExcelGenerationForm.get('caseType')?.valueChanges.subscribe(value => {
      this.panelSelectionModel.clear()
      this.Panels = []
      if (value)
        this.getPanels(value)

    });
  }

  onDrop(files: any) {
    if(files.length > 1){
      this.errorMessage = 'Error: Please Upload a Single File.'
    }
    else this.validateFile(files[0]);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    this.validateFile(input.files[0]);
  }

  validateFile(file){
   this.clearSelection()
    const regexp = new RegExp('^xlsm$')
    var ext = /[^\.]*$/.exec(file.name);
    if ((ext && !regexp.test(ext[0]))) {
      this.errorMessage = 'Error: File Format Not Supported'
    }
    this.uploadedFile = file
    this.uploadedFile.calculatedSize = `${Math.round(file.size / 1024)}KB`
  }

  downloadSampleTemplate(){
    return this._labAdminService.GetSampleTemplates(this.config.type, this._labAdminService.deploymentKey).subscribe({
      next: (res) => {
        let filename = 'UploadBulkFacilityUsers.xlsm'
        if(this.config.type === 'labusers') filename = 'UploadBulkLabUsers.xlsm'
        else if(this.config.type ==='referringphy') filename = 'UploadBulkReferringPhysicians.xlsm'
        else if(this.config.type ==='physician') filename = 'UploadBulkProviders.xlsm'

        importedSaveAs(res, filename)
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  getPanels(casetype: string) {
    this._labAdminService.GetPanels(this._labAdminService.deploymentKey, this.config?.primaryLabAccountId, casetype)
      .subscribe({
        next: (response: Panel[]) => {
          this.Panels = response;
        },
        error: (err) => {
          this._snackbar.open('Something Went Wrong','Close')
          console.error('Error:', err);
        }
      });
  }

  clearSelection(){
    this.uploadedFile = null
    this.errorMessage = ''
  }

  onUploadClick(){
    this._dialogRef.close(this.uploadedFile)
  }

  generateAndDownloadTemplate(){
    try{
      this.showLoader = true
      let obj = {
        "Casetype" : this.config?.caseTypes.find((j) => j.Casetype === this.lotExcelGeneration.caseType).CaseTypeDisplayName ,
        "DerivationType" : this.lotExcelGeneration.deviationType,
        "SetDifferentMinMax" : this.lotExcelGeneration.setDiffMinAndMax,
        "Levels": this.config.level.map((j) => j.AttributeValue),
        "OrganizationID" : this._labAdminService.organizationId,
        "Panels" : this.Panels.filter((k) => this.panelSelectionModel.selected.includes(k.OrderCodeID)).map((j) => ({'ProcedureName' : j.ProcedureName, 'OrderCodeID' : j.OrderCodeID}))
      }
     this._labAdminService.generateXlTemplate(obj).subscribe({
        next: (res) =>{
           importedSaveAs(res, "Manage Lot.xlsm")
           this.showLoader = false
        },
        error : (err) => {
          this.showLoader = false
          console.error(err)
          this._snackbar.open(StaticMessages.ON_ERROR, StaticMessages.CLOSE)
        }
      })
    }catch{
      this.showLoader = false
    }
    
  }

  get lotExcelGeneration(){
    return this.lotExcelGenerationForm.value
  }
}
export interface Panel {
  ProcedureName: string
  Sequence: number
  ProcedureCode: string
  OrderCodeID: number
}
