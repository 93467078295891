import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Tab } from 'src/app/base/Tab/tab.directive';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { InjectorService } from '../../../core/services/Injectorservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { ActionbtnComponent } from '../actionbtn/actionbtn.component';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import {  MatDialog } from '@angular/material/dialog';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-personalinfo',
  templateUrl: './UserPersonalInfo.component.html',
  styleUrls: ['./UserPersonalInfo.component.scss'],
  providers: [DatePipe]
})
export class UserPersonalInfoComponent extends BaseGridContainerComponent implements OnInit, OnChanges {
  formgrp_userInfo = this._fb.group({
    frm_vitalaxiscode:['',Validators.required],
    frm_Title: ['', Validators.required],
    frm_firstname: ['', Validators.required],
    frm_lastname: ['', Validators.required],
    frm_midname: ['', Validators.required],
    frm_Aliasname: ['', Validators.required],
    frm_LoginId: ['', Validators.required],
    frm_qualification: ['', Validators.required],
    frm_Workgroups: ['', Validators.required],
    frm_Gender: ['', Validators.required],
    frm_address1: ['', Validators.required],
    frm_address2: ['', Validators.required],
    frm_Street: ['', Validators.required],
    frm_City: ['', Validators.required],
    frm_State: ['', Validators.required],
    frm_Country: ['', Validators.required],
    frm_Zip: ['', Validators.required],
    frm_phone: ['', Validators.required],
    frm_Fax: ['', Validators.required],
    frm_Email: ['', Validators.required]
  })

  public resData;
  public SubMenuCardModel;
  public templatesetData;
  public tabs: Array<Tab> = [];
  public hiteditmethod: boolean = false;
  public hideuserinfo: boolean = false;
  public ifStatusDeleted: boolean;
  distinctRoleInfo: any=[];
  Address: any;
  userid: any;
  myRolesArray = [];
  backdata: any;
  loginname: any;
  hideUpdateBtn: boolean;
  downloadname: any;
  organizationLoginType: string = '';
  editMode : boolean = false;
  VitalAxisCode: any;
  hideDeleteBtn : boolean = false;
  CreateLive: boolean = false;
  userAuthStatus: any;
  LogName: any;
  UserName: any;
  userdisplayname: any;
  organizationguid: any;
  userloginname: any;
  DepartmentList: any = [];

  constructor(private injectorServiceGridContainer: InjectorService, private commonservice: CommonService, private clipboard: Clipboard, private _fb: FormBuilder, private ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar, private VitalHttpServices: VitalHttpServices, private tabService: VitalAdminTabService, public vitalHttpServices: VitalHttpServices, private commonService: CommonService, public ActionbtnComponent: ActionbtnComponent, public datepipe: DatePipe, public datashare: DataShareService,private dialog: MatDialog, public activityService: ActivityTrackerService) {
    super(injectorServiceGridContainer);
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }

  ngOnInit() {
    this.ngxService.stop('entityLoad');
    this.ngxService.start();
    this.viewDetails();
    this.GetButtondetails();
    this.getOrganizationLoginType()
    //this.usersAuthStatus();
    this.activityService.setActivitySession({ 'entityId': this.templateData.submenuData[0].CommonKey, 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': "Users" }] });
  }
  viewDetails() {
    this.hideuserinfo = false;
    if (this.templateData != undefined) {
      this.ngxService.stop();
      this.resData = this.templateData?.submenuData[0];
       this.usersAuthStatus(this.resData?.CommonKey)
      // this.loginname = this.resData.loginname
      if(this.resData.loginname){
        if((['email', 'autoinitiated', 'autoreinitiated'].includes(this.tabService.tabs[0].tabData.mainCard.User_Login_Type?.toString().toLowerCase())) && this.tabService.tabs[0].tabData.mainCard.Group_Login_Type?.toString().toLowerCase() == 'email'){
          this.userdisplayname =  !this.resData.email ? 'Not Specified' : this.resData.email;
        }
        else{
          this.userdisplayname = !this.tabService.tabs[0].tabData.mainCard.Login_Name ? 'Not Specified' : this.tabService.tabs[0].tabData.mainCard.Login_Name
        }
      }
      this.VitalAxisCode = this.resData.VitalAxis_Code
      if (this.resData.Status.toLowerCase() == "deleted") {
        this.ifStatusDeleted = true;
      } else {
        this.ifStatusDeleted = false;
      }

      Object.keys(this.resData).forEach(key => {
        if (key != 'AllowLogin') {
          if (!this.resData[key] || this.resData[key] == ' '
            || this.resData[key] == '') {
            this.resData[key] = 'Not Specified';
          }
        }
        else {
          this.resData[key] = this.resData[key] == 0 ? false : true
        }
      });
      if (this.resData.address2 == 'Not Specified') {
        this.Address = this.resData.address1;
      }
      else if (this.resData.address1 == 'Not Specified') {
        this.Address = this.resData.address2;
      }
      else {
        this.Address = this.resData.address1 + ', ' + this.resData.address2;
      }
      // if (this.resData.rolename) {
      //   this.myRolesArray = this.resData.rolename.split(',');
      // }
      this.DepartmentList = [];
      if (this.resData.DepartmentName) {
        this.DepartmentList = this.resData?.DepartmentName?.split(',');
      }
      this.downloadname = this.resData.FormattedDisplayName;
      this.userid = this.resData.userid
      sessionStorage.setItem('user_logintype', this.resData.LoginType);
    }
    else {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    }
  }
  getOrganizationLoginType() {
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id') };
    let query = this.vitalHttpServices.GetQuery("getorganizationname");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      if (res) {
        this.organizationLoginType = (res.data.submenuData[0].LoginType == null || res.data.submenuData[0].LoginType == '' || res.data.submenuData[0].LoginType.toLowerCase() == 'loginname') ? 'LoginName' : 'Email';

      }
    }, error => {
      console.error(error);
    })
  }
  edit() {
    this.hiteditmethod = true;
    this.hideuserinfo = true;
    this.editMode = true;
  }
  back() {
    this.hiteditmethod = false;
    this.hideuserinfo = false;
  }
  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open("Copied Successfully", "Close");
  }
  exportData() {
    const obj = {}
    const firstname = this.downloadname.split(" ");
    let data = firstname[0].charAt(0).toUpperCase()
    let date2 = firstname[1].charAt(0).toUpperCase()
    let filename ='User Details_';
    let data1 = (data + date2);
    if(data1){
      filename = filename + data1;
    if(filename.length >31){
      let fileName = filename.split('_')[0] +'_';
      let userName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
      if(userName.length > 31){
        fileName = filename.split('_')[0].replace(' ','') + '_';
        filename = fileName + userName +'_';
      }
      else{
        filename = fileName + userName +'_';
      }
    }
    else{
     filename = filename + '_';
    }
  }
    filename = filename + this.templateData.submenuData[0].Organizationid.toString()+'.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.submenuData[0]){
        filename = fileName  + this.templateData.submenuData[0].Organizationid.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    let commonKey = this.resData?.CommonKey || null;
    let id =  !commonKey ? this.resData?.userid || null : null;
    this.ngxService.start();
    this.vitalHttpServices.GetUserDetailsExport(commonKey, id).subscribe((resData:any) => {
      this.ngxService.stop();
      if (!resData.errors) {
        let roleData = JSON.parse(resData?.content);
        var ws = XLSX.utils.json_to_sheet(roleData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "" + 'Users');
        XLSX.writeFile(wb, filename);
        let acttrc = filename + " Exported";
        this.commonservice.createActivityTracker("Exported", this.userid, "User Details", 'Audit', obj, {}, acttrc);
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  update(commonKey) {
    this.ngxService.start("1");
    let queryVariable = { "accid": this.userid.toString() };
    let query = this.vitalHttpServices.GetQuery('userpersonalinfo');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      this.ngxService.stop("1");
      if (!Resdata.errors) {
        this.ActionbtnComponent.refresh();
        this.hiteditmethod = false;
        this.hideuserinfo = false;
        this.resData = Resdata.data.submenuData[0];
        // this.resData.loginname = this.loginname;
        if (this.resData.Status.toLowerCase() == "deleted") {
          this.ifStatusDeleted = true;
        } else {
          this.ifStatusDeleted = false;
        }
        if(this.organizationLoginType?.toString().toLowerCase() === 'email'){
         this.tabService.emailUpdated = this.resData.email;
        this.usersAuthStatus(this.resData?.CommonKey)
          if(this.resData.loginname){
            if((['email', 'autoinitiated', 'autoreinitiated'].includes(this.tabService.tabs[0].tabData.mainCard.User_Login_Type?.toString().toLowerCase())) && this.tabService.tabs[0].tabData.mainCard.Group_Login_Type?.toString().toLowerCase() == 'email'){
              this.userdisplayname = this.userdisplayname?.toString().toLowerCase() == 'not specified' ? this.tabService.emailUpdated : this.userdisplayname
            }
          }
        }
        Object.keys(this.resData).forEach(key => {
          if (key != 'AllowLogin') {
            if (!this.resData[key] || this.resData[key] == ' '
              || this.resData[key] == '') {
              this.resData[key] = 'Not Specified';
            }
          }
          else {
            this.resData[key] = this.resData[key] == 0 ? false : true
          }
        });
        if (this.resData.address2 == 'Not Specified') {
          this.Address = this.resData.address1;
        }
        else if (this.resData.address1 == 'Not Specified') {
          this.Address = this.resData.address2;
        }
        else {
          this.Address = this.resData.address1 + ', ' + this.resData.address2;
        }
        // if (this.resData.rolename) {
        //   this.myRolesArray = this.resData.rolename.split(',');
        // }
        this.DepartmentList = [];
        if (this.resData.DepartmentName) {
          this.DepartmentList = this.resData?.DepartmentName?.split(',');
        }
        this.userid = this.resData.userid
        sessionStorage.setItem('user_logintype', this.resData.LoginType);
      }
    }, error => {

      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
    });
  }
  //RBAC this
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Edit":
          this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
          case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
          case "CreateLive":
          this.CreateLive = seletedMenuPermissions[i].IsPermitted === 'true' ? true : false;
          break;
      }
    }
  }

  usersAuthStatus(commonkey) {
    let queryVariable = { "loginname": this.tabService.tabs[0].tabData.mainCard.Login_Name,"Commonkey":commonkey};
    let query = this.vitalHttpServices.GetQuery("getUserlogintype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
      if (res) {
        let queryVariable = { "userid": res.data.submenuData[0].userid.toString(),"Commonkey":commonkey };
        let query = this.vitalHttpServices.GetQuery("getUserInfofromuserid");
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.vitalHttpServices.GetData(queryResult).toPromise().then(data => {
          if (data){
            this.userAuthStatus = res?.data?.submenuData[0]?.UserAuthStatus
            this.LogName = res?.data?.submenuData[0]?.UpdatedLoginName
            this.UserName = res?.data?.submenuData[0]?.UserName
            this.organizationguid = res?.data?.submenuData[0]?.OrganizationGUID
            this.userloginname = res?.data?.submenuData[0]?.loginname
            this.distinctRoleInfo = Array.from(
              new Map(data?.data?.RoleInfo.map(item => [item.rolename, item])).values()
          );
          this.myRolesArray = [...new Set(this.distinctRoleInfo.filter(item => item.STATUS === 1).map(item => item.rolename) )];
          }
        }, error => {
          console.error(error);
        })
      }
    }, error => {
      console.error(error);
    })
  }


  changeuserstatus(key) {
    if(!this.hideDeleteBtn){
      this.deleteUser(key)
    }
  }

  deleteUser(key) {
    var obj =
    {
      "loginName": this.userloginname,
      "commonkey": this.resData.CommonKey,
      "Deletekey": key
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        header: 'Delete User',
        message: '',
        alert: 'Are you sure you want to delete the user ' + this.LogName + ' ?',
        continue: 'Yes',
        cancel: 'No',
        useRaisedButton: true
      },
    });
    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          this.ngxService.start();
          this.vitalHttpServices.DeleteUser(obj).subscribe(res => {
            let data: any = res;
            if (!data.error) {
              if (data.Success) {

                  this.ngxService.stop();
                  this._snackbar.open("User " + this.LogName + " deleted successfully", "Success");
                  // this.detailsDisplay = false;
                  this.update(this.resData.CommonKey);
                }

              }
            }, error => {
            console.error(error);
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", "Failed");
          });
        }
      });
  }

  matchCreatedDate(createddate,LastLoggedInDate){
    let colonIndexCD = createddate.lastIndexOf(':');
    let colonIndexLLD = LastLoggedInDate.lastIndexOf(':');
    if(colonIndexLLD != -1 && colonIndexCD != -1){
      return (createddate.substring(0,colonIndexLLD) == LastLoggedInDate.substring(0,colonIndexLLD)) ? 'NA' : LastLoggedInDate
    }
    return LastLoggedInDate;
  }
}
