import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as wjcCore from '@grapecity/wijmo';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';

@Component({
  selector: 'app-manage-payers-lookup',
  templateUrl: './manage-payers-lookup.component.html',
  styleUrls: ['./manage-payers-lookup.component.scss']
})
export class ManagePayersLookupComponent {
  lookuppage: boolean = false;
  searchData: boolean = false;
  PayersGridData: wjcCore.CollectionView<any>;//remove
  // @Output() gridshow: boolean;
  @Input() templateData: any;
  importData: any[] = [];
  dropdownvalues: any;
  listedGridHeader: any;
  lookupdata: any;
  payernameSearch: any;
  electronicidSearch: any;
  zipSearch: any;
  stateSearch: any;
  citySearch: any;
  sheetHeader: any[];
  radioChecked: boolean = false;
  deploymentkey: string = '';
  statedropdown: any[];
  PayerList: any;
  @Output() searchClicked: EventEmitter<any> = new EventEmitter<any>();

  gridHeaderList: any;
  gridheader: string[];
  mainlist: boolean = false;
  SubMenuCardModel: SubMenuCardModel;
  statedropdownlist: any[];
  GridData: any;
  isSelected: boolean=false;
  responseData: any;
  SetupInformation: any;
  constructor(
    private _fb: FormBuilder,
    private datashare: DataShareService,
    private commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private _dialogRef: MatDialogRef<ManagePayersLookupComponent>,
    private ngxService: NgxUiLoaderService,
    public templateHeaders: TemplateHeadersService,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }
  ngOnInit(): void {
    this.deploymentkey = sessionStorage.getItem('deploymentKey');
    this.dropdownValuesForStates();
    this.sheetHeader = ["Payer Name", "Electronic Payer ID", "Electronic Payer", "Payer Family", "Address", "State", "Phone", "Fax", "Email"];
    this.LookupForm.patchValue({
      payerName: this.config.prefilledData.payerName,
      electronicPayerId: this.config.prefilledData.electronicPayerId,
      city: this.config.prefilledData.city,
      state: this.config.prefilledData.state,
      zip: this.config.prefilledData.zip,
      addressandcontact: this.config.prefilledData.addressandcontact
    })
    this.search(); 
  }
  displayedColumns(item: any, header: any) {
    // Create a mapping between the display headers and the item keys
  const headerMapping: { [key: string]: string } = {
    'Payer Name': 'PayerName',
    'Electronic Payer ID': 'ElectronicPayerID',
    'Electronic Payer': 'IsElectronicPayer',
    'Payer Family': 'PayerFamily',
  };

  // Get the corresponding item key using the mapping, or use the header as-is
  const itemKey = headerMapping[header] || header;

  // Return the value from the item object using the mapped key
  return item[itemKey];
  }
  LookupForm = this._fb.group({
    payerName: ['', Validators.required],
    electronicPayerId: [''],
    city: [''],
    state: [''],
    zip: [''],
    addressandcontact: [false]
  })

  returnBtn() {
    this.templateData = false;
  }
  dropdownValuesForStates() {
    let queryVariable = { keyword: 'getDropDownValues' };
    let query = this.SubMenuCardModel.GetQuery('CategoryDropDown');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
   this.vitalHttpServices.GetData(queryResult, "configdb").toPromise().then(async data => {
      this.SetupInformation = data.data.submenuData[0];
      let dropdowndata = JSON.parse(this.SetupInformation.Items_JSON);
      this.statedropdown = dropdowndata.States.map(state => state.item);
    }, error => {
      console.error(error);
    })
  }

  stateValues($event: any, controlName: any) {
    this.LookupForm.patchValue({ [controlName]: $event });
  }

  search() {
    this.searchData = true;
    let query = this.SubMenuCardModel.GetQuery('payerssearch');
    this.lookupdata = this.LookupForm.controls;
    this.payernameSearch = (
      (this.lookupdata.payerName.value == '' || !this.lookupdata.payerName.value) ?
        this.config.prefilledData.payerName.toString().trim() :
        this.lookupdata.payerName.value.toString().trim()
    );
  //  if(!this.lookupdata.payerName.value || this.lookupdata.payerName.value.toString().trim() == '') {
  //   this._snackbar.open('Please provide payername','Close')
  //   return;
  //  }
    this.electronicidSearch = (
      (this.lookupdata.electronicPayerId.value == '' || !this.lookupdata.electronicPayerId.value) ?
        this.config.prefilledData.electronicPayerId.toString().trim() :
        this.lookupdata.electronicPayerId.value.toString().trim()
    );

    this.zipSearch = (
      (this.lookupdata.zip.value == '' || !this.lookupdata.zip.value) ?
        this.config.prefilledData.zip.toString().trim() :
        this.lookupdata.zip.value.toString().trim()
    );

    this.stateSearch = (
      (this.lookupdata.state.value == '' || !this.lookupdata.state.value) ?
        this.config.prefilledData.state :
        this.lookupdata.state.value.toString().trim()
    );

    this.citySearch = (
      (this.lookupdata.city.value == '' || !this.lookupdata.city.value) ?
        this.config.prefilledData.city.toString().trim() :
        this.lookupdata.city.value.toString().trim()
    );

    this.electronicidSearch = this.electronicidSearch === "" ? null : this.electronicidSearch;
    this.zipSearch = this.zipSearch === "" ? null : this.zipSearch;
    this.stateSearch = this.stateSearch === "" ? null : this.stateSearch;
    this.citySearch = this.citySearch === "" ? null : this.citySearch;
    let queryString =null;
     
    if(this.payernameSearch != null) {
      queryString = "organizationname like \"%" + this.payernameSearch + "%\"";
    }
    if (this.electronicidSearch != null) {
      if (queryString != null) {
        queryString += " AND electronicpayerid == \"" + this.electronicidSearch + "\"";
      } else {
        queryString = "electronicpayerid == \"" + this.electronicidSearch + "\"";
      }
    }  
    if (this.zipSearch != null) {
      if (queryString != null) {
        queryString += " AND zip == \"" + this.zipSearch + "\"";
      } else {
        queryString = "zip == \"" + this.zipSearch + "\"";
      }
    }   
    if (this.citySearch != null) {
      if (queryString != null) {
          queryString += " AND city LIKE \"%" + this.citySearch + "%\"";
      } else {
          queryString = "city LIKE \"%" + this.citySearch + "%\"";
      }
  } 
  if (this.stateSearch != null) {
      if (queryString != null) {
        queryString += " AND state LIKE \"%" + this.stateSearch + "%\"";
      } else {
          queryString = "state LIKE \"%" + this.stateSearch + "%\"";
      }
  }

    let queryVariable = {"filterParameter" : queryString};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.ngxService.stop();
          this.searchClicked.emit({}); 
          this.PayerList = data.data.submenuData; 
          if (data.data.submenuData.length == 0) {
            this.GridData = true;
          }
          else {
            this.GridData = false;
          }
        }
        if (data.length < 0) {
          this._snackbar.open('No records found', 'Close');
          return;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  handleRadioChange(event, data, index: any) {
    this.responseData=data;
    var radioButton = event.target;
    if (this.LookupForm.controls.addressandcontact.value == true) {
      data.address1 = '';
      data.address2 = '';
      data.phone = '';
      data.email = '';
    }
    if (radioButton.checked) {
      this.isSelected=true;
      this.radioChecked = index;
    }
    this.importData=[];
    this.importData.push(data);
  }

  saveData() {
    if(!this.isSelected) {
      this._snackbar.open('Please select the row','Close');
      return;
    }
    if(this.LookupForm.controls.addressandcontact.value == true) {
      this.responseData.address1 = '';
      this.responseData.address2 = '';
      this.responseData.Phone = '';
      this.responseData.Email = '';
    }
    this.importData.push(this.responseData);
    this._dialogRef.close(this.importData);
  }
}


