<div class="row casetypereporttemplate">
  <div class="col-md-12 p-0" *ngIf="!viewPage">
    <div class="modal-header admin-model-header mt-2 listingModalHeader pl-2">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong class="header-View">Casetype Report Templates </strong>
      </h3>
    </div>
  </div>
</div>

<section class="ang-mat-section casetypereporttemplate" *ngIf="!viewPage && gridLoaded">
  <div class="viewpageHeader">
    <div class="content-slab row" style="
    width: 68rem;margin-left: 0px;">
      <span class="col-sm-3 ml-3 pl-0">Name</span>
      <span class="col-sm-5 pl-0">Description</span>
      <span class="col-sm-3 ml-1 pl-0">Scope</span>
    </div>

    <div *ngIf="gridarray && gridKeys.length" class="templates-main">
      <div
        #scrollableDiv
        *ngIf="gridarray"
        [alwaysCallback]="true"
        (scroll)="onScroll($event)"
      >
        <div
          class="casetypetemplates-main outer-box p-0"
          *ngFor="let key of gridKeys"
        >
          <div class="d-flex mb-2">
            <span class="ml-1 mr-2"> Template Category : {{ key }}</span>
            <span class="f-12"></span>
          </div>
          <div
            class="items pt-0 pr-1 pl-1 pb-0"
            *ngFor="let item of gridarray[key]"
          >
            <div class="d-flex col-sm-12">
              <ng-container *ngFor="let header of columnheaders; let i = index">
                <span
                  *ngIf="item[header] !== key"
                  [ngClass]="{
                    'col-sm-5': header === 'Description',
                    'col-sm-3': header !== 'Description'
                  }"
                  class="text-truncate"
                  matTooltip=" {{ item[header] }}"
                >
                 {{ item[header] }}
                </span>

                <span *ngIf="item[header] === key" class="f-12">
                  <!-- Content for the special case can go here if needed -->
                </span>
              </ng-container>
              <div class="ml-auto d-flex align-items-center">
                
                <img
                  class="mr-2"
                  src="../../../../assets/icons/downloadicon.svg"
                  width="20px"
                  alt="download"
                  [matTooltip]="'Download'"
                  [class.disabled]="false"
                  [matTooltipDisabled]="false"
                  matTooltipPosition="left"
                  (click)="DownloadGitFile(item,'download')"
                  *ngIf="download"
                />
                &nbsp;&nbsp;&nbsp;
                <img
                  class="mr-2"
                  src="../../../../assets/icons/Edit-fill.svg"
                  width="15px"
                  alt="edit"
                  [matTooltip]="'Edit'"
                  [class.disabled]="false"
                  [matTooltipDisabled]="false"
                  matTooltipPosition="left"
                  *ngIf="editBtn"
                  (click)="caseTypeTemplateInformation(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!gridarray || !gridKeys.length" class="col-sm-12 text-center">
      <div class="col-sm-12 nodata-header-wrap">
        <span class="col-sm-12 nodata-wrapper">No Data Available</span>
      </div>
    </div>
  </div>
</section>

<div class="pl-2 casetypereporttemplate" *ngIf="viewPage">
  <div class="row m-0 p-0 align-items-center">
    <div class="col-md-10 p-0">
      <div class="modal-header admin-model-header mt-2 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong class="header-View"> {{ previewHeader }} Template </strong>
        </h3>
      </div>
    </div>
    <div class="float-right col-sm-2 p-0 mt-2 mb-2">
      <div class="p-0 button-wrap">
        <app-button
          buttonclass="secondary"
          class="ml-auto edit-page-button"
          (click)="returnToListpage()"
          >Return</app-button
        >
      </div>
    </div>
  </div>

  <div
    id="PreviewUploadTemplate"
    class="col-sm-12 p-0 border"
    *ngIf="!NotBlankTemplate"
  >
    <!-- <nav class="navbar vla-navbar navbar-expand-lg navbar-light bg-light "></nav> -->
      <div class="row casetypereporttemplate" [formGroup]="frm_Report">
        <div class="col-sm-10 p-0 d-flex align-items-center">
          <div class="casedropdownsection col-sm-7" *ngIf="!BlankTemplate">
            <div class="search-control-wrap d-flex">
             
              <div class="search-clients d-flex">
                  <div class="entity-dropdown col-sm-3">
                      <button
                      #dropdownButton
                        type="button"
                        class="menu_btn"
                        mat-button
                        data-toggle="dropdown"
                        id="dropdownMenu1"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="entity-list">{{ dropdownValue }}</span>
                        <!-- <em class="fa fa-chevron-down" *ngIf="!showDrop"></em> -->
                        <em class="fa fa-angle-down down-btn-align"></em>
                      </button>
                      <ul class="dropdown-menu" role="menu">
                        <li>
                          <ng-container
                            *ngFor="let item of DropDownValues; let index = index"
                          >
                            <button class="role searchdropdown" val (click)="OnChangeEntry(item)">
                              <strong style="
                              font-weight: bold;
                              font-size: 12px;
                          ">{{ item }}</strong>
                            </button>
                          </ng-container>
                        </li>
                      </ul>
                    </div>
                <input
                  type="text"
                  class="col-sm-9 border-1 adm-search-control"
                  placeholder="Enter {{ dropdownValue }}"
                  (input)="onInputChange($event.target.value)"
                  formControlName="frm_InputValue"
                  (keydown.enter)="CaseSearch(frm_Report,dropdownValue)"
                />

              </div>
            </div>
            </div>
            <div class="col-sm-3 services-dropdownform-control" *ngIf="!BlankTemplate">
                <div class="viewSelectContainer p-0 mr-2 dropdownStyle">
                    <mat-form-field class="col-sm-12 p-0 testingDrop">
                      <mat-label> Services </mat-label>
                      <mat-select
                        [(value)]="defaultSelectedService"
                        (selectionChange)="caseTypeTemplateInformation(backUpItem,$event.value)"
                        [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
                      >
                        <mat-option *ngFor="let service of Services" [value]="service">
                          <div class="truncate-text">
                            {{ service }}
                          </div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
              </div>
        </div>     
      </div>
    <!-- </nav> -->
  </div>

  <div class="mt-1 pr-0 pl-0"  *ngIf="!NotBlankTemplate && checkGridVisibility(frm_Report) && !BlankTemplate">
    <ng-container *ngIf="templateInfo && templateInfo.length">
      <div class="content-slab row">
        <span class="col-sm-3 gridtext">Case Id</span>
        <span class="col-sm-3 gridtext">Accession Number</span>
        <span class="col-sm-3 gridtext">Service</span>
        <span class="col-sm-3 gridtext">Status</span>
      </div>
      <div class="items col-sm-12 p-0">
        <div class="d-flex" *ngFor="let item of templateInfo">
          <div class="col-sm-3 gridtext">{{ item.Case_ID }}</div>
          <div class="col-sm-3 gridtext">{{ item.Accession_Number }}</div>
          <div class="col-sm-3 gridtext">{{ item.Service }}</div>
          <div class="col-sm-3 gridtext">{{ item.Status }}</div>
        </div>
      </div>
    </ng-container>
    
    <div *ngIf="templateInfo && templateInfo.length === 0" class="col-sm-12 text-center">
      <div class="nodata-foundheader">
        <span class="col-sm-12 nodata-wrapper">No Data Available</span>
      </div>
    </div>
  </div>

  <div class="outer-box  mb-2" *ngIf="!isEditClicked">
    <ng-container>
      <div class="col-sm-12 p-0">
        <div class="col-sm-12 d-flex align-items-center">
          <div class="col-sm-10 p-0">
            <span class="right-elements pointer">Existing template</span>
          </div>
          <div class="col-sm-1">
            <span
            *ngIf="(templateInfo && templateInfo.length && shouldShowPreviewHeader(previewHeader)) || shouldShowPreviewHeaderForBlank(previewHeader)" 
            [disabled]='preview'
              class="right-elements pointer button-wrap"
              (click)="PreviewExistingTemp(previewVariable)"
              >Preview</span
            >
          </div>
          <div class="updateDataBtn button-wrap" *ngIf="updateDataBtn">
            <app-button
              buttonclass="secondary"
              (click)="editTemplateName(null, 'view')"
              >Update Data</app-button
            >
          </div>
        </div>
      </div>
      <div class="col-sm-12 pl-0">
        <div class="downloadButton">
          <app-button
            buttonclass="primary" [disabled]="download"
            (click)="DownloadGitFile(null, 'download')"
            >Download</app-button
          >
        </div>
      </div>
    </ng-container>
  </div>

  <div class="outer-box" *ngIf="isEditClicked">
    <ng-container>
      <div class="col-sm-12 p-0 mb-2">
        <div class="col-sm-12 p-0">
          <div class="col-sm-12">
            <span class="right-elements pointer">Update Template Name</span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 pl-2 d-flex mb-1" [formGroup]="frm_Edit">
        <div class="p-0 col-sm-5 pr-2 d-flex templatenameinput" *ngIf="isCurrentTemplateAvailble">
          <app-input
          [isReadOnly] ='true'
            class="col-sm-12"
            [labelValue]="currentTemplateLabelName"
            placeholder="Current Template Name"
            formcontrolname="currentfilename"
            [maxLength]="255"
          ></app-input>
          <img class="mt-3 copyIcon copy-data cursor" alt="copy"
          (click)="copy(frm_Edit)"
          src="/assets/images/copy.svg" />
        </div>

        <div class="p-0 col-sm-5 d-flex">
          <app-input
            class="col-sm-12"
            [labelValue]="newTemplateLabelName"
            placeholder="New Template Name"
            formcontrolname="newfilename"
            [maxLength]="255"
            > </app-input>
          </div>
        </div>
        
      <div class="col-sm-12 button-wrap">
        <div class="cols-sm-9"></div>
        <div class="d-flex">
          <app-button
            buttonclass="secondary"
            class="ml-auto edit-page-button"
            (click)="returnToViewPage()"
            >Cancel</app-button
          >
          <app-button
            buttonclass="primary"
            class="edit-page-button ml-2"
            [disabled]="!isCurrentTemplateAvailble"
            (click)="onSaveReportTemplate(frm_Edit, 'update')"
            >Update</app-button
          >
        </div>
      </div>
    </ng-container>
  </div>

  <div class="outer-box" *ngIf="shouldShowPreviewHeader(previewHeader) || shouldShowPreviewHeaderForBlank(previewHeader)">
    <ng-container>
      <div class="col-sm-12 p-0">
        <div class="col-sm-12 d-flex p-0">
          <div class="col-sm-11">
            <span class="right-elements pointer">Try New Template</span>
          </div>
          <div class="col-sm-1">
            <span
              *ngIf="fileName"
              class="right-elements pointer button-wrap"
              [ngClass]="{ 'disabled': !fileName }"
              (click)="
                PreviewNewTemp('trynewtemplate')
              "
            >
              Preview
            </span>
          </div>
          
        </div>
      </div>

   

      <div class="col-sm-12 d-flex p-0 mt-1">
          <div class="col-sm-9">
            <div class="p-0">
              <form [formGroup]="frm_UploadReport" class="mb-2">
                <div *ngIf="!fileName"
                  class="image-height col-sm-6 dropzone"
                  (fileDropped)="onDrop($event)"
                  (click)="triggerFileInput()"
                  appDnd
                >
                  <input
                    type="file"
                    accept=".xml,.flxr"
                    id="myFileInputID"
                    #fileInput
                    class="input inputarea buttonalignleft no-outline mt-2 mb-2"
                    formControlName="filename"
                    (click)="$event.stopPropagation()"
                    (change)="fileChange($event)"
                    hidden
                  />
                  <div class="upload-section">
                    <img
                      src="/assets/images/upload_exceldata.svg"
                      alt="upload"
                      width="25px"
                      height="auto"
                      
                    />
                    <label class="uploadtext mb-0" >
                      UPLOAD TEMPLATE
                    </label>
                  </div>
                  <span>Click to Upload Or Drag & Drop the template file</span>
                </div>
                <div *ngIf="fileName" class="mt-2 col-sm-12 file-info">
                    <div class="col-sm-12 d-flex align-items-center">
                      <img
                        src="/assets/icons/filecontent.svg"
                        alt="file"
                        width="25px"
                        height="auto"
                        />
                        <!-- (click)="triggerFileInput()" -->
                      <span class="col-sm-11 filenametext">{{ fileName }}</span>
                      <button type="button" class="btn btn-link text-danger delete-button" (click)="removeFile();$event.stopPropagation();">
                        <i class="fa fa-trash delete-icon"></i> <!-- Ensure you have Font Awesome included for the trash icon -->
                      </button>
                    </div>
                  </div>
                  
              </form>
            </div>
          </div>
        </div>
        
        
    </ng-container>
  </div>
</div>
