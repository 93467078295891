import { Component, Input } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { CollectionView } from '@grapecity/wijmo';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-manage-report-signatories',
  templateUrl: './manage-report-signatories.component.html',
  styleUrls: ['./manage-report-signatories.component.scss']
})
export class ManageReportSignatoriesComponent {
  destDeployment: string = '';
  SubMenuCardModel: SubMenuCardModel;
  @Input() templateData: any;
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  // gridData: wjcCore.CollectionView<any>;
  gridData: any = [];
  showPaginationMainGrid: boolean;
  gridHeaderList: any;
  listHeaderbackup: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  receivedObject: any;
  saveOrEditCompleted: any;
  auditableColumns: any;
  deleteBtn: boolean;
  userSessionDetails: any;
  sourceApp: string;
  dbName: string;
  activityEntity: {};
  storeSub: Subscription = new Subscription();
  destAccountId: string;
  destOrgId: string;
  uploadbtnClicked: boolean = false;
  cardContext: string = 'fromAccount';

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }
  async ngOnInit() {
    this.destDeployment = this.vitalHttpServices?.deploymentKey?.toString();
    this.destAccountId = sessionStorage?.getItem('AccountID').toString();
    this.destOrgId = this.commonService?.orgid?.toString();
    this.addGridData(this.templateData?.submenuData);
    this.getAuditableDetails(this.templateData?.menuURL)
    this.GetButtonAccess(this.VitalHttpServices?.SubmenuAction)
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData?.headerText)['SubMenu'].find(ele => ele?.URL == this.templateData?.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions?.length; i++) {
      switch (seletedMenuPermissions[i]?.Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getAllReportSignatories() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel?.GetQuery('reportsignatories');
    queryVariable = {
      "accid": this.destAccountId
    };
    let queryResult = this.commonService?.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data?.data?.submenuData;
          this.templateData.submenuData && this.templateData?.submenuData?.length > 0 ? this.addGridData(this.templateData?.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = [];
        }
        this.ngxService.stop();
      },
      error => {
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  addGridData(templateData: any) {
    this.gridData = [...[]];
    let primary = {};
    this.gridHeaderList = this.templateHeaders?.templateList;
    for (let i = 0; i < this.gridHeaderList?.length; i++) {
      if (this.gridHeaderList[i]?.templateName == 'Report Signatories') {
        this.listedGridHeader = this.gridHeaderList[i]?.headers;
        this.listHeaderbackup = JSON?.parse(JSON.stringify(this.listedGridHeader))
      }
    }
    let gridarray = [];
    this.gridheader = ['Id', 'OrganizationId', 'AccountId', 'EntityNameId', 'EntityName', 'EntityType', 'UserId', 'UserName', 'IsActive', 'CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate']
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'EntityName' || key == 'EntityType' || key == 'UserId') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader?.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    gridarray.sort((a, b) => a.EntityType?.localeCompare(b.EntityType));
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
  }


  recieveObjFromListing(object: any) {
    delete object?.newData?.CaseType
    if (object.newData.UserId.length == 0) {
      this.ngxService.stop();
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this._snackbar.open("Please select at least one user", "Close");
      return;
    }
    if (object?.newData?.EntityType === 'Locations') {
      object.newData.EntityType = 'Accounts';
    }
    else if (object?.newData?.EntityType === 'Case Type') {
      object.newData.EntityType = 'AccountCasetype';
    }
    else if (object?.newData?.EntityType === 'Test Category') {
      object.newData.EntityType = 'TestCategory';
    }
    object.newData.AccountId = this.destAccountId
    object.newData.UserId = object?.newData?.UserId?.join(',');
    if (object?.newData?.Action == 'Edit') {
      object.newData['Id'] = object?.oldData['Id']
    }
    this.insertReportSignatories(object)
  }


  insertReportSignatories(data) {
    this.ngxService.start();
    this.vitalHttpServices.reportSignatoriesCE(data?.newData, this.destDeployment).subscribe(response => {
      if (response?.status == "Success") {
        this.ngxService.stop();
        if (response?.content?.message?.toString().includes('already exist')) {
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this._snackbar.open(response?.content?.message, "Close")
          return;
        }
        else {
          if (data.newData.Action === 'Create') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            data.newData.IsActive = data?.newData?.IsActive == 1 ? 'Active' : 'Inactive'
            data.newData.EntityName = response?.content?.EntityName;
            data.newData.EntityType = response?.content?.EntityType;
            data.newData.UserId = response?.content?.UserId;
            this.commonService.auditDetails('Id', 'EntityType', [response.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response?.content?.message, "Close")
            this.getAllReportSignatories();
          }
          else if (data.newData.Action === 'Edit') {
            let oldObj = {}
            oldObj = structuredClone(data.newData)
            delete oldObj['EntityName']
            delete oldObj['UserId']
            delete oldObj['UserName']
            oldObj['IsActive'] = data?.newData?.IsActive == 1 ? 'Inactive' : 'Active'
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            data.newData.IsActive = data?.newData?.IsActive == 1 ? 'Active' : 'Inactive'
            data.newData.EntityName = response?.content?.EntityName;
            data.newData.EntityType = oldObj['EntityType'] = response?.content?.EntityType;
            data.newData.UserId = data?.rowData?.UserName;
            this.commonService.auditDetails('Id', 'EntityType', [oldObj], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response?.content?.message, "Close")
            this.getAllReportSignatories();
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(response?.content?.message, "Close")
          }
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open("failed!", "Close")
        this.ngxService.stop();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  EditReportSignatories(data) {
    this.ngxService.start();
    let oldObj = {};
    oldObj = structuredClone(data)
    delete oldObj['EntityName']
    delete oldObj['UserId']
    delete oldObj['UserName']
    oldObj['IsActive'] = data.IsActive == 1 ? 'Inactive' : 'Active'
    this.vitalHttpServices.reportSignatoriesCE(data, this.destDeployment).subscribe(response => {
      if (response?.status == "Success") {
        this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        data.IsActive = data?.IsActive == 1 ? 'Active' : 'Inactive'
        data.EntityName = response?.content?.EntityName;
        data.EntityType = oldObj['EntityType'] = response?.content?.EntityType;
        data.UserId = data?.rowData?.UserName;
        this.commonService.auditDetails('Id', 'EntityType', [oldObj], [data], data.Action, this.templateData, this.auditableColumns);
        this.gridShow = false;
          this.getAllReportSignatories();
          this.ngxService.stop();
          this._snackbar.open(response?.content?.message, "Close");
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open("failed!", "Close")
        this.ngxService.stop();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  reportSignatoriesStatusUpdate(object) {
    let finalData = structuredClone(object.rowData);
    finalData.IsActive = object.event.srcElement.checked ? 1 : 0;
    finalData.AccountId = this.destAccountId
    finalData.EntityName = finalData.EntityNameId
    finalData.CreatedBy = finalData.ModifiedBy = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid");
    delete finalData.CreatedBy;
    delete finalData.CreatedDate;
    delete finalData.CreatedBy;
    delete finalData.ModifiedBy;
    delete finalData.ModifiedDate;
    delete finalData.EntityNameId;
    finalData.Action = 'Edit';
    if (finalData.EntityType === 'Locations') {
      finalData.EntityType = 'Accounts';
    }
    else if (finalData.EntityType === 'Case Type') {
      finalData.EntityType = 'AccountCasetype';
    }
    else if (finalData.EntityType === 'Test Category') {
      finalData.EntityType = 'TestCategory';
    }
    this.EditReportSignatories(finalData)
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices?.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res?.content?.JsonData);
    })
  }

  // uploadReportSignatories() {
  //   this.gridShow = false;
  //   this.uploadbtnClicked = true;
  // }

  backClicked(event) {
    this.uploadbtnClicked = false;
    this.gridShow = true;
    this.getAllReportSignatories()
  }
}
