<div mat-dialog-title class="lot-preview-header">
</div>
<mat-dialog-content class="preview-lots" [class.dx-integration]="isFullScreenEnabled">
    <div class="lot-preview-body">
        <div class="body-heading d-flex align-items-center">
            <div class="vdm-heading">
                <span appTooltipEllipsis>{{config.data.lotNo}}</span>
            </div>
            <span [class]="'lot-'+status">{{status | uppercase}}</span>
            <img class="ml-auto close-lot-preview" [autofocus]="true" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
        </div>
        <div class="body-form mt-3">
            <div class="lot-each-item">
                <span>Casetype</span>
                <span>:</span>
                <span class="lot-values">{{config.data.casetypeDisplayName}}</span>
            </div>
            <div class="lot-each-item">
                <span>Deviation Type</span>
                <span>:</span>
                <span class="lot-values">{{config.data.deviationType === 'standard' ? 'Standard Deviation (SD)' : 'Percentage (%)'}}</span>
            </div>
            <div class="lot-each-item">
                <span>Effective Date</span>
                <span>:</span>
                <span class="lot-values">{{config.data.effectiveDate | formatDate : 'MM/dd/YYYY' }}</span>
            </div>
            <div class="lot-each-item">
                <span>Level</span>
                <span>:</span>
                <span class="lot-values">{{config.data.level}}</span>
            </div>
            <div class="lot-each-item">
                <span>Total Units</span>
                <span>:</span>
                <div class="d-flex align-items-center justify-content-ceenter">
                    <span class="lot-values d-inline mr-1">{{config.data.avialableTotalUnits.split('/')[1]}}</span>
                    <span class="lot-values d-inline">( <span class="font-weight-normal">Available: </span> <span class="lot-values d-inline" [class.text-danger]="alertLessLots">{{config.data.avialableTotalUnits.split('/')[0]}}</span> )</span>
                </div>
            </div>
            <div class="lot-each-item">
                <span>Expiry Date</span>
                <span>:</span>
                <span class="lot-values" [class.text-danger]="compareTwoDates(estimatedLotExpiryDate,(config.data.expiryDate | formatDate : 'MM-dd-yyyy' ))">{{config.data.expiryDate | formatDate : 'MM/dd/YYYY'}} <span class="expired" *ngIf="expiryDate">({{expiryDate}})</span></span>
            </div>
            <div class="lot-each-item">
                <span>Panel</span>
                <span>:</span>
                <!-- <span class="lot-values w-100" >{{panelName}}</span> -->
                <div class="each-panel mb-2">
                    <span class="lot-values chips panel-color" *ngFor="let panel of config.data.panelName.split('|')">{{panel}}</span>
                </div>
            </div>
            <div class="lot-each-item">
                <span>Barcode</span>
                <span>:</span>
                <div class="each-panel mb-2">
                    <span class="lot-values" style="font-size: 20px;" *ngIf="!config.data?.barcodes">-</span>
                    <span class="lot-values chips barcode-color" *ngFor="let panel of config.data?.barcodes?.split('|')">{{panel}}</span>
                </div>
            </div>
            <!-- <div class="lot-each-item">
                <span>Available Units</span>
                <span>:</span>
                <span class="lot-values" [class.text-danger]="alertLessLots">{{config.data.avialableTotalUnits.split('/')[0]}}</span>
            </div> -->
        </div>
        <div class="d-flex">
            <app-search-box [autofocus]="true" class="w-25 ml-auto align-self-end" placeHolder="Search Result Name" (enteredValue)="searchValue = $event" [removeSearchIcon]="true"></app-search-box>
        </div>
    </div>
    <div>
        <div>
            <div class="panel-header" [class.panel-header-percent]="deviationType == 'percent'">
                <span>Result Name</span>
                <span>Target Value</span>
                <span *ngIf="deviationType != 'percent'">SD</span>
                <span>Acceptable Deviation ({{!setDifferentMinMaxDeviation ? '±' : ''}}{{sign}})</span>
                <span>Acceptable Range</span>
            </div>
            <div class="px-4" *ngIf="showLoader">
                <app-shimmerui [line] = "3"></app-shimmerui>
            </div>
            <div class="panel-body " *ngIf="!showLoader" [class.panel-body-percent]="deviationType == 'percent'">
                <div *ngFor="let result of objectValues | filterValues : searchValue">
                    <ng-container *ngIf="result.results.length > 0">
                        <span class="panel-heading-name">{{result.panelName}}</span>
                        <div class="each-item" *ngFor="let result of result.results">
                            <span [innerHTML]="result.siteName | highlightText:searchValue" >{{result.siteName}}</span>
                            <div>
                                <span>{{result.targetValue}}</span>
                            </div>
                            <div *ngIf="deviationType != 'percent'">
                                <span>{{result.standardDeviation}}</span>
                            </div>
                            <div class="min-max-accept-deviation">
                                <div>
                                    <!-- <span *ngIf="i == 0 && setDifferentMinMaxDeviation">Min</span> -->
                                    <span>{{result.minAcceptableDeviation}}</span>
                                </div>
                                <div [class.d-none]="!setDifferentMinMaxDeviation">
                                    <!-- <span *ngIf="i == 0">Max</span> -->
                                    <span>{{result.maxAcceptableDeviation}}</span>
                                </div>
                            </div>
                            <div class="min-max-accept-deviation">
                                <div>
                                    <!-- <span *ngIf="i == 0">Min</span> -->
                                    <span>{{result.minAcceptableRange}}</span>
                                </div>
                                <div>
                                    <!-- <span *ngIf="i == 0">Max</span> -->
                                    <span>{{result.maxAcceptableRange}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>