<h1 mat-dialog-title [ngClass]="{'h1-width': data.useRaisedButton || false}">{{data.header}}</h1>
<span class="migrate_wrap">
  <div mat-dialog-content [ngClass]="{'dialog-wrap': data.useRaisedButton || false}">
    <!-- <span [innerText]="data.message"></span> -->
    <span><strong style="font-weight: bold;">{{data.message}}</strong></span>
    <p><strong style="font-weight: bold;">{{data.alert}}</strong></p>
  </div>
</span>

<div mat-dialog-actions class="confirm_dia" [ngStyle]="{'justify-content': data.cancel === 'dontshow' ? 'center':''}">
  <button type="submit"  [ngClass]="{'mat-raised-button admin-btn-success align-center mr-4 data': data.useRaisedButton, 'md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs vo_btn': !data.useRaisedButton}" (click)="onNoClick()">
    {{data.continue}}
  </button>
  <button *ngIf="data.cancel !== 'dontshow'" [mat-dialog-close]="data.animal" cdkFocusInitial
  [ngClass]="{'mat-raised-button admin-btn-success align-center data': data.useRaisedButton, 'md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs vo_btn': !data.useRaisedButton}">{{data.cancel}} </button>
</div>
