import { Component, Input } from '@angular/core';
import { LabadminService } from '../labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { error } from 'console';
import { CommondetailsComponent } from '../labadmin/components/commondetails/commondetails.component';
import { take } from 'rxjs';


@Component({
  selector: 'app-infofax',
  templateUrl: './infofax.component.html',
  styleUrls: ['./infofax.component.scss']
})
export class InfofaxComponent {
  userid: number
  faxDetailsServices: any = [];
  faxDetailsCaseTypes: any = [];
  faxDetailsLocations: any = [];
  noDataFoundFlag: boolean = false;


  @Input() userDetials = {}
  @Input() fromLocation:string='';
  @Input() isHideLocationGrid:boolean = false;
  npiDetails = {}
  otherIdentifiers = []
  faxList = [];
  labList = [];
  locationList = [];
  showLoader:boolean = true;
  showToolTip:boolean = false;
  toolTipContent:any = [];
  currentType:string = ''
  constructor(private labadminService: LabadminService, private LabadminSessionService: LabadminSessionService,
    private commonDetails:CommondetailsComponent
  ) {

  }

  ngOnInit() {
    if(this.fromLocation == 'location'){
       this.getFaxAndPrintDetails()
    }
    else{
      if(this.userDetials['faxEnable'])
       this.getFAXDetails();
      else
      {
        this.noDataFoundFlag = true;
        this.showLoader = false
      } 
    }

  }

  getFAXDetails() {
    let userData: any;
    this.LabadminSessionService.getLabAdminSession.pipe(take(1)).subscribe(Response => userData = Response);
    let accountIds = userData.userDetails['userAccountIDs']?.map(account => account.accountId).join(',');
    let objData = {
      LabAccountIds: accountIds,
      UserId: this.userDetials['UserID'],
      SearchValue: this.userDetials['UserID'] + '_Fax',
      Type: 'User',
      OFAccountId: this.userDetials['AccountId'],
      LoggedInUserId: userData.userDetails['userId']
    }
    this.showLoader = true;
    this.commonDetails.getFaxDetailsC(objData).subscribe(data => {
      if (Object.values(data?.content).some((arr: any) => arr?.length > 0)) {
        this.faxDetailsLocations = data?.content?.faxDetailsLocations?.map((item: any) => item.AccountName);
        this.faxDetailsCaseTypes = data?.content?.faxDetailsCaseTypes?.map((item: any) => item.CaseTypeDisplayname);
        this.faxDetailsServices = data?.content?.faxDetailsServices?.map((item: any) => item.Services);
      }
      else {
        this.noDataFoundFlag = true;
      }
      this.showLoader = false;
    })
  }
  getFaxAndPrintDetails(){
    let userData: any;
    this.LabadminSessionService.getLabAdminSession.pipe(take(1)).subscribe(Response => userData = Response);
    let accountIds = userData.userDetails['userAccountIDs']?.map(account => account.accountId).join(',');
    let objData = {
      LabAccountIds: accountIds,
      Type: 'Location',
      OFAccountId: this.userDetials['AccountID'],
      LoggedInUserId: userData.userDetails['userId']
    }
this.commonDetails.getFaxDataForLocationC(objData).subscribe(data => {
      this.showLoader=false;
      if(data){
        this.faxList = data?.content?.fax || [];
        this.labList = data?.content?.lab || [];
        this.locationList = data?.content?.location || [];
        if(this.faxList.length <= 0 && this.labList.length <= 0 && this.locationList.length <= 0){
          this.noDataFoundFlag = true;
        }
        else{
          this.noDataFoundFlag = false;
        }
      }
    },(error) =>{
      this.showLoader = false;
      this.noDataFoundFlag = true;
      console.error(error);

    });
  }

  count(type:string){
    if(type == ''|| type == null || type == undefined){
      return 0;
    }
    return type.split('|').length;
  }
  
  showList(type:string){
    return  type.split('|');
  }

  onMouseEnter(type:string,currentType:string) {
    if(type == '' || type == null || type == undefined){
      this.toolTipContent = [];
      this.currentType = '';
      this.showToolTip = false;
    }
    else{
      this.currentType = currentType;
    this.toolTipContent = type.split('|');
    this.showToolTip = true
    }
  }
  onLeaveMouse() {
     this.currentType = '';
     this.toolTipContent = '';
     this.showToolTip = false;
  }
}
