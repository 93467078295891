import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { VitalAdminTabService } from '../../tab.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'search-panel',
  templateUrl: './searchpanel.component.html',
  styleUrls: ['./searchpanel.component.scss']
})
export class SearchPanelComponent {
  custfilter = {
    Case_Type: "",
    Status:"",
    Display_Name:"",
    Last_Created_Accession_Number:"",
    Case_Type_Category:"",
    Last_Created_Accession_Date:""
    // casetype: "",
    // orderingphysician: "",
    // collectiondate: "",
    // caseStatus: "",
    // labaccount: "",
    // TestDetails: ""
  };
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  srcheight: number;
  filOrText: string;
  hideSearchbar: boolean=true;

  constructor(private commonService: CommonService, private datashare:DataShareService,private ref: ChangeDetectorRef, private activatedroute: ActivatedRoute,
    private router: Router, private vitalHttpServices: VitalHttpServices, public tabService: VitalAdminTabService,private activityService: ActivityTrackerService) {

  }
  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(this.templateData.submenuData==null)
    {
      this.hideSearchbar=false;
    }
    this.sortByKey(this.templateData.submenuData,"Case_Type","ASC")
  }
  filterpatient() {
    this.custfilter.Case_Type = this.filOrText;
    this.custfilter.Status = this.filOrText;
    this.custfilter.Display_Name = this.filOrText;
    this.custfilter.Last_Created_Accession_Number = this.filOrText;
    this.custfilter.Case_Type_Category = this.filOrText;
    this.custfilter.Last_Created_Accession_Date = this.filOrText;
  }
  AddTab(accountid: any, accounttype: any, caseTypeObject?) {
    this.vitalHttpServices.CaseTypeDisplayName = caseTypeObject.Display_Name;
    let tab: any = {};
    tab.accountid = accountid;
    tab.keyword = "casetype";
    tab.cardtype = accounttype;
    tab.text = accounttype;
    tab.Removable = true;
    this.tabService.addNewOrSelectTab(tab);
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.datashare.menulist.subscribe(data =>{
      if(data){
        this.srcheight = data+162
      }

    })
    this.activityService.setActivitySession({'entityId': '','entityType': '', 'context':[{'key':'parentMenu','value':'casetype'}]})

    // this.srcheight = window.innerHeight - 197
  }
  sortByKey(array, key, order) {
    var sortkey = key.split('.');
    if (sortkey.length > 1) {
      if (order === "ASC") {
        return array.sort(function (a, b) {
          var x = parseFloat(a[sortkey[0]][sortkey[1]]); var y = parseFloat(b[sortkey[0]][sortkey[1]]);
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      } else {
        return array.sort(function (a, b) {
          var x = b[sortkey[0]][sortkey[1]]; var y = a[sortkey[0]][sortkey[1]];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      }
    } else {
      if (order === "ASC") {
        return array.sort(function (a, b) {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      } else {
        return array.sort(function (a, b) {
          var x = b[key]; var y = a[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
      }
    }
  }
}
