import { Component } from '@angular/core';
import { LabadminService } from '../../../labadmin.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';

@Component({
  selector: 'app-pathologistmanagenment',
  templateUrl: './pathologistmanagenment.component.html',
  styleUrls: ['./pathologistmanagenment.component.scss']
})
export class PathologistmanagenmentComponent {
  isExpandSideBar:boolean = true;
  sidemenuData: any;

// constructor(private labadminsService: LabadminService,private vitalHttpServices:VitalHttpServices,private commonService :CommonService){

// }

ngOnInit(){
//   console.log(this.labadminsService.selectedSubmenu['SubMenuCardName'])
//   let newQuery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "getVitalAdminMenus")[0].Query;
//   let newQueryVariable = {
//     // CardName: this.context
//     CardName: this.labadminsService.selectedSubmenu['SubMenuCardName']
//   };
//   let newQueryResult = this.commonService.GetCardRequest(newQueryVariable, newQuery)
//   this.vitalHttpServices.GetData(newQueryResult).subscribe((newResData) => {
// let data =JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
// console.log(data);
// this.sidemenuData = data;
// // this.menuList = data
// // this.labAdminService.menuList = data;
//   })
}

  collapseSideNav(event){
      this.isExpandSideBar = !this.isExpandSideBar
  }
}
