import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, Validators, FormControl } from '@angular/forms';
import {  ThemePalette } from '@angular/material/core';
import {  MatOption,  MatOptionSelectionChange } from '@angular/material/core';
import {  MatSelect } from '@angular/material/select';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import {  MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { map, pairwise, scan, startWith, switchMap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import {  MatAutocompleteTrigger } from '@angular/material/autocomplete';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { SetupNewLocationComponent } from '../setup-new-location/setup-new-location.component';
import { cloneDeep } from 'lodash';
import { merge } from 'rxjs'
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface Task {
  color: ThemePalette;
}

// @Component({
//   selector: 'app-manage-sequence-wheel',
//   templateUrl: './manage-sequence-wheel.component.html',
//   styleUrls: ['./manage-sequence-wheel.component.scss']
// })
// export class ManageSequenceWheelComponent implements OnInit {
  @Component({
    selector: 'app-identity-formats',
    templateUrl: './identity-formats.component.html',
    styleUrls: ['./identity-formats.component.scss'],
  })
  
  export class IdentityFormatsComponent implements OnInit {
  codeList: any = [];
  dateList: any = [];
  task: Task = { color: 'primary' };
  allSelectedCodes = false;
  allSelectedCustomCodes = false;
  allSelectedCustomDates = false;
  actionTitle = "Sequence Scheme";
  allSelectedDates = false;
  allSelectedCasetypes = false;
  allSelectedService = false;
  allSelectedBillTo = false;
  addEditScreen = false;
  gridScreen = true;
  @ViewChild('matSelectOptionCodes') matSelectOptionCodes: MatSelect;
  @ViewChild('matSelectOptionCustomCodes') matSelectOptionCustomCodes: MatSelect;
  @ViewChild('matSelectOptionDate') matSelectOptionDate: MatSelect;
  @ViewChild('matSelectOptionCustomDate') matSelectOptionCustomDate: MatSelect;
  @ViewChild('matSelectOptionCasetype') matSelectOptionCasetype: MatSelect;
  @ViewChild('matSelectOptionBillTo') matSelectOptionBillTo: MatSelect;
  @ViewChild('matSelectOptionServiceType') matSelectOptionServiceType: MatSelect;

  @Input() templateData: any;
  public SubMenuCardModel;
  public deploymentKeys = [];
  casetypeList: any = [];
  billToList: any = [];
  serviceList: any = [];
  validateCasetype: boolean = false;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  cardClicked: any = [[false, false, false, false, false, false], [false, false, false, false, false, false]];
  gridArray: any[];
  showDefaultFormats: boolean = false;
  formatGridData: wjcCore.CollectionView<any>;
  gridWidth: number = 0;
  showPaginationMainGrid: boolean = false;
  destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
  columns: ColDef[];
  searchContext = sessionStorage.getItem("search_context").toLowerCase();
  removable = true;
  destOrgid = sessionStorage.getItem('org_id').toUpperCase();
  standardFormat: any = [];
  rangeList: any = [];
  rangeLength: any = { frmDefaultFormat: 3, frmCustomFormat: 3 };
  finalArray: any = [];
  selectedrowData: any = {};
  editEnabled: boolean = false;

  private preDefinedCodes = [
    { code: "%CT%", date: "", desc: "Case Type Mnemonic" },
    { code: "%LAM%", date: "", desc: "Lab Mnemonic" },
    { code: "%LM%", date: "", desc: "Location Mnemonic" },
    { code: "%OM%", date: "", desc: "Group Mnemonic" },
    { code: "%OFM%", date: "", desc: "Ordering Facility Mnemonic" },
    { code: "", date: "%YY%", desc: "YY" },
    { code: "", date: "%YYYY%", desc: "YYYY" },
    { code: "", date: "%MM%", desc: "MM" },
    { code: "", date: "%DD%", desc: "DD" }]
  hideCreateBtn: boolean = false;
  hideEditBtn: boolean = false;
  hideDeleteBtn: boolean = false;
  configuredRunningNumbers: any = [];
  isLiveAccount: boolean = false;
  newstatus: boolean=false;
  ofId: any;
  ofList: any = [];
  // facilityCasetypeList: any = [];
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  arrowIconSubject1 = new BehaviorSubject('arrow_drop_down1');
  standardTemplates: any;
  disableCustom: boolean;
  auditableColumns: any;
  ofName: any;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: UntypedFormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public newLocation: SetupNewLocationComponent,
    public activityService: ActivityTrackerService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.deploymentKeys = this.VitalHttpServices.DeploymentKeys;

    this.columns = [
      //************* Grid Customized Column names *********/
      new ColDef('casetype', 'Case Type'),
      new ColDef('sequencetype', 'Sequence Type'),
      new ColDef('dformat', 'Scheme'),
      new ColDef('dalternateformat', 'Custom Scheme'),
      new ColDef('services', 'Services'),
      new ColDef('billto', 'Bill To'),
      new ColDef('oforgname', 'OF (Group)'),
      new ColDef('runningnumber', 'Running Number')
    ];
  }

  resultMaxLen = 15;

  matcher = new MyErrorStateMatcher();

  selected = new UntypedFormControl(0);

  seqFormGrp = this._fb.group({
    // frmAccount: [""],
    frmCasetype: ["", Validators.required],
    frmSerType: [""],
    frmBillto: [""],
    frmIsResearch: false,
    frmOrdFacility: [""],
    frmDefaultFormat: this._fb.group({
      frmCodes: [[]],
      frmCustomCode: "",
      frmDivision: "-",
      frmDate: [[], Validators.required],
      frmRange: ["", Validators.required],
      frmBeginsAt: "",
      frmOutput: ["", Validators.maxLength(this.resultMaxLen)],
      frmScheme: "",
      frmSequenceName: "",
      frmOFCodes: ""
    }),
    frmCustomFormat: this._fb.group({
      frmCodes: [[]],
      frmCustomCode: "",
      frmDivision: "-",
      frmDate: [[]],
      frmRange: "",
      frmBeginsAt: "",
      frmOutput: ["", Validators.maxLength(this.resultMaxLen)],
      frmScheme: "",
      frmSequenceName: ""
    })
  })

  searchCasetype = new UntypedFormControl();
  searchBillTo = new UntypedFormControl();
  searchServices = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  // filteredAccounts: Observable<any[]>;
  filteredServiceOptions: Observable<any[]>;
  filteredBillToOptions: Observable<any[]>;
  disableMnemonic: boolean;
  @ViewChild('searchC') searchCTextBox: ElementRef;
  tabs = ['Scheme', 'Custom Scheme'];
  // associatedLabs: Array<any> = [];
  activityEntity: any;

  async ngOnInit() {
    this.searchContext = sessionStorage.getItem("search_context").toLowerCase();
    this.getAuditableDetails(this.templateData.menuURL);
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);

    await this.getAccountType();
    this.resultMaxLen = (this.isLiveAccount) ? 12 : 15;
    this.GetButtondetails();

    await this.addGridData(this.templateData.submenuData);

    this.filteredOptions = this.seqFormGrp.controls.frmCasetype.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.filterCasetype(name))
      );

    this.filteredServiceOptions = this.searchServices.valueChanges
      .pipe(
        startWith<string>(''),
        map(obj => this.filterServices(obj))
      );

    this.filteredBillToOptions = this.searchBillTo.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.filterBillTo(name))
      );

    this.seqFormGrp.get('frmDefaultFormat.frmRange').valueChanges.subscribe(x => {
      if ((this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmBeginsAt.value &&
        Number(this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmBeginsAt.value) <= 1) ||
        !this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value) {
        this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
          frmBeginsAt: ""
        });
      }
    })

    this.seqFormGrp.get('frmCustomFormat.frmRange').valueChanges.subscribe(x => {
      if ((this.seqFormGrp.controls['frmCustomFormat']['controls'].frmBeginsAt.value &&
        Number(this.seqFormGrp.controls['frmCustomFormat']['controls'].frmBeginsAt.value) <= 1) ||
        !this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value) {
        this.seqFormGrp.controls['frmCustomFormat'].patchValue({
          frmBeginsAt: ""
        });
      }
    })

    merge.apply(null,[this.seqFormGrp.get('frmDefaultFormat.frmCodes').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmOFCodes').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmCustomCode').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmDivision').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmDate').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmRange').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmBeginsAt').valueChanges,
    this.seqFormGrp.get('frmCasetype').valueChanges
    ]
    ).subscribe(() => {
      this.concatFormat('frmDefaultFormat');
    });

    merge.apply(null,[this.seqFormGrp.get('frmCustomFormat.frmCodes').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmCustomCode').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmDivision').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmDate').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmRange').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmBeginsAt').valueChanges,
    this.seqFormGrp.get('frmCasetype').valueChanges
    ]
    ).subscribe(() => {
      this.concatFormat('frmCustomFormat');
    });

    this.seqFormGrp.controls.frmOrdFacility.valueChanges.subscribe(x => {
      if(!this.seqFormGrp.controls.frmOrdFacility.value || !this.ofId)
      {
        this.ofId = null;
        this.ofName = "";
        this.seqFormGrp.controls.frmBillto.clearValidators();
        this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
        this.seqFormGrp.controls.frmSerType.clearValidators();
        this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
        this.filterTemplates();
      }
    })
  }

  toggleAllSelection(dtype, custom?: Boolean) {
    if (dtype == 'Codes') {
      if (custom) {
        if (this.allSelectedCustomCodes) {
          this.matSelectOptionCustomCodes.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionCustomCodes.options.forEach((item: MatOption) => item.deselect());
        }
      } else {
        if (this.allSelectedCodes) {
          this.matSelectOptionCodes.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionCodes.options.forEach((item: MatOption) => item.deselect());
        }
      }
    }
    else if (dtype === 'Date') {
      if (custom) {
        if (this.allSelectedCustomDates) {
          this.matSelectOptionCustomDate.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionCustomDate.options.forEach((item: MatOption) => item.deselect());
        }
      } else {
        if (this.allSelectedDates) {
          this.matSelectOptionDate.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionDate.options.forEach((item: MatOption) => item.deselect());
        }
      }
    }
    else if (dtype === 'Casetype') {
      if (this.allSelectedCasetypes) {
        this.matSelectOptionCasetype.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionCasetype.options.forEach((item: MatOption) => item.deselect());
      }
    }
    else if (dtype === 'Service') {
      if (this.allSelectedService) {
        this.matSelectOptionServiceType.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionServiceType.options.forEach((item: MatOption) => item.deselect());
      }
    } else if (dtype === 'BillTo') {
      if (this.allSelectedBillTo) {
        this.matSelectOptionBillTo.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionBillTo.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }

  sortFunc(a, b) {
    return 1;
  }

  optionClick(dtype, custom?: Boolean) {
    this.cardClicked[this.selected.value] =[false, false, false, false, false, false];
    if (dtype == 'Codes') {
      let newStatus = true;
      this.newstatus=newStatus
      if (custom) {
        this.matSelectOptionCustomCodes.options.forEach((item: MatOption) => {
          if (!item.selected) {
            newStatus = false;
          }
        });
        this.allSelectedCustomCodes = newStatus;
      } else {
        this.matSelectOptionCodes.options.forEach((item: MatOption) => {
          if (!item.selected) {
            newStatus = false;
          }
        });
        this.allSelectedCodes = newStatus;
      }
    }
    else if (dtype === 'Date') {
      let newStatus1 = true;

      if (custom) {
        this.matSelectOptionCustomDate.options.forEach((item: MatOption) => {
          if (!item.selected) {
            newStatus1 = false;
          }
        });
        this.allSelectedCustomDates = newStatus1;
      } else {
        this.matSelectOptionDate.options.forEach((item: MatOption) => {
          if (!item.selected) {
            newStatus1 = false;
          }
        });
        this.allSelectedDates = newStatus1;
      }
    }
    else if (dtype === 'BillTo') {
      let newStatus1 = true;
      this.matSelectOptionBillTo.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus1 = false;
        }
      });
      this.disableCustom ? null : this.resetError();
      this.allSelectedBillTo = newStatus1;
    }
    else if (dtype === 'Service') {
      let newStatus1 = true;
      this.matSelectOptionServiceType.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus1 = false;
        }
      });
      this.disableCustom ? null : this.resetError();
      this.allSelectedService = newStatus1;
    }
    else if (dtype === 'Casetype') {
      this.checkValidOption();
    }
    else if (dtype === 'Range' && custom) {
      this.rangeLength['frmCustomFormat'] = this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value?.toString().split('-')[0].length;
    }
    else if (dtype === 'Range' && !custom) {
      this.rangeLength['frmDefaultFormat'] = this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value?.toString().split('-')[0].length;
    }
  }

  resetError() {
    if (!(this.matSelectOptionServiceType.options.some((item: MatOption) => item.selected)) && !(this.matSelectOptionBillTo.options.some((item: MatOption) => item.selected))) {
      this.seqFormGrp['controls']["frmSerType"].setValidators([]);
      this.seqFormGrp['controls']["frmSerType"].updateValueAndValidity()
      this.seqFormGrp['controls']["frmBillto"].setValidators([]);
      this.seqFormGrp['controls']["frmBillto"].updateValueAndValidity()
    }
  }

  async getLookupValues() {
    await this.populateCaseTypes(this.templateData.cardIdentifier);

    let query1 = this.SubMenuCardModel.GetQuery('getidentityformatsentities');
    let queryVariable1 = { "accid": this.templateData.cardIdentifier.toString(), "orgid": sessionStorage.getItem('org_id').toString() };
    let queryResult1 = this.commonService.GetCardRequest(queryVariable1, query1);
    await this.VitalHttpServices.GetData(queryResult1).toPromise().then(async res1 => {
      await this.getSampleDataValues();
      const uniqueServices = new Set();
      const uniqueBillTo = new Set();
      let orgLevelBillToData = res1.data.submenuDataOrgAttributes.filter(res => res.AttributeType.toLowerCase() == 'billto');
      let globalData = res1.data.submenuDataOrgAttributesGlobal.filter(res => res.AttributeType.toLowerCase() == 'billto');
      let temp = [...res1.data.submenuDataOrgAttributes, ...res1.data.submenuDataOrgAttributesGlobal]
      let serviceArray = (temp.length > 0) ? temp.filter(res => res.AttributeType.toLowerCase() == 'services') : [];
      this.ofList = res1.data.submenuDataOFOrgs;
      this.configuredRunningNumbers = res1.data.submenuDataSequences;
      serviceArray.forEach(element => {
        const trimmedService = element.AttributeName.toString().trim();
        uniqueServices.add(trimmedService)
      })
      this.serviceList = Array.from(uniqueServices);
      let billToArray = (orgLevelBillToData && orgLevelBillToData.length > 0) ? orgLevelBillToData :
        (globalData && globalData.length > 0) ? globalData : [];
      billToArray.forEach(element => {
        if (element.AttributeName.toString().toLowerCase().trim() != 'select' && element.AttributeName.toString().toLowerCase().trim() != '-- select --') {
          const trimmedBillTo = element.AttributeName.toString().trim();
          uniqueBillTo.add(trimmedBillTo)
        }
      })
      this.billToList = Array.from(uniqueBillTo);
    }, error => {
      console.error(error);
      this._snackbar.open("An error occurred while processiong your request", "Failed");
    });
  }

  filterCasetype(value) {
    if (!value || value == '') {
      return this.casetypeList.slice(0,25);
    }
    const val = value;

    let tempArray = [...this.casetypeList]
    if (tempArray && tempArray.length > 0) {
      return tempArray.filter(va => va.casetype && va.casetype.toString().toLowerCase().includes(val.toString().toLowerCase())).slice(0,25);
    } else {
      return [];
    }
  }

  clearSearch(event, dtype) {
    event.stopPropagation();
    if (dtype === 'Casetype') {
      this.searchCasetype.patchValue('');
      this.seqFormGrp.patchValue({
        frmCasetype: ''
      })
    }
    else if (dtype == 'OF') {
      this.seqFormGrp.patchValue({
        frmOrdFacility: ''
      })
      this.seqFormGrp.controls.frmDefaultFormat.patchValue({
        frmCodes: [],
      });
      this.filterTemplates();
    }
    else if (dtype === 'Service') {
      this.searchServices.patchValue('');
    } else if (dtype === 'BillTo') {
      this.searchBillTo.patchValue('');
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchServices.patchValue('');
    this.searchBillTo.patchValue('');
    this.searchCasetype.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchCTextBox.nativeElement.focus();
    }
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    // this.editabled = true;
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  checkValidOption() {
    this.codeList.forEach(element => {
      switch (element.item) {
        case 'Group Mnemonic': if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
          (element['disabled']) = this.templateData.submenuData[0].orgmnemonic ? false : true;
        }
          break;
        case 'Ordering Facility Mnemonic':
        case 'Lab Mnemonic':
        case 'Location Mnemonic': (element['disabled']) = (this.datashare.accountMnemonic ? false : true); break;
        case 'Case Type Mnemonic':
          this.casetypeList.find(ele => {
            if (this.seqFormGrp.controls.frmCasetype.value && ele.casetype
              && ele.casetype.toString().toLowerCase().trim() == this.seqFormGrp.controls.frmCasetype.value.toString().toLowerCase().trim()) {
              (element['disabled']) = ele.casetypemnemonic ? false : true;
            } else {
              element['disabled'] = true;
            }
          });
          break;
      }
    });
  }

  async concatFormat(grpname) {
    let formatString = '';
    let derivedDate = '', derivedCode = '', derivedRange = '';
    let code = '', date = '', range = '', customCode = '', division = '', outputString = '';
    let beginsAt = this.seqFormGrp.controls[grpname]['controls'].frmBeginsAt.value;
    if(this.disableCustom)
    {
      code = this.seqFormGrp.controls[grpname]['controls'].frmOFCodes?.value || "";
      derivedCode = code;
    } else {
      if (this.seqFormGrp.controls[grpname]['controls'].frmCodes.value && this.seqFormGrp.controls[grpname]['controls'].frmCodes.value.length > 0) {
        for (let i = 0; i < this.seqFormGrp.controls[grpname]['controls'].frmCodes.value.length; i++) {
          switch (this.seqFormGrp.controls[grpname]['controls'].frmCodes.value[i]) {
            case 'Group Mnemonic': if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
              code += this.templateData.submenuData[0].orgmnemonic ? this.templateData.submenuData[0].orgmnemonic : '';
            }
              derivedCode += '%OM%'
              break;
            case 'Ordering Facility Mnemonic': derivedCode += '%OFM%';
              code += (this.datashare.accountMnemonic ? this.datashare.accountMnemonic : ''); break;
            case 'Lab Mnemonic': derivedCode += '%LAM%';
              code += (this.datashare.accountMnemonic ? this.datashare.accountMnemonic : ''); break;
            case 'Location Mnemonic': derivedCode += '%LM%';
              code += (this.datashare.accountMnemonic ? this.datashare.accountMnemonic : ''); break;
            case 'Case Type Mnemonic':
              derivedCode += '%CT%';
              this.casetypeList.find(element => {
                if (this.seqFormGrp.controls.frmCasetype.value
                  && element.casetype
                  && element.casetype.toString().toLowerCase().trim() == this.seqFormGrp.controls.frmCasetype.value.toString().toLowerCase().trim()) {
                  code += (element.casetypemnemonic ? element.casetypemnemonic : '');
                }
              });
              break;
          }
        }
      }
    }
    if (this.seqFormGrp.controls[grpname]['controls'].frmDate.value && this.seqFormGrp.controls[grpname]['controls'].frmDate.value.length > 0) {
      const d = new Date();
      for (let i = 0; i < this.seqFormGrp.controls[grpname]['controls'].frmDate.value.length; i++) {
        derivedDate += '%' + this.seqFormGrp.controls[grpname]['controls'].frmDate.value[i] + '%'
        switch (this.seqFormGrp.controls[grpname]['controls'].frmDate.value[i]) {
          case 'YY':
            let yy = d.getFullYear();
            date += yy.toString().substring(2, 4); break;
          case 'MM': let month = d.getMonth();
            date += ((month + 1).toString().length > 1) ? (month + 1).toString() : '0' + ((month + 1).toString()); break;
          case 'DD': let day = d.getDate();
            date += (day.toString().length > 1) ? day.toString() : '0' + (day.toString()); break;
          case 'YYYY':
            let year = d.getFullYear();
            date += year.toString(); break;
        }
      }
    }
    division = this.seqFormGrp.controls[grpname]['controls'].frmDivision.value ?? '';
    customCode = this.seqFormGrp.controls[grpname]['controls'].frmCustomCode.value ?? '';

    let sequenceNameString = code.toString().replace(',', '') + customCode.toString().replace(',', '') + date.toString().replace(',', '')
      + division.toString().replace(',', '');

    if (this.seqFormGrp.controls[grpname]['controls'].frmRange.value) {
      switch (this.seqFormGrp.controls[grpname]['controls'].frmRange.value) {
        case '001-999': range = (!beginsAt ? '001' : await this.counting(beginsAt, this.seqFormGrp.controls[grpname]['controls'].frmRange.value, grpname));
          derivedRange += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
          break;
        case '0001-9999': range = (!beginsAt ? '0001' : await this.counting(beginsAt, this.seqFormGrp.controls[grpname]['controls'].frmRange.value, grpname));
          derivedRange += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
          break;
        case '00001-99999': range = (!beginsAt ? '00001' : await this.counting(beginsAt, this.seqFormGrp.controls[grpname]['controls'].frmRange.value, grpname));
          derivedRange += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
          break;
        case '000001-999999': range = (!beginsAt ? '000001' : await this.counting(beginsAt, this.seqFormGrp.controls[grpname]['controls'].frmRange.value, grpname));
          derivedRange += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
          break;
      }
    }

    outputString = code.toString().replace(',', '') + customCode.toString().replace(',', '') + date.toString().replace(',', '')
      + division.toString().replace(',', '') + range.toString().replace(',', '');

    formatString = derivedCode + customCode.toString().trim() + derivedDate.toString()
      + division.toString() + derivedRange.toString();

    this.seqFormGrp.controls[grpname].patchValue({
      frmOutput: outputString,
      frmScheme: formatString,
      frmSequenceName: sequenceNameString,
      frmBeginsAt: beginsAt
    }, { onlySelf: true, emitEvent: false });
  }

  getFormatType(groupname) {
    if ((this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmCustomCode.value ||
      this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmBeginsAt.value ||
      this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmOFCodes.value ||
      (this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmCodes.value?.length > 0) ||
      (this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmDate.value?.length > 0) ||
      this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value) &&

      (this.seqFormGrp.controls['frmCustomFormat']['controls'].frmCustomCode.value ||
        this.seqFormGrp.controls['frmCustomFormat']['controls'].frmBeginsAt.value ||
        (this.seqFormGrp.controls['frmCustomFormat']['controls'].frmCodes.value?.length > 0) ||
        (this.seqFormGrp.controls['frmCustomFormat']['controls'].frmDate.value?.length > 0) ||
        this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value)) {
      return (groupname == 'frmDefaultFormat') ? 'NN%' : 'US%'
    } else {
      return (groupname == 'frmDefaultFormat') ? 'NN%' : 'US%'
    }
  }

  async counting(num: String, range, grpname) {
    let numberLength = num.length;
    this.rangeLength[grpname] = await range.toString().split('-')[0].length;
    let difference = this.rangeLength[grpname] - numberLength;
    num = ('0'.repeat(difference) + num)
    return String(num);
  }

  outputClass() {
    let grpName = (this.selected.value == 0) ? 'frmDefaultFormat' : 'frmCustomFormat'
    return !this.seqFormGrp.controls[grpName]['controls'].frmOutput.value ? 'empty-style' : 'valid-style'
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  async onClickCard(i, data) {
    if (this.cardClicked[this.selected.value][i]) {
      this.cardClicked[this.selected.value][i] = false;
      this.resetForm(this.selected.value == 0 ? true : false, this.selected.value == 1 ? true : false);
      await this.resetValidators(this.selected.value == 0 ? 'frmDefaultFormat' : 'frmCustomFormat');
    } else {
      this.cardClicked[this.selected.value] = [false, false, false, false, false, false];
      this.cardClicked[this.selected.value][i] = true;

      if (this.selected.value == 0) {
        this.seqFormGrp.controls.frmDefaultFormat.patchValue({
          frmCodes: !this.disableCustom ? (data.Description.toString().includes('facility') ? ['Ordering Facility Mnemonic'] : data.Description.toString().includes('Lab') ? ['Lab Mnemonic'] : ['Case Type Mnemonic']) : [],
          frmDate: data.Description.toString().includes('YYYY') ? ['YYYY'] : data.Description.toString().includes('MM') ? ['MM'] : ['YY'],
          frmRange: '00001-99999',
          frmCustomCode: '',
          frmDivision: '-',
          frmBeginsAt: '',
          // frmOFCodes: ""
        })
        this.rangeLength['frmDefaultFormat'] = this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value?.toString().split('-')[0].length;
      } else {
        this.seqFormGrp.controls.frmCustomFormat.patchValue({
          frmCodes: data.Description.toString().includes('facility') ? ['Ordering Facility Mnemonic'] : data.Description.toString().includes('Lab') ? ['Lab Mnemonic'] : ['Case Type Mnemonic'],
          frmDate: data.Description.toString().includes('YYYY') ? ['YYYY'] : data.Description.toString().includes('MM') ? ['MM'] : ['YY'],
          frmRange: '00001-99999',
          frmCustomCode: '',
          frmDivision: '-',
          frmBeginsAt: '',
        })
        this.rangeLength['frmCustomFormat'] = this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value?.toString().split('-')[0].length;
      }
    }
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      let index = -1, index1 = -1
      index = e._p._cols.findIndex(va => va && va._binding && va._binding._path && va._binding._path == 'dformat')
      index1 = e._p._cols.findIndex(va => va && va._binding && va._binding._path && va._binding._path == 'dalternateformat')
      if (index != -1 && index == e.col) {
        let defaultData = e._p._rows[e.row]._data['format'];
        defaultData = defaultData.split(',')
        defaultData = defaultData.filter(va => va && va.toString().toLowerCase().trim() != e._p._rows[e.row]._data['dformat'].toString().toLowerCase().trim())
        defaultData = defaultData.join(',');
        tt.setTooltip(e.cell, `${defaultData}`);
      } else if (index1 != -1 && index1 == e.col) {
        let customData = e._p._rows[e.row]._data['alternateformat'];
        customData = customData.split(',')
        customData = customData.filter(va => va && va.toString().toLowerCase().trim() != e._p._rows[e.row]._data['dalternateformat'].toString().toLowerCase().trim())
        customData = customData.join(',');
        tt.setTooltip(e.cell, `${customData}`);
      }
      else if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  async createFormat() {
    await this.getLookupValues();
    await this.resetForm(true, true, true);
    this.ofId = null;
    this.ofName = "";
    this.seqFormGrp.controls.frmBillto.clearValidators();
    this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
    this.seqFormGrp.controls.frmSerType.clearValidators();
    this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
    this.addEditScreen = true;
    this.gridScreen = false;
    this.editEnabled = false;
    this.actionTitle = 'Create Sequence Scheme';
    this.selectedrowData = null;
  }

  //#region refresh data
  async refreshGrid(createNew?) {
    this.resetForm(true, true, true);
    this.serviceChanged();
    this.billToChange();
    let queryVariable = { accid: this.templateData.cardIdentifier.toString(), orgid: this.destOrgid.toString(), casetype: this.searchContext };
    let query = this.SubMenuCardModel.GetQuery("getidentityformats");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          this.templateData['submenuData'] = [...data.data.submenuData]
        }
        this.editEnabled = false;
        this.addEditScreen = false;
        if (!createNew) {
          this.gridScreen = true;
          if (this.gridScreen) {
            if (this.templateData['submenuData'].length > 0) {
              this.addGridData(this.templateData['submenuData']);
            } else {
              this.addGridData([]);
            }
          }
        } else {
          this.createFormat();
        }
      }
      else {
        this._snackbar.open('Something went wrong! Please refresh the menu', 'Close');
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion

  resetForm(defaultFormat, customFormat, resetAll?) {
    if (resetAll) {
      this.allSelectedService = false;
      this.allSelectedBillTo = false;
      this.seqFormGrp.reset();

      this.seqFormGrp.patchValue({
        frmCasetype: "",
        frmOrdFacility: "",
        frmSerType: [],
        frmBillto: [],
        frmIsResearch: false
      })
      Object.keys(this.seqFormGrp.controls).forEach(key => {
        this.seqFormGrp.controls[key].setErrors(null);
      });

      this.seqFormGrp.controls["frmCasetype"].setValidators([Validators.required]);
      this.seqFormGrp.controls["frmCasetype"].updateValueAndValidity();

      this.selected.setValue(0);
    }

    if (defaultFormat) {
      this.allSelectedDates = false;
      this.allSelectedCodes = false;
      this.cardClicked[0] = [false, false, false, false, false, false];

      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        frmCodes: [],
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: [],
        frmRange: "",
        frmBeginsAt: "",
        frmOutput: "",
        frmScheme: "",
        frmSequenceName: "",
        frmOrdFacility: "",
        frmOFCodes: ""
      });

      Object.keys(this.seqFormGrp.controls.frmDefaultFormat['controls']).forEach(key => {
        this.seqFormGrp.controls.frmDefaultFormat['controls'][key].setErrors(null);
      });

      this.seqFormGrp.controls.frmDefaultFormat['controls']["frmOutput"].setValidators([Validators.maxLength(this.resultMaxLen)]);
      this.seqFormGrp.controls.frmDefaultFormat['controls']["frmOutput"].updateValueAndValidity();
    }

    if (customFormat) {
      this.allSelectedCustomDates = false;
      this.allSelectedCustomCodes = false;
      this.cardClicked[1] = [false, false, false, false, false, false];
      this.seqFormGrp.controls['frmCustomFormat'].patchValue({
        frmCodes: [],
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: [],
        frmRange: "",
        frmBeginsAt: "",
        frmOutput: "",
        frmScheme: "",
        frmSequenceName: "",
        frmOFCodes: ""
      });

      Object.keys(this.seqFormGrp.controls.frmCustomFormat['controls']).forEach(key => {
        this.seqFormGrp.controls.frmCustomFormat['controls'][key].setErrors(null);
      });

      this.seqFormGrp.controls.frmCustomFormat['controls']["frmOutput"].setValidators([Validators.maxLength(this.resultMaxLen)]);
      this.seqFormGrp.controls.frmCustomFormat['controls']["frmOutput"].updateValueAndValidity()
    }
  }

  getRunningNumber(format, derivedFormat) {
    let number = format.match(/\d+/g);
    let lastnumber = number ? (parseInt(number[number.length - 1])) : 0;
    let runningNumber = derivedFormat.substr(derivedFormat?.length - lastnumber);
    return runningNumber
  }

  addGridData(data) {
    this.actionTitle = "Sequence Scheme";
    this.gridArray = [];
    let primary = {};
    let inArray = [];
    let finalTemp = [];

    let inDefaultArray = [];
    if (data && data.length > 0) {
      finalTemp = data.filter(r => r.associationtype && r.associationtype.toString() != 'default');
      inDefaultArray = data.filter(r => r.associationtype
        && (r.associationtype.toString() == 'default'))
      inArray = [...finalTemp, ...inDefaultArray.filter(el => finalTemp && !finalTemp.some(e => e.casetype == el.casetype))]
    }
    if (inArray) {
      if (inArray.length > 0) {
        inArray = inArray.map(va => ({
          ...va, runningnumber: ((va.dformat && va.format) ? this.getRunningNumber(va.format, va.dformat) : '00001'),
          schemeRunningNumber: ((va.dformat && va.format) ? this.getRunningNumber(va.format, va.dformat) : '00001'),
          customSchemeRunningNumber: (va.dalternateformat && va.alternateformat) ? this.getRunningNumber(va.alternateformat, va.dalternateformat) : '00001'
        }))
        this.formatGridData = new CollectionView([]);
        inArray.sort((a, b) => (a.casetype - b.casetype || a.dformat.localeCompare(b.format)));
        for (let i = 0; i < inArray.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(inArray[i])) {
            if (this.showDefaultFormats) {
              if (!value) {
                value = 'NA';
              }
            }
            else {
              if (!value) {
                value = 'Not Specified';
              }
              if (key == 'billto' || key == 'services') {
                value = (value == '' || value == null || value.toString().toLowerCase() == 'not specified') ? 'ALL' : value;
              }
            }
            primary[key] = value;
          }
          this.gridArray.push(primary);
        }
        this.formatGridData = (this.showDefaultFormats) ? new CollectionView(this.gridArray, { pageSize: 10 }) : new CollectionView(this.gridArray, { groupDescriptions: ['casetype'], pageSize: 10 });
        this.gridWidth = (194 * Object.keys(inArray[0]).length) + 37;
        this.showPaginationMainGrid = this.gridArray.length > 10 ? true : false;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }

  ExportExcel(flex) {
    let tempHeader = flex.columns[0]._hdr;
    let filename = 'SequenceScheme_' + this.templateData.secondarykeys.OrganizationId.toString() +
      '.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    flex.columns.forEach(element => {
      if (element._hdr == 'Action') {
        element._hdr = ''
      }
    });

    let wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true,
        includeColumns: function (column) {
          return (column.binding !== 'casetype')
        }
      },
      null,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
    flex.columns[0]._hdr = tempHeader
    wb.sheets[0].name = 'SequenceScheme';
    wb.saveAsync(filename);
  }

  filterServices(value) {
    if (!value || value == '') {
      return this.serviceList;
    }
    const val = value;

    let tempArray = [...this.serviceList]
    if (tempArray && tempArray.length > 0) {
      return tempArray.filter(va => va && va.toString().toLowerCase().includes(val.toString().toLowerCase()));
    } else {
      return [];
    }
  }

  filterBillTo(value) {
    if (!value || value == '') {
      return this.billToList;
    }
    const val = value;

    let tempArray = [...this.billToList]
    if (tempArray && tempArray.length > 0) {
      return tempArray.filter(va => va && va.toString().toLowerCase().includes(val.toString().toLowerCase()));
    } else {
      return [];
    }
  }


  removeChip(value: string, str): void {
    switch (str) {
      case 'Service': this.allSelectedService = false;
        this.matSelectOptionServiceType.options.forEach((item: MatOption) => {
          if (value == item.value) {
            item.deselect()
          }
        });
      case 'BillTo': this.allSelectedBillTo = false;
        this.matSelectOptionBillTo.options.forEach((item: MatOption) => {
          if (value == item.value) {
            item.deselect()
          }
        });
    }
  }

  async getSampleDataValues() {
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { "keyword": "Standard Format", "context": "SequenceScheme" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, 'configdb').toPromise().then((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        if (Resdata.data.submenuData[0] && Resdata.data.submenuData[0].Items_JSON) {
          this.standardTemplates = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.filterTemplates();
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  async validateDuplicates(inputArray) {
    let validFlag = true;
    let createdby = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid");

    const {
      frmCasetype,
      frmSerType,
      frmBillto,
      frmIsResearch,
      frmDefaultFormat,
      frmCustomFormat
    } = inputArray.value
    let temp = [];
    let temp1 = [];
    if (this.allSelectedBillTo && this.allSelectedService && ((!this.ofId) || (this.ofId && this.ofId == -1))) {
      temp = (!this.ofId) ? [] : this.serviceList;
      temp1 = (!this.ofId) ? [] : this.billToList;
    }
    else {
      temp = frmSerType;
      temp1 = frmBillto;
      if (temp.length > 0 && temp1.length > 0 && !this.ofId) {
        this.ofId = -1;
        this.ofName = "Default Group(-1)"
      }
    }
    let obj = this.casetypeList.find(ele => ele.casetype && ele.casetype.toString().toLowerCase().trim() == this.seqFormGrp.controls.frmCasetype.value?.toString().toLowerCase().trim())
    this.finalArray = [{
      "tablename": "identityformat",
      "casetype": this.seqFormGrp.controls.frmCasetype.value,
      "organizationid": this.ofId ? obj.organizationid : Number(sessionStorage.getItem('org_id')),
      "formatids": !this.selectedrowData?.formatid ? null : this.selectedrowData.formatid,
      "formatid": !this.selectedrowData?.formatid ? null : this.selectedrowData.formatid.toString().split(',')[0],
      "entityname": this.seqFormGrp.controls.frmCasetype.value,
      "entitytype": 'Account Casetype',
      "format": (((!frmDefaultFormat.frmScheme) || (frmDefaultFormat.frmScheme.toString().trim() == '-')) ? null : (frmDefaultFormat.frmScheme ? frmDefaultFormat.frmScheme : null)),
      "alternateformat": (((!frmCustomFormat.frmScheme) || (frmCustomFormat.frmScheme.toString().trim() == '-')) ? null : (frmCustomFormat.frmScheme ? frmCustomFormat.frmScheme : null)),
      "billto": temp1,
      "services": temp,
      "oforgid": this.ofId ? String(this.ofId) : ((frmSerType?.length > 0 && frmBillto?.length > 0) ? -1 : null),
      "labaccountid": 0,
      "caseflowtype": frmIsResearch ? 'QC' : null,
      "accountid": this.templateData.cardIdentifier,
      "defaultsequencename": frmDefaultFormat.frmSequenceName ?? null,
      "customsequencename": frmCustomFormat.frmSequenceName ?? null,
      "defaultrunningnumber": frmDefaultFormat.frmBeginsAt ?? null,
      "customrunningnumber": frmCustomFormat.frmBeginsAt ?? null,
      "createdby": createdby
    }]
      this.finalArray[0]["services"] = ((this.finalArray[0]["services"] && this.finalArray[0]["services"].toString().trim().toLowerCase() == 'all') &&
        (this.finalArray[0]["billto"] && this.finalArray[0]["billto"].toString().trim().toLowerCase() == 'all')) ? "" : (!this.finalArray[0]["services"] ? "" : this.finalArray[0]["services"].toString());
      this.finalArray[0]["billto"] = ((this.finalArray[0]["services"] && this.finalArray[0]["services"].toString().trim().toLowerCase() == 'all') &&
        (this.finalArray[0]["billto"] && this.finalArray[0]["billto"].toString().trim().toLowerCase() == 'all')) ? "" : (!this.finalArray[0]["billto"] ? "" : this.finalArray[0]["billto"].toString());

      this.finalArray[0]["format"] = ((frmCustomFormat.frmScheme.toString().trim() == '-') ? null : frmCustomFormat.frmScheme)
      this.finalArray[0]["alternateformat"] = ((frmDefaultFormat.frmScheme.toString().trim() == '-') ? null : frmDefaultFormat.frmScheme)

      await this.VitalHttpServices.validateIdentityFormats(this.finalArray, this.destDeployment).toPromise().then(result => {
      if (result && result.some(el => !el.error)) {
        if (result.some(el => el.notemessage && el.notemessage.toString().includes('already exists'))) {
          this._snackbar.open("Scheme already exists", "Failure");
          validFlag = false;
        }
        else {
          let obj = this.getConfiguredRunningNumber(this.finalArray[0].defaultsequencename);
          if(obj)
          {
            if(Number(this.finalArray[0].defaultrunningnumber) < Number(obj.lastnumber)+1)
            {
              this._snackbar.open("Begins At value should be greater than configured value","Close");
              validFlag = false;
            } else {
              validFlag = true;
            }
          } else {
            validFlag = true;
          }
        }
      } else {
        this._snackbar.open("Error occurred while processing your request !", "Failure");
        validFlag = false;
      }
    }, error => {
      this._snackbar.open("Error occurred while processing your request !", "Failure");
      console.error(error);
      validFlag = false;
    })
    return validFlag;
  }

  async validateScheme(inputArray) {
    let validScheme = true;
    validScheme = await this.validateFields(inputArray);
    validScheme = validScheme ? await this.validateDuplicates(inputArray) : validScheme;

    return validScheme;
  }

  async saveScheme(inputArray, action) {
    if (!await this.validateScheme(inputArray)) {
      return;
    } else {
      this.ngxService.start();
      if (this.finalArray.some(x => x.formatid)) {
        this.VitalHttpServices.EditIdentityFormats(this.finalArray, this.destDeployment).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors && result?.length > 0) {
            if (result.some(va => va.Status == 'Success')) {
              this.activityEntity.entityid = this.finalArray[0].formatid;
              this.activityService.setActivitySession(this.activityEntity);
              let auditNewArray = this.getAuditableObject(this.finalArray, true);
              let auditOldArray = this.getAuditableObject([this.selectedrowData]);
              this.commonService.auditDetails("formatid", "format", auditOldArray, auditNewArray, 'Edit', this.templateData, this.auditableColumns)
              this._snackbar.open(result[0]['notes'], "Close");
            } else {
              this._snackbar.open(result[0]['notes'], "Close");
              return
            }
            this.refreshGrid();
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong. Please try again', 'Close');
          console.error(error);
        })
      } else {
        await this.VitalHttpServices.bulkUploadIdentityFormats(this.finalArray, this.destDeployment).toPromise().then(result => {
          this.ngxService.stop();
          if (!result.errors && result?.length > 0) {
            if (result.some(va => va.Status == 'Success')) {
              this._snackbar.open(result[0]['notes'], "Close");
              this.finalArray = this.finalArray.map(x => ({...x, format: x.alternateformat, alternateformat: x.format}))
              result = result.map(x => ({...x, formatid: result[0].ID}))
              let auditNewArray = this.getAuditableObject(this.finalArray);
              this.commonService.auditDetails("formatid", "format", result, auditNewArray, 'Create', this.templateData, this.auditableColumns)
            } else {
              this._snackbar.open(result[0]['notes'], "Close");
              return
            }
            if (action == 'new') {
              this.refreshGrid(true);
              return;
            } else {
              this.refreshGrid();
            }
          }
        }, error => {
          console.error(error);
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request!", "Close")
        })
      }
    }
  }

  resetValidators(groupname, setNull?) {
    if (setNull) {
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].setValidators([]);
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].updateValueAndValidity()
      this.seqFormGrp.controls[groupname]['controls']["frmRange"].setValidators([]);
      this.seqFormGrp.controls[groupname]['controls']["frmRange"].updateValueAndValidity({ onlySelf: true, emitEvent: false })
    } else {
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].setValidators([Validators.required]);
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].updateValueAndValidity();
      this.seqFormGrp.controls[groupname]['controls']["frmRange"].setValidators([Validators.required]);
      if (groupname == 'frmDefaultFormat') {
        this.seqFormGrp.controls[groupname]['controls']["frmRange"].updateValueAndValidity();
      } else {
        this.seqFormGrp.controls[groupname]['controls']["frmRange"].updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }
  }

  async validateFields(inputArray) {
    const {
      frmCasetype,
      frmSerType,
      frmBillto,
      frmDefaultFormat,
      frmCustomFormat,
    } = inputArray.value

    this.resetValidators('frmDefaultFormat');
    if(this.ofId)
    {
      this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      if ((!frmSerType || (frmSerType?.length == 0)) || (!frmBillto || (frmBillto?.length  == 0))) {
        this._snackbar.open("Please enter all the mandatory fields", "Close");
        return false;
      }
    }

    if ((frmSerType && frmSerType?.length > 0) && (!frmBillto || frmBillto?.length < 1)) {
      this._snackbar.open("Please enter all the mandatory fields", "Close");
      return false;
    }

    if ((frmBillto && frmBillto?.length > 0) && (!frmSerType || frmSerType?.length < 1)) {
      this._snackbar.open("Please enter all the mandatory fields", "Close");
      return false;
    }

    if (!this.seqFormGrp.controls.frmCasetype.value ||
      (!frmDefaultFormat.frmDate || frmDefaultFormat.frmDate?.length < 1) ||
      !frmDefaultFormat.frmRange) {
      this._snackbar.open("Please enter all the mandatory fields", "Close");
      this.selected.setValue(0);
      return false;
    }

    if ((frmCustomFormat.frmCodes?.length > 0) ||
      frmCustomFormat.frmCustomCode ||
      frmCustomFormat.frmBeginsAt ||
      (frmCustomFormat.frmDate?.length > 0) ||
      frmCustomFormat.frmRange) {
      this.resetValidators('frmCustomFormat');
      if ((!frmCustomFormat.frmDate || frmCustomFormat.frmDate?.length < 1) ||
        !frmCustomFormat.frmRange) {
        this._snackbar.open("Please enter all the mandatory fields", "Close");
        this.selected.setValue(1);
        return false;
      }
    } else {
      this.resetValidators('frmCustomFormat', true);
    }

    if (this.seqFormGrp.controls.frmDefaultFormat['controls'].frmOutput.value && this.seqFormGrp.controls.frmDefaultFormat['controls'].frmOutput.value.length > this.resultMaxLen) {
      this._snackbar.open("Please enter a valid length of Scheme!", "Close");
      this.selected.setValue(0);
      return false
    }

    if (this.seqFormGrp.controls.frmCustomFormat['controls'].frmOutput.value && this.seqFormGrp.controls.frmCustomFormat['controls'].frmOutput.value.length > this.resultMaxLen) {
      this._snackbar.open("Please enter a valid length of Scheme!", "Close");
      this.selected.setValue(1);
      return false
    }

    let flag = true
    if (this.isLiveAccount && this.seqFormGrp.controls.frmDefaultFormat['controls'].frmOutput.value && 
      ((this.seqFormGrp.controls.frmDefaultFormat['controls'].frmOutput.value.length == this.resultMaxLen)
    || (this.seqFormGrp.controls.frmDefaultFormat['controls'].frmOutput.value.length == (this.resultMaxLen-1)))) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '360px',
        panelClass: 'admin-custom-popup',
        data: { header: "", message: "Recommended sequence length is 10 characters. Do you wish to proceed ?", continue: "Proceed", cancel: "Cancel" }
      })
      let data = await dialogRef.afterClosed().toPromise().then(result => {
        if (!result) {
          this.selected.setValue(0);
          flag = false
        }
      })
      return flag
    }

    if (flag && this.isLiveAccount && this.seqFormGrp.controls.frmCustomFormat['controls'].frmOutput.value && (this.seqFormGrp.controls.frmCustomFormat['controls'].frmOutput.value.length == this.resultMaxLen
      || this.seqFormGrp.controls.frmCustomFormat['controls'].frmOutput.value.length == (this.resultMaxLen-1))) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '360px',
        panelClass: 'admin-custom-popup',
        data: { header: "", message: "Recommended sequence length is 10 characters. Do you wish to proceed ?", continue: "Proceed", cancel: "Cancel" }
      })
      let data = await dialogRef.afterClosed().toPromise().then(result => {
        if (!result) {
          this.selected.setValue(1);
          flag = false
        }
      })
      return flag
    }
    return true;
  }

  async saveCloseData(action, inputArray) {
    await this.saveScheme(inputArray, action);
  }

  //#region Edit page data
  async openEditScreen(grid, event) {
    await this.getLookupValues();
    await this.resetForm(true, true, true);
    if (this.hideEditBtn) {
      this._snackbar.open("User is not authorized !", "Close");
      return;
    }
    this.editEnabled = true;
    this.addEditScreen = true;
    this.gridScreen = false;
    this.actionTitle = 'Edit Sequence Scheme'
    var hti = grid.hitTest(event);
    this.selectedrowData = hti.panel.rows[hti.row].dataItem;
    for (var key in this.selectedrowData) {
      if (this.selectedrowData[key] == "Not Specified") {
        this.selectedrowData[key] = "";
      }
    }
    await this.getInputData();
  }
  //#endregion

  //#region
  getInputData() {
    let defaultFormat = this.splitFormat(this.selectedrowData.format);
    let customFormat = this.selectedrowData.alternateformat ? this.splitFormat(this.selectedrowData.alternateformat) : '';
    let target = [], defaultAccession = '', defaultRunningNumber, customAccession = '', customRunningNumber;

    if (this.selectedrowData.format && this.selectedrowData.alternateformat) {
      defaultAccession = this.selectedrowData.dformat;
      defaultRunningNumber = String(this.selectedrowData.schemeRunningNumber)
      customAccession = this.selectedrowData.alternateformat ? this.selectedrowData.dalternateformat : ''
      customRunningNumber = String(this.selectedrowData.customSchemeRunningNumber)
    }
    else {
      defaultAccession = this.selectedrowData.alternateformat ? this.selectedrowData.dalternateformat : '';
      defaultRunningNumber = String(this.selectedrowData.schemeRunningNumber)
      customAccession = this.selectedrowData.dformat;
      customRunningNumber = String(this.selectedrowData.customSchemeRunningNumber)
    }
    
    this.ofId = this.selectedrowData.oforgid || null 
    this.ofName = this.selectedrowData.oforgname ? (this.selectedrowData.oforgname + '('+this.ofId +')') : ""; 
    let serviceData = this.selectedrowData.services ? (this.selectedrowData.services.toString().toLowerCase().trim() != 'all' ? this.selectedrowData.services.split(',') : ((this.ofId == -1) ? this.serviceList : [])) : [];
    let billToData = this.selectedrowData.billto ? (this.selectedrowData.billto.toString().toLowerCase().trim() != 'all' ? this.selectedrowData.billto.split(',') : ((this.ofId == -1) ? this.billToList : [])) : [];
    
    this.seqFormGrp.patchValue({
      frmOrdFacility: this.selectedrowData.oforgname ? this.selectedrowData.oforgname : '',
      frmCasetype: this.selectedrowData.casetype ? this.selectedrowData.casetype : '',
      frmSerType: serviceData,
      frmBillto: billToData,
      frmIsResearch: this.selectedrowData.caseflowtype ? true : false
    })
    this.rangeLength['frmDefaultFormat'] = defaultFormat.range?.toString().split('-')[0].length;
    this.rangeLength['frmCustomFormat'] = customFormat['range'] ? customFormat['range'].toString().split('-')[0].length : 0;
    
    target = [...this.serviceList]
    if (target.every(v => serviceData.includes(v))) {
      this.allSelectedService = true;
    }
    target = []
    target = [...this.billToList]
    if (target.every(v => billToData.includes(v))) {
      this.allSelectedBillTo = true;
    }
    
    if(this.ofId && this.ofId != -1)
    {
      this.disableCustom = true;
      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        frmOFCodes: defaultFormat.custom,
        frmCodes: "",
        frmCustomCode: "",
        frmDivision: defaultFormat.division,
        frmDate: defaultFormat.date,
        frmRange: defaultFormat.range,
        frmBeginsAt: defaultRunningNumber,
        frmOutput: defaultAccession
      })
      this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      this.concatFormat("frmDefaultFormat");
      this.filterTemplates(true);
    } else {
      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        frmCodes: defaultFormat.code,
        frmCustomCode: defaultFormat.custom,
        frmDivision: defaultFormat.division,
        frmDate: defaultFormat.date,
        frmRange: defaultFormat.range,
        frmBeginsAt: defaultRunningNumber,
        frmOutput: defaultAccession
      });
      this.serviceChanged();
      this.billToChange();
    }

    if (customFormat) {
      this.seqFormGrp.controls['frmCustomFormat'].patchValue({
        frmCodes: customFormat['code'],
        frmCustomCode: customFormat['custom'],
        frmDivision: customFormat['division'],
        frmDate: customFormat['date'],
        frmRange: customFormat['range'],
        frmBeginsAt: customRunningNumber,
        frmOutput: customAccession
      });
    }
  }
  //#endregion

  //#region split the format to patch the data in format tab
  splitFormat(inputFormat) {
    let str = inputFormat.toString().split('%')
    let obj = {
      code: []
      , division: ''
      , date: []
      , range: ''
      , custom: ''
    }

    str.forEach(element => {
      if (element) {
        this.preDefinedCodes.find(ele => {
          if (ele.code && element && ele.code.toString() == '%' + element + '%') {
            obj.code.push(ele.desc.toString())
          } else if (ele.date && element && ele.date.toString() == '%' + element + '%') {
            obj.date.push(ele.desc.toString())
          }
        })
        if (element == '-') {
          obj.division = element;
        } else if (element && element.toString().endsWith("NN") || element.toString().endsWith("US")) {
          let difference = Number(element.toString().match(/\d+/)[0]);
          let fnum = ('0'.repeat(difference - 1) + 1)
          let lnum = ('9'.repeat(difference))
          obj.range = fnum + '-' + lnum;
        } else if (!this.preDefinedCodes.some(va => element && (va.code.toString().toLowerCase() == ('%' + element + '%').toString().toLowerCase() || va.date.toString().toLowerCase() == ('%' + element + '%').toString().toLowerCase()))) {
          obj.custom += element
        }
      }
    });
    return obj;
  }
  //#endregion

  async populateCaseTypes(accountID, isFacility?) {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getSchemeCasetypes');
    queryVariable = { accid: accountID.toString() };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(async (res) => {
      if (!res.errors) {
        if (res.data) {
          // if (isFacility) {
          //   this.facilityCasetypeList = res.data.submenuData;
          // } else {
            // this.casetypeList = (this.searchContext == 'facility') ?
            //   (res.data.submenuData.filter((ele) => ele.casetype && this.facilityCasetypeList.some(casetype => casetype.casetype && (casetype.casetype.toString().toLowerCase().trim() == ele.casetype.toString().toLowerCase().trim()))))
            //   : res.data.submenuData;
          // }
          this.casetypeList = res.data.submenuData;
          this.seqFormGrp.controls.frmCasetype.enable();
        }
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  // async fetchCaseTypes(accountid) {
  //   await this.populateCaseTypes(accountid);
  // }

  deleteRecord(grid, event) {
    if (this.hideDeleteBtn) {
      this._snackbar.open("User is not authorized !", "Close");
      return;
    }
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    // for (var key in selectedrowData) {
    //   if (selectedrowData[key] == "Not Specified") {
    //     selectedrowData[key] = "";
    //   }
    // }
    if (selectedrowData.formatid && selectedrowData.formatid != "Not Specified") {
      let arrayObj = [{
        formatid: selectedrowData.formatid != "Not Specified" ? selectedrowData.formatid : "",
      }];
      arrayObj[0]['formatids'] = selectedrowData.formatid != "Not Specified" ? selectedrowData.formatid : "";
      arrayObj[0]['billto'] = selectedrowData.billto != "Not Specified" ? selectedrowData.billto : "";
      arrayObj[0]['organizationid'] = selectedrowData.organizationid != "Not Specified" ? selectedrowData.organizationid : "";
      arrayObj[0]['accountid'] = this.templateData.cardIdentifier;
      arrayObj[0]['casetype'] = selectedrowData.casetype != "Not Specified" ? selectedrowData.casetype : "";
      arrayObj[0]['format'] = selectedrowData.format != "Not Specified" ? selectedrowData.format : "";
      arrayObj[0]['alternateformat'] = selectedrowData.alternateformat != "Not Specified" ? selectedrowData.alternateformat : "";
      arrayObj[0]['sequencetype'] = selectedrowData.sequencetype != "Not Specified" ? selectedrowData.sequencetype : "";

      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Confirmation", message: "Clicking on Yes, will delete the record!", alert: "Do you want to continue ?", continue: "Yes", cancel: "No" }
      });
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.DeleteIdentityFormats(arrayObj, this.destDeployment).subscribe(result => {
            if (!result.errors) {
              this._snackbar.open('Deleted successfully!', 'Close');
              let auditObj = this.getAuditableObject([selectedrowData]);
              this.commonService.auditDetails("formatid", "format", auditObj, [{}], 'Delete', this.templateData, this.auditableColumns)
              this.refreshGrid();
            }
            else {
              this._snackbar.open('Deleted failed!', 'Close')
            }
          }, error => {
            console.error(error);
          })
        }
        else {
          return
        }
      }, error => {
        console.error(error);
      });
    } else {
      this._snackbar.open("The record is not configured in database", "Close");
      return
    }
  }

  cardClass(i) {
      return this.cardClicked[this.selected.value][i] ? 'onClick-style' : 'card-style'
  }

  //#region Checking if the selected row has formatID cause it should not be default data
  isDeletable(row) {
    if (row._data.formatid && row._data.formatid != "Not Specified") {
      return true;
    }
    else {
      return false;
    }
  }
  //#endregion

  //#region
  /* RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
  }
  //#endregion

  //#region
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  //#endregion

  getRangeLength(grpName) {
    return this.seqFormGrp.controls[grpName]['controls'].frmRange.value ? this.seqFormGrp.controls[grpName]['controls'].frmRange.value.toString().split('-')[0].length : 0;
  }

  //#region chcek if the selected Account is Live or Test
  async getAccountType() {
    let obj = {
      contextId: this.templateData.cardIdentifier,
      context: 'lab',
      contexttype: 'account'
    }
    let dbname = this.destDeployment;
    await this.VitalHttpServices.getAccountType(obj, dbname).toPromise().then(result => {
      if (result.length > 0) {
        this.isLiveAccount = result[0].isLive ? true : false;
      }
      else {
        this.isLiveAccount = false;
      }
    }, error => {
      console.error(error)
    })
  }
  //#endregion

  openOrClosePanel1(evt: any, trigger1: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger1.panelOpen)
      trigger1.closePanel();
    else
      trigger1.openPanel();
  }

  clearOrdFacility(evt: any): void {
    evt.stopPropagation();
    this.seqFormGrp.patchValue({
      frmOrdFacility: ""
    })
    this.ofId = null;
    this.ofName = null;
    this.seqFormGrp.controls.frmBillto.clearValidators();
    this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
    this.seqFormGrp.controls.frmSerType.clearValidators();
    this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
    this.inputAutoComplete?.nativeElement.focus();
  }

  // ofList has only of organizationname
  filterOF(value) {
    if (!value || value == '') {
      return this.ofList.slice(0,25)
    }
    let users = this.ofList
    if (users && users.length > 0) {
      return users.filter(va => va && va.organizationname && (va.organizationname.toString().toLowerCase().includes(value.toLowerCase())
      || va.organizationid.toString().toLowerCase().includes(value.toLowerCase()))).slice(0,25);
    } else {
      return []
    }
  }

  // group id
  getOfID(event, value) {
    if (event.source.selected) {
      this.ofId = value.organizationid;
      this.ofName = value.organizationname +'(' +this.ofId +')'; 
      this.filterTemplates(true);
      this.disableCustom = true;
      this.selected.setValue(0);
      this.resetForm(false, true); 
      this.seqFormGrp.controls.frmDefaultFormat.patchValue({
        frmCodes: [],
      });
      this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
    }
  }

  filterTemplates(isOFConfig?: Boolean)
  {
    this.disableCustom = isOFConfig ? true : false;
    this.standardFormat = [];
    this.standardFormat = isOFConfig ? [...this.standardTemplates.FacilityFormat, ...this.standardTemplates.CasetypeFormat] : [...this.standardTemplates.LabFormat, ...this.standardTemplates.CasetypeFormat];
    this.codeList = this.standardTemplates.Codes.filter(v => isOFConfig ? (v.item.toString().trim() != 'Lab Mnemonic') : (v.item.toString().trim() != 'Ordering Facility Mnemonic'))
    this.dateList = [...this.standardTemplates.Dates];
    this.rangeList = [...this.standardTemplates.Ranges];
    this.checkValidOption();
  }

  serviceChanged() {
    (!this.disableCustom) ? this.validateDefaultOrg() : null;
    if(!this.disableCustom)
    {
      if(this.seqFormGrp?.value?.frmSerType?.length) {
        this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
        this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      } else {
        this.seqFormGrp.controls.frmBillto.clearValidators();
        this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      }
    }
  }

  billToChange(){
    (!this.disableCustom) ? this.validateDefaultOrg() : null;
    if(!this.disableCustom)
    {
      if(this.seqFormGrp?.value?.frmBillto?.length){
        this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
        this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      } else {
        this.seqFormGrp.controls.frmSerType.clearValidators();
        this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      }
    }
  }

  validateDefaultOrg()
  {
    if((this.seqFormGrp?.value?.frmBillto?.length > 0) && (this.seqFormGrp?.value?.frmSerType?.length > 0))
    {
      this.seqFormGrp.patchValue({
        frmOrdFacility: "Default Group",
      })
      this.ofId = -1;
      this.ofName = "Default Group(-1)" 
    } else {
      this.seqFormGrp.patchValue({
        frmOrdFacility: "",
      })
      this.ofId = null;
      this.ofName = null;
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      if(res.content)
      {
        this.auditableColumns =  JSON.parse(res.content.JsonData);
      }
    }, error => {
      console.error(error);
    })
  }

  getAuditableObject(inputArray:any, isUpdate?: Boolean) {
    if(inputArray.length > 0)
    {
      inputArray = inputArray.map(x => ({...x, format: (isUpdate ? x['format'] : x['alternateformat']), alternateformat: (isUpdate ? x['alternateformat'] : x['format']), sequencetype:'Accession' }))
      for (var key in inputArray[0]) {
        if (inputArray[0][key] == "Not Specified") {
          inputArray[0][key] = "";
        }
      }
      return [{
          "casetype": inputArray[0].casetype || ""
          ,"entitytype": inputArray[0].entitytype || ""
          ,"sequencetype": inputArray[0].sequencetype || ""
          ,"format": inputArray[0].alternateformat || ""
          ,"alternateformat": inputArray[0].format || ""
          ,"billto": inputArray[0].billto || "ALL"
          ,"services": inputArray[0].services || "ALL"
          ,"oforgname": this.ofName
          ,"caseflowtype": inputArray[0].caseflowtype || ""
          ,"runningnumber": (inputArray[0].defaultrunningnumber ? Number(inputArray[0].defaultrunningnumber).toString() : (inputArray[0].runningnumber ? Number(inputArray[0].runningnumber).toString() : "1"))
      }]
    }
  }

  getConfiguredRunningNumber(sequenceNameString)
  {
    let obj = this.configuredRunningNumbers.find(ele =>
      (ele.sequencename && ele.sequencename.toString().toLowerCase() == sequenceNameString?.toString().toLowerCase()))
    return obj;
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}

