import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { CollectionView } from '@grapecity/wijmo';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-bulk-upload-offline-process',
  templateUrl: './bulk-upload-offline-process.component.html',
  styleUrls: ['./bulk-upload-offline-process.component.scss']
})
export class BulkUploadOfflineProcessComponent {
  templateData: any;
  ListedGridHeader: any;
  gridData: any;
  gridHeader: string[];
  listedGridHeader: any[];
  SubMenuCardModel: SubMenuCardModel;
  gridheaderList: { templateName: string; headers: { Name: string; type: string; key: string; colWidth: string; }[]; }[];
  listHeaderbackup: any;
  jobType: string = 'upload';
  dateRangeOptions = [
    { value: 'today', label: 'Today' },
    { value: 'last-7-days', label: 'Last 7 Days' },
    { value: 'last-week', label: 'Last Week' },
    { value: 'this-week', label: 'This Week' },
    { value: 'last-month', label: 'Last Month' },
    { value: 'date-range', label: 'Date Range' }
  ];
  depKey: string = '';
  grpName: string = '';
  dateRange: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public VitalHttpServices: VitalHttpServices, private _snackbar: MatSnackBar,private datePipe : DatePipe,
    public commonService: CommonService, private ngxService: NgxUiLoaderService, private datashare: DataShareService, private templateHeaders: TemplateHeadersService,
    public templateViewService: TemplateViewService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );

  }
  
  ngOnInit(): void {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.dateRange = `${this.datePipe.transform(yesterday, 'yyyy-MM-dd')} - ${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}`;
    const dates = this.dateRange.split(" - ");
    this.getOfflineProcessList(this.jobType, this.depKey, this.grpName, this.dateRange);
    this.templateViewService.globalMasterDataForQueries['type'] = this.jobType;
    this.templateViewService.globalMasterDataForQueries['DeploymentKey'] = this.depKey;
    this.templateViewService.globalMasterDataForQueries['GroupName'] = this.grpName;
    this.templateViewService.globalMasterDataForQueries['filterParameter'] = `StartDateTime >= "${dates[0]}" and StartDateTime <= "${dates[1]}"`;

  }

  emitFilters($event: any) {
    if ($event == 'upload' || $event == 'update') {
      this.jobType = $event;
    }
    this.depKey = $event?.DeploymentKey?.object?.DeploymentKey === 'All Deployments' ? '' : $event?.DeploymentKey?.object?.DeploymentKey || this.depKey;
    this.grpName = $event?.GroupName?.object?.GroupName === 'All Group' ? '' : $event?.GroupName?.object?.GroupName || this.grpName;
    this.dateRange = $event?.StartDateTime === this.dateRange ? this.dateRange : $event?.StartDateTime || this.dateRange;
    this.getOfflineProcessList(this.jobType, this.depKey, this.grpName, this.dateRange);
  }

  getOfflineProcessList(listOf?, DeploymentKey?, GroupName?, StartDateTime?) {
    const dates = StartDateTime.split(" - ");
    this.jobType = listOf;
    this.templateViewService.globalMasterDataForQueries['type'] = listOf;
    let queryString = `StartDateTime >= "${dates[0]}" and StartDateTime <= "${dates[1]}"`
    let queryVariable = { type: listOf, DeploymentKey: DeploymentKey, GroupName: GroupName,  "filterParameter": queryString }
    let query = this.SubMenuCardModel.GetQuery("VitalAdminScheduledJobs");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(data => {
      this.ngxService.stop();
      if (!data?.errors) {
        this.AddGridData(data?.data?.submenuData);
      }
    }, error => {
      console.error(error);
    });

  }

  AddGridData(values?) {
    this.ngxService.start();
    this.gridHeader = ['DeploymentKey', 'GroupName', 'FeatureName', 'StartDateTime', 'jobStatus', 'ErrorReason', 'AzurefileId'];
    this.gridheaderList = this.templateHeaders.templateList;
    const manageConfigHeader = this.gridheaderList?.find(header => header?.templateName === 'Offline Process');
    this.ListedGridHeader = manageConfigHeader ? manageConfigHeader?.headers : [];
    this.listHeaderbackup = JSON.parse(JSON.stringify(this.ListedGridHeader));
    let gridarray = values ? values.map(item => {
      const primary = {};
      Object.entries(item).forEach(([key, value]) => {
        if (this.gridHeader?.includes(key)) {
          primary[key] = value;
        }
      });
      return primary;
    }) : [];
    this.gridData = new CollectionView(gridarray);
    this.ngxService.stop();
  }


}
