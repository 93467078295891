import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-site-format',
  templateUrl: './manage-site-format.component.html',
  styleUrls: ['./manage-site-format.component.scss'],
})
export class ManageSiteFormatComponent implements OnInit {
  formatBuilder: any = [];
  placeHolders: any = [];
  draggedIndex: number | null = null;
  selectedTempIndex: number = -1;
  defaultFormatBuilder: any = [];
  dbName: string = '';
  actionedBy: string = '-100';
  maxLength:number = 100;
  accountCaseTypeId: string = '';
  entityType: string = 'Account Casetype';
  sequenceType: string = 'siteformat';
  findings: string = 'Findings';
  activityEntity: any;
  recentCaseTypeData:any = [];
  recentCaseTypeString:string = '';
  completeFormatBuilder = [];
  FormatID: number = 0;
  isFirstTime: boolean = true;
  backupFormat:string = '';
  defaultFormats:string= '';
  standardSiteFormat:any = {};
  siteDataList: any = [];
  emptyData :boolean = false;
  keydownValue:string= '';
  maxLengthMsg:string = 'Maximum(100) character limit reached';
  predefindedPlaceholder:any=['%bodysite%','%subsite%','%sitelocation%','%extractionprocedure%','%altnom%','%findings%']
  placeholderRegex = new RegExp(`(${this.predefindedPlaceholder.join('|')})`, 'gi');
  @Input() createButtonEnabled:boolean = false;
  @Input() siteFormat: number;
  @Input() caseType: string = '';
  @Input() orgId: string = '';
  @Output() isFormChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('textareaElement') textareaElement!: ElementRef;


  constructor(
    public ngxService: NgxUiLoaderService,
    public commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private activityService: ActivityTrackerService,
    private matdialog: MatDialog,
  ) {}
  async ngOnInit() {
    let _this = this;
    try{
    _this.activityService.getActivitySession.subscribe(res => _this.activityEntity = res);
    _this.activityEntity.entityId = '';
    _this.activityService.setActivitySession(_this.activityEntity);
    _this.accountCaseTypeId = _this.vitalHttpServices.accountCaseTypeId;
    _this.dbName = _this.vitalHttpServices?.deploymentKey?.toString();
    _this.actionedBy = sessionStorage?.getItem('Userid')
      ? sessionStorage?.getItem('Userid').toString()
      : '-100';
    _this.ngxService.start();
    let query = _this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = {
      context: 'ManageSiteFormat',
      //keyword: 'siteformat' + _this.siteFormat,
    };
    let queryResult = _this.commonService.GetCardRequest(queryVariable, query);
    await _this.vitalHttpServices.GetData(queryResult, 'configdb').toPromise().then(
      async (Resdata) => {
        if (!Resdata.errors) {
          const submenuData = Resdata?.data?.submenuData;
          const jsonTemp = submenuData.find((item) => item.Keyword === 'StandardTemplates' && item.Status === true);
          const standard = JSON.parse(jsonTemp.Items_JSON);
          this.standardSiteFormat["data"] = standard?.['standardformat'+_this.siteFormat] || [];
          const jsonFormat = submenuData.find((item) => item.Keyword === ('SiteFormat' + _this.siteFormat) && item.Status === true);
          _this.placeHolders = JSON.parse(jsonFormat.Items_JSON) || [];
          _this.completeFormatBuilder = structuredClone(_this.placeHolders);
          let query1 = _this.vitalHttpServices.GetQuery('getidentityformatdetails');
          let queryVariable1 = {
            entityid: _this.accountCaseTypeId,
            entitytype: _this.entityType,
            sequencetype: _this.sequenceType,
            orgid: _this.orgId,
            attributetype: _this.findings,
            isactive: 'true',
          };
          // getting existing identityformat and findings
          let queryResult1 = _this.commonService.GetCardRequest(
            queryVariable1,
            query1
          );
         await _this.vitalHttpServices.GetData(queryResult1, _this.dbName).toPromise().then(
            (res) => {
              if (!res.errors) {
                _this.recentCaseData(res?.data);
                _this.ngxService.stop();
              } else {
                _this.ngxService.stop();
              }
            },
            (error) => {
              _this.ngxService.stop();
              console.error(error);
            }
          );
        } else {
          _this.ngxService.stop();
        }
      },
      (error) => {
        _this.ngxService.stop();
        console.error(error);
      }
    );
  }
    catch (error) {
      console.error(error);
      _this.ngxService.stop();
    }
  }

  dataPreparationForTemp(){
    try{
       this.standardSiteFormat["data"].forEach(e => {
        e.forEach((element, i) => {
          const entry = this.recentCaseTypeData.find(item => item.hasOwnProperty(element.text));
          const value = entry ? entry[element.text] : element.value;
          element['displayvalue'] = this.emptyData ? '' : value;
        });
       });
    }
    catch(error){
        console.log(error);
    }
  }

  previewDataPreparation(builder:any){
    this.recentCaseTypeString  = '';
    if(this.emptyData){
      return;
    }
    builder?.forEach(format => {
    // Find the corresponding entry in recentCaseTypeData
    const entry = this.recentCaseTypeData.find(item => item.hasOwnProperty(format));

    // If the entry exists, append the value to the result string
    if (entry) {
        //this.recentCaseTypeString += entry[format] + ' ';
        this.recentCaseTypeString += entry[format];
    }
    else{
      // if(format == ',') {
      //   // if there is , space is not required
      //   this.recentCaseTypeString = this.recentCaseTypeString.trim();
      // }
      //this.recentCaseTypeString += format + ' ';
      this.recentCaseTypeString += format;
    }
  });
  return this.recentCaseTypeString;
  }

  findResultData(recentcase:any){
  this.recentCaseTypeData.push({'Body Site':recentcase?.['bodysite'] ? recentcase['bodysite'] :'bodysite'});
  this.recentCaseTypeData.push({'Sub Site':recentcase?.['subsite'] ? recentcase['subsite'] :'subsite'});
  this.recentCaseTypeData.push({'Site Location':recentcase?.['sitelocation'] ? recentcase['sitelocation'] :'sitelocation'});
  this.recentCaseTypeData.push({'Display Name':recentcase?.['displayname'] ? recentcase['displayname'] :'Altnom'})
  this.recentCaseTypeData.push({'Extraction Procedure':recentcase?.['extractionprocedure'] ? recentcase['extractionprocedure']: 'extractionprocedure'})
  this.recentCaseTypeData.push({'Findings':recentcase?.['findings'] ? recentcase['findings']: 'Findings'});
  }

  async recentCaseData(indentityFormat:any){
    let _this = this;
    try{
      _this.ngxService.start("format")
    await this.vitalHttpServices.getCasesRecentData(_this.orgId, _this.caseType, _this.siteFormat, _this.dbName).toPromise().then(
      (res) =>{
          if(res?.content == '' || (!res?.content?.bodysite && !res?.content?.subsite && !res?.content?.sitelocation
            && !res?.content?.displayname && !res?.content?.extractionprocedure && !res?.content?.extractionprocedure)){
              _this.emptyData = true;
            }
          _this.findResultData(res?.content)
          _this.prepareFormatBuilder(indentityFormat);
          _this.dataPreparationForTemp();
          _this.ngxService.stop("format")
        },
      (error) => {
        _this.ngxService.stop("format");
        console.error(error);
      }
    );
  }
  catch(error){
    console.log(error);
    _this.ngxService.stop("format");
  }
  }
  prepareFormatBuilder(res) {
    const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement; // Explicitly cast to HTMLTextAreaElement
    let formatBuilders = res?.identityFormats || []; // getting existing formats
    let attributes = res?.attributes || []; // getting casetypes for finding to add placeholders
    // existing format builders will show in UI
    if (formatBuilders.length) {
      this.defaultFormats = formatBuilders[0].Format;
      this.FormatID = formatBuilders[0].FormatID;
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
      this.isFirstTime = this.FormatID > 0 ?  false : true;
      textarea.value =  structuredClone(this.defaultFormats + ' ');
      textarea.focus();
      //this.backupFormat = structuredClone(this.defaultFormats);
      this.pushToFormatBuilder(textarea.value);
    }

    // based on the findings, findings place holder will add in placeholders
    if (attributes && attributes.length > 0) {
      // Get unique types from attributes
      const distinctTypes = [
        ...new Set(
          attributes.map((item) => item?.AttributeContext?.trim().toLowerCase())
        ),
      ];
      if (distinctTypes.includes(this.caseType?.trim().toLowerCase())) {
        this.placeHolders.push({"text":this.findings,"value":this.findings});
        this.completeFormatBuilder.push({"text":this.findings,"value":this.findings});
      }
    }
  }

  // Function to append the selected placeholder to the description in the form.
  appendToTextarea(event: any) {
    let selectedValue = event?.value.trim(); // Get the selected value without extra spaces
    const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement; // Explicitly cast to HTMLTextAreaElement
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    let currentValue = textarea.value.trim();
    const wordsArray = currentValue.split(this.placeholderRegex).filter(Boolean);//.map(word => word.toLowerCase()); // Split by placeholders

    // Check if the selected value is already in the currentValue
    // const wordExists = wordsArray.some(word => word.trim().toLowerCase() === '%'+selectedValue.toLowerCase()+'%' || word.trim().toLowerCase() === '%'+selectedValue.toLowerCase()+'%,');
    const wordExists = wordsArray.some(word => word.trim().toLowerCase() === '%'+selectedValue.toLowerCase()+'%');

    if (wordExists) {
      // If the word exists, remove it
      // const filteredArray = wordsArray.filter(word => (word.trim().toLowerCase() !== '%'+selectedValue.toLowerCase()+'%' && word.trim().toLowerCase() !== '%'+selectedValue.toLowerCase()+'%,'));
      const filteredArray = wordsArray.filter(word => (word.trim().toLowerCase() !== '%'+selectedValue.toLowerCase()+'%'));

      if(filteredArray?.length == 1 && filteredArray[0] == ''){
          currentValue = ''
        }
        else{
        currentValue = filteredArray.join('').trim(); // Update currentValue without the removed word
        currentValue = currentValue + ' ';
        }
    } else {

      if(selectedValue.length + textarea?.value?.length > this.maxLength) {
        this._snackbar.open(this.maxLengthMsg, 'Close');
        return;
      }
      // Check if there is a space before adding the selected value
      const spaceBeforeWord = startPos > 0 && currentValue[startPos - 1] !== ' ' ? ' ' : '';
      // If the word doesn't exist, add it
      currentValue = currentValue.substring(0, startPos) + spaceBeforeWord + '%' + selectedValue + '%' + ' ' + currentValue.substring(endPos).trim(); // Add the selected value
    }

    // Update the textarea with the new value
    textarea.value = currentValue;
    // Reset the cursor position
    textarea.selectionStart = startPos + (wordExists ? 0 : selectedValue.length + 4); // Adjust for addition or deletion
    textarea.selectionEnd = textarea.selectionStart; // Keep the cursor at the same position
    textarea.focus();
    this.pushToFormatBuilder(textarea.value);
  }

  onKeyup(){
    const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement;
    let data = textarea.value;
    this.formatBuilder = [];
    this.pushToFormatBuilder(data);
  }

// making chips are selected
  pushToFormatBuilder(data:any){
    if(this.defaultFormats.trim().toLowerCase() == data.trim().toLowerCase()){
      this.isFormChanged.emit(false);
    }
    else{
      this.isFormChanged.emit(true);
    }
    this.formatBuilder = [];
    const wordsArray = data.split(this.placeholderRegex).filter(Boolean).map(word => word);

    wordsArray.forEach(word => {
      // Find the item in completeFormatBuilder that matches the word
      // const match = this.completeFormatBuilder.find(item => '%'+item.value.toLowerCase()+'%' === word.toLowerCase() || '%'+item.value.toLowerCase()+'%,' === word.toLowerCase());
      const match = this.completeFormatBuilder.find(item => '%'+item.value.toLowerCase()+'%' === word.toLowerCase());


      // If a match is found, push the corresponding text into this.formatBuilder
      if (match) {
        this.formatBuilder.push(match.text);
        // if('%'+match.value.toLowerCase()+'%,' === word){  // checking comma is there immediate to word then add comma
        //   this.formatBuilder.push(',');
        // }
      }
      else{
        this.formatBuilder.push(word);
      }
    });
    if(this.formatBuilder?.length == 1 && this.formatBuilder[0] == ' '){
      this.formatBuilder = [];
    }
  this.previewDataPreparation(this.formatBuilder);
  this.selectStandardTemp(data);
  }

  templateClick(index: number, item: any) {
    if(!this.textareaElement) return;
    const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement; // Explicitly cast to HTMLTextAreaElement
    if(this.selectedTempIndex == index){
      textarea.value = this.defaultFormats;
      this.pushToFormatBuilder(textarea.value);
      return;
    }
    let data = this.createTextareaValue(item);
    textarea.value = data + ' ';
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    this.pushToFormatBuilder(textarea.value);
  }

  createTextareaValue(item){
    let data = '';
    item.forEach((element, i) => {
      const value = element.value.trim();

      if (i === 0 && (value === ',' ||value.toLowerCase() === 'or')) {
         data += value;
      } else if (i === 0 ) {
         data += `%${value}%`;
      }
        else if (value === ',') {
          data += value;
      } else if (value.toLowerCase() === 'or') {
          data += ` ${value}`;
      } else {
          data += ` %${value}%`;
      }
  });
  return data;
  }

  deleteSiteFormat(){
    try{
    let arrayObj = [{
      formatids: this.FormatID.toString(),
    }];
    let dialogRef = this.matdialog.open(ConfirmLabadminComponent, {
      autoFocus: false,
      disableClose: true,
      width: '360px',
      data: { header: "", message: "This action will permanently delete site format.", alert: "Do you want to continue?", continue: "Delete", cancel: "Cancel" }
    })
    return dialogRef.afterClosed().subscribe(result => {
          if(result){
            this.ngxService.start('deleteformat');
            this.vitalHttpServices.DeleteIdentityFormats(arrayObj, this.dbName).subscribe((data) =>{
              this.ngxService.stop('deleteformat');
              if (!data.errors) {
                this._snackbar.open('Site format deleted successfully!', 'Close');
                let newObject = {};
                newObject["format"] = '';
                this.isFirstTime = true;
                this.activityEntity.entityId = '';
                this.activityService.setActivitySession(this.activityEntity);
                let oldformat = structuredClone(this.defaultFormats);
                this.FormatID = 0;
                this.defaultFormats = '';
                this.clearSiteFormat();
                this.activityTracker(newObject, oldformat,true);

              }else {
                this._snackbar.open('Site format deletion failed!', 'Close')
              }
            }, error => {
              this.ngxService.stop('deleteformat');
              console.error(error);
          });
        }
       });
      }
      catch (error) {
        this.ngxService.stop('deleteformat');
        console.error(error);
      }
  }

  restoreDefaults() {
    const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement; // Explicitly cast to HTMLTextAreaElement
    if(this.defaultFormats.trim() == ''){
      textarea.value = '';
    }
    else{
      textarea.value = structuredClone(this.defaultFormats + ' ');
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    }
    textarea.focus();
    this.pushToFormatBuilder(textarea.value);
  }

  clearSiteFormat(){
    const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement;
    textarea.value = '';
    textarea.focus();
    this.formatBuilder = []
    this.selectedTempIndex = -1;
    this.pushToFormatBuilder("");
  }

  saveSiteFormat() {
    try {
      const textarea = this.textareaElement.nativeElement as HTMLTextAreaElement; // Explicitly cast to HTMLTextAreaElement
      let format = textarea.value.trim();
      if(format.length > this.maxLength){
        this._snackbar.open(this.maxLengthMsg, 'Close');
        return;
      }
      if (!format.length) {
        this._snackbar.open('Format should not be empty', 'Close');
        return;
      }
      if(this.defaultFormats.trim().toLowerCase() == format.toLowerCase()){
        this._snackbar.open('There are no changes to update', 'Close');
        return;
      }
      this.ngxService.start("upsert");
      let newObject = {
        formatid: this.FormatID,
        entityid: this.accountCaseTypeId,
        entitytype: this.entityType,
        sequencetype: this.sequenceType,
        format: format,
        actionedby: this.actionedBy,
      };
      this.vitalHttpServices.siteFormatUpsert(newObject, this.dbName).subscribe(
        (data) => {
          this.ngxService.stop("upsert");
          if (
            data?.content &&
            [
              'site format created successfully',
              'site format updated successfully',
              'there are no changes to update'
            ].some((msg) => msg === data.content.message.toLowerCase())
          ) {
            if(format.length == this.maxLength){
              textarea.value = format
            }
            else{
            textarea.value = format + ' ';
            }
            textarea.focus();
            this.FormatID = data?.content?.formatid;
            this.activityEntity.entityId = '';
            this.activityService.setActivitySession(this.activityEntity);
            this.activityTracker(newObject, structuredClone(this.defaultFormats),false);
            this.defaultFormats = structuredClone(format);
            this.isFormChanged.emit(false);
            this.isFirstTime = false;
            this._snackbar.open(data?.content?.message, 'Close');
          } else {
            this._snackbar.open('Failed', 'Close');
            this.ngxService.stop("upsert");
          }
        },
        (error) => {
          this._snackbar.open('Failed', 'Close');
          console.log(error);
          this.ngxService.stop("upsert");
        }
      );
    } catch (error) {
      this._snackbar.open('Failed', 'Close');
      console.log(error);
      this.ngxService.stop("upsert");
    }
  }
  activityTracker(newObj:any, oldFormat:string, isDeleted:boolean=false){
    if(newObj["format"].trim().toLowerCase() == oldFormat.trim().toLowerCase()) {return};
    let templateObj = { menuURL: "Site Format" }
    let sessionId = this.commonService.generateGuid();
    let jsonFinalData = {}
    jsonFinalData["format"] = {
      oldValue: oldFormat,
      newValue: newObj["format"]
    };
    if(isDeleted){
      this.commonService.createActivityObject('', this.caseType, templateObj?.menuURL,
        'Delete', '', '', sessionId, {'format':'format'}, '', jsonFinalData);
    }
    else{
      this.commonService.createActivityObject('', this.caseType, templateObj?.menuURL,
      this.isFirstTime == true ? 'Create':'Edit', '', '', sessionId, {'format':'format'}, '', jsonFinalData);
    }
  }

  selectStandardTemp(currentTextareaValue:string){

    let temp = this.standardSiteFormat?.["data"];

    for (let j = 0; j < temp.length; j++) {
    let tempData = this.createTextareaValue(temp[j]);

    if (tempData.trim().toLowerCase() === currentTextareaValue.trim().toLowerCase()) {
      this.selectedTempIndex = j; // Set index when a match is found
      break; // Stop the loop once a match is found
    }else{
      this.selectedTempIndex = -1;
    }
  }
  }

}
