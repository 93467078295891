import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { VaRulesExclusions } from '../fax/fax.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
@Component({
  selector: 'app-distribute-email',
  templateUrl: './distribute-email.component.html',
  styleUrls: ['./distribute-email.component.scss']
})
export class DistributeEmailComponent implements OnInit {
  selectedReportType: any = 'Attachment';
  step = 0;
  secondaryRuleId: any;

  constructor(
    private _labadminservice: LabadminService,
    private _vitalHttpServices: VitalHttpServices,
    private _fb: FormBuilder,
    private _dailogService: DialogService,
    public _dialogRef: MatDialogRef<DistributeEmailComponent>,
    private _snackbar: MatSnackBar,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.getEmailPasswordList();
  }
  categories: any = ['Case Type', 'Services', 'Physicians'];
  defaultReports: any = ['Attachment', 'Hyperlink'];
  selectedLabs: any
  emailForm = this._fb.group({
    laboratory: ['', [Validators.required]],
    emailAddress: [[], [Validators.required]],
    emailPassword: '',
    customPassword : ''
  })
  labAccountDetails = this.data.labAccountDetails;
  distributedEmailId= this.data.distributedEmailId.emailId;
  policyFormat= this.data.distributedEmailId.passwordFormat;
  policyName = this.data.distributedEmailId.passwordName;
  passwordPoliciesList : any = [];
  emailIds : string[]= [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  saveEmail() {
    let finalObj
    if (!this.emailForm.valid) {
      this._snackbar.open('Enter valid email address', "Close")
      return;
    }
    finalObj = { ...this.emailForm.value, ...{ 'reportAs': this.selectedReportType?.toLowerCase() == "hyperlink" ? "AsLink" : 'Attachment' } }
    finalObj['emailAddress'] = finalObj?.emailAddress?.join(';');
    finalObj['iscustom'] =  finalObj?.emailPassword == "Custom Password" ? true : false;
    finalObj['emailPassword'] = finalObj?.emailPassword == "Custom Password" ? finalObj?.customPassword : finalObj?.emailPassword     
    this.saveCaseTypeWithEmail(finalObj);
  }

  onReportTypeChange(value) {
    this.selectedReportType = value ;
    this.emailForm.patchValue({
      emailAddress: null,
      emailPassword: '',
      customPassword :''
    })
    Object.keys(this.emailForm.controls).forEach(key => {
      this.emailForm.controls[key].setErrors(null)
    });
  }



  ///sample///

  setStep(index: number) {
    this.step = index;
  }

  onNoClick() {
    this._dialogRef.close('');
  }

  emailAdvanceAlert = '';
  casetypesList: any = []
  accountId: number = this.data.accountId
  ruleName: string = this.data.ruleName
  physicianList: any
  ruleId: number = 0
  loggedInUserId = this.data.loggedInUserId
  labOrgId: number = this.data.labOrgid
  orderingCaseCategorySelection = new SelectionModel<any>(true, []);
  orderingPhysicianSelection = new SelectionModel<any>(true, []);
  orderingServiceSelection = new SelectionModel<any>(true, []);
  caseCategoriesList: any = []
  SavedVAExclusionData: VaRulesExclusions[] = []
  defaultServices: any
  saveFilteredCaseTypes: any = [];


  get getter() {
    return this.emailForm.controls.laboratory
  }

  ngOnInit(): void {
    this.emailForm.controls.laboratory.valueChanges.subscribe((res) => {
      this.LoadCaseCategoryPhysicianDataForEmail(res)
    })
    if (this.labAccountDetails.length == 1) {
      this.emailForm.controls.laboratory.patchValue(this.labAccountDetails[0].AccountId)
    }
 
    // this.emailFormControl.setValue('');
  }

  LoadCaseCategoryPhysicianDataForEmail(locationId: any = '') {
    var LabaccountId = locationId == '' ? 0 : locationId
    const searchValue = `${this.labOrgId}_${this.accountId}_Email_Distribution_OF_Secondary`;
    this.GetVARulesSearchDetails(searchValue, LabaccountId)
  }

  GetVARulesSearchDetails(SearchValue: string, labaccountid: any) {
    this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(true))
    this._labadminservice.GetVARulesSearchDetails(SearchValue, this._labadminservice.deploymentKey)
      .subscribe({
        next: (data: any[]) => {
          this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(false))
          if (data.length) {
            this.ruleId = data[0].RuleId
            this.secondaryRuleId = data[1].RuleId
            if (this.ruleId) {
              this.VaruleExclusionDetails(this.ruleId, labaccountid)
            }
            else this.getEmailReportData(labaccountid)
          }
          else this.getEmailReportData(labaccountid)
        }, error: (err) => {
          console.error(err)
          this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(false))
        }
      })
  }

  getCasetypeByAccId(labAccId): Observable<any> {

    const exclusionIds = new Set(
      this.SavedVAExclusionData
        .filter(exclusion => exclusion.entityType === 'Casetype')
        .map(exclusion => exclusion.entityId)
    );
    return this._labadminservice.getCaseCategoryByAccId('0', this.labOrgId, labAccId, this._labadminservice.deploymentKey).pipe(
      map(caseCategories =>
        caseCategories.map(category => ({
          ...category,
          selectionModel: new SelectionModel(true, [...this.getCasetypeIdHelper(category.Cases.filter(caseItem => !exclusionIds.has(caseItem.CaseType)))])
        }))
      )
    )
  }

  getCasetypeDisplayNameHelper(caseType: any) {
    return caseType.reduce((p, c) => ([...p, c.CaseTypeDisplayname]), [])
  }

  getCasetypeIdHelper(caseType: any) {
    return caseType.reduce((p, c) => ([...p, c.CaseTypeId]), [])
  }

  saveCaseTypeWithEmail(emailObj) {
    var res = this.caseCategoriesList.reduce((p, c) => (p || c.selectionModel.hasValue()), false)

    if (!res && this.orderingServiceSelection.selected.length == 0 && this.orderingPhysicianSelection.selected.length == 0) {
      this._dailogService.openDialog('Alert', 'Excluding all is not allowed, Please include at least one checkbox in each Category', '', 'OK', 'dontshow')
      return
    }

    let tempSaveFilteredCaseTypes = [];
    for (const obj of this.caseCategoriesList) {
      let selectedCaseIds = obj.selectionModel.selected      
      obj.Cases.map((i: any) => {
        if (!selectedCaseIds.includes(i.CaseTypeId)) {
          tempSaveFilteredCaseTypes.push({
            "ruleId": this.ruleId,//to be added
            "entityType": 'Casetype',
            "entityId": i.CaseType,
            "Type": this.ruleName,
            "Labid": this.emailForm.controls.laboratory.value
          })
        }
      });
    }

    this.defaultServices.filter((x: any) => !this.orderingServiceSelection.selected.includes(x)).map((i: any) => {
      tempSaveFilteredCaseTypes.push({
        "ruleId": this.ruleId,
        "entityType": 'Service',
        "entityId": i.value,
        "Type": this.ruleName,
        "Labid": this.emailForm.controls.laboratory.value
      })
    });

    this.physicianList.filter((x: any) => !this.orderingPhysicianSelection.selected.includes(x)).map((i: any) => {
      tempSaveFilteredCaseTypes.push({
        "ruleId": this.ruleId,
        "entityType": 'orderingPhysician',
        "entityId": i.UserID,
        "Type": this.ruleName,
        "Labid": this.emailForm.controls.laboratory.value
      })
    });
    this.saveFilteredCaseTypes.exclusionData = tempSaveFilteredCaseTypes;
    this.saveFilteredCaseTypes.emailData = emailObj;
    if (this.ruleId > 0) {
      this.DeleteVaRulesExclusions(this.ruleId, this.saveFilteredCaseTypes?.exclusionData)
      if (this.secondaryRuleId > 0) {
        let exclusionData = this.saveFilteredCaseTypes?.exclusionData.map(va => ({
          ...va, // Copy existing properties
          ruleId: this.secondaryRuleId // Update ruleId
        }));
        this.DeleteVaRulesExclusions(this.secondaryRuleId, exclusionData)
      }
      this.saveFilteredCaseTypes.exclusionData = [];
      this._dialogRef.close(this.saveFilteredCaseTypes)
    }
    else this._dialogRef.close(this.saveFilteredCaseTypes);
  }

  DeleteVaRulesExclusions(ruleID: number, resData: any) {

    this._labadminservice.DeleteVaRulesExclusions(ruleID, this._labadminservice.deploymentKey).subscribe({
      next: (data) => {
        this._labadminservice.InsertVARulesExclusions(this.loggedInUserId, resData, this._labadminservice.deploymentKey).subscribe({
          next: (data) => console.log(data),
          error: (err) => console.error(err)
        })
      },
      error: (err) => console.error(err)
    })
  }

  toggleAllCaseCategory(caseCategoriesList) {
    if (this.isAllCasesCategorySelected(caseCategoriesList)) {
      for (let i of caseCategoriesList) {
        i.selectionModel.clear()
      }
      return
    }
    for (let i of caseCategoriesList) {
      i.selectionModel.clear();
      i.selectionModel.select(...this.getCasetypeIdHelper(i.Cases))
    }
  }

  isAllCasesCategorySelected(caseCategoriesList) {
    return caseCategoriesList.reduce((p, c) => (p && this.isAllCaseSelected(c.Cases, c.selectionModel)), true)
  }

  toggleAllCases(caseCategoryList, selectionmodel) {
    if (this.isAllCaseSelected(caseCategoryList.Cases, selectionmodel)) {
      selectionmodel.clear()
      return
    }
    selectionmodel.select(...this.getCasetypeIdHelper(caseCategoryList.Cases))
  }

  isAllCaseSelected(casesList, selectionmodel) {
    return casesList.length === selectionmodel.selected.length
  }

  toggleAllServices() {
    if (this.isSelectedService()) {
      this.orderingServiceSelection.clear();
      return;
    }
    this.orderingServiceSelection.select(...this.defaultServices);
  }

  isSelectedService() {
    const numSelected = this.orderingServiceSelection.selected.length;
    const numRows = this.defaultServices.length;
    return numSelected === numRows;
  }

  toggleAllPhysicians() {
    if (this.isSelectedPhysicains()) {
      this.orderingPhysicianSelection.clear();
      return;
    }
    this.orderingPhysicianSelection.select(...this.physicianList);
  }

  isSelectedPhysicains() {
    const numSelected = this.orderingPhysicianSelection.selected.length;
    const numRows = this.physicianList.length;
    return numSelected === numRows;
  }

  VaruleExclusionDetails(ruleId: number, labaccountid: any) {
    this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(true))
    this._labadminservice.GetVAruleExclusionDetails(ruleId, this._labadminservice.deploymentKey).subscribe({
      next: (data: VaRulesExclusions[]) => {
        this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(false))
        this.SavedVAExclusionData = data
        this.getEmailReportData(labaccountid)
      },
      error: (err) => {
        console.error(err)
        this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(false))
      }
    })
  }
  //#region  loader
  loaderKeys = { caseListSection: 'caseListSection', servicesSection: 'servicesSection', physicianSection: 'physicianSection' }
  loaderSections = {
    caseListSection: { loading: false, error: null },
    servicesSection: { loading: false, error: null },
    physicianSection: { loading: false, error: null }
  }
  loader$ = new BehaviorSubject<any>(this.loaderSections)
  getLoaderObj = (loading: boolean = false, error: string | null = null) => ({ loading, error })
  changeLoadingState(sectionKey, value: { loading: boolean, error: string | null }) {
    let result = this.loader$.value
    result[sectionKey] = value
    this.loader$.next(result)
  }


  //#endregion


  getEmailReportData(LabaccountId: any) {
    if (this.ruleId == 0) {
      this.saveFilteredCaseTypes = this.data.savedObjects
    }
    this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(true))
    forkJoin([
      this.getCasetypeByAccId(LabaccountId),
      this.getServices(),
      this._labadminservice.physicianDataForFaxandPrinting(this.accountId, String(this.loggedInUserId), this._labadminservice.deploymentKey)
    ]).subscribe(([caseCategories, services, accounts]: [any, any, any]) => {
      this.changeLoadingState(this.loaderKeys.caseListSection, this.getLoaderObj(false))
      // Update case categories
      this.caseCategoriesList = caseCategories;

      this.orderingServiceSelection.clear();
      const jsonData = JSON.parse(services.Message);
      this.defaultServices = jsonData.map((item: any, index: number) => ({
        Attribute_Name: item.Attribute_Name,
        Attribute_Type: item.Attribute_Type,
        value: index + 1
      }));
      this.defaultServices = this.defaultServices.sort((a, b) => {
        return a.Attribute_Name.localeCompare(b.Attribute_Name);
      });

      // Select services excluding those in SavedVAExclusionData

      const serviceExclusionIds = new Set(
        this.SavedVAExclusionData
          .filter(exclusion => exclusion.entityType === 'Service')
          .map(exclusion => +exclusion.entityId)  // Convert entityId to number
      );
      const includedServices = this.defaultServices.filter(service => !serviceExclusionIds.has(service.value));
      this.orderingServiceSelection.select(...includedServices);

      // Update ordering facilities
      this.physicianList = accounts
      this.orderingPhysicianSelection.clear()
      const physicianexclusionIds = new Set(
        this.SavedVAExclusionData
          .filter(exclusion => exclusion.entityType === 'orderingPhysician')
          .map(exclusion => exclusion.entityId)
      );
      this.orderingPhysicianSelection.select(...accounts.filter(attribute => !physicianexclusionIds.has(attribute.UserID)))
    }
    )
  }

  getServices(): Observable<any> {
    let obj = {
      organizationid: -1,
      attributetype: ['Services'],
      casetype: '',
      tablename: 'OrganizationAttributes',
    };
    return this._vitalHttpServices.getOrganizationAttributes(obj, this._labadminservice.deploymentKey)
  }

   getEmailPasswordList(){
    this.passwordPoliciesList ={};
    let queryVariable = { "orgid": this._labadminservice.organizationId };
    let query = this._vitalHttpServices.GetQuery("getNotificationPassword");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this._vitalHttpServices.GetData(queryResult, this._labadminservice.deploymentKey).toPromise().then( res => {
      if (res) {
        this.passwordPoliciesList = this.toGetUniqueValues(res?.data?.passwordPolicyData?.filter(va => va.policyname != "Custom Password"),'policyformat');
      }
      this.passwordPoliciesList.push({ "policyformat": "Custom Password", "policyname": "Custom Password" });
      let password = this.passwordPoliciesList.find(va => this.policyName && va.policyname == this.policyName)
      this.emailForm.patchValue({
        emailAddress: this.distributedEmailId?.split(';') ?? null,
        emailPassword: password?.policyname ?? 'Custom Password',
        customPassword: this.policyFormat
      });
      this.emailIds = this.distributedEmailId ? this.distributedEmailId?.split(';') : [];
    }, error => {
      console.error(error);
    })
  }
  toGetUniqueValues(options, distinctKey) {
    const password = new Set();
    const uniqueData = options.filter(item => {
      const isUnique = !password.has(item[distinctKey]);
      if (isUnique) {
        password.add(item[distinctKey]);
      }
      return isUnique;
    });
    return [...uniqueData];
  }
  // get emailFormControl(): FormControl {
  //   return this.emailForm.get('emailAddress') as FormControl;
  // }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    if (value && this.validateEmail(value)) {
        this.emailIds.push(value);
      // this.emailFormControl.setValue('');
    }

    if (input) {
      input.value = '';
    }
  }

  remove(email: string): void {
    const index = this.emailIds.indexOf(email);
    if (index >= 0) {
      this.emailIds.splice(index, 1);
    }
  }

  edit(email: string, newEmail: string): void {
    const index = this.emailIds.indexOf(email);
    if (index >= 0 && this.validateEmail(newEmail)) {
      this.emailIds[index] = newEmail;
    }
  }

  validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  getPasswordDesc(password){
    if(this.passwordPoliciesList.length != 0 && password){
      let filtereredPassword = this.passwordPoliciesList.findIndex(va=> va.policyformat ==  password)
      return this.passwordPoliciesList[filtereredPassword].passworddescription;
    }else return '';
  }

  //#endregion
}