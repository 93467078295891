<div class="col-md-12 p-0" *ngIf="!uploadClicked && !copyDataClicked">
    <div class="modal-header admin-model-header mt-2 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt p-0">
            <strong class="header-View"> Case Tags </strong>
        </h3>
    </div>
</div>

<div class="row col-sm-12 m-0 p-0" id="casetag-drop" *ngIf="!uploadClicked && !copyDataClicked">
    <div class="col-sm-12 button-wrap m-0 p-0">

        <div class="col-sm-5 filter-wrapper p-0">
            <!-- toggle wrap -->
            <span *ngIf="!isFlagNodata">
                <mat-button-toggle-group #toggleGroup="matButtonToggleGroup" (change)="onValChange(toggleGroup.value)"
                    value="Active" class="mt-2">
                    <mat-button-toggle value="Active" (click)="selectedVal = 'Active';activeData('active')">
                        Active
                    </mat-button-toggle>
                    <mat-button-toggle value="All" (click)="selectedVal = '';activeData('')"><span> &nbsp;&nbsp;</span>
                        All
                        <span> &nbsp;&nbsp;</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </span>

            <!-- filter wrap -->
            <div class="dropdown ml-3 mt-2">
                <!-- *ngIf="!isFlagNodata" -->
                <!-- [ngClass]="!isFlag?'ct-admin-btn-success-outline-filter' :'ct-admin-btn-success-outline'" -->
                <button mat-raised-button class="ct-admin-btn-success-outline" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="isFlag = !isFlag">
                    <!-- <img [src]="isFlag ? '../../../../assets/icons/case_tags/filter.svg' : '../../../../assets/icons/case_tags/filter_white.svg'"
                alt="Filter" class="material-icons md-24 cursor"> -->
                    <!-- <img src="../../../../assets/icons/case_tags/filter.svg" alt="Filter"
                class="material-icons md-24 cursor"> -->
                    <span>Filters</span>
                </button>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <span (click)="$event.stopPropagation()">
                        <div class="col-sm-12 d-flex">
                            <div class="col-sm-6 filter-data p-0">Filters</div>
                            <div class="col-sm-6 clear-all">
                                <span (click)="clearAll()">
                                    Clear All
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12 m-0">
                            <div class="row">
                                <div class="col-sm-4">
                                    <app-case-tags-filter #caseTypeFilter [DataList]="CasetypeDataList"
                                        [displayName]="'casetype'" [placeholder]="'Search Case Type'"
                                        [isEditable]='isEditable'
                                        (myObjectChange)="handleDataChange('listCasetype',$event)">
                                    </app-case-tags-filter>
                                </div>
                                <div class="col-sm-4">
                                    <app-case-tags-filter #caseStatusFilter [DataList]="caseStatusDataList"
                                        [displayName]="'item'" [placeholder]="'Search Case Status'"
                                        [isEditable]='isEditable'
                                        (myObjectChange)="handleDataChange('listStatus',$event)">
                                    </app-case-tags-filter>
                                </div>
                                <div class="col-sm-4">
                                    <app-case-tags-filter #roleFilter [DataList]="RoleList"
                                        [displayName]="'displayname'" [placeholder]="'Search Roles'"
                                        [isEditable]='isEditable'
                                        (myObjectChange)="handleDataChange('listRoles',$event)">
                                    </app-case-tags-filter>
                                </div>
                            </div>
                        </div>
                    </span>

                    <div class="row col-sm-12 button-wrap p-0">
                        <!-- [disabled]="!checkChipExists(listCasetype,listStatus,listRoles)" -->
                        <button mat-raised-button class="ct-admin-btn-success-outline" (click)="applyChanges()"
                            type="button">Apply</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- create button wrap -->
        <div class="col-sm-7 button-wrapper p-0">
            <!-- copy create & upload  -->
            <div class="button-wrap">
                <app-button matTooltip="Copy" *ngIf="!copyBtn" [matTooltipDisabled]="copyBtn" (click)="loadCopyScreen()"
                    image="icons/Global_Icon.svg" class="mr-3" ></app-button>

                <app-button matTooltip="Flags" *ngIf="commonService.showFlagIcon" [matTooltipDisabled]="copyBtn" (click)="showMenuRelatedFlags()"
                    image="images/FlagIcon.svg" class="mr-3" ></app-button>

                <app-button buttonHoverText=":Bulk Upload" [matTooltipDisabled]="uploadBtn" [multiple]="true" [hideLeftBtn]="createBtn" [isRightButtonDisabled]="uploadBtn" [hideRightBtn]="uploadBtn"
                    image=":icons/Excel-Bulk Upload_white.svg" buttonText="Create" (leftBtnClick)="createCardPopup()"
                    (rightBtnClick)="loadUploadScreen()"></app-button>
            </div>
        </div>
    </div>
</div>

<div class="col-sm-12 content-slab button-wrap mt-2" *ngIf="!uploadClicked && !copyDataClicked">
    <img *ngIf="!isFlagNodata" [class.disabled]="exportBtn" class="excel"
        src="/assets/icons/case_tags/excel_download_blue.svg" matTooltip="Export" alt="Download As Excel"
        (click)="ExportExcel(cardData)">
</div>

<!-- expand collapse panel -->
<mat-expansion-panel [(expanded)]="xpandStatus" id="caseTags" class="row col-sm-12 p-0 mt-2"
    *ngIf="checkChipExists(updatedCasetypelist,updatedCaseStatuslist,updatedRolelist) && !uploadClicked && !copyDataClicked">
    <mat-expansion-panel-header class="pr-2 pl-2">
        <span class="w-100 d-flex m-0 p-0">
            <div *ngFor="let item of chipsList" class="tag-divider">
                <strong>{{item}}</strong>
            </div>
        </span>
    </mat-expansion-panel-header>
    <div class="col-sm-12 px-0">
        <div class="w-100 d-flex m-0 p-0 card-select">
            <div class="font-weight-normal chip-wrap1">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let case of updatedCasetypelist" [selectable]="selectable" [removable]="removable"
                        (removed)="removeChip($event,'listCasetype',case)">
                        {{case.casetype}}
                        <mat-icon matChipRemove (click)="removeChip({checked : false},'listCasetype',case)"
                            *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>

            <div class="font-weight-normal chip-wrap2">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let status of updatedCaseStatuslist" [selectable]="selectable"
                        [removable]="removable" (removed)="removeChip($event,'listStatus',status)">
                        {{status.item}}
                        <mat-icon matChipRemove (click)="removeChip({checked : false},'listStatus',status)"
                            *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>

            <div class="font-weight-normal chip-wrap3">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let role of updatedRolelist" [selectable]="selectable" [removable]="removable"
                        (removed)="removeChip($event,'listRoles',role)">
                        {{role.displayname}}
                        <mat-icon matChipRemove (click)="removeChip({checked : false},'listRoles',role)"
                            *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
</mat-expansion-panel>

<div *ngIf="!checkCardData(cardData) && !uploadClicked && !copyDataClicked">
    <!--card list wrap -->
    <div class="row mt-2 ml-0 p-0 card-scroll" *ngIf="!checkCardData(cardData)">
        <ng-container *ngFor="let i of sortByDate(cardData)">
            <div class="col-sm-4 pl-0" *ngIf="i.childList.length">
                <div class="card mb-4" [ngClass]="i.isactive ?'card' :'card-inactive'">
                    <div class="card-body" (click)="editCardPopup(i,cardData)">
                        <div class="col-sm-12 p-0 m-0" *ngIf="i.groupid == null">
                            <div class="col-sm-12 ct-card-title pr-2 pl-2 pt-2 m-0 font-weight-bold">{{i.groupname}}
                                <img *ngIf="i.tagname && i.tagname.split(',').length > 3"
                                    src="../../../../assets/icons/case_tags/scroll.svg" alt="Swap"
                                    class="p-0 m-0 material-icons md-22 swap" title="Scroll">
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 m-0 tag-wraper">
                            <div class="tag-pad" *ngFor="let item of i.childList">
                                <div [ngClass]="item.isactive ?'tag' :'tag-inactive'">
                                    <p class="m-0 font-weight-normal word-overflow">
                                        <span>
                                            {{item.tagname}}
                                        </span>
                                    </p>
                                    <span class="d-flex flex-wrap tagvalue-wrap">
                                        <ng-container *ngFor="let list of item?.tagvalue?.split(',')">
                                            <button type="button" data-toggle="modal" data-target=".bd-example-modal-lg"
                                                class="btn-design mr-1 mb-1 font-weight-light"
                                                [ngStyle]="tagColor(list,item)">
                                                <div class="ct-data-wrap">
                                                    {{list}}
                                                </div>
                                            </button>
                                        </ng-container>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- no data wrap -->
<span *ngIf="isFlagNodata && !uploadClicked && !copyDataClicked">
    <div class="nodata-wrap nodata-header-wrap">
        <div class="nodata-wrapper">No Data Available</div>
    </div>
</span>

<!--Copy from other entity-->
<div *ngIf="!uploadClicked && copyDataClicked">
    <div class="modal-header admin-model-header mt-2 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt p-0">
            <strong class="header-View">Copy Case Tags </strong>
        </h3>
    </div>
    <form [formGroup]="copyOrgTagsMethodForm" class="copy-collection mt-2">
        <div class="p-0 ml-0">
            <div class="col-sm-12 text-wrap-icdcode">
                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label><span class="error-msg">*</span>Deployment</mat-label>
                    <mat-select disableOptionCentering (selectionChange)="onChangeDeployment($event)" type="text"
                        matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label><span class="error-msg">*</span>Group </mat-label>
                    <input formControlName="frmOrganization" type="text" matInput #searchbar
                        (keyup)="fetchOrgSeries(copyOrgTagsMethodForm.value.frmOrganization)"
                        [matAutocomplete]="orgauto" placeholder="Search here ..." class="input-wrap">
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                title="{{ show.organizationname }} ({{show.organizationid}})"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                value="{{ show.orgname }}">
                                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>

                <!-- Roles!-->
                <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                    <mat-label>Role</mat-label>
                    <input type="text" matInput formControlName="frmRole" [(ngModel)]="searchUserInput" #searchuserbar
                        (keyup)="fetchRoleSeries(searchuserbar.value)" [matAutocomplete]="userauto" class="input-wrap"
                        placeholder="Search here ...">
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <mat-autocomplete #userauto="matAutocomplete">
                        <div *ngFor="let role of searchRoleList">
                            <mat-option class="mat-opt-align"
                                *ngIf="role.displayname.toString().toLowerCase().includes(searchUserInput.toString().toLowerCase())"
                                (onSelectionChange)="selectedCopyDataUser($event, role)" value="{{ role.displayname }}"
                                title="{{ role.displayname }}">
                                <span>{{ role.displayname }}</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="align-center dml-btn row d-flex mt-2">
                <button mat-raised-button class="ct-admin-btn-success mr-4"
                    (click)="refreshGrid('list')">Return</button>
                <button mat-raised-button class="ct-admin-btn-success" [disabled]="disableGetDataBtn() || postUpload"
                    (click)="getDataToCopy()">Get Data</button>
            </div>

            <div *ngIf="gridwidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formateItem(flexgrid, $event)" [frozenColumns]="1">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'organizationid') && !(columnname == 'organizationtagid') && !(columnname == 'organizationcasetagvalueid') && !(columnname == 'groupid') &&
                     !(columnname == 'slno') && !(columnname == 'tablename') && !(columnname == 'notes') && !(columnname == 'tagvaluesequence')"
                            [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
            </div>
            <div *ngIf="!ReturnBtn && !postUpload && gridwidth > 0 "
                class="row align-center pl-3 mt-2 ml-5 align-center">
                <input type="checkbox" id="copyasinactive" [disabled]="selectedData.length==0"
                    ng-init='checkStatus=false' [checked]='checkStatus' ng-model='checkStatus'
                    (change)='checkValue($event)' class="border-style">
                <label class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
            </div>

            <div class="col-sm-12 pl-0">
                <div *ngIf="gridwidth > 0" class="m-1 p-0">
                    <!-- <button mat-raised-button class="ct-admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Return</button> -->
                    <span style="float: right;">
                        <button mat-raised-button class="ct-admin-btn-success mr-2" *ngIf="!postUpload"
                            (click)="cancelGrid()">Cancel</button>
                        <button mat-raised-button class="ct-admin-btn-success mr-2" *ngIf="postUpload"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button class="ct-admin-btn-success mr-2" *ngIf="!postUpload"
                            [disabled]="approveDisable()" (click)="approveCopyOrgTags(true)">Approve</button>
                        <button mat-raised-button class="ct-admin-btn-success mr-2" *ngIf="postUpload"
                            (click)="ExportUpload(flexgrid)">Download Result</button>
                    </span>
                </div>
            </div>

            <div class="container create-btn col-sm-12 align-center mt-3" *ngIf="gridwidth == 0">
                <div class="col-sm-10 nodata-wrap nodata-header-wrap">
                    <div class="nodata-wrapper">No Data Available</div>
                </div>
            </div>
        </div>
    </form>
</div>

<!--Upload-->
<div *ngIf="uploadClicked && !copyDataClicked" class="tag-form">
    <div class="modal-header admin-model-header mt-2 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt p-0">
            <strong class="header-View">Upload Case Tags </strong>
        </h3>
    </div>

    <div class="col-sm-12 p-0 flex-end">
        <mat-form-field class="col-sm-12 example-additional-selection p-0 drop-wrap" appearance="outline">
            <mat-label class="d-flex">
                Download Templates
            </mat-label>
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate('mandatoryFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="mt-2 mr-3">
            <span class="instr-size">
                <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px"
                    height="auto" />
                You can download the sample template file to upload the {{templateData.menuURL}}.</span>
        </div>
    </div>

    <div class="row d-flex m-0 mt-2">
        <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
            <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
                <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                    (change)="onFileChange($event)" hidden />
                <span class="align-center img-wrap">
                    <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
                </span>
                <span class="align-center">Drag & Drop Excel files here</span>
                <span class="align-center or-txt">OR</span>

                <span class="align-center">
                    <label class="btn-sm btn browse-file rounded" for="fileDropRef">Browse Files</label>
                </span>
            </div>
        </div>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
        <span class="col-sm-12 p-0">
            <button mat-raised-button class="col-sm-1 ct-admin-btn-success mt-4 font-size"
                (click)="refreshGrid('list')">
                Back
            </button>
        </span>
    </div>

    <div *ngIf="gridwidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
            [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)" [frozenColumns]="2">

            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" *ngIf="postUpload" [binding]="status" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'" [freeze]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
                [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="ct-admin-btn-success mr-4" *ngIf="gridwidth > 0"
                    (click)="gridRemove()">Return</button>
                <button mat-raised-button class="ct-admin-btn-success mr-4" *ngIf="gridwidth == 0"
                    (click)="refreshGrid('list')">Return</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <button mat-raised-button *ngIf="!postUpload" class="ct-admin-btn-success mr-2"
                    (click)="cancelUpload()">Cancel</button>
                <button mat-raised-button *ngIf="!postUpload" class="ct-admin-btn-success mr-2"
                    [disabled]="approveDisable()" (click)="approveOrgTags()">Approve</button>
                <button mat-raised-button *ngIf="postUpload" class="ct-admin-btn-success mr-2"
                    (click)="refreshGrid('list')">Finish</button>
                <button mat-raised-button *ngIf="postUpload" class="ct-admin-btn-success"
                    (click)="ExportUpload(grid)">Download Result</button>
            </div>
        </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>