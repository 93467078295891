import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { addNabvTab, disableListView, setReloadingPage, toggleReloadPage } from '../../store/actions/navbar.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LabadminSessionService } from '../../services/labadmin-session.service';

@Component({
  selector: 'app-client-location-details',
  templateUrl: './client-location-details.component.html',
  styleUrls: ['./client-location-details.component.scss'],
})
export class ClientLocationDetailsComponent implements OnInit, OnChanges {
  @Input() locationDetails: any | '';
  @Output() editLocation:EventEmitter<string>=new EventEmitter();
  @Input() isHideLocationGrid:boolean = false;
  @Input() showLoader:boolean=false

  logo: string = '';
  isChecked: boolean = false;
  sourceApp = 'VitalDx';
  isEditDisabled: boolean = false;
  isAddDisabled: boolean = false;
  isStatusDisabled: boolean = false;
  labAdminSessionData: any;

  constructor(
    //private dialog: MatDialog,
    private labAdminSessionService: LabadminSessionService,
    private _dailogService: DialogService,
    private labAdminService: LabadminService,
    private _snackbar: MatSnackBar,
    private store:Store,
    private _router:Router,
    private route:ActivatedRoute,
  ) {}
  ngOnInit() {
    this.labAdminSessionService.getLabAdminSession.subscribe((res) => {
      this.labAdminSessionData = res;
    });
    if (
      this.locationDetails?.['Logo'] != null &&
      this.locationDetails?.['Logo'] != ''
    ) {
      this.logo = 'data:image/jpg;base64,' + this.locationDetails['Logo'];
    }
    this.isChecked = this.locationDetails?.Status?.toLowerCase() == 'active' ? true : false;
    this.GetButtonAccess(this.labAdminService.rbacPermissions);
  }
  ngOnChanges() {
    if (
      this.locationDetails?.['Logo'] != null &&
      this.locationDetails?.['Logo'] != ''
    ) {
      this.logo = 'data:image/jpg;base64,' + this.locationDetails['Logo'];
    }
    this.isChecked = this.locationDetails?.Status?.toLowerCase() == 'active' ? true : false;

  }

  toggleChange(event: any) {
    let status  = event ? 'active' : 'inactive';
    this.isChecked = event
      this._dailogService.openLabadminConfimationdialog('', 'Are you sure want to change the status?', 'Yes', 'No')
      .afterClosed()
      .subscribe((result) => {
        if (result == 'true') {
          this.isChecked = event
       
          let StatusDetails = {
            sessionData :{
             ApplicationDomain: JSON.parse(
               this.labAdminSessionData.labAminSession.data
             ).domainUrl.split('//')[1],
             LoggedInUserOrgName: this.labAdminSessionData.userDetails.orgName,
             LoggedInUserOrgGuid: this.labAdminSessionData.userDetails.userOrgGUID,
             LoggedInUserOrdId: this.labAdminSessionData.userDetails.organizationId,
             accountsOrgGuid: '',
           },
            Id :this.locationDetails?.AccountID,
            status :status,
            modifiedby :this.labAdminSessionData['userDetails']['userId'],
            loggedInUserPrimaryAccountId :this.labAdminService.associatedAccountIds.split(',')[0],
            organizationId : this.locationDetails.OrganizationID,
            vFrom : 'location',
           }
          this.labAdminService.UpdateStatus(StatusDetails, this.labAdminService.deploymentKey,).subscribe({
            next:(data)=>
              {
                this.store.dispatch(toggleReloadPage({URL:'Clients'}));
                this.store.dispatch(toggleReloadPage({URL:'Locations'}));
                
                this._snackbar.open('Status updated successfully.', 'Close');
              },
              error:(error)=>
                {
                  this._snackbar.open('Something Went Wrong.', 'Close');
                  console.error(error)
                }
          })
        }
      else 
      {
        this.isChecked = !event
      }
    })
    }





  downloadLogo() {
    const link = document.createElement('a');
    link.href = this.logo;
    link.download = this.getFileNameFromBase64(this.logo, `${this.locationDetails.DisplayName}_logo.jpg`); // Provide a default filename if needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getFileNameFromBase64(dataUrl: string, defaultName: string): string {
    // You can modify this function if you want to derive the filename from somewhere else
    return defaultName;
  }

  // RBAC
  GetButtonAccess(actionButtonDetails) {

    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
        seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Clients")["SubMenu"]
        seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == 'Locations')["ActionButton"]
      }
      try {
        for (let i = 0; i < seletedMenuPermissions.length; i++) {
          switch (seletedMenuPermissions[i].Button.toLowerCase()) {
            case 'edit':
              this.isEditDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'status':
              this.isStatusDisabled =  seletedMenuPermissions[i].IsPermitted === "false"
              break;
              case 'add':
              this.isAddDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    EditLocation(){
    this.editLocation.emit(this.locationDetails.AccountID)
    }

    redirectToCreateOrEditPage(accountId:number=0)
  {
      let htext = accountId?'Edit Location':'Create Location'
      let routerLink = accountId?`/labadmin/clients/results/edit/${accountId}`: '/labadmin/clients/results/create'
      let obj : any = {Htext : htext, Level: 3, routerLink : routerLink}
      this.store.dispatch(addNabvTab({value: obj}));
      this.store.dispatch(disableListView({URL:'Locations'}))
      accountId?this._router.navigate(['edit',accountId],{relativeTo:this.route}):this._router.navigate(['create'],{relativeTo:this.route})

  }
  }

