import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { searchFilterPipetemplate } from 'src/app/core/utility/tripfilter.pipe';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog } from '@angular/material/dialog';
import { Action } from 'rxjs/internal/scheduler/Action';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { DialogService } from 'src/app/core/services/dialog.service';


@Component({
  selector: 'app-manage-l2lconfigurations',
  templateUrl: './manage-l2lconfigurations.component.html',
  styleUrls: ['./manage-l2lconfigurations.component.scss'],
  providers: [searchFilterPipetemplate]

})

export class ManageL2lconfigurationsComponent implements OnInit, OnDestroy {
  @Input() templateData: any;
  deploymentkey: string = '';
  accountid: string = '';
  orgid: string = '';
  create: boolean = false;
  editclicked: boolean = false;
  subMenuCardModel: any;
  gridShow: boolean = true;
  CreateForm: FormGroup;
  L2lgridData: wjcCore.CollectionView<any>;
  gridHeaderList: { templateName: string; headers: { Name: string; type: string; key: string; colWidth: string; }[]; }[];
  listedGridHeader: { Name: string; type: string; key: string; colWidth: string; }[];
  gridheader: string[];
  newAction: any = 'create';
  sourcelabdropdownvalues: any[];
  accountidfordropdown: any;
  destdropdownvalues: any[];
  destdata: any;
  editObj: any;
  savedata: any;
  sourceLabData: any;
  sourceLabId: any;
  destLabId: any;
  destLabData: any;
  labaguid: any;
  labbguid: any;

  allconfigurations: any;
  deleteObject: any;
  auditableColumns: any;
  activityEntity: any;
  activityEdit: { sourcelab: any; destinationlab: any; isexternal: any; issendoutlab: any; mappingserver: any; mappingdb: any; LabBurl: any; };
  createBtn: boolean=false;
  editBtn: boolean=false;
  deleteBtn: boolean=false;
  exportBtn: boolean = false;
  saveOrEditCompleted: any;

  soucelabvalue: any;
  noteMessage: String = '';
  // laba: any;
  // labb: any;
  // accida: any;
  // accidb: any;

  constructor(
    private _fb: FormBuilder,
    private datashare: DataShareService,
    private commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    public templateHeaders: TemplateHeadersService,
    private activityService: ActivityTrackerService,
    private _dailogService: DialogService,
    private templateViewService: TemplateViewService
  ) {
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }

  ngOnInit(): void {
    let templateData = this.templateData.submenuData;
    this.deploymentkey = sessionStorage.getItem('deploymentKey');
    this.accountid = sessionStorage.getItem('Locationid');
    this.orgid = sessionStorage.getItem('org_id');
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    // this.getdata();
    this.dropdownValuesForSourceLab();
    this.addDataToGrid(templateData);
    this.getAuditableDetails(this.templateData.menuURL);
    this.GetButtonAccess(this.vitalHttpServices?.SubmenuAction);

    this.templateViewService.globalMasterDataForQueries['accountid'] = Number(sessionStorage.getItem('AccountID'));
  }

  ngOnDestroy(): void {
    this.templateViewService.globalMasterDataForQueries['accountid'] = null;
  }

  getdata() {
    let templateData = this.templateData.submenuData
    let query = this.subMenuCardModel.GetQuery('getl2lconfigurations');
    let queryVariable = { orgid: this.orgid };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        this.ngxService.stop();
        if (!data.errors) {
          let templateData = data.data.submenuData;
          this.allconfigurations = data.data.submenuData;
          if (templateData.length > 0) {
            this.addDataToGrid(templateData);
          }
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'L2L Mapping') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
      }
    }
    this.gridheader = ['Sourcelab', 'Destinationlab', 'MappingServer', 'MappingDbName', 'LabBUrl', 'IsExternalDb', 'IsSendoutLab', 'LabAguid', 'LabBguid', 'L2LConfigId', 'accountid', 'AssociatedAccount', 'Sourceguid', 'Destguid'];
    let gridarray = []
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                if (value == null) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      this.L2lgridData = new CollectionView(gridarray)
    }
  }

  // createL2lConfiguration() {
  //   this.create = true;
  //   this.editclicked = false;
  //   this.createNewForm();
  //   this.newAction = 'create'
  //   this.dropdownValuesForSourceLab();
  // }

  // get getters() {
  //   return this.CreateForm.controls;
  // }

  // createNewForm() {
  //   this.CreateForm = this._fb.group({
  //     // sourcelab: [''],
  //     sourcelab: [sessionStorage.getItem('locationName',), Validators.required],
  //     destinationlab: ['', Validators.required],
  //     mappingserver: [''],
  //     mappingdb: [''],
  //     destlaburl: [''],
  //     sendoutlab: true,
  //     external: true
  //   })
  //   this.soucelabvalue = sessionStorage.getItem('locationName');
  //   let query = this.subMenuCardModel.GetQuery('getAssociatedSendout');
  //   let queryVariable = { accid: this.accountid, status: 'active' };
  //   let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //   this.ngxService.start();
  //   this.vitalHttpServices.GetData(queryResult).subscribe(
  //     data => {
  //       if (!data.errors) {
  //         this.ngxService.stop();
  //         if (data?.data?.submenuData) {
  //           this.destLabData = data.data.submenuData;
  //           this.destdropdownvalues = Array.from(new Set(data.data.submenuData.map(obj => obj.displayname)));
  //         }
  //       }
  //     },
  //     error => {
  //       this.ngxService.stop();
  //       console.error(error);
  //     }
  //   );
  // }

  returnbutton() {
    this.create = false;
    this.gridShow = true;
    this.refreshgrid();
  }

  // editL2lConfiguration(object: any) {
  //   this.create = true;
  //   this.editclicked = true
  //   this.newAction = 'edit';

  //   this.CreateForm.patchValue({
  //     sendoutlab: object.rowData.IsSendoutLab,
  //     external: object.rowData.IsExternalDb,
  //     sourcelab: object.rowData.Sourcelab,
  //     destinationlab: object.rowData.Destinationlab,
  //     mappingserver: object.rowData.MappingServer,
  //     mappingdb: object.rowData.MappingDbName,
  //     destlaburl: object.rowData.LabBUrl
  //   })
  //   this.activityEdit = { sourcelab: object.rowData.Sourcelab, destinationlab: object.rowData.Destinationlab, isexternal: object.rowData.IsExternalDb, issendoutlab: object.rowData.IsSendoutLab, mappingserver: object.rowData.MappingServer, mappingdb: object.rowData.MappingDbName, LabBurl: object.rowData.LabBUrl }
  //   this.activityEdit.isexternal = (this.activityEdit.isexternal == true) ? 'Yes' : (this.activityEdit.isexternal == false) ? 'No' : this.activityEdit.isexternal;
  //   this.activityEdit.issendoutlab = (this.activityEdit.issendoutlab == true) ? 'Yes' : (this.activityEdit.issendoutlab == false) ? 'No' : this.activityEdit.issendoutlab;
  //   this.editObj = object.rowData;
  // }
  deleteL2lConfiguration(object: any) {
    let templateData = this.templateData.submenuData
    this.deleteObject = object.rowData;
    let newObj = {
      "action": "delete",
      "accountid": object.rowData.accountid,
      "AssociatedAccount": object.rowData.AssociatedAccount,
      "l2lconfigid": object.rowData.L2LConfigId,
      "sourcelab": object.rowData.Sourcelab,
      "organizationid": this.orgid,
      "Destinationlab": object.rowData.Destinationlab,
      "isexternal": object.rowData.IsExternalDb,
      "issendoutlab": object.rowData.IsSendoutLab,
      "LabBurl": object.rowData.LabBUrl,
      "mappingserver": object.rowData.MappingServer,
      "mappingdb": object.rowData.MappingDbName,
    }
    this._dailogService.openLabadminConfimationdialog(
      '',
      'Are you sure you want to delete this record?',
      'Delete',
      'Cancel'
    ).afterClosed().subscribe((result)=>{
      if (result) {
        this.ngxService.start();
        this.vitalHttpServices.manageL2lConfiguration(newObj, this.deploymentkey).subscribe(
          result => {
            this.ngxService.stop();
            if (result.content.Status != 'Failure') {
              newObj["L2LConfigId"] = object.rowData.L2LConfigId;
              newObj["Sourcelab"] = object.rowData.Sourcelab;
              this._snackbar.open('L2L Mapping Deleted Successfully', 'Close');
              this.refreshgrid();
              newObj.isexternal = (newObj.isexternal == true) ? 'Yes' : (newObj.isexternal == false) ? 'No' : newObj.isexternal;
              newObj.issendoutlab = (newObj.issendoutlab == true) ? 'Yes' : (newObj.issendoutlab == false) ? 'No' : newObj.issendoutlab;
              this.commonService.auditDetails('L2LConfigId', 'Sourcelab', [newObj], [{}], 'Delete', this.templateData, this.auditableColumns);
              this.create = false;
              this.addDataToGrid(templateData);
            }
          },
          error => {
            this.ngxService.stop();
            console.error(error);
          }
        );
      }
    });
  }

  //to get the notemessage
  dropdownValuesForSourceLab() {
    let query = this.subMenuCardModel.GetQuery('getorglabaccounts');
    let queryVariable = { orgid: this.orgid, associationtype: "Laboratories", status: 'active' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).toPromise().then(
      data => {
        if (!data.errors) {
          this.ngxService.stop();
          if (data?.data?.submenuData) {
            this.sourcelabdropdownvalues = data?.data?.submenuData.map(obj => obj.displayname);
            this.sourceLabData = data.data.submenuData;
          }
        }
        if (!this.sourcelabdropdownvalues.includes(sessionStorage.getItem('locationName'))) {
          this.noteMessage = 'The Source Lab is inactive or not a Laboratory. Please select another';
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );
  }


  // isChangeDropdown() {
  //   let sourcelab = this.CreateForm.controls.sourcelab.value;
  //   let obj1 = this.sourceLabData.find(i => i.displayname == sourcelab);
  //   if (obj1) {
  //     this.sourceLabId = obj1.accountid;
  //   }
  //   if (this.sourcelabdropdownvalues.includes(sourcelab)) {
  //     this.CreateForm.patchValue({ destinationlab: '' });
  //     let query = this.subMenuCardModel.GetQuery('getAssociatedSendout');
  //     let queryVariable = { accid: this.sourceLabId.toString(), status: 'active' };
  //     let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //     this.ngxService.start();
  //     this.vitalHttpServices.GetData(queryResult).subscribe(
  //       data => {
  //         if (!data.errors) {
  //           this.ngxService.stop();
  //           if (data?.data?.submenuData) {
  //             this.destLabData = data.data.submenuData;
  //             // let obj2=this.destLabData.find(i => i.AccountName== this.CreateForm.controls.destinationlab.value);
  //             // this.labbguid=obj2.LabBGUID;
  //             this.destdropdownvalues = Array.from(new Set(data.data.submenuData.map(obj => obj.displayname)));
  //           }
  //         }
  //       },
  //       error => {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }
  // }
  // dropdownValues($event: any, controlName: any) {
  //   this.CreateForm.patchValue({ [controlName]: $event });
  //   if (controlName == 'sourcelab' && this.sourcelabdropdownvalues.includes(this.CreateForm.controls.sourcelab.value)) {
  //     this.message = false;
  //   }
  //   // if(this.CreateForm.controls.sourcelab.value == ''){
  //   //   this.CreateForm.patchValue({ destinationlab: '' });
  //   //   this.destdropdownvalues = []; 
  //   // }
  //   // if (controlName == 'sourcelab' && this.sourcelabdropdownvalues.includes($event) && this.soucelabvalue != $event ) {
  //   //   this.CreateForm.patchValue({ destinationlab: '' });
  //   //   this.destdropdownvalues = [];
  //   // }
  //   if (
  //     controlName === 'sourcelab' &&
  //     (this.CreateForm.controls.sourcelab.value === '' ||
  //       (this.sourcelabdropdownvalues.includes($event) && this.soucelabvalue !== $event))
  //   ) {
  //     this.CreateForm.patchValue({ destinationlab: '' });
  //     this.destdropdownvalues = [];
  //   }
  //   if (controlName == 'sourcelab') {
  //     this.soucelabvalue = $event;
  //   }
  //   if (this.soucelabvalue == $event && this.sourcelabdropdownvalues.includes(this.CreateForm.controls.sourcelab.value)) {
  //     if (this.sourcelabdropdownvalues && controlName == 'sourcelab' && this.CreateForm.controls.destinationlab.value == '' && this.destdropdownvalues.length == 0) {
  //       this.isChangeDropdown();
  //     }
  //   }
  // }
  recieveObjFromListing(object: any) {
    let templateData = this.templateData.submenuData
    if(object.newData.LabBurl && !this.isValidURL(object?.newData.LabBurl)) {
      this._snackbar.open('Invalid Destination Lab Url', 'Close');
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      return;
    }
    if (object.newData.Action == 'Edit') {
      this.activityEntity.entityId =object.newData.L2LConfigId;
      this.activityService.setActivitySession(this.activityEntity);
      this.ngxService.start();
      delete object.newData.LabAaccountid;
      delete object.newData.LabBaccountid;
      this.vitalHttpServices.manageL2lConfiguration(object.newData, this.deploymentkey).subscribe(
        result => {
          if (result.content.Status != 'Failure') {
            this.ngxService.stop();
            this.getdata();
            this._snackbar.open('L2L Mapping Updated successfully', 'Close');
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            function toLowerCaseKeys(obj: any) {
              return Object.keys(obj).reduce((acc, key) => {
                acc[key.toLowerCase()] = obj[key];
                return acc;
              }, {});
            }

            object.newData.isexternal = (object.newData.isexternal == 1) ? 'Yes' : (object.newData.isexternal == 0) ? 'No' : object.newData.isexternal;
            object.newData.issendoutlab = (object.newData.issendoutlab == 1) ? 'Yes' : (object.newData.issendoutlab == 0) ? 'No' : object.newData.issendoutlab;
            object.oldData.IsExternalDb = (object.oldData.IsExternalDb == true) ? 'Yes' : (object.oldData.IsExternalDb == false) ? 'No' : object.oldData.IsExternalDb;
            object.oldData.IsSendoutLab = (object.oldData.IsSendoutLab == true) ? 'Yes' : (object.oldData.IsSendoutLab == false) ? 'No' : object.oldData.IsSendoutLab;
            let isexternal = "isexternal";
            let value1 = object.oldData.IsExternalDb;
            object.oldData[isexternal] = value1;
            let mappingdb = "mappingdb";
            let value2 = object.oldData.MappingDbName;         
            object.oldData[mappingdb] = value2;
            let newobj = toLowerCaseKeys(object.newData);
            let oldobj = toLowerCaseKeys(object.oldData);
            let entitykey=  result.content.L2LConfigId;
            let transactionid=this.commonService.generateGuid();
             this.commonService.createActivityObject(entitykey,result.content.Sourcelab,'L2L Mapping','Edit',newobj,oldobj,transactionid,this.auditableColumns);
             this.addDataToGrid(templateData);
          }
        },
        error => {
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          console.error(error);
        }
      );
    }
    else {
      for (const key in object.newData) {
        if (
          object.newData.hasOwnProperty(key) &&
          (object.newData[key] === '' || object.newData[key] === undefined || object.newData[key] === null)
        ) {
          object.newData[key] = null;
        }
      }
      object.newData.issendoutlab = (object.newData.issendoutlab == 1) ? true : (object.newData.issendoutlab == 0) ? false : object.newData.issendoutlab;
      object.newData.isexternal = (object.newData.isexternal == 1) ? true : (object.newData.isexternal == 0) ? false : object.newData.isexternal;
      this.ngxService.start();
      this.vitalHttpServices.manageL2lConfiguration(object.newData, this.deploymentkey).subscribe(
        result => {
          if (result.content.Message == 'DestinationLab guid is null' || result.content.Message == 'SourceLab guid is null') {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this.ngxService.stop();
            this._snackbar.open(result.content.Message, 'Close');
            return;
          }
          if (result.content.Status != 'Failure') {
            this.ngxService.stop();
            if (result.content.Message == 'Configuration already exists') {
              this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
              this._snackbar.open(result.content.Message, 'Close');
              return;
            }
            this._snackbar.open('L2L Mapping Created Successfully', 'Close');
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            let activityTrackerObj = [];
            let Sourcelab = "Sourcelab";
            let value1 = result.content.Sourcelab;        
            let Destinationlab = "Destinationlab";
            let value2 =result.content.Destinationlab;
            object.newData[Sourcelab] = value1;
            object.newData[Destinationlab] = value2;
            activityTrackerObj = object.newData;
            activityTrackerObj['L2LConfigId'] = result.content.L2LConfigId;
            activityTrackerObj['isexternal'] = (activityTrackerObj['isexternal'] == true) ? 'Yes' : (activityTrackerObj['isexternal'] == false) ? 'No' : activityTrackerObj['isexternal'];
            activityTrackerObj['issendoutlab'] = (activityTrackerObj['issendoutlab'] == true) ? 'Yes' : (activityTrackerObj['issendoutlab'] == false) ? 'No' : activityTrackerObj['issendoutlab'];
            let entitykey=  result.content.L2LConfigId;
           let transactionid=this.commonService.generateGuid();
            this.commonService.createActivityObject(entitykey,result.content.Sourcelab,'L2L Mapping','Create',activityTrackerObj,activityTrackerObj,transactionid,this.auditableColumns);
            this.create = false;
            this.addDataToGrid(templateData);
            this.refreshgrid();
          }
        },
        error => {
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          console.error(error);
        }
      );
    }
  }
  // saveL2LConfig(action: any, editForm?) {
  //   let templateData = this.templateData.submenuData
  //   if (action == 'edit') {
  //     let tempobj = [];
  //     var newObj1 = {
  //       "LabAguid": this.editObj.Sourceguid,
  //       "LabBguid": this.editObj.Destguid,
  //       "mappingserver": this.CreateForm.controls.mappingserver.value,
  //       "mappingdb": this.CreateForm.controls.mappingdb.value,
  //       "LabBurl": this.CreateForm.controls.destlaburl.value,
  //       "isexternal": this.CreateForm.controls.external.value,
  //       "issendoutlab": this.CreateForm.controls.sendoutlab.value,
  //       "LabAaccountid": this.editObj.LabAaccid,
  //       "LabBaccountid": this.editObj.LabBaccid,
  //       "l2lconfigid": this.editObj.L2LConfigId,
  //       "action": "edit",
  //       "Sourcelab": this.CreateForm.controls.sourcelab.value,
  //       "Destinationlab": this.CreateForm.controls.destinationlab.value
  //     }

  //     tempobj.push(newObj1);
  //     this.ngxService.start();
  //     this.vitalHttpServices.manageL2lConfiguration(tempobj[0], this.deploymentkey).subscribe(
  //       result => {
  //         if (result.content.Status != 'Failure') {
  //           this.ngxService.stop();
  //           this._snackbar.open('Updated successfully', 'Close');
  //           tempobj[0].isexternal = (tempobj[0].isexternal == true) ? 'Yes' : (tempobj[0].isexternal == false) ? 'No' : tempobj[0].isexternal;
  //           tempobj[0].issendoutlab = (tempobj[0].issendoutlab == true) ? 'Yes' : (tempobj[0].issendoutlab == false) ? 'No' : tempobj[0].issendoutlab;
  //           this.commonService.auditDetails('l2lconfigid', 'sourcelab', [this.activityEdit], [tempobj[0]], "Edit", this.templateData, this.auditableColumns);
  //           this.CreateForm.reset();
  //           this.create = false;
  //           this.addDataToGrid(templateData);
  //           this.refreshgrid();
  //         }
  //       },
  //       error => {
  //         this.ngxService.stop();
  //         console.error(error);
  //       }
  //     );
  //   }
  //   else {
  //     this.savedata = this.CreateForm.controls
  //     if ((!this.savedata.sourcelab.value || this.savedata.sourcelab.value.toString().trim() == '') || (!this.savedata.destinationlab.value || this.savedata.destinationlab.value.toString().trim() == '')) {
  //       this.CreateForm.controls.sourcelab.markAsDirty();
  //       this.CreateForm.controls.sourcelab.updateValueAndValidity();
  //       this.CreateForm.controls.destinationlab.markAsDirty();
  //       this.CreateForm.controls.destinationlab.updateValueAndValidity();
  //       this._snackbar.open('Please select required fields', 'Close')
  //       return;
  //     }
  //     if (!this.sourcelabdropdownvalues.includes(this.CreateForm.controls.sourcelab.value) || !this.destdropdownvalues.includes(this.CreateForm.controls.destinationlab.value)) {
  //       this._snackbar.open('Please Enter valid data', 'Close');
  //       return;
  //     }
  //     let destlab = this.CreateForm.controls.destinationlab.value;
  //     let objnew = this.destLabData.find(i => i.displayname == destlab);
  //     if (objnew) {
  //       this.destLabId = objnew.AssociatedAccount;
  //     }

  //     let obj1 = this.sourceLabData.find(i => i.displayname == this.CreateForm.controls.sourcelab.value);
  //     if (obj1) {
  //       this.labaguid = obj1.externalguid;
  //     }

  //     let obj2 = this.destLabData.find(i => i.displayname == this.CreateForm.controls.destinationlab.value);
  //     if (obj2) {
  //       this.labbguid = obj2.externalguid;
  //     }
  //     var saveobj = {
  //       "LabAguid": this.labaguid,
  //       "LabBguid": this.labbguid,
  //       "mappingserver": this.savedata.mappingserver.value,
  //       "mappingdb": this.savedata.mappingdb.value,
  //       "LabBurl": this.savedata.destlaburl.value,
  //       "isexternal": this.savedata.external.value,
  //       "issendoutlab": this.savedata.sendoutlab.value,
  //       "accountid": this.accountid,
  //       "organizationid": this.orgid,
  //       "action": 'create',
  //       "LabAaccountid": this.sourceLabId,
  //       "LabBaccountid": this.destLabId,
  //       "l2lconfigid": 0,
  //       "Sourcelab": this.CreateForm.controls.sourcelab.value,
  //       "Destinationlab": this.CreateForm.controls.destinationlab.value
  //     }
  //     let tempobj: any = [];
  //     tempobj.push(saveobj);
  //     this.ngxService.start();
  //     this.vitalHttpServices.manageL2lConfiguration(tempobj[0], this.deploymentkey).subscribe(
  //       result => {
  //         if (result.content.Status != 'Failure') {
  //           this.ngxService.stop();
  //           if (result.content.Message == 'Configuration already exists') {
  //             this._snackbar.open('Configuration already exists', 'Close');
  //             return;
  //           }
  //           let activityTrackerObj = []
  //           activityTrackerObj = tempobj;
  //           this._snackbar.open('Created successfully', 'Close');
  //           activityTrackerObj[0].isexternal = (activityTrackerObj[0].isexternal == true) ? 'Yes' : (activityTrackerObj[0].isexternal == false) ? 'No' : activityTrackerObj[0].isexternal;
  //           activityTrackerObj[0].issendoutlab = (activityTrackerObj[0].issendoutlab == true) ? 'Yes' : (activityTrackerObj[0].issendoutlab == false) ? 'No' : activityTrackerObj[0].issendoutlab;
  //           activityTrackerObj[0]['l2lconfigid'] = result.content.L2LConfigId;
  //           activityTrackerObj[0]['Sourcelab'] = tempobj[0].Sourcelab;
  //           this.commonService.auditDetails('l2lconfigid', 'Sourcelab', activityTrackerObj, activityTrackerObj, "Create", this.templateData, this.auditableColumns);
  //           this.CreateForm.reset();
  //           this.create = false;
  //           this.addDataToGrid(templateData);
  //           this.refreshgrid();
  //         }
  //       },
  //       error => {
  //         this.ngxService.stop();
  //         console.error(error);
  //       }
  //     );
  //   }
  // }
  refreshgrid() {
    this.create = false;
    this.gridShow = true;
    this.getdata();
    this.activityEntity.entityId='';
    this.activityService.setActivitySession(this.activityEntity);
  }
  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  isValidURL(string) {
    const regex = /^(https?:\/\/)(www\.)?[\w-]+(\.[\w-]+)+$/i;
    return regex.test(string);
  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData?.headerText)['SubMenu'].find(ele => ele?.URL == this.templateData?.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions?.length; i++) {
      switch (seletedMenuPermissions[i]?.Button) {
        case "Create":
          this.createBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
          case "Delete":
            this.deleteBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
            break;
      }
    }
  }
}

