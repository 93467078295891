import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VDLocation } from 'src/app/model/vd-template.model';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { ExcelService } from '../../../core/services/excel.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { error } from 'console';

@Component({
  selector: 'app-statistic-master-data',
  templateUrl: './statistic-master-data.component.html',
  styleUrls: ['./statistic-master-data.component.scss']
})

export class StatisticMasterDataComponent {
  SubMenuCardModel: SubMenuCardModel;
  @Input() templateData: any;
  filteredLabList: Observable<any[]>;
  readonly labList: any[] = []
  reportList: any[] = [];
  gridShow: boolean = true;
  reportGridHeader: any = [{ DisplayName: " ", colWidth: "col-sm-1", Name: "" }, { DisplayName: "Title", colWidth: "col-sm-6", Name: "Title" }, { DisplayName: "Status", colWidth: "col-sm-2", Name: "Status" }, { DisplayName: "Download", colWidth: "col-sm-3", Name: "TargetFileName" }];
  detailedReportGridHeader: any = [{ DisplayName: "Requested", colWidth: "col-sm-2", Name: "RunDateAndTime" }, { DisplayName: "Status", colWidth: "col-sm-1", Name: "Status" }, { DisplayName: "Generated", colWidth: "col-sm-2", Name: "EndTime" }, { DisplayName: "Criteria", colWidth: "col-sm-7", Name: "ReportFilterCriteria" }];
  detailedReportGridData: any = [{ Title: "Report For Export Casetype", Status: "New", Download: "", GroupUniqueID: "", SubReports: [] }];
  sourceApp: any = '';
  isMaximized: boolean = true;
  caseTypesList: any = [];
  filteredCaseTypes: Observable<any[]>;
  searchResult: any[];
  caseTypeCount: string = '';
  isLab: boolean = false;
  isOFInfo: boolean = false;
  isCaseType: boolean = false;
  destDeploymentKey: string;
  private intervalId: any;
  userId: string;
  FilesNotFound: boolean = false;
  isDropdownVisible = false; // Controls dropdown visibility
  isAllCasetypes = false; // Controls 'Select All' checkbox
  userSessionDetails: any;
  activityEntity: {};
  generateReportBtn: boolean = false;
  isReportLoadingBtn: boolean = false;
  downloadBtn: boolean = false;
  accountIds: any;
  VitalDxLoader: boolean = false;

  constructor(
    public ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: FormBuilder,
    public VitalHttpServices: VitalHttpServices,
    private _snackBar: MatSnackBar,
    public commonService: CommonService,
    public excelService: ExcelService,
    public templateHeaders: TemplateHeadersService,
    public activityService: ActivityTrackerService,
    private labdaminService: LabadminService,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
  }

  masterDataFormGroup = this._fb.group({
    frmLaboratory: ["", Validators.required],
    frmIsOF: false,
    frmIsLocation: false,
    frmIsCaseType: false,
    frmIsAllCaseType: false,
    frmLocationId: [""]
  })

  async ngOnInit() {
    let labName;
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      this.sourceApp = 'VitalDx';
      await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async (session: any) => {
        await this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
          let menuObj = result[result.length - 1];
          if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
            this.userSessionDetails = session.userDetails
            await this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails);
            this.templateData = this.labdaminService.templateData;
            this.accountIds = this.labdaminService.associatedAccountIds.toString();
            this.userId = session["userDetails"].userId;
            this.templateData['cardIdentifier'] = this.labdaminService.accountId;
            this.templateData['secondarykeys']['OrganizationId'] = this.labdaminService.organizationId;
            this.labList.push(...session["userDetails"].userAccountIDs);

            this.destDeploymentKey = this.labdaminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', this.destDeploymentKey);
            await this.configureReports();
            await this.callAPIs();
            let labObj = this.labList.find(x => x.accountId == this.templateData.cardIdentifier)
            await this.getMasterDataToExport(false, this.templateData.cardIdentifier.toString(), labObj.accountName);
            this.GetButtondetails();
            if(this.labList && this.labList?.length == 1)
            {
              this.masterDataFormGroup.patchValue({
                frmLaboratory: this.labList[0].accountName,
                frmLocationId: this.labList[0].accountId
              })
            }
            /*Refresh the Report data after 30000 seconds */
            this.intervalId = setInterval(() => {
              this.getMasterDataToExport(false, this.templateData.cardIdentifier.toString(), labObj.accountName);
            }, 30000);
          }
        })
      })
    } else {
      this.destDeploymentKey = (sessionStorage.getItem('deploymentKey') == null) ? '' : sessionStorage.getItem('deploymentKey').toString();
      this.userId = (sessionStorage.getItem('Userid') == null) ? '-100' : sessionStorage.getItem('Userid').toString();
      this.accountIds = this.templateData.cardIdentifier.toString();
      labName = (sessionStorage.getItem('locationName') == null) ? '' : sessionStorage.getItem('locationName').toString();
      await this.configureReports();
      await this.getMasterDataToExport(false, this.templateData.cardIdentifier.toString(), labName);
      this.GetButtondetails();
      await this.callAPIs();
      this.labList.push({
        "accountId": Number(this.templateData.cardIdentifier),
        "accountName": labName,
        "accountGUID": ""
      });
      if(this.labList && this.labList.length > 0)
        {
          this.masterDataFormGroup.patchValue({
            frmLaboratory: this.labList[0].accountName,
            frmLocationId: this.labList[0].accountId
          })
        }
      /*Refresh the Report data after 30000 seconds */
      this.intervalId = setInterval(() => {
        this.getMasterDataToExport(false, this.templateData.cardIdentifier.toString(), labName);
      }, 30000);
    }

    this.filteredLabList = this.masterDataFormGroup.controls.frmLaboratory.valueChanges
      .pipe(
        startWith<any>(new VDLocation()),
        map(val => this.commonService.filterData(val, this.labList, 'accountName', 'accountId'))
      );
  }

  async refreshMasterReport() {
    if (this.detailedReportGridData[0]['SubReports'] != null && this.detailedReportGridData[0]['SubReports'].some(report => report.Status.toLowerCase() == 'new' || report.Status.toLowerCase() == 'processing')) {
      let groupUniqueID = this.detailedReportGridData[0]['GroupUniqueID'];
      if (groupUniqueID != null && groupUniqueID != '') {
        await this.VitalHttpServices.refreshMasterDataToExport(this.userId, groupUniqueID, this.destDeploymentKey).toPromise().then(result => {
          if (result && result.content) {
            this.detailedReportGridData[0]['SubReports'] = result.content;
            if (this.detailedReportGridData[0]['SubReports'].length > 0) {
              this.bindMasterDataToExport(false);
            }
          }
        })
      }
    }
  }

  hideCaseTypeDD() {
    this.isDropdownVisible = false;
    this.filterCasetype('');
  }

  async callAPIs() {
    try {
      await this.VitalHttpServices.getCasetypes(this.templateData.secondarykeys.OrganizationId.toString(), this.destDeploymentKey).toPromise().then(result => {
        if (result && result.content) {
          this.caseTypesList = (result.content.length > 0) ? result.content.filter(x => x.Category?.toString().toLowerCase() != 'hemonc').map(x => ({ ...x, Selected: false })) : [];
          this.searchResult = this.caseTypesList;
        }
      })
    } catch (e) {
      console.error(e);
      this._snackBar.open("Error occurred while processing!", "Close");
    }
  }

  filterCasetype(value: string) {
    this.searchResult = []
    if (!value) {
      return (this.searchResult = this.caseTypesList);
    }

    if (value) {
      this.searchResult = this.caseTypesList.filter(function (series) {
        if (series && series.DisplayName != null) {
          return series.DisplayName.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }

  async getMasterDataToExport(isGenerate: boolean, labAccountId: string, labName: string, caseTypes?: any, criteria?:any) {
    let menuText = '';
    this.VitalDxLoader = isGenerate;
    await this.VitalHttpServices.getMasterDataToExport(isGenerate ? 'GenerateReport':'getMasterDataToExport', this.templateData['secondarykeys'].OrganizationId,
      'Lab Administrator', 'CaseTypeExport', this.userId, labAccountId, labName, menuText, caseTypes,criteria, this.destDeploymentKey).toPromise().then(x => {
        this.VitalDxLoader = false;
        if (x.content) {
          let resultData = JSON.parse(x.content);
          if (resultData.ReportList.length > 0) {
            this.reportList = resultData.ReportList;
            this.isLab = this.reportList.some(x => x.ReportName.toString().toLowerCase() == 'labmasterdatareport') ? true : false;
            this.isOFInfo = this.reportList.some(x => x.ReportName.toString().toLowerCase() == 'ofmasterdatareport') ? true : false;
            this.isCaseType = this.reportList.some(x => x.ReportName.toString().toLowerCase() == 'casetypemasterdatareport') ? true : false;
          }
          if (resultData.ReportDetails) {
            if(resultData.ReportDetails.length > 0)
            {
              this.detailedReportGridData[0]['SubReports'] = resultData.ReportDetails;
              this.bindMasterDataToExport(isGenerate);
            }
          }
        } else {
          this._snackBar.open("An error occurred while processing the request", "Close");
          return
        }
      }, error => {
        this.VitalDxLoader = false;
        this._snackBar.open("An error occurred while processing the request", "Close");
        console.error(error);
      })
  }

  bindMasterDataToExport(isGenerate: boolean) {
    this.isReportLoadingBtn = !isGenerate ? false : true; //Enable Generate button by setting it 'true'

    this.detailedReportGridData[0]['GroupUniqueID'] = this.detailedReportGridData[0]['SubReports'][0].GroupUniqueID;

    if (this.detailedReportGridData != null && this.detailedReportGridData.length > 0 && this.detailedReportGridData[0].SubReports?.length > 0) {
      if (this.detailedReportGridData[0].SubReports.every(report => report.Status.toLowerCase() == 'success') && this.detailedReportGridData[0].SubReports.every(report => report.TargetFileName != '')) {
        this.detailedReportGridData[0].Status = 'Success';
        this.FilesNotFound = false;
        this.isReportLoadingBtn = false;
      }
      else {
        if (this.detailedReportGridData[0].SubReports.every(report => report.Status.toLowerCase() == 'failed')) {
          this.detailedReportGridData[0].Status = 'Failed';
          this.isReportLoadingBtn = false;
        }
        else if (this.detailedReportGridData[0].SubReports.every(report => report.Status.toLowerCase() == 'success') && this.detailedReportGridData[0].SubReports.every(report => report.TargetFileName == '')) {
          this.detailedReportGridData[0].Status = 'Success';
          this.FilesNotFound = true;
          this.isReportLoadingBtn = false;
        }
        else if (this.detailedReportGridData[0].SubReports.some(report => report.Status.toLowerCase() == 'success') && this.detailedReportGridData[0].SubReports.every(report => report.TargetFileName == '')) {
          this.detailedReportGridData[0].Status = 'Partial';
          this.FilesNotFound = true;
          this.isReportLoadingBtn = false;
        }
        else if (this.detailedReportGridData[0].SubReports.some(report => report.Status.toLowerCase() == 'success') && this.detailedReportGridData[0].SubReports.some(report => report.TargetFileName != '')) {
          this.detailedReportGridData[0].Status = 'Partial';
          this.FilesNotFound = false;
          this.isReportLoadingBtn = false;
        }
        else if (this.detailedReportGridData[0].SubReports.every(report => report.Status.toLowerCase() == 'new')) {
          this.detailedReportGridData[0].Status = 'New';
          this.FilesNotFound = true;
          this.isReportLoadingBtn = true;
        }
        else if (this.detailedReportGridData[0].SubReports.some(report => report.Status.toLowerCase() == 'new')) {
          this.detailedReportGridData[0].Status = 'Processing';
          this.FilesNotFound = true;
          this.isReportLoadingBtn = false;
        }
        else if (this.detailedReportGridData[0].SubReports.every(report => report.Status.toLowerCase() == 'processing')) {
          this.detailedReportGridData[0].Status = 'Processing';
          this.FilesNotFound = true;
          this.isReportLoadingBtn = true;
        }
        else if (this.detailedReportGridData[0].SubReports.some(report => report.Status.toLowerCase() == 'failed') && this.detailedReportGridData[0].SubReports.some(report => report.Status.toLowerCase() == 'processing') && this.detailedReportGridData[0].SubReports.some(report => report.Status.toLowerCase() != 'success')) {
          this.detailedReportGridData[0].Status = 'Processing';
          this.FilesNotFound = true;
          this.isReportLoadingBtn = false;
        }
      }
    }
  }

  //#region to display value in dropdown
  displayFn(selectedoption) {
    return selectedoption ? (selectedoption.DisplayName) : undefined;
  }
  //#endregion

  generateReport(formValue) {
    let NewReportGenerated = true;
    this.FilesNotFound = false;
    let OFcheck = false;
    let Accountcheck = false;
    let CasetypeCheck = false;
    if(!this.isLab && !this.isOFInfo && !this.isCaseType)
    {
      this._snackBar.open("No reports configured", "Close");
      return
    }
    if (this.isLab) {
      Accountcheck = formValue.frmIsLocation;
    }
    if (this.isOFInfo) {
      OFcheck = formValue.frmIsOF;
    }
    if (this.isCaseType) {
      CasetypeCheck = formValue.frmIsCaseType;
    }

    if (this.isReportLoadingBtn) {
      this._snackBar.open("You cannot initiate untill current reports are processed", "Close");
      NewReportGenerated = false;
    }
    let caseTypes = this.caseTypesList.filter(x => x.Selected).map(x => ({ Casetype: x.CaseType, Siteformat: x.SiteFormat }));
    if (!CasetypeCheck) {
      CasetypeCheck = (caseTypes.length > 0) ? true : false;
    }
    if (!CasetypeCheck && !Accountcheck && !OFcheck) {
      this._snackBar.open('Atleast one filter must be selected', 'Close');
      NewReportGenerated = false;
    }
    if (!formValue.frmLocationId) {
      this._snackBar.open('Please select laboratory', 'Close');
      NewReportGenerated = false;
    }
    if ((CasetypeCheck || Accountcheck || OFcheck) && NewReportGenerated) {
      let obj = {isLab : Accountcheck, isOF: OFcheck}
      this.getMasterDataToExport(true, formValue.frmLocationId.toString(), formValue.frmLaboratory, caseTypes,obj);
    }
  }

  clearInput(e) {
    this.masterDataFormGroup.patchValue({
      frmLaboratory: '',
      frmLocationId: ''
    })
  }

  onLabChange(event, item) {
    if(event.source.selected)
    {
      this.masterDataFormGroup.patchValue({
        frmLaboratory: item.accountName,
        frmLocationId: item.accountId
      })
    }
  }

  displayedColumns(item: any, header: any) {
    // only datetime column are considering as time zone and other columns are not considered
    let timezonecolumns = ['rundateandtime', 'endtime'];
    let dateValue = new Date(item[header.Name]);
    // if it is a valid format then only convert to local time zone else empty string
      if(timezonecolumns.includes(header.Name.toLowerCase())){
        if(!isNaN(dateValue.getTime())){
          const formattedDate = dateValue.toLocaleString();
          return formattedDate;
        }
        else{
          return ''
        }
      }
    return item[header.Name]
  }

  // Toggle dropdown visibility
  toggleDropdown(isCaseType?: boolean) {
    this.isDropdownVisible = !this.isDropdownVisible;
    if(isCaseType)
    {
      (this.masterDataFormGroup.value.frmIsCaseType) ? this.selectAllCasetypes(true, false) : this.selectAllCasetypes(false, false);
    }
  }

  // Handle individual case type selection logic
  onCaseTypeChange(event, item) {
    if (event.source) {
      this.caseTypesList = this.caseTypesList.map(x => ({ ...x, Selected: (item.CaseType == x.CaseType) ? event.checked : x.Selected }));
      this.searchResult = this.searchResult.map(x => ({ ...x, Selected: (item.CaseType == x.CaseType) ? event.checked : x.Selected }));
      let isAllCasetypes = this.caseTypesList.every(caseType => caseType.Selected);
      this.masterDataFormGroup.patchValue({
        frmIsCaseType: isAllCasetypes,
        frmIsAllCaseType: isAllCasetypes
      })
      this.caseTypeCount = isAllCasetypes ? 'ALL' : (this.caseTypesList.filter(x => x.Selected).length).toString()
    }
  }

  selectAllCasetypes(isChecked: boolean, controls?: boolean) {
    let isAllCasetypes = isChecked;
    this.caseTypesList = this.caseTypesList.map(caseType => ({ ...caseType, Selected: isChecked }));
    this.searchResult = this.searchResult.map(caseType => ({ ...caseType, Selected: isChecked }));
    if (!controls) {
      this.masterDataFormGroup.patchValue({
        frmIsCaseType: isAllCasetypes,
        frmIsAllCaseType: isAllCasetypes
      })
    }
    this.caseTypeCount = isAllCasetypes ? 'ALL' : '';
  }

  downloadZipFile(GroupUniqueID: string) {
    this.ngxService.start('download-zip');
    const groupUniqueId = GroupUniqueID; // Set this as needed
    this.VitalHttpServices.downloadReport(groupUniqueId, this.destDeploymentKey).subscribe((blob) => {
      this.ngxService.stop('download-zip');
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'DownloadedFiles.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, (error) => {
      this.ngxService.stop('download-zip');
      console.error(error);
    });
  }

  private GetButtondetails() {
    if (this.sourceApp == 'VitalDx') {
      this.GetButtonAccess(this.labdaminService.subMenuPermissions);
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
  }

  private GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Master Data")['ActionButton'];
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Generate Report":
          this.generateReportBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
        case "Download Report":
          this.downloadBtn = seletedMenuPermissions[i].IsPermitted === "true";
          break;
      }
    }
  }

  clickLaboratory()
  {
    if(!this.masterDataFormGroup.value.frmLaboratory) {
      this.masterDataFormGroup.patchValue({
        frmLaboratory:""
      })
      this.masterDataFormGroup.controls.frmLaboratory.setValidators([Validators.required]);
      this.masterDataFormGroup.controls.frmLaboratory.updateValueAndValidity();
    }
  }

  async configureReports()
  {
    await this.VitalHttpServices.configureCustomReports(this.templateData.secondarykeys.OrganizationId.toString(), this.userId, this.destDeploymentKey).toPromise().then(result =>
    {
      return
    }, error => {
      console.error(error)
    })
  }

  ngOnDestroy() {
    // Clear the interval to prevent memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
