<div *ngIf="templateData && templateData.labels && gridwidth > 0">
  <div *ngIf="UserList.length>0" class="mb-2">
    <strong class="mr-2">User Case Comments:</strong>
    <a href="javascript:;" (click)="AddTab(item,'user')"
      style="margin-right: 1%;color: blue;text-decoration: underline;"
      *ngFor="let item of UserList">{{item.Username}},</a>
  </div>
  <strong *ngIf="UserList.length>0" style="display: block;">Group Case Comments:</strong>
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
          <strong>{{templateData.menuURL}} List</strong>
        </h3>
        <div *ngIf="commonService.showFlagIcon" class="ml-2">
          <span class="cpy-drg-Icon-globe" (click)="showMenuRelatedFlags()">
              <img src="/assets/images/FlagIconOnHover.svg" matTooltip="Flags" class="globe_white" />
          </span>
        </div>
      </div>
  <div>
    <wj-flex-grid [isReadOnly]="true" (click)="onClick(grid, $event)" [itemsSource]="gridData"
      class="mt-2" #grid [headersVisibility]="'Column'">
      <div *ngFor="let columnname of gridHeader">
        <wj-flex-grid-column [binding]="columnname" [allowDragging]="false" [header]="columnname.split('_').join(' ')"
          [width]="170" [visible]="!(columnname == 'AttributeId') && !(columnname == 'CreatedBy') && !(columnname == 'CreatedDate') && !(columnname == 'ModifiedBy') && !(columnname == 'ModifiedDate')  && !(columnname == 'ruleid')" [allowResizing]="true" [format]="'d'"></wj-flex-grid-column>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
  </div>

  <span class="row col-sm-12 align-center mt-2">
    <wj-collection-view-navigator class="align-center" [byPage]="true" [cv]="gridData">
    </wj-collection-view-navigator>
  </span>


  <div class="col-sm-12 form-inline well well-lg align-center mt-2">
    <button (click)="ExportExcel(grid)" mat-raised-button class="admin-btn-success"
      [disabled]="hideExportBtn ">Export</button>
  </div>
</div>
<div class="modal-header admin-model-header mt-3" *ngIf="gridwidth == 0">
    <h3 class="modal-title w-100 admin-model-header-txt">
      <strong>{{templateData.menuURL}} List</strong>
    </h3>
    <div *ngIf="commonService.showFlagIcon" class="ml-2">
      <span class="cpy-drg-Icon-globe" (click)="showMenuRelatedFlags()">
          <img src="/assets/images/FlagIconOnHover.svg" matTooltip="Flags" class="globe_white" />
      </span>
    </div>
  </div>
<div *ngIf="gridwidth == 0" class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found </div>
</div>
