import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from '../../labadmin.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { concatMap, groupBy, map, mergeMap, Observable, of, Subscription, tap, toArray, filter, reduce, take } from 'rxjs';
import * as models from '../../components/share/model/usermodels';
import { ExtAttributeConstants } from '../share/utility/ex_attribute _constants';
import { CommondetailsComponent } from '../commondetails/commondetails.component';
import { hideLoader, showLoader } from '../Utility Functions';
import { error } from 'console';

@Component({
  selector: 'app-infoassociation',
  templateUrl: './infoassociation.component.html',
  styleUrls: ['./infoassociation.component.scss'],
})
export class InfoassociationComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  workGroupList = [];
  @Input() userRoles = [];
  @Input() userDetails : any;
  @Input() fromLocation = ''
  @Input() locationDetails: any = [];
  @Input() isHideLocationGrid:boolean = false;
  // observables
  @Input() sharedSurgiCenter=null
  facilityLocations = [];
  labLocations = [];
  locationsList : any;
  stateList = [];
  subcription: Subscription;
  userSessionDetails: any;
  noDataFoundFlag: boolean = false;
  surgiCenterDetails: any = [];
  isProvidersMenu: boolean = false;
  casetypeList: any;
  caseTypeTitleArr: any;
  seUserList: any;
  courierUserList: any;
  casetypesCount: any;
  showToolTip: boolean = false;
  toolTipContent: any = [];
  LabOrganizations: any[];
  currentCategory: string;
  associateSurgicenterSavedData: any;
  associatePhysicianInitialData: any;
  associatePhysiciaSavedData: any;
  associateOFSavedData: any;
  showLocationLoader: boolean;
  locationKeys: any;
  loadData: boolean = true;
  pathologistRoleAssociationData: any
  PhysicianRoleAssociationData: any
  enableuserassociation: boolean = false
  locationText :string = 'Facility Location(s)'
  loader:number=0///if count more than 1 the its

  constructor(
    private vitalHttpServices: VitalHttpServices,
    private labAdminservice: LabadminService,
    private commonService: CommonService,
    private store: Store<{ breadcrum: any }>,
    private labAdminSessionService: LabadminSessionService,
    private commonDetails:CommondetailsComponent
  ) {
    store.select('breadcrum').subscribe((data) => {
      this.stateList = data;
      let currentMenu = data?.[data?.length - 1];
      this.isProvidersMenu = currentMenu['routerLink'] === '/labadmin/clients/orders';
      if(currentMenu['routerLink'] === '/labadmin/lab/users') 
        this.locationText = 'Lab Location(s)';
   
    });
  }
  accountIDs : string

   ngOnInit() {
    if (this.isProvidersMenu) {
      this.getAssociateSurgicenterData();
    }
    if (this.fromLocation == 'location') {
      let _this = this
      this.showLocationLoader=true;
      this.labAdminSessionService.getLabAdminSession.subscribe(async session => {
        this.showLocationLoader=false;
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
        }
      })
        this.getLabrotaryLocation()
    }
      else {
        this.accountIDs  = this.userDetails?.labLocationList.map((i)=>i.AccountId).join(',')
        if (
          this.stateList.length > 0 &&
          this.userDetails['DepartmentIds'] !== null &&
          this.userDetails['DepartmentId'] !== ''
        ) {
          if(this.userDetails.labLocationList?.length)
          {
            this.locationsList =  this.userDetails.labLocationList?.map((i) => {
              const roleName =
                 this.userDetails.ReadingLocationDetails.RoleName.toLowerCase();
              if (roleName === 'pathologist' || roleName === 'cytotechnologist' || roleName === 'cytotechnologist supervisor') {
                if (i.AccountId ===  this.userDetails.ReadingLocationDetails.AccountId) {
                  i.isReadingLocationEnabled = true;
                }
                return i;
              } else return i;
            }); //to bind ReadingLocation
            
            this.locationsList = this.userDetails['labLocationList']?.reduce((acc, obj) => {
              const key = obj.OrganizationName;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(obj);
              return acc;
            }, {});
          
            this.locationKeys = Object.keys(this.locationsList);
          }
          else{
            this.noDataFoundFlag= true
          }

          this.getworkGroup();
          this.getPathList();
        }

        if (this.userRoles?.length > 0) {
          let sales = this.userRoles.find((va) => va.Role === 'Sales Executive');
          if (sales !== undefined) {
            this.getAssociatedOfLocations(this.accountIDs,Number(sales.UserID))
          }
        }
        else
          this.noDataFoundFlag = true;
      }
  }
    getLabLocationList() {
      this.loader=showLoader(this.loader)
     this.labAdminservice.getLabLocations(0, this.userSessionDetails.userId, this.locationDetails.AccountID, this.labAdminservice.deploymentKey)
      .toPromise()
      .then( (data: any) => {
        let faclicatity = JSON.parse(JSON.stringify(data));
        if (data.length > 0) {

          faclicatity.forEach((element) => {
            element.sublocation = data.filter(va => va.OrganizationName === element.OrganizationName)
          })
        }
        const seen = new Set();
        faclicatity = faclicatity.filter(item => {
          const duplicate = seen.has(item.OrganizationName);
          seen.add(item.OrganizationName);
          return !duplicate;
        });
        this.LabOrganizations = faclicatity;
        this.loader=hideLoader(this.loader)
      })
      .catch(error => {
        // Handle error
        console.error('An error occurred:', error);
        this.loader=hideLoader(this.loader)
      });
  }

  getLabrotaryLocation() {
    this.showLocationLoader=true;
    this.loader=showLoader(this.loader)
   this.commonDetails.getLabrotaryLocationC()
      .subscribe({
        next: (data: any) => {
        this.showLocationLoader=false;
        this.LabOrganizations = data?.filter((i:any) => i?.labGroupData?.length>0);
        this.getFacilityCasetypes();
        this.loader=hideLoader(this.loader)
        },
        error: (err) => {
          console.error(err);
          this.showLocationLoader=false;
          this.loader=hideLoader(this.loader)
        },
      });
  }

  checkInfoAssociation() {
    if (this.userRoles?.length)
      return this.facilityLocations?.length || this.locationsList;
    return this.workGroupList?.length;

  }

  async getFacilityCasetypes() {
    let accountids = [];
    if (this.userSessionDetails?.userAccountIDs?.length > 0) {
      this.userSessionDetails.userAccountIDs.forEach((element) => {
        accountids.push(element.accountId);
      });
    }
    let ids = accountids.join(',');
    this.showLocationLoader=true;
    this.loader=showLoader(this.loader)
    await this.commonDetails.casetypeCategoryC(this.userSessionDetails.organizationId,ids ,this.labAdminservice.deploymentKey).toPromise().then(async value => {
      this.casetypeList = value.map(category => ({...category,Cases: category.Cases.filter(caseItem => caseItem.IsSelected)})).filter(category => category.Cases.length > 0);
      this.showLocationLoader=false;
      this.casetypesCount = this.casetypeList.flatMap(category => category.Cases).length;
      let arr = structuredClone(this.casetypeList);
      let resultObj: any = {};
      arr.forEach(item => {
        resultObj[item.Category] = item.Cases.map(caseItem => caseItem.CaseTypeDisplayname)
      });

      this.caseTypeTitleArr = resultObj;
      this.loader=hideLoader(this.loader)
      await this.getSalesExecutiveUsers(ids);
    })
      .catch(error =>
      {
        this.showLocationLoader=false
        console.error(error)
        this.loader=hideLoader(this.loader)
      }
      )
  }
  async getSalesExecutiveUsers(accountIds) {
    this.loader=showLoader(this.loader)
   await this.commonDetails.salesExecutiveAndCourierDataC(accountIds).toPromise().then(async result => {
      this.seUserList = result.OFUserList.filter(va => va.RoleName == 'Sales Executive')
      this.courierUserList = result.OFUserList.filter(va => va.RoleName == 'Courier')
      if (this.locationDetails.IsSharedSurgicenter) {
        this.getAssociatedSurgiCeneter(accountIds)
      }
      else {
        this.getSurgicenter(accountIds);
      
      }
      this.loader=hideLoader(this.loader)
    })
      .catch(error =>
        console.error(error),
        this.loader=hideLoader(this.loader)
      )
  }
  getSurgicenterPhy() {
    this.loader=showLoader(this.loader)
this.commonDetails.getphysicianDataC().subscribe({
        next: (data) => {
          this.associatePhysiciaSavedData = data.filter((i) => i.IsActive);
          this.loader=hideLoader(this.loader)
        },
        error: (err) =>( console.error(err),this.loader=hideLoader(this.loader))
      });
  }
  getAssociatedSurgiCeneter(accountIds) {
    this.loader=showLoader(this.loader)
      this.commonDetails.shredSurgicenterforOfC(accountIds).subscribe({
        next: (data) => {
          this.associateSurgicenterSavedData = data.filter(
            (i: any) => i.IsActive
          );
          this.loader=hideLoader(this.loader)
          this.getSurgicenterPhy();
        },
        error: (err) => {
          console.error(err);
          this.loader=hideLoader(this.loader)
        },
      });
  }
  getSurgicenter(accountIds) {
    this.loader=showLoader(this.loader)
      this.commonDetails.getsharedsurgicenter_C(accountIds).subscribe({
        next: (data) => {
          this.associateOFSavedData = data.filter((i: any) => i.IsActive);
          this.loader=hideLoader(this.loader)
        },
        error: (err) => {
          console.error(err);
          this.loader=hideLoader(this.loader)
        },
      });
  }
  // pathologist list in chip
  getPathList() {
    this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(
      (session) => {
        let ofAccount = '';
        if (this.userDetails && Array.isArray(this.userDetails['labLocationList'])) {
          ofAccount = this.userDetails['labLocationList']
            .filter(location => location && location.AccountId != null)
            .map(location => location.AccountId)
            .join(',');
        }
        const organizationId = this.userDetails['labLocationList'][0]?.OrganizationId;
        let transcriptionistData = this.userRoles.find((va) => va.Role === 'Pathologist Assistant');
        let medicalassistantData = this.userRoles.find((va) => va.Role === 'Medical Assistant');
        if(transcriptionistData || medicalassistantData ) this.EnableUserAssociationFlag()
        if(transcriptionistData)
        {
         this.getPathologistAssociationByTranscriptionist(organizationId,transcriptionistData.UserID,ofAccount,'','pathologist'
          )
        }
        if(medicalassistantData)
        {
          this.getphysicianAssociationByMedicalStaff(organizationId,medicalassistantData.UserID,ofAccount,'','Physician')
        }
      });

  }

  getworkGroup() {
    this.loader=showLoader(this.loader)
    this.commonDetails.getWorkGroupC().subscribe({next:(newResData) => {
        this.workGroupList = []
        let data = newResData.data.Admin3_VwGetOrganizationDepartments_list;
        if (this.userDetails !== '') {
          let ids = this.userDetails['DepartmentIds']?.split(',');
          if (ids.length > 0 && data.length > 0) {
            for (let i = 0; i < ids.length; i++) {
              let obj = data.find((va) => va.DepartmentId === parseInt(ids[i]));
              if (obj !== undefined) {
                this.workGroupList.push(obj);
              }
            }
          }
        }
        this.loader=hideLoader(this.loader)
      },
      error:(err)=>{
        this.loader=hideLoader(this.loader)
      }
    });
  }

  getAssociatedOfLocations(locationIds: string, salesexecutiveId: number) {
    this.loader=showLoader(this.loader)
this.commonDetails.getAssociatedOfLocationsC(locationIds,salesexecutiveId)
      .subscribe({next:(data:any)=>{
        this.facilityLocations=data?.filter((i:any)=>i.selectedObjects?.length>0)
        // console.log(this.facilityLocations,'this.facilityLocations')
        this.loader=hideLoader(this.loader)

      },
      error:(err)=>{
        console.error(err)
        this.loader=hideLoader(this.loader)
      }
    })
  }

  ngOnDestroy() {
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
  }

  getAssociateSurgicenterData() {
    let userData: any;
    this.loader=showLoader(this.loader)
    this.labAdminSessionService.getLabAdminSession.subscribe(Response => userData = Response);
  this.commonDetails.getSharedSurgicenterC().subscribe({
        next: (data) => {
          this.surgiCenterDetails = data.filter(center => center.IsActive === true);
          this.loader=hideLoader(this.loader)
        },
        error: (err) => {
          console.error(err);
          this.loader=hideLoader(this.loader)
        }
      });
  }

  onMouseEnter(val) {
    this.currentCategory = val['Category'];
    this.toolTipContent = this.caseTypeTitleArr?.[val['Category']];
    this.showToolTip = true
  }

  onLeaveMouse() {
    this.currentCategory = '';
    this.toolTipContent = '';
    this.showToolTip = false
  }

  getPathologistAssociationByTranscriptionist(OrganizationID: string,ExecutiveUserId: number,iOFAccountID: string,loggedInUserid: string,type: string
  ) {
    this.loader=showLoader(this.loader)
    this.commonDetails.getTranscriptionistC(OrganizationID,ExecutiveUserId,iOFAccountID,loggedInUserid,type).subscribe({
        next: (data: any) => {
          if(data)
          {
            const filteredTrueData = data.filter((i) => i.IsAssociated == 'TRUE');
            const filteredFalseData = data.filter((i) => i.IsAssociated !== 'TRUE');
            if(data.length === filteredFalseData.length )
             this.pathologistRoleAssociationData = data;
            else  this.pathologistRoleAssociationData = filteredTrueData;
            this.loader=hideLoader(this.loader)
          }

        },
        error: (err) => {
          console.error(err);
          this.loader=hideLoader(this.loader)
        },
      });
  }

  getphysicianAssociationByMedicalStaff(OrganizationID: string,ExecutiveUserId: number,iOFAccountID: string,loggedInUserid: string,type: string
  ) {
    this.loader=showLoader(this.loader)
    this.commonDetails.getMedicatlStaffC(OrganizationID,ExecutiveUserId,iOFAccountID,loggedInUserid,type)
      .subscribe({
        next: (data: any) => {
          const filteredTrueData = data.filter((i) => i.IsAssociated == 'TRUE');
          const filteredFalseData = data.filter((i) => i.IsAssociated !== 'TRUE');
          if(data.length === filteredFalseData.length )
           this.PhysicianRoleAssociationData = data;
          else  this.PhysicianRoleAssociationData = filteredTrueData;
          this.loader=hideLoader(this.loader)
        },
        error: (err) => {
          console.error(err);
          this.loader=hideLoader(this.loader)
        },
      });
  }


  EnableUserAssociationFlag() {
    const organizationId = this.userDetails['labLocationList'][0]?.OrganizationId;
    this.loader=showLoader(this.loader)
    this.commonDetails.getEnableUserAssociationFlagC().subscribe({
        next: (data: any) => {
          this.enableuserassociation = data.toString() == 'true' ? true : false;
          this.loader=hideLoader(this.loader)
        },
        error: (error: any) => {
          console.error(error);
          this.loader=hideLoader(this.loader)
        },
      });
  }



}

