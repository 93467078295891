import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LabadminService } from '../../labadmin.service';
import { Store } from '@ngrx/store';
import { addNabvTab } from '../../store/actions/navbar.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mattabnav',
  templateUrl: './mattabnav.component.html',
  styleUrls: ['./mattabnav.component.scss']
})
export class MattabnavComponent {

  @Input() tabList=[];
  isCreationModeEnabled$ : Observable<boolean>
  fullScreenMode$ : Observable<boolean>

  constructor(
    private router:Router,
    private labadminService:LabadminService,
    private store: Store<{ breadcrum: [], creationMode : boolean, fullScreenMode : boolean}>){

  }

  // ngOnChanges(){
  //   this.changeTabNav(this.tabList[0])
  // }
 //ngOnInit(){ this.changeTabNav(this.tabList[0])}
  ngOnChanges(){
    if(this.tabList.length && !this.labadminService.redirectToMenu){
      this.changeTabNav(this.tabList[0]);
    }
  }
 ngOnInit(){
  this.isCreationModeEnabled$ = this.store.select('creationMode')
  this.fullScreenMode$ = this.store.select('fullScreenMode')
 }

 getLocation(path){
  let exist=  document.location.href.split('#')[1]
  return exist.includes(path)
 }
 getClass(path){
  let exist=  document.location.href.split('#')[1]
  return exist.includes(path)
 }

 tabChanged(route){

this.router.navigate([`${this.tabList[route.index].routerLink}`])
 }

 changeTabNav(item:object):any{
  let level = 1
  this.labadminService.selectedSubmenu = item;
  if(item['routerLink'].includes('clients')) level = 2
  item={...item,Htext:item['URL'],Level:level} 
  // item['Htext']=item['URL']

  this.router.navigate([`${item['routerLink']}`])

  this.addBreadCrum(item)
// console.log(item['SubMenuCardName'])
 }

 addBreadCrum(navvalue) {
  this.store.dispatch(addNabvTab({value: navvalue}));
}
isActive(item: any): boolean {
  return this.getLocation(item.routerLink);
}

}
