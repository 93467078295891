<div class="site-format">
  <div *ngIf="!createButtonEnabled"
    style="float: right; margin-top: -35px; z-index: 1000; position: relative"
  >
  <button class="mr-3 button-color save-button" *ngIf="this.defaultFormats" (click) ="deleteSiteFormat()">Delete</button>
    <!-- <button class="mr-3 button-color reset-button" (click) = "restoreDefaults()">Restore Defaults</button> -->
    <button class="button-color save-button" (click) ="saveSiteFormat()">Save</button>
  </div>

  <!-- format builder -->
   <div style="text-align: right;color: red">Max character limit:{{maxLength}}</div>
  <div class="format-builder-div">
    <div class="d-flex justify-content-between">
      <div class="format-buider-text-margin primary-text">
        Format Builder<small style="color: red">*</small>
        <mat-hint class="pt-2 ml-3">
          <img
            src="../../../assets/icons/home_icons/help.svg"
            alt="Help"
            style="width: 15px;height: 12px"
          />
          <span class="note-hint-size ml-1"
            >Please configure correct site format for getting proper result</span
          >
        </mat-hint>
      </div>
      <a class="reset-btn" (click) ="clearSiteFormat()">Clear All</a>
    </div>
    <div class="chip-div-margin">
      <mat-form-field class="col-sm-12 p-0">
        <textarea #textareaElement trim matInput maxlength="100" rows="2" class="p-0" type="textarea" (keyup)="onKeyup()">

        </textarea>
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div class="format-buider-text-margin placeholder-text">
      Placeholders
    </div>
    <div class="chip-div-margin">
      <mat-chip-set>
        <mat-chip
          class="chip-margin"
          *ngFor="let item of placeHolders"
          (click)="appendToTextarea(item)"
          [ngClass]="{
            'selected-chip': formatBuilder.includes(item.text),
            'unselected-chip': !formatBuilder.includes(item.text)
          }"
        >
          <span style="cursor: pointer;"
            class="chip-text"
            [ngStyle]="{
              color: formatBuilder.includes(item.text) ? 'white' : 'black'
            }"
            >{{ item.text }}</span
          >
        </mat-chip>
      </mat-chip-set>
    </div>
  </div>

  <!-- Result -->
  <div class="mt-3">
    <span class="primary-text">Result</span>
    <mat-hint class="pt-2 ml-3">
      <img
        src="../../../assets/icons/home_icons/help.svg"
        alt="Help"
        style="width: 15px"
      />
      <span class="hint-size ml-1"
        >Result is generated based on the recent data available for this case type</span
      >
    </mat-hint>
    <div class="result-data-div mt-2 align-items-center">
      <div *ngIf="formatBuilder?.length > 0" class="result-data-length-div">
        <span *ngFor="let item of formatBuilder;let j = index">
          <span class="result-display-text">{{ item }}</span>
          <!-- <ng-container *ngIf="j < formatBuilder.length - 1 && formatBuilder[j + 1]?.trim() !== ','">&nbsp;</ng-container> -->
        </span>
        <mat-divider vertical="true" class="vertical-divider"></mat-divider>
        <span class="result-value-text">
          {{recentCaseTypeString}}
        </span>
      </div>
    </div>
  </div>

  <!-- Standard Templates -->
  <div class="mt-3">
    <div class="d-flex std-temp-text-div">
      <span class="primary-text std-temp">Standard Templates</span>
      <mat-divider class="mat-divider-div"></mat-divider>
    </div>
    <div class="mt-2">
      <div class="d-flex">
        <div
        [ngClass] = "{
          'selected-temp-div': i === selectedTempIndex,
          'unselected-temp-div': i !== selectedTempIndex}"
          class="std-temp-data"
          [ngStyle]="{ 'margin-right': i == standardSiteFormat?.['data'].length - 1 ? '0px' : '7px' }"
          (click)="templateClick(i,item)"
          *ngFor="let item of standardSiteFormat?.['data'];let i = index">

          <div style="text-align: center;min-height: 60px;">
            <span *ngFor="let value of item; let i = index">
                <span>{{value.text}}</span>
                <ng-container *ngIf="i < item?.length - 1 && item[i + 1]?.text.trim() !== ','">&nbsp;</ng-container>
            </span>
          </div>

          <div style="text-align: center;">
            <span *ngFor="let value of item; let ii = index">
              <span class="std-temp-value-text" [ngStyle]="{color:i === selectedTempIndex? 'white':'#0364B4'}">
                <span>{{ value.displayvalue }}</span>
                <ng-container *ngIf="ii < item?.length - 1 && item[ii + 1]?.text.trim() !== ','">&nbsp;</ng-container>
              </span>
            </span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
