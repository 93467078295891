import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { HtmlFilterPipe } from 'src/app/core/utility/htmlfilter.pipe';
import { StripHtmlPipe } from 'src/app/core/utility/striphtml.pipe';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { ShortenTextPipe } from '../../../core/utility/shortentext.pipe';
import { TemplateViewService } from '../../../core/utility/template-view.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmLabadminComponent } from '../confirm-labadmin/confirm-labadmin.component';
import { DialogService } from 'src/app/core/services/dialog.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as _ from 'lodash';

@Component({
  selector: 'app-templates-view',
  templateUrl: './templates-view.component.html',
  styleUrls: ['./templates-view.component.scss'],
  providers:[ ShortenTextPipe, StripHtmlPipe, HtmlFilterPipe ]
})
export class TemplatesViewComponent implements OnChanges, OnDestroy {
  valueClone: any;
  editViewClicked: boolean = false;
  createViewClicked: boolean = false;
  SubMenuCardModel: SubMenuCardModel;
  formBuilderObj: any = {};
  finalFormData: any[] = [];
  dataToBeSaved: any = {};
  formValidationData: any[] = [];
  valuesForDropDown: any = [];
  chipValues: any = [];
  context: string = '';
  finalObj: any = {};
  dataForm: any = [{}];
  temp: any[] = [];
  isFormChanged = false;
  fieldPatterns = {
    number: '^[0-9]*$',
    alpha: '^[a-zA-Z]*$',
    alphaNumeric: '^[a-zA-Z0-9]*$',
    excludeZero : '^[1-9][0-9]*$'
  };
  auditableColumns: any;
  isSaveDisabled = false;
 // editor: Editor;
  saveButtonClicked = false;
  toolTipText: any;
  optionToolTipText: any;
  originalEditValue: any;
  activityEntity: any;
  previousSequenceValue: any;
  selectedCaseType: any;

  @Input() public Placeholders: any = [];
  @Input() public dynamicFormData: any = [];
  @Input("viewSites")
  @Output() closeViewTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Input() templateData: any;
  @Input() selectDropDown: any;
  @Input() createNewClicked: any;
  @Input() editExistingClicked: any;
  @Input() subMenuCardModel: any;
  @Input() selectedListOption: any;
  @Input() hideEdit = false;
  @Input() saveOrEditCompleted: any;
  @Input() HeaderName
  @Input() viewRecord
  @Input() isUserLevel = false;
  @Input() isAdmin3 = true;
  @Input() enableorDisableSeqOnDefault :boolean;
  @Input() isHideTopSectionInCreate :boolean = false;
  @Input() formNoteMessage: any = '';


  @Output() saveOrEditEventTriggered = new EventEmitter<any>();
  @Output() editTemplateClickedFromView = new EventEmitter<any>();


  @ViewChild('myForm', { static: false }) myForm!: NgForm;
  @ViewChildren('dynamicFields') inputFields: QueryList<ElementRef>;
  @ViewChildren('textareaElement') textAreas: QueryList<ElementRef>; // to get list of all textarea in view(HTML)


  // toolbar: Toolbar = [
  //   ['bold', 'italic'],
  //   ['underline', 'strike'],
  //   ['code', 'blockquote'],
  //   ['ordered_list', 'bullet_list'],
  //   [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  //   ['link', 'image'],
  //   ['text_color', 'background_color'],
  //   ['align_left', 'align_center', 'align_right', 'align_justify'],

  // ];

  constructor(
    public tempService: TemplateViewService,
    public commonService: CommonService,
    public VitalHttpServices: VitalHttpServices,
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _snackbar: MatSnackBar,
    public activityService: ActivityTrackerService,
    private shortentext:ShortenTextPipe,
    private labAdminService: LabadminService,
    private matdialog: DialogService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  // ngOnInit(): void {
  //   this.editor = new Editor();
  // }

  resetDynamicForm() { // when we click on reset form based on edit or create we should call particular messages.
    this.editViewClicked ? this.editDynamicForm() : this.createDynamicForm();
    this.isFormChanged = false;
  }

//  defaultFormCreation() { // to create Default Dynamcim Form
    // this.dynamicFormData = this.tempService.objMapping(this.viewRecord, this.HeaderName);
    // this.dynamicFormData.forEach(element => {
    //   element.value = this.viewRecord[element['valueToBeFetchedBy']];
    // });
 // }

  backView() { //method to go to previous page
    this.getActivityEntity(false);
    this.activityEntity['entityId'] = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.closeViewTemplate.emit('close');
  }

  returnBtn(reset?) { //button used to return back to listing page
    if(reset && this.isFormChanged){
      this.matdialog.openLabadminConfimationdialog('', 'All your changes will be lost. Do you want to proceed?', 'OK', 'Cancel')
      // let dialogRef = this.matdialog.openLabadminConfimationdialog(ConfirmLabadminComponent, {
      //   disableClose: true,
      //   autoFocus: false,
      //   width: '360px',
      //   panelClass: 'admin-custom-popup',
      //   data: { header: "", message: "All your changes will be lost. Do you want to proceed?", continue: "OK", cancel: "Cancel" }
      //     })
      // return dialogRef
      .afterClosed().subscribe(result => {
        if (result) {
          this.backView();
        }
        else return;
      })
    }
    else this.backView();
  }

  appendToTextarea(value: any, particularField: any) { // on click of chip we are appending the value to textarea.
    let selectedValue = `{{${value}}}`;

    const textarea = this.textAreas.find(textarea => // to get all the textarea and then from those textareas we will get the textarea which we want.
      textarea.nativeElement.getAttribute('name') === particularField?.properties?.source?.targetField)?.nativeElement as HTMLTextAreaElement;

    textarea.focus(); // to focus on particular textarea so that we can get proper postions.
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    let currentValue = textarea.value;

    // let descriptionField = this.dynamicFormData.find(item =>
    //   item.columnName === particularField?.properties?.source?.targetField);

    let descriptionField = particularField?.groupedField;

    if (descriptionField?.value.length < particularField?.groupedField?.inputValidations?.maxLength && (textarea.value.length + selectedValue.length < particularField?.groupedField?.inputValidations?.maxLength)){
      const description = currentValue.substring(0, startPos) + selectedValue + currentValue.substring(endPos);
      textarea.value = description;
      descriptionField.value = description;
      textarea.setSelectionRange(startPos + selectedValue.length, startPos + selectedValue.length);
      textarea.blur();
      this.onTextAreaChange(particularField);
    } else {
      this._snackbar.open('Character limit reached for description!', 'Close');
    }
  }

  onTextAreaChange(particularField: any) {
    let hiddenTextArea = this.dynamicFormData.find((item: any) => item.columnName === particularField?.properties?.source?.copyTo);
    hiddenTextArea['value'] = particularField?.groupedField?.value;
  }

  createDynamicForm() { // we are creating new dynamicForm here
    this.dynamicFormData.forEach(item => {
      item.value = ''; // making all value as '', so that if any value is present we will remove here

      // here we want the dropdown which is parent or whose value is dependent on options of other dropdowns
      if (item?.properties?.patchPredefinedValue) {
        item['value'] = this.tempService.globalMasterDataForQueries[item?.columnName] || '';
      }

      // if (item.dataType === 'multiselectDropdown') {
      //   item['dummyValue'] = [...[]];
      //   item['value'] = item?.value ? Array.isArray(item?.value) ? item?.value : [item?.value] : null;
      // }

      if (['Active', 'Status', 'IsActive','External DB','Send Out Lab'].includes(item.displayName)) {  // by default we need it as true so
        item.value = 1;
      }

      if (['Default', 'Abnormal', 'IsDefault'].includes(item.displayName)) { // by default we need it as false so
        item.value = 0;
      }
      if (item.dataType === 'autocomplete') {
        item['dummyValue'] = { ...{ itemName: '', itemId: '' } };
      }

      if (item?.dataType === 'groupedTextArea') {
        item['groupedField'].value = '';
      }

      if (item?.dataType === 'multiselectDropdown') {
        item['dummyValue'] = [...[]];
        item.value = [...[]];
      }
    });

    this.context = 'Create ';
    this.templateData = this.templateData || this.VitalHttpServices.Templatedata;
    this.editViewClicked = false;
    this.createViewClicked = true;
    this.getDependentDropdownsOnEdit();
  }


  editDynamicForm() {
    // this.defaultFormCreation();

    if(['Default Role', 'Auto Assignment Rule','Disclaimers', 'Workgroups','Payers'].includes(this.HeaderName)) {
      this.editTemplateClickedFromView.emit({ action: 'edit', rowData: this.viewRecord, event: Event });
      return;
    }

    const item = this.dynamicFormData.find(item => item.columnName.toString().toLowerCase() === 'sequence');
    if (item && this.viewRecord?.Sequence === -1) {
       this.viewRecord.Sequence = '';
    }

    this.dynamicFormData.forEach(element => {
      element.value = this.viewRecord[element['valueToBeFetchedBy']];

      if (['Is_Default', 'Default', 'Abnormal', 'IsDefault'].includes(element.valueToBeFetchedBy)) {
        element.value = element.value === 'Yes' || element.value === true ? 1 : 0;
      }

      if (['IsActive', 'Active', 'Status', 'isActive'].includes(element.valueToBeFetchedBy)) {
        element.value = ['Yes', true, 'Active'].includes(element.value) ? 1 : 0;
      }

      if(element?.dataType === 'badge'){
        this.onBadgeChange({target: {checked: element?.value}},element?.columnName,element?.dependentFields,true)
      }

      if (element?.dataType === 'groupedTextArea') {
        let hiddenTextArea = this.dynamicFormData.find((item: any) => item.columnName === element?.properties?.source?.copyTo);
        element['groupedField'].value = hiddenTextArea?.['value'];
      }

      if (element?.dataType === 'multiselectDropdown' && element?.properties?.source?.separatedBy) {
        element.value = element.value.split('|');
        element['dummyValue'] = this.getValueToPatchForMultiSelect(element);
      } else if (element?.dataType === 'multiselectDropdown' && !element?.properties?.source?.separatedBy) {
        element.value = element?.value ? Array.isArray(element.value) ? element.value : [element.value] : null;
        if (element?.properties?.source?.dataType === 'number' && element.value) {
          element.value = element.value.map(str => parseInt(str));
        }
        element['dummyValue'] = this.getValueToPatchForMultiSelect(element);
      }

      // if (element?.dataType === 'autocomplete') {
      //   let selectedObject = element?.properties?.source?.options.find((item: any) => item[element?.properties?.source?.keyToBeSentToBackend] === element?.value);
      //   if(selectedObject) {
      //     this.getValueToPatchForAutoComplete(selectedObject, element);
      //   }
      // }

    });
    let oldData = this.getFormatedFormObject();
    this.originalEditValue = structuredClone(oldData);
    this.getDependentDropdownsOnEdit();
    this.context = 'Edit ';
    this.createViewClicked = false;
    this.editViewClicked = true;
  }


  async getDependentDropdownsOnEdit() { // to get the options of dependent dropdowns
    // Fetch dependent values for dropdowns and autocompletes
    await Promise.all(this.dynamicFormData?.filter(fieldObject => (fieldObject.dataType === 'dropdown' || fieldObject.dataType === 'autocomplete' || fieldObject.dataType === 'staticDropdown') && fieldObject?.properties?.source?.dependentFieldsList?.length)
      .map(async fieldObject => {
          await this.tempService.getDependentValues(fieldObject, this.dynamicFormData, this.subMenuCardModel, this.templateData, true);
      })
    );

    this.dynamicFormData?.filter((formField: any) => formField.dataType === 'autocomplete')
    .forEach((autoCompleteField: any) => {
      if(autoCompleteField?.properties?.source?.uiDependentFieldsList) {
        let dependentFields = autoCompleteField['properties']?.['source']?.['uiDependentFieldsList'];
        if(dependentFields?.length) {
          this.getUiFilteredData(autoCompleteField, dependentFields);
        }
      }
    });


    // Get value for autocompletes
    this.dynamicFormData?.filter((formField: any) => formField.dataType === 'autocomplete')
    .forEach((autoCompleteField: any) => {
        let selectedObject = autoCompleteField?.properties?.source?.options.find((item: any) => item[autoCompleteField?.properties?.source?.keyToBeSentToBackend] === autoCompleteField?.value);
        if (selectedObject) {
            this.getValueToPatchForAutoComplete(selectedObject, autoCompleteField);
        }
    });
  }

  getUiFilteredData(field:any, dependentFields: any) {
    let toBeChangedFormFields = this.dynamicFormData.filter(obj => dependentFields.includes(obj.columnName));
    this.tempService.filterUIFields(field, dependentFields, this.dynamicFormData, 'UserId', true);
  }


  onBadgeChange(event: any, columnName, dependentField?:any,fromEdit :boolean = false) { // on badge change value should be as 0 or 1

    const item = this.dynamicFormData?.find(item =>
      ['sequence', 'displayorder','sequenceorder'].includes(item?.columnName?.toString().toLowerCase())
    );

    if (item && item.value !== '' && item.value !== -1) {
        this.previousSequenceValue = (fromEdit && item?.value == -1) ? '' : item.value;
    }

    let field = this.dynamicFormData?.find((item: any) => item.columnName === columnName);
    if(this.enableorDisableSeqOnDefault && columnName?.toString().toLowerCase() == 'isdefault'){
      field['value'] = event.target.checked == true ? 1 : 0;
      this.dynamicFormData?.forEach(item=>{
        if(dependentField?.includes(item?.columnName)) {
          item.value = field['value'] === 1 ? '' : this.previousSequenceValue;
        }
      })
    }else{
      field['value'] = event.target.checked == true ? 1 : 0;
    }
  }

  markAllFieldsOfFormTouched() {
    // Mark all form controls as touched to trigger validation
    Object.values(this.myForm.controls).forEach(control => {
      control.markAsTouched();
    });
    const missingField = this.dynamicFormData?.find((obj: any) => {
      if (obj['dataType'] === 'autocomplete') {
        return (obj.inputValidations?.required && !obj.value);
      } else {
        return (obj.inputValidations?.required && !obj.value) || obj.invalid || !this.myForm.controls[obj['columnName']]?.valid;
      }
    });
    if (missingField) {
      // Focus on the invalid input
      const inputElement = this.inputFields?.find(input => input?.nativeElement?.name === missingField?.columnName);
      const textAreaElement = this.textAreas?.find(input => input?.nativeElement?.name === missingField?.columnName);

      if (textAreaElement) {
        textAreaElement.nativeElement.focus();
        if (this.HeaderName == 'Grossing Templates')
          this.textAreas.toArray()[1].nativeElement.focus();
      }
      if (inputElement) {
        inputElement.nativeElement.focus();
      }
    }
    // ObjectUnsubscribedError.values()
  }

  saveDynamicForm() { //Passing the data to API side
    this.saveButtonClicked = true;
    this.isSaveDisabled = true;
    this.saveOrEditCompleted = '';
    this.ngxService.start();
    const defaultFields = {
      OrganizationId: sessionStorage?.getItem('org_id') && sessionStorage?.getItem('org_id') != '' ? sessionStorage?.getItem('org_id') : this.labAdminService.organizationId,
      CaseType: this.templateData?.secondarykeys?.casetype?.toString() || '',
      CreatedBy: sessionStorage?.getItem('Userid') ? sessionStorage?.getItem('Userid').toString() : '-100',
      ModifiedBy: sessionStorage?.getItem('Userid') ? sessionStorage?.getItem('Userid').toString() : '-100',
    };

    const action = {
      Action: this.createViewClicked ? "Create" : "Edit"
    };

    const dataToBeSaved: any = {};
    this.dynamicFormData.forEach(a => {
      if (a.columnName) {
        dataToBeSaved[a.columnName] = a.value;
      }
    });

    if (dataToBeSaved?.CaseType) {
      delete defaultFields.CaseType;
    }

    const finalObj = { ...dataToBeSaved, ...action, ...defaultFields, ...{casetypeObject: this.selectedCaseType} };

    if (this.checkRequiredFields() && this.myForm.valid) {
      this.saveOrEditEventTriggered.emit(finalObj);
    } else {
      this.ngxService.stop();
      this.isSaveDisabled = false;
      this.markAllFieldsOfFormTouched();
      this._snackbar.open("Mandatory field(s) are missing", 'Close');
    }

    // setTimeout(() => {
    //   this.isSaveDisabled = false;
    // }, 300);
  }


  ngOnChanges(changes: SimpleChanges) {
    this.valueClone = this.viewRecord;
    this.temp = this.tempService.objMapping(this.viewRecord, this.HeaderName);

    if (this.createNewClicked && changes?.createNewClicked?.currentValue) {
      this.saveOrEditCompleted = undefined;
      this.createNewClicked = undefined;
      this.saveButtonClicked = false;
      this.getActivityEntity();
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
      this.createDynamicForm();
    } else if (this.editExistingClicked && changes?.editExistingClicked?.currentValue) {
      this.saveOrEditCompleted = undefined;
      this.editExistingClicked = undefined;
      this.saveButtonClicked = false;
      this.getActivityEntity();
      let idField = this.tempService.getTemplateConfiguration(this.HeaderName)?.idKey;
      this.activityEntity.entityId = this.viewRecord?.[idField]?.toString();
      this.activityService.setActivitySession(this.activityEntity);
      this.editDynamicForm();
    }

    if (this.saveOrEditCompleted && changes?.saveOrEditCompleted?.currentValue !== changes?.saveOrEditCompleted?.previousValue) {
        this.saveButtonClicked = false;
        this.ngxService.stopAll();
        if(this.saveOrEditCompleted === 'failed' || this.saveOrEditCompleted === 'error') {
          this.isSaveDisabled = false;
          this.saveOrEditCompleted = undefined;
        } else {
          this.backView();
          this.saveOrEditCompleted = undefined;
          this.isSaveDisabled = false;
        }

    }
  }

  onBlurOfInput($event: any, fieldItem: any) {
    if($event?.target?.value !== fieldItem?.['previousValue'] && $event.target.value) {
      fieldItem['previousValue'] = $event.target.value;
      let replicateFields = fieldItem?.['properties']?.['replicateFields'];
      if (replicateFields?.length) {
        this.dynamicFormData.forEach(obj => {
          if (replicateFields.includes(obj.columnName)) {
            if(!obj.value){
             obj.value = $event?.target?.value?.trim();
            }
          }
        });
      }
    }
  }

  onSelectChange(selectedColumn) {
    if (selectedColumn['columnName'] == 'roleName') {
      this.tempService.workGrouproledata = selectedColumn['value'];
    }
    if (selectedColumn?.['properties']['source']['dependentFieldsList']?.length) {
      // this.getDependentValues(selectedColumn);
      this.tempService.getDependentValues(selectedColumn, this.dynamicFormData, this.subMenuCardModel, this.templateData);


      selectedColumn?.['properties']['source']['dependentFieldsList'].forEach((dependentField: any) => {
        let tempDependentField = this.dynamicFormData.find((item: any) => item?.['columnName'] === dependentField);
        if(tempDependentField) {
          tempDependentField['value'] = '';
          tempDependentField['dummyValue'] = { itemId: '', itemName: '' };
        }
      })
    }

    if (selectedColumn?.['properties']['source']['isPatchValueToOtherField']) {
      setTimeout((x) => {
      let patchValue = '';
      selectedColumn?.['properties']['source']['isPatchValueToOtherField']['sourceFields'].forEach((dependentField: any, index: any) => {
        let tempDependentField = this.dynamicFormData.find((item: any) => item?.['columnName'] === dependentField);

        if(tempDependentField) {
          patchValue += tempDependentField['dummyValue']['itemName'] + ' / ';
        }
      });
      let tempTextValue = this.dynamicFormData.find((item: any) => item?.['columnName'] === selectedColumn?.['properties']['source']['isPatchValueToOtherField']['targetField']);

        tempTextValue['value'] = patchValue.substring(0, 249).replace(/ \/ $/, '');
    }, 300);
    }

  }

  // for autocomplete dropdown start.
  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any, field = null) {
    if (!autoCompleteInputboxValue) {
      if (this.HeaderName?.toLowerCase().includes('grossing templates') && (field['columnName'] === 'SiteName')) {
        return autoCompleteOptions?.filter(option =>
          option.BodySite?.toLowerCase() !== "select"
        )
      } else {
        return autoCompleteOptions;
      }
    }

    else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      if (this.HeaderName?.toLowerCase().includes('grossing templates') && (field['columnName'] === 'SiteName')) {
        filteredAutoCompleteOptions = filteredAutoCompleteOptions?.filter(option =>
          option.BodySite?.toLowerCase() !== "select"
        )
      }
      return filteredAutoCompleteOptions.filter((option: any) => option[filterProperty]?.toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }

  onAutoCompleteSelectionChange(e, field, particularObject) {
    if (e.source.selected) {
      if(field?.columnName?.toLowerCase() === 'casetype') {
        this.selectedCaseType = particularObject;
      }
      field.value = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      field.dummyValue['itemId'] = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      this.onSelectChange(field);
      field['listBackupValue'] = null;

      if(field['properties']?.['source']?.['uiDependentFieldsList']) {
        let dependentFields = field['properties']?.['source']?.['uiDependentFieldsList'];
        if(dependentFields?.length) {
          this.tempService.filterUIFields(field, dependentFields, this.dynamicFormData, 'UserId');
        }
      }
    }
  }

  onClearOfAutoComplete($event: any, field: any) {
    $event.stopPropagation();
    field['listBackupValue'] = null;
    field.value = null; // once we type something then we have to remove the data.
    field['dummyValue'] = { itemId: '', itemName: '' }; // once we type something then we have to remove the data.

    field['listBackupValue'] = null;
    field['properties']['source']['options'] = structuredClone(field['properties']['source']['options']);

    if(field['properties']?.['source']?.['uiDependentFieldsList']) {
      let dependentFields = field['properties']?.['source']?.['uiDependentFieldsList'];
      if(dependentFields?.length) {
        this.tempService.filterUIFields(field, dependentFields, this.dynamicFormData, 'UserId');
      }
    }

    if(field['properties']?.['source']?.['dependentFieldsList']) {
      field?.['properties']['source']['dependentFieldsList'].forEach((dependentField: any) => {
        let tempDependentField = this.dynamicFormData.find((item: any) => item?.['columnName'] === dependentField);
        if(tempDependentField) {
          tempDependentField['value'] = '';
          tempDependentField['dummyValue'] = { itemId: '', itemName: '' };
          tempDependentField['properties']['source']['options'] = structuredClone(tempDependentField['properties']['source']['defaultOptions'])
        }
      })
    }
  }

  onAutoCompleteInputChange($event: any, field: any) {
    field['listBackupValue'] = null;
    field.value = null; // once we type something then we have to remove the data.
    field.dummyValue['itemId'] = null; // once we type something then we have to remove the data.
    this.getUserBasedOnTypedName($event.target.value, field); // to get the user Id from the name typed.
  }

  getUserBasedOnTypedName(value: any, field: any) {
    let keyToBeSentToBackend = field['properties']?.['source']?.['keyToBeSentToBackend'];
    let selectedObject = field?.properties?.source?.options?.filter((item: any) => { // from the available options we will searc for the user and then assign the id.
      return item.username === value;
    });
    field.value = selectedObject[0]?.[keyToBeSentToBackend] ? selectedObject[0]?.[keyToBeSentToBackend] : null; // from the available options we will searc for the user and then assign the id.
    field.dummyValue['itemId'] = selectedObject[0]?.[keyToBeSentToBackend] ? selectedObject[0]?.[keyToBeSentToBackend] : null; // from the available options we will searc for the user and then assign the id.
  }
  // for autocomplete dropdown end.

  checkRequiredFields() {
    let missingFields = this.dynamicFormData?.find(obj => obj.inputValidations?.required && !obj.value);
    if(this.HeaderName === 'Custom Abbreviations'){
      const phraseObject = this.dynamicFormData?.find(item => item.columnName === 'Phrase');
    if (phraseObject && phraseObject?.value === '<p></p>') {
          missingFields = true;
        }
    }
    return missingFields ? false : true;
  }

  getSettings(field: any) {
    return field['properties']?.['source']?.['dropdownSettings'];
  }

  getSelectedCaseTypesForMultiSelect(selectedArray: any, fieldOptions: any) {
    let filteredValues = fieldOptions?.filter(item => selectedArray.includes(item.CaseType));
    let resultArray = filteredValues?.map((item: any) => item?.dropdownDisplayColumn)?.join(', ');
    return resultArray;
  }


  // for multiple dropdown start.
  onMultiDropdownSelect(item: any, field: any) {
    const value = item[field['properties']?.['source']?.['keyToBeSentToBackend']];
    if (!field.value) {
      field.value = [];
    }
    field.value.push(value);
    if(field?.columnName?.toLowerCase() === 'casetype' && field.value) {
      this.selectedCaseType = {};
      let caseTypeObject = this.getSelectedCaseTypesForMultiSelect(field.value, field['properties']?.['source']?.['options']);
      this.selectedCaseType['CasetypeDisplayName'] = caseTypeObject;
    }
    this.onSelectChange(field);
    this.isFormChanged = true;
  }

  onMultiDropdownSelectAll(items: any, field: any) {
    field.value = [];
    for (let itr = 0; itr < items.length; itr++) {
      field.value.push(items[itr][field['properties']?.['source']?.['keyToBeSentToBackend']]);
    }
    if(field?.columnName?.toLowerCase() === 'casetype' && field.value) {
      this.selectedCaseType = {};
      let caseTypeObject = this.getSelectedCaseTypesForMultiSelect(field.value, field['properties']?.['source']?.['options']);
      this.selectedCaseType['CasetypeDisplayName'] = caseTypeObject;
    }
    this.onSelectChange(field);
    this.isFormChanged = true;
  }

  onMultiDropdownDeSelect(items: any, field: any) {
    const index: number = field.value.indexOf(items[field['properties']?.['source']?.['keyToBeSentToBackend']]);
    if (index !== -1) {
      field.value.splice(index, 1);
    }
    if(field?.columnName?.toLowerCase() === 'casetype' && field.value) {
      this.selectedCaseType = {};
      let caseTypeObject = this.getSelectedCaseTypesForMultiSelect(field.value, field['properties']?.['source']?.['options']);
      this.selectedCaseType['CasetypeDisplayName'] = caseTypeObject;
    }
    this.onSelectChange(field);
    this.isFormChanged = true;
  }

  onMultiDropdownDeSelectAll(field: any) {
    field.value = [];
    this.onSelectChange(field);
    this.selectedCaseType['CasetypeDisplayName'] = undefined;
    this.isFormChanged = true;
  }
  // for multiple dropdown end.

  //Text fields trim the value
  trimField(field: any): void {
    field.value = field?.value ?  field?.value?.trim() :field?.value;
  }

  //patch for autoComplete
  getValueToPatchForAutoComplete(selectedObject, fieldItem) {
    if (fieldItem?.properties?.source?.toDisplayInIputField && fieldItem?.value && fieldItem?.value !== 'Not Specified' && selectedObject) {
      const values = fieldItem?.properties?.source?.toDisplayInIputField.map((key: any) => selectedObject?.[key]).filter(Boolean);
      let fieldDisplayValue: any;
      if (values.length > 1) {
        fieldDisplayValue = values[0] + ' ( ' + values[1] + ' )';
      } else if (values.length === 1) {
        fieldDisplayValue = values[0];
      } else {
        fieldDisplayValue = '';
      }
      fieldItem.dummyValue = { itemName: fieldDisplayValue, itemId: fieldItem?.value };

    }
  }
  //patch end for autoComplete


  //patch for autoComplete
  getValueToPatchForMultiSelect(fieldItem) {
    let tempValue: any;
    if (fieldItem?.properties?.source?.dataValueToDisplay) {
      if (fieldItem.value) {
        tempValue = fieldItem.value.map(id => {
          const item = fieldItem?.properties?.source?.options.find((obj: any) => {
            return obj[fieldItem?.properties?.source?.keyToBeSentToBackend] == id;
          });
          return item ? item : null;
        }).filter(role => role !== null);
      }
    }
    return tempValue ? tempValue : [];
  }
  //patch end for autoComplete

  ngOnDestroy(): void {
    this.dynamicFormData = undefined;
  }

  async getActivityEntity(getAudit = true) {
    // this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
    if (getAudit) {
      this.getAuditableDetails(this.templateData.menuURL);
    }
    await this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  getAuditableDetails(location: any) {
    let locactionTable=location
    let obj = {
      "microscopic codes": 'Microscopic Notes',
      "correlation" :'Correlation Templates',
      "nomenclature" :'Nomenclature Templates'
    }
    if(obj[location.toLowerCase()]){
      locactionTable = obj[location.toLowerCase()]
    }
    this.VitalHttpServices.getDisplayColumns({ "TableName": locactionTable }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }

  clearFilterClicked($event: any, field: any) {
    if(!field['listBackupValue']) {
      field['listBackupValue'] = field['dummyValue']['itemName'];
      field['dummyValue']['itemName'] = '';
      $event.stopPropagation();
    }
  }

  autoCompletePanelClosed(field: any) {
    if(!field['dummyValue']['itemName']) {
      let tempName = field['listBackupValue'];
      field['dummyValue']['itemName'] = tempName;
      field['listBackupValue'] = null;
    }
  }

  checkValue(fieldValue, field) {
    if(!fieldValue?.itemId && field?.['properties']['source']['dependentFieldsList']?.length) {
      field?.['properties']['source']['dependentFieldsList'].forEach((dependentField: any) => {
        let tempDependentField = this.dynamicFormData.find((item: any) => item?.['columnName'] === dependentField);
        if(tempDependentField) {
          tempDependentField['value'] = '';
          tempDependentField['dummyValue'] = { itemId: '', itemName: '' };
          tempDependentField['properties']['source']['options'] = [];
          tempDependentField['properties']['source']['options'] =  tempDependentField['properties']['source']['defaultOptions'];
        }
      })
    }
  }


  Editorconfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '12rem',
    maxHeight:'12rem',
    placeholder: 'Enter text here...',
    //height:'12rem',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Times New Roman',
    defaultFontSize: '4',
    toolbarHiddenButtons: [
      [
        'customClasses',
        'strikeThrough',
        'subscript',
        'superscript',
        'insertVideo',
        'link',
        'unlink',
        'toggleEditorMode',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  checkCurrentValue() {
    let newValue = this.getFormatedFormObject();
    if(!this.shallowLooseEqual(this.originalEditValue, newValue)) {
      this.isFormChanged = true;
    } else {
      this.isFormChanged = false;
    }
  }


  getFormatedFormObject(): any {
    const oldData: any = {};
    this.dynamicFormData.forEach(a => {
      if (a.columnName) {
        oldData[a.columnName] = a.value;
      }
    });
    return oldData;
  }

  shallowLooseEqual(obj1, obj2) {
    // Ensure both are objects, otherwise they cannot be compared as objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    // Get keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if both objects have the same number of properties
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Check each property with loose equality (==)
    for (let key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        // If the property is an array, compare element by element
        if (Array.isArray(val1) && Array.isArray(val2)) {
            if (val1.length !== val2.length) return false;
            for (let i = 0; i < val1.length; i++) {
                if (!_.isEqual(val1, val2)) return false; // Loose equality for array elements
            }
        }
        // If the property is not an array, use loose equality
        else if (val1 != val2) {
            return false;
        }
    }
    return true;
  }

  clearZero(event: Event,fieldName:any) {
    const input = event.target as HTMLInputElement;

    if (input.value === '0') {
        input.value = '';
        this.myForm.controls[fieldName].patchValue('');
    }
}



}
