<div>
    <mat-dialog-actions [formGroup]="LookupForm">
        <div class="my-2 align-items-center w-100 payer-wrap">
            <ng-container>
                <div class="d-flex col-sm-12">
                    <div class="col-sm-2 vdm-heading p-0">
                        <span> Payer Lookup</span>
                    </div>
                    <div class="col-sm-10 d-flex button-wrap p-0 align-center">
                        <span class="toggle-font mr-2">Do not import the contact and address details:</span>
                        <app-toggle-switch type="secondary" formcontrolname="addressandcontact" class="p-0">
                        </app-toggle-switch>
                    </div>
                </div>
            </ng-container>
            <ng-container>
                <div class="col-sm-12">
                    <div class="row">
                        <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements"
                            formcontrolname="payerName" labelValue="Payer Name"></app-custom-input>
                        <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" formcontrolname="electronicPayerId"
                            labelValue="Electronic payer Id"></app-custom-input>
                        <app-input-auto-complete labelValue="State" class="col-sm-3 pr-2 pb-2 "
                            [dropDownValue]="statedropdown" formcontrolname="state"
                            (onSelected)="stateValues($event, 'state')"></app-input-auto-complete>
                        <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" formcontrolname="city"
                            labelValue="City"></app-custom-input>
                    </div>
                    <div class="col-sm-12 p-0">
                        <div class="row">
                            <app-custom-input class="col-sm-3 pr-2 pb-2input-elements" formcontrolname="zip" mask="000000000000000"
                                labelValue="Zip code"></app-custom-input>
                            <app-button buttonclass="primary" class="col-sm-2 npi-search-btn mt-2" customClass="admin"
                                (click)="search()">
                                Search
                            </app-button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-dialog-actions>
    <div>
    </div>


    <div class="parent-div mt-2 table-align" *ngIf="searchData == true">
        <div class="header d-flex p-0 mb-1 table-content grid-header-hr">
            <div class="header-wrap" *ngIf="!GridData" style="width: auto !important;">
                <span class="buttonClass">
                    <div class="dataTable"></div>
                </span>
                <span class="widthClass pl-1" *ngFor="let header of sheetHeader">
                    <div class="dataTable valueDiv" *ngIf="!GridData">{{header}}</div>
                </span>
            </div>
        </div>

        <div class="container-fluid header-wrap p-0 ml-3">
            <div cdkDropList cdkDropListOrientation="vertical" *ngIf="!GridData" class="tableScroll"
                style="width: fit-content !important;">
                <span *ngIf="PayerList?.length > 0">
                    <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'"
                        [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive')?'card-data mt-1 ml-0  mb-1 inactive-class':'card-data mt-1 ml-0  mb-1'"
                        *ngFor="let item of PayerList | searchfiltersTemplate: templatefilter; let i = index;">

                        <input class="buttonClass" (click)="handleRadioChange($event,item, i)" [id]="radio"
                            name="payerRadio" type="radio">
                        <span class="text-muted text-ellipsis dataTable widthClass" *ngFor="let header of sheetHeader">
                            <div *ngIf="header != 'ResultMessage' && header != 'Result'" class="valueDiv">
                                {{ displayedColumns(item,header) | stripHtml }}</div>
                            <div *ngIf="header == 'ResultMessage' || header == 'Result'"
                                [ngClass]="item['isvalid']==1 || item['result']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||['isvalid']==0 ? 'text-danger pl-1':null"
                                class="valueDiv">
                                {{ displayedColumns(item,header) | stripHtml }}</div>
                        </span>
                    </div>

                </span>
            </div>
            <div class="col-sm-12 nodata-header-wrap-popup" *ngIf="GridData">
                <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
            </div>
        </div>
    </div>
    <div class="col-sm-12 align-center mt-2 mb-2">
        <app-button mat-dialog-close class="mr-3" buttonclass="secondary" customClass="admin"
            (click)="returnBtn()">Cancel</app-button>
        <app-button buttonclass="primary" *ngIf="!GridData" customClass="admin" (click)="saveData()">Import</app-button>
    </div>