import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LabadminSessionService } from '../labadmin/services/labadmin-session.service';
import { LabadminService } from '../labadmin/labadmin.service';
import { map, take } from 'rxjs';
import { enableView } from '../labadmin/components/Utility Functions';
import { ExtAttributeConstants } from '../labadmin/components/share/utility/ex_attribute _constants';
import { CommondetailsComponent } from '../labadmin/components/commondetails/commondetails.component';

@Component({
  selector: 'app-infoattachment',
  templateUrl: './infoattachment.component.html',
  styleUrls: ['./infoattachment.component.scss'],
})
export class InfoattachmentComponent {
  @Input() locationDetails: any;
  @Input() isHideLocationGrid:boolean = false;
  @Input() from : string = '';
  @Input() pathUserId : string = ''; //only applicable for user. for location it will be loggedinuserid.
  accession: SafeHtml;
  diagnosis: SafeHtml;
  note: SafeHtml;
  noDataFound: boolean = false;
  fileTypeCache: { [key: string]: boolean } = {};
  showLoader:boolean = true;
  attachmentList: any = [];
  sourceApp = 'VitalDx';
  isPreviewDisabled: boolean = false;
  isDownloadDisabled: boolean = false;
  isLoading:boolean = true;
  isEnableInstructions: boolean = false;
  loggedInUserOrgId:string = ''
  constructor(
    private sanitizer: DomSanitizer,
    private labAdminSessionService: LabadminSessionService,
    private labAdminService: LabadminService,
    private commonDetails:CommondetailsComponent
  ) { }
  ngOnInit() {
    let loggedInUserId;
    this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(
      async (session) => {
        loggedInUserId = session['userDetails'].userId;
        this.loggedInUserOrgId = session['userDetails'].organizationId
      }
    );

    let accountId = '0'
    let oper = 1

    if(this.from === 'location')
    {
      this.GetVAExtAttrValueForEnableInstructions()
      accountId = this.locationDetails?.AccountID
      oper = 0
      this.pathUserId = loggedInUserId
      this.accession = this.sanitizer.bypassSecurityTrustHtml(this.locationDetails?.CustomInstruction);
      this.diagnosis = this.sanitizer.bypassSecurityTrustHtml(this.locationDetails?.CustomInstructionDiagnosis);
      this.note = this.sanitizer.bypassSecurityTrustHtml(this.locationDetails?.Notes);
    }

    this.commonDetails.getAttachmentC(
        this.pathUserId,
        accountId,
        oper,
      ).subscribe({ 
        next : (data) => {
          this.showLoader = false;
          this.isLoading = false;
          this.attachmentList = data?.Attachements || [];
          if (
            (this.locationDetails?.CustomInstruction == '' ||
              this.locationDetails?.CustomInstruction == null) &&
            (this.locationDetails?.CustomInstructionDiagnosis == '' ||
              this.locationDetails?.CustomInstructionDiagnosis == null) &&
            (this.locationDetails?.Notes == '' ||
              this.locationDetails?.Notes == null) &&
            this.attachmentList?.length <= 0
          ) {
            this.noDataFound = true;
          }
          else{
            this.noDataFound = false;
          }
          if(this.attachmentList?.length > 0){
          //this.GetButtonAccess(this.labAdminService.subMenuPermissions);
          }
      },
      error: (error) =>{
        this.showLoader = false;
        this.isLoading = false;
        this.noDataFound = true;
        console.error(error);
      }
    });
  }

  // file opening a new url/tab
  viewAttachment(file: any) {
    if(!file.disableView){
    this.showLoader = true;
    this.labAdminService
      .ViewSupplements(file.FileId, this.labAdminService.deploymentKey)
      .subscribe((data) => {
        this.showLoader = false;
        let bloBFile = new Blob([data], { type: data.type });
        var fileURL = URL.createObjectURL(bloBFile);
        window.open(fileURL);
      },(error)=>{
        this.showLoader = false;
        console.log(error);
      });
    }
  }

  // download file
  downloadFile(file: any) {
    if(!this.isDownloadDisabled){
    this.showLoader = true;
    this.labAdminService
      .ViewSupplements(file.FileId, this.labAdminService.deploymentKey)
      .subscribe((data) => {
        this.showLoader = false;
        let bloBFile = new Blob([data], { type: data.type });
        var fileURL = URL.createObjectURL(bloBFile);
        var downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = file.Name;
        downloadLink.click();
      },(error)=>{
        this.showLoader = false;
        console.log(error);
      });
    }
  }

  // check same file type is aleady is there or not
  isViewableFileType(fileType: string): boolean {
    if (fileType in this.fileTypeCache) {
      return this.fileTypeCache[fileType];
    }
    const allowedExtensions = [
      'application/pdf',
      'image/png',
      'image/jpg',
      'image/jpeg',
    ];
    const result = allowedExtensions.includes(fileType);
    this.fileTypeCache[fileType] = result;
    return result;
  }

   // RBAC
   GetButtonAccess(actionButtonDetails) {

    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {

      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Attachments & Notes")["ActionButton"]

      try {
        for (let i = 0; i < seletedMenuPermissions?.length; i++) {
          switch (seletedMenuPermissions[i].Button.toLowerCase()) {
            case 'preview':
              this.isPreviewDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'download':
              this.isDownloadDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  GetVAExtAttrValueForEnableInstructions(
  ) {

    this.commonDetails.getEnableInstructionC()
      .subscribe({
        next: (res: any) => {
          this.isEnableInstructions = res.toString() == 'true' ? true : false;

        },
        error: (err) => console.error(err)
      });
  }
}
