<div class="px-4" *ngIf="sourceApp == 'VitalDx'  && !gridPage">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>
<div *ngIf ="gridPage">
    <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="GridData" [context]="'Cassette Templates'"
    [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="sourceApp =='VitalDx'? true : uploadBtn" [hideExport]="exportBtn" 
    [hideCopy]="sourceApp =='VitalDx'? true : copyBtn" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
    [showHeaderModule]="true"  [saveOrEditCompleted]="saveOrEditCompleted"
    [isAdmin3]="true" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true"
    (uploadTemplateEventTriggered)="uploadCassetteTemplates()" (copyTemplateEventTriggered)="copyFromOrg()"
    (emitFilters)="onSelectionCasetype($event)">
    </app-templates-listing>
</div>

<!-- bulk upload -->
<app-common-bulk-functions *ngIf="uploadbtnClicked" [templateData]="templateData" [uploadClicked]="uploadbtnClicked"
    [selectedUser]="selectedUser" (uploadBack)=backClicked($event) [fromUserCard]="fromUserCard"
    [cardContext]="'fromCaseType'" [columnsToRemove]="['accountid']"></app-common-bulk-functions>

<!-- copy -->
<div *ngIf="copyClicked">

    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Cassette Templates</strong>
        </h3>
    </div>

    <form [formGroup]="copyFromOrgFrm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDeplyment">
                    <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Group <span class="error-msg">*</span></mat-label>
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                    (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization);  trimFieldValue('frmOrganization')" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            title="{{ show.organizationname }} ({{show.organizationid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.organizationname }}">
                            <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            </mat-form-field>


            <div class="col-sm-12 mt-4 mb-2" *ngIf="!noDataFound">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!noDataFound && gridWidth == 0" type="button"
                    (click)="refreshGrid()">Back</button>
            </div>
        </div>
        <div class="col-sm-12 parent-div mt-2 table-align">
            <div class="header d-flex p-0 table-content" *ngIf="gridWidth>0">
                <div class="header-wrap">
                    <span *ngIf="!postUpload" class="buttonClass">
                    </span>
                    <span class="widthClass" *ngFor="let header of sheetHeader">
                        <div class="dataTable valueDiv">{{header}}</div>
                    </span>
                </div>
            </div>
            <div class="row p-0" style="width: fit-content !important;">
                <div cdkDropList cdkDropListOrientation="vertical" class="col-sm-12 tableScroll" *ngIf="!Gridata">
                    <span *ngIf="gridWidth > 0">
                        <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'"
                            [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive')?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                            *ngFor="let item of griddata">
                            <input class="buttonClass" *ngIf="!postUpload" type="checkbox"
                                (change)="CopyDataChecked($event,item)">
                            <div class="text-muted text-ellipsis dataTable widthClass"
                                *ngFor="let header of sheetHeader">
                                <div *ngIf="header != 'ResultMessage' && header != 'Result'" class="valueDiv"
                                    [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                                    {{ displayedColumns(item,header.toLowerCase()) | stripHtml }}</div>
                                <div *ngIf="header == 'ResultMessage' || header == 'Result'"
                                    [ngClass]="item['isvalid']==1 || item['result']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||['isvalid']==0 ? 'text-danger pl-1':null"
                                    class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)"
                                    #isElipsed>
                                    {{ displayedColumns(item,header.toLowerCase()) | stripHtml }}</div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-sm-12 nodata-header-wrap align-center" *ngIf="noDataFound">
                <span class="col-sm-12 nodata-wrapper"> No Data Available</span>
            </div>
        </div>
            <button mat-raised-button class="admin-btn-success mt-4 mr-2 " *ngIf="noDataFound" type="button"
            (click)="removegrid()">Back</button>

        <div class="col-sm-12 pl-0 mt-4">

            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0" (click)="removegrid()">Back</button>
                <mat-checkbox [color]="task.color" class="font-style  row align-center col-sm-2 m-0 p-0 ml-1" [checked]='checkStatus'
                ng-init='checkStatus' *ngIf="enableActive && gridWidth > 0" ng-model='checkStatus' (change)='checkValue($event)' 
                style="margin-left: 18.25rem !important;" [disabled]="tempExcelArr.length==0">
                Copy as Inactive
                </mat-checkbox>
            <span style="float: right;">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload && gridWidth > 0"
                    (click)="refreshGrid()">Cancel</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload"
                    (click)="refreshGrid()">Finish</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload && gridWidth > 0"
                    (click)="copyBulkInsert()" [disabled]="tempExcelArr.length==0">Approve</button>
                <button mat-raised-button class="admin-btn-success" *ngIf="postUpload"
                    (click)="ExportExcel(flexgrid)">Download Result</button>
            </span>
        </div>

    </form>
</div>