<!-- <mat-spinner class="labadmin-spinner" *ngIf="sourceApp=='VitalDx' && !tableGrid"
style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp=='VitalDx' && !tableGrid">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>

<div *ngIf="tableGrid" class="col-sm-12 m-0 p-0" #listwrap>
    <!-- <div class="modal-header admin-model-header header-color mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div *ngIf="CaseComments.toLowerCase() != 'organization'">{{headerName}}</div>
                <div *ngIf="CaseComments.toLowerCase() == 'organization'">{{headerName}}</div>
            </strong>
        </h3>
    </div> -->
    <!-- <form *ngIf="!ViewScreen" [formGroup]="frm_testForm" autocomplete="off"> -->
        <!-- <div *ngIf="!userFlag && UserList.length >0" class="button-field-wrap mt-2">
            <button mat-raised-button *ngIf="selectedOption == 'Group Case Comments'" class="admin-btn-success mr-2"
                (click)="openUserToggleDialog()" [disabled]="hideManageCaseCommentbtn">Manage Case Comments</button>
            <button mat-raised-button
                *ngIf="(!isAllUser) && (selectedOption != 'Group Case Comments') && (gridwidth == 0)  && migrateenable"
                [disabled]="hideMigratebtn" (click)="MigrateGLdata()" class="admin-btn-success mr-2"
                title="Migrate group level to User level">Migrate</button>
            <mat-form-field appearance="outline">
                <mat-label>
                    <strong>Case Comments</strong>
                </mat-label>
                <input type="text" #txtInput matInput [(ngModel)]="selectedOption" formControlName="frm_panel"
                    style="width: 94%;" title="frm_testForm.value" (input)="filterUsers(frm_testForm.value.frm_panel)"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option class="dropdown-opt-wrap" *ngIf="showOrgInDrop"
                        (onSelectionChange)="getOrgdata($event);" value="Group Case Comments">
                        <span>Group Case Comments</span>
                    </mat-option>
                    <mat-option class="dropdown-opt-wrap" (click)="UserGridData($event,'All User')" value="All Users">
                        <span>All Users</span>
                    </mat-option>
                    <mat-option class="dropdown-opt-wrap" *ngFor="let item of userNamesList"
                        (onSelectionChange)="UserGridData($event, item)" value="{{item.username}} ({{item.rolename}})"
                        title="{{item.username}} ({{item.rolename}})">
                        <span>{{item.username}} ({{item.rolename}})</span>
                    </mat-option>
                </mat-autocomplete>
                <button matSuffix mat-icon-button>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button *ngIf="selectedOption" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                    (click)="fnToggleFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div *ngIf="CaseComments == 'Organization' && UserList.length > 0"
            class="button-field-wrap p-0 m-0 row col-sm-12">
            <span class="row instr-size mb-2">
                <img aria-hidden="true" class="mb-1 mr-2" alt="help" src="/assets/icons/help.svg" width="15px"
                    height="auto" />
                You can select the group or user level templates to view the details in the Grid.</span>
        </div> -->

    <!-- </form> -->

    <!-- <div *ngIf="userFlag && !ViewScreen">
        <div class="row button-wrap align-center" >
            <button mat-raised-button *ngIf="gridwidth == 0  && migrateenable" [disabled]="hideMigratebtn"
            (click)="MigrateGLdata()" class="ManCommtBtn mt-3"
            title="Migrate group level to User level">Migrate</button>
            <mat-form-field appearance="outline" class="col-sm-3 mt-3">
                <mat-label><strong>Role</strong></mat-label>
                <mat-select disableOptionCentering [(ngModel)]="selectedRole">
                    <mat-option (onSelectionChange)="setUserRole($event,user)" value="{{user.RoleName}}"
                        *ngFor="let user of userRoles" class="dropdown-opt-wrap">
                        {{user.RoleName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> -->

        <div *ngIf="templateData && templateData.labels">
            <div class="container-fluid m-0 p-0">
                <!-- <wj-flex-grid [headersVisibility]="'Column'" [frozenColumns]="1" class="mt-2" [isReadOnly]="true"
                (initialized)="initGrid(grid)" [itemsSource]="gridData" #grid>
                <wj-flex-grid-column [header]="'Action'" [freeze]="true" align="center" [width]="120" [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <button class="edit-delete-btn" (click)="editCaseComments(row)" [disabled]="hideUpdateBtn"><em
                                id="editButton" [title]="'Action'" class="edit-template p-0 fa fa-edit"></em></button>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column *ngFor="let columnname of gridHeader" align="left" [binding]="columnname" [header]="columnname.split('_').join(' ')"
                    [visible]="!(columnname == 'Id') &&  !(columnname == 'Type') && !(columnname == 'Created_Date') && !(columnname == 'Created_Date')
                    && !(columnname == 'Created_By') && !(columnname == 'Organization_Id') && !(columnname == 'siteid')&& !(columnname == 'sitename')  && !(columnname == 'Site_Id') && !(columnname == 'Site_Name')  && !(columnname == 'User_Id') &&!(columnname == 'Modified_By') &&  !(columnname == 'Modified_Date') &&!(columnname == 'UserId')
                    && !((!userFlag && columnname == showColumns(userFlag)) || (userFlag && columnname == showColumns(userFlag))  )"
                    [allowDragging]="false" [allowSorting]="false" [width]="'*'" [allowResizing]="true"
                    [format]="'d'">
                </wj-flex-grid-column>
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid> -->
                <app-templates-listing *ngIf="templateData" [gridHeader]="listedgridheader" [gridData]="gridData"
                    [groupBy]="userFlag ? ['sitename', 'Case_Type']:['sitename']" [context]="'Case Comments'"
                    [hideEdit]="hideUpdateBtn" [hideCreate]="hideCreateBtn" [hideUpload]="sourceApp =='VitalDx' ? true : hideUploadbtn"
                    [hideExport]="hideExportBtn" [hideCopy]="sourceApp =='VitalDx' ? true : hideCopyToOrgBtn"
                    (closingListTemplate)="handleListingScreen($event)" (editTemplateClicked)="newEditMethod($event)"
                    [hideDelete]="deleteBtn" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
                    [showTopButtons]="true" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
                    (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
                    (closingListTemplate)="handleListingScreen($event)" (copyTemplateEventTriggered)="copyOrg()"
                    (uploadTemplateEventTriggered)="loadUploadScreen()" [isUserLevel]="userFlag" (emitFilters)="emitFilters($event)"
                    [hideManage]="sourceApp =='VitalDx' ? true : hideManageCaseCommentbtn" (manageEventTriggered)="openUserToggleDialog()"
                    [hideMigrate]="sourceApp =='VitalDx' ? true : hideMigratebtn" (migrateEventTriggered)="MigrateGLdata()" [enableMigrate]="migrateenable"
                    [caseTypeUserRoles]="userRoles" [selectedRole]="selectedRole" (roleChangedEventTriggered)="roleChanged($event)" [gridwidth]="gridwidth"
                    [isAdmin3]="sourceApp =='VitalDx'? false : true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
                    (sortingChanged)="refeshGridToGroupLevel()" (sortCompleted)="createActivityTrackerForSort($event)"
                    (deleteTemplateClicked)="deleteTemplate($event)" [searchBarWidth]="sourceApp =='VitalDx' ? 'col-sm-6' : null">
                </app-templates-listing>




                <!-- <div *ngIf="(orgLevelList.length>0 || UserList.length>0) && !ViewScreen"
                    class="col-sm-12 mt-2 align-center">
                    <div class="col-sm-4 button-align">
                        <button *ngIf="orgLevelList.length>0 || exportButtonFlag == true" (click)="ExportExcel(grid)"
                            mat-raised-button class="admin-btn-success" [disabled]="hideExportBtn">Export</button>

                        <button (click)="openCreateScreen()" *ngIf="userFlag" mat-raised-button
                            class="admin-btn-success" [disabled]="hideCreateBtn">Create</button>

                        <button mat-raised-button class="admin-btn-success" *ngIf="userFlag" tpe="button"
                            title="Copy from other entity" [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">
                            Copy
                        </button>

                        <button *ngIf="underorguserlevel==true" mat-raised-button class="admin-btn-success"
                            type="button" title="Copy from other entity" [disabled]="hideCopyToOrgBtn"
                            (click)="copyOrg()">
                            Copy
                        </button>

                        <button *ngIf="Edituserlevel==true && orgLevelList.length>0" (click)="openCreateScreen()"
                            mat-raised-button class="admin-btn-success" [disabled]="hideCreateBtn">Create</button>

                        <button *ngIf="Edituserlevel==true && orgLevelList.length>0" mat-raised-button
                            class="admin-btn-success" type="button" title="Upload" [disabled]="hideUploadbtn"
                            (click)="loadUploadScreen()">
                            Upload
                        </button>

                        <button *ngIf="Edituserlevel==true && orgLevelList.length>0" mat-raised-button
                            class="admin-btn-success" tpe="button" title="Copy from other entity"
                            [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">
                            Copy
                        </button>

                    </div>
                </div> -->
            </div>
        </div>

        <!-- <div *ngIf="gridwidth == 0" class="col-sm-12 mt-4">
            <div class="nodata-wrap mb-4 align-center">
                <div class="nodata-design">No Data Found</div>
            </div>

            <div class="col-12 align-center">
                <div class="col-4 button-align">
                    <button mat-raised-button class="admin-btn-success" [disabled]="hideCreateBtn"
                        (click)="openCreateScreen()">Create</button>
                    <button *ngIf="!hideUserCopyToOrg" mat-raised-button class="admin-btn-success" type="button"
                        title="Upload" [disabled]="hideUploadbtn" (click)="loadUploadScreen()">
                        Upload
                    </button>
                    <button mat-raised-button class="admin-btn-success" title="Copy from other entity" type="button"
                        [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">
                        Copy
                    </button>
                </div>
            </div>

        </div> -->
        <!-- </div> -->
    </div>

    <div class="modal-header admin-model-header header-color mt-3" *ngIf="noRole">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>User Level Case Comments</strong>
        </h3>
    </div>

    <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="noRole">
        <div class="col-sm-10 nodata-wrap">
            <div class="nodata-design">Eligible role not found for user</div>
        </div>
    </div>

    <!-- copy -->
    <div *ngIf="copyDataClicked" class="col-sm-12">
        <div class="modal-header admin-model-header header-color mb-2 mt-3 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong  class="header-View" *ngIf="CaseComments.toLowerCase() == 'organization'">Copy Group Level Case Comments</strong>
                <strong  class="header-View" *ngIf="CaseComments.toLowerCase() != 'organization'">Copy User Level Case Comments</strong>
            </h3>
        </div>
        <form [formGroup]="copycollectionMethodForm" (keydown.enter)="$event.preventDefault()" class="copy-collection">
            <div class="row col-sm-12 p-0 ml-0 mt-4">
                <div class="row col-sm-12 mt-2 align-center">
                    <mat-form-field class="col-sm-4 example-additional-selection mat-height-wrap" appearance="outline">
                        <mat-label>
                            <strong>
                                Deployment
                            </strong>
                        </mat-label>
                        <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                            <mat-option class="dropdown-opt-wrap" *ngFor="let i of DeploymentKeys" value="{{i}}"
                                (onSelectionChange)="onChangeDeployment($event,i)">
                                <span>{{ i }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>
                                Group
                            </strong>
                        </mat-label>
                        <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                            [disabled]="disableOrgSearch" #searchbar
                            (click)="fetchOrgSeries(searchInput)"
                            (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization); trimFieldValue('frmOrganization')"
                            [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                        <mat-autocomplete #orgauto="matAutocomplete">
                            <div *ngFor="let show of searchResult">
                                <mat-option class="mat-opt-align"
                                    *ngIf="show.organizationid.toString().includes(searchInput?.toString()) || show.organizationname?.toString().toLowerCase().includes(searchInput?.toString().toLowerCase())"
                                    (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                    value="{{ show.organizationname }}"
                                    title="{{ show.organizationname }} ({{show.organizationid}})">
                                    <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                        <button matSuffix mat-icon-button (click)="fetchOrgSeries(searchInput)">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <button *ngIf="copycollectionMethodForm.value.frmOrganization" matSuffix mat-icon-button
                            aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field class="col-sm-4 pr-0 example-additional-selection" appearance="outline">
                        <mat-label>
                            <strong>User</strong>
                        </mat-label>
                        <input type="text" matInput formControlName="frmUser" [(ngModel)]="searchUserInput"
                            #searchuserbar (keyup)="fetchUserSeries(searchuserbar.value); trimUserFieldValue('frmUser')" (click)="fetchUserSeries(searchUserInput)" [matAutocomplete]="userauto"
                            placeholder="Search here ..." style="width: 95%;">
                        <mat-autocomplete #userauto="matAutocomplete">
                            <div *ngFor="let user of searchuserlist">
                                <mat-option class="mat-opt-align"
                                    *ngIf="user.username.toString().toLowerCase().includes(searchUserInput?.toString().toLowerCase())"
                                    (onSelectionChange)="selectedCopyDataUser($event, user)" value="{{ user.username }}"
                                    title="{{ user.username }} ({{user.rolename}})">
                                    <span>{{ user.username }} ({{user.rolename}})</span>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                        <button matSuffix mat-icon-button (click)="fetchUserSeries(searchUserInput)">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <button *ngIf="copycollectionMethodForm.value.frmUser" matSuffix mat-icon-button
                            aria-label="Clear" title="Reset Search" (click)="clearUser()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                </div>
            </div>

            <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridWidth == 0">
                <div class="col-sm-10 nodata-wrap">
                    <div class="nodata-design">No Data Found</div>
                </div>
            </div>

            <div class="row align-center mt-2">
                <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
                    <button *ngIf="userFlag" mat-raised-button class="admin-btn-success button-color mr-4"
                        (click)="refreshUserGrid()">
                        Back
                    </button>
                    <button *ngIf="!userFlag" mat-raised-button class="admin-btn-success button-color mr-4"
                        (click)="refeshGridToGroupLevel()">
                        Back
                    </button>
                </div>
                <div class="dml-btn">
                    <button mat-raised-button class="admin-btn-success button-color" [disabled]="disableGetDataBtn() || postUpload"
                        (click)="getDataToCopy()">Get
                        Data</button>
                </div>
            </div>
        </form>
        <div *ngIf="gridWidth > 0">
            <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [isReadOnly]="true"
                [itemsSource]="gridData" style="margin-top: 17px;" (formatItem)="formatItem(flexgrid, $event)"
                [frozenColumns]="2">

                <div *ngFor="let columnname of sheetHeader">
                    <wj-flex-grid-column *ngIf="!userFlag" [binding]="columnname?.toString().toLowerCase()"
                        [header]="columnname" [width]="'*'" [allowResizing]="true" [allowDragging]="false"
                        [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="userFlag" [binding]="columnname?.toString().toLowerCase()"
                        [header]="columnname" [width]="'*'" [allowResizing]="true" [allowDragging]="false"
                        [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                </div>
                <!-- Status -->
                <wj-flex-grid-column [header]="'result'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                    [width]="120" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column>

                <!-- Notes -->
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>

            <div *ngIf="!postUpload" class="row pl-3 mt-2 mb-2 ml-5 align-center">
                <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0"
                    ng-init='checkStatus=false' [checked]='checkStatus' ng-model='checkStatus'
                    (change)='checkValue($event)' class="border-style">
                <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
            </div>
            <div class="row align-center mt-2">
                <div *ngIf="!backBtn" class="dml-btn mr-4">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success button-color mr-4"
                        (click)="ExportExcelCopyandUpload(flexgrid)">Download
                        Result</button>
                    <button *ngIf="userFlag && postUpload" mat-raised-button class="admin-btn-success button-color"
                        (click)="refreshUserGrid()">
                        Finish
                    </button>
                    <button *ngIf="!userFlag && postUpload" mat-raised-button class="admin-btn-success button-color"
                        (click)="refeshGridToGroupLevel()">
                        Finish
                    </button>
                </div>
                <div *ngIf="!postUpload" class="dml-btn">
                    <button mat-raised-button class="admin-btn-success button-color"
                        [disabled]="selectedItems.length==0" (click)="uploadCopyData(flexgrid)">Approve</button>
                </div>
            </div>
        </div>
    </div>

    <!-- old create and edit forms -->
    <!-- <div *ngIf="formFlagCaseComments">
        <div class="modal-header admin-model-header header-color mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <span>
                    <strong *ngIf="headerNames">
                        <div>{{actionHeader}}</div>
                    </strong>
                    <strong *ngIf="!headerNames">
                        <div>{{actionHeader}}</div>
                    </strong>
                </span>
            </h3>
        </div>
        <form [formGroup]="caseCommentData" class="mt-1 form-height">
            <div class="text-wrap">
                <div class="row col-sm-12 m-0 p-0">
                    <div class="col-sm-4 mt-3 pr-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>
                                <strong>Name</strong>
                            </mat-label>
                            <input title="{{ caseCommentData.value.name }}" formControlName="templateName" type="text"
                                maxlength="510" class="form-control form-font input-bg" matInput
                                aria-label="DisplayName" [matAutocomplete]="auto" [matAutocompleteDisabled]="!auto">
                        </mat-form-field>
                        <mat-error *ngIf="submitted && formcontrol.templateName.invalid">
                        <span class="col-sm-12 text-danger font-size">Name is required!</span>
                    </mat-error>
                    </div>

                    <div *ngIf="userFlag" class="col-sm-4 mt-3 pr-0 pl-0">
                        <mat-form-field appearance="outline" class="col-sm-12 p-0">
                            <mat-label><strong>Case Type</strong></mat-label>
                            <mat-select disableOptionCentering formControlName="caseType"
                                [disabled]=" actionHeader =='Edit User Level Case Comment'">
                                <mat-option value="" selected>Select</mat-option>
                                <mat-option class="mat-opt-align" (onSelectionChange)="onCaseTypeChange(cat.CaseType)"
                                    *ngFor="let cat of caseTypeList" value="{{ cat.CaseType }}">
                                    {{cat.CaseType}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted && formcontrol.caseType.invalid" class="mt-3">
                            <span class="text-danger font-size"> Case type is required!</span>
                        </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline" class="col-sm-4 mt-3">
                        <mat-label><strong>Site</strong></mat-label>
                        <mat-select disableOptionCentering formControlName="siteID">
                            <mat-option value="All">All</mat-option>
                            <mat-option class="mat-opt-align" *ngFor="let cat of sitesOfSelectedCaseType"
                                value="{{cat.siteid}}">
                                {{ cat.sitename }} ({{cat.siteid}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="col-sm-4 user-row pr-0 pl-0" *ngIf="!userFlag">
                        <div class="col-sm-12 text-xs p-0">
                            <div
                                *ngIf="actionHeader =='Create Group Level Case Comment' || actionHeader =='Create User Level Case Comment' ">
                                <mat-label class="label-font">
                                    <strong>User</strong>
                                </mat-label>
                                <ng-multiselect-dropdown #matSelectOption formControlName="userId"
                                    [placeholder]="'Select'" [settings]="dropdownSettings" [data]="userDropdownList2"
                                    (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll()">
                                </ng-multiselect-dropdown>
                            </div>

                            <mat-form-field class="col-sm-12 example-full-width pl-0 mt-3" appearance="outline"
                                *ngIf="actionHeader =='Edit Group Level Case Comment' || actionHeader == 'Edit User Level Case Comment' ">
                                <mat-label class="label-font">
                                    <strong>User</strong>
                                </mat-label>
                                <input formControlName="userId" matInput />
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-4 mt-4 pl-3">
                        <mat-checkbox (change)='onChange($event)' value="true" class="check-box-align"
                            formControlName="active" color='primary'>Active</mat-checkbox>
                    </div>
                </div>
                <div class="col-sm-12 mt-3 p-0">
                    <mat-form-field appearance="outline" class="col-sm-12 mb-2">
                        <mat-label>
                            <strong>Description</strong>
                        </mat-label>
                        <textarea matInput type="text" title="{{ caseCommentData.value.description }}"
                            formControlName="description" class='comments-area textarea-font' maxlength="2000"
                            style="height: 30dvh !important;min-height: unset !important;max-height: unset !important;"></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="submitted &&  caseCommentData.controls['description'].invalid" class=" ml-2">
                    <span class="col-sm-12 text-danger font-size"> Description is required!</span>
                </mat-error>
                </div>

            </div>

            <div class="sub-button mt-4">
                <div class="form-inline well well-lg">
                    <button mat-raised-button class="admin-btn-success button-color mr-4"
                        (click)="backFromFormPage()">Back</button>
                    <button mat-raised-button class="admin-btn-success button-color mr-4" *ngIf="!createFrmCaseComment"
                        (click)="updateCaseComments(caseCommentData.value)">Update</button>
                    <button mat-raised-button class="admin-btn-success button-color" *ngIf="createFrmCaseComment"
                        (click)="CreateCaseComments(caseCommentData.value)">Save</button>
                </div>
            </div>
        </form>
    </div> -->
    <!-- upload -->
    <div *ngIf="uploadClicked" class="col-sm-12 my-adequacy-form">
        <div class="modal-header admin-model-header header-color mb-2 mt-3 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong class="header-View" *ngIf="CaseComments.toLowerCase() == 'organization'">Upload Group Level Case Comments</strong>
                <strong  class="header-View" *ngIf="CaseComments.toLowerCase() != 'organization'">Upload User Level Case Comments</strong>

            </h3>
        </div>

        <div class="button-field-wrap m-1 p-0">
            <mat-form-field class="col-sm-3 example-additional-selection p-0 m-0" appearance="outline">
                <mat-label>
                    <strong>Templates</strong>
                </mat-label>
                <mat-select disableOptionCentering>
                    <mat-option (click)="downloadAllFields()" class="dropdown-opt-wrap">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                    </mat-option>
                    <mat-option (click)="downloadBulkUpdateField()" class="dropdown-opt-wrap">
                        <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                    </mat-option>
                </mat-select>
                <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            </mat-form-field>
        </div>

        <div class="button-field-wrap">
            <span class="instr-size mb-2">
                <img aria-hidden="true" class="mb-1 mr-1" alt="help" src="/assets/icons/help.svg" width="15px"
                    height="auto" />
                You can download the sample template file to upload the Case Comments.</span>
        </div>

        <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
            <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
                <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                    (change)="onFileChange($event)" hidden />
                <span class="align-center img-wrap">
                    <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
                </span>
                <span class="align-center">Drag & Drop excel files here</span>
                <span class="align-center">Or</span>
                <span class="align-center"><label
                        class="btn btn-sm rounded admin-btn-success admin-btn button-color mt-2 browse"
                        for="fileDropRef">Browse
                        file</label></span>
            </div>
            <button mat-raised-button class="admin-btn-success button-color mt-4" (click)="refeshGridToGroupLevel()">
                Back
            </button>
        </div>

        <div *ngIf="gridWidth > 0">
            <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1 (initialized)="initLocationGrid(grid1)"
                [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)" [frozenColumns]="2">

                <div *ngFor="let columnname of sheetHeader">
                    <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [width]="'*'"
                        [header]="columnname" [visible]="!(columnname == 'result')" [allowResizing]="true" [width]="'*'"
                        [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                </div>
                <!-- Status -->
                <wj-flex-grid-column [header]="'result'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                    [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column>
                <!-- Notes -->

                <!-- Action -->
                <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="140"
                    [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton"
                                [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>

            <div class="row pl-3 mt-4">
                <div class="col-sm-2 p-0">
                    <button mat-raised-button class="admin-btn-success button-color" (click)="removeGrid()">
                        Back
                    </button>
                </div>
                <div class="col-sm-10 button-field-wrap p-0">
                    <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                        <button mat-raised-button *ngIf="postDownload" class="admin-btn-success button-color mr-4"
                            (click)="refeshGridToGroupLevel()">Finish</button>
                        <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success button-color mr-4"
                            (click)="refeshGridToGroupLevel()">Cancel</button>
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success button-color mr-4"
                            [disabled]="disableApprovebtn()" (click)="uploadData()">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success button-color"
                            (click)="ExportExcelCopyandUpload(grid1)">Download
                            Result</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
            <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
        </div>
    </div>


    <!-- warning popup -->
    <div *ngIf="invalidCasetypeFlag" class="col-sm-12">
        <div class="modal overlay" id="myModal1" role="dialog">
            <div class="modal-dialog" id="modaldialog1">
                <div class="modal-content">
                    <div class="modal-header warning-model-header">
                        <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                        <span type="button" id="button_size" data-dismiss="modal" (click)="closeCasetypeModel()"
                            class="mr-2">
                            <img src=".../../../../../../assets/icons/Close_Icon.svg" alt="Close"
                                class="material-icons md-24 cursor" title="Close">
                        </span>
                    </div>
                    <div class="modal-body p-2 msg-wrap">
                        <div class="row col-sm-12 body-message">
                            <p id="deleteuser"> The Case Comments
                                cannot be copied as the casetype is not enabled in destination Location.
                                Please enable the casetype and then try copying<br>Failed for Casetype :
                                {{repeatedNames}}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-sm warning-model-header" (click)="closeCasetypeModel()"
                            type="button">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
