import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher, MatOption, ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-manage-sequence-wheel',
  templateUrl: './manage-sequence-wheel.component.html',
  styleUrls: ['./manage-sequence-wheel.component.scss']
})
export class ManageSequenceWheelComponent implements OnInit {

  codeList: any = [];
  dateList: any = [];
  task: Task = { color: 'primary' };
  allSelectedCodes = false;
  allSelectedCustomCodes = false;
  allSelectedCustomDates = false;
  actionTitle = "Sequence Scheme";
  allSelectedDates = false;
  allSelectedCasetypes = false;
  allSelectedService = false;
  allSelectedBillTo = false;
  addEditScreen = false;
  gridScreen = true;
  @ViewChild('matSelectOptionCodes') matSelectOptionCodes: MatSelect;
  @ViewChild('matSelectOptionCustomCodes') matSelectOptionCustomCodes: MatSelect;
  @ViewChild('matSelectOptionDate') matSelectOptionDate: MatSelect;
  @ViewChild('matSelectOptionCustomDate') matSelectOptionCustomDate: MatSelect;
  @ViewChild('matSelectOptionCasetype') matSelectOptionCasetype: MatSelect;
  @ViewChild('matSelectOptionBillTo') matSelectOptionBillTo: MatSelect;
  @ViewChild('matSelectOptionServiceType') matSelectOptionServiceType: MatSelect;

  @Input() templateData: any;
  public SubMenuCardModel;
  public deploymentKeys = [];
  casetypeList: any = [];
  billToList: any = [];
  serviceList: any = [];
  validateCasetype: boolean = false;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  cardClicked: any = [[false, false, false, false, false, false], [false, false, false, false, false, false]];
  gridArray: any[];
  showDefaultFormats: boolean = false;
  gridData: wjcCore.CollectionView<any>;
  destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
  searchContext = sessionStorage.getItem("search_context").toLowerCase();
  removable = true;
  destOrgid = sessionStorage.getItem('org_id').toUpperCase();
  standardFormat: any = [];
  rangeList: any = [];
  rangeLength: any = { frmDefaultFormat: 3, frmCustomFormat: 3 };
  finalArray: any = [];
  selectedrowData: any = {};
  editEnabled: boolean = false;
  editingItem: number | null = null; // To track the item being edited

  listGridheaders: { "Name": string; "type": string; "key": string; "colWidth": string; }[];
  private allCaseTypeObj = {
    accountid: null
    , AccountCasetypeID: null
    , casetype: "All Case Types"
    , IsActive: null
    , casetypemnemonic: null
    , organizationid: null
    , displayname: "All Case Types"
    , CaseType: "All Case Types"
    , CasetypeDisplayName: "All Case Types"
    , Case_Type: "All Case Types"
  }
  private preDefinedCodes = [
    { code: "%CT%", date: "", desc: "Case Type Mnemonic" },
    { code: "%LAM%", date: "", desc: "Lab Mnemonic" },
    { code: "%LM%", date: "", desc: "Location Mnemonic" },
    { code: "%OM%", date: "", desc: "Group Mnemonic" },
    { code: "%OFM%", date: "", desc: "Ordering Facility Mnemonic" },
    { code: "", date: "%YY%", desc: "YY" },
    { code: "", date: "%YYYY%", desc: "YYYY" },
    { code: "", date: "%MM%", desc: "MM" },
    { code: "", date: "%DD%", desc: "DD" }]
  hideCreateBtn: boolean = false;
  hideExportBtn: boolean = false;
  hideEditBtn: boolean = false;
  hideDeleteBtn: boolean = false;
  sequenceHideExportBtn: boolean = false;
  sequenceHideEditBtn: boolean = false;
  sequenceHideDeleteBtn: boolean = false;
  sequenceHideCreateBtn: boolean = false;
  configuredRunningNumbers: any = [];
  isLiveAccount: boolean = false;
  newstatus: boolean = false;
  ofId: any;
  ofList: any = [];
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  arrowIconSubject1 = new BehaviorSubject('arrow_drop_down1');
  standardTemplates: any;
  disableCustom: boolean;
  auditableColumns: any;
  ofName: any;
  groupBy: string[];
  selectedCaseType: string;
  tabList: any = ['Scheme Format', 'Sequences'];
  activeTab: number = 0;
  sequencesDataList = [];
  gridHeaderList: { templateName: string; headers: { Name: string; type: string; key: string; colWidth: string; }[]; }[];
  listGridHeader: { Name: string; type: string; key: string; colWidth: string; }[];
  listHeaderbackup: any;
  toolTipText: any;
  sequenceFilter: object = {}
  searchText: string = "";
  prevSequence: any;
  defaultChipList: any[];
  defaultRangeArray: any;
  customChipList: any[];
  customRangeArray: any;
  draggedIndex: number;
  caseProfileList: any[];
  orderCodeList: any[];
  isCasetypeSelected: boolean = false;
  entityType: any;
  entityId: any;
  accountCasetypeId: any;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: UntypedFormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    public activityService: ActivityTrackerService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.deploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  resultMaxLen = 25;
  matcher = new MyErrorStateMatcher();
  selected = new UntypedFormControl(0);
  seqFormGrp = this._fb.group({
    frmCasetype: ["", Validators.required],
    frmSerType: [""],
    frmBillto: [""],
    frmIsResearch: false,
    frmOrdFacility: [""],
    frmCaseprofile: [""],
    frmPanel: [""],
    frmDefaultFormat: this._fb.group({
      frmCodes: [[]],
      frmDate: [[]],
      frmDivision: "-",
      frmRange: ["", Validators.required],
      frmCustomCode: "",
      frmBeginsAt: "",
      frmOutput: ["", Validators.maxLength(this.resultMaxLen + 5)],
      frmScheme: "",
      frmSequenceName: "",
      frmOFCodes: ""
    }),
    frmCustomFormat: this._fb.group({
      frmCodes: [[]],
      frmDate: [[]],
      frmDivision: "-",
      frmRange: "",
      frmCustomCode: "",
      frmBeginsAt: "",
      frmOutput: ["", Validators.maxLength(this.resultMaxLen + 5)],
      frmScheme: "",
      frmSequenceName: ""
    })
  })

  seqTabFormGrp = this._fb.group({
    frmSequenceName: ["",Validators.required],
    frmRunningNumber: [""]
  })

  searchCasetype = new UntypedFormControl();
  searchBillTo = new UntypedFormControl();
  searchServices = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  filteredCaseProfileOptions: Observable<any[]>;
  filteredOrderCodeOptions: Observable<any[]>;
  filteredServiceOptions: Observable<any[]>;
  filteredBillToOptions: Observable<any[]>;
  disableMnemonic: boolean;
  @ViewChild('searchC') searchCTextBox: ElementRef;
  tabs = ['Scheme', 'Custom Scheme'];
  activityEntity: any;

  gridHeader = [
    { key: 'sequencetype', Name: 'Sequence Type', colWidth: '20%', dataType: "text", maxLength: 25 },
    { key: 'sequencename', Name: 'Scheme', colWidth: '30%', dataType: "text", maxLength: 25 },
    { key: 'runningnumber', Name: 'Running#', colWidth: '20%', dataType: "number", maxLength: 6 }
  ]

  async ngOnInit() {
    this.searchContext = sessionStorage.getItem("search_context").toLowerCase();
    this.getAuditableDetails(this.templateData.menuURL);
    if(this.activeTab == 0){
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    }
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    await this.populateCaseTypes(this.templateData.cardIdentifier);
    this.GetButtondetails();
    this.selectedCaseType = 'All Case Types';
    await this.addGridData(this.templateData.submenuData);

    this.filteredOptions = this.seqFormGrp.controls.frmCasetype.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.commonService.filterData(name, this.casetypeList.filter(x => x.Case_Type != 'All Case Types'), 'Case_Type'))
      );

    this.filteredCaseProfileOptions = this.seqFormGrp.controls.frmCaseprofile.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.commonService.filterData(name, this.caseProfileList, 'Display_name'))
      );

    this.filteredOrderCodeOptions = this.seqFormGrp.controls.frmPanel.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.commonService.filterData(name, this.orderCodeList, 'OrderCode'))
      );

    this.filteredServiceOptions = this.searchServices.valueChanges
      .pipe(
        startWith<string>(''),
        map(obj => this.filterServices(obj))
      );

    this.filteredBillToOptions = this.searchBillTo.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this.filterBillTo(name))
      );

    this.seqFormGrp.get('frmDefaultFormat.frmRange').valueChanges.subscribe(x => {
      if ((this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmBeginsAt.value &&
        Number(this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmBeginsAt.value) <= 1) ||
        !this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value) {
        this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
          frmBeginsAt: ""
        });
      }
    })

    this.seqFormGrp.get('frmCustomFormat.frmRange').valueChanges.subscribe(x => {
      if ((this.seqFormGrp.controls['frmCustomFormat']['controls'].frmBeginsAt.value &&
        Number(this.seqFormGrp.controls['frmCustomFormat']['controls'].frmBeginsAt.value) <= 1) ||
        !this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value) {
        this.seqFormGrp.controls['frmCustomFormat'].patchValue({
          frmBeginsAt: ""
        });
      }
    })

    merge.apply(null, [this.seqFormGrp.get('frmDefaultFormat.frmCodes').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmOFCodes').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmCustomCode').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmDivision').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmDate').valueChanges
    ]
    ).subscribe(() => {
      this.concatFormat('frmDefaultFormat');
      this.patchBeginsAt();
    });

    merge.apply(null, [this.seqFormGrp.get('frmDefaultFormat.frmRange').valueChanges,
    this.seqFormGrp.get('frmDefaultFormat.frmBeginsAt').valueChanges]).subscribe(() => {
      this.concatFormat('frmDefaultFormat');
    });

    merge.apply(null, [this.seqFormGrp.get('frmCustomFormat.frmCodes').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmCustomCode').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmDivision').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmDate').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmRange').valueChanges,
    this.seqFormGrp.get('frmCustomFormat.frmBeginsAt').valueChanges
    ]
    ).subscribe(() => {
      this.concatFormat('frmCustomFormat');
    });

    merge.apply(null, [this.seqFormGrp.get('frmCaseprofile').valueChanges,
    this.seqFormGrp.get('frmPanel').valueChanges
    ]
    ).subscribe(() => {
       if(this.entityType?.toLowerCase() == 'account casetype')
       {
        this.disableCustom = false;
       } else {
        this.disableCustom = true;
        this.selected.setValue(0);
       }
    });

    this.seqFormGrp.controls.frmOrdFacility.valueChanges.subscribe(x => {
      if (!this.seqFormGrp.controls.frmOrdFacility.value || !this.ofId) {
        this.ofId = null;
        this.ofName = "";
        if (!this.seqFormGrp.controls.frmBillto.value?.length && !this.seqFormGrp.controls.frmSerType.value?.length) {
          this.seqFormGrp.controls.frmBillto.clearValidators();
          this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
          this.seqFormGrp.controls.frmSerType.clearValidators();
          this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
        }
        this.filterTemplates();
      }
    })
  }

  clickDropdown(controlName: string, isMandatory: boolean, trigger: any)
  {
    if(!this.editEnabled)
    {
      if(!this.seqFormGrp.value[controlName]) {
        this.seqFormGrp.patchValue({
        [controlName]:""
        })
        if(isMandatory)
        {
          this.seqFormGrp.controls[controlName].setValidators([Validators.required]);
          this.seqFormGrp.controls[controlName].updateValueAndValidity();
        }
      }
      trigger.openPanel();
    } else {
      trigger.closePanel();
    }
  }

  filterSequence() {
    for (let i = 0; i < this.gridHeader?.length; i++) {
      this.sequenceFilter[this.gridHeader[i].key] = this.searchText
    }
  }

  clearSequenceSearch() {
    this.searchText = '';
    this.filterSequence();
  }

  toggleAllSelection(dtype, custom?: Boolean) {
    if (dtype == 'Codes') {
      if (custom) {
        if (this.allSelectedCustomCodes) {
          this.matSelectOptionCustomCodes.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionCustomCodes.options.forEach((item: MatOption) => item.deselect());
        }
      } else {
        if (this.allSelectedCodes) {
          this.matSelectOptionCodes.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionCodes.options.forEach((item: MatOption) => item.deselect());
        }
      }
    }
    else if (dtype === 'Date') {
      if (custom) {
        if (this.allSelectedCustomDates) {
          this.matSelectOptionCustomDate.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionCustomDate.options.forEach((item: MatOption) => item.deselect());
        }
      } else {
        if (this.allSelectedDates) {
          this.matSelectOptionDate.options.forEach((item: MatOption) => item.select());
        } else {
          this.matSelectOptionDate.options.forEach((item: MatOption) => item.deselect());
        }
      }
    }
    else if (dtype === 'Casetype') {
      if (this.allSelectedCasetypes) {
        this.matSelectOptionCasetype.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionCasetype.options.forEach((item: MatOption) => item.deselect());
      }
    }
    else if (dtype === 'Service') {
      if (this.allSelectedService) {
        this.matSelectOptionServiceType.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionServiceType.options.forEach((item: MatOption) => item.deselect());
      }
    } else if (dtype === 'BillTo') {
      if (this.allSelectedBillTo) {
        this.matSelectOptionBillTo.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionBillTo.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }

  sortFunc(a, b) {
    return 1;
  }

  enableToolTip(e) {
    this.toolTipText = e.scrollWidth > e.clientWidth ? e.textContent : '';
  }

  optionClick(dtype, custom?: Boolean, item?) {
    let grpName = custom ? "frmCustomFormat" : "frmDefaultFormat";
    if (dtype == 'Codes') {
      let newStatus = true;
      if (custom) {
        this.matSelectOptionCustomCodes.options.forEach((item: MatOption) => {
          if (!item.selected) {
            let itemIndex = this.customChipList.findIndex(x => x.name == item.value);
            newStatus = false;
            (itemIndex != -1) ? this.removeChips(this.customChipList[itemIndex],grpName,itemIndex) : null
          } else {
            this.addChip(item.value,grpName,'frmCodes');
          }
        });
        this.allSelectedCustomCodes = newStatus;
      } else {
        this.matSelectOptionCodes.options.forEach((item: MatOption) => {
          if (!item.selected) {
            let itemIndex = this.defaultChipList.findIndex(x => x.name == item.value);
            newStatus = false;
            (itemIndex != -1) ? this.removeChips(this.defaultChipList[itemIndex],grpName,itemIndex) : null
          } else {
            this.addChip(item.value,grpName,'frmCodes');
          }
        });
        if(this.matSelectOptionCodes.options.some((item: MatOption) => (item.selected && item.value?.toString().toLowerCase() == 'ordering facility mnemonic')))
        {
          this.seqFormGrp.controls[grpName]['controls']['frmBeginsAt'].disable();
        } else {
          this.seqFormGrp.controls[grpName]['controls']['frmBeginsAt'].enable();
        }
        this.allSelectedCodes = newStatus;
      }
      this.seqFormGrp['controls'][grpName]['controls']['frmCodes'].updateValueAndValidity();
    }
    else if (dtype === 'Date') {
      let newStatus1 = true;
      if (custom) {
        this.matSelectOptionCustomDate.options.forEach((item: MatOption) => {
          if (!item.selected) {
            let itemIndex = this.customChipList.findIndex(x => x.name == item.value);
            newStatus1 = false;
            (itemIndex != -1) ? this.removeChips(this.customChipList[itemIndex],grpName,itemIndex) : null
          } else {
            this.addChip(item.value,grpName,'frmDate');
          }
        });
        this.allSelectedCustomDates = newStatus1;
      } else {
        this.matSelectOptionDate.options.forEach((item: MatOption) => {
          if (!item.selected) {
            let itemIndex = this.defaultChipList.findIndex(x => x.name == item.value);
            newStatus1 = false;
            (itemIndex != -1) ? this.removeChips(this.defaultChipList[itemIndex],grpName,itemIndex) : null
          } else {
            this.addChip(item.value,grpName,'frmDate');
          }
        });
        this.allSelectedDates = newStatus1;
      }
      this.seqFormGrp['controls'][grpName]['controls']['frmDate'].updateValueAndValidity();
    }
    else if (dtype === 'BillTo') {
      let newStatus1 = true;
      this.matSelectOptionBillTo.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus1 = false;
        }
      });
      this.disableCustom ? null : this.resetError();
      this.allSelectedBillTo = newStatus1;
    }
    else if (dtype === 'Service') {
      let newStatus1 = true;
      this.matSelectOptionServiceType.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus1 = false;
        }
      });
      this.disableCustom ? null : this.resetError();
      this.allSelectedService = newStatus1;
    }
    else if (dtype === 'Casetype') {
      this.getCaseProfiles(item.casetype);
      this.getOrderCodes(item.casetype);
      this.isCasetypeSelected = true;
      this.entityId = item.AccountCasetypeID;
      this.accountCasetypeId = item.AccountCasetypeID;
      this.entityType = 'Account Casetype'
      if(this.defaultChipList.length || this.customChipList.length)
      {
        let groupName = this.defaultChipList.some(x => x.name == 'Case Type Mnemonic') ? 'frmDefaultFormat' : null;
        groupName ? this.getCasetypeMnemonic(groupName) : null;
        groupName = this.customChipList.some(x => x.name == 'Case Type Mnemonic') ? 'frmCustomFormat' : null;
        groupName ? this.getCasetypeMnemonic(groupName) : null;
      }
    } else if (dtype === 'Caseprofile') {
      this.clearSearch(null, 'Panel')
      this.entityId = item.CaseProfileId;
      this.entityType = 'Case Profile';
      this.disableCustom = true;
      this.selected.setValue(0);
    } else if (dtype === 'Panel') {
      this.clearSearch(null, 'Caseprofile')
      this.entityId = item.OrderCodeID;
      this.entityType = 'Order Codes';
      this.disableCustom = true;
      this.selected.setValue(0);
    }
    else if (dtype === 'Range') {
      this.rangeLength[grpName] = this.seqFormGrp.controls[grpName]['controls'].frmRange.value?.toString().split('-')[0].length;
      this.addChip(this.seqFormGrp.controls[grpName]['controls'].frmRange.value,grpName,'frmRange');
    }
  }

  resetError() {
    if (!(this.matSelectOptionServiceType.options.some((item: MatOption) => item.selected)) && !(this.matSelectOptionBillTo.options.some((item: MatOption) => item.selected))) {
      this.seqFormGrp['controls']["frmSerType"].setValidators([]);
      this.seqFormGrp['controls']["frmSerType"].updateValueAndValidity()
      this.seqFormGrp['controls']["frmBillto"].setValidators([]);
      this.seqFormGrp['controls']["frmBillto"].updateValueAndValidity()
    }
  }

  async getCaseProfiles(casetype: string) {
    let queryVariable = { orgid: this.templateData.secondarykeys.OrganizationId?.toString(), accountid: this.templateData.cardIdentifier?.toString(), casetype: casetype, status: "true" };
    let query = this.SubMenuCardModel.GetQuery("managecaseprofile");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(data => {
      if (!data.errors) {
        this.caseProfileList = [];
        this.caseProfileList = data.data.submenuData;
      }
    }, error => {
      console.error(error);
    });
  }

  async getOrderCodes(casetype: string) {
    let queryVariable = { orgid: this.templateData.secondarykeys.OrganizationId.toString(), casetype: casetype, isOrderable: "true", status: "true" };
    let query = this.SubMenuCardModel.GetQuery("getcasetypelevelordercodes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(data => {
      if (!data.errors) {
        this.orderCodeList = [];
        this.orderCodeList = data.data.submenuData;
      }
    }, error => {
      console.error(error);
    });
  }

  async getLookupValues() {
    await this.populateCaseTypes(this.templateData.cardIdentifier);

    let query1 = this.SubMenuCardModel.GetQuery('getidentityformatsentities');
    let queryVariable1 = { "accid": this.templateData.cardIdentifier.toString(), "orgid": sessionStorage.getItem('org_id').toString() };
    let queryResult1 = this.commonService.GetCardRequest(queryVariable1, query1);
    await this.VitalHttpServices.GetData(queryResult1).toPromise().then(async res1 => {
      await this.getSampleDataValues();
      const uniqueServices = new Set();
      const uniqueBillTo = new Set();
      let orgLevelBillToData = res1.data.submenuDataOrgAttributes.filter(res => res.AttributeType.toLowerCase() == 'billto');
      let globalData = res1.data.submenuDataOrgAttributesGlobal.filter(res => res.AttributeType.toLowerCase() == 'billto');
      let temp = [...res1.data.submenuDataOrgAttributes, ...res1.data.submenuDataOrgAttributesGlobal]
      let serviceArray = (temp.length > 0) ? temp.filter(res => res.AttributeType.toLowerCase() == 'services') : [];
      this.ofList = res1.data.submenuDataOFOrgs;
      this.configuredRunningNumbers = res1.data.submenuDataSequences;
      serviceArray.forEach(element => {
        if(element.AttributeName)
        {
          const trimmedService = element.AttributeName?.toString().trim();
          uniqueServices.add(trimmedService)
        }
      })
      this.serviceList = Array.from(uniqueServices);
      let billToArray = (orgLevelBillToData && orgLevelBillToData.length > 0) ? orgLevelBillToData :
        (globalData && globalData.length > 0) ? globalData : [];
      billToArray.forEach(element => {
        if (element.AttributeName && element.AttributeName.toString().toLowerCase().trim() != 'select' && element.AttributeName.toString().toLowerCase().trim() != '-- select --') {
          const trimmedBillTo = element.AttributeName.toString().trim();
          uniqueBillTo.add(trimmedBillTo)
        }
      })
      this.billToList = Array.from(uniqueBillTo);
    }, error => {
      console.error(error);
      this._snackbar.open("An error occurred while processiong your request", "Failed");
    });
  }

  filterCasetype(value) {
    if (!value || value == '') {
      return this.casetypeList.slice(0, 25);
    }
    const val = value;

    let tempArray = [...this.casetypeList]
    if (tempArray && tempArray.length > 0) {
      return tempArray.filter(va => va.Case_Type && va.Case_Type.toString().toLowerCase().includes(val.toString().toLowerCase())).slice(0, 25);
    } else {
      return [];
    }
  }

  clearSearch(event, dtype) {
    event ? event.stopPropagation() : null;
    if (dtype === 'Casetype') {
      this.searchCasetype.patchValue('');
      this.seqFormGrp.patchValue({
        frmCasetype: ''
      })
      this.caseProfileList = [];
      this.orderCodeList = [];
      this.clearSearch(null, 'Panel');
      this.clearSearch(null, 'Caseprofile');
      this.isCasetypeSelected = false;
      this.entityType = '';
      this.entityId = '';
      if(this.defaultChipList.length || this.customChipList.length)
      {
        let groupName = this.defaultChipList.some(x => x.name == 'Case Type Mnemonic') ? 'frmDefaultFormat' : null;
        groupName ? this.getCasetypeMnemonic(groupName) : null;
        groupName = this.customChipList.some(x => x.name == 'Case Type Mnemonic') ? 'frmCustomFormat' : null;
        groupName ? this.getCasetypeMnemonic(groupName) : null;
      }
    }
    else if (dtype == 'OF') {
      this.seqFormGrp.patchValue({
        frmOrdFacility: ''
      })
      this.seqFormGrp.controls.frmDefaultFormat.patchValue({
        frmCodes: [],
      });
      this.filterTemplates();
    }
    else if (dtype === 'Service') {
      this.searchServices.patchValue('');
    } else if (dtype === 'BillTo') {
      this.searchBillTo.patchValue('');
    } else if (dtype === 'Panel') {
      this.entityType = 'Account Casetype'
      this.entityId = this.accountCasetypeId
      this.seqFormGrp.patchValue({
        frmPanel: ''
      })
      this.seqFormGrp.controls.frmPanel.updateValueAndValidity();
    } else if (dtype === 'Caseprofile') {
      this.entityType = 'Account Casetype'
      this.entityId = this.accountCasetypeId
      this.seqFormGrp.patchValue({
        frmCaseprofile: ''
      })
      this.seqFormGrp.controls.frmCaseprofile.updateValueAndValidity();
    }
  }

  getCasetypeMnemonic(groupName:string)
  {
    let tempChipList = groupName == 'frmDefaultFormat' ? this.defaultChipList : this.customChipList;
    let defaultCaseTypeObj = this.getCodes('Case Type Mnemonic', groupName);
    
    tempChipList.forEach(element => {
      if(element.controlName == 'frmCodes')
      {
        element.derivedValue = defaultCaseTypeObj['derivedCode'];
        element.orginalValue = defaultCaseTypeObj['originalCode'];
      }
    });
    if(groupName == 'frmDefaultFormat')
    {
      this.defaultChipList = tempChipList;
    } else {
      this.customChipList = tempChipList;
    }
    this.formatDraggedItem(groupName);
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchServices.patchValue('');
    this.searchBillTo.patchValue('');
    this.searchCasetype.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchCTextBox.nativeElement.focus();
    }
  }

  openOrClosePanel(evt: any, control:string, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else{
      this.clickDropdown(control,false,trigger);
    }
  }

  async concatFormat(grpname) {
    const excludedObj = (grpname == 'frmDefaultFormat') ? ((this.defaultChipList && this.defaultChipList.length > 0) ? this.defaultChipList.filter(x => x.controlName == 'frmCustomCode' || x.controlName == 'frmDivision') : [])
      : ((this.customChipList && this.customChipList.length > 0) ? this.customChipList.filter(x => x.controlName == 'frmCustomCode' || x.controlName == 'frmDivision') : []);

    const indexes = (grpname == 'frmDefaultFormat') ? ((this.defaultChipList && this.defaultChipList.length > 0) ? this.defaultChipList
      .map((x, index) => (x.controlName === 'frmCustomCode' || x.controlName === 'frmDivision' ? index : -1))
      .filter(index => index !== -1) : [])
      : ((this.customChipList && this.customChipList.length > 0) ? this.customChipList
        .map((x, index) => (x.controlName === 'frmCustomCode' || x.controlName === 'frmDivision' ? index : -1))
        .filter(index => index !== -1) : []);

    // let beginsAt = this.seqFormGrp.controls[grpname]['controls'].frmBeginsAt.value;

    // let headers = ["frmCodes", "frmDate", "frmRange"];
    let tempChipList = (grpname == 'frmDefaultFormat') ? ((this.defaultChipList && this.defaultChipList.length > 0) ? this.defaultChipList.filter(x => x.controlName != 'frmCustomCode' && x.controlName != 'frmDivision') : [])
    : ((this.customChipList && this.customChipList.length > 0) ? this.customChipList.filter(x => x.controlName != 'frmCustomCode' && x.controlName != 'frmDivision') : []);
    // let tempRangeArray = [];
    // headers.forEach(element => {
    //   if (this.seqFormGrp.controls[grpname]['controls'][element]?.value?.length > 0) {
    //     switch (element) {
    //       case 'frmRange':
    //         let obj = this.getRange(grpname, this.seqFormGrp.controls[grpname]['controls'].frmRange.value, beginsAt);
    //         if (obj['derivedCode']) {
    //           tempRangeArray.push({ controlName: element, name: "Range", derivedValue: obj['derivedCode'], orginalValue: obj['originalCode'] });
    //         }
    //         break;
    //       case 'frmCodes':
    //         this.seqFormGrp.controls[grpname]['controls'][element]?.value.forEach(e => {
    //           let obj = this.getCodes(e, grpname);
    //           tempChipList.push({ controlName: element, name: e, derivedValue: obj['derivedCode'], orginalValue: obj['originalCode'] });
    //         });
    //         break;
    //       case 'frmDate':
    //         this.seqFormGrp.controls[grpname]['controls'][element]?.value.forEach(e => {
    //           const date = this.getDates(e);
    //           tempChipList.push({ controlName: element, name: e, derivedValue: date, orginalValue: "%" + e + "%" });
    //         });
    //         break;
    //     }
    //   }
    // });

    indexes.forEach((index, i) => {
      if (index < tempChipList.length) {
        const currentObj = tempChipList[index];
        tempChipList[index] = excludedObj[i] || null;
        if (currentObj !== undefined) {
          // Move existing objects to the next index
          for (let j = tempChipList.length; j > index + 1; j--) {
            tempChipList[j] = tempChipList[j - 1];
          }
          // Place the existing object at the next index
          tempChipList[index + 1] = currentObj;
        }
      } else {
        tempChipList.push(excludedObj[i]);
      }
    });
    if (grpname == 'frmDefaultFormat') {
      // this.defaultRangeArray = [...tempRangeArray];
      this.defaultChipList = [...tempChipList];
    } else {
      // this.customRangeArray = [...tempRangeArray];
      this.customChipList = [...tempChipList];
    }
    this.formatDraggedItem(grpname);
  }

  formatDraggedItem(groupName: string) {
    let outputString = '', formatString = '', sequenceNameString = '';
    const tempChipList = (groupName == 'frmDefaultFormat') ? this.defaultChipList : this.customChipList;
    const tempRangeArray = (groupName == 'frmDefaultFormat') ? this.defaultRangeArray : this.customRangeArray;

    tempChipList.forEach(element => {
      switch (element.controlName) {
        case 'frmCodes':
          outputString += element.derivedValue.toString();
          sequenceNameString += element.derivedValue;
          formatString += element.orginalValue.toString();
          break;
        case 'frmDate':
          outputString += element.derivedValue.toString();
          sequenceNameString += element.derivedValue;
          formatString += element.orginalValue.toString();
          break;
        case 'frmRange':
          outputString += element.derivedValue.toString();
          formatString += element.orginalValue.toString();
          break;
        case 'frmDivision':
          outputString += element.derivedValue;
          sequenceNameString += element.derivedValue;
          formatString += element.orginalValue.toString();
          break;
        case 'frmCustomCode':
          outputString += element.derivedValue;
          sequenceNameString += element.derivedValue;
          formatString += element.orginalValue.toString();
          break;
      }
    });

    outputString += (tempRangeArray?.length > 0) ? this.seqFormGrp.controls[groupName]['controls']['frmBeginsAt'].value : '';
    formatString += (tempRangeArray?.length > 0) ? tempRangeArray[0].orginalValue : '';
    let index = this.standardFormat.findIndex(x => x.format == formatString || x.customformat == formatString)
    this.cardClicked[this.selected.value] = [false, false, false, false, false, false];
    if (index != -1) {
      this.cardClicked[this.selected.value][index] = true;
    }
    this.seqFormGrp.controls[groupName].patchValue({
      frmOutput: outputString,
      frmScheme: formatString,
      frmSequenceName: sequenceNameString
    }, { onlySelf: true, emitEvent: false });
  }

  getCodes(code: string, grpname?: string) {
    let resultObj = { originalCode: "", derivedCode: "" }
    switch (code) {
      case 'Group Mnemonic': if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
        resultObj.derivedCode = this.templateData.submenuData[0].orgmnemonic ? this.templateData.submenuData[0].orgmnemonic : '';
      }
        resultObj.originalCode += '%OM%'
        break;
      case 'Ordering Facility Mnemonic': resultObj.originalCode += '%OFM%';
        resultObj.derivedCode += 'OFM'; break;
      case 'Lab Mnemonic': resultObj.originalCode += '%LAM%';
        resultObj.derivedCode += (this.datashare.accountMnemonic ? this.datashare.accountMnemonic : ''); break;
      case 'Location Mnemonic': resultObj.originalCode += '%LM%';
        resultObj.derivedCode += (this.datashare.accountMnemonic ? this.datashare.accountMnemonic : ''); break;
      case 'Case Type Mnemonic':
        resultObj.originalCode += '%CT%';
        this.casetypeList.find(element => {
          if (this.seqFormGrp.controls.frmCasetype.value
            && element.Case_Type
            && element.Case_Type.toString().toLowerCase().trim() == this.seqFormGrp.controls.frmCasetype.value.toString().toLowerCase().trim()) {
            resultObj.derivedCode += (element.casetypemnemonic ? element.casetypemnemonic : '');
          }
        });
        break;
    }
    return resultObj;
  }

  getDates(date: string) {
    const d = new Date();
    let result = '';
    switch (date) {
      case 'YY':
        let yy = d.getFullYear();
        result = yy.toString().substring(2, 4); break;
      case 'MM': let month = d.getMonth();
        result = ((month + 1).toString().length > 1) ? (month + 1).toString() : '0' + ((month + 1).toString()); break;
      case 'DD': let day = d.getDate();
        result = (day.toString().length > 1) ? day.toString() : '0' + (day.toString()); break;
      case 'YYYY':
        let year = d.getFullYear();
        result = year.toString(); break;
    }
    return result;
  }

  getRange(grpname: string, range: string, beginsAt: string) {
    let resultObj = { originalCode: "", derivedCode: "" }
    switch (range) {
      case '001-999': resultObj.derivedCode = range;
        resultObj.originalCode += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
        break;
      case '0001-9999': resultObj.derivedCode = range;
        resultObj.originalCode += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
        break;
      case '00001-99999': resultObj.derivedCode = range;
        resultObj.originalCode += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
        break;
      case '000001-999999': resultObj.derivedCode = range;
        resultObj.originalCode += "%" + this.rangeLength[grpname].toString() + this.getFormatType(grpname);
        break;
    }
    return resultObj;
  }

  getFormatType(groupname) {
    if ((this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmCustomCode.value ||
      this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmBeginsAt.value ||
      this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmOFCodes.value ||
      (this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmCodes.value?.length > 0) ||
      (this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmDate.value?.length > 0) ||
      this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value) &&

      (this.seqFormGrp.controls['frmCustomFormat']['controls'].frmCustomCode.value ||
        this.seqFormGrp.controls['frmCustomFormat']['controls'].frmBeginsAt.value ||
        (this.seqFormGrp.controls['frmCustomFormat']['controls'].frmCodes.value?.length > 0) ||
        (this.seqFormGrp.controls['frmCustomFormat']['controls'].frmDate.value?.length > 0) ||
        this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value)) {
      return (groupname == 'frmDefaultFormat') ? 'NN%' : 'US%'
    } else {
      return (groupname == 'frmDefaultFormat') ? 'NN%' : 'US%'
    }
  }

  addChip(itemVal, groupname, controlName) {
    let tempChipList = structuredClone((groupname == 'frmDefaultFormat') ? this.defaultChipList : this.customChipList);
    let tempRangeArray = structuredClone((groupname == 'frmDefaultFormat') ? this.defaultRangeArray : this.customRangeArray);
    let obj;
    switch(controlName)
    {
      case 'frmCustomCode':
        case 'frmDivision':
        let inputValue = this.seqFormGrp.controls[groupname].get(controlName)?.value.trim();
        if (inputValue) {
          let custVal = (controlName == 'frmCustomCode') ? 'Custom Code: ' : '';
            tempChipList.push({ controlName: controlName, name: (custVal + inputValue), derivedValue: inputValue, orginalValue: inputValue });
          (controlName == 'frmCustomCode') ? this.seqFormGrp.controls[groupname].get(controlName)?.setValue('') : null; // Clear the input
        }
      break;
      case 'frmRange':
        obj = this.getRange(groupname, this.seqFormGrp.controls[groupname]['controls'].frmRange.value, this.seqFormGrp.controls[groupname]['controls'].frmBeginsAt.value);
        let countVal = this.counting(this.seqFormGrp.controls[groupname]['controls'].frmBeginsAt.value, this.seqFormGrp.controls[groupname]['controls'].frmRange.value, groupname)
        this.seqFormGrp.controls[groupname].patchValue({
          frmBeginsAt: countVal
        })
        if(obj && obj['derivedCode'])
        {
          if (!tempRangeArray || tempRangeArray?.length == 0) {
            tempRangeArray = [];
            tempRangeArray.push({ 
              controlName: controlName, 
              name: "Range", 
              derivedValue: obj['derivedCode'], 
              orginalValue: obj['originalCode']
            });
          } else {
            tempRangeArray[0].derivedValue = obj['derivedCode'];
            tempRangeArray[0].orginalValue = obj['originalCode'];
          }
        }
        break;
      case 'frmCodes':
        obj = this.getCodes(itemVal, groupname);
        const existingChipIndex = tempChipList.findIndex((x) => x.name?.toString().toLowerCase() === itemVal?.toString().toLowerCase() && 
          x.controlName === controlName
        );
        let countValue = this.counting(this.seqFormGrp.controls[groupname]['controls'].frmBeginsAt.value, this.seqFormGrp.controls[groupname]['controls'].frmRange.value, groupname)
        this.seqFormGrp.controls[groupname].patchValue({
          frmBeginsAt: countValue
        })
        if (existingChipIndex == -1) {
          tempChipList.push({ 
            controlName: controlName, 
            name: itemVal, 
            derivedValue: obj['derivedCode'], 
            orginalValue: obj['originalCode']
          });
        } else {
          tempChipList[existingChipIndex].derivedValue = obj['derivedCode'];
          tempChipList[existingChipIndex].orginalValue = obj['originalCode'];
        }
        break;
      case 'frmDate':
          const date = this.getDates(itemVal);
          const existingDateIndex = tempChipList.findIndex((x) => x.name?.toString().toLowerCase() === itemVal?.toString().toLowerCase() && 
          x.controlName === controlName
        );
        
        if (existingDateIndex == -1) {
          tempChipList.push({ 
            controlName: controlName, 
            name: itemVal, 
            derivedValue: date, 
            orginalValue: "%" + itemVal + "%"
          });
        } else {
          tempChipList[existingDateIndex].derivedValue = date;
          tempChipList[existingDateIndex].orginalValue = "%" + itemVal + "%";
        }
        break;
    }
    if (groupname == 'frmDefaultFormat') {
      this.defaultRangeArray = tempRangeArray || [];
      this.defaultChipList = tempChipList || [];
    } else {
      this.customRangeArray = tempRangeArray || [];
      this.customChipList = tempChipList || [];
    }
    if(controlName == 'frmCustomCode' || controlName == 'frmDivision')
    {
      this.formatDraggedItem(groupname);
    }
  }

  onInputBlur(groupname, controlName) {
    this.addChip('',groupname, controlName);
  }

  removeChips(item, grpname, index) {
    if (item.controlName == 'frmRange') {
      if (grpname == 'frmDefaultFormat') {
        this.defaultRangeArray = this.defaultRangeArray.filter(x => x.name != item.name)
      } else {
        this.customRangeArray = this.customRangeArray.filter(x => x.name != item.name)
      }
      this.seqFormGrp.controls[grpname].get(item.controlName)?.setValue("");
    } else {
      if (grpname == 'frmDefaultFormat') {
        this.defaultChipList.splice(index, 1);
      } else {
        this.customChipList.splice(index, 1);
      }
    }
    this.formatDraggedItem(grpname);
    if (item.controlName == 'frmCodes' || item.controlName == 'frmDate') {
      let val = this.seqFormGrp.controls[grpname]['controls'][item.controlName].value.filter(x => x != item.name);
      this.seqFormGrp.controls[grpname].get(item.controlName)?.setValue(val);
      this.optionClick(item.controlName == 'frmCodes' ? 'Codes' : 'Date', false);
    }
    this.seqFormGrp['controls'][grpname]['controls'][item.controlName].updateValueAndValidity();
  }

  counting(number: string, range, grpname) {
    try {
      number = !number ? '' : number;
      let codeValue = this.seqFormGrp.controls[grpname]['controls']['frmCodes'].value;
      let isOFMExists = (codeValue && codeValue?.length) ? (codeValue.some(x => x.includes('Facility'))) : false;
      let num = (((/^0+$/.test(number)) || !number) && !isOFMExists) ? '1' : (isOFMExists ? '0' : number);
      let numberLength = num?.length;
      this.rangeLength[grpname] = range?.toString().split('-')[0].length;
      let difference = this.rangeLength[grpname] - numberLength;
      num = ('0'.repeat(difference) + num)
      return String(num);
    } catch (e) {
      console.error(e);
    }
  }

  outputClass() {
    let grpName = (this.selected.value == 0) ? 'frmDefaultFormat' : 'frmCustomFormat'
    return !this.seqFormGrp.controls[grpName]['controls'].frmOutput.value ? 'empty-style' : 'valid-style'
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  async onClickCard(i, data) {
    if (this.cardClicked[this.selected.value][i]) { //De-select the template
      this.cardClicked[this.selected.value][i] = false;
      this.resetForm(this.selected.value == 0 ? true : false, this.selected.value == 1 ? true : false);
    } else { //Select the template
      this.cardClicked[this.selected.value] = [false, false, false, false, false, false];
      this.cardClicked[this.selected.value][i] = true;

      if (this.selected.value == 0) {
        this.defaultChipList = [];
        this.seqFormGrp.controls.frmDefaultFormat.patchValue({
          frmCodes: (data.Description.toString().includes('Facility') ? ['Ordering Facility Mnemonic'] : data.Description.toString().includes('Lab') ? ['Lab Mnemonic'] : ['Case Type Mnemonic']),
          frmDate: data.Description.toString().includes('YYYY') ? ['YYYY'] : data.Description.toString().includes('MM') ? ['MM'] : ['YY'],
          frmRange: '00001-99999',
          frmCustomCode: '',
          frmDivision: '-',
          frmBeginsAt: '',
          // frmOFCodes: ""
        })
        this.rangeLength['frmDefaultFormat'] = this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmRange.value?.toString().split('-')[0].length;
        Object.keys(this.seqFormGrp.controls.frmDefaultFormat['controls']).forEach(key => {
          let itemVal = (key == 'frmCodes' || key == 'frmDate') ? this.seqFormGrp.controls.frmDefaultFormat['controls'][key].value[0] : ''
          this.addChip(itemVal,'frmDefaultFormat', key);
        });
      } else {
        this.customChipList = [];
        this.seqFormGrp.controls.frmCustomFormat.patchValue({
          frmCodes: data.Description.toString().includes('Facility') ? ['Ordering Facility Mnemonic'] : data.Description.toString().includes('Lab') ? ['Lab Mnemonic'] : ['Case Type Mnemonic'],
          frmDate: data.Description.toString().includes('YYYY') ? ['YYYY'] : data.Description.toString().includes('MM') ? ['MM'] : ['YY'],
          frmRange: '00001-99999',
          frmCustomCode: '',
          frmDivision: '-',
          frmBeginsAt: '',
        })
        this.rangeLength['frmCustomFormat'] = this.seqFormGrp.controls['frmCustomFormat']['controls'].frmRange.value?.toString().split('-')[0].length;
        Object.keys(this.seqFormGrp.controls.frmCustomFormat['controls']).forEach(key => {
          let itemVal = (key == 'frmCodes' || key == 'frmDate') ? this.seqFormGrp.controls.frmCustomFormat['controls'][key].value[0] : ''
          this.addChip(itemVal,'frmCustomFormat', key);
        });
      }
      this.patchBeginsAt();
    }
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      let index = -1, index1 = -1
      index = e._p._cols.findIndex(va => va && va._binding && va._binding._path && va._binding._path == 'dformat')
      index1 = e._p._cols.findIndex(va => va && va._binding && va._binding._path && va._binding._path == 'dalternateformat')
      if (index != -1 && index == e.col) {
        let defaultData = e._p._rows[e.row]._data['format'];
        defaultData = defaultData.split(',')
        defaultData = defaultData.filter(va => va && va.toString().toLowerCase().trim() != e._p._rows[e.row]._data['dformat'].toString().toLowerCase().trim())
        defaultData = defaultData.join(',');
        tt.setTooltip(e.cell, `${defaultData}`);
      } else if (index1 != -1 && index1 == e.col) {
        let customData = e._p._rows[e.row]._data['alternateformat'];
        customData = customData.split(',')
        customData = customData.filter(va => va && va.toString().toLowerCase().trim() != e._p._rows[e.row]._data['dalternateformat'].toString().toLowerCase().trim())
        customData = customData.join(',');
        tt.setTooltip(e.cell, `${customData}`);
      }
      else if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  async createFormat() {
    await this.resetForm(true, true, true);
    await this.getLookupValues();
    this.ofId = null;
    this.ofName = "";
    this.seqFormGrp.controls.frmBillto.clearValidators();
    this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
    this.seqFormGrp.controls.frmSerType.clearValidators();
    this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
    this.addEditScreen = true;
    this.gridScreen = false;
    this.editEnabled = false;
    this.actionTitle = 'Create Sequence Scheme';
    this.selectedrowData = null;
  }

  async changeTab(tab, index) {
    let contextVal = index == 0 ? "Sequence Scheme" : "Sequences"
    this.activeTab = index;
    this.selectedCaseType = 'All Case Types';
    index == 0 ? await this.refreshGrid() : await this.refreshSequence();
    await this.getAuditableDetails(contextVal);
    this.activityEntity.entityId = '';
    this.activityEntity.entityType = contextVal;
    this.activityEntity.context= [{ 'key': 'parentMenu', 'value': tab }];
    this.activityService.setActivitySession(this.activityEntity);
  }

  //#region refresh data
  async refreshGrid(createNew?) {
    this.resetForm(true, true, true);
    this.serviceChanged();
    this.billToChange();
    let queryVariable = { accid: this.templateData.cardIdentifier.toString(), orgid: this.destOrgid.toString(), casetype: this.searchContext };
    let query = this.SubMenuCardModel.GetQuery("getidentityformats");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          this.templateData['submenuData'] = [...data.data.submenuData]
        }
        this.editEnabled = false;
        this.addEditScreen = false;
        if (!createNew) {
          this.gridScreen = true;
          if (this.gridScreen) {
            if (this.templateData['submenuData'].length > 0) {
              this.emitFilters({casetype:this.selectedCaseType});
            } else {
              this.addGridData([]);
            }
          }
        } else {
          this.createFormat();
        }
      }
      else {
        this._snackbar.open('Something went wrong! Please refresh the menu', 'Close');
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }
  //#endregion

  trackByItem(index: number, item: any): any {
    return item.sequencename; // Use a unique identifier for items
  }

  trackByHeader(index: number, header: any): any {
    return header.key; // Use a unique identifier for headers
  }

  editSequence(item, index) {
    this.configuredRunningNumbers = cloneDeep(this.sequencesDataList);
    this.editEnabled = true;
    this.editingItem = index;
    this.prevSequence = item
  }

  deleteSequence(item, action) {
    this.prevSequence = item;
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      autoFocus: false,
      width: '400px',
      panelClass: 'admin-custom-popup',
      data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
    });
    return dialogRef.afterClosed().toPromise().then(async result => {
      if (result) {
        await this.saveSequenceChanges(item, action);
      }
      else {
        return
      }
    }, error => {
      console.error(error);
    });
  }

  async saveSequenceChanges(item, action: string) {
    if (item) {
      if (action != 'Delete' && (!item.sequencename?.toString().trim())) {
        if(action == "Edit") 
          {
            this._snackbar.open("Sequence Name cannot be empty", "Close");
          } else {
            const frmSequenceNameControl = this.seqTabFormGrp.get('frmSequenceName');
            frmSequenceNameControl?.markAsDirty();
            frmSequenceNameControl?.setErrors({ dirtyError: true });
          }
          return
        }
        else {
          let obj = item;
          obj['sequencename'] = action != 'Delete' ? (item.sequencename?.toString().trim()) : item.sequencename;
        obj['organizationid'] = this.destOrgid;
        obj['action'] = action || null;
        obj['prevSequencename'] = this.prevSequence?.sequencename || "";
        this.ngxService.start('saveSeq');
        await this.VitalHttpServices.manageSequence(obj, this.destDeployment).toPromise().then(async result => {
          this.ngxService.stop('saveSeq');
          if (result && result.Success) {
            this.activityEntity.entityid = obj.sequencename;
            this.prevSequence ? (this.prevSequence["sequencetype"] = "Accession") : null
            obj["sequencetype"] = "Accession";
            this.activityService.setActivitySession(this.activityEntity);
            this.templateData.menuURL = "Sequences";
            this.commonService.auditDetails("sequencename", "sequencename", (action == 'Edit' ? [this.prevSequence] : [obj]), [obj], action, this.templateData, this.auditableColumns)            
            this._snackbar.open(result.Message, "Close");
            if(result.Message?.toString().includes('exists'))
            {
              return
            }
            await this.refreshSequence();
            this.templateData.menuURL = "Sequence Scheme";
            this.editingItem = null;
            this.editEnabled = false;
            this.prevSequence = null;
          } else {
            this._snackbar.open("Error occurred whileprocessing your request", "Close");
          }
        }, error => {
          this.ngxService.stop('saveSeq');
          console.error(error);
        })
      }
    }
  }

  cancelSequenceEdit() {
    this.editEnabled = false;
    this.editingItem = null; // Reset if canceling
    this.configuredRunningNumbers = cloneDeep(this.sequencesDataList);
  }

  async refreshSequence() {
    this.addEditScreen = false;
    this.gridScreen = true;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    this.gridHeaderList = this.templateHeaders?.templateList;
    for (let i = 0; i < this.gridHeaderList?.length; i++) {
      if (this.gridHeaderList[i]?.templateName == 'Sequences') {
        this.listGridHeader = this.gridHeaderList[i]?.headers;
        this.listHeaderbackup = JSON?.parse(
          JSON.stringify(this.listGridHeader)
        );
      }
    }
    let query = this.SubMenuCardModel.GetQuery('getsequences');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id').toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start('seq-tab');
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(async res => {
      this.ngxService.stop('seq-tab');
      this.configuredRunningNumbers = this.sequencesDataList = res.data.submenuDataSequences?.length ? (res.data.submenuDataSequences.map(item => ({
          ...item, 
          sequencetype: item.sequencetype.toUpperCase() 
      }))) : [];
    }, error => {
      this.ngxService.stop('seq-tab');
      console.error(error);
    })
    if (this.configuredRunningNumbers) {
      if (this.configuredRunningNumbers.length > 0) {
        this.configuredRunningNumbers.sort((a, b) => (a.sequencename < b.sequencename ? -1 : 1))
      }
    }
  }

  resetForm(defaultFormat, customFormat, resetAll?) {
    if (resetAll) {
      this.isCasetypeSelected = false;
      this.allSelectedService = false;
      this.allSelectedBillTo = false;
      this.caseProfileList = [];
      this.orderCodeList = [];
      this.clearSearch(null, 'Panel');
      this.clearSearch(null, 'Caseprofile');
      this.entityType = '';
      this.entityId = '';
      this.seqFormGrp.reset();

      this.seqFormGrp.patchValue({
        frmCasetype: "",
        frmOrdFacility: "",
        frmSerType: [],
        frmBillto: [],
        frmIsResearch: false
      })
      Object.keys(this.seqFormGrp.controls).forEach(key => {
        this.seqFormGrp.controls[key].setErrors(null);
      });
      this.seqFormGrp.controls["frmCasetype"].setValidators([Validators.required]);
      this.seqFormGrp.controls["frmCasetype"].updateValueAndValidity();
      this.selected.setValue(0);
    }

    if (defaultFormat) {
      this.defaultChipList = [];
      this.defaultRangeArray = [];
      this.allSelectedDates = false;
      this.allSelectedCodes = false;
      this.cardClicked[0] = [false, false, false, false, false, false];
      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        frmCodes: [],
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: [],
        frmRange: "",
        frmBeginsAt: "",
        frmOutput: "",
        frmScheme: "",
        frmSequenceName: "",
        frmOrdFacility: "",
        frmOFCodes: ""
      });
      Object.keys(this.seqFormGrp.controls.frmDefaultFormat['controls']).forEach(key => {
        this.seqFormGrp.controls.frmDefaultFormat['controls'][key].setErrors(null);
      });
      this.seqFormGrp.controls.frmDefaultFormat['controls']["frmOutput"].setValidators([Validators.maxLength(this.resultMaxLen + 5)]);
      this.seqFormGrp.controls.frmDefaultFormat['controls']["frmOutput"].updateValueAndValidity();
    }

    if (customFormat) {
      this.customChipList = [];
      this.customRangeArray = [];
      this.allSelectedCustomDates = false;
      this.allSelectedCustomCodes = false;
      this.cardClicked[1] = [false, false, false, false, false, false];
      this.seqFormGrp.controls['frmCustomFormat'].patchValue({
        frmCodes: [],
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: [],
        frmRange: "",
        frmBeginsAt: "",
        frmOutput: "",
        frmScheme: "",
        frmSequenceName: "",
        frmOFCodes: ""
      });
      Object.keys(this.seqFormGrp.controls.frmCustomFormat['controls']).forEach(key => {
        this.seqFormGrp.controls.frmCustomFormat['controls'][key].setErrors(null);
      });
      this.seqFormGrp.controls.frmCustomFormat['controls']["frmOutput"].setValidators([Validators.maxLength(this.resultMaxLen + 5)]);
      this.seqFormGrp.controls.frmCustomFormat['controls']["frmOutput"].updateValueAndValidity()
    }
  }

  getRunningNumber(format, derivedFormat) {
    let number = format.match(/\d+/g);
    let lastnumber = number ? (parseInt(number[number.length - 1])) : 0;
    let runningNumber = derivedFormat.substr(derivedFormat?.length - lastnumber);
    return runningNumber
  }

  addGridData(data) {
    this.actionTitle = "Sequence Scheme";
    this.gridArray = [];
    let primary = {};
    let inArray = [];
    let finalTemp = [];

    let inDefaultArray = [];
    if (data && data.length > 0) {
      finalTemp = data.filter(r => r.associationtype && r.associationtype.toString() != 'default');
      inDefaultArray = data.filter(r => r.associationtype
        && (r.associationtype.toString() == 'default'))
      inArray = [...finalTemp, ...inDefaultArray.filter(el => finalTemp && !finalTemp.some(e => e.casetype == el.casetype))]
    }
    if (inArray) {
      if (inArray.length > 0) {
        inArray = inArray.map(va => ({
          ...va, runningnumber: ((va.dformat && va.format) ? this.getRunningNumber(va.format, va.dformat) : '00001'),
          schemeRunningNumber: ((va.dformat && va.format) ? this.getRunningNumber(va.format, va.dformat) : '00001'),
          customSchemeRunningNumber: (va.dalternateformat && va.alternateformat) ? this.getRunningNumber(va.alternateformat, va.dalternateformat) : '00001'
        }))
        this.gridData = new CollectionView([]);
        inArray.sort((a, b) => (a.casetypedisplayname - b.casetypedisplayname || a.dformat.localeCompare(b.format)));
        for (let i = 0; i < inArray.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(inArray[i])) {
            if (this.showDefaultFormats) {
              if (!value) {
                value = 'NA';
              }
            }
            else {
              if (!value) {
                value = '-';
              }
              if (key == 'billto' || key == 'services') {
                value = (value == '' || value == null || value.toString() == '-') ? 'ALL' : value;
              }
            }
            primary[key] = value;
          }
          this.gridArray.push(primary);
        }
        this.groupBy = ["Case Type"];
        this.listGridheaders = this.getDataHeaders();
        this.gridArray = this.getGroupedData(this.gridArray);
        this.gridData = new CollectionView(this.gridArray, { pageSize: 10 })
      }
    }
  }

  getGroupedData(arrayData) {
    let hierData = [];
    arrayData.map(va => {
      let parentInfo: { Name: string, DataList?: Object[] } = { Name: va.casetypedisplayname };
      if (!hierData.some(val => val.Name == va.casetypedisplayname)) {
        parentInfo.DataList = arrayData.filter(val => val.casetypedisplayname == va.casetypedisplayname);
        hierData.push(parentInfo);
      }
    });
    if (!hierData.length) {
      hierData = arrayData;
    }
    return hierData;
  }

  getDataHeaders() {
    let valueObj = [{
      "Name": "Scheme",
      "type": "text",
      "key": "dformat",
      "colWidth": "col-sm-2",
      "toolTipKey": "format"
    }, {
      "Name": "Custom Scheme",
      "type": "text",
      "key": "dalternateformat",
      "colWidth": "col-sm-2",
      "toolTipKey": "alternateformat"
    }, {
      "Name": "Running#",
      "type": "text",
      "key": "runningnumber",
      "colWidth": "col-sm-1"
    }, {
      "Name": "Case Profile",
      "type": "text",
      "key": "caseprofile",
      "colWidth": "col-sm-2"
    }, {
      "Name": "Panel",
      "type": "text",
      "key": "ordercode",
      "colWidth": "col-sm-1"
    }, {
      "Name": "Services",
      "type": "text",
      "key": "services",
      "colWidth": "col-sm-1"
    },
    {
      "Name": "Bill To",
      "type": "text",
      "key": "billto",
      "colWidth": "col-sm-1"
    },
    {
      "Name": "OF(Group)",
      "type": "text",
      "key": "oforgname",
      "colWidth": "col-sm-1"
    }]
    return valueObj;
  }

  exportExcel() {
    let filename = 'SequenceScheme_';
    filename = filename + this.templateData?.secondarykeys?.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_'
      if (fileName.length > 31) {
        filename = fileName.split('_')[0] + '.xlsx';
      } else {
        if (this.templateData.secondarykeys) {
          filename = fileName + this.templateData?.secondarykeys?.OrganizationId.toString() + '.xlsx';
        }
        else {
          filename = fileName.split('_')[0] + '.xlsx';
        }
      }
    }
    var ws = XLSX.utils.json_to_sheet(this.configuredRunningNumbers);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Sequences');
    XLSX.writeFile(wb, filename);
  }

  downloadSequenceFormat(ev?)
  {
    let filename = 'SequenceScheme_';
    filename = filename + this.templateData?.secondarykeys?.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_'
      if (fileName.length > 31) {
        filename = fileName.split('_')[0] + '.xlsx';
      } else {
        if (this.templateData.secondarykeys) {
          filename = fileName + this.templateData?.secondarykeys?.OrganizationId.toString() + '.xlsx';
        }
        else {
          filename = fileName.split('_')[0] + '.xlsx';
        }
      }
    }
    let dataList = [];
    this.gridArray.forEach(va => {
      dataList = [...dataList, ...va.DataList];
    })
    var ws = XLSX.utils.json_to_sheet(dataList.map(x => ({...x, casetype: x.casetypedisplayname, enableresearch: x.caseflowtype ? 'Yes('+x.caseflowtype+')' : 'No'})).map(({oforgid,entity,orgmnemonic,accountmnemonic,associationtype,casetypedisplayname,schemeRunningNumber,customSchemeRunningNumber, ...rest}) => rest));
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SequenceFormat');
    XLSX.writeFile(wb, filename);
  }

  filterServices(value) {
    if (!value || value == '') {
      return this.serviceList;
    }
    const val = value;

    let tempArray = [...this.serviceList]
    if (tempArray && tempArray.length > 0) {
      return tempArray.filter(va => va && va.toString().toLowerCase().includes(val.toString().toLowerCase()));
    } else {
      return [];
    }
  }

  filterBillTo(value) {
    if (!value || value == '') {
      return this.billToList;
    }
    const val = value;

    let tempArray = [...this.billToList]
    if (tempArray && tempArray.length > 0) {
      return tempArray.filter(va => va && va.toString().toLowerCase().includes(val.toString().toLowerCase()));
    } else {
      return [];
    }
  }

  removeChip(value: string, str): void {
    switch (str) {
      case 'Service': this.allSelectedService = false;
        this.matSelectOptionServiceType.options.forEach((item: MatOption) => {
          if (value == item.value) {
            item.deselect()
          }
        });
      case 'BillTo': this.allSelectedBillTo = false;
        this.matSelectOptionBillTo.options.forEach((item: MatOption) => {
          if (value == item.value) {
            item.deselect()
          }
        });
    }
  }

  async getSampleDataValues() {
    let query = this.VitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { "keyword": "Standard Format", "context": "SequenceScheme" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, 'configdb').toPromise().then((Resdata) => {
      if (!Resdata.errors) {
        if (Resdata.data.submenuData[0] && Resdata.data.submenuData[0].Items_JSON) {
          this.standardTemplates = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.filterTemplates();
        }
      }
    }, error => {
      console.error(error);
    });
  }

  async validateDuplicates(inputArray) {
    let validFlag = true;
    let createdby = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid");

    const {
      frmCasetype,
      frmSerType,
      frmBillto,
      frmIsResearch,
      frmDefaultFormat,
      frmCustomFormat
    } = inputArray.value
    let temp = [];
    let temp1 = [];
    if (this.allSelectedBillTo && this.allSelectedService && ((!this.ofId) || (this.ofId && this.ofId == -1))) {
      temp = (!this.ofId) ? [] : this.serviceList;
      temp1 = (!this.ofId) ? [] : this.billToList;
    }
    else {
      temp = frmSerType;
      temp1 = frmBillto;
      if (temp.length > 0 && temp1.length > 0 && !this.ofId) {
        this.ofId = -1;
        this.ofName = "Default Group(-1)"
      }
    }
    let casetypeObj = this.seqFormGrp.controls.frmCasetype.value ? this.casetypeList.find(x => x.Case_Type == this.seqFormGrp.controls.frmCasetype.value) : "";
    this.finalArray = [{
      "tablename": "identityformat",
      "casetype": casetypeObj.casetype,
      "organizationid": Number(sessionStorage.getItem('org_id')),
      "formatids": !this.selectedrowData?.formatid ? null : this.selectedrowData.formatid,
      "formatid": !this.selectedrowData?.formatid ? null : this.selectedrowData.formatid.toString().split(',')[0],
      "entityname": casetypeObj.casetype,
      "entitytype": this.entityType,
      "entityid": this.entityId,
      "format": (((!this.seqFormGrp.controls.frmDefaultFormat['controls'].frmScheme.value) || (this.seqFormGrp.controls.frmDefaultFormat['controls'].frmScheme.value.toString().trim() == '-')) ? null : (this.seqFormGrp.controls.frmDefaultFormat['controls'].frmScheme.value ? this.seqFormGrp.controls.frmDefaultFormat['controls'].frmScheme.value : null)),
      "alternateformat": this.disableCustom ? null : (((!this.seqFormGrp.controls.frmCustomFormat["controls"].frmScheme.value) || (this.seqFormGrp.controls.frmCustomFormat["controls"].frmScheme.value.toString().trim() == '-')) ? null : (this.seqFormGrp.controls.frmCustomFormat["controls"].frmScheme.value ? this.seqFormGrp.controls.frmCustomFormat["controls"].frmScheme.value : null)),
      "billto": temp1,
      "services": temp,
      "oforgid": this.ofId ? String(this.ofId) : ((frmSerType?.length > 0 && frmBillto?.length > 0) ? -1 : null),
      "labaccountid": this.entityType?.toString().toLowerCase() == 'order codes' ? 1 : 0,
      "caseflowtype": frmIsResearch ? 'QC' : null,
      "accountid": this.templateData.cardIdentifier,
      "defaultsequencename": this.seqFormGrp.controls.frmDefaultFormat["controls"].frmSequenceName.value ?? null,
      "customsequencename": this.disableCustom ? null : (this.seqFormGrp.controls.frmCustomFormat["controls"].frmSequenceName.value ?? null),
      "defaultrunningnumber": this.seqFormGrp.controls.frmDefaultFormat["controls"].frmBeginsAt.value ?? null,
      "customrunningnumber": this.disableCustom ? null : (this.seqFormGrp.controls.frmCustomFormat["controls"].frmBeginsAt.value ?? null),
      "createdby": createdby,
      "caseprofile": this.seqFormGrp.controls.frmCaseprofile.value ?? null,
      "ordercode": this.seqFormGrp.controls.frmPanel.value ?? null
    }]
    this.finalArray[0]["services"] = ((this.finalArray[0]["services"] && this.finalArray[0]["services"].toString().trim().toLowerCase() == 'all') &&
      (this.finalArray[0]["billto"] && this.finalArray[0]["billto"].toString().trim().toLowerCase() == 'all')) ? "" : (!this.finalArray[0]["services"] ? "" : this.finalArray[0]["services"].toString());
    this.finalArray[0]["billto"] = ((this.finalArray[0]["services"] && this.finalArray[0]["services"].toString().trim().toLowerCase() == 'all') &&
      (this.finalArray[0]["billto"] && this.finalArray[0]["billto"].toString().trim().toLowerCase() == 'all')) ? "" : (!this.finalArray[0]["billto"] ? "" : this.finalArray[0]["billto"].toString());

    // this.finalArray[0]["format"] = this.disableCustom ? null : ((this.seqFormGrp.controls.frmCustomFormat["controls"].frmScheme.value.toString().trim() == '-') ? null : this.seqFormGrp.controls.frmCustomFormat["controls"].frmScheme.value)
    // this.finalArray[0]["alternateformat"] = ((this.seqFormGrp.controls.frmDefaultFormat["controls"].frmScheme.value.toString().trim() == '-') ? null : this.seqFormGrp.controls.frmDefaultFormat["controls"].frmScheme.value)

    await this.VitalHttpServices.validateIdentityFormats(this.finalArray, this.destDeployment).toPromise().then(result => {
      if (result && result.some(el => !el.error)) {
        if (result.some(el => el.notemessage && el.notemessage.toString().includes('already exists'))) {
          this._snackbar.open("Scheme already exists", "Failure");
          validFlag = false;
        }
        else {
          let sequenceNumberExist = this.getConfiguredRunningNumber(this.finalArray[0].defaultsequencename);
          let isBeginsAtLesser = (sequenceNumberExist && (Number(this.finalArray[0].defaultrunningnumber) < Number(sequenceNumberExist.lastnumber) + 1)) ? true : false;
          let isBeginsAtGreater = (sequenceNumberExist && (Number(this.finalArray[0].defaultrunningnumber) > Number(sequenceNumberExist.lastnumber) + 1)) ? true : false;
          if (isBeginsAtLesser || isBeginsAtGreater) {
            let msgStr = (isBeginsAtLesser ? "You are trying to modify the 'Begins At' value to a lesser number from the existing one. There may be existing cases available having same accession sequence. Please make sure the existing cases are scrubbed before proceeding with this action." :
              "You are trying to modify the 'Begins At' value to a greater number from the existing one. The cases with accession sequence will be generated with the new number provided. Please confirm before proceeding with this action.")
            let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              autoFocus: false,
              width: '500px',
              panelClass: 'admin-custom-popup',
              data: {
                header: "", message: msgStr,
                alert: "", continue: "Yes", cancel: "No"
              }
            });
            return dialogRef.afterClosed().toPromise().then(async result => {
              if (result) {
                validFlag = true;
              }
              else {
                this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
                  frmBeginsAt: this.selectedrowData.schemeRunningNumber
                });
                validFlag = false;
              }
            }, error => {
              validFlag = false;
              console.error(error);
            });
          }
        }
      } else {
        this._snackbar.open("Error occurred while processing your request !", "Failure");
        validFlag = false;
      }
    }, error => {
      this._snackbar.open("Error occurred while processing your request !", "Failure");
      console.error(error);
      validFlag = false;
    })
    return validFlag;
  }

  async validateScheme(inputArray) {
    let validScheme = true;
    validScheme = await this.validateFields(inputArray);
    validScheme = validScheme ? await this.validateDuplicates(inputArray) : validScheme;

    return validScheme;
  }

  async saveScheme(inputArray, action) {
    if (!await this.validateScheme(inputArray)) {
      return;
    } else {
      this.ngxService.start();
      if (this.finalArray.some(x => x.formatid)) {
        this.VitalHttpServices.EditIdentityFormats(this.finalArray, this.destDeployment).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors && result?.length > 0) {
            if (result.some(va => va.Status == 'Success')) {
              this.activityEntity.entityid = this.finalArray[0].formatid;
              this.activityService.setActivitySession(this.activityEntity);
              let auditNewArray = this.getAuditableObject(this.finalArray, true);
              let auditOldArray = this.getAuditableObject([this.selectedrowData]);
              this.commonService.auditDetails("formatid", "format", auditOldArray, auditNewArray, 'Edit', this.templateData, this.auditableColumns)
              this._snackbar.open(result[0]['notes'], "Close");
            } else {
              this._snackbar.open(result[0]['notes'], "Close");
              return
            }
            this.refreshGrid();
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong. Please try again', 'Close');
          console.error(error);
        })
      } else {
        await this.VitalHttpServices.bulkUploadIdentityFormats(this.finalArray, this.destDeployment).toPromise().then(result => {
          this.ngxService.stop();
          if (!result.errors && result?.length > 0) {
            if (result.some(va => va.Status == 'Success')) {
              this._snackbar.open(result[0]['notes'], "Close");
              this.finalArray = this.finalArray.map(x => ({ ...x, format: x.alternateformat, alternateformat: x.format }))
              result = result.map(x => ({ ...x, formatid: result[0].ID }))
              let auditNewArray = this.getAuditableObject(this.finalArray);
              this.commonService.auditDetails("formatid", "format", result, auditNewArray, 'Create', this.templateData, this.auditableColumns)
            } else {
              this._snackbar.open(result[0]['notes'], "Close");
              return
            }
            if (action == 'new') {
              this.refreshGrid(true);
              return;
            } else {
              this.refreshGrid();
            }
          }
        }, error => {
          console.error(error);
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request!", "Close")
        })
      }
    }
  }

  resetValidators(groupname, setNull?) {
    if (setNull) {
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].setValidators([]);
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].updateValueAndValidity()
      this.seqFormGrp.controls[groupname]['controls']["frmRange"].setValidators([]);
      this.seqFormGrp.controls[groupname]['controls']["frmRange"].updateValueAndValidity({ onlySelf: true, emitEvent: false })
    } else {
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].setValidators([Validators.required]);
      this.seqFormGrp.controls[groupname]['controls']["frmDate"].updateValueAndValidity();
      this.seqFormGrp.controls[groupname]['controls']["frmRange"].setValidators([Validators.required]);
      if (groupname == 'frmDefaultFormat') {
        this.seqFormGrp.controls[groupname]['controls']["frmRange"].updateValueAndValidity();
      } else {
        this.seqFormGrp.controls[groupname]['controls']["frmRange"].updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }
  }

  async validateFields(inputArray) {
    const {
      frmCasetype,
      frmSerType,
      frmBillto,
      frmDefaultFormat,
      frmCustomFormat,
    } = inputArray.value
    this.seqFormGrp.markAllAsTouched();
    if (this.ofId) {
      this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      if ((!frmSerType || (frmSerType?.length == 0)) || (!frmBillto || (frmBillto?.length == 0))) {
        this._snackbar.open("Please enter all the mandatory fields", "Close");
        return false;
      }
    }

    if ((frmSerType && frmSerType?.length > 0) && (!frmBillto || frmBillto?.length < 1)) {
      this._snackbar.open("Please enter all the mandatory fields", "Close");
      return false;
    }

    if ((frmBillto && frmBillto?.length > 0) && (!frmSerType || frmSerType?.length < 1)) {
      this._snackbar.open("Please enter all the mandatory fields", "Close");
      return false;
    }

    if (!this.seqFormGrp.controls.frmCasetype.value || !frmDefaultFormat.frmRange) {
      this._snackbar.open("Please enter all the mandatory fields", "Close");
      this.selected.setValue(0);
      return false;
    }

    if ((frmCustomFormat.frmCodes?.length > 0) ||
      frmCustomFormat.frmCustomCode ||
      // frmCustomFormat.frmBeginsAt ||
      (frmCustomFormat.frmDate?.length > 0) ||
      frmCustomFormat.frmRange) {
      if (!frmCustomFormat.frmRange) {
        this._snackbar.open("Please enter all the mandatory fields", "Close");
        this.selected.setValue(1);
        return false;
      }
    }

    if (this.seqFormGrp.controls.frmDefaultFormat['controls'].frmSequenceName.value && this.seqFormGrp.controls.frmDefaultFormat['controls'].frmSequenceName.value.length > this.resultMaxLen) {
      this._snackbar.open("Please enter a valid length of Scheme!", "Close");
      this.selected.setValue(0);
      return false
    }

    if (this.seqFormGrp.controls.frmCustomFormat['controls'].frmSequenceName.value && this.seqFormGrp.controls.frmCustomFormat['controls'].frmSequenceName.value.length > this.resultMaxLen) {
      this._snackbar.open("Please enter a valid length of Scheme!", "Close");
      this.selected.setValue(1);
      return false
    }
    return true;
  }

  async saveCloseData(action, inputArray) {
    await this.saveScheme(inputArray, action);
  }

  //#region Edit page data
  async openEditScreen(event) {
    if (this.hideEditBtn) {
      this._snackbar.open("User is not authorized !", "Close");
      return;
    }
    await this.resetForm(true, true, true);
    await this.getLookupValues();
    if(!this.casetypeList.some(x => x.casetype == event.casetype))
    {
      this._snackbar.open("Selected casetype is In-Active","Close");
      return
    }
    this.editEnabled = true;
    this.addEditScreen = true;
    this.gridScreen = false;
    this.actionTitle = 'Edit Sequence Scheme'
    this.selectedrowData = event;
    for (var key in this.selectedrowData) {
      if (this.selectedrowData[key] == "-") {
        this.selectedrowData[key] = "";
      }
    }
    await this.getCaseProfiles(this.selectedrowData.casetype);
    await this.getOrderCodes(this.selectedrowData.casetype);
    await this.getInputData();
  }
  //#endregion

  //#region
  getInputData() {
    let target = [], defaultAccession = '', defaultRunningNumber, customAccession = '', customRunningNumber;

    if (this.selectedrowData.format && this.selectedrowData.alternateformat) {
      defaultAccession = this.selectedrowData.dformat;
      defaultRunningNumber = String(this.selectedrowData.schemeRunningNumber)
      customAccession = this.selectedrowData.alternateformat ? this.selectedrowData.dalternateformat : ''
      customRunningNumber = String(this.selectedrowData.customSchemeRunningNumber)
    }
    else {
      defaultAccession = this.selectedrowData.alternateformat ? this.selectedrowData.dalternateformat : '';
      defaultRunningNumber = String(this.selectedrowData.schemeRunningNumber)
      customAccession = this.selectedrowData.dformat;
      customRunningNumber = String(this.selectedrowData.customSchemeRunningNumber)
    }

    this.ofId = this.selectedrowData.oforgid || null
    this.ofName = this.selectedrowData.oforgname ? (this.selectedrowData.oforgname + '(' + this.ofId + ')') : "";
    let serviceData = this.selectedrowData.services ? (this.selectedrowData.services.toString().toLowerCase().trim() != 'all' ? this.selectedrowData.services.split(',') : ((this.ofId == -1) ? this.serviceList : [])) : [];
    let billToData = this.selectedrowData.billto ? (this.selectedrowData.billto.toString().toLowerCase().trim() != 'all' ? this.selectedrowData.billto.split(',') : ((this.ofId == -1) ? this.billToList : [])) : [];
    let casetypeObj = this.getEntityName({ entityid: "", casetype: this.selectedrowData.casetype, entitytype: "Account Casetype" });
    let entityObj = this.getEntityName(this.selectedrowData);
    this.entityType = entityObj.entitytype ? entityObj.entitytype : "Account Casetype";
    this.entityId = entityObj.entityid ? entityObj.entityid : casetypeObj.entityid;
    this.seqFormGrp.patchValue({
      frmOrdFacility: this.selectedrowData.oforgname ? this.selectedrowData.oforgname : '',
      frmCasetype: (casetypeObj && casetypeObj.entityname) ? casetypeObj.entityname : '',
      frmSerType: serviceData,
      frmBillto: billToData,
      frmIsResearch: this.selectedrowData.caseflowtype ? true : false,
      frmPanel: (entityObj && entityObj.entityname && entityObj.entitytype == 'Order Codes') ? entityObj.entityname : '',
      frmCaseprofile: (entityObj && entityObj.entityname && entityObj.entitytype == 'Case Profile') ? entityObj.entityname : ''
    })
    
    let temp = this.splitFormat(this.selectedrowData.format, 'frmDefaultFormat',String(this.selectedrowData.schemeRunningNumber));
    const defaultRangeList = [...temp.rangeList];
    const defaultChipList = [...temp.chipList];
    temp = this.selectedrowData.alternateformat ? this.splitFormat(this.selectedrowData.alternateformat, 'frmCustomFormat',String(this.selectedrowData.customSchemeRunningNumber)) : null;
    const customRangeList = temp ? [...temp.rangeList] : [];
    const customChipList = temp ? [...temp.chipList] : [];
    this.rangeLength['frmDefaultFormat'] = defaultRangeList.find(x => x.controlName == 'frmRange').derivedValue?.toString().split('-')[0].length;
    this.rangeLength['frmCustomFormat'] = customRangeList.find(x => x.controlName == 'frmRange') ? customRangeList.find(x => x.controlName == 'frmRange')['range']?.toString().split('-')[0].length : 0;

    target = [...this.serviceList]
    if (target.every(v => serviceData.includes(v))) {
      this.allSelectedService = true;
    }
    target = []
    target = [...this.billToList]
    if (target.every(v => billToData.includes(v))) {
      this.allSelectedBillTo = true;
    }

    let defaultCodes = defaultChipList.filter(x => x.controlName == 'frmCodes').map(x => x.name);
    let defaultDate = defaultChipList.filter(x => x.controlName == 'frmDate').map(x => x.name);
    if (this.ofId && this.ofId != -1) {
      this.disableCustom = true;
      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        // frmOFCodes: defaultFormat.custom,
        frmCodes: defaultCodes,
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: defaultDate,
        frmRange: defaultRangeList.find(x => x.controlName == 'frmRange').derivedValue,
        frmBeginsAt: defaultRunningNumber,
        frmOutput: defaultAccession
      })
      this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      this.defaultChipList = defaultChipList;
      this.defaultRangeArray = defaultRangeList;
      this.concatFormat("frmDefaultFormat");
      this.filterTemplates(true);
    } else {
      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        frmCodes: defaultCodes,
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: defaultDate,
        frmRange: defaultRangeList.find(x => x.controlName == 'frmRange').derivedValue,
        frmBeginsAt: defaultRunningNumber,
        frmOutput: defaultAccession
      });
      this.serviceChanged();
      this.billToChange();
      this.defaultChipList = defaultChipList;
      this.defaultRangeArray = defaultRangeList;
      this.concatFormat('frmDefaultFormat');
    }

    if (customChipList && customChipList.length) {
      let customCodes = customChipList.filter(x => x.controlName == 'frmCodes').map(x => x.name);
      let customDate = customChipList.filter(x => x.controlName == 'frmDate').map(x => x.name);
      this.seqFormGrp.controls['frmCustomFormat'].patchValue({
        frmCodes: customCodes,
        frmCustomCode: "",
        frmDivision: "-",
        frmDate: customDate,
        frmRange: customRangeList.find(x => x.controlName == 'frmRange').derivedValue,
        frmBeginsAt: customRunningNumber,
        frmOutput: customAccession
      });
      this.customChipList = customChipList;
      this.customRangeArray = customRangeList;
      this.concatFormat('frmCustomFormat');
    }
  }
  //#endregion

  getEntityName(inputObj) {
    let entityObj = { entityid: "", entityname: "", entitytype: "" };
    let temp;
    switch (inputObj.entitytype) {
      case "":
      case "Account Casetype": temp = this.casetypeList.find(x => x.casetype == inputObj.casetype);
        entityObj = { entityid: temp?.AccountCasetypeID, entityname: temp?.Case_Type, entitytype: inputObj?.entitytype }
        break;
      case "Case Profile": temp = this.caseProfileList.find(x => x.Display_name == inputObj.caseprofile);
        entityObj = { entityid: temp?.CaseProfileId, entityname: ((temp && temp.Display_name) ? temp.Display_name : ''), entitytype: inputObj?.entitytype }
        break;
      case "Order Codes": temp = this.orderCodeList.find(x => x.Name == inputObj.ordercode);
        entityObj = { entityid: temp?.OrderCodeID, entityname: ((temp && temp.Name) ? temp.Name : ''), entitytype: inputObj?.entitytype }
        break;
    }
    return entityObj;
  }

  //#region split the format to patch the data in format tab
  splitFormat(inputFormat, grpName, beginsAt) {
    let str = inputFormat.toString().split(/(?=[%-])|(?<=[%-])|[%\-]/).filter(Boolean).filter(x => x != '%');
    let chipList = [];
    let rangeList = [];

    str.forEach(element => {
      if (element) {
        this.preDefinedCodes.find(ele => {
          if (ele.code && element && ele.code.toString() == '%' + element + '%') {
            let obj = this.getCodes(ele.desc.toString(), grpName);
            chipList.push({ controlName: 'frmCodes', name: ele.desc.toString(), derivedValue: obj['derivedCode'], orginalValue: obj['originalCode'] });
          }
          else if (ele.date && element && ele.date.toString() == '%' + element + '%') {
            const date = this.getDates(ele.desc.toString());
            chipList.push({ controlName: 'frmDate', name: ele.desc.toString(), derivedValue: date, orginalValue: "%" + ele.desc.toString() + "%" });
          }
        })
        if (element == '-') {
          chipList.push({ controlName: 'frmDivision', name: element, derivedValue: element, orginalValue: element });
        } else if (element && element.toString().endsWith("NN") || element.toString().endsWith("US")) {
          let difference = Number(element.toString().match(/\d+/)[0]);
          let fnum = ('0'.repeat(difference - 1) + 1)
          let lnum = ('9'.repeat(difference))
          this.rangeLength[grpName] = fnum?.length;
          let obj = this.getRange(grpName, (fnum + '-' + lnum), beginsAt);
          rangeList.push({ controlName: 'frmRange', name: "Range", derivedValue: obj['derivedCode'], orginalValue: obj['originalCode'] });
        } else if (!this.preDefinedCodes.some(va => element && (va.code.toString().toLowerCase() == ('%' + element + '%').toString().toLowerCase() || va.date.toString().toLowerCase() == ('%' + element + '%').toString().toLowerCase()))) {
          chipList.push({ controlName: "frmCustomCode", name: ("Custom Code: " + element), derivedValue: element, orginalValue: element })
        }
      }
    });
    return { rangeList: rangeList, chipList: chipList };
  }
  //#endregion

  async populateCaseTypes(accountID) {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getSchemeCasetypes');
    queryVariable = { accid: accountID.toString() };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(async (res) => {
      if (!res.errors) {
        if (res.data) {
          this.casetypeList = (res.data.submenuData && res.data.submenuData.length > 0) ? res.data.submenuData.map(x => ({ ...x, CaseType: x.casetype ,CasetypeDisplayName: (x.displayname ? x.displayname : x.casetype),Case_Type: (x.displayname ? x.displayname : x.casetype) })).sort((a, b) => (a.Case_Type < b.Case_Type ? -1 : 1)) : [];
          this.casetypeList.unshift(this.allCaseTypeObj);
          this.seqFormGrp.controls.frmCasetype.enable();
        }
      }
    }, error => {
      console.error(error);
    })
  }

  emitFilters(event?) {
    this.selectedCaseType = event ? event.casetype : 'All Case Types';
    let filteredData = this.templateData.submenuData;
    if (this.selectedCaseType != 'All Case Types') {
      filteredData = this.templateData.submenuData.filter(x => x.casetype == this.selectedCaseType);
    }
    this.addGridData(filteredData);
  }

  deleteRecord(event) {
    if (this.hideDeleteBtn) {
      this._snackbar.open("User is not authorized !", "Close");
      return;
    }
    let selectedrowData = event;
    if (selectedrowData.formatid && selectedrowData.formatid != "-") {
      let arrayObj = [{
        formatid: selectedrowData.formatid != "-" ? selectedrowData.formatid : "",
      }];
      arrayObj[0]['formatids'] = selectedrowData.formatid != "-" ? selectedrowData.formatid : "";
      arrayObj[0]['billto'] = selectedrowData.billto != "-" ? selectedrowData.billto : "";
      arrayObj[0]['organizationid'] = selectedrowData.organizationid != "-" ? selectedrowData.organizationid : "";
      arrayObj[0]['accountid'] = this.templateData.cardIdentifier;
      arrayObj[0]['casetype'] = selectedrowData.casetype != "-" ? selectedrowData.casetype : "";
      arrayObj[0]['format'] = selectedrowData.format != "-" ? selectedrowData.format : "";
      arrayObj[0]['alternateformat'] = selectedrowData.alternateformat != "-" ? selectedrowData.alternateformat : "";
      arrayObj[0]['sequencetype'] = selectedrowData.sequencetype != "-" ? selectedrowData.sequencetype : "";

      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        autoFocus: false,
        width: '400px',
        panelClass: 'admin-custom-popup',
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      });
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.ngxService.start('deleteSeq');
          this.VitalHttpServices.DeleteIdentityFormats(arrayObj, this.destDeployment).subscribe(async result => {
            this.ngxService.stop('deleteSeq');
            if (!result.errors) {
              this._snackbar.open('Deleted successfully!', 'Close');
              if(selectedrowData.entitytype?.toLowerCase() == "case profile")
              {
                await this.getCaseProfiles(selectedrowData.casetype);
              } else if(selectedrowData.entitytype?.toLowerCase() == "order codes")
              {
                await this.getOrderCodes(selectedrowData.casetype);
              }
              let auditObj = this.getAuditableObject([selectedrowData]);
              this.commonService.auditDetails("formatid", "format", auditObj, [{}], 'Delete', this.templateData, this.auditableColumns)
              await this.refreshGrid();
            }
            else {
              this._snackbar.open('Deleted failed!', 'Close')
            }
          }, error => {
            this.ngxService.stop('deleteSeq');
            console.error(error);
          })
        }
        else {
          return
        }
      }, error => {
        console.error(error);
      });
    } else {
      this._snackbar.open("The record is not configured in database", "Close");
      return
    }
  }

  cardClass(i) {
    return this.cardClicked[this.selected.value][i] ? 'onClick-style' : 'card-style'
  }

  //#region
  /* RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
  }
  //#endregion

  //#region
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Edit":
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "EditSequence":
          this.sequenceHideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "DeleteSequence":
          this.sequenceHideDeleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "ExportSequence":
          this.sequenceHideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "CreateSequence":
          this.sequenceHideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  //#endregion

  getRangeLength(grpName) {
    return this.seqFormGrp.controls[grpName]['controls'].frmRange.value ? this.seqFormGrp.controls[grpName]['controls'].frmRange.value.toString().split('-')[0].length : 0;
  }

  openOrClosePanel1(evt: any, trigger1: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger1.panelOpen)
      trigger1.closePanel();
    else
      trigger1.openPanel();
  }

  clearOrdFacility(evt: any): void {
    evt.stopPropagation();
    this.seqFormGrp.patchValue({
      frmOrdFacility: ""
    })
    this.ofId = null;
    this.ofName = null;
    if (!this.seqFormGrp.controls.frmBillto.value?.length && !this.seqFormGrp.controls.frmSerType.value?.length)
    {
      this.seqFormGrp.controls.frmBillto.clearValidators();
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.clearValidators();
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      this.inputAutoComplete?.nativeElement.focus();
    }
  }

  // ofList has only of organizationname
  filterOF(value) {
    if (!value || value == '') {
      return this.ofList.slice(0, 25)
    }
    let users = this.ofList
    if (users && users.length > 0) {
      return users.filter(va => va && va.organizationname && (va.organizationname.toString().toLowerCase().includes(value.toLowerCase())
        || va.organizationid.toString().toLowerCase().includes(value.toLowerCase()))).slice(0, 25);
    } else {
      return []
    }
  }

  // group id
  getOfID(event, value) {
    if (event.source.selected) {
      this.ofId = value.organizationid;
      this.ofName = value.organizationname + '(' + this.ofId + ')';
      this.filterTemplates(true);
      this.disableCustom = true;
      this.selected.setValue(0);
      this.resetForm(false, true);
      this.seqFormGrp.controls.frmDefaultFormat.patchValue({
        frmCodes: ['Ordering Facility Mnemonic'],
        frmBeginsAt: ""
      });
      this.addChip('Ordering Facility Mnemonic','frmDefaultFormat','frmCodes');
      this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
      this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
    }
  }

  filterTemplates(isOFConfig?: Boolean) {
    this.disableCustom = isOFConfig ? true : false;
    if(this.standardTemplates)
    {
      this.standardFormat = [];
      this.standardFormat = isOFConfig ? [...this.standardTemplates.FacilityFormat, ...this.standardTemplates.CasetypeFormat] : [...this.standardTemplates.LabFormat, ...this.standardTemplates.CasetypeFormat];
      this.codeList = [...this.standardTemplates.Codes];
      this.dateList = [...this.standardTemplates.Dates];
      this.rangeList = [...this.standardTemplates.Ranges];
    }
  }

  serviceChanged() {
    (!this.disableCustom) ? this.validateDefaultOrg() : null;
    if (!this.disableCustom) {
      if (this.seqFormGrp.controls.frmSerType.value?.length) {
        this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
        this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
        this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
        this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
      } else {
        if (!this.seqFormGrp.controls.frmBillto.value?.length && !this.seqFormGrp.controls.frmSerType.value?.length) {
          this.seqFormGrp.controls.frmBillto.clearValidators();
          this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
        }
      }
    }
  }

  billToChange() {
    (!this.disableCustom) ? this.validateDefaultOrg() : null;
    if (!this.disableCustom) {
      if (this.seqFormGrp.controls.frmBillto.value?.length) {
        this.seqFormGrp.controls.frmSerType.setValidators([Validators.required]);
        this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
        this.seqFormGrp.controls.frmBillto.setValidators([Validators.required]);
        this.seqFormGrp.controls.frmBillto.updateValueAndValidity();
      } else {
        if (!this.seqFormGrp.controls.frmBillto.value?.length && !this.seqFormGrp.controls.frmSerType.value?.length) {
          this.seqFormGrp.controls.frmSerType.clearValidators();
          this.seqFormGrp.controls.frmSerType.updateValueAndValidity();
        }
      }
    }
  }

  validateDefaultOrg() {
    if ((this.seqFormGrp?.value?.frmBillto?.length > 0) && (this.seqFormGrp?.value?.frmSerType?.length > 0)) {
      this.seqFormGrp.patchValue({
        frmOrdFacility: "Default Group",
      })
      this.ofId = -1;
      this.ofName = "Default Group(-1)"
    } else {
      this.seqFormGrp.patchValue({
        frmOrdFacility: "",
      })
      this.ofId = null;
      this.ofName = null;
    }
  }

  async getAuditableDetails(location: any) {
    await this.VitalHttpServices.getDisplayColumns({ "TableName": location }).toPromise().then((res) => {
      if (res.content) {
        this.auditableColumns = JSON.parse(res.content.JsonData);
      }
    }, error => {
      console.error(error);
    })
  }

  getAuditableObject(inputArray: any, isUpdate?: Boolean) {
    if (inputArray.length > 0) {
      inputArray = inputArray.map(x => ({ ...x, format: (isUpdate ? x['format'] : x['alternateformat']), alternateformat: (isUpdate ? x['alternateformat'] : x['format']), sequencetype: 'Accession' }))
      for (var key in inputArray[0]) {
        if (inputArray[0][key] == "-") {
          inputArray[0][key] = "";
        }
      }
      let entityObj = this.getEntityName(inputArray[0]);
      return [{
        "entityname": (entityObj && entityObj.entityname) ? entityObj.entityname : ''
        , "entitytype": inputArray[0].entitytype || ""
        , "sequencetype": inputArray[0].sequencetype || ""
        , "format": inputArray[0].alternateformat || ""
        , "alternateformat": inputArray[0].format || ""
        , "billto": inputArray[0].billto || "ALL"
        , "services": inputArray[0].services || "ALL"
        , "oforgname": this.ofName
        , "caseflowtype": inputArray[0].caseflowtype || ""
        , "runningnumber": (inputArray[0].defaultrunningnumber ? Number(inputArray[0].defaultrunningnumber).toString() : (inputArray[0].runningnumber ? Number(inputArray[0].runningnumber).toString() : "1"))
      }]
    }
  }

  getConfiguredRunningNumber(sequenceNameString) {
    let obj = this.configuredRunningNumbers.find(ele =>
      (ele.sequencename && ele.sequencename.toString().toLowerCase() == sequenceNameString?.toString().toLowerCase()))
    return obj;
  }

  onDragStart(index: number) {
    this.draggedIndex = index;
    const chip = document.querySelectorAll('.example-box')[index];
    chip?.classList.add('dragging');
  }

  onDragEnd(event: any) {
    const chips = document.querySelectorAll('.example-box');
    chips.forEach(chip => chip.classList.remove('dragging', 'dragging-over')); // Remove the classes after drag ends
  }

  onDragOver(event: DragEvent,groupname: string, index?): void {
    event.preventDefault(); // Allows dropping by preventing the default behavior.

    if (this.draggedIndex !== null && this.draggedIndex !== index && index !== undefined) {
      const draggingChip = document.querySelectorAll('.example-box')[this.draggedIndex];
      const targetChip = event.target as HTMLElement;

      // Add a class to visually highlight where the item is being dragged over
      targetChip.classList.add('dragging-over');
      
      if(groupname == "frmDefaultFormat")
      {
        // Move the dragged chip to its new position in the list (this will be done visually)
        const list = this.defaultChipList.slice(); // Create a shallow copy of the list
        list.splice(index, 0, list.splice(this.draggedIndex, 1)[0]); // Move the dragged item in the array
        this.defaultChipList = list; // Update the list to reflect the new order
      } else {
        const list = this.customChipList.slice(); // Create a shallow copy of the list
        list.splice(index, 0, list.splice(this.draggedIndex, 1)[0]); // Move the dragged item in the array
        this.customChipList = list;
      }
      this.draggedIndex = index; // Update the dragged item’s position
    }
  }

  onDrop(droppedIndex: number, groupName: string): void {
    this.draggedIndex = null;
    // if (this.draggedIndex !== null && this.draggedIndex !== droppedIndex) {
      const chip = document.querySelectorAll('.example-box')[droppedIndex];
      chip?.classList.remove('dragging-over');

      // if (groupName == 'frmDefaultFormat') {
      //   const draggedItem = this.defaultChipList[this.draggedIndex];
      //   this.defaultChipList.splice(this.draggedIndex, 1); // Remove the dragged item
      //   this.defaultChipList.splice(droppedIndex, 0, draggedItem); // Insert the dragged item at the new index
      // } else {
      //   const draggedItem = this.customChipList[this.draggedIndex];
      //   this.customChipList.splice(this.draggedIndex, 1); // Remove the dragged item
      //   this.customChipList.splice(droppedIndex, 0, draggedItem);
      // }
      // this.draggedIndex = null;
      this.formatDraggedItem(groupName);
    // }
  }

  patchBeginsAt() {
    let str = this.seqFormGrp.controls['frmDefaultFormat']['controls'].frmSequenceName.value?.toString();
    let sequenceNumberExist = str ? this.getConfiguredRunningNumber(str) : null;
    if (sequenceNumberExist) {
      this.seqFormGrp.controls['frmDefaultFormat'].patchValue({
        frmBeginsAt: (Number(sequenceNumberExist.lastnumber) + 1).toString()
      });
    }
  }

  create()
  {
    this.addEditScreen = true;
    this.gridScreen = false;
    this.seqTabFormGrp.reset();
    this.seqTabFormGrp.patchValue({
      frmSequenceName: "",
      frmRunningNumber: ""
    })

    Object.keys(this.seqTabFormGrp.controls).forEach(key => {
      this.seqTabFormGrp.controls[key].setErrors(null);
    });
    this.seqTabFormGrp.controls["frmSequenceName"].setValidators([Validators.required]);
    this.seqTabFormGrp.controls["frmSequenceName"].updateValueAndValidity();
  }

  saveSequenceTab()
  {
    let obj = {
      sequencetype: "Accession",
      sequencename: this.seqTabFormGrp.controls.frmSequenceName.value || "",
      runningnumber: this.seqTabFormGrp.controls.frmRunningNumber.value || ""
    }
    this.prevSequence = null;
    this.saveSequenceChanges(obj, "Create");
  }

  disableClearAll() {
    if(this.selected.value == 0)
    {
      return (this.defaultChipList.length > 0 || this.defaultRangeArray.length > 0) ? false : true;
    } else {
      return (this.customChipList.length > 0 || this.customRangeArray.length > 0) ? false : true;
    }
  }

  clearAll()
  {
    this.resetForm(this.selected.value == 0 ? true : false, this.selected.value == 1 ? true : false);
  }
}