<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> -->
<!-- <div style="display: flex;">
<div class="menu" *ngIf="menuJson">

</div>
<div class="divtemplatecontainer">

</div>
</div> -->

<div class="content-wrap" >
  <div class="side-menu">
    <div class="sidemenu" [style.min-height.px]="">
      <div *ngFor="let menu of menuJson; let i = index">
        <!-- <hr class="hrMenu"> -->
        <p *ngIf="menu.Htext!==''" [routerLinkActive]='["link-active"]' class="menuparent cursor-pointer"
          (click)='collapseExpand(i)'>
          <a class="menutext cursor-pointer">
            {{menu.Htext | uppercase }}
          </a>
          <span class="fa fa-minus iconMenu" *ngIf="(!menu.expand && menu.SubMenu.length > 0 )"
            (click)='$event.stopPropagation();collapseExpand(i)'></span>
          <span class="fa fa-plus iconMenu iconMenu" *ngIf="(menu.expand || menu.SubMenu.length == 0)"
            (click)='$event.stopPropagation();collapseExpand(i)'></span>
        </p>
        <div *ngIf="menu.Htext==''">
          <div class="menuparent">
          </div>
        </div>
        <hr class="hrMenu secondhr">
        <div *ngFor="let childmenu of menu.SubMenu;let j=index" class="menuchild">
          <p *ngIf="!menu.expand && childmenu.visible">
            <a *ngIf="j==0" class="expand-menu">
            </a>
            <span class="menutext cursor-pointer"
              [ngClass]="{'rcorners': (menuIndex && menuIndex.mainMenuIndex === i && menuIndex.subMenuIndex === j )}"
              (click)="SubMenuClick(menu.Htext, childmenu, { genericCard: genericCardIdentifier, 'cardtype':cardtype, mainMenuIndex: i,subMenuIndex:j})">
              {{childmenu.URL | uppercase }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div > -->
  <div class="main-card pr-3"
    [ngStyle]="{width:(activityTracker && activityTracker != '') ?  ((activityTracker != 'CASES' && activityTracker != 'New Diagnosis Templates') ? '83%' : '83%'): '58.5%'}">
    <template-container *ngIf="submenuData ||  template ==='vdgenric'" [mainCard]="mainCard"
      [templateData]="templateData" [templateChangedTime]="templateChangedTime">
    </template-container>
  </div>
  <!-- </div> -->

  <!-- activity-tracker -->
  <div class="trackerDiv" *ngIf="activityTracker != 'CASES'" (click)="openActivityTracker()">
    <em class="fa fa-angle-up popout" *ngIf="isOver"></em>
    <button class="trackerButton"
    [class.mat-elevation-z2]="!isOver"[class.mat-elevation-z8]="isOver"  (mouseover)="isOver = true" (mouseleave)="isOver = false">
    <span>Activity Tracker</span>
    </button>
  </div>
  <lib-activity-tracker-lib *ngIf="openActivityTkr" [trackerInput]="trackerInput" [openActivityDialog]="true" [activityPath]="activityPath" (closeActivityDialog)="openActivityTkr = false" [apiUrl]="trackerApiUrl"></lib-activity-tracker-lib>
  <!-- <button mat-fab (click)="activityTracker = !activityTracker;showCollapse = !showCollapse"
    class="activity-tracker-wrap p-0 mt-3" *ngIf="activityTracker != 'CASES'"
    [ngClass]="activityTracker ? 'activity_collapsed':'activity_expand'" [class.mat-mdc-elevation-z2]="!isOver"
    [class.mat-mdc-elevation-z8]="isOver" (mouseover)="isOver = true" (mouseleave)="isOver = false">
    <span class="d-flex">
      <em class="fa fa-angle-right activity-arrow" *ngIf="showCollapse"></em>
      <em class="fa fa-angle-left activity-arrow" *ngIf="!showCollapse"></em>
      <span class="vertical-line"></span>
      <img src="../../../assets/icons/activitytracker.svg" alt="ActivityTracker">
      <span class="ml-1 pl-1 align-center" *ngIf="!activityTracker">Activity Tracker</span>
    </span>
  </button> -->

  <!-- *ngIf="activityTracker" -->
  <div class="activity-tracker" *ngIf="!activityTracker && (activityTracker != 'CASES' && activityTracker != 'New Diagnosis Templates')" style="min-height: 60vh;">

    <mat-tab-group>
      <mat-tab class="dynamicmatlable">
        <ng-template mat-tab-label>
          <span (click)="setActiveTab('All')">All</span>
        </ng-template>
        <div *ngIf="activeTab === 'All'" class="col-sm-12 alltab">
          <app-activity-tracker [searchstring]="activeTab"></app-activity-tracker>
        </div>
      </mat-tab>

      <mat-tab class="custom-tab">
        <ng-template mat-tab-label>
          <span (click)="setActiveTab('Audit')">Audit</span>
        </ng-template>

        <div *ngIf="activeTab === 'Audit'" class="col-sm-12 alltab">
          <app-activity-tracker [searchstring]="activeTab"></app-activity-tracker>
        </div>
      </mat-tab>

      <mat-tab label="Audit" class="custom-tab">
        <ng-template mat-tab-label>
          <span (click)="setActiveTab('Comments')">Comments</span>
        </ng-template>
        <div *ngIf="activeTab === 'Comments'" class="col-sm-12 alltab">
          <app-activity-tracker [searchstring]="activeTab"></app-activity-tracker>
        </div>
      </mat-tab>

      <mat-tab class="custom-tab">
        <ng-template mat-tab-label>
          <span>Incidents</span>
        </ng-template>
        <div class="col-sm-12 alltab align-center mt-4">
          <div class="nodata-design mb-2">
            Incident Data Not Found
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="cmtcontainer col-sm-12 pl-2">
      <div class="input-container col-sm-12 pl-0 pr-0">
        <mat-form-field class="example-full-width pl-1 col-sm-12" appearance="outline">
          <div class="row m-0 d-flex">
            <div class="col-sm-11 p-0">
              <input type="tel" matInput placeholder="Type here ..." (input)="onInputChange($event.target.value)"
                [(ngModel)]="comment" (keydown.enter)="submitComment()" #commentInput>
            </div>
            <div class="col-sm-1 pl-2">
              <i class="fa fa-paper-plane send-icon" [ngClass]="{'visible': isInputValid}" (click)="submitComment()"
                aria-hidden="true"></i>
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
