import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { ThemePalette } from '@angular/material/core/common-behaviors';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, filter } from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from 'src/app/client/DbModel/SubMenuCard/Submenucardmodel';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { ThemePalette, _MatOptionBase, MatOption } from '@angular/material/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { MatSelect } from '@angular/material/select';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';


declare var $;

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-new-manage-rule-outs',
  templateUrl: './new-manage-rule-outs.component.html',
  styleUrls: ['./new-manage-rule-outs.component.scss']
})

export class NewManageRuleOutsComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };
  @ViewChild('matSelectOptionSite') matSite: MatSelect;
  @Input() templateData;
  @Input() templateChangedTime;
  gridHeader = ['SubSite', 'AttributeName', 'SequenceOrder', 'IsActive', 'AttributeContextId', 'AttributeID', 'RuleOutsName', 'Site'];
  columns = [
    new ColDef('SubSite', 'Site'),
    new ColDef('RuleOutsName', 'Name'),
    new ColDef('SequenceOrder', 'Sequence'),
    new ColDef('IsActive', 'Status')//,
  ];
  SubMenuCardModel: SubMenuCardModel;
  gridData: CollectionView;
  gridExcelData: CollectionView;
  showPaginationMainGrid: boolean = false;
  gridwidth: number = 0;
  gridExcelWidth: number = 0;
  hideUpdateBtn = false;
  searchInput: String = '';
  noDataFound: boolean = false;
  srcOrgType: any;
  highlightNotes = true;
  srcOrgName: any;
  organizationid: any;
  gridcolwidth: number;
  postDownload: boolean = false;
  ifGIHistology: boolean = false;
  ifNoSiteFound: boolean = false;
  exportData: any;
  AddEditScreen: boolean = false;
  showBtn: boolean = false;
  headerValue: string;
  oldSiteValue: any;
  frm_isActive: boolean = true;
  siteFound: boolean = false;
  searchSiteInput: string = "";
  selSiteId: string;
  destSiteId: string;
  destSubsiteList = [];
  // filteredOptions: Observable<any[]>;
  checkAllSite: boolean = false;
  attributesName: any;
  selectdSubsite: any;
  srcDeployment: any;
  destDeployment: any;
  searchSiteTextInput: string;
  ifChckdAll: boolean = false;
  ifSiteSel: boolean = false;
  searchsitelist = [];
  checkStatus = false;
  public DeploymentKeys = [];
  submitted = false;
  excelDataArray: any[] = [];
  orgList: any[] = [];
  srcOrgid: any;
  gridExcelArray: any[] = [];
  workBook: any;
  sheetsToSelect: string[];
  sheetHeader: any[];
  sitetoggle: boolean = false;
  showAlert = false;
  gridDisplay: boolean;
  ErrorAlert = "";
  searchSiteID: any = "";
  backBtn: boolean = false;
  postUpload: boolean;
  showInvalidColumns: boolean = false;
  public searchResult: Array<any> = [];
  showDelete: boolean = true;
  invalidColumns: any = '';
  //public excelColumns: any = [];
  selector: Selector = null;
  selectedData: any = [];
  selectedItems: any = [];
  bulkUpload: boolean = false;
  groupedGridShow: any = true;
  copyDataClicked: boolean = false;
  headerVisibility: string = "";
  ShowGrid: boolean = true;
  editBtn: boolean = false;
  ifDupRuleFound: boolean = false;
  copyResdata: any = [];
  private dbname;
  uploadClicked: boolean = false;
  Addscreenduplicatecheck: boolean = false;
  dragDrop : boolean = false;
  // Deletebtn:boolean =true;

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;

  frm_RuleOuts = this._fb.group({
    frm_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_Sequence: 0,
    frm_subsite: [new Array<number>(), Validators.required],
    frm_active : true
  });

  copyRuleOutsForm = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmSites: [""],
    frmDepKey: ["", Validators.required],
    frmDestSite : [""]
  })
  selectedrowData: any;
  dragnDrop: any;
  changeToTrue: boolean;
  private dragIndex: number;
  uploadBtn: boolean;
  createBtn: boolean = false;
  exportBtn: boolean = false;
  copyBtn: boolean = false;
  deletebtn: boolean = false;
  siteFormat: number = 2;
  groupBy: string[] = [];
  listGridheaders: { "Name": string; "type": string; "key": string; "colWidth": string; }[];
  destSites: Array<object> = [] ;
  destSiteSelected: any = null;
  destSiteName: any = null;
  activityEntity: any;
  auditableColumns: any = [];
  frm_RuleOutsClone: any;
  dragItems: any[] = [];
  siteIds: number[] = [];
  sortAZ: boolean = true;


  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService, private dialog: MatDialog, private _fb: FormBuilder, public commonService: CommonService, public vitalHttpServices: VitalHttpServices, public _snackBar: MatSnackBar
    ,public activityService: ActivityTrackerService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare
    );
    this.dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
  }
  MandatoryFields = [{}]
  BulkUpdate = [{}]
  excelColumns = [{}]

  SampleDataBulkUpdate = [{
    RuleOutId: '1234'
    , RuleOutName: 'NAMETEST'
    , SequenceOrder: '1'
    ,Active:'1/0'
  }]
  // SampleDataMandatoryFields=[{
  // RuleOutName:'NAMETEST'
  // ,SiteID:'450'
  // ,Active:"1/0"
  // }]
  SampleDataAllFields = [{
    RuleOutName: 'NAMETEST'
    , SiteID: 'All/450'
    , Active: "1/0"
  }]

  async ngOnInit() {
    this.templateData.submenuData = this.templateData.submenuData || [];
    this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
    this.organizationid = this.templateData.secondarykeys.OrganizationId;
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    await this.getSiteFormat();

    this.getAuditableDetails("Rule Outs")
    this.initialFunctions();
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    this.copyRuleOutsForm.controls.frmOrganization.valueChanges.subscribe(val =>
    {
      if(!val || val?.toString().trim() == '')
        {
          this.copyRuleOutsForm.patchValue({
            frmSites: ''
          })
          this.searchSiteInput = '';
          this.searchSiteID = '';
          this.copyRuleOutsForm.controls.frmSites.disable();
        } else {
          this.copyRuleOutsForm.controls.frmSites.enable();
        }
      }
    )
  }

  clearOrganization() {
    this.copyRuleOutsForm.patchValue({
      frmOrganization: '',
      frmSites: ''
    })
    this.attributesName = [];
  }

  clearSites(dest?) {
    if(!dest){
      this.copyRuleOutsForm.patchValue({
        frmSites: ''
      })
      this.searchSiteID = null;
        this.searchSiteTextInput = "";
        this.searchSiteInput =  "";
    }
    else {
      this.copyRuleOutsForm.patchValue({
        frmDestSite: ''
      })
      this.destSiteSelected = null;
      this.destSiteName = null;
    }
  }

  //RBAC audit
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL.toString().trim() == 'Rule Outs')['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deletebtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  initialFunctions() {
    this.getGrid(true);
    this.GetUserIdInfo();
    this.getDestSubSitesList(this.organizationid, this.dbname);
    this.uploadClicked = false;
    this.listGridheaders  = this.getDataHeaders();
  }

  getDataHeaders(){
    let valueObj = [{
              "Name": "Rule Out Name (ID)",
              "type": "text",
              "key": "DisplayName",
              "colWidth": "col-sm-9"
            },
            {
              "Name": "Site Name",
              "type": "text",
              "key": "Site",
              "colWidth": "col-sm-2 hidden"
            }]
    return valueObj;
  }

  async selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridExcelWidth = -1;
      this.checkAllSite = false;
      this.searchSiteID = '';
      this.searchSiteInput = '';
      this.ifChckdAll = false;
      this.attributesName = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      let siteData = await this.getSites(this.templateData.secondarykeys.casetype,this.srcOrgid,this.srcDeployment);
      this.attributesName = this.assignSiteData(siteData);
      this.checkAllSiteValue({checked : false});
      // this.getSubSitesList(this.srcOrgid, this.srcDeployment);
    }
  }

  initGrid(grid, hitCount?) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  frm_isActiveFunc() {
    this.frm_isActive = this.frm_isActive == false ? true : false;
  }


  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.ifSiteSel = true;
      this.gridExcelWidth = -1;
      this.sitetoggle = false;
      this.destSiteId = data.siteid;
      this.searchSiteID = data.siteid;
      this.searchSiteTextInput = data.sitename;
      this.searchSiteInput = data.sitename;
    }
  }

  selectedDestCopyData(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      // this.ifSiteSel = true;
      this.gridExcelWidth = -1;
      this.destSiteSelected = data.siteid;
      this.destSiteName = data.sitename.toString();
    }
  }

  checkAllSiteValue(e) {
    if (e.checked) {
      this.checkAllSite = true;
      this.searchSiteID = '';
      this.destSiteId = null;
      this.searchSiteTextInput = '';
      this.searchSiteInput = '';
      this.searchSiteInput = '';
      this.searchSiteID = 'all';
      this.ifChckdAll = true;
      this.copyRuleOutsForm.controls["frmSites"].disable();
      this.copyRuleOutsForm.controls["frmDestSite"].disable();
      this.clearSites(1);
    } else {
      this.checkAllSite = false;
      this.destSiteId = null;
      this.searchSiteID = '';
      this.searchSiteInput = '';
      this.copyRuleOutsForm.controls["frmSites"].enable();
      this.copyRuleOutsForm.controls["frmDestSite"].enable();
      this.ifChckdAll = false;
      this.searchSiteTextInput = '';
    }
    this.removeGrid();
    this.disableGetDataBtn();
  }

  GetUserIdInfo() {
    let query = this.vitalHttpServices.GetQuery('userroles');
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: { email: localStorage.getItem("UserEmail") },
    };
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryRequest, this.dbname).subscribe((data) => {
      this.ngxService.stop();
      if (!data.errors && (data != undefined && data.data.submenuData.length > 0)) {
        let userid = data.data.submenuData[0].userid;
        sessionStorage.setItem('Userid', userid);
      }
      else {
        sessionStorage.setItem('Userid', '');
      }
    },
      (error) => {
        this.ngxService.stop();
        console.error(error);
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      }
    );
  }

  async editRuleOuts(editData) {
    if (!this.editBtn) {
      let siteData = await this.getSites(this.templateData.secondarykeys.casetype,sessionStorage.getItem('org_id'));
      this.attributesName = this.assignSiteData(siteData);
      this.checkDuplicateRuleOut();
      this.Addscreenduplicatecheck = true;
      this.clearForm();
      this.AddEditScreen = true;
      this.showBtn = false;
      this.headerValue = "Edit Rule Out";
      //patch value to screen
      this.selectedrowData = editData;
      this.frm_isActive = this.selectedrowData.IsActive ? true : false
      this.frm_RuleOuts.patchValue({
        frm_name: this.selectedrowData.RuleOutName,
        frm_Sequence: this.selectedrowData.Sequence,
        frm_subsite: !editData.Site.match(/all sites/i) ? [ Number(this.selectedrowData.SiteId)] : siteData.map(va=> va.SiteID),
        frm_active : this.frm_isActive
        });
      this.frm_RuleOutsClone = JSON.parse(JSON.stringify(this.frm_RuleOuts.value));
      this.selectdSubsite = this.selectedrowData.AttributeContextId;
      if(this.selectedrowData.SiteId?.toString().toLowerCase() !=='not specified'){
        this.activityEntity.entityId = this.selectedrowData.SiteId?.toString().toLowerCase() !=='not specified' ?this.selectedrowData.SiteId.toString():"";
      }else{
        this.activityEntity.entityId = this.selectedrowData.RuleOutId?.toString();
      }
      this.activityService.setActivitySession(this.activityEntity);
    }
  }

  disableSites(){
    return this.headerValue?.match(/edit/i) ? true : false;
  }

  expotGridData() {
    let casetype = this.templateData.secondarykeys.casetype;
    let filename;
    if (casetype.toString()) {
      filename = 'Rule Outs_' + this.organizationid.toString() + '.xlsx';
    }
    else {
      filename = 'Rule Outs_' + this.organizationid.toString() + '.xlsx';
    }
    var sheet = XLSX.utils.book_new();
    let array = [];
    // this.exportData = this.exportData.reverse();
    for (let itr = 0; itr < this.exportData.length; itr++) {
      array.push(this.exportData[itr]);
    }
    let sheetData = XLSX.utils.json_to_sheet(array);
    XLSX.utils.book_append_sheet(sheet, sheetData, 'OrganizationRuleOut');
    XLSX.writeFile(sheet, filename);
  }

  // Export Grid data
  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["notes"];
      excel.push(e._data);
    });
    let filename = 'Users_' + this.organizationid + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'users');
    XLSX.writeFile(wb, filename);
  }

  uploadRuleOUts() {
    if (!this.uploadBtn) {
      this.uploadClicked = true;
      this.AddEditScreen = false;
      this.ShowGrid = false;
      this.gridExcelArray = [];
      this.gridExcelData = new CollectionView([]);
      this.gridExcelWidth = 0;

      let queryVariable = { tablename: 'OrganizationAttributes' };
      let query = this.vitalHttpServices.GetQuery("getBulkUpdateTableColumns");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vitalHttpServices.GetData(queryResult, this.dbname).subscribe(data => {
        let res = data.data.submenudata;
        let allFields = {};
        // for (var i = 0; i < res.length; i++) {
        //   if (res[i].allowedcolumn == 'AttributeName' || res[i].allowedcolumn == 'AttributeContextId') {
        //     allFields['RuleOutName'] = '';
        //     allFields['SiteID'] = '';
        //     allFields.
        //   }
        // }
        this.excelColumns[0]['RuleOutName'] = ''
        this.excelColumns[0]['SiteID'] = ''
        this.excelColumns[0]['Active'] = ''
        //mandatory
        this.MandatoryFields[0]['RuleOutName'] = ''
        this.MandatoryFields[0]['SiteID'] = ''
        this.MandatoryFields[0]['Active'] = ''
        //Bulk update
        this.BulkUpdate[0]['RuleOutId'] = ''
        this.BulkUpdate[0]['RuleOutName'] = ''
        this.BulkUpdate[0]['SequenceOrder'] = ''
        this.BulkUpdate[0]['Active']=''
      });
    }
  }

  // Download Mandatory Fields
  downloadExcelSheet() {
    let filename = 'Rule Outs_' + 'All Fields_' + this.organizationid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.excelColumns);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationRuleOuts');
    ws = XLSX.utils.json_to_sheet(this.SampleDataAllFields);
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }
  // downloadMandatoryFields(){
  //   let filename ='Rule Out_'+'Minimal Fields_' + this.organizationid.toString() + '.xlsx';
  //   var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
  //   var wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationAttributes');
  //   ws = XLSX.utils.json_to_sheet(this.SampleDataMandatoryFields);
  //   XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
  //   XLSX.writeFile(wb, filename);
  // }
  downloadBulkUpdate() {
    let filename = 'Rule Out_' + 'Bulk Update_' + this.organizationid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.BulkUpdate);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationRuleOuts');
    ws = XLSX.utils.json_to_sheet(this.SampleDataBulkUpdate);
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);

  }

  async onFileDropped(ev: any) {
    const file = ev.target ? ev.target.files[0] : ev[0];
    const filename = file.name;
    const splitArray = filename.split('.');
    const fileExtension = splitArray[splitArray.length - 1].toUpperCase();

    if (fileExtension !== 'XLSX' && fileExtension !== 'XLS') {
      this._snackBar.open('Please upload an excel file only.', 'Close');
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e: any) => {
      try {
        const arrayBuffer = e.target.result;
        const bytes = new Uint8Array(arrayBuffer);
        let binary = "";
        for (let i = 0; i < bytes.byteLength; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        const workbook = XLSX.read(binary, { type: 'binary' });

        // Filter out sheets that match 'sampledata' (case insensitive)
        this.sheetsToSelect = workbook.SheetNames.filter(
          (sheetName) => !sheetName.toLowerCase().includes('sampledata')
        );

        // Find and process the 'OrganizationAttributes' worksheet
        let worksheet;
        workbook.SheetNames.find((sheetName) => {
          if (sheetName.toLowerCase() === 'organizationruleouts') {
            worksheet = workbook.Sheets[sheetName];
            return true; // Exit the loop after finding the sheet
          }
          return false;
        });

        if (!worksheet) {
          this._snackBar.open('Sheet "OrganizationRuleOuts" not found.', 'Close');
          return;
        }

        // Convert worksheet to JSON
        let temp : any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Check if the uploaded file is empty (excluding header)
        if (temp.length <= 1 || temp.slice(1).every((row : Array<string>) => row.every((str) => str === ''))) {
          this._snackBar.open('The uploaded file is empty!', 'Close');
          return;
        }

        // Process headers and prepare FormData
        temp[0] = temp[0].reverse();
        temp[0].push("ResultMessage");
        temp = temp[0].filter((value) => value); // Remove empty values

        const formData = new FormData();
        const additionalData = {
          sheetNames: ['OrganizationRuleOuts'],
          columns: temp,
          additionalAttributes: {
            tablename: 'OrganizationRuleOuts',
            organizationid: this.templateData.secondarykeys.OrganizationId,
            casetype: this.templateData.cardtype.toString()
          }
        };

        // Append file and additional data to FormData
        formData.append('file', file);
        formData.append('additionalAttributes', JSON.stringify(additionalData));

        if (this.fileDropRef) {
          this.fileDropRef.nativeElement.value = ''; // Reset the input
        }

        this.ngxService.start("validateExcel");

        // Example: Call HTTP service to validate data
        const response = await this.vitalHttpServices.getExcelData(formData, this.dbname).toPromise();

        if (response.content?.length) {
          this.gridExcelWidth++;
          this.sheetHeader = temp;
          this.gridExcelArray = response.content;
          this.excelDataArray = response.content;
          this.gridExcelData = new CollectionView(response.content);
        }
        else this._snackBar.open("Something went wrong!" , 'Close');
      } catch (error) {
        this._snackBar.open('Error uploading data' , 'Close');
        console.error('Error uploading file:', error);
      } finally {
        this.ngxService.stop("validateExcel");
      }
    };

    reader.onerror = (error) => {
      this._snackBar.open('Error reading file', 'Close');
      console.error('File reading error:', error);
    };

    reader.readAsArrayBuffer(file);
  }


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
   if(result){
      this.convertToJson(result)
    }
  }

  //Method to validate sheet data.
  convertToJson(sheetname) {
    this.excelDataArray = [];
    let tempExcelArr = [];
    if (this.uploadClicked) {
      var worksheet;
      this.workBook.SheetNames.find(e => {
        if (e.toLowerCase() == sheetname.toLowerCase()) {
          worksheet = this.workBook.Sheets[e];
        }
      });
      tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
      if (tempExcelArr.length == 0) {
        this._snackBar.open("The uploaded excel does not contain any data", "Close");
        tempExcelArr = [];
        return;
      }
      if (tempExcelArr[0].RuleOutName == "" || tempExcelArr[0].RuleOutName == null) {
        this._snackBar.open("The uploaded excel does not contain any data", "Close");
        tempExcelArr = [];
        return;
      }
    }

    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse();
    temp[0].push("ResultMessage");
    this.sheetHeader = temp[0];
    if (this.sheetHeader.filter(element => element == 'AttributeID').length > 0) {
      this.bulkUpload = true;
    }
    else {
      this.bulkUpload = false;
    }
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if(key.toString().match(/Active/i))
          {
            value = (value != null && value != '') ? (value ? true : false) : true;
          }
          if(key.toString().toLowerCase().match(/ruleoutname/i))
          {
            if(value=='' || value==null)
            {
              this._snackBar.open("Mandatory field is missing!")
              break;
            }
          }
          if(key.match(/attributeid/i))
          {
            if(value=='' || value==null)
            {
              this._snackBar.open("Mandatory field is missing!")
              break;
            }
          }
          primary[key] = value;
        }
      }
      primary["destselected"] = 1;
      tempArray.push(primary)

      primary = {}
    }

    for(let i =0 ; i<tempArray.length ;i++){
      if(!this.copyDataClicked)
      {
        if(!tempArray[i]['AttributeID'] && tempArray.some((va,index)=> va['SiteID'] == tempArray[i]['SiteID'] && va['RuleOutName'].toString().toLowerCase() == tempArray[i]['RuleOutName'].toString().toLowerCase() && index < i)){
          tempArray[i]['ResultMessage'] = 'Duplicate data found in Excel';
        }
        if(tempArray[i]['AttributeID'] && tempArray.some((va,index)=> va['AttributeID'] == tempArray[i]['AttributeID'] && index != i)){
          tempArray[i]['ResultMessage'] = 'Duplicate data found in Excel';
        }
      }
    }

    tempExcelArr = tempArray;
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    this.excelDataArray.sort((a, b) => a.groupname < b.groupname ? -1 : a.groupname > b.groupname ? 1 : 0)
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, tablename: 'OrganizationAttributes', organizationid: this.templateData.secondarykeys.OrganizationId, attributecontext: this.templateData.cardtype.toString() });
      i++;
    });
    this.validateExcelData(this.excelDataArray);
  }


  // Validate Excel
  async validateExcelData(excelArr) {
    try {
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(excelArr);
      this.invalidColumns = '';
      this.showInvalidColumns = false;
      let validData = dataArray.filter(va=> !va.resultmessage);
      ///// if the data is less than 10k records then validating each subsiteids
      this.ngxService.start("ValidateExcelData");
      // if (validData.length && validData.length < 10000) {
        await this.vitalHttpServices.ValidateBulkRuleOuts(validData, this.dbname).toPromise().then(result => {
          if (result.content && result.content.length) {
            result = result.content;
            if (result.length > 0) {

              if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
                this.showInvalidColumns = true;
                this.invalidColumns = result[0].InvalidColumns;
              }
              this.showDelete = true;
              dataArray.filter(e => {
                result.filter(r => {
                  if (r.SlNo === e.slno) {
                    e.resultmessage = r.NoteMessage;
                  }
                });
              });

              this.AddExcelGridData(dataArray);
            } else {
              this.gridExcelWidth = 0;
            }
          } else {
            this._snackBar.open("Something went wrong!", "Error");
          }
        });
      // } else {
      //   if(validData.length >= 10000){
      //     dataArray.filter(e => {
      //       e.notes = "Not able to validate due to large number of records";
      //     });
      //   }
        // this.AddExcelGridData(dataArray);
      //
    }
    catch (error) {
      console.error(error);
      this._snackBar.open("Something went wrong!", "Error!");
    }
    finally {
      this.ngxService.stop("ValidateExcelData");
    }
  }



  //#region
  AddExcelGridData(data) {
    this.gridExcelArray = [];
    this.gridData = new CollectionView([]);
    this.noDataFound = false
    this.gridDisplay = true;
    let gridArray = [];
    this.gridExcelData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        this.ngxService.start();
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          let checkDataType = false
          let dataTypeCol;
          for (let [key, value] of Object.entries(data[i])) {
            let flag = false;


            if (key.toLowerCase() == 'isactive') {
              if (value == 'true' || value == true) {
                value = true
              } else if (value == 'false' || value == false || !value) {
                value = false
              }
              primary[key] = value;
            }

            if (key.toLowerCase() == 'casetype') {
              value = this.templateData.secondarykeys.casetype.toString();
              primary[key] = value;
            }

            if (key.toLowerCase() == 'resultmessage') {
              let str = value.toString();
              if (str.includes(";")) {
                str = str.replace(/;/g, "");
                value = str;
              } else {
                value = value;
              }
            }

            primary[key] = value;
          }
          this.gridExcelArray.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector.column.grid.headersVisibility = HeadersVisibility.Column
        }
        this.ngxService.stop();
        this.gridExcelData = new CollectionView(this.gridExcelArray);
        this.showPaginationMainGrid = false;
        // if (this.gridExcelArray.length > 10) {
        //   this.showPaginationMainGrid = true;
        // } else {
        //   this.showPaginationMainGrid = false;
        // }
        this.gridExcelWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridExcelWidth > 1300) {
          this.gridExcelWidth = 1300;
        }
      }
    }
  }

  async addRuleOuts() {
    if (!this.createBtn) {
      this.Addscreenduplicatecheck = false;
      this.AddEditScreen = true;
      this.showBtn = true;
      this.headerValue = "Create Rule Outs";
      this.frm_isActive = true;
      this.clearForm();
      this.checkDuplicateRuleOut();
      let siteData = await this.getSites(this.templateData.secondarykeys.casetype,sessionStorage.getItem('org_id'));
      this.attributesName = this.assignSiteData(siteData);
      this.frm_RuleOuts.reset();
      this.frm_RuleOuts.patchValue({frm_subsite : []});
    }
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  getSubSitesList(orgId, dbName) {
    //get sites list for the casetype
    let query = this.vitalHttpServices.GetQuery('SubSitesListForRuleOuts');
    let queryVariable = { orgid: orgId.toString(), casetype: this.templateData.secondarykeys.casetype }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetSubSiteDate(this.templateData.secondarykeys.casetype, orgId, dbName).subscribe(
      (Resdata) => {
        this.attributesName = [];
        if (Resdata.content && Resdata.content.length > 0) {
          this.attributesName = JSON.parse(JSON.stringify(Resdata.content));
        }
        this.ngxService.stop();
      },
      (error) => {
        console.error(error);
        this.ngxService.stop();
      });
  }

  async getSiteFormat() {
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype };
    let query = this.SubMenuCardModel.GetQuery('casetypesiteformat');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices
      .GetData(queryResult)
      .toPromise()
      .then(
        data => {
          if (!data.errors) {
            if (data) {
              this.siteFormat = data.data.submenuData[0].SiteFormat ? data.data.submenuData[0].SiteFormat : 2;
            }
          }
        })
  }
  async getSites(casetype,orgid,srcDeployment?) {
    let queryVariable = { casetype: casetype, orgid: orgid.toString() };
    let sitedata;
    let query = this.SubMenuCardModel.GetQuery(this.siteFormat == 2 ? "subsitesoncasetype" : 'bodysitesOnCasetype');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    try {
      sitedata = await this.vitalHttpServices.GetData(queryResult,srcDeployment ?? this.dbname).toPromise();
    }
    catch (err) {
      console.error(err);
    }
    return (sitedata ? sitedata.data.submenuData : []);
  }

  assignSiteData(siteData) {
    siteData = siteData.map(va => ({ ...va, sitename: this.siteFormat == 2 ? (va.SubSite.toString() + ' (' + va.BodySite.toString() + ') ') : va.BodySite }))
    siteData = this.convertObjKeysToLower(siteData);
    return siteData;
  }

  getDestSubSitesList(orgId, dbName) {
    //get sites list for the casetype
    let query = this.vitalHttpServices.GetQuery('SubSitesListForRuleOuts');
    let queryVariable = { orgid: orgId.toString(), casetype: this.templateData.secondarykeys.casetype }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetSubSiteDate(this.templateData.secondarykeys.casetype, orgId, dbName).subscribe(
      (Resdata) => {
        if (!Resdata.errors) {
          if (this.copyDataClicked) {
            let attr = [];
            attr = JSON.parse(JSON.stringify(Resdata));
            if (this.copyDataClicked) {
              for (var i = 0; i < attr.length; i++) {
                if (attr[i].SiteName.toLowerCase() == this.searchSiteInput.toLowerCase()) {
                  this.destSiteId = attr[i].SiteID;
                  break;
                }
              }
            }
            if (this.destSiteId == undefined) {
              this.destSiteId = "";
            }
          } else {
            this.attributesName = [];
            this.attributesName = JSON.parse(JSON.stringify(Resdata));
          }

        }
        this.ngxService.stop();
      },
      (error) => {
        console.error(error);
        this.ngxService.stop();
      });
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  CheckIfOnlyNumber(value) {
    if (value && value != '') {
      const pattern = /[0-9]/;
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (flag) {
          let charcode = value.charCodeAt(i)
          const inputChar = String.fromCharCode(charcode);
          if (!pattern.test(inputChar)) {
            flag = false;
          }
        }
        else {
          return false;
        }
      }
    }
    return true;
  }

  public ruleList = [];
  checkDuplicateRuleOut() {
    let query = this.vitalHttpServices.GetQuery('newmanageruleouts');
    let queryVariable = { orgid: this.templateData.GroupData.OrganizationId.toString(), casetype: this.templateData.secondarykeys.casetype }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.dbname).subscribe(
      (Resdata) => {
        this.ngxService.stop();
        this.ruleList = Resdata.data.submenuData;
      });
  }
  public subsite: any;

  checkForDup(flag) {
    let ifDupFound = false;
    this.frm_RuleOuts.controls['frm_name'].setValue(this.frm_RuleOuts.controls['frm_name'].value.trim());
    if(!this.frm_RuleOuts.value.frm_name || !this.frm_RuleOuts.value.frm_subsite.join(',') ){
      this._snackBar.open('Please enter all mandatory fields before saving!','Close');
      return;
    }

    for (var i = 0; i < this.ruleList.length; i++) {
      if (this.frm_RuleOuts.value.frm_subsite && this.frm_RuleOuts.value.frm_subsite.includes(this.ruleList[i].AttributeContextId)) {
        if (this.ruleList[i].RuleOutName.toLowerCase() == this.frm_RuleOuts.value.frm_name.toLowerCase() && this.ruleList[i].IsActive == this.frm_isActive) {
          ifDupFound = true;
          break;
        }
      }
    }
    for (var j = 0; j < this.attributesName.length; j++) {
      if (this.frm_RuleOuts.value.frm_subsite && this.frm_RuleOuts.value.frm_subsite.includes(this.attributesName[j].siteid)) {
        this.subsite = this.attributesName[j].site;
        break;
      }
    }
    if (this.Addscreenduplicatecheck) {
      if (ifDupFound) {
        this.ifDupRuleFound = true;
        let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '498px',
          data: { header: "", message: "", alert: "Rule out with the same name already exists for the site -" + this.subsite + ". Do you still want to continue adding the same?", continue: "Yes", cancel: "No" }
        });
        return dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            this.Addscreenduplicatecheck = false;
            this.saveRuleOut(flag);
          }
          else {
            this.Addscreenduplicatecheck = true;
            return
          }
        },
          error => {
            console.error(error);
          });

      } else {
        this.Addscreenduplicatecheck = false;
        this.ifDupRuleFound = false;
        this.saveRuleOut(flag);
      }
    } else {
      this.Addscreenduplicatecheck = false;
      this.ifDupRuleFound = false;
      this.saveRuleOut(flag);
    }
  }

  saveRuleOut(flag) {
    this.submitted = true;
    this.showAlert = false;
    this.ErrorAlert = "";

    if (!this.frm_RuleOuts.value.frm_subsite || this.frm_RuleOuts.value.frm_subsite.join(',') == "") {
      this.showAlert = true;
      this.ErrorAlert = "Enter Valid site";
      return;
    }
    else if (!this.frm_RuleOuts.value.frm_name || this.frm_RuleOuts.value.frm_name.toString() == "") {
      this.showAlert = true;
      this.ErrorAlert = "Enter Valid Rule Out Name";
      return;
    }
    else if (this.frm_RuleOuts.value.frm_Sequence && !this.CheckIfOnlyNumber(this.frm_RuleOuts.value.frm_Sequence)) {
      this.showAlert = true;
      this.ErrorAlert = "Enter Valid Sequence";
      return;
    }

    let seqnum;
    if (this.frm_RuleOuts.value.frm_Sequence != null &&  this.frm_RuleOuts.value.frm_Sequence != undefined) {
      seqnum = this.frm_RuleOuts.value.frm_Sequence;
    } else if (this.frm_RuleOuts.value.frm_Sequence == 0) {
      seqnum = 0;
    } else {
      seqnum = null;
    }
    if (this.headerValue.toString().toLowerCase() == 'edit rule out') {
      if (this.frm_RuleOuts.value.frm_subsite[0] != this.selectdSubsite) {
        seqnum = null;
      }
    }
    let bgArr = [];
    let obj
    let attributecontextid;
    // if(!this.frm_RuleOuts.value.frm_subsite.length || this.frm_RuleOuts.value.frm_subsite.length == this.attributesName.length){
    //   attributecontextid = "-1";
    //   obj = {
    //     "Action": this.headerValue && this.headerValue.toString().toLowerCase(),
    //     "CreatedBy": (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
    //     // "CreatedBy": this.headerValue && this.headerValue.toLowerCase() == 'add' ?
    //     //   (sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ?
    //     //     sessionStorage.getItem('Userid') : "-100") : "-100",
    //     "ModifiedBy": (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
    //     "SequenceOrder": seqnum,
    //     "attributecontextid": "-1",
    //     "attributename": this.frm_RuleOuts.value.frm_name.toString() ?? "",
    //     "attributecontext": this.templateData.cardtype.toString() ?? "",
    //     "organizationid": this.templateData.GroupData.OrganizationId.toString() ?? "-100",
    //     "Status": this.frm_isActive && this.frm_isActive.toString() == "true" ? "1" : "0",
    //     "AttributeID": this.headerValue && this.headerValue.toLowerCase() == 'edit rule out' ? this.selectedrowData.AttributeID.toString() : "-1"

    //   }
    //   bgArr.push(obj);

    // }
    // else {
      attributecontextid = this.frm_RuleOuts.value.frm_subsite.join(',');
      if(this.frm_RuleOuts.value.frm_subsite.length != this.attributesName.length)
      {
        this.frm_RuleOuts.value.frm_subsite.forEach((site,index) => {
          obj = {
            "CreatedBy": (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
            // "CreatedBy": this.headerValue && this.headerValue.toLowerCase() == 'add' ?
            //   (sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ?
            //     sessionStorage.getItem('Userid') : "-100") : "-100",
            "ModifiedBy": (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
            "sequenceorder": this.frm_RuleOuts.value.frm_Sequence,
            "siteid": site ?? "-1",
            "ruleoutname": this.frm_RuleOuts.value.frm_name.toString().trim() ?? "",
            "casetype": this.templateData.cardtype.toString().trim() ?? "",
            "organizationid": this.templateData.GroupData.OrganizationId.toString() ?? "-100",
            "active": this.frm_isActive && this.frm_isActive.toString() == "true",
            "ruleoutid": this.headerValue && this.headerValue.toLowerCase() == 'edit rule out' ? this.selectedrowData.RuleOutId.toString() : null,
            "isvalid" : true,
            "slno" :  index + 1
          }

          bgArr.push(obj);
        })

      }
      else {
        obj = {
          "createdby": (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
          // "CreatedBy": this.headerValue && this.headerValue.toLowerCase() == 'add' ?
          //   (sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ?
          //     sessionStorage.getItem('Userid') : "-100") : "-100",
          "modifiedby": (sessionStorage.getItem('Userid') == "null" || sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined) ? -100 : sessionStorage.getItem('Userid'),
          "sequenceorder": this.frm_RuleOuts.value.frm_Sequence,
          "siteid": "All",
          "ruleoutname": this.frm_RuleOuts.value.frm_name.toString().trim() ?? "",
          "casetype": this.templateData.cardtype.toString().trim() ?? "",
          "organizationid": this.templateData.GroupData.OrganizationId.toString() ?? "-100",
          "active": this.frm_isActive && this.frm_isActive.toString() == "true",
          "ruleoutid": this.headerValue && this.headerValue.toLowerCase() == 'edit rule out' ? this.selectedrowData.RuleOutId.toString() : null,
          "isvalid" : 1,
          "slno" : 1
        }
        bgArr.push(obj);
      }
    // }
    this.ngxService.start('ManageRuleOuts1');
    this.vitalHttpServices.InsertRuleOuts(bgArr, this.dbname).subscribe(res => {

      if (res.content && res.content.length) {
        res = res.content
        if(!res[0].isSuccess){
          this.ngxService.stop('ManageRuleOuts1');
          this._snackBar.open(res[0].resultmessage,'Close')
          return;
        }
        if (res[0].resultmessage.match(/Success/i)) {
          if (flag == 'close') {
            this.refreshGrid();
          }
          else if (flag == 'add') {
            this.addRuleOuts();
            Object.keys(this.frm_RuleOuts.controls).forEach(key => {
              this.frm_RuleOuts.controls[key].setErrors(null)
            });

            obj["attributecontextid"] = attributecontextid;
            // this.commonService.createActivityTracker('Add Rule Outs', -1, 'Rule Out', 'Audit', obj, {});
          }
          this._snackBar.open(`Rule out ${ this.headerValue.match(/edit/i) ? 'updated' : 'created' } successfully!`, "Success!");

          let auditCol = JSON.parse(JSON.stringify(this.auditableColumns));
          delete auditCol["casetype"];
          bgArr.forEach(va=>{
            let value = !String(va.siteid).match(/all sites/i) ?  String(va.siteid) : 'All';
            if(/[0-9]+/g.test(value)) {
              let atr = this.attributesName.filter(val=> val.siteid == Number(va.siteid))
              if(this.selectedrowData)
              {
                this.selectedrowData["sequenceorder"] = this.selectedrowData['Sequence']?.toString();
                this.selectedrowData["active"] = this.selectedrowData['IsActive'] ? 'Active' : 'Inactive';
                this.selectedrowData["site"] = atr[0].sitename
                this.selectedrowData["Site"] =  this.selectedrowData["site"];
              }
              va["site"] =  atr[0].sitename
            } else {
              if(this.selectedrowData)
              {
                this.selectedrowData["active"] = this.selectedrowData['IsActive'] ? 'Active' : 'Inactive';
                this.selectedrowData["site"] =  'All';
                this.selectedrowData["Site"] =  this.selectedrowData["site"];
                this.selectedrowData["sequenceorder"] = this.selectedrowData['Sequence']?.toString();
              }
              va["site"] =  'All'
            }
            va["active"] = va["active"] ? 'Active' : 'Inactive';
            if(va.ruleoutid)
            {
              let oldObjArr = this.selectedrowData ? this.commonService.convertObjKeysToLower([this.selectedrowData]) : []
              oldObjArr[0].ruleoutid = oldObjArr[0].ruleoutid?.toString();
              let tempType=va.siteid.toString().toLowerCase() !=='all'? 'siteid':'ruleoutid';
              this.commonService.auditDetails(tempType,'ruleoutname', oldObjArr, [va], 'Edit', this.templateData,auditCol);
            } else {
              va.ruleoutid = 0;
              delete auditCol.sequenceorder
              let tempType=va.siteid.toString().toLowerCase() !=='all'? 'siteid':'ruleoutid'
              this.commonService.auditDetails(tempType,'ruleoutname', [va], [va], 'Create', this.templateData,auditCol);
            }
          })

          //   this._snackBar.open("Rule out updated successfully!", "Success!");
          // } else {
          //   res[0]["attributeid"] = res[0]["AttributeID"];
          //   res[0]["attributename"] = obj["attributename"];
          //   res[0]["sequenceorder"] = obj["sequenceorder"];
          //   res[0]["status"] = obj["Status"] ? 'Active' : 'InActive';
          //   obj["Status"] = obj["Status"] ? 'Active' : 'InActive';
          //   attributecontextid.split(',').forEach(va=> {
          //     let filteredData = this.attributesName.filter(val=> val.siteid == Number(va))
          //     if(filteredData && filteredData.length){
          //       res[0]["site"] = filteredData[0].sitename;
          //       obj["site"] = filteredData[0].sitename
          //       obj["status"] = obj["Status"] ? 'Active' : 'InActive';
          //       obj = this.convertObjKeysToLower([obj])
          //       res = this.convertObjKeysToLower([res])
          //       delete auditCol["sequenceorder"];
          //       this.commonService.auditDetails('attributeid','attributename',res[0], obj, 'Create',this.templateData,auditCol);
          //     }
          //   })
          //   this._snackBar.open("Rule out created successfully!", "Success!");
          // }
          this.ngxService.stop('ManageRuleOuts1');
        }
        else {
          if (res[0].resultmessage == "Rule out with the same name already exists for the site") {
            this.ngxService.stop('ManageRuleOuts1');
            this._snackBar.open("Rule out with the same name already exists for a selected site","Close")
          }
          else {
            console.error(res.Message);
            this.ngxService.stop('ManageRuleOuts1');
            this._snackBar.open("Error occured while processing the data","Close")
          }
          if(res[0].resultmessage != "Rule out with the same name already exists for the site")
          {
          if (flag == 'close') {
            this.refreshGrid();
          }
          else if (flag == 'add') {
            this.addRuleOuts();
            Object.keys(this.frm_RuleOuts.controls).forEach(key => {
              this.frm_RuleOuts.controls[key].setErrors(null)
            });
          }
        }
      }
      }
      else {
        this.ngxService.stop('ManageRuleOuts1');
        this._snackBar.open("Error occured while processing the data","Close");
      }
      this.submitted = false;

    }, (error) => {
      console.error(error);
      this.ngxService.stop('ManageRuleOuts1');
      this._snackBar.open("Error occured while processing the data","Close")
      this.submitted = false;
    });
  }

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.gridExcelArray[i]['resultmessage'] && (this.gridExcelArray[i]['resultmessage'].toString().match(/Rule Out already exists!/i) || this.gridExcelArray[i]['resultmessage'].toString().match(/Attribute Name column value is missing!/i)) || this.gridExcelArray[i]['resultmessage'].toString().match(/InValid AttributeContextID/i) || this.gridExcelArray[i]['resultmessage'].toString().match(/AttributeContextID column value is missing!/i) || this.gridExcelArray[i]['resultmessage'].toString().match(/Mandatory fields missing!/i)) {
        existsCount++;
      }
    }
    if (existsCount == this.gridExcelArray.length) {
      return true
    }
    return false;
  }

  async backToGrid() {
    // this.ngxService.start();
    // this.clearForm();
    // this.ngxService.stop();
    //this.AddGridData();
    await this.getRuleOutlist();
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.AddEditScreen = false;
  }

  clearForm() {
    this.submitted = false;
    this.showAlert = false;
    this.ErrorAlert = "";
  }

  removeGridGrouping() {
    this.ShowGrid = false;
    this.groupedGridShow = false;
    this.getGrid(false);
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  getGrid(isGrouped: Boolean) {
    let gridarray = []
    this.exportData = JSON.parse(JSON.stringify(this.templateData.submenuData));

    gridarray = this.templateData.submenuData?.map(item => {
      let primary = {};

      // Iterate over each key-value pair in the current item
      for (let [key, value] of Object.entries(item)) {
        primary[key] = value || 'Not Specified';
      }

      return primary;
    });
    gridarray.sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
    gridarray = isGrouped ? this.getGroupedData(gridarray) : [ { DataList : gridarray }];
    this.groupBy = ["Site"];
    this.gridData = new CollectionView(gridarray);
    this.ShowGrid = true;
  }

 getGroupedData(arrayData) {
    if(!arrayData.length) return arrayData;
    const hierData = new Map(); // Using a Map to store grouped data

    const allsiteData = arrayData.filter(va=> va.Site.match(/all site/i))
    allsiteData.forEach(item => {
      const key = (this.siteFormat == 2)
      ? `${item.Site}${(item.ParentSite && item.ParentSite.toLowerCase() !== 'not specified') ? ' (' + item.ParentSite + ')' : ''}`
      : item.Site;


        if (!hierData.has(key)) {
            hierData.set(key, []); //Setting key as site name
        }

        hierData.get(key).push(item);
    });

    arrayData.forEach(item => {
      if(!item.Site.match(/all site/i)){
        const key = (this.siteFormat == 2) ? `${item.Site} ${item.ParentSite ? '(' + item.ParentSite + ')' : ''}` : item.Site; //Checking site format

        if (!hierData.has(key)) {
            hierData.set(key, []); //Setting key as site name
        }

        hierData.get(key).push(item); // Adding data matching the key set previously
      }
    });

    return (Array.from(hierData, ([Name, DataList]) => ({ Name, DataList })).sort((a, b) => a.Name.localeCompare(b.Name))); // return an array
  }

  onRowDragging(s: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    this.dragIndex = e.row;
    s.collectionView.moveCurrentToPosition(this.dragIndex);
  }

  onRowDragged(s: wjGrid.FlexGrid, e: wjGrid.CellRangeEventArgs) {
    let arr = s.collectionView.sourceCollection;
    let dropIndex = e.row;
    let dragId: number;
    let dropId: number;

    if (arr[this.dragIndex].SiteName.toLowerCase() == arr[dropIndex].SiteName.toLowerCase()) {//when not grouped

      let dragItem = arr[this.dragIndex];
      let dropItem = arr[dropIndex];
      s.collectionView.deferUpdate(() => {
        dragId = dragItem.AttributeID;
        dropId = dropItem.AttributeID;
        arr.splice(this.dragIndex, 1);
        arr.splice(dropIndex, 0, dragItem);
        s.collectionView.moveCurrentToPosition(dropIndex);
      });
      // drag and drop site is not matching, not allowed to switch
      if (dragId && dropId) {
        let obj = {
          dragId: dragId,
          dropId: dropId,
          orgid: this.templateData.secondarykeys.OrganizationId,
          casetype: this.templateData.secondarykeys.casetype
        }
        this.dragnDrop = true
        if (this.dragnDrop) {
          let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
            disableClose: true,
            autoFocus: false,
            width: '500px',
            data: { header: "", message: "", alert: "The order in which the " + this.templateData.menuURL.toString().trim() + " are listed will be changed. Do you want to continue?", continue: "Yes", cancel: "No" }
          });
          return dialogRef.afterClosed().toPromise().then((result) => {
            if (result) {  //Change position in view level if we want/(yes)
              this.ngxService.start();
              this.vitalHttpServices.updateRuleOutsSequence(obj).subscribe((res) => {
                if (res && res.Success == true) {
                  this.ngxService.stop();
                  this._snackBar.open(res.Message, 'Close');
                  this.refreshGrid();
                } else {

                  // Change position in view level if update fails
                  s.collectionView.deferUpdate(() => {
                    arr.splice(dropIndex, 1);
                    arr.splice(this.dragIndex, 0, dragItem);
                    s.collectionView.moveCurrentToPosition(this.dragIndex);
                  });
                  this.ngxService.stop();
                  this._snackBar.open("An error occurred while processing your request", "Failed");
                }
              }, error => {
                // Change position in view level if update fails
                s.collectionView.deferUpdate(() => {
                  arr.splice(dropIndex, 1);
                  arr.splice(this.dragIndex, 0, dragItem);
                  s.collectionView.moveCurrentToPosition(this.dragIndex);
                });
                this.ngxService.stop();
                this._snackBar.open("An error occurred while processing your request", "Failed");
              });
            }
          });
        }
        else {
          this._snackBar.open("You are not authorized for this action!", "Close")
          s.collectionView.deferUpdate(() => {
            arr.splice(dropIndex, 1);
            arr.splice(this.dragIndex, 0, dragItem);
            s.collectionView.moveCurrentToPosition(this.dragIndex);
          });
        }
      }
    }
    else {
      this.removeGridGrouping();
      this._snackBar.open("Sequence order can be changed only within same Site Name!", 'Close')
    }
  }

  async refreshGrid() {
    this.clearFormErrors(this.frm_RuleOuts);
    this.clearFormErrors(this.copyRuleOutsForm);
    if(this.copyDataClicked)
    this.removeGrid(0);
    else
    this.removeGrid(1);

    await this.backToGrid();
    this.copyDataClicked = false;
    this.uploadClicked = false;
    this.checkAllSite = false;
    this.searchSiteID = '';
    this.destSiteId = null;
    this.destSiteName = null;
    this.searchSiteInput = '';
    this.ifChckdAll = false;
    this.checkStatus = false;
    this.checkAllSiteValue({checked : false});
    this.dragDrop = false;
  }

  clearFormErrors(form){
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.controls[key].setErrors(null)
    });
  }

  // Delete Row
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid(1);
    }
    this._snackBar.open('Deleted successfully', 'Close');
  }

  // Cancel
  removeGrid(initFlag?) {
    if(initFlag)
    this.gridExcelWidth = 0;
    else
    this.gridExcelWidth = -1;
    this.excelDataArray = [];
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
    this.checkStatus = false;
  }

  uploadRuleOutData() {
    if (this.gridExcelArray && this.gridExcelArray.length > 0) {
      let validArr = [];
      if (!this.copyDataClicked) {
        for (var i = 0; i < this.gridExcelArray.length; i++) {
          if (this.gridExcelArray[i].resultmessage.toLowerCase() == "valid") {
            validArr.push(this.gridExcelArray[i]);
          }
        }
      } else {
        for (var i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i].resultmessage.toLowerCase() == "valid" ||
          this.gridExcelArray[i].resultmessage == "Rule out with the same name already exists for the site") {
            validArr.push(this.excelDataArray[i]);
          }
        }
      }
      let bigArr = [];
      bigArr = validArr;
      var loggeduserid;
      if (sessionStorage.getItem('Userid') == "" || sessionStorage.getItem('Userid') == undefined || sessionStorage.getItem('Userid') == null) {
        loggeduserid = "-100";
      } else {
        loggeduserid = sessionStorage.getItem('Userid')
      }
      let tstArr = [];
      for (var i = 0; i < bigArr.length; i++) {
        delete bigArr[i]["ruleout"]
        delete bigArr[i]["sequence"]
        delete bigArr[i]["subsite"]
        if (!this.bulkUpload) {
          delete bigArr[i]["attributeid"]
        }
        delete bigArr[i]["attributetype"]
        delete bigArr[i]["createdby"]
        delete bigArr[i]["isactive"]
        delete bigArr[i]["createddate"]
        delete bigArr[i]["ruleoutsname"]
        delete bigArr[i]["status"]
        delete bigArr[i]["modifiedby"]
        delete bigArr[i]["subsite1"]
        //delete bigArr[i]["sitename"]
        // delete bigArr[i]["active"]
        bigArr[i]["CreatedBy"] = loggeduserid;
        bigArr[i]["ModifiedBy"] = loggeduserid;
        if(this.copyDataClicked){
          bigArr[i]["SequenceOrder"] = null;
        }
        // else {
        // if (!this.bulkUpload) {
        //   bigArr[i]["Status"] = true;
        // }
        // else {
        //   delete bigArr[i]["Status"]
        // }
        // }
        bigArr[i]["Action"] = 'add';
        if (!this.bulkUpload) {
          bigArr[i]["AttributeID"] = 0;
        }
        else {
          delete bigArr[i]["CreatedBy"];
        }

        if (!this.copyDataClicked) {
          if (bigArr[i].attributeid) {
            bigArr[i]["Action"] = 'edit';
          } else {
            bigArr[i]["Action"] = 'add';
          }
        }
        tstArr.push(bigArr[i]);
      }
      if (tstArr.length > 0) {
        if (!this.bulkUpload) {
          this.ngxService.start();
          this.vitalHttpServices.InsertRuleOuts(this.gridExcelArray, this.dbname).subscribe(res => {
            if (res.content && res.content.length > 0) {
              res = res.content;
              this.showDelete = false;
              this.postUpload = true;
              this.postDownload = true;
              this.checkStatus = false;
              this.ngxService.stop();
              if (res.some(va=>va.isSuccess)) {
                // this._snackBar.open("Rule out created successfully!", "Success");
                this.ngxService.stop();
                //   for (var l = 0; l < this.excelDataArray.length; l++) {
                //     let primi = {}
                //     for (let [key, value] of Object.entries(this.excelDataArray[l])) {
                //       if (key.toLowerCase() == 'notes') {
                //         key = 'notes';
                //         if (value == "Rule out with the same name already exists for the site") {
                //           primi[key] = "valid";
                //         }
                //         else {
                //           primi[key] = value;
                //         }

                //       }

                //       if (key.toLowerCase() == 'attributename') {
                //         key = 'RuleOut';
                //         primi[key] = value;
                //       }

                //       if (key.toLowerCase() == 'sequenceorder') {
                //         key = 'Sequence';
                //         for (var k = 0; k < res.length; k++) {
                //           if (this.excelDataArray[k].attributename == res[k].RuleOut) {
                //             primi[key] = res[k].Sequence;
                //             break;
                //           }
                //         }

                //       }

                //       if (key.toLowerCase() == 'status' || key.toLowerCase() == 'active') {
                //         key = 'Active';
                //         primi[key] = value;
                //       }
                //       if (key.toLowerCase() == 'site') {
                //         key = 'site';
                //         primi[key] = value;
                //       }
                //       if (key.toLowerCase() == 'attributecontext') {
                //         key = 'CaseType';
                //         primi[key] = this.templateData.secondarykeys.casetype;
                //       }
                //       // else {
                //       //   primi[key] = value;
                //       // }
                //     }
                //     properArr.push(primi);
                //   }
                //   this.gridExcelArray = properArr;
                // }
                const ruleoutIdExists = this.gridExcelArray[0]?.hasOwnProperty('ruleoutid');
                this.commonService.auditDetails('','',[],this.gridExcelArray,!this.copyDataClicked ? (ruleoutIdExists ? 'Bulk Update' : 'Upload') : 'Copy',this.templateData,[]);

                this.gridExcelArray = res;
                this.excelDataArray = res;
                this.gridExcelData = new CollectionView(res);
                this.postUpload = true;
                // this.gridExcelArray = this.convertObjKeysToLower(this.gridExcelArray);
                // this.AddExcelGridData(this.gridExcelArray);
                this.ngxService.stop();

              }
              //  else {
              //   if (res[0].Notes == 'Site ID is not available' || res[0].Notes == 'Rule out with the same name already exists for the site') {
              //     this.ngxService.stop();
              //     let properArr = [];

              //     for (var l = 0; l < this.excelDataArray.length; l++) {
              //       let primi = {}
              //       for (let [key, value] of Object.entries(this.excelDataArray[l])) {
              //         if (key.toLowerCase() == 'attributename') {
              //           key = 'RuleOut';
              //           primi[key] = value;
              //         }
              //         if (key.toLowerCase() == 'attributecontextid') {
              //           key = 'SubSite';
              //           for (var k = 0; k < this.destSubsiteList.length; k++) {
              //             if (this.destSubsiteList[k].SiteID == value) {
              //               value = this.destSubsiteList[k].SiteName;
              //               break;
              //             }
              //           }
              //           primi[key] = value;
              //         }

              //         if (key.toLowerCase() == 'bodysite') {
              //           key = 'SubSite';
              //           primi[key] = value;
              //         }

              //         if (key.toLowerCase() == 'site') {
              //           key = 'site';
              //           primi[key] = value;
              //         }
              //         if (key.toLowerCase() == 'sequenceorder') {
              //           key = 'Sequence';
              //           for (var k = 0; k < res.length; k++) {
              //             if (this.excelDataArray[k].attributename == res[k].RuleOut) {
              //               primi[key] = res[k].Sequence;
              //               break;
              //             }
              //           }

              //         }

              //         if (key.toLowerCase() == 'status') {
              //           key = 'Active';
              //           primi[key] = value;
              //         }
              //         if (key.toLowerCase() == 'attributecontext') {
              //           key = 'CaseType';
              //           primi[key] = this.templateData.secondarykeys.casetype;
              //         }
              //         // else {
              //         //   primi[key] = value;
              //         // }
              //       }
              //       properArr.push(primi);
              //     }
              //     this.gridExcelArray = properArr;

              //     //  this.gridExcelArray=this.gridExcelArray.filter(ruleout => ruleout.attributename && this.excelDataArray.some(va=> ruleout.attributename == va.attributename));
              //     for (var m = 0; m < this.gridExcelArray.length; m++) {
              //       delete this.gridExcelArray[m].status;
              //       delete this.gridExcelArray[m].Status;
              //     }
              //     for (var c = 0; c < res.length; c++) {
              //       this.gridExcelArray[c]["notes"] = res[c]["Notes"];
              //       if (res[c]["Notes"] == 'Site ID is not available' || res[c]["Notes"] == 'Rule out with the same name already exists for the site') {
              //         this.gridExcelArray[c]["status"] = "Failure";
              //         this.gridExcelArray[c]["Active"] = this.excelDataArray[c]["active"]
              //         this.gridExcelArray[c]["RuleOutName"] = this.excelDataArray[c]["ruleoutname"]
              //         this.gridExcelArray[c]["SiteID"] = this.excelDataArray[c]["siteid"]
              //       }
              //       else {
              //         if (res[c]["Notes"] == 'Success') {
              //           this.gridExcelArray[c]["status"] = "Success";
              //           this.gridExcelArray[c]["Active"] = this.excelDataArray[c]["active"]
              //           this.gridExcelArray[c]["RuleOutName"] = this.excelDataArray[c]["ruleoutname"]
              //           this.gridExcelArray[c]["SiteID"] = this.excelDataArray[c]["siteid"]
              //         }
              //       }


              //     }
              //     this.gridExcelArray = this.convertObjKeysToLower(this.gridExcelArray);
              //     this.AddExcelGridData(this.gridExcelArray);
              //     this.ngxService.stop();
              //   }
              //   else {
              //     this._snackBar.open("Something went wrong!", "Error");
              //     this.ngxService.stop();
              //   }
              // }
            } else {
              this._snackBar.open("Something went wrong!", "Error");
              this.ngxService.stop();
            }
          },error=> {
            console.error(error);
            this._snackBar.open("Something went wrong!", "Error");
            this.ngxService.stop();
          });
        }
        else {
          this.vitalHttpServices.UpdateRuleOuts(this.gridExcelArray, this.dbname).subscribe(res => {
            if (res.content && res.content.length > 0) {
              res = res.content;
              this.showDelete = false;
              this.postUpload = true;
              this.postDownload = true;
              this.bulkUpload = false;
              // this._snackBar.open("Rule out Update successfully!", "Success");
              this.ngxService.stop();
              for (var m = 0; m < this.gridExcelArray.length; m++) {
                delete this.gridExcelArray[m].status;
                delete this.gridExcelArray[m].Status;
              }
              this.gridExcelArray=[];
              this.gridExcelArray=tstArr;
              for (var c = 0; c < res.length; c++) {
                this.gridExcelArray[c]["slno"] = res[c]["SlNo"];
                if (!res[c]["Notes"].match(/Success/)) {
                  this.gridExcelArray[c]["status"] = "Ignored"
                }
                else {
                  this.gridExcelArray[c]["status"] = res[c]["Status"]
                  this.gridExcelArray[c]["resultmessage"]=res[c]["Notes"]
                }
              }
              this.gridExcelArray = this.gridExcelArray.map(va => ({
                ...va,
                status: va.status || 'Ignored'
              }))

              this.commonService.auditDetails('','',[],this.gridExcelArray,'Upload',this.templateData,[]);
              this.gridExcelArray = this.convertObjKeysToLower(this.gridExcelArray);
              this.AddExcelGridData(this.gridExcelArray);
              this.ngxService.stop();
            } else {
              this._snackBar.open("Something went wrong!", "Error");
              this.ngxService.stop();
            }
          },error=> {
            console.error(error);
            this._snackBar.open("Something went wrong!", "Error");
            this.ngxService.stop();
          });
        }
      } else {
        this.ngxService.stop();
        if (this.copyDataClicked) {
          this._snackBar.open('Copying data failed! Please check the Notes message.', 'Close');
        } else {
          this._snackBar.open('Uploading data failed! Please check the Notes message.', 'Close');
        }
        return;
      }
    } else {
      this.ngxService.stop();
      this._snackBar.open('Copying data failed! Please check the Notes message.', 'Close');
      return;
    }
  }

  copyOrg() {
    if (!this.copyBtn) {
      this.destDeployment = this.dbname//sessionStorage.getItem('deploymentKey').toUpperCase();
      this.srcDeployment = this.destDeployment
      this.copyDataClicked = true;
      this.ShowGrid = false;
      this.uploadClicked = false;
      this.postUpload = false;
      this.backBtn = true;
      this.srcOrgid = ''
      this.copyRuleOutsForm.reset()
      this.copyRuleOutsForm.patchValue({
        frmOrganization: "",
        frmDepKey: this.destDeployment
      })
      this.getListOrg();
    }
  }

  //#region popup for copy org
  // copyRuleOutsFromOrg() {
  //   let colNames = [];
  //   let colDefs = [];
  //   colNames = ['RuleOut', 'SubSite', 'Sequence', 'CaseType', 'Active']

  //   colDefs = ['RuleOut', 'SubSite', 'Sequence', 'CaseType', 'Active']
  //   let dialogRef = this.dialog.open(OrgTableComponent, {
  //     disableClose: true,
  //   });
  //   let orgid, casetypeData;
  //   orgid = sessionStorage.getItem('org_id');
  //   let card = sessionStorage.getItem('context');
  //   casetypeData = this.templateData.cardtype;
  //   (<OrgTableComponent>dialogRef.componentInstance).dataToTakeAsInput = {
  //     OrganizationID: orgid,
  //     CaseType: casetypeData,
  //     accountid: this.templateData.cardIdentifier,
  //     cardType: card,
  //     TableName: 'OrganizationAttributes',
  //     ColumnNames: colNames,
  //     columnDefs: colDefs,
  //   };
  //   return dialogRef.afterClosed().toPromise().then(result => {
  //     if (result) {

  //       this.refreshGrid();
  //     }
  //   })
  // }

  //#region Get data to copy-from-other-entity
  getDataToCopy() {
    try {
      this.removeGrid()
      this.gridExcelWidth = -1;
      this.uploadClicked = false;
      this.postUpload = false;
      this.backBtn = true;
      let siteData = [];
      let res;
      let arrTst = [];

      //  this.validateSubSites();
      this.ngxService.start();
      this.vitalHttpServices.getOrgDataForCopy(this.srcOrgid, this.templateData.secondarykeys.casetype, '', 'OrganizationRuleOuts', '', this.searchSiteID, this.searchSiteTextInput, this.srcDeployment).subscribe(parentOrgData => {
        this.ngxService.stop();
        if (!parentOrgData.errors) {
          if (parentOrgData.length > 0) {
            res = parentOrgData;
            // if (this.searchSiteID == 'all') {
            //   this.destSiteId = '';
            // }
            let site = {};

            for (var i = 0; i < parentOrgData.length; i++) {
              site = {
                "attributename": parentOrgData[i].AttributeName,
                "attributecontextid": this.destSiteSelected ?? this.destSiteId,
                "organizationid": this.organizationid,
                "attributecontext": this.templateData.cardtype
              }
              siteData.push(site);
            }

            var i = 1;
            if (this.copyDataClicked) {
              //   this.sheetHeader = ["Sequence", "SubSite ", "RuleOut",  "CaseType", "Active","Notes"]//Object.keys(data[0]);
              this.sheetHeader = ["Active", "RuleOutName", "Site", "CaseType", "ResultMessage"]
            }
            if (this.searchSiteID == 'all') {
              for (var k = 0; k < this.attributesName.length; k++) {
                for (var j = 0; j < res.length; j++) {
                  if (res[j].SubSite != null) {
                    if (this.attributesName[k].sitename.toLowerCase() == res[j].SubSite.toLowerCase()) {
                      res[j].SiteID = res[j].SiteID;
                      res[j].AttributeContextId = res[j].SiteID;
                      res[j].Site = res[j].SubSite;
                      res[j].Organizationid = this.organizationid;
                      res[j].organizationid = this.organizationid;
                      res[j].attributename = res[j].Name;
                      res[j].attributecontext = this.templateData.secondarykeys.casetype;
                    }
                    if (res[j].SiteID == undefined) {
                      res[j].SiteID = "";
                    }
                  res[j].destSelected = 0;
                  }
                  if(this.destSiteSelected){
                    res[j].SiteID = this.destSiteSelected;
                    res[j].destSelected = 1;
                    res[j].Site = this.destSiteName;
                    res[j].AttributeContextId = this.destSiteSelected;
                  }
                  delete res[j].AttributeID;
                }
              }
            } else {
              for (var j = 0; j < res.length; j++) {
                res[j].SiteID = this.destSiteId;
                res[j].Organizationid = this.organizationid;
                res[j].AttributeContextId = this.destSiteId;
                res[j].organizationid = this.organizationid;
                res[j].attributename = res[j].Name;
                res[j].Site = res[j].SubSite;
                res[j].attributecontext = this.templateData.secondarykeys.casetype;
                res[j].destSelected = 0;
                if(this.destSiteSelected){
                  res[j].SiteID = this.destSiteSelected;
                  res[j].Site = this.destSiteName;
                  res[j].destSelected = 1;
                  res[j].AttributeContextId =  this.destSiteSelected;
                }
                delete res[j].AttributeID;
              }
            }

            arrTst.push(res);
            this.excelDataArray = [];
            this.excelDataArray = this.convertObjKeysToLower(res);

            this.excelDataArray = this.excelDataArray.filter(ruleout => ruleout.active == true)
            this.excelDataArray.find(d => {
              Object.assign(d, {
                slno: Number(i), resultmessage: '', tablename: 'OrganizationAttributes', organizationid: Number(sessionStorage.getItem('org_id')),
                casetype: this.templateData.cardtype.toString(),
              });
              i++;
            });
            this.validateExcelData(this.excelDataArray);
            this.ngxService.stop();
            // }
          } else {
            this.gridExcelWidth = 0;
            this.ngxService.stop();
          }
        } else {
          this._snackBar.open("Something went wrong!", "Error!");
          this.ngxService.stop();
        }
      });
    }
    catch (error) {
      this.ngxService.stop();
      this._snackBar.open("Something went wrong!", "Error!");
      console.error(error);
    }
  }

  disableGetDataBtn() {
    let val;
    if (this.searchInput == '' || this.srcOrgid == '' || this.searchSiteInput == '' || this.searchSiteID == '') {
      if (this.searchSiteInput == '' || this.searchSiteID == '') {
        if (this.checkAllSite == false || this.searchInput == '' || this.srcOrgid == '') {
          val = true;
        } else {
          val = false;
        }
      } else {
        val = false;
      }
    } else {
      val = false;
    }
    return val;
  }

  initializeGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  // Set color to the notes column based on validity
  setNoteColor(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs){
    if (e.panel == flexGird.cells) {
      const column = e.panel["_cols"][e.col] ?._binding ?._key
      if(!column?.match(/resultmessage/i)) return;
      const record = e.panel.rows[e.row]["_data"];
      wjCore.toggleClass(e.cell, (record.isvalid || record.isSuccess)? 'high-value' : 'error-msg', true);
    }
  }

  // formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
  //   if (e.panel == flexGird.cells) {
  //     var value = e.panel.getCellData(e.row, e.col, false);
  //     if (value) {
  //       if (value.toString().includes('Mandatory fields is missing!') || value.toString().includes('AttributeId does not exists') || value.toString().includes('Null') || value.toString().includes('Site is not available') || value.toString().includes('Not able to validate') || value.toString().includes('AttributeContextID column value is missing!;') || value.toString().includes('Duplicate data found in Excel')
  //         || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('Attribute Name column value is missing') || value.toString().includes('Mandatory fields missing!') || value.toString().includes('already exists') || value.toString().includes('Site ID is not available')) {
  //         wjCore.toggleClass(e.cell, 'error-msg ', true);
  //       }
  //       else if (value == 'Valid' || value == 'Success' || value == 'Rule out created successfully' || value == 'Updated Successfully') {
  //         wjCore.toggleClass(e.cell, 'high-value', true);
  //       }
  //       // else if (value.toString().includes('Rule Out already exists!')) {
  //       //   wjCore.toggleClass(e.cell, 'warn-value', true);
  //       // }
  //     }
  //   }
  // }

  //#region Export Grid data
  ExportExcelCopyandUpload(flex) {
    let excel = [];
    let exportRows = structuredClone(flex.rows.map(va=> va["_data"]));
    exportRows.forEach(row => {
      delete row["isEdit"]
      delete row["isValid"]
      delete row["isSuccess"]
      delete row["tempsequence"]
      if (this.uploadClicked) {
        delete row["modifiedby"];
        delete row["id"];
        delete row["action"];
        // delete row["active"];
        delete row["createdby"];
        delete row["modifieddate"];
        delete row["tablename"];
        delete row["slno"];
        delete row["sequenceorder"];
      }
      else {
        delete row["notemessage"];
        delete row["resultmessage"];
        delete row["ruleoutname"];
        delete row["siteid"];
        delete row["attributeid"];
        delete row["modifiedby"];
        delete row["id"];
        delete row["action"];
        delete row["active"];
        delete row["createdby"];
        delete row["modifieddate"];
        delete row["tablename"];
        delete row["slno"];
        delete row["sequenceorder"];
      }
      excel.push(row);
    });
     // Check if 'status' column exists and change header if it does
     let headers = Object.keys(excel[0]); // Get column headers from the first row
     if (headers.includes("status")) {
       // Change the header from 'status' to 'result'
       headers[headers.indexOf("status")] = "result";
     }

     // Create a new array with the updated headers
     let updatedExcel = excel.map(row => {
       let updatedRow = {};
       Object.keys(row).forEach(key => {
         let newKey = key === "status" ? "result" : key;
         updatedRow[newKey] = row[key];
       });
       return updatedRow;
     });
    let filename = 'Rule Out_' + this.organizationid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      updatedExcel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationRuleOut');
    XLSX.writeFile(wb, filename);
  }

  //Upload to database the select one
  uploadCopyData(flexgrid) {
    this.excelDataArray = [];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    this.copyResdata = this.excelDataArray;
    // if (this.searchSiteTextInput.toLowerCase() == 'all') {
    //   this.siteFound = true;
    // }
    if (this.searchSiteID != "all") {
      if (this.siteFound) {
        this.ifNoSiteFound = false;
      } else {
        this.ifNoSiteFound = true;
        // this.excelDataArray = [];
        // this.gridExcelArray = [];
      }
    }
    this.uploadRuleOutData();
  }

  //on changes deployment key
  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.srcDeployment = DeploymentKey;
      this.searchInput = '';
      this.searchSiteInput = '';
      this.sitetoggle = false;
      this.searchResult = []
      this.attributesName = []
      //  this.srcOrgType = "Laboratory"
      this.removeGrid();
      this.gridExcelWidth = -1
      this.resetSiteForm();
      this.getListOrg();
    }
  }

  //#region copy-from-entity: orglist
  fetchOrgSeries(value: string) {
    this.searchResult = []
    this.attributesName = [];
    this.selSiteId = '';
    this.searchSiteID = '';
    this.searchSiteInput = '';
    value = value?.toString().trim();
    if (!value) {
      this.attributesName = [];
      this.searchResult = this.orgList.filter(x => x.organizationname).sort((a, b) => a.organizationname?.toString().toLowerCase() < b.organizationname?.toString().toLowerCase() ? -1 : a.organizationname?.toString().toLowerCase() > b.organizationname?.toString().toLowerCase() ? 1 : 0)
      return (this.searchResult = this.searchResult.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.orgList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
            }
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.orgList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
        }
      })
      this.searchResult = this.orgList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }

  resetSiteForm() {
    this.copyRuleOutsForm.patchValue({
      frmOrganization: "",
      frmSites: "",
      frmDepKey: this.srcDeployment
    })
  }

  getListOrg() {
    this.orgList = [];
    let dbName;
    let query = this.vitalHttpServices.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(async res => {
      this.ngxService.stop();
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.orgList = res.data.Organizations_list;
        let siteData = await this.getSites(this.templateData.secondarykeys.casetype,sessionStorage.getItem('org_id'));
        this.destSites = this.assignSiteData(siteData)
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  validateSubSites() {
    let query = this.vitalHttpServices.GetQuery('SubSitesListForRuleOuts');
    let queryVariable = { orgid: this.templateData.GroupData.OrganizationId.toString(), casetype: this.templateData.cardIdentifier }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();

    this.vitalHttpServices.GetSubSiteDate(this.templateData.secondarykeys.casetype, this.organizationid, this.dbname).subscribe(
      (Resdata) => {
        if (!Resdata.errors) {
          let subsites;
          this.siteFound = false;
          subsites = JSON.parse(JSON.stringify(Resdata));
          this.destSubsiteList = subsites;
          for (var i = 0; i < subsites.length; i++) {
            if (subsites[i].SiteName.toLowerCase() == this.searchSiteInput.toLowerCase()) {
              this.siteFound = true;
              break;
            }
          }
        } else {
          this._snackBar.open("Something went wrong!", "Error");
          this.ngxService.stop();
        }
      },
      (error) => {
        console.error(error);
        this.ngxService.stop();
      });
  }

  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  public searchSitelist: any = [];
  fetchSiteSeries(value: string) {
    this.searchsitelist = []
    value = value?.toString().trim();
    if (!value) {
      this.searchsitelist = this.attributesName.filter(x => x.sitename).sort((a, b) => a.sitename?.toString().toLowerCase() < b.sitename?.toString().toLowerCase() ? -1 : a.sitename?.toString().toLowerCase() > b.sitename?.toString().toLowerCase() ? 1 : 0)
      return (this.searchsitelist = this.searchsitelist.splice(0, 25));
    }
    this.sitetoggle = false;
    let idValidate = /^[0-9]*$/
    this.ifSiteSel = false;
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.sitetoggle = true;
        //
        this.searchsitelist = this.attributesName.filter(function (series) {
          if (series && series.sitename != null) {
            return series.sitename.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchsitelist = this.searchsitelist.splice(0, 25);
      } else {
      }
    }
  }
  //#endregion

  //#region Delete in List Page
  deleteRuleOuts(data) {
    if (!this.deletebtn) {
        let dataItem = data;
        let obj = {
          "attributeid": dataItem.RuleOutId,
          "organizationid": sessionStorage.getItem('org_id').toString(),
          "casetype": this.templateData.secondarykeys.casetype.toString()
          ,"ruleoutname": dataItem.RuleOutName
          ,"active": dataItem.IsActive ? 'Active' : 'Inactive'
          ,"sequenceorder": dataItem.Sequence
          ,"site": dataItem.Site?.toString().toLowerCase() == 'all sites' ? 'All' : dataItem.Site
        }
        // let dbname = sessionStorage.getItem('deploymentKey')
        let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '360px',
          panelClass: 'admin-custom-popup',
          data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
        })
        return dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            this.ngxService.start();
            this.vitalHttpServices.DeleteRuleOuts(obj, this.dbname).subscribe(
              (res) => {
              this.ngxService.stop();
                if(res.content){
                  this.ngxService.stop();
                  delete this.auditableColumns["casetype"];
                  this.commonService.auditDetails('attributeid','ruleoutname',[obj], [{}], 'Delete',this.templateData,this.auditableColumns);
                  this._snackBar.open('Data deleted successfully', 'Close');
                  this.getRuleOutlist();
                }
                else {
                  this._snackBar.open('Data deletion failed', 'Close');
                }
              },
              error => {
                console.error('Error deleting item:', error);
                this.ngxService.stop();
              }
            );
          }
        })
    }
  }

  async getRuleOutlist() {
    let query = this.SubMenuCardModel.GetQuery('newmanageruleouts');
    let queryVariable = {
      orgid: sessionStorage.getItem('org_id').toString(),
      casetype: this.templateData.secondarykeys.casetype.toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.vitalHttpServices.GetData(queryResult, this.dbname).toPromise().then(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.getGrid(true);
        }
      },
      error => {
        // this.gridShow = false;
        console.error(error);
      });
    this.ngxService.stop();
  }
  //#endregion

  ruleOutCreation(event){
    if(!event.bulkUpload){
      this.addRuleOuts();
    }
    else {
      this.uploadRuleOUts();
    }
  }

  fetchDestSites(searchInput : string,destSites){
    if(!searchInput ){
      return destSites || [];
    }
    let returnArray = [];
    returnArray = destSites.filter((va : any)=> va.sitename.toString().toLowerCase().trim().includes(searchInput.toLowerCase()));
    return returnArray.splice(0,25);
  }

  _clearPreviousSelectedOption(autoComplete : MatAutocomplete,skip: _MatOptionBase | null, emitEvent?: boolean){
    autoComplete.options.forEach(option => {
      if (option !== skip && option.selected) {
        option.deselect(emitEvent);
      }
    });
  }

  clearSiteDrop(searchValue){
    searchValue = '';
    this.frm_RuleOuts.patchValue({
      frm_subsite : []
    });
  }
  checkAllSites(){
    return this.attributesName.length == this.frm_RuleOuts.controls["frm_subsite"].value?.length;
  }

  someSitesChecked(){
    return (!this.checkAllSites() && this.frm_RuleOuts.controls["frm_subsite"].value?.length > 0);
  }

  resetFormData(){
    if(!this.changesMade()) return;
    if(this.headerValue.match(/edit/i)){
      this.frm_isActive = this.selectedrowData.IsActive ? true : false;
      this.frm_RuleOuts.patchValue({
        frm_name: this.selectedrowData.RuleOutName,
        frm_Sequence: this.selectedrowData.Sequence,
        frm_subsite: !this.selectedrowData.Site.match(/all sites/i) ? [ Number(this.selectedrowData.SiteId)] : this.attributesName.map(va=> va.siteid),
        frm_active : this.selectedrowData.IsActive ? true : false
      });
      this.frm_RuleOutsClone = JSON.parse(JSON.stringify(this.frm_RuleOuts.value));
      this.selectdSubsite = this.selectedrowData.AttributeContextId;
    }
    else {
      this.frm_RuleOuts.reset();
      this.frm_RuleOuts.patchValue({frm_subsite : []});
      this.frm_isActive = true ;
    }
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }

  changesMade(){
    if(this.disableSites() && this.frm_RuleOutsClone){
      return JSON.stringify(this.frm_RuleOutsClone) != JSON.stringify(this.frm_RuleOuts.value) || (this.frm_isActive != this.frm_RuleOutsClone.frm_active);
    }

    return  this.frm_RuleOuts && (Object.values(this.frm_RuleOuts.value).some((va : any)=> {
      if(Array.isArray(va)) {
        return va.length ? true : false;
      }
      return  va
    }
    )) || !this.frm_isActive;
  }

  dragDropItem(item){
    if(item.reset){
      this.dragDrop = false;
      this.dragItems = [];
      return
    }
    else if(item.save){
      if(item.sortAZ != null){
        this.dragDrop = true;
        let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '360px',
          panelClass: 'admin-custom-popup',
          data: { header: "", message: "The rule outs will be sorted in " + (item.sortAZ ? "ascending" : "descending") + " order, it cannot be reverted.", alert: "Do you want to continue?", continue: "Yes", cancel: "No" }
        })
        this.ngxService.stop();
        return dialogRef.afterClosed().toPromise().then(result => {
          if(result)
          this.saveDragDrop(this.dragItems,item.sortAZ);
        })
      }
      this.saveDragDrop(this.dragItems,item.sortAZ);
      return;
    }
    let siteId = Number(item.data[0].SiteId);
    if (isNaN(siteId)) {
      // Handle NaN case explicitly
      let nanIndex = this.dragItems.findIndex((va: any) => isNaN(va.siteId));
      if (nanIndex === -1) {
        // Add if no existing NaN entry
        this.dragItems.push({ siteId: NaN, data: this.getIds(item.data) });
      } else {
        // Update existing NaN entry
        this.dragItems[nanIndex].data = this.getIds(item.data);
      }
    } else {
      // Handle non-NaN case
      let index = this.dragItems.findIndex((va: any) => va.siteId === siteId);
      if (index === -1) {
        // Add if siteId doesn't exist
        this.dragItems.push({ siteId: siteId, data: this.getIds(item.data) });
      } else {
        // Update if siteId already exists
        this.dragItems[index].data = this.getIds(item.data);
      }
    }
  }

  getIds(data){
    return data.map(va=> va.RuleOutId)
  }

  async saveDragDrop(dragItems,sortAZ){
    let requestData = {
      SortAZ : sortAZ,
      organizationid : this.templateData.secondarykeys.OrganizationId,
      casetype : this.templateData.secondarykeys.casetype,
      dragdroplist : this.getContiguousList(dragItems)
    }
    this.ngxService.start();
    await this.vitalHttpServices.updateRuleOutsSequence(requestData).toPromise().then(async response=> {
      if(response.content){
        if(requestData.SortAZ != null){
          this.sortAZ = !this.sortAZ
          this._snackBar.open("Rule outs sorted successfully","Close");
          let activityObject=response.content;
          activityObject = activityObject.map(({ Status, ...rest }) => rest);
          this.commonService.auditDetails('', '', [], activityObject, 'Re-Order', this.templateData, '');
          await this.backToGrid();
        }
        else {
          this.dragDrop = true;
          let activityObject=response.content;
          activityObject = activityObject.map(({ Status, ...rest }) => rest);
          this.commonService.auditDetails('', '', [], activityObject, 'Re-Order', this.templateData, '');
          this._snackBar.open("Sequence updated successfully","Close");
          // this.refreshGrid();
          await this.backToGrid();
        }
      }
      else {
        this._snackBar.open("Sequence updation failed","Close")
      }
    }, error=> {
      console.error(error);
      this._snackBar.open("Sequence updation failed","Close")
    })
    this.ngxService.stop();
  }

  getContiguousList(list){
      return list.map(item => item.data);
  }

  toggleAllSelection(checked){
    // this.checkUncheckAll(checked);
    if(checked){
      let siteids = []
      this.attributesName.forEach(va=> {
        siteids.push(va.siteid);
      })
      this.frm_RuleOuts.patchValue({
        frm_subsite : siteids
      })
    }
    else {
      this.frm_RuleOuts.patchValue({
        frm_subsite : []
      })
    }
  }
}



class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
