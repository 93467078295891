<span class="udf-wrap">
<!-- <p>user-defined-fields works!</p> -->
<div *ngIf="gridPage" class="m-2 col-sm-12">
    <div *ngIf="!noDataFound" class="upload-grid">
        <div class="modal-header admin-model-header mb-2 mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong>User Defined Fields</strong>
            </h3>
        </div>
        <wj-flex-grid class="grid-udf" #grid [isReadOnly]="true" [headersVisibility]="'Column'"
            (initialized)="initGrid(grid)" [itemsSource]="UDFGridData">
            <wj-flex-grid-column [header]="'Actions'" align="center" [freeze]="true" [width]="250" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button [disabled]="editBtn" class="edit-delete-btn" (click)="getInputData('edit',row)"><em
                            id="editButton" [title]="'Edit'" class=" cursor p-0 fa fa-edit"></em></button>
                    <button *ngIf="DeleteUDF"class="edit-delete-btn"><em id="editButton" [title]="'Delete'"
                            class="pl-2 fa fa-trash" (click)="deleteUDF(row)"></em></button>
                    <!-- <img id="copyButton" class="edit-template cursor edit-delete-btn pl-2" title="Copy" alt="copy"
                         src="/assets/images/copy.svg" /> -->
                    <button [disabled]="editBtn" class=" cursor edit-delete-btn pl-2" title="Copy">
                        <img src="/assets/images/copy.svg"  class="edit-template" *ngIf="!editBtn" (click)="getInputData('copy',row)"
                            alt="buttonpng" border="0" />
                    </button>

                </ng-template>
            </wj-flex-grid-column>
            <!-- <wj-flex-grid-column [header]="'Copy'" align="center" [width]="170" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">

                </ng-template>
            </wj-flex-grid-column> -->
            <!-- <div *ngFor="let columnname of gridheader"> -->
            <wj-flex-grid-column align="center" *ngFor="let columnname of gridheader" [binding]="columnname"
                [visible]="!(columnname == 'Organizationid')  && !(columnname == 'ParentID') && !(columnname == 'CaseType') &&  !(columnname == 'CreatedBy') &&  !(columnname == 'CreatedDate') &&  !(columnname == 'ModifiedBy') &&  !(columnname == 'ModifiedDate')"
                [header]="columnname.split('_').join(' ')" [width]="170" [fixed]="true" [allowResizing]="true"
                [allowDragging]="false" [format]="'d'">
            </wj-flex-grid-column>
            <!-- </div> -->

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <br />
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="UDFGridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="UDFGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>
        <div class="align-center mt-4">
            <button mat-raised-button [disabled]="exportBtn" class="admin-btn-success mr-4"
                (click)="ExportExcel(grid,1)">Export</button>
            <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4"
                (click)="getInputData('',[])">
                Create
            </button>
            <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mr-4"
                (click)="loadUploadScreen()">
                Upload
            </button>
            <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success" (click)="copyScreen()">
                Copy
            </button>
        </div>
    </div>

    <div class="row container create-btn align-center" *ngIf="noDataFound">
        <div class="modal-header admin-model-header mb-2 mt-2 col-sm-12">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong>User Defined Fields</strong>
            </h3>
        </div>
        <div class="col-sm-12 nodata-wrap mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>

    <div class="align-center mt-4" *ngIf="noDataFound">
        <button [disabled]="createBtn" mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('',[])">
            Create
        </button>
        <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mr-4" (click)="loadUploadScreen()">
            Upload
        </button>
        <button [disabled]="uploadBtn" mat-raised-button class="admin-btn-success" (click)="copyScreen()">
            Copy
        </button>
    </div>
</div>


<div *ngIf="uploadClicked" class="my-sites-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload User Defined Fields</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('min')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal
                    Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('all')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All
                    Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('update')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the UDF .</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" (initialized)="initGrid(grid)"
            [itemsSource]="gridData" #grid [headersVisibility]="'Column'"
            [frozenColumns]="2" (formatItem)="formatItem(grid, $event)">

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>

            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            <!-- <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
            headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator> -->
        </wj-flex-grid>
        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        [disabled]="disableApprovebtn()" (click)="uploadUDFData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportResult(excelDataArray)">Download</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
<!-- Create/Edit UDF  -->
<div *ngIf="addEditClicked" class="m-2 col-sm-12">
    <form [formGroup]="UDFFormGroup">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong *ngIf="!editEnabled">Create User Defined Field</strong>
                    <strong *ngIf="editEnabled">Edit User Defined Field</strong>
                </h3>
            </div>
            <div class="text-wrap col-sm-12 p-0">
                <div class="divider col-sm-12 d-flex"><strong class="heading-font">Criteria</strong></div>
                <div class="row col-sm-12 pl-3 ml-0">
                    <!-- <mat-form-field class="w-100 col-sm-3 pr-0" appearance="outline">
                        <mat-label>
                            <strong><span class="text-danger">*</span>
                                Case Status</strong>
                        </mat-label>
                        <mat-select disableOptionCentering (selectionChange)="selectingStatus($event.value)" multiple
                            #matSelectOption formControlName="frmStatus">
                            <div class="select-all">
                                <mat-checkbox [color]="task.color" [(ngModel)]="allSelected"
                                    style="margin-left: 16px; margin-top: 10px;" [ngModelOptions]="{standalone: true}"
                                    (change)="toggleAllSelection()">Select
                                    All</mat-checkbox>
                            </div>
                            <mat-option *ngFor="let item of Status" (click)="optionClick()" [value]="item.item">
                                {{item.item}} </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-label class="font-size mt-2 pr-2"><strong>Case Status<span class="text-danger">*</span></strong>
                    </mat-label>
                    <ng-multiselect-dropdown  class="text-xs multi-wrap"
                        [placeholder]="'Search ...'" #matSelectOption
                        [settings]="dropdownSettings" formControlName="frmStatus" [data]="Status" (onSelect)="onCheckChange($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll()">
                    </ng-multiselect-dropdown>


                    <mat-checkbox class="col-sm-3 pr-0 w-100 mb-3" [color]="task.color" formControlName="procedureLevel"
                        style="display: flex;align-items: center;justify-content: center;"
                        [ngModelOptions]="{standalone: true}">Procedure Level</mat-checkbox>

                    <mat-form-field class="col-sm-3 form-field-dropdown" appearance="outline" *ngIf="UDFFormGroup.value.procedureLevel">
                        <mat-label><strong>Procedure Name</strong></mat-label>
                        <button matSuffix mat-icon-button ml-5>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                            formControlName="frmProcedureName" title="{{ UDFFormGroup.value.frmProcedureName}}"
                            [matAutocomplete]="auto6" style="width:95% !important;">
                        <mat-autocomplete #auto6="matAutocomplete">
                            <mat-option class="mat-opt-align" id="frmMatOption" value="">
                                Select
                            </mat-option>
                            <mat-option class="mat-opt-align" id="frmMatOption" *ngFor="let P of filterProcedures |async" value="{{ P.Value}}-{{P.Id}}"
                                autoActiveFirstOption title="{{P.Value}}-{{P.Id}}">
                                {{P.Value}}-{{P.Id}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="divider col-sm-12 d-flex"><strong class="heading-font">User Defined Fields</strong></div>
                <div class="col-sm-12 slider-toggle">
                    <div class="col-sm-3 slider-style">
                        <label class="toggle mt-3 ">
                            <input formControlName="frmAttributeType" title="{{ UDFFormGroup.value.frmAttributeType}}"
                                (change)="onChangeDisable()" type="checkbox">
                            <span class="slider"></span>
                            <span class="labels" data-on="Header" data-off="Control"></span>
                        </label>
                    </div>

                    <!-- <mat-form-field class="w-100 col-sm-3 pr-0" appearance="outline">
                        <mat-label>
                            <strong><span class="text-danger"
                                    *ngIf="UDFFormGroup.value.frmAttributeType == false">*</span>
                                Header</strong>
                        </mat-label>
                        <mat-select disableOptionCentering formControlName="frmParentAttribute"
                            [disabled]="UDFFormGroup.value.frmAttributeType == 'header'">
                            <mat-option *ngFor="let P of UDFParents" [value]="P.Id">{{P.Value}}-{{P.Id}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                      <mat-form-field class="col-sm-3 mt-1 pr-0 form-field-dropdown" appearance="outline">
                        <mat-label><strong>Header</strong></mat-label>
                        <button matSuffix mat-icon-button ml-5>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                            formControlName="frmParentAttribute" [matAutocomplete]="auto9"
                            style="width:95% !important;">
                        <mat-autocomplete #auto9="matAutocomplete">
                            <mat-option class="mat-opt-align" id="frmMatOption" *ngFor="let option of  filterParent | async"
                                value="{{option.Value}}-{{option.Id}}" autoActiveFirstOption title="{{option.Value}}-{{option.Id}}">
                                {{option.Value}}-{{option.Id}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 mt-1">
                    <mat-form-field class="w-100 col-sm-3 pr-0" appearance="outline">
                        <mat-label>
                            <strong>
                                Display Type</strong>
                        </mat-label>
                        <mat-select disableOptionCentering formControlName="frmDisplayType"
                            (selectionChange)="selectionDisplayType($event.value)">
                            <mat-option *ngFor="let P of UDFDisplayTypes" [value]="P.Id">{{P.Value}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 col-sm-3 pr-0 mt-3">
                        <mat-label>
                            <strong>
                                UDF Name</strong>
                        </mat-label>
                        <input trim type="text" matInput maxlength="100" title="{{ UDFFormGroup.value.frmAttributeName}}"
                            formControlName="frmAttributeName">
                    </mat-form-field>


                    <mat-form-field class="w-100 col-sm-3 pr-0" appearance="outline">
                        <mat-label>
                            <strong>
                                Display Name</strong>
                        </mat-label>
                        <input trim type="text" matInput maxlength="2000" formControlName="frmDisplayName">
                    </mat-form-field>
                    <mat-form-field class="w-100 col-sm-3 pr-0" appearance="outline">
                        <mat-label >
                            <strong> <span class="text-danger"
                                *ngIf="mandateDispVal">*</span>
                                Display Value</strong>
                        </mat-label>
                        <input matInput [matTextareaAutosize]="false" formControlName="frmDisplayValue" />
                    </mat-form-field>

                </div>

                <div class="col-sm-12 d-flex mt-1 mb-1">

                    <!-- <mat-form-field class="w-100 col-sm-6 pr-0" appearance="outline">
                        <mat-label>
                            <strong>
                                Action </strong>
                        </mat-label>
                        <textarea matInput [matTextareaAutosize]="false" cols="20" rows="4"
                            formControlName="frmAction"></textarea>
                    </mat-form-field> -->

                    <mat-form-field class="col-sm-3 pr-0 mt-3" appearance="outline">
                        <mat-label><strong>Action</strong></mat-label>
                        <button matSuffix mat-icon-button ml-5>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <input type="text" aria-label="Assignee" placeholder="Search..." style="width:95% !important;"
                            matInput title="{{ UDFFormGroup.value.frmAction}}" formControlName="frmAction"
                            [matAutocomplete]="auto1">
                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option id="frmMatOption" class="mat-opt-align"
                                *ngFor="let option of  filterActions | async" value="{{ option.item}}"
                                autoActiveFirstOption title="{{ option.item}}">
                                {{ option.item}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="w-100 col-sm-3 pr-0 mt-3" appearance="outline">
                        <mat-label>
                            <strong>Default Value</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="200" formControlName="frmDefaultValue">
                    </mat-form-field>
                    <mat-form-field class="w-100 col-sm-3 pr-0 mt-3" appearance="outline">
                        <mat-label>
                            <strong>
                                Version Number </strong>
                        </mat-label>
                        <input type="text" (keypress)="AllowOnlyNumber($event)" matInput
                            formControlName="frmVersionNum">
                    </mat-form-field>

                    <section class="col-sm-3 w-100">
                        <mat-checkbox [color]="task.color" class="mt-2 example-margin" formControlName="frmActive">
                            <strong>Active</strong>
                        </mat-checkbox>
                    </section>
                </div>
                <div class="col-sm-12 d-flex mt-1 mb-1">
                    <mat-form-field class="w-100 col-sm-3 pr-0 mt-3" appearance="outline">
                        <mat-label>
                            <strong>
                                Data Source Type</strong>
                        </mat-label>
                        <mat-select disableOptionCentering formControlName="frmDataSourceType"
                            (selectionChange)="changeDataSourecType($event)" placeholder="Data Source Type">
                            <mat-option value=''>Select</mat-option>
                            <mat-option *ngFor="let item of udfDataSourcedrpdwn" value="{{item.item}}">{{item.item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field  appearance="outline" class="w-100 col-sm-3 mt-3 pr-0">
                        <mat-label><strong> Additional Data </strong></mat-label>
                        <input type="text" matInput maxlength="100"
                            [matAutocomplete]="entityType" formControlName="frmAdditionalData" #inputAutoCompleteGroup
                            #trigger8="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;">
                        <div matSuffix style="display:flex">
                            <button (click)="clear($event, '')" *ngIf="UDFFormGroup.value.frmAdditionalData!==null"
                                aria-label="Clear" mat-icon-button type="button">
                                <mat-icon class="icon-clear">clear</mat-icon>
                            </button>
                            <button (click)="openOrClosePanel($event, trigger8)" aria-label="Clear" mat-icon-button type="button">
                                <mat-icon class="icon-wraps">{{arrowIconSubject.getValue()}}</mat-icon>
                            </button>
                        </div>
                        <mat-autocomplete #entityType="matAutocomplete" (closed)="arrowIconSubject.next('arrow_drop_down')"
                            (opened)="arrowIconSubject.next('arrow_drop_up')" (optionSelected)="arrowIconSubject.next('arrow_drop_down')"
                            [displayWith]="displayFn">
                            <mat-option value=''>Select</mat-option>
                            <mat-option class="mat-opt-align"
                                disableOptionCentering value="{{item.item}}" *ngFor="let item of addtionalDatadrpdn">
                                {{item.item}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>


                    <mat-form-field class="w-100 col-sm-3 pr-0 mt-3" appearance="outline">
                        <mat-label>
                            <strong>
                                Ref Type</strong>
                        </mat-label>
                        <mat-select disableOptionCentering formControlName="frmRefType" placeholder="Ref Type">
                            <mat-option value=''>Select</mat-option>
                            <mat-option value="AOE">AOEs</mat-option>
                        </mat-select>
                        <!-- <input type="area" matInput maxlength="200"> -->

                    </mat-form-field>
                    <mat-form-field class="w-100 col-sm-3 pr-0 form-field-height" appearance="outline" *ngIf="UDFFormGroup.value.frmRefType?.toLowerCase() == 'aoe'">
                        <mat-label>
                            <strong><span class="text-danger"
                                    *ngIf="UDFFormGroup.value.frmRefType?.toLowerCase() == 'aoe'">*</span>Ref
                                Code</strong>
                        </mat-label>
                        <mat-select disableOptionCentering formControlName="frmRefCode">
                            <mat-option
                                *ngFor="let P of UDFRefCodes" [value]="P.Id">{{P.Value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 d-flex mt-1 mb-1">
                    <section class="col-sm-3 w-100">
                        <mat-checkbox [color]="task.color" class=" example-margin" formControlName="frmEditable">
                            <strong>Editable</strong>
                        </mat-checkbox>
                    </section>
                    <section class="col-sm-3 w-100">
                        <mat-checkbox [color]="task.color" class=" example-margin" formControlName="frmMandatory">
                            <strong>Mandatory</strong>
                        </mat-checkbox>
                    </section>
                    <section class="col-sm-3 w-100">
                        <mat-checkbox [color]="task.color" class=" example-margin" formControlName="frmValidateSection">
                            <strong>Validate
                                Section Level</strong>
                        </mat-checkbox>
                    </section>
                    <section class="col-sm-3 w-100">
                        <mat-checkbox [color]="task.color" class=" example-margin" formControlName="frmCollapsible">
                            <strong>Collapsible</strong>
                        </mat-checkbox>
                    </section>
                </div>
                <mat-accordion class="col-sm-12 p-0 additional-wrap">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="accordianheader">
                            <mat-panel-title> <strong>
                                    Field Style Details </strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="col-sm-12 w-100 mt-1 p-0 pl-2 m-0">
                            <mat-form-field class="col-sm-3 p-0 m-0 mt-3 form-field" appearance="outline">
                                <mat-label>
                                    <strong> Row Number</strong>
                                </mat-label>
                                <input type="text" matInput maxlength="200" formControlName="frmRowNum"
                                    (keypress)="AllowOnlyNumber($event)">
                            </mat-form-field>
                            <mat-form-field class="col-sm-3 pr-0 mt-3 form-field" appearance="outline">
                                <mat-label>
                                    <strong> Column Number </strong>
                                </mat-label>
                                <input type="text" matInput maxlength="4000" formControlName="frmColNum"
                                    (keypress)="AllowOnlyNumber($event)">
                            </mat-form-field>

                            <mat-form-field class="col-sm-3 mt-3" appearance="outline">
                                <mat-label><strong>Row Span</strong></mat-label>
                                <button matSuffix mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="frmRowSpan" [matAutocomplete]="auto2" style="width:95% !important;"
                                    (keypress)="AllowOnlyNumber($event)">
                                <mat-autocomplete #auto2="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  ['Select',1,2,3,4,5,6,7,8,9,10]" value="{{ option}}"
                                        autoActiveFirstOption title="{{ option}}">
                                        {{ option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 w-100 mt-1 ml-1 m-0 p-0 pl-1">
                            <mat-form-field class="col-sm-3 pl-0 mt-2" appearance="outline">
                                <mat-label><strong>Column Span</strong></mat-label>
                                <button matSuffix mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="frmColSpan" [matAutocomplete]="auto3" style="width:95% !important;"
                                    (keypress)="AllowOnlyNumber($event)">
                                <mat-autocomplete #auto3="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption"
                                        *ngFor="let option of  ['Select',1,2,3,4,5,6,7,8,9,10]" value="{{ option}}"
                                        autoActiveFirstOption title="{{ option}}">
                                        {{ option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="w-100 col-sm-3 p-0 mt-2 form-field" appearance="outline">
                                <mat-label>
                                    <strong>
                                        Section Order</strong>
                                </mat-label>
                                <input type="text" matInput maxlength="4000" formControlName="frmSectionOrder"
                                    (keypress)="AllowOnlyNumber($event)">
                            </mat-form-field>

                            <mat-form-field class="col-sm-3 pr-0 mt-2" appearance="outline">
                                <mat-label><strong>Cell Style</strong></mat-label>
                                <button matSuffix mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="frmCellStyle" [matAutocomplete]="auto4"
                                    style="width:95% !important;">
                                <mat-autocomplete class="mat-opt-align" #auto4="matAutocomplete">
                                    <mat-option id="frmMatOption" value="">
                                        Select
                                    </mat-option>
                                    <mat-option id="frmMatOption" *ngFor="let option of  filterCellStyle | async"
                                        value="{{ option.item}}" autoActiveFirstOption title="{{ option.item}}">
                                        {{ option.item}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-12 mt-1 mb-1 m-0 p-0 pl-2">
                            <mat-form-field class="col-sm-3 pr-0 mt-2 m-0 p-0" appearance="outline">
                                <mat-label><strong>Label Style</strong></mat-label>
                                <button matSuffix mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="frmLabelStyle" [matAutocomplete]="auto5"
                                    style="width:95% !important;">
                                <mat-autocomplete #auto5="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="frmMatOption" value="">
                                        Select
                                    </mat-option>
                                    <mat-option id="frmMatOption" *ngFor="let option of  filterLabelStyle | async"
                                        value="{{ option.item}}" autoActiveFirstOption title="{{ option.item}}">
                                        {{ option.item}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="col-sm-3 pr-0 mt-2" appearance="outline">
                                <mat-label><strong>Control Style</strong></mat-label>
                                <button matSuffix mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="frmControlStyle" [matAutocomplete]="auto7"
                                    style="width:95% !important;">
                                <mat-autocomplete #auto7="matAutocomplete">
                                    <mat-option id="frmMatOption" value="">
                                        Select
                                    </mat-option>
                                    <mat-option class="mat-opt-align" id="frmMatOption" *ngFor="let option of  filterControlStyle | async"
                                        value="{{ option.item}}" autoActiveFirstOption title="{{ option.item}}">
                                        {{ option.item}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="col-sm-3 pr-0 mt-2" appearance="outline">
                                <mat-label><strong>CSS Class</strong></mat-label>
                                <button matSuffix mat-icon-button>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <input type="text" aria-label="Assignee" placeholder="Search..." matInput
                                    formControlName="frmCssClass" [matAutocomplete]="auto8"
                                    style="width:95% !important;">
                                <mat-autocomplete #auto8="matAutocomplete">
                                    <mat-option id="frmMatOption" value="">
                                        Select
                                    </mat-option>
                                    <mat-option class="mat-opt-align" id="frmMatOption" *ngFor="let option of  filterCssClass | async"
                                        value="{{ option.item}}" autoActiveFirstOption title="{{ option.item}}">
                                        {{ option.item}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 mt-1 mb-1 m-0 p-0 pl-1">
                            <mat-form-field class=" col-sm-3 pr-0 mt-3 pl-1" appearance="outline">
                                <mat-label>
                                    <strong>
                                        Div Name</strong>
                                </mat-label>
                                <input type="text" matInput formControlName="frmDivName">
                            </mat-form-field>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion class="col-sm-12 mt-2">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="accordianheader">
                            <mat-panel-title><strong>
                                    Additional Details</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="col-sm-12 p-0 m-0">
                            <div class="row p-0 m-0 mt-2">
                                <mat-form-field class="w-100 col-sm-3 p-0" appearance="outline">
                                    <mat-label>
                                        <strong>
                                            Max Length</strong>
                                    </mat-label>
                                    <input type="text" matInput maxlength="200" formControlName="frmMaxLength"
                                        (keypress)="AllowOnlyNumber($event)">
                                </mat-form-field>

                                <mat-form-field class="w-100 col-sm-3 pr-0" appearance="outline">
                                    <mat-label>
                                        <strong>Water Mark </strong>
                                    </mat-label>
                                    <input type="text" matInput maxlength="4000" formControlName="frmWaterMark">
                                </mat-form-field>

                                <mat-form-field *ngIf="dbName != 'THX' ||dbName != 'THXVAR'" class="w-100 col-sm-3 pr-0"
                                    appearance="outline">
                                    <mat-label>
                                        <strong>
                                            Batch ProfileID</strong>
                                    </mat-label>
                                    <input type="text" matInput formControlName="frmBatchProfileID">
                                </mat-form-field>

                            </div>

                            <div class="col-sm-12 d-flex mt-1 mb-1 p-0">

                                <mat-form-field *ngIf="dbName != 'THX' ||dbName != 'THXVAR'" class="w-100 col-sm-3 p-0 mt-3"
                                    appearance="outline">
                                    <mat-label>
                                        <strong>
                                            Sequence</strong>
                                    </mat-label>
                                    <input type="text" matInput formControlName="frmSequence">
                                </mat-form-field>
                                <mat-form-field class="w-100 col-sm-3 pr-0 mt-3" appearance="outline">
                                    <mat-label>
                                        <strong>
                                            Order CodeID</strong>
                                    </mat-label>
                                    <input type="text" matInput formControlName="frmOrderCodeID">
                                    <!-- <input type="area" matInput maxlength="200"> -->
                                </mat-form-field>

                                <mat-form-field class="w-100 col-sm-3 pr-0 mt-3" appearance="outline">
                                    <mat-label>
                                        <strong>
                                            Spanish Display Name</strong>
                                    </mat-label>
                                    <input type="text" matInput formControlName="frmSpanishName">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-12 d-flex mt-1 mb-1 p-0">
                                <section class="col-sm-3 w-100 mt-1">
                                    <mat-checkbox [color]="task.color" class="mt-2 example-margin"
                                        formControlName="frmQuickAccession"><strong>Quick
                                            Accession</strong></mat-checkbox>
                                </section>

                                <section class="col-sm-3 w-100 mt-1">
                                    <mat-checkbox [color]="task.color" class="mt-2 example-margin"
                                        formControlName="frmHTMLControl">
                                        <strong>HTML
                                            Control</strong>
                                    </mat-checkbox>
                                </section>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="button-wrap col-sm-9 mt-4 ">
                <button *ngIf="action == 'Edit UDF'" mat-raised-button class="admin-btn-success mr-4"
                    (click)="validateForm('Update','',UDFFormGroup.value)">
                    Save & Close
                </button>
                <button *ngIf="action == 'Create UDF'" mat-raised-button class="admin-btn-success mr-4"
                    (click)="validateForm('','',UDFFormGroup.value)">
                    Save & Close
                </button>
                <button *ngIf="action == 'Create UDF'" mat-raised-button class="admin-btn-success"
                    (click)="validateForm('','new',UDFFormGroup.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="copyScreenPage">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Copy User Defined Fields</strong>
        </h3>
    </div>
    <form [formGroup]="copyFromOrgFrm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-2">
            <div class="col-sm-12">
                <mat-form-field class="col-sm-4 example-additional-selection mt-2 mat-height-wrap" appearance="outline">
                    <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDeplyment">
                        <mat-option class="dropdown-opt-wrap" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label>Group <span class="error-msg">*</span></mat-label>
                    <button matSuffix mat-icon-button (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <input type="text" matInput formControlName="frmOrganization" #searchbar
                        (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"
                        (keyup)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization); trimFieldValue('frmOrganization')" [matAutocomplete]="orgauto"
                        placeholder="Search here ..." style="width:95% !important;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                *ngIf="show.orgname.toLowerCase().includes(searchInput.toLowerCase())"
                                title="{{ show.orgname }} ({{show.orgid}})"
                                (onSelectionChange)="getDataToCopy(show)"  
                                value="{{ show.orgname }}">
                                <span>{{ show.orgname }} ({{show.orgid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>

                <div *ngIf="gridWidth > 0" class="col-sm-12 mt-2">
                    <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
                        (initialized)="initGrid(flexgrid,1)" #flexgrid
                        (formatItem)="formatItem(flexgrid, $event)" [frozenColumns]="2">
                        <div *ngFor="let columnname of sheetHeader">
                            <wj-flex-grid-column
                                [visible]="!(columnname == 'OrganizationId') && !(columnname == 'AttributeId') && !(columnname == 'Casetype')"
                                [binding]="columnname?.toString().toLowerCase()" [header]="columnname" [format]="'d'"
                                [allowResizing]="true" [width]="130" [allowDragging]="false" [allowSorting]="false">
                            </wj-flex-grid-column>
                        </div>
                        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                    <section *ngIf="!postUpload" class="align-center row">
                        <input type="checkbox" id="copyasinactive" [disabled]="selectedData.length==0"
                            ng-init='checkStatus=false' [checked]='checkStatus' formControlName="Copyisactive"
                            ng-model='checkStatus' class="border-style">
                        <label for="DKeys" class="font-style col-sm-1.5 m-0 p-0 ml-1">Copy as Inactive</label>
                    </section>
                    <div class="col-sm-12 p-0 mt-4 mb-3">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                            (click)="refreshGrid('list')">Back</button>
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                            (click)="removeGrid('back')">Back</button>
                        <span style="float: right;">
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                                (click)="refreshGrid('list')">Cancel</button>
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                                (click)="refreshGrid('list')">Finish</button>
                            <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                                class="admin-btn-success" (click)="uploadUDFData(true)">Approve</button>
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                                (click)="ExportResult(excelDataArray)">Download Result</button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12" *ngIf="gridWidth == 0">
                <div class="container create-btn col-sm-12 align-center " *ngIf="noDataFoundCopy">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">Back</button>
            </div>
        </div>
    </form>
</div>
</span>
