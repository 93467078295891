import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterValues'
})
export class FilterValuesPipe implements PipeTransform {

  transform(value: any, args: string): any {
    if(args && args.length > 0) return value.map((j) => {
      let results =  j.results.filter((res) => res.siteName.trim().toLowerCase().includes(args.trim().toLowerCase()))
      return {...j, results}
  })
    else return value;
  }

}
