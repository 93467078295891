import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../../core/services/commonservices';
import { MatDialog } from '@angular/material/dialog';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExcelService } from '../../../core/services/excel.service';
import { DatePipe } from '@angular/common';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import * as XLSX from 'xlsx';
import { CollectionView } from '@grapecity/wijmo';
import { environment } from 'src/environments/environment';
import { format, parse } from 'date-fns';

declare var $:any;

@Component({
  selector: 'app-manage-caselist',
  templateUrl: './manage-caselist.component.html',
  styleUrls: ['./manage-caselist.component.scss']
})
export class ManageCaselistComponent {
  @Input() selecteddefaultdeploymentkey: any;
  @Input() templateData: any;

  frm_caselist = this._fb.group({
    frmDeploymentKey: ['', Validators.required]
  });
  EnteredData = this._fb.group({

  });
  public DeploymentKeys = [];
  InitialDeployKey: string;
  closedModal: boolean;
  enableDeploymentlist: boolean;
  selectedData: boolean;
  FormDataEntry: boolean;
  disableDeployment: boolean;
  deploymentKey: any;
  headerDeploymentKey: any;
  allOrglist: any = [];
  destOrgid: string;
  gridPage: boolean;
  gridDisplay: boolean;
  gridWidth: number=0;
  workBook: any;
  sheetsToSelect: any[];
  excelDataArray: any[];
  sheetHeader: any[];
  searchText: any;
  gridData: any = [];
  postUpload: boolean = false;
  toolTipText: any;
  optionToolTipText: any;
  dataSelect: boolean;
  backBtn: boolean;
  postDownload: boolean;
  showInvalidColumns: boolean;
  invalidColumns: string;
  showDelete: boolean;
  auditableColumns: any;
  configuredData: any;
  updateFlag: any;
  configureSheets: any;
  missingHeaders: any = [];
  activityEntity: any;
  trackerInput: { productId: any; customerId: string; entityType: any; actionType: string; entityId: any; ticketId: string; index: string; locationId: string; context: any; createdBy: string; startDate: string; endDate: string; };
  activityPath: any[];
  openActivityTkr: boolean;
  trackerApiUrl: any;
  actiontype: string;
  auditUserId: any;
  isUpdateChecked:boolean = false;
  updatedData: any;

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;

  updateFields: any = [{
    AccessionNumber: null,
    RegistryStatus: null,
    RegistrySubmittedDate: null,
    LabOrgid: null,
    Source: null,
    ReportedForState: null,
    ReportedForCounty: null,
    ReportedState: null,
    ReportedCounty: null,
  }];

  sampleDataUpdate = [{
    AccessionNumber:'BDC24-0069',
    RegistryStatus : 'NotDetected',
    RegistrySubmittedDate : '28/02/2024 12:00:00',
    LabOrgid : 12036,
    Source : 'State',
    ReportedForState : 'Patient',
    ReportedForCounty : 'Patient',
    ReportedState : 'Florida',
    ReportedCounty : 'SampleCounty'
  }]


  constructor(public ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private dialog: MatDialog, public _snackBar: MatSnackBar,private excelService: ExcelService,private datePipe: DatePipe, public activityService: ActivityTrackerService) {
    this.InitialDeployKey = sessionStorage.getItem('DeploymentKey') ? sessionStorage.getItem('DeploymentKey').toString() : '';
    this.vaservices.deploymentKey = this.InitialDeployKey;
    this.templateData = {menuURL: 'caselist'};
    this.frm_caselist.patchValue({
      frmDeploymentKey: "none"
    });
    this.DeploymentKeys = this.vaservices.DeploymentKeys;
    this.frm_caselist.controls['frmDeploymentKey'].enable()
  }
  ngOnInit() {
    this.selectedData = true;
    this.frm_caselist.patchValue({
      frmDeploymentKey: ''
    });
    this.deploymentKey = null;
    if (this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase() != 'all') {
      this.onChangeDep(this.selecteddefaultdeploymentkey)
    }
    this.getConfigurations();
    this.getAuditableDetails('Case List')
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Case List', 'context': [{ 'key': 'home','value':  'Case List' }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.stop();
  }

  //#region On click of the 'X' button to close modal
  caseListClose() {
    this.selectedData = true;
    this.enableDeploymentlist = false;
    this.closedModal = true;
    this.FormDataEntry = false;
    this.disableDeployment = false;
    this.frm_caselist.patchValue({
      frmDeploymentKey: ''
    });
    this.dataSelect = false;
    this.gridWidth = 0;
    this.deploymentKey = null;
    this.postUpload = false;
    $('#genericModal').modal('hide');
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.formcontrol['frmDeploymentKey'].enable();
  }
  //#endregion

  get formcontrol() {
    return this.frm_caselist.controls;
  }

  async onChangeDep(deviceValue) {
    this.selectedData = false;
    this.dataSelect = true;
    this.deploymentKey = deviceValue;
    this.headerDeploymentKey = deviceValue;
    this.auditUserId = Object.entries(this.vaservices.auditUsers).find(([key]) => key.toLowerCase() === deviceValue.toLowerCase())?.toString() || '';
    sessionStorage.setItem('DeploymentKey',deviceValue);
    this.frm_caselist = this._fb.group({
      frmDeploymentKey: [deviceValue, Validators.required]
    });
    this.formcontrol['frmDeploymentKey'].disable();
    this.ngxService.stop();
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackBar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va=> va.toLowerCase() != 'sampledata');
          if (!this.isValidExcelFile(this.sheetsToSelect)) {
            this._snackBar.open('Invalid Excel file. Please upload the correct file.', 'Close');
            return;
          }
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == this.configuredData.tablename.toLowerCase())
            if (validSheet.length == 0) {
              this._snackBar.open('No matching sheet found for the configured table name.', 'Close');
              return;
            }
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.validateSheetStructure(this.sheetsToSelect[0]);
              if(this.missingHeaders.length > 0){
                return;
              }
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  // Example validation function
  isValidExcelFile(sheetNames: string[]): boolean {
    const expectedSheetName = this.configuredData.tablename.toLowerCase();
    return sheetNames.some(sheetName => sheetName.toLowerCase() === expectedSheetName);
  }

  validateSheetStructure(sheetName: string) {
    const sheet = this.workBook.Sheets[sheetName];
    const sheetJson = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const requiredHeaders = ['AccessionNumber','RegistryStatus','RegistrySubmittedDate','LabOrgid','Source','ReportedForState','ReportedForCounty','ReportedState','ReportedCounty'];
    const sheetHeaders = sheetJson[0];
    this.missingHeaders = requiredHeaders.filter(header => !sheetHeaders.toString().includes(header));
    if (this.missingHeaders.length > 0) {
        this._snackBar.open(`The sheet is missing required headers: ${this.missingHeaders.join(', ')}`, 'Close');
        return false;
    }
    return true;
  }
   //#region Method to validate sheet data.
   convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {};
    let tempArray = [];
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackBar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      this.formcontrol['frmDeploymentKey'].setErrors(null);
      return;
    }
    tempExcelArr.find((r): any => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          if (!value) {
            value = null;
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary);
      primary = {};
    });
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].reverse();
    temp[0].push("ResultMessage");
    temp[0].push("Result");
    this.sheetHeader = temp[0];
    this.sheetHeader.reverse();
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.commonService.convertObjKeysToLower(tempArray);
    this.excelDataArray.forEach(obj => {
      for (const key of this.configuredData.columns[0]["Bulk Update"]) {
        if (!obj.hasOwnProperty(key?.toLowerCase())) {
          obj[key?.toLowerCase()] = "";
        }
      }
    });
    this.excelDataArray.find((d): any => {
      Object.assign(d, {
        slno: Number(i), resultmessage: '', tablename: this.configuredData.tablename, type: 'update'
      });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  //#endregion

  validateExcel(excelArr) {
    this.ngxService.start();
    const dataArray = this.convertObjKeysToLower(excelArr)
    let validateObj = {
      "storedProcedureName": this.configuredData.sp[0]["validate"],
      "jsonData": dataArray,
      "isValidate": true,
      "columnsToRemove": ["accountid","source","isvalid"]
    }
    this.vaservices.bulkUploadFunctionalities(validateObj, this.deploymentKey).subscribe(
      (result) => {
        if (!result.errors) {
          let data;
          if (result.status.toLowerCase() == 'success') {
            this.postUpload = false;
            data = JSON.parse(result.content);
            if (data[0]["InvalidColumns"] && data[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = data[0].InvalidColumns;
            }
            this.excelDataArray.filter(e => {
              data.find(r => {
                if (r.slno === e.slno) {
                  e.resultmessage = r.resultmessage;
                  e.isvalid = r.isvalid;
                }
              })
            });
            this.updatedData = data.map(item => ({
              ...item,
              source: !item.source ? 'State' : item.source
            }));
            this.excelGridData(this.updatedData)
          }
          else {
            this._snackBar.open("Something went wrong!", "Close");
          }
        } else {
          this._snackBar.open("Please provide valid data", 'Failed');
        }
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
        this._snackBar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
      }
    );
  }

  convertObjKeysToLower(inputArr) {
    return inputArr.map(obj => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key.replace(/\s/g, "").toLowerCase()] = value;
        return acc;
      }, {});
    });
  }

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if(result){
      this.convertToJson(result)
    }
  }
  //#endregion

  //#region Download All Fields
  downloadTemplate(type: String) {
    let templateType
    let sample
    let sampleExcel = [{}]
    if(type == 'Bulk Update'){
      templateType = this.updateFields
      sample = true;
      sampleExcel = this.sampleDataUpdate
    }
    let filename = 'CaseList_' + type + ' Fields_' + '.xlsx';
    templateType.reverse()
    var ws = XLSX.utils.json_to_sheet(templateType);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'caselist');
    sample ? ws = XLSX.utils.json_to_sheet(sampleExcel) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  //Back to selecting deployment
  deploymentSelection() {
    this.EnteredData.reset();
    this.FormDataEntry = false;
    this.dataSelect = false;
    this.isUpdateChecked = false;
    this.frm_caselist.patchValue({
      frmDeploymentKey: ''
    })
    this.postUpload = false;
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.formcontrol['frmDeploymentKey'].enable();
  }
  //#endregion

  filtertemplates() {
    let excelDataArray = this.excelDataArray.filter(obj =>
      Object.values(obj).some(value =>
        typeof value === 'string' ? value.toLowerCase().includes(this.searchText.toLowerCase()) :
          typeof value === 'number' ? value.toString().includes(this.searchText) : false
      ))
    this.excelGridData(excelDataArray);
  }

  clearSearch() {
    this.searchText = '';
    this.filtertemplates();
  }

  ngOnDestroy(): void {
    this.gridData = new CollectionView([]);
    console.log('data',this.gridData);

  }

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridData = [];
    if (data && data.length > 0) {
      this.gridData = data;
      if (!this.postUpload) {
        this.sheetHeader.includes("Result") && this.sheetHeader.splice(this.sheetHeader.indexOf("Result"), 1);
      } else {
        !this.sheetHeader.includes("Result") && this.sheetHeader.splice(0, 0, "Result")
      }
      this.gridWidth = (170 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  //#region
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
    this.isUpdateChecked = false;
  }
  //#endregion

   //#region Export Grid data
   ExportExcel() {
    let excel = [];
    this.updatedData.forEach(item => {
      const filteredObject = this.sheetHeader.reduce((acc, key) => {
        if (item.hasOwnProperty(key?.toLowerCase())) {
          acc[key.toLowerCase()] = item[key.toLowerCase()];
        }
        return acc;
      }, {});
      excel.push(filteredObject);
    });
    let filename = 'CaseList' + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'caselist');
    XLSX.writeFile(wb, filename);
  }
  //#endregion

  //#region Upload ExcelData to Insert API
  uploadData() {
    this.uploadBulk()
  }
  //#endregion

  getConfigurations() {
    this.ngxService.start();
    let query = this.vaservices.GetQuery('CategoryDropDown');
    let queryVariable = { keyword: 'Case List', context: 'bulkFunctionalities' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult, 'configdb').subscribe(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.configuredData = JSON.parse(Resdata?.data?.submenuData[0]?.Items_JSON);
        this.configureSheets = this.configuredData.sheets;
      }
    }, error => {
      this.ngxService.stop()
      console.error(error);
    })
  }

  //#region
  uploadBulk() {
  if (this.updatedData && this.updatedData.length > 0) {
    let errorcount = 0;
    for (let i = 0; i < this.updatedData.length; i++) {
      if (this.updatedData[i]["isvalid"] == 0 || this.updatedData[i]["isvalid"] == -1) {
        errorcount++;
      }
    }
    if (errorcount > 0) {
      this._snackBar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
      return
    }
    let id = this.auditUserId.split(',')[1];
    const updatedNewData = this.updatedData.map(item => ({
      ...item,
      loggedinuserid: id ? id : -1
    }))
    updatedNewData.forEach(element => {
      if(element['registrysubmitteddate']){
        element['registrysubmitteddate'] = this.convertTo24HourDateTime(element['registrysubmitteddate'])
      }
    });

    let procName = this.configuredData.sp[0]["update"]
    this.ngxService.start();
    let uploadObj = {
      "storedProcedureName": procName,
      "jsonData":  updatedNewData
    }
    this.vaservices.bulkUploadFunctionalities(uploadObj, this.deploymentKey).subscribe(res => {
        this.ngxService.stop();
        this.actiontype = 'Bulk Update'
        if (res.status.toLowerCase() == 'success') {
          let result = JSON.parse(res.content);
          this.postUpload = true;
          this.updatedData = [...result]
          this.updatedData.forEach(element => {
            if(element['registrysubmitteddate']){
              element['registrysubmitteddate'] = this.convertTo12HourDateTime(element['registrysubmitteddate'])
            }
          });
          this.excelGridData(this.updatedData);
          this.commonService.auditDetails('', '', [], this.updatedData, this.actiontype, this.templateData, this.auditableColumns);
        }
        else{
          this._snackBar.open("Something went wrong!","Close");
        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }

   //#region Delete Row (soft-delete)
   deleteRow(row) {
    this.updatedData = this.updatedData.filter(va => va.slno != row.slno);
    if(!this.isUpdateChecked && !this.updatedData.some(va=>va.resultmessage.toString().toLowerCase().includes('updated'))){
      this.isUpdateChecked = true;
    }
    this.excelGridData(this.updatedData)
    if (this.updatedData.length == 0) {
      this.removeGrid();
    }
    this._snackBar.open('Deleted successfully', 'Close');
  }
  //#endregion

  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }
  displayedColumns(item: any, header: any) {
    let data = item[header] ? item[header] : ""
    return data;
  }

  backClicked(event) {
    this.removeGrid();
    this.FormDataEntry = false;
    this.dataSelect = false;
    this.isUpdateChecked = false;
    this.frm_caselist.patchValue({
      frmDeploymentKey: ''
    })
    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.formcontrol['frmDeploymentKey'].enable();
  }

  disableApprovebtn() {

  }

  //#endregion
  getAuditableDetails(location: any) {
    this.vaservices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);

    })
  }

  openActivityTracker() {
    this.ngxService.start();
    this.openActivityTkr = true;
    let context = {};
    let activitySearchParams: any;
    this.trackerApiUrl = environment.baseUrl2;
    this.activityService.getActivitySession.subscribe(activityTab => activitySearchParams = activityTab);
    activitySearchParams.entityType = 'caselist';
    this.activityService.setActivitySession(activitySearchParams);

    this.activityPath = ['Home', 'Bulk Upload Caselist', 'Case List'];

    this.trackerInput = {
      'productId': this.vaservices.GetDxurlBasedOnDeployment(sessionStorage.getItem('DeploymentKey')).replace(/(^\w+:|^)\/\//, ''),
      'customerId': '',
      'entityType': activitySearchParams.entityType,
      'actionType': 'audit',
      'entityId': activitySearchParams.entityId,
      'ticketId': '',
      'index': '',
      'locationId': '',
      'context': activitySearchParams.context,
      'createdBy': '',
      'startDate': '',
      'endDate': ''
    }
    this.ngxService.stop();
  }

  convertTo24HourDateTime(dateString: string): string {
    const parsedDate = parse(dateString, 'dd/MM/yyyy hh:mm:ss a', new Date());
    const formattedDateTime = format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
    return formattedDateTime;
  }

  convertTo12HourDateTime(dateString: string): string {
    const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formattedDateTime = format(parsedDate, 'dd/MM/yyyy hh:mm:ss a');
    return formattedDateTime;
  }

}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}


