import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { qualityAttributeAndFlags } from 'src/app/common/constants/qualityAttributeAndFlags';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/base/popup/dialog/dialog.component';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';

@Component({
  selector: 'app-new-case-profiles',
  templateUrl: './new-case-profiles.component.html',
  styleUrls: ['./new-case-profiles.component.scss'],
})
export class NewCaseProfilesComponent implements AfterViewInit, OnDestroy {
  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideEditBtn: boolean;
  gridheader = [
    'CaseProfileId',
    'accountid',
    'IsActive',
    'Modified_by',
    'Modified_date',
    'Bill_To',
    'ICD10',
    'caseid',
    'No_of_jars',
    'Services',
    'Extraction_procedure',
    'Order_code',
    'Case_type',
    'Display_name',
    'Sequence',
  ];
  @Input() templateData: any;
  gridData: any;
  private dragIndex: number;
  hideDragnDropBtn: any;
  changeToTrue: boolean;
  subMenuCardModel: SubMenuCardModel;
  tempExport: any[];
  profilesSelectionModel = new SelectionModel(false, []);
  isactive: Boolean = true;
  tooTipText = '';
  profiles = [];
  filterCondition = new BehaviorSubject({ status: 'all', searchText: '' });
  filterCondition$ = this.filterCondition.asObservable();
  updateCaseProfile=new BehaviorSubject<any>(null)
  updateCaseProfile$ = this.updateCaseProfile.asObservable();
  caseProfiles$: any;
  caseProfilesFitered$: any;
  sequenceEditMode: boolean = false;
  isSortDataChanged: boolean = false;
  caseProfileSortData: { currentData: any; previousData: any };
  dbName;
  scrollHistory: number = 0;
  caseprofilesessionUnsubscribe;
  @ViewChildren('scrollableDiv') maindiv;
  maxSequence: number = 0;
  userID: any;
  orgId: any;
  locationId: any;
  activityEntity: any;
  isTabActive: boolean = false;

  flagsSelectionModel = new SelectionModel(false, []);
  auditableColumns: { status: boolean };

  constructor(
    private vitalservice: VitalHttpServices,
    private _dialog: DialogService,
    private datashare: DataShareService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private _snackbar: MatSnackBar,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>,
    private labAdminService: LabadminService,
    private activityService: ActivityTrackerService,
    private matdialog: MatDialog
  ) {
    this.subMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalservice,
      datashare
    );
  }
  ngOnDestroy(): void {
    document.removeEventListener('visibilitychange', this.onVisibilityChange.bind(this));
    this.sourceApp == 'VitalDx'
      ? this.caseprofilesessionUnsubscribe.unsubscribe()
      : '';
  }

  toolTipshow(element) {
    this.tooTipText =
      element.scrollWidth > element.clientWidth ? element.textContent : '';
  }
  userSessionDetails: any;
  sourceApp: string = '';
  destDeployment: string;
  showloader = true;
  IsconfigurationEnabled:boolean = true;

  ngOnInit() {
    document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
    let checkLaunchUrl = window.location.href.split('/#/')[1];
    if (checkLaunchUrl.toString().toLowerCase() !== 'home') {
      this.caseprofilesessionUnsubscribe =
        this.labAdminSessionService.getLabAdminSession
          .pipe(
            concatMap((session: any) =>
              this.store.select('breadcrum').pipe(
                take(1),
                concatMap((i) => {
                  this.userSessionDetails = session['userDetails'];
                  this.sourceApp = 'VitalDx';
                  return this.commonService.getCommmonTemplateData(
                    i[i.length - 1],
                    this.userSessionDetails
                  );
                })
              )
            )
          )
          .subscribe((data) => {
            this.templateData = this.labAdminService.templateData;
            this.dbName = this.labAdminService.deploymentKey;
            this.userID = this.userSessionDetails.userId;
            this.locationId =
              this.userSessionDetails.userAccountIDs[0].accountId;
            this.getCaseProfileData();
            this.GetButtondetails(this.labAdminService.subMenuPermissions);
            this.getUrlConfigurationEnabled(this.dbName);
          });
      this.activityService.setActivitySession({
        entityId: '',
        entityType: 'Case profiles',
        context: [
          { key: 'parentMenu', value: 'Lab' },
          { key: 'Lab Management', value: 'Case profiles' },
        ],
      });
      this.getAuditableDetails('Case profiles');
    
    } else {
      this.dbName = sessionStorage.getItem('deploymentKey');
      // this.orgId=sessionStorage.getItem('org_id')
      this.userID = sessionStorage.getItem('Userid');
      this.locationId = sessionStorage.getItem('Locationid');
      this.getCaseProfileData();
      this.activityService.setActivitySession({
        entityId: '',
        entityType: this.templateData.menuURL,
        context: [{ key: 'parentMenu', value: this.templateData.menuURL }],
      });
      this.activityService.getActivitySession.subscribe(
        (res) => (this.activityEntity = res)
      );
      this.GetButtondetails(this.vitalservice.SubmenuAction);
      this.getAuditableDetails(this.templateData.menuURL);
      this.getUrlConfigurationEnabled(this.dbName);
    }
  }
  getUrlConfigurationEnabled(deployment:string){
    this.caseProfiles$ = this.vitalservice
    .GetData(
      {
        OperationName: null,
        Query: `query{
            CaseProfiles:VitalAdminAttributes(Context: "Encryption", Keyword: "CaseProfiles") {
              Items_JSON
            }
          }`,
        Variables: null,
      },
     'configdb'
    ).subscribe({
next: (data)=>{
if(data?.data?.CaseProfiles?.Items_JSON)
{
   const resultData=JSON.parse(data.data.CaseProfiles.Items_JSON)
   this.IsconfigurationEnabled=resultData[deployment.toLowerCase()]=="true"

}
},error: (err)=>{
  console.error(err)
    }
  }
  )
  }
  //filter Functionality
  filterStatus(updateObj: any) {
    this.filterCondition.next({ ...this.filterCondition.value, ...updateObj });
  }

  tempCaseProfiles: any = []
  firstTime: boolean = true
  getCaseProfileData() {
    this.firstTime=true;
    this.showloader = true;
    this.updateCaseProfile.next(null)
    this.caseProfiles$ = combineLatest([this.vitalservice
      .GetData(
        {
          OperationName: null,
          Query: this.vitalservice.GetQuery(this.templateData.Keyword),
          Variables: {
            orgid: this.templateData.secondarykeys.OrganizationId.toString(),
          },
        },
        this.dbName
      )
      .pipe(
        tap(() => (this.showloader = true)),
        map((d: any) => {
          if(this.firstTime){
            this.tempCaseProfiles = d.data.submenuData
            this.firstTime = false
          }
          return this.tempCaseProfiles
        }),
        catchError((err) => {
          this._snackbar.open('Unable to process request.', 'Close');
          console.error(err);
         this.showloader = false;
          return err;
        })
      ),this.updateCaseProfile$]).pipe(
        map(([data,alteringData]:any)=>{
          if(alteringData && this.tempCaseProfiles && this.tempCaseProfiles.length > 0)
            {
              return this.tempCaseProfiles=this.tempCaseProfiles?.map(i=>(i.CaseProfileId==alteringData.CaseProfileId?{...i,...alteringData}:{...i}))
            }
          return this.tempCaseProfiles
        })
      );
    this.caseProfilesFitered$ = combineLatest([
      this.caseProfiles$,
      this.filterCondition$
    ]).pipe(
      map(([data, filterValue]: any) => {
        if (data && data.length > 0) {
          let res = [];
          res =
            filterValue.searchText != ''
              ? data.filter((i) =>
                  this.filterCaseProfiles(i, filterValue.searchText)
                )
              : data;
          const totalCount = res.length;
          const activeCount = res.filter((v) => v.IsActive).length;
          res =
            filterValue.status.toLowerCase() != 'all'
              ? filterValue.status.toLowerCase() == 'active'
                ? res.filter((v) => v.IsActive)
                : res.filter((v) => !v.IsActive)
              : res;
          return {
            data: res,
            totalCount,
            activeCount,
            isNoDataPreseant: false,
          };
        }
        return {
          data: [],
          totalCount: 0,
          activeCount: 0,
          isNoDataPreseant: true,
        };
      }),
      tap((res: any) => {
        this.caseProfileSortData = {
          currentData: res.data,
          previousData: res.data.map((j) => ({ ...j })),
        };
        this.maxSequence = res.data.length;
        this.showloader = false;
      })
    );
  }
  stringNormalize(str: any) {
    return str != null ? str.toString().toLowerCase().replace(/\s/g, '') : '';
  }
  filterCaseProfiles(obj, text: string) {
    let txt = this.stringNormalize(text);
    return (
      this.stringNormalize(obj.Case_type).includes(txt) ||
      this.stringNormalize(obj.Display_name).includes(txt) ||
      this.stringNormalize(obj.Order_code).includes(txt) ||
      this.stringNormalize(obj.Extraction_procedure).includes(txt) ||
      this.stringNormalize(obj.No_of_jars).includes(txt) ||
      this.stringNormalize(obj.ICD10).includes(txt) ||
      this.stringNormalize(obj.Bill_To).includes(txt) ||
      this.stringNormalize(obj.Services).includes(txt)
    );
  }

  // for RBAC
  GetButtondetails(data: any) {
    this.GetButtonAccess(data);
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(
        (e) => e.Htext == this.labAdminService.templateData.menuURL
      )['ActionButton'];
    } else {
      seletedMenuPermissions = actionButtonDetails
        .find((e) => e.Htext == this.templateData.headerText)
        ['SubMenu'].find((ele) => ele.URL == this.templateData.menuURL)[
        'ActionButton'
      ];
    }
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn =
            seletedMenuPermissions[i].IsPermitted == 'true' ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn =
            seletedMenuPermissions[i].IsPermitted == 'true' ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn =
            seletedMenuPermissions[i].IsPermitted == 'true' ? false : true;
          break;
        case 'DragnDrop':
          this.hideDragnDropBtn =
            seletedMenuPermissions[i].IsPermitted == 'true' ? false : true;
          break;
      }
    }
  }

  editCaseProfile(row) {
    if (!this.userID || this.userID == '') {
      this._snackbar.open('Userid does not exist in the deployment.', 'Close');
      return;
    }
    this.ngxService.start()
    this.isTabActive = true;
    let deployid = this.dbName;
    let dxurl = this.vitalservice.GetDxurlBasedOnDeployment(deployid);
    dxurl = dxurl.endsWith('/') ? dxurl.slice(0, -1) : dxurl;
    if(this.IsconfigurationEnabled)
    {
      const object={caseid:row.caseid,organizationid:this.templateData.secondarykeys.OrganizationId,userid:this.userID,accountid:row.accountid}
      this.labAdminService.getEncryptedValue(object).subscribe({next:(data:any)=>{
        let queryString =
        `/VACreateCaseProfile.aspx?caseid=${data.caseid}&from=admin&organizationid=${ data.organizationid}&userid=${data.userid}&accountid=${data.accountid}`;
      var newWindow = window.open(dxurl + queryString);
      this.ngxService.stop()
      },error:(err)=>{
        console.error('Something went wrong!')
        this.ngxService.stop()
      }})
    }
    else{
      const data={caseid:row.caseid,organizationid:this.templateData.secondarykeys.OrganizationId,userid:this.userID,accountid:row.accountid}
        let queryString =
        `/VACreateCaseProfile.aspx?caseid=${data.caseid}&from=admin&organizationid=${ data.organizationid}&userid=${data.userid}&accountid=${data.accountid}`;
      var newWindow = window.open(dxurl + queryString);
      this.ngxService.stop()

    }



  }

  createCaseProfile() {
    if (!this.userID || this.userID == '') {
      // checking the userid exist or not
      this._snackbar.open('Userid does not exist in the deployment.', 'Close');
      return;
    }
    this.ngxService.start()
    this.isTabActive = true;
    let deployid = this.dbName;
    let dxurl = this.vitalservice.GetDxurlBasedOnDeployment(deployid);
    dxurl = dxurl.endsWith('/') ? dxurl.slice(0, -1) : dxurl;
    if (this.IsconfigurationEnabled)
      {
        const object={caseid:0,organizationid:this.templateData.secondarykeys.OrganizationId,userid:this.userID,accountid:this.locationId}
        this.labAdminService.getEncryptedValue(object).subscribe({next:(data:any)=>{
          let queryString =
          `/VACreateCaseProfile.aspx?caseid=${data.caseid}&from=admin&organizationid=${ data.organizationid}&userid=${data.userid}&accountid=${data.accountid}`;
        var newWindow = window.open(dxurl + queryString);
        this.ngxService.stop()
        },error:(err)=>{
          console.error('Something went wrong!')
          this.ngxService.stop()
        }})
      }
      else{
        const data={caseid:0,organizationid:this.templateData.secondarykeys.OrganizationId,userid:this.userID,accountid:this.locationId}
        let queryString =
          `/VACreateCaseProfile.aspx?caseid=${data.caseid}&from=admin&organizationid=${ data.organizationid}&userid=${data.userid}&accountid=${data.accountid}`;
        var newWindow = window.open(dxurl + queryString);
        this.ngxService.stop()

      }

    }

  // saves the drag and droped data
  drop(event: CdkDragDrop<any>) {
    if (
      event.previousContainer === event.container &&
      event.currentIndex != event.previousIndex
    ) {
      // let previousIndex = event.previousIndex
      // let currentIndex = event.currentIndex

      // if (event.previousIndex > event.currentIndex) {
      //   previousIndex = event.currentIndex
      //   currentIndex = event.previousIndex
      // }

      //let previousData = event.previousContainer.data.slice(previousIndex, currentIndex + 1).map((i) => i.Sequence)

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.checkSequenceChanged();
      // let currentChangedData = event.container.data.slice(previousIndex, currentIndex + 1)

      // if (currentChangedData.length > 1) {
      //   let modifiedAttributes = []

      //   for (let index = 0; index < currentChangedData.length; index++) {
      //     const element = { ...currentChangedData[index] };
      //     element.Sequence = previousData[index]
      //     modifiedAttributes.push(element)
      //   }

      //   let data = modifiedAttributes.map((i) => (
      //     {
      //       id: i.CaseProfileId,
      //       sequence: i.Sequence
      //     }
      //   ))

      //   this.ngxService.start()
      //   this.vitalservice.SwapSequence(qualityAttributeAndFlags.Swap_CaseProfiles, data)
      //     .subscribe({
      //       next: () => {
      //         this._snackbar.open('Sequence updated successfully', 'Close')
      //         this.getCaseProfileData()
      //       },
      //       error: () => {
      //         this._snackbar.open('Something went wrong. Please try again', 'Close')
      //         moveItemInArray(
      //           event.container.data,
      //           event.currentIndex,
      //           event.previousIndex
      //         );
      //         this.ngxService.stop()
      //       },
      //       complete: () => this.ngxService.stop()
      //     })
      // }
    }
  }

  UpdateStatusOfCaseprofile(i) {
           let obj = {
            isactive: i.IsActive,
            caseProfileId: i.CaseProfileId,
            userid: !this.userID || this.userID == '' ? -100 : this.userID,
          };
          this.ngxService.start();
          this.vitalservice
            .UpdateActiveStatusforCaseProfile(obj, this.dbName)
            .subscribe(
              (res) => {
                if (res && res.status == "true") {
                  //this.getUpdatedGrid();
                  this.ngxService.stop();
                  this._snackbar.open(res.statusmessage+'.', 'Close');
                  this.updateCaseProfile.next(res.content)
                  this.commonService.createActivityObject(
                    i.CaseProfileId,
                    i.Display_name,
                    this.templateData.menuURL,
                    'Edit',
                    { status: i.IsActive ? "Active" : "In Active" },
                    { status: !i.IsActive  ? "Active" : "In Active" },
                    this.commonService.generateGuid(),
                    this.auditableColumns
                  );

                  //this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, { isactive: oldValue })
                }
              },
              (error) => {
                // Change position in view level if update fail
                i.IsActive = !i.IsActive;
                this.ngxService.stop();
                this._snackbar.open(
                  'An error occurred while processing your request.',
                  'Failed'
                );
              }
            );
      }

  UpdateVAEntityAttribute(value: boolean) {
    let obj = {
      entityid: this.templateData.secondarykeys.OrganizationId,
      configid: this.templateData.secondarykeys.OrganizationId,
      entytitype: 2,
      AttributeName: 'IsCaseProfileEnabled',
      ConfigName: 'IsCaseProfileEnabled',
      tableName: 'VaEntityExtAttributes',
      scope: 3,
      ExtAttrValue1: value,
      id: -1,
      description: 'Update IsCaseProfileEnabled Flag',
    };

    this.ngxService.start();
    this.vitalservice.UpdateVAEntityAttribute(obj, this.dbName).subscribe(
      (res) => {
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
        this._snackbar.open(
          'An error occurred while processing your request.',
          'Failed'
        );
      }
    );
  }

  ngAfterViewInit(): void {
    this.maindiv.changes.subscribe((comps: QueryList<any>) => {
      if (comps.first) comps.first.nativeElement.scrollTop = this.scrollHistory;
    });
  }

  // export the list of case profile data in Excel format
  exportexcel(profiles) {
    const fileName = this.sourceApp=='VitalDx'?'Case Profiles.xlsx':`Case_Profiles_${this.templateData.secondarykeys.OrganizationId.toString()}.xlsx`;
    let profilesExportData = profiles.map((i) => {
      let obj={
        'Case Profile Id':i.CaseProfileId?.toString(),
        // 'Sequence':i.CaseProfileSequence?.toString(),
        'Display Name':i.Display_name,
        'Case Type':i.Case_type,
        'Order Code':i.Order_code?.toString(),
        'Extraction Procedure':i.Extraction_procedure,
        'Services':i.Services,
        'Jar(s)':i.No_of_jars?.toString(),
        'ICD Code':i.ICD10?.toString(),
        'Bill To':i.Bill_To,
        'Status':i.IsActive ? 'Active' : 'InActive',
        'Modified Date':i.Modified_date,
        'Modified By':i.Modified_by?.replace(/[0-9]|\(|\)/gm,''),
        'Created By':i.CreatedBy?.replace(/[0-9]|\(|\)/gm,''),
        'Created Date':i.CreatedDate
      }
      if (this.sourceApp === 'VitalDx') delete obj['Case Profile Id']
      return obj;
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(profilesExportData, {
      cellStyles: true,
    });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Case Profiles');
    XLSX.writeFile(wb, fileName);
  }

  onScroll = (e: any) => (this.scrollHistory = e.target.scrollTop);

  enableSequenceEditMode() {
    this.filterCondition.next({ status: 'Active', searchText: '' });
    this.sequenceEditMode = !this.sequenceEditMode;
    this.isSortDataChanged = false;
  }
  isFilterApplied = () =>
    this.filterCondition.value.searchText != '' ||
    !['all', 'active'].includes(this.filterCondition.value.status);
  returnToList() {
    if (this.isSortDataChanged) {
      let dialogRef = this.matdialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '360px',
        panelClass: 'admin-custom-popup',
        data: {
          header: '',
          message: 'All your changes will be lost. Do you want to proceed?',
          continue: 'Proceed',
          cancel: 'Cancel',
        },
      });
      return dialogRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            this.filterCondition.next({ status: 'all', searchText: '' });
            this.sequenceEditMode = !this.sequenceEditMode;
          }
        });

      // let ref = this._dialog.openDialog('Alert', `All your changes will be lost. Are you sure to continue?`, '', 'Continue', 'Cancel')
      // ref.afterClosed().subscribe((res) => {
      //   if(res){
      //     this.filterCondition.next({ status:'All', searchText: '' })
      //     this.sequenceEditMode = !this.sequenceEditMode
      //   }
      // })
    } else {
      this.filterCondition.next({ status: 'all', searchText: '' });
      this.sequenceEditMode = !this.sequenceEditMode;
    }
  }

  onFocusOut(
    actualValue: number,
    changedValue: HTMLInputElement,
    container: any
  ) {
    if (actualValue != +changedValue.value) {
      if (+changedValue.value > this.maxSequence) {
        this._dialog.openDialog(
          'Alert',
          `Sequence cannot be greater than maximum sequence(${this.maxSequence})`,
          '',
          'Ok',
          'Cancel'
        );
        changedValue.value = String(actualValue);
      } else {
        moveItemInArray(container, actualValue - 1, +changedValue.value - 1);
        this.checkSequenceChanged();
      }
    }
  }

  //calculates the sequence and updates accordingly.
  checkSequenceChanged() {
    // list.map((CL: any, i: number) => {
    //   if (CL.Sequence != '') CL.Sequence = i + 1
    // })
    //this.caseProfileSortData.currentData = [...list]
    this.isSortDataChanged =
      this.caseProfileSortData.currentData
        .map((i) => i.CaseProfileId)
        .join(',') !=
      this.caseProfileSortData.previousData
        .map((i) => i.CaseProfileId)
        .join(',');
  }

  saveSequence() {
    this.ngxService.start();
    let caseProfilesSequence = [];
    let jsondata= []
    const result: any = {};
    for (
      let index = 0;
      index < this.caseProfileSortData.currentData.length;
      index++
    ) {
      const currentElement = this.caseProfileSortData.currentData[index];
      const previousElement = this.caseProfileSortData.previousData[index];

      if (currentElement.CaseProfileId != previousElement.CaseProfileId) {
        jsondata.push({id : currentElement.CaseProfileId, 
          name: currentElement.Display_name,
          old : previousElement.CaseProfileSequence,
          new : currentElement.CaseProfileSequence}
        )
        caseProfilesSequence.push({
          id: currentElement.CaseProfileId,
          sequence: previousElement.CaseProfileSequence,
          to: qualityAttributeAndFlags.Swap_CaseProfiles,
        });
      }
    }
    // console.log(jsondata)
    // console.log(caseProfilesSequence);
    jsondata.forEach(item => {      
     
      let itemName = item.name;
      const oldValue = item.old;
      const newValue = item.new;  
      result[itemName] = result[itemName] || []; 
   
      result[itemName].push({         
      Sequence: 
        {
            oldValue: oldValue,
            newValue: newValue
        }
      });
    });
 
 // console.log(result)
    this.vitalservice
      .SwapSequence2(
        { UpdateSequences: caseProfilesSequence, SortItems: [] },
        this.dbName
      )
      .subscribe({
        next: (res) => {
          let sessionId = this.commonService.generateGuid(); 
          this.commonService.createActivityObject('',"Sequence", this.templateData.menuURL, 'Edit', '', '', sessionId,this.auditableColumns,'',result);   
          this._snackbar.open('Sequence updated successfully.', 'Close');
          this.sequenceEditMode = !this.sequenceEditMode;
          this.filterCondition.next({ status: 'all', searchText: '' });
          this.getCaseProfileData();
          this.ngxService.stop();
        },
        error: (err) => this.handleError(err),
      });
  }
  onPressEnter(input:HTMLElement)
  {
    input.blur();
  }
  handleError(err) {
    this.ngxService.stop();
    this._snackbar.open('Something went wrong. Please try again.', 'Close');
    console.error(err);
  }

  private getAuditableDetails(location: any) {
    this.vitalservice.getDisplayColumns({ TableName: location }).subscribe({
      next: (res) => {
        try {
          this.auditableColumns = JSON.parse(res.content.JsonData);
        } catch (error) {
          console.error(error);
        }
      },
      error: (err) => console.error(err),
    });
  }

  onVisibilityChange(): void {
    if (document.visibilityState === 'visible' && this.isTabActive) {
      this.ReloadData();
    }
  }

  ReloadData(): void {
      this.getCaseProfileData();
      this.isTabActive = false;
     // console.log('ActiveTab');
  }

  showMenuRelatedFlags() {
    let dialogRef = this.matdialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: 'Case Profile',
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }
}
