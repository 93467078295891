import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { CommonService } from '../services/commonservices';
import { VitalHttpServices } from '../services/VitalHttpServices';

@Injectable({
  providedIn: 'root'
})
export class TemplateViewService {
  workGrouproledata: any;
  globalMasterDataForQueries: any = {
    userId: '',
    userRole: '',
    CaseType: '',
    accountId: '',
    TemplateName: '',
    accountid: '',
    type: '',
    DeploymentKey: '',
    GroupName: '',
    filterParameter:''
  };

  templateView = [
    /*  Gross Template */
    {
      templateName: "Grossing Templates",
      idKey: 'OrgGrossTempID',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [

        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          listClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 ',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              responseType: '',
              dependentFieldsList: ['SiteName', 'Placeholders'],
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              isQueryParamsInGlobalMasterData: true
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'SiteName',
          columnName: 'SiteName',
          displayName: 'Site',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Site',
            defaultValue: '',
            width: '23%',
            source: {
              dependsOn: true,
              defaultOptions: [{ BodySite: 'All', dropdownDisplayColumn: 'All' }],
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['BodySite'],
              datasourceKeyword: 'bodysitesOnCasetype',
              datasourceDB: [],
              datasourceQueryVariable: { 'caseType': 'dependsOn', 'orgId': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'BodySite',
              toDisplayInIputField: ['BodySite'],
              hasAllOption: true
            }
          },

        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'DisplayOrder',
          displayName: 'Sequence',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          listClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            minLength: '',
            inputDataType: 'excludeZero',
          },
          properties: {
            placeholder: 'Sequence',
            width: '23%',
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            defaultValue: '',
            width: '13%'
          }
        },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'IsDefault',
          displayName: 'Default',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          dependentFields: ['DisplayOrder'],
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%'
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4 hidden',
          inputValidations:
          {
            required: true,
            maxLength: '4000',
            minLength: '',
            inputDataType: '',
          }
        },
        // PlaceHolder and description
        {
          manageClassName: 'col-sm-12',
          viewClassName: 'hidden',
          dataType: 'groupedTextArea',
          columnName: 'Placeholders',
          displayName: 'Placeholders',
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: 'CaseType',
              options: [],
              dataValueToDisplay: ['Name'],
              datasourceKeyword: 'CategoryDropDown',
              datasourceDB: ['configdb'],
              datasourceQueryVariable: { 'context': '', 'keyword': 'CaseType - dependsOn' },
              responseType: 'stringify',
              targetField: 'groupedDescription',
              copyTo: 'Description',
              pathToResposeData: ['data', 'submenuData', 0, 'Items_JSON', 'DataMarkers'],
              responseDataType: 'Stringfy',
            }
          },
          groupedField: {
            valueToBeFetchedBy: 'groupedDescription',
            columnName: 'groupedDescription',
            displayName: 'Description',
            viewClassName: 'col-sm-12',
            manageClassName: 'col-sm-12 mt-4',
            inputValidations:
            {
              required: true,
              maxLength: '4000',
              minLength: '',
            },
          }
        },
        {
          valueToBeFetchedBy: 'OrgGrossTempID',
          columnName: 'OrgGrossTempID',
          displayName: 'TemplateID',
          viewClassName: 'hidden',
          dataType: 'input',
          manageClassName: 'hidden',
          listClassName: 'hidden',
          inputValidations:
          {
            required: false,
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        },
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              responseType: '',
              pathToResposeData: ['content'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              isQueryParamsInGlobalMasterData: true
              // truncateLength: 22
            }
          },
        }
      ]
    },
    //  Case Comments
    {
      templateName: "Case Comments",
      idKey: 'Id',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'templateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },

        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CaseType',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              dependentFieldsList: ['SiteName', 'Placeholders'],
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 14
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },

        {
          valueToBeFetchedBy: 'SiteName',
          columnName: 'SiteName',
          displayName: 'Site',
          viewClassName: 'col-sm-2',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Site',
            defaultValue: '',
            width: '23%',
            source: {
              dependsOn: true,
              defaultOptions: [{ BodySite: 'All', dropdownDisplayColumn: 'All' }],
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['BodySite'],
              datasourceKeyword: 'bodysitesOnCasetype',
              datasourceDB: [],
              datasourceQueryVariable: { 'caseType': 'dependsOn', 'orgId': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'BodySite',
              toDisplayInIputField: ['BodySite'],
              hasAllOption: true
            }
          },
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'caseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'dropdown',
          useViewProperty: 'CasetypeDisplayName',
          manageClassName: 'col-sm-3 mb-2',
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'userid': 'userId', 'Rolename': 'userRole' },
              isQueryParamsInGlobalMasterData: true,
              dependentFieldsList: ['SiteName'],
            },
            visibility: 'user'
          },
        },
        {
          valueToBeFetchedBy: 'SiteName',
          columnName: 'SiteName',
          displayName: 'Site',
          viewClassName: 'col-sm-3  hidden',
          dataType: 'dropdown',
          manageClassName: 'col-sm-3',
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: 'CaseType',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['BodySite', 'SiteID'],
              datasourceKeyword: 'bodysitesOnCasetype',
              datasourceDB: [],
              datasourceQueryVariable: { 'caseType': 'dependsOn', 'orgId': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'BodySite',
              hasAllOption: true
            },
            visibility: 'user'
          },
        },
        {
          valueToBeFetchedBy: 'User',
          columnName: 'userId',
          displayName: '',
          value: [],
          viewClassName: 'col-sm-3  hidden',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-4 multiselectDropdown-type1',
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'DisplayRole'],
              datasourceKeyword: 'getuserscasecomments',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              separatedBy: '',
              isHardCodedQueryVariable: true,
              dropdownSettings: {
                singleSelection: false,
                idField: 'userid',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: false,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['dropdownDisplayColumn'],
              dataType: 'number',
              visibility: 'org'
            }
          },
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'Sequence',
          displayName: 'Sequence',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            inputDataType: 'excludeZero',
            maxLength: '5',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'isActive',
          displayName: 'Status',
          value: '',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            visibility: 'both',
            width: '13%'
          }
        },
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
            }
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'Group Case Comments', itemName: 'Group ( Group Level )' },
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [
                { username: 'Group Case Comments', dropdownDisplayColumn: 'Group ( Group Level )', rolename: 'Group Level' },
                { username: 'All Users', dropdownDisplayColumn: 'All Users', rolename: 'Group Level' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'DisplayRole'],
              datasourceKeyword: 'getuserscasecomments',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              separatedBy: '',
              isHardCodedQueryVariable: true,
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['dropdownDisplayColumn'],
            }
          },
        }

      ]
    },
    // Adequacy code
    {
      templateName: "Adequacy Codes",
      idKey: 'Id',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'Name',
          columnName: 'Name',
          displayName: 'Adequacy Code',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Code Name',
            defaultValue: '',
            width: '',
            replicateFields: ['DisplayName'],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              isQueryParamsInGlobalMasterData: true,
              // truncateLength: 14
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },

        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-2 mb-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CaseType',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'userid': 'userId', 'Rolename': 'userRole' },
              isQueryParamsInGlobalMasterData: true,
              // dependentFieldsList: ['SiteName'],
            },
            visibility: 'user'
          },
        },


        {
          valueToBeFetchedBy: 'DisplayName',
          columnName: 'DisplayName',
          displayName: 'Display Name',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Display Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-4',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'DisplayRole'],
              datasourceKeyword: 'getAdequacyCodesUsers',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['username'],
              isHardCodedQueryVariable: true
            }
          },
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'sequenceorder',
          displayName: 'Sequence',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            inputDataType: 'excludeZero',
            maxLength: '5',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
            visibility: 'user'
          }
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'sequenceorder',
          displayName: 'Sequence',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            inputDataType: 'excludeZero',
            maxLength: '5',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'org'
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          value: '',
          viewClassName: 'col-sm-4',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            visibility: 'both',
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Abnormal',
          columnName: 'Abnormal',
          displayName: 'Abnormal',
          value: '',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            visibility: 'both',
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            visibility: 'both'
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              isQueryParamsInGlobalMasterData: true,
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              isDistinctCheck: 'CasetypeDisplayName',
              separatedBy: '',
              // truncateLength: 18
            }
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'Group', itemName: 'Group ( Group Level )' },
          inputValidations: {
            required: false,
          },
          value: 'Group',
          properties: {
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [{ username: 'Group', dropdownDisplayColumn: 'Group ( Group Level )', rolename: 'Group Level' }],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'DisplayRole'],
              datasourceKeyword: 'getAdequacyCodesUsers',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['username'],
              isHardCodedQueryVariable: true
              // truncateLength:18
            }
          },
        },
      ]
    },
    //Revision reasons
    {
      templateName: "Revision Templates",
      idKey: 'Id',
      hasStatusColumn: false,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'TemplateType',
          columnName: 'TemplateType',
          displayName: 'Template Type',
          dataType: 'staticDropdown',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [
                { type: 'AddendumReason', displayName: 'Addendum Reasons' },
                { type: 'AmendmentReason', displayName: 'Amendment Reasons' },
                { type: 'CorrectionReason', displayName: 'Correction Reasons' }
              ],
              dataValueToDisplay: ['displayName'],
              dataPropertiesToDisplay: [],
              keyToBeSentToBackend: 'type',
            }
          },
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          }
        }
      ],
      listFilterDropdownFields: [
        {
          columnName: 'type',
          displayName: 'Revison Reason',
          dataType: 'staticDropdown',
          manageClassName: 'col-sm-10 mr-2',
          value: 'Addendum Reasons',
          properties: {
            placeholder: 'Select Reason',
            defaultValue: '',
            width: '23%',
            source: {
              dependsOn: '',
              options: [{ type: 'Addendum Reasons' }, { type: 'Amendment Reasons' }, { type: 'Correction Reasons' }],
              dataValueToDisplay: ['type'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: '',
              datasourceDB: [],
              datasourceQueryVariable: '',
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'type',
            }
          },
        }
      ]
    },
    //Revision Notes
    {
      templateName: "Revision Notes",
      hasStatusColumn: false,
      idKey: 'Id',
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-4',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          value: [],
          viewClassName: 'col-sm-3',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              responseType: '',
              pathToResposeData: ['content'],
              datasourceDB: [],
              isSingleValueToDisplay: '',
              separatedBy: '|',
              isQueryParamsInGlobalMasterData: true,
              dropdownSettings: {
                singleSelection: false,
                idField: 'CaseType',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 150,
                itemsShowLimit: 44,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['dropdownDisplayColumn'],
            }
          },
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              isDistinctCheck: 'CasetypeDisplayName',
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              responseType: '',
              pathToResposeData: ['content'],
              separatedBy: '',
              isQueryParamsInGlobalMasterData: true,
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 22
            }
          },
        }
      ]
    },
    //Screening Comments
    {
      templateName: "Screening Comments",
      idKey: 'Id',
      hasStatusColumn: false,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '510',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 ',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              isQueryParamsInGlobalMasterData: true,
              // truncateLength: 14
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },

        // {
        //   valueToBeFetchedBy: 'IsActive',
        //   columnName: 'IsActive',
        //   displayName: 'Status',
        //   viewClassName: 'col-sm-2',
        //   dataType: 'badge',
        //   manageClassName: 'col-sm-2',
        //   inputValidations:
        //   {
        //     required: false,
        //   },
        //   properties: {
        //     width: '13%',
        //   }
        // },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'IsDefault',
          displayName: 'Default',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          dependentFields: ['Sequence'],
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',

          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 mr-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              isQueryParamsInGlobalMasterData: true,
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              isDistinctCheck: 'CasetypeDisplayName',
              separatedBy: '',
              // truncateLength: 18
            }
          },
        },
      ]
    },
    //Rejection Templates
    {
      templateName: "Rejection Templates",
      idKey: 'AttributeId',
      hasStatusColumn: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'AttributeName',
          columnName: 'AttributeName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'SequenceOrder',
          displayName: 'Sequence',
          value: '',
          viewClassName: 'col-sm-12 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            minLength: '',
            inputDataType: 'excludeZero',
          },
          properties: {
            width: '23%',
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'AttributeDescription',
          columnName: 'AttributeDescription',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          }
        }
      ]
    },
    //Deletion Templates
    {
      templateName: "Deletion Reasons",
      hasStatusColumn: false,
      idKey: 'OrgTemplateID',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        }
      ]
    },
    //Manage categories
    {
      templateName: "Attachment Category",
      idKey: 'CategoryID',
      hasStatusColumn: false,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'CategoryType',
          columnName: 'CategoryType',
          displayName: 'Category Type',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          //dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Category',
            defaultValue: '',
            width: '',
            //isDisableInEdit: true,
            source: {
              options: [],
              dataValueToDisplay: ['name'],
              dataPropertiesToDisplay: ['name'],
              datasourceKeyword: 'CategoryDropDown',
              datasourceDB: ['configdb'],
              datasourceQueryVariable: { 'context': 'Categories', 'keyword': 'Categories' },
              responseType: 'stringify',
              pathToResposeData: ['data', 'submenuData', 0, 'Items_JSON', 'Categories'],
              keyToBeSentToBackend: 'value',
              toDisplayInIputField: ['name'],
              bypassDynamicQueryVariableSetup: true,
              responseDataType: 'Stringfy',
              considerKeyValues: true
              // truncateLength:18
            }
          },
        },

        {
          valueToBeFetchedBy: 'CategoryName',
          columnName: 'CategoryName',
          displayName: 'Category Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '250',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'SequenceOrder',
          columnName: 'Sequence',
          displayName: 'Sequence',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            inputDataType: 'excludeZero',
          },
          properties: {
            placeholder: '',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        }
        // {
        //   valueToBeFetchedBy: 'IsActive',
        //   columnName: 'IsActive',
        //   displayName: 'Status',
        //   viewClassName: 'col-sm-2',
        //   dataType: 'badge',
        //   manageClassName: 'col-sm-2',
        //   inputValidations:
        //   {
        //     required: false,
        //   },
        //   properties: {
        //     width: '13%',
        //   }
        // }
      ]
    },
    //Diagnosis References
    {
      templateName: "Diagnosis References",
      idKey: 'ID',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CaseType',
              datasourceDB: [],
              responseType: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              pathToResposeData: ['content'],
              isQueryParamsInGlobalMasterData: true,
              // truncateLength: 28
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },

        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 mr-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              responseType: '',
              pathToResposeData: ['content'],
              isQueryParamsInGlobalMasterData: true,
              // truncateLength: 22
            }
          },
        }
      ]
    },
    //CustomAbbreviations
    {
      templateName: "Custom Abbreviations",
      hasStatusColumn: true,
      idKey: 'Id',
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'Abbreviation',
          columnName: 'Abbreviation',
          displayName: 'Abbreviation',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '500',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'caseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              defaultOptions: [{ CaseType: 'All', dropdownDisplayColumn: 'All', CasetypeDisplayName: 'All' }],
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'accountid': 'accountId' },
              isQueryParamsInGlobalMasterData: true,
              dependentFieldsList: ['SiteName'],
              // truncateLength: 28
              hasAllOption: true
            },
            // isDisabledInAdmin: true,
            // patchPredefinedValue: true,
          },
        },
        // {
        //   valueToBeFetchedBy: 'caseType',
        //   columnName: 'casetype',
        //   displayName: 'Case Type',
        //   viewClassName: 'col-sm-3 hidden',
        //   dataType: 'autocomplete',
        //   manageClassName: 'col-sm-3 mb-2',
        //   dummyValue: { itemId: '', itemName: '' },
        //   inputValidations: {
        //     required: true,
        //   },
        //   properties: {
        //     placeholder: 'Case Type',
        //     defaultValue: '',
        //     width: '',
        //     source: {
        //       dependsOn: '',
        //       options: [],
        //       dataValueToDisplay: ['dropdownDisplayColumn'],
        //       dataPropertiesToDisplay: ['CaseType'],
        //       datasourceKeyword: 'CaseTypeforAddendumNotes',
        //       isDistinctCheck: 'CaseType',
        //       datasourceDB: [],
        //       datasourceQueryVariable: { 'id': '' },
        //       responseType: '',
        //       pathToResposeData: ['data', 'submenuData'],
        //       keyToBeSentToBackend: 'CaseType',
        //       toDisplayInIputField: ['CaseType'],
        //     },
        //     visibility: 'user'
        //   },
        // },

        {
          valueToBeFetchedBy: 'caseType',
          columnName: 'casetype',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3 mb-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              defaultOptions: [{ CaseType: 'All', dropdownDisplayColumn: 'All', CasetypeDisplayName: 'All' }],
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'userid': 'userId', 'Rolename': 'userRole' },
              isQueryParamsInGlobalMasterData: true,
              dependentFieldsList: ['SiteName'],
            },
            visibility: 'user'
          },
        },

        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'DisplayRole'],
              datasourceKeyword: 'GetCustomAbbrUsers',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['username'],
              // truncateLength: 28
            }
          },
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'Sequence',
          displayName: 'Sequence',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            maxLength: '5',
            required: false,
            inputDataType: 'excludeZero',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-4',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            visibility: 'both',
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Phrase',
          columnName: 'Phrase',
          displayName: 'Phrase',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'richTextArea',
          manageClassName: 'col-sm-12',
          inputValidations:
          {
            required: true,
            maxLength: '',
            minLength: '',
            inputDataType: '',

          },
          properties: {
            visibility: 'both'
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4 ',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8',
          dummyValue: { itemId: 'All', itemName: 'All' },
          inputValidations: {
            required: false,
          },
          value: 'All',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            // hideInAdmin: true,
            source: {
              defaultOptions: [{ CaseType: 'All', dropdownDisplayColumn: 'All', CasetypeDisplayName: 'All' }],
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'accountid': 'accountId' },
              isQueryParamsInGlobalMasterData: true,
              dependentFieldsList: ['SiteName'],
              // truncateLength: 18
            },
            visibility: 'org'
          },
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4 ',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8',
          dummyValue: { itemId: 'All', itemName: 'All' },
          inputValidations: {
            required: false,
          },
          value: 'All',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            // hideInAdmin: true,
            source: {
              defaultOptions: [{ CaseType: 'All', dropdownDisplayColumn: 'All', CasetypeDisplayName: 'All' }],
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'userid': 'userId', 'Rolename': 'userRole' },
              isQueryParamsInGlobalMasterData: true,
              dependentFieldsList: ['SiteName'],
              // truncateLength: 18
            },
            visibility: 'user'
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'Group', itemName: 'Group ( Group Level )' },
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [{ username: 'Group', dropdownDisplayColumn: 'Group ( Group Level )', rolename: 'Group Level' }],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'DisplayRole'],
              datasourceKeyword: 'GetCustomAbbrUsers',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['username'],
              //  truncateLength: 18
            }
          },
        },
      ]
    },
    //Microscopic Notes
    {
      templateName: "Microscopic Codes",
      hasStatusColumn: false,
      idKey: 'PreferenceId',
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'Name',
          columnName: 'preferencename',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 ',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 14
            },
            visibility: 'org',
            showInLabAdmin: true,
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'userid',
          displayName: '',
          value: [],
          viewClassName: 'col-sm-3 hidden',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-4 multiselectDropdown-type1',
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['UserName', 'DisplayRole'],
              datasourceKeyword: 'microCodesUSerList',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              separatedBy: '',
              isHardCodedQueryVariable: true,
              dropdownSettings: {
                singleSelection: false,
                idField: 'UserId',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: false,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'UserId',
              toDisplayInIputField: ['dropdownDisplayColumn'],
              dataType: 'number',
            },
            visibility: 'org'
          },
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'casetype',
          displayName: 'Case Type',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3 mb-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Case Type',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],

              datasourceDB: [],
              responseType: '',
              pathToResposeData: [],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/templates/GetCasetypes',
              urlQueryParameters: { 'userid': 'userId', 'Rolename': 'userRole' },
              isQueryParamsInGlobalMasterData: true,
            },
            visibility: 'user'
          },
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'preferencevalue',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            visibility: 'both'
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 18
            }
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8',
          dummyValue: { itemId: 'Group', itemName: 'Group ( Group Level )' },
          inputValidations: {
            required: false,
          },
          value: 'Group',
          properties: {
            isP4: false,
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [
                { UserName: 'Group', dropdownDisplayColumn: 'Group ( Group Level )', RoleName: 'Group Level' },
                { UserName: 'All', dropdownDisplayColumn: 'All Users', RoleName: 'Group Level' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['UserName', 'DisplayRole'],
              datasourceKeyword: 'microCodesUSerList',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              separatedBy: '',
              isHardCodedQueryVariable: true,
              keyToBeSentToBackend: 'UserName',
              // truncateLength:18
            }
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8',
          dummyValue: { itemId: 'All', itemName: 'All Users' },
          inputValidations: {
            required: false,
          },
          value: 'Group',
          properties: {
            isP4: true,
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [
                { UserName: 'All', dropdownDisplayColumn: 'All Users', RoleName: 'Group Level' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['UserName', 'DisplayRole'],
              datasourceKeyword: 'microCodesUSerList',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              separatedBy: '',
              isHardCodedQueryVariable: true,
              keyToBeSentToBackend: 'UserName',
              // truncateLength:18
            }
          },
        },
      ]
    },
    //Sequester Reasons
    {
      templateName: "Sequester Reasons",
      hasStatusColumn: false,
      idKey: 'OrgTemplateID',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '100',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        }
      ]
    },
    //UserDefault Roles
    {
      idKey: 'idKey',
      templateName: "Default Role",
      configurableTemplateFields: [
        {
          label: 'UserEmail',
          displayName: "Email Address",
          type: 'div',
          value: '',
          viewClassName: 'col-sm-4'
        },
        {
          label: 'RoleName',
          displayName: "Role Name",
          type: 'div',
          value: '',
          viewClassName: 'col-sm-3'
        },
        {
          label: 'DeploymentKey',
          displayName: "Deployment",
          type: 'div',
          value: '',
          viewClassName: 'col-sm-2'
        },
        {
          label: 'Entity',
          displayName: "Entity",
          type: 'div',
          value: '',
          viewClassName: 'col-sm-2'
        },
        {
          label: 'IsActive',
          displayName: "Status",
          type: 'div',
          value: '',
          viewClassName: 'col-sm-1'
        }

      ]
    },
    //corelation templates
    {
      templateName: "Correlation Templates",
      idKey: 'AttributeID',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'AttributeValue',
          columnName: 'AttributeValue',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Attribute Value',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'getCaseTypesForCategory',
              datasourceDB: [],
              datasourceQueryVariable: { "filterParam": "Category = 'Hemonc'", "orgid": "" },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 28
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'AttributeDescription',
          columnName: 'AttributeDescription',
          displayName: 'Summary',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'getCaseTypesForCategory',
              datasourceDB: [],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceQueryVariable: { "filterParam": "Category = 'Hemonc'", "orgid": "" },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 22
            }
          },
        }
      ]
    },
    //tubetype templates
    {
      templateName: "Tubetype Templates",
      idKey: 'AttributeID',
      hasStatusColumn: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'AttributeValue',
          columnName: 'AttributeValue',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Attribute Value',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'dropdown',
          manageClassName: 'col-sm-4',
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CaseType',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              dependentFieldsList: [''],
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
            },
            visibility: 'org',
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
            isHideInLabAdmin: true
          },
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'AttributeDescription',
          columnName: 'AttributeDescription',
          displayName: 'Summary',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '100',
            minLength: '',
            inputDataType: '',
          }
        }
      ]
    },
    //nomenclature templates
    {
      templateName: "Nomenclature Templates",
      idKey: 'Id',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '510',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-3',
          useViewProperty: 'CasetypeDisplayName',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'getCaseTypesForCategory',
              datasourceDB: [],
              datasourceQueryVariable: { "filterParam": "Category = 'Hemonc' or CaseType = 'FISH'", "orgid": "" },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 28
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' }],
              dataValueToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'getCaseTypesForCategory',
              datasourceDB: [],
              datasourceQueryVariable: { "filterParam": "Category = 'Hemonc' or CaseType = 'FISH'", "orgid": "" },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 22
            }
          },
        }
      ]
    },
    //Sendout reasons
    {
      templateName: "Sendout Reasons",
      idKey: 'Id',
      hasStatusColumn: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
          }
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'Sequence',
          displayName: 'Sequence',
          value: '',
          viewClassName: 'col-sm-12 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            minLength: '',
            inputDataType: 'excludeZero',
          },
          properties: {
            width: '23%',
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          }
        }
      ]
    },
    // Workgroup
    {
      templateName: "Workgroups",
      hasStatusColumn: true,
      idKey: 'departmentId',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'departmentName',
          columnName: 'departmentName',
          displayName: 'Department Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Department Name',
            defaultValue: '',
            width: '',
          }
        },

        {
          valueToBeFetchedBy: 'role',
          columnName: 'roleName',
          displayName: 'Role',
          hideLabel: true,
          value: [],
          viewClassName: 'col-sm-3',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-3 marg-top',
          hasLable: 'Role',
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: '',
            defaultValue: '',
            width: '',
            // isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['Role'],
              datasourceKeyword: 'organizationcustomworkrolelist',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              dependentFieldsList: ['tabName', 'displayName'],
              separatedBy: '',
              dropdownSettings: {
                singleSelection: false,
                idField: 'Role',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'Role',
              toDisplayInIputField: ['role'],
            },
            visibility: 'org'
          },
        },

        {
          valueToBeFetchedBy: 'tabDisplayName',
          columnName: 'tabName',
          displayName: 'Tab Name',
          hideLabel: true,
          value: [],
          viewClassName: 'col-sm-3',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-3 marg-top',
          hasLable: 'Tab Name',
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: '',
            defaultValue: '',
            width: '',
            // isDisableInEdit: true,
            source: {
              dependsOn: true,
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['TabDisplayName'],
              datasourceKeyword: 'organizationcustomworkTabNamelist',
              datasourceDB: [],
              datasourceQueryVariable: { 'role': 'dependsOn', 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              dependentFieldsList: ['displayName'],
              separatedBy: '',
              isDistinctCheck: 'TabDisplayName',
              dropdownSettings: {
                singleSelection: false,
                idField: 'TabDisplayName',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'TabDisplayName',
              toDisplayInIputField: ['tabDisplayName'],
            },
            visibility: 'org'
          },
        },

        {
          valueToBeFetchedBy: 'customWorklist',
          columnName: 'displayName',
          displayName: 'Worklist',
          hideLabel: true,
          value: [],
          viewClassName: 'col-sm-3',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-3 marg-top',
          hasLable: 'Worklist',
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: '',
            defaultValue: '',
            width: '',
            // isDisableInEdit: true,
            source: {
              dependsOn: true,
              isMultiDependent: true,
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['DisplayName'],
              datasourceKeyword: 'organizationcustomworkDisplayNamelist',
              datasourceDB: [],
              datasourceQueryVariable: { 'role': 'dependsOn', 'tabdisplayname': 'dependsOn', 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              separatedBy: '',
              dropdownSettings: {
                singleSelection: false,
                idField: 'DisplayName',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'DisplayName',
              toDisplayInIputField: ['customWorklist'],
            },
            visibility: 'org'
          },
        },

        {
          valueToBeFetchedBy: 'isActive',
          columnName: 'isActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
      ]
    },
    //Payers
    {
      templateName: "Payers",
      hasStatusColumn: true,
      idKey: 'departmentId',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'PayerName',
          displayName: 'Payer Name',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'ElectronicPayerID',
          displayName: 'ElectronicPayerID',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'Address1',
          viewClassName: 'col-sm-3',
          displayName: 'Address1'
        },
        {
          valueToBeFetchedBy: 'Address2',
          displayName: 'Address2',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'City',
          viewClassName: 'col-sm-3',
          displayName: 'City'
        },
        {
          valueToBeFetchedBy: 'State',
          displayName: 'State & Zipcode',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'Phone',
          displayName: 'Phone',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'Fax',
          displayName: 'Fax',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'Email',
          displayName: 'Email',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'BillingType',
          displayName: 'Billing Type',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'PayerFamily',
          displayName: 'Payer Family',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'ParticipatingInfo',
          displayName: 'Participating info',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'IsElectronicPayer',
          displayName: 'IsElectronicPayer',
          viewClassName: 'col-sm-3'
        },
        {
          valueToBeFetchedBy: 'Status',
          displayName: 'Status',
          viewClassName: 'col-sm-3'
        }
      ]
    },
    //L2l mapping
    {
      templateName: "L2L Mapping",
      hasStatusColumn: false,
      idKey: 'departmentId',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'IsExternalDb',
          columnName: 'isexternal',
          displayName: 'External DB',
          viewClassName: 'col-sm-4',
          dataType: 'badge',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '15%',
          }
        },
        {
          valueToBeFetchedBy: 'IsSendoutLab',
          columnName: 'issendoutlab',
          displayName: 'Send Out Lab',
          viewClassName: 'col-sm-4',
          dataType: 'badge',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '15%',
          }
        },
        {
          valueToBeFetchedBy: 'accountid',
          columnName: 'accountid',
          displayName: 'Source Lab',
          viewClassName: 'col-sm-4 ',
          useViewProperty: 'Sourcelab',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Source Lab',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['displayname'],
              dataPropertiesToDisplay: ['displayname'],
              isDistinctCheck: '',
              datasourceDB: [],
              datasourceKeyword: 'getorglabaccounts',
              datasourceQueryVariable: { 'orgId': '', associationtype: 'Laboratories', 'status': 'active' },
              responseType: '',
              dependentFieldsList: ['AssociatedAccount'],
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'accountid',
              toDisplayInIputField: ['displayname'],
              isQueryParamsInGlobalMasterData: true,
              hasAllOption: false
            },
            // isDisabledInAdmin: false,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'AssociatedAccount',
          columnName: 'AssociatedAccount',
          displayName: 'Destination Lab',
          useViewProperty: 'Destinationlab',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Destination Lab',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: true,
              options: [],
              dataValueToDisplay: ['displayname'],
              dataPropertiesToDisplay: ['displayname'],
              datasourceKeyword: 'getAssociatedSendout',
              datasourceDB: [],
              datasourceQueryVariable: { 'accountid': 'dependsOn', 'status': 'active' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'AssociatedAccount',
              toDisplayInIputField: ['displayname'],
              hasAllOption: false
            }
          },
        },
        {
          valueToBeFetchedBy: 'MappingServer',
          columnName: 'mappingserver',
          displayName: 'Mapping Server',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'MappingDbName',
          columnName: 'mappingdb',
          displayName: 'Mapping DB',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          inputValidations:
          {
            required: false,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'LabBUrl',
          columnName: 'LabBurl',
          displayName: 'Destination Lab URL',
          viewClassName: 'col-sm-4',
          manageClassName: 'col-sm-3 mt-4',
          dataType: 'input',
          inputValidations:
          {
            required: false,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'L2LConfigId',
          columnName: 'l2lconfigid',
          displayName: 'L2LConfigId',
          viewClassName: 'col-sm-4 hidden'

        },
        {
          valueToBeFetchedBy: 'LabAguid',
          columnName: 'LabAguid',
          displayName: 'Source GUID',
          viewClassName: 'col-sm-4'

        },
        {
          valueToBeFetchedBy: 'LabBguid',
          columnName: 'LabBguid',
          displayName: 'Destination GUID',
          viewClassName: 'col-sm-4'

        }
      ]
    },
    //Report Signatories
    {
      templateName: "Report Signatories",
      hasStatusColumn: true,
      idKey: 'Id',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'EntityType',
          columnName: 'EntityType',
          displayName: 'Entity Type',
          dataType: 'staticDropdown',
          viewClassName: 'col-sm-2',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: true,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [
                { type: 'Locations', displayName: 'Locations', keyword: '' },
                { type: 'Case Type', displayName: 'Case Type', keyword: '' },
                { type: 'Test Category', displayName: 'Test Category', keyword: '' }
              ],
              dataValueToDisplay: ['displayName'],
              dataPropertiesToDisplay: ['type'],
              dependentFieldsList: ['EntityName'],
              keyToBeSentToBackend: 'type',
            }
          },
        },
        {
          valueToBeFetchedBy: 'EntityNameId',
          viewValueToBeFetched: 'EntityName',
          columnName: 'EntityName',
          displayName: 'Entity Name',
          viewClassName: 'col-sm-3',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-4',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations:
          {
            required: true,
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            source: {
              dependsOn: true,
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['name'],
              datasourceKeyword: 'getreportsignatoriesdropdown',
              datasourceDB: [],
              datasourceQueryVariable: { 'type': 'dependsOn', 'orgid': '', 'accountId': '' },
              responseType: '',
              dependentFieldsList: [''],
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'entityname',
              toDisplayInIputField: ['name'],
              hasAllOption: true
            },
          },
        },
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: '',
          value: [],
          viewClassName: 'col-sm-6 hidden',
          manageClassName: 'col-sm-6  multiselectDropdown-type1',
          dataType: 'multiselectDropdown',
          inputValidations: {
            required: true,
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            // isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'rolename'],
              datasourceKeyword: 'getallusersunderorganization',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '', 'accountId': '', 'filterName': '' },
              // hasAccountId:true,
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              isSingleValueToDisplay: '',
              separatedBy: '',
              isHardCodedQueryVariable: false,
              dropdownSettings: {
                singleSelection: false,
                idField: 'userid',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'userid',
              toDisplayInIputField: ['username', 'rolename'],
              dataType: 'number',
            },
          },
        },
        {
          valueToBeFetchedBy: 'UserName',
          columnName: '',
          displayName: 'User',
          value: [],
          viewClassName: 'col-sm-6',
          manageClassName: 'hidden',
          dataType: '',
          inputValidations: {
            required: false,
          },
          properties: {
            isDisableInEdit: true,
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['username', 'rolename'],
              datasourceKeyword: 'getallusersunderorganization',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '', 'accountId': '', 'filterName': '' },
              responseType: '',
              pathToResposeData: ['data', 'Card'],
              isSingleValueToDisplay: '',
              separatedBy: '',
              isHardCodedQueryVariable: false,
              dropdownSettings: {
                singleSelection: false,
                idField: 'userid',
                textField: 'dropdownDisplayColumn',
              },
              toDisplayInIputField: ['username', 'rolename'],
            },
          },
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        }
      ]
    },
    //Extraction Procedures
    {
      templateName: "Extraction Procedures",
      idKey: 'ExtnProcedureId',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'ExtnProcedureName',
          columnName: 'ExtnProcedureName',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '510',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            isDisableInEdit: true,
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 14
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'Sequence',
          displayName: 'Sequence',
          value: '',
          viewClassName: 'col-sm-12 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            inputDataType: 'excludeZero',
          },
          properties: {
            width: '23%',
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'isdefault',
          displayName: 'Default',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          dependentFields: ['Sequence'],
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        }

      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 mr-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 22
            }
          },
        },
      ]
    },
    //Collection Methods
    {
      templateName: "Collection Methods",
      idKey: 'Id',
      hasStatusColumn: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'ExtnProcedureName',
          columnName: 'ExtnProcedureName',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '510',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            isDisableInEdit: true,
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 14
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'Sequence',
          displayName: 'Sequence',
          value: '',
          viewClassName: 'col-sm-12 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            inputDataType: 'excludeZero',
          },
          properties: {
            width: '23%',
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'isdefault',
          displayName: 'Default',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          dependentFields: ['Sequence'],
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        }

      ],
    },
    //Manage Medications
    {
      templateName: "Medications",
      hasStatusColumn: true,
      idKey: 'MedicationId',
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'MedicationCode',
          columnName: 'MedicationCode',
          displayName: 'Medication Code',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-4',
          inputValidations:
          {
            required: true,
            maxLength: '100',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Medication Code',
            defaultValue: '',
            width: '',
            replicateFields: ['MedicationBrand', 'MedicationDescription'],
          }
        },
        {
          valueToBeFetchedBy: 'MedicationBrand',
          columnName: 'MedicationBrand',
          displayName: 'Brand Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-4',
          inputValidations:
          {
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Enter Brand Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            defaultValue: '',
            width: '13%'
          }
        },
        {
          valueToBeFetchedBy: 'MedicationDescription',
          columnName: 'MedicationDescription',
          displayName: 'Medication Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          value: ['CaseType'],
          viewClassName: 'col-sm-3',
          dataType: 'multiselectDropdown',
          manageClassName: 'col-sm-12 mt-3',
          inputValidations: {
            required: true
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            isDisableInEdit: true,
            isDisabledInAdmin: true,
            width: '',
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CaseTypeDisplayName'],
              datasourceKeyword: 'getcasetypemedicationlist',
              datasourceDB: [],
              datasourceQueryVariable: { 'organizationId': '', 'accountId': '', 'caseType': null },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              separatedBy: '~',
              dropdownSettings: {
                singleSelection: false,
                idField: 'CaseType',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 150,
                itemsShowLimit: 44,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
                noFilteredDataAvailablePlaceholderText: 'No Results'
              },
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['dropdownDisplayColumn'],
            },
            patchPredefinedValue: true
          },
        }
      ],
      listFilterDropdownFields: [

        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 ml-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [
                { CaseType: 'All Case Types', CaseTypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CaseTypeDisplayName'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'getcasetypemedicationlist',
              datasourceDB: [],
              datasourceQueryVariable: { 'organizationId': '', 'accountId': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CaseType'],
              // truncateLength: 22
            }
          }
        }
      ]
    },
    //Observation & Quantitation Templates
    {
      templateName: "Observation & Quantitation Templates",
      hasStatusColumn: false,
      idKey: 'templateId',
      isHideTopSectionInCreate: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'templateName',
          columnName: 'templateName',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'templateDescription',
          columnName: 'templateDescription',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'TemplateSequence',
          columnName: 'TemplateSequence',
          displayName: 'Sequence',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          listClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            minLength: '',
            inputDataType: 'excludeZero',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        },
      ]
    },
    //Clinical Information
    {
      templateName: "Clinical Information",
      hasStatusColumn: false,
      idKey: 'Attribute_Name',
      isHideTopSectionInCreate: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'Attribute_Name',
          columnName: 'Attribute_Name',
          displayName: 'Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '50',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'Attribute_Description',
          columnName: 'Attribute_Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',
          }
        },
        {
          valueToBeFetchedBy: 'Sequence_Order',
          columnName: 'Sequence_Order',
          displayName: 'Sequence',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          listClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            minLength: '',
            inputDataType: 'excludeZero',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        },
      ]
    },
    {
      templateName: "Sites",
      hasStatusColumn: false,
      idKey: 'id',
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 mr-2',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              // truncateLength: 28
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        }
      ]
    },
    //Cassette Templates
    {
      templateName: "Cassette Templates",
      idKey: 'Id',
      hasStatusColumn: true,
      mandatoryFields: ['CaseType'],
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'TemplateName',
          columnName: 'TemplateName',
          displayName: 'Template Name',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '510',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Template Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 ',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              isQueryParamsInGlobalMasterData: true,
              // truncateLength: 14
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'Sequence',
          columnName: 'Sequence',
          displayName: 'Sequence',
          value: '',
          viewClassName: 'col-sm-12 hidden',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            inputDataType: 'excludeZero',
          },
          properties: {
            width: '23%',
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'IsDefault',
          displayName: 'Default',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4 hidden',
          inputValidations:
          {
            required: true,
            maxLength: '1000',
            minLength: '',
            inputDataType: '',

          }
        },
        // PlaceHolder and description
        {
          manageClassName: 'col-sm-12',
          viewClassName: 'hidden',
          dataType: 'groupedTextArea',
          columnName: 'Placeholders',
          displayName: 'Placeholders',
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['Name'],
              datasourceKeyword: 'CategoryDropDown',
              datasourceDB: ['configdb'],
              datasourceQueryVariable: { 'context': 'Placeholders', 'keyword': 'CassetteTemplates' },
              responseType: 'stringify',
              targetField: 'groupedDescription',
              copyTo: 'Description',
              pathToResposeData: ['data', 'submenuData', 0, 'Items_JSON', 'PlaceHolder'],
              responseDataType: 'Stringfy',
              bypassDynamicQueryVariableSetup: true
            }
          },
          groupedField: {
            valueToBeFetchedBy: 'groupedDescription',
            columnName: 'groupedDescription',
            displayName: 'Description',
            viewClassName: 'col-sm-12',
            manageClassName: 'col-sm-12 mt-4',
            inputValidations:
            {
              required: true,
              maxLength: '1000',
              minLength: '',
            },
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-10 mr-2',
          dummyValue: { itemId: 'All Case Types', itemName: 'All Case Types' },
          inputValidations: {
            required: false,
          },
          value: 'All Case Types',
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              dependsOn: '',
              datasourceDB: [],
              responseType: '',
              pathToResposeData: ['content'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName'],
              urlEndpointForAPI: 'api/GetTemplateCastypes',
              urlQueryParameters: { 'AccountId': 'accountIds', 'TemplateName': 'TemplateName' },
              isQueryParamsInGlobalMasterData: true,
              options: [
                { CaseType: 'All Case Types', CasetypeDisplayName: 'All Case Types' },
              ],
              dataValueToDisplay: ['CasetypeDisplayName'],
              dataPropertiesToDisplay: [],
              isDistinctCheck: 'CasetypeDisplayName',
              separatedBy: '',
              // truncateLength: 22
            }
          },
        },
      ]
    },
    //Worklists
    {
      templateName: "Worklists",
      idKey: 'Id',
      hasStatusColumn: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'Role',
          columnName: 'Role',
          displayName: 'Role',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'Role',
            defaultValue: '',
            width: '',
          }
        },
        {
          valueToBeFetchedBy: 'TabDisplayName',
          columnName: 'TabName',
          displayName: 'TabName',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'TabName',
            defaultValue: '',
            width: '',
          }
        },
        {
          valueToBeFetchedBy: 'DisplayName',
          columnName: 'Worklist',
          displayName: 'Worklist',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-4',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'Worklist',
            defaultValue: '',
            width: '',
          }
        },

        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-4',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '',
          }
        },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'IsDefault',
          displayName: 'Default',
          viewClassName: 'col-sm-4',
          dataType: 'badge',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            isDisableInEdit: false,
            placeholder: '',
            defaultValue: '',
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'Description',
          columnName: 'Description',
          displayName: 'Description',
          value: '',
          viewClassName: 'col-sm-12',
          dataType: 'textarea',
          manageClassName: 'col-sm-12 mt-4',
          inputValidations:
          {
            required: false,
            maxLength: '5000',
            minLength: '',
            inputDataType: '',
          }
        }
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'Role',
          columnName: 'Roles',
          displayName: 'Roles',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Roles', itemName: 'All Roles' },
          inputValidations: {
            required: false,
          },
          value: 'Role',
          properties: {
            placeholder: 'Select Roles',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            isPipeEnabled: true,
            hideInAdmin: false,
            source: {
              dependsOn: '',
              options: [
                { Role: 'All Roles' },
              ],
              dataValueToDisplay: ['Role'],
              dataPropertiesToDisplay: ['Role'],
              datasourceKeyword: 'organizationcustomworkrolelistforworklist',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              dependentFieldsList: ['tabDisplayName'],
              separatedBy: '',
              dropdownSettings: {
                singleSelection: false,
                idField: 'Role',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
              },
              keyToBeSentToBackend: 'Role',
              toDisplayInIputField: ['role'],
            },
          },
        },

        {
          valueToBeFetchedBy: 'tabDisplayName',
          columnName: 'tabDisplayName',
          displayName: 'Tab Name',
          viewClassName: 'col-sm-3 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Tab Names', itemName: 'All Tab Names' },
          inputValidations: {
            required: false,
          },
          value: 'tabDisplayName',
          properties: {
            placeholder: '',
            defaultValue: '',
            width: '',
            // isDisableInEdit: true,
            source: {
              dependsOn: false,
              isDistinctCheck: 'TabDisplayName',
              options: [
                { TabDisplayName: 'All Tab Names' },
              ],
              dataValueToDisplay: ['TabDisplayName'],
              dataPropertiesToDisplay: ['TabDisplayName'],
              datasourceKeyword: 'organizationcustomworkTabNamelistForWorklist',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              isSingleValueToDisplay: '',
              dependentFieldsList: [],
              separatedBy: '',
              dropdownSettings: {
                singleSelection: false,
                idField: 'TabDisplayName',
                textField: 'dropdownDisplayColumn',
                enableCheckAll: true,
                allowSearchFilter: true,
                limitSelection: -1,
                clearSearchFilter: true,
                maxHeight: 50,
                itemsShowLimit: 1,
                closeDropDownOnSelection: false,
                showSelectedItemsAtTop: false,
                defaultOpen: false,
              },
              keyToBeSentToBackend: 'TabDisplayName',
              toDisplayInIputField: ['tabDisplayName'],
            },
          },
        },

      ]

    },
    //Hot Keys
    {
      templateName: "Hot Keys",
      idKey: 'HotKeyId',
      hasStatusColumn: true,
      configurableTemplateFields: [
        {
          valueToBeFetchedBy: 'UserId',
          columnName: 'UserId',
          displayName: 'User Name',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-4',
          useViewProperty: 'UserName',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              filterForUserDropdown: true,
              showClearIcon: true,
              uiDependentFieldsList: ['DiagnosisCodeId', 'AdequacyCodeId'],
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['UserName'],
              datasourceKeyword: 'GetHotkeyUsers',
              datasourceDB: [],
              datasourceQueryVariable: { 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'UserId',
              toDisplayInIputField: ['UserName'],
            },
            isDisabledInAdmin: false,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-4',
          dummyValue: { itemId: 'GYN PAP', itemName: 'GYN PAP' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              byPassOptionsFetching: true,
              dependsOn: '',
              options: [{ CaseType: 'GYN PAP', dropdownDisplayColumn: 'GYN PAP' }],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CaseType'],
              datasourceKeyword: '',
              datasourceDB: [],
              datasourceQueryVariable: {},
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CaseType'],
            },
            isDisabledInAdmin: false,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'DiagnosisCodeId',
          columnName: 'DiagnosisCodeId',
          displayName: 'Diagnosis Code',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-4',
          useViewProperty: 'DiagnosisCode',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: false,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['DiagnosisCode'],
              datasourceKeyword: 'GetDiagnosisCodes',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '', 'casetype': 'Gyn Pap' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'DiagnosisCodeId',
              toDisplayInIputField: ['DiagnosisCode'],
              preFilterProperty: ['!', 'UserId'],
              isPatchValueToOtherField: {
                targetField: 'HotKeyDisplayName',
                sourceFields: ['DiagnosisCodeId', 'AdequacyCodeId']
              }
            },
            isDisabledInAdmin: false,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'AdequacyCodeId',
          columnName: 'AdequacyCodeId',
          displayName: 'Adequacy Code',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-4 mt-4',
          useViewProperty: 'AdequacyCode',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            placeholder: 'Select',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: false,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['AdequacyCode'],
              datasourceKeyword: 'GetAdequacyCodes',
              datasourceDB: [],
              datasourceQueryVariable: { 'orgid': '', 'casetype': 'Gyn Pap' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'AdequacyCodeId',
              toDisplayInIputField: ['AdequacyCode'],
              preFilterProperty: ['!', 'UserId'],
              isPatchValueToOtherField: {
                targetField: 'HotKeyDisplayName',
                sourceFields: ['DiagnosisCodeId', 'AdequacyCodeId']
              }
            },
            isDisabledInAdmin: false,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'HotKeyDisplayName',
          columnName: 'HotKeyDisplayName',
          displayName: 'Display Name',
          viewClassName: 'col-sm-4',
          dataType: 'input',
          manageClassName: 'col-sm-4 mt-4',
          inputValidations:
          {
            required: true,
            maxLength: '250',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'Display Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-2',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
      ],
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'CaseType',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'GYN PAP', itemName: 'GYN PAP' },
          inputValidations: {
            required: false,
          },
          Value: "GYN PAP",
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            hideInAdmin: true,
            source: {
              byPassOptionsFetching: true,
              dependsOn: '',
              options: [{ CaseType: 'GYN PAP', dropdownDisplayColumn: 'GYN PAP' }],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CaseType'],
              datasourceKeyword: '',
              datasourceDB: [],
              datasourceQueryVariable: {},
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CaseType'],
            }
          },
        },
        {
          valueToBeFetchedBy: 'UserName',
          columnName: 'UserName',
          displayName: 'User Name',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 ml-2',
          dummyValue: { itemId: 'Group', itemName: 'Group ( Group Level )' },
          inputValidations: {
            required: false,
          },
          value: 'Group',
          properties: {
            placeholder: 'Select User',
            defaultValue: '',
            width: '',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              filterForUserDropdown: true,
              options: [{ UserName: 'Group ( Group Level )', UserId: 0, dropdownDisplayColumn: 'Group ( Group Level )', RoleName: 'Group Level' }],
              dataValueToDisplay: ['UserName'],
              dataPropertiesToDisplay: [],
              datasourceKeyword: 'GetHotkeyUsers',
              datasourceDB: [],
              datasourceQueryVariable: { 'assoicatedaccid': '' },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              separatedBy: '',
              keyToBeSentToBackend: 'UserId',
              toDisplayInIputField: ['UserName'],
            }
          },
        },
      ]
    },
    //screening type
    {
      templateName: "Screening Types",
      idKey: 'AttributeId',
      hasStatusColumn: true,
      configurableTemplateFields: [

        {
          valueToBeFetchedBy: 'CaseType',
          columnName: 'CaseType',
          displayName: 'Case Type',
          viewClassName: 'col-sm-4 hidden',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: false,
          },
          properties: {
            placeholder: 'Select Case Type',
            defaultValue: '',
            width: '23%',
            isDisableInEdit: true,
            source: {
              dependsOn: '',
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['CasetypeDisplayName'],
              datasourceKeyword: 'CaseTypeforAddendumNotes',
              isDistinctCheck: 'CasetypeDisplayName',
              datasourceDB: [],
              datasourceQueryVariable: { 'id': '', 'assoicatedaccid': '' },
              responseType: '',
              dependentFieldsList: ['AttributeName'],
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'CaseType',
              toDisplayInIputField: ['CasetypeDisplayName']
            },
            isDisabledInAdmin: true,
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'AttributeName',
          columnName: 'AttributeName',
          displayName: 'Collection Method',
          viewClassName: 'col-sm-3',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-3',
          dummyValue: { itemId: '', itemName: '' },
          inputValidations: {
            required: true,
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'Collection Method',
            defaultValue: '',
            width: '23%',
            source: {
              dependsOn: true,
              defaultOptions: [{ extnprocedurename: 'All', dropdownDisplayColumn: 'All' }],
              options: [],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['extnprocedurename'],
              datasourceKeyword: 'getcollectionmethods', //keyword
              datasourceDB: [],
              datasourceQueryVariable: { 'casetype': 'dependsOn', 'orgid': '' }, //queryvariable
              responseType: '',
              pathToResposeData: ['data', 'submenuDataExtnProcedures'],
              keyToBeSentToBackend: 'extnprocedurename',
              toDisplayInIputField: ['extnprocedurename'],
              hasAllOption: true
            }

          },
        },
        {
          valueToBeFetchedBy: 'Procedure',
          columnName: 'Procedure',
          displayName: 'Name',
          value: '',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '255',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'Name',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'TestName',
          columnName: 'TestName',
          displayName: 'TestName',
          value: '',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '200',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            isDisableInEdit: true,
            placeholder: 'TestName',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'CPTCodes',
          columnName: 'CPTCodes',
          displayName: 'CPTCodes',
          value: '',
          viewClassName: 'col-sm-3',
          dataType: 'input',
          manageClassName: 'col-sm-3',
          inputValidations:
          {
            required: true,
            maxLength: '2000',
            minLength: '',
            inputDataType: '',
          },
          properties: {
            placeholder: 'CPTCodes',
            defaultValue: '',
            width: '',
            replicateFields: [],
            visibility: 'both'
          }
        },
        {
          valueToBeFetchedBy: 'SequenceOrder',
          columnName: 'SequenceOrder',
          displayName: 'Sequence',
          viewClassName: 'col-sm-3',
          dataType: 'inputSequence',
          manageClassName: 'col-sm-2',
          listClassName: 'col-sm-3',
          inputValidations:
          {
            required: false,
            maxLength: '5',
            minLength: '',
            inputDataType: 'excludeZero',
          },
          properties: {
            placeholder: 'Sequence',
            defaultValue: '',
            width: '23%',
            replicateFields: [],
          }
        },
        {
          valueToBeFetchedBy: 'IsActive',
          columnName: 'IsActive',
          displayName: 'Status',
          viewClassName: 'col-sm-3',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },
        {
          valueToBeFetchedBy: 'IsDefault',
          columnName: 'IsDefault',
          displayName: 'Default',
          viewClassName: 'col-sm-3',
          dataType: 'badge',
          manageClassName: 'col-sm-2',
          dependentFields: ['SequenceOrder'],
          inputValidations:
          {
            required: false,
          },
          properties: {
            width: '13%',
          }
        },

      ]
    },
    {
      templateName: "Manage Configurations",
      idKey: 'id',
      hasStatusColumn: true,
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'category',
          columnName: 'category',
          displayName: 'Category',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Categories', itemName: 'All Categories' },
          inputValidations: {
            required: true,
          },
          properties: {
            value: 'All Categories',
            placeholder: 'Select Category',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [
                { category: 'All Categories', dropdownDisplayColumn: 'All Categories' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['category'],
              datasourceKeyword: 'manageConfigurations',
              isDistinctCheck: 'category',
              datasourceDB: ['configdb'],
              datasourceQueryVariable: { },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'category',
              toDisplayInIputField: ['category'],
              // truncateLength: 28
            },
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'featureNames',
          columnName: 'featureName',
          displayName: 'Menu Name',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Features', itemName: 'All Features' },
          inputValidations: {
            required: true,
          },
          properties: {
            value: 'All Feature',
            placeholder: 'Select Feature',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [
                { featureNames: 'All Features', dropdownDisplayColumn: 'All Features' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['featureNames'],
              datasourceKeyword: 'manageConfigurations',
              isDistinctCheck: 'featureNames',
              datasourceDB: ['configdb'],
              datasourceQueryVariable: { },
              responseType: '',
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'featureNames',
              toDisplayInIputField: ['featureNames'],
              // truncateLength: 28
            },
            patchPredefinedValue: true,
          },
        }
      ]
    },
    {
      templateName: "Offline Process",
      idKey: 'id',
      hasStatusColumn: false,
      listFilterDropdownFields: [
        {
          valueToBeFetchedBy: 'DeploymentKey',
          columnName: 'DeploymentKey',
          displayName: 'Deployment Key',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Deployments', itemName: 'All Deployments' },
          inputValidations: {
            required: true,
          },
          properties: {
            value: 'All Deployments',
            placeholder: 'Select Deployment',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [
                { category: 'All Deployments', dropdownDisplayColumn: 'All Deployments' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['DeploymentKey'],
              datasourceKeyword: 'VitalAdminScheduledJobs',
              isDistinctCheck: 'DeploymentKey',
              datasourceDB: ['configdb'],
              datasourceQueryVariable: { 'type': 'type', 'DeploymentKey':'DeploymentKey' , 'GroupName':'GroupName' , 'filterParameter':'filterParameter' },
              responseType: '',
              isQueryParamsInGlobalMasterData: true,
              bypassDynamicQueryVariableSetup: true,
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'DeploymentKey',
              toDisplayInIputField: ['DeploymentKey'],
              // truncateLength: 28
            },
            patchPredefinedValue: true,
          },
        },
        {
          valueToBeFetchedBy: 'GroupName',
          columnName: 'GroupName',
          displayName: 'Group Name',
          viewClassName: 'col-sm-4',
          dataType: 'autocomplete',
          manageClassName: 'col-sm-8 mr-2',
          dummyValue: { itemId: 'All Group', itemName: 'All Group' },
          inputValidations: {
            required: true,
          },
          properties: {
            value: 'All Group',
            placeholder: 'Select Group',
            defaultValue: '',
            width: '',
            source: {
              dependsOn: '',
              options: [
                { category: 'All Group', dropdownDisplayColumn: 'All Group' },
              ],
              dataValueToDisplay: ['dropdownDisplayColumn'],
              dataPropertiesToDisplay: ['GroupName'],
              datasourceKeyword: 'VitalAdminScheduledJobs',
              isDistinctCheck: 'GroupName',
              datasourceDB: ['configdb'],
              datasourceQueryVariable:  { 'type': 'type', 'DeploymentKey':'DeploymentKey' , 'GroupName':'GroupName' , 'filterParameter':'filterParameter' },
              responseType: '',
              isQueryParamsInGlobalMasterData: true,
              bypassDynamicQueryVariableSetup: true,
              pathToResposeData: ['data', 'submenuData'],
              keyToBeSentToBackend: 'GroupName',
              toDisplayInIputField: ['GroupName'],
              // truncateLength: 28
            },
            patchPredefinedValue: true,
          },
        },
      ]
    }

  ]

  constructor(private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    public VitalHttpServices: VitalHttpServices,
    public labAdminService: LabadminService) { }
  objMapping(input, Name, templatesJson = this.templateView) {
    for (let i = 0; i < templatesJson.length; i++) {
      if (templatesJson[i].templateName.includes(Name)) {
        templatesJson[i].configurableTemplateFields.forEach((element, index) => {
          templatesJson[i].configurableTemplateFields[index]['value'] = input[element?.viewValueToBeFetched ? element?.viewValueToBeFetched : element?.valueToBeFetchedBy || element.label];
        });
        return templatesJson[i].configurableTemplateFields;
      }
    }
  }

  public getTemplateConfiguration(templateName) {
    let fields: any = this.templateView.find((item: any) => item.templateName === templateName);
    return fields;
  }

  async getDependentValues(selectedColumn: any, dynamicFormData: any, subMenuCardModel: any, templateData: any, isEdit = false, selectedRecord = null) {
    const dataTypeFields = ['dropdown', 'chip', 'multiselectDropdown', 'autocomplete', 'groupedTextArea', 'labAdminDropdown'];
    await Promise.all(
      dynamicFormData.map(async (fieldItem: any) => {
        if (dataTypeFields.includes(fieldItem.dataType) && selectedColumn?.properties?.source?.dependentFieldsList.includes(fieldItem.columnName)) {
          const tempOptions = await new Promise((resolve) => resolve(this.getDropdownOptions(fieldItem, selectedColumn, subMenuCardModel, templateData, selectedRecord)));
          // let tempArr = this.getValueByPath(tempOptions, fieldItem?.properties?.source?.pathToResposeData);

          if (tempOptions) {
            if (fieldItem?.properties?.source.responseType !== 'stringify') {
              let tempArr = this.getValueByPath(tempOptions, fieldItem?.properties?.source?.pathToResposeData);
              // if (fieldItem?.properties?.source?.isSingleValueToDisplay) { // in revsion notes we have to provide single value
              //   tempArr = tempArr?.map((item: any) => {
              //     return item[fieldItem?.properties?.source?.isSingleValueToDisplay];
              //   });
              // }
              tempArr = tempArr?.length > 0 ? tempArr : [];
              fieldItem.properties.source.options = [];
              fieldItem.properties.source.options = [...tempArr];
              if (fieldItem.properties?.source?.dataPropertiesToDisplay?.length) {
                tempArr = this.getNewColumnNameBasedOnTheInput(tempArr, fieldItem.properties?.source?.dataPropertiesToDisplay, fieldItem.properties?.source?.dataValueToDisplay);
                fieldItem.properties.source.options = [...fieldItem.properties.source.options];
                fieldItem.properties.source.options = Array.from(new Set(fieldItem.properties.source.options.map(item => JSON.stringify(item))))
                  .map((str: any) => JSON.parse(str));

              } else {
                fieldItem.properties.source.options = [...fieldItem.properties.source.options];
                fieldItem.properties.source.options = Array.from(new Set(fieldItem.properties.source.options.map(item => JSON.stringify(item))))
                  .map((str: any) => JSON.parse(str));
              }
              if (fieldItem.properties.source.defaultOptions) {
                fieldItem.properties.source.options.unshift(...fieldItem.properties.source.defaultOptions);
              }
              if (fieldItem?.properties?.source?.isDistinctCheck) {
                fieldItem.properties.source.options = this.toGetUniqueValuesForGraphQL(fieldItem.properties.source.options, fieldItem?.properties?.source?.isDistinctCheck)
              }
            } else {
              let pathToStringifiedValue = fieldItem?.properties?.source?.pathToResposeData.slice(0, fieldItem?.properties?.source?.pathToResposeData.length - 1);
              let parsedJsonPath = fieldItem?.properties?.source?.pathToResposeData[fieldItem?.properties?.source?.pathToResposeData.length - 1];
              let tempArr = this.getValueByPath(tempOptions, pathToStringifiedValue);
              tempArr = tempArr?.length ? JSON.parse(tempArr) : [];
              tempArr = tempArr?.[parsedJsonPath]?.map((item: any) => {
                return item[fieldItem?.properties?.source?.dataValueToDisplay];
              });
              tempArr = tempArr?.length > 0 ? tempArr : [];
              fieldItem.properties.source.options = [];
              fieldItem.properties.source.options = [...tempArr];
              if (fieldItem?.properties?.source?.isDistinctCheck) {
                fieldItem.properties.source.options = this.toGetUniqueValuesForGraphQL(fieldItem.properties.source.options, fieldItem?.properties?.source?.isDistinctCheck)
              }
            }
          } else {
            fieldItem.properties.source.options = [];
          }
        }
      })
    );
  }

  hasArrayValues(obj) {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        return true;
      }
    }
    return false;
  }
  transformObject(obj) {
    let filterParameter: any = [];
    let newObj: any = {};

    for (let key in obj) {
      // Check if the key is "role" and the value exists in workGrouproledata
      if (key === "role" && this.workGrouproledata && this.workGrouproledata.length > 0) {
        let roleValues = this.workGrouproledata.join(",");
        filterParameter.push(`role in "${roleValues}":string []`);
      } else if (Array.isArray(obj[key])) {
        // Join array values into a comma-separated string
        let values = obj[key].join(",");
        filterParameter.push(`${key} in "${values}":string []`);
      } else {
        newObj[key] = obj[key];
      }
    }
    newObj.filterParameter = filterParameter.join(" and ");
    return newObj;
  }

  async getDropdownOptions(fieldItem: any, selectedColumn: any, subMenuCardModel: any, templateData: any, selectedRecord = null) {
    const masterData = {
      caseType: templateData?.secondarykeys?.casetype?.toString(),
      keyword: templateData?.secondarykeys?.casetype?.toString(),
      orgId: this.labAdminService.sourceApp != 'vitalDX' ? this.commonService?.orgid?.toString() : this.labAdminService.organizationId,
      id: this.labAdminService.sourceApp != 'vitalDX' ? this.commonService?.orgid?.toString() : this.labAdminService.organizationId, // for revison notes
      orgid: this.labAdminService.sourceApp != 'vitalDX' ? this.commonService?.orgid?.toString() : this.labAdminService.organizationId, // for Adequecy codes
      organizationId: this.labAdminService.sourceApp != 'vitalDX' ? this.commonService?.orgid?.toString() : this.labAdminService.organizationId, // for Adequecy codes
      context: this.VitalHttpServices.deploymentKey.toString().toLowerCase() == 'thx' ? "GrossTemplateDataMarkers - THX" : "GrossTemplateDataMarkers",
      accountId: this.labAdminService.sourceApp != 'vitalDX' ? sessionStorage.getItem('AccountID') : this.labAdminService.accountId,
      assoicatedaccid: this.labAdminService.sourceApp == 'vitalDX' ? `accountId in \"${this.labAdminService?.associatedAccountIds}\":string []` : `accountId in \"${sessionStorage?.getItem('AccountID')?.toString()}\":string []`
    };

    let role;
    if (this.commonService.finalizeAfterTc) {
      role = 'Lab Manager,Lab Director,Technician'
    }
    else if (!this.commonService.finalizeAfterTc && this.commonService.isScreeningEnabled) {
      role = 'Cytotechnologist Supervisor,Cytotechnologist,Pathologist,Pathologist Assistant,Screening Technologist'
    }
    else if (!this.commonService.finalizeAfterTc && !this.commonService.isScreeningEnabled) {
      role = 'Pathologist,Pathologist Assistant,Screening Technologist'
    }
    masterData['filterName'] = "rolename in \"" + role.toString() + "\":string []";

    let hardCodedQueryVariable: any = {
      "orgid": this.labAdminService.sourceApp != 'vitalDX' ? sessionStorage.getItem('org_id') : this.labAdminService.organizationId,
      "filtername": "rolename in \"" + role.toString() + "\":string []"
    };

    let queryVariable: any = {};
    let query: any;

    if (!fieldItem?.properties?.source?.isHardCodedQueryVariable && !fieldItem?.properties?.source?.bypassDynamicQueryVariableSetup) {
      if (fieldItem?.properties?.source?.datasourceQueryVariable) {
        Object.entries(fieldItem?.properties?.source?.datasourceQueryVariable).forEach(([key, value]) => {
          queryVariable[key.toLowerCase()] = value !== 'dependsOn' ? this.getDependentValueWithOtherParam(value, masterData, key, selectedColumn['value']) : selectedColumn['value'];
        });
      }
    } else if (fieldItem?.properties?.source?.isHardCodedQueryVariable && !fieldItem?.properties?.source?.bypassDynamicQueryVariableSetup) {
      queryVariable = hardCodedQueryVariable;
    } else if (fieldItem?.properties?.source?.bypassDynamicQueryVariableSetup) {
      if (fieldItem?.properties?.source?.isQueryParamsInGlobalMasterData) {
        queryVariable = fieldItem?.properties?.source?.datasourceQueryVariable;

        queryVariable = this.getGlobalForGraphQL(queryVariable);

      } else {
        queryVariable = fieldItem?.properties?.source?.datasourceQueryVariable;
      }
    }

    if (this.hasArrayValues(queryVariable)) {
      queryVariable = this.transformObject(queryVariable);
    }

    query = this.VitalHttpServices.GetQuery(fieldItem?.properties?.source?.datasourceKeyword);
    const queryResult = this.commonService.GetCardRequest(queryVariable, query);
    //Report Signatories
    if (query?.toString()?.includes('Admin3_VwReportSignatoriesEntityName_list') && queryVariable?.type === 'Test Category') {
      delete queryVariable.accountid
    }
    else if (query?.toString()?.includes('Admin3_VwReportSignatoriesEntityName_list') && (queryVariable?.type === 'Locations' || queryVariable?.type === 'Case Type')) {
      delete queryVariable.orgid
    }
    else if (query?.toString()?.includes('vwGetAssociatedSendout_List')) {
      queryVariable.accountid = queryVariable.accountid.toString()
    }
    this.ngxService.start(); // Show loading spinner
    try {
      const data = await this.VitalHttpServices.GetData(queryResult, ...(fieldItem?.properties?.source?.datasourceDB?.[0] === 'configdb'
        ? fieldItem?.properties?.source?.datasourceDB
        : [this.labAdminService.deploymentKey])).toPromise();
      this.ngxService.stop(); // Hide loading spinner
      if (!data.errors) {
        // user dropdown filter conditions
        if (fieldItem?.properties?.source?.filterForUserDropdown && templateData.template === "app-hotkey-configuration") {
          this.userDropdownBind(data);
        }
        return data; // Return the submenuData
      }
    } catch (error) {
      this.ngxService.stop(); // Hide loading spinner in case of error
      console.error(error);
      throw error; // Rethrow the error to handle it upstream
    }
  }

  getGlobalForGraphQL(queryVariable) {
    let res = Object.keys(queryVariable).reduce((result, key) => {
      if (key in this.globalMasterDataForQueries) {
        result[key] = this.globalMasterDataForQueries[key];
      }
      return result;
    }, {});
    return res;
  }


  userDropdownBind(data) {
    let submenuData = data?.data?.submenuData;
    if (!submenuData || submenuData?.length <= 0) {
      return data;
    }
    const uniqueMap = new Map();
    const distinctAccounts = submenuData.filter((item, index, self) =>
      index === self.findIndex(t =>
        t.AccountId === item.AccountId &&
        t.IsScreeningEnabled === item.IsScreeningEnabled
      )
    );
    let rows = [];
    if (distinctAccounts.length) {
      distinctAccounts.forEach(element => {
        if (element.IsScreeningEnabled) {
          // Push items matching AccountId and RoleName in the specified list
          rows = rows.concat(submenuData.filter((item) => item.AccountId == element.AccountId &&
            ['cytotechnologist', 'cytotechnologist supervisor', 'pathologist'].includes(item.RoleName.toLowerCase())));
        }
        else {
          // Push items matching AccountId and RoleName = 'Pathologist'
          rows = rows.concat(submenuData.filter((item) => item.AccountId == element.AccountId && item.RoleName.toLowerCase() == 'pathologist'))
        }
      });
    }
    // Filter rows to ensure distinct UserId values
    let distinctRows = rows.filter((value, index, self) =>
      index === self.findIndex((t) => t.UserId === value.UserId)
    );
    data.data.submenuData = distinctRows;
    return data;
  }

  getValueByPath(obj: any, path: any) {
    return path.reduce((acc, key) => (acc && (!acc[key] || acc[key] !== 'undefined')) ? acc[key] : undefined, obj);
  }

  async getAllMasterData(type: any, value = null, subMenuCardModel: any, templateData: any, selectedListOption: any, configDataFields: any) {
    const dataTypeFields = ['dropdown', 'chip', 'multiselectDropdown', 'autocomplete', 'groupedTextArea', 'labAdminDropdown']; // checking only for these type of fields

    await Promise.all( // creating promises for each dropdown fields
      configDataFields?.map(async (fieldItem: any) => {
        if (dataTypeFields.includes(fieldItem.dataType)) {
          if (fieldItem?.properties?.source?.dependsOn) { // if dropdown is dependent on other field make its option as []
            // fieldItem['properties']['source']['options'] = [];
            return;
          }

          if (fieldItem?.properties?.source?.byPassOptionsFetching) { // if byPassOptionsFetching is there then no need to anything
            return;
          }

          let tempOptions;

          if (fieldItem?.properties?.source?.urlEndpointForAPI) {
            tempOptions = await this.getDropDownOptionsFromApi(fieldItem, fieldItem, subMenuCardModel, templateData)
          } else {
            tempOptions = await this.getDropdownOptions(fieldItem, fieldItem, subMenuCardModel, templateData); // method to get the API or GraphQL data
          }

          if (fieldItem?.properties.source.responseType !== 'stringify') { // sometime response would be stringify or normal JSON
            let tempArr = this.getValueByPath(tempOptions, fieldItem?.properties?.source?.pathToResposeData); // here based on the path what we give in json based on that we will get value

            if (fieldItem.properties?.source?.dataPropertiesToDisplay?.length) {
              tempArr = tempArr?.length > 0 ? this.getNewColumnNameBasedOnTheInput(tempArr, fieldItem?.properties.source?.dataPropertiesToDisplay, fieldItem?.properties.source?.dataValueToDisplay) : [];
              fieldItem.properties.source.options = [...fieldItem.properties.source.options, ...tempArr];
            } else {
              tempArr = tempArr?.length > 0 ? tempArr : [];
              fieldItem.properties.source.options = [...fieldItem.properties.source.options, ...tempArr];
            }
            if (fieldItem.properties.source.defaultOptions) {
              fieldItem.properties.source.options.unshift(...fieldItem.properties.source.defaultOptions);
            }
            if (fieldItem?.properties?.source?.isDistinctCheck) {
              if(fieldItem?.properties?.source?.isDistinctCheck === 'TabDisplayName') {
                fieldItem.properties.source['originalOptions'] = structuredClone(fieldItem.properties.source.options);
              }
              fieldItem.properties.source.options = this.toGetUniqueValuesForGraphQL(fieldItem.properties.source.options, fieldItem?.properties?.source?.isDistinctCheck);
            }
            if (fieldItem['properties']['source']['preFilterProperty']?.length) {
              fieldItem['properties']['source']['tempOrigialOptions'] = structuredClone(fieldItem.properties.source.options);
              let options = this.initialFilter(fieldItem.properties.source.options, fieldItem['properties']['source']['preFilterProperty'], fieldItem);
              fieldItem.properties.source.options = structuredClone(options);
            }
          } else {
            const pathToStringifiedValue = fieldItem?.properties?.source?.pathToResposeData.slice(0, -1); // path till the stringified value
            const parsedJsonPath = fieldItem?.properties?.source?.pathToResposeData[fieldItem?.properties?.source?.pathToResposeData.length - 1]; // after JSON.parse if there is an one more path then we are doing this.

            let tempArr = this.getValueByPath(tempOptions, pathToStringifiedValue);
            tempArr = tempArr?.length ? JSON.parse(tempArr) : [];
            if (!fieldItem?.properties?.source?.considerKeyValues) {
              tempArr = tempArr?.[parsedJsonPath]?.map((item: any) => item[fieldItem?.properties?.source?.dataValueToDisplay]);
            } else {
              tempArr = tempArr?.[parsedJsonPath];
            }
            tempArr = tempArr ? tempArr : [];
            fieldItem.properties.source.options = [...fieldItem.properties.source.options, ...tempArr];
            if (fieldItem?.properties?.source?.isDistinctCheck) {
              fieldItem.properties.source.options = this.toGetUniqueValuesForGraphQL(fieldItem.properties.source.options, fieldItem?.properties?.source?.isDistinctCheck)
            }
            if (fieldItem['properties']['source']['preFilterProperty']?.length) {
              fieldItem['properties']['source']['tempOrigialOptions'] = structuredClone(fieldItem.properties.source.options);
              let options = this.initialFilter(fieldItem.properties.source.options, fieldItem['properties']['source']['preFilterProperty'], fieldItem);
              fieldItem.properties.source.options = structuredClone(options);
            }
          }
        }

      })
    );
  }
  //used to get the distinct data from graphQL
  toGetUniqueValuesForGraphQL(options, distinctKey) {
    const seenCaseTypes = new Set();
    // Filter the data to keep only unique CaseTypes
    const uniqueData = options.filter(item => {
      const isUnique = !seenCaseTypes.has(item[distinctKey]);
      if (isUnique) {
        seenCaseTypes.add(item[distinctKey]);
      }
      return isUnique;
    });
    return [...uniqueData];
  }

  getNewColumnNameBasedOnTheInput(optionsArray: any, propertiesToDisplayArray: any, dropdownDisplayColumn: any) {
    let modifiedArrayWithNewProperty = optionsArray.map(obj => {
      // Assign the value to dropdownDisplayColumn property based on the length of propertiesToDisplayArray
      if (propertiesToDisplayArray.length === 1) {
        obj[dropdownDisplayColumn] = obj[propertiesToDisplayArray[0]]; // If only one element, assign its value directly
      } else {
        // Concatenate values with parentheses if propertiesToDisplayArray has more than one element
        obj[dropdownDisplayColumn] = obj[propertiesToDisplayArray[0]] + ' ( ' + obj[propertiesToDisplayArray[1]] + ' )';
      }
      return obj;
    });
    return modifiedArrayWithNewProperty;
  }

  async getDropDownOptionsFromApi(fieldItem: any, selectedColumn: any, subMenuCardModel: any, templateData: any) {
    let apiUrl = fieldItem?.properties?.source?.urlEndpointForAPI;
    let apiQueryParams = fieldItem?.properties?.source?.urlQueryParameters;
    const finalURL = `${apiUrl}${this.constructQueryStringForApi(fieldItem, apiQueryParams)}`;
    this.ngxService.start(); // Show loading spinner
    try {
      const data = await this.VitalHttpServices.getGenericDetailsBasedOnInputs(finalURL).toPromise();
      this.ngxService.stop(); // Hide loading spinner
      if (!data.errors) {
        return data; // Return the submenuData
      }
    } catch (error) {
      this.ngxService.stop();; // Hide loading spinner in case of error
      console.error(error);
      throw error; // Rethrow the error to handle it upstream
    }
  }

  constructQueryStringForApi(fieldItem, parameters) {
    const queryString = Object.keys(parameters).map(key => this.getParamValue(fieldItem, parameters, key)).join('&');
    return queryString.length > 0 ? `?${queryString}` : '';
  }

  getParamValue(fieldItem, parameters, key) {
    if (fieldItem?.properties?.source?.isQueryParamsInGlobalMasterData) {
      return `${key}=${encodeURIComponent(this.globalMasterDataForQueries[parameters[key]])}`
    }
  }

  getDependentValueWithOtherParam(value: any, masterData: any, key: any, selectedColumnValue: any) {
    if (value == null || value == undefined)
      return null
    else if (value.includes('dependsOn')) {
      let newValue = value.replace("dependsOn", selectedColumnValue);
      return newValue;
    } else if (key === 'filterParam') {
      return value;
    } else {
      return masterData[key] ? value + masterData[key] : value;
    }
  }

  filterUIFields(currentField: any, dependentFields: any, formObject: any, filterParam: any, inEdit = false) {
    let toBeChangedFormFields = formObject.filter(obj => dependentFields.includes(obj.columnName));
    toBeChangedFormFields?.forEach((item: any) => {
      item['dummyValue'] = { itemId: '', itemName: '' };
      if (!inEdit) item['value'] = null;
      if (!item['properties']['source']['tempOrigialOptions']?.length) item['properties']['source']['tempOrigialOptions'] = structuredClone(item['properties']?.['source']?.['options']);

      item['properties']['source']['options'] = [...[]];
      if (!currentField['value'] || currentField['value'] === "Not Specified") {
        let filteredOptions = item['properties']['source']['tempOrigialOptions'].filter((item: any) => {
          return !item[filterParam];
        });
        item['properties']['source']['options'] = structuredClone(filteredOptions);
      } else {
        let filteredOptions = item['properties']['source']['tempOrigialOptions'].filter((item: any) => {
          return item[filterParam] === currentField['value'];
        });
        item['properties']['source']['options'] = structuredClone(filteredOptions);
      }
    });
  }

  initialFilter(obtainedData: any, prefilterValue: any, fieldItem) {
    let operation = prefilterValue[0];
    let prefilterKey = prefilterValue[1];
    if (operation === '!') {
      return obtainedData.filter((item: any) => {
        return !item[prefilterKey];
      });
    } else {
      return obtainedData;
    }
  }

}

function includes(Name: any) {
  throw new Error('Function not implemented.');
}
