import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ThemePalette } from '@angular/material/core';
import {  MatOption } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSelect } from '@angular/material/select';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { ExcelService } from '../../../core/services/excel.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-default-stains',
  templateUrl: './default-stains.component.html',
  styleUrls: ['./default-stains.component.scss']
})

export class DefaultStainsComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  @Input() templateData: any;
  gridPage: Boolean = true;
  addEditClicked: Boolean = false;
  gridwidth: number;
  gridData: CollectionView;
  showPaginationMainGrid: boolean = false;
  selectedrowData: any = {};
  gridarray: any[];
  public SubMenuCardModel;
  columns: any[];
  gridHeader = [];
  DeploymentKeys: any;
  action: string;
  sitesList: any = [];
  OFAccountsList: any = [];
  servicesList: any = [];
  extnProcedureList: any = [];
  allSelectedSite = false;
  allSelectedExnProcedure = false;
  allSelected = false;
  panelDropdown = [];
  currentData: any = {};
  readonlyTestSequence: boolean = false;
  public moreHide: boolean = false;
  destDeployment: string = ''
  noDataFound: boolean = false;
  configdefaultstaindata1: any;
  subMenuCopyData: any[] = [];
  uploadClicked: boolean = false
  bulkData: any = [];
  sheetHeader: any = [];
  disableSequence: Boolean = false;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  @ViewChild('inputAutoCompletePanel') inputAutoCompletePanel: any;
  @ViewChild('inputAutoCompleteExtn') inputAutoCompleteExtn: any;
  @ViewChild('inputAutoCompleteSite') inputAutoCompleteSite: any;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  panelTypearrowIconSubject = new BehaviorSubject('arrow_drop_down');

  @ViewChild('matSelectOption') matSelectOption: MatSelect;
  @ViewChild('matSelectOptionExn') matSelectOptionExn: MatSelect;
  @ViewChild('matSelectOptionSite') matSelectOptionSite: MatSelect;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  dataList: any = [];
  validateOF: boolean = false;
  validateExtn: boolean = false;
  validateSite: boolean = false;
  isAdvancedFlag: boolean = false;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  uploadBtn: boolean = true;
  hideSaveBtn: boolean = false;
  isAccountProcData: any;
  isAssociationProcdata: any;
  message: any;
  siteFormat: any;
  testTypeData: any;
  bulkGridData: CollectionView;
  postUpload: boolean = false;
  showDelete: boolean = false;
  displayHeaders: any = ["ID", "Panel", "Sequence(Panel)", "Panel Type", "Panel Description", "Procedure Name", "CPT Code", "Fish Test", "Extraction Procedure", "Ordering Facility", "Service", "Site", "Active", "Sequence(Procedure)"];
  mappedHeaders: any = ["id", "panelname", "panelsequence", "paneltype", "paneldescription", "procedurename", "cptcode", "isfishtest", "extractionprocedureid", "orderingfacilityid", "service", "siteid", "isactive", "proceduresequence"];
  errorMessages = ['Mandatory field(s) are missing', 'Record already exists', 'Please provide data to update', 'Record does not exists', 'Sequence cannot be 0']
  invalidColumns: string;
  showInvalidColumns: boolean = false;
  userid: number;
  gridName: string = "";
  auditableColumns: any;
  activityEntity: any;
  auditableColumnsCopy: any;
  showUploadGrid = false;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: UntypedFormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, private excelService: ExcelService,
    public activityService: ActivityTrackerService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.userid = sessionStorage.getItem("Userid") ? Number(sessionStorage.getItem("Userid")) : -100
    await this.getSiteFormat();
    this.gridName = "Default Stains List";
    this.getAuditableDetails(this.templateData.menuURL);
    this.commonService.createContext(this.templateData?.secondarykeys, '',this.templateData?.menuURL);
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    if (this.templateData?.submenuData && this.templateData?.submenuData?.length > 0) {
      let isactivestatuscount = await this.activestatuscheck(this.templateData?.submenuData);
      if (isactivestatuscount) {
        this.isAccountProcData = true
        this.panelDropdown = [...new Map(this.templateData?.submenuData?.map(item =>
          [item["Panel"], item])).values()];
        this.panelDropdown.sort((a, b) => a?.Panel?.toString()?.toLowerCase().trim() < b?.Panel?.toString()?.toLowerCase().trim() ? -1 : a?.Panel?.toString()?.toLowerCase().trim() > b?.Panel?.toString()?.toLowerCase().trim() ? 1 : 0);
        await this.getStainAssociationDetails();
        await this.addGridData(this.templateData?.submenuData);
        this.isAdvancedFlag = false;
      }
      else {
        this.isAccountProcData = false
        this.panelDropdown = [];
        this.isAdvancedFlag = true;
        await this.getStainAssociationDetails();
      }
    }
    else {
      this.isAccountProcData = false
      await this.getStainAssociationDetails();
      this.isAdvancedFlag = true;
    }
    if (!this.isAccountProcData && !this.isAssociationProcdata) {
      if (this.templateData?.submenuData && this.templateData?.submenuData?.length > 0) {
        this.panelDropdown = [...new Map(this.templateData?.submenuData?.map(item =>
          [item["Panel"], item])).values()];
        this.panelDropdown?.sort((a, b) => a?.Panel?.toString()?.toLowerCase().trim() < b?.Panel?.toString()?.toLowerCase().trim() ? -1 : a?.Panel?.toString()?.toLowerCase().trim() > b?.Panel?.toString()?.toLowerCase().trim() ? 1 : 0);

        //this.getStainAssociationDetails();
        await this.addGridData(this.templateData?.submenuData);
        this.isAdvancedFlag = false;
      } else {
        this.gridwidth = 0
      }
    }
    this.GetButtondetails();
  }

  // @ViewChild('search') searchTextBox: ElementRef;

  searchTextboxControl = new UntypedFormControl();
  selectedValues = [];

  testForm = this._fb.group({
    frmpanel: ["", Validators.required],
    frmprocedure: ["", Validators.required],
    frmcptcodes: ["", Validators.required],
    frmtestsequence: ["", Validators.required],
    frmsequence: ["", Validators.required],
    frmactive: true,
    frmfishtest: false,
    frmtestdescription: 'ScreeningType',
    frmtesttype: '',
    frmaadvanced: false,
    frmexnprocedure: ["", Validators.required], //procedure
    frmservice: "",
    frmsite: ["", Validators.required], //site
    frmorderingfacility: '',
    frmexnprocedureid: '',
    frmsiteid: '',
    frmorderingfacilityid: '',
    frmtestid: '',
    frmprocedureid: ''
  })

  //#region if data is not found in AccountProcedures table
  async getStainAssociationDetails() {
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.start();
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString() };
    let query = this.SubMenuCardModel.GetQuery("casetypedefaultstainlist");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(async data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.dataList = data.data.submenuData;
        this.panelDropdown = data.data.submenuData;
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          // let isactivestatuscount = await this.activestatuscheck(data.data.submenuData);
          // if (isactivestatuscount) {
            this.isAssociationProcdata = true
            if (!this.isAccountProcData) {
              this.templateData['submenuData'] = null
            }
            let arr = [];
            arr = cloneDeep(this.panelDropdown)
            this.panelDropdown = []
            var flags = [], l = arr.length, i;
            for (i = 0; i < l; i++) {
              if (flags[arr[i].Panel]) continue;
              flags[arr[i].Panel] = true;
              this.panelDropdown.push(arr[i]);
            }
            if (!(this.templateData.submenuData && this.templateData.submenuData.length > 0)) { await this.addGridData(data.data.submenuData); }
          // }
          // else {
          //   this.isAssociationProcdata = false
          // }
        }
        else {
          this.isAssociationProcdata = false
          if (!(this.templateData.submenuData && this.templateData.submenuData.length > 0)) { await this.addGridData([]); }
        }
      }
    },
      error => {
        this.ngxService.stop();
        console.error(error);
      });
  }
  //#endregion

  //#region Tooltip for Grid
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  //#region set the data for edit form
  openEditScreen(grid, event) {
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    this.selectedrowData = selectedrowData;
    this.currentData = cloneDeep(selectedrowData)
    this.ngxService.start();
    this.action = 'edit';
    this.gridPage = false;
    this.addEditClicked = true;
    this.panelDropdown = [];
    this.panelDropdown = [...new Map(this.subMenuCopyData.map(item =>
      [item["Panel"], item])).values()];
    this.getDropdownValues();
    this.activityEntity['entityid'] = selectedrowData.Id;
    this.activityService.setActivitySession(this.activityEntity);
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.isAdvancedFlag = false;
      this.selectedrowData = this.templateData.submenuData.find(e => e.Id == selectedrowData.Id)
      this.testForm.patchValue({
        frmpanel: (selectedrowData.Panel && selectedrowData.Panel.toLowerCase() == '') ? '' : selectedrowData.Panel,
        frmprocedure: (selectedrowData.Procedure_Name && selectedrowData.Procedure_Name.toLowerCase() == '') ? '' : selectedrowData.Procedure_Name,
        frmcptcodes: (selectedrowData.CPT_Code && selectedrowData.CPT_Code.toLowerCase() == '') ? '' : selectedrowData.CPT_Code,
        frmtestsequence: (selectedrowData.TestSequence && selectedrowData.TestSequence.toString().toLowerCase() == '') ? '' : selectedrowData.TestSequence,
        frmsequence: (selectedrowData.Procedure_Sequence && selectedrowData.Procedure_Sequence.toString().toLowerCase() == '') ? '' : selectedrowData.Procedure_Sequence,
        frmactive: selectedrowData.Status.toString().toLowerCase() == 'active' ? true : false,
        frmtestid: selectedrowData.testid,
        frmprocedureid: selectedrowData.Procedure_Id,
        frmexnprocedure: '',
        frmservice: '',
        frmsite: '',
        frmorderingfacility: '',
        frmexnprocedureid: '',
        frmsiteid: '',
        frmorderingfacilityid: '',
        frmfishtest: selectedrowData.Fish_Test && selectedrowData.Fish_Test.toString().toLowerCase() == '' ? false : selectedrowData.Fish_Test,
        frmtestdescription: selectedrowData.Panel_Description && selectedrowData.Panel_Description.toString().toLowerCase() == '' ? 'ScreeningType' : selectedrowData.Panel_Description,
        frmtesttype: selectedrowData.Panel_Type && selectedrowData.Panel_Type.toString().toLowerCase() == '' ? '' : selectedrowData.Panel_Type,
      })
    } else if (this.dataList && this.dataList.length > 0) {
      this.isAdvancedFlag = true;
      this.selectedrowData = this.dataList.find(e => e.Id == selectedrowData.Id)
      this.testForm.patchValue({
        frmpanel: (selectedrowData.Panel && selectedrowData.Panel.toLowerCase() == '') ? '' : selectedrowData.Panel,
        frmprocedure: (selectedrowData.Procedure_Name && selectedrowData.Procedure_Name.toLowerCase() == '') ? '' : selectedrowData.Procedure_Name,
        frmcptcodes: (selectedrowData.CPT_Code && selectedrowData.CPT_Code.toLowerCase() == '') ? '' : selectedrowData.CPT_Code,
        frmtestsequence: (selectedrowData.Test_Sequence && selectedrowData.Test_Sequence.toString().toLowerCase() == '') ? '' : selectedrowData.Test_Sequence,
        frmsequence: (selectedrowData.Sequence_Order && selectedrowData.Sequence_Order.toString().toLowerCase() == '') ? '' : selectedrowData.Sequence_Order,
        frmactive: selectedrowData.Status.toString().toLowerCase() == 'active' ? true : false,
        frmtestid: selectedrowData.testid,
        frmexnprocedure: (selectedrowData.Extraction_Procedure && selectedrowData.Extraction_Procedure.toLowerCase() == '') ? '' : selectedrowData.Extraction_Procedure,
        frmexnprocedureid: selectedrowData.extnprocedureid,
        frmsiteid: selectedrowData.siteid,
        frmfishtest: selectedrowData.Fish_Test && selectedrowData.Fish_Test.toString().toLowerCase() == '' ? false : selectedrowData.Fish_Test,
        frmtestdescription: selectedrowData.Panel_Description && selectedrowData.Panel_Description.toString().toLowerCase() == '' ? 'ScreeningType' : selectedrowData.Panel_Description,
        frmtesttype: selectedrowData.Panel_Type && selectedrowData.Panel_Type.toString().toLowerCase() == '' ? '' : selectedrowData.Panel_Type,
        frmorderingfacilityid: selectedrowData.OFAccountId,
        frmservice: (selectedrowData.Services && selectedrowData.Services.toLowerCase() == '') ? '' : (selectedrowData.Services == 'ALL' ? 'All' : selectedrowData.Services),
        frmsite: (selectedrowData.Site && selectedrowData.Site.toLowerCase() == '') ? '' : this.siteFormat == 2 ? (selectedrowData.Site + ' (' + selectedrowData.Parent_Site + ')' ) : selectedrowData.Site  ,
        frmorderingfacility: (selectedrowData.Ordering_Facilty && (selectedrowData.Ordering_Facilty.toLowerCase() == '' || selectedrowData.Ordering_Facilty?.toString().toLowerCase() == 'all')) ? '' : selectedrowData.Ordering_Facilty,
        frmprocedureid: selectedrowData.Procedure_Id,
      })
    }
    this.disableSequence = false;
    if (this.testForm.value.frmtestsequence) {
      this.disableSequence = true;
    }
  }
  //#endregion

  //Get Site Format for case type
  async getSiteFormat() {
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype };
    let query = this.SubMenuCardModel.GetQuery('casetypesiteformat');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices
      .GetData(queryResult)
      .toPromise()
      .then(
        data => {
          if (!data.errors) {
            if (data) {
              this.siteFormat = data.data.submenuData[0].SiteFormat ? data.data.submenuData[0].SiteFormat : 2;
            }
          }
        })
  }

  //#region open add/edit page
  addDefaultStain() {
    this.gridPage = false;
    this.addEditClicked = true;
    this.action = 'create'
    this.ngxService.start();
    this.disableSequence = false;
    this.panelDropdown = [];
    this.panelDropdown = [...new Map(this.subMenuCopyData.map(item =>
      [item["Panel"], item])).values()];
    this.getDropdownValues();
  }
  //#endregion
  getdropdownvalue(flag){
    if(flag){
      this.moreHide = true
      this.testForm.patchValue({
        frmorderingfacility: '',
        frmorderingfacilityid: '',
        frmsite: '',
        frmsiteid: '',
        frmexnprocedure: '',
        frmexnprocedureid: '',
      frmservice: ''
      })
    }else{
      this.moreHide = false
    }

  }

  async getDropdownValues() {
    if (this.panelDropdown.length > 0) {
      const temp = cloneDeep(this.panelDropdown);
      this.panelDropdown = [];
      var flags = [], l = temp.length, i;
      for (i = 0; i < l; i++) {
        if (flags[temp[i].Panel]) continue;
        flags[temp[i].Panel] = true;
        this.panelDropdown.push(temp[i]);
      }
    }
    this.extnProcedureList = [];
    this.sitesList = [];
    this.OFAccountsList = [];
    this.ngxService.start();
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString(), orgid: sessionStorage.getItem('org_id') };
    let query = this.SubMenuCardModel.GetQuery("getdefaultstainvalues");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(async data => {
      this.ngxService.stop();
      if (!data.errors) {
        await this.getSites();
        await this.getTestTypes();
        this.OFAccountsList = data.data.submenuDataOFs;
        this.sitesList.sort((a, b) => a.sitename.toString().toLowerCase().trim() < b.sitename.toString().toLowerCase().trim() ? -1 : a.sitename.toString().toLowerCase().trim() > b.sitename.toString().toLowerCase().trim() ? 1 : 0)
        this.OFAccountsList.sort((a, b) => a.displayname.toString().toLowerCase().trim() < b.displayname.toString().toLowerCase().trim() ? -1 : a.displayname.toString().toLowerCase().trim() > b.displayname.toString().toLowerCase().trim() ? 1 : 0)
        data.data.submenuDataServicesGolbal.forEach(e => {
          data.data.submenuDataServices.push(e)
        })
        let array = [...data.data.submenuDataServices]
        array.sort((a, b) => a.services.toString().toLowerCase() < b.services.toString().toLowerCase() ? -1 : a.services.toString().toLowerCase() > b.services.toString().toLowerCase() ? 1 : 0);

        const uniqueTrimmedServices = new Set();
        array.forEach(obj => {
          const trimmedService = obj.services.trim();
          uniqueTrimmedServices.add(trimmedService);
        });
        this.servicesList = uniqueTrimmedServices;
        if (data.data.submenuDataExtnProcedures.length > 0) {
          this.extnProcedureList = data.data.submenuDataExtnProcedures;
          this.extnProcedureList.sort((a, b) => a.extnprocedurename.toString().toLowerCase().trim() < b.extnprocedurename.toString().toLowerCase().trim() ? -1 : a.extnprocedurename.toString().toLowerCase().trim() > b.extnprocedurename.toString().toLowerCase().trim() ? 1 : 0)
        }
        else {
          let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString() };
          let queryKey = sessionStorage.getItem('deploymentKey').match(/thx/i) ? "casetypecollectionmethodmasterlistP4" : "casetypecollectionmethodmasterlistNonP4"
          let query = this.SubMenuCardModel.GetQuery(queryKey);
          let queryResult = this.commonService.GetCardRequest(queryVariable, query);
          this.ngxService.start();
          await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(data => {
            this.ngxService.stop();
            if (!data.errors) {
              if (data.data.submenuData && data.data.submenuData.length > 0) {
                data.data.submenuData.forEach(d => {
                  this.extnProcedureList.push({ extnprocedurename: d.Name, extnprocedureid: d.ID })
                })
                // const ids = temp.map(({ extnprocedureid }) => extnprocedureid);
                // this.extnProcedureList = temp.filter(({ extnprocedureid }, index) => !ids.includes(extnprocedureid, index + 1));
                this.extnProcedureList.sort((a, b) => a?.extnprocedurename?.toString()?.toLowerCase()?.trim() < b?.extnprocedurename?.toString()?.toLowerCase()?.trim() ? -1 : a?.extnprocedurename?.toString()?.toLowerCase()?.trim() > b?.extnprocedurename?.toString()?.toLowerCase()?.trim() ? 1 : 0)
              }
            }
          },
            error => {
              this.ngxService.stop();
              console.error(error);
            })
        }
      }
    },
      error => {
        this.ngxService.stop();
        console.error(error);
      });
  }


  async getTestTypes() {
    let query = this.SubMenuCardModel.GetQuery('getdistincttesttypes');
    let queryResult = this.commonService.GetCardRequest(new Object, query);
    try {
      this.testTypeData = [];
      let testtypeData = await this.VitalHttpServices.GetData(queryResult).toPromise();
      this.testTypeData = testtypeData.data.submenudata;
      this.testTypeData = this.commonService.sortByName(this.testTypeData, 'testtype');
    }
    catch (err) {
      console.error(err);
    }
  }

  filterPanelTypes(value) {
    if (!value) {
      return this.testTypeData;
    }
    return this.testTypeData.filter(va => va.testtype.toString().toLowerCase().includes(value.toString().toLowerCase().trim()));
  }

  async getSites() {
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: sessionStorage.getItem('org_id') };

    let query = this.SubMenuCardModel.GetQuery(this.siteFormat == 2 ? "subsitesoncasetype" : 'bodysitesOnCasetype');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    try {
      let data = await this.VitalHttpServices.GetData(queryResult).toPromise();
      let sitedata= data?.data?.submenuData.filter(option=>option.BodySite?.toLowerCase() !== "select");
      this.assignSiteData(sitedata);
    }
    catch (err) {
      console.error(err);
    }
  }

  assignSiteData(siteData) {
    siteData = siteData.map(va => ({ ...va, sitename: this.siteFormat == 2 ? (va.SubSite.toString() + ' (' + va.BodySite.toString() + ') ') : va.BodySite }))
    siteData = this.convertObjKeysToLower(siteData);
    this.sitesList = siteData;
  }

  getErrorMessage(fieldValue, fieldName) {
    if (fieldName == '') {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
    else if (fieldName.match(/cpt/i)) {
      if (fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (fieldValue.match(/[^a-zA-Z0-9-., /]/) != null) {
        return 'Special chars and spaces not allowed in CPT Code. Characters allowed: / , -';
      }
    }
    else if (fieldName == 'sequence') {
      if (fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
    else if (fieldName.match(/testsequence/i)) {
      if (fieldValue.toString().replace(/\s+/g, '').length == 0) {
        return 'Please enter a value';
      }
      else if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
    else if (fieldName.match(/ExtProcedure/i)) {
      return 'Select Extraction Procedure';
    }
    else if (fieldName.match(/sitename/i)) {
      return 'Select Sitename';
    }
  }
  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //#region Sow Main grid in the component
  async addGridData(inArray) {
    this.activityService.setActivitySession(this.activityEntity);
    this.gridarray = [];
    this.gridHeader = [];
    this.gridData = new CollectionView([]);
    let primary = {};
    if (this.templateData.submenuData) {
      this.subMenuCopyData = cloneDeep(this.templateData.submenuData);
    }
    else {
      this.subMenuCopyData = cloneDeep(inArray);
    }
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.gridHeader = [];
      this.gridHeader = [
        'OFAccountId',
        'Procedure_Id',
        'testid',
        'Id',
        'Panel',
        'Procedure_Name',
        'CPT_Code',
        'Procedure_Sequence',
        'Sequence',
        'Fish_Test', "Panel_Description", "Panel_Type",
        'TestSequence',
        'Status'
      ];
      this.gridName = "Default Stains List"
      inArray.sort((a, b) => a.Panel < b.Panel ? -1 : 1).sort((a, b) => a.TSequence < b.TSequence ? -1 : 1)
    }
    else {
      this.gridHeader = [];
      // this.gridHeader = ["Status","Id", "extnprocedureid", "siteid", "AccountId", "testid", "Procedure_Id","Sequence_Order", "Test_Sequence", "Extraction_Procedure", "Site", "Ordering_Facilty", "Services", "CPT_Code", "Procedure_Name", "Panel", "CaseType", "OFAccountId"]
      this.gridHeader = ["Panel", "Procedure_Name", "CPT_Code", 'Fish_Test', "Panel_Description", "Panel_Type", "Services", "Ordering_Facilty", "Site", "Extraction_Procedure", "Test_Sequence", "Sequence_Order", "Id", "extnprocedureid", "siteid", "AccountId", "testid", "Procedure_Id", "CaseType", "OFAccountId", "Status"]
      this.gridName = "Default Stains with Additional Attributes"
      if (this.siteFormat == 2) {
        let index = this.gridHeader.findIndex(va => va.match('Site'));
        this.gridHeader = [
          ...this.gridHeader.slice(0, index + 1),
          "Parent_Site",
          ...this.gridHeader.slice(index + 1)
        ]
      }
      inArray.sort((a, b) => a.Panel < b.Panel ? -1 : 1).sort((a, b) => a.Sequence_Order < b.Sequence_Order ? -1 : 1)
    }


    let fishTestKeys = ['Fish_Test', "Panel_Description", "Panel_Type"]
    let savedDataKeys = ['IsFishTest', "Description", "TestType"]

    if (inArray) {
      if (inArray.length > 0) {
        for (let i = 0; i < inArray.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(inArray[i])) {
            let index = savedDataKeys.findIndex(va => va.toString().toLowerCase() == key.toString().toLowerCase())
            if (index != -1) {
              if (!this.checkCaseType(this.templateData.secondarykeys.casetype)) {
                this.gridHeader = this.gridHeader.filter(f => !fishTestKeys.includes(f));
                continue;
              }
              key = fishTestKeys[index];
              if (key.match(/Fish_Test/i)) {
                value = value ? value == 1 ? true : false : false;
              }
            }

            for (let j = 0; j < this.gridHeader.length; j++) {

              if (key.toString().toLowerCase() === this.gridHeader[j].toString().toLowerCase()) {
                if (key != 'Fish_Test' && !value) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          this.gridarray.push(primary);
        }
        this.gridData = new CollectionView(this.gridarray, { groupDescriptions: ['Panel'], pageSize: 10 })
        this.gridwidth = (186 * Object.keys(inArray[0]).length) + 37;
        this.showPaginationMainGrid = this.gridarray.length > 10 ? true : false;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridwidth = 0;
      }
    }
    else {
      this.gridwidth = 0;
    }
  }
  //#endregion

  filterPanels(value) {
    if (value == '') {
      return this.panelDropdown;
    }
    let panels = this.panelDropdown;
    return panels.filter(va => va.Panel.toString().toLowerCase().includes(value.toLowerCase()));
  }

  clearInput(evt: any, field): void {
    evt.stopPropagation();
    if (field === 'Panel') {
      this.testForm.patchValue({
        frmpanel: '',
      })
      this.inputAutoCompletePanel ?.nativeElement.focus()
    }
    else if (field === 'OF') {
      this.testForm.patchValue({
        frmorderingfacility: '',
        frmorderingfacilityid: ''
      })
      this.inputAutoComplete ?.nativeElement.focus()
    }
    else if (field === 'Extn') {
      this.testForm.patchValue({
        frmexnprocedure: '',
        frmexnprocedureid: ''
      })
      this.inputAutoCompleteExtn ?.nativeElement.focus()
    }
    else if (field === 'Site') {
      this.testForm.patchValue({
        frmsite: '',
        frmsiteid: ''
      })
      this.inputAutoCompleteSite ?.nativeElement.focus()
    }
  }

  openOrClose(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  filterServices(value) {
    if (!value) {
      return this.servicesList
    }
    let service = [];
    service = this.servicesList
    if (service.length > 0) {
      return service.filter(va => va.services.toLowerCase().includes(value.toLowerCase()));
    }
    else {
      return [];
    }
  }

  toggleAllSelection(dtype) {
    if (dtype == 'OF') {
      if (this.allSelected) {
        this.matSelectOption.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOption.options.forEach((item: MatOption) => item.deselect());
      }
    }
    else if (dtype === 'Site') {
      if (this.allSelectedSite) {
        this.matSelectOptionSite.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionSite.options.forEach((item: MatOption) => item.deselect());
      }
    }
    else if (dtype === 'Extn') {
      if (this.allSelectedExnProcedure) {
        this.matSelectOptionExn.options.forEach((item: MatOption) => item.select());
      } else {
        this.matSelectOptionExn.options.forEach((item: MatOption) => item.deselect());
      }
    }
  }

  optionClick(dtype) {
    if (dtype == 'Extn') {
      let newStatus1 = true;
      this.matSelectOptionExn.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus1 = false;
        }
      });
      this.allSelectedExnProcedure = newStatus1;
    }
    else if (dtype === 'Site') {
      let newStatus2 = true;
      this.matSelectOptionSite.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus2 = false;
        }
      });
      this.allSelectedSite = newStatus2;
    }
    if (dtype == 'OF') {
      let newStatus3 = true;
      this.matSelectOption.options.forEach((item: MatOption) => {
        if (!item.selected) {
          newStatus3 = false;
        }
      });
      this.allSelected = newStatus3;
    }
  }

  saveStain(action: string, data: any) {
    const { frmpanel,
      frmprocedure,
      frmcptcodes,
      frmtestsequence,
      frmsequence,
      frmactive,
      frmfishtest,
      frmtestdescription,
      frmtesttype
    } = data;

    if (this.checkEmpties(data)) {
      this._snackbar.open('Please enter all the required fields before saving!', 'Close');
      return;
    }
    if (!/^[a-zA-Z0-9-,. /]+$/.test(frmcptcodes)) {
      this._snackbar.open('Special chars exist in CPT Code', 'Close');
      return;
    }
    if (!/^\d+$/.test(frmsequence) || !/^\d+$/.test(frmtestsequence)) {
      this._snackbar.open('Sequence takes only numbers as input', 'Close');
      return;
    }
    if (data.frmexnprocedure.length > 0 && data.frmsite.length == 0) {
      this.testForm.controls["frmsite"].setErrors({ 'incorrect': true })
      return;
    } else if (data.frmexnprocedure.length == 0 && data.frmsite.length > 0) {
      this.testForm.controls["frmexnprocedure"].setErrors({ 'incorrect': true })
      return
    }
    this.checkPanelExists(action, data);
  }

  checkPanelExists(action, data) {
    let panelNotExists = false;
    let panelArray = this.panelDropdown && this.panelDropdown.some(va => va.Panel == data.frmpanel)
    let message = this.action.match(/create/i) ? "A new panel will be added. Do you wish to continue?" :
      "The panel name will be changed. Do you wish to continue?"
    if (!panelArray) {
      if (!this.action.match(/create/i) && !panelNotExists) {
        let dialogRef = this.dialog.open(ConfirmComponent, {
          disableClose: true,
          width: '300px',
          data: { header: "Alert", message: "", alert: "Please select a panel that already exists!", continue: "OK", cancel: "dontshow" }
        });
        return;
      }
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '413px',
        data: { header: "Alert", message: "", alert: message, continue: "yes", cancel: "no" }
      });
      return dialogRef.afterClosed().toPromise().then((result) => {
        if (result) {
          this.sendData(action, data);
        }
        else {
          return false
        }
      })
    }
    else {
      this.sendData(action, data);
    }
  }

  checkEmpties(data) {
    if (data.frmpanel == "" || data.frmprocedure == "" || data.frmcptcodes == "") {
      return true;
    }
    if(this.moreHide)
    {
      if(data.frmexnprocedure.length == 0 || data.frmsite.length == 0){
       return true;
      }
    }
    return false;
  }

  getSelectedOrderingFacilities(selectedFacilities,allFacilities)
  {
    return allFacilities.length == selectedFacilities.length ? '-1' : selectedFacilities.join(',');
  }

  async sendData(action, data) {
    let frmtestid = this.panelDropdown ? this.panelDropdown.filter(va => va.Panel == data.frmpanel) : []
    let objArr = [{
      id: (this.currentData && this.currentData.Id) ? this.currentData.Id : '',
      labaccountid: Number(this.templateData.cardIdentifier),
      casetype: this.templateData.cardtype,
      testname: data.frmpanel,
      procedurename: data.frmprocedure,
      cptcodes: data.frmcptcodes,
      procedureid: Number(data.frmprocedureid),
      testid: frmtestid.length > 0 ? frmtestid[0].testid : data.frmtestid,
      oldtestid: this.currentData.testid,
      isfishtest: data.frmfishtest ?? false,
      testdescription: data.frmfishtest ? data.frmtestdescription : null,
      testtype: data.frmfishtest ? data.frmtesttype : null,
      isactive: data.frmactive ? 1 : 0,
      sequence: data.frmtestsequence,
      sequenceorder: data.frmsequence,
      tsequence: data.frmsequence,
      extnprocedureid: (this.action == 'edit') ? ((data.frmexnprocedureid == '') ? '' : data.frmexnprocedureid) : (data.frmexnprocedure && data.frmexnprocedure.length > 0) ? data.frmexnprocedure.toString() : '',
      services: data.frmservice,
      isadvanced: (this.action == 'edit') ? this.isAdvancedFlag : (data.frmservice || (data.frmsite && data.frmsite.length > 0) || (data.frmexnprocedure && data.frmexnprocedure.length > 0) || (data.frmorderingfacility && data.frmorderingfacility.length > 0)) ? true : false,
      siteid: (this.action == 'edit') ? ((data.frmsiteid == '') ? '' : data.frmsiteid) : (data.frmsite && data.frmsite.length > 0) ? data.frmsite.toString() : '',
      ofaccountid: (this.action == 'edit') ? ((!data.frmorderingfacilityid || data.frmorderingfacilityid == '') ? '-1' : data.frmorderingfacilityid) : (data.frmorderingfacility && data.frmorderingfacility.length > 0) ? this.getSelectedOrderingFacilities(data.frmorderingfacility,this.OFAccountsList) : '-1',
      createdby: !sessionStorage.getItem('Userid') ? -100 : sessionStorage.getItem('Userid'),
      exnprocedure: (data.frmexnprocedure && data.frmexnprocedure.length > 0) ? data.frmexnprocedure.toString() : '',
      site: (data.frmsite && data.frmsite.length > 0) ? data.frmsite.toString() : ''
    }]
    try {
      if (this.action.match(/create/i)) {
        this.ngxService.start();
        await this.VitalHttpServices.InsertDefaultStain(objArr, this.destDeployment).toPromise().then(response => {
          let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString() };
          let query = this.SubMenuCardModel.GetQuery("casetypedefaultprocstainlist");
          let queryResult = this.commonService.GetCardRequest(queryVariable, query);
          this.VitalHttpServices.GetData(queryResult).subscribe(data => {
            if (!data.errors) {
              if (data.data.submenuData && data.data.submenuData.length > 0) {
                this.panelDropdown = [];
                this.templateData['submenuData'] = data.data.submenuData;
                this.panelDropdown = [...new Map(this.templateData.submenuData.map(item =>
                  [item["Panel"], item])).values()];
                this.panelDropdown.sort((a, b) => a.Panel.toString().toLowerCase().trim() < b.Panel.toString().toLowerCase().trim() ? -1 : a.Panel.toString().toLowerCase().trim() > b.Panel.toString().toLowerCase().trim() ? 1 : 0);

              }
            }
          });
          let query1 = this.SubMenuCardModel.GetQuery("casetypedefaultstainlist");
          let queryResult1 = this.commonService.GetCardRequest(queryVariable, query1);
          this.VitalHttpServices.GetData(queryResult1).subscribe(data => {
            if (!data.errors) {
              this.dataList = data.data.submenuData;
              if (data.data.submenuData && data.data.submenuData.length > 0) {
                this.panelDropdown.push(...data.data.submenuData);
                let arr = [];
                arr = cloneDeep(this.panelDropdown)
                this.panelDropdown = []
                var flags = [], l = arr.length, i;
                for (i = 0; i < l; i++) {
                  if (flags[arr[i].Panel]) continue;
                  flags[arr[i].Panel] = true;
                  this.panelDropdown.push(arr[i]);
                }
              }
            }
          })
          this.ngxService.stop();
          this.auditData({}, objArr, 'Create');
          if (action == 'new') {
            if (response[0].OutputMessage.match(/Record Exist/i)) {
              this._snackbar.open("This combination of procedure and cptcodes already exists", "Close");
              return;
            }
            if (objArr[0].isadvanced && (this.templateData.submenuData && this.templateData.submenuData.length > 0)) {
              let dialogRef = this.dialog.open(ConfirmComponent, {
                disableClose: true,
                width: '500px',
                data: { header: "Note", message: "", alert: "Added stain will not reflect in the default stain list due to additional attributes!", continue: "OK", cancel: "dontshow" }
              });
            }
            else {
              this._snackbar.open("Default stain created", "Close");
            }
            this.formGroupDirective.resetForm();
            this.callResetForm();
            Object.keys(this.testForm.controls).forEach(key => {
              this.testForm.controls[key].setErrors(null)
            });
          }
          else {
            if (response[0].OutputMessage.match(/Record Exist/i)) {
              this._snackbar.open("This combination of procedure and cptcodes already exists", "Close");
              return;
            }
            if (objArr[0].isadvanced && (this.templateData.submenuData && this.templateData.submenuData.length > 0)) {
              let dialogRef = this.dialog.open(ConfirmComponent, {
                disableClose: true,
                width: '500px',
                data: { header: "Note", message: "", alert: "Added stain will not reflect in the default stain list due to additional attributes!", continue: "OK", cancel: "dontshow" }
              });
              return dialogRef.afterClosed().toPromise().then(result => {
                if (result) {
                  this.refreshGrid();
                }
              }, error => {
                console.error(error);
              });
            }
            else {
              this._snackbar.open("Default stain created", "Close");
              this.isAccountProcData = false;
              this.isAssociationProcdata = false;
              this.refreshGrid();
            }
            return;
          }
        }
        ).catch(error => this._snackbar.open("An error occurred while processing your request", "Failed"))
      }
      else {
        if (!this.validateFormdata()) {
          this.ngxService.start();
          let checkdbfordataexists = this.templateData.submenuData ? this.templateData.submenuData.filter(va =>
            va.Panel == objArr[0].testname
            && va.Procedure_Name == objArr[0].procedurename
            && va.CPT_Code == objArr[0].cptcodes
            && va.Sequence == objArr[0].sequence
            && va.Procedure_Sequence == objArr[0].tsequence
            && va.isactive == objArr[0].isactive
          ) : this.dataList ? this.dataList.filter(va =>
            va.Panel == objArr[0].testname
            && va.Procedure_Name == objArr[0].procedurename
            && va.CPT_Code == objArr[0].cptcodes
            && va.Sequence_Order == objArr[0].sequenceorder
            && va.Test_Sequence == objArr[0].sequence
            && va.isactive == objArr[0].isactive) : []
          if (this.templateData.submenuData && this.templateData.submenuData.some(va => va.Panel.toString().toLowerCase().trim() == data.frmpanel.toString().toLowerCase().trim()
            && va.Procedure_Name.toString().toLowerCase().trim() == data.frmprocedure.toString().toLowerCase().trim()
            && va.CPT_Code.toString().toLowerCase().trim() == data.frmcptcodes.toString().toLowerCase().trim() && va.Procedure_Id != data.frmprocedureid)) {
            checkdbfordataexists = 1
          }
          if (checkdbfordataexists.length == 0) {
            let checkChangeArray;
            if (!this.isAdvancedFlag) {
              checkChangeArray = objArr.filter(va =>
                va.procedurename == this.currentData.Procedure_Name &&
                va.testname == this.currentData.Panel &&
                va.cptcodes == this.currentData.CPT_Code &&
                va.sequence == this.currentData.TestSequence &&
                va.tsequence == this.currentData.Procedure_Sequence &&
                va.isfishtest == this.currentData.Fish_Test &&
                va.testdescription == this.currentData.Panel_Description &&
                va.testtype == this.currentData.Panel_Type
                && va.isactive == (this.currentData.Status == 'Active' ? 1 : 0)
              )
            } else {
              checkChangeArray = objArr.filter(va =>
                va.procedurename == this.currentData.Procedure_Name &&
                va.testname == this.currentData.Panel &&
                va.cptcodes == this.currentData.CPT_Code &&
                va.sequence == this.currentData.TestSequence &&
                va.sequenceorder == this.currentData.Sequence_Order &&
                va.isfishtest == this.currentData.Fish_Test &&
                va.testdescription == this.currentData.Panel_Description &&
                va.testtype == this.currentData.Panel_Type &&
                va.siteid == ((this.currentData.siteid == '') ? "" : this.currentData.siteid) &&
                va.extnprocedureid == ((this.currentData.extnprocedureid == '') ? "" : this.currentData.extnprocedureid) &&
                va.ofaccountid == ((this.currentData.OFAccountId == '') ? "" : this.currentData.OFAccountId) &&
                va.services == ((this.currentData.Services == '') ? "" : this.currentData.Services) &&
                va.isactive == (this.currentData.Status == 'Active' ? 1 : 0)
              )
            }
            if (checkChangeArray.length == 0) {
              let dataExists;
              let serviceFlag = false;
              if (this.isAdvancedFlag) {
                dataExists = this.dataList ? this.dataList.filter(
                  va => va.Panel == objArr[0].testname &&
                    va.Procedure_Name.toString().toLowerCase().trim() == objArr[0].procedurename.toString().toLowerCase() &&
                    va.CPT_Code.toString().toLowerCase().trim() == objArr[0].cptcodes.toString().toLowerCase().trim() &&
                    va.siteid == objArr[0].siteid &&
                    va.extnprocedureid == objArr[0].extnprocedureid &&
                    va.OFAccountId == objArr[0].ofaccountid &&
                    va.Id != this.currentData.Id && (va.Services == objArr[0].services || va.Services.toString().toLowerCase().trim() == 'all') ) : []

                    if(dataExists.length == 0){
                      if(objArr[0].services.toString().toLowerCase().trim() == 'all'){
                       dataExists = this.dataList ? this.dataList.filter(
                        va => va.Panel == objArr[0].testname &&
                          va.Procedure_Name.toString().toLowerCase().trim() == objArr[0].procedurename.toString().toLowerCase() &&
                          va.CPT_Code.toString().toLowerCase().trim() == objArr[0].cptcodes.toString().toLowerCase().trim() &&
                          va.siteid == objArr[0].siteid &&
                          va.extnprocedureid == objArr[0].extnprocedureid &&
                          va.OFAccountId == objArr[0].ofaccountid &&
                          va.Id != this.currentData.Id && va.Services.toString().toLowerCase().trim() != 'all') : []
                      }
                      if(dataExists.length > 0){
                        serviceFlag = true;
                      }
                    }
              } else {
                dataExists = this.templateData.submenuData ? this.templateData.submenuData.filter(
                  va => va.Panel == objArr[0].testname &&
                    va.Procedure_Name == objArr[0].procedurename &&
                    va.CPT_Code == objArr[0].cptcodes &&
                    va.Id != this.currentData.Id) : []
              }

              if (dataExists.length < 1) {
                await this.VitalHttpServices.UpdateDefaultStain(objArr, this.destDeployment).toPromise().then(response => {
                  this.ngxService.stop();
                  if (response.Success) {
                    this.auditData(this.currentData, objArr, 'Edit');
                    this._snackbar.open("Default Stain updated successfully", "Close")
                    this.currentData.Procedure_Name = objArr[0].procedurename
                    this.currentData.Panel = objArr[0].testname
                    this.currentData.testid = objArr[0].testid
                    this.currentData.CPT_Code = objArr[0].cptcodes
                    this.currentData.Sequence = objArr[0].sequence
                    this.currentData.Procedure_Sequence = objArr[0].tsequence
                    this.currentData.Procedure_Name = objArr[0].procedurename
                    this.currentData.Status = objArr[0].isactive == 1 ? 'Active' : 'Inactive'
                    this.action = "edit"
                    this.isAccountProcData = false;
                    this.isAssociationProcdata = false;
                    this.refreshGrid();
                  }
                  else {
                    this._snackbar.open("Default Stain update failed", "Close")
                  }
                }
                ).catch(error => {
                  console.error(error)
                  this._snackbar.open("An error occurred while processing your request", "Failed")
                })
              }
              else {
                this._snackbar.open("This combination of procedure and cptcodes already exists", "Close")
              }
            }
            else {
              this._snackbar.open("Please change at least one field before updating", "Close")
            }
          }
          else {
            this._snackbar.open("A stain with the same panel, procedure or CPT code already exists.", "Close")
          }
        }
      }
      this.ngxService.stop();
    }
    catch {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    }
  }

  validateFormdata() {
    let val = '';
    if (this.validateExtn) {
      val = 'Extraction Procedure'
    }
    else if (this.validateOF) {
      val = 'Ordering Facility'
    }
    else if (this.validateSite) {
      val = 'Site'
    }
    if (val) {
      this._snackbar.open('Please select ' + val + ' from dropdown', 'Close')
      return true
    } else {
      return false
    }
  }

  disableTestSeq(selectedValue, action) {
    if (action == '' || selectedValue.source._selected) {
      let value = selectedValue.source ? selectedValue.source.value : selectedValue;
      let panel = this.panelDropdown.filter(va => va.Panel.toString().toLowerCase().trim() === value.toString().toLowerCase().trim())
      if (!this.action.includes('edit')) {
        if (panel.length > 0) {
          this.testForm.patchValue({
            frmpanel: value,
            frmtestsequence: panel[0].Test_Sequence ? panel[0].Test_Sequence.toString() : panel[0].TestSequence ? panel[0].TestSequence.toString() : ""
          })
          if(panel[0].Test_Sequence){

            this.disableSequence = true;
          }
          else {
          this.disableSequence = false;
          }
        }
        else {
          this.testForm.patchValue({
            frmpanel: value,
            frmtestsequence: ""
          })
          this.disableSequence = false;
          this.formcontrol['frmtestsequence'].setErrors(null);
        }
      }
      else {
        if (panel.length > 0) {
          this.testForm.patchValue({
            frmpanel: value,
            frmtestsequence: (panel[0].Test_Sequence && panel[0].Test_Sequence.toString() != "") ? panel[0].Test_Sequence.toString() :
              (panel[0].TestSequence && panel[0].TestSequence.toString() != "") ? panel[0].TestSequence.toString() : ""
          })
        }
        this.readonlyTestSequence = false;
        this.formcontrol['frmtestsequence'].setErrors(null);
      }
    }
  }

  get formcontrol() {
    return this.testForm.controls;
  }

  clearSearch(event, dtype) {
    event.stopPropagation();
    if (dtype === 'OF') {
      this.testForm.patchValue({
        frmorderingfacility: '',
        frmorderingfacilityid: ''
      })
    }
    else if (dtype === 'Extn') {
      this.testForm.patchValue({
        frmexnprocedure: '',
        frmexnprocedureid: ''
      })
    }
    else if (dtype === 'Site') {
      this.testForm.patchValue({
        frmsite: '',
        frmsiteid: ''
      })
    }
  }

  filterOF(value) {
    if (value == '') {
      return !this.OFAccountsList ? [] : this.OFAccountsList;
    }
    for (let i = 0; i < this.OFAccountsList.length; i++) {
      if (this.OFAccountsList[i].displayname.toString().toLowerCase() === value.toString().toLowerCase()) {
        this.testForm.patchValue({
          frmorderingfacilityid: this.OFAccountsList[i].associatedaccountid
        })
        this.validateOF = false;
        break
      } else {
        this.validateOF = true;
        this.testForm.patchValue({
          frmorderingfacilityid: ''
        })
      }
    }
    return this.OFAccountsList.filter(va => va.displayname.toString().toLowerCase().includes(value.toLowerCase()));
  }

  filterExtnProcedure(value) {
    if (value == '') {
      return !this.extnProcedureList ? [] : this.extnProcedureList;
    }
    for (let i = 0; i < this.extnProcedureList.length; i++) {
      if (this.extnProcedureList[i].extnprocedurename.toString().toLowerCase() === value.toLowerCase()) {
        this.testForm.patchValue({
          frmexnprocedureid: this.extnProcedureList[i].extnprocedureid
        })
        this.validateExtn = false;
        break
      } else {
        this.validateExtn = true
        this.testForm.patchValue({
          frmexnprocedureid: ''
        })
      }
    }
    return this.extnProcedureList.filter(va => va.extnprocedurename.toString().toLowerCase().includes(value.toLowerCase()));
  }

  filterSite(value) {
    if (value == '') {
      return !this.sitesList ? [] : this.sitesList;
    }
    for (let i = 0; i < this.sitesList.length; i++) {
      if (this.sitesList[i].sitename.toString().toLowerCase().trim() === value.toLowerCase().trim()) {
        this.testForm.patchValue({
          frmsiteid: this.sitesList[i].siteid
        })
        this.validateSite = false;
        break
      }
      else {
        this.testForm.patchValue({
          frmsiteid: ''
        })
        this.validateSite = true
      }
    }

    return this.sitesList.filter(va => va.sitename.toString().toLowerCase().includes(value.toLowerCase()));
  }

  async refreshGrid(list?) {
    if (!list)
      this.formGroupDirective.resetForm();
    this.callResetForm();
    this.uploadClicked = false;
    this.showInvalidColumns = false;
    this.bulkData.length = 0;
    this.postUpload = false;
    this.gridPage = true;
    this.addEditClicked = false;
    this.action = '';
    this.showUploadGrid = false;
    this.ngxService.start();
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString() };
    let query = this.SubMenuCardModel.GetQuery("casetypedefaultprocstainlist");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);

    await this.VitalHttpServices.GetData(queryResult).toPromise().then(async data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.templateData['submenuData'] = [...data.data.submenuData];
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          let isactivestatuscount = await this.activestatuscheck(data.data.submenuData);
          if (isactivestatuscount) {
            this.isAccountProcData = true
            this.panelDropdown = [];
            this.panelDropdown = [...new Map(data.data.submenuData.map(item =>
              [item["Panel"], item])).values()];
            this.panelDropdown.sort((a, b) => a.Panel.toString().toLowerCase().trim() < b.Panel.toString().toLowerCase().trim() ? -1 : a.Panel.toString().toLowerCase().trim() > b.Panel.toString().toLowerCase().trim() ? 1 : 0);

            await this.getStainAssociationDetails();
            await this.addGridData(data.data.submenuData);
            this.isAdvancedFlag = false;
          } else {
            this.isAccountProcData = false
            this.panelDropdown = [];
            this.isAdvancedFlag = true;
            await this.getStainAssociationDetails();
          }
        }
        else {
          this.panelDropdown = [];
          this.templateData['submenuData'] = null;
          this.isAdvancedFlag = true;
          await this.getStainAssociationDetails();
        }

        if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
          if (!this.isAccountProcData && !this.isAssociationProcdata) {
            this.panelDropdown = [...new Map(this.templateData.submenuData.map(item =>
              [item["Panel"], item])).values()];
            this.panelDropdown.sort((a, b) => a.Panel.toString().toLowerCase().trim() < b.Panel.toString().toLowerCase().trim() ? -1 : a.Panel.toString().toLowerCase().trim() > b.Panel.toString().toLowerCase().trim() ? 1 : 0);
            //this.getStainAssociationDetails();
            await this.addGridData(this.templateData.submenuData);
            this.isAdvancedFlag = false;
          }
        }
        if (this.panelDropdown.length > 0) {
          const temp = cloneDeep(this.panelDropdown);
          this.panelDropdown = [];
          var flags = [], l = temp.length, i;
          for (i = 0; i < l; i++) {
            if (flags[temp[i].Panel]) continue;
            flags[temp[i].Panel] = true;
            this.panelDropdown.push(temp[i]);
          }
        }
      }
    },
      error => {
        this.ngxService.stop();
        console.error(error);
      });
  }

  async activestatuscheck(data) {
    let activestatuscount = 0;
    data.forEach(element => {
      if (element.Status == 'Active') {
        activestatuscount++
      }
    });
    if (activestatuscount > 0) {
      return true
    } else {
      return false
    }
  }
  onSelectionOption(event, obj, dtype) {
    if (event.source.selected) {
      if (dtype == 'OF') {
        this.testForm.patchValue({
          frmorderingfacilityid: obj.associatedaccountid
        })
      }
      else if (dtype === 'Site') {
        this.testForm.patchValue({
          frmsiteid: obj.siteid
        })
      }
      else if (dtype === 'Extn') {
        this.testForm.patchValue({
          frmexnprocedureid: obj.extnprocedureid
        })
      }
    }
  }

  callResetForm() {
    this.allSelectedSite = false;
    this.allSelectedExnProcedure = false;
    this.allSelected = false;
    this.moreHide = false;
    this.testForm.reset();
    this.testForm.patchValue({
      frmpanel: '',
      frmprocedure: '',
      frmcptcodes: '',
      frmtestsequence: '',
      frmsequence: '',
      frmactive: true,
      frmaadvanced: false,
      frmexnprocedure: '',
      frmservice: '',
      frmsite: '',
      frmorderingfacility: '',
      frmexnprocedureid: '',
      frmsiteid: '',
      frmorderingfacilityid: '',
      frmfishtest: false,
      frmtestdescription: 'ScreeningType',
      frmtesttype: ''
    })
  }

  //#region RBAC
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
  }
  //#endregion

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
          case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  //#endregion

  exportGrid(flex) {
    let tempHeader = flex.columns[0]._hdr;
    let filename = 'DefaultStains_' + this.templateData.secondarykeys.OrganizationId.toString() +
      '(' + this.templateData.secondarykeys.casetype + ')' +
      '.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    flex.columns.forEach((element, index) => {
      if (element._hdr == 'Action') {
        element._hdr = '';
      }
    });

    let wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true,
        includeColumns: function (column) {
          return (column.binding !== 'extnprocedureid' && column.binding !== 'siteid' && column.binding !== 'CaseType'
            && column.binding !== 'AccountId' && column.binding !== 'testid' && column.binding !== 'Procedure_Id' && column.binding !== 'OFAccountId' && column.binding !== 'Sequence')
        }
      },
      null,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
    flex.columns[0]._hdr = tempHeader
    wb.sheets[0].name = 'DefaultStains';
    wb.saveAsync(filename);
  }

  //#endregion disable edit button
  editEnabled(row) {
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      return true
    } else if (row._data.Ordering_Facilty && row._data.Ordering_Facilty.toString().toLowerCase() === 'all') {
      return false;
    }
    else {
      return true;
    }
  }




  showModal = false;
  dbName: any = sessionStorage.getItem('deploymentKey').toUpperCase();
  //dbName: any="UPM";
  configdefaultstaindata: any;



  gridColumns: any[] = [
    { header: 'Services', binding: 'SERVICES', width: '120' },
    { header: 'Site Name', binding: 'SITENAME', width: '120' },
    { header: 'Body Site', binding: 'PARENTSITENAME', format: 'n0', width: '*' },
    { header: 'Extn Procedure Name', binding: 'EXTNPROCEDURENAME', format: 'n0', width: '*' },
    { header: 'Of Location Name', binding: 'OFACCOUNTNAME', format: 'n0', width: '*' }
  ];


  getDefaultstainvalidator() {
    const accid = this.templateData.cardIdentifier.toString();
    const orgid = sessionStorage.getItem('org_id');
    const casetype = this.templateData.secondarykeys.casetype.toString();

    const obj = {
      "accid": accid,
      "orgnid": orgid,
      "casetype": casetype
    };
    this.ngxService.start();
    return this.VitalHttpServices.GetConfigDefaultStain(obj, this.dbName).subscribe(result => {
      if (result.Success) {
        var data = JSON.parse(result.Message);
        if (data[0]['OutputMessage'].toLowerCase() === 'success') {
          for (let i = 1; i < data.length; i++) {
            if (
              data[i]['EXTNPROCEDURENAME'] &&
              data[i]['ExtnProcedureID'] &&
              data[i]['SiteID'] &&
              data[i]['PARENTSITEID'] &&
              data[i]['SERVICES'] &&
              data[i]['PARENTSITENAME'] &&
              data[i]['SITENAME']
            ) {
              data[i]['EXTNPROCEDURENAME'] = data[i]['EXTNPROCEDURENAME'] + ' (' + data[i]['ExtnProcedureID'] + ')';
              data[i]['SITENAME'] = data[i]['SITENAME'] + ' (' + data[i]['SiteID'] + ')';
              data[i]['OFACCOUNTNAME'] = data[i]['OFACCOUNTNAME'] ? data[i]['OFACCOUNTNAME'] + ' (' + data[i]['OFAccountID'] + ')' : '';
              this.configdefaultstaindata = data;
            }
          }
          if (this.configdefaultstaindata === undefined || this.configdefaultstaindata.length === 0) {
            this.noDataFound = true;
            this.hideSaveBtn = false;
            this.showModal = true;
            this.message = "No Data Found";
          } else {
            this.hideSaveBtn = true;
            this.noDataFound = false;
            this.configdefaultstaindata1 = this.getLimitedData(this.configdefaultstaindata);
            if (this.configdefaultstaindata1.length > 0) {
              this.ngxService.stop();
              this.showModal = true;
            }
          }
        } else {
          this.noDataFound = true;
          this.hideSaveBtn = false;
          this.showModal = true;
          this.message = data[0]['OutputMessage'];
        }
        this.ngxService.stop();
      } else {
        this.ngxService.stop();
        this._snackbar.open('Request timed out', 'Close');
        console.error(result.Message)
      }
    },
      error => {
        this.ngxService.stop();
        console.error(error)
      });
  }

  getLimitedData(data: any[]): any[] {
    if (data) {
      return data.slice(1, 100);
    } else {
      return [];
    }
  }

  exportDataToExcel() {
    // Create a new workbook
    const workbook = { Sheets: {}, SheetNames: [] };
    this.ngxService.start();
    // Add the data from the grid to a worksheet
    for (let i = 0; i < this.configdefaultstaindata.length; i++) {
      delete this.configdefaultstaindata[i]["ExtnProcedureID"];
      delete this.configdefaultstaindata[i]["SiteID"];
      delete this.configdefaultstaindata[i]["PARENTSITEID"];
      delete this.configdefaultstaindata[i]["OFAccountID"];
      delete this.configdefaultstaindata[i]["OutputMessage"];
      const isEmptyRow = Object.values(this.configdefaultstaindata[i]).every(value => value === '');

      if (isEmptyRow) {
        this.configdefaultstaindata.splice(i, 1);
        i--; // Decrement i to account for the removed row
      }
    }

    const worksheet = XLSX.utils.json_to_sheet(this.configdefaultstaindata);
    //this.configdefaultstaindata = data;
    workbook.Sheets['Sheet1'] = worksheet;
    workbook.SheetNames.push('Sheet1');
    let filename = 'Default Stains_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "" + 'DefaultStainsValidator');
    XLSX.writeFile(wb, filename);
    this.ngxService.stop();
  }

  checkCaseType(caseType) {
    return caseType.toString().toLowerCase().includes('fish');
  }

  closePopup() {
    this.showModal = false;
  }

  uploadDefaultStain() {
    this.uploadClicked = true;
    this.addEditClicked = false;
    this.gridPage = false;
  }


  onFileChange(ev) {
    this.showUploadGrid = false;
    let workBook: any = {};
    let sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        sheetsToSelect = workBook.SheetNames;
        if (sheetsToSelect) {
          let sheetsToIgnore = ['sampledata', 'sites', 'extractionprocedures', 'orderingfacilities']
          sheetsToSelect = sheetsToSelect.filter(va => !sheetsToIgnore.some(val => val == va.toLowerCase().trim()));
          this.ngxService.start('upload');
          try{
            if (sheetsToSelect.length > 1) {
              let validSheet = sheetsToSelect.filter(va => va.toLowerCase() == 'procedureassociationdetails')
              sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0], workBook) : this.openModal(workBook, sheetsToSelect);
            }
            else {
              this.convertToJson(sheetsToSelect[0], workBook)
            }
          }
          catch {
            this.ngxService.stop('upload');
          }
          this.ngxService.stop('upload');
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef && this.fileDropRef.nativeElement ? this.fileDropRef.nativeElement.value = "" : null;
  }

  convertToJson(sheetname, workBook) {
    this.ngxService.start("tojson")
    try {
    var worksheet;
    workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = workBook.Sheets[e];
      }
    });
    this.bulkData = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0 || tempExcelArr.every(va => Object.values(va).every(val => val == ''))) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      this.ngxService.stop("tojson");
      return;
    }
    let primary = {}
    let tempArray = []
    tempExcelArr = tempExcelArr.filter(va => !Object.values(va).every(val => !val))
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          key = this.mapKeysToProcess(key);
          if (key.toString().match(/isactive/i)) {
            value = !(value === null || value === "") ? (value.toString().toLowerCase().trim() == '1' || value.toString().match(/true/i)) ? 1 : 0 : 1
          }
          else if (key.toString().match(/proceduresequence/i)) {
            if (value && value.toString() == '0') {
              value = 1;
            }
            else {
              value = value;
            }
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp = temp[0].reverse()
    temp.push("ResultMessage");
    temp.push("slno");
    this.sheetHeader = temp;
    // for (let i = 0; i < this.sheetHeader.length; i++) {
    //   if (this.sheetHeader[i]) {
    //     this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
    //   }
    // }
    this.bulkData = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.bulkData.find((d): any => {
      Object.assign(d, { slno: i, resultmessage: '', tablename: 'procedureassociationdetails', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), labaccountid: this.templateData.cardIdentifier, casetype: this.templateData.cardtype.toString(), userid: this.userid });
      i++;
    });
      this.validateExcel(this.bulkData);
    }
    catch (error) {
      console.error(error);
      this._snackbar.open('Data validation failed!', 'Close');
      this.removeGrid();
    }
    finally{
      this.ngxService.stop("tojson");
    }
  }

  async openModal(workBook, sheetsToSelect) {
    let result = await this.commonService.openMultisheetModal(sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result, workBook)
    }
  }

  async validateExcel(excelArr) {
    this.ngxService.start();
    await this.VitalHttpServices.validateDefaultStains(excelArr, this.destDeployment).toPromise().then(result => {
      this.ngxService.stop();
      if (result) {
        if (!Array.isArray(result)) {
          excelArr.forEach(va => va.resultmessage = 'Invalid columns found')
          this.mapToGrid(excelArr);
          // this.bulkData.length = 0;
          this.showInvalidColumns = true;
          this.invalidColumns = result.invalidcolumns
          return;
        }
        if (result.length > 0) {
          this.ngxService.stop();
          this.showDelete = true;
          this.mapToGrid(result);
          this.bulkData = result;
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
          this.bulkData.length = 0;
        }
      }
      else {
        this._snackbar.open("Please provide valid data", 'Failed');
        this.bulkData.length = 0;
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("Please provide valid data", 'Failed');
      this.bulkData.length = 0;
      console.error(error);
    });

  }

  mapToGrid(excelData) {
    this.ngxService.start("grid");
    let gridArray = [];
    excelData.forEach(va => {
      let primary = {};
      for (let [key, value] of Object.entries(va)) {
        key = this.mapKeysToDisplay(key);
        if (this.sheetHeader.some(va => va.toString().toLowerCase().trim() == key.toString().toLowerCase().trim())) {
          if (value == null) {
            value = '';
          }
          primary[key] = value
        }
      }
      gridArray.push(primary);
    })
    gridArray = this.convertObjKeysToLower(gridArray);
    this.bulkGridData = new CollectionView(gridArray, { pageSize: 10 });
    this.showUploadGrid = true;
    this.ngxService.stop("grid");
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.bulkData = this.bulkData.filter(va => va.slno != row.dataItem.slno);
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
  }
  //#endregion

  //#region Map keys to how api accepts them
  mapKeysToProcess(key) {
    let displayHeadersIndex = this.displayHeaders.findIndex(va => va.toString().toLowerCase().trim() == key.toString().toLowerCase().trim())
    if (displayHeadersIndex != -1) {
      key = this.mappedHeaders[displayHeadersIndex];
    }
    return key;
  }
  //#endregion

  //#region Map back to user keys
  mapKeysToDisplay(key) {
    let mappedIndex = this.mappedHeaders.findIndex(val => val.toString().toLowerCase().trim() == key.toString().toLowerCase().trim());
    if (mappedIndex != -1) {
      key = this.displayHeaders[mappedIndex];
    }
    return key;
  }
  //#endregion

  //#region  Function to cancel the data selection
  removeGrid() {
    this.bulkData.length = 0;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.showDelete = false;
    this.showUploadGrid = false;
    this.fileDropRef && this.fileDropRef.nativeElement ? this.fileDropRef.nativeElement.value = "" : null;
    this.bulkGridData = new CollectionView([]);
  }
  //#endregion

  async uploadDefaultStains() {
    let validData = this.bulkData.filter(va => va.resultmessage && va.resultmessage.match(/valid/i))
    if (validData.length == 0) {
      return;
    }
    if (validData.some(va => !va.id && va.extractionprocedureid)) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '300px',
        data: { header: "Alert", message: "", alert: "Added stain will not reflect in the default stain list due to additional attributes!", continue: "yes", cancel: "no" }
      })
      return dialogRef.afterClosed().toPromise().then(async (result) => {
        if (result) {
          await this.sendDataToUpload();
        }
        else {
          return false
        }
      });

    }
    else {
      this.sendDataToUpload();
    }
  }

  async sendDataToUpload() {
    this.ngxService.start();
    let action;
    if(this.bulkData.some(va=> va.id)){
      action = 'edit'
    }
    else {
      action = 'create'
    }
    for (let item of this.bulkData) {
      if (item.hasOwnProperty('resultmessage')) {
        item.notes = item.resultmessage;  // Add the new key 'notes'
        delete item.resultmessage;       // Remove the old 'resultmessage' key
      }
    }
    await this.VitalHttpServices.uploadDefaultStains(this.bulkData, this.destDeployment).toPromise().then(result => {
      if (result) {
        if (result.length > 0) {
          this.commonService.auditDetails('id', 'testname', [], result, 'Upload', this.templateData, this.auditableColumns);
          for (let item of result) {
            if (item.hasOwnProperty('notes')) {
              item.resultmessage = item.notes;  // Add the new key 'resultmessage'
              delete item.notes;               // Remove the old 'notes' key
            }
            if (item.hasOwnProperty('status')) {
              item.result = item.status;  // Add the new key 'result'
              delete item.status;               // Remove the old 'staus' key
            }
          }
          this.showDelete = false;
          this.postUpload = true
          this.sheetHeader.push('Result');
          this.mapToGrid(result);
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.error(error);
    });
    this.ngxService.stop();
  }


  createActivityTrackerForBulk(action,orginalData,result){
    let clonedResult = cloneDeep(result.filter(va=> va.resultmessage && va.resultmessage.toString().toLowerCase().match(/successfully/i)))

    if(action == 'create'){
      this._snackbar.open("Data has been uploaded successfully","Close");
      for(let i = 0; i< clonedResult.length;i++){
        this.commonService.createActivityTracker('Created', clonedResult[i]['id'], 'Default Stains', 'Audit',clonedResult[i], {});
      }
    }
    else {
      this._snackbar.open("Data has been updated successfully","Close");
      for(let i = 0; i< clonedResult.length;i++){
        this.commonService.createActivityTracker('Edited', clonedResult[i].attributeid, 'Default Stains', 'Audit', clonedResult[i],{});
      }
    }
  }

  disableApprove() {
    return this.bulkData && !this.bulkData.some(va => va.resultmessage && va.resultmessage.match(/valid/i))
  }

  //#regin to show notes with color
  addNoteColor(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    let column = e.panel["_cols"][e.col] ?._binding ?._key;
    if (column && !column.match(/resultmessage/i)) {
      return;
    }
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        let validMessages = ['valid', 'Default Stain created successfully', 'Default Stain updated successfully']
        if (!validMessages.some(va => va.toString().toLowerCase().trim() == value.toString().toLowerCase().trim())) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        // else if (value.toString().includes('exists')) {
        //   wjCore.toggleClass(e.cell, 'warn-value', true);
        // }
      }
      //  }
    }
  }
  //#endregion

  downloadTemplate(allFieldsCheck: boolean = false, update?) {
    if (update) {
      this.updateExcelTemplate();
    }
    else {
      this.uploadExcelTemplate(allFieldsCheck);
    }
  }

  updateExcelTemplate() {
    let columns = ["ID", "Active", "Sequence(Procedure)"]
    let excelSheetData = [];
    let sampleSheetData = [];
    columns.forEach(va => {
      let columnObject = {}
      let sampleDataObject = {};
      if (va.match(/active/i)) {
        columnObject["header"] = va
        // columnObject["value"] = [{ name: true }, { name: false }];
        sampleDataObject["header"] = va;
        sampleDataObject["value"] = "TRUE";
      }
      else {
        sampleDataObject["header"] = va;
        sampleDataObject["value"] = va == 'ID' ? "23456" : "50";
        columnObject["header"] = va;
        columnObject["value"] = "";
      }
      sampleSheetData.push(sampleDataObject);
      excelSheetData.push(columnObject);
    });
    var finalData = {
      "name": "procedureassociationdetails",
      "values": excelSheetData
    }
    var sampleData = {
      "name": "sampledata",
      "values": sampleSheetData
    }
    let workbookData = this.transform([finalData, sampleData]);
    let sheetname = 'DefaultStain_UpdateFields_' + this.templateData.cardIdentifier.toString();
    this.excelService.exportAsExcelFile(workbookData, sheetname);
  }

  async uploadExcelTemplate(allFieldsCheck) {
    let columns = ["Panel", "Procedure Name", "CPT Code", "Sequence(Panel)", "Sequence(Procedure)", "Active"];
    let columnData = { "Panel": "Cytology", "Procedure Name": "H & E", "CPT Code": "45679", "Extraction Procedure": "24", "Ordering Facility": "5", "Service": "All", "Site": "45", "Panel Description": "description", "Panel Type": "primary" }
    if (this.checkCaseType(this.templateData.secondarykeys.casetype)) {
      let additionalColumns = ["Fish Test", "Panel Description", "Panel Type"];
      columns = [...columns,...additionalColumns];
    }
    if (allFieldsCheck) {
      let additionalDetails = ["Extraction Procedure", "Ordering Facility", "Service", "Site"];
      columns = [...columns, ...additionalDetails];
    }
    let excelSheetData = [];
    let sampleSheetData = [];
    columns.forEach(va => {
      let columnObject = {}
      let sampleDataObject = {};
      if (va.match(/active/i)) {
        columnObject["header"] = va
        if (va.match(/Fish Test/i)) {
          columnObject["value"] = [{ name: true }, { name: false }];
        }
        sampleDataObject["header"] = va;
        sampleDataObject["value"] = true;
      }
      else {
        sampleDataObject["header"] = va;
        sampleDataObject["value"] = va == 'ID' ? "23456" : columnData[va] ? columnData[va] : "50";
        columnObject["header"] = va;
        columnObject["value"] = "";
      }
      sampleSheetData.push(sampleDataObject);
      excelSheetData.push(columnObject);
    });
    var sampleData = {
      "name": "sampledata",
      "values": sampleSheetData
    }
    let siteData = await this.getSiteData();
    let additionalData = await this.getAdditionalData();
    let extractionProcedureData = additionalData.OFData;
    let orderingFacilityData = additionalData.extProcList;
    let servicesList = additionalData.servicesList;
    let serviceIndex = excelSheetData.findIndex(va => va.header.toString().toLowerCase() == 'service')
    if (serviceIndex != -1 && servicesList && servicesList.length > 0) {
      excelSheetData[serviceIndex].value = []
      !excelSheetData[serviceIndex].value.some(va=> va.toString().toLowerCase() == 'all') ? excelSheetData[serviceIndex].value.push({ name: 'All' }) : null;
      servicesList.forEach(va => {
        excelSheetData[serviceIndex].value.push({ name: va.services })
      })
    }
    var finalData = {
      "name": "procedureassociationdetails",
      "values": excelSheetData
    }

    let transformData = allFieldsCheck ? [finalData, sampleData, siteData, extractionProcedureData, orderingFacilityData] : [finalData, sampleData]
    let workbookData = this.transform(transformData);
    let sheetname = 'DefaultStains_' + (allFieldsCheck ? "AllFields_" : "MinimalFields_") + this.templateData.cardIdentifier.toString();
    this.excelService.exportAsExcelFile(workbookData, sheetname);
  }


  async getAdditionalData() {
    this.ngxService.start();
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), accid: this.templateData.cardIdentifier.toString(), orgid: sessionStorage.getItem('org_id') };
    let query = this.SubMenuCardModel.GetQuery("getdefaultstainvalues");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let ofList = [];
    let extProcList = [];
    let servicesList = [];
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(async data => {
      this.ngxService.stop();
      if (!data.errors) {
        data.data.submenuDataOFs.forEach(va => {
          let OFDataObject = {};
          OFDataObject["ID"] =  va.associatedaccountid
          OFDataObject['Ordering Facility'] = va.displayname;
          ofList.push(OFDataObject);
        })

        data.data.submenuDataExtnProcedures.forEach(va => {
          let siteDataObject = {};
          siteDataObject["ID"] =  va.extnprocedureid
          siteDataObject['Extraction Procedures'] = va.extnprocedurename;
          extProcList.push(siteDataObject);
        })
        if(data.data.submenuDataExtnProcedures.length == 0)
        {
          let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString() };
        let queryKey = sessionStorage.getItem('deploymentKey').match(/thx/i) ? "casetypecollectionmethodmasterlistP4" : "casetypecollectionmethodmasterlistNonP4"
        let query = this.SubMenuCardModel.GetQuery(queryKey);
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.ngxService.start();
        await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(data => {
          this.ngxService.stop();
          if (!data.errors) {
            if (data.data.submenuData && data.data.submenuData.length > 0) {
              data.data.submenuData.forEach(d => {
                let siteDataObject = {};
                siteDataObject["ID"] =   d.ID
                siteDataObject["Extraction Procedures"] = d.Name;
                extProcList.push(siteDataObject);
              })
              // const ids = temp.map(({ extnprocedureid }) => extnprocedureid);
              // this.extnProcedureList = temp.filter(({ extnprocedureid }, index) => !ids.includes(extnprocedureid, index + 1));
            }
          }
        },
          error => {
            this.ngxService.stop();
            console.error(error);
          })
        }
        let array = [...data.data.submenuDataServices]
        if (array.length == 0)
          array = [...data.data.submenuDataServicesGolbal]
        array.sort((a, b) => a.services.toString().toLowerCase() < b.services.toString().toLowerCase() ? -1 : a.services.toString().toLowerCase() > b.services.toString().toLowerCase() ? 1 : 0);

        array.forEach(obj => {
          obj.services = obj.services.trim();
        });
        servicesList = array;
      }
    })
    let additionalDetails = {
      OFData: {
        "name": "orderingfacilities",
        "values": ofList
      },
      extProcList: {
        "name": "extractionprocedures",
        "values": extProcList
      },
      servicesList: servicesList
    }
    return additionalDetails;
  }
  async getSiteData() {
    this.ngxService.start();
    let dataList = [];
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.templateData.secondarykeys.OrganizationId.toString() };
    let query = this.SubMenuCardModel.GetQuery(this.siteFormat != 2 ? "bodysitesOnCasetype" : "subsitesoncasetype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(async data => {
      this.ngxService.stop();
      if (!data.errors) {
        data.data.submenuData.forEach(va => {
          let siteDataObject = {};
          siteDataObject["ID"] = va.SiteID
          if (this.siteFormat == 2) {
            siteDataObject["Sites"] = va.SubSite + '(' + va.BodySite + ')';
            // siteDataObject["value"] = va.SubSite + '(' + va.BodySite + ')';
          }
          else {
            siteDataObject["Sites"] = va.BodySite + ' (' + va.SiteID + ')';
            // siteDataObject["value"] = va.BodySite + ' (' + va.SiteID + ')';
          }
          dataList.push(siteDataObject);
        })
      }
    });
    var sitesData = {
      "name": "sites",
      "values": dataList
    }
    return sitesData;
  }

  transform(data) {
    return data.map(({ name, values }) => {
      let noOfRowaToGenerate = 200;
      if (name == 'sampledata') {
        noOfRowaToGenerate = 1;
      }
      else if (name == 'sites' || name == 'extractionprocedures' || name == 'orderingfacilities') {
        return {
          workSheet: name,
          rows: values
        }
      }
      const headers = values.reduce((prev, next) =>
        ({
          ...prev, [next.header]: Array.isArray
            (next.value) ? next.value.map(({ name }) => name) : next.value
        }), {})
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers)
      }
    })
  }

  downloadUploadedData(flex) {
    let excel = [];
    flex.rows.find(e => {
      let dataClone = cloneDeep(e._data);
      for (let [key] of Object.entries(dataClone)) {
        let index = this.sheetHeader.findIndex(va => va.toString().toLowerCase() == key.toString().toLowerCase());
        if (index != -1) {
          let changedKey = this.sheetHeader[index];
          dataClone[changedKey] = dataClone[key];
          delete dataClone[key];
        }
        delete dataClone['slno'];
        delete dataClone['tablename'];
      }
      excel.push(dataClone);
    });
    let filename = this.templateData.menuURL.toString() + '_' + this.templateData.cardIdentifier.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DefaultStains');
    XLSX.writeFile(wb, filename);
  }

  //#region Convert ObjKeys to lowercase
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.toString().toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  //#region Main function which creates audits w.r.t Create/Edit
  auditData(oldObj, newObj, actionString) {
    oldObj = this.getAuditableObj(oldObj);
    newObj = this.getAuditableObj(newObj, true);
    if(!this.isAdvancedFlag)
    {
      delete this.auditableColumns['extnprocedure'];
      delete this.auditableColumns['services'];
      delete this.auditableColumns['site'];
      delete this.auditableColumns['ofaccount'];
    } else {
      this.auditableColumns = this.auditableColumnsCopy;
    }
    this.commonService.auditDetails('id', 'testname', [oldObj], [newObj], actionString, this.templateData, this.auditableColumns);
  }
  //#endregion

  //#region Gets auditable columns and values in a object form based on input obj
  getAuditableObj(inputObj, isNewObj?:boolean) {
    inputObj = isNewObj ? inputObj[0] : inputObj;
    if(this.isAdvancedFlag)
    {
      let IDs = isNewObj ? (inputObj?.ofaccountid.toString().split(',')) : (inputObj.OFAccountId?.toString().split(','));
      inputObj['ofaccount'] = IDs ? this.OFAccountsList.filter(x => IDs.includes(x.associatedaccountid.toString())).map(e => e.displayname) :  null

      IDs = inputObj.extnprocedureid ? inputObj.extnprocedureid.toString().split(',') : [];
      inputObj['extnprocedure'] = (this.extnProcedureList && IDs) ? this.extnProcedureList.filter(x => IDs.includes(x.extnprocedureid.toString())).map(e => e.extnprocedurename) :  null

      IDs = inputObj.siteid ? inputObj.siteid.toString().split(',') : [];
      inputObj['site'] = (this.sitesList && IDs) ? this.sitesList.filter(x => IDs.includes(x.siteid.toString())).map(e => e.sitename) :  null
    }
    return {
      "id": isNewObj ? (inputObj.id ?? '') : (inputObj.Id ?? '')
      , "testname": isNewObj ? (inputObj.testname ?? '') : (inputObj.Panel ?? '')
      , "procedurename": isNewObj ? (inputObj.procedurename ?? '') : (inputObj.Procedure_Name ?? '')
      , "cptcodes": isNewObj ? (inputObj.cptcodes ?? '') : (inputObj.CPT_Code ?? '')
      , "sequence": isNewObj ? inputObj.sequence : inputObj.TestSequence ? inputObj.TestSequence : inputObj.Test_Sequence
      , "sequenceorder": isNewObj ? (inputObj.sequenceorder ?? '') : (this.isAdvancedFlag ? (inputObj.Sequence_Order ?? '') : (inputObj.Procedure_Sequence ?? ''))
      , "isactive": isNewObj ? (inputObj.isactive?.toString() == '1' || inputObj.isactive?.toString().toLowerCase() == 'active' ? 'Active' : 'Inactive') : (inputObj.Status?.toString() == '1' || inputObj.Status?.toString().toLowerCase() == 'active' ? 'Active' : 'Inactive')
      , "extnprocedure": (inputObj.extnprocedure ?? '')
      , "ofaccount": inputObj['ofaccount'] ? inputObj['ofaccount'].toString() : ''
      , "services": isNewObj ? (inputObj.services ?? '' ) : (inputObj.Services ?? '' )
      , "site": inputObj['site'] ? inputObj['site'].toString() : ''
    }
  }
  //#endregion

  //#region get configured Auditable columns to display in Activity Tracker
  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      if (res.content) {
        this.auditableColumns = JSON.parse(res.content.JsonData);
        this.auditableColumnsCopy = this.auditableColumns;
      }
    }, error => {
      console.error(error);
    })
  }
  //#endregion
}
