<div class="vdm-sidemenu" [class]="isExpand ? 'sidemenu-expanded' : 'sidemenu-collapsed'"  *ngIf="!(fullScreenMode$ | async)">
  <div class="d-flex justify-content-end" >
    <button mat-flat-button class="expand-side-bar" (click)="sideBarExpand()">
      <i class="fa fa-angle-double-right text-md" *ngIf="!isExpand" aria-hidden="true"></i>
      <i class="fa fa-angle-double-left text-md" *ngIf="isExpand" aria-hidden="true"></i>
    </button>
  </div>

  <ul class="nav flex-column vdm-nav" *ngIf="isExpand" >
    <ng-container *ngFor="let item of submenuList;let i=index;">
      <li class="nav-item px-2 mb-1" *ngIf="item.Htext !== undefined && item.SubMenu.length == 0 " (click)="sideMenuSelected(item)">
        <a class="nav-link  px-2" *ngIf="item.Htext?.toLowerCase() !== 'manage tests/stains'" [ngClass]="newValue?.routerLink == item.routerLink ? 'active' : 'inactive'">{{item.Htext|titlecase}}
        </a>
        <a class="nav-link  px-2" *ngIf="item.Htext?.toLowerCase() === 'manage tests/stains'" [ngClass]="newValue?.routerLink == item.routerLink ? 'active' : 'inactive'">{{item.Htext}}
        </a>
      </li>
      <span *ngIf="item.Htext !== undefined && item.SubMenu.length>0">
        <div class="subMenuHeading text-muted _600 ml-3 mb-1" style="font-size:13px;"> {{item.Htext | uppercase}} </div>
        <div class="ml-3">
          <ng-container *ngFor="let list of item.SubMenu;let j = index;">
            <li class="nav-item px-2 mb-1" *ngIf="list.URL !== undefined" (click)="sideMenuSelected(item.SubMenu[j])">
              <a class="nav-link  px-2" [ngClass]="newValue?.routerLink == list.routerLink ? 'active' : 'inactive'">{{list.URL|titlecase}}
              </a>
            </li>
          </ng-container>
        </div>
      </span>
    </ng-container>
  </ul>
</div>
