<span class="icd-code">
<div *ngIf="gridShow">
    <div class="row">
        <div class="col-md-12">
            <div class="modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>ICD CODES</strong>
                </h3>
            </div>
        </div>
    </div>
    <br>
    <div class="col-lg-12 p-0 m-md-1 m-lg-0">
        <!-- the grid -->
        <wj-flex-grid [headersVisibility]="'Column'" [filterColumns]="filterColumns" #flex [(itemsSource)]="gridData"
            (initialized)="initializeGrid(flex)" [isReadOnly]="true">
            <wj-flex-grid-column [binding]="'buttons'" class="cursor" [header]="'Actions'" [width]="120"
                [minWidth]="120" align="center"></wj-flex-grid-column>
                <wj-flex-grid-column [binding]="'organizationicd9codeid'" [visible]="false" [header]="'ID'" [isRequired]="true" [width]="120"
                [minWidth]="120" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'Sequence'" [header]="'Sequence'" [isRequired]="true" [width]="120"
                [minWidth]="120" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'ICD9Code'" [allowSorting]="true" [header]="'ICD Code'" [isRequired]="true"
                [width]="220" [minWidth]="220"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'ICD9CodeName'" [selectionMode]="'None'" [isRequired]="true"
                [header]="'Code Name'" [width]="620" [minWidth]="620"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'CreatedBy'" [header]="'CreatedBy'" [visible]="false" [isRequired]="true" [width]="120"
                [minWidth]="120" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'CreatedDate'" [header]="'CreatedDate'" [visible]="false" [isRequired]="true" [width]="120"
                [minWidth]="120" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'ModifiedBy'" [header]="'ModifiedBy'" [visible]="false" [isRequired]="true" [width]="120"
                [minWidth]="120" [format]="'d'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'ModifiedDate'" [header]="'ModifiedDate'" [visible]="false" [isRequired]="true" [width]="120"
                [minWidth]="120" [format]="'d'"></wj-flex-grid-column>

            <wj-flex-grid-filter [filterColumns]="filterColumns" #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <!-- template for buttons on items in view mode -->
        <div id="tplBtnViewMode" style="display:none">
            <div [disabled]="editBtn" id="btnEdit" class="btn btn-sm edit-delete-btn">
                <em class="fa fa-edit" aria-hidden="true"></em>
            </div>
            <div [disabled]="deleteBtn" id="btnDelete" class="btn btn-sm edit-delete-btn">
                <em class="fa fa-trash-o"></em>
            </div>
            <div>

                <!-- template for buttons on items in edit mode -->
                <div id="tplBtnEditMode" style="display:none">
                    <div id="btnOK" class="btn btn-sm cursor">
                        <em class="fa fa-check" id="btnOK" aria-hidden="true" style="color: green;"></em>
                    </div>
                    <div id="btnCancel" class="btn btn-sm cursor">
                        <em class="fa fa-times" id="btnCancel" aria-hidden="true" style="color: red;"></em>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 mt-4 align-center">
        <div class="row button-align">
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridShow" [disabled]="exportBtn"
                type="button" (click)="ExportExcel(flex)">Export</button>
            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="createBtn" type="button"
                (click)="insertFlag()">Create</button>
            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="uploadBtn" type="button"
                (click)="loadUploadScreen()">Upload</button>
            <button [disabled]="copyBtn" title="Copy From Other Entity" [disabled]="copyBtn" mat-raised-button
                class="admin-btn-success" (click)="loadCopyScreen()">
                Copy
            </button>
        </div>
    </div>

</div>
<div class="col-sm-12" *ngIf="noDataFound">
    <div class="row">
        <div class="col-md-12">
            <div class="modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>ICD CODES</strong>
                </h3>
            </div>
        </div>
    </div>
    <br>
    <div class="row container create-btn align-center">
        <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>
    <div class="col-12 mt-4 align-center">
        <div class="row button-align">

            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="createBtn" type="button"
                (click)="insertFlag()">Create</button>
            <button mat-raised-button class="admin-btn-success mr-4" [disabled]="uploadBtn" type="button"
                (click)="loadUploadScreen()">Upload</button>
            <button [disabled]="copyBtn" title="Copy From Other Entity" [disabled]="copyBtn" mat-raised-button
                class="admin-btn-success" (click)="loadCopyScreen()">
                Copy
            </button>
        </div>
    </div>
</div>
<div *ngIf="enableInsert">
    <div class="col-sm-12 m-0 p-0">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Create Indicative ICD Code</strong>
            </h3>
        </div>
        <form [formGroup]="addIcdCode" autocomplete="off">
            <div class="text-wrap-icdcode row col-sm-12 p-0 ml-0">
                <div class="row col-sm-12 mt-2 mb-4">
                    <mat-form-field appearance="outline" class="col-sm-4">
                        <mat-label><strong>ICD Code</strong></mat-label>
                        <input trim matInput formControlName="icdCode">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4">
                        <mat-label><strong>Name</strong></mat-label>
                        <input trim matInput formControlName="icdName">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4">
                        <mat-label>Sequence</mat-label>
                        <input appBlockCopyPaste (keypress)="AllowOnlyNumber($event)" matInput formControlName="sequence" maxlength="4">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-3">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">
                        Back
                    </button>
                </div>
                <div class="col-sm-9 button-wrap">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="saveAndClose()">
                        Save & Close
                    </button>
                    <button mat-raised-button class="admin-btn-success" (click)="saveAndCreate()">
                        Save & Create
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <!-- <strong>{{action}}</strong> -->
            <strong>Copy ICD CODES</strong>
        </h3>
    </div>
    <form [formGroup]="copyIcdMethodForm" class="copy-collection">
        <div class="p-0 ml-0">
            <div class="col-sm-12 text-wrap-icdcode">
                <mat-form-field class="col-sm-4 mt-2 mb-4 example-additional-selection" appearance="outline">
                    <mat-label>Deployment</mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4  mt-2 mb-4 example-additional-selection" appearance="outline">
                    <mat-label>Group <span class="error-msg">*</span></mat-label>
                    <input formControlName="frmOrganization" type="text" matInput #searchbar
                    (click)="fetchOrgSeries(copyIcdMethodForm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyIcdMethodForm.value.frmOrganization); trimFieldValue('frmOrganization')" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                    <!-- <em class="fa fa-chevron-down chevron-align drop-down"></em> -->
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                title="{{ show.orgname }} ({{show.orgid}})"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                value="{{ show.orgname }}">
                                <span>{{ show.orgname }} ({{show.orgid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                    <button matSuffix mat-icon-button (click)="fetchOrgSeries(copyIcdMethodForm.value.frmOrganization)">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                </mat-form-field>
            </div>

            <div *ngIf="gridwidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.result=='Success' ?'green':cell.item.result=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.result}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>


                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                        [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span [ngStyle]="{color: cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' ?'red': '#ff9800' }">{{cell.item.notes}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="modifyColumnName(columnname)"
                            [visible]="!(columnname == 'organizationid') && !(columnname == 'casetype') &&
                            !(columnname == 'icd9codedescription') && !(columnname == 'organizationicd9codeid') && !(columnname == 'icdstandard') &&
                            !(columnname == 'organizationicd9codeguid') && !(columnname == 'slno') && !(columnname == 'tablename') && !(columnname == 'resultmessage')"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

            </div>
            <div class="col-sm-12 pl-0 mt-4">

                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0"
                    (click)="refreshGrid('list')">Back</button>

                <div *ngIf="gridwidth > 0" class="m-1 p-0">
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
                        (click)="gridRemove()">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload"
                            (click)="refreshGrid('list')">Cancel</button>
                        <!-- <div *ngIf="!btnDisable"> -->
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload"
                            [disabled]="approveDisable()" (click)="approveICDCollection(true)">Approve</button>
                        <button mat-raised-button class="admin-btn-success" *ngIf="postUpload"
                            (click)="ExportExcel(flexgrid)">Download
                            Result</button>
                    </span>
                </div>
            </div>

            <div class="col-sm-12" *ngIf="gridwidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noData">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <!-- <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">Back</button> -->
            </div>
        </div>
    </form>
</div>

<!-- Upload  -->
<div *ngIf="uploadClicked" class="my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload ICD CODES</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('mandatoryFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('allFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate('updateCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload {{templateData.menuURL}}.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
            Back
        </button>
    </div>

    <div *ngIf="gridwidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
            [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">


            <!-- Status -->
            <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.result=='Success' ?'green':cell.item.result=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.result}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname" [visible]="!(['ICD9CodeName','ICD9Code','ICD9CodeDescription','ICDStandard'].includes(columnname))" [allowResizing]="true" [width]="'*'" [allowDragging]="false" [format]="'d'">
                </wj-flex-grid-column>
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]="!(['OrganizationICD9CodeId','Sequence','ResultMessage'].includes(columnname))" [allowResizing]="true" [width]="'*'"
                    [allowDragging]="false">
                </wj-flex-grid-column>
            </div>
            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete && !postUpload" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
                    (click)="gridRemove()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0"
                    (click)="refreshGrid('list')">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                    (click)="refreshGrid('list')">Cancel</button>
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                    [disabled]="approveDisable()" (click)="approveICDCollection()">Approve</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                    (click)="refreshGrid('list')">Finish</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                    (click)="ExportExcel(grid)">Download
                    Result</button>

            </div>
        </div>


    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
</span>
