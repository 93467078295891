import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChange } from '@angular/core';
import * as XLSX from 'xlsx';
import cloneDeep from 'lodash';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { searchFilterPipetemplate } from '../../core/utility/tripfilter.pipe';
import { CommonService } from 'src/app/core/services/commonservices';
import { MatDialog } from '@angular/material/dialog';
import { MenuFlagsConfigurationComponent } from '../menu-flags-configuration/menu-flags-configuration.component';

import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { convertLocalDateTime } from '../../../app/labadmin/components/Utility Functions';
@Component({
  selector: 'app-templates-listing2',
  templateUrl: './templates-listing2.component.html',
  styleUrls: ['./templates-listing2.component.scss'],
  providers: [searchFilterPipetemplate]
})

export class TemplatesListing2Component implements OnInit {
  searchText: string = "";
  templatefilter: object = {};
  gridheader: any[];
  GridData: any[] = [];
  backupGridData: any[];
  groupByParam: any[];
  groupdata: any[];
  groupByKeys = [];
  context: any;
  filename: any;
  ActiveGridData = [];
  InactiveGridData = [];
  ActiveCount = 0
  InactiveCount = 0
  AllCount = 0
  expandCollapse: boolean[] = [];

  @Input()
  sortAZ: boolean = true;

  @Input()
  dragDrop: boolean = false;


  @Input()
  siteLabelAlphabetFlag: boolean = false;

  @Input() gridHeader
  organizationId: any;
  dragDropData: any;
  isGroupLevelTabs: boolean;
  showGrid: boolean;
  listBackupValue: any;
  @Input("context")
  set _context(value: any) {
    this.filename = value
    this.context = 'Search ' + value
    if(value === 'Rule Outs')
      {
        this.context = 'Search ' + value + ' or Sites'
      }
  }
  @Input() hideDownload
  @Input() hideDragDrop
  @Input() hideEdit
  @Input() hideCreate
  @Input() hideExport
  @Input() hideUpload
  @Input() hideCopy
  @Input() hideDelete
  @Input() groupBy = [];
  @Input() templateData
  @Input() isAdmin3 = true;
  @Input() caseTypeList : any[];
  @Input() selectedCaseType : any;
  @Input() exportExcelFields: any;
  @Input() showHeaderModule = true;
  @Input() searchBarWidth: any;
  @Input() hideActiveCount: boolean = false;
  @Input() isAllTab: boolean = true;
  @Input() isSequenceType: boolean = false;
  @Input() uniqueKey : string;

  @Output()
  copyEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  createEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  editEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  deleteEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  gridRefreshEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  dragDropEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  downloadEmitter: EventEmitter<any> = new EventEmitter();
  //site order changes
  @Output()
  toggleChangeEmitter : EventEmitter<any> = new EventEmitter();
  @Output()
  manageSiteOrderEventTriggered : EventEmitter<any> = new EventEmitter();
  @Output() emitFilters = new EventEmitter<any>();


  @Input("gridData")
  set _gridData(value: any) {
    this.GridData = value._view;
    this.forceUpdateGridData();
  }
  showHelpMessage: boolean = false;
  searchCaseType:string='';

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;


  constructor(private ngxService: NgxUiLoaderService,private  searchfiltersTemplate : searchFilterPipetemplate, public commonService : CommonService,
    private dialog: MatDialog,private labAdminService : LabadminService
   ) { }

  ngOnInit(): void {

    if(this.groupBy?.length>1){
      this.groupDataByProperty(this.GridData,'Sitescheme');
    }
    this.ActiveCount = 0
    this.InactiveCount = 0
    this.AllCount = 0
    this.ActiveGridData = [];
    this.InactiveGridData = [];
    // let groupData = this.getGroupData(this.GridData,this.groupBy);
    this.isGroupLevelTabs = this.templateData ?.menuURL.toString().toLowerCase() == 'templates' ? true : false;
    this.showGrid = false;
    this.backupGridData = this.GridData;
    try {
      this.ngxService.start("initLoad");
      if (this.isGroupLevelTabs) {
        this.selectOrgLevel();
      } else {
        this.alterGridData()
        if (!this.dragDrop) {
          if(this.context!='Search Site Order')
            {
              this.selectButton('All');
            }
        }
        else {
          this.selectButton('Active')
        }
      }
      this.templatefilter = {};
    }
    catch (error) {
      console.error(error);
    }
    finally {
      this.showGrid = true;
      setTimeout(()=> {
        this.filtertemplates();
        this.ngxService.stop("initLoad");
      },500)
    }
  }

//Genric Method for the Group
  groupDataByProperty(data: any[], propertyName: string) {
    const groupedData = [];
    data.forEach(parent => {
        const groupedItems = {};
        parent.DataList.forEach(child => {
            const propertyValue = child[propertyName] || 'Default'; // Get the value of the specified property
            if (!groupedItems[propertyValue]) {
                groupedItems[propertyValue] = [];
            }
            groupedItems[propertyValue].push(child);
        });
        const groupedParent = { ...parent, GroupedDataList: groupedItems };
        groupedData.push(groupedParent);
    });
    this.GridData = groupedData;
 }
  ngOnChanges(changes:any) {
    if(this.groupBy?.length>1){
      this.groupDataByProperty(this.GridData,'Sitescheme');
    }
    if(changes.selectedCaseType)
    {
      this.searchCaseType = this.selectedCaseType;
      this.selectedCaseType = this.selectedCaseType ? this.selectedCaseType : changes.selectedCaseType?.previousValue;
      this.searchCaseType= !this.isAdmin3?this.caseTypeList.find(i=>i.CaseType==this.selectedCaseType)?.CasetypeDisplayName:this.selectedCaseType

    }
    // this.ActiveCount = 0
    // this.InactiveCount = 0
    // this.AllCount = 0
    // this.ActiveGridData = [];
    // this.InactiveGridData = [];

    // this.isGroupLevelTabs = this.templateData ?.menuURL.toString().toLowerCase() == 'templates' ? true : false;
    // this.showGrid = false;
    // this.backupGridData = this.GridData;
    // try {
    //   this.ngxService.start("initLoad");
    //   if (this.isGroupLevelTabs) {
    //     this.selectOrgLevel();
    //   } else {
    //     this.alterGridData()
    //     if (!this.dragDrop) {
    //       if(this.context!='Search Site Order')
    //         {
    //           this.selectButton('All');
    //         }
    //     }
    //     else {
    //       this.selectButton('Active')
    //     }
    //   }
    //   this.templatefilter = {};
    // }
    // catch (error) {
    //   console.error(error);
    // }
    // finally {
    //   this.showGrid = true;
    //   setTimeout(()=> {
    //     this.filtertemplates();
    //     this.ngxService.stop("initLoad");
    //   },500)
    // }
  }

  // ALTER METHOD USED INSTEAD OF NGONCHANGES
  forceUpdateGridData() {
    this.isGroupLevelTabs = this.templateData ?.menuURL.toString().toLowerCase() == 'templates' ? true : false;
    this.ActiveCount = 0
    this.InactiveCount = 0
    this.AllCount = 0
    this.ActiveGridData = [];
    this.InactiveGridData = [];
    this.backupGridData = this.GridData;
    if (this.isGroupLevelTabs) {
      this.selectOrgLevel();
    } else {
      this.alterGridData()
      if (!this.dragDrop) {
        this.selectButton('All')
      }
      else {
        this.selectButton('Active')
      }
    }
  }

  selectedButton: string | null = null;

  alterGridData() {
    for (let i = 0; i < this.backupGridData?.length; i++) {
      let statusKey = "";
      for (let j = 0; j < this.backupGridData[i].DataList.length; j++) {
        for (const [key, value] of Object.entries(this.backupGridData[i].DataList[j])) {

          if (key === 'IsActive' || key === 'Active' || key === 'Status') {
            statusKey = key;
            if (value == 'Yes' || value == true || value == 'Active') {
              this.backupGridData[i].DataList[j][key] = 1;
            } else {
              this.backupGridData[i].DataList[j][key] = 0;
            }
          }
        }
      }
      let gridData: { Name?: string, DataList?: Array<object> } = {};
      let activeData = JSON.parse(JSON.stringify(this.getModdedData(this.backupGridData[i].DataList, statusKey, 1)));
      if (activeData.length) {
        gridData.Name = this.backupGridData[i].Name;
        gridData.DataList = activeData;
        this.ActiveGridData.push(gridData);
      }
      let inactiveData = JSON.parse(JSON.stringify(this.getModdedData(this.backupGridData[i].DataList, statusKey, 0)));
      if (inactiveData.length) {
        gridData = {};
        gridData.Name = this.backupGridData[i].Name;
        gridData.DataList = inactiveData;
        this.InactiveGridData.push(gridData);
      }
    }
    this.dragDropData = JSON.parse(JSON.stringify(this.ActiveGridData));
    this.AllCount = this.getDataCount(this.backupGridData)
    this.ActiveCount = this.getDataCount(this.ActiveGridData)
    this.InactiveCount = this.getDataCount(this.InactiveGridData)
  }

  getDataCount(gridData) {
    let count = 0;
    if(!gridData)
    return 0;
    gridData.forEach(va => {
      count += va.DataList.length;
    })
    return count;
  }

  getModdedData(dataList, key, value) {
    return dataList.filter(object => object[key] == value);
  }

  selectButton(buttonId: string): void {
    this.searchText = "";
    this.templatefilter = {};
    this.selectedButton = buttonId
    this.expandCollapse.length = 0;
    // if(buttonId != 'All'){
    //   if(buttonId == 'Active'){
    //     this.InactiveGridData.length ? this.GridChange(buttonId) : null;
    //   }
    //   else {
    //     this.ActiveGridData.length ? this.GridChange(buttonId) : null;
    //   }

    // }
    // else {
      this.GridChange(buttonId)
    // }
    }

  displayedColumns(item: any, header: any, isToolTipKey?:boolean) {
    const headerKey = isToolTipKey ? (header.toolTipKey ? header.toolTipKey : header.key) : header.key;
    return item[headerKey];
  }

  GridChange(val) {
    let activeGridSelection = ['Active', 'Org-Level'];
    let inactiveGridSelection = ['InActive', 'Location-Level'];
    if (activeGridSelection.includes(val)) {
      this.GridData = this.ActiveGridData
    } else if (inactiveGridSelection.includes(val)) {
      this.GridData = this.InactiveGridData
    } else {
      // this.GridData = (this.ActiveCount > 0 || this.InactiveCount > 0) ? this.ActiveGridData.concat(this.InactiveGridData) : this.backupGridData;
      this.GridData = this.backupGridData;
    }
    this.GridData = [...this.GridData];
    // if (this.viewPort)
    //   this.viewPort.scrollToIndex(0, 'auto');
  }

  handleSearch = this.debounce(this.filtertemplates,1000);
  debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  filtertemplates() {
    for (let i = 0; i < this.gridHeader?.length; i++) {
      this.templatefilter[this.gridHeader[i].key] = this.searchText
    }
    if (this.viewPort){
      this.viewPort.scrollToIndex(50, 'auto');
        setTimeout(() => {
          this.viewPort.scrollToIndex(0, 'auto');
        }, 500);
    }
    // this.templatefilter['AttributeName'] = this.searchText;
    // this.templatefilter['AttributeDescription'] = this.searchText;
    // this.templatefilter.CaseType = this.searchText;
    // this.templatefilter.OFOrgName = this.searchText;
    // this.templatefilter.OrderingFacility = this.searchText;
    // this.templatefilter.Status = this.searchText;
    // this.templatefilter.VersionNumber = this.searchText;
  }

  filter(e, Name) {
    if (this.backupGridData.length > 0) {
      this.GridData = this.backupGridData.filter(va => va[Name].toLowerCase().includes(e.target.value))
    }
  }

  exportData() {
    const filteredData = this.GridData
        .map(parentItem => ({
            ...parentItem,
            DataList: this.searchfiltersTemplate.transform(parentItem.DataList, this.templatefilter)
        }))
        .filter(parentItem => parentItem.DataList.length > 0);

    if(filteredData.length >0){
      (this.filename?.toLowerCase() == 'sequence scheme') ?  this.downloadEmitter.emit(filteredData) : this.ExportExcel(filteredData);
    }
}
  // Export Excel with file Name and sheet name
  ExportExcel(flex) {
    let orgid = sessionStorage.getItem('org_id') && sessionStorage.getItem('org_id') != '' ? sessionStorage.getItem('org_id') : this.labAdminService.organizationId
    let filename = this.filename;
    if (this.isAdmin3) {
      filename += '_' + orgid;
    }
    filename += '.xlsx';
    let excel = flex[0]?.DataList;
    if (this.groupBy?.length > 0) {
      excel = this.getExtractedData(flex);
    }
    let ws;
    if (this.exportExcelFields && Object.keys(this.exportExcelFields).length > 0) {
      // Filter and map data based on exportExcelFields
      const exportData = excel.map(item => {
        let filteredItem = {};
        for (let key in this.exportExcelFields) {
          if (item[key] !== undefined) {
            filteredItem[this.exportExcelFields[key]['displayName']] = this.exportExcelFields[key]['isDate'] ? (this.exportExcelFields[key]['isDate'] && item[key] !== 'Not Specified') ? convertLocalDateTime(item[key]) : item[key] || 'Not Specified' : item[key] || 'Not Specified';
          }
        }
        return filteredItem;
      });
      ws = XLSX.utils.json_to_sheet(this.searchfiltersTemplate.transform(exportData, this.templatefilter));
    } else {
      ws = XLSX.utils.json_to_sheet(this.searchfiltersTemplate.transform(excel, this.templatefilter));
    }
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + filename);
    XLSX.writeFile(wb, filename);
  }

  clearFilterClicked($event: any, field: any) {
    // setTimeout(() => {
      if(!this.listBackupValue) {
        this.listBackupValue = field;
        this.searchCaseType = '';
        $event.stopPropagation();
      }
    // }, 100);
  }

  // Clear search bar
  clearSearch() {
    this.searchText = '';
    this.filtertemplates();
  }

  autoCompletePanelClosed(field: any) {
    // setTimeout(() => {
      if(!this.searchCaseType) {
        let tempName = this.listBackupValue;
        this.searchCaseType = tempName;
        this.listBackupValue = null;
      }
    // }, 100);
  }

  getExtractedData(gridData) {
    let dataList = [];
    gridData.forEach(va => {
      dataList = [...dataList, ...va.DataList];
    })
    return dataList;
  }

  getDataList(parentItem) {
    // if(this.groupBy.length){
    return parentItem.DataList;
    // }
    // return this.GridData;
  }
  //site Order list
  getDataSchemaList(parentItem)
  {
    return parentItem.GroupedDataList;
  }


  toggle(index) {
    if (!this.dragDrop)
      this.expandCollapse[index] = !this.expandCollapse[index];
  }

  copyFrmEntity() {
    this.copyEmitter.emit();
  }

  create(bulkUpload) {
    this.createEmitter.emit({ bulkUpload: bulkUpload });
  }

  toggleChange(event,value){
    this.toggleChangeEmitter.emit(value);
  }

  editMethod(item) {
    this.editEmitter.emit(item);
  }

  delete(event) {
    this.deleteEmitter.emit(event);
  }

  manageClicked() {
    this.manageSiteOrderEventTriggered.emit();
  }


  toggleDragDrop() {
    this.selectButton("Active");
    this.dragDrop = !this.dragDrop;
    this.expandCollapse = [];
  }

  returnToGrid() {
    this.dragDrop = !this.dragDrop;
    this.gridRefreshEmitter.emit();
  }


  dropItem(event) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    let emitData = {
      data: event.container.data
    }
    this.dragDropEmitter.emit(emitData)
  }


  resetDraggedItems() {
    this.GridData = JSON.parse(JSON.stringify(this.dragDropData));
    this.dragDropEmitter.emit({ reset: true })
  }

  checkListChanges() {
    return JSON.stringify(this.dragDropData) == JSON.stringify(this.GridData) ? false : true;
  }

  saveDragDrop(sortAZ?) {
    this.dragDropEmitter.emit({ save: true, sortAZ: sortAZ })
  }

  downloadMethod(item) {
    this.downloadEmitter.emit(item);
  }

  //#region Used for VD Template
  filterOrgOrLocationLevel() {
    for (let i = 0; i < this.backupGridData.length; i++) {
      let gridData: { Name?: string, DataList?: Array<object> } = {};
      let orgLevelData = JSON.parse(JSON.stringify(this.backupGridData[i].DataList.filter(x => !x.Location)));
      let locationLevelData = JSON.parse(JSON.stringify(this.backupGridData[i].DataList.filter(x => x.Location != null && x.Location != '')));
      if (orgLevelData.length) {
        gridData.Name = this.backupGridData[i].Name;
        gridData.DataList = orgLevelData;
        this.ActiveGridData.push(gridData);
      }
      if (locationLevelData.length) {
        gridData = {};
        gridData.Name = this.backupGridData[i].Name;
        gridData.DataList = locationLevelData;
        this.InactiveGridData.push(gridData);
      }
    }
    this.dragDropData = JSON.parse(JSON.stringify(this.ActiveGridData));
    this.AllCount = this.getDataCount(this.backupGridData)
    this.ActiveCount = this.getDataCount(this.ActiveGridData)
    this.InactiveCount = this.getDataCount(this.InactiveGridData)
  }
  //#endregion

  //#region by default Org-level data is shown
  selectOrgLevel() {
    this.filterOrgOrLocationLevel();
    let str = this.selectedButton ? this.selectedButton : 'Org-Level';
    this.selectButton(str);
  }
  //#endregion

  //#region returns true if the download icon should be shown
  isTemplateFile(item) {
    if (!this.hideDownload && this.isGroupLevelTabs) {
      let extension = item.Template.split('.').pop();
      if (extension == 'lbl' || extension == 'nlbl' || extension == 'txt' || extension == 'mwp') {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  //#endregion

  //user for site order

  showMessage() {
    this.showHelpMessage = true;
  }

  hideMessage() {
    this.showHelpMessage = false;
  }

  getGroupDataValue(groupedDataList){
    let valueArr = [];
    if(groupedDataList)
    {
      groupedDataList.forEach(val=> {
        valueArr.push(...val.value);
      })
    }
    return valueArr;
  }


  filteredDataPresent(gridData){
    for(let item of gridData){
      if(this.searchfiltersTemplate.transform(item.DataList,this.templatefilter).length)
        return true
    }
    return false;
  }

  onAutoCompleteSelectionChange(event, casetype: any) {
    if(event)
    {
      // this.searchCaseType = casetype?.Case_Type;
      this.searchCaseType = casetype?.CasetypeDisplayName;
      this.emitFilters.emit(casetype);
      this.listBackupValue = null;
    }
  }

  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any) {
    if (!autoCompleteInputboxValue) {
      return autoCompleteOptions;
    } else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      return filteredAutoCompleteOptions?.filter((option: any) => option[filterProperty]?.toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }
  showMenuRelatedFlags() {
    let dialogRef = this.dialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: this.filename,
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }

  //#region Checking if the selected row has uniqueKey or required key
  isDeletable(item) {
    if(this.uniqueKey)
    {
      return (item[this.uniqueKey] && item[this.uniqueKey] != "-");
    }
    return true;
  }
  //#endregion
}
