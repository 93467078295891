import { Directive } from '@angular/core';

@Directive({
  selector: '[appDisplaySelectedValue]'
})
export class DisplaySelectedValueDirective {

  constructor() { }

}
