<!-- Header -->
<div class="col-sm-12 d-flex pt-2 {{ sourceApp !='VitalDx' ? 'mt-2' : '' }}">
    <div class="col-sm-10 m-0 d-flex p-0">
        <div class="vdm-heading" *ngIf="sourceApp !='VitalDx' && !createClicked && !editClicked">
            <span>{{templateData.menuURL}}</span>
        </div>
        <div class="vdm-heading" *ngIf="(createClicked || editClicked)">
            <span> {{editClicked? 'Edit ' : 'Create '}} Rule for {{selectedTab}}</span>
        </div>
    </div>
</div>

<!-- Shimmer loader UI -->
<div class="px-4" *ngIf="sourceApp=='VitalDx' && !(gridData || templateData)">
    <app-shimmerui [line]=8></app-shimmerui>
</div>

<!-- tabs and options -->
<div class="container mt-1 ml-0 p-0 manage-auto" *ngIf="!editClicked && !createClicked"
    style="width: calc(100vw - 19vw);border-bottom: solid 1px #E0E9FF;">
    <div class="row m-0 p-0 d-flex align-items-center">
        <div class="col-5 m-0 mt-0 p-0">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item switchingtab" *ngFor="let tab of tabList; let i = index"
                    [ngClass]="{'active': activeTab === i}">
                    <a class="nav-link" (click)="changeTab(tab,i)">{{tab}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Main grid page -->
<div class="col-sm-12 p-0 m-0" *ngIf="!createClicked && !editClicked">
    <app-templates-listing  *ngIf="gridData && templateData && stopLoaderDX" [gridHeader]="gridheader" [gridData]="gridData" [context]="'Auto Assignment Rule'"
        [hideEdit]="!isEdit" [hideCreate]="!isCreate" [hideUpload]="true" [hideExport]="!isExport" [hideCopy]="true"
        [hideDelete]="!isDeleteRule" (CreateTemplateClicked)="createRuleFn($event)"
        (editTemplateClicked)="editRuleFn($event)" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
        [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="false"
        (deleteTemplateClicked)="deleteData($event)" [isAdmin3]="sourceApp =='VitalDx'? false : true"
        (emitFilters)="emitFilters($event)" [isAutoCompleteList]="true" [isExternalOrgTab]="isExternalOrgTab">
    </app-templates-listing>
    <mat-spinner class="labadmin-spinner" *ngIf="sourceApp =='VitalDx' && !stopLoaderDX"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"> </mat-spinner>
</div>

<!-- Delete Pop-Up -->
<div *ngIf="isDelete" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialogDelete">
            <div class="modal-content">
                <div class="confirmHeader p-2 mt-2 d-flex justify-content-center">
                    <img src="../../../../assets/icons/user_icons/warning.svg" alt="Warning"
                        class="material-icons md-48">
                </div>

                <div class="modal-body p-2 msg-wrap">
                    <div class="col-sm-12 body-msg align-center">
                        <div class="popup-title">Are you sure you want to delete this record? <br>
                            <span class="popup-subtitle mt-2">You cannot revert this action.</span>
                        </div>
                    </div>
                </div>

                <div class="modal-footer mb-3">
                    <button mat-raised-button class="ct-admin-btn-success-outline mr-3"
                        (click)="close()">Cancel</button>
                    <button mat-raised-button class="admin-button ct-admin-btn-success"
                        (click)="deleteRuleList()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Loader/Spinner -->
<mat-spinner class="labadmin-spinner" *ngIf="sourceApp =='VitalDx' && !stopLoaderDX && (createClicked || editClicked)"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"> </mat-spinner>

<!-- Create/Edit Form -->
<div class="col-sm-12 p-0 m-0" *ngIf="stopLoaderDX && (createClicked || editClicked)">
    <form class="bg-wrapper" [formGroup]="AARFormGrp" autocapitalize="true" autocomplete="off">

        <div class="col-sm-12 header align-status">
            <span>Status : </span><span [ngClass]="frmStatus.value == 'Inactive' ? 'grey-color' : 'success-color'"> {{
                frmStatus.value}} </span>
        </div>

        <div class="col-sm-12 d-flex">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong>Lab Locations<span class="text-danger">*</span></strong>
                    </mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedLabs"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Lab')" [color]="task.color"
                        [disabled]="!labLocationList || labLocationList?.length == 0">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmLabLocations')" matInput
                            [matAutocomplete]="auto" formControlName="frmLabLocations" required>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <div *ngFor="let lab of filteredLabLocation | async">
                                <mat-option (mousedown)="optionClicked($event, lab, 'Lab'); $event.preventDefault()"
                                    [value]="selectedLabLocations">
                                    <mat-checkbox class="mat-checkbox-color-bg" [checked]="lab.selected"
                                        (change)="toggleSelection(lab, 'Lab', true)" (click)="$event.stopPropagation()">
                                        {{ lab.LabName }}
                                    </mat-checkbox>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedLabLocations"
                            [removable]="removable" (removed)="removeChip(location, 'Lab')" [removable]="true">
                            {{ location.LabName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-8"><strong>Ordering Facility Locations<span
                                class="text-danger">*
                            </span></strong></mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-4 p-0" *ngIf="isFacitlityContext"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedOFs"
                        [disabled]="OFLocationList?.length == 0 || selectedLabLocations.length == 0"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('OF')" [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmOFLocations')" matInput
                            [matAutocomplete]="autoOFLoc" formControlName="frmOFLocations" required>
                        <mat-autocomplete #autoOFLoc="matAutocomplete" [displayWith]="displayFn">
                            <div *ngFor="let ofLoc of filteredOFLocation | async">
                                <mat-option (mousedown)="optionClicked($event, ofLoc, 'OF')"
                                    [value]="selectedOFLocations" [disabled]="selectedLabLocations.length == 0">
                                    <mat-checkbox class="mat-checkbox-color-bg" [checked]="ofLoc.selected"
                                        (change)="toggleSelection(ofLoc, 'OF')" (click)="$event.stopPropagation()">
                                        {{ ofLoc.OrderingFacilityName }}
                                    </mat-checkbox>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row maxHight">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedOFLocations"
                            (removed)="removeChip(location, 'OF')" [removable]="true">
                            {{ location.OrderingFacilityName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-8">
                        <strong>Ordering Facility Physicians<span class="text-danger">*</span></strong>
                    </mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-4 p-0"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedOFPhysicians"
                        [disabled]="!OFPhysiciansList || OFPhysiciansList.length == 0 || selectedOFLocations.length == 0 || selectedLabLocations.length == 0"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Physician')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmOFPhysicians')" matInput
                            [matAutocomplete]="autoOFPhyc" formControlName="frmOFPhysicians" required>
                        <!-- </mat-form-field> -->

                        <mat-autocomplete #autoOFPhyc="matAutocomplete" [displayWith]="displayFn">
                            <div *ngFor="let Physician of filteredOFPhysician | async">
                                <mat-option (mousedown)="optionClicked($event, Physician, 'Physician')"
                                    [value]="selectedOFPhysicians"
                                    [disabled]="selectedOFLocations.length == 0 || selectedLabLocations.length == 0">
                                    <mat-checkbox class="mat-checkbox-color-bg" [checked]="Physician.selected"
                                        (change)="toggleSelection(Physician, 'Physician')"
                                        (click)="$event.stopPropagation()">
                                        {{ Physician.OrderingPhysicianname }}
                                    </mat-checkbox>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedOFPhysicians"
                            (removed)="removeChip(location, 'Physician')" [removable]="true">
                            {{ location.OrderingPhysicianname }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="col-sm-12 d-flex">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-8">
                        <strong>Case Type<span class="text-danger">*</span></strong>
                    </mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-4 p-0"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedCasetype"
                        [disabled]="caseTypesList.length == 0 || selectedLabLocations.length == 0"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Casetype')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " matInput [matAutocomplete]="autoC"
                            (click)="triggerEvent('frmCasetypes')" #caseTypeSearch formControlName="frmCasetypes"
                            required>
                        <mat-autocomplete [displayWith]="displayFn" #autoC="matAutocomplete">
                            <mat-optgroup [disabled]="selectedLabLocations.length == 0"
                                *ngFor="let group of filteredCasetypeList">
                                <div>
                                    <mat-checkbox class="mat-checkbox-color-bg" [checked]="group.selected"
                                        [indeterminate]="checkPartialSelection(group)" (mousedown)="toggleParent(group)"
                                        (click)="$event.stopPropagation()">
                                        {{group.Item}}
                                    </mat-checkbox>
                                    <button mat-button (click)="expandDocumentTypes(group)">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </button>
                                </div>
                                <div *ngFor="let name of group.Children">
                                    <mat-option (mousedown)="toggleCasetype(name)" (click)="$event.stopPropagation()"
                                        [disabled]="selectedLabLocations.length == 0" [value]="name"
                                        [ngClass]="isExpandCategory[group.Item] ? 'list-show' : 'list-hide'">
                                        <mat-checkbox class="mat-checkbox-color-bg" [checked]="name.selected"
                                            (change)="toggleCasetype(name)" (click)="$event.stopPropagation()">
                                            {{name.CaseType}}
                                        </mat-checkbox>
                                    </mat-option>
                                </div>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let casetype of selectedCasetypes"
                            (removed)="removeChip(casetype, 'Casetype')" [removable]="true">
                            {{ casetype.CaseType }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row" [ngClass]="{'pb-2':sourceApp =='VitalDx'}">
                    <mat-label class="label-style col-sm-3"><strong>Insurance</strong></mat-label>
                    <mat-radio-group formControlName="frmIsPayerFamily" class="col-sm-6 p-0"
                        (change)="onPayerChange($event)">
                        <mat-radio-button value="Payer" class="label-style col-sm-2 m-0 p-0" checked>
                            Payor
                        </mat-radio-button>
                        <mat-radio-button value="PayerFamily" class="label-style col-sm-3 m-0">
                            Payor Family
                        </mat-radio-button>
                    </mat-radio-group>

                    <!-- [disabled]="!enableInsurance || enableInsurance?.length == 0" -->
                    <mat-checkbox class="label-style selectALL col-sm-3 mr-0 p-0"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedInsurance"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Insurance')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row">
                    <mat-form-field class="example-full-width col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmInsurance')" matInput
                            [matAutocomplete]="autoInsurance" formControlName="frmInsurance">

                        <mat-autocomplete #autoInsurance="matAutocomplete" [displayWith]="displayFn" multiple>
                            <div *ngFor="let Insurance of filteredInsurance | async">
                                <mat-option (mousedown)="optionClicked($event,Insurance, 'Insurance')"
                                    [value]="selectedInsurance">
                                    <mat-checkbox class="mat-checkbox-color-bg" [checked]="Insurance.selected"
                                        (change)="toggleSelection(Insurance, 'Insurance')"
                                        (click)="$event.stopPropagation()">
                                        {{ isPayerFamily ? Insurance.PayerFamily : Insurance.PayerName }}
                                    </mat-checkbox>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let payer of selectedInsurance"
                            (removed)="removeChip(payer, 'Insurance')" [removable]="true">
                            {{ isPayerFamily ? payer.PayerFamily : payer.PayerName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong>Insurance Plan Type</strong>
                    </mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [(ngModel)]="allSelectedInsurancePlanType"
                        [disabled]="!insurancePlanTypeDataList || insurancePlanTypeDataList?.length == 0"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('InsurancePlanType')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmInsurancePlanType')" matInput
                            [matAutocomplete]="autoInsurancePlanType" formControlName="frmInsurancePlanType">
                    </mat-form-field>

                    <mat-autocomplete #autoInsurancePlanType="matAutocomplete" [displayWith]="displayFn">
                        <div *ngFor="let plantype of filteredInsurancePlanType | async">
                            <mat-option (mousedown)="toggleSelection(plantype, 'InsurancePlanType')"
                                [value]="selectedInsurancePlanType">
                                <mat-checkbox class="mat-checkbox-color-bg" [checked]="plantype.selected"
                                    (change)="toggleSelection(plantype, 'InsurancePlanType')"
                                    (click)="$event.stopPropagation()">
                                    {{ plantype.AttributeName }}
                                </mat-checkbox>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let type of selectedInsurancePlanType"
                            (removed)="removeChip(type, 'PlanType')" [removable]="true">
                            {{ type.AttributeName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="col-sm-12 d-flex">
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0" *ngIf="!isFacitlityContext">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong>Services<span class="text-danger">*</span></strong>
                    </mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0" [(ngModel)]="allSelectedServices"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}"
                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Services')"
                        [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12" appearance="outline">
                        <mat-chip-list class="col-sm-12 p-0 m-0">
                            <mat-chip *ngIf="!selected[i]" class="chip-color" (click)="onServiceChange(true,i)">
                                {{servicesList[i]}}
                            </mat-chip>
                            <mat-chip *ngIf="selected[i]" class="selected-chip-color" highlighted
                                (click)="onServiceChange(false,i)">
                                {{servicesList[i]}}
                            </mat-chip>
                            <mat-chip *ngIf="!selected[i+1]" class="chip-color" (click)="onServiceChange(true,i+1)">
                                {{servicesList[i+1]}}
                            </mat-chip>
                            <mat-chip *ngIf="selected[i+1]" class="selected-chip-color" highlighted
                                (click)="onServiceChange(false,i+1)">
                                {{servicesList[i+1]}}
                            </mat-chip>
                            <mat-chip *ngIf="!selected[i+2]" class="chip-color" (click)="onServiceChange(true,i+2)">
                                {{servicesList[i+2]}}
                            </mat-chip>
                            <mat-chip *ngIf="selected[i+2]" class="selected-chip-color" highlighted
                                (click)="onServiceChange(false,i+2)">
                                {{servicesList[i+2]}}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
            <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                <div class="row">
                    <mat-label class="label-style col-sm-6">
                        <strong>Bill To</strong>
                    </mat-label>
                    <mat-checkbox class="label-style selectALL col-sm-6 p-0"
                        [ngClass]="{'select-checkbox-style':sourceApp =='VitalDx'}" [(ngModel)]="allSelectedBillTo"
                        [disabled]="!billToList || billToList?.length == 0" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection('BillTo')" [color]="task.color">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="row mt-3">
                    <mat-form-field class="example-full-width col-sm-12"
                        [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                        appearance="outline">
                        <input type="text" placeholder="Select " (click)="triggerEvent('frmBillto')" matInput
                            [matAutocomplete]="autoBillTo" formControlName="frmBillto">
                    </mat-form-field>

                    <mat-autocomplete #autoBillTo="matAutocomplete" [displayWith]="displayFn">
                        <div *ngFor="let billto of filteredBillTo | async">
                            <mat-option (mousedown)="optionClicked($event, billto, 'BillTo')" [value]="selectedBillTo">
                                <mat-checkbox class="mat-checkbox-color-bg" [checked]="billto.selected"
                                    (change)="toggleSelection(billto, 'BillTo')" (click)="$event.stopPropagation()">
                                    {{ billto.AttributeName }}
                                </mat-checkbox>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </div>
                <div class="row">
                    <mat-chip-list class="col-sm-12 scrollable-chip-list mt-1">
                        <mat-chip class="dropdown-chip-color" *ngFor="let location of selectedBillTo"
                            (removed)="removeChip(location, 'BillTo')" [removable]="true">
                            {{ location.AttributeName }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3 pb-3">
            <div class="rule-setting-wrap">
                <div class="vdm-heading">
                    <span>Rule Settings<span class="text-danger">*</span></span>
                </div>

                <div class="row d-flex">
                    <mat-label class="label-style col-sm-4 ml-1">
                        <strong>Effective Date<span class="text-danger">*</span></strong>
                    </mat-label>
                    <mat-label class="label-style col-sm-4 ml-1">
                        <strong>Expiry Date<span class="text-danger">*</span></strong>
                    </mat-label>
                </div>

                <div class="row mt-3 mb-3" [ngClass]="{'mt-4':sourceApp =='VitalDx'}">
                    <mat-form-field class="col-sm-4 example-full-width"
                        [ngClass]="{'lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input placeholder="mm/dd/yyyy" matInput [min]="minFromDate" [max]="maxToDate"
                            [matDatepicker]="fromPicker" formControlName="frmEffectiveDate">
                        <mat-datepicker-toggle class="p-0 m-0" matSuffix [for]="fromPicker"
                            (click)="datePickerOnClick()">
                        </mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="col-sm-4 example-full-width"
                        [ngClass]="{'lab-admin-form-text':sourceApp =='VitalDx'}" appearance="outline">
                        <input placeholder="mm/dd/yyyy" matInput [min]="minToDate" [max]="maxToDate"
                            [matDatepicker]="toPicker" formControlName="frmExpiryDate">
                        <mat-datepicker-toggle class="p-0 m-0" matSuffix [for]="toPicker" (click)="datePickerOnClick()">
                        </mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>

                    <div class="d-flex">
                        <!-- // it's req in feature implimentation -->
                        <!-- <div class="d-flex status-pad">
                            <span class="vdm-sub-heading">Status:</span>
                            <span class="allow-login">
                                <app-toggle-switch [isChecked]="isStatusChecked" formControlName="frmStatus" (clicked)="toggleChangeSatus($event)"
                                    type="secondary"></app-toggle-switch>
                            </span>
                        </div> -->

                        <div class="row d-flex align-center"
                            *ngIf="(createClicked || editClicked)  && selectedTab ==='External Organization'">
                            <div class="d-flex status-pad">
                                <span class="vdm-sub-heading">Auto Send Out:</span>
                                <span class="allow-login">
                                    <!-- formControlName="frmAutoSendOut" -->
                                    <app-toggle-switch class="user-flags-toggle" [isChecked]="isAutoSendChecked"
                                        (clicked)="toggleChangeAutoSend($event)" type="secondary"></app-toggle-switch>
                                </span>
                            </div>

                            <img src="/assets/icons/home_icons/help.svg" alt="Help"
                                class="material-icons help-icon-size mr-2"
                                matTooltip="By enabling Auto Send Out, the system will automatically send the case to the external lab once the Technical Component is complete.">
                        </div>
                    </div>
                </div>
            </div>

            <div class="assign-detail-wrap" *ngIf="selectedTab !=='External Organization'">
                <div class="vdm-heading">
                    <span>Assignee Details<span class="text-danger">*</span></span>
                </div>

                <div *ngIf="!readingLocationList?.length">
                    <span class="error-msg">There are no records</span>
                </div>
                <div class="col-sm-12 d-flex mb-2 p-0">
                    <div class="col-sm-4 field-container p-0 mr-0 ml-0 mt-3">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">
                                <strong>Primary Reading Location<span class="text-danger">*</span></strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text': sourceApp === 'VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size"
                                    formControlName="frmPrimaryReadingLocation" placeholder="--Select--"
                                    [displayWith]="displayFn"
                                    (selectionChange)="onReadingLocChange($event.value, 1, null, $event)">
                                    <mat-option [value]="" selected>
                                        -- Select --
                                    </mat-option>
                                    <mat-option *ngFor="let loc of readingLocationList" [value]="loc.AccountId"
                                        (click)="isPrimaryAccountEPG = loc.IsEPGAccount">
                                        {{ loc.LocationName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">
                                <strong>Secondary Reading Location</strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size" [displayWith]="displayFn"
                                    [disabled]="!AARFormGrp.controls.frmPrimaryAssignee.value" placeholder="--Select--"
                                    formControlName="frmSecondaryReadingLocation"
                                    (selectionChange)="onReadingLocChange($event.value,2,null,$event)">
                                    <mat-option [value]="" [disabled]="!AARFormGrp.controls.frmPrimaryAssignee.value"
                                        (onSelectionChange)="AARFormGrp.get('frmSecondaryAssignee').setValue(''); validateAssigneeSection()">
                                        -- Select --</mat-option>
                                    <mat-option *ngFor="let loc of readingLocationList"
                                        (click)="isSecondaryAccountEPG = loc.IsEPGAccount" [value]="loc.AccountId"
                                        [disabled]="!AARFormGrp.controls.frmPrimaryAssignee.value">
                                        {{loc.LocationName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">
                                <strong>Tertiary Reading Location</strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size" [displayWith]="displayFn"
                                    [disabled]="!AARFormGrp.controls.frmSecondaryAssignee.value"
                                    formControlName="frmTertiaryReadingLocation" placeholder="--Select--"
                                    (selectionChange)="onReadingLocChange($event.value,3,null,$event)">
                                    <mat-option [value]="" [disabled]="!AARFormGrp.controls.frmSecondaryAssignee.value"
                                        (onSelectionChange)="AARFormGrp.get('frmTertiaryAssignee').setValue(''); validateAssigneeSection()">
                                        -- Select
                                        --</mat-option>
                                    <mat-option *ngFor="let loc of readingLocationList"
                                        (click)="isTertiaryAccountEPG = loc.IsEPGAccount" [value]="loc.AccountId"
                                        [disabled]="!AARFormGrp.controls.frmSecondaryAssignee.value">
                                        {{loc.LocationName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 d-flex mb-4 p-0">
                    <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">
                                <strong>Primary Assignee {{ isPathologistTab ?
                                    'Pathologist' : 'Cytotechnologist'}}<span class="text-danger">*</span></strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size" [displayWith]="displayFn"
                                    [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value"
                                    placeholder="--Select--" formControlName="frmPrimaryAssignee"
                                    (selectionChange)="onAssigneeSelection(0)">
                                    <mat-option [value]="" (click)="validateAssigneeSection()"
                                        [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value">--
                                        Select
                                        --</mat-option>
                                    <span *ngIf="isPathologistTab">
                                        <span *ngFor="let group of primaryAssigneeList">
                                            <div *ngFor="let child of group.UserDetailsList">
                                                <mat-option
                                                    *ngIf="isPathologistTab && isPrimaryAccountEPG && child.UserId == -100"
                                                    [value]="child">Available to all Pathologist</mat-option>
                                                <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                    [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value">
                                                    {{child.FormattedDisplayName}}
                                                </mat-option>
                                            </div>
                                        </span>
                                    </span>
                                    <span *ngIf="!isPathologistTab">
                                        <mat-optgroup *ngFor="let group of primaryAssigneeList" [label]="group.Parent"
                                            [disabled]="group.disabled">
                                            <div *ngFor="let child of group.UserDetailsList">
                                                <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                    [disabled]="!AARFormGrp.controls.frmPrimaryReadingLocation.value">
                                                    {{child.FormattedDisplayName}}
                                                </mat-option>
                                            </div>
                                        </mat-optgroup>
                                    </span>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">

                                <strong>Secondary Assignee {{ isPathologistTab ? 'Pathologist' :
                                    'Cytotechnologist'}}<span class="text-danger"
                                        *ngIf="AARFormGrp.controls.frmSecondaryReadingLocation.value">*</span></strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size"
                                    [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value"
                                    placeholder="--Select--" formControlName="frmSecondaryAssignee"
                                    (selectionChange)="onAssigneeSelection(1)">
                                    <mat-option [value]="" (click)="validateAssigneeSection()"
                                        [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value">--
                                        Select
                                        --</mat-option>
                                    <span *ngIf="isPathologistTab">
                                        <span *ngFor="let group of secondaryAssigneeList">
                                            <div *ngFor="let child of group.UserDetailsList">
                                                <mat-option
                                                    *ngIf="isPathologistTab && isSecondaryAccountEPG && child.UserId == -100"
                                                    [value]="child">Available to all Pathologist</mat-option>
                                                <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                    [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value">
                                                    {{child.FormattedDisplayName}}
                                                </mat-option>
                                            </div>
                                        </span>
                                    </span>
                                    <span *ngIf="!isPathologistTab">
                                        <mat-optgroup *ngFor="let group of secondaryAssigneeList"
                                            [label]="group.Parent == 'Pathologist' ? '' : group.Parent"
                                            [disabled]="group.disabled">
                                            <div *ngFor="let child of group.UserDetailsList">
                                                <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                    [disabled]="!AARFormGrp.controls.frmSecondaryReadingLocation.value">
                                                    {{child.FormattedDisplayName}}
                                                </mat-option>
                                            </div>
                                        </mat-optgroup>
                                    </span>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="field-container mt-3 col-sm-4 p-0 mr-0 ml-0">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">

                                <strong>Tertiary Assignee {{ isPathologistTab ? 'Pathologist' :
                                    'Cytotechnologist'}} <span class="text-danger"
                                        *ngIf="AARFormGrp.controls.frmTertiaryReadingLocation.value">*</span></strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size" [displayWith]="displayFn"
                                    [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value"
                                    placeholder="--Select--" formControlName="frmTertiaryAssignee"
                                    (selectionChange)="onAssigneeSelection(2)">
                                    <mat-option [value]=""
                                        [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value">--
                                        Select
                                        --</mat-option>
                                    <span *ngIf="isPathologistTab">
                                        <span *ngFor="let group of tertiaryAssigneeList">
                                            <div *ngFor="let child of group.UserDetailsList">
                                                <mat-option
                                                    *ngIf="isPathologistTab && isTertiaryAccountEPG && child.UserId == -100"
                                                    [value]="child">Available to all Pathologist</mat-option>
                                                <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                    [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value">
                                                    {{child.FormattedDisplayName}}
                                                </mat-option>
                                            </div>
                                        </span>
                                    </span>
                                    <span *ngIf="!isPathologistTab">
                                        <mat-optgroup *ngFor="let group of tertiaryAssigneeList" [label]="group.Parent"
                                            [disabled]="group.disabled">
                                            <div *ngFor="let child of group.UserDetailsList">
                                                <mat-option *ngIf="child.UserId != -100" [value]="child"
                                                    [disabled]="!AARFormGrp.controls.frmTertiaryReadingLocation.value">
                                                    {{child.FormattedDisplayName}}
                                                </mat-option>
                                            </div>
                                        </mat-optgroup>
                                    </span>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="assign-detail-wrap" *ngIf="selectedTab ==='External Organization'">
                <div class="col-sm-12 d-flex m-0 p-0">
                    <div class="vdm-heading">
                        <span>Send Out Details<span class="text-danger">*</span></span>
                    </div>

                    <div class="col-sm-4 align-item-center icon-size"
                        *ngIf="(createClicked || editClicked)  && selectedTab ==='External Organization'">
                        <img src="/assets/icons/home_icons/help.svg" alt="Help"
                            class="material-icons help-icon-size mr-2">
                        <span>S/O Services is professional only</span>
                    </div>
                </div>

                <div class="col-sm-12 mb-2 p-0">
                    <div class="col-sm-4 field-container p-0 mr-0 ml-0">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">
                                <strong>Reading Location<span class="text-danger">*</span></strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select disableOptionCentering class="font-size" [displayWith]="displayFn"
                                    placeholder="--Select--" formControlName="frmPrimaryReadingLocation">
                                    <mat-option *ngFor="let loc of readingLocationList" [value]="loc.AccountId"
                                        (click)="isPrimaryAccountEPG = loc.IsEPGAccount">
                                        {{ loc.LocationName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- // it's req in feature implimentation -->
                    <!-- <div class="col-sm-4 field-container p-0 mr-0 ml-0 mt-3">
                        <div class="row">
                            <mat-label class="label-style col-sm-12">
                                <strong>S/O Bill to </strong>
                            </mat-label>
                        </div>
                        <div class="row mt-3">
                            <mat-form-field class="example-full-width col-sm-12"
                                [ngClass]="{'lab-admin-form-field lab-admin-form-text':sourceApp =='VitalDx'}"
                                appearance="outline">
                                <mat-select class="font-size" [displayWith]="displayFn"
                                    (selectionChange)="onReadingLocChange($event.value,1,null,$event)">
                                    <mat-option [value]="">
                                        -- Select --</mat-option>
                                    <mat-option *ngFor="let loc of readingLocationList" [value]="loc.AccountId"
                                        (click)="isPrimaryAccountEPG = loc.IsEPGAccount">
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- // Notes for Lab & Facility -->
            <div class="col-sm-12 pl-0">
                <div class="vdm-heading">
                    <span>Notes</span>
                </div>
                <mat-form-field class="col-sm-12 example-full-width pl-0" appearance="outline">
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="4000"
                        cdkAutosizeMaxRows="5" formControlName="frmNotes"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="col-sm-12 mx-2 align-center" *ngIf="(createClicked || editClicked)">
            <div class="sub-button mb-3">
                <button mat-raised-button class="admin-save-button mr-3" (click)="back()">Return</button>
                <button mat-raised-button *ngIf="editClicked" class="admin-save-button mr-3" type="submit"
                    (click)="showPop(true)">{{statusBtnName}}</button>
                <button mat-raised-button class="admin-save-button" type="submit" (click)="validateRule()">Save</button>
            </div>
        </div>
    </form>
</div>

<!-- Activate status pop-up-->
<div *ngIf="activateOrDeactivate" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialogAssign">
            <div class="modal-content">
                <div class="confirmHeader p-2 mt-2 d-flex justify-content-center">
                    <img src="../../../../assets/icons/user_icons/warning.svg" alt="Warning"
                        class="material-icons md-48">
                </div>

                <div class="modal-body p-2 msg-wrap">
                    <div class="col-sm-12 body-msg align-center">
                        <div class="popup-title">{{confirmationMessage}}</div>
                    </div>
                </div>

                <div class="modal-footer mb-3">
                    <button mat-raised-button class="ct-admin-btn-success-outline mr-3" (click)="close()">No</button>
                    <button mat-raised-button class="admin-button ct-admin-btn-success"
                        (click)="validateRule(true)">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Pathologist License Details Alert -->
<ng-template #widgetEditorModal>
    <div class="mat-dialog-header border-bottom license-alert-header">
        <div class="col-sm-12">
            <div class="row">
                <span class="col-sm-6 mb-0 mt-2">
                    <h3 class="p-0 m-0">
                        <strong class="header-View header-font">License Information</strong>
                    </h3>
                </span>
                <span class="col-sm-6 selectALL align-status p-0">
                    <button mat-icon-button matDialogClose tabindex="-1">
                        <mat-icon aria-label="Close dialog">close</mat-icon>
                    </button>
                </span>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="font-size">
                <em class="fa fa-info-circle cursor" aria-hidden="true"></em>
                The selected Pathologist is licensed to work only for the following states:
            </div>
        </div>
    </div>
    <mat-dialog-content class="p-3">
        <div class="col-sm-12 p-0 m-0 licensed-info">
            <div class="row licensed-info-height m-0 p-0">
                <div *ngIf="primaryPathLicenseInfo?.length>0" class="col-sm-4">
                    <div class="path-name"><b>{{"Dr. " +primaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <div class="state-name p-0 m-0" *ngFor="let pathData of primaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'licensured'">
                            <b>{{pathData.OfState}}</b>
                            <span class="p-0 m-0">{{pathData.ExpiryDate ?' ('+ pathData.ExpiryDate +')' : ''}}</span>
                            <span class="licence-expired p-0 m-0">{{pathData.isExpired ?' Expired!' : ''}}</span>
                        </span>
                    </div>
                </div>
                <div *ngIf="secondaryPathLicenseInfo?.length>0" class="col-sm-4">
                    <div class="path-name"><b>{{"Dr. " +secondaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <div class="state-name p-0 m-0" *ngFor="let pathData of secondaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'licensured'">
                            <b>{{pathData.OfState}}</b>
                            <span class=" p-0 m-0">{{pathData.ExpiryDate ?' ('+ pathData.ExpiryDate +')' : ''}}</span>
                            <span class="licence-expired p-0 m-0">{{pathData.isExpired ?' Expired!' : ''}}</span>
                        </span>
                    </div>
                </div>
                <div *ngIf="tertiaryPathLicenseInfo?.length > 0" class="col-sm-4">
                    <div class="path-name"><b>{{"Dr. " +tertiaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <div class="state-name p-0 m-0" *ngFor="let pathData of tertiaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'licensured'">
                            <b>{{pathData.OfState}}</b>
                            <span class=" p-0 m-0">{{pathData.ExpiryDate ?' ('+ pathData.ExpiryDate +')' : ''}}</span>
                            <span class="licence-expired p-0 m-0">{{pathData.isExpired ?' Expired!' : ''}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 pt-1 pl-0 pr-0 m-0">
            <h3 class="p-0 m-0">
                <strong class="header-font">Do you wish to create this Auto Assignment Rule for these facilities from
                    Pathologist not licensed state(s)? </strong>
            </h3>
        </div>
        <div class="col-sm-12 p-0 m-0 non-licensed-info">
            <div class="non-licensed-info-height">
                <div *ngIf="primaryPathLicenseInfo?.length>0">
                    <div class="path-name p-0 m-0"><b>{{"Dr. " +primaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <span class="p-0 m-0" *ngFor="let pathData of primaryPathLicenseInfo">
                        <span class="p-0 m-0" *ngIf="pathData.MessageType == 'notlicensured'">
                            <div class="state-name p-0 m-0">
                                <b>{{pathData.OfState}}</b>
                            </div>
                            <div class="font-size default-color p-0 m-0">{{pathData.OfName ?'('+ pathData.OfName +')' :
                                ''}}</div>
                        </span>
                    </span>
                </div>

                <div class="pt-4" *ngIf="secondaryPathLicenseInfo?.length>0">
                    <div class="path-name"><b>{{"Dr. " +secondaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <span *ngFor="let pathData of secondaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'notlicensured'">
                            <div class="state-name p-0 m-0">
                                <b>{{pathData.OfState}}</b>
                            </div>
                            <div class="font-size default-color p-0 m-0">{{pathData.OfName ?'('+ pathData.OfName +')' :
                                ''}}</div>
                        </span>
                    </span>
                </div>

                <div class="pt-4" *ngIf="tertiaryPathLicenseInfo?.length>0">
                    <div class="path-name"><b>{{"Dr. " +tertiaryPathLicenseInfo[0].PathologistName}}</b></div>
                    <span *ngFor="let pathData of tertiaryPathLicenseInfo">
                        <span *ngIf="pathData.MessageType == 'notlicensured'">
                            <div class="state-name p-0 m-0">
                                <b>{{pathData.OfState}}</b>
                            </div>
                            <div class="font-size default-color p-0 m-0">{{pathData.OfName ?'('+ pathData.OfName +')' :
                                ''}}</div>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="border-top footer-btn">
        <app-button buttonclass="secondary" class="edit-page-button" mat-button matDialogClose>No</app-button>
        <app-button buttonclass="primary" class="edit-page-button ml-2" mat-button matDialogClose (click)="saveData()">
            Yes</app-button>
    </mat-dialog-actions>
</ng-template>