import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent implements OnInit {
  @Input() spinnerDiameter:number=50;
  @Input() isFromTemplateForm = false;
  @Input() customClass:string=''
  
  ngOnInit(): void {
    if(this.customClass)
    {
      this.customClass= 'default-loader ' +this.customClass
    }
    else{
      this.customClass='default-loader'
    }
  }

}
