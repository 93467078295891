import { Component, Input } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import { CardQueries } from 'src/app/model/card-query';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { InjectorService } from '../../../core/services/Injectorservices';
//import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { VitalAdminTabService } from '../../tab.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';


@Component({
  selector: 'grid-container',
  templateUrl: './gridcontainer.component.html',
  styleUrls: ['./gridcontainer.component.scss']
})
export class GridContainerComponent {

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  public hideExportBtn: boolean = false;
  public actionButtonDetails: any[];
  gridHeader = [];
  UserList = [];
  @Input()
  filename: any;
  constructor(private injectorServiceGridContainer: InjectorService, private datashare: DataShareService, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, public commonService: CommonService, private dialog: MatDialog,) {
    // super(injectorServiceGridContainer);
  }
  OnInit() {
    this.GetButtondetails();
  }

  ngOnChanges() {
    this.gridHeader = []
    let labels = JSON.parse(JSON.stringify(this.templateData.labels))
    for (let i = 0; i < labels.length; i++) {
      let column: any;
      // label = this.templateData.labels[i];
      this.gridHeader.unshift(labels[i]);
    }
    this.AddGridData();

  }

  GetButtondetails() {
    let casebutton = this.VitalHttpServices.CasemenuAction;
    if (!casebutton) {
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  AddGridData() {
    let gridarray = []
    let primary = {}
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
              if(!value){
                value = 'Not Specified';
                primary[key] = value;
              }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      if (this.templateData.submenuData.submenuData1 !== undefined) {
        if (this.templateData.submenuData.submenuData1.length > 0) {
          this.UserList = this.templateData.submenuData.submenuData1
        }
      } else {
        this.UserList = []
      }
      this.gridData = new CollectionView(gridarray, { pageSize: 10 })
      this.gridwidth = (170 * this.gridHeader.length) + 37;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
      if (this.gridwidth != 0) {
        this.gridwidth = this.getpercentage(this.gridwidth)
      }
    } else {
      this.gridwidth = 0;
    }

    // this.gridData = new CollectionView(this.templateData.submenuData, { pageSize: 10 })
    // this.gridwidth = (170 * this.templateData.labels.length) + 37;
    // if (this.gridwidth > 1300)
    //   this.gridwidth = 1300;
  }
  getpercentage(pixel: number) {
    // var pixels = 100;
    var screenWidth = window.screen.width;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 8
    }
    return per;
  }

  AddTab(user: any, item: string) {
    if (item === 'user') {
      let tab: any = {};
      // this accountid is actually used to  populate the data for user(userid)
      tab.accountid = user.UserID;
      tab.keyword = "Users";
      tab.cardtype = "Users";
      tab.text = user.Username;
      tab.Removable = true;
      this.tabService.addNewOrSelectTab(tab);
    }
  }

  onClick(grid, e) {

    var hti = grid.hitTest(e);
    var col = hti.col;
    var row = hti.row;

    if (hti.panel === grid.cells) {
      var item = hti.panel.rows[row].dataItem;
      var val;
      if (item) {
        if (item.RuleID != undefined) {
          val = this.templateData.submenuData.filter(va => va.RuleID === item.RuleID);
          if (val.length > 0) {
            item = val[0]
          } else {
            val = this.templateData.submenuData.filter(va => va.VDIdentifier === item.VDIdentifier);
            item = val;
          }

        }
      }

      let tab = {
        accountid: item.VD_Identifier,
        text: 'VDInstallations',
        Removable: true,
        cardtype: 'VD Installations',
        keyword: "vdinstallations",
        orgid: this.templateData.GroupData.OrganizationId
      }
      if (item.VD_Identifier !== undefined) {
        sessionStorage.setItem('deploymentKey', "configdb");
        this.tabService.addNewOrSelectTab(tab);
      }
    }
  }

  ExportExcel(flex) {
    let url:any = this.templateData.menuURL;
    let id:any = this.templateData.secondarykeys.OrganizationId;
    let filename = this.commonService.getExcelFileName(url,id)
    this.commonService.ExportToExcel(flex,filename);
  }

  showMenuRelatedFlags() {
    let dialogRef = this.dialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: 'Requisition Rules',
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }
}
