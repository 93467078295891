import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, map, catchError, tap, finalize } from 'rxjs/operators';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-updatereporttemplate',
  templateUrl: './updatereporttemplate.component.html',
  styleUrls: ['./updatereporttemplate.component.scss']
})
export class UpdatereporttemplateComponent {
  @Output() close = new EventEmitter<void>();
  deploymentKeySelectionModel = new SelectionModel<any>(true, []);
  deploymentKey: any[];
  public DeploymentKeys = []
  dbName: string;
  casetypeTemplateData: any;
  casetypeList: {}[];
  templateNameList: string[];
  isGetDataClicked: boolean = false;
  @Input() selecteddefaultdeploymentkey:string;
  frm_Template = this._fb.group({
    deploymentname: ['', Validators.required],
    casetypename: ['', Validators.required],
    templatetype: ['', Validators.required],
    category:""
  });

  frm_EditTemplate = this._fb.group({
    currentfilename: '',
    newfilename: ['', Validators.required]
  });
  isCurrentTemplateAvailble: boolean=false;
  FiltercasetypeList:any;
  FilterTemplateList: any;
  selectedDeployment: string;
  FilterCasetypeCategoryList:any;
  CategoryList: {}[];
  DeepCopyOfDropDownList: any;
  DeepCopyOfCasetypeList: any;

  constructor(public vaservices: VitalHttpServices, private _fb: FormBuilder,private clipboard: Clipboard,
    private commonService: CommonService, public datashare: DataShareService, public ngxservice: NgxUiLoaderService,
    public _snackBar: MatSnackBar) {
    this.deploymentKey = this.vaservices.DeploymentKeys
  }


  ngOnInit(): void {
    this.selectedDeployment=this.selecteddefaultdeploymentkey?this.selecteddefaultdeploymentkey:''
    this.dbName=this.selectedDeployment
    this.frm_Template.patchValue({
      deploymentname:this.selectedDeployment
    })
    if(this.dbName){
      this.getDropDownData()
      }
    // this.frm_Template.controls.casetypename.disable()
    // this.frm_Template.controls.templatetype.disable()
  }

  isSaveEnable(form){
    if(!form?.controls?.newfilename?.value.trim() || (form?.controls?.newfilename?.value.toString().toLowerCase().trim() === form?.controls?.currentfilename?.value.toString().toLowerCase().trim()))
    {
      return true
    }
    return false
  }

  fetchCaseTypeByCategory(input) {
    if(input.trim()){
    of(this.DeepCopyOfDropDownList)
      .pipe(
        map(items => items.filter((item: any) => item["Category"].toLowerCase() === input?.toLowerCase())),
        map(items => items.map(item => item["Casetype"])),
        map(caseTypes => [...new Set(caseTypes)])
      )
      .subscribe((result: any) => {
        this.casetypeList=result;
        this.FiltercasetypeList=result.slice(0,25);
      });
    }
    else{
      this.FiltercasetypeList=this.DeepCopyOfCasetypeList.slice(0,25);
      this.casetypeList=this.DeepCopyOfCasetypeList;
    }

  }
  
  fetchCaseTypeBySearch(input) {
    of(input).pipe(
      debounceTime(300), // Adding a debounce time to handle rapid input
      map(searchTerm => searchTerm?.trim().toLowerCase()),
      map(searchTerm => {
        if (!searchTerm) {
          return this.casetypeList.slice(0, 25);
        } else {
          return this.casetypeList
            .filter(item => item.toString().toLowerCase().includes(searchTerm))
            .slice(0, 25); // Assuming you want to limit the results to 25
        }
      })
    ).subscribe(filteredCaseTypes => {
      this.FiltercasetypeList = filteredCaseTypes;
    });
  }

  fetchTemplatesBySearch(input) {
    of(input).pipe(
      debounceTime(300), // Adding a debounce time to handle rapid input
      map(searchTerm => searchTerm?.trim().toLowerCase()),
      map(searchTerm => {
        if (!searchTerm) {
          return this.templateNameList.slice(0, 25);
        } else {
          return this.templateNameList
            .filter(item => item.toString().toLowerCase().includes(searchTerm))
            .slice(0, 25); // Assuming you want to limit the results to 25
        }
      })
    ).subscribe(filterdtemplates => {
      this.FilterTemplateList = filterdtemplates;
    });
  }

 
  onChangeDeployment(dep){
    this.dbName=dep
    this.selectedDeployment=dep
    this.getDropDownData()
    return
  }


  getDropDownData(): void {
    
    this.vaservices.GetCasetypeReportTemplates(this.dbName).pipe(
      map(data => {
        this.casetypeTemplateData = data.slice();
        this.DeepCopyOfDropDownList=JSON.parse(JSON.stringify(this.casetypeTemplateData))
        this.casetypeList = [...new Set(data.map((item: any) => item["Casetype"]))];
        this.DeepCopyOfCasetypeList=JSON.parse(JSON.stringify(this.casetypeList))
        this.CategoryList=[...new Set(data.map((item: any) => item["Category"]))];
        if (data.length > 0) {
          this.templateNameList = Object.keys(data[0]);
          this.templateNameList = this.templateNameList.filter(item => item.toLowerCase() != 'category' && item.toLowerCase() != 'casetype' && item.toLowerCase() != 'success')
        }

        return data;
      }),
      catchError(error => {
        console.log(error);
        return of([]);
      })
    ).subscribe();
  }

  GetTemplateName(data) {
    if (this.validateForm(data)) {
      // Validate controls and values
      const caseTypeName = data.controls.casetypename ?.value ?.toLowerCase().trim();
      const templateType = data.controls.templatetype ?.value;

      if (!caseTypeName || !templateType) {
        console.error('casetypename or templatetype value is missing');
        return;
      }

      const tempCurrentName = this.casetypeTemplateData.filter(item =>
        item.Casetype.toLowerCase() === caseTypeName && item[templateType]
      );

      if (tempCurrentName.length > 0 && tempCurrentName[0][templateType]) {
     
          this.isCurrentTemplateAvailble=true
          this.frm_EditTemplate.patchValue({
            currentfilename: tempCurrentName[0][templateType].toString()
          });
        
        

        this.isGetDataClicked = true;
      } else {
        this.isGetDataClicked = true;
        this.isCurrentTemplateAvailble=false
        console.error('No matching template found or templateType property missing');
      }
    }
  }

  validateForm(data: any): any {
    if (!data.controls.deploymentname.value.trim()) {
      this._snackBar.open("Deployment Required")
      return
    } else if (!data.controls.casetypename.value.trim()) {
      this._snackBar.open("Casetype Required")
      return
    } else if (!data.controls.templatetype.value.trim()) {
      this._snackBar.open("Templatetype Required")
      return
    }
    return true
  }




  async onSaveReportTemplate(formValue, optionsformValue) {
    if (!formValue.controls.newfilename.value.trim()) {
      this._snackBar.open("New template name required", 'Close');
      this.frm_EditTemplate.patchValue({
        newfilename: ''
      })
      return
    }
    await this.editTemplateName(formValue.controls.newfilename.value.trim(), optionsformValue)
  }

  async editTemplateName(value, optionsformValue) {

    let tempJson = {
      CaseType: optionsformValue.controls.casetypename.value.toString(),
      Accid: 0,
      Template: optionsformValue.controls.templatetype.value.toString(),
      Action: 'update',
      NewFileName: !value ?.trim() ? null : value.toString(),
      TableName: 'Casetypes',
      Accounttype:''
    };

    this.ngxservice.start();

    this.vaservices.getReportTemplateName(tempJson,this.dbName).pipe(
      tap(res => this.ngxservice.stop()), // Stop the loader regardless of the response
      map(res => {
        if (!res[0].Success) {
          throw new Error('No template found');
        } else {
          this._snackBar.open("Updated Successfully", 'Close');
          this.getDropDownData()
          this.CloaseModalPage()
        }
      }),
      catchError(error => {
        this._snackBar.open(error.message, 'Close');
        return of(null); // Return an observable to complete the stream
      }),
      finalize(() =>
        this.ngxservice.stop()
      ) // Ensure the loader is stopped on completion
    ).subscribe();
  }

  CloaseModalPage() {
    this.frm_EditTemplate.reset()
    this.frm_Template.reset()
    this.isGetDataClicked = false
    this.close.emit();
  }
  isBtnDisabled(formval){
    if(!formval.controls.deploymentname.value.trim() || !formval.controls.casetypename.value.trim() || !formval.controls.templatetype.value.trim()){
        return true
    }
    return false
  }
  resetFields(){
    this.isGetDataClicked = false;
    this.frm_EditTemplate.controls['newfilename'].setValue('');
    }
  
    copy(form) {
      this.clipboard.copy(form.controls.currentfilename.value);
      this._snackBar.open("Copied Successfully", "Close");
    }

}
