export class UserIP {
    IPAdd: string;
    CountryName: string;
    CountrySName: string;

    constructor(IPAdd: string,countryName: string, countrySName: string) {
        this.IPAdd = IPAdd;
        this.CountryName = countryName;
        this.CountrySName = countrySName;
    }
}
