<div class="mat-dialog-header border-bottom license-alert-header">
    <div class="col-sm-12">
        <div class="row">
            <span class="col-sm-1 mb-0 mt-2"></span>
            <span class="col-sm-10 mb-0 mt-2">
                <h3 class="p-0 m-0">
                    <strong class="header-View header-font">Edit Group</strong>
                </h3>
            </span>
            <span class="col-sm-1 selectALL align-status p-0">
                <button class="p-0 m-0" [disabled]="isBtnDisabled" #closeModelBtn mat-icon-button matDialogClose
                    tabindex="-1">
                    <mat-icon class="p-0 m-0" aria-label="Close dialog">close</mat-icon>
                </button>
            </span>
        </div>
    </div>
    <div class="col-sm-12 scroll-page">
        <section [formGroup]="OrgFormGrp">
            <mat-accordion [multi]="true" class="mat-expansion-demo-width">
                <mat-expansion-panel class="remove-mat-expansion-panel-padding" #panel1 (click)="panel1.expanded = true"
                    [expanded]="true" [hideToggle]="true">
                    <mat-expansion-panel-header class="my-expansion-panel-header">Group
                        Information</mat-expansion-panel-header>
                    <section>
                        <div class="section-division pt-2">
                            <div class="first-col">
                                <div class="upload-image" [ngClass]="selectedImage ? 'logo' : 'afterdelete'"
                                    (appFileDragDrop)="onDrop($event)" top="30%" right="25%">
                                    <ng-container *ngIf="selectedImage; else uploadlogo">
                                        <img id="logo" [src]="selectedImage" height="100" />
                                        <div id="seperator" *ngIf="selectedImage"></div>
                                        <div id="icons">
                                            <img id="download" class="upload-logo-icons upload-icon"
                                                matTooltip="Download" *ngIf="selectedImage"
                                                src="/assets/images_vla/Download.svg" alt="download" draggable="false"
                                                (click)="downloadImage(selectedImage)" />
                                            <img [id]="'group'" class="upload-logo-icons" *ngIf="selectedImage"
                                                matTooltip="ReUpload" src="/assets/images_vla/Replace_logo.svg"
                                                (click)="triggerFileInput.click()" draggable="false" />
                                            <input #triggerFileInput type="file" (change)="onFileSelected($event)"
                                                click="value = null" value="" style="display: none" />
                                            <img id="delete" class="upload-logo-icons" *ngIf="selectedImage"
                                                matTooltip="Delete" src="/assets/icons/delete-red.svg" alt="delete"
                                                (click)="onDeleteLogo()" draggable="false" />
                                        </div>
                                    </ng-container>
                                    <ng-template #uploadlogo>
                                        <div class="after-delete-background-color" (click)="triggerFileInput.click()"
                                            height="100">
                                            <img id="upload" class="upload-logo-icons upload-icon"
                                                src="/assets/images_vla/Upload.svg" matTooltip="Upload"
                                                draggable="false" />
                                            <input #triggerFileInput type="file" (change)="onFileSelected($event)"
                                                style="display: none" />
                                            <span class="uploadlogo-text">UPLOAD LOGO</span> <br>
                                            <div class="upload-text p-0 m-0 pt-2"><b>Click to Upload or Drag Drop the
                                                    Logo File</b></div>
                                            <div class="file-text p-0 m-0">File Format: .jpeg / .jpg / .png </div>
                                            <div class="file-text p-0 m-0">File Size: Less than 5MB</div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <app-custom-input required="true" labelValue="Group Name" maxLength="255"
                                formcontrolname="frmGroupname" (input)="getMnemonic(OrgFormGrp.value.frmGroupname)"
                                #myInput placeholder="Enter Group Name"></app-custom-input>
                            <app-custom-input labelValue="NPI" maxLength="10" formcontrolname="frmNpi"
                                placeholder="Enter NPI"></app-custom-input>
                            <app-custom-input labelValue="CLIA" maxLength="10" formcontrolname="frmClia"
                                placeholder="Enter Clia"></app-custom-input>
                        </div>
                    </section>

                    <section>
                        <div class="input-width pt-2">
                            <app-custom-input required="true" labelValue="Mnemonic" maxLength="18" formcontrolname="frmMnemonic"
                                placeholder="Enter Mnemonic"></app-custom-input>
                            <app-custom-input labelValue="State LIC" maxLength="25" formcontrolname="frmStatelic"
                                placeholder="Enter State LIC"></app-custom-input>
                            <app-custom-input labelValue="URL" maxLength="25" formcontrolname="frmUrl"
                                placeholder="Enter URL"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input class="mr-2" labelValue="Lab Group ID" mask="0000000000" maxLength="10"
                                formcontrolname="frmLabgroupid" placeholder="Enter Lab Group ID"></app-custom-input>
                            <app-custom-input labelValue="Unique ID" maxLength="50" formcontrolname="frmUniqueid"
                                placeholder="Enter Unique ID"></app-custom-input>
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="auto-input-field">
                                        <input #input type="text" #trigger="matAutocompleteTrigger"
                                            placeholder="Select Name Display Format"
                                            formControlName="frmUsernamedisplayformat" [matAutocomplete]="auto"
                                            class="select-input" (focus)="onInputFocus('frmUsernamedisplayformat');trigger.openPanel();"
                                            matTooltip="{{OrgFormGrp.value.frmUsernamedisplayformat}}"
                                            matTooltipPosition="below" />
                                        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                            (opened)="dropdown.classList.add('showRotate')"
                                            (closed)="dropdown.classList.remove('showRotate')"
                                            (onSelection)="onFormatSelection($event,'web')">
                                            <mat-option *ngFor="let dropDown of UsernameWebFormat"
                                                [value]="dropDown.item" class="disble-right-sign">
                                                <div matTooltip="{{ dropDown.item }}" matTooltipPosition="right"> {{
                                                    dropDown.item }}</div>
                                            </mat-option>
                                            <mat-option *ngIf="UsernameWebFormat && UsernameWebFormat.length === 0"
                                                disabled>{{"No data found"}}</mat-option>
                                        </mat-autocomplete>
                                        <div id="img-toggle"
                                            (click)="openOrCloseDD($event, trigger, input,'frmUsernamedisplayformat')">
                                            <img #dropdown class="cusor-pointer"
                                                src="../../../../assets/images/expand.png" alt="expand">
                                        </div>
                                    </div>
                                    <label>Name Display Format</label>
                                </div>
                            </div>
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="auto-input-field">
                                        <input #input2 type="text" #trigger2="matAutocompleteTrigger"
                                            placeholder="Select Reports Display Format"
                                            formControlName="frmUsernamereportformat" [matAutocomplete]="auto2"
                                            class="select-input" (focus)="onInputFocus('frmUsernamereportformat');trigger2.openPanel();"
                                            matTooltip="{{OrgFormGrp.value.frmUsernamereportformat}}"
                                            matTooltipPosition="below" />
                                        <mat-autocomplete #auto2="matAutocomplete" autoActiveFirstOption
                                            (opened)="dropdown2.classList.add('showRotate')"
                                            (closed)="dropdown2.classList.remove('showRotate')"
                                            (onSelection)="onFormatSelection($event)">
                                            <mat-option *ngFor="let dropDown of UsernameReportFormat"
                                                [value]="dropDown.item" class="disble-right-sign">
                                                <div matTooltip="{{ dropDown.item }}" matTooltipPosition="right"> {{
                                                    dropDown.item }}</div>
                                            </mat-option>
                                            <mat-option
                                                *ngIf="UsernameReportFormat && UsernameReportFormat.length === 0"
                                                disabled>{{"No data found"}}</mat-option>
                                        </mat-autocomplete>
                                        <div id="img-toggle"
                                            (click)="openOrCloseDD($event, trigger2, input2,'frmUsernamereportformat')">
                                            <img #dropdown2 class="cusor-pointer"
                                                src="../../../../assets/images/expand.png" alt="expand">
                                        </div>
                                    </div>
                                    <label>Reports Display Format</label>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="auto-input-field">
                                        <input #input1 type="text" #trigger1="matAutocompleteTrigger" placeholder="Select Time Zone to display"
                                            formControlName="frmTimezone" [matAutocomplete]="auto1" class="select-input"
                                            (focus)="onInputFocus('frmTimezone');trigger1.openPanel();"
                                            matTooltip="{{OrgFormGrp.value.frmTimezone}}" matTooltipPosition="below" />
                                        <mat-autocomplete #auto1="matAutocomplete" autoActiveFirstOption
                                            (opened)="dropdown1.classList.add('showRotate')"
                                            (closed)="dropdown1.classList.remove('showRotate')">
                                            <mat-option *ngFor="let dropDown of Timezones" [value]="dropDown.item" class="disble-right-sign">
                                                <div matTooltip="{{ dropDown.item }}" matTooltipPosition="right"> {{
                                                    dropDown.item }}</div>
                                            </mat-option>
                                            <mat-option *ngIf="Timezones && Timezones.length === 0" disabled>{{"No datafound"}}</mat-option>
                                        </mat-autocomplete>
                                        <div id="img-toggle" (click)="openOrCloseDD($event, trigger1, input1,'frmTimezone')">
                                            <img #dropdown1 class="cusor-pointer" src="../../../../assets/images/expand.png" alt="expand">
                                        </div>
                                    </div>
                                    <label>Time Zone</label>
                                </div>
                            </div>
                            <div class="col-sm-3 p-0">
                                <div class="col-sm-12 d-flex status-wrap mt-3">
                                    <div class="sub-heading d-flex"><b>Status:</b></div>
                                    <div class="col-sm-12 d-flex p-0 m-0">
                                        <div class="pr-0 mr-1 ml-2 sub-heading marg-top">{{OrgFormGrp.value.frmIsactive ?
                                            'Active':'Inactive'}}</div>
                                        <div class="col-sm-2 p-0 m-0">
                                            <app-toggle-switch formcontrolname="frmIsactive" type="secondary"></app-toggle-switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </mat-expansion-panel>
                <mat-expansion-panel #panel2 (click)="panel2.expanded = true" [expanded]="true" [hideToggle]="true">
                    <mat-expansion-panel-header class="my-expansion-panel-header">Contact
                        Information</mat-expansion-panel-header>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Address Line 1" maxLength="255" formcontrolname="frmAddress1"
                                placeholder="Enter Address Line 1"></app-custom-input>
                            <app-custom-input labelValue="Address Line 2" maxLength="255" formcontrolname="frmAddress2"
                                placeholder="Enter Address Line 2"></app-custom-input>
                            <app-custom-input labelValue="City" maxLength="25" formcontrolname="frmCity"
                                placeholder="Enter City"></app-custom-input>
                            <app-custom-input labelValue="Country" maxLength="25" formcontrolname="frmCountry"
                                placeholder="Enter Country"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-select id="state" [required]="false" labelValue="State"
                                formcontrolname="frmState" defaultOption="Select State" dropDownKeyProp="item"
                                dropDownValueProp="itemValue" [dropDownValues]="StateList"></app-custom-select>
                            <app-custom-input labelValue="Zip" mask="00000-0000" maxLength="10" formcontrolname="frmZip"
                                (change)="getStateAndCity()" placeholder="Enter Zip"></app-custom-input>
                            <app-custom-input labelValue="Phone" maxLength="25" formcontrolname="frmPhone"
                                placeholder="Enter Phone" mask="000.000.0000x0000000000"></app-custom-input>
                            <app-custom-input labelValue="Email" maxLength="1000" formcontrolname="frmEmail"
                                placeholder="Enter Email"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Fax" maxLength="55" formcontrolname="frmFax"
                                placeholder="Enter Fax"
                                mask="000.000.0000,000.000.0000,000.000.0000,000.000.0000"></app-custom-input>
                            <app-custom-input labelValue="Billing Phone Number" maxLength="25"
                                formcontrolname="frmBillingphonenumber"
                                placeholder="Enter Billing Phone Number"></app-custom-input>
                        </div>
                    </section>
                </mat-expansion-panel>
                <mat-expansion-panel #panel3 (click)="panel3.expanded = true" [expanded]="true" [hideToggle]="true">
                    <mat-expansion-panel-header
                        class="my-expansion-panel-header">Configurations</mat-expansion-panel-header>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-select id="logintype" [required]="false" labelValue="Login Type"
                                formcontrolname="frmLogintype" defaultOption="Select Login Type" dropDownKeyProp="item"
                                dropDownValueProp="item" [dropDownValues]="loginTypeList"></app-custom-select>
                            <app-custom-input labelValue="GUID" isReadOnly="true" maxLength="100"
                                formcontrolname="frmGuid" placeholder="Enter GUID"></app-custom-input>
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="input-with-icon">
                                        <input #ref placeholder="Enter External GUID" type="text"
                                            formControlName="frmExternalorgguid" [readonly]="isDisabledExtGUID()"
                                            maxLength="100" />
                                        <i class="fa fa-refresh" matTooltip="Reset"
                                            [ngStyle]="{'cursor': isDisabledExtGUID() ? null : 'pointer' }"
                                            (click)="OrgFormGrp.get('frmExternalorgguid').setValue(commonService.generateGuid())"
                                            aria-hidden="true"></i>
                                    </div>
                                    <label>External GUID</label>
                                </div>
                            </div>

                            <app-custom-input labelValue="App URL" maxLength="255" formcontrolname="frmAppurl"
                                placeholder="Enter App URL"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="VitalOrders URL" maxLength="255" formcontrolname="frmVourl"
                                placeholder="Enter VitalOrders URL"></app-custom-input>
                            <app-custom-input labelValue="Patient Portal URL" maxLength="1000"
                                formcontrolname="frmPatientportalurl"
                                placeholder="Enter Patient Portal URL"></app-custom-input>
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="auto-input-field">
                                        <input #input3 type="text" #trigger3="matAutocompleteTrigger"
                                            placeholder="Search Patient Portal Location"
                                            formControlName="frmPatientportalaccountid" [matAutocomplete]="auto3"
                                            class="select-input" (focus)="onInputFocus('frmPatientportalaccountid');trigger3.openPanel();"
                                            matTooltip="{{OrgFormGrp.value.frmPatientportalaccountid?.accountname}}"
                                            matTooltipPosition="below" />
                                        <mat-autocomplete #auto3="matAutocomplete" autoActiveFirstOption
                                            (opened)="dropdown3.classList.add('showRotate')"
                                            (closed)="dropdown3.classList.remove('showRotate')"
                                            (optionSelected)="getOFPhysicians($event.option?.value?.accountid)"
                                            [displayWith]="displayFnLoc">
                                            <mat-option *ngFor="let loc of filteredOFLocation | async" [value]="loc"
                                                class="disble-right-sign">
                                                <div matTooltip="{{ loc.accountname }}" matTooltipPosition="right">
                                                    {{ loc.accountname }}</div>
                                            </mat-option>
                                            <!-- <mat-option *ngIf="!(filteredOFLocation | async)?.length" disabled>
                                                {{"No result found"}}
                                            </mat-option> -->
                                        </mat-autocomplete>
                                        <div id="img-toggle"
                                            (click)="openOrCloseDD($event, trigger3, input3,'frmPatientportalaccountid')">
                                            <img #dropdown3 class="cusor-pointer"
                                                src="../../../../assets/images/expand.png" alt="expand">
                                        </div>
                                    </div>
                                    <label>Patient Portal Location</label>
                                </div>
                            </div>
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="auto-input-field">
                                        <input #input4 type="text" #trigger4="matAutocompleteTrigger"
                                            placeholder="Search Patient Portal Physician"
                                            formControlName="frmPatientportalphysicianid" [matAutocomplete]="auto4"
                                            class="select-input"
                                            (focus)="onInputFocus('frmPatientportalphysicianid');trigger4.openPanel();"
                                            matTooltip="{{OrgFormGrp.value.frmPatientportalphysicianid?.physicianname}}"
                                            matTooltipPosition="below" />
                                        <mat-autocomplete #auto4="matAutocomplete" autoActiveFirstOption
                                            (opened)="dropdown4.classList.add('showRotate')"
                                            (closed)="dropdown4.classList.remove('showRotate')"
                                            [displayWith]="displayFnPhy">
                                            <mat-option *ngFor="let phy of filteredOFPhysician | async" [value]="phy"
                                                class="disble-right-sign">
                                                <div matTooltip="{{ phy.physicianname }}" matTooltipPosition="right"> {{
                                                    phy.physicianname }}</div>
                                            </mat-option>
                                            <!-- <mat-option
                                                *ngIf="(filteredOFPhysician | async)?.length == 0"
                                                disabled>{{"No data found"}}</mat-option> -->
                                        </mat-autocomplete>
                                        <div id="img-toggle"
                                            (click)="openOrCloseDD($event, trigger4, input4,'frmPatientportalphysicianid')">
                                            <img #dropdown4 class="cusor-pointer"
                                                src="../../../../assets/images/expand.png" alt="expand">
                                        </div>
                                    </div>
                                    <label>Patient Portal Physician</label>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Cover Page Template" maxLength="250"
                                formcontrolname="frmCoverpagetemplate"
                                placeholder="Enter Cover Page Template"></app-custom-input>
                            <app-custom-input labelValue="Hold Release Duration(In minutes)" maxLength="2" mask="00"
                                formcontrolname="frmHoldreleaseduration"
                                placeholder="Enter Hold Release Duration(In minutes)"></app-custom-input>
                            <app-custom-input labelValue="Reference Lab" maxLength="400"
                                formcontrolname="frmReferencelab" placeholder="Enter Reference Lab"></app-custom-input>
                            <app-custom-input labelValue="Batching Mnemonic" maxLength="400"
                                formcontrolname="frmBatchingmnemonic"
                                placeholder="Enter Batching Mnemonic"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division-checkboxlist pt-2">
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmDiagicdcodereminder'><b>Notify Users To Provide Diagnostic ICD
                                    Codes</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmShowreviewpathsignature'><b>Show Review Pathologist
                                    Signature</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmAutopopulatediagicd9codes'><b>Auto Populate Diagnostic ICD
                                    Codes</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmIsofallowfullfilltests'><b>Pathologist Fulfill
                                    Tests</b></mat-checkbox>
                        </div>
                    </section>
                    <section>
                        <div class="section-division-checkboxlist pt-2">
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmIsvitalordersenabled'><b>VitalOrders Enabled</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary' formControlName='frmIstestorganization'
                                (click)="isTestEnabled()"><b>Test Group</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmShowonlyaccountdetails'><b>Show Only Location
                                    Details</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary' formControlName='frmIsorgsetuppending'
                                (click)="isTestEnabled()"><b>Group Setup Pending</b></mat-checkbox>
                        </div>
                    </section>
                    <section>
                        <div class="section-division-checkboxlist pt-2">
                            <mat-checkbox class="p-0 m-0" color='primary' formControlName='frmAdtenabled'><b>ADT
                                    Enabled</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary'
                                formControlName='frmSendoutdisclaimer'><b>Sendout Disclaimer</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary' formControlName='frmLoadbydefault'><b>Load By
                                    Default</b></mat-checkbox>
                            <mat-checkbox class="p-0 m-0" color='primary' formControlName='frmDefaultlab'><b>Default
                                    Lab</b></mat-checkbox>
                        </div>
                    </section>
                    <section>
                        <div class="section-division-checkboxlist pt-2">
                            <mat-checkbox class="p-0 m-0" color='primary' formControlName='frmIsadenabled'><b>AD
                                    Enabled</b></mat-checkbox>
                        </div>
                    </section>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="!hideGoLiveInfo && !(orgType == 'facility')" #panel4
                    (click)="panel4.expanded = true" [expanded]="true" [hideToggle]="true">
                    <mat-expansion-panel-header class="my-expansion-panel-header">Go Live
                        Information</mat-expansion-panel-header>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Group Description" maxLength="255"
                                formcontrolname="frmOrganizationdescription"
                                placeholder="Enter Group Description"></app-custom-input>
                            <app-custom-input labelValue="Group Type" maxLength="255" formcontrolname="frmOrgtype"
                                placeholder="Enter Group Type"></app-custom-input>
                            <app-custom-input labelValue="Invoice Code" maxLength="50" formcontrolname="frmInvoicecode"
                                placeholder="Enter Invoice Code"></app-custom-input>

                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="input-with-icon">
                                        <input [readonly]="true" #goLiveDatepickerInput matInput
                                            [placeholder]="'mm/dd/yyyy'" formControlName="frmGolivedate"
                                            [matDatepicker]="picker" (mouseleave)="picker.close();">
                                        <img (click)="picker.open()" src="../../../../assets/icons/calendar_icon.svg"
                                            alt="Calendar" class="icon-size">
                                        <mat-datepicker #picker></mat-datepicker>
                                    </div>
                                    <label>Go Live Date</label>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Release Version" maxLength="100"
                                formcontrolname="frmReleaseversion"
                                placeholder="Enter Release Version"></app-custom-input>
                            <span>
                                <div class="sub-heading"><b>Customer Status</b></div>
                                <div class="row p-0 m-0">
                                  <div class="col-sm-4 p-0 d-flex">
                                    <div class=" p-0 pt-2 mr-1 sub-heading">
                                        {{OrgFormGrp.value.frmCustomerstatus ?
                                        'Active:':'Inactive:'}}</div>
                                    <div class="p-0 m-0 pt-2"> <app-toggle-switch
                                            formcontrolname="frmCustomerstatus" type="secondary"></app-toggle-switch>
                                    </div>
                                  </div>
                                    <div class="col-sm-8 p-0 m-0 selectALL align-status"><mat-checkbox class="p-0 m-0"
                                            color='primary'
                                            formControlName='frmIscustomer'><b>Customer</b></mat-checkbox></div>
                                </div>
                            </span>
                        </div>
                    </section>
                </mat-expansion-panel>
                <mat-expansion-panel #panel5 [expanded]="false" [hideToggle]="false">
                    <mat-expansion-panel-header class="my-expansion-panel-header">Additional
                        Information</mat-expansion-panel-header>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Group Code" maxLength="25"
                                formcontrolname="frmOrganizationcode" placeholder="Enter Group Code"></app-custom-input>
                            <app-custom-input labelValue="State Display Name" maxLength="50"
                                formcontrolname="frmStatedisplayname"
                                placeholder="Enter State Display Name"></app-custom-input>
                            <app-custom-input labelValue="Jar Label Template" maxLength="500"
                                formcontrolname="frmJarlabeltemplate"
                                placeholder="Enter Jar Label Template"></app-custom-input>
                            <app-custom-input labelValue="Storage ID" maxLength="19" mask="0000000000000000000"
                                formcontrolname="frmStorageid" placeholder="Enter Storage ID"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="input-with-icon">
                                        <input [readonly]="true" #datepickerInput matInput [placeholder]="'mm/dd/yyyy'"
                                            formControlName="frmArchivedtilldate" [matDatepicker]="pickerTillDate"
                                            (mouseleave)="pickerTillDate.close();">
                                        <img (click)="pickerTillDate.open()"
                                            src="../../../../assets/icons/calendar_icon.svg" alt="Calendar"
                                            class="icon-size">
                                        <mat-datepicker #pickerTillDate></mat-datepicker>
                                    </div>
                                    <label>Archived Till Date</label>
                                </div>
                            </div>
                            <app-custom-input labelValue="External Practice GUID" maxLength="100"
                                formcontrolname="frmExternalpracticeguid"
                                placeholder="Enter External Practice GUID"></app-custom-input>
                            <app-custom-input labelValue="Group Version" mask="0000000000" maxLength="10"
                                formcontrolname="frmOrgversion" placeholder="Enter Group Version"></app-custom-input>
                            <app-custom-input labelValue="URL Type" maxLength="1000" formcontrolname="frmUrltype"
                                placeholder="Enter URL Type"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Taxonomy Code" maxLength="10"
                                formcontrolname="frmTaxonomycode" placeholder="Enter Taxonomy Code"></app-custom-input>
                            <app-custom-input labelValue="Sendout Order Hold Duration" maxLength="2"
                                formcontrolname="frmSendoutorderholdduration" mask="00"
                                placeholder="Enter Sendout Order Hold Duration"></app-custom-input>
                            <app-custom-select id="Collectiondateformat" [required]="false"
                                labelValue="Collection Date Format" formcontrolname="frmCollectiondateformat"
                                defaultOption="Select Collection Date Format" dropDownKeyProp="format"
                                dropDownValueProp="format" [dropDownValues]="dateFormat"></app-custom-select>
                            <app-custom-select id="Receiveddateformat" [required]="false"
                                labelValue="Received Date Format" formcontrolname="frmReceiveddateformat"
                                defaultOption="Select Received Date Format" dropDownKeyProp="format"
                                dropDownValueProp="format" [dropDownValues]="dateFormat"></app-custom-select>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-select id="Packagedateformat" [required]="false"
                                labelValue="Package Date Format" formcontrolname="frmPackagedateformat"
                                defaultOption="Select Package Date Format" dropDownKeyProp="format"
                                dropDownValueProp="format" [dropDownValues]="dateFormat"></app-custom-select>
                            <app-custom-select id="ETAdateformat" [required]="false" labelValue="ETA Date Format"
                                formcontrolname="frmEtadateformat" defaultOption="Select ETA Date Format"
                                dropDownKeyProp="format" dropDownValueProp="format"
                                [dropDownValues]="dateFormat"></app-custom-select>
                            <app-custom-input labelValue="Support ID" maxLength="10" formcontrolname="frmSupportid"
                                placeholder="Enter Support ID"></app-custom-input>
                            <app-custom-input labelValue="Template Group ID" maxLength="10"
                                formcontrolname="frmTemplateorganizationid" mask="0000000000"
                                placeholder="Enter Template Group ID"></app-custom-input>
                        </div>
                    </section>
                    <section>
                        <div class="section-division pt-2">
                            <app-custom-input labelValue="Spawn Hold Duration" maxLength="2"
                                formcontrolname="frmSpawnholdduration" mask="00"
                                placeholder="Enter Spawn Hold Duration"></app-custom-input>
                            <div class="input-container m-0">
                                <div class="input-elements custom-field">
                                    <div class="input-with-icon">
                                        <input [readonly]="true" #lastDatepickerInput matInput
                                            [placeholder]="'mm/dd/yyyy'" formControlName="frmLastarchiveddate"
                                            [matDatepicker]="pickerArchived" (mouseleave)="pickerArchived.close();">
                                        <img (click)="pickerArchived.open()"
                                            src="../../../../assets/icons/calendar_icon.svg" alt="Calendar"
                                            class="icon-size">
                                        <mat-datepicker #pickerArchived></mat-datepicker>
                                    </div>
                                    <label>Last Archived Till Date</label>
                                </div>
                            </div>
                            <app-custom-input *ngIf="!isP4Deployment" labelValue="Source GUID" maxLength="100"
                                formcontrolname="frmSourceguid" placeholder="Enter Source GUID"></app-custom-input>
                        </div>
                    </section>
                </mat-expansion-panel>


                <mat-expansion-panel #panel6 [expanded]="false" [hideToggle]="false" class="mt-2 mb-2"
                    *ngIf="showAssignCard">
                    <mat-expansion-panel-header class="my-expansion-panel-header">VitalAxis
                        Representatives</mat-expansion-panel-header>
                    <section>
                        <app-vital-axis-assign-representatives
                            *ngIf="representativeUsersList && representativeRolesList?.length && selectedRepresentatives"
                            [openedLocation]="'Edit Group'" [deploymentKey]="deploymentKey"
                            [originalUsersList]="representativeUsersList" [originalRolesList]="representativeRolesList"
                            [isShowAssign]="false" (emitSelectedUsersArray)="selectedUsersFromFrameWork($event)"
                            [finalAssignedUsers]="selectedRepresentatives">
                        </app-vital-axis-assign-representatives>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </section>
    </div>
    <div class="col-sm-12 footer-section pt-1">
        <div class="row footer-section">
            <span class="col-sm-6 clear-all" (click)="patchData()">Reset</span>
            <span class="col-sm-6 selectALL align-status">
                <!-- <app-button [isDisabled]="isBtnDisabled" buttonclass="secondary" class="edit-page-button" mat-button
                    matDialogClose>Return</app-button> -->
                <app-button class="ml-auto col-1 edit-page-button" [isDisabled]="isBtnDisabled" buttonclass="primary"
                    (click)="isOrganizationExists(OrgFormGrp.value)">Save</app-button>
            </span>
        </div>
    </div>
</div>
