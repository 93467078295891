import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  MatDialog,  MatDialogConfig } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, lastValueFrom, async, interval } from 'rxjs';
import { VitalAdminTabService } from 'src/app/client/tab.service';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { UserSession } from 'src/app/core/services/user.session';
import { environment } from 'src/environments/environment';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { MainCardModel } from '../../DbModel/MainCardModel';
import { OrgCompareActionBtnComponent } from '../OrgCompareActionBtn/OrgCompareActionBtn.component';
import { ManageOrganizationComponent } from '../manage-organization/manage-organization.component';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import * as XLSX from 'xlsx';
import { DomSanitizer } from '@angular/platform-browser';

declare var $;
@Component({
  selector: 'app-actionbtn',
  templateUrl: './actionbtn.component.html',
  styleUrls: ['./actionbtn.component.scss']
})
export class ActionbtnComponent implements OnInit {
  @Input() cardtype: any;
  @Input() casetype: any;
  @Input() actions: any;
  @Input() secondary: any;
  @Input() mainCard: any;
  @Input() UserType: any;
  @Input() userStatus: any;
  @Input() userId: any;
  @Input() loginName: any;
  @Input() actionbutton: any;
  @Input() MenuJson: any;
  @Input() templateData:any;
  @Input() roleMenuPermissions: any;
  @Output() filesAction = new EventEmitter();
  public Base64jsonData: any;
  public isImpbtnDisable: boolean = true;
  public ifUnlockClicked = false;
  public ifPasswordClicked = false;
  public ifUserUnlockedSuccess = false;
  public UserUnlockMessage;
  public passwordGen;
  public ifPasswordGenerated = false;
  public textToCopy;
  public checked;
  public ifUserStatus = false;
  public msg;
  public inituserStatus;
  public initChecked;
  public tf: string = "";
  fileName: string = "";
  public ifApproveRequest = false;
  public SubMenuCardModel;
  public showSearch = true;
  public approveResponse;
  public searchValue;
  importbutton: number;
  public unlock: boolean;
  public exportfile: boolean = true;
  public orgcompare: boolean = true;
  public inactiveuser: boolean;
  public regeneratepassword: boolean;
  public approvepasswordrequest: boolean;
  public populateCaseList: boolean;
  public populateASCCases: boolean;
  menuType: string;
  SelectedMenuModel: string;
  GenericModalClass: string;
  public fromData: string = "actionButtoninLab";
  modalID: number = 1;
  buttonaction: any;
  importFileButton: boolean = false;
  htnapproved: boolean = true;
  organizationLoginType: any;
  email: any;
  userid: any;
  ifResetPasswordClicked: boolean = true;
  migratuser: boolean;
  dialog: any;
  userLoginType: any;
  // deploymentCheck: boolean;
  organizationid: any;
  commonKey: any;
  public allowLogin: any;
  public duplicatemaincardvalue: any;
  editGroupButton: boolean;
  userAuthStatus: any;
  emailUpdated: any;
  LogName: any;
  UserName: any;
  status: number;
  organizationguid: any;
  userloginname: any;
  UserEmail: any;
  deploymentKey: any;
  userRepresentatives: any;
  auditableColumns: any;
  patientReportTheme: boolean;
  url: string;
  endpoint: string;
  cardTypeValue: any;
  reportBuilderEndPoint: string;
  reportBuilder: boolean;

  constructor(public vitalHttpServices: VitalHttpServices, private datashare: DataShareService, private commonService: CommonService,
    private ngxService: NgxUiLoaderService, private _snackbar: MatSnackBar, private usersession: UserSession,private activityService: ActivityTrackerService,
    private httpClient: HttpClient, public MainCardModel: MainCardModel,
    public matDialog: MatDialog, public VitalAdminTabService: VitalAdminTabService, private clipboard: Clipboard, public sanitizer: DomSanitizer) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare)
  }
  subscription: Subscription;
  secondarykeys;
  isGroupCard: Boolean = false;

  ngOnInit(): void {
    this.vitalAxisRepresentatives();
    //this.deploymentKey=sessionStorage.getItem('deploymentkey');
    try{
    this.Associate_role();

    this.cardTypeValue = sessionStorage.getItem('context');
    //this.vitalHttpServices.SubmenuAction = this.roleMenuPermissions;
    this.vitalHttpServices.Action = this.actionbutton;
    //this.datashare.getMenuButtonData(this.roleMenuPermissions);
    //this.datashare.getActionButtonData(this.actionbutton);
    // this.deploymentCheck = false;
    this.orgLoginType();
    this.usersAuthStatus()
    this.isGroupCard = ['orgid','location','facility','accid'].includes(this.cardtype?.toString().toLowerCase()) ? true : false;
    }
    catch(error) {
      console.error(error);
      this._snackbar.open('An error occurred while processing your request','Close');
      this.ngxService.stop('entityLoad');
    }
    if(['users', 'physician', 'pathologist'].includes(this.cardtype?.toString().toLowerCase())){
      this.emailUpdated = this.VitalAdminTabService.emailUpdated;
    }
    }

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  async orgLoginType() {
    if (['users', 'physician', 'pathologist'].includes(this.cardtype?.toString().toLowerCase())) {
      let queryVariable = { "loginname": this.mainCard.Login_Name };
      let query = this.vitalHttpServices.GetQuery("getorgidfromuserid");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      await this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
        if (res) {
          this.organizationLoginType = res.data.submenuData.OrganizationLoginType
          this.userLoginType = res.data.submenuData.UserLoginType
          this.email = res.data.submenuData.email
          this.UserEmail = res.data.submenuData.email
          this.allowLogin = res.data.submenuData.AllowLogin
          this.userid = res.data.submenuData.userid
          this.organizationid = res.data.submenuData.organizationid
          this.commonKey = res.data.submenuData.CommonKey
          this.datashare.userCard = { email : this.email,userid : this.userid , allowLogin : this.allowLogin, userStatus : this.userStatus,loginName:this.mainCard.Login_Name}
          this.getAssociatedUsers(this.commonKey);
        }
      }, error => {
        console.error(error);
      })
    }
  }

  async usersAuthStatus() {
    if (['users', 'physician', 'pathologist'].includes(this.cardtype?.toString().toLowerCase())) {
      let queryVariable = { "loginname": this.mainCard.Login_Name };
      let query = this.vitalHttpServices.GetQuery("getUserlogintype");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      await this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
        if (res) {
          this.userAuthStatus = res.data.submenuData[0].UserAuthStatus
          this.LogName = res.data.submenuData[0].UpdatedLoginName
          this.UserName = res.data.submenuData[0].UserName
          this.organizationguid = res.data.submenuData[0].OrganizationGUID
          this.userloginname = res.data.submenuData[0].loginname
        }
      }, error => {
        console.error(error);
      })
    }
  }
  async getAssociatedUsers(commonKey: any): Promise<any> {
    let queryVariable : any = { "commonkey": commonKey.toString() };
      let query = this.vitalHttpServices.GetQuery("getusersfromcommonkey");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      await this.vitalHttpServices.GetData(queryResult).toPromise().then(res => {
        if (res) {
          this.datashare.userData = res.data.submenuData;
          queryVariable = { keyword: 'EligibleRoles',context : 'Users' };
          query = this.vitalHttpServices.GetQuery("CategoryDropDown");
          queryResult = this.commonService.GetCardRequest(queryVariable, query);
          this.vitalHttpServices.GetData(queryResult,'configdb').subscribe(res => {
            this.datashare.eligibleRoles = JSON.parse(res.data.submenuData[0].Items_JSON);
          },error =>{
            console.error(error);
          })
        }
      }, error => {
        console.error(error);
      })
  }


   async resetPasswordPopup() {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (re.test(this.datashare.userCard.email) == false) {
      let dialogRef = this.matDialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Reset Password", message: "Invalid user Email ID", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return false;
    }
    if (this.mainCard.Status?.toString().toLowerCase() != 'active') {
      let dialogRef = this.matDialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Reset Password", message: "This user is inactive or has been deleted!", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return false;
    }
    else{
      this.toSendEmail();
    }
  }

  toSendEmail() {
    this.ifResetPasswordClicked = false;
    let obj = {
      email: this.datashare.userCard.email
    }
    this.ngxService.start();
    if (this.datashare.userCard.email && this.datashare.userCard.email?.toString().toLowerCase().trim() != 'not specified') {
          this.vitalHttpServices.sendResetPwdLink(obj).subscribe(result => {
            this.ngxService.stop();

            if (result.Success) {
              result.Message = JSON.parse(result.Message);
              result.Message = result.Message.email ? result.Message.message + " " + result.Message.email : result.Message.message;
              let dialogRef = this.matDialog.open(ConfirmComponent, {
                disableClose: true,
                width: '500px',
                data: { header: "Reset Password", message: result.Message, alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
              });
              return;
            }
            else {
              this._snackbar.open(result.Message, 'Close');
            }
          }, error => {
            this.ngxService.stop();
            console.error(error);
            this._snackbar.open('Something went wrong.Please try again', 'Close');
          })
      }
    else {
      this.ngxService.stop()
      let dialogRef = this.matDialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Reset Password", message: "", alert: "This user does not have an email id! Please assign an email and try again.", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
      });
    }
  }
  Associate_role() {
    // this.datashare.getActionButtonData(this.actionbutton);
    if (sessionStorage.getItem("RoleName") !== "") {
      for (let i = 0; i < this.actionbutton.length; i++) {
        let value = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
        switch (this.actionbutton[i].Button) {
          case "Unlock/Lock User":
            this.unlock = value
            break;
          case "InActive user":
            this.inactiveuser = value
            break;
          case "Regenerate password":
            this.regeneratepassword = value
            break;
          case "Approve password request":
            this.approvepasswordrequest = value
            break;
          case "Populate CaseList":
            this.populateCaseList = value
            break;
          case "Populate ASC Cases":
            this.populateASCCases = value
            break;
          case "Export file":
            this.exportfile = value
            break;
          case "Group Compare":
            this.orgcompare = value
            break;
          case "Migrate user":
            this.migratuser = value
            break;
          case "Import":
            this.importFileButton = value
            break;
          case "Edit Group":
            this.editGroupButton = value
            break;
          case "Patient Report Theme":
            this.patientReportTheme = value
            break;
          case "Report Builder":
            this.reportBuilder = value
            break;
        }
      }
    }
    // for (var i = 0; i < this.actionbutton.length; i++) {
    //   if (this.actionbutton[i].Button === "Unlock/Lock User")
    //     this.unlock = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "InActive user")
    //     this.inactiveuser = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "Regenerate password")
    //     this.regeneratepassword = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "Approve password request")
    //     this.approvepasswordrequest = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "Populate CaseList")
    //     this.populateCaseList = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "Populate ASC Cases")
    //     this.populateASCCases = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "Export file")
    //     this.exportfile = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    //   else if (this.actionbutton[i].Button === "Org Compare")
    //     this.orgcompare = this.actionbutton[i].IsPermitted.toLowerCase() == "true" ? false : true;
    // }
  }

  // ImportButton() {
  //   this.datashare.getbuttonaction(this.actionbutton);
  //   this.buttonaction = this.actionbutton[0].IsPermitted;
  //   for (var i = 0; i < this.actionbutton.length; i++) {
  //     if (this.actionbutton[i].Button === 'Import')
  //       return (this.actionbutton[i].IsPermitted.toLowerCase() === 'true' ? false : true);
  //   }
  // }

  private FileUpload;
  ngOnChanges(): void {
    this.userStatus = this.mainCard.Status;
    this.inituserStatus = this.mainCard.Status;
    // this.mainCard.Organization_Status = (this.mainCard.Organization_Status != null) ? this.mainCard.Organization_Status.toLowerCase() : null;
    if (this.userStatus) {
      if (this.userStatus?.toString().toLowerCase() == 'inactive') {
        this.checked = false;
        this.initChecked = false;
        this.msg = "Activate"
      } else {
        this.checked = true;
        this.initChecked = true;
        this.msg = "Deactivate"
      }
    }
    this.importbutton = environment.EnableimportButton

    this.secondarykeys = this.secondary;
    this.fileName = "";
    this.usersAuthStatus();
    if(this.cardtype?.toString().toLowerCase() == 'users'){
      this.emailUpdated = this.VitalAdminTabService.emailUpdated;
    }
}

  @ViewChild(OrgCompareActionBtnComponent, { static: false }) child: OrgCompareActionBtnComponent;
  // ngAfterViewInit() {
  //   // child is set
  //   this.child.initial();
  // }

  // exportLabData(secondarykeys){
  //   this.filesAction.emit(secondarykeys);
  // }

  exportLabData(action) {
    if (action) {
      this.ngxService.start();
      this.vitalHttpServices.exportLab(action.casetype, action.OrganizationId, this.mainCard.id).subscribe(data => {
        if (data) {
          this.ngxService.stop();
          this.commonService.downloadBlob(data, `${action.casetype}.json`, true, null);
        } else {
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        }
      }, error => {
        console.error();
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      })
    }
  }

  // downloadBlob(data, filename) {
  //   let blobData: string = JSON.stringify(data);
  //   blobData = JSON.stringify(JSON.parse(blobData));
  //   var blob = new Blob([blobData], { type: 'application/text' });
  //   if (window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveBlob(blob, filename);
  //   } else {
  //     const elem = window.document.createElement('a');
  //     elem.href = window.URL.createObjectURL(blob);
  //     elem.download = filename;
  //     document.body.appendChild(elem);
  //     elem.click();
  //     window.URL.revokeObjectURL(elem.href);
  //     document.body.removeChild(elem);
  //   }
  // }

  handleFileInput(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    let filename = file.name;
    this.fileName = file.name;
    if (filename) {
      let splitarry = filename.split('.');
      if (splitarry[1].toLowerCase() != "json") {
        this._snackbar.open("Only json format file is allowed", "Failed");
      } else {
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      }//end of inner if
    }//end of outer if
    e.target.value = ''
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    var tempFile = e.target.result.split(';base64,');
    this.Base64jsonData = tempFile[1];
    let isValidJson = this.ValidateJSON(this.Base64jsonData);
    if (isValidJson) {
      this.isImpbtnDisable = false;
    } else {
      this.isImpbtnDisable = true;
      this._snackbar.open("Please upload a Valid Json", "Failed");
    }
  }

  ValidateJSON(base64data) {
    try {
      JSON.parse(atob(base64data));
    } catch (e) {
      return false;
    }
    return true
  }

  openModal(ModalType) {
    this.fileName = "";
    this.isImpbtnDisable = true;
    if (ModalType.toLowerCase() == "case type") {
      //$('#m-sm').modal({ backdrop: 'static', keyboard: false })
      $('#m-sm').appendTo("body").modal('show');
    }

  }

  closeImportModal() {
    this.fileName = '';
    this.isImpbtnDisable = true;
  }
  importFile(event) {

    if (event.target.files.length == 0) {
      return
    }
    this.handleFileInput(event)
    // let file: File = event.target.files[0];
    // after here 'file' can be accessed and used for further process
  }
  fnImportCasetype(action) {
    if (action) {
      this.ngxService.start();
      let paresedJson = JSON.parse(atob(this.Base64jsonData));
      let stringifiedJson = JSON.stringify(paresedJson);
      let finalResultJson = stringifiedJson.replace(/[^\x20-\x7E]/g, "'");
      finalResultJson = finalResultJson.replace(/'''/g, "'");
      let finalparsedJson = JSON.parse(finalResultJson);

      this.fileName = '';
      var jsonData = {
        "accountid": this.mainCard.id,
        "organizationID": action.OrganizationId,
        "jsonstring": JSON.parse(finalResultJson)  //this.Base64jsonData
      }
      this.vitalHttpServices.ImportLab(jsonData).subscribe(data => {
        let element: HTMLElement = document.getElementById('closeModal') as HTMLElement;
        if (data.Success) {
          this.ngxService.stop();
          this._snackbar.open(data.Message.toString(), "Success");
          element.click();
          $('#m-sm').appendTo("body").modal('hide');
          //$('#m-sm').modal({ backdrop: false, keyboard: false, show: false });
          //$('#m-sm').modal('hide');
          //$('#m-sm').appendTo("body").modal('hide');
        } else {
          element.click();
          $('#m-sm').appendTo("body").modal('hide');
          this.ngxService.stop();
          this._snackbar.open(data.Message.toString(), "Failed");
        }
      }, error => {
        console.error();
        let element: HTMLElement = document.getElementById('closeModal') as HTMLElement;
        element.click();
        //$('#m-sm').modal('hide');
        this.ngxService.stop();
        this._snackbar.open(error.toString(), "Failed");
      });//end of importlab
    }
  }

  PopulateCaseList() {
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    // for (let m = 0; m < environment.Vitaldxuserid.length; m++) {
    //   let env_depKey = environment.Vitaldxuserid[m];
    //   let env_depKeyName = env_depKey.substring(0, env_depKey.indexOf('-'));
    //   let  deploymentKey = this.vitalHttpServices.deploymentKey
    //   if ( deploymentKey.toLowerCase() == env_depKeyName.toLowerCase()) {
    //     loggedIn_userID = env_depKey.substring(env_depKey.indexOf('-') + 1);
    //     break;
    //   }
    // }
    let userDetails =
    {
      loginName: this.mainCard.Login_Name,
      loggedInUser: loggedIn_userID,
      OrganizationId: ""
    }
    this.ngxService.start();
    this.vitalHttpServices.PopulateCaseList(userDetails).subscribe(res => {
      this.ngxService.stop();
      if (res.Success) {
        this._snackbar.open(res.Message, "Success");
      }
      else {
        this._snackbar.open(res.Message, "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }

  PopulateASCCases() {
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    // for (let m = 0; m < environment.Vitaldxuserid.length; m++) {
    //   let env_depKey = environment.Vitaldxuserid[m];
    //   let env_depKeyName = env_depKey.substring(0, env_depKey.indexOf('-'));
    //   let  deploymentKey = this.vitalHttpServices.deploymentKey
    //   if ( deploymentKey.toLowerCase() == env_depKeyName.toLowerCase()) {
    //     loggedIn_userID = env_depKey.substring(env_depKey.indexOf('-') + 1);
    //     break;
    //   }
    // }

    let userDetails =
    {
      loginName: this.mainCard.Login_Name,
      loggedInUser: loggedIn_userID,
      OrganizationId: ""
    }
    this.ngxService.start();
    this.vitalHttpServices.PopulateASCCases(userDetails).subscribe(res => {
      this.ngxService.stop();
      if (res.Success) {
        this._snackbar.open(res.Message, "Success");
      }
      else {
        this._snackbar.open(res.Message, "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }

  public loginname;
  public firstName;
  public successIP;
  public requestID;
  public requestedDate;
  public lastname;
  public facilityaddress;
  public requestedIP;


  migrateUser() {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (re.test(this.datashare.userCard.email) == false) {
      let dialogRef = this.matDialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "Invalid user Email ID", alert: "", continue: "OK", cancel: "dontshow", useRaisedButton: true }
      });
      return false;
    }
    if (this.mainCard.Status?.toString().toLowerCase() != 'active') {
      this.matDialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Migrate User", message: "", alert: "This user is inactive. Please activate the user to migrate", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
      });
      return;
    }
    else {
    let dialogRef = this.matDialog.open(ConfirmComponent, {
      disableClose: true,
      width: '500px',
      data: { header: "Migrate User", message: "An Email with invitation link will be sent to the user. Do you want to proceed?", alert: "", continue: "Yes", cancel: "No", useRaisedButton: true}
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        if (this.datashare.userCard.email && this.datashare.userCard.email != '') {
          let requestData = [];
          let requestJson = {
            Email: this.datashare.userCard.email,
            UserID: this.datashare.userCard.userid,
            OrganizationID: Number(this.organizationid)
          }
          requestData.push(requestJson);
          this.ngxService.start();
          let depKey = sessionStorage.getItem('deploymentKey');
          this.vitalHttpServices.validateMigrateUser(requestData,depKey).subscribe(async result => {
            this.ngxService.stop();
            if (!result.errors) {
              if (result[0] ) {
                let warningMessage = result[0].StatusMessage;
                // let userId = result[0].UserID;
                // let organizationId = result[0].OrganizationID;
                if (warningMessage?.toString().toLowerCase().includes("duplicate")) {
                  let userName = ""
                  if (result[0].StatusMessage?.toString().toLowerCase().includes("duplicate")) {
                    userName += result[0].LoginName
                  }
                  userName = userName?.toString().substring(0, userName.length - 1);
                  this.matDialog.open(ConfirmComponent, {
                    disableClose: true,
                    width: '500px',
                    data: { header: "Migrate User", message: "", alert: "A user with the same email id already exists. Please provide a different email id.", continue: 'OK', cancel: "dontshow" , useRaisedButton: true}
                  });
                }
                else if (warningMessage?.toString().includes("login type is not Email")) {
                  this.matDialog.open(ConfirmComponent, {
                    disableClose: true,
                    width: '500px',
                    data: { header: "Migrate User", message: "", alert: "This group does not support Email based login for the Users. Please contact support team.", continue: 'OK', cancel: "dontshow" , useRaisedButton: true}
                  });
                }
                else if (warningMessage?.toString().includes("User does not exist")) {
                  this.matDialog.open(ConfirmComponent, {
                    disableClose: true,
                    width: '500px',
                    data: { header: "Migrate User", message: "", alert: "This user does not exist!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
                  });
                }
                else if(warningMessage?.toString().toLowerCase().includes("Group does not exist")){
                  this.matDialog.open(ConfirmComponent, {
                    disableClose: true,
                    width: '500px',
                    data: { header: "Migrate User", message: "", alert: "This group does not exist!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
                  });
                }
                else {
                  await this.confirmMigration(requestData,depKey);
                }
              }
              else {
                await this.confirmMigration(requestData,depKey);
              }
            }
          }, error => {
            console.error(error);
          });
        }
        else {
          this.matDialog.open(ConfirmComponent, {
            disableClose: true,
            width: '500px',
            data: { header: "Migrate User", message: "", alert: "This user does not have an email assigned. Please assign an email and try again!", continue: 'OK', cancel: "dontshow", useRaisedButton: true }
          });
        }
      }
    });

  }
  }

  async confirmMigration(requestData,depKey){
    this.ngxService.start();
    await this.vitalHttpServices.migrateUser(requestData,depKey).toPromise().then(result => {
      let email = requestData[0].Email;
      if (result && result.length > 0) {
        let entitykey= this.commonKey;
        let info = `Migrate User - "${this.datashare.userCard.loginName}" email migration has been initiated`
       let value = {'Email Status':{"newValue":result[0].StatusMessage  + ' ' + '(' + email + ')'}};
       let transactionid=this.commonService.generateGuid();
      this.activityService.setActivitySession({ 'entityId': entitykey, 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': 'Users' }] })
        this.commonService.createActivityObject(this.commonKey,this.datashare.userCard.loginName,'Users','Create',{},{},transactionid,'',{},value,info);
        this._snackbar.open('Invite email sent successfully', 'Close');
      }
      else {
        this._snackbar.open('User migration failed', 'Close');
      }
    },error=> {
      this._snackbar.open('User migration failed', 'Close');
    });
    this.ngxService.stop();
  }

  approveRequest() {
    this.ifApproveRequest = true;
    let queryVariable;
    this.ngxService.start();
    if (this.searchValue != undefined) {
      queryVariable = { "requestId": this.searchValue };
    } else if (this.searchValue == undefined) {
      queryVariable = { "loginname": this.mainCard.Login_Name, "userId": this.userId.toString() };
    }
    else {
      queryVariable = { "loginname": this.mainCard.Login_Name, "userId": this.userId, "requestId": this.searchValue };
    }
    let query = this.vitalHttpServices.GetQuery("approverequestpassword");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(res => {
      this.ngxService.stop();
      if (res.data.submenuData.length > 0) {
        this.htnapproved = false;
        this.approveResponse = res.data.submenuData[0];
        this.loginname = this.approveResponse.loginname === null ? this.mainCard.Login_Name : this.approveResponse.loginname;
        this.firstName = this.approveResponse.FirstName;
        this.lastname = this.approveResponse.LastName;
        this.facilityaddress = this.approveResponse.FacilityAddress;
        this.requestID = this.approveResponse.RequestID;
        this.requestedDate = this.approveResponse.RequestedDate;
        this.requestedIP = this.approveResponse.RequestedIP;
        this.successIP = this.approveResponse.SuccessIP;
        if (this.approveResponse.RequestID != undefined) {
          this.showSearch = false;
          this.htnapproved = false;
        } else {
          this.showSearch = true;
          this.htnapproved = true;
        }
      } else {
        this.showSearch = true;
        this.htnapproved = true;
        if (this.searchValue != undefined) {
          this.showMsg = true;
          this.htnapproved = true;
        }
      }
    });
  }

  UnlockUserPopUp() {
   const obj={
    }
    this.ifUnlockClicked = true;
    let modifiedBy=sessionStorage.getItem('Userid')?sessionStorage.getItem('Userid'):-100
    this.ngxService.start();
    this.vitalHttpServices.UnlockUser(this.mainCard.Login_Name, this.userId,modifiedBy).subscribe(data => {
      if (!data.error) {
        this.ngxService.stop();
        this.ifUnlockClicked = false;
        if (this.ifUnlockClicked == false) {
          this.commonService.createActivityTracker('Edited',this.userId, "User Details", 'Audit', obj, {},"User Is UnLocked");
        } else {
          this.commonService.createActivityTracker('Edited',this.userId, "User Details", 'Audit', obj, {},"User Is Locked");
        }
        if (data.Success) {
          // this.UserUnlockMessage = data.Message;
          // this.ifUserUnlockedSuccess = true;
          this._snackbar.open(data.Message, "Success");
          this.refresh();
          this.ngxService.start();
          this.MainCardModel.GetData(this.userId, this.cardtype, this.cardtype, 0).subscribe(result => {
            if (!result.error) {
              this.ngxService.stop();
              let UnlockData: any = result.data.Card;
              var unlockDataIndex = UnlockData.findIndex(x => x.Login_Name == this.mainCard.Login_Name);
              //this.commonService.createActivityTracker('info', this.custReportId, 'User Details', 'Audit', obj, {});
              //this.commonService.createActivityTracker('Edited',this.userId, this.cardtype, 'Audit', obj, {},"User Unlocked");
              if (unlockDataIndex != -1)
                this.mainCard.Locked = UnlockData[unlockDataIndex].Locked;
            } else {

              this.ngxService.stop();
              this._snackbar.open("An error occurred while processing your request", "Failed");
            }
          }, error => {
            this.ngxService.stop();
            this._snackbar.open("An error occurred while processing your request", "Failed");
            console.error(error);
          });
        } else {
          // this.ifUserUnlockedSuccess = false;
          // this.UserUnlockMessage = "Error While Unlocking,Please contact Administrator!!!";
          this.ngxService.stop();
          this._snackbar.open(data.Message, "Failed");
        }
      } else {

        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  RegeneratePasswordPopUp() {
    const obj={

    }
    this.ifPasswordClicked = true;
    this.ngxService.start();
    let modifiedBy=sessionStorage.getItem('Userid')?sessionStorage.getItem('Userid'):-100
      let  deploymentKey = this.vitalHttpServices.deploymentKey

    this.vitalHttpServices.GetResetPassword(this.mainCard.Login_Name,modifiedBy,deploymentKey).subscribe(data => {
      this.ngxService.stop();
      this.passwordGen = data[0];
      this.ifPasswordClicked = false;
      this.ifPasswordGenerated = true;
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error)
    });
    this.commonService.createActivityTracker('Edited',this.userId, "User Details", 'Audit', obj, {},"Password Regenerated");

  }

  copyFunction() {
    this.textToCopy = this.passwordGen;
  }

  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open("Copied Successfully", "Close");
  }


  statusChange() {
    this.ifUserStatus = false;
    if (this.userStatus.toLowerCase() == 'inactive') {
      this.userStatus = 'active';
      this.commonService.createActivityTracker('Edited',this.userId, this.cardtype, 'Audit', obj, {},"User Is Active");

    } else {
      this.userStatus = 'Inactive';
      this.commonService.createActivityTracker('Edited',this.userId, this.cardtype, 'Audit', obj, {},"User Is InActive");

    }
    var obj =
    {
      "loginName": this.mainCard.Login_Name,
      "userId": this.userId,
      "status": this.userStatus,
      "modifiedby":sessionStorage.getItem('Userid')?sessionStorage.getItem('Userid'):-100
    }

    this.vitalHttpServices.ChangeUserStatus(obj).subscribe(resp => {
      let data: any = resp;
      if (data.Success) {
         this._snackbar.open("Status updated successfully for " + this.userloginname, "Success");
        this.refresh();
      } else {
        this.userStatus = this.inituserStatus;
        this._snackbar.open("An error occurred while processing your request", "Failed");
        return false;
      }
    })
  }
  statchange(event) {
    if (event.target.checked === false) {
      this.ifUserStatus = true;
    } else {
      this.ifUserStatus = false;
    }

  }
  refresh() {
    let val = this.usersession.query
    this.vitalHttpServices.GetData(val).subscribe(value => {
      if (value) {
        // if(this.initChecked==false){
        //   value.data.Card[0].Status = "active";
        // } else{
        //   value.data.Card[0].Status = "Inactive";
        // }

        let obj = value.data.Card[0];
        let tabData = this.VitalAdminTabService.tabs.findIndex(va => va.tabData.mainCard.Login_Name === obj.Login_Name);
        if (tabData !== -1) {
          this.VitalAdminTabService.tabs[tabData].tabData.mainCard = obj
        }

        let userData = this.VitalAdminTabService.tabs[tabData].tabData.mainCard
        this.email = userData.Email;
        this.allowLogin = userData.AllowLogin;
        this.VitalAdminTabService.status = value.data.Card[0].Status
        // this.datashare.getuserdata(obj);
        // this._snackbar.open("Status Updated Successfully for " + this.mainCard.Full_Name, "Success");
        setTimeout(() => {
          if (value.data.Card[0].Status == "active") {
            this.checked = true;
            this.initChecked = true;
          }
          else {
            this.checked = false;
            this.initChecked = false;
          }

        })

      }
    })
  }
  close() {
    this.ifUserStatus = false;
    if (this.userStatus?.toString().toLowerCase() == 'inactive') {
      this.checked = false;
    } else {
      // this.checked = false;
      setTimeout(() => {
        this.checked = true;
      })

    }

  }



  closeApprovalPopup() {
    this.ifApproveRequest = false;
    this.searchValue = undefined;
    this.firstName = "";
    this.lastname = "";
    this.requestedIP = "";
    this.requestedDate = "";
    this.successIP = "";
    this.facilityaddress = "";
    this.loginname = "";
    this.requestID = undefined;
    this.showSearch = true;
    this.showMsg = false;
  }
  public showMsg = false;
  public txtMsg;
  data: any;


  requestApproval(type) {
    if (this.searchValue != undefined || this.requestID != undefined) {
      let reqId = this.requestID == undefined ? this.searchValue : this.requestID;
      if (reqId.match('RID')) {
        let loggedIn_userID = sessionStorage.getItem('Userid');
        this.ngxService.start();
        var obj = {
          "loginName": this.loginname,
          "status": type,
          "requestid": this.requestID == undefined ? this.searchValue : this.requestID,
          "actionby": loggedIn_userID != "" ? loggedIn_userID : "-100",
        }
        this.vitalHttpServices.ApproveRequestPassword(obj).subscribe(returnres => {
          let res = JSON.stringify(returnres);
          let cc = JSON.parse(res);
          if (cc.Success) {
            this.txtMsg = cc.Message;
            this._snackbar.open(this.txtMsg)
            this.ngxService.stop();
            this.ifApproveRequest = false;
            this.closeApprovalPopup();
          } else {
            this._snackbar.open(cc.Message, "Failed");
            this.ngxService.stop();
          }
        });
      }
      else {
        this._snackbar.open("Please enter a valid Request ID", "Alert");
        return false;
      }
    }
    else {
      this._snackbar.open("Please enter Request ID", "Alert");
      return false;
    }
  }

  SelectedModal(ActionButtonType: string) {
    // // this.menuType = ActionButtonType;
    // if (ActionButtonType === "OrgCompare") {
    //   this.SelectedMenuModel = "OrgCompareinLab";
    //   // this.GenericModalClass = "modal genericModalinLab OrgCompareDialog OrgCompareinLabModal";
    //   // // $('#OrgCompareinLabModal').modal('show');

    //   // // $('.OrgCompareinLabModal').appendTo("body").modal('show');
    //   // // $('#TextArea').removeClass('collapse');
    //   // // $('#TextArea').addClass('collapse');
    //   // // $('#TextArea').addClass('show');
    //   // const data = {};
    //   // this.OrgCompareActionBtnComp.forEach((comp) => {
    //   //   comp.Index = data.Index;
    //   // });


    //   // $('#OrgCompareinLabModal').appendTo("body").modal('show');
    //   if (this.child) {
    //     // this.setID(new OrgCompareComponent())
    //     this.child.initial();
    //   }
    // }

      const dialogConfig = new MatDialogConfig();
      // The user can't close the dialog by clicking outside its body
      dialogConfig.disableClose = true;
      dialogConfig.id = "modal-component";
      dialogConfig.width = "98%";
      dialogConfig.position = { top: '15px', right: '15px', bottom: '15px', left: '15px' };
      dialogConfig.maxWidth = "100%";
      dialogConfig.data = {repsentativeData:this.userRepresentatives}

      if (ActionButtonType === "OrgCompare") {
        let orgCompareData: any = {};
        dialogConfig.height = "95%";

      this.subscription = this.vitalHttpServices.SharedOrgCompareData.subscribe(OrgCompare_data => { orgCompareData = OrgCompare_data });
      orgCompareData = {
        OrgCOmpare_CaseType: this.mainCard.Name,
        OrgCOmpare_OrgID: this.mainCard.OrganizationID,
        OrgCOmpare_AccID: this.mainCard.id,
        fromButton: "actionButtoninLab"
      };
      this.vitalHttpServices.SetOrgData(orgCompareData);
      const modalDialog = this.matDialog.open(OrgCompareActionBtnComponent, dialogConfig);
    } else if(ActionButtonType == 'EditGroup') {
      dialogConfig.height = "auto";
      dialogConfig.panelClass = 'org-popup',
      this.vitalHttpServices["OrganizationData"] = { accountId: this.mainCard.id, logintype: this.mainCard.Group_Login_Type }
      const dialogRef = this.matDialog.open(ManageOrganizationComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        this.vitalAxisRepresentatives();
      });
    }
  }

  destroyChild() {
    if (this.child) {
      this.child.ngOnDestroy();
    }
  }
  // message:string;
  receiveMessage($event) {
    // this.message = $event;
    this.modalID = $event;
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }
  async validationEmail(email: string) {
    let isValid = false;
    let result: any = await lastValueFrom(
      this.vitalHttpServices.getAllowedDomain(this.organizationguid)
    );
    if (result) {
      if (result?.toString().includes(email)) {
        isValid = true;
      }
      isValid = false;
    }
    return isValid
  }

   // vitalaxis representations
   async vitalAxisRepresentatives() {
    let queryVariable = { "deploymentkey": sessionStorage.getItem('deploymentKey'), "orgid": sessionStorage.getItem('org_id') };
    let query = this.vitalHttpServices.GetQuery("vitalaxisrepresentativeassociations");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult, 'configdb').toPromise().then(res => {
      this.userRepresentatives = this.commonService.selectedRepresentatives = [...[]];
      if (!res.error && res?.data?.submenuData?.length) {
        this.userRepresentatives = res.data.submenuData.filter(user => user.IsAssociationActive);
        this.commonService.selectedRepresentatives =  res.data.submenuData;
      }
    }, error => {
      console.error(error);
    })
  }


  ExportExcel() {
    const filterFields = (data) => {
      return data.map(item => ({
        "EmployeeId": item.EmployeeId,
        "RepresentativeName": item.RepresentativeName,
        "Email": item.Email,
        "RoleName": item.RoleName,
        "Phone": item?.Phone,
        "WorkLocation": item?.WorkLocation,
        "Department": item?.Department,
        "CreatedBy":item.CreatedBy,
        "CreatedDate": item.CreatedDate,
        "ModifiedBy":item.ModifiedBy,
        "ModifiedDate":item.ModifiedDate
      }));
    };

    // Apply filtering to the array
    const filteredData = filterFields(this.commonService.selectedRepresentatives);

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'VitalRepresentativeInfo');
    let filename = 'VitalRepresentativeInfo_' + this.commonService.selectedRepresentatives[0].OrganizationId + '.xlsx';
    // Write the workbook and create a downloadable file
    XLSX.writeFile(workbook, filename);

  }

  async patientReportLaunch() {
    this.ngxService.start();
    try {
      let Encrypt: any = {
          "DeploymentKey": sessionStorage.getItem('DeploymentKey'),
          "AccountID": !sessionStorage.getItem('Locationid') ? this.VitalAdminTabService?.tabs[0]?.tabData?.mainCard?.id : sessionStorage.getItem('Locationid'),
          "UserEmail": localStorage.getItem('UserEmail'),
          "OrganizationId": !sessionStorage.getItem('org_id') ? this.VitalAdminTabService?.tabs[0]?.tabData?.mainCard?.Org_ID : sessionStorage.getItem('org_id'),
          "UserId": sessionStorage.getItem('Userid'),
          "UserRole": localStorage.getItem('Homepage_role') ? localStorage.getItem('Homepage_role') : sessionStorage.getItem('RoleName'),
          "type": "Client"
        }
      await this.vitalHttpServices.EncryptUrlPayload(Encrypt).subscribe(result => {
        this.ngxService.stop();
        if (!result.error) {
          if (!this.vitalHttpServices?.patientUrl) {
            this._snackbar.open("Not able to launch Patient Report Theme")
          }
          else {
            this.endpoint = this.vitalHttpServices.patientUrl + 'sso?data=' + result;
            if (this.VitalAdminTabService.reportThemeWindow && !this.VitalAdminTabService.reportThemeWindow.closed) {
              this.VitalAdminTabService.reportThemeWindow.location.href = this.endpoint;
              this.VitalAdminTabService.reportThemeWindow.focus();
            }
            else{
              this.VitalAdminTabService.reportThemeWindow = window.open(this.endpoint);
            }
          }
        }
      });
    } catch (error) {
      this.ngxService.stop();
      console.error(error);
    }
  }

  async getReportBulderLaunch(){
    this.ngxService.start();
    try {
      let Encrypt : any = {
        "DeploymentKey": sessionStorage.getItem('DeploymentKey'),
        "UserEmail": localStorage.getItem('UserEmail'),
        "AccountIds": !sessionStorage.getItem('Locationid') ? this.VitalAdminTabService?.tabs[0]?.tabData?.mainCard?.id : sessionStorage.getItem('Locationid'),
        "ProductName": "VitalAdmin",
        "OrganizationId": !sessionStorage.getItem('org_id') ? this.VitalAdminTabService?.tabs[0]?.tabData?.mainCard?.Org_ID : sessionStorage.getItem('org_id'),
        "UserId": sessionStorage.getItem('Userid')
      }
      await this.vitalHttpServices.EncryptUrlPayload(Encrypt).subscribe(result => {
        this.ngxService.stop();
        if(!result.error) {
          if (!this.vitalHttpServices?.reporbuilderlaunch) {
            this._snackbar.open("Not able to launch ReportBuilder")
          }
          else {
            this.sendMssageToStopParentLoader(this.vitalHttpServices.reporbuilderlaunch);
            this.reportBuilderEndPoint=this.vitalHttpServices?.reporbuilderlaunch + 'sso?data=' + result;
            if (this.VitalAdminTabService.reportBuilderWindow && !this.VitalAdminTabService?.reportBuilderWindow?.closed) {
              this.VitalAdminTabService.reportBuilderWindow.location.href = this.reportBuilderEndPoint;
              this.VitalAdminTabService?.reportBuilderWindow.focus();
            }
            else{
              this.VitalAdminTabService.reportBuilderWindow = window.open(this.reportBuilderEndPoint);
            }
          }
        }
      });
    } catch (error) {
      this.ngxService.stop();
      console.error(error);
    }
  }

  sendMssageToStopParentLoader(inputmsg) {
    let message = inputmsg;
    parent.postMessage(message, '*');
  }

}
