import { Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-default-role-settings',
  templateUrl: './default-role-settings.component.html',
  styleUrls: ['./default-role-settings.component.scss']
})
export class DefaultRoleSettingsComponent {
  task: Task = {
    color: 'primary',
  };

  showGrid: boolean = false;
  @Input() templateData: any;
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  gridHeaderList: any;
  listHeaderbackup: any;
  SubMenuCardModel: SubMenuCardModel;
  showTemplateData: any;
  ViewScreen: boolean;
  action: string = '';
  gridPage: boolean = true;
  addEditScreen: boolean = false;
  currentData: any;
  UserDefaultRoleForm = this._fb.group({
    frmuseremail: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/)]],
    frmrolename: ["", Validators.required],
    frmdepkey: "",
    frmentity: "",
    frmIsActive: true
  })
  public DeploymentList: any = [];
  public DeploymentListNone: any = [];
  public EntityList: any = [];
  public EntityListNone: any = [];
  public entities: any;
  RoleList: any = [];
  RoleListNone: any = [];
  rowId: any;
  trackerInput: { productId: any; customerId: string; entityType: any; actionType: string; entityId: any; ticketId: string; index: string; locationId: string; context: any; createdBy: string; startDate: string; endDate: string; };
  activityPath: any[];
  openActivityTkr: boolean;
  trackerApiUrl: any;
  activityEntity: {};
  auditableColumns: any;
  actiontype: string;
  activityTracker: any;



  constructor(private activeModal: NgbActiveModal, private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private _fb: FormBuilder,
    private _snackbar: MatSnackBar,
    public activitySession:ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  ngOnInit() {
    this.getAllUserDefaultRoles();
    this.getdrpdownList();
    this.getAuditableDetails('Default Role');
    sessionStorage.setItem('defaultOrgId','-1');
    this.activitySession.setActivitySession({'entityId': '','entityType': 'Default Role', 'context':[{'key':'home','value':'Default Role'}]})
    this.activitySession.getActivitySession.subscribe(res=> this.activityEntity = res);
  }

  //Get dropdownlist for Rolename, entity and deployment
  async getdrpdownList() {
    let res = await this.VitalHttpServices.EntityDetails().toPromise();
    this.entities = res;
    let defaultDepAll: any = ["ALL"]
    let selectNone: any = ['None']
    this.DeploymentListNone = defaultDepAll.concat(this.VitalHttpServices.DeploymentKeys);
    this.DeploymentList = selectNone.concat(this.DeploymentListNone)
    this.entities.forEach(entity => {
      this.EntityListNone.push(entity.EntityName);
    });
    this.EntityList = selectNone.concat(this.EntityListNone)
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery('allrolenames');
    queryVariable = { RoleName: null };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    // this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(
      data => {
        if (!data.errors) {
          data.data.submenuData.forEach(element => {
            this.RoleListNone.push(element.RoleName);

          });
          this.RoleListNone = [...new Set(this.RoleListNone)].sort();
          this.RoleList = this.RoleListNone

        }
      },
      error => {
        console.error(error);
      }
    );
  }

  //Get All the Data from given GraphQl keyword.
  getAllUserDefaultRoles() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getuserdefaultroles');
    queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult,'configdb').subscribe(
      data => {
        if (!data.errors) {
          this.showTemplateData = data.data.submenuData;
          this.addGridData(this.showTemplateData)
        }
        this.ngxService.stop();
      },
      error => {
        this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

//Send formatted Data to Grid
  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'UserDefaultRoles') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }
    }
    let gridarray = [];
    this.gridheader = [
      , 'Id'
      , 'UserEmail'
      , 'RoleName'
      , 'DeploymentKey'
      , 'Entity'
      , 'CreatedDate'
      , 'CreatedBy'
      , 'ModifiedDate'
      , 'ModifiedBy'
      , 'IsActive'
    ]
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'RoleName' || key == 'DeploymentKey' || key == 'Entity') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    gridarray.sort((a, b) => a.UserEmail.localeCompare(b.UserEmail))
    this.gridData = new CollectionView(gridarray);
    this.showGrid = true;
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
  }

  SetConfigClose() {
    this.activeModal.close();
    this.showGrid = false;
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  //Add and Edit Part
  getInputData(action, data) {
    if (action.toLowerCase() == 'edit') {
      this.action = "Edit Default Role";
      this.actiontype = 'Edit'
      this.currentData = data
      this.UserDefaultRoleForm.patchValue({
        frmuseremail: this.currentData.UserEmail,
        frmrolename: this.currentData.RoleName,
        frmdepkey: this.currentData.DeploymentKey,
        frmentity: this.currentData.Entity,
        frmIsActive: this.currentData.Active,
      })
    }
    else {
      this.UserDefaultRoleForm.reset();
      this.action = "Create Default Role";
      this.actiontype = 'Create'
      this.UserDefaultRoleForm.patchValue({
        frmuseremail: "",
        frmrolename: "",
        frmdepkey: "",
        frmentity: "",
        frmIsActive: true
      })
      Object.keys(this.UserDefaultRoleForm.controls).forEach(key => {
        this.UserDefaultRoleForm.controls[key].setErrors(null)
      });
    }
    this.gridPage = false;
    this.addEditScreen = true;
  }

  // Save the configurations(Add / Edit)
  createUserDefaultRoles(action, data) {
    const { frmuseremail, frmrolename, frmdepkey, frmentity, frmIsActive } = data;
    let message = '';
    const missingFields = [];
    if (!frmuseremail?.toString().trim()) missingFields.push('Email');
    if (!frmrolename?.toString().trim() || frmrolename?.toString()?.toLowerCase().trim() == 'not specified') {
      missingFields.push('Role');
    }
    if (missingFields.length) {
      message = `Please enter the required fields: ${missingFields.join(', ')}`;
    }

    if (message) {
      this._snackbar.open(message, 'Close');
      return;
    }
    var validRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
    if(!validRegex.test(frmuseremail)){
      this._snackbar.open('Please enter the valid email','Close')
      return;
    }
    let saveData = {};
    if (this.action.toLowerCase().includes('edit')) {
      if (this.showTemplateData.some((va: any) =>
      va.UserEmail && va.UserEmail.toString().toLowerCase().trim() == frmuseremail.toString().toLowerCase().trim()  && va.Id != this.rowId)) {
        this._snackbar.open('User email already exists!', 'Close')
        return;
      }
      saveData = {
        id: this.rowId,
        userEmail: frmuseremail,
        roleName: frmrolename,
        deploymentkey: frmdepkey == "None" ? null: frmdepkey,
        isactive: frmIsActive,
        entity: frmentity == "None" ? null: frmentity,
        Username: localStorage.getItem("UserEmail")
      }
    } else {
      if (this.showTemplateData.some((va: any) =>
      va.UserEmail && va.UserEmail.toString().toLowerCase().trim() == frmuseremail.toString().toLowerCase().trim())) {
        this._snackbar.open('User email already exists!', 'Close')
        return;
      }
      saveData = {
        id: null,
        userEmail: frmuseremail,
        roleName: frmrolename,
        deploymentkey: frmdepkey == "None" ? null: frmdepkey,
        isactive: frmIsActive,
        entity: frmentity == "None" ? null: frmentity,
        Username: localStorage.getItem("UserEmail")
      }
    }

    this.InsertDefaultRoles(saveData, action);
  }

  InsertDefaultRoles(saveData, action) {
    this.ngxService.start();
    this.VitalHttpServices.addEditdefaultRoles(saveData).subscribe(response => {
      this._snackbar.open(response[0].Message, "Close");
      this.commonService.auditDetails(this.actiontype =='Edit'?'Id':'id',this.actiontype =='Edit'?'RoleName':'roleName',this.actiontype =='Edit'?[this.currentData]:response, [saveData], this.actiontype ,{'menuURL': 'Default Role'},this.auditableColumns);
      if (action == 'close') {
        this.refreshGrid();
      }
      else {
        this.UserDefaultRoleForm.reset();
        // this.getData('create')
        this.UserDefaultRoleForm.patchValue(
          {
            frmIsActive: true,
          }
        )
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  refreshGrid() {
    this.gridPage = true;
    this.addEditScreen = false;
    this.showGrid = true;
    this.getAllUserDefaultRoles()
  }

  #editStarting
  newEditMethod(editObj: any) {
    editObj.rowData.IsActive == "Active" ? editObj.rowData.Active = 1 : editObj.rowData.Active = 0;
    this.openEditScreen(editObj.rowData, editObj.event);
  }

  openEditScreen(grid, event?) {
    let selectedrowData = grid;
    this.getInputData('edit', selectedrowData);
    this.rowId = selectedrowData.Id
  }

  newCreateMethod(){
    this.getInputData('',[])
  }

  openActivityTracker() {
    this.ngxService.start();
    this.openActivityTkr = true;
    let context = {};
    let activitySearchParams: any;
    this.trackerApiUrl = environment.baseUrl2;
    this.activitySession.getActivitySession.subscribe(activityTab => activitySearchParams = activityTab);
    activitySearchParams.entityType = 'Default Role';
    this.activitySession.setActivitySession(activitySearchParams);

    this.activityPath = ['Home', 'Default Role Configuration', 'Default Role'];

    this.trackerInput = {
      'productId': 'vitaladmin@vitalaxis.com',
      'customerId': sessionStorage.getItem('defaultOrgId'),
      'entityType': activitySearchParams.entityType,
      'actionType': 'audit',
      'entityId': activitySearchParams.entityId,
      'ticketId': '',
      'index': '',
      'locationId': '',
      'context': activitySearchParams.context,
      'createdBy': '',
      'startDate': '',
      'endDate': ''
    }
    this.ngxService.stop();
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location}).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }
}
