import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { take } from 'rxjs';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
export interface Task {
  color: ThemePalette;
}

declare var $: any;
@Component({
  selector: 'app-manage-collection-methods',
  templateUrl: './manage-collection-methods.component.html',
  styleUrls: ['./manage-collection-methods.component.scss']
})
export class ManageCollectionMethodsComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };

  @Input()
  public templateData: any;
  noDataFound: boolean = false;
  gridPage: boolean = false;
  uploadClicked: boolean = false;
  addEditScreen: boolean = false;
  SubMenuCardModel: any;
  gridheader: string[];
  collectionMethodGridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  workBook: any;
  sheetsToSelect: string[];
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  gridWidth: number = 0;
  excelDataArray: any[] = [];
  showInvalidColumns: boolean = false;
  postUpload: boolean;
  allFields = [{}]
  mandatoryFields = [{}]
  sampleDataAll = [{
    ExtnProcedureName: 'Name',
    Sequence: 'Number',
    IsActive: '0/1',
    IsDefault: '0/1'
  }]
  SampleDataAllForNonP4 = [{
    ExtnProcedureName: 'Name',
    Sequence: 'Number',
    IsActive: '0/1',
  }]
  sampleDataMin = [{
    ExtnProcedureName: 'Name'
  }]
  sheetHeader: any[];
  invalidColumns: any = '';
  showDelete: boolean;
  gridDisplay: boolean;
  gridData: CollectionView<any>;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  action: string = '';
  currentData: any;
  auditableColumns:any;
  activityEntity:any;
  accountID:any;
  showConfirmPopupFlag:any;
  caseprofileAlert:boolean;
  locations:string="";
  headerMenu :string ;
  exportExcelFields = {
    ExtnProcedureName: { displayName: "Template Name", isDate: false },
    CasetypeDisplayName: { displayName: "Case Type", isDate: false },
    Sequence: { displayName: "Sequence", isDate: false },
    IsDefault: { displayName: "Is Default", isDate: false },
    IsActive: { displayName: "Status", isDate: false },
    CreatedBy: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    ModifiedBy: { displayName: "Modified By", isDate: false },
    ModifiedDate: { displayName: "Modified Date", isDate: true },
  }


  collectionMethodForm = this._fb.group({
    frmname: ["", Validators.required],
    frmsequence: "",
    frmactive: true,
    frmdefault: false
  })

  copycollectionMethodForm = this._fb.group({
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
    frmOrganization: ["", Validators.required],
    frmOrgID: [""],
    frmcpyasinactive: false,
  })

  PopUpmsgs = (locations) => {
    return {
      1: { message: `There are default stains available for the other ${this.headerMenu} in the case type. Please configure default stain for this ${this.headerMenu}.` },
      2: { message: `There are default stains available for the other ${this.headerMenu} in the case type for Location ${locations} Please configure default stain for this ${this.headerMenu}.` },
      3: { message: `This ${this.headerMenu} is mapped to a case profile. Please check on the impacts.`}
    }
  }

  dKey: boolean;
  copyDataClicked: boolean = false;
  destDeployment: string = '';
  DeploymentKeys: string[] = [];
  searchResult: any[];
  orgList: any = [];
  searchInput: any;
  srcOrgid: any;
  srcDeployment: any;
  disableOrgSearch: boolean = false;
  selector: Selector = null;
  selectedCopyData: any = [];
  selectedData: any = [];
  copyFlag: any;
  dragIndex: number;
  bulkUpload: any = false;
  copyBtn: boolean;
  organizationList: any;
  dragnDrop: boolean;
  masterLevel: boolean = false;
  editTooltip: string = 'Edit';
  listContext: any;
  editEnabled: boolean = false;
  hiddenColumns: any = [];
  saveOrEditCompleted: any;
  gridHeaderList: any;
  ViewScreen: boolean = false;
  cardContext: string = 'fromCaseType';
  receivedObject: any;
  userSessionDetails: any;
  sourceApp: string = '';
  dbName: string;
  gridHeader: any[];
  orgId: any;
  caselist: any[];
  dbNameLabAdmin: string;
  editClone: boolean;
  selectedCaseType: any;
  
  constructor(public _snackbar: MatSnackBar, private _fb: UntypedFormBuilder, private dialog: MatDialog,
    private datashare: DataShareService, private templateHeaders: TemplateHeadersService,
    private commonService: CommonService, private vitalHttpServices: VitalHttpServices,
     public ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService,public VitalMenuComponent: VitalMenuComponent,
     private labadminService: LabadminService, private labAdminSessionService : LabadminSessionService, private store: Store<{ breadcrum : [] }>) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare,
    )
  }

  async ngOnInit() {
    //#region Check if data exists and then populate
    this.headerMenu = this.templateData?.menuURL === 'Extraction Procedures' ? 'Extraction Procedures' : 'Collection Method'

    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
          await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
            if (Object.keys(session["userDetails"]).length > 1) {
              _this.userSessionDetails = session["userDetails"]
              _this.sourceApp = 'VitalDx'
              let menuObj = {}
              await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
                menuObj = result[result.length - 1]
              })
              await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
              _this.templateData = _this.labadminService.templateData;
              _this.dbName = _this.labadminService.deploymentKey;
              sessionStorage.setItem('org_id', _this.labadminService.organizationId);
              sessionStorage.setItem('deploymentKey', this.dbName);
              this.getOrgCaseType();
              this.getAuditableDetails(this.templateData.menuURL)
              this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
              this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
              _this.GetButtonAccess(_this.labadminService.subMenuPermissions);
            }
          })   
    }else{
    this.gridPage = true
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.showCollectionMethodList(this.templateData.submenuData) : this.getMasterLevelData();
    this.getAuditableDetails(this.templateData.menuURL);
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    this.accountID = sessionStorage.getItem('Locationid')
    // this.getMasterLevelData()
    //#endregion
    }
  }



  //#regin to show notes
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing!' || value == 'Null' || value.toString().includes('Zero is not allowed for Sequence') || (value.toString().includes('Sequence only takes numbers')) || (value.toString().includes('Null values exist!'))) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('Extraction Procedure added') || value.toString().includes('Extraction Procedures added') || value.toString().includes(this.templateData.menuURL.toString()+'added') || value.toString().includes('Collection Method added') || value.toString().includes('Collection Methods added') || value == 'Success'
          || value.toString().includes('Collection Method will be added on approval') || value.toString().includes('Extraction Procedure will be added on approval')|| value.toString().includes('There are default stains available for the other Extraction Procedures in the case type. Please configure default stain for this Extraction Procedure.')
        ||value.toString().includes('default stains')|| value.toString().includes('success')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Extraction Procedure already exists!')) || (value.toString().includes('Collection Method already exists!')) || (value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }
  showCollectionMethodList(templateData: object[]) {
    this.gridHeaderList = this.templateHeaders.templateList.find((item: any) => item?.templateName == (this.sourceApp!='VitalDx'?'Extraction Procedures':'Extraction Procedures Lab'))?.['headers'];
    this.gridPage = true
    this.uploadClicked = false;
    this.noDataFound = false;
    this.addEditScreen = false;
    this.gridheader = ['ModifiedDate', 'ModifiedBy', 'CreatedDate', 'CreatedBy', 'IsActive', 'Sequence', 'ExtnProcedureName', 'Level', 'ExtnProcedureId','CaseType','IsDefault','extnId','CasetypeDisplayName'];
    this.hiddenColumns = ['ModifiedDate', 'ModifiedBy', 'CreatedDate', 'CreatedBy', 'Level', 'OrganizationID', 'ExtnProcedureId','extnId'];
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    if (templateData.length > 0) {
      for (let i = 0; i < templateData.length; i++) {
        primary = {}
        for (let [key, value] of Object.entries(templateData[i])) {
          for (let j = 0; j < this.gridheader.length; j++) {
            if (key === this.gridheader[j]) {
              if (value == null) {
                value = 'Not Specified';
              }
              if (key.match(/IsDefault/i)) {
                value = value == 1 ? true : false
              }
              primary[key] = value
            }
          }
        }
        gridarray?.push(primary)
      }
      
      gridarray?.sort((a, b) => {
        const seqA = isNaN(Number(a.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(a.Sequence);
        const seqB = isNaN(Number(b.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(b.Sequence);
        return seqA - seqB;
      });
      
      if (gridarray?.length > 0) {
        this.gridPage = true;
      }
      else {
        this.ngxService.stop();
        this.gridPage = false;
        this.noDataFound = true;
      }
      this.collectionMethodGridData = new CollectionView(gridarray);
    }
    else {
      this.getMasterLevelData(null,this.selectedCaseType);
    }
    this.ngxService.stop();
  }

  //Export collection method list
  exportCollectionMethodList(flex, type?) {
    let excel = [];
    let modifiedExcel: any = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate()
    let rows = flex.rows
    rows.find(e => {
      excel.push(e._data);
    });

    for (let i = 0; i < excel.length; i++) {
      modifiedExcel[i] = {}
      modifiedExcel[i]["ExtnProcedureID"] = excel[i]["ExtnProcedureId"]
      modifiedExcel[i]["ExtnProcedureName"] = excel[i]["ExtnProcedureName"]
      modifiedExcel[i]["Sequence"] = excel[i]["Sequence"]
      modifiedExcel[i]["IsActive"] = excel[i]["IsActive"] == 'Active' ? true : false
      excel[i]["IsDefault"] ? modifiedExcel[i]["IsDefault"] = excel[i]["IsDefault"] : null
      modifiedExcel[i]["CreatedBy"] = excel[i]["CreatedBy"]
      modifiedExcel[i]["CreatedDate"] = excel[i]["CreatedDate"]
      modifiedExcel[i]["ModifiedBy"] = excel[i]["ModifiedBy"]
      modifiedExcel[i]["ModifiedDate"] = excel[i]["ModifiedDate"]
    }
    let filename = this.templateData.menuURL.toString() + '_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      modifiedExcel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationExtractionProcedure');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate()
    // this.commonService.createActivityObject("", this.templateData.menuURL, 'Export', filename, {}, this.templateData.secondarykeys);
    // this.showCollectionMethodList(this.templateData.submenuData)
  }

  //#region Tooltip for the wijmo grid
  initGrid(grid, hitCount?) {
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationextractionprocedure')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    // this.fileDropRef.nativeElement.value = "";
  }

  //#region Open upload screen with prerequisite data fetch
  loadUploadScreen() {
    this.uploadClicked = true;
    this.gridPage = false;
    // this.getTemplates()
    this.bulkUpload = true;
    this.gridWidth = 0
    let queryVariable = { tablename: 'OrganizationExtractionProcedure', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          for (let item of data.data.allFields) {
            if (!item.Column.match(/OrganizationID/i) && !item.Column.match(/ExtnProcedureID/i) && !item.Column.match(/Casetype/i) && !item.Column.match(/OrganizationExtnProcedureGUID/i)) {
              this.allFields[0][item.Column] = null;
            }
          }
          this.mandatoryFields[0]["ExtnProcedureName"] = null
        }
      }
    }, error => {

      console.error(error);
    });

  }
  //#endregion

  refreshGrid(context?) {
    // this.removeGrid();
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.editEnabled = false;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.copyFlag = false;
    this.gridPage = true;
    this.copyFlag = false;
    this.copycollectionMethodForm.controls.frmOrganization.enable()
    this.copyDataClicked = false;
    this.bulkUpload = false
    this.resetFormErrors();
    this.addEditScreen = false;
    this.uploadClicked = false;
    this.fetchCollectionMethodList(context)
  }
  fetchCollectionMethodList(context?, copyData?,caseType?) {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.templateData.submenuData = [];
    this.masterLevel = false;
    let caseTypeLists;
    if(this.sourceApp=='VitalDx'){
      let selectedCaseType = this.selectedCaseType ? this.selectedCaseType : caseType ? caseType :'All Case Types'  ;
      caseTypeLists = selectedCaseType=='All Case Types' ? (Array.isArray(this.caselist)  ? this.caselist?.map(item=>item?.Case_Type) : caseType) : selectedCaseType;
    }else{
      caseTypeLists = this.templateData.secondarykeys.casetype.toString() 
    }
    let queryVariable = { filterParameter:   `casetype  in \"${caseTypeLists}\":string[]` , orgid: this.sourceApp=='VitalDx'? this.templateData.secondarykeys.OrganizationId.toString() :this.templateData.secondarykeys.OrganizationId.toString() };
    if(queryVariable?.filterParameter?.length > 100 ){
      delete queryVariable?.filterParameter;
    }
    let queryKey = "casetypecollectionmethodlist";
    let query = this.SubMenuCardModel.GetQuery(queryKey);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.listContext = context
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.templateData.submenuData = data.data.submenuData;
        if(this.templateData?.submenuData?.length && this.editClone == false){
          this.editBtn = false;
        }
        if (!this.bulkUpload) {
          if (!this.copyFlag) {
            if (!this.listContext) {
              this.getInputData('', this.templateData.submenuData)
            }
            else {
              this.showCollectionMethodList(this.templateData.submenuData)
            }
          }
          else {
            this.AddGridData(this.excelDataArray, 0);
          }
        }
        else {
          this.AddGridData(this.excelDataArray, 0);
        }
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = ""
    let sample
    let sampleExcel = [{}]
    if ((sessionStorage.getItem('DeploymentKey').toString().match(/thx/i))) {
      if (allFieldsCheck) {
        filename = this.templateData.menuURL.toString() + '_All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
        sample = true
        sampleExcel = this.sampleDataAll
      }
      else{
      filename = this.templateData.menuURL.toString() + '_Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      sample = true
      sampleExcel = this.sampleDataMin
      }
    }
    else if (allFieldsCheck) {
      filename = this.templateData.menuURL.toString() + '_All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      sample = true
      sampleExcel = this.SampleDataAllForNonP4
    }
    else {
      filename = this.templateData.menuURL.toString() + '_Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      sample = true
      sampleExcel = this.sampleDataMin
    }

    if (!(sessionStorage.getItem('DeploymentKey').toString().match(/thx/i))) {
      if (this.allFields) {
        delete this.allFields[0]['IsDefault']
      }
    }
    var ws = XLSX.utils.json_to_sheet(allFieldsCheck ? this.allFields : this.mandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationExtractionProcedure');
    sample ? ws = XLSX.utils.json_to_sheet(sampleExcel) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }

  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/isactive/i)) {
              if(value === '1' || value === true || value === 1 ||value ==='' ||value ===null){
                value = true;
              }
              else{
                value = false;
              }
            }
            else if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value == '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    if (copyData) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        if (sessionStorage.getItem('DeploymentKey').toString().match(/thx/i)) {
          if (!tempExcelArr[i]["IsDefault"] || tempExcelArr[i]["IsDefault"] == '') {
            tempExcelArr[i]["IsDefault"] = false;
          }
          else {
            tempExcelArr[i]["IsDefault"] = true;
          }
        }
        else {
          //default value will not be copied
          const index = this.sheetHeader.indexOf('IsDefault');
          if (index > -1) {
            this.sheetHeader.splice(index, 1);
          }
          delete tempExcelArr[i]["IsDefault"]
        }
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', tablename: 'organizationextractionprocedure', organizationid: this.templateData?.secondarykeys?.OrganizationId?.toString(), casetype: this.templateData?.cardtype?.toString(),accountid:this.accountID });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }

  // Close Modal


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  redirectiontoTabSave(val){
      this.VitalMenuComponent.changeComponent(val,{changeTab: val=='Case Profiles',tabIndex: 0});
      this.showConfirmPopupFlag=undefined;
  }


  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.validateCollectionMethodData(dataArray).subscribe(result => {
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                this.templateData.menuURL.toString().match(/Extraction Procedure/i) ? r.NoteMessage = r.NoteMessage.toString().replace('Collection Method', 'Extraction Procedure') : null;
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddGridData(dataArray, 1);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
          // this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
    this.ngxService.stop();
  }
  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
  }
  //#endregion

  //#region  Function to cancel the data selection
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.copycollectionMethodForm.controls.frmOrganization.enable();
    this.postUpload = false;
    this.noDataFound = false;
    this.showDelete = false;
    // this.fileDropRef.nativeElement.value = "";
    this.selectedData= [];
    this.copycollectionMethodForm.patchValue({
      frmDepKey: this.copycollectionMethodForm.value.frmDepKey,
      frmOrgType: "Laboratory",
      frmOrganization: "",
      frmOrgID: "",
      frmcpyasinactive: false
    })
    this.searchResult = []
    this.resetFormErrors();
  }
  //#endregion

  //#region  Reset any error indication in the form
  resetFormErrors() {
    Object.keys(this.copycollectionMethodForm.controls).forEach(key => {
      this.copycollectionMethodForm.controls[key].setErrors(null)
    });
  }
  //#endregion

  AddGridData(data, hitCount) {
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (key == 'sequence') {
                if (value == 0) {
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = 'Zero is not allowed for Sequence';
                  }
                }
                else {
                  let pattern = /[0-9]+/
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['notes'];
                  }
                }
              }
              // if(key.toLowerCase().toString().includes('notes')){
              //   if(value){
              //     if(primary['notes'] != ' Extraction Procedure will be added on approval' ||  primary['notes'] != ' Collection Method will be added on approval'){
              //       primary['notes'] = this.templateData.menuURL.toString()+ 'already exists!';
              //     }
              //     else{
              //       primary['notes'] = ' Extraction Procedure added' ? ' Extraction Procedure added' :'Collection Method added';
              //     }

              //   }
              //       else{
              //         primary['notes'] = ' Extraction Procedure will be added on approval' ? ' Extraction Procedure will be added on approval' :'Collection Method will be added on approval';
              //       }
              // }
              if (value && value.toString().match(/null/i)) {
                value = "NULL"
                primary['notes'] = 'Null values exist!';
              }
              if (key == 'extnprocedurename' && (!value || value.toString() == '')) {
                value = ''
                primary['notes'] = 'Mandatory field is missing!'
              }

            }

            if(key == 'isdefault'){
              if(value == 'false' || value==false || value==0 || value=="0"){
                value=false
              }else{
                value=true
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }

        const defaultCount = (gridArray || []).filter(item => item.isdefault === 1).length;
      
        // If there are more than one default, update notes and increment errorCount
        if (defaultCount > 1) {
          // Update the excelDataArray and set displayorder
          gridArray = (gridArray || []).map((item, i) => {
            if (item.isdefault === 1) {
              // Set displayorder to -1
              item.sequence = -1; 
            }
            return item; // Return the updated item
          });

          gridArray.forEach((item, i) => {
            if (item.isdefault === 1) {
              gridArray[i]["notes"] = 'Multiple default values found'; // Update notes
            }
          });
        }
        if(this.copyDataClicked && !this.postUpload){
          gridArray = gridArray.filter(ele=> ele.isactive === true);
        }
        this.excelDataArray = gridArray;
        if(this.excelDataArray.length == 0){
          this.noDataFound = true;
        }
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if(gridArray.length > 0){
        this.gridData = new CollectionView(gridArray);
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      }
    }
    this.ngxService.stop();
  }

  approveCollectionMethods(copyData?) {
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i)
            && !this.excelDataArray[i]["notes"].toString().match(/this Extraction Procedure/i)&& !this.excelDataArray[i]["notes"].toString().match(/other locations/i)) {
            errorcount++
          }
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === false) {
              this.excelDataArray[i][key] = "false";
            } else if (value == "null" || value == "" || value == null) {
              this.excelDataArray[i][key] = null;
            } else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            if (this.excelDataArray[i]["isactive"] && !(this.excelDataArray[i]["notes"].toString().toLowerCase().match(/already exists/i))) {
              this.excelDataArray[i]["isactive"] = this.excelDataArray[i]["isactive"] == null ? true : (this.excelDataArray[i]["isactive"] == 0 || this.excelDataArray[i]["isactive"].toString().toLowerCase() == 'false') ? false : true
            }
            this.excelDataArray[i]["accountid"]=this.accountID
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        selectedData.push(this.selectedData[i]._data);
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        if (this.copycollectionMethodForm.value.frmcpyasinactive == true) {
          for (let i = 0; i < this.selectedCopyData.length; i++) {
            if(!this.selectedCopyData[i]["notes"].toString().toLowerCase().match(/already exists/i)){
            this.selectedCopyData[i]["isactive"] = false
            }
          }
        }
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      copyData ? dataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray.filter(va => !va.notes.toString().toLowerCase().match(/already exists/i))
      let context = copyData ? null : ''
      let isUpload = this.copyFlag ? false : true;
      sendjson.length > 0 ? this.addCollectionMethods(sendjson, isUpload, context) : this._snackbar.open(this.templateData.menuURL.toString().trim().slice(0, -1) + '(s) already exist', 'Close')
    }
  }
  
  //#endregion

  // onloadedRows(flex: wjGrid.FlexGrid) {
  // for (var i = 0; i < flex.rows.length; i++) {
  //   var row = flex.rows[i];
  //   var item = row.dataItem;
  //   row.cssClass = item.notes != 'Mandatory field is missing!' && item.notes != 'Null values exist!' ? 'warning-text' : 'error-msg'
  // }
  // }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find( (e) => e.Htext == this.labadminService.templateData.menuURL)['ActionButton']
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL.toString().toLowerCase().trim() == 'collection methods')['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          this.editClone = this.editBtn;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "DragnDrop":
          this.dragnDrop = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getInputData(action, data) {
    if (action.toLowerCase() == 'edit') {
      this.action = this.templateData.menuURL.toString().match(/extraction procedure/i) ? 'Edit Extraction Procedure' : 'Edit Collection Method'
      this.currentData = data;
      this.activityEntity.entityId = this.currentData.ExtnProcedureId;
      this.activityService.setActivitySession(this.activityEntity);
      this.collectionMethodForm.patchValue({
        frmname: this.currentData.ExtnProcedureName,
        frmsequence: this.currentData.Sequence,
        frmactive: this.currentData.IsActive == 'Active' ? true : false,
        frmdefault: this.currentData.IsDefault ? this.currentData.IsDefault : null
      })
    }
    else {
      this.collectionMethodForm.reset();
      this.action = this.templateData.menuURL.toString().match(/extraction procedure/i) ? 'Create Extraction Procedure' : 'Create Collection Method'
      this.collectionMethodForm.patchValue({
        frmname: "",
        frmsequence: "",
        frmactive: true,
        frmdefault: false
      })
      Object.keys(this.collectionMethodForm.controls).forEach(key => {
        this.collectionMethodForm.controls[key].setErrors(null)
      });
    }
    this.gridPage = false;
    this.addEditScreen = true;
  }


  openEditScreen(grid, event) {
    this.editEnabled = true;
    if (!this.masterLevel) {
      var hti = grid.hitTest(event);
      let selectedrowData = hti.panel.rows[hti.row].dataItem;
      // this.selectedrowData = selectedrowData;
      this.getInputData('edit', selectedrowData);
    }
    else {
      this._snackbar.open('Global ' + this.templateData.menuURL + 's cannot be edited!', 'Close')
    }
  }

  getErrorMessage(fieldValue, fieldName?) {
    if (!fieldName) {
      if (!fieldValue.toString().match(/^[0-9]+$/g)) {
        return 'Enter only numbers';
      }
    }
    else {
      if (fieldValue.toString().replace(/\s/g, '').length == 0) {
        return 'Please enter a value';
      }
    }
  }

  allowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  checkRepeated(data) {
    if(!(this.action.match(/edit/i))){
    let message = ""
    if (!this.masterLevel) {
      this.templateData.submenuData.some(va => va.ID != data.extnprocedureid && va.Name == data.extnprocedurename.trim()) ? message = this.templateData.menuURL.toString().trim() + " with the same name already exists!" : null
      if (message != "") {
        let dialogRef = this.dialog.open(ConfirmComponent, {
          disableClose: true,
          width: '260px',
          data: { header: "", message: "", alert: message, continue: "OK", cancel: "dontshow" }
        });
        return false;
      }
      return true;
    }
    return true
  }
  }

  loadCopyScreen() {
    this.copyDataClicked = true
    this.noDataFound = false;
    this.gridPage = false;
    this.action = this.templateData.menuURL.toString().match(/extraction procedure/i) ? 'Copy Extraction Procedures' : 'Copy Collection Methods'
    this.copycollectionMethodForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey'),
      frmOrgType: "Laboratory",
      frmOrganization: "",
      frmOrgID: "",
      frmcpyasinactive: false
    })
    this.resetFormErrors()
    this.disableOrgSearch = true;
    this.destDeployment = sessionStorage.getItem('deploymentKey')
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys
    this.getListOrg();
  }

  clearEntity() {
    this.selectedData = [];
    this.searchResult = [];
    this.copycollectionMethodForm.patchValue({
      frmOrganization: ''
    })
  }

  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = []
    this.selectedData = []
    this.copycollectionMethodForm.value.frmOrgID = ""
    if (!value.trim()) {
      let copyOrgList=this.organizationList?.slice();
      return (this.searchResult = copyOrgList?.splice(0,25));
    }
    let idValidate = /^[0-9]*$/

    if (this.copycollectionMethodForm.value.frmOrgType) {
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.searchResult = this.organizationList?.filter(function (series) {
            if (series && series?.orgname != null) {
              return series?.orgname?.toString().toLowerCase().includes(value?.toLowerCase());
            }
          });

          this.searchResult = this.searchResult?.splice(0, 25);
        }
      }
      else {
        this.searchResult = this.organizationList?.filter(function (series) {
          if (series && series.orgid != null) {
            return series?.orgid?.toString().toLowerCase().includes(value?.toLowerCase());
          }
        });
        this.searchResult = this.searchResult?.splice(0, 25);
      }
      this.copycollectionMethodForm.value.frmOrgID = this.organizationList?.filter(function (series: any) {
        if (series && series.orgname != null) {
          return series?.orgname?.toString().toLowerCase() == value?.toLowerCase() ? series.orgid : "";
        }
      });
      this.resetFormErrors()
    }
  }

  selectedCopyDataOrganization(event, data) {
    this.selectedData=[];
    if (event.source.selected) {
      this.copycollectionMethodForm.patchValue({
        frmOrgID: data.orgid,
        frmOrganization: data.orgname,
        frmcpyasinactive: false
      })
    }
    this.getDataToCopy();
    // this.organizationList = (orgType.toLowerCase() === 'laboratory') ? this.orgList.filter(o => o.orgtype.toLowerCase() != 'ordering facilities') : this.orgList.filter(o => o.orgtype.toLowerCase() != 'laboratories');

  }

  onChangeDeployment(e, depKey) {
    this.selectedData=[];
    if (e.source.selected) {
      this.copycollectionMethodForm.controls.frmOrganization.enable()
      this.copycollectionMethodForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: "",
        frmOrgID: "",
        frmcpyasinactive: false
      })
      this.gridWidth = 0
      this.searchInput = '';
      this.searchResult = [];
      this.postUpload = false;
      this.excelDataArray = []
      this.getListOrg();
    }
  }


  getListOrg() {
    let dbName = this.copycollectionMethodForm.value.frmDepKey;
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery("orgtype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        if (data) {
          if (data.data.submenuData.length > 0) {
            this.orgList = data.data.submenuData
            this.organizationList = this.orgList.filter(o => o.orgtype.toLowerCase() != 'ordering facilities')
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }

  getDataToCopy(caseType?) {
    this.postUpload = false;
    if (typeof (this.copycollectionMethodForm.value.frmOrgID) == 'object') {
      let frmorgid = null
      frmorgid = this.copycollectionMethodForm.value.frmOrgID
      this.copycollectionMethodForm.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { casetype:  this.sourceApp=='VitalDx'? (caseType == 'All Case Types' ? null:caseType) : this.templateData.secondarykeys.casetype.toString() , orgid: this.sourceApp=='VitalDx'? this.templateData.secondarykeys.OrganizationId.toString() :this.copycollectionMethodForm.value.frmOrgID.toString() };
    // let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.copycollectionMethodForm.value.frmOrgID.toString() };
    let queryKey = "casetypecollectionmethodlist";
    let query = this.SubMenuCardModel.GetQuery(queryKey);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copycollectionMethodForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        let copyData = (data.data.submenuData && data.data.submenuData.length > 0) ? data.data.submenuData.map(({ Sequence, ...rest }) => ({ ...rest })) : [];
        if (copyData.length == 0) {
          this.gridWidth = 0;
          this.noDataFound = true;
          return
        }
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }


  disableGetData() {
    const { frmOrgID } = this.copycollectionMethodForm.value
    return frmOrgID == "" ? true : false
  }

  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.match(/extnprocedure/i)) {
          primary['Extnprocedurename'] = value
        }
        else if (key.match(/isactive/i)) {
          primary[key] = value == 'Active' ? true : false
        }
        else if (key.match(/IsDefault/i)) {
          primary['IsDefault'] = value;
        }
        else if (key.match(/Sequence/i)) {
          primary[key] = value;
        }
        // delete copyData[i]['status']
      }
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }

  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data['slno']
      delete e._data['tablename']
      delete e._data['createdby']
      excel.push(e._data);
    });
    let filename = this.templateData.menuURL.toString() + '_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationExtractionProcedure');
    XLSX.writeFile(wb, filename);
  }

  getMasterLevelData(context?,caseType?) {
    let caseTypeList = Array.isArray(caseType)  ?caseType?.map(item=>item?.Case_Type) : caseType;
    this.selectedData=[];
   this.copycollectionMethodForm.patchValue({
    frmcpyasinactive :false
   })
    this.templateData.submenuData = [];
    let queryVariable 
    if(this.sourceApp === 'VitalDx'){
      queryVariable = { filterParameter: (caseType == 'All Case Types'? null:`casetype  in \"${caseTypeList}\":string[]`)  };
    }
    else {
      queryVariable = {casetype : this.templateData.secondarykeys.casetype.toString()}
    }
    if(queryVariable?.filterParameter?.length > 100 ){
      delete queryVariable?.filterParameter;
    }
    let queryKey = "casetypecollectionmethodmasterlist";
    let query = this.SubMenuCardModel.GetQuery(queryKey);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let dbName = this.vitalHttpServices?.deploymentKey?.toString();
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        if(data?.data?.submenuData?.length){
          this.editBtn = true;
        }
        this.templateData.submenuData = data.data.submenuData;
        if (!context) {
          if (this.templateData.submenuData.length > 0) {
            this.showCollectionMethodList(this.templateData.submenuData)
            this.noDataFound = false;
            this.masterLevel = true;
          }
          else {
            this.noDataFound = true;
            this.collectionMethodGridData = new CollectionView(this.templateData.submenuData);
            this.masterLevel = false
          }
          this.editTooltip = this.masterLevel ? 'Global ' + this.templateData.menuURL + 's cannot be edited!' : 'Edit'
        }
        else {
          let copyData = (this.templateData.submenuData && this.templateData.submenuData.length > 0) ? this.templateData.submenuData.map(({ Sequence, ...rest }) => ({ ...rest })) : [];
          if (copyData.length == 0) {
            this.gridWidth = 0;
            this.noDataFound = true;
            return
          }
          this.copycollectionMethodForm.patchValue({
            frmOrganization: ''
          })
          this.resetFormErrors();
          this.copyDataProcess(copyData);
        }
      }
    }, error => {

      console.error(error);
    });
    this.ngxService.stop();
  }

  shouldShowColumn(columnname: string): boolean {
    return !this.hiddenColumns.toString().toLowerCase().includes(columnname.toLowerCase());
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location,  "DeploymentKey":this.dKey?'THX':'All'}).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }

  getStatus(isActive:any){
    if(isActive){
      return 'Active';
    }else{
      return 'Inactive';
    }
  }
  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copycollectionMethodForm, controlName ,this.copycollectionMethodForm.value.frmOrganization)
  }

  saveCollectionMethod(action: string, data: any) {
    if (this.action.match(/edit/i)) {
     this.editCollectionMethod([data])
    }
    else {
      this.excelDataArray = [];
      this.checkRepeated(data) ? this.addCollectionMethods([data], true, 'upload') : null;
    }
  }
  //#region
  addCollectionMethods(dataArray: object[], bulkUpload: boolean = false, context?) {
    let caseType = this.sourceApp !='VitalDx' ? this.templateData?.secondarykeys?.casetype :  dataArray?.[0]?.['casetype']
    if (bulkUpload == false && dataArray?.[0]?.['isdefault'] === 1) {

      const defaultValue = this.collectionMethodGridData?._pgView?.find(
        procedure => procedure.CaseType === caseType && procedure.IsDefault === 'Yes' && procedure.IsActive === 'Active'
      );

      if (defaultValue) {
        this.ngxService.stop();
        const dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '500px',
          data: {
            header: '',
            message: `"${defaultValue.ExtnProcedureName}" is already marked as the default. Are you sure you want to set this record as the new default?`,
            alert: 'Note: previous record will be unmarked',
            continue: "Yes",
            cancel: "No"
          }
        });

        return dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            this.extractionProcedureCreateAndCopy(dataArray, bulkUpload, context);
          } else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            return false;
          }
        }).catch(error => {
          console.error(error);
        });
      }
      else {
        this.extractionProcedureCreateAndCopy(dataArray, bulkUpload, context);
      }
    }
    else {
      this.extractionProcedureCreateAndCopy(dataArray, bulkUpload, context);
    }
  }

  extractionProcedureCreateAndCopy(dataArray: object[], bulkUpload: boolean = false, context?){
    this.ngxService.start();
    this.vitalHttpServices.bulkUploadCollectionMethods(dataArray).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          // this.postDownload = true;
          this.showDelete = false;
          this.masterLevel = false;
          if(this.copyDataClicked && this.postUpload){
            this.copycollectionMethodForm.controls.frmOrganization.disable()
          }
          else {
            if(result[0]?.Notes?.toString().toLowerCase().includes('exist') || result[0]?.Notes?.toString().toLowerCase().includes('marked as default')){
              this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
              if(this.sourceApp =='VitalDx'){
                  if (result[0]?.Notes?.toString().toLowerCase().includes('marked as default') && result[0]?.Notes?.includes('Collection Methods')) {
                    result[0].Notes = result[0]?.Notes?.replace(/Collection Methods/g, 'Extraction Procedure');
                    this._snackbar.open(result[0]?.Notes, "Close");
                    return;
                 }
                 else if(result[0]?.Notes?.toString().toLowerCase().includes('marked as default') && result[0]?.Notes?.includes('Extraction Procedure')){
                    this._snackbar.open(result[0]?.Notes, "Close");
                    return;
                 }
                 else{
                   this._snackbar.open('Extraction Procedure already exists', "Close");
                   return;
                 }
              }
              else{
                this._snackbar.open(result[0]?.Notes, "Close");
                return;
              }
            }
            else{
              this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            }
          }
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                if (result[j]['IsDefault']==1) {
                  this.excelDataArray[i]['notes'] = this.templateData.menuURL.toString().match(/Extraction Procedure/i) ? result[j]['Notes'].toString().replace('Collection Method added', 'Extraction Procedure Added successfully ,No impact on default stains') : result[j]['Notes'];
                  this.excelDataArray[i]['status'] = result[j]['Status']
                }else{
                  this.excelDataArray[i]['notes'] = this.templateData.menuURL.toString().match(/Extraction Procedure/i) ? result[j]['Notes'].toString().replace('Collection Method', 'Extraction Procedure') : result[j]['Notes'];
                  this.excelDataArray[i]['status'] = result[j]['Status']
                }
              }
            }
          }
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.headerMenu + '(s) already exist!', 'Close') }
            else { this._snackbar.open(this.headerMenu + ' already exists!', 'Close'); }
            return;
          }
          else {
            if(this.sourceApp =='VitalDx'){
              let message = bulkUpload ? 'Data uploaded successfully' : this.copyFlag ? 'Extraction procedure(s) copied successfully' : 'Extraction Procedure created successfully';
              this._snackbar.open(message, 'Close');
            }
            else{
              let message = bulkUpload ? 'Data uploaded successfully' : this.copyFlag ? this.templateData.menuURL.toString().trim().slice(0, -1) + '(s) copied successfully' : this.templateData.menuURL.toString().trim() + ' created successfully';
              this._snackbar.open(message, 'Close');
            }
            dataArray[0]['sequence'] = result[0]['sequence'];
            if(result[0]['IsDefault']==1&& this.bulkUpload==false && (this.copyFlag==undefined ||this.copyFlag==false )){
              this._snackbar.open(this.headerMenu + " configured successfully, no impact on default stains", 'Close');
            }
            if((result[0]['StainAlert']==1 ||result[0]['StainAlert']==2)  
              && (dataArray[0]["isactive"]==true ||dataArray[0]["isactive"]==undefined)
              && result[0]['IsDefault']==0
              ){
              this.showConfirmPopupFlag = result[0]['StainAlert'];
              this.locations=result[0]['Locations'];
            }
            dataArray[0]['isactive'] = dataArray[0]['isactive'] == 1 ? 'Active' : 'Inactive';
            dataArray[0]['isdefault'] = dataArray[0]['isdefault'] == 1 ? 'Yes' : 'No';
            this.commonService.auditDetails('extnprocedureid','extnprocedurename',result,  (bulkUpload ||this.copyFlag) ?this.excelDataArray:dataArray, bulkUpload ? 'Upload' : this.copyFlag ? 'Copy' : 'Create', this.templateData, this.auditableColumns);
          }
          if (!this.bulkUpload) {
            if (context == '') {
              this.getInputData('', this.templateData.submenuData);
            }
          }
          context ? this.refreshGrid(context) : this.fetchCollectionMethodList();
        }
        else {
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }

  editCollectionMethod(collectionMethodData: object[]) { 
    let caseType = this.sourceApp !='VitalDx' ? this.templateData?.secondarykeys?.casetype : collectionMethodData?.[0]?.['casetype']
    if (collectionMethodData[0]['isdefault'] === 1) {

      const defaultValue = this.collectionMethodGridData?._pgView?.find(
        procedure => procedure.CaseType === caseType && procedure.IsDefault === 'Yes' && procedure.IsActive === 'Active'
        && collectionMethodData?.[0]?.['extnprocedureid'] != procedure?.ExtnProcedureId
      );

      if (defaultValue) {
        this.ngxService.stop();
        const dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '500px',
          data: {
            header: '',
            message: `"${defaultValue.ExtnProcedureName}" is already marked as the default. Are you sure you want to set this record as the new default?`,
            alert: 'Note: previous record will be unmarked',
            continue: "Yes",
            cancel: "No"
          }
        });

        return dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            this.extractionProcedureUpdate(collectionMethodData);
          } else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            return false;
          }
        }).catch(error => {
          console.error(error);
        });
      }
      else {
        this.extractionProcedureUpdate(collectionMethodData);
      }
    }
    else {
      this.extractionProcedureUpdate(collectionMethodData);
    }
  }

  extractionProcedureUpdate(collectionMethodData){
    this.ngxService.start();
    this.vitalHttpServices.editCollectionMethod(collectionMethodData).toPromise().then(response => {
      if(response[0]?.Notes?.toString().toLowerCase().includes('exist') || response[0]?.Notes?.toString().toLowerCase().includes('marked as default')){
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        if(this.sourceApp =='VitalDx'){
          if (response[0]?.Notes?.toString().toLowerCase().includes('marked as default') && response[0]?.Notes?.includes('Collection Methods')) {
            response[0].Notes = response[0]?.Notes?.replace(/Collection Methods/g, 'Extraction Procedure');
            this._snackbar.open(response[0]?.Notes, "Close");
            return;
          }
          else if(response[0]?.Notes?.toString().toLowerCase().includes('marked as default') && response[0]?.Notes?.includes('Extraction Procedure')){
            this._snackbar.open(response[0]?.Notes, "Close");
            return;
         }
         else{
           this._snackbar.open('Extraction Procedure already exists', "Close");
           return;
         }
          }
          else{
            this._snackbar.open(response[0]?.Notes, "Close");
            return;
          }
      }
      else if (response[0]?.Notes?.match(/success/i)){
        this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        if(this.sourceApp =='VitalDx'){
          this._snackbar.open('Extraction Procedure updated successfully', "Close");
        }
        else{
          this._snackbar.open(this.headerMenu + " updated successfully", "Close");
        }
        collectionMethodData[0]['isactive'] = this.commonService.getStatus(collectionMethodData[0]['isactive']);
        collectionMethodData[0]['sequence'] = response[0]['sequence'];
        collectionMethodData[0]['isdefault'] = collectionMethodData[0]['isdefault'] == 1 ? 'Yes' : 'No';
        this.commonService.auditDetails('ExtnProcedureId','ExtnProcedureName', [this.currentData], collectionMethodData, 'Edit', this.templateData,this.auditableColumns);
        if(response[0]['IsDefault']==1 && this.bulkUpload==false && (this.copyFlag==undefined ||this.copyFlag==false )){
          this._snackbar.open(this.headerMenu + " updated successfully,No impact on default stains", 'Close');
        }
        if(response[0]['CaseProfileAlert']==1 && collectionMethodData[0]["isactive"].toLowerCase()=="inactive"){
          this.caseprofileAlert=true;
          this.showConfirmPopupFlag=5;
        }else{
          this.caseprofileAlert=false;
        }
        if(response[0]['StainAlert']==1 && collectionMethodData[0]["isactive"].toLowerCase()=="active" && response[0]['IsDefault']==0){
          this.showConfirmPopupFlag = response[0]['StainAlert'];
        }else if(response[0]['StainAlert']==2 && collectionMethodData[0]["isactive"].toLowerCase()=="active" && response[0]['IsDefault']==0){
          this.showConfirmPopupFlag = response[0]['StainAlert'];
          this.locations=response[0]['Locations'];
        }
        this.fetchCollectionMethodList('list');
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open(this.headerMenu + " update failed!", "Close");
        this.ngxService.stop();
      }
    }
    ).catch(error => { 
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this._snackbar.open("An error occurred while processing your request", "Failed"); console.error(error) })
    this.ngxService.stop();
  }

  recieveObjFromListing(object: any) {
    object.newData = { ...object.newData, ...{ 'accountid': this.accountID }};
    this.currentData = object.oldData;
    const newObj = {};
    Object.entries(object.newData).forEach(([key, value]) => {
      newObj[key.toLowerCase()] = value;
    });
    if(newObj['sequence'] != undefined){
      if(isNaN(newObj['sequence']) || Number(newObj['sequence'])<0){
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open('Please enter valid sequence', 'Close');
        return;
      }
    }
    delete newObj['action'];
    newObj['sequence'] = object?.newData?.isdefault === 1 && object?.newData?.IsActive === 1 ? newObj['sequence'] = '-1' : newObj['sequence'];
    newObj['sequence'] = newObj['sequence']=='' || newObj['sequence'] == 0 ? null : newObj['sequence'];
    if (object?.newData?.Action == 'Create') {
      delete newObj['modifiedby'];
      this.addCollectionMethods([newObj],false,'list');
    }
    else {
      delete newObj['createdby'];
      newObj['extnprocedureid'] = object.oldData['ExtnProcedureId'];
      this.editCollectionMethod([newObj]);
    }
  }
  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  backClicked(event) {
    this.uploadClicked = false;
    this.gridPage = true;
    this.refreshGrid('list');
  }

  getNote(masterLevel){
    let isMasterLevel = masterLevel ? 'Global Level' : 'Entity Level';
    return `Showing '${isMasterLevel}' ${this.templateData.menuURL.toString().trim()}`;
  }

  emitFilters($event: any) {
    this.selectedCaseType = $event?.CaseType?.object?.CaseType;
    if(this.masterLevel){
      this.getMasterLevelData('',$event?.CaseType?.object?.CaseType=='All Case Types'?this.caselist:$event?.CaseType?.object?.CaseType);
    }
    else
    this.fetchCollectionMethodList('list','',$event?.CaseType?.object?.CaseType)
  }

  getOrgCaseType(){
    let keyword = 'CaseTypeforAddendumNotes';
    let accountID = this.userSessionDetails?.userAccountIDs?.map(item => item?.accountId).join(',') ;
    let orgid = sessionStorage.getItem('org_id')
    let queryVariable = { id: orgid.toString() , assoicatedaccid :`accountId in \"${accountID}\":string [] `};
    let query = this.SubMenuCardModel.GetQuery(keyword);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult,this.dbName).subscribe(data => {
      if (!data?.errors) {
        if (data) {
          this.caselist = [];
          data?.data?.submenuData?.forEach(element => {
            const temp = {
              'Case_Type': element?.CaseType
            }
            this.caselist?.push(temp);
          });
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.showCollectionMethodList(this.templateData.submenuData) : this.getMasterLevelData('',this.caselist);
        }
      }
    }, error => {

      console.error(error);
    });
  }

  
  createActivityTrackerForSort($event: any) {
    if(this.sourceApp !== 'VitalDx') {
      this.commonService.auditDetails('', '', [], $event, 'Re-Order', this.templateData, '');
    }
  }
}
