import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseSettingsTemplate } from '../../commoncomponents/templateclasses/basesettings';
@Component({
  selector: 'settingstemplate-container',
  templateUrl: './settingstemplatecontainer.component.html',
  styleUrls: ['./settingstemplatecontainer.component.scss']
})
export class SettingsTemplateContainerComponent extends BaseSettingsTemplate implements OnInit, OnChanges {
  mainData: boolean = true;
  @ViewChild("flexgrid") flexgrid: FlexGrid;
  girdView = "";
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  public template: string = '';
  @Input()
  public submenuData: any;
  @Input()
  labels: any;
  @Input()
  public GroupData: any = {};

  @Input()
  templateData: any;


  public groupByKey: string = "";

  public subHeader: string = "";
  templateGroupedData: any;
  templateGroupedDataKeys: string[];
  public templateLables: any;
  public settingsQuery: string;
  subDataList: any;
  public subDataListModel: { "key": "", "value": 0 }[] = [];
  labelFirstCount: number = 0;
  firstInstance: boolean;
  Querydata: any = {};
  DataSub = [];
  gridwidth: number;
  gridHeader = [];
  queryTitle: any;
  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  groupbyName: string;
  l2lconfigData: any;
  commonservice: CommonService;
  disableeditsave: {};
  test: boolean = true;

  constructor(private injectorService1: InjectorService,
    public ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar) {
    super(injectorService1, ngxService, _snackbar);
  }

  initializeGrid(flexgrid: FlexGrid) {
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid)
        this.flexgrid.refresh(true);
    });
  }

  GetSettingsQuery() {
    //  let query: string = "query($accid: String,  $orgid  :  String)  { ";
    let query: string = "";
    if (this.templateData.template == 'casetypetemplate') {
      query = "query($accid: String,  $casetype  :  String)  { ";
    }
    else {
      query = "query($accid: String,  $casetype  :  String, $orgid  :  String)  { ";
    }
    // let query: string = "query( $orgid  :  String)  { ";
    let index: number = 0;
    for (index = 0; index < this.templateData.submenuData.length; index++) {
      query = query + this.templateData.submenuData[index].Querytxt + " ";
    }
    query = query + " }";

    let queryVariable: any = {};
    queryVariable.accid = this.templateData.cardIdentifier.toString();
    if (this.templateData.GroupData && this.templateData.GroupData.OrganizationId)
      queryVariable.orgid = this.templateData.GroupData.OrganizationId.toString();
    if (this.templateData.GroupData && this.templateData.GroupData.casetype)
      queryVariable.casetype = this.templateData.GroupData.casetype.toString();

    let queryRequest: any = {
      "OperationName": null,
      "Query": query,
      "Variables": queryVariable
    };
    // let queryRequest =
    // {"OperationName":null,"Query":"query($accid: String,  $orgid  :  String)  {   Set_Custom_Accession_Number :IdentityFormat(AccountId:'27305')\r\n  {\r\n    Format\r\n  }  Provide_Finish_Accession_button:OrganizationAttributes_list(Organizationid:$orgid filter: \"Attributename like \\\"%SkipSiteInfo%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   Single_Page_Accessioning:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%SkipSiteInfo%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }     Skip_Grossing_stage:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%SkipGrossingStatus%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }  Show_Only_Orderable_Tests:OrganizationAttributes_list(Organizationid:$orgid filter: \"Attributename like \\\"%DisplayOnlyorderedpanel%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   Fulfill_Tests_by_Default:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%FulfillOrderedPanel%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   Show_Cassette_Information:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%hidCassetteInfoInProcedureName%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   }","Variables":{"accid":"27305","orgid":"11639"}}
    return queryRequest;
  }

  GetSettingsSubQuery() {
    //  let query: string = "query($accid: String,  $orgid  :  String)  { ";
    let query: string = "";
    if (this.Querydata.CategoryName === "L2L Mapping") {
      query = "query( $casetype  :  String, $accid : String)  { ";
      query = query + this.Querydata.Querytxt + " ";
      query = query + " }";

    } else {
      // if (this.Querydata.ConfigurationName !== "Survelliance Information") {
      query = "query( $casetype  :  String, $orgid  :  String)  { ";
      query = query + this.Querydata.Querytxt + " ";
      // }
      query = query + " }";
      // } else {
      //   query = "query( $casetype  :  String)  { ";
      //   query = query + this.Querydata.Querytxt + " ";
      //   // }
      //   query = query + " }";
      // }
    }
    // let query: string = "query( $orgid  :  String)  { ";
    let index: number = 0;
    // for (index = 0; index < this.templateData.submenuData.length; index++) {
    let queryVariable: any = {};
    queryVariable.accid = this.templateData.cardIdentifier.toString();
    if (this.templateData.GroupData && this.templateData.GroupData.OrganizationId)
      queryVariable.orgid = this.templateData.GroupData.OrganizationId.toString();
    if (this.templateData.GroupData && this.templateData.GroupData.casetype)
      queryVariable.casetype = this.templateData.GroupData.casetype.toString();

    let queryRequest: any = {
      "OperationName": null,
      "Query": query,
      "Variables": queryVariable
    };
    // let queryRequest =
    // {"OperationName":null,"Query":"query($accid: String,  $orgid  :  String)  {   Set_Custom_Accession_Number :IdentityFormat(AccountId:'27305')\r\n  {\r\n    Format\r\n  }  Provide_Finish_Accession_button:OrganizationAttributes_list(Organizationid:$orgid filter: \"Attributename like \\\"%SkipSiteInfo%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   Single_Page_Accessioning:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%SkipSiteInfo%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }     Skip_Grossing_stage:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%SkipGrossingStatus%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }  Show_Only_Orderable_Tests:OrganizationAttributes_list(Organizationid:$orgid filter: \"Attributename like \\\"%DisplayOnlyorderedpanel%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   Fulfill_Tests_by_Default:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%FulfillOrderedPanel%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   Show_Cassette_Information:OrganizationAttributes_list(Organizationid:'11639' filter: \"Attributename like \\\"%hidCassetteInfoInProcedureName%\\\"\")\r\n  {\r\n    AttributeValue\r\n  }   }","Variables":{"accid":"27305","orgid":"11639"}}

    return queryRequest;
  }

  getsubdata_link(subrow) {
    if (subrow) {
      this.Querydata = {}
      this.queryTitle = subrow.ConfigurationName.split(" ").join("_")
      this.Querydata = subrow
      this.GetSubData()
    }
  }
  getL2LData(value, array) {
    if (value === 'L2L Mapping') {
      this.queryTitle = "Send_Out_Professional_Case_L2L1"
      this.DataSub = this.l2lconfigData[this.queryTitle]
      this.gridShowData()
    } else {
      this.queryTitle = "Send_Out_Professional_Case_L2L";
      this.DataSub = this.l2lconfigData[this.queryTitle]
      this.gridShowData()
    }
  }
  UpdateSaveButton(settingTableData, template, inputValue) {
    let parseColumnNames = JSON.parse(settingTableData.ColumnName);
    let updatefileds = {};
    var upcol = parseColumnNames.UpdateColumns.split(',');
    upcol.forEach(element => {
      updatefileds[element] = inputValue.toString();
    });
    let colval = this.GetUpdateID(template, parseColumnNames.IDColumnName).toString();
    if (colval != 0) {
      let query = {
        inputdata: {
          TableName: settingTableData.TableName,
          ColumnName: parseColumnNames.IDColumnName,
          ColumnValue: colval
        },
        dynamic: {
          Dynamic: JSON.stringify(updatefileds)
        }
      }
      let finalquery = this.commonservice.GetUpdateQuery(query);
      this.ngxService.start();
      this.injectorService.vitalHttpServices.GetData(finalquery).subscribe(result => {
        if (!result.errors) {
          this.ngxService.stop();
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
        console.error(error);
      });
    }//end of if
  }

  GetUpdateID(template, idColname) {
    let utemplate = template.split(' ').join('_');
    if (this.subDataResultList != undefined) {
      if (this.subDataResultList[utemplate]) {
        if (this.subDataResultList[utemplate].length != 0) {
          return this.subDataResultList[utemplate][0][idColname];
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else { return 0 }
  }
  GetSubData() {
    this.ngxService.start();
    this.injectorService.vitalHttpServices.GetData(this.GetSettingsSubQuery()).subscribe(result => {
      if (!result.errors) {
        this.ngxService.stop();
        if (result.data) {
          if (this.Querydata.CategoryName === "L2L Mapping") {
            this.l2lconfigData = result.data
            this.getL2LData("L2L Mapping", result.data);
            return
          } else {
            this.DataSub = result.data[this.queryTitle];
          }

          // this.DataSub = result.data[this.queryTitle];
          this.gridShowData()


        }
      } else if (result.errors) {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }

  gridShowData() {
    let gridarray = []
    let primary = {}
    if (this.DataSub.length > 0) {
      primary = {}
      this.gridHeader = []
      for (let [key, value] of Object.entries(this.DataSub[0])) {
        this.gridHeader.unshift(key)
      }
      gridarray = this.DataSub
      if (this.Querydata.ConfigurationName === "Rule Out Options" || this.Querydata.ConfigurationName === "Clinical History") {
        this.girdView = "hgrid";
        if (this.Querydata.ConfigurationName === "Rule Out Options") {
          this.groupbyName = "Site_Name";
          this.gridData = new CollectionView(gridarray, { groupDescriptions: [this.groupbyName] })
        }
        if (this.Querydata.ConfigurationName === "Clinical History") {
          this.groupbyName = "Preference_Header_Name";
          this.gridData = new CollectionView(gridarray, { groupDescriptions: [this.groupbyName] })
        }
        setTimeout(function () {
          if (this.flexgrid) {
            this.flexgrid.collapseGroupsToLevel(0);
            this.flexgrid.refresh(true);
          }
        });
        this.gridwidth = (170 * (this.gridHeader.length - 1)) + 37;
        if (this.gridwidth > 1300)
          this.gridwidth = 1300;
      } else {
        this.girdView = "grid";
        this.gridData = new CollectionView(gridarray, { pageSize: 10 })
        this.gridwidth = (170 * this.gridHeader.length) + 37;
        if (this.gridwidth > 1300)
          this.gridwidth = 1300;
      }


    } else {
      this.gridData = new CollectionView([])
      this.gridwidth = 0
    }
    this.mainData = false;
  }
  IsValidSubDataResultListItem(rowsubHeader: any, subHeader: any) {
    let valid: boolean = false;
    if (this.subDataResultList.length > 0) {
      if (this.subDataResultList[rowsubHeader[subHeader].split(' ').join('_')])
        valid = true;
    }
    return valid;
  }

  GetSubDataResultList(template: any) {
    if (this.subDataListModel.length > 0) {

      let index: any = 0;
      index = this.subDataList.findIndex(model => model.key.toString() === template.split(' ').join('_'));
      if (index > -1)
        return this.subDataListModel[index].value;
      else
        return 0;
    }
    else
      return 0;
  }


  GetSubDataListItem(template: any, index: any) {
    template = template.split(' ').join('_')
    if (this.subDataResultList !== undefined) {
      let index: any = 0;
      if (this.subDataResultList[template]) {
        if (this.subDataResultList[template].length == 0) {
          return 0;
        } else {
          if (this.subDataResultList[template].length > 0) {
            if (this.subDataResultList[template][0].AttributeValue === "true") {
              return true
            } else if (this.subDataResultList[template][0].AttributeValue === "false") {
              return false
            } else {
              return this.subDataResultList[template][0].AttributeValue
            }
          }
        }
      } else {
        return 0;
      }
    }
    else
      return 0;
  }

  IsCheckedSubDataResultListItem(rowsubHeader: any, subHeader: any) {
    let valid: boolean = false;
    if (this.subDataResultList.length > 0) {
      if (this.subDataResultList[rowsubHeader[subHeader].split(' ').join('_')]) {
        if (this.subDataResultList[rowsubHeader[subHeader].split(' ').join('_')][0].Status.trim() == 'Active')
          valid = true;
      }
    }
    return valid;
  }

}
