<div class="px-4" *ngIf="sourceApp=='VitalDx' && isDataLoading && !create">
    <app-shimmerui [line] = 9></app-shimmerui>
</div>

<app-templates-listing [ngStyle]="{'display': (templateData && !create && gridShow && !isDataLoading) ? 'block' : 'none'}" [gridHeader]="listedGridHeader"
    [gridData]="CustomFieldsGriddata" [context]="'Custom Fields'" [hideDelete]="deleteBtn" [hideCreate]="createBtn"
    [hideUpload]="uploadBtn" [isShowActiveTabs]=false (CreateTemplateClicked)="createCustomFields()"
    (deleteTemplateClicked)="deleteData($event)" [hideExport]="exportBtn" [templateData]="templateData"
    [templateData]="templateData" [subMenuCardModel]="subMenuCardModel"
    (uploadTemplateEventTriggered)="uploadCustomFields()" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
    [showHeaderModule]="true" [gridwidth]="0" [isAdmin3]="sourceApp =='VitalDx' ? false : true">
</app-templates-listing>

<div class="d-flex mt-3" *ngIf="create">
    <div class="col-sm-2 vdm-heading">
        <span>Create Custom Field</span>
    </div>
    <div class="col-sm-10 button-wrap p-0 mb-2">
        <button class="col-sm-1 btn secondary mr-2" type="button" (click)="return()" >Return</button>
        <button class="col-sm-1 btn primary" (click)="saveCustomField()">Save</button>
    </div>
</div>

<section [formGroup]="CreateForm" class="col-sm-12 p-2 create-wrap" *ngIf="create">
    <div class="col-sm-12 p-0">
        <div class="row">
            <app-custom-input class="col-sm-3 ml-2" [required]="true" formcontrolname="fieldname"
                labelValue="Field Name"></app-custom-input>
        </div>
    </div>
</section>

<app-common-bulk-functions *ngIf="uploadbtnClicked" [templateData]="templateData" [uploadClicked]="uploadbtnClicked"
     [cardContext]="cardContext"(uploadBack)=backClicked($event) 
     [columnsToRemove]="['OfAccountId','OfOrganizationId','Value','organizationid','accountid']" [isAdmin3]="false">
</app-common-bulk-functions>