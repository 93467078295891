import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { addNabvTab, disableListView, setReloadingPage } from '../../store/actions/navbar.actions';
import {editUrlObj} from '../share/utility/routingConstans'
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { concatMap, forkJoin, groupBy, map, mergeMap, reduce, shareReplay, take, toArray } from 'rxjs';
import { ExtAttributeConstants } from '../share/utility/ex_attribute _constants';
import { enableView } from '../Utility Functions';
@Component({
  selector: 'app-commondetails',
  templateUrl: './commondetails.component.html',
  styleUrls: ['./commondetails.component.scss']
})
export class CommondetailsComponent implements OnInit {

  userDetails: any = {};
  npiDetails: any = {};
  savedRoles: any = [];
  tabList = []

  @Output() CloseDetials = new EventEmitter<any>();
  @Output() EditUser = new EventEmitter<string>();
  @Output() EditLocation = new EventEmitter<string>();
  @Input() viewType = "small"
  @Input() userInfo
  @Input() fromLocation='';
  @Input() contentOf:string|null=null;
  @Input() isHideLocationGrid:boolean = false;
  @Input() type: string

  selectedTab: string = '';
  userid = ''
  locationDetails:any;
  showSpinner: boolean=true;
  loggedInUserId:number = 0;
  pathUserId: number = 0
  URL:string|null=''
  statusChanged:boolean[]=[false]
  loggedInUserOrgId: any;
  deploymentKey: any;

  //global observable for NPI.
  npiValidation$ = null

  constructor
  (
    private store: Store<{ breadcrum: [] }>, 
    private commonService: CommonService, 
    private labAdminservice: LabadminService, 
    private vitalHttpServices: VitalHttpServices,
    private _router:Router, 
    private labAdminSessionService: LabadminSessionService
  ) 
  {}
 
  ngOnInit(): void {
    if(this.labAdminservice.fromGlobalSearch){
      let getTabsEntities = {
        "Providers": "LabAdminClientsProviders",
        "Users": "LabAdminClientsUsers",
        "Clients": "LabAdminClientsLocations",
        "Referring Physicians": "LabAdminClientsReferringPhysicians",
        "Pathologist": "LabAdminUsers",
        "labusers": "LabAdminUsers"
      }
      this.getTabs(getTabsEntities[this.labAdminservice.selectedDropdown])
    }
    else{
      this.store.select('breadcrum').pipe(take(1)).subscribe(data => {
        if (data.length > 0) {
          if (data.length > 1) {
            this.getTabs(data[data.length - 1]['DetailsMenu'])
          }
          else {
            this.getTabs(data[data.length - 1]['SubMenu'][0]['DetailsMenu'])
          }
        }
      })
     }
  }

CloseView(){
  this.CloseDetials.emit(false)
  this.statusChanged[0] && this.store.dispatch(setReloadingPage({URL:this.URL,reload:true}))
}

  async ngOnChanges() {
    let _this = this;
    this.URL=editUrlObj[this.contentOf]?.URL
    this.labAdminSessionService.getLabAdminSession.subscribe(
      async (session) => {
        this.loggedInUserId = session['userDetails'].userId;
        this.loggedInUserOrgId = session['userDetails'].organizationId;
        this.deploymentKey = session['userDetails'].deploymentKey 
      }
    );
    if(this.userInfo.associationType == 'Laboratory'){
      this.contentOf='labUser'
    }
    else if(this.userInfo.associationType == "Ordering Facility"){
      this.contentOf='clientUser'
    }
    _this.showSpinner=true;
    if ((this.userInfo !== '' || this.npiDetails !== '') &&  this.fromLocation !== 'location') {
      this.userid = this.userInfo['userid']
      this.getUserDetailsAndRoles(this.userid);
    }
    else if(this.fromLocation == 'location'){
       this.getLocationDetails();
    }
    else
    _this.showSpinner=false;
  }

   getLocationDetails(){
    if(this.userInfo){
      forkJoin([this.labAdminservice.getLocationDetails(this.userInfo?.['ofAccountId'],Number(this.labAdminservice.organizationId), this.labAdminservice.deploymentKey),this.labAdminservice.GetServices(this.userInfo?.['ofAccountId'],2,this.loggedInUserId,this.labAdminservice.deploymentKey)]) .
      subscribe({next:(data)=>{
        if(data[0]){
          const details=data[0]
          const services=data[1]
          this.userDetails = details;
          this.locationDetails = {...details, viewNPIStatus : details.NPI.toString().toLowerCase() != 'unknown' && details.NPI.toString().length == 10 ? this.getProviderStatus(details.ProviderStatus) : ''};
          this.userDetails.OrganizationID = this.userInfo?.['organizationId'];
          this.userDetails.AccountID = this.userInfo?.['ofAccountId'];
          this.locationDetails.OrganizationID = this.userInfo?.['organizationId'];
          this.locationDetails.AccountID = this.userInfo?.['ofAccountId'];
          this.GetServices(services);
          this.getNpiValidationC('SearchLocation', details.NPI)
        }
        this.showSpinner=false
      },
      error:(err)=>{
        console.error(err)
        this.showSpinner=false 
      }
    })
      
    
    }
  }

   GetServices(data){
   
      if(data) {
        const filteredArray = data
          ?.filter((value: string | null | undefined) => value !== null && value !== undefined && value.trim() !== '') // Trim whitespace
          .map((value: string | null | undefined) => value?.toLowerCase()); // Convert to lowercase

        const uniqueArray = [...new Set(filteredArray)]; // Remove duplicates

        const titleCaseArray = uniqueArray.map((value: string | null | undefined) => {
          // Convert each value to title case
          return value!.replace(/\b\w/g, char => char.toUpperCase()); // Use ! to assert that value is not null or undefined
        });

        this.userDetails.serviceDetails = titleCaseArray.join(', ');
        this.locationDetails.serviceDetails = titleCaseArray.join(', ');
      }
  
  }

  getTabs(menuName: string) {
    let newQuery = this.vitalHttpServices.mainQueryList.filter(
      (x) => x.Keyword == 'getVitalAdminMenusForDx'
    )[0].Query;
    let OrgId = this.labAdminservice.organizationId
    let queryString = "Organizationid in \"" + OrgId + "\":string[] OR Organizationid in \"-1\":string[]"
    let newQueryVariable = {
      CardName: menuName,
      filtername: queryString
    };
    let newQueryResult = this.commonService.GetCardRequest(
      newQueryVariable,
      newQuery
    );
    this.vitalHttpServices.GetData(newQueryResult, this.labAdminservice.deploymentKey).subscribe((newResData) => {
      let data = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
      this.labAdminservice.subMenuPermissions = JSON.parse(newResData.data.submenuData1[0].Permission).MenuItems
      this.tabList = data;
      if (data.length > 0) {
        this.selectedTab = data[0].Htext.toLowerCase();
      }
    });
  }

  getTabData(event) {
    this.selectedTab = event.toLowerCase();
  }

  getUserDetailsAndRoles(userid) {
  let _this=this
    forkJoin([this.labAdminservice.getUserProfile(userid, this.labAdminservice.deploymentKey, true) ,this.labAdminservice.getsaveRoles(this.userid, this.labAdminservice.deploymentKey)]).pipe(map((data:any)=>({userDetails:data[0],roles:data[1]})))
    .subscribe({
      next(value:any){
        _this.userDetails= {...value.userDetails, viewNPIStatus : value.userDetails.NPI.toString().toLowerCase() != 'unknown' && value.userDetails.NPI.toString().length == 10 ? _this.getProviderStatus(value.userDetails.ProviderStatus) : ''}
        //console.log(value.userDetails);
        _this.savedRoles=value.roles
        _this.pathUserId = value.roles.find((j) => j.Role.toLowerCase() == 'pathologist')?.UserID ?? 0
        if(_this.pathUserId === 0){
          _this.tabList = _this.tabList.filter((j) => j.Template.toLowerCase() != 'attachments')
        }        
        _this.getNpiValidationC('SearchUser', value.userDetails.NPI)
        _this.getFlagsOfinfoDetails()
        _this.showSpinner=false
      },
      error(err) {
        console.log(err)
        _this.showSpinner=false
      }

    })
  }

  getuserRoles() {
    this.labAdminservice.getsaveRoles(this.userid, this.labAdminservice.deploymentKey).subscribe(data => {
      this.savedRoles = data;
    })
  }
  redirectoEdit(userId: any) {
    if (this.labAdminservice.fromGlobalSearch) {
      this.EditLocation.emit(userId)
    }
    else {
      if (this.contentOf) {
        let routerObj = { ...editUrlObj[this.contentOf] }
        routerObj.routerLink = routerObj.routerLink + userId
        let obj: any = { Htext: routerObj.Htext, Level: routerObj.Level, routerLink: routerObj.routerLink }
        this.store.dispatch(addNabvTab({ value: obj }));
        routerObj?.URL && this.store.dispatch(disableListView({URL:routerObj?.URL}))
        if (routerObj.queryParams) {
          let finalObj: any = { id: userId, link: routerObj.routerLink };
          this._router.navigate([routerObj.routerLink], { queryParams: routerObj.queryParams })
        }
        else {
          let finalObj: any = { id: userId, link: routerObj.routerLink };
          this._router.navigate([routerObj.routerLink]);
        }
      }
    }
  }
  editLocation(accountId) {
    if (this.labAdminservice.fromGlobalSearch) {
      this.EditLocation.emit(accountId)
    }
    else {
      let routerObj = { ...editUrlObj[this.contentOf] }
      routerObj.routerLink = routerObj.routerLink + accountId
      let obj: any = { Htext: routerObj.Htext, Level: routerObj.Level, routerLink: routerObj.routerLink }
      this.store.dispatch(addNabvTab({ value: obj }));
      routerObj?.URL && this.store.dispatch(disableListView({URL:routerObj?.URL}))
      if (routerObj.queryParams)
        this._router.navigate([routerObj.routerLink], { queryParams: routerObj.queryParams })
      else this._router.navigate([routerObj.routerLink])
    }
  }

  getProviderStatus = (status : string) =>  status ? status : 'Active'
 /////caching the api's used in different places of details page
 infoDetailsFlags$:any=null
 isSharedSurgiCenter$:any=null
 labrotaryLocations$:any=null
 workGroupsC$:any=null
 associationByTranscription$=null
 associationByMedicalStaff$=null
  associationOfLocation$=null
  enableUSerAssociationFlag$=null
 getFlagsOfinfoDetails(){

   const organizationId = this.userDetails['labLocationList'][0]?.OrganizationId;
  if(this.infoDetailsFlags$==null)
   this.infoDetailsFlags$= forkJoin([
     this.labAdminservice.GetVAExtAttrValue(organizationId, ExtAttributeConstants.ManagePathologistPrivilegesForLabAdmin, '2', this.deploymentKey),
     this.labAdminservice.GetVAExtAttrValue(this.loggedInUserOrgId, ExtAttributeConstants.EnableGrossingRoles, '2', this.deploymentKey)
   ]).pipe(shareReplay(1))
   
 }

getSharedSurgicenterC(){
  if(this.isSharedSurgiCenter$==null)
    this.isSharedSurgiCenter$= this.labAdminservice.GetSharedSurgiAccountsData
      (this.userDetails['UserID'], this.loggedInUserId.toString(), this.deploymentKey).pipe(shareReplay(1))
    return this.isSharedSurgiCenter$ 
   }  

   getLabrotaryLocationC(){
    if(this.labrotaryLocations$==null)
    {
      this.labrotaryLocations$=
      this.labAdminservice
      .getLabLocations(
        0, this.loggedInUserId, this.locationDetails.AccountID, this.labAdminservice.deploymentKey
      ).pipe(concatMap((e) => e),
      groupBy((person: any) => person.OrganizationName),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (acc, cur) => {
              cur.IsChecked &&  acc.labGroupData.push(cur);
              return acc;
            },
            { labGroupName: group.key, labGroupData: [] }
          )
        )
      ),
      toArray(),shareReplay(1))
    }
    return this.labrotaryLocations$
   }
   getWorkGroupC(){

    if(this.workGroupsC$==null)
    {
      // this.userDetails['DepartmentIds']="3,4,5,6"
    let newQuery = this.vitalHttpServices.mainQueryList.filter(
      (x) => x.Keyword == 'organizationworkgroups'
    )[0].Query;
    let OrgId = this.labAdminservice.organizationId;
    // let queryString = "OrganizationId in \""+OrgId+"\""
    let newQueryVariable = {
      organizationId: this.labAdminservice.organizationId,
    };
    let newQueryResult = this.commonService.GetCardRequest(
      newQueryVariable,
      newQuery
    );
    this.workGroupsC$=this.vitalHttpServices
      .GetData(newQueryResult, this.labAdminservice.deploymentKey).pipe(shareReplay(1))
      
    }
    return this.workGroupsC$

   }
   getTranscriptionistC(OrganizationID: string,ExecutiveUserId: number,iOFAccountID: string,loggedInUserid: string,type: string){
    if(this.associationByTranscription$==null)
      this.associationByTranscription$= this.labAdminservice.getAssociationsByRole(OrganizationID, ExecutiveUserId, iOFAccountID,loggedInUserid.toString(),type, this.labAdminservice.deploymentKey).pipe(shareReplay(1))  
    return this.associationByTranscription$
   }
   getMedicatlStaffC(OrganizationID: string,ExecutiveUserId: number,iOFAccountID: string,loggedInUserid: string,type: string)
   {
    if(this.associationByMedicalStaff$==null)
      this.associationByMedicalStaff$=this.labAdminservice.getAssociationsByRole(OrganizationID,ExecutiveUserId,iOFAccountID,loggedInUserid.toString(),type,this.labAdminservice.deploymentKey).pipe(shareReplay(1))
    return this.associationByMedicalStaff$
  }

  getAssociatedOfLocationsC(locationIds: string, salesexecutiveId: number)
  {
    if(this.associationOfLocation$==null)
      this.associationOfLocation$=this.labAdminservice
    .getOFAssociationForSE(locationIds, salesexecutiveId, this.labAdminservice.deploymentKey).pipe(
      concatMap((e :any) => e),
      groupBy((j: any) => j.organizationid),
      mergeMap((group: any) => group.pipe(toArray())),
      toArray(),
      map((res: any) =>
        res.map((j) => ({
          orderingFacilityName: j[0].OrganizationName,
          selectedObjects: j.filter((i) => i.isAssociated == true),
        }))
      ),
      shareReplay(1)
    )
    
      return this.associationOfLocation$
  }
  getEnableUserAssociationFlagC(){
    if(this.enableUSerAssociationFlag$==null)
    {    const organizationId = this.userDetails['labLocationList'][0]?.OrganizationId;
      this.enableUSerAssociationFlag$=this.labAdminservice .GetVAExtAttrValue(organizationId, ExtAttributeConstants.EnableUserAssociation, '2', this.labAdminservice.deploymentKey).pipe(shareReplay(1))
    }
    return this.enableUSerAssociationFlag$
  }
  shredSurgicenterOf$=null
  shredSurgicenterforOfC(accountIds)
  {
    if(this.shredSurgicenterOf$==null)
      this.shredSurgicenterOf$=this.labAdminservice.getSharedSurgiCenterForOF(this.locationDetails.AccountID, accountIds, 4, this.labAdminservice.deploymentKey).pipe(shareReplay(1))
    return this.shredSurgicenterOf$
  }
  sharedSurgiCenterOF3$=null
  getsharedsurgicenter_C(accountIds){
    if(  this.sharedSurgiCenterOF3$==null)
      this.sharedSurgiCenterOF3$=    this.labAdminservice.getSharedSurgiCenterForOF(this.locationDetails.AccountID, accountIds, 3, this.labAdminservice.deploymentKey).pipe(shareReplay(1))
  return this.sharedSurgiCenterOF3$
  }
  physicianData$=null
  getphysicianDataC(){
    if(this.physicianData$==null)
    this.physicianData$=this.labAdminservice.getPhysicinData(this.locationDetails.AccountID, this.loggedInUserId, this.labAdminservice.deploymentKey).pipe(shareReplay(1))

      return this.physicianData$
  }
  salesExecutiveAndCourierData$=null
  salesExecutiveAndCourierDataC(accountIds)
  {
    if(this.salesExecutiveAndCourierData$==null)
      this.salesExecutiveAndCourierData$=this.labAdminservice.getSalesExecutiveAndCourierData(this.locationDetails.OrganizationID, this.locationDetails.AccountID, accountIds, this.labAdminservice.deploymentKey).pipe(shareReplay(1))
    return this.salesExecutiveAndCourierData$
  }
  casetypeCategory$=null
  casetypeCategoryC(orgId,ids,deploymentKey){
    if(this.casetypeCategory$==null)
      this.casetypeCategory$=this.labAdminservice.getCaseCategoryByAccId(this.locationDetails.AccountID, orgId,ids ,deploymentKey).pipe(shareReplay(1))
      return this.casetypeCategory$
  }

  getNpiValidationC(action, npi){
    let objData = {
      City: null,
      FirstName: null,
      LastName: null,
      NPI: npi,
      OrganizationName: null,
      State: null,
      Taxonomy: null,
      Zip: null
    }
    if(this.npiValidation$==null)
      this.npiValidation$ = this.labAdminservice.npiValidate(Number(this.labAdminservice.organizationId), action, objData, this.deploymentKey).pipe(shareReplay(1))
    return this.npiValidation$
  }

  pathologistPrivilagesData$=null
  managePathPreviliagesC()
  {
    if(this.pathologistPrivilagesData$==null)
    {
      this.pathologistPrivilagesData$= forkJoin([this.labAdminservice
      .GetVAExtAttrValue(
        this.userDetails['labLocationList'][0]?.OrganizationId,
      ExtAttributeConstants.ManagePathologistPrivilegesForLabAdmin,
      '2',
        this.deploymentKey
      ), this.labAdminservice.GetVAExtAttrValue(this.userDetails['labLocationList'][0]?.OrganizationId, ExtAttributeConstants.EnablePathologistManagement, '2',this.deploymentKey)
    ]).pipe(shareReplay(1))
    }
    return this.pathologistPrivilagesData$

  }
  locationFaxprintingData$=null
  getFaxDataForLocationC(objData){
    if(this.locationFaxprintingData$==null)
      this.locationFaxprintingData$=    this.labAdminservice.FaxandPrinting(objData, this.deploymentKey).pipe(shareReplay(1))
    return this.locationFaxprintingData$
  }
  faxDetails$=null
  getFaxDetailsC(objData)
  {
    if(this.faxDetails$==null){
      this.faxDetails$=this.labAdminservice.FaxandPrinting(objData, this.deploymentKey).pipe(shareReplay(1))
    }
    return this.faxDetails$
  }
  isInstructionEnabled$=null
  getEnableInstructionC()
  {
    if(this.isInstructionEnabled$==null)
    {
      this.isInstructionEnabled$=this.labAdminservice
      .GetVAExtAttrValue(
        this.loggedInUserOrgId ,
        ExtAttributeConstants.EnableInstructions,
        '2',
        this.deploymentKey
      ).pipe(shareReplay(1))
    }
    return this.isInstructionEnabled$
  }
  getAttachments$=null
  getAttachmentC(pathUserId,accountId,oper){
    if(this.getAttachments$==null)
      this.getAttachments$=this.labAdminservice.getAttachmentsAndNotes(
        pathUserId,
        accountId,
        this.loggedInUserId.toString(),
        oper,
        this.deploymentKey
      ).pipe(
        map((attc) =>{
          attc.Attachements = attc.Attachements.map((k) => ({
          ...k,
          disableView: !enableView(k.MediaType)
          }))
          return attc
        }),
        shareReplay(1)
      )
    return this.getAttachments$

  }
  ///location preferences

  getLocationPreferenceData$=null
   getLocationPreferenceC(accountIds){
    if(this.getLocationPreferenceData$==null)
    this.getLocationPreferenceData$=forkJoin([this.labAdminservice
      .GetIsPcEnabled(
        accountIds,
        this.locationDetails.AccountID,
        Number(this.labAdminservice.organizationId),this.deploymentKey),
        this.labAdminservice.CustomOrgValues(Number(this.labAdminservice.organizationId), this.locationDetails.AccountID, 3, this.deploymentKey),
        this.labAdminservice.CustomOrgValues(Number(this.labAdminservice.organizationId), this.locationDetails.OrganizationID, 2, this.deploymentKey),
        this.labAdminservice
      .GetVAExtAttrValue(
        this.loggedInUserOrgId,
        ExtAttributeConstants.AllowLabAdminToViewReports,
        '2',
        this.labAdminservice.deploymentKey
      )]).pipe(shareReplay(1))
      return this.getLocationPreferenceData$
   }
   //location info
   timeZoneForLocation$=null
   gettimeZoneForLocationC(){
    if(this.timeZoneForLocation$==null)
    {
      this.timeZoneForLocation$=this.labAdminservice.GetVAExtAttrValue(this.loggedInUserOrgId,ExtAttributeConstants.EnableTimeZoneConversion,'2',this.deploymentKey
      ).pipe(shareReplay(1))

    }
    return this.timeZoneForLocation$
   }
  
  
}



