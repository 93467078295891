<div class="col-sm-12 row">
  <div *ngIf="detailsDisplay" class="col-sm-12">
    <form [formGroup]="frm_EditUsers" autocomplete="off">
      <!-- Modal Header -->
      <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt"><strong>Edit User</strong></h3>
      </div>
      <!-- Modal body -->
      <div class="row p-0 m-0 mt-2">
        <div class="col-12 ShowFrmAddUserDetails" id="accordin_top">
          <div class="panel-group" id="accordion">
            <!--loctions-->
            <div class="panel panel-default">
              <a data-toggle="collapse" data-target="#UserAccounts" aria-expanded="true" aria-controls="UserAccounts"
                href="#UserAccounts" class="AdduserAccordian">
                <div class="panel-heading accordian">
                  <h4 class="panel-title m-0">
                    Location
                  </h4>
                </div>
              </a>
              <div id="UserAccounts" class="collapse show" data-parent="#accordion">
                <div class="panel-body">
                  <div id="Accounts">
                    <div class="row mb-1 px-1 mx-0">
                      <div class="col-1 p-0 m-0 text-center">

                      </div>
                      <div class="col-5 p-0 m-0">
                        <strong>Locations:</strong>
                      </div>
                      <div class="col-2 px-1 py-0 m-0">
                        <strong>Works:</strong>
                      </div>
                      <div class="col-2 px-1 py-0 m-0">
                        <strong>Manages:</strong>
                      </div>
                      <div class="col-2 px-1 py-0 m-0">
                        <strong>Send for Review:</strong>
                      </div>
                    </div>
                    <div class="row mb-1 px-1 mx-0" id="Location">
                      <div class="col-1 p-0 m-0 text-center">
                        <input type="checkbox" class="form-check-input" formControlName="frmchk_OrgLocation" (change)="edit_onChangefrmchk_OrgLocation($event.target.checked)" />
                        <input type="text" formControlName="frmLblchk_OrgLocation" id="displaybox">
                      </div>
                      <div class="col-5 p-0 m-0">

                      </div>
                      <div class="col-2 px-1 py-0 m-0">
                        <select name="Drp_OrgWorks" id="Drp_OrgWorks" class="form-control" formControlName="frmDrp_OrgWorks"
                          (change)="edit_onChangefrmDrp_OrgWorks($event.target.value)">
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      </div>
                      <div class="col-2 px-1 py-0 m-0">
                        <select name="Drp_OrgManages" id="Drp_OrgManages" class="form-control" formControlName="frmDrp_OrgManages"
                          (change)="edit_onChangefrmDrp_OrgManages($event.target.value)">
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      </div>
                      <div class="col-2 px-1 py-0 m-0">
                        <select name="Drp_OrgSendforReview" id="Drp_OrgSendforReview" class="form-control" disabled=true
                          formControlName="frmDrp_OrgSendforReview" (change)=" edit_onChangefrmDrp_OrgSendforReview($event.target.value)">
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      </div>
                      <br /> <br />
                    </div>
                    <div formArrayName="frmArrEditAccounts">
                      <span *ngFor="let userRl of frm_EditUsers.controls.frmArrEditAccounts.controls; let userAccounts_i = index"
                        formGroupName="{{userAccounts_i}}">
                        <div class="row mb-1 px-1 mx-0">
                          <!-- *ngFor="let data of templateData" -->
                          <div class="col-1 p-0 m-0 text-center">
                            <input type="checkbox" class="form-check-input" formControlName="frmchk_Location" (change)=" edit_onChangefrmchk_Location($event.target.checked)" />
                            <input type="text" formControlName="frmLblchk_Location" id="displaybox">
                          </div>
                          <div class="col-5 p-0 m-0">
                            <input class="col-10 p-0 m-0" type="text" formControlName="frmLbl_Location" disabled=true
                              id="displaynone">
                          </div>
                          <div class="col-2 px-1 py-0 m-0">
                            <select name="Drp_Works" id="Drp_Works" class="form-control" formControlName="frmDrp_Works">
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                          <div class="col-2 px-1 py-0 m-0">
                            <select name="Drp_Manages" id="Drp_Manages" class="form-control" formControlName="frmDrp_Manages">
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                          <div class="col-2 px-1 py-0 m-0">
                            <select name="Drp_SendforReview" id="Drp_SendforReview" class="form-control"
                              formControlName="frmDrp_SendforReview">
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!--Roles-->
            <div class="panel panel-default">
              <a data-toggle="collapse" data-target="#UserRoles" aria-expanded="false" aria-controls="UserRoles" href="#UserRoles"
                class="AdduserAccordian">
                <div class="panel-heading accordian">
                  <h4 class="panel-title m-0">
                    Roles
                  </h4>
                </div>
              </a>
              <div id="UserRoles" class="collapse" data-parent="#accordion">
                <div class="panel-body">
                  <span id="Accounts" *ngIf="showRoles">
                    <div class="row mb-1 px-1 mx-0">
                      <div class="col-1 p-0 m-0">
                      </div>
                      <div class="col-4 p-0 m-0" id="role_size">
                        Roles
                      </div>
                      <div class="col-1 p-0 m-0 text-center" id="role_size">
                        Primary
                      </div>
                      <div class="col-2 p-0 m-0 text-center" id="role_size">
                        Status
                      </div>
                      <div class="col-2 p-0 m-0 text-center" id="role_size">
                        Hold Release Duration in minutes&nbsp;&nbsp;
                      </div>
                      <div class="col-2 p-0 m-0 text-center" id="role_size">
                        Medical Director
                      </div>
                    </div>
                    <br />
                    <div formArrayName="frmArrEditRoles">
                      <span *ngFor="let userRl of frm_EditUsers.controls.frmArrEditRoles.controls; let userRole_i = index"
                        formGroupName="{{userRole_i}}">
                        <div class="row mb-1 px-1 mx-0" id="userrole">
                          <div class="col-1 p-0 m-0">
                            <input type="checkbox" formControlName="frmChk_UserRoles_RoleName" #chkrole class="d-block m-auto"
                              (click)="edit_enableRadioBtnForRoleNameChk(userRl, userRole_i, $event)">
                            <input type="text" id="displaybox" formControlName="frmLblChk_UserRoles_RoleName">
                          </div>
                          <div class="col-4 p-0 m-0">
                            <input type="text" formControlName="frmlbl_DisplayName" disabled='true' id="displayname">
                          </div>
                          <div class="col-1 p-0 m-0">
                            <input type="radio" formControlName="frmRd_UserRoles_RoleName" class="d-block m-auto"
                              [value]="frm_EditUsers.value.frmArrEditRoles[userRole_i].frmLblChk_UserRoles_RoleName"
                              (click)='editTogglePrimaryRole($event, userRole_i)' />
                          </div>
                          <div class="col-2 p-0 m-0">
                            <input type="checkbox" class="d-block m-auto" (click)="ActivateOrInactivate($event,userRl)"
                              formControlName="frmLbl_usrStatus" #chkrole [style.visibility]="chkrole.disabled == true ? 'none' : 'hidden'">
                          </div>
                          <div class="col-2 p-0 m-0" *ngIf="HRD_value==-1">
                            <input type="text" formControlName="frmInp_RoleHoldreleaseDuration" autocomplete="off"
                              #holdduration (keypress)="AllowOnlyNumber($event)" [style.display]="holdduration.disabled == true ? 'none' : 'initial'">
                          </div>
                          <div class=" col-2 p-0 m-0">
                            <input type="checkbox" formControlName="frmChk_IsMedicalDirector" class="d-block m-auto"
                              (click)="MedicalDirectorCheck($event, userRole_i)" *ngIf="MD_KValue == userRole_i">
                          </div>
                        </div>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--UserPersonal info-->
          <div id="Userdata">
            <div class="panel-body">
              <div class="row mb-1 px-2 mx-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1">
                      <label for="Inp_title"><strong>Title</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <select name="Inp_title" id="Inp_title" class="form-control" formControlName="frmInp_title"
                        maxlength="7">
                        <option value="Dr.">Dr.</option>
                        <option value="Miss.">Miss.</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Prof.">Prof.</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Address1"><strong>Address 1 </strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Address1" class="form-control" formControlName="frmInp_Address1" maxlength="50">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_FirstName"><strong>First
                          Name<span class="text-danger pr-1">*</span> &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_FirstName" class="form-control" formControlName="frmInp_FirstName" maxlength="25">
                      <div *ngIf="alertOnSave1 && formcontrol.frmInp_FirstName.invalid">
                        <span class="text-danger"> First Name is required!</span>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Address2"><strong>Address 2 &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Address2" class="form-control" formControlName="frmInp_Address2" maxlength="50">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_LastName"><strong>Last
                          Name<span class="text-danger pr-1">*</span>&nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input type="text" id="inp_LastName" class="form-control" formControlName="frmInp_LastName"
                        maxlength="25" [ng-pattern]="'^[a-zA-Z \-\']$'">
                        <div *ngIf="alertOnSave1 && formcontrol.frmInp_LastName.invalid">
                          <span class="text-danger"> Last Name is required!</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Street"><strong>Street &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Street" class="form-control" formControlName="frmInp_Street" maxlength="25">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_MiddleInitial"><strong>Middle Initial &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_MiddleInitial" class="form-control" formControlName="frmInp_MiddleInitial"
                        maxlength="25">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_City"><strong>City &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_City" class="form-control" formControlName="frmInp_City" maxlength="25">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Qualification"><strong>Qualification &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Qualification" class="form-control" formControlName="frmInp_Qualification"
                        maxlength="50">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_State"><strong>State &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <select id="inp_State" formControlName="frmEditInp_State" class="form-control" maxlength="25">
                        <div *ngIf="frmEditInp_State===''">
                          <option label="Select">Select</option>
                        </div>
                        <option *ngFor="let s of editStateList" value="{{s.LookUpValue}} ({{s.Description}})">
                          {{s.LookUpValue}} ({{s.Description}})
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0" *ngIf="userLoginType != 'Email'">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1">
                      <label for="inp_LoginName"><strong>Login Name</strong></label>
                      <!-- <span class="text-danger pr-1" *ngIf="organizationLoginType=='LoginName'">*</span>&nbsp;&nbsp; -->
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <div>
                        <input id="inp_LoginName" class="form-control" formControlName="frmInp_LoginName" autocomplete="off"
                          maxlength="12" disabled>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Zip"><strong>Zip &nbsp;&nbsp;<span class="pr-1"></span>&nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Zip" (keypress)="AllowOnlyNumber($event)" class="form-control" formControlName="frmInp_Zip"
                        maxlength="7">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_NPI"><strong>NPI<span class="text-danger pr-1">*</span>&nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_NPI" class="form-control" formControlName="frmInp_NPI" maxlength="10">
                      <div *ngIf="alertOnSave1 && formcontrol.frmInp_NPI.invalid">
                        <span class="text-danger"> NPI is required!</span>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Country"><strong>Country &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Country" class="form-control" formControlName="frmInp_Country" maxlength="25">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Gender"><strong>Gender &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <select name="inp_Gender" id="inp_Gender" class="form-control" formControlName="frmInp_Gender">
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Phone#"><strong>Phone &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Phone#"  class="form-control" formControlName="frmInp_Phone" (input)="removeTrailCharacter(frm_EditUsers.controls.frmInp_Phone)"
                      [specialCharacters]="['x','-','.']" [validation]="false"  mask="000-000-0000x0000000000" [dropSpecialCharacters]="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_DisplayFormatWebpage"><strong>Name Display Format &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <select name="inp_DisplayFormatWebpage" id="inp_DisplayFormat" class="form-control"
                        formControlName="frmInp_DisplayFormatWebpage">
                        <option value="F L">First Last</option>
                        <option value="F L D">First Last Qualification</option>
                        <option value="L, F">Last , First</option>
                        <option value="L, F M.">Last , First Middle.</option>
                        <option value="L, F D">Last , First Qualification</option>
                        <option value="S. F L">Title. First Last</option>
                        <option value="S. F L D">Title. First Last Qualification</option>
                        <option value="S. L, F">Title. Last , First</option>
                        <option value="S. L, F M.">Title. Last , First Middle.</option>
                        <option value="S. L, F D">S. L, F D</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Fax"><strong>Fax
                          &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Fax" class="form-control" mask="000.000.0000,000.000.0000,000.000.0000,000.000.0000" [specialCharacters]="[',','.']" [validation]="false" [dropSpecialCharacters]="false" formControlName="frmInp_Fax"
                      (input)="removeTrailCharacter(frm_EditUsers.controls.frmInp_Fax)">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_DisplayFormatReports"><strong>Reports Display Format &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <select name="inp_DisplayFormatReports" id="inp_DisplayFormat" class="form-control"
                        formControlName="frmInp_DisplayFormatReports">
                        <option value="F L">First Last</option>
                        <option value="F L D">First Last Qualification</option>
                        <option value="L, F">Last , First</option>
                        <option value="L, F M.">Last , First Middle.</option>
                        <option value="L, F D">Last , First Qualification</option>
                        <option value="S. F L">Title. First Last</option>
                        <option value="S. F L D">Title. First Last Qualification</option>
                        <option value="S. L, F">Title. Last , First</option>
                        <option value="S. L, F M.">Title. Last , First Middle.</option>
                        <option value="S. L, F D">Title. Last , First Qualification</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Email"><strong>Email<span class="text-danger pr-1" *ngIf="organizationLoginType== 'Email' && frm_EditUsers.value.frmInp_AllowLogin">*</span>&nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input [readonly]="userData.LoginType.toString().toLowerCase() == 'email' && userData.email != ''" type="email" id="inp_Email"
                        class="form-control" formControlName="frmInp_Email" maxlength="50">
                        <div *ngIf="alertOnSave1 && formcontrol.frmInp_Email.invalid && organizationLoginType== 'Email' && frm_EditUsers.value.frmInp_AllowLogin">
                          <span class="text-danger"> Email is required!</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_SSN"><strong>SSN &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_SSN" (keypress)="AllowOnlyNumber($event)" class="form-control" formControlName="frmInp_SSN"
                        maxlength="9">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_UPIN"><strong>UPIN &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_UPIN" class="form-control" formControlName="frmInp_UPIN" maxlength="50">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_UserCode"><strong>User Code &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_UserCode" class="form-control" formControlName="frmInp_UserCode" maxlength="12">
                    </div>
                  </div>
                </div>

              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Region"><strong>Region<span class="text-danger pr-1">*</span>&nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-2 p-0 m-0 pr-3">
                      <input type="checkbox" id="chkUS" formControlName="frmInp_US">&nbsp;
                      <label>US</label>
                    </div>
                    <div class="col-md-2 p-0 m-0 pr-3">
                      <input type="checkbox" id="chkST" formControlName="frmInp_ST">&nbsp;
                      <label>ST</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_ShowMyCaseOnly"><strong>Show MyCase Only &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_ShowMyCaseOnly" type="checkbox" formControlName="frmInp_ShowMyCaseOnly">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0" *ngIf="organizationLoginType.toString().toLowerCase() == 'email'">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_AllowLogin"><strong>Allow Login &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_AllowLogin" formControlName="frmInp_AllowLogin" type="checkbox">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0" *ngIf="showfrmInp_AllowReviewPathologist">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_AllowReviewPathologist"><strong>Allow Review Pathologist - to Sign-out Review
                          Cases &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_AllowReviewPathologist" formControlName="frmInp_AllowReviewPathologist" type="checkbox">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0" *ngIf="showfrmInp_AllowtoeditAccession">
                    <div class="col-md-5 p-0 m-0 pr-1">
                      <label for="inp_AllowtoeditAccession"><strong>Allow to edit
                          Accession &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_AllowtoeditAccession" type="checkbox" formControlName="frmInp_AllowtoeditAccession"
                        [disabled]="true">
                    </div>
                  </div>
                </div>
              </div>
              <!-- here was pathologist licensure -->
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0" *ngIf="showfrmInp_Threshold">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Threshold"><strong>Threshold &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                      <input id="inp_Threshold" class="form-control" formControlName="frmInp_Threshold" autocomplete="off"
                        (keypress)="AllowOnlyNumber($event)" maxlength="10">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0" *ngIf="showfrmInp_EditSite">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_EditSite"><strong>Allow to Edit Site, Grossing - and Processing
                          Information &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_EditSite" type="checkbox" formControlName="frmInp_EditSite" [disabled]="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0" *ngIf="showfrmDrp_ThresholdDuration">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_ThresholdDuration"><strong>Threshold Duration &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3" *ngIf="ArrThresholdDuration.length > 0">
                      <select name="DeploymentKeys" formControlName="frmDrp_ThresholdDuration" class="form-control"
                        maxlength="100">
                        <option label="Select">Select</option>
                        <option *ngFor="let i of ArrThresholdDuration" value={{i.LookUpValue}}>{{i.Description}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1">
                      <label for="inp_AllowPathologistDiagnosisTemplate"><strong>Allow Pathologist Diagnosis
                          Template &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_AllowPathologistDiagnosisTemplate" type="checkbox" formControlName="frmInp_AllowPathologistDiagnosisTemplate">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1">
                      <label for="inp_PopulateDiag"><strong>Auto Populate Diag ICD9Codes &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_PopulateDiag" formControlName="frmInp_PopulateDiag" type="checkbox">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label for="inp_Notifyusers"><strong>Notify users to provide Diagnostic ICD
                          Codes &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0">
                      <input id="inp_Notifyusers" type="checkbox" formControlName="frmInp_Notifyusers">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0" *ngIf="ShowLicensureDetails">
                <div class="col-lg-6 p-0 m-md-1 m-lg-0">
                  <div class="row m-0 p-0">
                    <div class="col-md-5 p-0 m-0 pr-1 ">
                      <label><strong>Licensure &nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-1 px-2 m-0" *ngIf="ShowLicensureDetails">
                <div class="col-lg-12 p-0 m-md-1 m-lg-0">
                  <!-- Pathologist licensure -->
                  <div class="row m-0 p-0 w-100 d-block">
                    <div class="col-md-5 p-0 m-0 pr-1 LicensureLabel ">
                    </div>
                    <div class="col-md-7 p-0 m-0 pr-3">
                    </div>

                    <div class="col-12 p-2 m-0 pr-3 text-center">
                      <div class="row p-1 m-0 d-block" id="pathalogy_scroll">
                        <div class="col-12 p-0 m-0">
                          <div class="row m-0 p-0 mt-2">
                            <div class="col-5 p-0 m-0 d-flex">
                              <strong>State</strong>
                            </div>
                            <div class="col-5 p-0 m-0 d-flex">
                              <strong>Expiration Date (Calendar)</strong>
                            </div>
                            <div class="col-2 p-0 m-0 d-flex">
                              <strong>Add/Delete row</strong>
                            </div>
                          </div>
                          <div formArrayName="formLicensure">
                            <span *ngFor="let userLicensure of frm_EditUsers.controls.formLicensure.controls; let userLicensure_i = index"
                              formGroupName="{{userLicensure_i}}">
                              <div class="row m-0 p-0">
                                <div class="col-5 px-1 m-0" id="Licensure">
                                  <div id="Licensure_array">
                                    <mat-form-field>
                                      <select matNativeControl formControlName="frmDrp_LicsenceStates">
                                        <option label="Select">Select</option>
                                        <option *ngFor="let s of editStateList" value="{{s.LookUpValue}} ({{s.Description}})">
                                          {{s.LookUpValue}} ({{s.Description}})
                                        </option>
                                      </select>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="col-5 px-1 m-0 d-flex">
                                  <mat-form-field id="mat">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="datepicker" formControlName="frmDrp_LicsenceDate">
                                    <mat-datepicker #datepicker></mat-datepicker>
                                  </mat-form-field>
                                  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                                </div>
                                <div class="col-2 p-1 m-0" id="Licensure">
                                  <div id="Licensure_array">
                                    <span>
                                      <button class="m-2" (click)="editlicensure('add', userLicensure_i)" *ngIf="userLicensure_i == (frm_EditUsers.controls.formLicensure.controls.length - 1)"
                                        id="userLicensure">
                                        <em class="fa fa-plus-circle" id="LicensureIcon"></em>
                                      </button>
                                      <button class="m-2" (click)=" editlicensure('delete', userLicensure_i)" id="userLicensure">
                                        <em class="fa fa-minus-circle" id="LicensureIcon"></em>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" id="modalfooter">
        <div class="container-fluid container-wrap w-100 p-0 m-0" *ngIf="detailsDisplay">
          <div class="row m-0 p-0">
            <div class="col-xs-4 col-md-3 col-lg-2 m-0 p-0 mb-sm-2">
              <!-- <button class="btn btn-sm admin-btn-success d-block m-auto" [disabled]="frm_AddUsergroup.invalid" -->
              <button mat-raised-button class="admin-btn-success d-block m-auto" [disabled]="true" id="displaybox"
                (click)=Save(frm_AddUsergroup.value)>Next: Upload Signature</button>
              <div class="add-button mt-4">
                <div *ngIf="!ifStatusDeleted">
                  <button mat-raised-button class="admin-btn-success mr-4" (click)=" changestatus('deleted')">Delete</button>
                </div>
                <button mat-raised-button class="admin-btn-success mr-4" (click)="editBack()">Back</button>
                <button mat-raised-button style="margin-left: -6px;" class="admin-btn-success mr-4" (click)="resetEditUserDetails()">Reset</button>
                <button mat-raised-button *ngIf="organizationLoginType=='Email'" class="admin-btn-success" (click)="editAndClose(frm_EditUsers.value)"
                 >
                  <span style="margin-left: -6px;">Update</span></button>
                <button mat-raised-button *ngIf="organizationLoginType=='LoginName'" class="admin-btn-success" (click)="editAndClose(frm_EditUsers.value)"
                  >
                  <span style="margin-left: -6px;">Update</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Error Part -->
      <div class="col-sm-12">
        <div *ngIf="organizationLoginType=='LoginName'">
          <span class="text-danger text-center" *ngIf="alertOnSave1">
            *Mandatory fields: First Name, Last Name, Login Name,
            Region, NPI !!</span>
        </div>
        <div *ngIf="organizationLoginType=='Email'">
          <span class="text-danger text-center" *ngIf="alertOnSave1">
            *Mandatory fields: First Name, Last Name, Email,
            Region, NPI !!</span>
        </div>
        <span class="text-danger text-center" *ngIf="alertOnSave2">
          Please select Location!!</span>
        <span class="text-danger text-center" *ngIf="alertOnSave4">
          Please Enter valid
          Email!!</span>
        <span class="text-danger text-center" *ngIf="alertOnSave6">
          Please Enter valid NPI
          number!!</span>
        <span class="text-danger text-center" *ngIf="alertOnSave10">
          A user with the same email already exists in this group!</span>
        <!-- <span class="text-danger" *ngIf="alertOnSave6">*Please enter Lab Manager Hold duration time in minutes (0-99)!!</span> -->
        <span class="text-danger text-center" *ngIf="alertOnSaveCommon" id="commonAlertOnSave">{{CommonAlertOnSave}}</span>
      </div>
      <br />
      <br />
    </form>
  </div>
</div>
