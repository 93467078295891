import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';

declare var $: any;
@Component({
  selector: 'app-revise-notes-templates',
  templateUrl: './revise-notes-templates.component.html',
  styleUrls: ['./revise-notes-templates.component.scss']
})
export class ReviseNotesTemplatesComponent implements OnInit, OnDestroy {
  public caseId;
  private deploymentKey = "";
  showTab = false;
  @Input()
  templateData: any;
  public SubMenuCardModel;
  public DeploymentKeys = [];
  dropdownVal: any;
  public RevData: [];
  dKey: boolean;
  gridheader: string[];
  RevNotesGridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  showPagination: boolean;
  gridData: wjcCore.CollectionView<any>;
  gridShowDiv: boolean;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('grid') gridRef: WjFlexGrid;
  gridWidth: number = 0;
  postUpload: boolean;
  selectedData: any = [];
  selector: Selector = null;
  showGrid: boolean = false;
  templateType: string;
  str = '';
  changeData: any;
  action: string = '';
  currentData: any;
  gridPage: boolean = true;
  addEditScreen: boolean = false;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  dragnDrop: boolean;
  copyBtn: boolean;
  deleteBtn: boolean;
  excelDataArray: any[] = [];
  showInvalidColumns: boolean = false;
  copyDataClicked: boolean = false;
  bulkUpload: any = false;
  uploadClicked: boolean = false;
  masterLevel: boolean = false;
  rowId: any;
  selectHeader: string;
  addeddata = [];
  AssociationDetails: any = [];
  accountsList: any = [];
  allFields = [{}]
  sampleData = [{
    templateName: 'Name',
    description: 'Description',
    casetype: 'Casetype1|Casetype2|Casetype3|Casetype4|',
  }]
  sampleDataUpdate = [{
    Id: 1234,
    templateName: 'Name',
    description: 'Description',
  }]
  BulkUpdate = [{}]
  workBook: any;
  sheetsToSelect: string[];
  sheetHeader: any[];
  copyFlag: any;
  invalidColumns: any = '';
  noDataFound: boolean = false;
  gridDisplay: boolean;
  showDelete: boolean;
  selectedCopyData: any = [];
  searchResult: any[];
  searchInput: any;
  orgList: any = [];
  organizationList: any;
  caselist: any = [];
  selectedItems = [];
  searchCaseInput: String = '';
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;



  columns = [
    new ColDef('Description', 'Description'),
    new ColDef('TemplateName', 'Template Name'),
    new ColDef('CaseType', 'Case Type'),
    new ColDef('OrganizationID', 'OrganizationID'),
    new ColDef('Id', 'Id'),

  ];

  copyColumns = [
    new ColDef('templatetype', 'Template Type'),
    new ColDef('casetype', 'Case Type'),
    new ColDef('description', 'Description'),
    new ColDef('templatename', 'Template Name'),
    new ColDef('organizationid', 'OrganizationID'),
    new ColDef('id', 'Id')
  ];

  revisionReasonsForm = this._fb.group({
    frmname: ["", Validators.required],
    frmdesc: ["", Validators.required],
    frmcasetype: []
  })

  copyRevisionReasonsForm = this._fb.group({
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
    frmcasetype: [""],
    frmOrganization: ["", Validators.required],
    frmOrgID: [""],

  })

  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  caselistname: any = [];
  srcDeployment: any;
  searchCaselist: any[];
  usertoggle: boolean;
  Casetypelist: unknown[];
  userList: any[];
  userListArray: any[];
  copycasename: any;
  // deleteBtn: boolean = true;
  gridHeaderList: any;
  listedGridHeader: any;
  listHeaderbackup: any;
  ViewScreen: boolean = false;
  auditableColumns: any;
  saveOrEditCompleted: any;
  activityEntity: any;
  sourceApp: string;
  userSessionDetails: any;
  storeSub: Subscription = new Subscription();
  labAdminSubcription: Subscription = new Subscription();
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;
  dbName: string;
  selectedDeploymentKey: any;
  exportExcelFields = {
    TemplateName: { displayName: "Template Name", isDate: false },
    CasetypeDisplayName: { displayName: "Case Type", isDate: false },
    Description: { displayName: "Description", isDate: false },
    TemplateType: { displayName: "Template Type", isDate: false },
    CreatedBy: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    ModifiedBy: { displayName: "Modified By", isDate: false },
    ModifiedDate: { displayName: "Modified Date", isDate: true },
  };

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,private templateViewService:TemplateViewService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public templateHeaders: TemplateHeadersService, private activityService: ActivityTrackerService,
    private labAdminService: LabadminService, private labAdminSessionService: LabadminSessionService, private store: Store<{ breadcrum: [] }>) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
   if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
    this.sourceApp = 'VitalDx';
    let _this = this;
    // let dbName = this.labAdminService.deploymentKey;
   this.labAdminSubcription = await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async (session:any) => {
      this.storeSub =  this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
        let menuObj = result[result.length -1];
        if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session.userDetails
          _this.templateViewService.globalMasterDataForQueries['TemplateName'] = 'revisionnotes'; 
          _this.templateViewService.globalMasterDataForQueries['accountIds'] =_this.labAdminService?.associatedAccountIds
          await this.commonService.getCommmonTemplateData(menuObj,this.userSessionDetails);
          _this.sourceApp='VitalDx';
          this.templateData=this.labAdminService.templateData;
          _this.dbName = _this.labAdminService.deploymentKey;
          sessionStorage.setItem('deploymentKey', this.dbName);
          sessionStorage.setItem('org_id', this.templateData.secondarykeys.OrganizationId);
          this.getDistinctCaseList();
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.noDataFound = true;
          this.getAuditableDetails(this.templateData.menuURL);
          this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
          this.GetButtonAccess(this.labAdminService.subMenuPermissions);
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
          this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          this.activityEntity.entityId = '';
          this.activityService.setActivitySession(this.activityEntity);
        }
       })
     })
    }
    else {
    this.dbName = this.vitalHttpServices?.deploymentKey?.toString();
    this.sourceApp = 'Admin';
    this.getDistinctCaseList();
    this.templateViewService.globalMasterDataForQueries['TemplateName'] = 'revisionnotes'; 
    this.templateViewService.globalMasterDataForQueries['accountIds'] = sessionStorage?.getItem('AccountID'); 
    this.addDataToGrid(this.templateData.submenuData);
    this.getAuditableDetails(this.templateData.menuURL)
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    this.copyRevisionReasonsForm.controls.frmOrganization.valueChanges.subscribe(x => {
      if (!this.copyRevisionReasonsForm.controls.frmOrganization.value) {
        this.userList = [];
        this.userListArray = [];
      }
    });

    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);

    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
  }
  }

  getDistinctCaseList() {
    let keyword = 'CaseTypeforAddendumNotes';
    let accountID = this.sourceApp === 'VitalDx'
    ? this.userSessionDetails?.userAccountIDs?.map(item => item?.accountId).join(',')
    : sessionStorage?.getItem('AccountID');
    let orgid = sessionStorage.getItem('org_id')

    let queryVariable = { id: orgid.toString() ,assoicatedaccid :`accountId in \"${accountID}\":string [] `};
    let query = this.SubMenuCardModel.GetQuery(keyword);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let casetype;
          this.caselist = [];
          casetype = data.data.submenuData;

          casetype.forEach(element => {
            const temp = {
              'Case_Type': element.CaseType
            }
            this.caselist.push(temp);
          });
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'Case_Type',
            textField: 'Case_Type',
            enableCheckAll: true,
            allowSearchFilter: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false
          };

        }
      }
    }, error => {

      console.error(error);
    });


  }
  onCheckChange(item: any) {
    this.caselistname.push(item.Case_Type);
  }

  onSelectAll(items: any) {
    this.caselistname = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.caselistname.push(items[itr].Case_Type);
    }
  }

  onItemDeSelect(items: any) {
    const index: number = this.caselistname.indexOf(items.Case_Type);
    if (index !== -1) {
      this.caselistname.splice(index, 1);
    }
  }
  public onDeSelectAll() {
    this.caselistname = [];
  }

  uniqueData(data) {
    return data.filter(function (a) {
      var key = a.TemplateName?.toString().toLowerCase() + '|' + a.Description?.toString().toLowerCase() + '|' + a.CaseType?.toString().toLowerCase();
      if (!this[key]) {
        this[key] = true;
        return true;
      }
    }, Object.create(null));
  }

  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'RevisionNotes') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }

    this.gridPage = true;
    this.gridheader = ['CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate', 'Description', 'TemplateName', 'TemplateType', 'CaseType', 'OrganizationId', 'Id','CasetypeDisplayName'];
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    if (data) {
      if (data.length > 0) {
        data = this.uniqueData(data)
        for (let i = 0; i < data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                if (value == null) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        gridarray.sort((a, b) => a.Id < b.Id ? -1 : 1)
      }
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
        // this.hideGrid = false;
        // this.noDataFound = false
      }
      else {
        this.ngxService.stop();
        this.gridShowDiv = false;
        // this.hideGrid = false;
        this.noDataFound = true;
      }
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.RevNotesGridData = new CollectionView(gridarray)
      // this.RevNotesGridData = new CollectionView(gridarray, { pageSize: 10 })
      this.showGrid = true
      this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      this.gridShowDiv = false;
      // this.hideGrid = false;
      this.noDataFound = true;
    }
  }

  refreshGrid(context?) {
    // this.removeGrid();
    if (this.copyDataClicked == true) {
      this.formGroupDirective.resetForm();
    }
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.copyDataClicked = false;
    this.bulkUpload = false;
    if (context != true) {
      this.addEditScreen = false;
    }    //this.noDataFound=false;
    this.uploadClicked = false;
    this.gridWidth = 0;
    this.getData(context);
    if(this.copyDataClicked){
      this.copyRevisionReasonsForm.controls['frmOrganization'].enable();
      this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
    }
    //this.fetchCollectionMethodList(context)
  }
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.selectedItems = [];
    this.selectedData = [];
    this.searchCaselist = [];
    this.searchResult = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.noDataFound = false;
    this.showDelete = false;
    this.copyRevisionReasonsForm.patchValue({
      frmOrgID: "",
      frmOrganization: '',
      frmcasetype: ""
    })
    Object.keys(this.copyRevisionReasonsForm.controls).forEach(key => {
      this.copyRevisionReasonsForm.controls[key].setErrors(null)
    });
    if(this.copyDataClicked){
    if(!this.copyRevisionReasonsForm.controls['frmDepKey'].value){
      this.copyRevisionReasonsForm.controls['frmOrganization'].disable();
      this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
    }
    else{
      this.copyRevisionReasonsForm.controls['frmOrganization'].enable();
      this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
    }
  }
  }

  getData(context?) {
    let keyword = ''
    keyword = 'getOrganizationReviseTemplatesNotes'

    let queryVariable = { type: 'AddendumNotes', orgid: sessionStorage.getItem('org_id') };
    let query = this.SubMenuCardModel.GetQuery(keyword);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          if(this.sourceApp == 'VitalDx')
          {

            this.templateData.submenuData = (data.data.submenuData.length > 0 && this.templateData.secondarykeys?.casetype) ? data.data.submenuData.filter(x => x.CaseType == this.templateData.secondarykeys.casetype ) : data.data.submenuData;
          } else {
          this.templateData.submenuData = data.data.submenuData;
          }
          if (context) {
            return
          }
          if (this.templateData.submenuData == 0) {
            this.gridPage = true;
            this.showGrid = false;
          }
          else {
            this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : null
            this.addEditScreen = false;
            this.gridPage = true;
          }
        }
        if (!this.templateData?.submenuData?.length) this.RevNotesGridData = new CollectionView(...[]);
      }
      if (!this.templateData?.submenuData?.length) this.RevNotesGridData = new CollectionView(...[]);
    }, error => {
      console.error(error);
    });

  }
  initGrid(grid, hitCount?) {
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  //#region Open upload screen with prerequisite data fetch
  loadUploadScreen() {
    if (this.uploadBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.uploadClicked = true;
    this.gridPage = false;
    // this.getTemplates()
    this.bulkUpload = true;
    this.gridWidth = 0
    let queryVariable = { tablename: 'OrganizationReviseTemplates', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          for (let item of data.data.allFields) {
            if (!item.Column.match(/OrganizationReviseTemplateGUID/i) && !item.Column.match(/templateType/i) && !item.Column.match(/OrganizationID/i) && !item.Column.match(/AdminKey/i)) {
              this.allFields[0][item.Column] = null;
            }
            if (!item.Column.match(/Casetype/i) && !item.Column.match(/OrganizationID/i) && !item.Column.match(/templateType/i) && !item.Column.match(/OrganizationReviseTemplateGUID/i) && !item.Column.match(/AdminKey/i)) {
              this.BulkUpdate[0][item.Column] = null;
            }
            this.BulkUpdate[0]["ID"] = null;
          }
          delete this.allFields[0]['CreatedDate']
          delete this.allFields[0]['CreatedBy']
          delete this.allFields[0]['ModifiedDate']
          delete this.allFields[0]['ModifiedBy']
          delete this.allFields[0]['SourceGUID']
          delete this.BulkUpdate[0]['CreatedDate']
          delete this.BulkUpdate[0]['CreatedBy']
          delete this.BulkUpdate[0]['ModifiedDate']
          delete this.BulkUpdate[0]['ModifiedBy']
          delete this.BulkUpdate[0]['SourceGUID']
        }
      }
    }, error => {

      console.error(error);
    });

  }
  //#endregion

  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    this.uploadedHeaders = Object.keys(tempExcelArr[0]);
    this.expectedHeaders =this.bulkUpdateFile ? Object.keys(this.BulkUpdate[0]) : Object.keys(this.allFields[0]);
    if(this.bulkUpdateFile){
        // Check if arrays are of equal length
        if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
          this.UpdateColumnMisMatch = true;
       }
       this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
    }else if(!this.bulkUpdateFile && !copyfrmorg){
      if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
        this._snackbar.open("Invalid excel", "Close") 
        return;
      }
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if ((key.toString().toLowerCase() == 'templatename' || key.toString().toLowerCase() == 'description' || key.toString().toLowerCase() == 'casetype') && value != null && value != undefined) {
              value = value.toString().trim();
            }
            if (value == null) {
              value = null;
            }
            else if (value == '') {
              value = ''
            }
            else if (value.toString().match(/null/i)) {
              value = 'null'

            }
            primary[key] = value;
          }
        }
        primary['templatetype'] = "AddendumNotes"
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    this.copyColumns = [
      new ColDef('templatetype', 'Template Type'),
      new ColDef('casetype', 'Case Type'),
      new ColDef('description', 'Description'),
      new ColDef('templatename', 'Template Name'),
      new ColDef('organizationid', 'OrganizationID'),
      new ColDef('id', 'Id')
    ];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push('ResultMessage')
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push('ResultMessage')
      this.copyColumns.push(
        new ColDef('resultmessage', 'ResultMessage'),
      )
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
      for(let i=0; i<tempExcelArr.length; i++){
       for(let j=0; j<this.missingColumn.length; j++){
         tempExcelArr[i][this.missingColumn[j]] = '';
    
       }
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    let orgId = sessionStorage.getItem('org_id');
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, resultmessage: '', tablename: 'OrganizationReviseTemplates', organizationid: orgId.toString(), accountid: this.templateData.cardIdentifier });
      i++;
    });
    this.ngxService.start('4');
    this.validateExcel(this.excelDataArray);
    this.ngxService.stop('4');
  }



  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString() == 'Ignored' || value == 'Mandatory field is missing!' || value.toString().includes('Field is missing') || value.toString().toLowerCase().includes('already exists') || value == 'Failure'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('Does not') || value.toString().includes('not exist') || value == 'Id Missing') {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().toLowerCase().includes('approval') || value.toString().toLowerCase().includes('successfully') || value == 'Success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
      }
    }
  }


  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.validateRevisionNotesData(dataArray).subscribe(result => {
      if (!result.errors) {
        this.ngxService.stop();
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          for (let i = 0; i < dataArray.length; i++) {
            if (!this.copyDataClicked) {
              if (this.bulkUpdateFile && (this.missingColumn.some(e=> e.toLowerCase() == 'id') || (!dataArray[0].templatename && !dataArray[0].description))) {
                this._snackbar.open("Please provide valid data", 'Failed');
                this.ngxService.stop();
                return;
              }
            }
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.resultmessage = r.NoteMessage;
              }
            });
          });
          this.ngxService.start('2')
          this.AddGridData(dataArray, 1);
          this.ngxService.stop('2')
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
          this.ngxService.stop()
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.error(error);
    });
    this.ngxService.stop();
  }

  AddGridData(data, hitCount) {
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["result"] || this.excelDataArray[i]["result"] == '') {
        this.excelDataArray[i]["result"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (value && value.toString().match(/null/i)) {
                value = "NULL"
                primary['resultmessage'] = 'Null values exist!'
              }
              // if ((key == 'templatename' || key == 'description' || key == 'casetype') && (!value || value.toString() == '')) {
              //   value = ''
              //   primary['notes'] = 'Mandatory field is missing!'
              // }
              if ((key == 'templatename' || key == 'description' || key == 'casetype')) {
                if(value){
                  value = value
                }
                else if(this.UpdateColumnMisMatch && this.missingColumn.length > 0){
                    if(this.missingColumn.find(e=> e.toLowerCase() == key.toString().toLowerCase())){
                     value = '';
                    }else{
                      value = ''
                      primary['resultmessage'] = 'Mandatory field is missing!'
                    }
                } 
                else{
                  value = ''
                  primary['resultmessage'] = 'Mandatory field is missing!'
                }
              }
              if(key == 'id' && (!value || (value && value.toString().toLowerCase().trim() == ''))){
                  value = ''
                  primary['resultmessage'] = 'Id Missing'
              }

            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.gridData = new CollectionView(gridArray);
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
  }

  approveRevNotesmethod(copyData?) {
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["resultmessage"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["resultmessage"].toString().match(/approval/i) && !this.excelDataArray[i]["resultmessage"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (!this.bulkUpdateFile && (value === "null" || value === "" || value === null)) {
              this.excelDataArray[i][key] = null;
            }
            else if(this.bulkUpdateFile){
               if(value === ""){
                 this.excelDataArray[i][key] = ''
               }else if(value === null || value === "null"){
                 this.excelDataArray[i][key] = null
               }
              }
            else{
                 this.excelDataArray[i][key] = value.toString();
            }
          }
          if(this.bulkUpdateFile){
            this.excelDataArray[i]["modifiedby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          }else if(!this.bulkUpdateFile){
            this.excelDataArray[i]["createdby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        selectedData.push(this.selectedData[i]._data);
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      copyData ? dataArray.some(va => va.resultmessage.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected addendum note already exists", "Close") : null : null
      let sendjson = dataArray.filter(va => !va.resultmessage.toString().toLowerCase().match(/already exists/i))
      let context = copyData ? null : ''
      sendjson.length > 0 ? sendjson.some(va => va.id) ? this.editMethod(sendjson, true) : this.addRevNotesmethod(sendjson, true, context) : this._snackbar.open('Revision notes already exists', 'Close')
    }
  }

  //#region
  addRevNotesmethod(dataArray: object[], bulkUpload: boolean = false, context?) {
    this.ngxService.start();
    this.vitalHttpServices.InsertRevReasons(dataArray,this.dbName).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        //this.copyRevisionReasonsForm.controls.frmOrganization.disable()
        if (result.length > 0) {
          if (this.copyDataClicked) {
            this.commonService.auditDetails('', '', [], dataArray, 'Copy', this.templateData, this.auditableColumns);
          }
          else {
            this.commonService.auditDetails('', '', [], dataArray, 'Upload', this.templateData, this.auditableColumns);
          }
          this.postUpload = true;
          this.showDelete = false;
          // this.postDownload = true;
          if (this.copyDataClicked && this.postUpload) {
            this.copyRevisionReasonsForm.controls['frmOrganization'].disable();
            this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
          }
          this.showDelete = false;
          this.masterLevel = false;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult[0].data.length; j++) {
              if (this.excelDataArray[i]['slno'] == uploadresult[0].data[j]['slno']) {
                this.excelDataArray[i]['result'] = uploadresult[0].data[j]['Message'].toString().match(/Created successfully/i) ? 'Success' : 'Ignored'
                this.excelDataArray[i]['resultmessage'] = uploadresult[0].data[j]['notes']
              }
            }
          }
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          this.sheetHeader.push('Result');
          this.copyColumns.push(
            new ColDef('result', 'Result'),
          )
          this.AddGridData(this.excelDataArray, 0);

          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exist!', 'Close') }
            else { this._snackbar.open('Revision notes already exists!', 'Close'); }
            return;
          }
          else {
            let message = bulkUpload ? 'Data uploaded successfully' : this.copyFlag ? 'Revision notes copied successfully' : 'Revision notes created successfully';
            this._snackbar.open(message, 'Close');
          }
          if (!this.bulkUpload) {
            if (context == '') {
              this.getInputData('', this.templateData.submenuData);
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion

  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.UpdateColumnMisMatch = false
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulk update')){
      this.bulkUpdateFile = true
    }
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
        if (this.sheetsToSelect.length > 1) {
          let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'addendumnotes')
          this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
        } else {
          this.convertToJson(this.sheetsToSelect[0])
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }


  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data['slno']
      delete e._data['tablename']
      delete e._data['copyflag']
      excel.push(e._data);
    });
    // this.templateData.menuURL.toString().toLowerCase()
    let filename = 'Revision notes_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationReviseTemplates');
    XLSX.writeFile(wb, filename);
  }

  downloadBulkUpdate() {
    let filename = 'Revision notes_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.BulkUpdate);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationReviseTemplates');
    ws = XLSX.utils.json_to_sheet(this.sampleDataUpdate);
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData');
    XLSX.writeFile(wb, filename);
  }

  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = ""
    filename = 'Revision notes_' + 'AllFields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.allFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'AddendumNotes');
    ws = XLSX.utils.json_to_sheet(this.sampleData);
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData');
    XLSX.writeFile(wb, filename);
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
  }

  //Copy from diff entity
  loadCopyScreen() {
    if (this.copyBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.copyDataClicked = true
    this.gridPage = false;
    this.noDataFound = false;
    this.copyRevisionReasonsForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey'),
      frmOrgType: "Laboratory",
      frmOrganization: "",
      frmcasetype: "",
      frmOrgID: ""
    })
    Object.keys(this.copyRevisionReasonsForm.controls).forEach(key => {
      this.copyRevisionReasonsForm.controls[key].setErrors(null)
    });
    //this.resetFormErrors()
    // this.disableOrgSearch = true;
    // this.destDeployment = sessionStorage.getItem('deploymentKey')
    // this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys
    this.getListOrg();
    if(!this.copyRevisionReasonsForm.controls['frmDepKey'].value){
      this.copyRevisionReasonsForm.controls['frmOrganization'].disable();
      this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
    }
    else{
      this.copyRevisionReasonsForm.controls['frmOrganization'].enable();
      this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
    }
  }

  onChangeDeployment(e, depKey) {
    this.selectedDeploymentKey = depKey;
    if (e.source.selected) {
      this.copyRevisionReasonsForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: "",
        frmOrgID: "",
        frmcasetype: "",
      });
      this.srcDeployment = depKey;
      this.gridWidth = 0
      this.searchInput = '';
      this.searchResult = [];
      this.searchCaselist = []
      this.searchCaseInput = '';
      this.postUpload = false;
      this.excelDataArray = []
      this.getListOrg();
      this.copyRevisionReasonsForm.controls['frmOrganization'].enable();
      this.copyRevisionReasonsForm.controls['frmcasetype'].disable();
    }

  }

  getListOrg() {
    let dbName = this.copyRevisionReasonsForm.value.frmDepKey;
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery("orgtype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        //this.resetFormErrors();
        if (data) {
          if (data.data.submenuData.length > 0) {
            this.orgList = data.data.submenuData
            this.organizationList = this.orgList.filter(o => o.orgtype.toLowerCase() != 'ordering facilities');
            setTimeout(() => {
              this.fetchOrgSeries(null);
            }, 500);
          }
        }
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }

  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = []
    this.searchCaselist = []
    this.copyRevisionReasonsForm.value.frmOrgID = ""
    value = value?.toString().trim();
    if (!value) {
      this.searchResult = this.organizationList.filter(x => x.orgname).sort((a, b) => a.orgname?.toString().toLowerCase() < b.orgname?.toString().toLowerCase() ? -1 : a.orgname?.toString().toLowerCase() > b.orgname?.toString().toLowerCase() ? 1 : 0)
      return (this.searchResult = this.searchResult.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/

    if (this.copyRevisionReasonsForm.value.frmOrgType) {
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.orgname != null) {
            return series.orgname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });

        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.orgid != null) {
          return series.orgid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
      this.copyRevisionReasonsForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.orgname != null) {
        return series.orgname.toString().toLowerCase() == value.toLowerCase() ? series.orgid : "";
      }
    });
    //this.resetFormErrors()
    }
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.copyRevisionReasonsForm.patchValue({
        frmOrgID: data.orgid,
        frmOrganization: data.orgname,
        frmcasetype: "",
      })
      this.copyRevisionReasonsForm.controls['frmcasetype'].enable();
      this.getCaseList(data.orgid)
    }
    //this.getDataToCopy();
  }
  getCaseList(org_id) {
    let orgid = org_id === null ? sessionStorage.getItem('org_id') : org_id;
    let queryVariable = { id: orgid.toString() };
    let query = this.SubMenuCardModel.GetQuery('CaseTypeforAddendumNotes');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let dbName;
    let casetypelists = [];
    dbName = this.srcDeployment;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        let casesnamelist = data.data.submenuData
        this.Casetypelist = [...new Map(casesnamelist.map((item) => [item['CaseType'], item['CaseType']])).values(),]
        casetypelists.push(...this.Casetypelist);
        this.userList = casetypelists;
        this.userListArray = casetypelists;
      }
    }, error => {
      console.error(error);
    });
  }
  //fetch based on the orgid selected the siteid will show
  fetchCaseSeries(value: string) {
    value = value?.toString().trim();
    // this.removeGrid();
    this.searchCaselist = []
    value = value?.toString().trim();
    if (!value) {
      this.searchCaselist = this.userListArray.filter(x => x).sort((a, b) => a?.toString().toLowerCase() < b?.toString().toLowerCase() ? -1 : a?.toString().toLowerCase() > b?.toString().toLowerCase() ? 1 : 0)
      return (this.searchCaselist = this.searchCaselist.splice(0, 25));
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.searchCaselist.find(r => {
          if (r.toString().toLowerCase() === value.toLowerCase()) {
            // this.srcUserid = r.userid
            return r
          } else {
            // this.srcUserid = ''
            //return r.push('All')
            this.usertoggle = true
          }
        })
        this.searchCaselist = this.userListArray.filter(function (series) {
          if (series != null) {
            return series.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchCaselist = this.searchCaselist.splice(0, 25);
      }
    }
    else {
      this.searchCaselist = this.userListArray.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
        }
      });
      this.searchCaselist = this.searchCaselist.splice(0, 25);
    }

  }
  selectedCopyDataSite(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.copycasename = data
      // this.getDataToCopy();
    }
  }


  getDataToCopy() {
    this.postUpload = false;
    let queryVariable;
    if (typeof (this.copyRevisionReasonsForm.value.frmOrgID) == 'object') {
      let frmorgid: any
      frmorgid = this.copyRevisionReasonsForm.value.frmOrgID
      this.copyRevisionReasonsForm.value.frmOrgID = frmorgid[0].orgid
    }
    if (this.copyRevisionReasonsForm.value.frmcasetype === "") {
      queryVariable = { orgid: this.copyRevisionReasonsForm.value.frmOrgID.toString() };

    }
    else {
      queryVariable = { orgid: this.copyRevisionReasonsForm.value.frmOrgID.toString(), CaseType: this.copyRevisionReasonsForm.value.frmcasetype };
    }
    let query = this.SubMenuCardModel.GetQuery("getOrganizationReviseTemplatesNotesCopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyRevisionReasonsForm.value.frmDepKey).subscribe(data => {
      if (!data.errors) {
        let copyData = data.data.submenuData;
        copyData = this.uniqueData(copyData)

        if (copyData.length == 0) {
          this.gridWidth = 0;
          this.ngxService.stop();
          this.noDataFound = true;
          return
        }
        this.noDataFound = false;
        this.copyDataProcess(copyData);
        this.ngxService.stop();
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }
  disableGetDataBtn() {
    return this.copyRevisionReasonsForm.value.frmOrgID == '' ? true : (this.orgList) != '' ? false : true
  }

  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.match(/name/i)) {
          primary['templatename'] = value
        }
        else if (key.match(/description/i)) {
          primary['description'] = value
        }
        else if (key.match(/casetype/i)) {
          primary['casetype'] = value;
        }
        delete copyData[i]['result']
      }
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }
  //#endregion of Copy

  exportReviseTemplatesMethodList(RevNotesGridData) {
    if (this.exportBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    let excel = [];
    let modifiedExcel: any = [];
    // const view = this.gridRef.collectionView;
    // this.gridRef.beginUpdate()
    // let rows = this.gridRef.rows
    let rows = RevNotesGridData._pgView
    rows.find((e: any) => {
      excel.push(e);
    });

    for (let i = 0; i < excel.length; i++) {
      modifiedExcel[i] = {}
      modifiedExcel[i]["Id"] = excel[i]["Id"]
      modifiedExcel[i]["OrganizationID"] = excel[i]["OrganizationID"]
      modifiedExcel[i]["CaseType"] = excel[i]["CaseType"]
      modifiedExcel[i]["Template Type"] = "AddendumNotes"
      modifiedExcel[i]["Template Name"] = excel[i]["TemplateName"]
      modifiedExcel[i]["Description"] = excel[i]["Description"]
      modifiedExcel[i]["CreatedBy"] = excel[i]["CreatedBy"]
      modifiedExcel[i]["CreatedDate"] = excel[i]["CreatedDate"]
      modifiedExcel[i]["ModifiedBy"] = excel[i]["ModifiedBy"]
      modifiedExcel[i]["ModifiedDate"] = excel[i]["ModifiedDate"]
    }
    let filename = 'Revision notes_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      modifiedExcel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + "OrganizationRevisionNotes");
    XLSX.writeFile(wb, filename);
    // this.gridRef.endUpdate()
  }

  getInputData(action, data) {
    if (this.createBtn || this.editBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.action = action;
    if (action.toLowerCase() == 'edit') {
      this.currentData = data
      let casesselectedvalue = data.CaseType;
      let casevaluelist = [];
      this.selectedItems = []
      casevaluelist = casesselectedvalue.split('|');
      casevaluelist.forEach(element => {
        this.selectedItems.push({ 'Case_Type': element.trim() })
      });

      this.revisionReasonsForm.patchValue({
        frmcasetype: this.selectedItems,
        frmname: this.currentData.TemplateName,
        frmdesc: this.currentData.Description
      })
    }
    else {
      this.revisionReasonsForm.reset();
      //this.action = this.templateData.menuURL.toString().match(/extraction procedure/i) ? 'Create Extraction Procedure' : 'Create Collection Method'
      Object.keys(this.revisionReasonsForm.controls).forEach(key => {
        this.revisionReasonsForm.controls[key].setErrors(null)
      });
    }
    this.gridPage = false;
    this.addEditScreen = true;
  }
  saveReviseReasons(action, data) {
    if (this.action.toLowerCase() == 'edit') {
      this.editRevTemplates(data)
    }
    else {
      this.createRevTemplates(action, data);
    }
  }
  //edit
  editRevTemplates(data) {
    const { frmname, frmcasetype, frmdesc } = data;
    if (!frmname.toString().trim() || !frmdesc.toString().trim() || frmcasetype.length < 1) {
      this._snackbar.open('Please enter the required fields!', 'Close')
      return
    }

    if (this.templateData.submenuData.some((va: any) =>
      va.TemplateName.toString().toLowerCase() == frmname.toString().toLowerCase() && va.CaseType == frmcasetype)) {
      this._snackbar.open('Revision notes already exists!', 'Close');
      return;
    }
    let cases = '';
    for (let index = 0; index < frmcasetype.length; index++) {
      cases += frmcasetype[index].Case_Type + '|';
    }


    cases = cases.substring(0, cases.length - 1);
    let saveData = {
      id: this.rowId,
      organizationid: this.templateData.secondarykeys.OrganizationId,
      casetype: cases,
      templatetype: "AddendumNotes",
      templatename: frmname,
      description: frmdesc,
      flag: 1
    }
    this.ngxService.start();
    this.editMethod([saveData]);

    // .catch(error => { this._snackbar.open("An error occurred while processing your request", "Failed"); console.error(error) })
    // this.ngxService.stop();
  }

  editMethod(saveData, bulkUpdate?: boolean) {
    bulkUpdate ?
      saveData.forEach(element => {
        element.flag = 0
      }) : null;
    this.vitalHttpServices.editRevTemplates(saveData,this.dbName).subscribe(response => {
      if (response[0].Success == true) {
        this._snackbar.open("Revision notes updated successfully", "Close")
        this.templateData.secondarykeys.casetype = '';
        if (!bulkUpdate) { this.refreshGrid(); }
        else {
          if (response.length > 0) {
            this.commonService.auditDetails('', '', [], saveData, 'Bulk Update', this.templateData, this.auditableColumns);
            this.postUpload = true;
            this.showDelete = false;
            // this.postDownload = true;
            this.showDelete = false;
            this.masterLevel = false;
            let uploadresult = this.convertObjKeysToLower(response);
            for (let i = 0; i < this.excelDataArray.length; i++) {
              for (let j = 0; j < uploadresult[0].data.length; j++) {
                if (this.excelDataArray[i]['slno'] == uploadresult[0].data[j]['slno']) {
                  this.excelDataArray[i]['result'] = uploadresult[0]['message'].toString().match(/updated successfully/i) ? 'Success' : 'Ignored'
                  this.excelDataArray[i]['resultmessage'] = uploadresult[0].data[j]['notes']
                }
              }
            }
            let dataExistsCount = 0;
            for (let i = 0; i < response.length; i++) {
              if (Object.values(response).every(function (item: any) {
                return item.Status == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
            this.sheetHeader.push('Result');
            this.copyColumns.push(
              new ColDef('result', 'Result'),
            )
            this.AddGridData(this.excelDataArray, 0);

            if (dataExistsCount == response.length) {
              this._snackbar.open('Revision notes already exists', 'Close');
              return;
            }
            else {
              let message = 'Data updated successfully';
              this._snackbar.open(message, 'Close');
            }
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      }
      else {
        this._snackbar.open("Revision notes update failed", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  //create
  createRevTemplates(action, data) {
    const { frmname, frmcasetype, frmdesc } = data;
    if (!frmname || !frmdesc || !frmname.toString().trim() || !frmdesc.toString().trim() || !frmcasetype || frmcasetype.length == 0) {
      this._snackbar.open('Please enter the required fields!', 'Close')
      return
    }
    let existingCasetypes = ""
    frmcasetype.forEach(val => {
      if (this.templateData.submenuData && this.templateData.submenuData.some(va => va.TemplateName == frmname && va.Description == frmdesc && va.CaseType && va.CaseType.includes(val.Case_Type))) {
        //put popup here
        existingCasetypes += val.Case_Type + '|'
      }
    })

    if (this.templateData.submenuData.some((va: any) =>
      va.TemplateName.toString().toLowerCase() == frmname.toString().toLowerCase() && va.CaseType.trim() == frmcasetype)) {
      this._snackbar.open('Revision notes already exists!', 'Close');
      return;
    }
    if (existingCasetypes.length > 0) {
      existingCasetypes = existingCasetypes.substring(0, existingCasetypes.length - 1);
      this._snackbar.open("Revision notes already exists for " + existingCasetypes, "Close")
      return;
    }
    let cases = '';
    for (let index = 0; index < frmcasetype.length; index++) {
      cases += frmcasetype[index].Case_Type + '|';

    }
    cases = cases.substring(0, cases.length - 1);
    let saveData = {
      id: this.rowId,
      organizationid: this.templateData.secondarykeys.OrganizationId,
      templatetype: "AddendumNotes",
      casetype: cases,
      templatename: frmname,
      description: frmdesc
    }
    this.ngxService.start();
    this.vitalHttpServices.InsertRevReasons([saveData],this.dbName).subscribe(response => {
      this._snackbar.open("Revision notes created successfully", "Close")
      if (action == 'close') {
        this.refreshGrid();
      }
      else {
        this.refreshGrid(true);
        this.revisionReasonsForm.reset();
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
    // .catch(error => { this._snackbar.open("An error occurred while processing your request", "Failed"); console.error(error) })
    // this.ngxService.stop();
  }
  openEditScreen(grid, event?) {
    if (this.editBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    // var hti = grid.hitTest(event);
    // let selectedrowData = hti.panel.rows[hti.row].dataItem;
    let selectedrowData = grid;
    // this.selectedrowData = selectedrowData;
    this.getInputData('edit', selectedrowData);
    this.rowId = selectedrowData.Id
  }




  // auditDetails(oldObj, newObjArray, value) {
  //   // let oldObject = {}
  //   if (value.match(/edit/i)) {
  //     this.ngxService.start();
  //     this.commonService.createActivityTracker(value, oldObj.ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[0], oldObj);
  //     this.ngxService.stop();
  //   } else {
  //     //new record
  //     this.ngxService.start();
  //     this.commonService.createActivityTracker(value, newObjArray.ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray, {});
  //     this.ngxService.stop();
  //   }
  // }
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  #editStarting
  newEditMethod(editObj: any) {
    this.openEditScreen(editObj.rowData, editObj.event);
  }

  newDeleteMethod(deleteObj: any) {
    this.deleteReasonRow(deleteObj.event, deleteObj.rowData)
  }

  recieveObjFromListing(object: any) {
    const newObj = {};
    object.newData = { ...object.newData, ...{ 'templatetype': 'AddendumNotes' } };
    if (object.newData.CaseType.length == 0) {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this._snackbar.open("Please select at least one case type!", "Close");
      return;
    }
    object.newData.CaseType = object.newData.CaseType.join('|');
    if (object.newData.Action === 'Create') {
      delete object.newData.Action
      delete object.newData.ModifiedBy
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.createRevisionNotesTemplates(newObj);
    }

    else {
      object.newData['id'] = object.oldData['Id'];
      object.newData = { ...object.newData, ...{ 'Flag': 1 } };
      delete object.newData.Action
      delete object.newData.CreatedBy
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.editRevisionNotesTemplate(newObj, object)
    }
  }

  editRevisionNotesTemplate(saveData, oldObj) {
    this.ngxService.start();
    this.vitalHttpServices.editRevTemplates([saveData],this.dbName).subscribe(response => {
      if (response[0].Success == true) {
        if (response[0].Data[0].notes.includes('already exist')) {
          this._snackbar.open("Revision notes already exists.", "Close")
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        }
        else {
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this.commonService.auditDetails('Id', 'TemplateName', [oldObj.oldData], [saveData], 'Edit', this.templateData, this.auditableColumns);
          this._snackbar.open("Revision notes updated successfully", "Close")
          this.refreshGrid();
        }       
      }
      else {
        this._snackbar.open("Revision notes update failed", "Close");
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  createRevisionNotesTemplates(data) {
    this.ngxService.start();
    this.vitalHttpServices.InsertRevReasons([data],this.dbName).subscribe(response => {
      if (response[0].Success == true) {

        let newActivityData = structuredClone(data);
        newActivityData['casetype'] = structuredClone(data?.casetypeobject?.CasetypeDisplayName);

        this.commonService.auditDetails('Id', 'templatename', [response[0].Data[0]], [newActivityData], 'Create', this.templateData, this.auditableColumns);
        if(response[0].Data[0].Message.includes('already exists'))
        {
          this._snackbar.open("Revision notes already exists.", "Close")
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        }
        else 
        {
          this._snackbar.open("Revision notes created successfully.", "Close")
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this.refreshGrid();
        }
      } else {
        this._snackbar.open("Revision notes creation failed", "Close");
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  deleteReasonRow(flex, rowData): any {
    if (!this.deleteBtn) {
      // rowData['OrganizationId'] = rowData['orgId']
      delete rowData['orgId'];
      let ObjData = rowData
      ObjData.OrganizationId = this.templateData.secondarykeys.OrganizationId;
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
          autoFocus: false,
        width: '400px',
        panelClass: 'admin-custom-popup',
        // data: { header: "", message: "Clicking on yes will delete the selected row.", alert: "Do you wish to continue?", continue: "yes", cancel: "no" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.ngxService.start();
          this.vitalHttpServices.deleteRevtemplateNotes(ObjData, this.dbName).subscribe(data => {
            ObjData['CaseType'] = ObjData['CasetypeDisplayName'];
            this.commonService.auditDetails('Id', 'TemplateName', [ObjData], [], 'Delete', this.templateData, this.auditableColumns);
            this.ngxService.stop();
            this._snackbar.open('Revision Notes Deleted successfully!', "Close");
            // this.templateData.secondarykeys.casetype = '';
            this.refreshGrid();
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.templateData.submenuData = this.templateData.submenuData.filter(va => va.Id != rowData.Id);
          }, error => {
            console.error(error)
            this.ngxService.stop();
          });
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  onSelectionCasetype(event)
  {
    this.templateData.secondarykeys.casetype = event?.CaseType?.object?.CaseType != "All Case Types" ? event?.CaseType?.object?.CaseType : "";
    this.refreshGrid();
  }

  ngOnDestroy() {
    if(this.labAdminSubcription) {
      this.labAdminSubcription.unsubscribe();
    }
    if(this.storeSub) {
      this.storeSub.unsubscribe();
    }
    this.templateViewService.globalMasterDataForQueries['TemplateName'] = ''; 
    this.templateViewService.globalMasterDataForQueries['accountIds'] ='';
  }
  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyRevisionReasonsForm, controlName , this.copyRevisionReasonsForm.value.frmOrganization)
  }
  trimCasetypeFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyRevisionReasonsForm, controlName , this.copyRevisionReasonsForm.value.frmcasetype)
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
