<div class="modal-dialog Caselist">
  <!-- Modal Header -->
  <div class="modal-content header-wrapss">
    <form [formGroup]="frm_caselist">
      <div class="modal-header admin-model-header header-freeze">
        <h3 class="modal-title w-100 admin-model-header-txt"> Bulk Upload Caselist
        </h3>
        <span type="button" data-dismiss="modal" (click)="caseListClose()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons cursor" title="Close">
        </span>
      </div>
      <div class="modal-body pb-0 pt-0 mt-3" *ngIf="!FormDataEntry">
        <div class="container-fluid p-0 m-0">
          <div class="row mb-1 mx-0">
            <div class="col-lg-12 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-6 float-left pl-0 dep-wrap">
                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                    <mat-label value="none">
                      <strong>Deployment
                      </strong>
                    </mat-label>
                    <mat-select disableOptionCentering formControlName="frmDeploymentKey" [disabled]="disableDeployment"
                      (selectionChange)="onChangeDep($event.value)" class="deploment">
                      <mat-option *ngFor="let i of DeploymentKeys" value="{{i.toString().toUpperCase()}}">{{i}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="dataSelect" class="col-sm-12">
      <form [formGroup]="EnteredData" autocomplete="off" class="form-height">
        <div class="button-field-wrap m-1 p-0">
          <mat-form-field class="col-sm-3 example-additional-selection p-0 copyfrmOrg-dropdown" appearance="outline">
            <mat-label class="d-flex">
              Templates
            </mat-label>
            <mat-select isableOptionCentering class="ml-2 temp-mat-align">
              <mat-option class="temp-font-size" (click)="downloadTemplate('Bulk Update')">
                <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="button-field-wrap">
          <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Case List.</span>
        </div>

          <div class="col-sm-12" *ngIf="gridWidth == 0">
            <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
              <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
                hidden />
              <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto" />
              </span>
              <span>Drag & Drop excel files here</span>
              <span class="align-center">Or</span>
              <label class="Expbtn btn-sm btn rounded browse ml-5" style="background-color: #3874ff;" for="fileDropRef">Browse
                File</label>
            </div>
            <button mat-raised-button class="Expbtn mr-4" (click)="deploymentSelection()">
              Back
            </button>
          </div>
      </form>
    </div>
    <div *ngIf="gridWidth > 0" class="col-sm-12 pr-5">
      <div class="global-search-panel d-flex col-sm-4 p-0">
        <div style="display: flex; margin-right: 3px;margin-left: 4px;">
          <mat-icon class="search-icon">search</mat-icon>
        </div>
        <div class="standing-line">
        </div>
        <input type="text" class="search-input" [placeholder]="'Search Case List'" [(ngModel)]="searchText" (keyup)="filtertemplates()">
        <ng-container *ngIf="searchText">
          <div class="standing-line">
          </div>
          <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
            <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
          </div>
        </ng-container>
      </div>
      <div class="parent-div mt-2 table-align">
        <div class="header d-flex p-0 mb-1 table-content" *ngIf="gridData?.length">
          <div class="header-wrap" style="width: auto !important;">
            <span class="buttonClass" *ngIf="!postUpload">
              <div class="dataTable"></div>
            </span>
            <span class="widthClass pl-1" *ngFor="let header of sheetHeader">
              <div class="dataTable valueDiv">{{header}}</div>
            </span>
          </div>
        </div>
        <div class="container-fluid header-wrap p-0">
          <div cdkDropList cdkDropListOrientation="vertical" class="tableScroll" style="width: fit-content !important;">
            <span *ngIf="gridData?.length > 0">
              <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'" class="card-data mt-1 ml-0  mb-1"
                *ngFor="let item of gridData | searchfiltersTemplate: templatefilter">
                <mat-icon *ngIf="!postUpload" class="actions buttonClass pl-2" matTooltip="Delete" (click)="deleteRow(item)">delete</mat-icon>
                <span (click)="RedirectToView(item)" class="text-muted text-ellipsis dataTable widthClass" *ngFor="let header of sheetHeader">
                  <div *ngIf="header != 'ResultMessage' && header != 'Result'" class="valueDiv" [matTooltip]="toolTipText"
                    (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                    {{ displayedColumns(item,header?.toLowerCase()) | stripHtml }}</div>
                  <div *ngIf="header == 'ResultMessage' || header == 'Result'" [ngClass]="item['isvalid']==1 || item['result']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||['isvalid']==0 || item['result']=='Failure' ? 'text-danger pl-1': null"
                    class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                    {{ displayedColumns(item,header?.toLowerCase()) | stripHtml }}</div>
                </span>
              </div>

            </span>
            <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
              <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
            </div>
            <div class="col-sm-12 nodata-header-wrap" *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
              <span class="col-sm-12 nodata-wrapper"> No Results </span>
            </div>
          </div>
        </div>
      </div>
      <div class="trackerDiv" *ngIf="activityTracker != 'CASES'" (click)="openActivityTracker()">
          <em class="fa fa-angle-up popout" *ngIf="isOver"></em>
          <button class="trackerButton"
          [class.mat-elevation-z2]="!isOver"[class.mat-elevation-z8]="isOver"  (mouseover)="isOver = true" (mouseleave)="isOver = false">
          <span>Activity Tracker</span>
          </button>
        </div>
        <lib-activity-tracker-lib *ngIf="openActivityTkr" [trackerInput]="trackerInput" [openActivityDialog]="true" [activityPath]="activityPath" (closeActivityDialog)="openActivityTkr = false" [apiUrl]="trackerApiUrl"></lib-activity-tracker-lib>
      <div>


        <div class="row pl-3 mt-4">
          <div class="col-sm-2 p-0">
            <button mat-raised-button class="Expbtn" (click)="removeGrid()">
              Back
            </button>
          </div>
          <div class="col-sm-10 button-field-wrap p-0">
            <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
              <button mat-raised-button *ngIf="postUpload" class="Expbtn mr-4" (click)="backClicked($event)">Finish</button>
              <button mat-raised-button *ngIf="!postUpload" class="Expbtn mr-4" (click)="backClicked($event)">Cancel</button>
              <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn()" class="Expbtn mr-4"
                (click)="uploadData()">Approve</button>
              <button mat-raised-button *ngIf="postUpload" class="Expbtn" (click)="ExportExcel()">Download
                Result</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
      </div>

    </div>
  </div>
</div>
