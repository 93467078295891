<div class="row p-0 m-0" id="ruleoutsDiv">
    <div class="col-12 p-0 m-0">
        <!-- <div class="modal-header admin-model-header mb-2 mt-3" *ngIf="ShowGrid && !AddEditScreen">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong>{{templateData.menuURL}} list</strong>
            </h3>
        </div> -->
        <div *ngIf="ShowGrid && !AddEditScreen" class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong class="header-View">{{templateData.menuURL}} list</strong>
            </h3>
        </div>
        <div class="row p-0 m-0" *ngIf="ShowGrid && !AddEditScreen">
            <div class="col-12 p-0 m-0" *ngIf="!noDataFound">
                <!-- <div class="button-field-wrap">
                    <span class="instr-size mb-2" *ngIf="!groupedGridShow">
                        <img aria-hidden="true" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
                        You can drag and drop any {{templateData.menuURL.toString().toLowerCase().trim()}} to change
                        it's sequence.</span>
                </div> -->

                <!-- <wj-flex-grid [ngStyle]="{ 'width.%': '100' }" id="grid" [isReadOnly]="true"
                        [itemsSource]="gridData" [alternatingRowStep]="0" [allowSorting]="false"
                        [allowDragging]="'Both'" [headersVisibility]="headerVisibility"
                        (draggingRow)="onRowDragging(grid, $event)" (draggedRow)="onRowDragged(grid, $event)"
                        (initialized)="initGrid(grid)" #grid>
                        <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="120" [isReadOnly]="true">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                                <button [disabled]="editBtn && !groupedGridShow" class="edit-delete-btn cursor" (click)="editRuleOuts(grid, $event)">
                                    <i id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></i>
                                </button>
                                <button [disabled]="deletebtn && !groupedGridShow" class="edit-delete-btn cursor" (click)="deleteRuleOuts(grid, $event)">
                                    <i id="editButton" [title]="'Delete'" class="p-0 fa fa-trash"></i>
                                </button>
                            </ng-template>
                        </wj-flex-grid-column>

                            <wj-flex-grid-column *ngFor="let col of columns" [width]="'*'" [minWidth]="40" [binding]="col.binding"
                                [header]="col.header" [fixed]="true" [allowResizing]="true" [allowDragging]="false"
                                [format]="'d'"
                                [visible]="!(col == 'AttributeContextId' || col == 'AttributeID' || col == 'RuleOutsName' || col == 'SubSite')">
                            </wj-flex-grid-column>



                        <wj-flex-grid-filter #filter *ngIf="groupedGridShow"></wj-flex-grid-filter>
                        single row filter in wijmo grid code
                        <wj-flex-grid-filter   [filterColumns]="['SequenceOrder']"></wj-flex-grid-filter>
                    </wj-flex-grid>
                    [groupBy]="userFlag ? ['sitename', 'Case_Type']:['sitename']" -->

                <app-templates-listing2 [gridHeader]="listGridheaders" [gridData]="gridData" [groupBy]="groupBy"
                    (createEmitter)="ruleOutCreation($event)" (copyEmitter)="copyOrg()"
                    (editEmitter)="editRuleOuts($event)" (gridRefreshEmitter)="dragDrop = false;refreshGrid()"
                    (dragDropEmitter)="dragDropItem($event)" (deleteEmitter)="deleteRuleOuts($event)"
                    [context]="'Rule Outs'" [dragDrop]="dragDrop" [sortAZ]="sortAZ" [hideEdit]="editBtn" [isEdit]="true"
                    [hideCreate]="createBtn" [hideDelete]="deleteBtn" [hideUpload]="uploadBtn" [hideExport]="false"
                    [hideCopy]="true"> 
                    <!-- Hiding copy - 118412 - June sprint 2024 -->
                </app-templates-listing2>

                <!-- <div *ngIf="gridwidth > 0 " style="text-align: center;" class="mt-2">
                        <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                            headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
                        </wj-collection-view-navigator>
                        <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'"
                            *ngIf="showPaginationMainGrid">
                            <wj-menu-item [value]="0">No Paging</wj-menu-item>
                            <wj-menu-item [value]="10">10</wj-menu-item>
                            <wj-menu-item [value]="15">15</wj-menu-item>
                            <wj-menu-item [value]="30">30</wj-menu-item>
                            <wj-menu-item [value]="50">50</wj-menu-item>
                        </wj-menu>
                    </div> -->
                <br />
            </div>
            <div class="col-12 p-0 m-0" *ngIf="noDataFound">
                <app-templates-listing2 [gridHeader]="listGridheaders" [gridData]="{_view : []}" [groupBy]="groupBy"
                    (createEmitter)="ruleOutCreation($event)" (copyEmitter)="copyOrg()"
                    (editEmitter)="editRuleOuts($event)" (gridRefreshEmitter)="dragDrop = false;refreshGrid()"
                    (dragDropEmitter)="dragDropItem($event)" (deleteEmitter)="deleteRuleOuts($event)"
                    [context]="'Rule Outs'" [dragDrop]="dragDrop" [sortAZ]="sortAZ" [hideEdit]="editBtn" [isEdit]="true"
                    [hideCreate]="createBtn" [hideDelete]="deleteBtn" [hideUpload]="uploadBtn" [hideExport]="false"
                    [hideCopy]="copyBtn">
                </app-templates-listing2>
            </div>
            <!-- <div class="align-center d-block mx-auto">
                <button *ngIf="!noDataFound && groupedGridShow" [disabled]="'false'" mat-raised-button class="admin-btn-success mt-2 mr-2"
                    (click)="expotGridData()">
                    Export
                </button>
                <button *ngIf="groupedGridShow" [disabled]="createBtn" mat-raised-button class="admin-btn-success mt-2 mr-2"
                    (click)="addRuleOuts()">
                    Create
                </button>

                <button *ngIf="groupedGridShow" [disabled]="uploadBtn" mat-raised-button class="admin-btn-success mt-2 mr-2"
                    (click)="uploadRuleOUts()">
                    Upload
                </button>
                <button *ngIf="groupedGridShow" [disabled]="copyBtn" mat-raised-button class="admin-btn-success mt-2"
                    (click)="copyOrg()">
                    Copy
                </button>
                <button *ngIf="!groupedGridShow && !noDataFound" [disabled]="'false'" mat-raised-button class="admin-btn-success mt-2 mr-2"
                    (click)="AddGridData()">
                    Back
                </button>
            </div> -->
        </div>

        <div *ngIf="AddEditScreen">
            <form [formGroup]="frm_RuleOuts" class="Gross-Form drop-down-field">
                <div class="row col-sm-12 p-0 m-0">
                    <div class="col-sm-12 d-flex p-0">
                        <!-- <div class="col-sm-12 modal-header admin-model-header mb-2 mt-3">
                            <h3 class="modal-title w-100 admin-model-header-txt">
                                <strong>{{headerValue}}</strong>
                            </h3>
                        </div> -->

                        <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
                            <h3 class="modal-title w-100 admin-model-header-txt">
                                <strong class="header-View">{{headerValue}}</strong>
                            </h3>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12 btn-wrap mb-2 p-0">
                        <a (click)=" resetFormData() " [ngClass]="changesMade() ? 'reset-btn' : 'disabled-reset-btn'">
                            Reset
                        </a>
                        <button mat-raised-button class="admin-btn-success ml-2 mr-2" type="button"
                            (click)="backToGrid()">
                            Return
                        </button>
                        <button mat-raised-button [disabled]="!changesMade()" class="admin-btn-success"
                            (click)="checkForDup('close')">
                            Save
                        </button>
                    </div> -->

                    <div class="col-sm-12 pull-right align-center button-wrap m-0 p-0">
                        <a class="reset-btn mr-3" (click)="changesMade() ? resetFormData() : null"
                            [ngClass]="changesMade() ? 'reset-btn' : 'disabled-reset-btn'">Reset</a>
                        <button class="mr-3 align-center buttonHover" type="button" (click)="backToGrid()">Return</button>
                        <button class="align-center primaryButtonColor buttonHover" [disabled]="!changesMade()"
                            (click)="checkForDup('close')">Save</button>
                    </div>

                    <div class="text-wraps col-sm-12 p-0 ml-0">

                        <div class="row col-sm-12 mb-1 mt-3">

                            <div class="col-sm-2 m-0 p-0" *ngIf="disableSites()">
                                <mat-form-field appearance="outline" class="w-100 col-sm-12 pr-0">
                                    <mat-label>Sequence &nbsp;</mat-label>
                                    <input matInput placeholder="Enter Sequence" id="Sequence" type="text"
                                        formControlName="frm_Sequence" maxlength="4"
                                        (keypress)="AllowOnlyNumber($event)" />
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3 m-0 p-0">
                                <mat-form-field appearance="outline" class="w-100 col-sm-12 pr-0">
                                    <mat-label>Name</mat-label>
                                    <input matInput placeholder="Enter Name" id="name" formControlName="frm_name"
                                        maxlength="1000" />
                                </mat-form-field>
                                <div
                                    *ngIf="(submitted && frm_RuleOuts && (!frm_RuleOuts.value.frm_name || frm_RuleOuts.value.frm_name == ''))">
                                    <span class="text-danger errorDisplayFont ml-4">
                                        Name is required !</span>
                                </div>
                            </div>

                            <div class="col-sm-3 m-0 p-0">
                                <mat-form-field appearance="outline" class="w-100 col-sm-12 pr-0">
                                    <mat-label>
                                        <strong>
                                            Sites</strong>
                                    </mat-label>
                                    <mat-select placeholder="Select" disableOptionCentering #matSelectOptionSite
                                        formControlName="frm_subsite" multiple>
                                        <div class="select-container">
                                            <mat-form-field class="w-100" style="min-height: 44px !important;">
                                                <input #searchsite autocomplete="off" [disabled]="disableSites()" placeholder="Search"
                                                    aria-label="Search" matInput />
                                                <button [disableRipple]="true" *ngIf="searchsite.value" matSuffix
                                                    mat-icon-button aria-label="Clear" (click)="searchsite.value = ''">
                                                    <mat-icon>close</mat-icon>
                                                </button>

                                            </mat-form-field>
                                            <mat-optgroup
                                                *ngIf="fetchDestSites(searchsite.value,attributesName).length == 0">
                                                <div>No result found!</div>
                                            </mat-optgroup>
                                            <div class="select-all">
                                                <mat-checkbox [color]="task.color" [disabled]="disableSites()"
                                                    [checked]="checkAllSites()" class="chk-box-align"
                                                    [indeterminate]="someSitesChecked()"
                                                    (change)="toggleAllSelection($event.checked)">
                                                    Select All</mat-checkbox>
                                            </div>
                                            <!-- <mat-option (onSelectionChange)="selectedCopyDataUser($event) class="mat-opt-align" [disabled]='ifChckdAll' (onSelectionChange)="selectedCopyDataUser($event, site)"
                                                                value="{{ site.siteid }}" title="{{ site.sitename }} ({{site.siteid}})">
                                                                <span>{{ site.sitename }} ({{site.siteid}})</span> -->
                                            <mat-option [color]="task.color" [disabled]="disableSites()"
                                                class="mat-opt-align"
                                                *ngFor="let option of fetchDestSites(searchsite.value,attributesName)"
                                                [value]="option.siteid" title="{{option.sitename}}({{option.siteid}})">
                                                {{option.sitename}}({{option.siteid}})
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" class="w-100 col-sm-12">
                                            <mat-label><strong><span class="error-msg">*</span>Sites</strong> </mat-label>
                                            <i class="fa fa-chevron-down chevron-align"></i>
                                            <input type="text"  #searchsite matInput formControlName="frmSites" #trigger="matAutocompleteTrigger"
                                                [matAutocomplete]="userauto" placeholder="Search here ..." style="width: 95%;" formControlName="frm_subsite">
                                            <mat-autocomplete #userauto="matAutocomplete">
                                                <div *ngFor="let site of fetchDestSites(searchsite.value,attributesName)">
                                                    <mat-option class="mat-opt-align" [disabled]='ifChckdAll' (onSelectionChange)="selectedCopyDataUser($event, site)"
                                                        value="{{ site.sitename }}" title="{{ site.sitename }} ({{site.siteid}})">
                                                        <span>{{ site.sitename }} ({{site.siteid}})</span>
                                                    </mat-option>
                                                </div>
                                            </mat-autocomplete>

                                            <button *ngIf="searchsite.value" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                                                (click)="clearSiteDrop(searchsite.value)">
                                                <mat-icon>close</mat-icon>
                                            </button>

                                        </mat-form-field> -->
                                <!-- <mat-form-field appearance="outline" class="w-100 col-sm-12">
                                    <mat-label> Site&nbsp;<span class="error-msg">*</span></mat-label>
                                    <mat-select disableOptionCentering class="form-font"  formControlName="frm_subsite"
                                        style="border: none !important;">
                                        <mat-option *ngFor="let sites of attributesName" value="{{sites.siteid}}">
                                            {{sites.sitename}} ({{sites.siteid}})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                                <div
                                    *ngIf="(submitted && frm_RuleOuts && frm_RuleOuts.value.frm_subsite && !frm_RuleOuts.value.frm_subsite.length)">
                                    <span class="text-danger errorDisplayFont ml-4">
                                        Site is required !
                                    </span>
                                </div>
                            </div>

                            <div class="col-sm-3 mt-2">
                                <span class="col-sm-12 d-flex">

                                    <!-- <toggle-button (changed)="checked=$event">
                                            </toggle-button> -->

                                    <!-- <input type="checkbox" id="toggle-button-checkbox" (change)="changed.emit($event.target.checked)">
                                    <label class="toggle-button-switch" for="toggle-button-checkbox"></label>
                                    <div class="toggle-button-text">
                                        <div class="toggle-button-text-on">ON</div>
                                        <div class="toggle-button-text-off">OFF</div>
                                    </div>-->
                                    <!-- <span>Active: &nbsp;</span> -->
                                    <div class="d-flex">
                                        <span>Status:</span>
                                        <app-toggle-switch [isChecked]="frm_isActive" (clicked)="frm_isActive = $event" type="secondary">
                                        </app-toggle-switch>
                                    </div>
                                    <!-- <app-switch size="small" name="skip_notification"
                                        (change)="frm_isActive = !frm_isActive" [value]="frm_isActive"></app-switch> -->
                                    <!-- <mat-checkbox [color]="task.color" class="cursor mt-2" [(checked)]="frm_isActive"
                                        (change)="frm_isActiveFunc()">
                                    </mat-checkbox> -->
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row mt-4">
                    <div class="col-sm-3">
                        <button mat-raised-button class="admin-btn-success" (click)="backToGrid()">
                            Back
                        </button>
                    </div>
                    <div class="col-sm-9 button-wrap">
                        <button mat-raised-button class="admin-btn-success mr-4" (click)="checkForDup('close')">
                            Save & Close
                        </button>
                        <div *ngIf="showBtn">
                            <button mat-raised-button class="admin-btn-success" (click)="checkForDup('add')">
                                Save & Create
                            </button>
                        </div>

                    </div>
                </div> -->
            </form>
        </div>


        <!-- copy -->
        <div *ngIf="copyDataClicked" class="col-sm-12 copy-level">
            <!-- <div class="modal-header admin-model-header mb-2 mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>Copy Group Level Rule Outs</strong>
                </h3>
            </div> -->

            <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong class="header-View">Copy Group Level Rule Outs</strong>
                </h3>
            </div>

            <form [formGroup]="copyRuleOutsForm" autocomplete="off" (keydown.enter)="$event.preventDefault()" class="copy-collection">
                <div class="sub-header m-1 col-sm-2 p-0"><b>Source <span class="sub-header grey-color mt-3">(Copy
                            from)</span>
                        <hr class="separation-line  m-0 p-0">
                    </b>
                </div>
                <div class="copy-wrap mb-2 mt-3">
                    <div class="col-sm-12 p-4">
                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label><strong>Deployment</strong></mat-label>
                            <mat-select disableOptionCentering type="text" matInput #searchbar
                                formControlName="frmDepKey">
                                <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                                    (onSelectionChange)="onChangeDeployment($event,i)">
                                    <span>{{ i }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                            <mat-label><strong>Group </strong></mat-label>
                            <i class="fa fa-chevron-down chevron-align mt-2" (click)="fetchOrgSeries(copyRuleOutsForm.value.frmOrganization)"></i>
                            <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                                [disabled]="disableOrgSearch" #searchbar
                                (click)="fetchOrgSeries(copyRuleOutsForm.value.frmOrganization)"
                                (keyup)="fetchOrgSeries(copyRuleOutsForm.value.frmOrganization)"
                                [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 93%;">
                            <mat-autocomplete #orgauto="matAutocomplete">
                                <div *ngFor="let show of searchResult">
                                    <mat-option class="mat-opt-align"
                                        (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                        value="{{ show.organizationname }}"
                                        title="{{ show.organizationname }} ({{show.organizationid}})">
                                        <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                                    </mat-option>
                                </div>
                            </mat-autocomplete>

                            <button *ngIf="copyRuleOutsForm.value.frmOrganization" matSuffix mat-icon-button
                                aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>

                        <mat-form-field class="col-sm-3 pr-0 example-additional-selection" appearance="outline">
                            <mat-label><strong><span class="error-msg">*</span>Site</strong> </mat-label>
                            <i class="fa fa-chevron-down chevron-align mt-2" (click)="fetchSiteSeries(searchsitebar.value)"></i>
                            <input type="text" matInput formControlName="frmSites" #trigger="matAutocompleteTrigger"
                                [(ngModel)]="searchSiteInput" #searchsitebar
                                (keyup)="fetchSiteSeries(searchsitebar.value)"
                                (keydown)="fetchSiteSeries(searchsitebar.value)"
                                (focus)="fetchSiteSeries(searchsitebar.value);trigger.openPanel()"
                                [matAutocomplete]="userauto" placeholder="Search here ..." style="width: 93%;">
                            <mat-autocomplete #userauto="matAutocomplete">
                                <div *ngFor="let site of searchsitelist">
                                    <mat-option class="mat-opt-align" [disabled]='ifChckdAll'
                                        (onSelectionChange)="selectedCopyDataUser($event, site)"
                                        value="{{ site.sitename }}" title="{{ site.sitename }} ({{site.siteid}})">
                                        <span>{{ site.sitename }} ({{site.siteid}})</span>
                                    </mat-option>
                                </div>
                            </mat-autocomplete>

                            <button *ngIf="searchsitebar.value" matSuffix mat-icon-button aria-label="Clear"
                                title="Reset Search" (click)="clearSites()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>

                        <span class="col-sm-1">
                            <mat-checkbox [color]="task.color" style="cursor: pointer !important;" class="mt-2"
                                [disabled]="ifSiteSel" [(checked)]="checkAllSite" (change)='checkAllSiteValue($event)'>
                                <span>All Sites</span>
                            </mat-checkbox>
                        </span>
                    </div>
                </div>


                <div class="sub-header m-1 col-sm-2 p-0"><b>Destination <span class="sub-header grey-color mt-3">(Copy
                            to)</span>
                        <hr class="separation-line m-0 p-0">
                    </b>
                </div>

                <div class="copy-wrap mt-3">
                    <div class="p-4">
                        <mat-form-field class="col-sm-3 pr-0 example-additional-selection" appearance="outline">
                            <mat-label><strong>Site</strong> </mat-label>
                            <i class="fa fa-chevron-down chevron-align mt-2" (click)="fetchDestSites(destSiteName,destSites)"></i>
                            <input type="text" matInput formControlName="frmDestSite"
                                #desttrigger="matAutocompleteTrigger" #searchDestsite [(ngModel)]="destSiteName"
                                (input)="destSiteSelected = null" [matAutocomplete]="siteauto"
                                placeholder="Search here ..." style="width: 93%;">
                            <mat-autocomplete #siteauto="matAutocomplete">
                                <div *ngFor="let site of fetchDestSites(destSiteName,destSites)">
                                    <mat-option class="mat-opt-align" [disabled]='ifChckdAll'
                                        (onSelectionChange)="selectedDestCopyData($event, site)"
                                        value="{{ site.sitename }}" title="{{ site.sitename }} ({{site.siteid}})">
                                        <span>{{ site.sitename }} ({{site.siteid}})</span>
                                    </mat-option>
                                </div>
                            </mat-autocomplete>

                            <button *ngIf="destSiteName" matSuffix mat-icon-button aria-label="Clear"
                                title="Reset Search"
                                (click)="clearSites(1);_clearPreviousSelectedOption(siteauto,null, false);">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>


                <div class="row pull-right mt-2 mr-2">
                    <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
                        <button mat-raised-button class="admin-btn-success mr-4 buttonHover" (click)="refreshGrid()">
                            Return
                        </button>
                    </div>
                    <div class="dml-btn">
                        <button mat-raised-button class="admin-btn-success" [disabled]="disableGetDataBtn()"
                            (click)="getDataToCopy()">Get
                            Data</button>
                    </div>
                </div>
                <div *ngIf="gridExcelWidth > 0">

                    <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [isReadOnly]="true"
                        (formatItem)="formatItem(flexgrid, $event)" [itemsSource]="gridExcelData"
                        style="margin-top: 17px;" [frozenColumns]="2">

                        <div *ngFor="let columnname of sheetHeader">
                            <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                                [width]="'*'" [allowResizing]="true" [allowDragging]="false" [allowSorting]="false"
                                [format]="'d'" [visible]="!(columnname == 'CaseType')">
                            </wj-flex-grid-column>

                        </div>
                        <!-- Status -->
                        <wj-flex-grid-column [header]="'Result'" [binding]="status" *ngIf="postUpload"
                            [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                <span
                                    [ngStyle]="{color: cell.item.status=='Success' ? 'green' : 'red'}">{{cell.item.status}}
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>

                        <!-- Notes -->
                        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                    <br />
                    <div *ngIf="gridExcelWidth > 0 " style="text-align: center;" class="mt-2">
                        <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                            headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridExcelData">
                        </wj-collection-view-navigator>
                        <wj-menu class="ml-2" [(value)]="gridExcelData.pageSize" [header]="'Page Size'"
                            *ngIf="showPaginationMainGrid">
                            <wj-menu-item [value]="0">No Paging</wj-menu-item>
                            <wj-menu-item [value]="10">10</wj-menu-item>
                            <wj-menu-item [value]="15">15</wj-menu-item>
                            <wj-menu-item [value]="30">30</wj-menu-item>
                            <wj-menu-item [value]="50">50</wj-menu-item>
                        </wj-menu>
                    </div>
                    <br />
                    <br />
                    <div *ngIf="!postUpload" class="row pl-3 mt-2 mb-2 ml-5 align-center">
                        <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0"
                            ng-init='checkStatus=false' [checked]='checkStatus' ng-model='checkStatus'
                            (change)='checkValue($event)' class="border-style">
                        <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
                    </div>
                    <div class="row align-center mt-2">
                        <div *ngIf="!postUpload" class="dml-btn">
                            <button mat-raised-button class="admin-btn-success" [disabled]="selectedItems.length==0"
                                (click)="uploadCopyData(flexgrid)">Approve</button>
                        </div>

                    </div>

                    <div class="row pl-3 mt-4">
                        <div class="col-sm-12 button-wrap p-0 m-0">
                            <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                                    (click)="refreshGrid()">Finish</button>
                                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                                    (click)="ExportExcelCopyandUpload(flexgrid)">Download
                                    Result</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>

            <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridExcelWidth == 0">
                <div class="col-sm-10 nodata-wrap">
                    <div class="nodata-design">No Data Found</div>
                </div>
            </div>
        </div>

        <!--Bulk Upload-->
        <div *ngIf="uploadClicked" class="my-bulk-form">

            <!-- <div class="modal-header admin-model-header mb-2 mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>Upload Rule Outs</strong>
                </h3>
            </div>  -->
            <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong class="header-View">Upload Rule Outs</strong>
                </h3>
            </div>
            <div class="button-field-wrap m-1 p-0">
                <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
                    <mat-label class="d-flex">
                        Templates
                    </mat-label>
                    <mat-select disableOptionCentering class="ml-2">
                        <!-- removing the template for Minimal filed -->
                        <!-- <mat-option class="temp-font-size" (click)="downloadMandatoryFields()">
                            <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                        </mat-option> -->
                        <mat-option class="temp-font-size" (click)="downloadExcelSheet()">
                            <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                        </mat-option>
                        <mat-option class="temp-font-size" (click)="downloadBulkUpdate()">
                            <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="button-field-wrap">
                <span class="instr-size mb-2">
                    <img aria-hidden="true" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
                    You can download the sample template file to upload the Rule Out.</span>
            </div>

            <div class="col-sm-12 p-0" *ngIf="gridExcelWidth == 0">
                <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
                    <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                        (change)="onFileDropped($event)" hidden />
                    <span class="align-center img-wrap">
                        <img src="/assets/images/upload_excel.svg" width="60px" height="auto">
                    </span>
                    <span>Drag & Drop excel files here</span>
                    <span class="align-center">Or</span>
                    <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>

            <div *ngIf="gridExcelWidth > 0">

                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridExcelData" (formatItem)="formatItem(grid, $event)"
                    (initialized)="initLocationGrid(grid)" #grid [headersVisibility]="'Column'" [frozenColumns]="2">
                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'Result')" [allowResizing]="true" [width]="320"
                            [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Result'" [binding]="status" *ngIf="postUpload"
                        [allowResizing]="true" [width]="240" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: highlightNotes? (cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red'):''}">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Action -->
                    <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="130"
                        [isReadOnly]="true">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><i id="deleteButton"
                                    [title]="'Delete'" class="cursor p-0 fa fa-trash"></i></button>
                        </ng-template>
                    </wj-flex-grid-column>

                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <br />
                <div *ngIf="gridExcelWidth > 0 " style="text-align: center;" class="mt-2">
                    <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridExcelData">
                    </wj-collection-view-navigator>
                    <wj-menu class="ml-2" [(value)]="gridExcelData.pageSize" [header]="'Page Size'"
                        *ngIf="showPaginationMainGrid">
                        <wj-menu-item [value]="0">No Paging</wj-menu-item>
                        <wj-menu-item [value]="10">10</wj-menu-item>
                        <wj-menu-item [value]="15">15</wj-menu-item>
                        <wj-menu-item [value]="30">30</wj-menu-item>
                        <wj-menu-item [value]="50">50</wj-menu-item>
                    </wj-menu>
                </div>
                <br />
                <br />

                <div class="row pl-3 mt-4">
                    <div class="col-sm-2 p-0">
                        <button mat-raised-button class="admin-btn-success"
                            (click)="gridExcelWidth == 0 ? refreshGrid() : removeGrid(1)">
                            Back
                        </button>
                    </div>
                    <div class="col-sm-10 button-wrap p-0 m-0">
                        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0 col-sm-10 button-wrap">
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                                (click)="refreshGrid()">Finish</button>
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                                (click)="refreshGrid()">Cancel</button>
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                                (click)="uploadRuleOutData()">Approve</button>
                            <button mat-raised-button *ngIf="postUpload" class="col-sm-3 admin-btn-success"
                                (click)="ExportExcelCopyandUpload(grid)">Download
                                Result</button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
                <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
            </div>
        </div>
    </div>
</div>