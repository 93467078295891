<div class="title mb-2">
    <div class="modal-header admin-model-header mt-2 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong class="header-View" *ngIf="!createOrEditClicked;else createPage"> {{ headerName }} </strong>
            <ng-template #createPage>
                <strong class="header-View">{{context}} {{ headerName }} </strong>
            </ng-template>
        </h3>
    </div>
    <div class="ml-auto p-3 pr-4 cursor">
        <img *ngIf="!createOrEditClicked;else createPageButtons" class="ml-auto cursor-pointer mr-2" mat-dialog-close
            src="/assets/icons/Close_black.svg" alt="cross" />
        <ng-template #createPageButtons>
            <div class="float-right d-flex">
                <a (click)="resetDynamicForm()" class="reset-btn" *ngIf="configForm.dirty || resetEnabled">Reset</a>
                <app-button buttonclass="secondary" class="edit-page-button mr-2" style="width:70px"
                    (click)="returnBtn(configForm.dirty || resetEnabled)">Return</app-button>
                <app-button buttonclass="primary" class="edit-page-button" style="width:70px"
                    (click)="saveConfiguration()">Save</app-button>
            </div>
        </ng-template>
    </div>
</div>
<!-- [ngStyle]="{'display': listPage ? 'block' : 'none'}" -->
<div *ngIf="listPage && gridLoaded">
    <app-templates-listing [gridHeader]="ListedGridHeader" [gridData]="gridData" [context]="headerName"
        [showHeaderModule]="false" [hideCreate]="false" [hideEdit]="false" [hideUpload]="true" [hideExport]="exportBtn"
        [hideCopy]="true" (CreateTemplateClicked)="createConfigurations()"
        (editTemplateClicked)="editConfiguration($event)" (editTemplateToggleClicked)="editToggleConfiguration($event)"
        [templateData]="templateData" [saveOrEditCompleted]="saveOrEditCompleted" (emitFilters)="emitFilters($event)"
        [isShowToggle]="true" [showFlagsIcon]="false">
    </app-templates-listing>
    <!-- [note]="getNote()"  -->
</div>


<div *ngIf="createOrEditClicked" style="height: calc(100ch - 43vh);overflow-y: scroll;">
    <form [formGroup]="configForm" autocomplete="off">
        <div class="row col-sm-12 m-0 p-0 pl-1">
            <div class="col-sm-12 m-0 p-0 pl-2 pr-2 mb-2 d-flex justify-content-between">
                <span class="section-header">
                    <strong>Flag Details</strong>
                </span>
            </div>

            <div autocomplete="off" class="row col-sm-12 m-0 p-0 pl-2 pr-2 mb-2">

                <div class="col-sm-3 m-0 p-0 viewInputContainer">
                    <mat-form-field class="col-sm-12 p-0">
                        <mat-label class="font-color"> <strong>Configuration Type</strong> </mat-label>
                        <input name="Configuration Type" value="Flag" formControlName="configurationType" trim
                            matInput />
                    </mat-form-field>
                </div>

                <div class="col-sm-3 viewInputContainer">
                    <mat-form-field [ngClass]="disableFieldsOnEdit ? 'disable-form':''" class="col-sm-12 p-0">
                        <mat-label class="font-color"> <strong>Flag Name</strong> </mat-label>
                        <input name="Flag Name" noSpaces formControlName="configurationFlagName" matInput />
                    </mat-form-field>
                </div>

                <div class="col-sm-3 m-0 p-0 viewInputContainer">
                    <mat-form-field class="col-sm-12 p-0">
                        <mat-label class="font-color"> <strong>Display Name</strong> </mat-label>
                        <input [required]="true" [maxlength]="100" [minlength]="1" name="Display Name"
                            formControlName="configurationDisplayName" trim matInput />
                    </mat-form-field>
                </div>

                <div class="col-sm-3 m-0 viewInputContainer">
                    <mat-form-field class="col-sm-12 p-0">
                        <mat-label class="font-color"> <strong>Category</strong> </mat-label>
                        <input name="Category" [required]="true" aria-label="Category" [matAutocomplete]="auto"
                            formControlName="configurationCategory" matInput />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option class="mat-opt-align p-0" (closed)="arrowIconSubject3.next('arrow_drop_down3')"
                                (opened)="arrowIconSubject3.next('arrow_drop_up3')"
                                (optionSelected)="arrowIconSubject3.next('arrow_drop_down3')"
                                *ngFor="let dropdownOptions of categoryDDValues" [value]="dropdownOptions">
                                <span class="p-2"> {{ dropdownOptions }} </span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <!-- row 2 -->
            <div class="col-sm-12 row m-0 p-0 pl-2 pr-2 mb-2">
                <div class="col-sm-3 m-0 p-0 viewSelectContainer">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="disableFieldsOnEdit ? 'disable-form':''">
                        <mat-label class="font-color"> <strong>Table</strong> </mat-label>
                        <mat-select name="Table" [required]="true" [panelClass]="'customCEOverlayPanelClass'"
                            formControlName="configurationTable">
                            <mat-option class="p-2" *ngFor="let dropdownOptions of tableDDValues"
                                [value]="dropdownOptions">
                                <div> {{
                                    dropdownOptions
                                    }}
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <app-multi-select-checkbox [listData]="rolesDDValues" class="col-sm-3 viewSelectContainer"
                    (onFocusOut)="validateMultiSelectDropdown('frmRole')" [selectionModel]="RolesSelectionModel"
                    [isFormValid]="roleValuesValid" [labelValue]="'Role'" [required]="true" [isAdmin]="true"></app-multi-select-checkbox>

                <app-custom-dropdown-checkbox [isAllOptionShown]="true" [listData]="menuDDValues" [isDisabled]="false"
                    (onFocusOut)="validateMultiSelectDropdown('frmMenu')" class="col-sm-3 p-0 viewSelectContainer"
                    [isValid]="menuValuesValid" [labelValue]="'Menu'" [selectionModel]="MenuSelectionModel"
                    dropDownKeyProp="menuName" dropDownValueProp="menuName" placeholder=""
                    [required]="true" [isAdmin]="true"></app-custom-dropdown-checkbox>

                <app-multi-select-checkbox [listData]="DeploymentList" class="col-sm-3 viewSelectContainer"
                    (onFocusOut)="validateMultiSelectDropdown('frmDeployment')"
                    [selectionModel]="DeploymentListSelectionModel" [isFormValid]="true"
                    [labelValue]="'Excluded Deployments'" [required]="false" [isAdmin]="true"></app-multi-select-checkbox>
            </div>

            <!-- row 3 -->
            <div class="col-sm-12 row m-0 p-0 pl-2 pr-2 mb-2">
                <div class="col-sm-3 m-0 p-0 viewSelectContainer">
                    <mat-form-field class="col-sm-12 p-0">
                        <mat-label class="font-color"> <strong>Scope</strong> </mat-label>
                        <mat-select name="Scope" [required]="true" [panelClass]="'customCEOverlayPanelClass'"
                            formControlName="configurationScope">
                            <mat-option class="p-2" *ngFor="let dropdownOptions of scopeDDValues"
                                [value]="dropdownOptions">
                                <div> {{ dropdownOptions }} </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- row 4 -->
            <div class="col-sm-12 row m-0 p-0 pl-2 pr-2 mb-2">
                <div class="col-sm-6 m-0 p-0 viewTextAreaContainer">
                    <mat-form-field class="col-sm-12 p-0 ceTextArea">
                        <mat-label class="font-color"> <strong>Description</strong> </mat-label>
                        <textarea #textareaElement type="textarea" trim matInput class="CETextarea" [maxlength]="200"
                            [required]="true" style="min-height: 45px !important;" [attr.title]="''" name="Description"
                            rows="5" formControlName="configurationDescription">
                                                </textarea>
                    </mat-form-field>
                </div>

                <div class="col-sm-6 viewTextAreaContainer">
                    <mat-form-field class="col-sm-12 p-0 ceTextArea">
                        <mat-label class="font-color"> <strong>Instructions</strong> </mat-label>
                        <textarea #textareaElement type="textarea" trim matInput class="CETextarea" [maxlength]="200"
                            [required]="false" style="min-height: 45px !important;" [attr.title]="''"
                            name="Instructions" rows="5" formControlName="configurationInstruction">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>

        </div>
        <div class="row col-sm-12 m-0 p-0 pl-1">
            <div class="col-sm-12 m-0 p-0 pl-2 pr-2 d-flex justify-content-between">
                <span class="section-header" style="height: 21px">
                    <strong>Field Details</strong>
                </span>
                <app-button buttonclass="secondary" class="edit-page-button mr-2" style="width: 90px;"
                    (click)="addField()" [disabled]="configForm.get('configField.AttributeFields').length >= 2">
                    + Add Field
                </app-button>
            </div>

            <mat-card class="col-sm-12 m-0 p-0 flag-details-card mat-elevation-z0 m-2" style="max-width: 98%;"
                *ngFor="let field of configForm.get('configField.AttributeFields').controls; let index = index">

                <div class="pl-2 pt-2">
                    <strong>Field {{ index + 1 }}</strong>
                </div>

                <div [formGroup]="field">
                    <div class="col-sm-12 row m-0 p-0 m-2">
                        <!-- Field Name -->
                        <div class="col-sm-3 m-0 p-0 viewInputContainer">
                            <mat-form-field class="col-sm-12 p-0">
                                <mat-label class="font-color"> <strong>Field Name</strong> </mat-label>
                                <input formControlName="fieldname" trim matInput />
                            </mat-form-field>
                        </div>

                        <!-- Field Watermark -->
                        <div class="col-sm-3 viewInputContainer">
                            <mat-form-field class="col-sm-12 p-0">
                                <mat-label class="font-color"> <strong>Field Watermark</strong> </mat-label>
                                <input formControlName="fieldWaterMark" trim matInput />
                            </mat-form-field>
                        </div>

                        <!-- Select Field Type -->
                        <div class="col-sm-3 m-0 p-0 viewSelectContainer">
                            <mat-form-field class="col-sm-12 p-0">
                                <mat-label class="font-color"> <strong>Select Field Type</strong> </mat-label>
                                <mat-select formControlName="datatype"
                                    (selectionChange)="updateDropdownValues(index, $event.value)">
                                    <mat-option class="p-2" *ngFor="let option of fieldTypeDDValues" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Key-Value Pairs -->
                    <div *ngIf="['toggle', 'strict dropdown', 'dropdown']?.includes(field?.get('datatype')?.value?.toString()?.toLowerCase())"
                        class="col-sm-12 m-0 p-0 m-2">

                        <span *ngFor="let control of field.get('datasource').controls; let valueIndex = index"
                            class="d-flex align-items-center mb-2">

                            <!-- Key -->
                            <div class="col-sm-3 m-0 p-0 mr-2 viewInputContainer">
                                <mat-form-field class="col-sm-12 p-0">
                                    <mat-label class="font-color"> <strong>Key {{ valueIndex + 1 }}</strong>
                                    </mat-label>
                                    <input [formControl]="control.get('key')" trim matInput />
                                </mat-form-field>
                            </div>

                            <!-- Value -->
                            <div class="col-sm-3 m-0 p-0 viewInputContainer">
                                <mat-form-field class="col-sm-12 p-0">
                                    <mat-label class="font-color"> <strong>Value {{ valueIndex + 1 }}</strong>
                                    </mat-label>
                                    <input aria-label="Value" [matAutocomplete]="auto"
                                        [formControl]="control.get('value')" matInput />

                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option class="mat-opt-align p-2"
                                            (closed)="arrowIconSubject2.next('arrow_drop_down2')"
                                            (opened)="arrowIconSubject2.next('arrow_drop_up2')"
                                            (optionSelected)="arrowIconSubject2.next('arrow_drop_down2')"
                                            *ngFor="let dropdownOptions of addFieldDDValues" [value]="dropdownOptions">
                                            <span> {{ dropdownOptions }} </span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <!-- Set Final State -->
                            <div class="col-sm-3">
                                <span class="mr-2"> Set Final State: </span>
                                <label class="ct-tag-toggle">
                                    <input type="checkbox" [formControl]="field.get('finalStates').at(valueIndex)"
                                        (change)="updateFinalState(index, valueIndex)">
                                    <span class="slider"></span>
                                </label>
                            </div>

                            <!-- Add/Remove Icons -->
                            <mat-icon *ngIf="valueIndex === field.get('datasource').controls.length - 1"
                                (click)="addValue(index)" class="material-icons-outlined">
                                add_circle
                            </mat-icon>

                            <mat-icon *ngIf="valueIndex !== field.get('datasource').controls.length - 1"
                                (click)="removeValue(index, valueIndex)" class="material-icons-outlined">
                                do_not_disturb_on
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </mat-card>
        </div>
    </form>
</div>