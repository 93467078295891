import { DOCUMENT, LocationStrategy } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription } from 'rxjs';
import { EntitySearchComponent } from 'src/app/base/search/entitysearch.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { UserSession } from 'src/app/core/services/user.session';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CardQueries } from '../../model/card-query';
import { ResultTabArray } from '../../model/Tab';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageReportRecipientComponent } from '../../client/Templates/manage-report-recipient/manage-report-recipient.component'
import { UserRoleMgmtComponent } from 'src/app/client/Templates/user-role-mgmt/user-role-mgmt.component';
import { ExportCaseDetailsComponent } from 'src/app/client/Templates/export-case-details/export-case-details.component';
import { DefaultRoleSettingsComponent } from 'src/app/client/Templates/default-role-settings/default-role-settings.component';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { HttpClient } from '@angular/common/http';
import { ManageConfigurationsComponent } from 'src/app/client/Templates/manage-configurations/manage-configurations.component';
import { VitalAdminTabService } from '../../client/tab.service';
import { BulkUploadOfflineProcessComponent } from 'src/app/client/Templates/bulk-upload-offline-process/bulk-upload-offline-process.component';

declare var $: any;
declare var jQuery: any;
declare var window: any;
export class Validationclass {
  Loginname: string;
  Password: string;
}
@Component({
  selector: 'vt-home-layout',
  templateUrl: 'home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})

export class HomeLayoutComponent {



  @Input()
  templateDataInp: any;
  defaultdeployment: any;

  @ViewChild('editor1') editor1: ElementRef;
  @ViewChild('editor2') editor2: ElementRef;
  @ViewChild('diffeditor') diffeditor: ElementRef;

  public val: Validationclass;
  public EntityList: any = [];
  public entities: any;
  public Searchentities: any;
  public isFavEntity: any;
  public isFavDeployment: any;
  public templateChangedTime = true;
  public DrpDwnEntityValue: any;
  public queryvalue: any = null;
  public submitType: boolean=false;
  public searchQueryValue: any;
  public searchDrpDwnEntityValue: any;
  userActivity;
  public isButtonEnable;
  userInactive: Subject<any> = new Subject();
  isLocked = false;
  public ifCompared = true;
  public text1 = "";
  public text2 = "";
  public isCompared = false;
  public userRole;
  public associationTypeList: any[];
  //Create model
  public UserIpModelHeader = "";
  public menuType = "";
  public SelectedMenuModel;
  public GenericModalClass = "modal";
  public templateData: any = [];
  public usersubscript: Subscription;
  //SearchForm : FormGroup;
  //@Output() private onFormGroupChange = new EventEmitter<any>();
  @ViewChild('SearchInput', { static: false }) SearchInput: ElementRef;

  username: string;
  srcheight: number;
  diffHeight: number;
  rolename: any;
  menulist: any;
  quicklist: any;
  comaprelist: any;
  showDrop: boolean;
  searchType = "";
  searchValue = "";
  public HomeComponent = "HomeComponent";
  public showRevision = false;
  AddtionalDetailsArray: any[] = [];
  EachEntityTabArray: any[] = [];
  showIpModel = false;

  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";

  @Input()
  languages = [
    "bat",
    "c",
    "coffeescript",
    "cpp",
    "csharp",
    "csp",
    "css",
    "dockerfile",
    "fsharp",
    "go",
    "handlebars",
    "html",
    "ini",
    "java",
    "javascript",
    "json",
    "less",
    "lua",
    "markdown",
    "msdax",
    "mysql",
    "objective-c",
    "pgsql",
    "php",
    "plaintext",
    "postiats",
    "powershell",
    "pug",
    "python",
    "r",
    "razor",
    "redis",
    "redshift",
    "ruby",
    "rust",
    "sb",
    "scss",
    "sol",
    "sql",
    "st",
    "swift",
    "typescript",
    "vb",
    "xml",
    "yaml"
  ];

  @Input()
  themes = [
    {
      value: "vs",
      name: "Light"
    },
    {
      value: "vs-dark",
      name: "Dark"
    },
    {
      value: "hc-black",
      name: "High Contrast Dark"
    }
  ];

  // input
  inputOptions = { theme: "vs", language: 'plaintext' };
  // compare, output
  diffOptions = {
    theme: "vs",
    language: "plaintext",
    readOnly: true,
    renderSideBySide: true
  };
  originalModel: DiffEditorModel = {
    code: '',
    language: 'plaintext'
  };

  modifiedModel: DiffEditorModel = {
    code: '',
    language: 'plaintext'
  };

  DeploymentList: string[] = [];
  defaultdeploymentkey: any;
  isFavdepl: any;
  defaultdepl: any;
  ThreepartSearchEntitlyList: any = [];
  DrpDwnEntityValueFOrSearch: any;
  createLiveUser: boolean = false;
  typesso: boolean = true;
  roleList: any = [];
  cronForm: any;
  userAuthFlag: boolean = false;
  quckListdisabled: boolean = false;
  isTemplatePopupVisible: boolean = false;
  environmentData: any;
  PermissionJson: any;
  url: string;
  endpoint: string;

  constructor(@Inject(DOCUMENT) private _document: Document, private ngxService: NgxUiLoaderService, public router: Router,
    public usersession: UserSession, public vitalHttpServices: VitalHttpServices, private commonService: CommonService,
    public datashare: DataShareService, public _snackBar: MatSnackBar, public cookieService: CookieService, private entitySearch: EntitySearchComponent,
    public aroute: ActivatedRoute, public el: ElementRef, public domSanitizer: DomSanitizer, private _fb: FormBuilder, private cd: ChangeDetectorRef,
    public dialog: MatDialog, private locationStrategy: LocationStrategy,
    public entityservice: EntitySearchComponent, public modalService: NgbModal, private http:HttpClient, public tabService: VitalAdminTabService) {

    this.defaultdeployment = this.commonService.selectedDefaultDeployment();
    // this.environmentData=this.vitalHttpServices.environmentData;
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          if (event.url === '/home') {
            this.router.navigate(['/login'])
          }
          /* Do something here */
        }
      }
    });
    history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', function (event) {
      history.pushState(null, null, window.location.href);
    });
    window.addEventListener('beforeunload', this.closeChildWindow);
  }

  async ngOnInit() {
  //   this.http.get<any>('../../assets/config/appsettings.json').subscribe(data => {
  //     this.vitalHttpServices.setjsondata(data);
  //     if(!this.environmentData)
  //     {
  //       this.loginchanges();
  //     }
  //     else{
  //       this.environmentData=this.vitalHttpServices.environmentData;
  //       this.loginchanges();
  //     }
  //    });
  // }


  // loginchanges(){
    sessionStorage.setItem("page", btoa("login"))
    this.ngxService.start('OnInit');
    try {
      let homePage_Role = localStorage.getItem('Homepage_role');
      let allowAllUsersRole = localStorage.getItem('allowAllUsersRole');
      if ((homePage_Role && homePage_Role != "") && (allowAllUsersRole && allowAllUsersRole != ""))// && userid != "-1"
      {
        sessionStorage.setItem('deploymentKey', "configdb");
        this.getRolesOnFusionAuth("-1");

        // this.getHomeValues();
      }
      else {
        let userid = "-1";
        sessionStorage.setItem('deploymentKey', "configdb");
        this.getRolesOnFusionAuth(userid);
      }
    }
    catch (e) {
      console.error(e);
      this._snackBar.open('An error occurred while loading. Please reload the page again.', 'Failed');
    }
    finally {
      this.ngxService.stop('OnInit');
    }
  }


  async getHomeValues() {
    try {
      this.ngxService.start('getHomeValues');
      if (this.vitalHttpServices.SsoType != 'SoloLaunch') {
        await this.vitalHttpServices.getURLs();
        const exist = this.EachEntityTabArray.length;
        let Value = this.vitalHttpServices.sssodata;
        sessionStorage.setItem('deploymentKey', Value.DisplayField.deploymentkey);
        sessionStorage.setItem('context', Value.DisplayField.context);
        sessionStorage.setItem('selectedContext', Value.DisplayField.context);
        await this.GetHomeDetails();
        this.commonService.getAccountType(Value);
        this.sendMssageToStopParentLoader("CloseLoader");
        // this.CallVitalAdminApp({id:Value.DisplayField.Locationid,"context": "context"});
        //if (exist == undefined) {
        if (exist == 0) {
          let tabArr = new ResultTabArray();
          tabArr.GUID = Value.GUID;
          tabArr.Removable = true;
          tabArr.TabName = Value.DisplayText;
          tabArr.Count = null;
          tabArr.active = true;
          tabArr.disabled = false;
          tabArr.Content = Value;
          this.EachEntityTabArray.push(tabArr);
          this.cd.detectChanges();
          this.AddtionalDetailsArray.push(Value);
        }

      }
      else {
        this.typesso = false;
        await this.vitalHttpServices.getURLs();
        let dep = this.commonService.getDefaultDeployment();
        if (dep !== null && dep !== undefined && dep !== "NULL") {
          this.defaultdeploymentkey = dep;
        } else {
          if (!this.vitalHttpServices.checkDeploymentLevelAdmin) {
            this.defaultdeploymentkey = "ALL";    // this is the code for run the application as for all the deployment
          }
          else {
            this.defaultdeploymentkey = this.vitalHttpServices.DeploymentKeys;    //this is code for single deployment wise
          }
        }
        this.datashare.selectedDefaultDeployment = this.defaultdeploymentkey;
        sessionStorage.setItem("page", btoa("home"));
        let userlogged = localStorage.getItem("UserEmail");
        let homePage_Role = localStorage.getItem('Homepage_role');
        let allowAllUsersRole = localStorage.getItem('allowAllUsersRole');
        let defaultEntity = localStorage.getItem('Defaultentity');
        if (homePage_Role.toLowerCase() != "associate") {
          this.vitalHttpServices.checkAuditUsers(userlogged);
        }
        let logoutFlag = true;
        if ((!userlogged || userlogged == "") &&
          (!homePage_Role || homePage_Role == "") &&
          (!allowAllUsersRole || allowAllUsersRole == "")) {
          logoutFlag = false;
          this.logout();
        }
        if (logoutFlag) {
          this.setTimeout();
          this.srcheight = window.innerHeight - 30;
          this.diffHeight = window.innerHeight - 240;
          this.datashare.ResetSql.subscribe(data => {
            this.SelectedMenuModel = false;
            $('#genericModal').modal('hide');
          });
          this.username = localStorage.getItem("user_name");
          this.rolename = localStorage.getItem("Homepage_role");

          try {
            let res = await this.vitalHttpServices.EntityDetails().toPromise();
            this.entities = res;
          }
          catch (err) {
            console.error(err);
            this.entities = [];
          }

          if (!this.vitalHttpServices.checkDeploymentLevelAdmin) {
            let defaultDepAll: any = ["ALL"]  //this comment code is used when we want to make single deployment work as multiple deployment
            this.DeploymentList = defaultDepAll.concat(this.vitalHttpServices.DeploymentKeys);  //this comment code is used when we want to make single deployment work as multiple deployment
          }
          else {
            this.DeploymentList = this.vitalHttpServices.DeploymentKeys;   // this code is used when we want this will work as single deployment
            this.defaultdeploymentkey = this.vitalHttpServices.DeploymentKeys[0];   // this code is used when we want this will work as single deployment
          }
          //remove case and patient from dropdown if role name is associate
          if (this.rolename.toString().toLowerCase() == 'associate') {
            this.entities.forEach((element, index) => {
              if (element.EntityName.toString().toLowerCase() === 'case' || element.EntityName.toString().toLowerCase() === 'patient') {
                this.entities.splice(index, 1);
              }
            });
          }
          this.entities.forEach(entity => {
            if (!this.EntityList.includes(entity.EntityName)) {
            this.EntityList.push(entity.EntityName);
            }
          });
          this.DrpDwnEntityValue = this.EntityList[0];
          this.checkDefaultEntity(defaultEntity);
          if (this.defaultdeploymentkey.toString().toLowerCase() != 'all')
            this.checkDefaultdepl(this.defaultdeploymentkey);
          //close the window if it is a login pop-up
          if (window.opener) {
            if (localStorage.getItem("ReloadPage") && localStorage.getItem("ReloadPage") == "Yes") {
              try {
                localStorage.removeItem("ReloadPage");
                window.opener.location.reload(true);
              }
              catch (error) {
                console.log(error);
              }
            }
            window.self.close();
          }
          else
            await this.GetHomeDetails();

        }
      }
    }
    catch (e) {
      console.error(e);
      this._snackBar.open('An error occurred while loading. Please reload the page again.', 'Failed');
    }
    finally {
      this.ngxService.stop('getHomeValues');
    }
  }
  // obj:any;
  // threePartSearch(enti:any){
  //   switch(enti){
  //     case 'Location':this.obj={entname :"Loc",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     case 'Facility':this.obj={entname :"Fac",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     case 'Physician':this.obj={entname :"Phy",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     case 'Pathologist':this.obj={entname :"Path",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     case 'Account ID':this.obj={entname :"Accid",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     case 'Organization ID':this.obj={entname :"Orgid",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     case 'Patient':this.obj={entname :"Pat",entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //     default:this.obj={entname :enti,entdesc:enti};this.ThreepartSearchEntitlyList.push(this.obj);break;
  //   }
  // }

  checkDefaultEntity(defaultEntity) {
    for (let i = 0; i < this.EntityList.length; i++) {
      if (this.EntityList[i] == defaultEntity) {
        this.isFavEntity = i;
        this.DrpDwnEntityValue = defaultEntity;
        //this.isdefault=true;
      }
    }
  }
  checkDefaultdepl(defaultdepl) {
    for (let i = 0; i < this.DeploymentList.length; i++) {
      if (this.DeploymentList[i].toUpperCase() == defaultdepl) {
        this.isFavDeployment = i;
        this.defaultdeploymentkey = defaultdepl;
        //this.isdefault=true;
      }
    }
  }

  // isdepdefault:boolean=false;
  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.defaultdeploymentkey = DeploymentKey.toUpperCase();
      this.datashare.selectedDefaultDeployment = DeploymentKey.toUpperCase();
      sessionStorage.setItem('selectedDeployment', this.datashare.selectedDefaultDeployment)
      this.defaultdeployment = DeploymentKey.toUpperCase();
      // this.entitySearch.fnFilterData(e.source.value, 'Deploymentkey', null);
      //this.entitySearch.fnApplyFilter()
    }
  }

  ifTextCompare() {
    this.ifCompared = false;
    this.ngxService.start()
    setTimeout(() => {
      this.ifCompared = true;
      this.ngxService.stopAll()
    }, 1000)
    $('#modelId').modal('show');
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  CheckedEntities() { }
  openblankrequisition() { }

  logout() {
    this.vitalHttpServices.logout();
  }

  SelectedEntities: any[] = [];
  OnChangeEntry(DrpDwnEntityValue, index) {
    this.DrpDwnEntityValue = DrpDwnEntityValue;
    this.SearchInput.nativeElement.focus();
  }

  OnChangeEntity(DrpDwnEntityValue, index) {
    this.DrpDwnEntityValue = DrpDwnEntityValue;
    var menuclasslist = (<HTMLElement>document.getElementById('entityDrpdwn'));
    if (menuclasslist.classList.contains('show'))
      menuclasslist.classList.remove('show');
    this.SearchInput.nativeElement.focus();
    this.userRole = localStorage.getItem("Homepage_role");
    if (this.userRole.toLowerCase() == 'support technician') {
      this.showRevision = true;
    } else {
      this.showRevision = false;
    }
  } // end of OnChangeEntity

  ngAfterViewInit() {
    $('body').removeClass('body-login');
    $('body').removeClass('login_bg');
    document.body.style.backgroundImage = '';
    document.body.style.backgroundColor = 'background-color: #fafafa !important;';
  }

  EventPropagation(event) {
    var menuclasslist = (<HTMLElement>document.getElementById('entityDrpdwn'));
    if (menuclasslist.classList.contains('show'))
      menuclasslist.classList.remove('show');
    else
      menuclasslist.classList.add('show');
    event.stopPropagation();
  }
  smallEventPropagation(event) {
    let menuclasslist = (<HTMLElement>document.getElementById('smallentity'));
    if (menuclasslist.classList.contains('show'))
      menuclasslist.classList.remove('show');
    else
      menuclasslist.classList.add('show');
    event.stopPropagation();
  }

  clearSearch() {
    this.queryvalue = ""
    // this.fnChildSearchSubmit(false);
  }

  threePartSearch(enti: any) {
    let shortentity = "";
    switch (enti) {
      case 'Location': shortentity = "Loc"; break;
      case 'Laboratory': shortentity = "Lab"; break;
      case 'Users': shortentity = "User"; break;
      case 'Facility': shortentity = "Fac"; break;
      case 'Physician': shortentity = "Phy"; break;
      case 'Pathologist': shortentity = "Path"; break;
      case 'Patient': shortentity = "Pat"; break;
      // case 'Account ID': shortentity = "Accid"; break;
      // case 'Group ID': shortentity = "orgid"; break;
      default: shortentity = enti; break;
    }
    return shortentity;
  }
  fnChildSearchSubmit(value) {
    let userlogged = localStorage.getItem("UserEmail");
    if (!userlogged || userlogged == "") {
      this.logout();
    }
    else {
      let isThreePartSearch = this.queryvalue.split(':').length === 3 ? true : false;
      if (this.rolename.toString().toLowerCase() == 'associate' && isThreePartSearch) {
        let tempContext = this.queryvalue.split(':')[1].toString().toLowerCase();
        if (tempContext == "pat" || tempContext == "case") {
          this.searchQueryValue = "not allowed";
          this.searchDrpDwnEntityValue = tempContext;
        }
        else {
          if (!this.vitalHttpServices.checkDeploymentLevelAdmin) {
            this.searchQueryValue = this.queryvalue;  // this is used when we are making work this single deployement as the all the deployment
          }
          else {
            // this is used when we are using this as single deployement wise
            if ((this.queryvalue.split(':')[0].toLowerCase() == this.vitalHttpServices.DeploymentKeys[0].toLowerCase())) {
              this.searchQueryValue = this.queryvalue;
            }
            else {
              this._snackBar.open('Deployment is not valid', 'Failed');
            }
          }
          this.searchDrpDwnEntityValue = this.DrpDwnEntityValue;
        }
      }
      else {
        //this.searchQueryValue = this.queryvalue;
        if (isThreePartSearch) {
          if (!this.vitalHttpServices.checkDeploymentLevelAdmin) {
            // this is used when we are making work this single deployement as the all the deployment
            this.searchQueryValue = this.queryvalue;
          }
          else {
            // this is used when we are using this as single deployement wise
            if ((this.queryvalue.split(':')[0].toLowerCase() == this.vitalHttpServices.DeploymentKeys[0].toLowerCase())) {
              this.searchQueryValue = this.queryvalue;
            }
            else {
              this._snackBar.open('Deployment is not valid', 'Failed');
            }
          }

        } else {
          if (this.defaultdeploymentkey !== 'ALL' && this.defaultdeploymentkey !== 'Deployment' && this.DrpDwnEntityValue) {
            // if(this.DrpDwnEntityValue.toLowerCase()=="users")
            // {
            //   this.DrpDwnEntityValue='user'
            // }
            this.DrpDwnEntityValueFOrSearch = this.threePartSearch(this.DrpDwnEntityValue);
            this.searchQueryValue = this.defaultdeploymentkey.toLowerCase() + ":" + this.DrpDwnEntityValueFOrSearch.toLowerCase() + ":" + this.queryvalue;


          } else {
            this.searchQueryValue = this.queryvalue;
          }
        }
        // this.searchDrpDwnEntityValue =this.ConvertToOrginalEntity(this.DrpDwnEntityValue);
        this.searchDrpDwnEntityValue = this.DrpDwnEntityValue;

      }
      this.submitType = !this.submitType;
    }
  }
  // ConvertToOrginalEntity(entity:any){
  //   let shortentity="";
  //   switch(entity){
  //     case 'Location ID':shortentity="Location";break;
  //     case 'Lab':shortentity="Laboratory";break;
  //     case 'Account ID':shortentity="Account ID";break;
  //     case 'Group ID':shortentity="Group ID";break;
  //     default:shortentity=entity;break;
  //   }
  //   return shortentity;
  // }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), parseInt(localStorage.getItem("timeOut")));
  }
  // @HostListener('window:mousemove') refreshUserState() {
  //   if (!this.isLocked) {
  //     clearTimeout(this.userActivity);
  //     this.setTimeout();
  //   }

  // }
  // @HostListener('window:keydown') kbactivityUserState() {
  //   if (!this.isLocked) {
  //     clearTimeout(this.userActivity);
  //     this.setTimeout();
  //   }
  // }
  ngOnDestroy() {
    this.closeChildWindow();
    window.removeEventListener('beforeunload', this.closeChildWindow);
  }
  private closeChildWindow = () => {
    if(this.tabService.reportThemeWindow){
      this.tabService.reportThemeWindow.close();
    }
    else if(this.tabService.reportBuilderWindow){
      this.tabService.reportBuilderWindow.close();
    }
  }

  onChangeLanguage(language) {
    this.inputOptions = Object.assign({}, this.inputOptions, { language: language });
    this.originalModel = Object.assign({}, this.originalModel, { language: language });
    this.modifiedModel = Object.assign({}, this.modifiedModel, { language: language });
  }

  onChangeTheme(theme) {
    this.inputOptions = Object.assign({}, this.inputOptions, { theme: theme });
    this.diffOptions = Object.assign({}, this.diffOptions, { theme: theme });
  }

  onChangeInline(checked) {
    this.diffOptions = Object.assign({}, this.diffOptions, { renderSideBySide: !checked });
  }

  onCompare() {
    this.originalModel = Object.assign({}, this.originalModel, { code: this.text1 });
    this.modifiedModel = Object.assign({}, this.originalModel, { code: this.text2 });
    this.isCompared = true;
    this.ifCompared = false;
  }

  offCompare() {
    this.isCompared = false;
    this.ifCompared = true;
  }

  closeModal() {
    $('#myModal').modal('hide');
    this.isCompared = false;
    this.ifCompared = true;
    this.text1 = "";
    this.text2 = "";
  }

  async GetHomeDetails() {
    if (this.vitalHttpServices.mainQueryList || this.vitalHttpServices.mainQueryList.length <= 0) {
      let query = new CardQueries().mainQuery;
      let queryRequest: any = {
        OperationName: null,
        Query: query,
        Variables: null,
      };
      let result = await this.vitalHttpServices.GetData(queryRequest, 'configdb').toPromise();
      if (!result.errors) {
        this.vitalHttpServices.mainQueryList = result.data.Card;
        sessionStorage.setItem('GraphqlMainQuery', btoa(JSON.stringify(this.vitalHttpServices.mainQueryList)));
        //once all the queries are fetched get roles
        if (!this.typesso) {
          await this.getRoleData();
          await this.getDropdownValues();
          await this.getCasetypeList();
        }
        else {
          await this.getRoleData();
          await this.getDropdownValues();
          await this.getCasetypeList();
        }

        //  this.getAssociationTypesDropdown();
      } else {
        console.error(result);
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      }
    }
    else {
      if (!this.typesso) {
        await this.getRoleData();
      }
      else {
        await this.getDropdownValues();
        await this.getCasetypeList();
      }

    }
  }

  async getCasetypeList() {
    let query = new CardQueries().getCasetypeList();
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: null,
    };
    let res = await this.vitalHttpServices.GetData(queryRequest, 'configdb').toPromise();
    if (!res.errors) {
      if (res.data && res.data.Casetypes && res.data.Casetypes.length > 0 && res.data.Casetypes[0].Items_JSON) {
        this.vitalHttpServices.casetypeList = JSON.parse(res.data.Casetypes[0].Items_JSON.toString()).casetypes_list;
      }
      else {
        this.vitalHttpServices.casetypeList = [];
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      }
    } else {
      this.vitalHttpServices.casetypeList = [];
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    }
  }

  //#region get all the dropdown datas used in Setup new Location Page
  async getDropdownValues() {
    let drpquery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "CategoryDropDown");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "keyword": "getDropDownValues" }
    };
    let result = await this.vitalHttpServices.GetData(drpqueryRequest, 'configdb').toPromise();
    if (!result.errors) {
      this.vitalHttpServices.locationDropdownValues = JSON.parse(result.data.submenuData[0].Items_JSON);
      this.ngxService.stop();
    }
    else {
      console.error(result.errors);
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    }
  }


  public GetQuery(keyword: string) {
    let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
  }

  async getRoleData() {
    let queryVariable = {
      RoleName: localStorage.getItem("Homepage_role"),
      CardType: "Homepage",
    };
    let query: string = this.vitalHttpServices.GetconfigQuery();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let data = await this.vitalHttpServices.GetData(queryResult, 'configdb').toPromise();
    if (!data.errors) {
      if (data.data.submenuData && data.data.submenuData.length > 0) {

        //when we want to use this application work as all deployment wise comment this if part
        if (!this.typesso) {
          if (localStorage.getItem("Homepage_role").toLowerCase() != "associate") {
            let result = this.vitalHttpServices.auditUsers;
            if (result) { // Check if result is not null or undefined
              for (let [key, value] of Object.entries(result)) {
                if (key.toLowerCase() === this.defaultdeploymentkey.toLowerCase()) {
                  let auditUserId = value.toString();
                  sessionStorage.setItem('Userid', auditUserId);
                }
              }
            }
          }
        }
        let homepagedata = JSON.parse(data.data.submenuData[0].Permission);
        this.PermissionJson = JSON.parse(data.data.submenuData[0].Permission)
        this.menulist = homepagedata.MenuItems;
        this.quicklist = homepagedata.QuickItems;
        if (typeof this.quicklist === 'undefined' || this.quicklist.length === 0) {
          this.quckListdisabled = true;
        }
        this.comaprelist = homepagedata.CompareItems;
        if (homepagedata.DeploymentKeys && homepagedata.DeploymentKeys.length >= 0) {
          this.vitalHttpServices.Additionaldeployment = homepagedata.DeploymentKeys
        }
      }
    }
    else {
      console.error(data.errors);
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  SelectedModal(e) {
    this.menuType = e.Template;
    if (this.menuType === "IPuser") {
      this.showIpModel = true;
      this.SelectedMenuModel = "UserIpModel";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal UserIpModel";
    }
    else if (this.menuType === "HL7") {
      this.SelectedMenuModel = "HL7LogsModel";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal HL7LogsModel genericModal";
    }
    else if (this.menuType === "AddUser") {
      this.SelectedMenuModel = "AddUserModel";
      this.createLiveUser = false;
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal AddUserModel genericModal";
    }
    else if (this.menuType === "AddUserLive") {
      this.SelectedMenuModel = "AddUserModel";
      this.createLiveUser = true;
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal AddUserModel genericModal";
    }
    else if (this.menuType.toLowerCase() === "new-casetype-enable-casetype") {
      this.SelectedMenuModel = this.menuType.toLowerCase();
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal NewCustomerEnableCasetype genericModal";
    }
    else if (this.menuType === "setup-new-customer") {
      this.SelectedMenuModel = "setup-new-customer";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal SetupNewCustomer genericModal";
    }
    else if (this.menuType === "VDID") {
      this.SelectedMenuModel = "VDIDModel";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal VDIDModel genericModal";
    } else if (this.menuType === "RevisionReason") {
      this.SelectedMenuModel = "RevisionReasonModal";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal RevisionReasonModal";
    } else if (this.menuType === "ManageReportRecipients") {
      let modelref = this.modalService.open(ManageReportRecipientComponent, {
        windowClass: "agentRecipientModal",
        backdrop: 'static',
        keyboard: false
      });

      // this.SelectedMenuModel = "ManageReportRecipientsModal";
      // $('#genericModal').modal('show');
      // this.GenericModalClass = "modal ManageReportRecipientsModal";
    }
    else if (this.menuType === "Executesql") {
      // this.SelectedMenuModel = "ExecutesqlModel";
      // $('#genericModal').modal('show');
      // this.GenericModalClass = "modal ExecutesqlModel";
      //const dep = this.datashare.selectedDefaultDeployment ? this.datashare.selectedDefaultDeployment : this.commonService.getDefaultDeployment();
      window.open(`${window.location.origin}/#/executesql`, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no');
    }
    else if (this.menuType === "OrgCompare") {
      this.SelectedMenuModel = "OrgCompareModel";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal OrgCompareDialog genericModal";
    }
    else if (this.menuType === "TextCompare") {
      this.SelectedMenuModel = "TextCompareModel";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal TextCompareDialog genericModal";
    }
    else if (this.menuType === "app-user-role-mgmt") {
      let modelref = this.modalService.open(UserRoleMgmtComponent, {
        windowClass: "agentRecipientModal",
        backdrop: 'static',
        keyboard: false
      });

      // this.SelectedMenuModel = "app-user-role-mgmt";
      // $('#genericModal').modal('show');
      // this.GenericModalClass = "modal UserRoleMgmt genericModal"
    }
    else if (this.menuType === "app-default-role-settings") {
      let modelref = this.modalService.open(DefaultRoleSettingsComponent, {
        windowClass: "agentRecipientModal",
        backdrop: 'static',
        keyboard: false
      });
    }else if (this.menuType === "app-acs-serach-optimization") {
      this.SelectedMenuModel = "app-acs-serach-optimization";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal AcsSearchOptimization genericModal";
    }

    else if (this.menuType === "app-json-format") {
      this.SelectedMenuModel = "app-json-format";
      $('#genericModal').modal('show');
      this.GenericModalClass = "modal JsonFormatt genericModal"
    }

    else if (this.menuType === "export-case-details") {
      let modelref = this.modalService.open(ExportCaseDetailsComponent, {
        windowClass: "agentRecipientModal",
        backdrop: 'static',
        keyboard: false
      });
      modelref.componentInstance.permissions = this.PermissionJson;

      // this.SelectedMenuModel = "export-case-details";
      // $('#genericModal').modal('show');
    }
    else if (this.menuType === "app-compendium-admin") {
      this.SelectedMenuModel = "app-compendium-admin";
      $('#genericModal').modal('show');
    }
    else if (this.menuType === "app-replicate-organization") {
      this.SelectedMenuModel = "ReplicateOrgModel";
      $('#genericModal').modal('show');
    }
    else if (this.menuType === "app-vital-insights") {
      this.SelectedMenuModel = "VitalInsightsModel";
      $('#genericModal').modal('show');
    } else if (this.menuType === "app-updatereporttemplate") {
      this.SelectedMenuModel = "updatereporttemplate";
      this.isTemplatePopupVisible = true
      $('#genericModal').modal('show');
    }
    else if (this.menuType === "app-generic-bulk-upload") {
      this.SelectedMenuModel = "GenricBulkUpload";
      $('#genericModal').modal('show');
    }
    else if (this.menuType === "app-patient-report-themes") {
      this.SelectedMenuModel = "LaunchReportThemeModel";
      this.getLaunchLink();
    }
    else if (this.menuType === "app-manage-caselist") {
      this.SelectedMenuModel = "app-manage-caselist";
      $('#genericModal').modal('show');
    }
    else if (this.menuType === "app-bulk-upload-offline-process") {
      let modelref = this.dialog.open(BulkUploadOfflineProcessComponent, {
        disableClose:true,
        width:'99%',
        height:'99%',
        data:{headerName:'Offline Bulk Process'}
      });
    }
    else if (this.menuType === "app-manage-configurations") {
      let modelref = this.dialog.open(ManageConfigurationsComponent, {
        disableClose:true,
        width:'99%',
        height:'99%',
        data:'Manage Configuration'
      });
    }

  }
  onPopupClose() {
    this.isTemplatePopupVisible = false;
    this.showIpModel = false;
    $('#genericModal').modal('hide');
  }
  searchentityarr: any;
  isdefault: boolean = false;
  onEntitySave(DrpEntityValue: any, favourite) {
    this.ngxService.start();
    let userlogged = localStorage.getItem("UserEmail");
    let homePage_Role = localStorage.getItem('Homepage_role');
    let preferencesDb;
    let dbname = this.defaultdeployment
    //let dbname = sessionStorage.getItem('selectedDeployment').toUpperCase() ? sessionStorage.getItem('selectedDeployment').toUpperCase() : null;
    sessionStorage.setItem('deploymentKey', 'configdb');
    if (dbname == 'ALL') {
      preferencesDb = null;
    } else {
      preferencesDb = dbname;
    }
    let oldValue = DrpEntityValue
    DrpEntityValue = favourite == this.isFavEntity ? null : DrpEntityValue
    const obj = {
      "Username": userlogged,
      "userEmail": userlogged,
      "deploymentkey": preferencesDb,
      "entity": DrpEntityValue,
      "isactive": 1
    }
    this.vitalHttpServices.onSaveEntity(obj, "configdb").subscribe(res => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      localStorage.setItem('Defaultentity', DrpEntityValue);
      if (favourite == this.isFavEntity) {
        this.isFavEntity = null;
        this.DrpDwnEntityValue = this.EntityList[0];
        this._snackBar.open(oldValue + ' removed from favourite entity.', 'Close');
      }
      else
        DrpEntityValue ? this._snackBar.open(DrpEntityValue + ' set as favourite entity.', 'Close') : this._snackBar.open(DrpEntityValue + ' removed from favourite entity.', 'Close');
      this.ngxService.stop();
      this.checkDefaultEntity(DrpEntityValue);


      // this.refreshPage();
      //this._snackBar.open('Saved Successfully.', 'Close');
      // this.commonService.createActivityTracker('Created', -1, 'Case Flag Saved Successfully', 'Audit', obj, {});
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  onDeploymentSave(deployment: any, fav) {
    this.ngxService.start();
    let userlogged = localStorage.getItem("UserEmail");
    let homePage_Role = localStorage.getItem('Homepage_role');
    let entity = localStorage.getItem('Defaultentity');
    let dbName = deployment;
    let oldValue = deployment
    deployment = fav == this.isFavDeployment ? null : deployment.toUpperCase();
    const obj = {
      "Username": userlogged,
      "userEmail": userlogged,
      "deploymentkey": deployment,
      "entity": entity,
      "isactive": 1
    }
    sessionStorage.setItem('deploymentKey', 'configdb');
    this.vitalHttpServices.onSaveEntity(obj, 'configdb').subscribe(res => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      localStorage.setItem('defaultdeployment', deployment);
      if (fav == this.isFavDeployment) {
        this.isFavDeployment = null;
        // when we want to use this as the all deployment wise the un comment this
        this.defaultdeploymentkey = "ALL";
        this.defaultdeployment = "ALL";
        this._snackBar.open(oldValue + ' removed from favourite deployment.', 'Close');
        //localStorage.removeItem('defaultdeployment');
      }
      else {
        this._snackBar.open(deployment + ' set as favourite deployment.', 'Close')
      }
      this.ngxService.stop();
      this.checkDefaultdepl(deployment);

      // localStorage.setItem('defaultdeployment', deployment);
      // this.ngxService.stop();
      // this._snackBar.open(dbName + ' set as favourite successfully.', 'Close');
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  //moving login concept in home page:
  private setDefaultRole(role) {
    let obj = {
      UserEmail: localStorage.getItem("UserEmail")
    }
    this.ngxService.start();
    this.vitalHttpServices.getUserDefaultRoles(obj, 'configdb').subscribe(data => {

      this.ngxService.stop();
      if (data[0]?.rolename) {
        localStorage.setItem('Homepage_role', data[0].rolename);
        localStorage.setItem("allowAllUsersRole", btoa(data[0].rolename));
      }
      else {
        localStorage.setItem('Homepage_role', role);
      }
      localStorage.setItem('Defaultentity', data[0].entity);
      localStorage.setItem('defaultdeployment', data[0].deploymentkey);
      this.getHomeValues();
    }, error => {
      this.ngxService.stop();
      localStorage.setItem('Homepage_role', role);
      this.getHomeValues();
      console.info(error);
    });
    //code to set role for homepage


    //code to navigate to home page

  }

  GetUserRoleOrder() {
    let queryResult = this.commonService.GetCardRequest(
      { 'status': '1' }, new CardQueries().getVitalAdminRoleNames());
    sessionStorage.setItem('deploymentKey', "configdb");
    this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let rolenameList = Resdata.data.submenuData;
        if (rolenameList && rolenameList.length > 0) {
          // allowAll users
          let allowAllUserIndex = -1;
          let allowAllUserFlag = true;
          let allowAllUserResData: any = [];
          allowAllUserResData = rolenameList.filter(e => e.IsAllowAllUsers == true);
          if (allowAllUserResData && allowAllUserResData.length > 0) {
            //to get search based role based on priority and IsAllowAllUsers column
            this.roleList.forEach((element, ind) => {
              let allowAllUserstempIndex = allowAllUserResData.find(e => e.RoleName == element);
              if (allowAllUserstempIndex) {
                let allowAllUserIndex1 = allowAllUserstempIndex.Order;
                if (allowAllUserIndex == -1 || allowAllUserIndex1 < allowAllUserIndex)
                  allowAllUserIndex = allowAllUserIndex1;
              }
              else {
                if (ind == (this.roleList.length - 1) && allowAllUserIndex == -1) {
                  localStorage.setItem("allowAllUsersRole", btoa(environment.DefaultRole));
                  allowAllUserFlag = false;
                }
              }
            });
            if (allowAllUserFlag) {
              let role = allowAllUserResData.find(e => e.Order == allowAllUserIndex).RoleName;
              localStorage.setItem("allowAllUsersRole", btoa(role));
            }
          }
          else {
            localStorage.setItem("allowAllUsersRole", btoa(environment.DefaultRole));
          }
          //to get the homepage role based on priority
          let flag = true;
          let index = -1;
          this.roleList.forEach((element, ind) => {
            let tempIndex = rolenameList.find(e => e.RoleName == element);
            if (tempIndex) {
              let index1 = tempIndex.Order;
              if (index == -1 || index1 < index)
                index = index1;
            }
            else {
              if (ind == (this.roleList.length - 1) && index == -1) {
                flag = false;
                this.setDefaultRole(environment.DefaultRole);
              }
            }
          });
          if (flag) {
            let role = rolenameList.find(e => e.Order == index).RoleName;
            this.setDefaultRole(role);
          }
        }
        else {
          // this.loginLoad(false)
        }
      }
    }, error => {
      console.error(error);
      //this.loginLoad(false)
    });
  }

  async getRolesOnFusionAuth(userid) {
    this.ngxService.start("home");
    this.vitalHttpServices.getADUserRoles(userid).subscribe(async resData => {
      if (resData) {
        this.ngxService.start();
        let data = JSON.parse(JSON.stringify(resData));
        if (data.data && data.data.length > 0) {
          this.typesso = true;
          let uservaliadtion = JSON.parse(data.userinfo);
          let sessiondata = JSON.parse(data.data);
          if (sessiondata.UserEmail == uservaliadtion.email) {
            this.vitalHttpServices.deploymentKey = sessiondata.DisplayField.deploymentkey;
            this.vitalHttpServices.auditUsers = sessiondata.Userid;
            sessionStorage.setItem('Userid', this.vitalHttpServices.auditUsers);
            sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
            localStorage.setItem('defaultdeployment', this.vitalHttpServices.deploymentKey);
           // await this.vitalHttpServices.getURLs();
            localStorage.setItem('accesstoken', data.token);
            let userinfodata = JSON.parse(data.userinfo);
            let formattedDisplayName = userinfodata.email && userinfodata.email != "" ? userinfodata.email.split('@')[0].split('.') : "";
            if (formattedDisplayName != "") {
              localStorage.setItem("user_name", this.convertCase(formattedDisplayName[0]) + ' ' + this.convertCase(formattedDisplayName[1]));
              this.commonService.adminLoggedInUser = this.convertCase(formattedDisplayName[0]) + ' ' + this.convertCase(formattedDisplayName[1]);
            }
            else {
              localStorage.setItem("user_name", "Error!");
            }
            localStorage.setItem("UserEmail", sessiondata.UserEmail);
            this.commonService.adminLoggedInUserEmail = sessiondata?.UserEmail;
            localStorage.setItem("allowAllUsersRole", btoa(sessiondata.Rolename));
            localStorage.setItem('Homepage_role', sessiondata.Rolename);
            await this.vitalHttpServices.getAllQueries();
            this.vitalHttpServices.SsoType = sessiondata.CallType;
            this.vitalHttpServices.sssodata = sessiondata;
            this.getHomeValues();
            this.vitalHttpServices.DeploymentKeys = sessiondata.DeployemntKeyList;
            this.ngxService.stop("home");
            return;
          }
          else {
            this.informationPopBeforeLogOut();
          }
          this.ngxService.stopAll();
        }
        else if (data.userinfo && data.userinfo != "") {
          localStorage.setItem('accesstoken', data.token)
          data = JSON.parse(data.userinfo);
          let loginemail = localStorage.getItem("UserEmail");
          if (loginemail.toLowerCase() == data.email.toLowerCase()) {
            let formattedDisplayName = data.email && data.email != "" ? data.email.split('@')[0].split('.') : "";
            if (formattedDisplayName != "") {
              localStorage.setItem("user_name", this.convertCase(formattedDisplayName[0]) + ' ' + this.convertCase(formattedDisplayName[1]));
              this.commonService.adminLoggedInUser = this.convertCase(formattedDisplayName[0]) + ' ' + this.convertCase(formattedDisplayName[1]);
            }
            else {
              localStorage.setItem("user_name", "Error!");
            }
            localStorage.setItem("UserEmail", data.email);
            this.commonService.adminLoggedInUserEmail = data?.email;
            if (data['groups'] && data['groups'].length > 0) {
              this.roleList = data['groups'];
              this.GetUserRoleOrder();
            }
            else {
              localStorage.setItem("allowAllUsersRole", btoa(environment.DefaultRole));
              this.setDefaultRole(environment.DefaultRole);
            }
            this.ngxService.stop("home");
            return;
          }
          else {
            this.ngxService.stop("home");
            this.router.navigate(['/login']);
            // this._snackBar.open('Invalid Login', 'Failed');
            sessionStorage.clear();
            localStorage.clear();
            this.cookieService.deleteAll('/', environment.domain);
            sessionStorage.setItem("page", btoa("login"))
            this.sendMssageToStopParentLoader("Logout");
            setTimeout(() => {
              this.vitalHttpServices.logout();
            }, 1000);
          }
        }

      }
      else {
        this.ngxService.stop("home");
        this.router.navigate(['/login']);
        // this._snackBar.open('Invalid Login', 'Failed');
        sessionStorage.clear();
        localStorage.clear();
        this.cookieService.deleteAll('/', environment.domain);
        sessionStorage.setItem("page", btoa("login"))
        this.sendMssageToStopParentLoader("Logout");
        setTimeout(() => {
          this.vitalHttpServices.logout();
        }, 1000);
      }
    }, error => {
      this.ngxService.stop("home");
      this.router.navigate(['/login']);
      //   this._snackBar.open('Invalid Login', 'Failed');
      sessionStorage.clear();
      localStorage.clear();
      this.cookieService.deleteAll('/', environment.domain);
      sessionStorage.setItem("page", btoa("login"))
      this.sendMssageToStopParentLoader("Logout");
      setTimeout(() => {
        this.vitalHttpServices.logout();
      }, 1000);
    });
    this.ngxService.stop("home");
  }

  sendMssageToStopParentLoader(inputmsg) {
    let message = inputmsg;
    parent.postMessage(message, '*');
  }

  //Information that user has the session with the different authentication
  informationPopBeforeLogOut() {
    this.sendMssageToStopParentLoader("CloseLoader");
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      width: '360px',
      panelClass: 'admin-custom-popup',
      data: { header: "", message: "A session is already active in the same browser. You will be logged out from here. Please try to login in a different browser OR Logout the active session and Login here again.", continue: "Ok", cancel: "dontshow" }
    })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.ngxService.stop("home");
        sessionStorage.clear();
        localStorage.clear();
        this.cookieService.deleteAll('/', environment.domain);
        sessionStorage.setItem("page", btoa("login"))
        this.sendMssageToStopParentLoader("Logout");
        this.vitalHttpServices.logout();
      }
    })
  }
  convertCase(stringValue) {
    return stringValue ? (stringValue.slice(0, 1).toUpperCase() + stringValue.slice(1).toLowerCase()) : "";
  }

  async getLaunchLink() {
    this.ngxService.start();
    try {
      let Encrypt: any = {
          "UserEmail": localStorage.getItem('UserEmail'),
          "UserRole": localStorage.getItem('Homepage_role'),
          "type": "Admin"
        }
      await this.vitalHttpServices.EncryptUrlPayload(Encrypt).subscribe(result => {
        this.ngxService.stop();
        if (!result.error) {
          if (!this.vitalHttpServices?.patientUrl) {
            this._snackBar.open("Not able to launch Patient Report Theme")
          }
          else {
            this.endpoint = this.vitalHttpServices.patientUrl + 'sso?data=' + result;
            if (this.tabService.reportThemeWindow && !this.tabService.reportThemeWindow.closed) {
              this.tabService.reportThemeWindow.location.href = this.endpoint;
              this.tabService.reportThemeWindow.focus();
            }
            else{
              this.tabService.reportThemeWindow = window.open(this.endpoint);
            }
          }
        }
      });
    } catch (error) {
      this.ngxService.stop();
      console.error(error);
    }
  }
}
