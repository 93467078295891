import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../core/services/commonservices';
import { VitalHttpServices } from '../core/services/VitalHttpServices';
import { VitalAdminTab } from './commoncomponents/tab';
import { MainCardModel } from './DbModel/MainCardModel';
import { VitalMenuComponent } from './Vital Search Menu/vital-menu.component';

@Injectable({
  providedIn: 'root',
})

export class VitalAdminTabService {
  public rolemenudata;
  public actionbuttondata;
  roleMenuPermissions: any;
  keyword: any;
  public emailUpdated: any
  public status: any
  public reportThemeWindow: Window;
  public reportBuilderWindow: Window;
  constructor(
    public commonService: CommonService,
    public vitalHttpServices: VitalHttpServices,
    private ngxService: NgxUiLoaderService,
    private _snackbar: MatSnackBar,
    private vitalmenu: VitalMenuComponent
  ) { }
  mainCardModel: MainCardModel = new MainCardModel(this.commonService, this.vitalHttpServices, this._snackbar);
  public tabs: VitalAdminTab[] = [];
  public tabSub = new BehaviorSubject<VitalAdminTab[]>(this.tabs);
  public removeTab(index: number) {
    if (this.reportBuilderWindow) {
      this.reportBuilderWindow.close();
    }
    if(this.reportThemeWindow){
      this.reportThemeWindow.close();
    }
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0) {
      this.tabs[this.tabs.length - 1].active = true;
    }
    this.tabSub.next(this.tabs);
  }


  public addTab(tab: VitalAdminTab) {
    this.removeTab(1)
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].active === true) {
        this.tabs[i].active = false;
      }
    }
    tab.id = this.tabs.length + 1;
    tab.active = true;
    if (this.keyword != 'casetype') { // checcking the kwyword if it is not case type then it will go
      //Adding an identifier for an OrganizationType Attribute in the header card
      //Check if the Context type is either Lab OR Facility
      let contexttype = sessionStorage.getItem('search_context');
      if (contexttype.toString().toLowerCase() == 'lab' || contexttype.toString().toLowerCase() == 'facility') {
        let orgTypeObj = {};
        let query = this.vitalHttpServices.GetQuery('isPOL');
        let queryRequest: any = {
          OperationName: null,
          Query: query,
          Variables: { orgid: sessionStorage.getItem('org_id') },
        };
        this.ngxService.start();
        this.vitalHttpServices.GetData(queryRequest).subscribe(
          (data) => {
            if (!data.errors) {
              let accountType = this.commonService.isLiveAccount == true ? "Live" : "Test"
              if (data.data.submenuData) {
                if (data.data.submenuData.length > 0) {
                  if (data.data.submenuData[0].Group_Type != 'POL') {
                    let ContextSearch;
                    ContextSearch = contexttype//tab.cardType;
                    orgTypeObj = {};
                    orgTypeObj = { 'Group_Type': ContextSearch.charAt(0).toUpperCase() + ContextSearch.slice(1) + ' (' + accountType + ')' }
                    this.commonService.isPol = false;
                  } else {
                    orgTypeObj = {};
                    orgTypeObj = data.data.submenuData[0];
                    orgTypeObj = { 'Group_Type': data.data.submenuData[0].Group_Type + ' (' + accountType + ')' }
                    this.commonService.isPol = true;
                  }
                  tab.tabData.mainCard = Object.assign(tab.tabData.mainCard, orgTypeObj)
                  Object.keys(orgTypeObj).find(key => {
                    if (key == 'Group_Type') {
                      if (tab.tabData.labels.includes('Group_Type') == false) {
                        tab.tabData.labels.push(key)
                      }
                    }
                  })
                  this.tabs.push(tab);
                }
                else {
                  let ContextSearch;
                  ContextSearch = contexttype//tab.cardType;
                  orgTypeObj = {};
                  orgTypeObj = { 'Group_Type': ContextSearch.charAt(0).toUpperCase() + ContextSearch.slice(1) + ' (' + accountType + ')' }
                  tab.tabData.mainCard = Object.assign(tab.tabData.mainCard, orgTypeObj)
                  Object.keys(orgTypeObj).find(key => {
                    if (key == 'Group_Type') {
                      if (tab.tabData.labels.includes('Group_Type') == false) {
                        tab.tabData.labels.push(key)
                      }
                    }
                  })
                  this.tabs.push(tab);
                }
              }
            }
          })
      }
      else {
        tab.tabData.labels = tab.tabData.labels.filter(va => va.toLowerCase() != "organizationid")
        this.tabs.push(tab);
        sessionStorage.setItem('org_id', tab.tabData.mainCard.Organizationid.toString());
        tab.tabData.mainCard.Last_Logged_In_Date = this.matchCreatedDate(tab.tabData.mainCard.User_Created_Date, tab.tabData.mainCard.Last_Logged_In_Date);
      }
    }
    else {
      this.tabs.push(tab);
    }
    this.tabSub.next(this.tabs);
  }

  matchCreatedDate(createddate, LastLoggedInDate) {
    let colonIndexCD = createddate.lastIndexOf(':');
    let colonIndexLLD = LastLoggedInDate.lastIndexOf(':');
    if (colonIndexLLD != -1 && colonIndexCD != -1) {
      return (createddate.substring(0, colonIndexLLD) == LastLoggedInDate.substring(0, colonIndexLLD)) ? 'NA' : LastLoggedInDate
    }
    return LastLoggedInDate;
  }

  public makeTabActiveByIndex(index: number) {
    if (this.tabs[index]) {
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].active = false;
      }
      this.tabs[index].active = true;
      this.tabSub.next(this.tabs);
    }
  }

  public makeTabActiveByCardType(cardtype: string) {
    let index: number = -1;
    index = this.tabs.findIndex(
      (tab) => tab.cardType.toLocaleLowerCase() === cardtype.toLocaleLowerCase()
    );
    this.makeTabActiveByIndex(index);
  }

  addNewOrSelectTab(newtab: any) {
    this.keyword = newtab.keyword;
    let selectedTab: number = -1;
    let labTab: number = -1;
    this.ngxService.start();
    let org;
    if (newtab.orgid !== undefined) {
      org = newtab.orgid;
    } else {
      org = 0;
    }
    this.mainCardModel.GetData(newtab.accountid, newtab.keyword, newtab.cardtype, org).subscribe((result) => {
      if (!result.errors) {

        this.ngxService.stop();
        let mainCard = result.data.Card[0];
        if (mainCard) {
          let isCompendium = newtab.keyword == 'casetype' ? !mainCard.isCompendium || mainCard.isCompendium == 0 ? false : true : null;
          let finalizeAfterTC = newtab.keyword == 'casetype' ? mainCard.FinalizeAfterTC : null;
          this.commonService.isCompendiumCaseType = isCompendium;
          mainCard.Compendium = isCompendium ? 'Yes' : 'No';
          delete mainCard.isCompendium
          let menuJson = newtab.rolemenu;
          let labels = Object.keys(mainCard);
          let secondarykeys: any;
          // if(newtab.cardtype.toLowerCase() == 'users' || newtab.cardtype.toLowerCase() == 'physician' || newtab.cardtype.toLowerCase() == 'pathologist'|| newtab.keyword == 'casetype'){
          //   let OrgIndex: number = -1;
          //   OrgIndex = labels.indexOf('Group_ID', 0);
          //   if(OrgIndex > -1){
          //     secondarykeys = { OrganizationId: mainCard[labels[OrgIndex]], casetype: newtab.cardtype }
          //   }
          //   }else{
          //   let index1 = mainCard.Group_Name && mainCard.Group_Name.toString().lastIndexOf('(');
          //  let OrgIndex1 = mainCard.Group_Name && mainCard.Group_Name.toString().slice(index1 + 1).split(')')[0];
          //   if (OrgIndex1 > -1) {
          //     secondarykeys = { OrganizationId: OrgIndex1, casetype: newtab.cardtype }
          //   }
          // }

          let index: number = -1;
          index = labels.indexOf('OrganizationID', 0);

          if (index > -1) {
            secondarykeys = {
              OrganizationId: mainCard[labels[index]],
              casetype: newtab.cardtype,
            };
          }

          index = labels.indexOf('id', 0);
          if (index > -1) {
            labels.splice(index, 1);
          }
          let tabData: any;

          // let casetype_exists = environment.casetypes_list.filter(data => data.toString().toLowerCase() === newtab.cardtype.toLowerCase());
          let casetype_exists = this.vitalHttpServices.casetypeList?.filter(data => data.toString().toLowerCase() === newtab.cardtype.toLowerCase());
          if (casetype_exists?.length > 0) {
            // All SurgicalCasetype menu
            sessionStorage.setItem('deploymentKey', 'configdb');
            let queryVariable = {
              RoleName: sessionStorage.getItem('RoleName'),
              CardType: 'SurgicalCasetype'
            };
            let query = this.vitalHttpServices.GetconfigQuery();
            let queryResult = this.commonService.GetCardRequest(queryVariable, query);
            this.vitalHttpServices.GetData(queryResult).subscribe((data) => {
              let newQuery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "getVitalAdminMenus")[0].Query;
              let newQueryVariable = {
                CardName: 'SurgicalCasetype'
              };
              let newQueryResult = this.commonService.GetCardRequest(newQueryVariable, newQuery)
              this.vitalHttpServices.GetData(newQueryResult).subscribe((newResData) => {
                sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                let roledata = JSON.parse(data.data.submenuData[0].Permission);

                this.rolemenudata = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
                if (finalizeAfterTC) {
                  this.vitalmenu.changeMenuJson(this.rolemenudata, "Masters", "Diagnosis Templates");
                }
                delete mainCard.FinalizeAfterTC
                if (isCompendium) {
                  this.vitalmenu.changeMenuJson(this.rolemenudata, "Masters", "Adequacy Codes")
                }
                else {
                  this.vitalmenu.changeMenuJson(this.rolemenudata, "Settings", "Requisition Profiles");
                }

                if (sessionStorage.getItem('search_context') != 'lab') {
                  this.vitalmenu.changeMenuJson(this.rolemenudata, "Settings", "Requisition Profiles");
                }

                this.actionbuttondata = roledata.ActionButton;
                this.roleMenuPermissions = roledata.MenuItems;
                this.vitalHttpServices.CasemenuAction = roledata.MenuItems;
                this.checkMenuConditions(newtab.cardtype.toLowerCase());

                tabData = {
                  notinitial: true,
                  mainCard: mainCard,
                  menuJson: menuJson,
                  labels: labels,
                  accountid: newtab.accountid,
                  cardtype: newtab.cardtype,
                  secondarykeys: secondarykeys,
                  rolepermission: this.actionbuttondata,
                  rolemenu: this.rolemenudata,
                  roleMenuPermissions: this.roleMenuPermissions,
                  GroupCode: newtab.GroupCode
                };
                selectedTab = this.tabs.findIndex(
                  (tab) => tab.cardType.trim().toLocaleLowerCase() === newtab.cardtype.trim().toLocaleLowerCase());
                if (selectedTab === -1) {
                  this.addTab(new VitalAdminTab(newtab.text, tabData, newtab.cardtype, newtab.accountid, newtab.Removable, newtab.GroupCode));
                }
                else {
                  this.tabs[selectedTab].title = newtab.text;
                  this.tabs[selectedTab].tabData = tabData;
                  this.tabs[selectedTab].cardType = newtab.cardtype;
                  this.tabs[selectedTab].accountid = newtab.accountid;
                  this.tabs[selectedTab].Removable = newtab.Removable;
                  this.tabs[selectedTab].GroupCode = newtab.GroupCode;
                  this.makeTabActiveByCardType(newtab.cardtype);
                }
                this.screeningTypeCasetypeFilter();
              }, error => {
                sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                console.error(error);
              });
            }, error => {
              sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
              console.error(error);
            });
          }
          else {
            //All MolecularCasetype
            sessionStorage.setItem('deploymentKey', 'configdb');
            if (sessionStorage.getItem('deploymentKey') === 'configdb') {
              let queryVariable = {
                RoleName: sessionStorage.getItem('RoleName'),
                CardType: 'MolecularCasetype'
              };
              let query = this.vitalHttpServices.GetconfigQuery();
              let queryResult = this.commonService.GetCardRequest(queryVariable, query);
              this.vitalHttpServices.GetData(queryResult).subscribe((data) => {
                let newQuery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "getVitalAdminMenus")[0].Query;
                let newQueryVariable = {
                  CardName: 'MolecularCasetype'
                };
                let newQueryResult = this.commonService.GetCardRequest(newQueryVariable, newQuery)
                this.vitalHttpServices.GetData(newQueryResult).subscribe((newResData) => {
                  sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                  let roledata = JSON.parse(data.data.submenuData[0].Permission);
                  this.rolemenudata = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
                  this.actionbuttondata = roledata.ActionButton;
                  this.roleMenuPermissions = roledata.MenuItems;
                  if (finalizeAfterTC) {
                    this.vitalmenu.changeMenuJson(this.rolemenudata, "Masters", "Diagnosis Templates");
                  }
                  delete mainCard.FinalizeAfterTC

                  if (isCompendium) {
                    this.vitalmenu.changeMenuJson(this.rolemenudata, "Masters", "Adequacy Codes")
                  }
                  else {
                    this.vitalmenu.changeMenuJson(this.rolemenudata, "Settings", "Requisition Profiles");

                  }

                  if (sessionStorage.getItem('search_context') != 'lab') {
                    this.vitalmenu.changeMenuJson(this.rolemenudata, "Settings", "Requisition Profiles");
                  }

                  this.vitalHttpServices.CasemenuAction = roledata.MenuItems;
                  this.checkMenuConditions(newtab.cardtype.toLowerCase());
                  tabData = {
                    notinitial: true,
                    mainCard: mainCard,
                    menuJson: menuJson,
                    labels: labels,
                    accountid: newtab.accountid,
                    cardtype: newtab.cardtype,
                    secondarykeys: secondarykeys,
                    rolepermission: this.actionbuttondata,
                    rolemenu: this.rolemenudata,
                    roleMenuPermissions: this.roleMenuPermissions,
                    GroupCode: newtab.GroupCode
                  };
                  selectedTab = this.tabs.findIndex((tab) => tab.cardType.trim().toLocaleLowerCase() === newtab.cardtype.trim().toLocaleLowerCase());
                  if (selectedTab === -1) {
                    this.addTab(new VitalAdminTab(newtab.text, tabData, newtab.cardtype, newtab.accountid, newtab.Removable, newtab.GroupCode));
                  } else {
                    this.tabs[selectedTab].title = newtab.text;
                    this.tabs[selectedTab].tabData = tabData;
                    this.tabs[selectedTab].cardType = newtab.cardtype;
                    this.tabs[selectedTab].accountid = newtab.accountid;
                    this.tabs[selectedTab].Removable = newtab.Removable;
                    this.tabs[selectedTab].GroupCode = newtab.GroupCode;
                    this.makeTabActiveByCardType(newtab.cardtype);
                  }
                  this.screeningTypeCasetypeFilter();
                }, error => {
                  console.error(error);
                  sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                });
              }, error => {
                sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
                console.error(error)
              });
            } else {
              tabData = {
                notinitial: true,
                mainCard: mainCard,
                menuJson: menuJson,
                labels: labels,
                accountid: newtab.accountid,
                cardtype: newtab.cardtype,
                secondarykeys: secondarykeys,
                rolepermission: newtab.rolepermission,
                rolemenu: newtab.rolemenu,
                roleMenuPermissions: newtab.roleMenuPermissions,
                GroupCode: newtab.GroupCode
              };
              selectedTab = this.tabs.findIndex((tab) => tab.cardType.trim().toLocaleLowerCase() === newtab.cardtype.trim().toLocaleLowerCase());
              if (selectedTab === -1) {
                this.addTab(new VitalAdminTab(newtab.text, tabData, newtab.cardtype, newtab.accountid, newtab.Removable, newtab.GroupCode));
              } else {
                this.tabs[selectedTab].title = newtab.text;
                this.tabs[selectedTab].tabData = tabData;
                this.tabs[selectedTab].cardType = newtab.cardtype;
                this.tabs[selectedTab].accountid = newtab.accountid;
                this.tabs[selectedTab].Removable = newtab.Removable;
                this.tabs[selectedTab].GroupCode = newtab.GroupCode;
                this.makeTabActiveByCardType(newtab.cardtype);
              }
            }
          }

        }
        // });
      } else {
        this.ngxService.stop();
        this._snackbar.open(
          'An error occurred while processing your request',
          'Failed'
        );
      }
    }, (error) => {
      this.ngxService.stop();
      this._snackbar.open(
        'An error occurred while processing your request',
        'Failed'
      );
      console.error(error);
    }
    );

  }

  screeningTypeCasetypeFilter() {
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'CaseType',
      context: 'ScreeningType',
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      if (!Resdata.errors) {
        if (Resdata.data.submenuData.length > 0) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          let caseTypeList = [];
          for (let i = 0; i < data.CaseType.length; i++) {
            for (let [key, value] of Object.entries(data.CaseType[i])) {
              caseTypeList.push(value);
            }
          }
          if (caseTypeList.length > 0) {
            if (!caseTypeList.some(va => va.toString().toLowerCase() == this.tabs[1].cardType.toString().toLowerCase())) {
              this.vitalmenu.changeMenuJson(this.rolemenudata, "Masters", "Screening Type")
            }
          }
        }
      }
    })
  }

  getsubmenudata(CardType) {
    sessionStorage.setItem('deploymentKey', 'configdb');
    let queryVariable = {
      RoleName: sessionStorage.getItem('RoleName'),
      CardType: CardType.toString(),
    };
    let query = this.vitalHttpServices.GetconfigQuery();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe((data) => {
      let newQuery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "getVitalAdminMenus")[0].Query;
      let newQueryVariable = {
        CardName: CardType.toString()
      };
      let newQueryResult = this.commonService.GetCardRequest(newQueryVariable, newQuery)
      this.vitalHttpServices.GetData(newQueryResult).subscribe((newResData) => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        let roledata = JSON.parse(data.data.submenuData[0].PermissionJson);
        this.rolemenudata = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
        this.actionbuttondata = roledata.ActionButton;
        this.roleMenuPermissions = roledata.MenuItems;

      }, error => {
        console.error(error)
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      });
    }, error => {
      console.error(error);
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    });
  }
  deleteRuleoutsMenu(htext, URL) {
    let index2 = [];
    this.rolemenudata.find((e, index) => {
      if (e.Htext.toLowerCase() == htext.Htext.toLowerCase()) {
        e.SubMenu.forEach((ele, i) => {
          if (ele.URL.toLowerCase() == URL.toLowerCase()) {
            index2.push([index, i]);
            // delete e.SubMenu[i]
          }
        });
      }
    });

    if (index2.length)
      index2.forEach(val => {
        this.rolemenudata[val[0]].SubMenu.splice(val[1], 1);
        if(!this.rolemenudata[val[0]].SubMenu.length){
          this.rolemenudata.splice(val[0],1);
        }
      });

  }

  checkMenuConditions(casetype) {
    let entityArray = [];
    let casetypeArray = [];
    for (let i = 0; i < this.rolemenudata.length; i++) {
      for (let j = 0; j < this.rolemenudata[i]?.SubMenu.length; j++) {
        if (this.rolemenudata[i].SubMenu[j]['AllowedEntities']) {
          entityArray = this.rolemenudata[i].SubMenu[j]['AllowedEntities'];
        }
        if (this.rolemenudata[i].SubMenu[j]['AllowedCasetypes'] && this.rolemenudata[i].SubMenu[j]['AllowedCasetypes'].length > 0) {
          casetypeArray = this.rolemenudata[i].SubMenu[j]['AllowedCasetypes']
        }
        if (this.rolemenudata[i].SubMenu[j]['URL']) {
          switch (this.rolemenudata[i].SubMenu[j]['URL'].toLowerCase()) {
            case 'rule outs':
              if (entityArray && entityArray.length > 0)
                entityArray.forEach(element => {
                  if (casetypeArray && casetypeArray.length > 0) {
                    let found: any = casetypeArray.find(e => casetype == e.toLowerCase());
                    found = found != undefined && found != null && found != "" ? true : false
                    if (sessionStorage.getItem('search_context').toLowerCase() != element.toLowerCase() ||
                      (sessionStorage.getItem('search_context').toLowerCase() == element.toLowerCase() &&
                        !found)) {
                      this.deleteRuleoutsMenu(this.rolemenudata[i], this.rolemenudata[i].SubMenu[j]['URL']);
                    }
                  }
                });
              break;
            case 'correlation templates':
            case 'tubetype templates':
            case 'nomenclature templates':
            case 'diagnosis references':
            case 'medications':
            case 'observation & quantitation templates':
              if (casetypeArray && casetypeArray.length > 0) {
                let found: any = casetypeArray.find(e => casetype.toLowerCase() == e.toLowerCase());
                found = found != undefined && found != null && found != "" ? true : false
                if (!found) {
                  this.deleteRuleoutsMenu(this.rolemenudata[i], this.rolemenudata[i].SubMenu[j]['URL']);
                  j--;
                }
              }
              break;
              // case 'clinical information':
              // if (casetypeArray && casetypeArray.length > 0) {
              //   let found: any = casetypeArray.find(e => casetype.toLowerCase() == e.toLowerCase());
              //   found = found != undefined && found != null && found != "" ? true : false
              //   if (found) {
              //     this.deleteRuleoutsMenu(this.rolemenudata[i], this.rolemenudata[i].SubMenu[j]['URL']);
              //     j--;
              //   }
              // }
              // break;
              // if (casetypeArray && casetypeArray.length > 0)
              //   casetypeArray.forEach(element => {
              //     if (casetypeArray && casetypeArray.length > 0) {
              //       let found: any = casetypeArray.find(e => casetype == e.toLowerCase());
              //       found = found != undefined && found != null && found != "" ? true : false
              //       if (!found) {
              //         this.deleteRuleoutsMenu(this.rolemenudata[i], this.rolemenudata[i].SubMenu[j]['URL']);
              //       }
              //     }
              //   });
              // break;
              // if (casetypeArray && casetypeArray.length > 0)
              //   casetypeArray.forEach(element => {
              //     if (casetypeArray && casetypeArray.length > 0) {
              //       let found: any = casetypeArray.find(e => casetype == e.toLowerCase());
              //       found = found != undefined && found != null && found != "" ? true : false
              //       if (!found) {
              //         this.deleteRuleoutsMenu(this.rolemenudata[i], this.rolemenudata[i].SubMenu[j]['URL']);
              //       }
              //     }
              //   });
              // break;
          }
        }
      }
    }
  }

}
