<div *ngIf="showGrid">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div>Grossing Attributes</div>
            </strong>
        </h3>

        <div *ngIf="commonService.showFlagIcon" class="ml-2">
            <span class="cpy-drg-Icon-globe" (click)="showMenuRelatedFlags()">
                <img src="/assets/images/FlagIconOnHover.svg" matTooltip="Flags" class="globe_white" />
            </span>
        </div>
    </div>
    <div class="col-sm-12 pl-0 gross-grid">
        <!-- <div class="row">
            <div class="col-sm-8">
                <span>
                    {{selectedAttributes}}
                </span>
            </div> -->
        <div class="row">
            <div class="col-sm-4 mt-2">

            </div>
            <div class="col-sm-4">

            </div>
            <mat-form-field class="col-sm-4 mt-2 pr-0 input-hight gross-attr" appearance="outline">
                <mat-label>Attribute Type</mat-label>
                <mat-select disableOptionCentering [formControl]="attributeType">
                    <mat-option (click)="getGrossingAttribute('All')" [value]="'All'">
                        All
                    </mat-option>
                    <mat-option *ngFor="let types of AttributeTypes" (click)="getGrossingAttribute(types.Item)"
                        [value]="types.Item">
                        {{types.Item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-4 row mt-2">
                <div class="col-sm-45 text-xs">
                    <label class="label-font">Attribute<span class="error-msg">*</span></label>
                  </div>
                  <div class="col-sm-8 text-xs">
                    <input  [formControl]="attributeType" maxlength="100" type="text"
                      class="form-control form-font" />
                  </div>
            </div> -->
        <div class="col-sm-12 row p-0 mr-0">
            <div class="col-sm-6">
                <span> <strong>Showing grossing attributes for attribute type: "{{attrtype}}" </strong></span>
            </div>
            <div class="col-sm-6 text-xs font-weight-bold text-grid">
                <img class="mr-1 mb-1" alt="help" src="../../../assets/icons/help.svg" width="15px" height="auto">
                <span> You can select the required attribute type from the dropdown.</span>
            </div>
        </div>
        <!-- </div> -->
    </div>
    <div *ngIf="templateData.submenuData && templateData.submenuData.length > 0" class="col-sm-12 p-0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" class="mt-2"
            [headersVisibility]="'Column'" (initialized)="initGrid(grid)" #grid [frozenColumns]="1">
            <wj-flex-grid-column [header]="'Action'" align="center" [width]="'*'" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button *ngIf="showEditIconInGrid(row)" class="edit-delete-btn"><em id="editButton"
                            [title]="'Update'" (click)="updateGrossAttributes(row)"
                            class="edit-template p-0 fa fa-edit" [disabled]="hideEditBtn"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <!-- <wj-flex-grid-column [binding]="columnname" [allowDragging]="false"
                    [visible]="!(columnname == 'Attribute_ID')" [header]="columnname.split('_').join(' ')" [width]="'*'"
                    [allowResizing]="true" [format]="'d'">
                </wj-flex-grid-column> -->
            <wj-flex-grid-column *ngIf="attrtype=='All'" [header]="'Type'" [width]="'*'" [binding]="'Attribute_Type'"
                [isReadOnly]="true">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Name'" [width]="'*'" [binding]="'Attribute_Name'" [isReadOnly]="true" >
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Sequence'" [width]="'*'" [binding]="'Sequence_Order'" [isReadOnly]="true" [format]="'d'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Status'" [width]="'*'" [binding]="'Status'" [isReadOnly]="true">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Configuration Level'" [width]="'*'" [binding]="'Attribute_Level'"
                [isReadOnly]="true">
            </wj-flex-grid-column>


            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="page-align">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <span class="row align-center">
            <div class="col-sm-12 button-align mt-3">
                <div class="p-0">
                    <button mat-raised-button class="admin-btn-success mr-2" (click)="exportGrossAttributesData(grid)"
                        [disabled]="hideExportBtn">
                        Export
                    </button>
                    <button mat-raised-button class="admin-btn-success mr-2" (click)="createGrossAttribute()"
                        [disabled]="hideCreateBtn">
                        Create
                    </button>
                    <button mat-raised-button class="admin-btn-success mr-2" (click)="uploadGrossAttrExcelData()"
                        [disabled]="hideUploadBtn">
                        Upload
                    </button>
                    <button mat-raised-button class="admin-btn-success mr-2" (click)="copyFromOrg()"
                        title="Copy from other entity" [disabled]="hideCopyBtn">
                        Copy
                    </button>
                    <button mat-raised-button class="admin-btn-success mr-2" *ngIf="showModal"
                        (click)="getGLDataPopup()" [disabled]="hideCopyBtn" title="Migrate default to group level">
                        Migrate
                    </button>
                </div>

                <!-- <div class="col-sm-5 p-0">
                    <h3 class="modal-title w-100 admin-model-header-txt main-title">
                        <strong class="configtext" *ngIf="showModal" (click)="getGLDataPopup()" data-toggle="modal"
                            data-target="#configmodal">Migrate default to group level</strong>
                    </h3>
                </div> -->

            </div>
        </span>
    </div>

    <div *ngIf="!templateData.submenuData || templateData.submenuData.length <= 0" class="col-sm-12 mt-4">
        <div class="nodata-wrap mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>

        <span class="col-sm-12 align-center mt-3">
            <div class="col-sm-4 button-align">
                <button mat-raised-button class="admin-btn-success" (click)="createGrossAttribute()"
                    [disabled]="hideCreateBtn || noConfiguration">
                    Create
                </button>
                <button mat-raised-button class="admin-btn-success" (click)="uploadGrossAttrExcelData()"
                    [disabled]="hideUploadBtn || noConfiguration">
                    Upload
                </button>
                <button mat-raised-button class="admin-btn-success" (click)="copyFromOrg()"
                    title="Copy from other entity" [disabled]="hideCopyBtn || noConfiguration">
                    Copy
                </button>
            </div>
        </span>

    </div>

    <!-- <div *ngIf="templateData.submenuData && templateData.submenuData.length > 0" class="sub-button mt-2">

    </div> -->
</div>


<div *ngIf="uploadClicked" class="my-gross-form">
    <div class="modal-header admin-model-header   mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>{{uploadHeader}}</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate(true)">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Grossing Attributes.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-3" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
      <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
          [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
            <!-- Status -->
            <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload" [allowResizing]="true"
                [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.result=='Success' ?'green':cell.item.result=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.result}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Notes  -->
            <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="300"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color:  cell.item.notes !='Mandatory field(s) missing!' && cell.item.notes !='Null values exist!' ? cell.item.notes=='Created Successfully'||cell.item.notes=='Valid'?'green':'#ff9800' : 'red'}">{{cell.item.notes}}
                    </span>
                </ng-template>
            </wj-flex-grid-column> -->

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="'*'"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <!-- <div > -->
                <wj-flex-grid-column *ngFor="let columnname of sheetHeader" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]="!(columnname == 'Result')" [allowResizing]="true"
                    [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            <!-- </div>  -->
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            <!-- <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator> -->
        </wj-flex-grid>
        <div class="page-align">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid"
                headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu class="ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>

        <div class="row pl-3">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-wrap p-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <!-- -->
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        [disabled]="disableApprovebtn()" (click)="uploadGrossAttributesData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download Result</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>

<div *ngIf="addEditScreen">
    <form [formGroup]="grossAttributeForm" class="Gross-Form">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>{{action}}</strong>
                </h3>
            </div>
            <div class="text-wrap row col-sm-12 p-0 ml-0 pb-4">
                <div class="col-sm-12 mt-4 d-flex attr-align">
                    <mat-form-field class="width col-sm-3" appearance="outline">
                        <mat-label>
                            <strong>
                                Type</strong>
                        </mat-label>
                        <mat-error>{{getErrorMessage(grossAttributeForm.value.frmAttrType,'')}}</mat-error>
                        <!-- <input type="text" aria-label="Assignee" maxlength="100" matInput formControlName="frmAttrType"
                            [matAutocomplete]="auto" [errorStateMatcher]="matcher" disableOptionCentering>
                        <em class="fa fa-chevron-down chevron-align mb-2"></em>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let panel of filterPanels(grossAttributeForm.value.frmAttrType)"
                                [value]="panel.Item">
                                <span>{{ panel.Item }}</span>
                            </mat-option>
                        </mat-autocomplete> -->
                        <mat-select disableOptionCentering class="" formControlName='frmAttrType'>
                            <!-- <mat-option selected>{{destDeployment}}</mat-option> -->
                            <mat-option *ngFor="let i of this.AttributeTypes" [value]="i.Item"
                                (onSelectionChange)="onChangeAttrType($event)">
                                {{ i.Item }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 col-sm-3">
                        <mat-label>
                            <strong> Name </strong>
                        </mat-label>
                        <mat-error>{{getErrorMessage(grossAttributeForm.value.frmAttrName,'')}}</mat-error>
                        <input type="text" matInput maxlength="2000" formControlName="frmAttrName"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                    <mat-form-field class="w-100 col-sm-3" appearance="outline">
                        <mat-label>
                            <strong>
                                Sequence</strong>
                        </mat-label>
                        <input type="text" matInput maxlength="5"
                            [readonly]="!action.includes('Edit') && readonlyTestSequence"
                            [(ngModel)]="grossAttributeForm.value.frmSeqOrder" formControlName="frmSeqOrder"
                            pattern="[0-9]+" (keypress)="allowOnlyNumber($event)" [errorStateMatcher]="matcher">
                        <!-- <mat-error>{{getErrorMessage(grossAttributeForm.value.frmSeqOrder,'sequence')}}
                        </mat-error> -->
                    </mat-form-field>
                    <span class="col-sm-3">
                        <mat-checkbox [color]="task.color" class="mt-2 " formControlName="frmIsActive">Active
                        </mat-checkbox>
                    </span>
                </div>
                <p *ngIf="globalIndicater">
                    <img class="mr-1 mb-1" alt="help" src="../../../assets/icons/help.svg" width="15px" height="auto">
                    <strong>All the attributes at "Global" level will be copied to "All locations".</strong>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 mt-4">
                <button mat-raised-button class="admin-btn-success" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap mt-4">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="saveGrossAttributes('',grossAttributeForm.value)">
                    Save & Close
                </button>
                <button mat-raised-button *ngIf="action == 'Create Grossing Attribute'" class="admin-btn-success"
                    (click)="saveGrossAttributes('new',grossAttributeForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="copyfromScreen">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Copy from Other Entity</strong>
        </h3>
    </div>
    <form [formGroup]="copyFromOrgFrm" class="copyFromOrg">
        <div class="row col-sm-12 p-0 ml-0">
            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                <mat-select disableOptionCentering type="text" matInput formControlName="frmDeplyment">
                    <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Group <span class="error-msg">*</span> </mat-label>
                <em class="fa fa-chevron-down chevron-align" (click)="fetchOrgSeries(searchbar.value)"></em>
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                    (click)="fetchOrgSeries(searchbar.value)"
                    (keyup)="fetchOrgSeries(searchbar.value); trimFieldValue('frmOrganization')" title="{{copyFromOrgFrm.value.frmOrganization}}"
                    [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align" title="{{ show.orgname }} ({{show.orgid}})"
                            (onSelectionChange)="selectedOrganization(show.orgname,show.orgid)"
                            value="{{ show.orgname }}">
                            <span>{{ show.orgname }} ({{show.orgid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <div class="col-sm-4 mb-3 flex-right">
                <button mat-raised-button class="admin-btn-success mt-1 get-height" [disabled]="postUpload"
                    (click)="getDataToCopy(-1, userid, dataToTakeAsInput)">Get Global Data</button>
            </div>

            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-2">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
                    (initialized)="initializeGrid(flexgrid,1)" #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload"
                        [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.result=='Success' ?'green':cell.item.result=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.result}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                        [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.notes !='Mandatory field(s) missing!' && cell.item.notes !='Null values exist!' ? cell.item.notes=='Created Successfully'||cell.item.notes=='Valid'?'green':'#ff9800' : 'red'}">{{cell.item.notes}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'Result')" [allowResizing]="true"
                            [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <section *ngIf="!postUpload" class="example-section align-center row">
                    <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0"
                        ng-init='checkStatus=false' [checked]='checkStatus' ng-model='checkStatus'
                        (change)='checkValue($event)' class="border-style">
                    <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
                </section>
                <div class="col-sm-12 pl-0 mt-4">
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                        (click)="refreshGrid('list')">Back</button>
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid()">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            [disabled]="disableApprovebtn()"
                            (click)="uploadGrossAttributesData('copy')">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download Result</button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="gridWidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">Back</button>
            </div>
        </div>
    </form>
</div>
<!-- warning popup -->
<div *ngIf="invalidAttributeTypeFlag" class="col-sm-12">
  <div class="modal overlay" id="myModal1" role="dialog">
      <div class="modal-dialog" id="modaldialog1">
          <div class="modal-content">
              <div class="modal-header warning-model-header">
                  <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                  <span type="button" id="button_size" data-dismiss="modal" (click)="closeAttributetypeModel()"
                      class="mr-2">
                      <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                          class="material-icons md-24 cursor" title="Close">
                  </span>
              </div>
              <div class="modal-body p-2 msg-wrap">
                  <div class="row col-sm-12 body-message">
                      <p> The Grossing Attribute
                          cannot be copied because {{caseType}} is not configured<br>Failed for Attributes :
                          {{repeatedNames}}</p>
                  </div>
              </div>
              <div class="modal-footer">
                  <button mat-raised-button class="admin-btn-success" (click)="closeAttributetypeModel()"
                      type="button">OK</button>
              </div>
          </div>
      </div>
  </div>
</div>
