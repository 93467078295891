import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';

@Component({
  selector: 'app-menu-flags-configuration',
  templateUrl: './menu-flags-configuration.component.html',
  styleUrls: ['./menu-flags-configuration.component.scss']
})
export class MenuFlagsConfigurationComponent implements OnInit {
  flagDetails: any;
  flags: any = [];
  dbName: string;
  wronglyConfiguredFlags: any;

  constructor(public dialogRef: MatDialogRef<MenuFlagsConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public commonService: CommonService, public vitalHttpServices: VitalHttpServices,
    private _fb: FormBuilder, private ngxService: NgxUiLoaderService, private _snackbar: MatSnackBar,) {

  }
  async ngOnInit() {
    this.flagDetails = this.commonService.flagsdetails;
    this.dbName= sessionStorage.getItem("deploymentKey");
    await this.getFlagDetails();
  }

  async getFlagDetails() {
    try {
      this.ngxService.start();
      const contents = [];
  
      // Fetch details sequentially
      for (const element of this.flagDetails) {
        const obj = {
          entityid: element.TargetTable?.toLowerCase() !== 'organizations'? sessionStorage.getItem("AccountID") : sessionStorage.getItem('org_id'),
          name: element.FlagName,
          ConfigName: element.FlagName,
          scope: 3,
          entytitype: element.TargetTable?.toLowerCase() !== 'organizations' ? 3 : 2,
          tableName: element.TargetTable,
          casetype: element.TargetTable?.toLowerCase() == 'accountcasetypes' ? this.data.templateData?.secondarykeys?.casetype : ''
        };
  
        try {
          const res = await this.vitalHttpServices.getVAEntityAttribute(obj, this.dbName).toPromise();
          contents.push(JSON.parse(res.Message));
        } catch (error) {
          console.error(`Error fetching details for ${element.FlagName}:`, error);
          continue;
        }
      }
      const excludedFlagsSet = new Set(contents.map(item => item[0]?.refconfigname?.trim()));
      this.wronglyConfiguredFlags = [];
      let CorrectConfiguredFlags = this.flagDetails.filter(item => {
        const isExcluded = excludedFlagsSet.has(item.FlagName);
        if (!isExcluded) {
          this.wronglyConfiguredFlags.push(item); 
        }
        return isExcluded;
      });
      this.flagDetails = CorrectConfiguredFlags.map((item, index) => {
        const content = contents[index];
          const fieldValue = JSON.parse(content[0].validvalues);

          // Helper function to handle 'n/a' values
          const processValue = (value: string) => value?.toString()?.toLowerCase() === 'n/a' ? null : value;
          // Update content fields
          content[0] = {
            ...content[0],
            actualscope: content[0].scope,
            scope: content[0].scope === 'both' ? '3' : content[0].scope,
            All_Locations_Value_1: processValue(content[0].All_Locations_Value_1),
            All_Locations_Value_2: processValue(content[0].All_Locations_Value_2),
            This_Location_Value_1: processValue(content[0].This_Location_Value_1),
            This_Location_Value_2: processValue(content[0].This_Location_Value_2)
          };

          // Process dropdown datasource
          const attributeField0 = fieldValue.AttributeFields[0];
          processAttributeField(attributeField0);

          const attributeField1 = fieldValue.AttributeFields[1];
          if (attributeField1) {
            processAttributeField(attributeField1);
          }

          function processAttributeField(attributeField: any) {
            if (['dropdown', 'strict dropdown'].includes(attributeField.datatype?.toLowerCase())) {
              attributeField.datasource = attributeField.datasource.map(item => {
                const [key, value] = Object.entries(item)[0];
                return {
                  [key]: value,
                  displayname: attributeField.datatype?.toLowerCase() === 'dropdown' ? `${value} (${key})` : value,
                  value: value
                };
              });
            }
          }
          return {
            ...item,
            content: content,
            fieldValue: fieldValue
          };
      });
      this.flagDetails.forEach(element => {
          element.disable = element.RoleName?.includes(sessionStorage.getItem("RoleName")) ? false : true;
          const attributeFields = element.fieldValue?.AttributeFields || [];

          attributeFields.forEach((field, index) => {
            if (field.datatype === 'toggle') {
              const fieldMappings = {
                0: ['This_Location_Value_1', 'All_Locations_Value_1'],
                1: ['This_Location_Value_2', 'All_Locations_Value_2']
              };

              const fieldsToUpdate = fieldMappings[index] || [];
              fieldsToUpdate.forEach(fieldName => this.onSliderChange(fieldName, element.FlagName));
            }
          });
      });
      this.flags = structuredClone(this.flagDetails);
      this.ngxService.stop();

    } catch (error) {
      this.ngxService.stop();
      console.error('Error fetching flag details:', error);
    }
  }
  checkDisable(column, item) {
    if (item.disable) {
      return true;
    }
    else {
      let scope = item?.fieldValue?.AttributeFields[0]?.scope;
      const alllocationfinalstate = item?.fieldValue?.AttributeFields[0]?.alllocationfinalstate;
      const thisLocationFinalState = item?.fieldValue?.AttributeFields[0]?.thislocationfinalstate;
      const targetval2 = this.flagDetails?.find(va => va.FlagName == item.FlagName)?.content[0][column];
      if (scope == '2') {
        return alllocationfinalstate === targetval2;
      }
      else if (scope == '3') {
        return thisLocationFinalState === targetval2;
      }
      else if (scope == 'both') {
         let val = column == 'This_Location_Value_1' ? thisLocationFinalState === targetval2 : alllocationfinalstate === targetval2
         return val;
      }
    }
  }

  async saveFlags() {
    for (const element of this.flags) {
      const { content, TargetTable, fieldValue } = element;
      const [content0] = structuredClone(content);
      let dataTypes = JSON.parse(content[0]?.validvalues);
      let attr1DataType = dataTypes.AttributeFields[0].datatype;
      let attr2DataType = dataTypes.AttributeFields[1]?.datatype;

      ['All_Locations_Value_1', 'All_Locations_Value_2', 'This_Location_Value_1', 'This_Location_Value_2'].forEach(key => {
        content0[key] = content0[key]?.toString();
      });

      let configValue = (locationValue1: string, locationValue2: string, dataType: string) => {
        let configValue = content0.scope == 2 ? locationValue1 : locationValue2;
        return dataType === 'free text' || configValue === 'N/A' ? configValue : (configValue.match(/\(([^)]+)\)/)?.[1] || configValue);
      };

      let config1 = configValue(content0.All_Locations_Value_1 ?? 'N/A', content0.This_Location_Value_1 ?? 'N/A', attr1DataType);
      let config2 = configValue(content0.All_Locations_Value_2 ?? 'N/A', content0.This_Location_Value_2 ?? 'N/A', attr2DataType);

      // Function to find the key for a given value in the datasource
      let findKeyForValue = (datasource: any[], targetValue: string) => {
        for (let entry of datasource) {
          for (let [key, value] of Object.entries(entry)) {
            if (value?.toString()?.toLowerCase() === targetValue?.toString()?.toLowerCase()) {
              return key;
            }
          }
        }
        return targetValue; // Return the original value if no key is found
      };

      config1 = findKeyForValue(fieldValue.AttributeFields[0].datasource, config1);
      if (config1 == 'N/A' && attr1DataType == 'toggle') config1 = "false"
      if (config2 == 'N/A' && attr2DataType == 'toggle') config2 = "false"
      if (!config2 || (config2 && config2 == 'N/A')) config2 = 'Not Set';

      if (fieldValue.AttributeFields.length > 1) {
        config2 = findKeyForValue(fieldValue.AttributeFields[1].datasource, config2);
      }
      const attributes = fieldValue.AttributeFields;

      for (let i = 0; i < attributes.length && i < 2; i++) {
        const regex = attributes[i]?.regex;
        if (regex) {
          const reg = new RegExp(regex);
          const config = i === 0 ? config1 : config2;
          if (!reg.test(config.toUpperCase())) {
            this._snackbar.open('Please enter valid input!', 'Close');
            this.ngxService.stop();
            return;
          }
        }
      }
      const obj = {
        ExtAttrValue1: config1,
        ExtAttrValue2: config2,
        configid: content0.id,
        entityid: content0.scope == 3 ? sessionStorage.getItem('AccountID') : sessionStorage.getItem('org_id'),
        entytitype: content0.scope,
        description: content0.Description,
        AttributeName: content0.refconfigname,
        id: content0.scope == 3 ? content0.AccountVeID ? content0.AccountVeID : -1 : content0.OrgVeId ? content0.OrgVeId : -1,
        tableName: TargetTable,
        casetype: TargetTable?.toLowerCase() == 'accountcasetypes' ? this.data.templateData?.secondarykeys?.casetype : ''
      };

      if (obj.id != -1 || obj.tableName.toLowerCase() == 'accountcasetypes' || obj.tableName.toLowerCase() == 'accounts' || obj.tableName.toLowerCase() == 'organizations') {
        await this.vitalHttpServices.UpdateVAEntityAttribute(obj, this.dbName).toPromise();
      } else {
        await this.vitalHttpServices.createVAEntityAttribute(obj, this.dbName).toPromise();
      }
    }
    this._snackbar.open("Configuration Successfull", "Close");
    this.configureActivityTracker();
    this.dialogRef.close('true');
  }
  
  cancelFlags() {
    this.dialogRef.close('true');
  }
  filterAutomCompleteOptions(selectedValue: string, data: Array<{ [key: string]: string }>) {
    // Early return if selectedValue is empty or falsy
    if (!selectedValue) {
      return data;
    }
  
    const lowercasedSelectedValue = selectedValue.toLowerCase();
  
    // Filter data based on key or value starting with selectedValue
    return data.filter(item => 
      Object.entries(item).some(([key, value]) => 
        key.toLowerCase().startsWith(lowercasedSelectedValue) ||
        value.toLowerCase().startsWith(lowercasedSelectedValue)
      )
    );
  }
  
  changeScope(scope, flagname) {
    this.ngxService.start()
    const flag = this.flags.find(va => va.FlagName === flagname);
    let assigningFlag = this.flagDetails.find(va => va.FlagName === flagname)
    if (flag) {
      flag.content[0].scope = scope;
      flag.content[0].All_Locations_Value_1 = assigningFlag.content[0].All_Locations_Value_1
      flag.content[0].This_Location_Value_1 = assigningFlag.content[0].This_Location_Value_1
      flag.content[0].All_Locations_Value_2 = assigningFlag.content[0].All_Locations_Value_2
      flag.content[0].This_Location_Value_2 = assigningFlag.content[0].This_Location_Value_2
    }
    const attributeFields = flag.fieldValue?.AttributeFields || [];

    attributeFields.forEach((field, index) => {
      if (field.datatype === 'toggle') {
        const fieldMappings = {
          0: ['This_Location_Value_1', 'All_Locations_Value_1'],
          1: ['This_Location_Value_2', 'All_Locations_Value_2']
        };

        const fieldsToUpdate = fieldMappings[index] || [];
        fieldsToUpdate.forEach(fieldName => this.onSliderChange(fieldName, flag.FlagName));
      }
    })
    this.ngxService.stop();
  }  
  onSliderChange(columnName, flagName) { // on badge change value should be as 0 or 1
    const flag = this.flagDetails.find(va => va.FlagName === flagName);
    if (flag) {
      flag.content[0][columnName] =  flag.content[0][columnName] == false || flag?.content[0][columnName]?.toString()?.toLowerCase() == 'false'|| flag.content[0][columnName] == 0 ? false : true;
    }
  }
  configureActivityTracker() {
    let differences = [];
  
    this.flagDetails.forEach(element => {
      const matchingFlag = this.flags.find(data => data.FlagName === element.FlagName);
  
      if (matchingFlag) {
        let difference: any = { FlagName: element.FlagName };
  
        // Compare values and add differences to the `difference` object
        if (matchingFlag.content[0].This_Location_Value_1?.toString()?.toLowerCase() != element.content[0].This_Location_Value_1?.toString()?.toLowerCase()) {
          difference.This_Location_Value_1 = matchingFlag.content[0].This_Location_Value_1?.toString()?.toLowerCase();
          difference.This_Location_Value_1_oldValue = element.content[0].This_Location_Value_1?.toString()?.toLowerCase();
        }
        if (matchingFlag.content[0].All_Locations_Value_1?.toString()?.toLowerCase() != element.content[0].All_Locations_Value_1?.toString()?.toLowerCase()) {
          difference.All_Locations_Value_1 = matchingFlag.content[0].All_Locations_Value_1?.toString()?.toLowerCase();
          difference.All_Locations_Value_1_oldValue = element.content[0].All_Locations_Value_1?.toString()?.toLowerCase();
        }
        if (matchingFlag.content[0].This_Location_Value_2?.toString()?.toLowerCase() != element.content[0].This_Location_Value_2?.toString()?.toLowerCase()) {
          difference.This_Location_Value_2 = matchingFlag.content[0].This_Location_Value_2?.toString()?.toLowerCase();
          difference.This_Location_Value_2_oldValue = element.content[0].This_Location_Value_2?.toString()?.toLowerCase();
        }
        if (matchingFlag.content[0].All_Locations_Value_2?.toString()?.toLowerCase() != element.content[0].All_Locations_Value_2?.toString()?.toLowerCase()) {
          difference.All_Locations_Value_2 = matchingFlag.content[0].All_Locations_Value_2?.toString()?.toLowerCase();
          difference.All_Locations_Value_2_oldValue = element.content[0].All_Locations_Value_2?.toString()?.toLowerCase();
        }
  
        // Add the `difference` object to the `differences` array if there are any differences
        if (Object.keys(difference).length > 1) {
          differences.push(difference);
        }
      }
    });
    differences = this.transformFlags(differences)
    let teansactionid = this.commonService.generateGuid()
    let activityInfo = `Modified configurations in  ${this.data.header}`
    this.commonService.createActivityObject("", "", this.data.header, "Edit", {}, {}, teansactionid, "", [], differences, activityInfo)

  // createActivityObject(entityid, changedValue, entityType, actionType, newObj, oldobject, transactionId, auditableColumns, uploadedFile?: any,jsonData?:any, activityInfo?:any) {

  }
  transformFlags(flags: any[]): any {
    const result: any = {};
  
    flags.forEach(flag => {
      const flagName = flag.FlagName;
      result[flagName] = [{}]; // Initialize an array with an empty object
  
      Object.keys(flag).forEach(key => {
        if (key.includes("_oldValue")) {
          const baseKey = key.replace("_oldValue", "");
          const extAttrKey = baseKey === "This_Location_Value_1" || baseKey === "All_Locations_Value_1" ? "extattrval" : "extattrval2";
  
          // Add the extattrval or extattrval2 property to the first object in the array
          result[flagName][0][extAttrKey] = {
            oldValue: flag[key] === null ? "null" : flag[key],
            newValue: flag[baseKey]
          };
        }
      });
    });
  
    return result;
  }
  resetFlags(){
    this.ngxService.start()
    this.flags = structuredClone(this.flagDetails);
    this.ngxService.stop()
  }
  checkAnyChangesToFlags(){
    let keysToCompare  = ["All_Locations_Value_1","All_Locations_Value_2","This_Location_Value_1", "This_Location_Value_2"];
    for (let i = 0; i < this.flagDetails.length; i++) {
      const item1 = this.flagDetails[i];
      const item2 = this.flags[i];

      if (item1?.FlagName === item2?.FlagName) {
        const content1 = item1?.content[0];
        const content2 = item2?.content[0];

        for (const key of keysToCompare) {
          const value1 = content1[key];
          const value2 = content2[key];

          if (value1?.toString()?.toLowerCase() !== value2?.toString()?.toLowerCase()) {
            return true; // Return false if any value is different
          }
        }
      }
    }
    return false; // Return true if all specified keys match
  }
  onInputChange(event: Event, item, column): void {
    if (item.fieldValue.AttributeFields[0].datatype?.toLowerCase() === 'number') {
      const inputElement = event.target as HTMLInputElement;
      const filteredValue = inputElement.value.replace(/[eE+\-\.]/g, '');
      const maxLength = item.fieldValue?.AttributeFields[0]?.maxcharactercount;
      // Ensure the filtered value does not exceed 2 characters
      if (filteredValue.length > maxLength) {
        // Trim the value to 2 characters
        const trimmedValue = filteredValue.slice(0, 2);
        inputElement.value = trimmedValue; // Update the input element
        item.content[0][column] = trimmedValue; // Update the data model
      } else {
        // Update the model with the filtered value if valid
        item.content[0][column] = filteredValue;
        inputElement.value = filteredValue; // Update the input element
        item.content[0][column] = filteredValue;
      }
    }
  }

}
