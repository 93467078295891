import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import '@grapecity/wijmo.input';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';


export function noSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Check if the control value contains any spaces
    const hasSpaces = control.value && control.value.includes(' ');
    return hasSpaces ? { 'noSpaces': { value: control.value } } : null;
  };
}
@Component({
  selector: 'app-manage-configurations',
  templateUrl: './manage-configurations.component.html',
  styleUrls: ['./manage-configurations.component.scss']
})

export class ManageConfigurationsComponent implements OnInit, OnDestroy {

  @Input() templateData: any = [];
  gridHeader: string[] = [];
  gridheaderList: any;
  gridData: any;
  headerName: string = 'Manage Configurations';
  context: string;
  rolesDDValues: any = [];
  menuDDValues: any = [];
  scopeDDValues: any = [];
  tableDDValues: any = [];
  categoryDDValues: any = [];
  fieldTypeDDValues: any = [];
  editData: any;
  categoryName: any;
  featureNames: any;
  fieldIndex: any[] = [1];
  ListedGridHeader: any;
  listHeaderbackup: any;
  configdata: any;
  saveOrEditCompleted: any;
  refconfigdefaultvalue: Object = {
    "ExtAttrValue1": [
      {
        "Default": "Not set"
      }
    ],
    "ExtAttrValue2": [
      {
        "Default": "Not set"
      }
    ]
  };
  DeploymentList: any = [];

  // Boolean Types
  listPage: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean;
  createOrEditClicked: boolean = false;
  roleValuesValid: boolean = true;
  menuValuesValid: boolean = true;
  disableFieldsOnEdit: boolean = false;
  resetEnabled: boolean = false;
  DeploymentListValid: boolean = true;
  gridLoaded: boolean = false;

  // Selection Models
  DeploymentListSelectionModel = new SelectionModel<any>(true, []);
  RolesSelectionModel = new SelectionModel<any>(true, []);
  MenuSelectionModel = new SelectionModel<any>(true, []);
  RolesSelectionModelUnSubscribe: any;
  DeploymentSelectionModelUnSubscribe: any;
  MenuSelectionModelUnSubscribe: any;

  // Class Type and Custom Objects
  SubMenuCardModel: SubMenuCardModel;
  configurationRoles: string[] = [];
  distinctURLs: any = [];
  keyValueArray: any;

  addFieldDDValues: string[] = ['BK', 'CD-CT', 'BK-BK', 'YD-12:00 AM', 'CD-12:00 AM', 'BK-12:00 AM'];

  arrowIconSubject1 = new BehaviorSubject('arrow_drop_down1');
  arrowIconSubject2 = new BehaviorSubject('arrow_drop_down2');
  arrowIconSubject3 = new BehaviorSubject('arrow_drop_down3');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public VitalHttpServices: VitalHttpServices, private _snackbar: MatSnackBar,
    public commonService: CommonService, private templateHeaders: TemplateHeadersService,
    public activityService: ActivityTrackerService, private matdialog: DialogService, private cdr: ChangeDetectorRef
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }
  ngOnDestroy(): void {
    if (this.RolesSelectionModelUnSubscribe)
      this.RolesSelectionModelUnSubscribe?.unsubscribe();

    if (this.MenuSelectionModelUnSubscribe)
      this.MenuSelectionModelUnSubscribe?.unsubscribe();

    if (this.DeploymentSelectionModelUnSubscribe)
      this.DeploymentSelectionModelUnSubscribe?.unsubscribe();
  }

  // forms
  configForm: any = this._fb.group({
    configurationType: { value: 'Flag', disabled: true },
    configurationFlagName: ['', [Validators.required, noSpacesValidator()]],
    configurationDisplayName: ['', Validators.required],
    configurationCategory: ['', Validators.required],
    configurationTable: ['', Validators.required],
    configurationScope: ['', Validators.required],
    configurationDescription: ['', Validators.required],
    configurationInstruction: [''],
    configurationFieldName: ['', Validators.required],
    configurationFlagWaterMark: ['', Validators.required],
    configurationFieldType: ['', Validators.required],
    configurationFieldValue: ['', Validators.required],
    configurationSetFinalState: 0,
    configField: this.createConfigFieldForm()
  });

  ngOnInit(): void {
    this.roleValuesValid = this.menuValuesValid = this.listPage = true;
    this.getConfiguration();    // To get main list data
  }

  getDefinedValuesForCreateAndEditScreen() {
    this.getRoleNames();    // to get Role names for the dropdown
    this.getFeatureNamesList();     // to get all the feature name for the dropdown
    this.DeploymentList = this.VitalHttpServices?.DeploymentKeys;   // to get all the deployment names for the dropdown
    this.multiSelectFormValidations();
  }

  getFeatureNamesList() {
    let Query = this.SubMenuCardModel?.GetQuery("getVitalAdminMenus");
    let QueryResult = this.commonService?.GetCardRequest(null, Query)

    this.VitalHttpServices.GetData(QueryResult, 'configdb').subscribe(data => {
      if (!data?.errors) {
        this.distinctURLs = [
          ...new Set(
            data?.data?.submenuData
              .filter(item => item && item?.JSONMenu) // Filter out null entries
              .filter(item => {
                const cardName = item?.CardName;
                const excludedCards = ['Facility', 'Users', 'Physician', 'Pathologist'];
                return !excludedCards?.includes(cardName);
              })
              .flatMap(item => JSON.parse(item?.JSONMenu)?.MenuItems) // Parse JSONMenu and get MenuItems
              .flatMap(menu => menu?.SubMenu?.map(subItem => subItem?.URL)) // Get URLs from SubMenu
          )
        ];
        let menuValue = structuredClone(this.distinctURLs?.sort());
        this.menuDDValues = menuValue?.map(item => ({ menuName: item }));
      }
    }, error => {
      console.error(error);
    });
  }

  multiSelectFormValidations() {
    this.DeploymentSelectionModelUnSubscribe = this.DeploymentListSelectionModel?.changed?.subscribe((data: any) => {
      this.DeploymentListValid = data?.source?.selected?.length > 0;
    });

    this.RolesSelectionModelUnSubscribe = this.RolesSelectionModel?.changed?.subscribe((data: any) => {
      this.roleValuesValid = data?.source?.selected?.length > 0;
    });

    this.MenuSelectionModelUnSubscribe = this.MenuSelectionModel?.changed?.subscribe((data: any) => {
      this.menuValuesValid = data?.source?.selected?.length > 0;
    });

    this.configForm?.get('configurationRoles')?.valueChanges?.subscribe(value => {
      this.configurationRoles = value;
    });
  }

  createConfigFieldForm(): FormGroup {
    return this._fb.group({
      AttributeFields: this._fb.array([this.createField()])
    });
  }

  getDropDownValues() {
    let configValues = this.configdata;
    // Function to process values: remove duplicates (case-insensitive), preserve original case, sort
    const processValues = (values) => {

      this.tableDDValues = [...new Set(this.configdata?.map(item => item?.refConfigTarget?.toLowerCase()))]
        .sort(); // Using Set to get unique values and sort them

      const seen = new Map();

      values?.forEach(value => {
        if (value) {
          const lowerCaseValue = value?.toString()?.toLowerCase();
          if (!seen?.has(lowerCaseValue)) {
            seen?.set(lowerCaseValue, value);
          }
        }
      });
      // Sort by lowercase representation while preserving original casing
      return [...seen?.values()]?.sort((a, b) => a?.toLowerCase()?.localeCompare(b?.toLowerCase()));
    };

    this.scopeDDValues = ["2", "3", "both"]?.map(value => {
      if (value === "2") return "All Location";
      if (value === "3") return "This Location";
      if (value === "both") return "Both";
      return value;
    });
    this.categoryDDValues = processValues(configValues?.map(item => item?.category));
    this.fieldTypeDDValues = ['Strict Dropdown', 'Toggle', 'Free Text', 'Dropdown']
  }

  validateMultiSelectDropdown(value) {
    switch (value) {
      case 'frmRole': !this.RolesSelectionModel?.selected?.length
        this.resetEnabled = true;
        break;
      case 'frmMenu': !this.MenuSelectionModel?.selected?.length
        this.resetEnabled = true;
        break;
      case 'frmDeployment': !this.DeploymentListSelectionModel?.selected?.length
        this.resetEnabled = true;
        break;
      default:
        break;
    }
  }

  getRoleNames() {
    let queryVariable = {};
    queryVariable = {}
    let query = this.SubMenuCardModel?.GetQuery("GetVitalAdminRolenames");
    let queryResult = this.commonService?.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(data => {
      if (!data?.errors) {
        this.rolesDDValues = data?.data?.submenuData?.map(role => role?.RoleName)?.filter(roleName => roleName?.toString().toLowerCase() !== 'associate');
      }
    }, error => {
      console.error(error);
    });
  }

  getConfiguration(category?, featureNames?) {
    let queryVariable = {};
    if (category)
      queryVariable = { category: category }
    else if (featureNames)
      queryVariable = { featureNames: featureNames }
    let query = this.SubMenuCardModel.GetQuery("manageConfigurations");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start('getData');
    this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(data => {
      if (!data?.errors) {
        this.gridLoaded = true;
        this.configdata = data?.data?.submenuData;
        this.getDropDownValues();
        this.AddGridData(this.configdata);
      }
      this.ngxService.stop('getData');
    }, error => {
      this.gridLoaded = true;
      console.error(error);
    });

  }

  AddGridData(values?) {
    this.resetEnabled = false;
    this.gridHeader = ['id', 'refConfigTarget', 'refConfigName', 'description', 'category', 'status', 'refconfigvalidvalues', 'name', 'deployment', 'featureNames', 'roleNames'
      , 'createdBy', 'modifiedBy', 'createdDate', 'modifiedDate'
    ];
    this.gridheaderList = this.templateHeaders?.templateList;
    const manageConfigHeader = this.gridheaderList?.find(header => header?.templateName === 'Manage Configurations');
    this.ListedGridHeader = manageConfigHeader ? manageConfigHeader?.headers : [];
    this.listHeaderbackup = JSON.parse(JSON.stringify(this.ListedGridHeader));
    let gridarray = values ? values.map(item => {
      const primary = {};
      Object.entries(item).forEach(([key, value]) => {
        if (this.gridHeader?.includes(key)) {
          primary[key] = value;
        }
      });
      return primary;
    }) : [];
    if (gridarray?.length > 0) {
      if (this.featureNames || this.categoryName) {
        gridarray = gridarray?.filter(item => {
          const featureMatches = this.featureNames ? item.featureNames?.toLowerCase() === this.featureNames?.toLowerCase() : true;
          const categoryMatches = this.categoryName ? item.category?.toLowerCase() === this.categoryName?.toLowerCase() : true;
          return featureMatches && categoryMatches;
        });
      }
      this.listPage = true;
    } else {
      this.listPage = !!(this.templateData && this.templateData?.labels);
    }
    gridarray = gridarray?.sort((a, b) => a?.refConfigName?.localeCompare(b?.refConfigName))
    this.gridData = new CollectionView(gridarray);
  }

  // getNote() {
  //   return `By Default, active flags that are created in last 30 days are listed`;
  // }

  emitFilters($event: any) {
    this.categoryName = $event?.category?.object?.category === 'All Categories' ? '' : $event?.category?.object?.category || this.categoryName;
    this.featureNames = $event?.featureName?.object?.featureNames === 'All Features' ? '' : $event?.featureName?.object?.featureNames || this.featureNames;
    this.getConfiguration(this.categoryName, this.featureNames);
  }

  backView() {
    this.RolesSelectionModel?.clear();
    this.MenuSelectionModel?.clear();
    this.createOrEditClicked = false;
    this.listPage = true;
    this.getConfiguration();
  }

  editToggleConfiguration(value) {
    if (value.action == 'edit') {
      this.ngxService.start('saveToggle');
      let finalObj = {
        id: value?.rowData?.id,
        flagname: value?.rowData?.refConfigName,
        refconfigtarget: value?.rowData?.refConfigTarget,
        menu: value?.rowData?.featureNames,
        isactive: value?.event?.srcElement?.checked ? 1 : 0,
        createdBy: !localStorage.getItem('UserEmail') ? 'system' : localStorage.getItem('UserEmail')
      }
      this.VitalHttpServices.manageConfigurations(finalObj, 'configdb').subscribe((data: any) => {
        if (data) {
          this.getConfiguration();
          if (data?.content?.message?.toString().includes('already exist')) {
            this.listPage = false;
            this._snackbar.open(data?.content?.message, "Close");
            this.ngxService.stop('saveToggle');
            return
          }
          else {
            this.listPage = false;
            // this.commonService.auditDetails('orgTemplateId', 'TemplateName', [data.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(data?.content?.message, "Close")
            this.ngxService.stop('saveToggle');
          }
        }
        else {
          this._snackbar.open(data?.content?.message, 'Close');
          this.listPage = false;
          this.ngxService.stop('saveToggle');
        }
      },
        (error: any) => {
          this.ngxService.stop('saveToggle');
          this._snackbar.open('Error occurred while updating', 'Close');
        });
    }
  }

  resetDynamicForm() {
    this.markFormGroupPristine(this.configForm);
    this.roleValuesValid = this.rolesDDValues?.length > 1 ? true : false;
    this.menuValuesValid = this.menuDDValues?.length > 1 ? true : false;
    this.DeploymentListValid = this.DeploymentList?.length > 1 ? true : false;
    this.disableFieldsOnEdit ? this.editConfiguration(this.editData) : this.createConfigurations();
    this.resetEnabled = false;
  }

  private markFormGroupPristine(formGroup: FormGroup) {
    Object.values(formGroup?.controls)?.forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupPristine(control);
      } else {
        control.markAsPristine();
      }
    });
  }

  returnBtn(reset?) {
    if (reset) {
      this.matdialog.openLabadminConfimationdialog('', 'All your changes will be lost. Do you want to proceed?', 'OK', 'Cancel')
        .afterClosed().subscribe(result => {
          if (result) {
            this.backView();
          }
        });
    } else {
      this.backView();
    }
  }

  saveConfiguration() {
    this.ngxService.start('save');
    const requiredFields = [
      'configurationFlagName',
      'configurationDisplayName',
      'configurationCategory',
      'configurationTable',
      'configurationScope',
      'configurationDescription',
    ];

    // Check if any required fields are empty
    const isAnyFieldEmpty = requiredFields?.some(field => this.configForm?.value[field] === '');

    if (isAnyFieldEmpty || this.RolesSelectionModel?.selected?.length === 0 || this.MenuSelectionModel?.selected?.length === 0) {
      if (this.RolesSelectionModel?.selected?.length === 0) {
        this.roleValuesValid = false;
      }
      if (this.MenuSelectionModel?.selected?.length === 0) {
        this.menuValuesValid = false;
      }
      if (this.DeploymentListSelectionModel?.selected?.length === 0) {
        this.DeploymentListValid = false;
      }

      this._snackbar.open('Mandatory Field Missing', 'Close');
      this.configForm.markAllAsTouched();
      this.ngxService.stop('save');
      return;
    }

    // Assuming you have an object indicating which values to set
    const valuesToSet = {
      "ExtAttrValue1": true, // Change this to false to see the 'Not set' behavior
      "ExtAttrValue2": false // Change this to true to see the update
    };

    Object.keys(valuesToSet).forEach(key => {
      if (valuesToSet[key]) {
        this.refconfigdefaultvalue[key][0].Default = this.configForm?.value?.configurationSetFinalState; // Replace with the desired value
      }
    });

    this.roleValuesValid = this.rolesDDValues?.length > 1 ? true : false;
    this.menuValuesValid = this.menuDDValues?.length > 1 ? true : false;
    this.DeploymentListValid = this.DeploymentList?.length > 1 ? true : false;
    let finalObj = {
      id: this.disableFieldsOnEdit ? this.editData?.id : null,
      contributiontype: this.configForm?.value?.configurationType === '' ? 'Flag' : this.configForm?.value?.configurationType,
      flagname: this.configForm?.value?.configurationFlagName,
      displayname: this.configForm?.value?.configurationDisplayName,
      category: this.configForm?.value?.configurationCategory,
      refconfigtarget: this.configForm?.value?.configurationTable,
      roles: this.RolesSelectionModel?.selected?.join(","),
      menu: this.MenuSelectionModel?.selected?.join(","),
      scope: this.configForm?.value?.configurationScope.toLowerCase() === 'all location' ? '2' : (this.configForm?.value?.configurationScope.toLowerCase() === 'this location' ? '3' : 'both'),
      description: this.configForm?.value?.configurationDescription,
      instructions: this.configForm?.value?.configurationInstruction,
      refconfigvalidvalues: this.configForm?.value?.configField,
      refconfigdefaultvalue: this.refconfigdefaultvalue,
      deploymentkeys: (this.DeploymentListSelectionModel?.selected?.length === this.DeploymentList?.length) ? 'ALL' : this.DeploymentListSelectionModel?.selected?.join(","),
      createdBy: !localStorage.getItem('UserEmail') ? 'system' : localStorage.getItem('UserEmail')
    }

    let apiObj = this.finalObjectCreation(finalObj)

    this.VitalHttpServices.manageConfigurations(apiObj, 'configdb').subscribe((data: any) => {
      if (data?.status == 'Success') {
        if (data?.content?.message?.toString().includes('already exist')) {
          this._snackbar.open(data?.content?.message, "Close");
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop('save');
          return
        }
        else {
          this.createOrEditClicked = false;
          this._snackbar.open(data?.content?.message, "Close")
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this.getConfiguration();
          this.ngxService.stop('save');
        }
      }
      else {
        this._snackbar.open('Error occurred while configuring', 'Close');
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this.ngxService.stop('save');
      }
    },
      (error: any) => {
        this._snackbar.open('Error occurred while configuring', 'Close');
        this.ngxService.stop('save');
      });
  }

  finalObjectCreation(finalObj) {
    let fieldsArray: any[] = finalObj?.refconfigvalidvalues?.AttributeFields;

    fieldsArray.forEach(field => {
      if (['toggle', 'strict dropdown', 'dropdown']?.includes(field?.datatype?.toString()?.toLowerCase())) {
        // Check if at least one final state is true
        const trueFinalState = field?.finalStates?.find((state, index) => {
          return state === true && field?.datasource?.[index];
        });

        if (trueFinalState !== undefined) {
          const index = field?.finalStates?.indexOf(trueFinalState);
          // Get the first key from the datasource object
          const keyFromDatasource = Object.keys(field?.datasource?.[index])[0]; // Retrieve the first key
          const valueFromDatasource = field?.datasource?.[index]?.[keyFromDatasource]; // Get the value of that key

          // Set alllocationfinalstate and thislocationfinalstate to an object
          if (this.configForm?.value?.configurationScope.toLowerCase() === 'all location') {
            field.alllocationfinalstate = valueFromDatasource;
          } else if (this.configForm?.value?.configurationScope.toLowerCase() === 'this location') {
            field.thislocationfinalstate = valueFromDatasource;
          } else {
            field.alllocationfinalstate = valueFromDatasource;
            field.thislocationfinalstate = valueFromDatasource;
          }
        }
        delete field.finalStates;
      }
    });

    fieldsArray.forEach(element => {
      element["scope"] = finalObj?.scope;
      element["regex"] = "";
      element["configinstruction"] = finalObj?.instructions;
      element["datasource"] = this.transformArray(element.datasource)
    });
    delete finalObj?.instructions;
    delete finalObj?.scope;
    delete finalObj?.scope;

    finalObj.refconfigvalidvalues.AttributeFields = fieldsArray;
    return finalObj;

  }

  transformArray(arr) {
    return arr.map(item => ({
      [item.key]: item?.value,
    }));
  }

  createConfigurations() {
    this.getDefinedValuesForCreateAndEditScreen();

    this.context = 'Create'
    this.resetEnabled = this.disableFieldsOnEdit = this.listPage = false;
    this.createOrEditClicked = true;
    this.configForm?.reset()
    this.cdr?.detectChanges();
    this.RolesSelectionModel?.clear();
    this.MenuSelectionModel?.clear();
    this.DeploymentListSelectionModel?.clear();
    this.configForm?.patchValue({
      configurationType: 'Flag',
      configurationFlagName: '',
      configurationDisplayName: '',
      configurationCategory: '',
      configurationTable: '',
      configurationScope: '',
      configurationDescription: '',
      configurationInstruction: '',

      // Resetting configField to an empty state
      configField: this.createConfigFieldForm()
    });
    // Clear the AttributeFields FormArray
    const attributeFields = this.configForm?.get('configField.AttributeFields') as FormArray;
    attributeFields?.clear();

    // If you want to initialize with a new field (optional)
    const newField = this.createField();
    attributeFields?.push(newField);
    this.roleValuesValid = true;
    this.menuValuesValid = true;
    this.DeploymentListValid = true;
  }

  editConfiguration(data?: any) {
    this.getDefinedValuesForCreateAndEditScreen();

    this.ngxService.start();
    this.resetEnabled = false;
    this.configForm.markAsPristine();

    this.editData = data?.rowData ?? this.editData;
    this.disableFieldsOnEdit = true;
    this.createOrEditClicked = true;
    this.listPage = false;
    this.context = 'Edit';

    // Parse configuration values and initialize selection models
    const referenceConfigValues = JSON.parse(this.editData?.refconfigvalidvalues || '{}');
    const { roles = [], menus = [], deployment = [] } = this.splitStringProperties(this.editData);
    const editedDeployment = deployment[0]?.toString()?.toLowerCase() == 'all' ? this.DeploymentList : deployment;
    this.initializeSelections(this.DeploymentListSelectionModel, editedDeployment);
    this.initializeSelections(this.RolesSelectionModel, roles);
    this.initializeSelections(this.MenuSelectionModel, menus);

    if (referenceConfigValues?.AttributeFields?.[0]) {
      if (referenceConfigValues.AttributeFields[0]?.scope?.toString() == "2" || referenceConfigValues.AttributeFields[0]?.scope == 2) {
        referenceConfigValues.AttributeFields[0].scope = 'All Location';
      } else if (referenceConfigValues?.AttributeFields[0]?.scope?.toString() == "3" || referenceConfigValues?.AttributeFields[0]?.scope == 3) {
        referenceConfigValues.AttributeFields[0].scope = 'This Location';
      } else {
        referenceConfigValues.AttributeFields[0].scope = 'Both';
      }
    }

    // Patch general form values
    this.configForm.patchValue({
      configurationFlagName: this.editData?.refConfigName,
      configurationDisplayName: this.editData?.name,
      configurationDescription: this.editData?.description,
      configurationInstruction: referenceConfigValues?.AttributeFields?.[0]?.configinstruction || '',
      configurationCategory: this.editData?.category,
      configurationTable: this.editData?.refConfigTarget?.toLowerCase(),
      configurationScope: referenceConfigValues?.AttributeFields?.[0]?.scope || '',
      configField: this.createConfigFieldForm()
    });

    // Populate AttributeFields form array
    this.populateAttributeFields(referenceConfigValues.AttributeFields);
    this.ngxService.stop();
  }
  //#region Healper for edit page configuration
  // Helper to split roles, menus, and deployments into arrays
  splitStringProperties(editData: any) {
    return {
      roles: editData?.roleNames?.split(',') || [],
      menus: editData?.featureNames?.split(',') || [],
      deployment: editData?.deployment?.split(',') || []
    };
  }
  // Helper to initialize selection models
  initializeSelections(selectionModel: any, items: string[]) {
    selectionModel?.clear();
    items?.forEach(item => selectionModel?.select(item));
  }
  // Helper to populate AttributeFields
  populateAttributeFields(attributeFieldsData = []) {
    const attributeFields = this.configForm?.get('configField.AttributeFields') as FormArray;
    attributeFields?.clear();

    attributeFieldsData?.forEach(field => {
      const newField = this.createField();
      newField?.patchValue({
        fieldname: field?.fieldname,
        datatype: field?.datatype,
        fieldWaterMark: field?.fieldWaterMark
      });

      this.populateFieldDataSources(newField, field);
      attributeFields.push(newField);
    });
  }
  // Helper to populate datasource and finalStates for a field
  populateFieldDataSources(field: FormGroup, fieldVAlues) {
    const datasourceArray = field?.get('datasource') as FormArray;
    const finalStatesArray = field?.get('finalStates') as FormArray;
    datasourceArray?.clear();
    finalStatesArray?.clear();

    fieldVAlues?.datasource?.forEach((obj, index) => {
      const [key, value] = Object?.entries(obj)?.[0];
      datasourceArray?.push(this._fb?.group({ key: [key], value: [value] }));
      if ('alllocationfinalstate' in fieldVAlues) {
        if (fieldVAlues?.scope?.toLowerCase() == 'all location' || fieldVAlues?.scope?.toLowerCase() == 'both') {
          finalStatesArray?.push(this._fb?.control(key?.toString()?.toLowerCase() == fieldVAlues['alllocationfinalstate']?.toLowerCase() ? true : false));
        } else if (fieldVAlues?.scope?.toLowerCase() == 'this location' || fieldVAlues?.scope?.toLowerCase() == 'both') {
          finalStatesArray?.push(this._fb?.control(key?.toString()?.toLowerCase() == fieldVAlues['thislocationfinalstate']?.toLowerCase() ? true : false));
        }
      }
      else {
        finalStatesArray?.push(this._fb?.control(fieldVAlues?.finalStates?.[index]));
      }
    });
  }
  //#endregion

  get AttributeFields(): FormArray {
    return this.configForm?.get('configField.AttributeFields') as FormArray;
  }

  createField() {
    return this._fb.group({
      fieldname: [''],
      datatype: [''],
      fieldWaterMark: [''],
      datasource: this._fb?.array([]), // Initialize datasource as FormArray
      finalStates: this._fb?.array([]) // Initialize finalStates as FormArray
    });
  }

  addField(): void {
    if (this.AttributeFields?.length < 2) {
      this.AttributeFields?.push(this.createField());
    }
  }

  addValue(fieldIndex: number): void {
    const field = this.AttributeFields?.at(fieldIndex) as FormGroup;
    const datasource = field?.get('datasource') as FormArray;

    const newValueGroup = this._fb?.group({
      key: [''],
      value: ['']
    });

    datasource?.push(newValueGroup);

    // Initialize final state as false
    const finalStates = field?.get('finalStates') as FormArray;
    finalStates.push(this._fb?.control(false)); // Add a control for final state
  }

  removeValue(fieldIndex: number, valueIndex: number): void {
    const field = this.AttributeFields?.at(fieldIndex) as FormGroup;
    const datasource = field?.get('datasource') as FormArray;
    const finalStates = field?.get('finalStates') as FormArray;

    datasource.removeAt(valueIndex);
    finalStates.removeAt(valueIndex); // Remove corresponding final state
  }

  updateDropdownValues(fieldIndex: number, value: string): void {
    const field = this.AttributeFields?.at(fieldIndex) as FormGroup;
    const valueArray = field?.get('datasource') as FormArray;

    valueArray.clear();
    const finalStates = field?.get('finalStates') as FormArray;
    finalStates.clear(); // Clear final states when datatype changes

    if (['toggle', 'strict dropdown', 'dropdown']?.includes(value?.toString()?.toLowerCase())) {
      this.addValue(fieldIndex);
    }
  }

  updateFinalState(fieldIndex: number, valueIndex: number, event): void {
    const field = this.AttributeFields?.at(fieldIndex) as FormGroup;
    const finalStates = field?.get('finalStates') as FormArray;

    // Iterate over all controls in finalStates FormArray
    finalStates.controls.forEach((control, index) => {
      // If the index matches the clicked one, set it to true, else set to false
      if (index === valueIndex) {
        control?.setValue(control?.value)
      } else {
        control?.setValue(index === valueIndex);
      }
    });
  }

  // Panel
  clearPanel(evt: any): void {
    evt.stopPropagation();
    this.configForm.patchValue({
      configurationCategory: ['']
    })
  }

  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }
}
