<div class="col-md-12">
  <div class="row">
      <div class="col-md-12 p-0 d-flex justify-content-between">
          <div class="modal-header admin-model-header mt-2 listingModalHeader">
              <h3 class="modal-title w-100 admin-model-header-txt">
                  <strong class="header-View"> Associated Client Locations </strong>
              </h3>
          </div>

          <div class="d-flex justify-content-center align-items-center" style="font-size: 12px !important;" *ngIf="['Ordering Facilities', 'Laboratories'].includes(selectedAssociationTab?.['keyword']) && !showAssociationConfigScreen">
            <div class="d-flex justify-content-between"> <img src="../../../../assets/icons/tableInfoIcon.svg" class="iconClass mr-2"/>
              {{ panelMessages?.[selectedAssociationTab?.['keyword']] }}
            </div>
          </div>
      </div>
  </div>

  <div class="col-md-12 mt-2 p-0">
    <app-new-association-form *ngIf="showAssociationConfigScreen"
    [templateData]="templateData"
    (returnButtonClicked)="onTabChange({index: 0}); showAssociationConfigScreen = false;"></app-new-association-form>
  </div>
  <div class="col-md-12 p-0 mt-4" *ngIf="!showAssociationConfigScreen">
    <div class="tab-container">
      <mat-tab-group (selectedTabChange)="onTabChange($event)" [dynamicHeight]="'true'" animationDuration="0ms"  [(selectedIndex)]="selectedTabIndex">
        <mat-tab *ngFor="let tab of finalDisplayTabs; let index = index" [label]="tab?.['displayName']">

            <hr class="separation-line m-0 p-0">
            <!-- <app-shimmerui [line] = 8 *ngIf="!GridData"></app-shimmerui> -->
            <ng-container *ngIf="selectedAssociationTab['keyword'] === 'Ordering Facilities' || selectedAssociationTab['keyword'] === 'Laboratories'">
              <mat-tab-body>
                <div class="container-fluid list-container col-sm-12 p-0 mt-0 text-center">
                  <div class="col-sm-12 header d-flex py-1 mb-1" *ngIf="GridData?.length">
                    <div class="col-sm-12 header-wrap">
                      <ng-container *ngIf="isDeAssociateClicked">
                        <span [ngStyle]="{width: '3%'}">
                          <div class="dataTable"></div>
                        </span>
                      </ng-container>
                      <span [ngStyle]="{width: header.colWidth}" *ngFor="let header of gridHeader; trackBy: trackByHeader">
                        <div class="dataTable" [ngStyle]="{'text-align': header?.textAlign ? header?.textAlign : 'left'}">{{header.Name}}</div>
                      </span>
                      <div class="col-sm-1 pr-0 edit-wraps dataTable">
                        <span *ngIf="GridData?.length > 0 && exportButton" (click)="exportExcel()">
                          <img src="../../../../assets/icons/Excel_Download.svg" matTooltip="Export" class="export-icon">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="container-fluid col-sm-12 header-wrap p-0">
                    <cdk-virtual-scroll-viewport itemSize="15" class="example-viewport">
                      <div *cdkVirtualFor="let item of GridData | searchfiltersTemplate: templatefilter; trackBy: trackByItem">
                        <div [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive') ? 'col-sm-12 card-data mt-1 ml-0 mb-1 inactive-class' : 'col-sm-12 card-data mt-1 ml-0 mb-1'">
                          <ng-container *ngIf="isDeAssociateClicked">
                            <div [ngStyle]="{width: '3%', 'text-align': 'center'}" class="text-muted text-ellipsis dataTable dataTableRowFont" >
                                <span style="position: relative;top: 2px">
                                  <input type="checkbox" [checked]="isItemSelected(item)" id="input_EnableSendOutCheckBox" (change)="onDeAssociateCheckboxChange(item, $event)" class="cursor">
                                </span>
                            </div>
                          </ng-container>
                          <div (click)="RedirectToView(item)" [ngStyle]="{width: header.colWidth, 'text-align': header?.textAlign ? header?.textAlign : 'left'}" class="text-muted text-ellipsis dataTable dataTableRowFont"
                          [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)"
                          #isElipsed *ngFor="let header of gridHeader; trackBy: trackByHeader">
                            <span *ngIf="header.Name.toLowerCase() != 'pc enabled' && header.Name.toLowerCase() != 'view report'">
                              {{ item[header['key']] | stripHtml }}
                            </span>
                            <!-- Uncomment if needed -->
                            <span *ngIf="header.Name.toLowerCase() =='pc enabled' || header.Name.toLowerCase() =='view report'">
                              <!-- <span id="export" [appAddCheckOrCross]="item[header['key']]"></span>
                              <span> {{ item[header['key']] }} </span>
                              <span> {{ header['key'] }} </span> -->
                              <img src="../../../../assets/icons/{{ item[header['key']] ? 'Active_Green.svg' : 'Inactive_Red.svg' }}" style="width: 15px;"/>
                            </span>
                          </div>
                          <div class="col-sm-1 btn-wraps d-flex" style="justify-content: end;">
                            
                            <mat-icon *ngIf="!hideEdit && selectedAssociationTab['keyword'] !== 'Laboratories'" class="pr-4 actions" matTooltip="Edit" (click)="editMethod(item)">create</mat-icon>
                            <!-- <mat-icon *ngIf="!hideDelete" class="actions ml-3" matTooltip="Delete" (click)="deleteMethod(item)">delete</mat-icon> -->
                          </div>
                        </div>
                      </div>
                    </cdk-virtual-scroll-viewport>
                    <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                      <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
                    </div>
                    <div class="col-sm-12 nodata-header-wrap" *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
                      <span class="col-sm-12 nodata-wrapper"> No Results </span>
                    </div>
                  </div>
                </div>
              </mat-tab-body>
            </ng-container>

            <!-- <ng-container *ngIf="selectedAssociationTab['keyword'] === 'Pathologists'">
                Pathologists
            </ng-container> -->

            <ng-container *ngIf="selectedAssociationTab['keyword'] === 'Send In Lab' || selectedAssociationTab['keyword'] === 'Pathologists' || selectedAssociationTab['keyword'] === 'Send-out Laboratories'">
              <div class="container-fluid col-sm-12 p-0 mt-0 text-center groupedTable" *ngIf="GridData && gridHeader">
                <div class="col-sm-12 header d-flex py-1 mb-1" *ngIf="GridData?.length">
                  <div class="col-sm-12 header-wrap">
                    <ng-container *ngIf="isDeAssociateClicked">
                      <span [ngStyle]="{width: '3%'}">
                        <div class="dataTable"></div>
                      </span>
                    </ng-container>
                    <span [ngStyle]="{width: header.colWidth}" *ngFor="let header of gridHeader; trackBy: trackByHeader">
                      <div class="dataTable">{{header.Name}}</div>
                    </span>
                    <div class="col-sm-1 pr-0 edit-wraps dataTable">
                      <span *ngIf="GridData?.length > 0" (click)="exportExcel()">
                        <img src="../../../../assets/icons/Excel_Download.svg" matTooltip="Export" class="export-icon">
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 p-0">
                  <ng-container *ngFor="let groupedItem of groupedGridData; let i = index;">
                    <div class="groupedRowsContainer col-sm-12 p-0" *ngIf="groupedItem[1]?.length">
                        <div class="groupHeader">
                          <div class="d-flex justify-content-between align-item-center cursor" (click)="togglePanel(i)"> {{ groupedItem[0] }} <img src="../../../../assets/icons/{{ isOpenedIndex === i ? 'tableCollapse' : 'tableExpandAll' }}.svg" class="iconClass ml-2"/> </div>
                          <div class="d-flex justify-content-between align-item-center"> <img src="../../../../assets/icons/tableInfoIcon.svg" class="iconClass mr-2"/> {{ panelMessages[groupedItem[0]] }} </div>
                        </div>
                        <div style="padding: 10px;" *ngIf="i === isOpenedIndex">
                          <div class="example-group-viewport" #viewport>
                            <div *ngFor="let item of groupedItem[1] | searchfiltersTemplate: templatefilter; trackBy: trackByItem">
                              <div [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive') ? 'col-sm-12 card-data mt-1 ml-0 mb-1 inactive-class' : 'col-sm-12 card-data mt-1 ml-0 mb-1'">
                                <ng-container *ngIf="isDeAssociateClicked">
                                  <div [ngStyle]="{width: '3%', 'text-align': 'center'}" class="text-muted text-ellipsis dataTable dataTableRowFont" >
                                      <span style="position: relative;top: 2px">
                                        <input type="checkbox" [checked]="isItemSelected(item)" id="input_EnableSendOutCheckBox" (change)="onDeAssociateCheckboxChange(item, $event)" class="cursor">
                                      </span>
                                  </div>
                                </ng-container>
                                <div (click)="RedirectToView(item)" [ngStyle]="{width: header.colWidth}" class="text-ellipsis dataTable dataTableRowFont" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed
                                *ngFor="let header of gridHeader; trackBy: trackByHeader; let colIndex = index;">
                                  <span *ngIf="header.Name.toLowerCase() != 'pc enabled' && header.Name.toLowerCase() != 'view report'">
                                    {{ (item['showAssociatedAccountName'] && header['key'] === 'Account_Name') ? item['Associated_Account_Name'] : item[header['key']] | stripHtml }}
                                  </span>
                                  <span *ngIf="header.Name.toLowerCase() =='pc enabled' || header.Name.toLowerCase() =='view report'">
                                    <span id="export" [appAddCheckOrCross]="item[header['key']]"></span>
                                  </span>
                                </div>
                                <!-- <div class="col-sm-1 btn-wraps d-flex" style="justify-content: end;">
                                  <mat-icon *ngIf="!hideEdit && selectedAssociationTab['keyword'] !== 'Laboratories'" class="pr-4 actions" matTooltip="Edit" (click)="editMethod(item)">create</mat-icon>
                                </div> -->
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 nodata-header-wrap" *ngIf="(groupedItem[1] | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && groupedItem[1]?.length !== 0">
                            <span class="col-sm-12 nodata-wrapper"> No Results </span>
                          </div>
                        </div>
                    </div>
                  </ng-container>
                </div>
                
              </div>
              <!-- {{ GridData | json }} -->
              <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
              </div>
            </ng-container>

            <!-- <ng-container *ngIf="selectedAssociationTab['keyword'] === 'Send-out Laboratories'">
                Send-out Laboratories
            </ng-container> -->
        </mat-tab>
      </mat-tab-group>


      <div class="custom-button-container d-flex" *ngIf="!isDeAssociateClicked">


        <div class="global-search-panel d-flex p-0" style="width: 166px;">
          <div style="display: flex; margin-right: 3px;margin-left: 4px;">
              <mat-icon class="search-icon">search</mat-icon>
          </div>
          <div class="standing-line">
          </div>
          <input type="text" class="search-input" [placeholder]="'Search'" [(ngModel)]="searchText"
              (keyup)="filtertemplates()">
          <ng-container *ngIf="searchText">
              <div class="standing-line">
              </div>
              <div  style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;"> 
                  <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
              </div>
          </ng-container>
        </div>



        <div class="ml-2 justify-content-center" (click)="showAssociationConfig()" *ngIf="(!['Facility', 'EPG', 'Send In', 'Send Out'].includes(selectedContext)) && (createButton && associateBtnCreate) && (!['Pathologists', 'Send In Lab', 'Send-out Laboratories', 'Ordering Facilities'].includes(selectedContext))">
          <button type="button" class="btn btn-return float-right">
            Associate
          </button>
        </div>


        <div class="ml-2 justify-content-center" (click)="isDeAssociateClicked = true" *ngIf="btnRemove && GridData?.length">
          <button type="button" class="btn btn-return float-right">
            De-Associate
          </button>
        </div>

        <div class="create-upload-btn d-flex ml-2 justify-content-center" *ngIf="selectedContext === 'Laboratory' && showCreateButtonIn.includes(selectedAssociationTab['keyword'])">
          <div class="create-only" (click)="createClicked()" *ngIf="showCreateButtonIn.includes(selectedAssociationTab['keyword']) && createButton">
              Create
          </div>

          <div class="Standing-line-create" *ngIf="(selectedContext === 'Laboratory' || uploadButton) && selectedAssociationTab['keyword'] === 'Ordering Facilities'">
          </div>

          <div class="upload-icon" *ngIf="(selectedContext === 'Laboratory' || uploadButton) && selectedAssociationTab['keyword'] === 'Ordering Facilities'"><img
                  src=".../../../../assets/icons/Bulk Upload_White.svg" matTooltip="Upload"
                  style="height: 15px; width: 15px;" (click)="uploadClicked()">
          </div>
        </div>
      </div>

      <div class="custom-button-container d-flex" *ngIf="isDeAssociateClicked">
        <div class="ml-2 justify-content-center" (click)="resetDeAssociate()" *ngIf="itemsToDeAssociate?.length">
          <a class="reset-btn mr-3">Reset</a>
        </div>

        <div class="ml-2 justify-content-center" (click)="returnDeAssociate()">
            <button type="button" class="btn btn-return">
                Return
            </button>
        </div>


        <div class="ml-2 justify-content-center">
            <button type="button" class="btn primaryButtonColor" [disabled]="!itemsToDeAssociate?.length" (click)="deAssociateAccounts()">
                Save
            </button>
        </div>
      </div>
      
    </div>
      
  </div>

</div>