<div class="px-4" *ngIf="sourceApp === 'VitalDx' && (!gridPage || !templateData)">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>

    <app-templates-listing *ngIf="gridPage && templateData" [gridHeader]="listedGridHeader" [gridData]="ScrngCommentsGridData"
        [context]="'Screening Comments'" [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="sourceApp =='VitalDx' ? true : uploadBtn"
        [hideExport]="exportBtn" [hideCopy]="sourceApp =='VitalDx' ? true : copyBtn" (closingListTemplate)="handleListingScreen($event)"
        (deleteTemplateClicked)="newDeleteMethod($event)" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [showTopButtons]="true" [saveOrEditCompleted]="saveOrEditCompleted"
        [showHeaderModule]="true" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
        (copyTemplateEventTriggered)="loadCopyScreen()" (uploadTemplateEventTriggered)="loadUploadScreen()"
        [hideDelete]="deletebtn" (emitFilters)="selectedCasetype($event)"
        [isAdmin3]="sourceApp =='VitalDx' ? false : true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
        (sortingChanged)="refreshGrid()" (sortCompleted)="createActivityTrackerForSort($event)" [enableorDisableSeqOnDefault]="true">
    </app-templates-listing>

<!-- Bulk Upload -->
<div *ngIf="uploadClicked" class="revise-form">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Upload Screening Comments</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate(true)">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadBulkUpdate()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload and update screening comments</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
            [headersVisibility]="'Column'" (formatItem)="formatItem(grid, $event)" [frozenColumns]="2">

            <!-- Action -->
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]="!(columnname == 'templatetype') && !(columnname == 'organizationid')  " [format]="'d'"
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>

            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button type="button" class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                    (click)="refreshGrid()">
                    Back
                </button>
                <button mat-raised-button class="admin-btn-success" *ngIf="gridWidth > 0"
                    (click)="removeGrid()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="approveScrComments()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download Result</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Screening Comments</strong>
        </h3>
    </div>
    <form [formGroup]="copyScreeningCommentsForm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                <mat-label>Deployment</mat-label>
                <mat-select disableOptionCentering (selectionChange)="onChangeDeployment($event)" type="text" matInput
                    #searchbar formControlName="frmDepKey">
                    <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                <mat-label>Group </mat-label>
                <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                    [disabled]="disableOrgSearch" #searchbar
                    (click)="fetchOrgSeries(copyScreeningCommentsForm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyScreeningCommentsForm.value.frmOrganization); trimFieldValue('frmOrganization')"
                    [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            *ngIf="show.orgid.toString().includes(searchInput.toString()) || show.orgname.toLowerCase().includes(searchInput.toLowerCase())"
                            title="{{ show.orgname }} ({{show.orgid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
                            <span>{{ show.orgname }} ({{show.orgid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchOrgSeries(copyScreeningCommentsForm.value.frmOrganization)"><em class="fa fa-chevron-down chevron-align"
                  style="position: relative;margin-bottom: 6px;"></em></button>
                <button *ngIf="copyScreeningCommentsForm.value.frmOrganization" matSuffix mat-icon-button
                    aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formatItem(flexgrid, $event)" [frozenColumns]="!postUpload ? 1 : 2 "
                    [format]="'d'">
                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [visible]="!(columnname=='templatetype')"
                            [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <!-- <section *ngIf="!postUpload" class="example-section align-center row">
                    <input type="checkbox" id="copyasinactive" [disabled]="selectedData.length==0"
                        ng-init='checkStatus=false' [checked]='checkStatus' (change)='checkValue($event)'
                        ng-model='checkStatus' class="border-style">
                    <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
                </section> -->
                <div class="col-sm-12 pl-0 mt-4">
                    <button mat-raised-button type="button" class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                        (click)="refreshGrid()">Back</button>
                    <button mat-raised-button type="button" class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid()">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid()">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid()">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="approveScrComments(true)">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download Result</button>
                    </span>
                </div>

            </div>
            <div class="col-sm-12 mt-4" *ngIf="gridWidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
                    <div class="nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" *ngIf="noDataFound"
                    (click)="removeGrid()">Back</button>
                <button mat-raised-button type="button" class="admin-btn-success mt-4" *ngIf="!noDataFound"
                    (click)="refreshGrid()">Back</button>
            </div>
        </div>
    </form>
</div>
