import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, EMPTY, Observable, combineLatest, of, observable } from 'rxjs';
import { catchError, map, tap, filter, concatMap, take } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/commonservices';
import * as XLSX from 'xlsx';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogService } from 'src/app/core/services/dialog.service';

import { VitalAdminTabService } from '../../tab.service';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { convertLocalDateTime } from 'src/app/labadmin/components/Utility Functions';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';


@Component({
  selector: 'app-manage-pathologist-privilege',
  templateUrl: './manage-pathologist-privilege.component.html',
  styleUrls: ['./manage-pathologist-privilege.component.scss'],
})
export class ManagePathologistPrivilegeComponent implements OnInit, OnDestroy {
  destDeployment: string;
  userSessionDetails: any;
  sourceApp: string;
  dbName: string;
  activityEntity: any;
  gridHeaderList: any = [];
  ListedGridHeader: any = [];
  listHeaderbackup: any = [];
  cloneObj: any;

  selectedPathologist: any = { itemName: '', itemId: '' };
  filteredUserData: any = [];
  casetypeSearchBox: any = '';
  auditableColumns: any;
  oldObject: { caseTypes: any; isOrgTemplates: any; isUserTemplates: any; };
  newObject: { caseTypes: any; isOrgTemplates: any; isUserTemplates: any; };
  showLoader: boolean=false;
  saveLoader: boolean=false;
  showEditPathologist: boolean=false;
  constructor(
    public _vitalHttpService: VitalHttpServices,
    public commonService: CommonService,
    private _fb: FormBuilder,
    public _snackBar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    private dialog: DialogService,
    private TemplateService: TemplateViewService, private templateHeaders: TemplateHeadersService, public activityService: ActivityTrackerService,
    private labadminService: LabadminService,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>,
    private matdialog: MatDialog,
  ) { }

  tooTipText: string = '';
  PatholologistPrivilageList$: any;
  FilteredPatholologistPrivilageList$
  searchSubject = new BehaviorSubject({ searchtext: '' });
  searchSubject$ = this.searchSubject.asObservable();
  @Input() templateData: any;
  orgId: string = '-100';
  isPathalogistEdit: boolean = false;
  locations: location[] = []; //['Lorem ipsum dolor sit amet consectetur','Lorem ipsum dolor sit amet consectetur','Lorem ipsum dolor sit amet consectetur','Lorem ipsum dolor sit amet consectetur']
  caseTypes: smeCasetypes[] = [];
  masterCasetypes: smeCasetypes[] = []
  locationSelectionModel = new SelectionModel<any>(true, []);
  deploymentKey = '';
  loggedInUserId = '';
  caseTypeFilterSubject = new BehaviorSubject({ searchText: '' });
  caseTypeFilteeSubject$ = this.caseTypeFilterSubject.asObservable();
  isInitialCaseLoad = true;
  isEditDisabled: boolean = false;
  isExportDisabled: boolean = false;
  savedPathalogistdata: any;
  editedPathalogistDetails: any
  casetypeSerachUnsubscribe: any
  pathologistPrivilageFormUnsubscribe: any
  saveObject = {
    caseTypes : [''],
    accountIds: [],
    pathUserID: [],
    isOrgTemplates : '',
    isUserTemplates : '',
  };
  formPathalogistPrivilage = this._fb.group({
    pathologistName: '',
    isMedicalDirector: false, //MD
    allowEditingUserTemplates: false, //user orgroup level
    allowEditingDiagnosisTemplates: false, //permission
    isSME: false, //subject Matter expert
    pathologistId: 0,
    accountIds: '',
  });
  labadminSessionUnsubscribe=null

   ngOnInit() {
    // this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
     
      this.sourceApp = 'VitalDx';
      this.labadminSessionUnsubscribe=this.labAdminSessionService.getLabAdminSession
      .pipe(
        concatMap((session: any) =>
          this.store.select('breadcrum').pipe(
            take(1),
            concatMap((i) => {
              this.userSessionDetails = session['userDetails'];
              this.sourceApp = 'VitalDx';
              return this.commonService.getCommmonTemplateData(
                i[i.length - 1],
                this.userSessionDetails,'subment',true);
            })
          )
        )
      ).subscribe((data)=>{
        this.orgId = this.userSessionDetails?.organizationId;
          this.loggedInUserId=this.userSessionDetails?.userId;
          this.templateData = this.labadminService.templateData;
          this.dbName = this.labadminService.deploymentKey;
          this.getPathologistList();
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': 'Lab'},{ 'key': 'Pathology Management', 'value': this.templateData.menuURL}] });
          this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
          this.getAuditableDetails(this.templateData.menuURL);
          this.GetButtonAccess(this.labadminService.subMenuPermissions)
      })
    }
    else {
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL}] });
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    this.getAuditableDetails(this.templateData.menuURL);
      this.orgId = sessionStorage.getItem('org_id');
      this.loggedInUserId =
        sessionStorage.getItem('Userid') != ''
          ? sessionStorage.getItem('Userid')
          : '-100';
      this.deploymentKey = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.getPathologistList();
      this.GetButtonAccess(this._vitalHttpService.SubmenuAction)
      this.casetypeSerachUnsubscribe = this.caseTypeFilterSubject.subscribe((data) => {
        this.caseTypes = data.searchText != '' ? this.masterCasetypes.map((i) => ({
          ...i,
          Cases: i.Cases.filter((j) =>
            j.DisplayName.toLowerCase().includes(
              data.searchText.toLowerCase()
            )
          ),
        }))?.filter((i) => i.Cases.length) : this.masterCasetypes
      });
      this.pathologistPrivilageFormUnsubscribe = this.formPathalogistPrivilage.valueChanges.subscribe(data => {
        if (data.isSME) {
          this.caseTypeFilterSubject.next({ searchText: '' })
        }
      })
    }
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Pathalog') {
        this.ListedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.ListedGridHeader))
      }
    }

  }


  casetypeSearch(event) {
    this.casetypeSearchBox = event;
    this.caseTypes = this.casetypeSearchBox != '' ? this.masterCasetypes.map((i) => ({
      ...i,
      Cases: i.Cases.filter((j) =>
        j.DisplayName.toLowerCase().includes(
          this.casetypeSearchBox.toLowerCase()
        )
      ),
    }))?.filter((i) => i.Cases.length) : this.masterCasetypes
    this.caseTypeFilterSubject.next({ searchText: event });
  }

  getPathologistList() {
    this.ngxService.start();
    // this.searchSubject.next({searchtext:''})
    this.PatholologistPrivilageList$ = this._vitalHttpService
      .getPathologistPrivilagesList(this.templateData.secondarykeys.OrganizationId.toString())
      .pipe(
        tap(() => this.ngxService.start()),
        catchError((err) => {
          console.error(err);
          this.ngxService.stop();
          return EMPTY;
        }),
        map((data: any[]) => {
          return data.map(item => ({
            ...item,
            isUserlevel: item.AllowEditingUserTemplates ? 'User' : 'Group'
          }));
        }),
        tap(() => this.ngxService.stop())
      );
    this.FilteredPatholologistPrivilageList$ = combineLatest([
      this.PatholologistPrivilageList$,
      this.searchSubject$
    ]).pipe(
      tap(() => this.ngxService.stop()),
      map(([data, filterText]: any) => {
        if (data && data.length) {
          this.cloneObj = structuredClone(data);
          let res= filterText.searchtext !== ''
            ? data.filter(
              (i: any) =>
                i.PathologistName.toLowerCase().includes(
                  filterText.searchtext.toLowerCase()
                ) ||
                (i.EditPrivilegesForCaseTypes &&
                  i.EditPrivilegesForCaseTypes.toLowerCase().includes(
                    filterText.searchtext.toLowerCase()
                  )) || (i.isUserlevel.toLowerCase().includes(
                    filterText.searchtext.toLowerCase()
                  ))
              // && (!this.selectedPathologist || i.PathologistName.toLowerCase() === this.selectedPathologist.toLowerCase())
            )
            : data;
            return {data:res,isNoDataPresent:false}
        }
        return {data,isNoDataPresent:true};
      })
    );
  }


  exportexcel(pathologistList: any) {

    pathologistList = pathologistList.map(pathologist => {
      let obj={
        'User Id':pathologist.UserId?.toString(),
        'Pathologist Name' : pathologist.PathologistName,
        'Medical Director':pathologist.IsMedicalDirector ? 'Yes' : 'No',
        'Location Name': pathologist.Location,
        'Subject Matter Expert':pathologist.SubjectMatterExpert
        ? 'Yes'
        : 'No',
        'SME for Casetypes':pathologist.EditPrivilegesForCaseTypes,
        // 'Edit Privileges For CaseTypes':pathologist.EditPrivilegesForCaseTypes,
        'Allow Editing Group Templates': pathologist.AllowEditingGroupTemplates ? 'Yes' : 'No',
        'Allow Editing User Templates': pathologist.AllowEditingUserTemplates
        ? 'Yes'
        : 'No',
        'Created Date':convertLocalDateTime(pathologist.createdDate),//.replace(/T|((\.)+\d+)/g,' ')??'',
        'Created By':pathologist.createdby,
        'Modified Date':convertLocalDateTime(pathologist.modifieddate),//.replace(/T|((\.)+\d+)/g,' ')??'' ,
        'Modified By':pathologist.modifiedby  
      }
      if (this.sourceApp === 'VitalDx') delete obj['User Id']
      return obj
      });
    const fileName = this.sourceApp == 'VitalDx'?'Pathologist Privileges.xlsx':'Pathologist Privileges_'+ this.orgId + '.xlsx';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(pathologistList);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Pathologist Privileges');
    XLSX.writeFile(wb, fileName);
  }

  getPathologisDetail(pathologistId, orgId, accountIds, pathologistName) {
    this.ngxService.start();
   
    this.showLoader=true
    this.isInitialCaseLoad = true;
    this._vitalHttpService
      .getPathologistPrivilagesDetail(pathologistId, orgId, accountIds)
      .pipe(
        map((i: any) => {
          return {
            ...i,
            SMECasetypes: i.SMECasetypes.filter(
              (j: any) => j.Category != ''
            )?.map((c) => ({
              ...c,
              selectionModel: new SelectionModel(
                true,
                c.Cases.reduce(
                  (p, c) => (c.isSMECaseType ? [...p, c.DisplayName] : [...p]),
                  []
                )
              ),
            })),
            selectionModel: new SelectionModel(true, []),
          };
        })
        // tap((i) => console.log(i))
      )
      .subscribe({
        next: (data) => {
          this.savedPathalogistdata = data;
          if (data) {
            this.populatePathalogistDeatails(
              data,
              pathologistId,
              pathologistName,
              accountIds
            );
            this.ngxService.stop();
          }
          this.showLoader=false
        },
        error: (err) => {
          console.error(err);
          this.ngxService.stop();
          this._snackBar.open("Something went wrong!","close")
          this.showLoader=false
          this.showEditPathologist=false
        },
        complete: () => { },
      });
  }

  toolTipshow(element) {
    this.tooTipText =
      element.scrollWidth > element.clientWidth ? element.textContent : '';
  }

  openEditPathologist(selectedpathData: any) {
    this.editedPathalogistDetails = selectedpathData
    this.showEditPathologist=true
    this.getPathologisDetail(
      selectedpathData.UserId,
      selectedpathData.OrganizationId,
      selectedpathData.AccountIds,
      selectedpathData.PathologistName
    );
  }

  populatePathalogistDeatails(
    data: any,
    pathologistId: number,
    pathologistName: string,
    accountIds
  ) {
    this.masterCasetypes = data.SMECasetypes;
    this.caseTypes = data.SMECasetypes;
    this.formPathalogistPrivilage.patchValue({
      isMedicalDirector: data.isMedicalDirector, //MD
      allowEditingUserTemplates: data.AllowEditingUserTemplates, //user orgroup level  --group false --user true
      allowEditingDiagnosisTemplates: data.AllowEditingDiagnosisTemplates, //permission --view false  --edit true
      isSME: data.isSME,
      pathologistId,
      pathologistName,
      accountIds,
    });
    this.locations = data.Locations;
    this.locationSelectionModel.clear();
    this.locationSelectionModel.select(
      ...this.locations
        .filter((i) => i.isMedicalDirectorAccount)
        .map((i) => i.Accountid)
    );
    this.isPathalogistEdit = true;
    console.log(data)
    this.activityEntity.entityId = pathologistId;
    this.activityService.setActivitySession(this.activityEntity);
    let oldobjcaseTypes =
    !data.allowEditingUserTemplates && data.isSME
      ? this.caseTypes
        .reduce(
          (p, c) => [
            ...p,
            ...c.selectionModel.selected.map((i) =>
              this.getCaseTypeByDisplayName(i, c.Cases)
            ),
          ],
          []
        )
        .join('^')
      : '';
    let oldisOrgTemplates = data.AllowEditingUserTemplates? false: data.AllowEditingDiagnosisTemplates
      this.oldObject = {
        caseTypes: oldobjcaseTypes,
        isOrgTemplates: oldisOrgTemplates,
        isUserTemplates: data.AllowEditingUserTemplates
      };
  }

  savePathologistData() {
    // this.caseTypeFilterSubject.next({ searchText: '' })
    const savedata = this.formPathalogistPrivilage.value;
    if (!this.validatePathologistData(savedata)) return;
    if (!savedata.allowEditingUserTemplates &&
      this.savedPathalogistdata.AllowEditingUserTemplates &&
      this.savedPathalogistdata.IsUserLevelDiagnosisTemplateEnabled) {

        this.dialog.openLabadminConfimationdialog('', "You have chosen to switch the Pathologist's Privileges back to the Diagnosis Templates available across the group. The Pathologist will not be able to access his/her user-specific Diagnosis Templates and any edits done to them will be lost. Do you want to proceed?", 'Proceed', 'Cancel')

        // let dialogRef = this.matdialog.open(ConfirmLabadminComponent, {
        //   disableClose: true,
        //   width: '360px',
        //   panelClass: 'admin-custom-popup',
        //   data: { header: "", message: "You have chosen to switch the Pathologist's Privileges back to the Diagnosis Templates available across the group. The Pathologist will not be able to access his/her user-specific Diagnosis Templates and any edits done to them will be lost. Do you want to proceed? ", continue: "Proceed", cancel: "Cancel" }
        //     })
        // return dialogRef
        .afterClosed().subscribe(result => {
          if (result) {
            this.savePathData(savedata)
          }
        })

      // const confirmPopUp = this.dialog.openDialog(
      //   '',
      //   "You have chosen to switch the Pathologist's privileges back to the Diagnosis Templates available across the organization. The Pathologist will not be able to access his/her user-specific Diagnosis Templates and any edits done  to them will be lost. Do you want to proceed? ",
      //   '',
      //   'Ok',
      //   'Cancel'
      // );
      // confirmPopUp.afterClosed().subscribe((result) => {
      //   if (result) {
      //     this.savePathData(savedata)
      //   }
      //   else return
      // })
    }


    else this.savePathData(savedata)

  }

  private savePathData(savedata: any) {
    let caseTypes: string = '';
    let accountIds: string = '';
    let isOrgTemplates = false;
    let isUserTemplates = false;
    accountIds = !savedata.isMedicalDirector
      ? ''
      : this.locationSelectionModel.selected.join(',');
    isOrgTemplates = savedata.allowEditingUserTemplates
      ? false
      : savedata.allowEditingDiagnosisTemplates;
    isUserTemplates = savedata.allowEditingUserTemplates;
    caseTypes =
      savedata.allowEditingDiagnosisTemplates && !savedata.allowEditingUserTemplates&& savedata.isSME
        ? this.caseTypes
          .reduce(
            (p, c) => [
              ...p,
              ...c.selectionModel.selected.map((i) =>
                this.getCaseTypeByDisplayName(i, c.Cases)
              ),
            ],
            []
          )
          .join('^')
        : '';
    const saveObject = {
      caseTypes,
      accountIds,
      pathUserID: savedata.pathologistId,
      isOrgTemplates,
      isUserTemplates,
    };
    this.newObject = saveObject;
    this.ngxService.start();
    this.saveLoader=true
    this._vitalHttpService
      .savePathologistPrivilages(saveObject, this.loggedInUserId, this.orgId)
      .subscribe({
        next: (data) => {
          if (data.Success) {
            this.resetEditPathologistPrivialagesData();
            this.saveLoader=false
            this.getPathologistList();
            this.isPathalogistEdit = !this.isPathalogistEdit
            this.handleSuccess('Edit',savedata);
            this._snackBar.open('Pathologist Privileges updated successfully.', 'Close');
          } else {
            console.error(data);
            this._snackBar.open('Something Went Wrong', 'Close');
          }
          this.ngxService.stop();
        },
        error: (err) => {
          console.error(err);
          this._snackBar.open('Something Went Wrong', 'Close');
          this.ngxService.stop();
          this.saveLoader=false
        },
        complete: () => { },
      });
  }
  private handleSuccess(Status: string,data: any) {
    this.ngxService.stop();
    let sessionId = this.commonService.generateGuid();
    if(Status == 'Edit')
      {
        this.oldObject.caseTypes = this.oldObject.caseTypes.replace(/\^/g, ', ');
        this.newObject.caseTypes = this.newObject.caseTypes.replace(/\^/g, ', ');
        this.commonService.createActivityObject(data.pathologistId,"Name: "+data.pathologistName, this.templateData.menuURL, 'Edit', this.newObject, this.oldObject, sessionId,this.auditableColumns);
      }
  }
  getCaseTypeByDisplayName(displayName: string, cases: cases[]): string {
    return displayName != ''
      ? cases.filter((i) => i.DisplayName == displayName)[0].CaseType
      : '';
  }

  private validatePathologistData(data: any) {
    if (
      data.isMedicalDirector && 
      !this.locationSelectionModel.selected.length
    ) {
      this._snackBar.open(
        'Select a location for the Medical Director or turn off the Medical Director button.',
        'Close'
      );

      return false;
    }
    if (data.allowEditingDiagnosisTemplates && !data.allowEditingUserTemplates && data.isSME && !this.isAtleastOnCaseSelcted()) {
      this._snackBar.open(
        'Select a case type for the SME or turn off the Subject Matter Expert button.',
        'Close'
      );
      return false;
    }
    return true;
  }

  resetEditPathologistPrivialagesData() {
    this.caseTypes = []
    this.formPathalogistPrivilage.reset()
    this.masterCasetypes = []
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.showEditPathologist=false

  }

  //#region selectionModel
  isAtleastOnCaseSelcted() {
    return this.caseTypes.reduce((p, c) => {
      return [...p, ...c.selectionModel.selected];
    }, []).length;
  }
  isAllCasesDisabled(obj: smeCasetypes) {
    return obj.Cases.reduce((p, c) => (p && c.isOtherSMECaseType), true)
  }
  isAllCategoryDisabled() {
    return this.caseTypes
      .reduce((p: any, c: any) => p && c.isCategoryDisabled, true);
  }
  isAllMaserCasedisabled() {
    return this.masterCasetypes.reduce((p, c) => (p && c.isCategoryDisabled), true)
  }
  isAllLocationDisabled() {
    return (
      this.locations.filter((i) => i.isOtherMedicalDirector).length ==
      this.locations.length
    );
  }
  toggleAllLocation() {
    if (this.isAllLocationSelected()) {
      this.locationSelectionModel.clear();
      return;
    }
    this.locationSelectionModel.select(
      ...this.locations
        .filter((i) => !i.isOtherMedicalDirector)
        .map((i) => i.Accountid)
    );
  }
  isAllLocationSelected() {
    let data = this.locations.filter((i) => !i.isOtherMedicalDirector);
    return data.length == this.locationSelectionModel.selected.length;
  }
  toggleAllCase(obj: any) {
    let enabledCases = obj.Cases.reduce(
      (p, c) => (!c.isOtherSMECaseType ? [...p, c.DisplayName] : [...p]),
      []
    );
    if (this.isAllSelectedCase(obj)) {
      this.caseTypeFilterSubject.value.searchText != '' ? obj.selectionModel.deselect(...enabledCases) : obj.selectionModel.clear();
      return;
    }
    obj.selectionModel.select(...enabledCases)

    // obj.selectionModel.select(
    //   ...obj.Cases.reduce(
    //     (p, c) => (!c.isOtherSMECaseType ? [...p, c.DisplayName] : [...p]),
    //     []
    //   )
    // );
  }
  isAllSelectedCase = (obj: any) => {
    let filteredData = obj.Cases.reduce(
      (p, c) => (!c.isOtherSMECaseType ? [...p, c.DisplayName] : [...p]),
      []
    );

    if (this.caseTypeFilterSubject.value.searchText != '') {
      return filteredData.length == obj.selectionModel.selected.filter(i => filteredData.includes(i)).length
    }
    return filteredData.length == obj.selectionModel.selected.length;
  };
  isAllCategorySelected() {
    return this.caseTypes
      .map((i: any) => this.isAllSelectedCase(i))
      .reduce((a: any, b: any) => a && b, true);
  }
  toggleAllCategory() {
    !this.isAllCategorySelected()
      ? this.caseTypes.map((i: any) =>
        i.selectionModel.select(
          ...i.Cases.reduce(
            (p, c) =>
              !c.isOtherSMECaseType ? [...p, c.DisplayName] : [...p],
            []
          )
        )
      )
      : this.caseTypes.map((i: any) => i.selectionModel.clear());
  }

  //#endregion

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails?.find(e => e.Htext == "Pathology Privileges")["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails?.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
      if (this._vitalHttpService.SubmenuAction) {
        try {
          let seletedMenuPermissions = this._vitalHttpService.SubmenuAction.find(
            (e) => e.Htext == this.templateData.headerText
          )['SubMenu'].find((ele) => ele.URL == this.templateData.menuURL)[
            'ActionButton'
          ];
          for (let i = 0; i < seletedMenuPermissions.length; i++) {
            switch (seletedMenuPermissions[i].Button.toLowerCase()) {
              case 'edit':
                this.isEditDisabled = !(
                  seletedMenuPermissions[i].IsPermitted === 'true'
                );
                break;
              case 'export':
                this.isExportDisabled = !(
                  seletedMenuPermissions[i].IsPermitted === 'true'
                );
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.casetypeSerachUnsubscribe){
      this.casetypeSerachUnsubscribe.unsubscribe();
    }
    if (this.pathologistPrivilageFormUnsubscribe){
      this.pathologistPrivilageFormUnsubscribe.unsubscribe();
    }
    if(this.labadminSessionUnsubscribe){
      this.labadminSessionUnsubscribe.unsubscribe();
    }
  }

  private isCasetypechanged(): boolean {
    let intialCases = this.caseTypes.reduce((p, c) => (
      !c.isCategoryDisabled ? [...p, ...c.Cases.filter(i => i.isSMECaseType).map(i => i.DisplayName)] : [...p]
    ), [])
    let selectedCases = this.caseTypes.reduce((p, c) => (
      [...p, ...c.selectionModel.selected]
    ), [])
    const isChanged = (!selectedCases.reduce((p, c) => (intialCases.includes(c) && p), true)) || (intialCases.length !== selectedCases.length);
    return isChanged;
  }

  private isLocationChanged() {
    const initialLocations = new Set(this.savedPathalogistdata.Locations.filter(i => i.isMedicalDirectorAccount).map(i => i.Accountid));
    return !this.locationSelectionModel.selected.every(location => initialLocations.has(location)) || this.locations.filter((i: any) => i.isMedicalDirectorAccount).length != this.locationSelectionModel.selected.length
  }

  private isPathalogistDetailsChanged(): boolean {

    const modifiedData = this.formPathalogistPrivilage.value
    return modifiedData.isMedicalDirector != this.savedPathalogistdata.isMedicalDirector
      || modifiedData.allowEditingDiagnosisTemplates != this.savedPathalogistdata.AllowEditingDiagnosisTemplates
      || modifiedData.allowEditingUserTemplates != this.savedPathalogistdata.AllowEditingUserTemplates
      || modifiedData.isSME != this.savedPathalogistdata.isSME
      || this.isLocationChanged() || this.isCasetypechanged()

  }

  returnToList() {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    if (!this.isPathalogistDetailsChanged()) {
      // this.searchSubject.next({searchtext:''})
      this.isPathalogistEdit = !this.isPathalogistEdit
      this.showEditPathologist=false
      return
    }
    this.dialog.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel')

    .afterClosed().subscribe(result => {
      if (result) {
        this.isPathalogistEdit = !this.isPathalogistEdit
        this.showEditPathologist=false
      }
    })

  }

  filterStatus(updateValue: any, isFromSearchField = false) {
    this.searchSubject.next({ searchtext: updateValue.searchtext });
    if (isFromSearchField) {
      this.selectedPathologist.itemName = '';
      this.selectedPathologist.itemId = '';
    };
  }

  onAutoCompleteSelectionChange(e, field, particularObject) {
    if (e.source.selected) {
      this.selectedPathologist['itemId'] = particularObject['PathologistName'];
      this.selectedPathologist['itemName'] = particularObject['PathologistName'];
      this.filterStatus({ searchtext: this.selectedPathologist['itemName'] });
    } else {
      this.filterStatus({ searchtext: '' });
    }
    // let filteredData = structuredClone(this.cloneObj);
    // let filteredObject = filteredData.filter((item: any) => {
    //   return (!this.selectedPathologist || item.PathologistName.toLowerCase() === this.selectedPathologist.itemName.toLowerCase())
    // });
    // this.PatholologistPrivilageList$ = of(structuredClone(filteredObject));

    document.dispatchEvent(new Event('click'));
  }

  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any) {
    if (!autoCompleteInputboxValue) {
      return autoCompleteOptions;
    } else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      return filteredAutoCompleteOptions.filter((option: any) => option[filterProperty].toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }

  getAllDataAfterFilter(data) {
    if (!data.target.value) {
      this.filterStatus({ searchtext: '' });
    } else {
      this.cloneObj = this.cloneObj;
    }
    // return this.PatholologistPrivilageList$ = of(this.cloneObj)
    // this.PatholologistPrivilageList$ = of(this.cloneObj);
    // this.filterStatus({ searchtext:data.target.value });
  }

  onReset = () => this.openEditPathologist(this.editedPathalogistDetails)

  getAuditableDetails(location: any) {
    this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
      console.log(this.auditableColumns)
    })
  }

  showMenuRelatedFlags() {
    let dialogRef = this.matdialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: 'Pathologist Privileges',
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }
}

export interface location {
  Accountid: string;
  AccountName: string;
  isMedicalDirectorAccount: boolean;
  isOtherMedicalDirector: boolean;
  ID: number;
}

export interface cases {
  ID: number;
  CaseType: string;
  Category: string;
  DisplayName: string;
  isSMECaseType: true;
  isOtherSMECaseType: boolean;
  FinalizeInProcessing: boolean;
}

export interface smeCasetypes {
  Category: string;
  Cases: cases[];
  selectionModel: SelectionModel<any>;
  isCategoryDisabled: boolean;
}

export interface ActivityTrackerObject {
  caseTypes : string,
  isOrgTemplates : boolean,
  isUserTemplates : boolean,
}
