import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { Operations, CreateActivityTrackerInput } from '../../../model/activity-tracker';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { DatePipe } from '@angular/common';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-mandatory-fields',
  templateUrl: './mandatory-fields.component.html',
  styleUrls: ['./mandatory-fields.component.scss'],
})

export class MandatoryFieldsComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };

  @Input() templateData: any;
  dbName: any = sessionStorage.getItem('deploymentKey').toUpperCase();
  accid: any;
  casetype: any;
  mandatefields: any = {};
  AccessionMandateFields: any = {};
  SendoutMandateFields: any = {};
  caseinfoobjectval: any = {};
  caseinfoflag: boolean = false;
  mandatflag: any;
  PatientDetailsinfoflag: boolean = false;
  patientobjectval: any = {};
  supplimentinfoflag: boolean = false;
  supplimentobjectval: any = {};
  attachmentinfoflag: boolean = false;
  attachmentmandobjectval: any = {};
  primaryinsuranceflag: boolean = false;
  primaryinsobjectval: any = {};
  secondaryinsuranceflag: boolean = false;
  secondaryinsobjectval: any = {};
  ternaryinsuranceflag: boolean = false;
  ternaryinsobjectval: any = {};
  gurantorinsobjectval: any = {};
  gurantorinsuranceflag: boolean = false;
  insurancedetailsflag: boolean = false;
  hideSaveBtn: boolean;
  Allfields: any;
  selectapplicable: any = '';
  vaservices: any;
  _snackbar: any;
  resetFields: any;
  saveedata: any = {}
  saveMandatefieldstring: any;
  newcaseinfoobjectval: any[] = [];
  newpatientobjectval: any[] = [];
  primInsurancepatientobjectval: any[] = [];
  secInsurancepatientobjectval: any[] = [];
  ternaryInsurancepatientobjectval: any[] = [];
  GaurantInsurancepatientobjectval: any[] = [];
  newsupplimentobjectval: any[] = [];
  newattachmentobjectval: any[] = [];
  checkBoxValue: boolean = false;
  anyCheckboxChecked = false;
  finalmandate: any = {};
  // allCaseType: boolean = false;
  selected:any;
  forallcasetype: string = '';
  selectedcasetype: boolean = false;
  flag: number = 1;
  defaultval:boolean=true;
  changedtoAccessionORsendout: boolean=false;
  showModal: boolean;
  previousPage: any;
  isSaved: boolean=false;
  mandateObject:any;
  caseList: any;
  createActivityOperations = new Operations();
  createActivityInput = new CreateActivityTrackerInput();

  defaultMandateICD = {
    "AccessionControlId": "txtICD",
    "AlertText": "Enter at least one of the ICD-10 codes",
    "CorrectionControlId": "gridCaseInformation_ctl02_txtICD",
    "DiagnosisControlId": "txtICDIndicative",
    "SiteInfoControlId": "",
    "Mandatory": "true",
    "isSelected": "false",
    "text": "ICD-10 (Indicative)",
    "dbcolumn": "",
  };

  defaultMandateCollectionTime = {
    "AccessionControlId": "txtCollectionTime",
    "AlertText": "Enter collection time",
    "CorrectionControlId": "txtCollectionTime",
    "DiagnosisControlId": "txtCollectionTime",
    "SiteInfoControlId": "",
    "Mandatory": "true",
    "isSelected": "false",
    "text": "Collection Time",
    "dbcolumn": "collectiontime"
  };

  mandateFieldsMap = {
    'AccessionMandateFields': 'AccessionMandateFields',
    'SendoutMandateFields': 'SendoutMandateFields',
    'RequisitionMandateFields': 'RequisitionMandateFields'
  };
  activityEntity: any;

  oldActObj={
    "Case Information":[],"Patient Information":[],"Supplemental Information":[],"Attachments":[],
    "Primary Insurance":[],"Secondary Insurance":[],"Tertiary Insurance":[],"Guarantor Insurance":[]
  }

  newActObj={
    "Case Information":[],"Patient Information":[],"Supplemental Information":[],"Attachments":[],
    "Primary Insurance":[],"Secondary Insurance":[],"Tertiary Insurance":[],"Guarantor Insurance":[]
  }

  constructor(private VitalHttpServices: VitalHttpServices, public _snackBar: MatSnackBar, private ngxService: NgxUiLoaderService, public commonService: CommonService,public activityService: ActivityTrackerService,public datePipe: DatePipe) { }

  ngOnInit(): void {
   this.getMandateDropdown();
    this.getMandatefield();
    this.selected= this.mandateObject[0].value;
    this.GetButtondetails();
    this.checkCasetypeStatus();
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
  }
  checkData(allfields: any, mandateObject: any): any {
    for (let i = 0; i < mandateObject.length; i++) {
      if (!(mandateObject[i].value in allfields)) {
        mandateObject.splice(i, 1);
        i--;
      }
    }
    return mandateObject;
  }

  getMandateDropdown() {
    let manObj = [
      {name:'Accession Page',value:'AccessionMandateFields'},
      {name:'Sendout Page',value:'SendoutMandateFields'},
      {name:'Requisition Page',value:'RequisitionMandateFields'}
    ];

    let optionsForContext = {
      'Facility': ['Sendout Page', 'Requisition Page']
    }

    let selectedContext = sessionStorage.getItem('context');

    if(selectedContext !== 'Facility') {
      this.mandateObject = manObj;
    } else {
      this.mandateObject = manObj.filter((item: any) => optionsForContext[selectedContext].includes(item.name));
    }
  }

  checkCasetypeStatus() {
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('casetypeDetails');
    let queryVariable = { "accid": sessionStorage.getItem("AccountID"), "casetype": this.templateData.GroupData.casetype.toString().trim() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.dbName).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.caseList = Resdata.data.submenuData;
        if(!this.hideSaveBtn)
        {
          this.hideSaveBtn=this.caseList[0]["Status"].toLowerCase() =='inactive'?true:false
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  closePopup() {
    this.showModal = false;
  }
  async getMandatefieldforcasetype() {
    let query = this.VitalHttpServices.GetQuery('getmandatoryfields');
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, this.dbName).subscribe(
     async data => {
        if (!data.errors) {
          var json = JSON.parse(data.data.submenuData[0].ExtAttrValue);
          this.Allfields = json;
          this.mandateObject=this.checkData(this.Allfields,this.mandateObject);
          if(this.defaultval){
            this.getData("AccessionMandateFields")
            this.changedtoAccessionORsendout=false;
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  compareEditData(submenuData: any, currentEditItem: any): any {
    throw new Error("Method not implemented.");
  }

  getMandatefield() {
    this.accid = sessionStorage.getItem("AccountID");
    if (this.casetype == 'All') {
      this.casetype = 'All';
    } else {
      this.casetype = this.templateData.secondarykeys.casetype;
    }
    const getobj={
      "accid": sessionStorage.getItem("AccountID"),
      "casetype": this.casetype
    }
    return this.VitalHttpServices.GetMandatoryfieldsOnCaseType(getobj, this.dbName).subscribe(data => {
      this.ngxService.stop();
      this.mandatefields = data;
      this.getMandatefieldforcasetype();
    },
    error => {
      this.ngxService.stop();
      console.log(error)
    });
  }

  mapData(allfields, mandatefields) {
    if (mandatefields && mandatefields[this.selectapplicable]) {
      for (let [key1, value1] of Object.entries(mandatefields[this.selectapplicable])) {
        for (let [key2, value2] of Object.entries(allfields[this.selectapplicable])) {
          if (key1 == key2) {
            for (let [key3, value3] of Object.entries(value1)) {
              for (let [key4, value4] of Object.entries(value2)) {
                if (key3 == key4) {
                  if (Array.isArray(value3)) {
                    value4.forEach(element => {
                      value3.forEach(value => {
                        if (value.text == element.text) {
                          element.isSelected = value.isSelected
                        }
                      });
                    });
                  }
                  else {
                    for (let [key5, value5] of Object.entries(value3)) {
                      for (let [key6, value6] of Object.entries(value4)) {
                        if (key6 == key5) {
                          if (Array.isArray(value6)) {
                            let arrayAllVal: any = value6
                            let arrayMandVal: any = value5
                            arrayMandVal.forEach(va => {
                              arrayAllVal.forEach(val => {
                                if (val.text == va.text) {
                                  val.isSelected = va.isSelected
                                }
                              })
                            })
                          }
                          else {
                            let objectAllVal: any = value6;
                            let objectMandVal: any = value5;
                            if (objectMandVal.text == objectAllVal.text) {
                              objectAllVal.isSelected = objectMandVal.isSelected
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  switchPage(event :any){
    let page = event.value;
    if(!this.isSaved && this.anyCheckboxChecked && !this.hideSaveBtn){
      if(page !== this.selectapplicable ){
        this.showModal = true;
        this.previousPage =this.selectapplicable
        this.selectapplicable =page;
      }
    }else{
      this.showModal=false;
      this.selectedcasetype=false;
      this.previousPage =this.selectapplicable
      this.selectapplicable =page;
      this.getData(this.selectapplicable)

    }
  }
  switchYesorNo(val?:boolean,selectapplicable?,isSave?){
    if(val !== true){
      this.showModal = false;
      this.selectapplicable=this.previousPage;
      this.selected = (this.selectapplicable === 'AccessionMandateFields')
      ? this.mandateObject[0].value : (this.selectapplicable === 'SendoutMandateFields')
        ? this.mandateObject[1].value
        : this.mandateObject[2].value;
    }else{
      this.showModal = false;
      if(this.anyCheckboxChecked && isSave) {
        this.onSave('SaveAndProceed');
        this.selectedcasetype = false;
        this.getData(selectapplicable)
      }else{
        this.selectedcasetype = false;
        this.getData(selectapplicable)
      }
    }
  }

  resetActivityObject(){
    this.oldActObj={
      "Case Information":[],"Patient Information":[],"Supplemental Information":[],"Attachments":[],
      "Primary Insurance":[],"Secondary Insurance":[],"Tertiary Insurance":[],"Guarantor Insurance":[]
    }

  }
  getActivityObject(data,objecttype?){
    if(objecttype == 'new'){
      return data.reduce((acc, item) => {
        acc[item.text] = {
            oldValue: item.isSelected =='true'?item.isSelected.toString():'false',
            newValue: item.isSelected
        };
        return acc;
    }, {});
    }else{
      return data.reduce((acc, item) => {
        acc[item.text] = {
            oldValue: item.isSelected,
            newValue: item.isSelected =='true'?item.isSelected.toString():'false'
        };
        return acc;
    }, {});
    }

  }


  getData(applicablepage: string) {
    // this.allCaseType = true;
    this.changedtoAccessionORsendout=true;
    this.anyCheckboxChecked=false;
    this.isSaved=false;
    this.defaultval=false;
    this.getMandatefield();
    this.resetActivityObject();
    this.selectapplicable = applicablepage;
    if (this.Allfields && Object.keys(this.Allfields).length > 0 && this.mandatefields && Object.keys(this.mandatefields).length > 0) {
      this.mapData(this.Allfields, this.mandatefields);
    }
    this.finalmandate = this.Allfields;
    this.flag= this.flag===0?1:0;
    if (applicablepage) {
      Object.entries(this.finalmandate).forEach(([key, value]) => {
        if (key == applicablepage) {
          Object.entries(value).forEach(([key, value]) => {
            if (key == "CaseInfoDetails") {
              this.caseinfoflag = true;
              this.caseinfoobjectval = value;
              this.caseinfoobjectval = this.caseinfoobjectval.CaseInformationProperties;
              this.oldActObj["Case Information"].push(this.getActivityObject(this.caseinfoobjectval))
            } else if (key == "PatientDetails") {
              this.PatientDetailsinfoflag = true;
              this.patientobjectval = value;
              this.patientobjectval = this.patientobjectval.PatientProperties;
              this.oldActObj["Patient Information"].push(this.getActivityObject(this.patientobjectval))
              Object.entries(value).forEach(([key, value1]) => {
                if (key == "InsuranceDetails") {
                  this.insurancedetailsflag = true;
                  Object.entries(value1).forEach(([inskey, insvalue]) => {
                    if (inskey == "PrimaryInsuranceProperties") {
                      this.primaryinsuranceflag = true;
                      this.primaryinsobjectval = insvalue;
              this.oldActObj["Primary Insurance"].push(this.getActivityObject(this.primaryinsobjectval))
                    } else if (inskey == "SecondaryInsuranceProperties") {
                      this.secondaryinsuranceflag = true;
                      this.secondaryinsobjectval = insvalue;
              this.oldActObj["Secondary Insurance"].push(this.getActivityObject(this.secondaryinsobjectval))
                    } else if (inskey == "TertiaryInsuranceProperties") {
                      this.ternaryinsuranceflag = true;
                      this.ternaryinsobjectval = insvalue;
              this.oldActObj["Tertiary Insurance"].push(this.getActivityObject(this.ternaryinsobjectval))
                    } else if (inskey == "GurantorsProperties") {
                      this.gurantorinsuranceflag = true;
                      this.gurantorinsobjectval = insvalue.filter(property => !['SubscribeInfo_txtPatientRelativeDob', 'SubscribeInfo_txtPatientRelativeGender', 'SubscribeInfo_txtPatientRelativeSSNNo'].includes(property.CorrectionControlId));
              this.oldActObj["Guarantor Insurance"].push(this.getActivityObject(this.gurantorinsobjectval))
                    }
                  })
                }

              })

            } else if (key == "SupplementalInformationDetails") {
              this.supplimentinfoflag = true;
              this.supplimentobjectval = value;
              this.supplimentobjectval = this.supplimentobjectval.SupplementalInfoProperties;
              this.oldActObj["Supplemental Information"].push(this.getActivityObject(this.supplimentobjectval))
            } else if (key == "AttachmentMandateFields") {
              this.attachmentinfoflag = true;
              this.attachmentmandobjectval = value
              this.attachmentmandobjectval = this.attachmentmandobjectval.SubCategoryProperties;
              this.oldActObj["Attachments"].push(this.getActivityObject(this.attachmentmandobjectval))
            }
          })
        }
      });
    }
  }

  updateaccessiondmandatefield: any = this.mandatefields;
  savemandatefield: any = {
    AccessionMandateFields: {
      CaseInfoDetails: {
        CaseInformationProperties: []
      },
      PatientDetails: {
        InsuranceDetails: {
          GurantorsProperties: [],
          PrimaryInsuranceProperties: [],
          SecondaryInsuranceProperties: [],
          TertiaryInsuranceProperties: []
        },
        PatientProperties: []
      },
      SupplementalInformationDetails: {
        SupplementalInfoProperties: []
      },
      AttachmentMandateFields: {
        SubCategoryProperties: []
      }
    },
    SendoutMandateFields: {
      CaseInfoDetails: {
        CaseInformationProperties: []
      },
      PatientDetails: {
        InsuranceDetails: {
          GurantorsProperties: [],
          PrimaryInsuranceProperties: [],
          SecondaryInsuranceProperties: [],
          TertiaryInsuranceProperties: []
        },
        PatientProperties: []
      },
      SupplementalInformationDetails: {
        SupplementalInfoProperties: []
      },
      AttachmentMandateFields: {
        SubCategoryProperties: []
      }
    },
    RequisitionMandateFields: {
      CaseInfoDetails: {
        CaseInformationProperties: []
      },
      PatientDetails: {
        InsuranceDetails: {
          GurantorsProperties: [],
          PrimaryInsuranceProperties: [],
          SecondaryInsuranceProperties: [],
          TertiaryInsuranceProperties: []
        },
        PatientProperties: []
      },
      SupplementalInformationDetails: {
        SupplementalInfoProperties: []
      },
      AttachmentMandateFields: {
        SubCategoryProperties: []
      }
    },

  }


  updateCheckboxValue(caseInfoProperty?: any, event?: MatCheckbox,isAllSelected?:any) {
    if (caseInfoProperty || isAllSelected) {
      this.anyCheckboxChecked=true;
      this.isSaved=false;
      if(caseInfoProperty){
        caseInfoProperty.isSelected = event.checked.toString();
      }
      this.newcaseinfoobjectval = [];
      this.newpatientobjectval = [];
      this.primInsurancepatientobjectval = [];
      this.secInsurancepatientobjectval = [];
      this.ternaryInsurancepatientobjectval = [];
      this.GaurantInsurancepatientobjectval = [];
      this.newsupplimentobjectval = [];
      this.newattachmentobjectval = [];

      var newCaseInfoObjectFalseVal = [];
      var newPatientObjectFalseVal = [];
      var primInsurancePatientObjectFalseVal = [];
      var secInsurancePatientObjectFalseVal = [];
      var ternaryInsurancePatientobjectFalseVal = [];
      var GaurantInsurancePatientObjectFalseVal = [];
      var newSupplimentObjectFalseVal = [];
      var newAttachmentObjectFalseVal = [];

      if (Array.isArray(this.caseinfoobjectval)) {
        for (let val of this.caseinfoobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newcaseinfoobjectval.push(val);
            }
              newCaseInfoObjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.patientobjectval)) {
        for (let val of this.patientobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newpatientobjectval.push(val);
            }
              newPatientObjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.primaryinsobjectval)) {
        for (let val of this.primaryinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.primInsurancepatientobjectval.push(val);
            }
              primInsurancePatientObjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.secondaryinsobjectval)) {
        for (let val of this.secondaryinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.secInsurancepatientobjectval.push(val);
            }
              secInsurancePatientObjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.ternaryinsobjectval)) {
        for (let val of this.ternaryinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.ternaryInsurancepatientobjectval.push(val);
            }
              ternaryInsurancePatientobjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.gurantorinsobjectval)) {
        for (let val of this.gurantorinsobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.GaurantInsurancepatientobjectval.push(val);
            }
              GaurantInsurancePatientObjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.supplimentobjectval)) {
        for (let val of this.supplimentobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newsupplimentobjectval.push(val);
            }
              newSupplimentObjectFalseVal.push(val);

          });
        }
      }

      if (Array.isArray(this.attachmentmandobjectval)) {
        for (let val of this.attachmentmandobjectval) {
          Object.entries(val).forEach(([key, value1]) => {
            if (key === "isSelected" && value1 === "true") {
              this.newattachmentobjectval.push(val);
            }
              newAttachmentObjectFalseVal.push(val);

          });
        }
      }
    }

    let caseInfo = Array.from(new Set(this.newcaseinfoobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let patientInfo = Array.from(new Set(this.newpatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let primInsurance = Array.from(new Set(this.primInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let secInsurance = Array.from(new Set(this.secInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let terInsurance = Array.from(new Set(this.ternaryInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let GaurantInsurance = Array.from(new Set(this.GaurantInsurancepatientobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let newsuppliment = Array.from(new Set(this.newsupplimentobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let newattachment = Array.from(new Set(this.newattachmentobjectval.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));


    let fcaseInfo = Array.from(new Set(newCaseInfoObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fpatientInfo = Array.from(new Set(newPatientObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fprimInsurance = Array.from(new Set(primInsurancePatientObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fsecInsurance = Array.from(new Set(secInsurancePatientObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fterInsurance = Array.from(new Set(ternaryInsurancePatientobjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fGaurantInsurance = Array.from(new Set(GaurantInsurancePatientObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fnewsuppliment = Array.from(new Set(newSupplimentObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
    let fnewattachment = Array.from(new Set(newAttachmentObjectFalseVal.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));

    this.newActObj["Case Information"]=[]
    this.newActObj["Patient Information"]=[]
    this.newActObj["Primary Insurance"]=[]
    this.newActObj["Secondary Insurance"]=[]
    this.newActObj["Tertiary Insurance"]=[]
    this.newActObj["Guarantor Insurance"]=[]
	  this.newActObj["Supplemental Information"]=[]
    this.newActObj["Attachments"]=[]

    this.newActObj["Case Information"].push(this.getActivityObject(fcaseInfo,'new'))
    this.newActObj["Patient Information"].push(this.getActivityObject(fpatientInfo,"new"))
    this.newActObj["Primary Insurance"].push(this.getActivityObject(fprimInsurance,"new"))
    this.newActObj["Secondary Insurance"].push(this.getActivityObject(fsecInsurance,"new"))
    this.newActObj["Tertiary Insurance"].push(this.getActivityObject(fterInsurance,"new"))
    this.newActObj["Guarantor Insurance"].push(this.getActivityObject(fGaurantInsurance,"new"))
    this.newActObj["Supplemental Information"].push(this.getActivityObject(fnewsuppliment,"new"))
    this.newActObj["Attachments"].push(this.getActivityObject(fnewattachment,"new"))

    if (this.selectapplicable == "AccessionMandateFields") {
      this.savemandatefield.AccessionMandateFields.CaseInfoDetails.CaseInformationProperties = caseInfo;
      this.savemandatefield.AccessionMandateFields.PatientDetails.PatientProperties = patientInfo;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.PrimaryInsuranceProperties = primInsurance;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.SecondaryInsuranceProperties = secInsurance;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.TertiaryInsuranceProperties = terInsurance;
      this.savemandatefield.AccessionMandateFields.PatientDetails.InsuranceDetails.GurantorsProperties = GaurantInsurance;
      this.savemandatefield.AccessionMandateFields.SupplementalInformationDetails.SupplementalInfoProperties = newsuppliment;
      this.savemandatefield.AccessionMandateFields.AttachmentMandateFields.SubCategoryProperties = newattachment;
    } else if (this.selectapplicable == "SendoutMandateFields") {
      this.savemandatefield.SendoutMandateFields.CaseInfoDetails.CaseInformationProperties = caseInfo;
      this.savemandatefield.SendoutMandateFields.PatientDetails.PatientProperties = patientInfo;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.PrimaryInsuranceProperties = primInsurance;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.SecondaryInsuranceProperties = secInsurance;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.TertiaryInsuranceProperties = terInsurance;
      this.savemandatefield.SendoutMandateFields.PatientDetails.InsuranceDetails.GurantorsProperties = GaurantInsurance;
      this.savemandatefield.SendoutMandateFields.SupplementalInformationDetails.SupplementalInfoProperties = newsuppliment;
      this.savemandatefield.SendoutMandateFields.AttachmentMandateFields.SubCategoryProperties = newattachment;
    }else if (this.selectapplicable == "RequisitionMandateFields") {
      this.savemandatefield.RequisitionMandateFields.CaseInfoDetails.CaseInformationProperties = caseInfo;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.PatientProperties = patientInfo;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.PrimaryInsuranceProperties = primInsurance;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.SecondaryInsuranceProperties = secInsurance;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.TertiaryInsuranceProperties = terInsurance;
      this.savemandatefield.RequisitionMandateFields.PatientDetails.InsuranceDetails.GurantorsProperties = GaurantInsurance;
      this.savemandatefield.RequisitionMandateFields.SupplementalInformationDetails.SupplementalInfoProperties = newsuppliment;
      this.savemandatefield.RequisitionMandateFields.AttachmentMandateFields.SubCategoryProperties = newattachment;
    }

  }

  onReset() {
    // Reset the data
    // Clear the selected values
    this.caseinfoobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.patientobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.primaryinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.secondaryinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.ternaryinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.gurantorinsobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.supplimentobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.attachmentmandobjectval.forEach((val: any) => (val.isSelected = "false"));
    this.anyCheckboxChecked=false;
    this.getData(this.selectapplicable);
  }

  checkAndAddDefaultICD(field: string) {
    let caseInformationProperties = (this.savemandatefield?.[field]?.CaseInfoDetails?.CaseInformationProperties) ?? [];
    const hasTxtICD = caseInformationProperties.some((property: any) => property.AccessionControlId === 'txtICD');
    if (!hasTxtICD) {
      caseInformationProperties.push(this.defaultMandateICD);
    }
    this.savemandatefield[field].CaseInfoDetails.CaseInformationProperties = caseInformationProperties;
  }

  checkAndAddDefaultCollectionTime(field: string) {
    let caseInformationProperties = (this.savemandatefield?.[field]?.CaseInfoDetails?.CaseInformationProperties) ?? [];
    const hasTxtCollectionTime = caseInformationProperties?.some((property: any) => property.AccessionControlId === 'txtCollectionTime');
    if (!hasTxtCollectionTime) {
      caseInformationProperties.push(this.defaultMandateCollectionTime);
    }
    this.savemandatefield[field].CaseInfoDetails.CaseInformationProperties = caseInformationProperties;
  }


  onSave(data?:string) {
    this.ngxService.start();
    if(data ==='SaveAndProceed'){
      this.selectapplicable=this.previousPage;
    }
    //code added to add ICD code as non mandatory if the checkbox is not clicked
    if (this.mandateFieldsMap.hasOwnProperty(this.selectapplicable)) {
      this.checkAndAddDefaultICD(this.mandateFieldsMap[this.selectapplicable]);
    }
    //code added to add Collection time as non mandatory if the checkbox is not clicked
    if (this.mandateFieldsMap.hasOwnProperty(this.selectapplicable)) {
      this.checkAndAddDefaultCollectionTime(this.mandateFieldsMap[this.selectapplicable]);
    }

    this.saveMandatefieldstring = JSON.stringify(this.savemandatefield);
    const obj = {
      "accid": sessionStorage.getItem("AccountID"),
      "casetype": this.casetype,
      "mandatefields": this.saveMandatefieldstring,
      "applicaplepage": this.selectapplicable,
      "forall": this.forallcasetype
    }

    this.VitalHttpServices.UpdateMandatoryfieldsOnCaseType(obj, this.dbName).subscribe(async res => {
    this.ngxService.stop();
      this._snackBar.open('Saved Successfully.', 'Close');
      this.isSaved=true;
      let pageType;
      if(this.selectapplicable =='AccessionMandateFields'){
          pageType='Accession Page'
      }else if(this.selectapplicable =='SendoutMandateFields'){
        pageType='Send Out Page'
      }else if(this.selectapplicable =='RequisitionMandateFields'){
        pageType='Requisition Page'
      }
      const tempFinalObj: any = {};
        if (!tempFinalObj[pageType]) {
          tempFinalObj["All Casetypes"]={}
          tempFinalObj[pageType] = [];
        }

      let tempNewOldObject = JSON.parse(JSON.stringify(this.newActObj));
      tempFinalObj[pageType].push(await this.updateOldActObject(this.oldActObj, this.newActObj));
      this.anyCheckboxChecked=false;
      let transactionid=this.commonService.generateGuid();
      if(this.forallcasetype){
        tempFinalObj["All Casetypes"]={oldValue:"No",newValue:this.forallcasetype.toLowerCase()=='all'?"Yes":"No"}
      }else{
        delete tempFinalObj["All Casetypes"]
      }
      if(Object.keys(tempFinalObj[pageType][0]).length > 0){
        await this.createActivityObject('',pageType,this.templateData.menuURL,'Edit',tempFinalObj,transactionid)
      }else{
        delete tempFinalObj[pageType]
        await this.createActivityObject('',pageType,this.templateData.menuURL,'Edit',tempFinalObj,transactionid)
      }
      await this.getMandatefield();
      this.oldActObj=tempNewOldObject;
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  createActivityObject(entityid, changedValue, entityType, actionType, obj, transactionId, uploadedFile?: any) {
    try {
      let temptype=actionType=='Edit'?"Modified":"Created"
      let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
      let date = new Date();
      let entityTypeContext: any
      let deployid = sessionStorage.getItem('DeploymentKey')
      this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);
      this.createActivityInput.productId = this.VitalHttpServices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, '');
      this.createActivityInput.product = 'VitalDx';
      this.createActivityInput.customerId = sessionStorage.getItem('org_id');
      this.createActivityInput.customerName = sessionStorage.getItem('Org_Name');
      this.createActivityInput.entityType = entityType;
      this.createActivityInput.actionType = 'audit';
      this.createActivityInput.userName = localStorage.getItem('user_name');
      this.createActivityInput.userId = localStorage.getItem('UserEmail');
      this.createActivityInput.entityId = entityid;
      this.createActivityInput.locationId = sessionStorage.getItem('Locationid');
      this.createActivityInput.locationName = sessionStorage.getItem('locationName');
      this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
      this.createActivityInput.transactionId = transactionId;
      this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
      this.createActivityInput.ticketInfo = actionType + " " + entityType;
      this.createActivityOperations.extendedAttributes = obj;
      this.createActivityOperations.attachments = (uploadedFile == null ? null : [uploadedFile]);
      this.createActivityInput.operation = this.createActivityOperations;
      this.createActivityInput.context = [{'key':'parentMenu','value':'casetype'},{'key':'casetype','value':this.templateData.secondarykeys.casetype}];
      this.createActivityOperations.info = temptype+" "+changedValue+ " in "+this.templateData.menuURL;
      if (Object.keys(this.createActivityOperations.extendedAttributes).length != 0 || uploadedFile != null) {
        this.VitalHttpServices.CreateActivity(this.createActivityInput)
          .subscribe((res) => {
           });
      }
    } catch (error) {
      console.error("Error occured while creating activity");
    }
  }


   updateValues(oldItem: any, newItem: any): void {
    for (const key in newItem) {
      if (newItem.hasOwnProperty(key)) {
        if (typeof newItem[key] === 'object' && newItem[key] !== null) {
          if (oldItem[key]) {
            this.updateValues(oldItem[key], newItem[key]);
          }
        } else if (key === 'newValue' && newItem[key] !== '' && oldItem[key] !== newItem[key]) {
          oldItem[key] = newItem[key].toString();
        }
      }
    }
  }

  filterValidActObject(obj) {
    for (let [key, value] of Object?.entries(obj)) {
      for (let [key1, value1] of Object?.entries(value[0])) {
        if (value1['newValue'] == '') {
          delete value[0][key1];
        }

        if(value1['newValue'] !=='' && value1['newValue'].toString() == value1['oldValue'].toString()){
          delete value[0][key1];
        }
      }
    }

    let finalvalidobj=this.removeEmptyObj(obj)
    return finalvalidobj;
  }

  removeEmptyObj(obj) {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].filter(item => Object.keys(item).length !== 0);
        if (obj[key].length === 0) {
          delete obj[key];
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.removeEmptyObj(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }
    return obj;
  }

   updateOldActObject(oldObject: any, newObject: any) {
    for (const section in newObject) {
      if (newObject.hasOwnProperty(section)) {
        newObject[section].forEach((newItem: any, index: number) => {
          if (oldObject[section] && oldObject[section][index]) {
            this.updateValues(oldObject[section][index], newItem);
          }
        });
      }
    }

    let finalobj=this.filterValidActObject(oldObject)
    return finalobj;
  }



  ApplyToAll(event: MatCheckbox) {
    this.selectedcasetype = event.checked;
    if (this.selectedcasetype) {
      this.forallcasetype = "All";
      this.updateCheckboxValue(undefined,undefined,this.selectedcasetype)
    } else {
      this.forallcasetype = "";
      this.updateCheckboxValue(undefined,undefined,this.selectedcasetype)
    }
  }

  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    //   this.datashare.SubmenuAction.subscribe(data => {
    //     if (data) {
    //       // this.actionButtonDetails = data;
    //       this.GetButtonAccess(data);
    //     }
    //   },
    //     error => console.error(error));
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Save":
          this.hideSaveBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          // this.anyCheckboxChecked=false;
          break;
      }
    }
  }

  isAnyCheckboxChecked(): boolean {
    // Check if any checkbox is selected in primary, secondary, tertiary, and guarantor tabs
    return this.primaryinsobjectval.some(property => property.isSelected === 'true') ||
      this.secondaryinsobjectval.some(property => property.isSelected === 'true') ||
      this.ternaryinsobjectval.some(property => property.isSelected === 'true') ||
      this.gurantorinsobjectval.some(property => property.isSelected === 'true') ||
      this.caseinfoobjectval.some(property => property.isSelected === 'true') ||
      this.patientobjectval.some(property => property.isSelected === 'true') ||
      this.attachmentmandobjectval.some(property => property.isSelected === 'true') ||
      this.supplimentobjectval.some(property => property.isSelected === 'true');
  }

}
