import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { ActivityTrackerService } from '../core/services/activity-tracker.service';
import { ShortenTextPipe } from '../core/utility/shortentext.pipe';
import { StripHtmlPipe } from '../core/utility/striphtml.pipe';
import { TemplateViewService } from '../core/utility/template-view.service';
import { searchFilterPipetemplate } from '../core/utility/tripfilter.pipe';
import { convertLocalDateTime } from '../labadmin/components/Utility Functions';
import { MenuFlagsConfigurationComponent } from '../commoncomponents/menu-flags-configuration/menu-flags-configuration.component';
import { LabadminService } from '../labadmin/labadmin.service';
import { MatDatepicker } from '@angular/material/datepicker';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-templates-listing',
  templateUrl: './templates-listing.component.html',
  styleUrls: ['./templates-listing.component.scss'],
  providers: [ searchFilterPipetemplate, ShortenTextPipe, StripHtmlPipe ]
})
export class TemplatesListingComponent implements OnInit {
  searchText: string;
  templatefilter: object = {};
  gridheader: any[];
  GridData: any[];
  backupGridData: any[];
  groupByParam: any[];
  groupdata: any[];
  groupByKeys = [];
  Context: any;
  filename: any;
  ActiveGridData = [];
  InactiveGridData = [];
  ActiveCount = 0
  InactiveCount = 0
  AllCount = 0
  viewScreen: boolean = false
  listScreen: boolean = true
  ViewRowData: any = {}
  oldRowData: any = {}
  organizationId: any;
  createChanged: any;
  editChanged: any;
  customisedJsonData: any;
  recievedObjectFromView: any = {};
  searchPlaceHolder: string;
  selectedButton: string | null = 'All';
  activityEntity: any;
  filterDropdownList: any = [];
  templateCustomObject: any = [];
  selectedListFilters: any = {};
  isShowActiveTabs = false;
  selectedCaseUser = 'Group Case Comments';
  toolTipText: any;
  optionToolTipText: any;
  isRedirecting = false;
  sortableGridData: any;
  originalSortableGridData: any;
  sortModeEnabled = false;
  scrollHistory : number = 0;
  finalSortedArray: any = [];
  disableSort = false;
  selectedFilterCaseType = 'All Case Types';
  selectedFilterUserType = 'Group Case Comments';
  isSortSaveClicked:any = false;
  orginalTemplateCustomObject: any;
  isMandoryFieldAvailable = false;
  showListAfterDataLoaded = false;
  listLoading=false
  showDiv:boolean = false;

  @Input() gridHeader
  @Input() context
  @Input() hideDelete = true;
  @Input() hideEdit = true;
  @Input() hideDowload = true;
  @Input() isVeiwPage = false;
  @Input() hideCreate = true;
  @Input() hideExport = true;
  @Input() hideUpload = true;
  @Input() hideCopy = true;
  @Input() hideFormCopy = true;
  @Input() groupBy
  @Input() gridData
  @Input() templateData: any;
  @Input() placeHolders: any;
  @Input() subMenuCardModel: any;
  @Input() selectedListOption: any;
  @Input() saveOrEditCompleted: any;
  @Input() showHeaderModule = true;
  @Input() showTopButtons = true;
  @Input() hideSort = false;
  @Input() isUserLevel = false;
  @Input() isAdmin3 = true;
  @Input() hideManage = true;
  @Input() hideMigrate = true;
  @Input() enableMigrate = true;
  @Input() caseTypeUserRoles: any;
  @Input() selectedRole: any;
  @Input() gridwidth: any;
  @Input() isAutoCompleteList = false;
  @Input() customListActiveTabs = false;
  @Input() isP4deployment = false;
  @Input() note: any;
  @Input() enableorDisableSeqOnDefault: boolean;
  @Input() listContext:string=''
  @Input() addEditContext:string=''
  @Input() isMigrateForPayers=''
  @Input() selectedCaseType: any;
  @Input() exportExcelFields: any;
  @Input() isShowToggle: any;
  @Input() sortedOrder: any = 'UNS';
  @Input() sortingTableColumnName: any = '';
  @Input() sequneceProperty: any = '';
  @Input() templateNameProperty: any = '';
  @Input() conditionDisableReOrder:boolean;
  @Input() searchBarWidth: any;
  @Input() isExternalOrgTab:boolean;
  @Input() dateRangeOptions :any = [];
  @Input() formNoteMessage: any = '';
  @Input() showFlagsIcon: boolean = true;

  @Output() closingListTemplate: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteTemplateClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveOrEditEventFromListingTriggered = new EventEmitter<any>();
  @Output() copyTemplateEventTriggered = new EventEmitter<any>();
  @Output() uploadTemplateEventTriggered = new EventEmitter<any>();
  @Output() editTemplateClicked = new EventEmitter<any>();
  @Output() editTemplateToggleClicked = new EventEmitter<any>();
  @Output() CreateTemplateClicked = new EventEmitter<any>();
  @Output() emitFilters = new EventEmitter<any>();
  @Output() manageEventTriggered = new EventEmitter<any>();
  @Output() migrateEventTriggered = new EventEmitter<any>();
  @Output() roleChangedEventTriggered = new EventEmitter<any>();
  @Output() sortingChanged = new EventEmitter<any>();
  @Output() sortCompleted = new EventEmitter<any>();  @Output()
  @Output() toggleChangeEmitter : EventEmitter<any> = new EventEmitter();

  createBtnCopy: boolean;
  isBtnDisabled: boolean = false;
  isHideTopSectionInCreate: boolean = false;
  offlineFilterType: string = 'upload';

  keysForStatus=['isactive','active','status','payersstatus'];
  @ViewChild('picker') picker: MatDatepicker<Date>;

  selectedRange: string = 'Today';  // Default selection
  startDate: Date | null = null;
  endDate: Date = new Date();  // Default end date is current date
  output: string = '';

  constructor(private searchfiltersTemplate: searchFilterPipetemplate,
    private templateViewService: TemplateViewService,
    private commonService: CommonService,
    private ngxService: NgxUiLoaderService,
    private VitalHttpServices: VitalHttpServices,
    private changeDetectorRef: ChangeDetectorRef,
    public activityService: ActivityTrackerService,
    private labAdminService : LabadminService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.templateViewService.globalMasterDataForQueries['CaseType'] = this.templateData?.secondarykeys?.casetype ? this.templateData?.secondarykeys?.casetype?.toString() : '';
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    let fieldObject = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
    this.isShowActiveTabs = fieldObject?.hasStatusColumn;
    this.isHideTopSectionInCreate = fieldObject?.isHideTopSectionInCreate;

    this.selectedCaseUser = this.isP4deployment ? 'All Users':'Group Case Comments';
    // this.isShowActiveTabs = fieldObject?.hasStatusColumn;
    if(fieldObject?.['listFilterDropdownFields']) {
      let configData: any = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
      configData['listFilterDropdownFields'] = configData['listFilterDropdownFields'].filter((item: any) => {
        if (this.isUserLevel) {
          return (item?.properties?.visibility === 'user' || item?.properties?.visibility === 'both')
        } else {
          return (item?.properties?.visibility === 'org' || (item?.properties?.visibility === 'both' || item?.properties?.showInLabAdmin) || !item?.properties?.visibility)
        }
      });
      this.getFilterDropDowns(configData['listFilterDropdownFields'], fieldObject?.['mandatoryFields']);
    } else {
      this.isMandoryFieldAvailable = true;
      this.showListAfterDataLoaded = true;
    }

    this.createBtnCopy = this.hideCreate;
    this.isFilterByCase(this.selectedListFilters);
    this.checkSortButton();
  }

  ngOnChanges(changes: SimpleChanges) {

    this.GridData = this.gridData?._pgView?.length ? this.gridData?._pgView : [];
    this.filename = this.context
    this.viewScreen = false;
    this.ActiveCount = 0
    this.InactiveCount = 0
    this.AllCount = 0
    this.ActiveGridData = [];
    this.InactiveGridData = [];
    this.backupGridData = this.GridData;
    this.alterGridData();
    this.selectButton(this.selectedButton);
    this.activityEntity.entityId = '';
    if (this.saveOrEditCompleted && changes?.saveOrEditCompleted?.currentValue !== changes?.saveOrEditCompleted?.previousValue) {
      if(this.saveOrEditCompleted !== 'failed' || this.saveOrEditCompleted !== 'error') {
        if(this.orginalTemplateCustomObject) {
          if(['Adequacy Codes', 'Case Comments', 'Worklists', 'Microscopic Codes'].includes(this.filename)) {
            this.refreshFilterOptions();
          }
        }
      }
    }
    if(changes?.gridData)
      this.listLoading=false
  }

  refreshFilterOptions() {
    this.disableSort = true;
    let tempFilterDropdown = structuredClone(this.orginalTemplateCustomObject);
    this.templateCustomObject = [...[]];
    this.templateCustomObject = tempFilterDropdown;
    this.selectedListFilters = {...{}};
  }

  async getFilterDropDowns(fieldObject, mandatoryFields = null) {
    await this.templateViewService.getAllMasterData(null, null, this.subMenuCardModel, this.templateData, null, fieldObject);

    let filteredFieldObject = structuredClone(fieldObject);

    if(this.isP4deployment !== false) {
      fieldObject = filteredFieldObject.filter((item: any) => item?.['properties']?.['isP4'] !== false);
    } else {
      fieldObject = filteredFieldObject.filter((item: any) => item?.['properties']?.['isP4'] !== true);
    }

    if(this.filename === 'Sites') {
      fieldObject[0]['dummyValue']['itemName'] = this.filename == 'Sites' ? this.selectedCaseType : fieldObject[0]?.['dummyValue']['itemName'];
    }

    this.templateCustomObject = structuredClone(fieldObject);
    this.orginalTemplateCustomObject = structuredClone(fieldObject);

    if(mandatoryFields?.length && !this.isAdmin3) {
      this.isMandoryFieldAvailable = this.checkMandatoryValues(mandatoryFields, this.orginalTemplateCustomObject);
      this.hideEdit = !this.isMandoryFieldAvailable ? true : this.hideEdit;
      this.hideDelete = !this.isMandoryFieldAvailable ? true : this.hideDelete;
    } else if(this.isAdmin3 || !mandatoryFields){
      this.isMandoryFieldAvailable = true;
    }
    this.showListAfterDataLoaded = true;
  }

  checkMandatoryValues(mandatoryArray, sourceArray) {
    return mandatoryArray.every((mandatoryItem) => {
      const sourceItem = sourceArray.find(item => item.columnName === mandatoryItem);
      return (
        sourceItem &&
        sourceItem.properties &&
        sourceItem.properties.source &&
        sourceItem.properties.source.options?.length > 1
      );
    });
  }

  alterGridData() {
    for (let i = 0; i < this.backupGridData?.length; i++) {
      for (const [key, value] of Object.entries(this.backupGridData[i])) {
        if (key === 'Is_Default' || key === 'Default' || key === 'Abnormal' || key === 'IsDefault') {
          if (value == 'Yes' || value == true) {
            this.backupGridData[i][key] = 'Yes'
          } else {
            this.backupGridData[i][key] = 'No'
          }
        }
        // if (key.toLowerCase() === 'isactive' || key.toLowerCase() === 'active' || key.toLowerCase() === 'status') {
        if (key === 'IsActive' || key === 'Active' || key === 'Status'  || key.toLowerCase() === 'status' || key.toLowerCase() === 'payersstatus' || key.toLowerCase() === 'isactive' || key.toLowerCase() === 'active') {
          if (value == 'Yes' || value == true || value == 'Active') {
            this.backupGridData[i][key] = 'Active';
            this.ActiveGridData.push(this.GridData[i])
          } else {
            this.backupGridData[i][key] = 'Inactive';
            this.InactiveGridData.push(this.GridData[i])
          }
        }
      }
      this.AllCount = this.backupGridData.length;
      this.ActiveCount = this.ActiveGridData.length;
      this.InactiveCount = this.InactiveGridData.length;
      this.isSortSaveClicked = false;
    }
  }

  selectButton(buttonId: string): void {
    this.selectedButton = buttonId;
    this.checkSortButton();
    this.GridChange(buttonId);
  }

  displayedColumns(item: any, header: any) {
    return item[header.key]
  }

  GridChange(val) {
    if (val == 'Active') {
      this.GridData = this.ActiveGridData
    } else if (val == 'InActive' || val == 'Inactive') {
      this.GridData = this.InactiveGridData
    } else {
      this.GridData = this.backupGridData;
    }
  }

  onToggleChange(item,event){
    event.stopPropagation();
    if(['Manage Configurations'].includes(this.filename)){
      this.editTemplateToggleClicked.emit({ action: 'edit', rowData: item, event: event });
    }
    else{
      this.editTemplateClicked.emit({ action: 'edit', rowData: item, event: event });
    }
  }

  filtertemplates() {
    for (let i = 0; i < this.gridHeader?.length; i++) {
      this.templatefilter[this.gridHeader[i].key] = this.searchText
    }
    this.updateFilterCount()
  }

  updateFilterCount() {
    const filterdData=this.searchfiltersTemplate.transform(this.GridData, this.templatefilter)
    let activeCount=0,inActiveCount=0;
    for (let i = 0; i < filterdData?.length; i++) {
      if(this.isShowActiveTabs) {
        for (const [key, value] of Object.entries(filterdData[i])) {
          if (this.keysForStatus.includes(key.toLowerCase().trim())) {
            if (value == 'Yes' || value == true || value == 'Active') {
              activeCount++;
            } else {
              inActiveCount++;
            }
          }
        }
      } else {
        activeCount++;
      }
  }
  this.ActiveCount=activeCount;
  this.InactiveCount=inActiveCount;
  this.AllCount=activeCount+inActiveCount;
}

  // Export Excel with file Name and sheet name
  ExportExcel() {
    let orgid = sessionStorage.getItem('org_id') && sessionStorage.getItem('org_id') != '' ? sessionStorage.getItem('org_id') : this.labAdminService.organizationId
    let filename = '';

    filename = this.filename === 'Observation & Quantitation Templates'
    ? 'Observation&Quantitation'
    : this.filename;

    let isOutsideOrgFeatures = ['Manage Configurations'];

    if (this.isAdmin3 && !isOutsideOrgFeatures?.includes(this.filename)) {
        filename += '_' + orgid;
    }

    filename += '.xlsx';
    let excelFinalData = structuredClone(this.GridData);

    if (this.templateData?.menuURL?.toString()?.toLowerCase() === 'workgroups') {
      for (let i = 0; i < excelFinalData.length; i++) {
        delete excelFinalData[i].tabDisplayName;
        delete excelFinalData[i].role;
        excelFinalData[i].customWorklist = excelFinalData[i].customWorklist.join(',');
      }
    }

    else if (this.templateData?.menuURL?.toString()?.toLowerCase() === 'report signatories'){

      for (let i = 0; i < excelFinalData.length; i++) {
      delete excelFinalData[i].Id;
      excelFinalData[i].EntityName = excelFinalData[i].EntityName + ' (' + excelFinalData[i].EntityNameId + ')';
      delete excelFinalData[i].EntityNameId;
      }
    }

    else if (this.templateData?.menuURL?.toString()?.toLowerCase() === 'auto assignment rules') {
      for (let i = 0; i < excelFinalData.length; i++) {
        if (this.isExternalOrgTab) {
          delete excelFinalData[i].Name;
        }
      }
    }
    else if(this.templateData?.menuURL?.toString()?.toLowerCase() === 'l2l mapping'){
      for (let i = 0; i < excelFinalData.length; i++) {
        delete excelFinalData[i].LabBguid;
        delete excelFinalData[i].LabAguid;
        delete excelFinalData[i].accountid;
        delete excelFinalData[i].AssociatedAccount;
        delete excelFinalData[i].L2LConfigId;
        }
    }
    else if (this.templateData?.menuURL?.toString().toLowerCase() === 'screening comments') {
        excelFinalData = excelFinalData.map(({ Sequence, ...rest }) => rest);
    }

    let ws;

    // Check if exportExcelFields is defined and has keys
    if (this.exportExcelFields && Object.keys(this.exportExcelFields).length > 0) {
        // Filter and map data based on exportExcelFields
        let filteredData = structuredClone(this.searchfiltersTemplate.transform(excelFinalData, this.templatefilter));
        const exportData = filteredData.map(item => {
            let filteredItem = {};
            for (let key in this.exportExcelFields) {
                if (item[key] !== undefined) {
                    filteredItem[this.exportExcelFields[key]['displayName']] = this.exportExcelFields[key]['isDate']  ? (this.exportExcelFields[key]['isDate'] && item[key] !== 'Not Specified') ? convertLocalDateTime(item[key]) : item[key] || '' : item[key] || '';
                }
            }
            return filteredItem;
        });
        // let filteredDataForExport = this.searchfiltersTemplate.transform(exportData, this.templatefilter);
        ws = XLSX.utils.json_to_sheet(exportData);
    } else {
        ws = XLSX.utils.json_to_sheet(this.searchfiltersTemplate.transform(excelFinalData, this.templatefilter));
    }

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.filename ==='Observation & Quantitation Templates' ?'Observation & Quantitation': this.filename );
    XLSX.writeFile(wb, filename);
  }

  RedirectToView(item, $event) {
    // this.saveOrEditCompleted = undefined;
    // this.createChanged = undefined;
    // this.editChanged = undefined;
    // this.closingListTemplate.emit('closeList');
    // this.listScreen = !this.listScreen
    // this.ViewRowData = item
    // this.oldRowData = structuredClone(item);
    // this.changeDetectorRef.detectChanges();
    $event.stopPropagation();
    if(this.context != 'Sites' && !this.isRedirecting) {
      this.isRedirecting = true;
      let idField = this.templateViewService.getTemplateConfiguration(this.filename)?.idKey;
      this.activityEntity.entityId = item?.[idField]?.toString();
      this.activityService.setActivitySession(this.activityEntity);
      this.getMasterDataFromService('view', item);
    }
  }

  editMethod(value) {
    this.isBtnDisabled = true;

    // if (!['Grossing Templates', 'Revision Reasons', 'Rejection Templates', 'Screening Comments', 'Deletion Templates', 'Categories', 'Diagnosis References', 'Custom Abbreviations', 'Revision Notes', 'Adequacy Codes', 'Microscopic Codes', 'Case Comments', 'Sequester Reasons', 'Correlation Templates', 'Tubetype Templates', 'Nomenclature Templates'].includes(this.filename)) {
    // } else {
    // }
    // this.editTemplateClicked.emit({ action: 'edit', rowData: value, event: Event });
    if(['Default Role', 'Auto Assignment Rule','Disclaimers', 'Workgroups','Payers','Sites','Manage Configurations'].includes(this.filename)) {
      this.isBtnDisabled = false;
      this.editTemplateClicked.emit({ action: 'edit', rowData: value, event: Event });
    } else {
      this.getMasterDataFromService('edit', value);
    }
    // this.activityEntity.entityId = value.Id;
    // this.activityService.setActivitySession(this.activityEntity);
  }

  formCopyMethod(value){
    if(['Disclaimers'].includes(this.filename)){
      this.editTemplateClicked.emit({ action: 'copy', rowData: value, event: Event });
    }
  }

  handleViewScreen(val: any) {
    if (val == 'close') {
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
      this.listScreen = !this.listScreen;
      this.closingListTemplate.emit('openList');
      this.changeDetectorRef.detectChanges();
    }
  }

  createClicked() {
    if(['Auto Assignment Rule','Disclaimers','Payers','Sites','Custom Fields','Manage Configurations'].includes(this.filename))
    {
      this.CreateTemplateClicked.emit();
    } else {
      if (['Default Role'].includes(this.filename)) {
        this.CreateTemplateClicked.emit({ action: 'Create', event: Event });
        this.isBtnDisabled = false;
        return;
      }
      // this.getAllMasterData('create');
      this.getMasterDataFromService('create');
    }
  }

  async getMasterDataFromService(type: any, value = null) {
    this.saveOrEditCompleted = undefined;
    // Call the service method instead of local method
    let configData: any = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
    configData['configurableTemplateFields'] = configData['configurableTemplateFields']?.filter((item: any) => {
      if (this.isUserLevel) {
        return (item?.properties?.visibility === 'user' || item?.properties?.visibility === 'both')
      } else {
        return (item?.properties?.visibility === 'org' || (item?.properties?.visibility === 'both' || item?.properties?.showInLabAdmin) || !item?.properties?.visibility)
      }
    });

    configData['configurableTemplateFields'] = this.getOnlyNeededFieldsBasedOnCaseTypes(configData['configurableTemplateFields'], ['anal pap', 'gyn pap'],this.filename);


    await this.templateViewService.getAllMasterData(type, value, this.subMenuCardModel, this.templateData, this.selectedListOption, configData['configurableTemplateFields']);
    this.isBtnDisabled = false;
    if (type === 'create') {
      this.customisedJsonData = structuredClone(configData['configurableTemplateFields']);
      this.createChanged = Math.floor(Math.random() * 500) + 1;
      this.closingListTemplate.emit('closeList');
      this.listScreen = !this.listScreen;
      this.changeDetectorRef.detectChanges();
    } else if (type === 'edit') {
      this.customisedJsonData = structuredClone(configData['configurableTemplateFields']);
      this.createChanged = undefined;
      this.editChanged = Math.floor(Math.random() * 500) + 1;
      this.ViewRowData = value;
      this.oldRowData = structuredClone(value);
      this.closingListTemplate.emit('closeList');
      this.listScreen = !this.listScreen;
      this.changeDetectorRef.detectChanges();
    } else if (type === 'view') {
      this.customisedJsonData = structuredClone(configData['configurableTemplateFields']);
      this.saveOrEditCompleted = undefined;
      this.createChanged = undefined;
      this.editChanged = undefined;
      this.closingListTemplate.emit('closeList');
      this.listScreen = !this.listScreen
      this.ViewRowData = value
      this.oldRowData = structuredClone(value);
      this.isRedirecting = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  getOnlyNeededFieldsBasedOnCaseTypes(featureFields: any, caseTypes: any, fileName: string) {
    if (!caseTypes?.includes(this.templateData?.secondarykeys?.casetype?.toLocaleLowerCase()) && this.isAdmin3 && fileName === 'Screening Types')
      {
        return featureFields?.filter(item => item.columnName !== 'AttributeName');
      }
    else{
    return featureFields;
    }
  }


  saveOrEditEventTriggered(object: any) {
    // this.recievedObjectFromView = object
    let obj = { oldData: this.oldRowData, newData: object };
    let fieldObject = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
    this.isShowActiveTabs = fieldObject?.hasStatusColumn;
    if(fieldObject?.['listFilterDropdownFields'] && ['Adequacy Codes', 'Case Comments', 'Worklists', 'Microscopic Codes'].includes(this.filename)) {
      let configData: any = structuredClone(this.templateViewService.getTemplateConfiguration(this.filename));
      configData['listFilterDropdownFields'] = configData['listFilterDropdownFields'].filter((item: any) => {
        if (this.isUserLevel) {
          return (item?.properties?.visibility === 'user' || item?.properties?.visibility === 'both')
        } else {
          return (item?.properties?.visibility === 'org' || (item?.properties?.visibility === 'both' || item?.properties?.showInLabAdmin) || !item?.properties?.visibility)
        }
      });
      this.getFilterDropDowns(configData['listFilterDropdownFields']);
      this.selectedListFilters  = {...{}};
    }
    this.saveOrEditEventFromListingTriggered.emit(obj);
    // this.isloading=true
    // this.searchText = '';
    // this.filtertemplates();
  }

  deleteMethod(rowData: any) {
    this.deleteTemplateClicked.emit({ action: 'delete', rowData: rowData, event: Event });
  }

  copyTemplate() {
    this.copyTemplateEventTriggered.emit();
  }

  uploadTemplate() {
    this.uploadTemplateEventTriggered.emit();
  }

  filtersChanged(field, $event) {
    this.selectedListFilters[field?.columnName] = $event.value;
    this.emitFilters.emit(this.selectedListFilters);
    // this.ActiveCount,this.AllCount,this.InactiveCount=0

    this.searchText = '';
    this.templatefilter={};
  }

  // for autocomplete dropdown start.
  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any) {
    if (!autoCompleteInputboxValue) {
      return autoCompleteOptions;
    } else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      return filteredAutoCompleteOptions?.filter((option: any) => option[filterProperty]?.toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }

  onAutoCompleteSelectionChange(e, field, particularObject) {
    if (e.source.selected) {
      field.value = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      setTimeout(() => {
        field.dummyValue = {...{}};
        field.dummyValue['itemName'] = particularObject[field?.properties?.source?.dataValueToDisplay[0]];
        field.dummyValue['itemId'] = particularObject[field['properties']?.['source']?.['keyToBeSentToBackend']];
      }, 300);
      // this.templateViewService.globalMasterDataForQueries.selectedUserObject = {...field.dummyValue};
      field['listBackupValue'] = null;
      this.checkSortButton();
    }

    this.searchText = '';
    this.templatefilter={};

    this.selectedListFilters[field?.columnName] = { event: e, object: particularObject };

    let caseTypeField = this.templateCustomObject.find((item) => item.columnName === 'CaseType');
    if(caseTypeField) {
      let caseTypeObj = { object: { CaseType: caseTypeField['value'] } };
      if(this.selectedListFilters['CaseType']) {
        delete this.selectedListFilters['CaseType'];
        this.selectedListFilters['CaseType'] = caseTypeObj
        this.isFilterByCase(this.selectedListFilters);
      }
    }

    let dependentFields = field['properties']?.['source']?.['dependentFieldsList'];
    if(dependentFields?.length) {
      dependentFields.forEach((fieldItem: any) => {
        let tempCustomName = this.templateCustomObject.find((item) => item?.columnName === fieldItem);
        if (tempCustomName) {
          tempCustomName.value = '';
          tempCustomName.dummyValue = { itemId: 'All Tab Names', itemName: 'All Tab Names' };
        
          const source = tempCustomName.properties.source;
          if (!source.originalOptions) {
            source.originalOptions = structuredClone(source.options);
          }
          
          const filterValue = field.value.trim().toLowerCase();
          const tempArr = source.originalOptions.filter((item) => 
            filterValue === 'all roles' || item?.Role?.trim().toLowerCase() === filterValue
          );
        
          if (!tempArr.some((item) => item.TabDisplayName === 'All Tab Names')) {
            tempArr.unshift({ itemId: 'All Tab Names', itemName: 'All Tab Names', TabDisplayName: 'All Tab Names' });
          }
        
          setTimeout(() => {
            source.options = filterValue  === 'all roles' ? this.toGetUniqueValues(tempArr, 'TabDisplayName') : [...tempArr];
          }, 500);
        
          delete this.selectedListFilters?.[fieldItem];
        }        
      });
    }

    this.emitFilters.emit(this.selectedListFilters);
    !this.isAdmin3?this.listLoading=true:null;
    this.selectedFilterCaseType = this.selectedListFilters?.['CaseType']?.['object']?.['CaseType'] || 'All Case Types';
    this.selectedFilterUserType = this.selectedListFilters?.['UserId']?.['object']?.['username'] || 'Group Case Comments';
    this.selectedCaseUser = particularObject[field?.properties?.source?.dataValueToDisplay[0]];

    // if(!this.hideMigrate) {
    //   if (particularObject?.AllowUserlvl == 1 || particularObject?.AllowUserlvl == true) {
    //     this.enableMigrate = true
    //   } else {
    //     this.enableMigrate = false
    //   }
    // }
    document.dispatchEvent(new Event('click'));
  }

  toGetUniqueValues(options, distinctKey) {
    const seenCaseTypes = new Set();
    const uniqueData = options.filter(item => {
      const isUnique = !seenCaseTypes.has(item[distinctKey]);
      if (isUnique) {
        seenCaseTypes.add(item[distinctKey]);
      }
      return isUnique;
    });
    return [...uniqueData];
  }

  checkSortButton() {
    const name = this.filename;
    const isCaseTypeAll = this.selectedFilterCaseType === 'All Case Types';
    const isButtonAll = this.selectedButton === 'All' || this.selectedButton === 'Active';

    const adminCases = ['Grossing Templates', 'Extraction Procedures', 'Screening Comments','Screening Types', 'Case Comments', 'Adequacy Codes'];
    const sortRequiredCases = ['Clinical Information', 'Rejection Templates', 'Sendout Reasons'];

    if (adminCases.includes(name)) {
        this.disableSort = this.isAdmin3 ? !isButtonAll : isCaseTypeAll || !isButtonAll;
    } else if (sortRequiredCases.includes(name)) {
        this.disableSort = !isButtonAll;
    } else {
        this.disableSort = true;
    }

    if(this.filename === 'Worklists' ){
      this.disableSort=this.conditionDisableReOrder
    }
  }


  migrateClicked() {
    this.migrateEventTriggered.emit();
  }

  manageClicked() {
    this.manageEventTriggered.emit();
  }

  setUserRole($event: any, selectedUser: any) {
    this.roleChangedEventTriggered.emit({ event: $event, user: selectedUser });
  }

  // Clear search bar
  clearSearch(){
    this.searchText = '';
    this.filtertemplates();
  }

  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }


  isFilterByCase(selectedListFilters: any) {
   if(this.context == 'Sites' && !this.isAdmin3){
      this.hideCreate = (selectedListFilters.CaseType || this.selectedCaseType) ? false : true;
    }
  }

  editTemplateClickedFromView($event: any) {
    this.editTemplateClicked.emit($event);
  }

  hasStatus(item: any, keys: string[], activeValues: any[]) {
    return keys.some(key => activeValues.includes(item[key])) || keys.every(key => !(key in item));
  }

  // this feature is not enabled yet, need to change this code.
  sortEnabled() {
    this.isBtnDisabled = true;
    this.sortModeEnabled = true;
    this.listScreen = false;

    const activeKeys = ['IsActive', 'Active', 'Status', 'PayersStatus'];
    const activeValues = ['Active', 'Yes', 1, true];

    const defaultKeys = ['Is_Default', 'Default', 'Abnormal', 'IsDefault'];
    const notDefaultValues = ['No', 0, false];

    const activeCount = structuredClone(
      this.GridData?.filter((item: any) => this.hasStatus(item, activeKeys, activeValues))
    );

    this.sortableGridData = structuredClone(
      activeCount?.filter((item: any) => this.hasStatus(item, defaultKeys, notDefaultValues))
    );


    this.originalSortableGridData = structuredClone(this.GridData);
    this.isBtnDisabled = false;
  }

  onScroll(e: any){
    this.scrollHistory = e.target.scrollTop
  }

  onDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container && event.previousIndex != event.currentIndex) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      const prevIndex = event.previousIndex;
      const currIndex = event.currentIndex;
      this.adjustSequences(prevIndex, currIndex);
    }
  }

  adjustSequences(prevIndex: number, currIndex: number) {
    if (prevIndex < currIndex) {
      for (let i = prevIndex; i <= currIndex; i++) {
        let temObj = {
          sequence: '',
          id: this.sortableGridData[i]['OrgGrossTempID'],
          template: "GrossingTemplates",
          modifiedby: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
        }
        if (i === currIndex) {
          this.sortableGridData[i].Sequence = this.sortableGridData[i - 1].Sequence + 1;
        } else {
          this.sortableGridData[i].Sequence = Math.max(1, this.sortableGridData[i].Sequence - 1);
        }

        const existingItem = this.finalSortedArray.find(
          (item: any) => item?.id === this.sortableGridData[i]['OrgGrossTempID']
        );

        temObj['sequence'] = this.sortableGridData[i].Sequence;

        if (existingItem) {
          existingItem.sequence = this.sortableGridData[i].Sequence;
        } else {
          this.finalSortedArray.push(temObj);
        }
      }
    } else if (prevIndex > currIndex) {
      for (let i = currIndex; i <= prevIndex; i++) {
        let temObj = {
          sequence: '',
          id: this.sortableGridData[i]['OrgGrossTempID'],
          template: "GrossingTemplates",
          modifiedby: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
        }
        if (i === currIndex) {
          this.sortableGridData[i].Sequence = Math.max(1, this.sortableGridData[i + 1].Sequence - 1);
        } else {
          this.sortableGridData[i].Sequence += 1;
        }

        const existingItem = this.finalSortedArray.find(
          (item: any) => item?.id === this.sortableGridData[i]['OrgGrossTempID']
        );

        temObj['sequence'] = this.sortableGridData[i].Sequence;

        if (existingItem) {
          existingItem.sequence = this.sortableGridData[i].Sequence;
        } else {
          this.finalSortedArray.push(temObj);
        }
      }
    }
  }



  returnToList(){
    this.sortModeEnabled = false;
    this.listScreen = true;
    this.finalSortedArray = [...[]];
    this.sortableGridData = [...[]];
  }

  saveReorder(finalArray) {
    let sortObject = { commonReOrder : finalArray }
    if(['adequacy codes','case comments','clinical information','grossing templates','rejection templates'].includes(this.templateData.menuURL.toString().toLowerCase()) && !this.isAdmin3){
      sortObject['caseType'] = this.selectedListFilters.hasOwnProperty("CaseType") ? this.selectedListFilters['CaseType']['object']['CaseType'] : ''
      sortObject['userId'] = this.selectedListFilters.hasOwnProperty("UserId") ? isNaN(this.selectedListFilters['UserId']['object']['userid']) ? '' : this.selectedListFilters['UserId']['object']['userid'] : ''
      sortObject['organizationId'] = this.labAdminService.organizationId ?? ''
      sortObject['loggedInUserId'] = sessionStorage.getItem('Userid') ?? ''
      sortObject['templateName'] = this.templateData.menuURL.toString().toLowerCase()
    }

    let dbName = !this.isAdmin3 ? this.labAdminService?.deploymentKey : this.VitalHttpServices?.deploymentKey?.toString();
    dbName = dbName != '' ? sessionStorage.getItem('DeploymentKey').toUpperCase() : '';
    this.VitalHttpServices.reorderTemplatesList(sortObject,dbName).subscribe((data: any) => {
      if(data || data?.content){
        this.sortCompleted.emit(finalArray);
        this.returnToList();
        this.isSortSaveClicked = true;
        this.sortingChanged.emit(true);
      }
      else{
        this.isSortSaveClicked = this.isSortSaveClicked === 'failed' ? 'error' : 'failed';
        return;
      }
      this.refreshFilterOptions();
    },
    (error: any) => {
      this.snackbar.open('Error occurred during reorder', 'Close');
      this.isSortSaveClicked = this.isSortSaveClicked === 'failed' ? 'error' : 'failed';
      this.refreshFilterOptions();
    });
  }

  clearFilterClicked($event: any, field: any) {
    if(!field['listBackupValue']) {
      field['listBackupValue'] = field['dummyValue']['itemName'];
      field['dummyValue']['itemName'] = '';
      $event.stopPropagation();
    }
  }

  autoCompletePanelClosed(field: any) {
    if(!field['dummyValue']['itemName']) {
      let tempName = field['listBackupValue'];
      field['dummyValue']['itemName'] = tempName;
      field['listBackupValue'] = null;
    }
  }

  sequenceTracking(type: any) {
    this.sortedOrder = type;
    if (type === 'ASC') {
      this.sortableGridData = this.sortableGridData?.sort((a, b) => a?.TemplateName?.localeCompare(b?.TemplateName));
    } else if (type === 'DESC') {
      this.sortableGridData = this.sortableGridData?.sort((a, b) => b?.TemplateName?.localeCompare(a?.TemplateName));
    }
    this.finalSortedArray = [];

    this.sortableGridData.forEach((element: any, index: any) => {
      element['Sequence'] = index + 1;
    });

    this.finalSortedArray = this.sortableGridData.map((item: any, index: any) => ({
      sequence: item?.Sequence,  // Assuming sequence is a placeholder, modify as needed
      id: item.OrgGrossTempID,
      template: "GrossingTemplates",
      modifiedby: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
    }));
  }

  tooltipForMigrate(menu){
    if(menu.toString().toLowerCase() === 'payers') {
      return 'Migrate Payers'
    }
    else {
      return 'Migrate group level data to User level'
    }
  }
  showMenuRelatedFlags() {
    let dialogRef = this.dialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: this.filename,
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }

  downloadMethod(fileDetails){
    try {
      JSON.parse(fileDetails);
      this.VitalHttpServices.downloadFile({ "id": fileDetails?.AzurefileId}).subscribe((res) => {
          let extension = `${fileDetails?.FeatureName}_offline_process.xlsx`;
          const data = new Blob([res], {
              type: extension
          });
          FileSaver.saveAs(data, JSON.parse(fileDetails)['name']);
      });
  }
  catch {
      this.VitalHttpServices.downloadFile({ "id": fileDetails?.AzurefileId}).subscribe((res) => {
          this.saveAsExcelFile(res, `${fileDetails?.FeatureName}_offline_process.xlsx`);
      });
  }
  }

  saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName);
}

  toggleChange(event,value){
    this.toggleChangeEmitter.emit(value);
  }

  onDateRangeChange(range: any) {
    this.selectedRange = range.label;
    this.output = '';
    this.startDate = null;  // Reset the start date when changing the range
    this.endDate = new Date();  // Always reset end date to today

    const today = new Date();
    switch (range.value) {
      case 'today':
        this.output = `${this.formatDate(today)} - ${this.formatDate(today)}`;
        this.selectedListFilters['StartDateTime'] = this.output;
        this.showDiv = false;
        this.emitFilters.emit(this.selectedListFilters);
        break;
      case 'last-7-days':
        this.setLast7Days(today);
        this.showDiv = false;
        break;
      case 'last-week':
        this.setLastWeek(today);
        this.showDiv = false;
        break;
      case 'this-week':
        this.setThisWeek(today);
        this.showDiv = false;
        break;
      case 'last-month':
        this.setLastMonth(today);
        this.showDiv = false;
        break;
      case 'date-range':
        // Open the date picker panel programmatically when "Date Range" is selected
        setTimeout(() => {
          this.picker.open();
        }, 0);
        // break;  // "Date Range" will allow user to select the start date
    }
  }

  setLast7Days(today: Date) {
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);
    this.output = `${this.formatDate(last7Days)} - ${this.formatDate(today)}`;
    this.selectedListFilters['StartDateTime'] = this.output;
    this.emitFilters.emit(this.selectedListFilters);
  }

  setLastWeek(today: Date) {
    const firstDayOfLastWeek = new Date(today);
    firstDayOfLastWeek.setDate(today.getDate() - today.getDay() - 6); // Last Sunday
    const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
    lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6); // Saturday
    this.output = `${this.formatDate(firstDayOfLastWeek)} - ${this.formatDate(lastDayOfLastWeek)}`;
    this.emitFilters.emit(this.output);
  }

  setThisWeek(today: Date) {
    const firstDayOfThisWeek = new Date(today);
    firstDayOfThisWeek.setDate(today.getDate() - today.getDay() + 1); // Monday
    const lastDayOfThisWeek = new Date(firstDayOfThisWeek);
    lastDayOfThisWeek.setDate(firstDayOfThisWeek.getDate() + 6); // Sunday
    this.output = `${this.formatDate(firstDayOfThisWeek)} - ${this.formatDate(lastDayOfThisWeek)}`;
    this.emitFilters.emit(this.output);
  }

  setLastMonth(today: Date) {
    const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
    const firstDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
    const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
    this.output = `${this.formatDate(firstDayOfLastMonth)} - ${this.formatDate(lastDayOfLastMonth)}`;
    this.emitFilters.emit(this.output);
  }

  updateOutput(event: any): void {
    this.startDate = event.value;
    this.output = `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
    this.emitFilters.emit(this.output);
  }

  formatDate(date){
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
}
