import { Component, OnInit, Input, ViewChild, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { catchError, concatMap, map, shareReplay, take, tap } from 'rxjs/operators';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { BehaviorSubject, Observable, combineLatest, lastValueFrom, observable, of } from 'rxjs';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { ManageByUsersComponent } from '../manage-by-users/manage-by-users.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogService } from 'src/app/core/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder } from '@angular/forms';
import { error } from 'console';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Interface } from 'readline';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';

@Component({
  selector: 'app-manage-custom-listing',
  templateUrl: './manage-custom-listing.component.html',
  styleUrls: ['./manage-custom-listing.component.scss']
})
export class ManageCustomListingComponent implements OnInit, AfterViewInit {
  isDataLoading: boolean = false;


  constructor(
    private _vitalHttpService: VitalHttpServices,
    private _dialogService: DialogService,
    private _snackbar: MatSnackBar,
    private _ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,
    public activityService: ActivityTrackerService,
    private matdialog: MatDialog
  ) { }

  @Input() templateData: any;
  templateUsers$: any;
  OrgID: number;
  keyword: string
  customListingEditMode: boolean = false
  customListingSequenceEditMode: boolean = false
  maxSequence: number = 5
  isDataChanged: boolean = false
  orgID: string = ''
  loggedInUserId : string = ''
  level: 'orglevel' | 'userlevel' | 'forlabadmin' | 'forlabadminUser' = 'forlabadmin'
  RolesData$: Observable<customListing[]>;
  selectedRole : string = '';
  selectedUser = { userName: '', commonkey: '' }
  selectedWorkList : string= ''
  selectedTab : string = ''
  defaultSelectedUser: string = ''
  defaultSelectedTab: string = ''
  defaultSelectedWorkList: string = ''
  userId : string = ''
  workListData$: Observable<customListing[]>;
  searchSubject = new BehaviorSubject({ searchtext: '', colVisible: 'all', addInSearch: 'all', addInExport: 'all', addInGroupBy: 'all' })
  searchSubject$ = this.searchSubject.asObservable()
  workListFilteredData$: Observable<any>
  currentTab : string = ''
  customWorkListData: { currentData : customListing[], previousData : customListing[]}

  oldcustomWorkListData :customListing[]=[];
  newcustomWorkListData :customListing[]=[];
  rolesInitialData = []
  usersIntialList = [];
  masterRolesData:customListing[]=[]
  rolesData : customListing[] = [];
  usersData : customListing[] = []
  workList : customListing[] = [];
  tabList : customListing[] = [];
  currentItem: customListing;
  displayName: string = ''
  defaultSortObj: customListing[]
  selectedWorkListId : string
  isEditDisabled : boolean = true
  isSetSortDefaultChecked : boolean = false
  scrollHistory: number = 0
  selectedRoleKey: string;
  rolesDataNotFound:boolean=false;
  userSessionDetails: any;
  sourceApp: string;
  destDeployment: string;
  dbName: string;
  auditableColumns: any;
  activityEntity: any;
  sessionId = this.commonService.generateGuid();
  newformCustomListingDetails : {
    Width: any,
    IsVisible: any,
    IsSearchable: any,
    DefaultExport: any,
    IsGroupby: any,
    DefaultSort: any
  }
  oldformCustomListingDetails : {
    Width: any,
    IsVisible: any,
    IsSearchable: any,
    DefaultExport: any,
    IsGroupby: any,
    DefaultSort: any
  }
  storesub:Subscription = new Subscription();
  @ViewChildren('scrollableDiv') maindiv
  formCustomListingDetails = this._fb.group(
    {
      Width: '',
      IsVisible: false,
      IsSearchable: false,
      DefaultExport: false,
      IsGroupby: false,
      Id: 0,
      DefaultSort: null,
      DefaultSortOrderColumnID: null
    })
    showLabAdminLoader:boolean=false


  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
     await this.labAdminSessionService.getLabAdminSession.pipe(take(1), concatMap(session=>
      this.store.select("breadcrum").pipe(take(1),concatMap(i => {
        let menuObj: object = i[i.length - 1];
        menuObj = { ...menuObj, accountLevel: 1 }
        this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails,'',true)
        return of(session)
      }))
    )).subscribe(async session => {
       if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
        this.userSessionDetails = session["userDetails"]
        this.sourceApp = 'VitalDx'
        this.isDataChanged = false
        this.orgID = session["userDetails"]["organizationId"];
        this.loggedInUserId = session["userDetails"]["userId"];
        this.templateData = this.labadminService.templateData;
        let menuObj={}
        this.storesub = this.store.select("breadcrum").subscribe(async (result:any) => {
          menuObj = result[result.length -1]
          this.keyword = menuObj["Keyword"]
        })
         await this.initializeCustomListng()
         this.getTemplateUserData()
         this.getRolName();
         this.GetButtonAccess(this.labadminService.subMenuPermissions)
         this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Custom Listing', 'context': [{ 'key': 'parentMenu', 'value': 'Lab'},{ 'key': 'Settings', 'value': 'Custom Listing'}] });
         this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
         this.getAuditableDetails('Custom Listing');
       }})
    }else{
      this.isDataChanged = false
      this.orgID = sessionStorage.getItem('org_id')
      this.loggedInUserId = sessionStorage.getItem('Userid')
      this.keyword = this.templateData.Keyword
      await this.initializeCustomListng()
      this.getTemplateUserData()
      this.getRolName();
      this.GetButtonAccess(this._vitalHttpService.SubmenuAction),
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL}] });
      this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
      this.getAuditableDetails(this.templateData.menuURL);

    }
    // this.activityService.setActivitySession({'entityId': '','entityType': this.templateData.menuURL, 'context':[{'key':'parentMenu','value':this.templateData.menuURL}]})
  }
  searchText:string=''
  filterStatus(updateValue) {
    this.searchSubject.next({ ...this.searchSubject.value, ...updateValue })
    this.searchText= updateValue.searchtext ? updateValue.searchtext : ''
  }

  getRolName(role = '', level = 'forlabadmin', tabname = '', commonkey : string = '') {
    this._ngxService.start()
    this.isDataLoading = true;
    this.RolesData$ = this._vitalHttpService.getWorkListData(this.orgID, role, level, tabname, commonkey).pipe(
      map(i => i.map(j => j)),
      map((i: any) => i.length && this.refineRoleName(i))
    )
    this.rolesDataNotFound=false
    combineLatest(([this.RolesData$, this.templateUsers$])).pipe().subscribe({
      next: (data: any) => {

        if (data[0].length && data[1].length) {
          this.rolesInitialData = data[0];
          if(level === 'forlabadmin'){
            this.masterRolesData=data[0]
            this.usersIntialList = data[1];
            this.refineDropdownValue(this.rolesInitialData, this.usersIntialList, '')
          }
          else this.refineDropdownValue(this.rolesInitialData, this.usersIntialList, 'users')
        }
        else{
          this.rolesDataNotFound=true;
          this.isDataLoading = false;
        }
        this.isDataLoading = false;
        this._ngxService.stop()
      },
      error: err => this.handleError(err),
      complete: () => {

      }
    })
  }

  refineDropdownValue(data: any, users: any, dropDown) {
    if (!data || !users) return { data: [], users: [], tabs: [], workLists: [] }
    else {
      let allRoles = data.map((i: any) => i.Role.toLowerCase())
      switch (dropDown) {

        case 'roles': {
          //filter users and tabs is All
          this.selectedUser = { userName: 'All', commonkey: '' }
          this.defaultSelectedUser = 'All'
          this.selectedTab = '';
          this.selectedWorkList = ''
          this.rolesData =  this.getUniqueListItems(data,'Role');
          this.usersData = this.refineUsersByRoles(users, this.selectedRoleKey)
          this.tabList = this.refineForTab(data, this.selectedRole);
          this.workList = []
          return
        }
        case 'users':
          {
            //filter tabs on users
            this.selectedUser.userName
            this.selectedTab = ''
            this.selectedWorkList = ''
            this.tabList = this.refineForTab(data, this.selectedRole);
            this.workList = []
            return
          }
        case 'tabs':
          {
            //filter workList
            this.selectedTab
            this.selectedWorkList = ''
            this.workList = this.refineForWorkList(data, this.selectedRole, this.selectedTab)
            return
          }

        default: {
          this.rolesData = this.getUniqueListItems(data,'Role');
          this.usersData = users;
          this.tabList = [];
          this.workList = [];
          this.selectedRole = ''
          this.selectedUser = { userName: '', commonkey: '' };
          this.selectedTab = ''
          this.selectedWorkList = ''
          return
        }
      }
    }
  }

  refineUsersByRoles(users: any, roleList: any) {
      return [{ username: 'All' }, ...users.filter((i) => i.rolename.includes(roleList) && i.isCustomlistingEnabled)]
  }

  refineRoleName(data: any) {
    let loggedinUserRole = 'lab administrator'
    let associationType = '' //need clarity
    let Level = 'orglevel'
    if (Level == 'orglevel') {
      if (loggedinUserRole.toLowerCase() == 'lab administrator') {
        data = data.filter(
          (val: any) =>
            val.Role.toLowerCase().indexOf('-ordering facility') == -1 &&
            val.Role.toLowerCase().indexOf('-both') == -1 &&
            val.Role.toLowerCase().indexOf('-epg') == -1
        );
      } else if (
        loggedinUserRole.toLowerCase() == 'user administrator' &&
        associationType.toLowerCase() == 'pathologists'
      ) {
        data = data.filter(
          (val: any) =>
            val.Role.toLowerCase().indexOf('-ordering facility') == -1 &&
            val.Role.toLowerCase().indexOf('-both') == -1
        );
      } else if (loggedinUserRole.toLowerCase() == 'user administrator') {
        data = data.filter(
          (val: any) =>
            val.Role.toLowerCase().indexOf('-laboratory') == -1 &&
            val.Role.toLowerCase().indexOf('-both') == -1 &&
            val.Role.toLowerCase().indexOf('-epg') == -1
        )
      }
    }
    return data
  }

  //getting work list data
  getWorkList(selectedTab = '') {
    this._ngxService.start()
    this.isDataLoading = true;
    this.currentTab = selectedTab // to use in return to reload the data.
    let level = this.selectedUser.userName == 'All' ? 'orglevel' : 'userlevel';
    let worklistId = this.selectedUser.userName == 'All' ? null : this.selectedWorkListId;
    const role = level === 'userlevel' ? this.selectedRoleKey : this.selectedRole
    this.workListData$ = this._vitalHttpService.getWorkListData(this.orgID, role, level, selectedTab, this.selectedUser.commonkey, worklistId).pipe(
      map(i => i.map(j => ({ ...j, SeqNumber: '', uiIsvisible: -1, uiIsSearchable: -1, uiDefaultExport: -1, uiIsGroupby: -1 }))),
      map(i => i.length && this.refineWorkListdata(i)),
      tap(i => {
        if(i.length){
          this.customWorkListData = {currentData : [...i.filter((cl) => cl.SeqNumber != '')], previousData : [...i.filter((cl) => cl.SeqNumber != '')]}
        }
        this._ngxService.stop();
        this.isDataLoading = false;
      }),
      catchError((err) => {
        this.isDataLoading = false;
        this._ngxService.stop()
        throw(err)
      })
    )
    this.workListFilteredData$ = combineLatest([this.workListData$, this.searchSubject$]).pipe(
      map(([data, filterText]: any) => {
        if (data && data.length) {
          this.searchText= filterText.searchtext ? filterText.searchtext : ''
          return {data:this.filterWorkListData(data, filterText),isNoDataPresent:false} //
        }
        return {data,isNoDataPresent:true}
      })
    )

  }

  filterWorkListData(data: customListing[], filterCondition: any) {
    let filterData = []
    filterData = filterCondition.searchtext != '' ? data.filter((i: customListing) => i.DisplayName.toLowerCase().includes(filterCondition.searchtext.toLowerCase())) : data;
    filterData = filterData.length ? filterData.filter((d: customListing) => {
      return ((filterCondition.colVisible == 'all' ? true : (Number(d.Isvisible) == Number(filterCondition.colVisible)) || (Number(d.uiIsvisible) == Number(filterCondition.colVisible) || (Number(filterCondition.colVisible) === 1 && d.Isvisible === 'truedefaultsort')))
        && (filterCondition.addInSearch == 'all' ? true : Number(d.IsSearchable) == Number(filterCondition.addInSearch))
        && (filterCondition.addInExport == 'all' ? true : Number(d.DefaultExport) == Number(filterCondition.addInExport))
        && (filterCondition.addInGroupBy == 'all' ? true : Number(d.IsGroupby) == Number(filterCondition.addInGroupBy))
      )
    }
    ) : filterData;
    return { swapableData: filterData.filter((cl) => cl.SeqNumber != ''), otherData: filterData.filter((cl) => cl.SeqNumber === '') }
  }

  refineWorkListdata(data: customListing[]) {
    for (let val of data) {
      if (val.DisplayName == '')
        val.DisplayName = val.Name
      if (val.Name.toLowerCase() == 'attachmentcount')
        val.DisplayName = 'AttachmentCount'
      if (val.Sequence == 0 || val.Name.toLowerCase() == 'priority')
        val.Sequence = 9999999
      if (val.OrderType != undefined && val.OrderType != null && val.OrderType != '')
        val.Isvisible = 'truedefaultsort';
    }
    data = data.sort((i: any, j: any) => i.Sequence - j.Sequence)  // ('Sequence').select();
    var cRowNumber = 0;
    for (let i of data) {
      !((i.SearchableText != "" && i.Sequence == 9999999)) ? i.SeqNumber = ++cRowNumber : '';
      i.uiIsvisible = this.getUiOption(i, 'Isvisible');
      i.uiIsSearchable = this.getUiOption(i, 'IsSearchable');
      i.uiDefaultExport = this.getUiOption(i, 'DefaultExport');
      i.uiIsGroupby = this.getUiOption(i, 'IsGroupby')
    }
    this.maxSequence = cRowNumber
    this.defaultSortObj = data.filter((i: any) => i.OrderType)

    return data
  }

  getUiOption(object, key) {
    switch (key) {
      case 'Isvisible': {
        if ((object.SearchableText != "" && object.Sequence == 9999999) || object.Name.toLowerCase() == 'priority') {
          return -1
        }
        return object.Isvisible === 'truedefaultsort' ? -2 : Number(object.Isvisible ?? '')
      }
      case 'IsSearchable': {
        return !object.MasterIsSearchable ? -1 : Number(object.IsSearchable ?? '')
      }
      case 'DefaultExport': {
        return !object.IsExport ? -1 : Number(object.DefaultExport ?? '')
      }
      case 'IsGroupby': {
        return !object.MasterIsGroupby ? -1 : Number(object.IsGroupby ?? '')
      }
      default:
        return -1
    }
  }

  refineForTab(data: any[], selectedRole = "lab director") {
    if (data.length) {
      return this.getUniqueListItems(data.filter(i => i.Role == selectedRole), 'TabDisplayName')
    }
    return data
  }

  refineForWorkList(data: any[], selectedRole = 'lab director', selectedTab = '') {
    return data && data.length ? data.filter(i => i.Role == selectedRole && i.TabDisplayName == selectedTab) : data
  }

  tabSelection(event: any) {
    if (event) {
      if(this.selectedTab !== event.selectedValue){
        this.selectedTab = event.selectedValue
        this.refineDropdownValue(this.rolesInitialData, this.usersIntialList, 'tabs')
      }
    }
    //else this.selectedTab = ''
  }

  workListSelection(event: any) {
    if(event){
      if(this.selectedWorkList !== event.selectedValue){
        this.filterStatus({searchtext: ''})
        this.selectedWorkListId = event.selectedObject.WorklistId
        this.selectedWorkList = event.selectedValue
        this.getWorkList(event.selectedObject.Name)
      }
    }
  }

  roleSelection(event: any) {
    if (event) {
      if(this.selectedRole !== event.selectedValue){
        this.selectedRole = event.selectedValue
        this.selectedRoleKey = event.selectedObject.SearchableText
        this.refineDropdownValue(this.rolesInitialData, this.usersIntialList, 'roles')
      }
    }
  }

  userSelection(event: any) {
    if (event) {
      if(this.selectedUser.userName != event.selectedValue){
        this.selectedUser = event.selectedValue != 'All' ? { userName: event.selectedValue, commonkey: event?.selectedObject?.commonKey ?? '' } : { userName: event.selectedValue, commonkey: '' }
        this.selectedUser.commonkey === '' ?(
          this.rolesInitialData=this.masterRolesData,
           this.refineDropdownValue(this.rolesInitialData, this.usersIntialList, 'users')) : this.getRolName(this.selectedRole, 'forlabadminUser', '', this.selectedUser.commonkey)
      }      
    }
  }

  getUniqueListItems(lis: any, key: any):customListing[]{
    return [...new Map(lis.map((item: any) => [item[key], item])).values()] as customListing[]
  }

  private GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this._vitalHttpService.mainQueryList.findIndex(
      mainQuery =>
        mainQuery.Keyword.toLowerCase() == keyword.toLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this._vitalHttpService.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  getTemplateUserData() {
    this.templateUsers$ = this._vitalHttpService.GetData({
      OperationName: null,
      Query: this.GetQuery(this.keyword),
      Variables: { organizationid: this.orgID.toString() }
    }).pipe(
      map((i: any) => {
      return i.data.ManageCustomListUsers
    }))
    return this.templateUsers$
  }

  openManageByUserDialog() {
    const ref = this.matdialog.open(ManageByUsersComponent, {
      disableClose: true,
      width: '85vw',
      minHeight :'65vh',
      data: {
        OrganizationID: Number(this.orgID),
        usersList$: this.templateUsers$,
        sourceApp: this.sourceApp
      }
    })
    let oldUserUpdate: { userName: string; Accessibility: string } = { userName: '', Accessibility: 'Group Level' };
    let newUserUpdate: { userName: string; Accessibility: string } = { userName: '', Accessibility: 'User Level' };
    ref.afterClosed().subscribe((res) => {
      if(res.LstUsers.length > 0){
          for(let i = 0;i<res.LstUsers.length;i++)
            {
              oldUserUpdate.userName = res.LstUsers[i].username
              newUserUpdate.userName = res.LstUsers[i].username
             
              this.commonService.createActivityObject(res.LstUsers[i].commonKey,"User: "+ oldUserUpdate.userName, this.templateData.menuURL, 'Edit', newUserUpdate, oldUserUpdate, this.sessionId,this.auditableColumns); 
            }      
               
            this.getTemplateUserData().subscribe((res) => {
              this.usersIntialList = res
              this.usersData = this.refineUsersByRoles(this.usersIntialList, this.selectedRoleKey)
            })
      }
    })
  }

  // saves the drag and droped data
  drop(event: CdkDragDrop<any>) {


    if (event.previousContainer === event.container) {

      let previousIndex = event.previousIndex
      let currentIndex = event.currentIndex

      if (event.previousIndex > event.currentIndex) {
        previousIndex = event.currentIndex
        currentIndex = event.previousIndex
      }

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.updateSequenceInUI(event.container.data)
    }
  }

  // To swap the custom list to its entered sequence.
  onFocusOut(actualValue: number, changedValue: HTMLInputElement, container: any) {
    if (actualValue != +changedValue.value) {
      if (+changedValue.value > this.maxSequence) {

        this._dialogService.openLabadminConfimationdialog('', `Sequence cannot be greater than maximum sequence(${this.maxSequence})`, 'OK', 'dontshow')
        changedValue.value = String(actualValue)
      }
      else {
        moveItemInArray(
          container,
          actualValue - 1,
          +changedValue.value - 1
        );
        this.updateSequenceInUI(container)
      }
    }
  }

  showAlert() {
    if (this.isDataChanged) {
          this._dialogService.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel').afterClosed().subscribe(result => {
            if (result) {
              this.getWorkList(this.currentTab)
            this.customListingSequenceEditMode = false
            }
          })
    }
    else this.customListingSequenceEditMode = false
  }

  //calculates the sequence and updates accordingly.
  updateSequenceInUI(list: any) {
    list.map((CL: any, i: number) => {
      if (CL.SeqNumber != '') CL.SeqNumber = i + 1
    })
    this.customWorkListData.currentData = [...list]
    this.isDataChanged = this.customWorkListData.currentData.map((i) => i.DisplayName?.toLowerCase()).join(',') != this.customWorkListData.previousData.map((i) => i.DisplayName?.toLowerCase()).join(',')

  }

  onEdited(item: any) {
    this.customListingEditMode = true
    this.currentItem = item
    this.displayName = item.DisplayName
    this.isSetSortDefaultChecked = item.OrderType != ''    
    this.oldformCustomListingDetails = {
      Width: item.Width,
      IsVisible: item.Isvisible=='truedefaultsort' ? true : item.Isvisible,
      IsSearchable: item.IsSearchable,
      DefaultExport: item.DefaultExport,
      IsGroupby: item.IsGroupby,
      DefaultSort: item.OrderType
      }
    this.formCustomListingDetails.patchValue({
      Width: item.Width,
      IsVisible: item.Isvisible=='truedefaultsort'?true:item.Isvisible,
      IsSearchable: item.IsSearchable,
      DefaultExport: item.DefaultExport,
      IsGroupby: item.IsGroupby,
      Id: item.WorklistId,
      DefaultSort: item.OrderType,
      DefaultSortOrderColumnID: null
    })
    this.activityEntity.entityId = item.WorklistId;
    this.activityService.setActivitySession(this.activityEntity);
    this.currentItem.Isvisible = this.currentItem.Isvisible == 'truedefaultsort' ? true : item.Isvisible
  }

  get getFormCustomList() {
    return this.formCustomListingDetails.controls
  }

  onSave() {

    if (!this.formCustomListingDetails.controls.DefaultSort.value || !this.defaultSortObj[0] || this.displayName === this.defaultSortObj[0]?.DisplayName) {
      this.saveCustomListing(this.formCustomListingDetails.value)
      return
    }
    const defaultSortDisplayName = this.defaultSortObj[0]?.DisplayName;

    this._dialogService.openLabadminConfimationdialog('', `${defaultSortDisplayName} is already selected as the default sort order for this list. Do you wish to change this default sort order?`, 'Proceed', 'Cancel')
    .afterClosed().subscribe(result => {
      if (result) {
        this.formCustomListingDetails.controls.DefaultSortOrderColumnID.patchValue(this.defaultSortObj[0].WorklistId);
        this.saveCustomListing(this.formCustomListingDetails.value)
      } 
    })

  }

  private handleSuccess(Status: string) {
    this._ngxService.stop();
    this.showLabAdminLoader = false
   
    if(Status == 'Edit')
      {
        if(this.formCustomListingDetails.controls.DefaultSort.value == null)
          {
            if(this.oldformCustomListingDetails.DefaultSort == '')
              {
                this.oldformCustomListingDetails.DefaultSort = null;
              }
          }        
        this.commonService.createActivityObject(this.formCustomListingDetails.value.Id,this.displayName,this.sourceApp == 'VitalDx'?'Custom Listing': this.templateData.menuURL, 'Edit', this.formCustomListingDetails.value, this.oldformCustomListingDetails, this.sessionId,this.auditableColumns);      
      }     
    }
  private comparesequence(oldWorkList,newWorkList)
    {
      let json = []
      for (let i = 0; i < this.oldcustomWorkListData.length; i++) {
        if(oldWorkList[i].SeqNumber != newWorkList[i].SeqNumber)
          {
           json.push({
            id:oldWorkList[i].WorklistId,
            name:oldWorkList[i].DisplayName,
            old:oldWorkList[i].SeqNumber,
            new:newWorkList[i].SeqNumber          
           })
          }
      }
      return json
    }

  private sequenceHandleSuccess(Status: string) {
    this._ngxService.stop();   
    if(Status == 'Edit')
      {
        let result = {};
        let json = this.comparesequence(this.oldcustomWorkListData,this.newcustomWorkListData)
        json.forEach(item => {
          const groupName = 'Custom Listing';
          let itemName = item.name;
          const oldValue = item.old;
          const newValue = item.new;
  
          result[groupName] = result[groupName] || [];          
        
          let newItem = {};
          newItem[itemName] = [
              {
                  'Sequence Number': {
                      oldValue: oldValue,
                      newValue: newValue
                  }
              }
          ];
  
          result[groupName].push(newItem);
      });  

         this.commonService.createActivityObject('','Sequence', this.templateData.menuURL, 'Edit', '', '',this.sessionId,this.auditableColumns,'',result);   

    }

  }

  onReset = () => this.onEdited(this.currentItem)

  openSequenceEdit(){
    this.customListingSequenceEditMode = !this.customListingSequenceEditMode
    this.oldcustomWorkListData = [...this.customWorkListData.previousData.map((i) => ({...i}))];
    this.newcustomWorkListData = this.customWorkListData.currentData;
    this.isDataChanged = false
  }

  saveCustomListing(data : any)
  {
    if(!this.formCustomListingDetails.controls.IsVisible.value)
     this.formCustomListingDetails.patchValue({
    DefaultSort:null,
    DefaultSortOrderColumnID:null
  }
    
    )
    this._ngxService.start()
    this.showLabAdminLoader = true
    let level = this.selectedUser.userName == 'All' ? 'orglevel' : 'userlevel';
    const role = level === 'userlevel' ? this.selectedRoleKey : this.selectedRole
    let obj = {...this.formCustomListingDetails.value, organizationId : this.orgID, level, role, tabname : this.currentTab}
    this._vitalHttpService.saveCustomlisting(obj).subscribe({
      next: (result: any) => {
        this.handleSuccess('Edit')        
        this.isSetSortDefaultChecked = false
        this._snackbar.open('Data updated successfully', 'Close')
        this._ngxService.stop()    
        this.showLabAdminLoader = false    
        this.isSetSortDefaultChecked = false
        this.returnToListHelper()
      },
      error: (error: any) => this.handleError(error)
    })
  }

  saveSequence(){

    let changedItems : {srcWorklistId : number, swap : string, targetId : number}[] = []

    for (let index = 0; index < this.customWorkListData.previousData.length; index++) {
      if(this.customWorkListData.currentData[index].WorklistId != this.customWorkListData.previousData[index].WorklistId){
        const element = this.customWorkListData.currentData[index]
        const targetIndex = this.customWorkListData.previousData.findIndex((value) => value.DisplayName === element.DisplayName)
        if(targetIndex != -1){
          changedItems.push({srcWorklistId : element.WorklistId, swap : `${element.DisplayName} -> ${this.customWorkListData.previousData[index].DisplayName}`, targetId : this.customWorkListData.previousData[index].WorklistId})
          moveItemInArray(
            this.customWorkListData.previousData,
            targetIndex,
            index
          );
        }
      }
    }
    const data = changedItems.map((i) => ({sourceId : i.srcWorklistId, targetId : i.targetId}))

    this._ngxService.start()
    this.showLabAdminLoader = true
    let level = this.selectedUser.userName == 'All' ? 'orglevel' : 'userlevel';
    const role = level === 'userlevel' ? this.selectedRoleKey : this.selectedRole
    let obj = {customListingSequences : data, organizationId : this.orgID, level, role, tabname : this.currentTab}
    this._vitalHttpService.swapCustomListingSequence(obj).subscribe({
      next : () =>{
      this._ngxService.stop()
      this.showLabAdminLoader = false
      this.sequenceHandleSuccess('Edit')
      this._snackbar.open('Sequence updated successfully', 'Close')
      this.customListingSequenceEditMode = false
      this.getWorkList(this.currentTab)
    },
    error : (err) => this.handleError(err)
    })
  }

  isworklistChanged()
  {
    const x = this.searchSubject.value
    return !(x.addInSearch == 'all' && x.addInGroupBy == 'all' && x.addInExport == 'all' && x.colVisible == 'all' && x.searchtext == '');
  }

  GetButtonAccess(submenuAction) {
    if(submenuAction){
      try {
        let seletedMenuPermissions
        if (this.sourceApp == 'VitalDx') {
          seletedMenuPermissions = submenuAction.find(e => e.Htext  == 'Custom Listing')["ActionButton"]
        }
        else {
        seletedMenuPermissions = submenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
        }
        for (let i = 0; i < seletedMenuPermissions.length; i++) {
        switch (seletedMenuPermissions[i].Button.toLowerCase()) {
          case "edit":
            this.isEditDisabled = !(seletedMenuPermissions[i].IsPermitted === "true") ;
            break;
        }
      }
      } catch (err) {
        console.error(err)
      }
    }
  }

  returnToList(){
    
    if(!this.isCustomListingDataChanged()) this.returnToListHelper()
    else {
      this._dialogService.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel')
      .afterClosed().subscribe(result => {
        if (result) {
         this.returnToListHelper()
        }
      })

      // const confirmPopUp = this._dialogService.openDialog("Alert", `All your changes will be lost. Do you want to proceed?`, "", "Proceed", "Cancel");
      // confirmPopUp.afterClosed().subscribe((res) => res && this.returnToListHelper())
    }
  }

  isCustomListingDataChanged() : boolean{
    const modifiedData = this.formCustomListingDetails.value
    return +modifiedData.Width != this.currentItem.Width || modifiedData.IsGroupby != this.currentItem.IsGroupby || modifiedData.IsSearchable != this.currentItem.IsSearchable || modifiedData.IsVisible != this.currentItem.Isvisible || modifiedData.DefaultSort != this.currentItem.OrderType || modifiedData.DefaultExport != this.currentItem.DefaultExport

  }

  onSetDefaultOrder(value : boolean){
    let sortValue : string = ''
    if(this.currentItem.OrderType === ''){
      sortValue = 'desc'//this.defaultSortObj[0]?.OrderType === '' || !this.defaultSortObj[0] ? 'asc' :  this.defaultSortObj[0]?.OrderType
    }
    else sortValue = this.currentItem.OrderType

    value ? this.formCustomListingDetails.patchValue({ DefaultSort : sortValue}) : this.formCustomListingDetails.patchValue({ DefaultSort : ''})

    this.isSetSortDefaultChecked = value
  }

  private returnToListHelper(){
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
      this.customListingEditMode = false
      this.currentItem = {} as customListing
      this.getWorkList(this.currentTab)
  }

  onScroll = (e: any) => this.scrollHistory = e.target.scrollTop

  ngAfterViewInit(): void {
    this.maindiv.changes.subscribe((comps: QueryList<any>) => {
      if (comps.first) comps.first.nativeElement.scrollTop = this.scrollHistory
    });
  }

  handleError(error){
    console.error(error)
    this.showLabAdminLoader = false
    this._snackbar.open('Something went wrong. Please try again', 'Close')
    this._ngxService.stop()
  }


  getAuditableDetails(location: any) {
    this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
      
    })
  }

  async initializeCustomListng(){
    await lastValueFrom(this._vitalHttpService.CopyAndGetCustomList(this.orgID))
  }

  clearSearch(to : 'role' | 'user' | 'tab' | 'worklist'){
    switch (to) {
      case 'role': if(this.selectedRole) {
        this.selectedRole = ''
        this.selectedUser = { userName: '', commonkey: '' }
        this.selectedTab = ''
        this.selectedRoleKey = ''
        this.selectedWorkList = ''
        this.selectedWorkListId = ''
        this.searchText = ''
      }
        break;
        case 'user': if(this.selectedUser) {
          this.selectedUser = { userName: '', commonkey: '' }
        this.selectedTab = ''
        this.selectedWorkList = ''
        this.selectedWorkListId = ''
        this.searchText = ''
        }
        break;
        case 'tab': if(this.selectedTab) {
          this.selectedTab = ''
          this.selectedWorkList = ''
          this.selectedWorkListId = ''
          this.searchText = ''
        }
        break;
        case 'worklist': if(this.selectedWorkList) {
          this.selectedWorkList = ''
          this.selectedWorkListId = ''
          this.searchText = ''
        }
        break;
      default:
        break;
    }
  }

  showMenuRelatedFlags() {
    let dialogRef = this.matdialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: 'Custom Listing',
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }
}

export interface customListing {
  DefaultExport : boolean
  DefaultSortOrderColumnID : number
  DepartmentIds : string
  DisplayName : string
  InputParameters : string
  IsDefault : boolean
  IsDepartmentDefault : boolean
  IsDynamicQuery : boolean
  IsExport : boolean
  IsGroupby : boolean
  IsSearchable : boolean
  IsSystem : boolean
  Isvisible : boolean | string
  MasterIsGroupby : boolean
  MasterIsSearchable : boolean
  Name : string
  OrderType : string
  Role : string
  SearchableText : string
  SeqNumber : number | string
  Sequence : number
  TabDisplayName : string
  TabName : string
  UseOwnQuery : boolean
  Width : number
  WorklistId : number
  uiDefaultExport : number
  uiIsGroupby : number
  uiIsSearchable : number
  uiIsvisible : number
}
