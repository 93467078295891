<div class="title">
    <div class="modal-header admin-model-header mt-2 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong class="header-View"> {{ data.headerName }} </strong>
        </h3>
        <img class="ml-auto cursor-pointer mr-3" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross"
            matTooltip="Close" />
    </div>
</div>
<div>
    <app-templates-listing [gridHeader]="ListedGridHeader" [gridData]="gridData" [context]="'Offline Process'"
        [dateRangeOptions]="dateRangeOptions" [showHeaderModule]="false" [hideCreate]="true" [hideEdit]="true"
        [hideUpload]="true" [hideExport]="true" [hideCopy]="true" [hideDowload]="false" [templateData]="templateData"
        (emitFilters)="emitFilters($event)" (toggleChangeEmitter)="emitFilters($event)">
    </app-templates-listing>
</div>