import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router,NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmLabadminComponent } from '../base/popup/confirm-labadmin/confirm-labadmin.component';
import { ActivityTrackerService } from '../core/services/activity-tracker.service';
import { CommonService } from '../core/services/commonservices';
import { VitalHttpServices } from '../core/services/VitalHttpServices';
import { CardQueries } from '../model/card-query';
import { LabadminService } from './labadmin.service';
import { LabadminSessionService } from './services/labadmin-session.service';
import { addNabvTab, decrement } from './store/actions/navbar.actions';
import { HttpClient } from '@angular/common/http';
import { fullScreenMode } from './store/actions/creation.actions';



@Component({
  selector: 'app-labadmin',
  templateUrl: './labadmin.component.html',
  styleUrls: ['./labadmin.component.scss'],
})
export class LabadminComponent implements OnDestroy{
  selectedRoute: any;
  labAdminDomain: any;
  userInfo: any = {};
  userRoles: any[] = [];
  menuList = [];
  isCreationModeEnabled$ : Observable<boolean>
  fullScreenMode$ : Observable<boolean>
  queryvalue: string="";
  globalSearchComponent: boolean;
  userSessionDetails:any;
  globalSearchDetails: any;
  clientSearchCriteria:any;
  showCritria: boolean = false;
  searchCriteria: any[]=[];
  readonly separatorKeysCodes: number[] = [];
  @ViewChild("globalSearch")globalSearch : ElementRef
  isOver = false;
  openActivityTkr = false;
  trackerInput: any = {}
  activityPath: any[];
  trackerApiUrl:any;
  labAdminSessionData: any;

  tempClientSearchCriteria: any;
  cloneClientSearchCriteria: any;
  EntityList: any;
  dropdownValue: any;
  showQueryValue: string;
  sendObject: { queryvalue: string; dropdownValue: any; };
  toolTipText: any;
  showMenu: boolean=true;
  checkShowSideMenu: any;
  isSwitchOrg: boolean = false;
  userAssociatedOrg: any[] = [];
  //showIntigrationLoader : boolean = false

  constructor(
    private router: Router,
    private vitalHttpServices: VitalHttpServices,
    private commonService: CommonService,
    public labAdminService: LabadminService,
    public labAdminSession: LabadminSessionService,
    public cookieService: CookieService,
    private dialog: MatDialog,
    private store: Store<{ breadcrum: [], creationMode : boolean, fullScreenMode : boolean }>,
    private activitySession: ActivityTrackerService
    // private http:HttpClient
  ) {
    this.router.navigate(['/labadmin'])
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          if (event.url === '/labadmin') {
            this.router.navigate(['/labadmin'])
          }
          /* Do something here */
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.checkShowSideMenu){
      this.checkShowSideMenu.unsubscribe()
    }
  }

  ngOnInit() {
    // this.http.get<any>('../../assets/config/appsettings.json').subscribe(data => {
    //   this.labAdminService.setjsondata(data);
    //  });
    sessionStorage.setItem('deploymentKey', "configdb");
    sessionStorage.setItem("page", btoa("login"));
    this.getRolesOnFusionAuth("-1");
    this.commonService.setTitle('VitalDx | Lab Admin', '../../assets/icons/DX.svg');
    this.isCreationModeEnabled$ = this.store.select('creationMode')
    this.fullScreenMode$ = this.store.select('fullScreenMode')
    this.getSearchCriteria()
    this.EntityList = [{label : "Clients", value: "Clients"}, {label : "Facility Users", value: "Client Users"}, {label : "Lab Users", value: "Lab Users"}, {label : "Providers", value: "Physician"}, {label : "Pathologist", value: "Pathologist"}, {label : "Referring Physician", value: "Referring Physician"}]

    // this.EntityList = ["Clients", "Client Users", "Lab Users", "Physician", "Pathologist", "Referring Physician"];
    this.dropdownValue = this.EntityList[0].label;
    // this.labAdminService.emitRefreshGrid.subscribe((data: any) => {
    //   if(data) {
    //     this.globalSearchComponent = true;
    //     // this.getGlobalSearchedData();
    //   } else {
    //     this.globalSearchComponent = false;
    //   }
    // });

    this.checkShowSideMenu= this.labAdminService.showSidebarMenu$.subscribe((isShow: any) => {
      if(isShow) {
        this.showMenu = true;
        this.queryvalue='';
        this.dropdownValue = this.EntityList[0].label;
        this.selectedRoute = this.labAdminService.menuList[0].routerLink;
      }
    })
  }

  settingsObject : any =  {
      "Htext" : "Settings",
      "SubMenu" : [],
      "Template" : "",
      "icon" : "",
      "iconType" : "",
      "routerLink" : "/labadmin/settings"
    }
    help : any =  {
      "Htext" : "Help",
      "SubMenu" : [],
      "Template" : "",
      "icon" : "",
      "iconType" : "",
      "routerLink" : "/labadmin/help"
    }

  decrement() {
    this.store.dispatch(decrement());
  }

  async GetHomeDetails() {
    if (
      this.vitalHttpServices.mainQueryList ||
      this.vitalHttpServices.mainQueryList.length <= 0
    ) {
      let query = new CardQueries().mainQuery;
      let OrgId = this.labAdminService.organizationId
      let queryRequest: any = {
        OperationName: null,
        Query: query,
        Variables: null,
      };
      let _this = this;
      let result = await _this.vitalHttpServices
        .GetData(queryRequest, 'configdb')
        .toPromise();
      if (!result.errors) {
        _this.vitalHttpServices.mainQueryList = result.data.Card;
        sessionStorage.setItem(
          'GraphqlMainQuery',
          btoa(JSON.stringify(_this.vitalHttpServices.mainQueryList))
        );
        let newQuery = _this.vitalHttpServices.mainQueryList.filter(
          (x) => x.Keyword == 'getVitalAdminMenusForDx'
        )[0].Query;

        let queryString = "Organizationid in \"" + OrgId + "\":string[] OR Organizationid in \"-1\":string[]"
        let newQueryVariable = {
          CardName: "LabAdministrator",
          filtername: queryString
        };
        let newQueryResult = _this.commonService.GetCardRequest(
          newQueryVariable,
          newQuery
        );
        _this.vitalHttpServices
          .GetData(newQueryResult, this.labAdminService.deploymentKey)
          .subscribe((newResData) => {

            let data = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
            _this.labAdminService.rbacPermissions = JSON.parse(newResData.data.submenuData1[0]?.Permission).MenuItems
            _this.menuList = data;
            _this.labAdminService.menuList = data;
            _this.selectedMenu(data[0]);
          });
      } else {
        this.logOut();
        console.error(result);
      }
    } else {
      this.logOut();
    }
  }

  async getRolesOnFusionAuth(userid) {
    try {
      
    this.vitalHttpServices.getADUserRoles(userid).subscribe(async (resData: any) => {
      if (resData) {
        //use this as local
        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //   "data": "{\"commonKey\":\"DDC52346-7861-40C8-8F44-DF5B8A67985F\",\"deploymentKey\":\"LIS\",\"orgGuid\":\"04d84726-6edf-4508-8167-a3dd91f77fca\",\"orgId\":\"4219\",\"userId\":22722812,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
        // };
      //   let resData = {
      //     "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
      //     "data": "{\"commonKey\":\"19F5A473-066D-4722-A409-037BAC97182E\",\"deploymentKey\":\"LIS\",\"orgGuid\":\"aced0093-b2ad-4011-ad5f-69929a52f2d2\",\"orgId\":\"3\",\"userId\":10,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
      // };
        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //   "data": "{\"commonKey\":\"af579c2c-d892-44bb-b666-377b7967982d\",\"deploymentKey\":\"LIS\",\"orgGuid\":\"aced0093-f964-4453-b88e-e818bd76a941\",\"orgId\":\"6900\",\"userId\":22753964,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
        // };

        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //     "data": "{\"commonKey\":\"6F84D64F-6B21-4289-8F77-64E9F7B40671\",\"deploymentKey\":\"THX\",\"orgGuid\":\"ACED0097-F708-45B8-AFE6-7E3A63B68D85\",\"orgId\":\"8\",\"userId\":208230,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
        //   };
          

        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //   "data": "{\"commonKey\":\"4b6aadcc-eef9-4c3c-930a-3d1e372a36dc\",\"deploymentKey\":\"LIS\",\"orgGuid\":\"ACED0105-5A4A-4D63-A897-BCBE1B4BEF6D\",\"orgId\":\"4900\",\"userId\":22978673,\"domainUrl\":\"https://jupiterqclis.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"isSwitchOrg\":true}"
        // };
        // };
        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //   "data": "{\"commonKey\":\"DDC52346-7861-40C8-8F44-DF5B8A67985F\",\"deploymentKey\":\"LIS\",\"orgGuid\":\"04d84726-6edf-4508-8167-a3dd91f77fca\",\"orgId\":\"4219\",\"userId\":22722812,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
        // };
        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //   "data": "{\"commonKey\":\"cca70006-02c0-4807-b36e-08203290beb8\",\"deploymentKey\":\"SAGIS\",\"orgGuid\":\"aced0096-948c-49b0-86c1-c95a4daaa03a\",\"orgId\":\"7580\",\"userId\":22759062,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\"}"
        // };
        // let resData = {
        //   "userinfo": "{\"aud\":\"8c8fe76f-455a-4103-94de-8bbe8365ee2f\",\"exp\":1711707066,\"iat\":1711706946,\"iss\":\"vitalaxis.com\",\"sub\":\"c01e818c-22b5-48ce-a668-ee206091b46e\",\"jti\":\"1d2f0d10-086f-4e0a-b1a2-dc3420f7d4bd\",\"authenticationType\":\"PASSWORD\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"email_verified\":true,\"preferred_username\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"scope\":\"offline_access\",\"auth_time\":1711706946,\"tid\":\"97ecda9b-e323-6684-68a9-d6f7b1f62e33\"}",
        //   "data": "{\"commonKey\":\"cca70006-02c0-4807-b36e-08203290beb8\",\"deploymentKey\":\"SAGIS\",\"orgGuid\":\"aced0096-948c-49b0-86c1-c95a4daaa03a\",\"orgId\":\"7580\",\"userId\":22759062,\"domainUrl\":\"https://jupiterdevbcm.vitalaxis.com\",\"sourceApp\":\"VitalDx\",\"email\":\"vbcm@jupiterdevbcm.vitalaxis.com\",\"submenu\":\"\"}"
        // };


        let sessionData = JSON.parse(JSON.stringify(resData));
        if ((sessionData.userinfo && Object.keys(sessionData.userinfo).length > 0) && (sessionData.data && Object.keys(sessionData.data).length > 0)) {
          let user = JSON.parse(resData.userinfo)
          this.setCookie(null);
           user.email=user.email=="" ? "1" : user.email
           localStorage.setItem("UserEmail", user.email);
           this.labAdminService.labadminLoggedinUserEmail = user?.email;
           let domainData = JSON.parse(resData.data)
           sessionStorage.setItem("Userid",domainData?.userId);
           this.labAdminDomain = domainData;
         //to get logout when the session is destroyed
         sessionStorage.setItem("domainUrl",btoa(this.labAdminDomain.domainUrl));
         this.setCookie(btoa(this.labAdminDomain.domainUrl));
         this.labAdminService.domainUrl=this.labAdminDomain.domainUrl
           if(domainData?.email?.trim().toLowerCase() == user?.email?.replace(/\s*@\s*/, '@').toLowerCase())
            {
          this.labAdminService.organizationId = domainData.orgId
          this.labAdminService.deploymentKey = domainData.deploymentKey;
          this.labAdminService.redirectToMenu = domainData?.submenu ?? '' // used to redirect to a specific menu instead of loading entire application.
          if(this.labAdminService.redirectToMenu) this.labAdminService.showIntigrationLoader = true
          this.labAdminService.sourceApp = 'vitalDX';
          sessionStorage.setItem("DeploymentKey", domainData.deploymentKey);
          let _this = this;
          this.labAdminService.getUserRoles({
            // Dev & QC
             "userId": domainData.userId,
            "orgGuid": domainData.orgGuid
          }, domainData.deploymentKey).subscribe(async (res) => {
            // Local enable below cmd above 3 lines
          //  "userId": '22935343',
          //  "orgGuid" : 'ACED0093-B7BD-48D5-A5EC-B0F193E1263D'
          //  }, "LIS").subscribe((res) => {
            await this.vitalHttpServices.getURLs();
            this.userInfo = res.content;
            this.labAdminService.associatedAccountIds = res?.content?.userAccountIDs?.map(item=>item.accountId).join(',')
            this.labAdminService.formattedDisplayName = this.userInfo?.formattedDisplayName;
            this.labAdminService.accountId=this.userInfo?.userAccountIDs[0]?.accountId;
            localStorage.setItem("user_name", this.userInfo?.formattedDisplayName);
            this.labAdminService.labadminLoggedinUser = this.userInfo?.formattedDisplayName;
            this.userRoles = this.sortRoles(res.content.userRoles, this.userInfo.defaultRole)
            this.isSwitchOrg = res.content.showSwitchOrg;
            this.userAssociatedOrg = res.content.associatedOrgs;
            this.labAdminSession.setLabAdminSession({ "labAminSession": sessionData, "userDetails": res.content });
            await this.GetHomeDetails();
          })

            }
            else{
              this.informationPopBeforeLogOut();
            }
        }
        else{
          this.logOut();
        }
      }
    }, error => {
      this.logOut();
    });
    
  } catch (error) {
    this.labAdminService.showIntigrationLoader = false
  }
  }
  sortRoles(roles,defaultRoleName){
    let defaultRole=roles.find(i=>i.roleDisplayName==defaultRoleName)
    return [defaultRole,...roles.filter(i=>i.roleDisplayName!=defaultRoleName)?.
      sort((a,b) =>  a.roleDisplayName.localeCompare(b.roleDisplayName ))]
  }


  selectedMenu(route) {
    this.showMenu = true;
    this.queryvalue = '';
    if(!this.labAdminService.redirectToMenu){      
      this.selectedRoute = route.routerLink;
      this.router.navigate([`${route.routerLink}`]);
      route=route.Htext=="Clients"?{ ...route, Level: 0,showDetails:false }:{ ...route, Level: 0 }
      this.store.dispatch(addNabvTab({ value: route }));      
      this.store.dispatch(fullScreenMode())
    }
    else{
      let url = this.getRedirectionUrl(route.routerLink)
      this.router.navigate([url]);
      //this.labAdminService.showIntigrationLoader = false
    }
  }

  changeRole(role) {
    if(this.labAdminDomain?.isvitaldesktop){
      window.location.href = atob(sessionStorage.getItem("domainUrl")) + "/VALogin.aspx?desktop=1&FormUserID=" + role.userId + "&from=switchRole";
    }else{
      window.location.href = atob(sessionStorage.getItem("domainUrl")) + "/VALogin.aspx?FormUserID=" + role.userId + "&from=switchRole";
    }
  }

  switchOrg(org){
    if(this.labAdminDomain?.isvitaldesktop){
      window.location.href = this.labAdminService.domainUrl + "/VALogin.aspx?desktop=1&FormUserID=" + org.AssociatedUserId + "&from=switchRole&MFAShowSwitchOrg=true";
    }else{
      window.location.href = this.labAdminService.domainUrl + "/VALogin.aspx?FormUserID=" + org.AssociatedUserId + "&from=switchRole&MFAShowSwitchOrg=true";
    }
  }

  logOut() {
    this.labAdminService.showIntigrationLoader = false
    this.vitalHttpServices.logoutAPI().subscribe(resData => {
      if (!resData.errors) {
        if (resData.statusCode == 200) {
          this.clearStoreData();
          sessionStorage.setItem('deploymentKey', "");
          sessionStorage.setItem("page", btoa(""));
          this.router.navigate(['/labadmin']);
          if(this.labAdminDomain?.isvitaldesktop){
            window.location.replace(environment.fusionauth + 'logout?client_id=' + environment.clientid+ '&post_logout_redirect_uri=' + atob(sessionStorage.getItem("domainUrl")) + "/VALogOut.aspx?desktop=1&sourceapplication=newlabadmin");
            return;
          }else{
             // Retrieve the domain URL from sessionStorage
             let domainUrl = atob(sessionStorage.getItem("domainUrl"));
             // If not found in sessionStorage, try cookies
             if (!domainUrl) {
               const cookieValue = this.cookieService.get('domainUrl');
               if (cookieValue) {
                 domainUrl = atob(cookieValue); // Decode base64 encoded value
               }
               else {
                 domainUrl = this.labAdminService.domainUrl
               }
             }
             // Example URL
             let url = domainUrl;
             url.replace(/back/g, 'forward');
             window.location.replace(environment.fusionauth + 'logout?client_id=' + environment.clientid + '&post_logout_redirect_uri=' + domainUrl + "/VALogOut.aspx?sourceapplication=newlabadmin");
             return;
          }
        }
      }
    }, error => {
      let domainUrl = this.labAdminService.domainUrl;
      window.location.href=environment.fusionauth + 'logout?client_id=' + environment.clientid + '&post_logout_redirect_uri=' + domainUrl + "/VALogOut.aspx?sourceapplication=newlabadmin";
       return ;
    })

  }
 // clear the data when the logout is happend
   clearStoreData(){
    this.userRoles=[];
    this.userInfo=[];
    this.menuList=[];
    this.labAdminService.formattedDisplayName = '';
    this.labAdminSession.setLabAdminSession('');
    localStorage.clear();
    this.cookieService.deleteAll('/', environment.domain);
    this.labAdminService.sourceApp = "";
    this.labAdminService.menuList = []
    this.labAdminService.baseUrl2 ='';
    this.labAdminService.selectedSubmenu = {}
    this.labAdminService.rbacPermissions = {}
    this.labAdminService.subMenuPermissions = {};
    this.labAdminService.organizationId = '';
    this.labAdminService.accountId = '';
    this.labAdminService.deploymentKey = '';
    this.labAdminService.templateData = {};
    this.labAdminService.detailsData={};
    this.labAdminService.subSideMenu=[];
    this.labAdminService.hidCMSCATURl1 = '';
    this.labAdminService.hidCMSCATCSKTKN = '';
    this.userAssociatedOrg=[];

   }

  //Information that user has the session with the different authentication
  informationPopBeforeLogOut(){
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      autoFocus: false,
      width: '360px',
      data: { header: "", message: "A session is already active in the same browser. You will be logged out from here. Please try to login in a different browser OR Logout from the active session and Login here again.", continue: "OK", cancel: "dontshow" }
        })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.logOut();
      }
    })
  }
  openActivityTracker() {
    this.openActivityTkr = true;
    this.labAdminSession.getLabAdminSession.subscribe(labAdminSessionData => this.labAdminSessionData = labAdminSessionData);
    let context = {};
    let activitySearchParams: any;
    this.trackerApiUrl = environment.baseUrl2;
    this.activityPath = []
    this.activitySession.getActivitySession.subscribe(activityTab => activitySearchParams = activityTab);

    this.trackerInput = {
      'productId': atob(sessionStorage.getItem("domainUrl")),
      'customerId': sessionStorage.getItem('org_id'),
      'entityType': activitySearchParams.entityType,
      'actionType': 'audit',
      'entityId': activitySearchParams.entityId,
      'ticketId': '',
      'index': '',
      'locationId': this.labAdminSessionData?.userDetails?.organizationId,
      'context': activitySearchParams.context,
      'createdBy': '',
      'startDate': '',
      'endDate': ''
    }

  }
  onSearchInputChange(){
    if(this.queryvalue.length==0){
      return;
    }
     this.showMenu=false;
    const searchValue = this.EntityList.find((j) => j.label === this.dropdownValue).value
    this.router.navigate([`/labadmin/globalsearch/search-records/${searchValue}/${this.queryvalue}`]);
  }
  onChangeSearchInput(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.globalSearch.nativeElement.blur();
      this.onSearchInputChange();
    }
  }
  clearSearch() {
    this.queryvalue = ""
    // this.fnChildSearchSubmit(false);
  }
  getSearchCriteria(){
   this.clientSearchCriteria= {"clients": [{"FacilityName": "Facility Name","LocationCode": "Location Code","NPI": "NPI"}]};
   this.tempClientSearchCriteria = structuredClone(this.clientSearchCriteria);
   this.clientSearchCriteria = Object.values(this.clientSearchCriteria.clients[0])
   this.cloneClientSearchCriteria=this.clientSearchCriteria;
  }

  //#region  mat-chip implementation
  // filterCriteria(event) {
  //   if(event.target.value.length >=1 ){
  //     this.showCritria=true;
  //   }
  //   else if (event.target.value.length ==0){
  //     this.showCritria=false;
  //   }
  //   if (event.target.value != '') {
  //     console.log(this.searchCriteria)
  //     const keysInJson = this.searchCriteria.map(item => item.split(':')[0]);

  //     // Filter arr1 based on keys present in json1

  //     this.clientSearchCriteria = this.cloneClientSearchCriteria
  //     .filter(item => !keysInJson.includes(item))
  //     .filter(va => va.toLowerCase().includes(event.target.value.toLowerCase())); // Filter by case-insensitive substring match
  // }
  //   else if (event.target.value == '') {
  //     this.clientSearchCriteria = this.cloneClientSearchCriteria;
  //   }
  // }

  // selectingCriteria(criteria) {
  //   this.searchCriteria=[]
  //   this.queryvalue =  criteria;
  //   //this.searchCriteria.push(criteria);
  // }
  // removeSearchCriteria(){
  //   this.searchCriteria=[];
  // }
  // remove(searchData): void {
  //   const index = this.searchCriteria.indexOf(searchData);

  //   if (index >= 0) {
  //     this.searchCriteria.splice(index, 1);
  //   }
  // }
  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;

  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     this.searchCriteria.push(value.trim());
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //     this.globalSearch.nativeElement.focus();
  //   }
  // }

  // updateObjectFromArray(obj, arr) {
  //   let updatedObj = {};

  //   arr.forEach(item => {
  //     let [key, value] = item.split(':');

  //     // Trim spaces from the key
  //     key = key.replace(/\s+/g, '');

  //     // Check if the key exists in the original object
  //     if (obj.hasOwnProperty(key)) {
  //       updatedObj[key] = value;
  //     }
  //   });

  //   return updatedObj;
  // }
  //#endregion
  OnChangeEntry(DrpDwnEntityValue) {
    this.dropdownValue = DrpDwnEntityValue;
  }
  globalSearchClear() {
      this.queryvalue = "";
      this.globalSearchDetails=[];
      this.selectedRoute = this.menuList[0].routerLink;
      let firstMenuItem = this.menuList[0].SubMenu[0];
      this.router.navigate([`${firstMenuItem.routerLink}`]);
      let obj : any = {...firstMenuItem , Level: 1}
      this.store.dispatch(addNabvTab({value: { ...this.menuList[0], Level : 0 }}));
      this.store.dispatch(addNabvTab({value: obj}));
  }

  enableToolTip(e) {
    this.toolTipText=e.scrollWidth > e.clientWidth?e.textContent:'';
  }

   // Set a cookie
   setCookie(value): void {
    this.cookieService.set('domainUrl', value, 1); // 1 day expiry
  }
  // Get a cookie
  getCookie(): void {
    const value = this.cookieService.get('domainUrl');
    console.log('Cookie value:', value);
  }

   // Delete a cookie
   deleteCookie(): void {
    this.cookieService.delete('domainUrl');
  }

  getRedirectionUrl(defaultUrl : string){
    switch (this.labAdminService.redirectToMenu) {
      case 'managelot': return '/labadmin/lab/labmanagement/managelots'   
      default: return defaultUrl
    }
  }
}
