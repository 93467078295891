<div mat-dialog-title class="email-header">
    <span>{{data.header}}</span>
    <img class="ml-auto" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>

<mat-dialog-content class="pl-2 pr-1">
    <div [formGroup]="emailForm" autocomplete="off">
        <div class="col-sm-6 mt-2 mb-2" *ngIf="labAccountDetails.length > 1">
            <app-custom-select labelValue="Laboratory" formcontrolname="laboratory" [required]="true"
                [dropDownValues]="labAccountDetails" dropDownKeyProp="DisplayName" dropDownValueProp="AccountId"
                defaultOption="Select Laboratory"></app-custom-select>
        </div>
        <ng-container *ngIf="loader$ | async as loader">
            <div [class]="labAccountDetails.length === 1 ? 'fax-content-height-without-search' : 'fax-content-height'"
                *ngIf="emailAdvanceAlert == '' && getter.value">
                <div class="m-0 p-0 mb-2 mr-2 ml-1" *ngFor="let category of categories; let i = index">
                    <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="step===i" (opened)="setStep(i)">
                            <mat-expansion-panel-header class="accSection pl-2 pr-2">
                                <mat-panel-title style="color: #0364b4">
                                    {{category}}
                                    <div class="each-item ml-3"
                                        *ngIf="!loader.caseListSection.loading && category === 'Case Type'">
                                        <input type="checkbox"
                                            [checked]="isAllCasesCategorySelected(caseCategoriesList)"
                                            (click)="$event.stopPropagation()"
                                            (change)="$event ? toggleAllCaseCategory(caseCategoriesList) : null">
                                        <span (click)="cAll.click()">All</span>
                                    </div>

                                    <div class="each-item ml-3"
                                        *ngIf="!loader.caseListSection.loading && category === 'Services'">
                                        <input #sAll type="checkbox" (change)="$event ? toggleAllServices() : null"
                                            (click)="$event.stopPropagation()"
                                            [checked]="orderingServiceSelection.hasValue() && isSelectedService()">
                                        <span (click)="sAll.click()">All</span>
                                    </div>

                                    <div class="each-item ml-3"
                                        *ngIf="!loader.caseListSection.loading && category === 'Physicians'">
                                        <input #pAll type="checkbox" (change)="$event ? toggleAllPhysicians() : null"
                                            (click)="$event.stopPropagation()"
                                            [checked]="orderingPhysicianSelection.hasValue() && isSelectedPhysicains()">
                                        <span (click)="pAll.click()">All</span>
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div *ngIf="category === 'Case Type'">
                                <app-shimmerui [line]="5" *ngIf="loader.caseListSection.loading"></app-shimmerui>
                                <div *ngIf="!loader.caseListSection.loading" class="mt-3">
                                    <div class="mb-4" *ngFor="let caseCategory of caseCategoriesList; let i = index">
                                        <div class="each-item vdm-b-13px">
                                            <input type="checkbox" #inp
                                                [checked]="caseCategory.selectionModel.hasValue() && isAllCaseSelected(caseCategory.Cases,caseCategory.selectionModel)"
                                                (click)="$event.stopPropagation()"
                                                (change)="$event ? toggleAllCases(caseCategory,caseCategory.selectionModel):null">
                                            <b (click)="inp.click()">{{caseCategory.Category}}</b>
                                        </div>
                                        <div class="grid-case-list mb-1 ml-3">
                                            <div class="each-item" *ngFor="let caseTypes of caseCategory.Cases">
                                                <input #ctype type="checkbox" (click)="$event.stopPropagation()"
                                                    (change)="$event?caseCategory.selectionModel.toggle(caseTypes.CaseTypeId):null"
                                                    [checked]="caseCategory.selectionModel.isSelected(caseTypes.CaseTypeId)">
                                                <div appTooltipEllipsis><span
                                                        (click)="ctype.click()">{{caseTypes.CaseTypeDisplayname}}
                                                    </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="category === 'Services'">
                                <div *ngIf="defaultServices">
                                    <app-shimmerui [line]="2" *ngIf="loader.caseListSection.loading"></app-shimmerui>
                                    <div class="service mt-3" *ngIf="!loader.caseListSection.loading">
                                        <div class="each-item" *ngFor="let service of defaultServices; let i = index">
                                            <input #ser type="checkbox" (click)="$event.stopPropagation()"
                                                (change)="$event ? orderingServiceSelection.toggle(service) : null"
                                                [checked]="orderingServiceSelection.isSelected(service)">
                                            <span (click)="ser.click()"
                                                appTooltipEllipsis>{{service.Attribute_Name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="category === 'Physicians'">
                                <app-shimmerui [line]="4" *ngIf="loader.caseListSection.loading"></app-shimmerui>
                                <div class="service mt-3" *ngIf="!loader.caseListSection.loading">
                                    <div class="each-item" *ngFor="let case of physicianList">
                                        <input #phy type="checkbox" (click)="$event.stopPropagation()"
                                            (change)="$event ? orderingPhysicianSelection.toggle(case) : null"
                                            [checked]="orderingPhysicianSelection.isSelected(case)">
                                        <span (click)="phy.click()" appTooltipEllipsis>{{case.Physician}}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="mb-3 ml-1">
                    <div class="heading mb-2">
                        <b>Email Section</b>
                    </div>
                    <div class="timezone-section mt-1">
                        <ng-container>
                            <div class="send-report-section pr-2">Send report as</div>
                            <div [ngClass]="selectedReportType === item ? 'services-ship-selected font-size-weight d-flex' : 'timezone-boder-style font-size-weight d-flex'"
                                tabindex="0" *ngFor="let item of defaultReports">
                                <span (click)="onReportTypeChange(item)">{{item}}</span>
                            </div>
                        </ng-container>
                    </div>

                    <div class="row grid col-sm-12 mainDiv pr-2">
                        <div class="col-sm-12 p-0 viewInputContainer">
                            <!-- {{emailForm.controls?.emailAddress?.value }} -->
                            <mat-form-field class="col-sm-12 chip-form-field">
                                <!-- {{emailIds | json}} -->
                                <mat-label class="email-label">Email Address</mat-label>
                                <mat-chip-grid #chipGrid aria-label="Enter emails"  formControlName="emailAddress">
                                  <mat-chip-row *ngFor="let email of emailIds"
                                                (removed)="remove(email)"
                                                [editable]="true"
                                                (edited)="edit(email, $event)"
                                                [aria-description]="'press enter to edit ' + email">
                                    {{ email }}
                                    <button matChipRemove [attr.aria-label]="'remove ' + email">
                                        <img class="ml-auto cursor-pointer" style="height: 10px;"
                                        src="/assets/icons/Close_black.svg" alt="cross"/>           
                                    </button>
                                  </mat-chip-row>
                                  <input placeholder="Enter Email Address..."
                                         [matChipInputFor]="chipGrid"
                                         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                         [matChipInputAddOnBlur]="addOnBlur"
                                         (matChipInputTokenEnd)="add($event)"/>
                                  <!-- <mat-error *ngIf="emailFormControl.hasError('emailAddress') && emailFormControl.touched">
                                    Invalid email address
                                  </mat-error> -->
                                </mat-chip-grid>
                              </mat-form-field>
                        </div>
                        <div class="col-sm-12 row m-0 p-0" >
                            <div class="col-sm-3 p-0">
                                <app-custom-select id="password" labelValue="Password" [required]="true" formcontrolname="emailPassword"
                                    defaultOption="Select Password" dropDownKeyProp="policyname" dropDownValueProp="policyformat"
                                    [dropDownValues]="passwordPoliciesList">
                                </app-custom-select>
                            </div>
                            <div class="col-sm-3 p-0" *ngIf="emailForm.controls?.emailPassword?.value?.toLowerCase()!= 'custom password'">
                                <img  class="ml-1 mt-3 info-icon" src="/assets/icons/info.svg" [matTooltip]="getPasswordDesc(emailForm.controls?.emailPassword?.value)"/>
                            </div>
                            <div class="col-sm-3 " *ngIf="emailForm.controls?.emailPassword?.value?.toLowerCase()== 'custom password'">
                                <app-input labelValue="Custom Password" formcontrolname="customPassword"  [maxLength]="10"  placeholder="Enter Custom Password" [required]="emailForm.controls?.emailPassword?.value?.toLowerCase()== 'custom password' ? true  : false" [type]="'password'" [inputType]="'password'" ></app-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="my-2 mx-4 d-flex align-items-center w-100 buttonSection">
        <app-button mat-dialog-close class="ml-auto email-btn mr-3" buttonclass="secondary"
            customClass="admin">Return</app-button>
        <app-button buttonclass="primary" class="email-btn" customClass="admin"
            (click)="saveEmail()">Save</app-button>
    </div>
</mat-dialog-actions>