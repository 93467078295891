<div>
    <div *ngIf="templateData && gridShow" class="col-lg-12 p-0 m-md-1 m-lg-0">
        <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="gridData" [context]="'Report Signatories'"
            [hideEdit]="true" [hideCreate]="createBtn" [hideUpload]="true" [hideExport]="exportBtn" [hideCopy]="true"
            [hideDelete]="true" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
            [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel" [isShowToggle]="true && !editBtn"
            [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true" (editTemplateClicked)="reportSignatoriesStatusUpdate($event)">
        </app-templates-listing>
    </div>
</div>
<!-- <div *ngIf="uploadbtnClicked">
    <app-common-bulk-functions [templateData]="templateData" (uploadBack)=backClicked($event)
        [cardContext]="cardContext" [columnsToRemove]="['organizationid']"></app-common-bulk-functions>
</div> -->