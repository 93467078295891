<div class="modal-dialog">
  <form [formGroup]="UserIpgroup">
    <div class="modal-content" style="margin-top: 60px !important;width: 44vw;right: 9%;">
      <!-- Modal Header -->
      <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt">User IP</h3>
        <span type="button" data-dismiss="modal" (click)="CloseUsermodal()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
            title="Close">
        </span>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row mb-1">
            <div class="col-md-4">
              <label for="DKeys"><strong>Select Deployment:</strong></label>
            </div>
            <div class="col-md-5">
              <select id="DKeys" class="form-control" required formControlName="DeploymentKey"
                (change)="SelectedDeploy(UserIpgroup.value,$event.value)">
                <option value="none" *ngIf="!selecteddefaultdeploymentkey || selecteddefaultdeploymentkey === 'ALL'"
                  disabled selected>--Select--</option>
                <option value="none" *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !== 'ALL'">{{
                  selecteddefaultdeploymentkey }}</option>
                <!-- <option *ngFor="let i of DeploymentKeys" [value]="i">{{ i }}</option> -->
                <ng-container *ngFor="let i of DeploymentKeys">
                  <option [value]="i" *ngIf="i !== selecteddefaultdeploymentkey">{{ i }}</option>
                </ng-container>

              </select>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-4">
              <label for="IPAdd"><strong>IP Address:</strong></label>
            </div>
            <div class="col-md-5" *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !=='ALL'">
              <!-- <input [attr.disabled]="UserIpgroup.value.DeploymentKey == 'none' ? true : null || isselecteddefaultkey" type="text"
                class="form-control" required name="ipaddress" formControlName="IPAdd" placeholder="xxx.xxx.xxx.xxx"> -->
              <!-- <span>{{ UserIpgroup.value.IPAdd }}</span> -->

              <input type="text" class="form-control" required name="ipaddress" formControlName="IPAdd"
                placeholder="xxx.xxx.xxx.xxx">
              <span class="invalid feedback" style="color: red;"
                *ngIf="(UserIpgroup.get('IPAdd').errors &&  UserIpgroup.value.IPAdd != '' && UserIpgroup.value.IPAdd != undefined)">
                Invalid Ip address entered.
              </span>
            </div>

            <div class="col-md-5" *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'">
              <input [attr.disabled]="UserIpgroup.value.DeploymentKey == 'none' ? true : null || isselecteddefaultkey"
                type="text" class="form-control" required name="ipaddress" formControlName="IPAdd"
                placeholder="xxx.xxx.xxx.xxx">

              <!-- <input
              class="form-control" required name="ipaddress" formControlName="IPAdd" placeholder="xxx.xxx.xxx.xxx">
              <span class="invalid feedback" style="color: red;"
                *ngIf="(UserIpgroup.get('IPAdd').errors &&  UserIpgroup.value.IPAdd != '' && UserIpgroup.value.IPAdd != undefined)">
                Invalid Ip address entered.
              </span> -->
            </div>
            <div class="col-md-3 mt-0 px-0">
              <button mat-raised-button class="admin-btn-success"
                      [ngClass]="{
                        'admin-btn-success': UserIpgroup.get('IPAdd').valid && UserIpgroup.get('DeploymentKey').valid,
                        'check-region-button-width': UserIpgroup.invalid
                      }"
                      (click)="fnCheckRegion(UserIpgroup.value)"
                      [disabled]="UserIpgroup.get('IPAdd').invalid || UserIpgroup.get('DeploymentKey').invalid">
                Check Region
              </button>
            </div>
            
          </div>    

            <div class="row mb-1">
              <div class="col-md-4">
                <label for="Region"><strong>Region:</strong></label>
              </div>
              <div class="col-md-5">
                <select class="form-control form-font" formControlName="Country">
                  <option value="US">USA</option>
                  <option value="ST">VitalAxis</option>
                </select>
              </div>
            </div>
            
          
          <div class="row mb-1">
            <div class="col-md-4">
              <label for="Country"><strong>Region Description:</strong></label>
            </div>
            <div class="col-md-5">
              <input type="text" class="form-control" required name="regionDescription" formControlName="Region" placeholder="Description"
                maxlength="100">
            </div>
          </div>
          

        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer" style=" border-top: none !important">
        <div class="container-fluid row">
          <div class="col-md-4 button_width mt-1 px-1">
            <button mat-raised-button class="admin-btn-success" (click)=insertUserIP(UserIpgroup.value)
            [ngClass]="{'admin-btn-success': !(UserIpgroup.invalid),'check-reagion-button-width': UserIpgroup.invalid}"
              [disabled]="UserIpgroup.invalid">Create</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
