import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-hotkey-configuration',
  templateUrl: './hotkey-configuration.component.html',
  styleUrls: ['./hotkey-configuration.component.scss'],
})
export class HotkeyConfigurationComponent implements OnInit {
  @Input() templateData: any;
  gridHeader = [];
  gridHeaderList: any = [];
  ListedGridHeader: any = [];
  SubMenuCardModel: SubMenuCardModel;
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  listHeaderbackup: any;
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  receivedObject: any;
  saveOrEditCompleted: any;
  auditableColumns: any;
  deleteBtn: boolean = true;
  activityEntity: any;
  sourceApp: any;
  storeSub: Subscription = new Subscription();
  userSessionDetails: any;
  inData: any;
  selectedData: any = { UserName: '', UserId: '' };
  filteredData: any;
  dbName: string;
  ViewScreen: boolean = false;
  isGynPapEnabled: any;

  exportExcelFields = {
    HotKeyName: { displayName: "Hot Key Name", isDate: false },
    HotKeyDisplayName: { displayName: "Hot Key Display Name", isDate: false },
    CaseType: { displayName: "CaseType", isDate: false },
    AdequacyCode: { displayName: "Adequacy Code", isDate: false },
    DiagnosisCode: { displayName: "Diagnosis Code", isDate: false },
    UserName: { displayName: "User Name", isDate: false },
    IsActive: { displayName: "Status", isDate: false },
  };

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private labAdminService: LabadminService,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>,
    private activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare
    );
  }
  async ngOnInit() {
    let _this = this;
    let checkLaunchUrl = window.location.href.split('/#/')[1];
    // checking application is running from vitaldx or vital admin and preparing grid data for dispaly
    if (checkLaunchUrl.toString().toLocaleLowerCase() != 'home') {
      _this.sourceApp = 'VitalDx';
      _this.dbName = _this.labAdminService?.deploymentKey;
      await _this.labAdminSessionService.getLabAdminSession
        .pipe(take(1))
        .subscribe(async (session: any) => {
          _this.storeSub = this.store
            .select('breadcrum')
            .pipe(take(1))
            .subscribe(async (result) => {
              let menuObj = result[result.length - 1];
              _this.storeSub.unsubscribe();
              if (
                session['userDetails'] &&
                Object.keys(session['userDetails']).length > 1
              ) {
                _this.userSessionDetails = session.userDetails;
                await _this.commonService.getCommmonTemplateData(
                  menuObj,
                  _this.userSessionDetails
                );
                await _this.getCaseTypes();
                _this.sourceApp = 'VitalDx';
                _this.templateData = _this.labAdminService.templateData;
                sessionStorage.setItem('deploymentKey', _this.dbName);
                sessionStorage.setItem(
                  'org_id',
                  _this.templateData.secondarykeys.OrganizationId
                );
                _this.templateData.submenuData &&
                _this.templateData.submenuData.length > 0
                  ? _this.addGridData(_this.templateData.submenuData)
                  : (_this.noDataFound = true);
                _this.GetButtonAccess(_this.labAdminService.subMenuPermissions);
                _this.getAuditableDetails(_this.templateData.menuURL);
                _this.activityService.setActivitySession({
                  entityId: '',
                  entityType: _this.templateData['menuURL'],
                  context: [
                    { key: 'parentMenu', value: 'Lab' },
                    {
                      key: 'Pathology Management',
                      value: _this.templateData['menuURL'],
                    },
                  ],
                });
                _this.activityService.getActivitySession.subscribe(
                  (res) => (_this.activityEntity = res)
                );
              }
            });
        });
    } else {
      //_this.templateData.submenuData && _this.templateData.submenuData.length > 0 ? _this.addGridData(this.templateData.submenuData) : _this.noDataFound = true;
      //this.getAuditableDetails(_this.templateData.menuURL);
      //this.GetButtonAccess(_this.vitalHttpServices.CasemenuAction);
      //_this.commonService.createContext(_this.templateData.secondarykeys, '', _this.templateData.menuURL);
      //_this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': "parentMenu", 'value': _this.templateData.menuURL }] })
      //_this.activityService.getActivitySession.subscribe(res => _this.activityEntity = res);
      //this.getAuditableDetails(_this.templateData.menuURL);
    }
  }

  // button(s) permission json
  GetButtonAccess(permissions) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = permissions
        .find((e) => e.Htext == 'Templates')
        .SubMenu.find(
          (va) => va.URL == this.labAdminService.templateData.menuURL
        )['ActionButton'];
    } else {
      seletedMenuPermissions = permissions
        .find((e) => e.Htext == this.templateData.headerText)
        ['SubMenu'].find((ele) => ele.URL == this.templateData.menuURL)[
        'ActionButton'
      ];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Create':
          this.createBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Edit':
          this.editBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Export':
          this.exportBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }

  // after create and edit grid refresh with new data
  async getAllHotKeys() {
    let _this = this;
    _this.selectedData = { UserId: '' };
    let query = '';
    let queryVariable = {};
    query = _this.SubMenuCardModel.GetQuery('hotkeyconfigurations');
    queryVariable = {
      orgid: this.labAdminService?.organizationId,
      casetype: 'gyn pap',
    };
    let queryResult = _this.commonService.GetCardRequest(queryVariable, query);
    _this.ngxService.start();
    await _this.vitalHttpServices
      .GetData(queryResult, _this.dbName)
      .toPromise()
      .then(
        (data) => {
          if (!data.errors) {
            _this.templateData.submenuData = data.data.submenuData;
            _this.templateData.submenuData &&
            _this.templateData.submenuData.length > 0
              ? _this.addGridData(_this.templateData.submenuData)
              : (_this.noDataFound = true);
          }
          _this.ngxService.stop();
        },
        (error) => {
          console.error(error);
          _this.ngxService.stop();
        }
      );
  }

  // grid data preparation
  addGridData(templateData: any) {
    this.filteredData = templateData?.filter(
      (item) =>
        item.OrganizationId == this.labAdminService?.organizationId &&
        item.CaseType.toLowerCase() == 'gyn pap'
    );
    let primary = {};
    this.gridHeaderList = this.templateHeaders?.templateList;
    for (let i = 0; i < this.gridHeaderList?.length; i++) {
      if (this.gridHeaderList[i]?.templateName == 'Hot Keys') {
        this.listedGridHeader = this.gridHeaderList[i]?.headers;
        this.listHeaderbackup = JSON?.parse(
          JSON.stringify(this.listedGridHeader)
        );
      }
    }

    let gridarray = [];
    this.gridheader = [
      'HotKeyId',
      'HotKeyName',
      'HotKeyDisplayName',
      'CaseType',
      'AdequacyCode',
      'AdequacyCodeId',
      'DiagnosisCode',
      'DiagnosisCodeId',
      'IsActive',
      'UserName',
      'UserId',
    ];
    for (let i = 0; i < this.filteredData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(this.filteredData[i])) {
        if (this.gridheader.includes(key)) {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          } else {
            primary[key] = value;
          }
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    gridarray.sort((a, b) =>
      a.HotKeyDisplayName.localeCompare(b.HotKeyDisplayName)
    );
    if (this.selectedData.UserId) {
      gridarray = gridarray.filter((item) => {
        return item.UserId === this.selectedData.UserId;
      });
    } else {
      gridarray = gridarray.filter((item) => {
        return item?.UserId?.toString().toLowerCase() == 'not specified';
      });
    }

    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
    return gridarray;
  }

  // receiving create and edit object for db update
  receiveObjFromListing(object: any) {
    try {
      //checking any changes are made for update
      // if(object.newData.Action.toLowerCase() == 'edit' && object.newData.AdequacyCodeId == object.oldData.AdequacyCodeId &&
      //   object.newData.DiagnosisCodeId == object.oldData.DiagnosisCodeId &&
      //   object.newData.HotKeyDisplayName.toLowerCase() == object.oldData.HotKeyDisplayName.toLowerCase() &&
      //   this.normalizeIsActive(object.newData.IsActive) == this.normalizeIsActive(object.oldData.IsActive)
      // )  {
      //   this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      //   this._snackbar.open('There are no changes to update', 'Close');
      //   return;
      // }
      //checking AdequacyCode, Diagnosiscode and UserId combination
      // let isUpsertNotRequired = this.checkCombination(object);
      // if (isUpsertNotRequired) {
      //   this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      //   this._snackbar.open('Combinations are already exists', 'Close');
      //   return;
      // }
      let newObject = {};
      this.ngxService.start();
      if (object.newData.Action == 'Edit') {
        object.newData.HotKeyId = object.oldData.HotKeyId;
        object.newData.UserId =
          object.newData.UserId?.toString().toLowerCase() == 'not specified'
            ? ''
            : object.newData.UserId;
        newObject = this.getFinalObject(object);
      } else {
        object.newData.HotKeyId = 0;
        newObject = object.newData;
      }
      object.newData.HotKeyName = object.newData.HotKeyDisplayName;
      this.vitalHttpServices.hotKeyUpsert(newObject, this.dbName).subscribe(
        async (data) => {
          this.ngxService.stop();
          if (data?.content) {
            if (
              [
                'primary combinations are already exists',
                'display name already exists',
              ].some((msg) =>
                data?.content?.message?.toLowerCase().includes(msg)
              )
            ) {
              this.saveOrEditCompleted =
                this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
              this._snackbar.open(data?.content?.message, 'Close');
              return;
            }
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            // list refresh
            await this.getAllHotKeys();
            // Activity Tracker
            this.createActivityTracker(object, data?.content?.hotkeyId);

            this._snackbar.open(data.content.message, 'Close');
          } else {
            this.saveOrEditCompleted =
              this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open('Failed', 'Close');
          }
        },
        (error) => {
          this.saveOrEditCompleted =
            this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          console.error(error);
          this._snackbar.open(
            'An error occurred while processing your request',
            'Close'
          );
          this.ngxService.stop();
        }
      );
    } catch (error) {
      this.saveOrEditCompleted =
        this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      console.error(error);
      this._snackbar.open(
        'An error occurred while processing your request',
        'Close'
      );
      this.ngxService.stop();
    }
  }

  getFinalObject(data) {
    let object: any = {};
    if (
      data.newData.HotKeyDisplayName.toLowerCase().trim() !=
      data.oldData.HotKeyDisplayName.toLowerCase().trim()
    ) {
      object['HotKeyDisplayName'] = data.newData.HotKeyDisplayName;
      object['HotKeyName'] = data.newData.HotKeyDisplayName;
    }
    if (data.newData.AdequacyCodeId != data.oldData.AdequacyCodeId) {
      object['AdequacyCodeId'] = data.newData.AdequacyCodeId;
    }
    if (data.newData.DiagnosisCodeId != data.oldData.DiagnosisCodeId) {
      object['DiagnosisCodeId'] = data.newData.DiagnosisCodeId;
    }
    object['OrganizationId'] = data.newData.OrganizationId;
    object['CaseType'] = data.newData.CaseType;
    object['IsActive'] = data.newData.IsActive;
    object['UserId'] = data.newData.UserId;
    object['HotKeyId'] = data.newData.HotKeyId;
    object['ModifiedBy'] = data.newData.ModifiedBy;
    return object;
  }
  // preparing object for activity tracker
  createActivityTracker(object, hotkeyId) {
    let newObject = object.newData;
    let oldObject = object.oldData;
    newObject.DiagnosisCode = this.getValueFromList(
      hotkeyId,
      newObject.DiagnosisCodeId,
      'DiagnosisCodeId'
    );
    newObject.AdequacyCode = this.getValueFromList(
      hotkeyId,
      newObject.AdequacyCodeId,
      'AdequacyCodeId'
    );
    newObject.UserName = this.getValueFromList(
      hotkeyId,
      newObject.UserId,
      'UserId'
    );
    newObject.IsActive = newObject.IsActive == 1 ? 'Active' : 'Inactive';
    oldObject.UserName =
      oldObject?.UserName?.toLowerCase() == 'not specified'
        ? ''
        : oldObject.UserName;
    this.cleanObject(newObject);
    this.commonService.auditDetails(
      'HotKeyId',
      'HotKeyDisplayName',
      [oldObject],
      [newObject],
      newObject.HotKeyId == 0 ? 'Create' : 'Edit',
      this.templateData,
      this.auditableColumns
    );
  }

  // getting value from id
  getValueFromList(hotkeyId, value, key) {
    const mapKey = {
      DiagnosisCodeId: 'DiagnosisCode',
      AdequacyCodeId: 'AdequacyCode',
      UserId: 'UserName',
    };
    const foundItem = this.filteredData.find(
      (item) => item[key] === value && item['HotKeyId'] === hotkeyId
    );
    return foundItem ? foundItem[mapKey[key]] : '';
  }

  // cleaning the object if null, empty and undefined
  cleanObject(obj) {
    for (let key in obj) {
      if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }

  // converting active/ inactive from status
  normalizeIsActive(isActive: any): string {
    if (typeof isActive === 'number') {
      return isActive === 1 ? 'active' : 'inactive';
    } else if (typeof isActive === 'string') {
      return isActive.toLowerCase().replace('inactive', 'inactive');
    }
    return 'inactive'; // Default to 'inactive' if unknown type or value
  }

  // checking AdequacyCodeId, DiagnosisCodeId and UserName for edit and create
  checkCombination(object) {
    // for edit operations
    if (object.newData.Action?.toLowerCase() == 'edit') {
      // same combitions: other parameters update
      if (
        object.oldData.UserId == object.newData.UserId &&
        object.oldData.AdequacyCodeId == object.newData.AdequacyCodeId &&
        object.oldData.DiagnosisCodeId == object.newData.DiagnosisCodeId
      ) {
        return false;
      } else {
        if (
          object.newData.UserId?.toString().toLowerCase() == 'not specified'
        ) {
          object.newData.UserId = '';
        }
        // checking different combitions exists or not
        const filtered = this.filteredData?.filter(
          (item) =>
            (item.UserId ?? '') == (object.newData.UserId ?? '') &&
            item.AdequacyCodeId == object.newData.AdequacyCodeId &&
            item.DiagnosisCodeId == object.newData.DiagnosisCodeId
        );
        return filtered && filtered.length > 0;
      }
    } else {
      // for create operations
      const filtered = this.filteredData?.filter(
        (item) =>
          (item.UserId ?? '') == (object.newData.UserId ?? '') &&
          item.AdequacyCodeId == object.newData.AdequacyCodeId &&
          item.DiagnosisCodeId == object.newData.DiagnosisCodeId
      );
      return filtered && filtered.length > 0;
    }
  }

  // getting activity tracker columns from db
  getAuditableDetails(location: any) {
    this.vitalHttpServices
      .getDisplayColumns({ TableName: location })
      .subscribe((res) => {
        this.auditableColumns = JSON.parse(res.content.JsonData);
      });
  }

  // in list page userdropdown filter based on the user selection
  emitFilters($event: any) {
    this.selectedData = { UserId: $event?.UserName?.object?.UserId };
    this.addGridData(this.templateData?.submenuData);
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true;
    } else if ((val = 'openList')) {
      this.ViewScreen = false;
    }
  }

  getCaseTypes() {
    let keyword = 'CaseTypeforAddendumNotes';
    let accountID = this.userSessionDetails?.userAccountIDs
      ?.map((item) => item?.accountId)
      .join(',');
    let orgid = this.labAdminService?.organizationId;

    let queryVariable = {
      id: orgid?.toString(),
      assoicatedaccid: `accountId in \"${accountID}\":string [] `,
    };
    let query = this.SubMenuCardModel.GetQuery(keyword);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(
      (data) => {
        if (!data?.errors) {
          if (data) {
            let requiredCasetype = data?.data?.submenuData?.find((item: any) => {
              return item?.CaseType?.toString()?.toLowerCase() === 'gyn pap';
            });
            this.isGynPapEnabled = requiredCasetype;
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
