<div class="modal-dialog casetypeTemplate">
    <div class="modal-content">
      <div class="row p-2 mt-1">
        <div class="col-md-12 pl-2 pr-2">
          <div class="modal-header admin-model-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
              <strong class="header-View">Casetype Report Template</strong>
            </h3>
          </div>
        </div>
      </div>
  
      <div class="col-md-12 row ml-0 dropdownsection" [formGroup]="frm_Template">
        <div class=" viewSelectContainer p-0 mr-1 depwidth">
          <mat-form-field class="col-sm-12 p-0">
            <mat-label> Deployment </mat-label>
            <mat-select
              formControlName="deploymentname"
              name=""
              formControlName="deploymentname"
              (selectionChange)="onChangeDeployment($event.value)"
              [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'"
            >
              <mat-option *ngFor="let dep of deploymentKey" [value]="dep">
                <div class="truncate-text">
                  {{ dep }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div class=" viewAutoCompleteContainer p-0 mr-2  categorywidth">
            <mat-form-field class="col-sm-12 viewAutoComplete ml-2 p-0">
              <mat-label> Category </mat-label>
              <em class="fa fa-chevron-down chevron-align"></em>
              <input
                trim
                type="text"
                formControlName="category"
                maxlength="200"
                matInput
                aria-label="Category"
                [matAutocomplete]="category"
                (input)="fetchCaseTypeByCategory($event?.target?.value)"
                (click)="fetchCaseTypeByCategory($event?.target?.value)"
              />
  
              <mat-autocomplete #category="matAutocomplete" (optionSelected)="fetchCaseTypeByCategory($event.option.value);resetFields();">
                <mat-option
                  class="mat-opt-align"
                  id="drop-down-mat"
                  *ngFor="let category of CategoryList"
                  [value]="category"
                >
                  <span>{{ category }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
  
        <div class=" viewAutoCompleteContainer p-0 mr-2 ">
          <mat-form-field class="col-sm-12 viewAutoComplete ml-2 p-0">
            <mat-label> Casetype </mat-label>
            <em class="fa fa-chevron-down chevron-align"></em>
            <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
            <input
              trim
              type="text"
              formControlName="casetypename"
              maxlength="200"
              matInput
              aria-label="Casetype"
              [matAutocomplete]="casetypename"
              (click)="fetchCaseTypeBySearch($event.target.value)"
              (input)="fetchCaseTypeBySearch($event.target.value)"
            />
            <mat-autocomplete #casetypename="matAutocomplete">
              <mat-option
                class="mat-opt-align"
                id="drop-down-mat"
                *ngFor="let casetype of FiltercasetypeList"
                [value]="casetype"
                (onSelectionChange)="fetchCaseTypeBySearch($event.target.value);"
              >
                <span  matTooltip="{{ casetype }}">{{ casetype }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
  
        <div class=" viewAutoCompleteContainer p-0 mr-2 ">
          <mat-form-field class="col-sm-12 viewAutoComplete ml-2 p-0">
            <mat-label> Template Type</mat-label>
            <em class="fa fa-chevron-down chevron-align"></em>
            <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
            <input
              trim
              type="text"
              formControlName="templatetype"
              maxlength="200"
              matInput
              aria-label="templatetype"
              [matAutocomplete]="templatename"
              (click)="fetchTemplatesBySearch($event.target.value)"
              (input)="fetchTemplatesBySearch($event.target.value)"
            />
            <mat-autocomplete #templatename="matAutocomplete">
              <mat-option
                class="mat-opt-align"
                id="drop-down-mat"
                *ngFor="let template of FilterTemplateList"
                [value]="template"
                (onSelectionChange)="resetFields();fetchTemplatesBySearch($event.target.value)"
              >
                <span>{{ template }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
  
        <div class="btnStyle">
          <app-button
            [isDisabled]="isBtnDisabled(frm_Template)"
            buttonclass="primary"
            class="col-sm-8 edit-page-button downloadbutton"
            (click)="GetTemplateName(frm_Template)"
            >Get Data</app-button
          >
        </div>
      </div>
  
      <div class="col-md-12 row ml-0" *ngIf="isGetDataClicked" [formGroup]="frm_EditTemplate" style="
      max-height: 50px;
  ">
        <div class="col-md-12 row">
          <div
            class="viewInputContainer inputStyle"
            *ngIf="isCurrentTemplateAvailble"
          >
            <mat-form-field class="col-sm-12 p-0">
              <mat-label> Current Template Name </mat-label>
              <input
                formControlName="currentfilename"
                name="currentfilename"
                trim
                matInput
                type="text"
                class="ellipsis-input"
                style="width: 90%;"
                [matTooltip]="currentfilenameTooltip"
              />
              <img class="copyIcon copy-data cursor" alt="copy"
              (click)="copy(frm_EditTemplate)"
              src="/assets/images/copy.svg" />
            </mat-form-field>
          </div>
  
          <div class="viewInputContainer inputStyle">
            <mat-form-field class="col-sm-12 p-0">
              <mat-label> New Template Name </mat-label>
              <input
                formControlName="newfilename"
                name="newfilename"
                trim
                matInput
                type="text"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div
        class="col-sm-12 align-center"
        [ngClass]="{
          'isGetDataClickedtrue': isGetDataClicked,
          'isGetDataClickedfalse': !isGetDataClicked
        }"
      >
        <div class="cols-sm-9"></div>
        <div class="d-flex">
          <div class="saveorcloseBtn">
            <app-button
              buttonclass="secondary"
              class="ml-auto edit-page-button"
              (click)="CloaseModalPage()"
              >Close</app-button
            >
          </div>
          <div class="saveorcloseBtn">
            <app-button
              buttonclass="primary"
              class="edit-page-button ml-2"
              [isDisabled]="isSaveEnable(frm_EditTemplate)"
              (click)="onSaveReportTemplate(frm_EditTemplate, frm_Template)"
              >Save</app-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  