<div *ngIf="sourceApp =='VitalDx' && !gridShow" class="px-4">
    <app-shimmerui></app-shimmerui>
</div>
<div [ngStyle]="{'display': gridShow ? 'block' : 'none'}">
    <app-templates-listing *ngIf="templateData" [gridHeader]="listedGridHeader" [gridData]="gridData" 
        listContext="Medications" addEditContext="Medications" 
        [context]="'Medications'" [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="true"
        [hideExport]="exportBtn" [hideCopy]="true" (closingListTemplate)="handleListingScreen($event)"
        (editTemplateClicked)="newEditMethod($event)"  (emitFilters)="emitFilters($event)"
        (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
        [isAdmin3]="sourceApp =='VitalDx'? false : true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null">
    </app-templates-listing>
</div>