import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { ActivityTrackerService } from "src/app/core/services/activity-tracker.service";
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from '../../../core/services/commonservices';

@Component({
  selector: 'app-pathology-sign-upload',
  templateUrl: './pathology-sign-upload.component.html',
  styleUrls: ['./pathology-sign-upload.component.scss']
})
export class PathologySignUploadComponent implements OnChanges {
  @Input()
  templateData: any;
  @Input()
  mainCard: any;
  sign_UploadSRC = "";
  files: File[] = [];
  allow = true;
  NewimageSrc: any;
  myForm = new FormGroup({
    sign_Upload: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required]),
    selectedRole:new FormControl('')
  });

  public noSign = true;
  filesData: any;
  filelist: any[];
  imagePath: boolean = true;
  public hideUploadBtn: boolean = true;
  public hideRemoveBtn: boolean = true;
  public ifStatusDelete: boolean = true;
  // public actionButtonDetails: any[];
  imageSrc: string;
  userRoles: any;
  userFlag: boolean = false;
  selectedRole: any = null;
  selectedUser: any = [];
  userSelected: boolean;
  noRole: boolean;
  oldImage: any;
  activityEntity: any;

  constructor(public commonService: CommonService, private ref: ChangeDetectorRef,public activityService: ActivityTrackerService, public _snackbar: MatSnackBar,
    private vitalHttpServices: VitalHttpServices, private dialog: MatDialog, public ngxService: NgxUiLoaderService, public DataShare: DataShareService) {
  }

  ngOnInit() {
    //this.userRoles = this.commonService.userRoles(this.templateData.menuURL.replace(/\s/g, ''))
    if (sessionStorage.getItem("search_context").toLowerCase() == "users" || sessionStorage.getItem("search_context").toLowerCase() == 'physician' || sessionStorage.getItem("search_context").toLowerCase() == 'pathologist' || sessionStorage.getItem("search_context").toLowerCase() == "userid") {
      this.userFlag = true; // for user card type
      this.userRoles = this.commonService.userRoles(this.templateData.menuURL.replace(/\s/g, ''));
      if (this.userRoles && this.userRoles.length > 0) {
        let userID = this.userRoles[0].userid.toString();
       this.myForm.patchValue({
        selectedRole:this.userRoles[0].DisplayName.toString()
       })
        this.selectedUser = userID
        this.userSelected = true;
      }
      else {
        this.noRole = true;
      }
    }
    this.getExistingSign();
    this.GetButtondetails();
    if (this.mainCard.Status.toLowerCase() == "deleted") {
      this.ifStatusDelete = true;
    } else {
      this.ifStatusDelete = false;
    }
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': 'Users' },{ 'key': 'userid', 'value': this.selectedUser }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  ngOnChanges() {
  }

  removeNewSign() {
    this.NewimageSrc = "";
    this.imagePath = true;
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  prepareFilesList(event) {
    let reader = new FileReader();
    reader.onload = function () {
      let output: any = document.getElementById('blah');
      output.src = reader.result;
    }
    this.imagePath = false;
    if (event[0]) {
      reader.readAsDataURL(event[0]);
    }
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe(data => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "Remove":
          this.hideRemoveBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  getExistingSign() {
    if (this.mainCard.Primary_Role.toLowerCase() === "pathologist" || this.mainCard.Primary_Role.toLowerCase() === "physician" || this.userRoles.some(va=> va.RoleName.toString().match(/pathologist/i) || va.RoleName.toString().match(/physician/i))){
      this.allow = true;
      let data = {
       // userid: this.userRoles[0] ? this.userRoles[0].userid.toString() : this.templateData.submenuData[0].userid
        userid: this.selectedUser
      };
      this.vitalHttpServices.GetSignFile(data).subscribe(res => {
        this.ngxService.start();
        if (res.Success) {
          if (res.Message != undefined && res.Message != "" && res.Message != null) {
            this.imageSrc = "data:image/jpg;base64," + res.Message;
            this.oldImage = "data:image/jpg;base64," + res.Message;
            this.noSign = false;
            this.imagePath = true;
          }
          else {
            this.imageSrc = "";
            this.noSign = true;
          }
        }
        this.ngxService.stop();
      }, error => {
          this.ngxService.stop();
          this.noSign = true;
          this._snackbar.open("An error occurred while processing your request", "Failed");
          console.error(error);
        })
    }
    else {
      // this._snackbar.open("Rolename isn't Physician or Pathologist");
      this.allow = false;
      this.noSign = true;
    }
  }

  get f() {
    return this.myForm.controls;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.myForm.patchValue({
      sign_Upload: "",
      fileSource: "",
    });
  }

  onFileChange(event) {
    if (!this.hideUploadBtn && !this.ifStatusDelete) {
      this.onRemove(event);
      let tempArrData: any
      if (event.target) {
        tempArrData = event.target.files[0];
      } else {
        tempArrData = event[0];
      }
      if (tempArrData.type.toString().toLowerCase() == "image/png" || tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
        event.addedFiles = [];
        event.addedFiles.push(tempArrData);
        this.files.push(...event.addedFiles);
        const reader = new FileReader();
        if (this.files && this.files.length) {
          const [sign_Upload] = this.files;
          reader.readAsDataURL(sign_Upload);
          reader.onload = () => {
            this.NewimageSrc = reader.result as string;
            this.imagePath = false;
          };
        } else {
          this._snackbar.open("An error occurred while processing your request", "Failed");
        }
      } else {
        this._snackbar.open("Please upload image file only", "Failed");
      }
    }
    else {
      this._snackbar.open("You don't have the required privileges' to upload signature!", "Close");
    }
  }

  toDataURL = async (url) => {
    var res = await fetch(url);
    var blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };

// old and new sign
  jsonObjectOfSign() {
    let oldValue = JSON.stringify({
      "source": this.oldImage,
      "type": "image"
    });
    let newValue = JSON.stringify({
      "source": this.NewimageSrc,
      "type": "image"
    })
    let jsonObject = {
      "Signature": {
        "oldValue": this.oldImage ? oldValue : null,
        "newValue": this.NewimageSrc ? newValue : null
      }
    }
    return jsonObject;
  }

  removeExistingSign() {
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '250px',
      data: { header: "Remove Sign", message: "Clicking on yes will remove the existing sign", alert: "Do you wish to continue?", continue: "yes", cancel: "no" }
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.ngxService.start();
        let data = {
          //userid: this.userRoles[0] ? this.userRoles[0].userid.toString() : this.templateData.submenuData[0].userid
          userid: this.selectedUser
        };
        this.vitalHttpServices.RemoveSign(data).subscribe(res => {
          if (res.Success) {
            this.commonService.createActivityObject(this.selectedUser, 'Signature', this.templateData.menuURL, 'Delete', '', '', '', [], '', this.jsonObjectOfSign());
            this._snackbar.open(res.Message, "Success");
            this.getExistingSign();
            this.oldImage = null;
            this.onRemove(event);
          } else {
            this._snackbar.open(res.Message, "Failed");
          }
          this.ngxService.stop();
        }, error => {
            this._snackbar.open("An error occurred while processing your request", "Failed");
          });
      }
    })
  }

  async ConfirmUploadSign() {
    if (this.NewimageSrc != "") {
      this.ngxService.start();
      // let accid = "";
      // if (this.mainCard.Locations != "")
      //   accid = this.mainCard.Locations.split(",")[0].split('(')[1].split(')')[0];
      let imageSrcString = await this.toDataURL(this.NewimageSrc)
      imageSrcString = imageSrcString.toString().split(",")[1];
      let data = {
        signFile: imageSrcString,
       // UserID: this.userRoles[0] ? this.userRoles[0].userid.toString() : this.templateData.submenuData[0].userid.toString() ,
        UserID: this.selectedUser ,
        //AccountID: accid
        AccountID: ""
      };
      this.vitalHttpServices.ConfirmUploadSign(data).subscribe(res => {
        if (res.Success) {
          this.commonService.createActivityObject(this.selectedUser, 'Signature', this.templateData.menuURL, 'Edit', '', '', '', [], '', this.jsonObjectOfSign());
          this._snackbar.open(res.Message, "Success");
          this.getExistingSign();
          this.onRemove(event);
        } else {
          this._snackbar.open(res.Message, "Failed");
        }
        this.ngxService.stop();
      }, error => {
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        })
    }
    else {
      this._snackbar.open("Please upload signature file", "Failed");
    }
  }

  setUserRole(event, data) {
    if (event.source.selected) {
      let userID = data.userid.toString();
      this.myForm.patchValue({
        selectedRole:data.DisplayName.toString()
       })
      this.selectedUser = userID
      this.userSelected = true;
      this.getExistingSign();
    }
  }
}

