
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';

import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-tubetype-templates',
  templateUrl: './tubetype-templates.component.html',
  styleUrls: ['./tubetype-templates.component.scss']
})
export class TubetypeTemplatesComponent implements OnDestroy {

  destDeployment: string = '';
  SubMenuCardModel: SubMenuCardModel;
  @Input() templateData: any;
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  gridHeaderList: any;
  listHeaderbackup: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  receivedObject: any;
  saveOrEditCompleted: any;
  auditableColumns: any;
  deleteBtn: boolean;
  activityEntity: {};
  deployment=sessionStorage.getItem('deploymentKey').toUpperCase();

  userSessionDetails: any;
  sourceApp: string;
  dbName: any;
  orgId:any;
  isOnInitCalled = false;
  showLabAdminLoader: any = 'show';
  exportExcelFields = {
    AttributeValue: { displayName: "Template Name", isDate: false },
    CasetypeDisplayName: { displayName: "Case Type", isDate: false },
    AttributeDescription: { displayName: "Description", isDate: false },
    IsActive: { displayName: "Status", isDate: false },
    CreatedBy: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    ModifiedBy: { displayName: "Modified By", isDate: false },
    ModifiedDate: { displayName: "Modified Date", isDate: true },
  };

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    private activityService: ActivityTrackerService,
    private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  async ngOnInit() {
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      
      let _this = this;
      _this.sourceApp = 'VitalDx'
      _this.showLabAdminLoader = 'show';
      if(this.isOnInitCalled === false) {
        await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
          if (Object.keys(session["userDetails"]).length > 1) {
            _this.userSessionDetails = session["userDetails"]
            _this.sourceApp = 'VitalDx';
            let menuObj = {}
            await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
                menuObj = result[result.length - 1];
            });
            await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
            _this.templateData = _this.labadminService.templateData;
            _this.dbName = _this.labadminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', _this.dbName);
            sessionStorage.setItem('org_id', _this.templateData.secondarykeys.OrganizationId);
            _this.orgId= _this.userSessionDetails?.organizationId;
  
            // _this.templateData.submenuData = _this.templateData.submenuData.filter(va => va.AttributeType == "TubeType");
            _this.templateData.submenuData && _this.templateData.submenuData.length > 0 ? _this.addGridData(_this.templateData.submenuData) : _this.showLabAdminLoader = 'hide';
              if (!_this.templateData?.submenuData?.length) _this.gridData = new CollectionView(...[]);
              _this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
              _this.deployment = _this.destDeployment;
            _this.getAuditableDetails('Tubetype Templates');
            _this.GetButtonAccess(_this.labadminService.subMenuPermissions);
            _this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Tubetype Templates', 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':'Tubetype Templates'}] });    
            _this.activityService.getActivitySession.subscribe(res => _this.activityEntity = res);
          }
        })
      }
    }
    else {
      // this.getAllTubetypeTemplates();
      // this.templateData.submenuData =this.templateData.submenuData.filter(va => va.AttributeType == "TubeType");
      this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
        if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
        this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
      // this.addGridData(this.templateData.submenuData);
      this.getAuditableDetails(this.templateData.menuURL)
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
      this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);    
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    }
  }



  // ngOnInit(): void {
  //  // this.getAllTubetypeTemplates();
  //  this.templateData.submenuData =this.templateData.submenuData.filter(va => va.AttributeType == "TubeType");
  //  this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
  //    if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
  //   this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
  //   // this.addGridData(this.templateData.submenuData);
  //   this.getAuditableDetails(this.templateData.menuURL)
  //   this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
  //   this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);    
  //   this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  // }

  GetButtonAccess(CasemenuAction) {

    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"];
    } else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL = this.templateData.menuURL)['ActionButton'];
    }

    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getAllTubetypeTemplates() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('tubetypetemplate');
    if(this.sourceApp !== 'VitalDx') {
      queryVariable = {
        "orgid": sessionStorage.getItem('org_id').toString(),
         "casetype": this.templateData.secondarykeys.casetype.toString(),
        // "attributetype" : "TubeType"
      };
    } else {
      queryVariable = {
        "orgid": sessionStorage.getItem('org_id').toString(),
        // "attributetype" : "TubeType"
      };
    }
    
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.showLabAdminLoader = 'show';
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
        }
        this.ngxService.stop();
        this.showLabAdminLoader = 'hide';
      },
      error => {
        // this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
        this.showLabAdminLoader = 'hide';
      }
    );
  }

  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Tubetype Templates') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    let gridarray = [];
    this.gridheader = [
                      'AttributeID',
                      'CaseType', 
                      'AttributeValue',
                      'AttributeDescription',
                      'CreatedBy',
                      'ModifiedBy',
                      'ModifiedDate',
                      'CreatedDate',
                      'IsActive',
                      'CasetypeDisplayName'
                    ]
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'AttributeValue' || key == 'Description') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    gridarray.sort((a, b) => a.AttributeValue.localeCompare(b.AttributeValue))
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.showLabAdminLoader = 'hide';
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
  }


  recieveObjFromListing(object: any) {
    object.newData['CaseType']
    object.newData = { ...object.newData, ...{ 'AttributeType': 'TubeType' ,'AttributeName' : 'TubeType' } };
    if(this.sourceApp === 'VitalDx') {
      object['newData']['CaseType'] = 'Clinical Chemistry';
    }
    if (object.newData.Action == 'Edit') {
      object.newData['AttributeID'] = object.oldData['AttributeID']
      this.insertOrEditTubetypeTemplate(object)
    }
    else if (object.newData.Action != 'Create' && object.newData.Action != 'Edit') {
          this.deleteTemplate(object)
        }
        else {
          this.insertOrEditTubetypeTemplate(object)
        }
  }

  insertOrEditTubetypeTemplate(data) {
    this.ngxService.start();
    // this.showLabAdminLoader = 'show';
    this.vitalHttpServices.templateManagementCECorelationTemplate(data.newData).subscribe(response => {
      if (response.status == "Success") {
        if (response.content.message.toString().includes('already exist')) {
          this._snackbar.open(response.content.message, "Close");
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          this.showLabAdminLoader = 'hide';
          return
        }
        else {
          if (data.newData.Action === 'Create') {
            data.newData.IsActive = data.newData.IsActive == 1 ? 'Active' : 'Inactive'
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('attributeId', 'AttributeValue', [response.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
            this.getAllTubetypeTemplates();
          }
          else if (data.newData.Action === 'Edit') {
            data.newData.IsActive = data.newData.IsActive == 1 ? 'Active' : 'Inactive';
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('AttributeID', 'AttributeValue', [data.oldData], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
          
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(response.content.message, "Close")
          }
          this.getAllTubetypeTemplates();
          //  this.addGridData(this.templateData.submenuData);
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open("failed!", "Close")
      }
      this.ngxService.stop();
      this.showLabAdminLoader = 'hide';
    }, error => {
      this.ngxService.stop();
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.showLabAdminLoader = 'hide';
      console.error(error);
    })
  }

  deleteTemplate(value) {
    if (!this.deleteBtn) {
      delete value?.rowData?.ModifiedBy
      delete value?.rowData?.CreatedBy
      value.rowData.IsActive = value.rowData.IsActive == 'Active' ? 1 : 0;
      value.rowData = { ...value.rowData, ...{ 'Action': 'Delete' ,  "AttributeType" : "TubeType", "OrganizationId" : this.sourceApp =='VitalDx' ? this.labadminService.organizationId : sessionStorage.getItem('org_id')} };
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
          autoFocus: false,
        width: '360px',
        panelClass: 'admin-custom-popup',
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
          })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.templateManagementCECorelationTemplate(value.rowData).subscribe(
            () => {
              value.rowData.IsActive = this.commonService.getStatus(value.rowData.IsActive);
              this.commonService.auditDetails('AttributeID', 'AttributeValue', [value.rowData], [], 'Delete', this.templateData, this.auditableColumns);
              this._snackbar.open('Tubetype template deleted successfully', 'Close');
              this.getAllTubetypeTemplates();
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  ngOnDestroy(): void {
    this.isOnInitCalled = false;
  }


}
