import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import { Store } from '@ngrx/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CreateActivityTrackerInput, Operations } from 'src/app/model/activity-tracker';
import { DatePipe } from '@angular/common';
import { DialogService } from '../../../core/services/dialog.service';

@Component({
  selector: 'app-workgroup',
  templateUrl: './workgroup.component.html',
  styleUrls: ['./workgroup.component.scss']
})
export class WorkgroupComponent {

  @Input() templateData: any;
  @Output() closeViewTemplate: EventEmitter<any> = new EventEmitter<any>();

  roleDropdownOptions = [];
  tabDisplayNameDropdownOptions = [];
  customWorkListDropdownOptions = [];

  auditableColumns: any;
  sourceApp: string = 'Admin';
  gridheader: string[];
  listHeaderbackup: any;
  destDeployment: string = sessionStorage.getItem('DeploymentKey').toUpperCase();
  SubMenuCardModel: any;
  saveOrEditCompleted: any;
  showLabAdminLoader: string;
  gridShow: boolean;
  userSessionDetails: any;
  storeSub: Subscription = new Subscription();
  isEdit = false;
  rowData: any;
  backupRowData: any;
  isFormChanged = false;

  gridHeaderList: { templateName: string; headers: { Name: string; type: string; key: string; colWidth: string; }[]; }[];

  listedGridHeader: any = [];
  gridData: CollectionView<any>;

  uploadBtn: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  gridArrayData: any;
  isSaveDisabled = false;
  createActivityInput = new CreateActivityTrackerInput();
  createActivityOperations = new Operations();
  dropdownRoleSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };

  dropdownTabSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };

  dropdownDisplaySettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  activityEntity: any = {};

  constructor(
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private vitalHttpServices: VitalHttpServices,
    private VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    private labAdminService: LabadminService,
    private labAdminSessionService: LabadminSessionService,
    private activityService: ActivityTrackerService,
    public datePipe: DatePipe,
    private store: Store<{ breadcrum: [] }>,
    private matdialog: DialogService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  async ngOnInit() {

    this.dropdownRoleSettings = {
      singleSelection: false,
      idField: 'Role',
      textField: 'Role',
      enableCheckAll: true,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 70,
      itemsShowLimit: 1,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    this.dropdownTabSettings = {
      singleSelection: false,
      idField: 'TabDisplayName',
      textField: 'TabDisplayName',
      enableCheckAll: true,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 70,
      itemsShowLimit: 1,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    this.dropdownDisplaySettings = {
      singleSelection: false,
      idField: 'DisplayName',
      textField: 'DisplayName',
      enableCheckAll: true,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 70,
      itemsShowLimit: 1,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };

    await this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    await this.workGrouporgDept()
    delete this.templateData.secondarykeys?.casetype;
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
  }

  getRoles() {
    let dbName;
    let query = this.GetQuery('organizationcustomworkrolelist');
    // let queryString = null
    let queryVariable: any = { "orgid": sessionStorage.getItem('org_id') };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.submenuData && res.data.submenuData.length > 0) {
        this.roleDropdownOptions = res.data.submenuData?.map((item: any) => item?.Role);
        if(this.roleDropdownOptions) this.roleDropdownOptions = this.getUniqueData(this.roleDropdownOptions);
        if (this.rowData?.role) {
          this.getTabDisplayName();
        }
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }


  // grid list
  workGrouporgDept() {
    this.ngxService.start();
    let data = {
      orgId: Number(this.commonService.orgid)
    }
    this.isFormChanged=false;
    this.VitalHttpServices.workGrouporgDepartments(data,this.destDeployment).subscribe(response => {
      if (response?.content?.length) {
        response.content = response?.content.map(obj => ({
          ...obj,
          role: obj.role.split(',').map(item => item.trim()),
          tabDisplayName: obj.tabDisplayName.split(',').map(item => item.trim()),
          customWorklist: obj.customWorklist.split(',').map(item => item.trim())
        }));
      }
      this.gridArrayData = response.content
      this.addGridData(this.gridArrayData);
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    this.listedGridHeader = [...[]];
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Workgroups') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }
    }
    let gridarray = [];
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        primary[key] = value;
      }
      gridarray.push(primary);
    }
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.showLabAdminLoader = 'hide';
    this.ngxService.stop();
    return gridarray;
  }

  // getAuditableDetails(location: any) {
  //   this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
  //     this.auditableColumns = JSON.parse(res.content.JsonData);
  //   })
  // }

  recieveObjFromListing(object: any) {
    if (!object?.newData?.departmentName || !object?.newData?.roleName?.length || !object?.newData?.tabName?.length || !object?.newData?.displayName?.length) {
      this._snackbar.open("Mandatory field(s) are missing", "Close");
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
    } else {
      let transformed = {
        JsonData: {
          departmentname: object.newData.departmentName,
          departmentid: null,
          organizationid: parseInt(object.newData.OrganizationId),
          isactive: !!object.newData.isActive,
          role: object.newData.roleName.join(','),
          tabdisplayname: object.newData.tabName.join(','),
          worklist: object.newData.displayName.join(','),
          createdby: Number(sessionStorage?.getItem('Userid')) || -100,
          modifiedby: Number(sessionStorage?.getItem('Userid')) || -100
        }
      };
      object.newData = { ...object.newData, ...{ 'Context': 'Workgroups' } };
      if (object.newData.Action == 'Edit') {
        object.newData['Id'] = object.oldData['Id']
        this.insertOrEditSequesterTemplate(object)
      }
      else {
        this.insertOrEditSequesterTemplate(transformed)
      }
    }
  }

  // create workgroup
  insertOrEditSequesterTemplate(data) {
    this.ngxService.start();
    this.vitalHttpServices.workGroupSave(data, this.destDeployment).subscribe(response => {
      if (response.status == "Success") {
        if (response.content?.Message.toString().includes('already exist')) {
          this._snackbar.open(response.content.Message, "Close");
          this.isEdit = false;
          this.workGrouporgDept()
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          return
        }
        else {
          let action = 'edit'
          if (data.JsonData.departmentid === null) {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this._snackbar.open(response.content.Message, "Close");
            this.isEdit = false;
            action = 'create';
            data.JsonData.departmentid = response.content.departmentid;
            this.workGrouporgDept()
            // this.commonService.auditDetails('Id', 'TemplateName', [response.content], [data], data.Action, this.templateData, this.auditableColumns);
          }
          else if (data.JsonData.departmentid != null) {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this._snackbar.open(response.content.Message, "Close");
            this.isEdit = false;
            this.workGrouporgDept()
            // this.commonService.auditDetails('Id', 'TemplateName', [data.oldData], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
          }
          else {
            this._snackbar.open(response.content.Message, "Close")
            this.isEdit = false;
            this.workGrouporgDept()
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          }
          
          let oldObj = action == 'edit' ? JSON.parse(response.content.oldObj) : undefined;
          let newObj = response.content.newObj ? JSON.parse(response.content.newObj) : undefined;
          const finalObj = this.getATObj(data,oldObj,newObj,action);
          const { departmentid,departmentname} = data.JsonData; 
          const activityInfo = action == 'edit' ? `Modified workgroup for department - "${departmentname}" ` : `Created workgroup with department - "${departmentname}" `
          const transactionId = this.commonService.generateGuid()
          this.commonService.createActivityObject(departmentid,departmentname,this.templateData.menuURL,action != 'edit' ? 'Create' : 'Edit',{},{},transactionId,{},"",finalObj,activityInfo);
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open(response.content.Message, "Close")
        this.isEdit = false;
        this.workGrouporgDept()
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  private transformObjForAT(inputObj: any, oldObjSent: boolean = false): any {
    const result = [];
  
    // Iterate through each role in the input object
    Object.keys(inputObj).forEach(role => {
      const tabWorkLists = {};
  
      // Iterate through each tab object under the current role
      inputObj[role].forEach(tabObj => {
        const tabName = Object.keys(tabObj)[0];  // Extract tab name
        const workListArray = tabObj[tabName].Worklist.split(",").sort();  // Split and sort work lists
  
        if (!tabWorkLists[tabName]) {
          tabWorkLists[tabName] = [];
        }
  
        // Process each work list in the sorted array
        workListArray.forEach((workListName) => {
          const workListChange = oldObjSent ? {
            "Worklist Name": { oldValue: workListName , newValue: "" }
          } : {
            "Worklist Name": { oldValue: "" , newValue: workListName }
          };
  
          tabWorkLists[tabName].push(workListChange);  // Add work list change to tab's work lists
        });
      });
  
      // Iterate through tab work lists and structure them under role and tab names
      Object.keys(tabWorkLists).forEach(tabName => {
        const workListEntry = {
          "Role Name": { oldValue: role,newValue: role },  // Set role name
          "Tab Name": { oldValue: tabName,newValue: tabName },  // Set tab name
          "Worklists": tabWorkLists[tabName]  // Assign work lists for the role and tab
        };
  
        result.push(workListEntry);  // Add structured entry to result array
      });
    });
  
    return result.length ? { "Worklists" : result } : {};  // Return formatted result object
  }

  private captureChanges(oldObj: any, newObj: any): any {
    // Deep clone newObj to prevent mutation of original object
    let updatedJson = JSON.parse(JSON.stringify(newObj));
  
    // Iterate through each object in oldObj.WorkLists
    for (const obj1 of oldObj.Worklists) {
      let found = false;
  
      // Compare each obj1 with corresponding obj2 in updatedJson.Worklists
      for (let obj2Index = 0; obj2Index < updatedJson.Worklists.length; obj2Index++) {
        let obj2 = updatedJson.Worklists[obj2Index];
        
        // Check if obj1 and obj2 match based on Role Name and Tab Name
        if (this.objectsHaveChanged(obj1, obj2)) {
          found = true;
          let workListJson = { Worklists: [] };
  
          // Check for Worklists in obj1 that are not in obj2
          for (const obj of obj1.Worklists) {
            if (!obj2.Worklists.find(item => item["Worklist Name"].newValue == obj["Worklist Name"].oldValue)) {
              workListJson.Worklists.push(obj);
            }
          }
  
          // Check for Worklists in obj2 that are not in obj1
          for (const obj of obj2.Worklists) {
            if (!obj1.Worklists.find(item => item["Worklist Name"].oldValue == obj["Worklist Name"].newValue)) {
              workListJson.Worklists.push(obj);
            }
          }
  
          // If there are changes to Worklists, update obj2.Worklists; otherwise, delete obj2
          if (workListJson.Worklists.length > 0) {
            obj2.Worklists = workListJson.Worklists;
          } else {
            updatedJson.Worklists.splice(obj2Index, 1);
          }
  
          break; // Exit the loop once a match is found
        }
      }
  
      // If obj1 is not found in updatedJson.Worklists, add it
      if (!found) {
        obj1["Role Name"].newValue = "";
        obj1["Tab Name"].newValue = "";
        updatedJson.Worklists.push(obj1);
      }
    }
  
    return updatedJson;  // Return updated JSON object
  }

  //Checks if two objects match based on Role Name and Tab Name.
  private objectsHaveChanged(obj1: any, obj2: any): boolean {
    return (
      obj1["Role Name"].newValue === obj2["Role Name"].newValue &&
      obj1["Tab Name"].newValue === obj2["Tab Name"].newValue
    );
  }


  getATObj(data,oldObj: any = {}, newObj: any = {}, actionBtn: string = 'edit'): any {
    // If no old object exists or action button is not 'edit', transform newObj
    if (!Object.keys(oldObj).length || actionBtn?.toString().toLowerCase().trim() !== 'edit') {
      newObj = this.transformObjForAT(newObj);
      newObj.Status = { newValue : data.JsonData.isactive ? 'Active' : 'Inactive' }
    } else {
      // Transform oldObj and newObj for comparison
      oldObj = this.transformObjForAT(oldObj, true);
      newObj = this.transformObjForAT(newObj);
      
      // Capture changes between oldObj and newObj
      newObj = this.captureChanges(oldObj, newObj);
      let isActive = this.backupRowData.isActive?.toString().toLowerCase() == 'active' ? true : false;  
      if(data.JsonData.isactive != isActive){
        newObj.Status = { oldValue : this.backupRowData.isActive ,newValue : data.JsonData.isactive ? 'Active' : 'Inactive' } 
      }
    }
    if(!newObj.Worklists?.length)
        delete newObj.Worklists
    return newObj;  // Return formatted object suitable for action
  }

  // RBAC
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL = this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  editTemplateClicked($event: any) {
    $event.rowData.role = $event.rowData.role.map(va=> va.toString().toLowerCase())
    this.rowData = structuredClone($event.rowData);
    this.backupRowData = structuredClone($event.rowData);
    this.rowData.isActive = ['Yes', true, 'Active'].includes(this.rowData.isActive) ? 1 : 0;
    this.activityEntity['entityId'] = this.rowData.departmentId;
    this.activityService.setActivitySession(this.activityEntity);
    this.getRoles();
  }

  getTabDisplayName() {
    let dbName;
    let query = this.GetQuery('organizationcustomworkTabNamelist');
    // let queryVariable = { "role": this.rowData?.role, "orgid": sessionStorage.getItem('org_id') };
    // queryVariable = this.transformObject(queryVariable);
    // filterParameter: `role in \"${this.rowData?.role?.join(",")}\"AND tabdisplayname in \"${this.rowData?.tabDisplayName?.join(",")}\":string[]`,
    let queryVariable = {
      filterParameter: `role in \"${this.rowData?.role?.join(",")}\":string[]`,
      "orgid": sessionStorage.getItem('org_id')
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.submenuData && res.data.submenuData?.length > 0) {
        this.tabDisplayNameDropdownOptions = structuredClone(res.data.submenuData?.map((item: any) => item?.TabDisplayName));
        if(this.tabDisplayNameDropdownOptions) this.tabDisplayNameDropdownOptions = this.getUniqueData(this.tabDisplayNameDropdownOptions);
        this.getCustomWorkList();
      } else {
        this.tabDisplayNameDropdownOptions = [];
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  getCustomWorkList() {
    let dbName;
    let query = this.GetQuery('organizationcustomworkDisplayNamelist');
    let queryVariable: any;
    queryVariable = {
      filterParameter: `role in \"${this.rowData?.role?.join(",")}\":string[] AND tabdisplayname in \"${this.rowData?.tabDisplayName?.join(",")}\":string[]`,
      "orgid": sessionStorage.getItem('org_id')
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.submenuData) {
        this.customWorkListDropdownOptions = structuredClone(res.data.submenuData?.map((item: any) => item?.DisplayName));
        if(this.customWorkListDropdownOptions) this.customWorkListDropdownOptions = this.getUniqueData(this.customWorkListDropdownOptions);
        this.isEdit = true;
      } else {
        this.customWorkListDropdownOptions = [];
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  // role list
  onRoleChange($event: any, selectOrUnselect = false) {
    if (selectOrUnselect) {
      this.rowData.role = $event;
    }
    this.tabDisplayNameDropdownOptions = [];
    this.customWorkListDropdownOptions = [];
    this.rowData.tabDisplayName = [];
    this.rowData.customWorklist = [];
    this.getTabDisplayName();
    this.isFormChanged = true;
  }

  onRoleDeslectAll() {
    this.rowData.role = [];
    this.tabDisplayNameDropdownOptions = [];
    this.customWorkListDropdownOptions = [];
    this.rowData.tabDisplayName = [];
    this.rowData.customWorklist = [];
    this.getTabDisplayName();
    this.isFormChanged = true;
  }

  // tab list
  onTabChange($event: any, selectOrUnselect = false) {
    if (selectOrUnselect) {
      this.rowData.tabDisplayName = $event;
    }
    this.customWorkListDropdownOptions = [];
    this.rowData.customWorklist = [];
    this.getCustomWorkList();
    this.isFormChanged = true;
  }

  onTabDeslectAll() {
    this.rowData.tabDisplayName = [];
    this.rowData.customWorklist = [];
    this.customWorkListDropdownOptions = [];
    this.getCustomWorkList();
    this.isFormChanged = true;
  }

  // customwork list
  onWorklistChange($event) {
    this.rowData.customWorklist = $event;
    this.isFormChanged = true;
  }

  onWorklistDeslectAll() {
    this.rowData.customWorklist = [];
    this.isFormChanged = true;
  }

  // return to workgroups
  returnWorkgroup() {
    if (this.isFormChanged) {
      this.matdialog.openLabadminConfimationdialog('', 'All your changes will be lost. Do you want to proceed?', 'OK', 'Cancel')
        .afterClosed().subscribe(result => {
          if (result) {
            this.isEdit = false;
            this.workGrouporgDept()

          }
          else return;
        })
    }
    else {
      this.isEdit = false;
      this.workGrouporgDept()
    }

  }

  // reset
  resetDynamicForm() {
    this.rowData = structuredClone(this.backupRowData);
    this.rowData.isActive = ['Yes', true, 'Active'].includes(this.rowData.isActive) ? 1 : 0;
    this.isFormChanged=false;
    this.getRoles();
  }

  // save workgroup
  saveWorkgroup() {
    let object: any = { newData: this.rowData, oldData: this.rowData };
    let JsonData: any = {
      Id: this.rowData?.id,
      departmentname: object?.newData?.departmentName,
      departmentid: this.rowData?.departmentId,
      organizationid: this.rowData.organizationId,
      isactive: !!object.newData.isActive,
      role: object?.newData?.role.join(','),
      tabdisplayname: object?.newData?.tabDisplayName.join(','),
      worklist: object?.newData?.customWorklist.join(','),
      createdby: Number(sessionStorage?.getItem('Userid')) || -100,
      modifiedby: Number(sessionStorage?.getItem('Userid')) || -100
    }
    this.ngxService.stop();
    this.insertOrEditSequesterTemplate({ JsonData: JsonData });
  }

  savedData() {
    if (!this.rowData?.departmentName || !this.rowData?.role?.length || !this.rowData?.tabDisplayName?.length || !this.rowData?.customWorklist?.length) {
      this._snackbar.open("Mandatory field(s) are missing", "Close");
    } else {
      this.saveWorkgroup()
    }
  }

  getUniqueData(optionsArray: any) {
    return Array.from(new Set(optionsArray?.map((item: any) => item)));
  }
}
