<div class="modal-body set-location-wrap" [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
  <div class="row">
    <div class="col-sm-12 p-0">
      <div class="row" *ngIf="sourceApp==''">
        <div class="col-md-12">
          <div class="modal-header admin-section-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
              <strong>Manage Case Profiles</strong>
            </h3>

            <div *ngIf="commonService.showFlagIcon" class="ml-2">
              <span class="cpy-drg-Icon-globe" (click)="showMenuRelatedFlags()">
                  <img src="/assets/images/FlagIconOnHover.svg" matTooltip="Flags" class="globe_white" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="sourceApp!=''" class="mt-3">
        <strong class="caseprofile-main-header">Manage Case Profiles</strong>
      </div> -->
      
      <ng-container *ngIf="caseProfilesFitered$|async as profiles">
        <div class="modal-body">
            <div *ngIf="!sequenceEditMode" class="d-flex align-items-center mt-2 mb-2 mx-2 filters">
            <span class="not-selectable" tabindex="0" (keyup.enter)="!profiles.isNoDataPresent?filterStatus({status:'all'}):null"
             [ngClass]="[filterCondition.value.status.toLowerCase()=='all' ? 'filterselected' : '',profiles.isNoDataPresent?'disabled':'']"
            (click)="!profiles.isNoDataPresent?filterStatus({status:'all'}):null">All ({{profiles.totalCount}})</span>
            <span id="seperator"></span>
                <div class="filters d-flex not-selectable flex-wrap">
                    <span class="not-selectable" tabindex="0" (keyup.enter)="!profiles.isNoDataPresent?filterStatus({status:'active'}):null"
                        [ngClass]="[filterCondition.value.status.toLowerCase()=='active'? 'filterselected' : '',profiles.isNoDataPresent?'disabled':'']"
                        (click)="!profiles.isNoDataPresent?filterStatus({status:'active'}):null" >
                        Active ({{profiles.activeCount}})
                    </span>
                    <span class="not-selectable" tabindex="0" (keyup.enter)="!profiles.isNoDataPresent?filterStatus({status:'inactive'}):null"
                        [ngClass]="[filterCondition.value.status.toLowerCase()=='inactive'? 'filterselected' : '',profiles.isNoDataPresent?'disabled':'']"
                        (click)="!profiles.isNoDataPresent?filterStatus({status:'inactive'}):null">
                        Inactive ({{profiles.totalCount-profiles.activeCount}})
                    </span>

              </div>
              <app-search-box placeHolder="Search" class="ml-auto search-box-width" (enteredValue)="filterStatus({searchText:$event})" [searchText]="filterCondition.value.searchText" [isDisabled]="profiles.isNoDataPresent"></app-search-box>
              <app-button [ngClass]="{'disabledButton': profiles.activeCount <= 1}" matTooltip="Sort Case Profiles" [matTooltipDisabled]="hideEditBtn" [isDisabled]="hideEditBtn||isFilterApplied()"  image="icons/Reorder_Blue.svg" (click)="enableSequenceEditMode()" class="ml-2"></app-button>
              <app-button buttonclass="primary" class="ml-2 btn-width" [isDisabled]="hideCreateBtn"
                (click)="createCaseProfile()">Create</app-button>

            </div>
            <div *ngIf="sequenceEditMode" class="mt-3 mb-2 d-flex">
              <app-button buttonclass="secondary" class = "ml-auto edit-page-button" (click)="returnToList()">Return</app-button>
              <app-button buttonclass="primary" class = "edit-page-button ml-2" [isDisabled]="!isSortDataChanged" (click)="saveSequence()" >Save</app-button>
            </div>
            <div *ngIf="profiles.isNoDataPresent" class="col-sm-12 text-center mt-4">
              <span class="w-50 text-center mt-4" [ngClass]="sourceApp!='VitalDx'?'nodata-design':''">
                No Result
              </span>
            </div>
            <div *ngIf="!profiles.isNoDataPresent">
              <div class="case-profiles-header">
                <div class="case-profiles-table-header m-1" [class]="sequenceEditMode ? 'case-profiles-sort-table-header' : ''">
                <div>
                  <div class=" "*ngIf="sequenceEditMode"></div>
                  <!-- <div class=" " [class.margin-left-5]="sequenceEditMode">#</div> -->
                  <div class=" margin-left-2" [class.margin-left-5]="sequenceEditMode">Display Name</div>
                  <div class=" " [class.margin-left-5]="sequenceEditMode">Order Code</div>
                  <div class="margin-left-2" [class.margin-left-5]="sequenceEditMode">Case Type</div>
                  <div class=" ">Extraction Procedure</div>
                  <div class=" ">Services</div>
                </div>
                <div>
                  <div class=" ">Jar(s)</div>
                  <div class=" ">ICD Code</div>
                  <div class=" ml-2" [class.margin-left-5]="sequenceEditMode">Bill To</div>
                  <div class=" margin-left-5" *ngIf="!sequenceEditMode">Status</div>
                  <div class="justify-self-end" *ngIf="!sequenceEditMode">
                    <span>
                      <img src="/assets/icons/Icon_Excel-Download.svg"
                        class="cursor" alt="Export" matTooltip="Export" [class.disabled]="hideExportBtn || profiles.data.length === 0" [matTooltipDisabled]="hideExportBtn || profiles.data.length === 0"
                        (click)="hideExportBtn || profiles.data.length === 0 ? null : exportexcel(profiles.data)">
                    </span>
                  </div>
                </div>
                </div>
              </div>
            </div>
              <div class="case-profiles-table-body case-profiles-max-height mt-2 mx-2" *ngIf="profiles.data.length" [class]="sequenceEditMode ? 'case-profiles-sort-table-body' : ''" cdkDropList [cdkDropListData]="profiles.data" (cdkDropListDropped)="drop($event)" #scrollableDiv>
                <div class="matelevation each-item mb-2  " [class.inactive-rule]="!i.IsActive" *ngFor="let i of profiles.data; let j = index" cdkDragBoundary=".case-profiles-table-body" cdkDragLockAxis="y" cdkDragPreviewContainer="parent" cdkDrag  [cdkDragDisabled]="!sequenceEditMode" >
                  <div>
                  <div class="drag-handle" cdkDragHandle *ngIf="sequenceEditMode" >
                    <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                  </div>
                    <!-- <div *ngIf="sequenceEditMode" class=" ellipsis">
                        <input class="input-width" #input type="text" (focusout)="onFocusOut(j+1, input, profiles.data)" (keyup.enter)="onPressEnter(input)" [value]="j+1"/>
                    </div>
                    <div *ngIf="!sequenceEditMode" class="  ellipsis " [matTooltip]="tooTipText" #sequence (mouseover)="toolTipshow(sequence)">
                      {{i.Sequence}}</div> -->
                    <div class="  ellipsis " [matTooltip]="tooTipText" #displayName  appTooltipEllipsis
                      (mouseover)="toolTipshow(displayName)">{{i.Display_name}}</div>
                    <div class=" margin-left-2 ellipsis  " [matTooltip]="tooTipText" appTooltipEllipsis #orderCode (mouseover)="toolTipshow(orderCode)">
                      {{i.Order_code ? i.Order_code:'-' }}</div>
                    <div class="  ellipsis " [matTooltip]="tooTipText " #caseType  appTooltipEllipsis (mouseover)="toolTipshow(caseType)">
                      {{i.Case_type}} </div>
                    <div class=" margin-left-2 ellipsis " [matTooltip]="tooTipText" #extractionProcedure appTooltipEllipsis
                      (mouseover)="toolTipshow(extractionProcedure)">{{i.Extraction_procedure? i.Extraction_procedure
                      :'-'}}</div>
                  
                    <div class=" margin-left-25 ellipsis  " [matTooltip]="i.Services" #services
                    ><img class="" width="20px" height="20px"
                        src="../../../../assets/images/{{i.Services}}.svg" /> </div>
                      </div>
                      <div>
                        <div class=" margin-left-2 ellipsis margin-left-16 " [matTooltip]="tooTipText" #jars (mouseover)="toolTipshow(jars)">
                      {{i.No_of_jars}} </div>
                    <div class=" margin-left-5 ellipsis 
                " [matTooltip]="tooTipText" #icd appTooltipEllipsis (mouseover)="toolTipshow(icd)">
                      {{i.ICD10}} <span *ngIf="i.ICD10.trim('')==''">-</span> </div>
                      <div class=" margin-left-6 ellipsis  " appTooltipEllipsis [matTooltip]="tooTipText" #billto
                        (mouseover)="toolTipshow(billto)">
                        {{i.Bill_To ? i.Bill_To : '-'}}</div>
                    <div class="  ellipsis  cursor" *ngIf="!sequenceEditMode">
                      <app-toggle-switch type="secondary" (clicked)="[i.IsActive = $event,
                  (hideEditBtn)?null:UpdateStatusOfCaseprofile(i)]" [isChecked]="i.IsActive" [isDisable]="hideEditBtn">
                      </app-toggle-switch>
                    </div>
                    <div class="justify-self-center" *ngIf="!sequenceEditMode">
                      <span>
                        <img src="/assets/icons/Edit-fill.svg" [class.disabled]="hideEditBtn" class="cusor-pointer"
                          alt="Edit" width="14px" [matTooltipDisabled]="hideEditBtn" matTooltip="Edit"
                          (click)="hideEditBtn ? null : editCaseProfile(i)">
                      </span>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-sm-12 text-center mt-4 " *ngIf="profiles.data.length==0">
                <div class="col-sm-12 nodata-header-wrap">
                  <span class="col-sm-12 nodata-wrapper"> No Results </span>
                </div>
              </div>
        </div>
      </ng-container>
      <div class="mx-4" *ngIf="showloader&&sourceApp!=''">
        <app-shimmerui></app-shimmerui>
      </div>
    </div>
  </div>
</div>
