<input type="text" *ngIf="hideSearchbar==true" placeholder="Search Casetype..." class="searchinput"
  [(ngModel)]="filOrText" [ngModelOptions]="{standalone: true}" type="text" (keyup)="filterpatient()">
<div *ngIf="templateData && templateData.submenuData" class="search-panel-container panel-container"
  [style.height.px]="srcheight">
  <div class="card vt-card my-1 pointer case-type-wrap"
    *ngFor="let caseType of templateData.submenuData|filter:custfilter"
    (click)="AddTab(caseType.accountid,caseType[templateData.labels[0]], caseType)">

    <div class="row case-type-border">
      <div class="col-sm-12 white case-wraper">
        {{caseType.Case_Type}}
      </div>
      <div class="row m-auto col-sm-12 pl-0 template-wraps">
        <ng-container *ngFor="let label of templateData.labels">
          <span class="tableHeader  col-sm-4" *ngIf="label.split('_').join(' ') === 'Case Type' ">
            {{label.split('_').join(' ')}}
          </span>
          <span class="tableHeader  col-sm-2"
            *ngIf="label.split('_').join(' ') === 'Display Name'">
            {{label.split('_').join(' ')}}
          </span>
          <span class="tableHeader  col-sm-2"
            *ngIf="label.split('_').join(' ') === 'Case Type Category' ">
            {{label.split('_').join(' ')}}
          </span>
          <span class="tableHeader  col-sm-2"
          *ngIf="label.split('_').join(' ') == 'Compendium'">
          {{label.split('_').join(' ')}}
        </span>
          <span class="tableHeader  col-sm-2"
            *ngIf="label.split('_').join(' ') == 'Status'">
            {{label.split('_').join(' ')}}
          </span>
        
        </ng-container>
      </div>
      <div class="row m-auto col-sm-12 pl-0">
        <ng-container *ngFor="let label of templateData.labels">
          <span class="casecell pointer  col-sm-4  text-ellipsis" title="{{caseType[label]}}"
            *ngIf="label.split('_').join(' ') === 'Case Type' ">
            <span>{{caseType[label]}}</span></span>
          <span class="casecell pointer  col-sm-2" title="{{caseType[label]}}"
            *ngIf="label.split('_').join(' ') === 'Display Name'">
            <span [ngClass]="caseType[label] === 'InActive'? 'text-danger ':'text-black'">{{caseType[label]}}</span>
          </span>
          <span class="casecell pointer  col-sm-2" title="{{caseType[label]}}"
          *ngIf="label.split('_').join(' ') === 'Case Type Category' ">
          <span [ngClass]="caseType[label] === 'InActive'? 'text-danger ':'text-black'">{{caseType[label]}}</span>
        </span>
        <span class="casecell pointer  col-sm-2" title="{{caseType[label]}}"
        *ngIf="label.split('_').join(' ') ==  'Compendium'">
        <span [ngClass]="caseType[label] === 'InActive'? 'text-danger ':'text-black'">{{caseType[label]}}</span>
      </span>
          <span class="casecell pointer  col-sm-2" title="{{caseType[label]}}"
            *ngIf="label.split('_').join(' ') ==  'Status'">
            <span [ngClass]="caseType[label] === 'InActive'? 'text-danger ':'text-black'">{{caseType[label]}}</span>
          </span>
        
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="templateData.submenuData== null" class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found </div>
</div>