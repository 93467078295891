import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { take } from 'rxjs/operators';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-cassette-templates',
  templateUrl: './cassette-templates.component.html',
  styleUrls: ['./cassette-templates.component.scss']
})
export class CassetteTemplatesComponent  implements OnInit, OnDestroy{
  @Input()
  templateData: any;
  public SubMenuCardModel;
  public DeploymentKeys = [];
  gridPage: boolean = false;
  gridShowDiv: boolean = false;
  public hideGrid = false;
  GridData: wjcCore.CollectionView<any>;
  showGrid: boolean = false;
  gridheader: string[];
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean = false;
  gridHeaderList: any;
  listedGridHeader: any;
  listHeaderbackup: any;
  receivedObject: any;
  Result: any;
  saveOrEditCompleted: any;
  isdefaultconfirmaddedit: boolean = false;
  auditableColumns: any;
  dbname: any;
  // Upload and Copy
  uploadbtnClicked: boolean = false;
  copyClicked: boolean = false;
  configuredData: any;
  gridWidth: number;
  searchResult: any[];
  postUpload: any;
  copyOrganizations: any;
  searchCaselist: any[];
  casetypeListArray: any[];
  Casetypelist: unknown[];
  usertoggle: boolean;
  sheetHeader: any[];
  excelDownloadArray: any[];
  excelDataArray: any;
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  tempExcelArr: any = [];
  gridArray: any;
  griddata: any[];
  toolTipText: any;
  optionToolTipText: any;
// Copy As inactive
  checkStatus: boolean = false;
  enableActive: boolean = true;
// RBAC BUttons
  uploadBtn: boolean = false;
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  copyBtn: boolean = false;
  selectedCasetype  :string = "";
  userSessionDetails: any;
  sourceApp: string;
  destDeployment: string;
  gridHeader = [];
  ListedGridHeader: any = [];
  task: Task = {
    color: 'primary',
  };
  copyFromOrgFrm = this._fb.group({
    frmDeplyment: '',
    frmOrganization: '',
    frmorgid: '',
    // frmcasetype: '',
  })
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  orgId: any;
  activityEntity: {};


  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService, private templateViewServices:TemplateViewService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public templateHeaders: TemplateHeadersService,
    private changeDetectorRef: ChangeDetectorRef, private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService, public activityService: ActivityTrackerService,
    private store: Store<{ breadcrum : [] }>
    ) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
    this.dbname = sessionStorage.getItem('DeploymentKey').toUpperCase();
  }
  ngOnDestroy(): void {
    
      this.templateViewServices.globalMasterDataForQueries['TemplateName'] = ''; 
      this.templateViewServices.globalMasterDataForQueries['accountIds']=''
 
  }
 
 async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      _this.templateViewServices.globalMasterDataForQueries['TemplateName'] = 'cassettetemplates'; 
      _this.templateViewServices.globalMasterDataForQueries['accountIds'] =_this.labadminService?.associatedAccountIds
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
            menuObj = result[result.length - 1]
          })
          await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);

          _this.templateData = _this.labadminService.templateData;
          _this.dbname = _this.labadminService.deploymentKey;
          sessionStorage.setItem('org_id', _this.labadminService.organizationId);
          sessionStorage.setItem('deploymentKey', this.dbname);
           _this.getData()
          this.getAuditableDetails(this.templateData.menuURL)
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
          this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          _this.GetButtonAccess(_this.labadminService.subMenuPermissions)
        }
      })

    }
    else {
      this.gridHeader = []
      this.orgId = this.templateData.secondarykeys.OrganizationId;
      let labels = JSON.parse(JSON.stringify(this.templateData.labels))
      for (let i = 0; i < labels.length; i++) {
        let column: any;
        this.gridHeader.unshift(labels[i]);
      }
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
      this.getData(null, this.templateData.secondarykeys.casetype.toString())
      this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      this.getAuditableDetails(this.templateData.menuURL)
      this.templateViewServices.globalMasterDataForQueries['TemplateName'] = 'cassettetemplates'; 
      this.templateViewServices.globalMasterDataForQueries['accountIds'] =sessionStorage?.getItem('AccountID'); 
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    }
  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }   for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i]?.IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getData(context?, casetype?) {
    let queryVariable
    queryVariable = casetype ? {orgid: sessionStorage.getItem('org_id'), casetype: casetype}: { orgid: sessionStorage.getItem('org_id') };
    let query = this.SubMenuCardModel.GetQuery('getOrgCassetteTemplates');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.checkStatus = false;
          this.templateData.submenuData = (data.data.submenuData.length > 0 && casetype) ? data.data.submenuData.filter(x => x.CaseType == casetype ) : data.data.submenuData;
          if (context) {
            return
          }
          this.gridPage = true;

          this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.showGrid = false;
          if (!this.templateData?.submenuData?.length) this.GridData = new CollectionView(...[]);
        }
      }
    }, error => {

      console.error(error);
    });
  }

  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Cassette Templates') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }
    }
    this.gridPage = true;
    this.gridheader = [
      'Id',
      'OrganizationID',
      'CaseType',
      'IsActive',
      'IsDefault',
      'Description',
      'TemplateName',
      'Sequence',
      'CreatedBy',
      'CreatedDate',
      'ModifiedBy',
      'ModifiedDate',
      'CasetypeDisplayName'
    ];
    this.gridheader.reverse();
    let gridarray = []
    let tempcolumns = {}
    this.ngxService.start();
    if (data && data.length>0) {
        for (let i = 0; i < data.length; i++) {
          tempcolumns = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                if (key === 'Default' && value == "Yes") {
                  value = true;
                } else if (key === 'Default' && value == "No") {
                  value = false;
                }
                if (value == null) {
                  value = '';
                }
                tempcolumns[key] = value
              }
            }
          }
          gridarray.push(tempcolumns)
        }
        gridarray?.sort((a, b) => {
          const seqA = isNaN(Number(a.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(a.Sequence);
          const seqB = isNaN(Number(b.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(b.Sequence);
          return seqA - seqB;
        });
      
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
      }
      else {
        this.ngxService.stop();
        this.gridShowDiv = false;
        this.noDataFound = true;
      }
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.GridData = new CollectionView(gridarray)
      this.showGrid = true
      this.ngxService.stop();
    }
    else {
      this.ngxService.stop();
      this.gridShowDiv = false;
      this.noDataFound = true;
    }
  }

  onSelectionCasetype(event)
  {
    this.selectedCasetype = event?.CaseType?.object?.CaseType != "All Case Types" ? event?.CaseType?.object?.CaseType : "";
    this.getData(null, this.selectedCasetype);
  }

  getCaseList(org_id) {
    let orgid = org_id === null ? sessionStorage.getItem('org_id') : org_id;
    let queryVariable = { id: orgid?.toString() };
    let query = this.SubMenuCardModel.GetQuery('CaseTypeforAddendumNotes');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let dbName;
    let casetypelists = [];
    dbName = this.dbname;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        let casesnamelist = data.data.submenuData
        this.Casetypelist = [...new Map(casesnamelist.map((item) => [item['CaseType'], item['CaseType']])).values(),]
        casetypelists.push(...this.Casetypelist);
        this.casetypeListArray = casetypelists;
      }
    }, error => {
      console.error(error);
    });
  }

  recieveObjFromListing(object: any) {
    this.receivedObject = object.newData;
    if (this.receivedObject.Action === 'Create') {
      this.receivedObject['Context'] = 'Cassette Templates';
      this.receivedObject['Id'] = null;
      delete this.receivedObject.Placeholders;
      object.oldData = null;
    }
    else if (this.receivedObject.Action === 'Edit') {
      // this.receivedObject['DisplayOrder'] = this.receivedObject['Sequence']
      this.receivedObject['Context'] = 'Cassette Templates';
      this.receivedObject['Id'] = object.oldData.Id;
      delete this.receivedObject.Placeholders;

    }
    this.CassetteTemplateAction(this.receivedObject, object.oldData);
  }

  //#region
  //Function to call API for all CRUD operations on Cassette templates.
  CassetteTemplateAction(newObj, oldObj = null) {
    this.ngxService.start();
    this.VitalHttpServices.templateManagementCE(newObj, this.dbname)
      .subscribe((res: any) => {
        this.ngxService.stop();
        if (res) {
          this.Result = res;
          if (this.Result.content.message.toString().toLowerCase().includes('same name already exists!')) {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this.isdefaultconfirmaddedit = false;
            this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              autoFocus: false,
              width: '500px',
              panelClass: 'admin-custom-popup',
              data: { header: "", message: "", alert: "A template with the same name already exists. Try again with a different name!", continue: 'OK', cancel: "dontshow" }
            });
            return
          }
          else {
            this.isdefaultconfirmaddedit = false;
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(this.Result.content.message, 'Close');
          }
          if (res.status == 'Success') {
            newObj.IsActive = newObj.IsActive == 1 ? 'Active' : 'Inactive'
            newObj.IsDefault = newObj.IsDefault == 1 ? 'Yes' : 'No'
            if (newObj.Action === 'Create') {
              this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
              delete newObj.Sequence;
              this.commonService.auditDetails('Id', 'TemplateName', [res], [newObj], newObj.Action, this.templateData, this.auditableColumns);
            }
            else if (newObj.Action === 'Edit') {
              this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;

              this.commonService.auditDetails('ID', 'TemplateName', [oldObj], [newObj], newObj.Action, this.templateData, this.auditableColumns);
            }
            else {
              this.commonService.auditDetails('ID', 'TemplateName', [oldObj], [], newObj.Action, this.templateData, this.auditableColumns);
            }

            this.gridPage = true;
            this.hideGrid = false;
            this.getData(null,this.sourceApp === 'VitalDx' ? null : this.templateData.secondarykeys.casetype.toString());
          } else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          }
        }
        else {
          this.gridPage = true;
          this.hideGrid = false;
          this.getData(null,this.sourceApp === 'VitalDx' ? null : this.templateData.secondarykeys.casetype.toString());
        }
      }, error => {
        this.ngxService.stop();
        console.error(error);
      })
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  uploadCassetteTemplates(){
    this.gridPage = false;
    this.uploadbtnClicked = true;
  }

  //Upload Screen back select
  backClicked($event){
    this.getData(null, this.templateData.secondarykeys.casetype.toString());
    this.uploadbtnClicked = false;
  }

  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmDeplyment: depKey
      })
      this.gridWidth = 0
      this.searchResult = [];
      this.postUpload = false;
      this.getListOrg();
      this.copyFromOrgFrm.controls.frmOrganization.enable();
    }
  }

  getListOrg() {
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let queryVariable = {};
    let query = this.VitalHttpServices.GetQuery("getalllaboratories");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        this.ngxService.stop();
        if (data) {
          if (data.data.submenuData.length > 0) {
            this.copyOrganizations = data.data.submenuData
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }

  fetchOrgSeries(value: string) {
      value = value?.trim();
      this.searchResult = [];
      if(this.copyFromOrgFrm.value.frmOrganization == '') {
        this.searchResult=[];
        this.gridWidth=0;
      }
      if (!value) {
        this.searchResult = this.copyOrganizations.slice(0, 25);
        return;
      }
      const idValidate = /^[0-9]*$/;
      const lowerCaseValue = value.toLowerCase();
  
      if (this.copyFromOrgFrm.value.frmOrganization) {
        const filterCondition = idValidate.test(value)
          ? (series) => series.organizationid && series.organizationid.toString().toLowerCase().includes(lowerCaseValue)
          : (series) => series.organizationname && series.organizationname.toString().toLowerCase().includes(lowerCaseValue);
  
        this.searchResult = this.copyOrganizations.filter(filterCondition).slice(0, 25);
      }
  }

    //fetch based on the orgid selected the casetype will show
  fetchCaseSeries(value: string) {
      value = value?.toString().trim();
      this.searchCaselist = []
      value = value?.toString().trim();
      if (!value) {
        this.searchCaselist = this.casetypeListArray.filter(x => x).sort((a, b) => a?.toString().toLowerCase() < b?.toString().toLowerCase() ? -1 : a?.toString().toLowerCase() > b?.toString().toLowerCase() ? 1 : 0)
        return (this.searchCaselist = this.searchCaselist.splice(0, 25));
      }
      this.usertoggle = false;
      let idValidate = /^[0-9]*$/
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.searchCaselist.find(r => {
            if (r.toString().toLowerCase() === value.toLowerCase()) {
              return r
            } else {
              this.usertoggle = true
            }
          })
          this.searchCaselist = this.casetypeListArray.filter(function (series) {
            if (series != null) {
              return series.toString().toLowerCase().includes(value.toLowerCase());
            }
          });
          this.searchCaselist = this.searchCaselist.splice(0, 25);
        }
      }
      else {
        this.searchCaselist = this.casetypeListArray.filter(function (series) {
          if (series && series.userid != null) {
            return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
          }
        });
        this.searchCaselist = this.searchCaselist.splice(0, 25);
      }
  
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmorgid: data.organizationid,
        frmOrganization: data.organizationname,
      })
      this.getDataToCopy()
    }
  }

  getConfigurations() {//check
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { keyword: this.templateData.menuURL.toString(), context: 'bulkFunctionalities' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.configuredData = JSON.parse(Resdata?.data?.submenuData[0]?.Items_JSON);//TODO : call api when configuredData empty
      }
    }, error => {
      this.ngxService.stop()
      console.error(error);
    })
  }

  copyFromOrg() {
   
    this.getConfigurations();
    this.copyClicked = true;
    this.gridShowDiv = false;
    this.gridPage = false;
    this.uploadbtnClicked = false;
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.dbname,
      frmOrganization: '',
      frmorgid: '',
    })
  }

  disableGetDataBtn() {
    return this.copyFromOrgFrm.value.frmorgid == '' ? true : (this.copyOrganizations) != '' ? false : true
  }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyFromOrgFrm, controlName , this.copyFromOrgFrm.controls[controlName].value)  
  }

  getDataToCopy() {
    this.postUpload = false;
    let queryVariable;
    if (typeof (this.copyFromOrgFrm.value.frmorgid) == 'object') {
      let frmorgid: any
      frmorgid = this.copyFromOrgFrm.value.frmorgid
      this.copyFromOrgFrm.value.frmorgid = frmorgid[0].orgid
    }

    queryVariable = { orgid: this.copyFromOrgFrm.value.frmorgid.toString(), casetype: this.templateData.secondarykeys.casetype.toString(), isactive: 'true'};
    let query = this.SubMenuCardModel.GetQuery("getOrgCassetteTemplates");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.copyFromOrgFrm.value.frmDeplyment).subscribe(data => {
      if (!data.errors) {
        this.checkStatus = false;
        let copyGridData = data.data.submenuData;
        if (copyGridData.length == 0) {
          this.gridWidth = 0;
          this.ngxService.stop();
          this.noDataFound = true;
          return
        }
        // Define the columns to be removed
        const columnsToRemove = ['Id', 'Type','CreatedBy','CreatedDate','ModifiedBy','ModifiedDate', 'casetypedisplayname'];

        // Loop through the array and delete the specified keys from each object
        copyGridData.forEach(obj => {
          columnsToRemove.forEach(key => {
            delete obj[key];
            obj['Sequence'] = ''
          });
        });
        this.noDataFound = false;
        this.convertToJson(copyGridData)
        this.ngxService.stop();
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  
  convertToJson(sheetname) {
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    tempExcelArr = sheetname
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    tempExcelArr.find(r => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    });
    this.sheetHeader = ["TemplateName", "Description", "IsDefault", "IsActive", "Sequence", "CaseType"];
    this.sheetHeader.reverse();
    this.sheetHeader.push("ResultMessage");
    this.sheetHeader.push("Result");
    this.sheetHeader.reverse();
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempArray);
    this.excelDownloadArray = [...this.excelDataArray];
    this.excelDataArray.find(d => {
      Object.assign((d), {
        slno: Number(i), resultmessage: '', type: 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), casetype : this.templateData.secondarykeys.casetype.toString() ,accountid: Number(sessionStorage.getItem('Locationid'))
      });
      i++;
    });

    this.validateExcel(this.excelDataArray);
  }

  convertObjKeysToLower(inputArr) {
    return inputArr.map(obj => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key.replace(/\s/g, "").toLowerCase()] = value;
        return acc;
      }, {});
    });
  }

  validateExcel(excelArr) {
    this.ngxService.start();
    const dataArray = this.convertObjKeysToLower(excelArr).map(x => ({...x, sequence: null}));
    let validateObj = {
      "storedProcedureName": this.configuredData.sp[0]["validate"],
      "jsonData": dataArray,
      "isValidate": true,
      "columnsToRemove": ["accountid", "casetypedisplayname"]
    }
    this.VitalHttpServices.bulkUploadFunctionalities(validateObj, this.dbname).subscribe(
      (result) => {
        if (!result.errors) {
          let data;
          if (result.status.toLowerCase() == 'success') {
            this.postUpload = false;
            data = JSON.parse(result.content);
            if (data[0]["InvalidColumns"] && data[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = data[0].InvalidColumns;
            }
            this.excelDataArray.filter(e => {
              data.find(r => {
                if (r.slno === e.slno) {
                  e.resultmessage = r.resultmessage;
                  e.isvalid = r.isvalid;
                }
              })
            });
            this.excelGridData(this.excelDataArray);
            this.tempExcelArr = [];
          }
          else {
            this._snackbar.open("Something went wrong!", "Close");
          }
        } else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
        this.ngxService.stop();
      },
      (error) => {
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  excelGridData(data) {
    this.griddata = [];
    this.gridArray = [];
    let primary = {};
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            primary[key] = value;

          }
          this.gridArray.push(primary);
        }
      }
      this.griddata = this.gridArray;
      if (!this.postUpload) {
        this.sheetHeader.includes("Result") && this.sheetHeader.splice(this.sheetHeader.indexOf("Result"), 1);//remove result
      } else {
        !this.sheetHeader.includes("Result") && this.sheetHeader.splice(0, 0, "Result")// this.sheetHeader.push("Status");
      }
      this.gridWidth = (170 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }

  // selected data to copy
  CopyDataChecked(event, item) {
    if (event.target.checked) {
      this.tempExcelArr.push(item);
    }
    else {
      this.tempExcelArr = this.tempExcelArr.filter(arrItem => arrItem.slno !== item.slno);
    }
  }

  //flag for copy as inactive
  checkValue(event) {
    if (event.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  copyBulkInsert() {
    this.excelDataArray = [...this.tempExcelArr];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["resultmessage"]) {
          if (this.copyClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
        }
        else if (this.excelDataArray[i]["isvalid"] == 0 || this.excelDataArray[i]["isvalid"] == -1) {
          errorcount++;
        }
        this.excelDataArray[i]["isactive"] =this.checkStatus ? false : true;
        this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") || -100;

      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }

      let procName = this.configuredData.sp[0]["insert"]
      this.ngxService.start();
      let upoloadObj = {
        "storedProcedureName": procName,
        "jsonData": this.excelDataArray
      }

      this.VitalHttpServices.bulkUploadFunctionalities(upoloadObj, this.dbname).subscribe(res => {
        this.ngxService.stop();
        if (res.status.toLowerCase() == 'success') {
          let result = JSON.parse(res.content);
          this.postUpload = true;
          this.enableActive = false;
          this.excelDataArray.filter(e => {
            result.find(r => {
              if (r.slno === e.slno) {
                e.resultmessage = r.resultmessage;
                e.result = r.result;
              }
            });
          });
          this.excelGridData(this.excelDataArray);
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Copy', this.templateData, this.auditableColumns);
          this.tempExcelArr = [];


        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }

  ExportExcel() {
    let excel = []
    this.excelDataArray.forEach(item => {
      const { organizationid, slno, type, tablename, showpopup, showinformation, isvalid, alertMessage, alertHeader, createdby, sequence, accountid,Address,State,referencecode,...rest } = item;
      excel.push(rest); // Push the transformed object into the array
    });
    let filename = this.templateData.menuURL + '_' + this.commonService.orgid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.configuredData.tablename);
    XLSX.writeFile(wb, filename);
  }

  displayedColumns(item: any, header: any) {
    return item[header]
  }

  // tooltip for note message
  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }

  //remove grid in Copy screen
  removegrid() {
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.dbname.toUpperCase(),
      frmorgid: "",
      frmOrganization: '',
    })
    Object.keys(this.copyFromOrgFrm.controls).forEach(key => {
      this.copyFromOrgFrm.controls[key].setErrors(null)
    });
    if(this.copyClicked){
      if(!this.copyFromOrgFrm.controls['frmDeplyment'].value){
      this.copyFromOrgFrm.controls['frmOrganization'].disable();
      }
      else{
      this.copyFromOrgFrm.controls['frmOrganization'].enable();
      }
    }
    this.checkStatus = false;
    this.enableActive = true;
    this.uploadbtnClicked = false;
    this.copyClicked = true;
    this.excelDataArray = [];
    this.tempExcelArr = [];
    this.griddata = [];
    this.gridWidth = 0;
    this.noDataFound=false;
    this.postUpload = false;
  }

  //cancel/finish click in copy screen
  refreshGrid(context?) {
    if (this.copyClicked == true) {
      this.formGroupDirective.resetForm();
    }
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.copyClicked = false;
    this.enableActive = true;
    this.getData(null, this.templateData.secondarykeys.casetype.toString());
    if(this.copyClicked){
      this.copyFromOrgFrm.controls['frmOrganization'].enable();
    }
  }
}
