import { Component, Input } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { CommondetailsComponent } from '../commondetails/commondetails.component';
import { changeZipValue } from '../Utility Functions';

@Component({
  selector: 'app-infonpi',
  templateUrl: './infonpi.component.html',
  styleUrls: ['./infonpi.component.scss']
})
export class InfonpiComponent {

  userid = ''

  @Input() userDetials: any
  @Input() fromLocation:string='';
  @Input() isHideLocationGrid:boolean = false;
  @Input() dataStream : any;
  npiDetails = {}
  otherIdentifiers = []
  noDataFoundFlag: boolean = false;
  loading: boolean = false;

  constructor(private labadminService: LabadminService, private commonDetails:CommondetailsComponent) {
  }

  ngOnInit() {
    let action = 'SearchUser'
    if(this.fromLocation == 'location') action = 'SearchLocation'
    this.getNPIDetails(action);
    
  }

  getNPIDetails(action: string) {
    if (this.userDetials['NPI']?.toLowerCase() === 'unknown' || !this.userDetials['NPI']) {
      this.noDataFoundFlag = true;
      return
    }
    else {
      let objData = {
        City: null,
        FirstName: null,
        LastName: null,
        NPI: this.userDetials['NPI'],
        OrganizationName: null,
        State: null,
        Taxonomy: null,
        Zip: null
      }
      // this.userid = this.userDetials['UserID'];
      this.loading = true
      this.dataStream.subscribe({
        next: data => {          
        this.loading = false
        if (data.length)
          this.npiDetails = { 
            ...data[0], 
            
            mailingContactAddress: [data[0].address[0]?.address1, data[0].address[0]?.address2, data[0].address[0]?.city, data[0].address[0]?.state, changeZipValue(`${data[0].address[0]?.zip}${data[0].address[0]?.zip4}`) ].filter(j => j).join(', '),

            primaryPracticeContactAddress: data[0].address.length > 1 ? [data[0].address[1]?.address1, data[0].address[1]?.address2, data[0].address[1]?.city, data[0].address[1]?.state, changeZipValue(`${data[0].address[1]?.zip}${data[0].address[1]?.zip4}`) ].filter(j => j).join(', ') : '',

            secondaryPracticeContactAddress: data[0].practicelocation.length ? [data[0].practicelocation[0]?.address1, data[0].practicelocation[0]?.address2, data[0].practicelocation[0]?.city, data[0].practicelocation[0]?.state, changeZipValue(`${data[0].practicelocation[0]?.zip}${data[0].practicelocation[0]?.zip4}`) ].filter(j => j).join(', ') : '',
          };
        else
          this.noDataFoundFlag = true
      },
      error: (err) => {
        console.error(err)
        this.loading = false
      }})
    }
  }
}
