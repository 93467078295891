<!-- <div class="row">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>Custom Abbreviations</strong>
            </h3>
        </div>
    </div>
</div> -->

<div class="px-4" *ngIf="sourceApp =='VitalDx' && !(gridData || templateData)">
    <app-shimmerui [line]=8></app-shimmerui>
</div>

<div class="col-lg-12 p-0 m-md-1 m-lg-0" [ngStyle]="{'display': gridShow ? 'block' : 'none'}">
    <app-templates-listing [gridHeader]="listedGridHeader" [gridData]="gridData" [context]="'Custom Abbreviations'"
        [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="sourceApp =='VitalDx' ? true : uploadBtn" [hideExport]="exportBtn" [hideCopy]="copyBtn"
        [hideDelete]="deleteBtn" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
        [hideCopy]="sourceApp =='VitalDx' ? true : copyBtn" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted"
        [showHeaderModule]="true" (deleteTemplateClicked)="deleteTemplate($event)" [isUserLevel]="userFlag"
        [caseTypeUserRoles]="userRoles" [isAdmin3]="sourceApp =='VitalDx'? false : true"
        (emitFilters)="emitFilters($event)" [selectedRole]="selectedRole"
        (roleChangedEventTriggered)="roleChanged($event)" (copyTemplateEventTriggered)="copyFromOrg()"
        (uploadTemplateEventTriggered)="uploadCustomAbbrData()" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null" [searchBarWidth]="sourceApp =='VitalDx' ? 'col-sm-6' : 'col-sm-5'">
    </app-templates-listing>
</div>
<!-- <div *ngIf="noDataFound" class="col-lg-12 p-0 m-md-1 m-lg-0">
    <div class="align-center  mt-4">
        <div class="nodata-design font-size">No Data Found</div>
    </div>
</div> -->
<div *ngIf="sourceApp !='VitalDx' && noRole">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">User Level Custom Abbreviations</strong>
        </h3>
    </div>
    <div class="container create-btn col-sm-12 align-center mt-4">
        <div class="col-sm-10 nodata-wrap">
            <div class="nodata-design">Eligible role not found for user</div>
        </div>
    </div>
</div>
<div *ngIf="copyBtnClicked">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Custom Abbreviations</strong>
        </h3>
    </div>
    <form [formGroup]="copyFromOrgFrm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                <mat-select disableOptionCentering type="text" matInput (selectionChange)="onChangeDeployment($event)" #searchbar formControlName="frmDeplyment">
                    <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                <mat-label>Entity<span class="error-msg">*</span></mat-label>
                <em class="fa fa-chevron-down chevron-align"
                    (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"></em>
                <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput" #searchbar
                    (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization); trimFieldValue('frmOrganization')"
                    [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;" [ngModelOptions]="{standalone: true}">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align" title="{{ show.orgname }} ({{show.orgid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                            value="{{ show.orgname }}">
                            <span>{{ show.orgname }} ({{show.orgid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline" [disabled]= "true">
              <mat-label>Casetype<span class="error-msg">*</span></mat-label>
              <mat-select formControlName="frmCasetype" [disabled]="true">
                <mat-option [value]="copyFromOrgFrm.controls.frmCasetype.value" [disabled]="true">{{ copyFromOrgFrm.controls.frmCasetype.value }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-3  example-additional-selection" appearance="outline">
                <mat-label>User</mat-label>
                <em class="fa fa-chevron-down chevron-align" (click)="fetchUserSeries(searchUserInput)"></em>
                <input type="text" matInput formControlName="frmUser" [(ngModel)]="searchUserInput" #searchuserbar
                    (keyup)="fetchUserSeries(searchuserbar.value); trimUserFieldValue('frmUser')"
                    (click)="fetchUserSeries(searchUserInput)" [matAutocomplete]="userauto"
                    placeholder="Search here ..." style="width: 95%;" [ngModelOptions]="{standalone: true}">
                <mat-autocomplete #userauto="matAutocomplete">
                    <div *ngFor="let user of searchuserlist">
                        <mat-option class="mat-opt-align"
                            *ngIf="user?.username?.toLowerCase().includes(searchUserInput?.toLowerCase())"
                            (onSelectionChange)="selectedCopyDataUser($event, user)"
                            value="{{ user.username }} ({{user.DisplayRole}})"
                            title="{{ user.username }} ({{user.DisplayRole}})">
                            <span>{{ user.username }} ({{user.DisplayRole}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>

            <div class="col-sm-12 row align-center mt-2">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="userSelected = undefined; refreshGrid('list')">Back</button>
                <div class="dml-btn">
                    <button mat-raised-button class="admin-btn-success" (click)="getDataToCopy(copyFromOrgFrm)">Get
                        Data</button>
                </div>
            </div>
            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4">

                <div class="global-search-panel d-flex col-sm-4 p-0">
                    <div style="display: flex; margin-right: 3px;margin-left: 4px;">
                      <mat-icon class="search-icon">search</mat-icon>
                    </div>
                    <div class="standing-line">
                    </div>
                    <input type="text" class="search-input" [placeholder]="'Search ' + templateData.menuURL" [(ngModel)]="searchText"
                      (keyup)="filtertemplates()" [ngModelOptions]="{standalone: true}">
                    <ng-container *ngIf="searchText">
                      <div class="standing-line">
                      </div>
                      <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
                        <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
                      </div>
                    </ng-container>
                </div>
                <div class="parent-div mt-2 table-align">
                    <div class="header d-flex p-0 mb-1 table-content" *ngIf="griddata?.length">
                        <div class="header-wrap" style="width: auto !important;">
                            <span class="buttonClass" *ngIf="!postUpload">
                                <div class="dataTable"></div>
                            </span>
                            <span class="widthClass pl-1" *ngFor="let header of sheetHeader">
                                <div class="dataTable valueDiv">{{header}}</div>
                            </span>
                        </div>
                    </div>
                    <div class="container-fluid header-wrap p-0">
                        <div cdkDropList cdkDropListOrientation="vertical" class="tableScroll"
                            style="width: fit-content !important;">
                            <span *ngIf="griddata?.length > 0">
                                <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'"
                                    [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive')?'card-data mt-1 ml-0  mb-1 inactive-class':'card-data mt-1 ml-0  mb-1'"
                                    *ngFor="let item of griddata | searchfiltersTemplate: templatefilter">
                                    <input *ngIf="!postUpload" class="buttonClass" type="checkbox"
                                        (change)="custAbbrChecked($event,item)">
                                        <span (click)="RedirectToView(item)" class="text-muted text-ellipsis dataTable widthClass"

                                        *ngFor="let header of sheetHeader">
                                        <div *ngIf="header != 'ResultMessage' && header != 'Result'" class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                                          {{ displayedColumns(item,header.toLowerCase()) | stripHtml }}</div>
                                        <div *ngIf="header == 'ResultMessage' || header == 'Result'"
                                          [ngClass]="item['isvalid']==1 || item['result']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||['isvalid']==0 ? 'text-danger pl-1':null" class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                                          {{ displayedColumns(item,header.toLowerCase()) | stripHtml }}</div>
                                        </span>
                                </div>
                            </span>
                        </div>
                        <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                            <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
                        </div>
                        <div class="col-sm-12 nodata-header-wrap"
                            *ngIf="(griddata | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && griddata?.length == 0">
                            <span class="col-sm-12 nodata-wrapper"> No Results </span>
                        </div>
                    </div>
                </div>
                <!-- <section *ngIf="!postUpload" class="align-center row mt-3">
                    <input type="checkbox" [disabled]="tempExcelArr.length==0" (change)='checkValue($event)'  [(ngModel)]="checkStatus" class="border-style">
                    <label for="DKeys" class="font-style col-sm-1.5 m-0 p-0 ml-1">Copy as Inactive</label>
                </section> -->
                <div class="col-sm-12 pl-0 mt-4">

                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid('back')">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="uploadCustomAbbr()">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download Result</button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12 mt-4" *ngIf="gridWidth == 0 && noDataFound">
                <div class="container create-btn col-sm-12 align-center">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<app-common-bulk-functions *ngIf="uploadbtnClicked" [templateData]="templateData" [selectedUser]="selectedUser" [selectedData] = "selectedData"
    (uploadBack)=backClicked($event) [cardContext]="cardContext" [columnsToRemove]="['accountid']" (uploaded)="uploaded($event)"></app-common-bulk-functions>
