import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabadminService } from 'src/app/labadmin/labadmin.service';

@Component({
  selector: 'app-lot-preview',
  templateUrl: './lot-preview.component.html',
  styleUrls: ['./lot-preview.component.scss']
})
export class LotPreviewComponent implements OnInit{
  status : 'active' | 'inactive' = 'active';
  SD :string = ''
  deviationType : string = 'standard'
  sign : 'SD' | '%' = 'SD'
  setDifferentMinMaxDeviation : boolean = true
  searchValue : string = ''
  alertLessLots : boolean = false
  objectValues =[]
  lotData : any;
  isFullScreenEnabled : boolean = false
  estimatedLotExpiryDate = formatDate(new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),'MM-dd-yyyy','en-US')
  expiryDate : string = ''
  
  constructor(
    private _labadminservice: LabadminService,
    private _snackbar: MatSnackBar,
    private _dialogRef: MatDialogRef<LotPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) { 
    this.isFullScreenEnabled = (this._labadminservice?.redirectToMenu ?? '') != ''
  }
  showLoader :boolean = false;

  ngOnInit(): void {
    this.getAssociatedResults(this.config.data.lotId)
    this.alertLessLots = this.config.data.avialableTotalUnits.split('/')[0] <= Math.round(this.config.data.avialableTotalUnits.split('/')[1] * 0.1)
    if(!this.config.data.status) this.status = 'inactive'
    if(this.config.data.expiryDate && new Date(this.config.data.expiryDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) this.expiryDate =  'Expired'
  }

    getAssociatedResults(lotId: any) {
        this.showLoader=true
        this._labadminservice.GetLotDetails(this._labadminservice.deploymentKey,lotId,this.config.loggedinUserOrgID).subscribe({
            next:(response :any)=>
              {  
                this.deviationType = response.DerivationType;
                const barcodeDetails = response.BarCodeDetails.reduce((acc, { OrdercodeID, Barcode, PanelName }) => {

                    if (!acc[OrdercodeID]) {
                      acc[OrdercodeID] = { OrdercodeID, PanelName, Barcodes: [], results: [] };
                    }
                    acc[OrdercodeID].Barcodes.push(Barcode);
                    return acc;
                  }, {} as Record<number, { OrdercodeID: number; PanelName: string; Barcodes: string[]; results: any[] }>);
                  
                  const resultCodeDetailsMap = response.ResultCodeDetails?.reduce((acc, item) => {
                    if (!acc[item.OrderCodeID]) acc[item.OrderCodeID] = [];
                    acc[item.OrderCodeID].push(item);
                    return acc;
                  }, {} as Record<number, any[]>);
                  
                  
                  this.objectValues = Object.values(barcodeDetails).map((barcode:any) => ({
                    panelName: barcode.PanelName,
                    Barcodes: barcode.Barcodes,
                    results: (resultCodeDetailsMap[barcode.OrdercodeID] || []).map(test => ({
                      siteName: test.ResultName,
                      targetValue: test.targetValue,
                      standardDeviation: test.standardDeviation,
                      minAcceptableDeviation: test.minAcceptableDeviation,
                      maxAcceptableDeviation: test.maxAcceptableDeviation,
                      minAcceptableRange: test.minAcceptableRange,
                      maxAcceptableRange: test.maxAcceptableRange,
                    }))
                  }));          
             
              },
              error: (err) => {
                this.showLoader=false
                this._snackbar.open('Something Went Wrong','Close')
                console.error('Error:', err);
              },complete:()=>this.showLoader=false
          })
    }
    compareTwoDates = (date1:string,date2:string) => new Date(date1) >= new Date(date2);

  
}
