import { Component, Input } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { forkJoin } from 'rxjs';
import { error } from 'console';
import { ExtAttributeConstants } from '../share/utility/ex_attribute _constants';
import { CommondetailsComponent } from '../commondetails/commondetails.component';

@Component({
  selector: 'app-location-preferences',
  templateUrl: './location-preferences.component.html',
  styleUrls: ['./location-preferences.component.scss'],
})
export class LocationPreferencesComponent {
  @Input() locationInfo: any;
  @Input() isHideLocationGrid:boolean = false;
  preferenceinfo: any;
  userSessionDetails: any;
  isPcEnabled: boolean = false;
  additionalSettings: any = [];
  showAdditionalSettings:boolean = false;
  showLoader: boolean = true;
  isAllowLabAdminToViewReports: boolean;

  constructor(
    private labAdminservice: LabadminService,private commonDetails:CommondetailsComponent,
    private labAdminSessionService: LabadminSessionService
  ) {}

  ngOnInit() {
    this.labAdminSessionService.getLabAdminSession.subscribe(
      async (session) => {
        this.userSessionDetails = session['userDetails'];
      }
    );
   this.setAllValues()
    // this.getPCEnabledInfo();
    // this.GetCustomerValues()
    // this.GetVAExtAttrValueForAllowLabAdminToViewReports()
  }
  setAllValues()
  {
    this.showLoader=true
    const accountIdLst = this.userSessionDetails.userAccountIDs.map((account) =>
      account.accountId.toString()
    );
    const accountIds = accountIdLst.join(',');
    this.commonDetails.getLocationPreferenceC(accountIds).subscribe({next:(data)=>{
      const pcEnabled=data[0]
      const additionalSettingsLocationLevel= data[1];
      const additionalSettingsOrgLevel =data[2];
      const viewReport=data[3]

      let value:string = pcEnabled?.IsPcEnabled.toString();
      this.isPcEnabled = value.trim().toLowerCase() == "true" ? true: false;

    
      if (additionalSettingsLocationLevel.length) {
        this.additionalSettings = additionalSettingsLocationLevel;
        this.additionalSettings = this.additionalSettings.filter((c) => c?.Value?.length > 0)
      }
      else if (additionalSettingsOrgLevel.length) {
        this.additionalSettings = additionalSettingsOrgLevel;
        this.additionalSettings = this.additionalSettings.filter((c) => c?.Value?.length > 0)
      }
      this.isAllowLabAdminToViewReports = viewReport?.toString() == 'true' ? true : false;
      this.showLoader=false
    },
  error:(err)=>{
console.error(err)
this.showLoader=false

  }})
  }

  getPCEnabledInfo() {
    const accountIdLst = this.userSessionDetails.userAccountIDs.map((account) =>
      account.accountId.toString()
    );
    const accountIds = accountIdLst.join(',');
    this.locationInfo.AccountID;
    this.labAdminservice
      .GetIsPcEnabled(
        accountIds,
        this.locationInfo.AccountID,
        Number(this.labAdminservice.organizationId),
        this.labAdminservice.deploymentKey
      )
      .subscribe({
        next:(data) =>{
          let value:string = data?.IsPcEnabled.toString();
          this.isPcEnabled = value.trim().toLowerCase() == "true" ? true: false;
        },error:(err)=>{
          console.error(err)
        }
  });


  }

  GetCustomerValues() {
    forkJoin([
      this.labAdminservice.CustomOrgValues(Number(this.labAdminservice.organizationId), this.locationInfo.AccountID, 3, this.labAdminservice.deploymentKey),
      this.labAdminservice.CustomOrgValues(Number(this.labAdminservice.organizationId), this.locationInfo.OrganizationID, 2, this.labAdminservice.deploymentKey)
    ]).subscribe(([additionalSettingsLocationLevel, additionalSettingsOrgLevel]) => {
      this.showLoader = false
      if (additionalSettingsLocationLevel.length) {
        this.additionalSettings = additionalSettingsLocationLevel;
        this.additionalSettings = this.additionalSettings.filter((c) => c?.Value?.length > 0)
      }
      else if (additionalSettingsOrgLevel.length) {
        this.additionalSettings = additionalSettingsOrgLevel;
        this.additionalSettings = this.additionalSettings.filter((c) => c?.Value?.length > 0)
      }

    })
  }

}
