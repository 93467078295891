<!-- tabs and options -->
<div *ngIf="!addEditScreen" class="container mt-1 ml-0 p-0 manage-wheel"
    style="width: calc(100vw - 19vw);border-bottom: solid 1px #E0E9FF;">
    <div class="row m-0 p-0 d-flex align-items-center">
        <div class="col-5 m-0 mt-0 p-0">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item switchingtab" *ngFor="let tab of tabList; let i = index"
                    [ngClass]="{'active': activeTab === i}">
                    <a class="nav-link" (click)="changeTab(tab,i)">{{tab}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div [ngStyle]="{'display': (gridScreen && !addEditScreen) ? 'block' : 'none'}">
    <app-templates-listing2 *ngIf="gridArray?.length > 0 && activeTab == 0" [gridHeader]="listGridheaders" [gridData]="gridData"
        [groupBy]="groupBy" (createEmitter)="createFormat($event)" [caseTypeList]="casetypeList" [isSequenceType]="true"
        (emitFilters)="emitFilters($event)" [selectedCaseType]="selectedCaseType" (editEmitter)="openEditScreen($event)"
        (deleteEmitter)="deleteRecord($event)" (downloadEmitter)="downloadSequenceFormat($event)" [context]="'Sequence Scheme'"
        [hideEdit]="hideEditBtn" [hideDownload]="hideDownloadBtn" [isEdit]="true" [hideCreate]="hideCreateBtn"
        [hideDelete]="hideDeleteBtn" [hideUpload]="true" [hideCopy]="true" [isAllTab]="false" [hideActiveCount]="true"
        [isAdmin3]="false" [hideExport]="hideExportBtn" [templateData]="templateData" [dragDrop]="false"
        [hideDragDrop]="true" [uniqueKey]="'formatid'">
    </app-templates-listing2>

    <div *ngIf="activeTab == 1" class="col-sm-12 p-0 mt-2">
        <div *ngIf="activeTab == 1" class="row col-sm-12 p-0 m-0">
            <div class="col-sm-5 p-0 m-0" style="height: 24px;">
                <mat-form-field appearance="outline" class="seq-bg col-sm-6 w-100 bringToTop listFilterDropdownLabAdmin p-0">
                    <em class="fa fa-chevron-down chevron-align em-LabAdmin"></em>
                    <input matInput type="text" value="Accession" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-7 p-0 m-0 d-flex justify-content-end">
                <div class="global-search-panel d-flex p-0" style="width: 250px;">
                    <div style="display: flex; margin-right: 3px;margin-left: 4px;">
                        <mat-icon class="search-icon">search</mat-icon>
                    </div>
                    <div class="standing-line">
                    </div>
                    <input type="text" class="search-input" [placeholder]="'Search Sequence'" [(ngModel)]="searchText"
                        (keyup)="filterSequence()">
                    <ng-container *ngIf="searchText">
                        <div class="standing-line">
                        </div>
                        <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
                            <mat-icon class="search-icon" (click)="clearSequenceSearch()">close</mat-icon>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="!sequenceHideCreateBtn" class="create-upload-btn d-flex ml-2 justify-content-center">
                    <div class="create-only" (click)="create(0)">
                        Create
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 header d-flex py-1 mb-1 mt-2" *ngIf="configuredRunningNumbers?.length">
            <div class="col-sm-12 header-wrap">
                <span [ngStyle]="{width: header.colWidth}" *ngFor="let header of gridHeader; trackBy: trackByHeader">
                    <div class="dataTable" [ngStyle]="{'text-align': header?.textAlign ? header?.textAlign : 'left'}">
                        {{header.Name}}</div>
                </span>
                <div class="col-sm-1 pr-0 edit-wraps dataTable">
                    <span *ngIf="configuredRunningNumbers?.length > 0 && !sequenceHideExportBtn"
                        (click)="exportExcel()">
                        <img src="../../../../assets/icons/Excel_Download.svg" matTooltip="Export" class="export-icon">
                    </span>
                </div>
            </div>
        </div>
        <div class="container-fluid col-sm-12 header-wrap p-0">
            <cdk-virtual-scroll-viewport itemSize="15" class="example-viewport">
                <div
                    *cdkVirtualFor="let item of configuredRunningNumbers | searchfiltersTemplate: sequenceFilter; trackBy: item; let i = index">
                    <div class="col-sm-12 card-data mt-1 ml-0  pl-4 pr-2 mb-1">
                        <div *ngFor="let header of gridHeader; trackBy: trackByHeader"
                            [ngStyle]="{width: header.colWidth, 'text-align': header?.textAlign ? header?.textAlign : 'left'}"
                            class="text-muted text-ellipsis dataTable dataTableRowFont" [matTooltip]="toolTipText"
                            (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                            <ng-container
                                *ngIf="editEnabled && editingItem === i && header['key'] != 'sequencetype'; else viewTemplate">
                                <input [type]="header.dataType" #inputRef [maxlength]="header.maxLength"
                                    (change)="$event.preventDefault(); $event.stopPropagation();"
                                    [(ngModel)]="item[header['key']]">
                            </ng-container>
                            <ng-template #viewTemplate>
                                <span>{{ item[header['key']] | stripHtml }}</span>
                            </ng-template>
                        </div>
                        <div class="col-sm-1 btn-wraps d-flex" style="justify-content: end;">
                            <mat-icon *ngIf="(editingItem != i && !sequenceHideEditBtn)" matTooltip="Edit" class="actions ml-1" (click)="editSequence(item,i)"
                                                title="Edit">create</mat-icon>
                            <mat-icon *ngIf="(editingItem != i && !sequenceHideDeleteBtn)" matTooltip="Delete" class="actions ml-1" (click)="deleteSequence(item,'DELETE')"
                                title="Delete">delete</mat-icon>
                            <div *ngIf="(editingItem === i) && editEnabled" matTooltip="Save" id="btnOK"
                                class="btn btn-sm cursor">
                                <em class="fa fa-check" id="btnOK" aria-hidden="true" style="color: green;"
                                    (click)="saveSequenceChanges(item, 'Edit')"></em>
                            </div>
                            <div *ngIf="(editingItem === i) && editEnabled" matTooltip="Cancel" id="btnCancel"
                                class="btn btn-sm cursor">
                                <em class="fa fa-times" id="btnCancel" aria-hidden="true" style="color: red;"
                                    (click)="cancelSequenceEdit()"></em>
                            </div>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
            <div class="col-sm-12 nodata-header-wrap" *ngIf="configuredRunningNumbers?.length === 0">
                <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
            </div>
            <div class="col-sm-12 nodata-header-wrap"
                *ngIf="(configuredRunningNumbers | searchfiltersTemplate: sequenceFilter)?.length === 0 && searchText && configuredRunningNumbers?.length !== 0">
                <span class="col-sm-12 nodata-wrapper"> No Results </span>
            </div>
        </div>
    </div>
</div>

<div class="d-flex mt-3" *ngIf="addEditScreen && activeTab == 0">
    <div class="col-sm-3 vdm-heading">
        <span>{{actionTitle}}</span>
    </div>
    <div class="col-sm-9 button-wrap p-0 mb-2">
        <button class="col-sm-1 btn secondary mr-2" type="button" (click)="refreshGrid()">Return</button>
        <button class="col-sm-1 btn primary" (click)="saveCloseData('',seqFormGrp)">Save</button>
    </div>
</div>

<div *ngIf="addEditScreen && !gridScreen && activeTab == 0" class="col-sm-12 manage-seq">
    <form [formGroup]="seqFormGrp" class="pt-3" oninput autocomplete="off">
        <div class="col-sm-12 d-flex criteria-alignment">
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection disable-readonly"
                appearance="fill">
                <mat-label class="text-align mb-0 mt-7">Sequence Type</mat-label>
                <input matInput type="text" value="Accession" readonly>
            </mat-form-field>

            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection"
                [ngClass]="editEnabled ? 'disable-readonly' : 'criteria-style'" appearance="fill">
                <mat-label class="text-align mb-0 mt-7">
                    <strong>Case Type</strong>
                </mat-label>
                <input #inputAutoCompleteCasetype [readonly]="editEnabled" type="text" matInput maxlength="100"
                    [matAutocomplete]="casetypeAutoComplete" formControlName="frmCasetype"
                    matTooltip="{{seqFormGrp?.value.frmCasetype}}" #trigger1="matAutocompleteTrigger"
                    [errorStateMatcher]="matcher" style="width: 95%;" 
                    (focus)="clickDropdown('frmCasetype',true,trigger1);"/>
                <mat-error class="mt-1"
                    *ngIf="seqFormGrp.get('frmCasetype').hasError('required') || (seqFormGrp.get('frmCasetype').hasError('required') && seqFormGrp.get('frmCasetype').dirty)">
                    {{'This field is required'}}</mat-error>
                <div *ngIf="!editEnabled" matSuffix style="display:flex">
                    <button (click)="clearSearch($event, 'Casetype')" *ngIf="seqFormGrp?.value.frmCasetype"
                        aria-label="Clear" mat-icon-button type="button">
                        <mat-icon>clear</mat-icon>
                    </button>

                    <button (click)="openOrClosePanel($event,'frmCasetype', trigger1)" aria-label="Clear" mat-icon-button
                        type="button">
                        <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                    </button>
                </div>
                <mat-autocomplete #casetypeAutoComplete="matAutocomplete"
                    (closed)="arrowIconSubject.next('arrow_drop_down')"
                    (opened)="arrowIconSubject.next('arrow_drop_up')"
                    (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
                    <mat-option matTooltip="{{option.Case_Type}}" class="mat-opt-align"
                        *ngFor="let option of filteredOptions | async" disableOptionCentering [value]="option.Case_Type"
                        (click)="optionClick('Casetype',false,option)">
                        {{option.Case_Type}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection"
                [ngClass]="(editEnabled || (!isCasetypeSelected || (isCasetypeSelected && entityType == 'Order Codes'))) ? 'disable-readonly' : 'criteria-style'"
                appearance="fill">
                <mat-label class="text-align mb-0 mt-7">
                    <strong> Case Profile </strong>
                </mat-label>
                <input #inputAutoCompleteCaseprofile
                    [readonly]="(editEnabled || !isCasetypeSelected || (isCasetypeSelected && entityType == 'Order Codes'))"
                    type="text" matInput maxlength="100" [matAutocomplete]="caseprofileAutoComplete"
                    formControlName="frmCaseprofile" matTooltip="{{seqFormGrp?.value.frmCaseprofile}}"
                    #triggerProfile="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;" 
                    (focus)="clickDropdown('frmCaseprofile',false,triggerProfile);"/>
                <div *ngIf="(!editEnabled && (isCasetypeSelected && entityType != 'Order Codes'))"
                    matSuffix style="display:flex">
                    <button (click)="clearSearch($event, 'Caseprofile')" *ngIf="seqFormGrp?.value.frmCaseprofile"
                        aria-label="Clear" mat-icon-button type="button">
                        <mat-icon>clear</mat-icon>
                    </button>

                    <button (click)="openOrClosePanel($event,'frmCaseprofile', triggerProfile)" aria-label="Clear" mat-icon-button
                        type="button">
                        <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                    </button>
                </div>
                <mat-autocomplete #caseprofileAutoComplete="matAutocomplete"
                    (closed)="arrowIconSubject.next('arrow_drop_down')"
                    (opened)="arrowIconSubject.next('arrow_drop_up')"
                    (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
                    <mat-option matTooltip="{{option.Display_name}}" class="mat-opt-align"
                        *ngFor="let option of filteredCaseProfileOptions | async" disableOptionCentering
                        [value]="option.Display_name" (click)="optionClick('Caseprofile',false,option)">
                        {{option.Display_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection"
                [ngClass]="(editEnabled || !isCasetypeSelected || (isCasetypeSelected && entityType == 'Case Profile')) ? 'disable-readonly' : 'criteria-style'"
                appearance="fill">
                <mat-label class="text-align mb-0 mt-7">
                    <strong>
                        Panel </strong>
                </mat-label>
                <input #inputAutoCompletePanel
                    [readonly]="(editEnabled || !isCasetypeSelected || (isCasetypeSelected && entityType == 'Case Profile'))"
                    type="text" matInput maxlength="100" [matAutocomplete]="PanelAutoComplete"
                    formControlName="frmPanel" matTooltip="{{seqFormGrp?.value.frmPanel}}"
                    #triggerPanel="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;" 
                    (focus)="clickDropdown('frmPanel',false,triggerPanel);"/>
                <div *ngIf="(!editEnabled && (isCasetypeSelected && entityType != 'Case Profile'))"
                    matSuffix style="display:flex">
                    <button (click)="clearSearch($event, 'Panel')" *ngIf="seqFormGrp?.value.frmPanel" aria-label="Clear"
                        mat-icon-button type="button">
                        <mat-icon>clear</mat-icon>
                    </button>

                    <button (click)="openOrClosePanel($event,'frmPanel', triggerPanel)" aria-label="Clear" mat-icon-button
                        type="button">
                        <mat-icon>{{arrowIconSubject.getValue()}}</mat-icon>
                    </button>
                </div>
                <mat-autocomplete #PanelAutoComplete="matAutocomplete"
                    (closed)="arrowIconSubject.next('arrow_drop_down')"
                    (opened)="arrowIconSubject.next('arrow_drop_up')"
                    (optionSelected)="arrowIconSubject.next('arrow_drop_down')" [displayWith]="displayFn">
                    <mat-option matTooltip="{{option.Name}}" class="mat-opt-align"
                        *ngFor="let option of filteredOrderCodeOptions | async" disableOptionCentering
                        [value]="option.Name" (click)="optionClick('Panel',false,option)">
                        {{option.Name}}{{option.OrderCode ? '('+option.OrderCode+')' : ''}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-sm-12 d-flex criteria-alignment mt-3">
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection"
                [ngClass]="editEnabled ? 'disable-readonly' : 'criteria-style'" appearance="fill">
                <mat-label class="text-align mb-0 mt-7">
                    <strong>OF (Group) </strong>
                </mat-label>
                <input type="text" [readonly]="editEnabled" matInput maxlength="100" [matAutocomplete]="orderingFac"
                    formControlName="frmOrdFacility" matTooltip="{{seqFormGrp?.value.frmOrdFacility}}"
                    #trigger11="matAutocompleteTrigger" [errorStateMatcher]="matcher" style="width: 95%;" />
                <mat-error class="mt-1"
                    *ngIf="seqFormGrp.get('frmOrdFacility').hasError('required') || (seqFormGrp.get('frmOrdFacility').hasError('required') && seqFormGrp.get('frmOrdFacility').dirty)">
                    {{'This field is required'}}</mat-error>
                <div class="m-0 p-0" *ngIf="!editEnabled" matSuffix style="display:flex">
                    <button class="m-0 p-0" (click)="clearSearch($event, 'OF')" *ngIf="seqFormGrp?.value.frmOrdFacility"
                        aria-label="Clear" mat-icon-button type="button">
                        <mat-icon class="m-0 p-0">clear</mat-icon>
                    </button>
                    <button class="m-0 p-0" (click)="openOrClosePanel($event,'frmOrdFacility', trigger11)" aria-label="Clear"
                        mat-icon-button type="button">
                        <mat-icon class="m-0 p-0">{{arrowIconSubject1.getValue()}}</mat-icon>
                    </button>
                </div>
                <mat-autocomplete #orderingFac="matAutocomplete" (closed)="arrowIconSubject1.next('arrow_drop_down')"
                    (opened)="arrowIconSubject1.next('arrow_drop_up')"
                    (optionSelected)="arrowIconSubject1.next('arrow_drop_down')" [displayWith]="displayFn">
                    <div *ngFor="let a of filterOF(seqFormGrp.value.frmOrdFacility)">
                        <mat-option class="mat-opt-align" (onSelectionChange)="getOfID($event,a)"
                            matTooltip="{{a.organizationname}} ({{a.organizationid}})" disableOptionCentering
                            value="{{a.organizationname}} ({{a.organizationid}})">
                            {{ a.organizationname }} ({{ a.organizationid }})
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection" appearance="fill">
                <mat-label class="text-align mb-0 mt-7"><strong> Service<span
                            *ngIf="seqFormGrp.controls.frmBillto.value.length > 0"></span></strong></mat-label>
                <mat-select (openedChange)="openedChange($event); searchS.focus()" placeholder="Select"
                    disableOptionCentering #matSelectOptionServiceType formControlName="frmSerType"
                    [sortComparator]="sortFunc" multiple (selectionChange)="serviceChanged()">
                    <mat-select-trigger>
                        {{matSelectOptionServiceType._value ? matSelectOptionServiceType._value[0] : ''}}
                        <span *ngIf="matSelectOptionServiceType._value?.length > 1" class="additional-selection">
                            (+{{matSelectOptionServiceType._value.length - 1}}
                            {{matSelectOptionServiceType._value?.length === 2 ? 'other' : 'others'}})
                        </span>
                    </mat-select-trigger>

                    <div class="select-container">
                        <mat-form-field class="dd w-100 m-0 p-0">
                            <input #searchS [formControl]="searchServices" (keydown)="$event.stopPropagation()"
                                autocomplete="off" placeholder="Search" aria-label="Search" matInput
                                [ngModelOptions]="{standalone: true}" />
                            <button [disableRipple]="true" *ngIf="searchS.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearSearch($event, 'Service')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-optgroup *ngIf="(filteredServiceOptions | async)?.length == 0">
                            <div>No result found!</div>
                        </mat-optgroup>
                        <div class="select-all">
                            <mat-checkbox *ngIf="(filteredServiceOptions | async)?.length != 0" [color]="task.color"
                                class="select-all" [(ngModel)]="allSelectedService"
                                [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Service')">Select
                                All</mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick('Service')"
                            *ngFor="let option of filteredServiceOptions | async" class="mat-opt-align w-100"
                            [value]="option" matTooltip="{{option}}">
                            {{option}}
                        </mat-option>
                    </div>
                </mat-select>
                <mat-error class="mt-1"
                    *ngIf="seqFormGrp.get('frmSerType').hasError('required') || (seqFormGrp.get('frmSerType').hasError('required') && seqFormGrp.get('frmSerType').dirty)">
                    {{'This field is required'}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection" appearance="fill">
                <mat-label class="text-align mb-0 mt-7"><strong> Bill To <span
                            *ngIf="seqFormGrp.controls.frmSerType.value.length > 0"></span></strong></mat-label>
                <mat-select (openedChange)="openedChange($event); searchC.focus()" placeholder="Select"
                    disableOptionCentering #matSelectOptionBillTo formControlName="frmBillto"
                    [sortComparator]="sortFunc" multiple (selectionChange)="billToChange()">
                    <mat-select-trigger>
                        {{matSelectOptionBillTo._value ? matSelectOptionBillTo._value[0] : ''}}
                        <span *ngIf="matSelectOptionBillTo._value?.length > 1" class="additional-selection">
                            (+{{matSelectOptionBillTo._value.length - 1}} {{matSelectOptionBillTo._value?.length === 2 ?
                            'other' : 'others'}})
                        </span>
                    </mat-select-trigger>

                    <div class="select-container">
                        <mat-form-field class="dd w-100 m-0 p-0">
                            <input #searchC [formControl]="searchBillTo" (keydown)="$event.stopPropagation()"
                                autocomplete="off" placeholder="Search" aria-label="Search" matInput
                                [ngModelOptions]="{standalone: true}" />
                            <button [disableRipple]="true" *ngIf="searchC.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearSearch($event, 'BillTo')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-optgroup *ngIf="(filteredBillToOptions | async)?.length == 0">
                            <div>No result found!</div>
                        </mat-optgroup>
                        <div class="select-all">
                            <mat-checkbox *ngIf="(filteredBillToOptions | async)?.length != 0" [color]="task.color"
                                class="select-all" [(ngModel)]="allSelectedBillTo" [ngModelOptions]="{standalone: true}"
                                (change)="toggleAllSelection('BillTo')">Select
                                All</mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick('BillTo')" *ngFor="let option of filteredBillToOptions | async"
                            class="mat-opt-align w-100" [value]="option" matTooltip="{{option}}">
                            {{option}}
                        </mat-option>
                    </div>
                </mat-select>
                <mat-error class="mt-1"
                    *ngIf="seqFormGrp.get('frmBillto').hasError('required') || (seqFormGrp.get('frmBillto').hasError('required') && seqFormGrp.get('frmBillto').dirty)">
                    {{'This field is required'}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection" appearance="fill">
                <mat-label class="text-align mb-0 mt-7">Enable Research</mat-label>
                <mat-select disableOptionCentering formControlName="frmIsResearch">
                    <!-- <mat-option title="--Select--" [value]="">{{"--Select--"}}</mat-option> -->
                    <mat-option [value]="true">{{"Yes"}}</mat-option>
                    <mat-option [value]="false">{{"No"}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-tab-group class="scheme-tab mt-4" [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)">
            <mat-tab class="scheme-tab"
                [style.height]="(defaultRangeArray?.length > 0 || defaultChipList?.length > 0 || seqFormGrp.controls.frmDefaultFormat?.value.frmOutput)? '322px' : '300px'"
                [label]="tabs[0]" id="tab" formGroupName="frmDefaultFormat">
                <div [style.height]="(defaultRangeArray?.length > 0 || defaultChipList?.length > 0 || seqFormGrp.controls.frmDefaultFormat?.value.frmOutput)? '322px' : '300px'"
                    class="dynamic-height">
                    <div class="col-sm-12">
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Codes</mat-label>
                            <mat-select disableOptionCentering #matSelectOptionCodes
                                [sortComparator]="sortFunc" formControlName="frmCodes"
                                [ngModelOptions]="{standalone: true}" multiple>
                                <mat-select-trigger>
                                    {{matSelectOptionCodes._value ? matSelectOptionCodes._value[0] : ''}}
                                    <span *ngIf="matSelectOptionCodes._value?.length > 1" class="additional-selection">
                                        (+{{matSelectOptionCodes._value.length - 1}}
                                        {{matSelectOptionCodes._value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <div class="select-container">
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCodes"
                                            class="select-all" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection('Codes',false)">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('Codes',false)" class="mat-opt-align"
                                        *ngFor="let option of codeList" [value]="option.item"
                                        matTooltip="{{option.item}}">
                                        {{option.item}}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align m-0 p-0 mt-7">Custom Code</mat-label>
                            <input class="bordernone" formControlName="frmCustomCode" [maxlength]="25" matInput
                                (blur)="onInputBlur('frmDefaultFormat','frmCustomCode')"
                                (keyup.enter)="$event.preventDefault(); $event.stopPropagation();addChip('','frmDefaultFormat','frmCustomCode')">
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Date Format</mat-label>
                            <mat-select disableOptionCentering #matSelectOptionDate formControlName="frmDate"
                                [ngModelOptions]="{standalone: true}" [sortComparator]="sortFunc" multiple>
                                <div class="select-container">
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedDates"
                                            class="select-all" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection('Date',false)">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('Date',false)" class="mat-opt-align"
                                        *ngFor="let option of dateList" [value]="option.item"
                                        matTooltip="{{option.item}}">
                                        {{option.item}}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 brd example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Divider</mat-label>
                            <input class="bordernone" style="background: #ebebeb; color:black;font-weight: bold;"
                                formControlName="frmDivision" matInput [value]="'-'"
                                (click)="addChip('','frmDefaultFormat','frmDivision')" [readonly]="true">

                            <button mat-icon-button matSuffix type="button"
                                (click)="addChip('','frmDefaultFormat','frmDivision')" aria-label="Add">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Range</mat-label>
                            <mat-select disableOptionCentering class="ml-2" formControlName="frmRange">
                                <mat-option matTooltip="--Select--" [value]="">{{"--Select--"}}</mat-option>
                                <mat-option matTooltip="{{option.item}}" *ngFor="let option of rangeList"
                                    (click)="optionClick('Range')" [value]="option.item" class="mat-opt-align">
                                    {{option.item}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="mt-2"
                            *ngIf="seqFormGrp.controls.frmDefaultFormat.get('frmRange').status == 'INVALID' || seqFormGrp.controls.frmDefaultFormat.get('frmRange').hasError('required') || (seqFormGrp.controls.frmDefaultFormat.get('frmRange').hasError('required') && seqFormGrp.controls.frmDefaultFormat.get('frmRange').dirty)">
                            {{'This field is required'}}</mat-error>
                        </mat-form-field>
                        <mat-form-field
                            [ngClass]="(disableCustom || (!seqFormGrp.controls.frmDefaultFormat?.value.frmRange)) ? 'disable-readonly' : 'brd'"
                            class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Begins at</mat-label>
                            <input class="bordernone" formControlName="frmBeginsAt"
                                matTooltip="{{seqFormGrp.controls.frmDefaultFormat?.value.frmBeginsAt}}"
                                [maxlength]="getRangeLength('frmDefaultFormat')" matInput
                                (keypress)="AllowOnlyNumber($event)"
                                [readonly]="disableCustom || (!seqFormGrp.controls.frmDefaultFormat?.value.frmRange)">
                        </mat-form-field>
                    </div>
                    <div class="row p-0 m-0 pt-3 pr-3 pl-3"
                        *ngIf="defaultChipList?.length > 0">
                        <div class="col-sm-10 m-0 p-0 chip-border">
                            <mat-chip-list class="example-chip col-sm-12 scrollable-chip-list mt-1"
                                style="display: flex; flex-wrap: wrap;">
                                <mat-chip class="drag-handle dropdown-chip-color example-box"
                                            *ngFor="let option of defaultChipList; let i =index;"
                                            [removable]="removable"
                                            (removed)="removeChips(option, 'frmDefaultFormat', i)"
                                            [removable]="true"
                                            draggable="true"
                                            (dragstart)="onDragStart(i)"
                                            (dragover)="onDragOver($event, 'frmDefaultFormat', i)"
                                            (dragend)="onDragEnd($event)"
                                            (drop)="onDrop(i,'frmDefaultFormat')">
                                    <b>{{ option.name ? ":: " : ""}}</b>{{ option.name ? option.name : '' }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <div *ngIf="defaultRangeArray?.length > 0" class="chip-seperator">
                                </div>
                                <mat-chip class="dropdown-chip-color example-box range-box" *ngIf="defaultRangeArray?.length > 0"
                                    [removable]="removable"
                                    (removed)="removeChips(defaultRangeArray[0], 'frmDefaultFormat',i)"
                                    [removable]="true">
                                    {{ defaultRangeArray[0].name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <div class="col-sm-2 m-0 p-0 pl-2">
                            <mat-form-field [ngClass]="outputClass()"
                                class="col-sm-12 m-0 p-0 output-style example-additional-selection" appearance="fill">
                                <mat-label>Result</mat-label>
                                <input matInput type="text" formControlName="frmOutput"
                                    matTooltip="{{seqFormGrp.controls.frmDefaultFormat?.value.frmOutput}}"
                                    [errorStateMatcher]="matcher" readonly>
                                <mat-error class="mt-2"
                                    *ngIf="seqFormGrp.controls.frmDefaultFormat.get('frmOutput').status == 'INVALID' || seqFormGrp.controls.frmDefaultFormat.get('frmOutput').hasError('maxlength')">
                                    {{'Max '}} {{this.resultMaxLen}} {{' characters allowed!'}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container>
                        <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
                    </ng-container>
                </div>
            </mat-tab>
            <mat-tab class="scheme-tab"
                [style.height]="(customRangeArray?.length > 0 || customChipList?.length > 0 || seqFormGrp.controls.frmCustomFormat?.value.frmOutput)? '322px' : '300px'"
                [disabled]="disableCustom" [label]="tabs[1]" id="tab" formGroupName="frmCustomFormat">
                <div [style.height]="(customRangeArray?.length > 0 || customChipList?.length > 0 || seqFormGrp.controls.frmCustomFormat?.value.frmOutput)? '322px' : '300px'"
                    class="dynamic-height">
                    <div class="col-sm-12">
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Codes</mat-label>
                            <mat-select disableOptionCentering #matSelectOptionCustomCodes formControlName="frmCodes"
                                [ngModelOptions]="{standalone: true}" [sortComparator]="sortFunc" multiple>
                                <mat-select-trigger>
                                    {{matSelectOptionCustomCodes._value ? matSelectOptionCustomCodes._value[0] : ''}}
                                    <span *ngIf="matSelectOptionCustomCodes._value?.length > 1"
                                        class="additional-selection">
                                        (+{{matSelectOptionCustomCodes._value.length - 1}}
                                        {{matSelectOptionCustomCodes._value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                </mat-select-trigger>
                                <div class="select-container">
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCustomCodes"
                                            class="select-all" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection('Codes',true)">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('Codes',true)" class="mat-opt-align"
                                        *ngFor="let option of codeList" [value]="option.item"
                                        matTooltip="{{option.item}}">
                                        {{option.item}}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align m-0 p-0 mt-7">Custom Code</mat-label>
                            <input class="bordernone" formControlName="frmCustomCode" [maxlength]="25" matInput
                                (blur)="onInputBlur('frmCustomFormat','frmCustomCode')"
                                (keyup.enter)="$event.preventDefault(); $event.stopPropagation();addChip('','frmCustomFormat','frmCustomCode')"
                                matTooltip="{{seqFormGrp.controls.frmCustomFormat?.value.frmCustomCode}}" type="text">
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Date Format</mat-label>
                            <mat-select disableOptionCentering #matSelectOptionCustomDate formControlName="frmDate"
                                [ngModelOptions]="{standalone: true}" [sortComparator]="sortFunc" multiple>
                                <div class="select-container">
                                    <div class="select-all">
                                        <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedCustomDates"
                                            class="select-all" [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection('Date',true)">
                                            Select All</mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick('Date',true)" class="mat-opt-align"
                                        *ngFor="let option of dateList" [value]="option.item"
                                        matTooltip="{{option.item}}">
                                        {{option.item}}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 brd example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Divider</mat-label>
                            <input class="bordernone" style="background: #ebebeb; color:black;font-weight: bold;"
                                formControlName="frmDivision" matInput value="-"
                                (click)="addChip('','frmCustomFormat','frmDivision')" [readonly]="true">

                            <button mat-icon-button matSuffix type="button"
                                (click)="addChip('','frmCustomFormat','frmDivision')" aria-label="Add">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field class="col-sm-2 m-0 p-0 example-additional-selection brd" appearance="fill">
                            <mat-label class="text-align mb-0 mt-7">Range</mat-label>
                            <mat-select disableOptionCentering class="ml-2" formControlName="frmRange">
                                <mat-option matTooltip="--Select--" [value]="">{{"--Select--"}}</mat-option>
                                <mat-option *ngFor="let option of rangeList" (click)="optionClick('Range',true)"
                                    matTooltip="{{option.item}}" [value]="option.item" class="mat-opt-align">
                                    {{option.item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- starts for custom chips -->
                    <div class="row p-0 m-0 pt-3 pr-3 pl-3"
                        *ngIf="customChipList?.length > 0">
                        <div class="col-sm-10 m-0 p-0 chip-border">
                            <mat-chip-list class="example-chip col-sm-12 scrollable-chip-list mt-1"
                                style="display: flex; flex-wrap: wrap;">
                                <mat-chip class="drag-handle dropdown-chip-color example-box"
                                    *ngFor="let option of customChipList; let i =index;" [removable]="removable"
                                    (removed)="removeChips(option, 'frmCustomFormat',i)" [removable]="true"
                                    draggable="true" (dragstart)="onDragStart(i)"
                                    (dragover)="onDragOver($event,'frmCustomFormat', i)"
                                    (dragend)="onDragEnd($event)"
                                    (drop)="onDrop(i,'frmCustomFormat')">
                                    <b>{{ option.name ? ":: " : ""}}</b>{{ option.name ? option.name : '' }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <div *ngIf="customRangeArray?.length > 0" class="chip-seperator">
                                </div>
                                <mat-chip class="dropdown-chip-color example-box range-box"
                                    *ngIf="customRangeArray?.length > 0" [removable]="removable"
                                    (removed)="removeChips(customRangeArray[0], 'frmCustomFormat',i)"
                                    [removable]="true">
                                    {{ customRangeArray[0].name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                        <div class="col-sm-2 m-0 p-0 pl-2">
                            <mat-form-field [ngClass]="outputClass()"
                                class="col-sm-12 m-0 p-0 output-style example-additional-selection" appearance="fill">
                                <mat-label>Result</mat-label>
                                <input matInput type="text" formControlName="frmOutput"
                                    matTooltip="{{seqFormGrp.controls.frmCustomFormat?.value.frmOutput}}"
                                    [errorStateMatcher]="matcher" readonly>
                                <mat-error class="mt-2"
                                    *ngIf="seqFormGrp.controls.frmCustomFormat.get('frmOutput').status == 'INVALID' || seqFormGrp.controls.frmDefaultFormat.get('frmOutput').hasError('maxlength')">
                                    {{'Max '}} {{this.resultMaxLen}} {{' characters allowed!'}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- ends for custom chips -->
                    <ng-container>
                        <ng-container *ngTemplateOutlet="sharedContent"></ng-container>
                    </ng-container>
                </div>
            </mat-tab>
        </mat-tab-group>
        <a href="javascript:void(0);" class="reset-link-section" (click)="clearAll()" [ngClass]="disableClearAll() ? 'disabled-reset-link' : 'reset-link'">Clear All</a>
        <ng-template #sharedContent>
            <div class="row mt-3 p-3">
                <div class="col-sm-12">
                    <span class="example-additional-selection"><b><u>Standard Templates</u></b></span>
                </div>
                <div class="col-sm-12 mt-2">
                    <div class="row Sample-height">
                        <div class="col-sm-4  mt-2" *ngFor="let data of standardFormat; let i = index">
                            <div *ngIf="i<3" class="card" [ngClass]="cardClass(i)" (click)="onClickCard(i,data)">
                                <div class="container">
                                    <p>{{data.Description}}</p>
                                    <h4><b>{{data.item}}</b></h4>
                                </div>
                            </div>
                            <div *ngIf="i>2" class="card" [ngClass]="cardClass(i)" (click)="onClickCard(i,data)">
                                <div class="container">
                                    <p>{{data.Description}}</p>
                                    <h4><b>{{data.item}}</b></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </form>
</div>

<div class="d-flex mt-3" *ngIf="addEditScreen && !gridScreen && activeTab == 1">
    <div class="col-sm-3 vdm-heading">
        <span>{{"Create Sequence"}}</span>
    </div>
    <div class="col-sm-9 button-wrap p-0 mb-2">
        <button class="col-sm-1 btn secondary mr-2" type="button" (click)="refreshSequence()">Return</button>
        <button class="col-sm-1 btn primary" (click)="saveSequenceTab()">Save</button>
    </div>
</div>

<div *ngIf="addEditScreen && !gridScreen && activeTab == 1" class="col-sm-12 manage-seq">
    <form [formGroup]="seqTabFormGrp" class="pt-3" oninput autocomplete="off">
        <div class="col-sm-12 d-flex criteria-alignment">
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection disable-readonly"
                appearance="fill">
                <mat-label class="text-align mb-0 mt-7">Sequence Type</mat-label>
                <input matInput type="text" value="Accession" readonly>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection"
                appearance="fill">
                <mat-label class="text-align mb-0 mt-7">Sequence Name</mat-label>
                <input matInput [errorStateMatcher]="matcher" formControlName="frmSequenceName" type="text">
                <mat-error class="mt-2"
                        *ngIf="seqTabFormGrp.get('frmSequenceName').hasError('dirtyError') || (seqTabFormGrp.get('frmSequenceName').hasError('required') && seqTabFormGrp.get('frmSequenceName').dirty)">
                        {{'This field is required'}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-sm-3 mr-2 m-0 p-0 criteria-style example-additional-selection"
                appearance="fill">
                <mat-label class="text-align mb-0 mt-7">Running Number</mat-label>
                <input matInput formControlName="frmRunningNumber" type="number">
            </mat-form-field>
        </div>
    </form>
</div>