import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { cloneDeep } from 'lodash';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { searchFilterPipetemplate } from 'src/app/core/utility/tripfilter.pipe';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-manage-payers',
  templateUrl: './manage-payers.component.html',
  styleUrls: ['./manage-payers.component.scss'],
  providers: [searchFilterPipetemplate]
})

export class ManagePayersComponent implements OnInit, OnChanges {
  @Input() templateData: any;
  subMenuCardModel: SubMenuCardModel;
  gridShow: boolean = true;
  PayersGridData: wjcCore.CollectionView<any>;
  listedGridHeader: any;
  gridHeaderList: any;
  gridheader: string[];
  create: boolean = false;
  lookuppage: boolean = false;
  savedata: any;
  deploymentkey: string = '';
  accountid: string = '';
  orgid: string = '';
  billingtypedropdown: any[];
  payerfamilydropdown: any[];
  copyOrganizations: any;
  searchResult: any[];
  cardContext: string = 'fromAccount'
  public DeploymentKeys = [];
  destDeployment: string;
  sheetHeader: any[];
  excelDownloadArray: any[];
  excelDataArray: any;
  gridWidth: number;
  gridArray: any;
  checkStatus: boolean = false;
  griddata: any[];
  postUpload: any;
  masterLevel: boolean = false;
  noDataFound: boolean = false;
  showInvalidColumns: boolean;
  invalidColumns: string = "";
  configuredData: any;
  tempExcelArr: any = [];
  lastActivePayerid:string='';
  uploadbtnClicked: any = false;
  altermessage: any;
  altermessageheader: string;
  selector: any;
  toolTipText: any;
  optionToolTipText: any;
  copyFromOrgFrm = this._fb.group({
    frmDeplyment: '',
    frmOrganization: '',
    frmorgid: '',
    frmAccountId: '',
    frmaccid: ''
  })

  migrateClicked:boolean=false;
  migrateHeaders:any[];
  migrateData:any[];
  migrateenable:boolean=false;
  activityEntity: any;
  res: any;
  //Rbac flags
  createBtn: boolean = false;
  copyBtn: boolean = false;
  uploadBtn: boolean = false;
  exportBtn: boolean = false;
  editBtn:boolean=false;
  migrateBtn: boolean=false;

  CreateForm: FormGroup;
  saveObj: any;
  Gridata: boolean;
  locationlevel: boolean=false;
  deploymentlevel: boolean=false;
  editObj: any;
  newAction: any;
  payerid: any;
  payerId: any;
  lastActivePayer: boolean=false;
  editclicked: boolean=false;
  isEditButton:boolean=false;
  activityEdit: any={status:0};

  copyClicked: boolean = false;
  organizationList: any;
  searchResultaccount: any[];
  selectedData: any[];
  copyGridData: any;
  auditableColumns: any;
  searchText: any;
  filterdData: any[];
  postMigrate: boolean=false;
  newflag:boolean=false;
  templatefilter: object = {};
  tempSubData: any[];
  ischeckedMigrate: boolean=false;
  constructor(
    private searchfiltersTemplate: searchFilterPipetemplate,
    private _fb: FormBuilder,
    private datashare: DataShareService,
    private commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    public templateHeaders: TemplateHeadersService,
    private _dailogService: DialogService,
    private dialog: MatDialog,
    private activityService: ActivityTrackerService
   ) {
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }

  ngOnInit(): void {
    
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.deploymentkey = sessionStorage.getItem('deploymentKey');
    this.accountid = sessionStorage.getItem('Locationid');
    this.orgid = sessionStorage.getItem('org_id');
    this.getButtonDetails();
    this.getAuditableDetails(this.templateData.menuURL)
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.activityService.setActivitySession(this.activityEntity);
    this.createNewForm();
    if(this.activityEntity?.entityId) {
    this.activityEntity.entityId = '';
    }
    }

    ngOnChanges(){
      this.tempSubData=JSON.parse(JSON.stringify(this.templateData.submenuData));
      this.templateData.submenuData = (this.templateData.submenuData && this.templateData.submenuData.length > 0) ? this.gridData() : this.getdata();
    }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  get getters() {
    return this.CreateForm.controls;
  }
  createNewForm() {
    this.CreateForm = this._fb.group({
      payerName: ['', Validators.required],
      electronicPayerId: [''],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      fax: [''],
      email: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      phone: [''],
      billingtype: [''],
      payerfamily: [''],
      participatinginfo: [''],
      payersguid: [''],
      iselectronicpayer: true,
      status: true
    })
  }
  
  LookupForm = this._fb.group({
    payerNamelookup: ['', Validators.required],
    electronicPayerIdlookup: [''],
    citylookup: [''],
    statelookup: [''],
    zipcodelookup: [''],
  })

  editPayers(object: any){
    // this.createNewForm();
    this.checkActiveData();
    this.create=true //to open create page
    this.CreateForm.controls.payerName.disable();
    this.CreateForm.controls.electronicPayerId.disable();
    this.CreateForm.controls.address1.disable();
    this.CreateForm.controls.address2.disable();
    this.CreateForm.controls.city.disable();
    this.CreateForm.controls.state.disable();
    this.CreateForm.controls.zip.disable();
    this.CreateForm.controls.phone.disable();
    this.CreateForm.controls.fax.disable();
    this.CreateForm.controls.email.disable();
    this.CreateForm.controls.billingtype.disable();
    this.CreateForm.controls.payerfamily.disable();
    this.CreateForm.controls.payersguid.disable();
    this.CreateForm.controls.participatinginfo.disable();
    this.editclicked=true; // to identify edit is clicked or not
    object.rowData.type = 'edit';
    this.newAction=object.rowData.type;  
    this.activityEntity.entityId = object.rowData.PayerID;
    object.rowData.Status = (object.rowData.Status === 'Active' || object.rowData.Status == true) ? 1 : 0;
    this.CreateForm.patchValue({
    payerName: object.rowData.PayerName,
    electronicPayerId: object.rowData.ElectronicPayerID,
    city: object.rowData.City,
    state: object.rowData.State1,
    address1: object.rowData.Address1,
    address2: object.rowData.Address2,
    phone: object.rowData.Phone,
    fax: object.rowData.Fax,
    billingtype: object.rowData.BillingType,
    zip: object.rowData.Zip,
    payerfamily: object.rowData.PayerFamily,
    participatinginfo: object.rowData.ParticipatingInfo,
    email: object.rowData.Email,
    iselectronicpayer: object.rowData.IsElectronicPayer,
    status: object.rowData.Status
  })
  this.payerId=object.rowData.PayerID;
  this.activityEdit={Status: object.rowData.Status,payerName: object.rowData.PayerName,payerId:object.rowData.PayerID}
  this.editObj= object.rowData;
  }

  getlocationpayers() {
    this.locationlevel = false;
    let query = this.subMenuCardModel.GetQuery('locationlevelpayers');
      // this.locationlevel=true;
      let queryVariable = {
        accid: sessionStorage.getItem('Locationid')
      };
      this.ngxService.start();
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.vitalHttpServices.GetData(queryResult).subscribe(
        data => {
          this.ngxService.stop();
          if (!data.errors) {
            this.templateData.submenuData = data.data.submenuData;
            this.templateData.submenuData
            if(this.templateData.submenuData.length == 0) {
              this.getdata();
            }
            this.checkActiveData();
            if (this.templateData.submenuData.length > 0) {
              this.locationlevel = true;
              // this.isEditButton=true;
              this.addDataToGrid(this.templateData.submenuData);
            }

          }
        },
        error => {
          this.ngxService.stop();
          console.error(error);
        }
      );
    
  }
  
gridData(){
  let templateData = this.templateData.submenuData
  if(this.templateData.submenuData?.length > 0) {
    this.locationlevel = true;
    this.isEditButton=true;
    this.addDataToGrid(templateData);
  }
  if(!this.uploadbtnClicked || !this.copyClicked) {
  this.checkActiveData();
  }
}

checkActiveData(){
  let templateData = this.templateData.submenuData
  let lastActiveData =  this.templateData.submenuData?.filter(va => va.Status === true)

    if (lastActiveData?.length == 1) {
      this.lastActivePayer = true;
      this.lastActivePayerid =  this.templateData.submenuData[0].PayerID
    }
    if (lastActiveData?.length == 0) {
      this.migrateenable = true;
      this.getdata();
    }
  }

  getdata() {
    this.isEditButton=false;
    this.migrateenable = true;
    let query = this.subMenuCardModel.GetQuery('globalpayers');
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        this.ngxService.stop();
        if (!data.errors) {
          let templateData = data.data.submenuData;
          if (templateData.length > 0) {
            this.masterLevel=true;
            this.addDataToGrid(templateData);
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Manage Payers') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
      }

    }
    this.gridheader = ['CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate', 'PayerName', 'IsElectronicPayer', 'ElectronicPayerID', 'AccountID', 'OrganizationID', 'BillingType', 'PayerCode',
      'PayerFamily', 'PayerID', 'payersstatus', 'ParticipatingInfo', 'Email', 'Fax', 'Phone', 'Status', 'Zip', 'State', 'City', 'Address', 'Address1','Address2','locationstatus','State1'];
    let gridarray = []
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                if (value == null) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      this.PayersGridData = new CollectionView(gridarray)
    }
  }

  createPayer() {
    this.create = true;
    this.editclicked=false;
    this.newAction='create'
    this.createNewForm();
    this.dropdownValuesForBillinType();
    this.dropdownValuesForPayerfamily();
  }

  dropdownValuesForPayerfamily() {
    let query = this.subMenuCardModel.GetQuery('managepayersfamily');
    let queryVariable = {};
    this.ngxService.start();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        this.ngxService.stop();
        if (!data.errors) {
          if (data?.data?.submenuData) {
            this.payerfamilydropdown = data?.data?.submenuData.map(obj => obj.payerfamily);
          }
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  dropdownValuesForBillinType() {
    let query = this.subMenuCardModel.GetQuery('managepayersbillingtype');
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.ngxService.stop();
          if (data?.data?.submenuData) {
            this.billingtypedropdown = data?.data?.submenuData.map(obj => obj.billingtype);
          }
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  save(action: any, editForm?) {
    let templateData = this.templateData.submenuData
    if (action == 'edit') { // edit 
      this.editObj.Status = editForm.controls.status.value;
      this.editObj.Status = (this.editObj.Status === 'Active' || this.editObj.Status == true) ? 1 : 0;
      let newObj = [];
      var newObj1 = {
        "Status": this.editObj.Status,
        "payerId": this.editObj.PayerID,
        "type": this.editObj.type,
        "accountid":this.editObj.AccountID
      }
      newObj.push(newObj1);
      if ((this.lastActivePayer) && (this.lastActivePayerid == this.editObj.PayerID) && (this.editObj.Status == false)) {
        let dialogRef = this.dialog.open(ConfirmLabadminComponent, { //popup
          disableClose: true,
          width: '500px',
          data: { header: "", message: "Inactivating payer",alert:"Default payers will be shown for this location, since all the payers associated to the locations. Do you want to proceed inactivating this payer?", continue: "Yes", cancel: "No" }
        });
        return dialogRef.afterClosed().toPromise().then((result) => {
          if(result){        
          this.vitalHttpServices.createpayers(newObj, this.deploymentkey).subscribe(
            result => {
               this.ngxService.stop();
              if (!result.errors) {
                this.commonService.auditDetails('payerId','payerName',[this.activityEdit], [newObj[0]], "Edit", this.templateData, this.auditableColumns);
                this._snackbar.open('Updated successfully', 'Close');
                this.CreateForm.reset();
                this.create=false;
                this.addDataToGrid(templateData);
              }
              this.refreshGrid();
            },
            error => {
               this.ngxService.stop();
              console.error(error);
            }
          );
        }
        });
      }
      else { 
        this.ngxService.start();
        this.vitalHttpServices.createpayers(newObj, this.deploymentkey).subscribe(
          result => {
            if (!result.errors) {
             this.ngxService.stop();
              this.commonService.auditDetails('payerId','payerName',[this.activityEdit], [newObj[0]], "Edit", this.templateData, this.auditableColumns);
              this._snackbar.open('Updated successfully', 'Close');
              // this.getlocationpayers();
              this.addDataToGrid(templateData);
              this.CreateForm.reset();
              this.create=false;    
            }
            this.refreshGrid();
          },
          error => {
            console.error(error);
          }
        );
      }
    }
    else {//create
      this.savedata = this.CreateForm.controls
      if (!this.savedata.payerName.value || this.savedata.payerName.value.toString().trim() == '') {
        this._snackbar.open('Please provide Payer name', 'Close')
        return;
      }
      var saveobj = {
        "payername": this.savedata.payerName.value,
        "electronicpayerid": this.savedata.electronicPayerId.value,
        "address1": this.savedata.address1.value,
        "address2": this.savedata.address2.value,
        "city": this.savedata.city.value,
        "state": this.savedata.state.value,
        "zip": this.savedata.zip.value,
        "fax": this.savedata.fax.value,
        "email": this.savedata.email.value,
        "phone": this.savedata.phone.value,
        "billingtype": this.savedata.billingtype.value,
        "payerfamily": this.savedata.payerfamily.value,
        "participatinginfo": this.savedata.participatinginfo.value,
        "iselectronicpayer": this.savedata.iselectronicpayer.value,
        "status": this.savedata.status.value,
        "slno": '',
        "accountid": this.accountid,
        "organizationid": this.orgid,
        "type": 'insert',
        "resultmessage": '',
        "result": '',
        "masterData":this.migrateenable
      }
      let tempobj:any = [];
      tempobj.push(saveobj);
      tempobj[0]['status'] = this.savedata.status.value == false ? 0 : 1;
      tempobj[0]['iselectronicpayer'] = this.savedata.iselectronicpayer.value == false ? 0 : 1;  
      if ( this.tempSubData &&
        this.tempSubData.some(va => va.PayerName == this.savedata.payerName.value &&
          (va.ElectronicPayerID == null ? '' : va.ElectronicPayerID) == this.savedata.electronicPayerId.value &&
          (!va.IsElectronicPayer ? 0 : 1) == this.savedata.iselectronicpayer.value &&
          (va.Address1 == null ? '' : va.Address1) == this.savedata.address1.value &&
          (va.Address2 == null ? '' : va.Address2)== this.savedata.address2.value &&
          (va.City == null ? '' : va.City) == this.savedata.city.value &&
          (va.Zip == null ? '' : va.Zip)== this.savedata.zip.value &&
          (va.State == null ? null : va.State) == this.savedata.state.value &&
          (va.PayerFamily == null ? '' : va.PayerFamily) == this.savedata.payerfamily.value &&
          (va.BillingType == null ? '' : va.BillingType) == this.savedata.billingtype.value &&
          (va.ParticipatingInfo == null ? '' : va.ParticipatingInfo) == this.savedata.participatinginfo.value &&
          (va.Fax == null ? '' : va.Fax) == this.savedata.fax.value &&
          (va.Email == null ? '' : va.Email) == this.savedata.email.value &&
          (va.Phone == null ? '' : va.Phone) == this.savedata.phone.value 
        )) {
        this._snackbar.open('Payer is already exist', 'Close');
        return;
      }
      else {
        this.ngxService.start();
        this.vitalHttpServices.createpayers(tempobj, this.deploymentkey).subscribe(
          result => {
            this.ngxService.stop();
            let activityTrackerObj = []
            activityTrackerObj = tempobj;
            activityTrackerObj[0].status = (activityTrackerObj[0].status == 0) ? 'InActive' : (activityTrackerObj[0].status == 1) ? 'Active' : activityTrackerObj[0].status;

            if (!result.errors) {
              this._snackbar.open('Created successfully', 'Close');
              this.activityEntity.entityId=result.content[0].payerId;
              // console.log(result.content[0].payerId);
              activityTrackerObj[0]['payerId'] = result.content[0].payerId;
              if (result.content[0].resultmessage == 'Payer has been created and associated to the location successfully') {
                this.commonService.auditDetails('payerId', 'payername',result.content, activityTrackerObj, "Create and Associate", this.templateData, this.auditableColumns);
              }
              else if (result.content[0].resultmessage == 'Payer has been successfully associated to the location') {
                this.commonService.auditDetails('payerId', 'payername',result.content, activityTrackerObj, "Association", this.templateData, this.auditableColumns);
              }
              else {
                this.commonService.auditDetails('payerId', 'payername',result.content, activityTrackerObj, "Create", this.templateData, this.auditableColumns);
              } 
              this.CreateForm.reset();             
              this.create=false; 
              this.getlocationpayers();
            }
          },
          error => {
            this.ngxService.stop();
            console.error(error);
          }
        );
      }
      this.refreshGrid();
    }
  }

  //meassge for entity/global data 
  getNote(){
    let isMasterLevel = this.masterLevel ? 'Default Level' :  'Location Level';
    return `Showing '${isMasterLevel}' ${this.templateData.menuURL.toString().trim()}`;
  }

  // lookup popup 
  lookup() {
    this.create = false;
    this.lookuppage = true;
  this.ngxService.stop();
    this._dailogService.openPayerDailog({
      electronicPayerId: this.CreateForm.controls.electronicPayerId.value, payerName: this.CreateForm.controls.payerName.value,
      state: this.CreateForm.controls.state.value, zip: this.CreateForm.controls.zip.value, city: this.CreateForm.controls.city.value
    }).afterClosed().subscribe(data => {
      this.saveObj = data[0];
      this.finalData();
    })

  }

  finalData() {
    this.create = true;
    this.CreateForm.patchValue({
      payerName: this.saveObj.PayerName,
      electronicPayerId: this.saveObj.ElectronicPayerID,
      city: this.saveObj.City,
      state: this.saveObj.state1,
      address1: this.saveObj.address1,
      address2: this.saveObj.address2,
      phone: this.saveObj.Phone,
      fax: this.saveObj.Fax,
      billingtype: this.saveObj.BillingType,
      zip: this.saveObj.Zip,
      payerfamily: this.saveObj.PayerFamily,
      participatinginfo: this.saveObj.participatinginfo,
      email: this.saveObj.Email,
      iselectronicpayer: this.saveObj.IsElectronicPayer,
      status: this.saveObj.status
    })
  }

  clearEntity() {
    this.selectedData = [];
    this.searchResult = [];
    this.gridWidth=0;
    this.copyFromOrgFrm.patchValue({
      frmOrganization:'',
      frmorgid:''
    })
  }

  clearLocation() {
    this.selectedData = [];
    this.searchResult = [];
    this.gridWidth=0;
    this.copyFromOrgFrm.patchValue({
      frmAccountId:'',
      frmaccid:''
    })

  }

  returnbutton() {
    this.CreateForm.reset();
    this.create = false;
    this.lookuppage = false;
    this.refreshGrid();
  }

  uploadPayers() {
    this.uploadbtnClicked = true;
    this.refreshGrid();
  }

  backClicked(event) {
    this.gridShow = true;
    this.uploadbtnClicked = false;
    this.refreshGrid();
    this.excelDataArray = [];
  }

  getButtonDetails() {
    this.getButtonAccess(this.vitalHttpServices.SubmenuAction);
  }

  refreshGrid() {
    this.CreateForm.reset(); 
    this.create=false;   
    this.gridShow = true;
    this.copyClicked = false;
    this.excelDataArray = [];
    this.tempExcelArr = [];
    this.copyFromOrgFrm.reset();
    this.griddata = [];
    this.getlocationpayers();
  }

  removegrid() {
    this.copyClicked = true;
    this.excelDataArray = [];
    this.tempExcelArr = [];
    this.griddata = [];
    this.gridWidth = 0;
    this.noDataFound=false;
  }

  backSelect() {
    this.copyClicked = false;
    this.create = false;
    this.gridShow = true;
    this.uploadbtnClicked = false;
    this.excelDataArray = [];
    this.tempExcelArr = [];
    this.copyFromOrgFrm.reset();
    this.griddata = [];
    this.noDataFound=false;
  }

  ExportExcel() {
    let excel = []
    this.excelDataArray.forEach(item => {
      const { organizationid, slno, type, tablename, showpopup, showinformation, isvalid, alertMessage, alertHeader, createdby, sequence, accountid,Address,State,referencecode,...rest } = item;
      excel.push(rest); // Push the transformed object into the array
    });
    let filename = this.templateData.menuURL + '_' + this.commonService.orgid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.configuredData.tablename);
    XLSX.writeFile(wb, filename);
  }

  copyFromOrg() {
    this.copyFromOrgFrm.controls.frmOrganization.valueChanges.subscribe(val => {
      if (!val || (val && val?.toString().trim() == '')) {
        this.copyFromOrgFrm.controls.frmAccountId.disable();
      }
      else {
        this.copyFromOrgFrm.controls.frmAccountId.enable();
      }
    });
    this.getConfigurations();
    this.copyClicked = true;
    this.gridShow = false;
    this.uploadbtnClicked = false;
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.deploymentkey,
      frmOrganization: '',
      frmorgid: '',
      frmAccountId: '',
      frmaccid: ''
    })
    this.getListAccount();
  }

  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmDeplyment: depKey
      })
      this.gridWidth = 0
      this.searchResult = [];
      this.postUpload = false;
      this.getListOrg();
      this.getListAccount();
      this.copyFromOrgFrm.controls.frmOrganization.enable();
    }
  }

  fetchOrgSeries(value: string) {
    value = value?.trim();
    this.searchResult = [];
    if(this.copyFromOrgFrm.value.frmOrganization == '') {
      this.searchResult=[];
      this.gridWidth=0;
    }
    if (!value) {
      this.searchResult = this.copyOrganizations.slice(0, 25);
      return;
    }
    const idValidate = /^[0-9]*$/;
    const lowerCaseValue = value.toLowerCase();

    if (this.copyFromOrgFrm.value.frmOrganization) {
      const filterCondition = idValidate.test(value)
        ? (series) => series.organizationid && series.organizationid.toString().toLowerCase().includes(lowerCaseValue)
        : (series) => series.organizationname && series.organizationname.toString().toLowerCase().includes(lowerCaseValue);

      this.searchResult = this.copyOrganizations.filter(filterCondition).slice(0, 25);
    }
  }


  getListOrg() {
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let queryVariable = {};
    let query = this.vitalHttpServices.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        this.ngxService.stop();
        if (data) {
          if (data.data.Organizations_list.length > 0) {
            this.copyOrganizations = data.data.Organizations_list
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }

  fetchLoactionSeries(value: string) {
    this.searchResultaccount = [];
    if (!value) {
      return (this.searchResultaccount = []);
    }
    let idValidate = /^[0-9]*$/;

    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResultaccount = this.organizationList.filter(function (series) {
          if (series && series.accountname != null) {
            return series.accountname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResultaccount = this.searchResultaccount.splice(0, 25);
      }
    } else {
      this.searchResultaccount = this.organizationList.filter(function (series) {
        if (series && series.accountid != null) {
          return series.accountid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResultaccount = this.searchResultaccount.splice(0, 25);
    }
    this.copyFromOrgFrm.value.frmAccountId = this.organizationList.filter(function (series: any) {
      if (series && series.accountname != null) {
        return series.accountid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });

  }

  getListAccount() {//check
    let queryVariable = { "accountid": null, "IsListedAsOF": null };
    let query = this.subMenuCardModel.GetQuery("accountDetails");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          this.organizationList = data.data.submenuData;//TODO : call api  organizationList is wempty
        }
      }
    }, error => {
      this.ngxService.stop();
      console.log(error);
    });
  }


  selectedCopyDataLocation(event, data) {
    this.selectedData = []
    if (event.source.selected && Object.keys(data).length > 0) {
      this.copyFromOrgFrm.patchValue({
        frmAccountId: data.accountname,
        frmaccid: data.accountid
      })
      this.getOrgLocPayers();
    }
  }

  selectedCopyDataOrganization(event, data) {
    this.selectedData = [];
    if (event.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmorgid: data.organizationid,
        frmOrganization: data.organizationname,
      })
    }
    // this.getOrgLocPayers();
  }

  getOrgLocPayers() {
    if (typeof (this.copyFromOrgFrm.value.frmorgid) == 'object') {
      let frmOrgid = null
      frmOrgid = this.copyFromOrgFrm.value.frmorgid
      this.copyFromOrgFrm.value.frmorgid = frmOrgid[0].orgid
    }
    if (typeof (this.copyFromOrgFrm.value.frmaccid) == 'object') {
      let frmAccid = null
      frmAccid = this.copyFromOrgFrm.value.frmaccid
      this.copyFromOrgFrm.value.frmaccid = frmAccid[0].accid
    }
    this.Gridata = false;
    let query = this.subMenuCardModel.GetQuery('locationlevelpayers');
    // let queryVariable = (
    //   (this.copyFromOrgFrm.value.frmorgid) && (!this.copyFromOrgFrm.value.frmaccid || this.copyFromOrgFrm.value.frmaccid == "")
    //     ? { orgid: this.copyFromOrgFrm.value.frmorgid.toString() } // If frmaccid is empty but frmorgid is not
    //     : {
    //       orgid: this.copyFromOrgFrm.value.frmorgid.toString(),
    //       accid: this.copyFromOrgFrm.value.frmaccid.toString()//TODO : pass account id only
    //     }
    // );
    let queryVariable ={accid: this.copyFromOrgFrm.value.frmaccid.toString()};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        this.ngxService.stop()
        if (!data.errors) {
          this.copyGridData = data.data.submenuData;
          if (this.copyGridData.length == 0) {
            this.noDataFound = true;
            this.gridWidth = 0;
            return;
          }
          this.noDataFound=false;
          this.convertToJson(this.copyGridData)
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  getdefaultpayers() {
    this.Gridata = false;
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let query = this.subMenuCardModel.GetQuery('globalpayers');
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult,dbName).subscribe(
      data => {
        this.ngxService.stop()
        if (!data.errors) {
          this.copyGridData = data.data.submenuData;
          if (this.copyGridData.length == 0) {
            this.noDataFound = true;
            this.gridWidth = 0;
            return;
          }
          this.noDataFound = false;
          this.convertToJson(this.copyGridData)
        }
      },
      error => {
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  excelGridData(data) {
    this.griddata = [];
    this.gridArray = [];
    let primary = {};
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            primary[key] = value;

          }
          this.gridArray.push(primary);
        }
      }
      this.griddata = this.gridArray;
      if (!this.postUpload) {
        this.sheetHeader.includes("Result") && this.sheetHeader.splice(this.sheetHeader.indexOf("Result"), 1);//remove result
      } else {
        !this.sheetHeader.includes("Result") && this.sheetHeader.splice(0, 0, "Result")// this.sheetHeader.push("Status");
      }
      this.gridWidth = (170 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  displayedColumns(item: any, header: any) {
    // Create a mapping between the display headers and the item keys
  const headerMapping: { [key: string]: string } = {
    'Payer Name': 'PayerName',
    'Electronic Payer ID': 'ElectronicPayerID',
    'Electronic Payer': 'IsElectronicPayer',
    'Payer Family': 'PayerFamily',
  };

  // Get the corresponding item key using the mapping, or use the header as-is
  const itemKey = headerMapping[header] || header;

  // Return the value from the item object using the mapped key
  return item[itemKey];
  }

  convertToJson(sheetname) {
    this.sheetHeader = ["PayerName", "ElectronicPayerID", "IsElectronicPayer", "PayerFamily", "Address", "Status", "Email"];
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    tempExcelArr = sheetname
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    tempExcelArr.find(r => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    });
    this.sheetHeader = ["PayerName", "ElectronicPayerID", "IsElectronicPayer", "PayerFamily", "Address", "Status", "Email"];
    this.sheetHeader.reverse();
    this.sheetHeader.push("ResultMessage");
    this.sheetHeader.push("Result");
    this.sheetHeader.reverse();
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempArray);
    this.excelDownloadArray = [...this.excelDataArray];
    this.excelDataArray.find(d => {
      Object.assign((d), {
        slno: Number(i), resultmessage: '', type: 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), accountid: Number(sessionStorage.getItem('Locationid'))
      });
      i++;
    });

    this.validateExcel(this.excelDataArray);
  }

  convertObjKeysToLower(inputArr) {
    return inputArr.map(obj => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key.replace(/\s/g, "").toLowerCase()] = value;
        return acc;
      }, {});
    });
  }

  // bulk upload generic to get sp 
  getConfigurations() {//check
    this.ngxService.start();
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { keyword: this.templateData.menuURL.toString(), context: 'bulkFunctionalities' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.configuredData = JSON.parse(Resdata?.data?.submenuData[0]?.Items_JSON);//TODO : call api when configuredData empty
      }
    }, error => {
      this.ngxService.stop()
      console.error(error);
    })
  }

  validateExcel(excelArr) {
    this.ngxService.start();
    const dataArray = this.convertObjKeysToLower(excelArr);
    let validateObj = {
      "storedProcedureName": this.configuredData.sp[0]["validate"],
      "jsonData": dataArray,
      "isValidate": true,
      "columnsToRemove": ["address", "state1", "payersstatus", "locationstatus", "organizationid", "accountid","payername"]
    }
    this.vitalHttpServices.bulkUploadFunctionalities(validateObj, this.destDeployment).subscribe(
      (result) => {
        if (!result.errors) {
          let data;
          if (result.status.toLowerCase() == 'success') {
            this.postUpload = false;
            data = JSON.parse(result.content);
            if (data[0]["InvalidColumns"] && data[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = data[0].InvalidColumns;
            }
            this.excelDataArray.filter(e => {
              data.find(r => {
                if (r.slno === e.slno) {
                  e.resultmessage = r.resultmessage;
                  e.isvalid = r.isvalid;
                  e.showinformation = r.showinformation
                  e.showpopup = r.showpopup;
                  e.alertMessage = r.alertmessage;
                  e.alertHeader = r.alertmessageheader;
                }
              })
            });
            this.excelGridData(this.excelDataArray);
            this.tempExcelArr = [];
          }
          else {
            this._snackbar.open("Something went wrong!", "Close");
          }
        } else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
        this.ngxService.stop();
      },
      (error) => {
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  initGrid(grid) {
    if (!this.postUpload) {
      this.selector = new Selector(grid, {
        itemChecked: () => {
          this.selectedData = grid.rows.filter(r => r.isSelected);
        }
      });
    }
  }

  // selected data to copy
  payerChecked(event, item) {
    if (event.target.checked) {
      this.tempExcelArr.push(item);
      // const newProperties = {
      //   masterData: this.migrateenable
      // };
      // this.tempExcelArr.forEach(item => {
      //   Object.assign(item, newProperties);
      // });
    }
    else {
      this.tempExcelArr = this.tempExcelArr.filter(arrItem => arrItem.slno !== item.slno);
    }
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  uploadBulk() {
    // this.tempExcelArr.filter(va =>va.masterData == true ? 1 : 0)
    // const newProperties = {
    //   masterData: this.migrateenable
    // };
    // this.excelDataArray.forEach(item => {
    //   Object.assign(item, newProperties);
    // });
    console.log(this.excelDataArray)
    this.excelDataArray = [...this.tempExcelArr];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["resultmessage"]) {
          if (this.copyClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
        }
        else if (this.excelDataArray[i]["isvalid"] == 0 || this.excelDataArray[i]["isvalid"] == -1) {
          errorcount++;
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }

      let procName = this.configuredData.sp[0]["insert"]
      this.ngxService.start();
      let upoloadObj = {
        "storedProcedureName": procName,
        "jsonData": this.excelDataArray
      }
      this.vitalHttpServices.bulkUploadFunctionalities(upoloadObj, this.destDeployment).subscribe(res => {
        this.ngxService.stop();
        if (res.status.toLowerCase() == 'success') {
          let result = JSON.parse(res.content);
          this.postUpload = true;
          this.excelDataArray.filter(e => {
            result.find(r => {
              if (r.slno === e.slno) {
                e.resultmessage = r.resultmessage;
                e.result = r.result;
              }
            });
          });
          this.excelGridData(this.excelDataArray);
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Copy', this.templateData, this.auditableColumns);
          this.tempExcelArr = [];


        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }

  // tooltip for note message
  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }

  getButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Create':
          this.createBtn = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Upload':
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
         case 'Edit':
            this.editBtn = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
            break;
        case 'Copy':
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
          case 'Migrate':
          this.migrateBtn = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }

  //patching billingtype and payerfamily  dropdown values to form
  billingtypePayerfamily($event: any, controlName: any) {
    this.CreateForm.patchValue({ [controlName]: $event });
  }
  displayedColumnsMigrate(item: any, header: any) {
    return item[header]
  }

  filtertemplates() {
    // this.filterdData =this.migrateData.filter(obj =>
    //   Object.values(obj).some(value =>
    //     typeof value === 'string' ? value.toLowerCase().includes(this.searchText.toLowerCase()) :
    //       typeof value === 'number' ? value.toString().includes(this.searchText) : false
      // ))
    // console.log(this.searchText,'search')
    for (let i = 0; i < this.migrateHeaders?.length; i++) {
      this.templatefilter[this.migrateHeaders[i]] = this.searchText
      // this.migrateData[this.migrateHeaders[i].key] = this.searchText
    }
  }

  clearSearch() {
    this.searchText = '';
    this.filtertemplates();
  }

  back(){
      this.gridShow=true;
      this.migrateClicked=false;
      this.tempExcelArr=[];
      this.filterdData=[];
      this.migrateData=[];
      this.postMigrate=false; 
  }

  async selectedPayerData(updateArray, inputArray) {
    // Create a Set of PayerIDs from inputArray for fast lookups
    const payerIDSet = new Set(inputArray.map(x => x.PayerID));
  
    // Update updateArray in a single loop
    updateArray.forEach(y => {
      y.isChecked = payerIDSet.has(y.PayerID);
    });
  
    // Optionally, return updateArray if needed
    return updateArray;
  }
  


  async MigrateGLdata() {
    let _this = this;
    _this.migrateClicked = true;
    _this.gridShow = false;
    _this.migrateHeaders = ["Payer Name", "Electronic Payer ID", "Electronic Payer", "Payer Family", "Address", "Status"];
    let query = _this.subMenuCardModel.GetQuery('globalpayers');
    let queryVariable = {};
    let queryResult = _this.commonService.GetCardRequest(queryVariable, query);
    _this.ngxService.start('migrate');
   await _this.vitalHttpServices.GetData(queryResult).toPromise().then(
      data => {
        _this.ngxService.stop('migrate');
        if (!data.errors) {
          _this.migrateData = data?.data?.submenuData;
          _this.filterdData=_this.migrateData
        }
      });
  }



  async backafterapprove(){
    let _this = this;
    let temp1 = cloneDeep(_this.filterdData);
    await _this.MigrateGLdata();
    _this.filterdData= await _this.selectedPayerData(_this.filterdData,temp1);
    _this.migratePayerChecked(event,_this.filterdData);
    this.postMigrate=false;
  
}

 
  migratePayerChecked(event, item) {
    if (event.target.checked) {
      this.ischeckedMigrate=true;
      this.tempExcelArr.push(item);
      const newProperties = {
        OrganizationId: this.orgid,
        AccountId: this.accountid
      };
      this.tempExcelArr.forEach(item => {
        Object.assign(item, newProperties);
      });
    } else {
      this.tempExcelArr = this.tempExcelArr?.filter(i => i !== item);
    }
  }
  
  getCheckMigrateData() {
    if(!this.ischeckedMigrate) {
      this._snackbar.open('please select the data','Close');
      return;
    }
    this.ngxService.start('proceed');
    this.postMigrate=false;
    this.filterdData=this.tempExcelArr;
    this.ngxService.stop('proceed');
    this.postMigrate=true;
  }

  approveMigrate() {
    // Remove the isChecked,Address,referencecode,State1 key from each object in this.tempExcelArr
    this.tempExcelArr = this.tempExcelArr.map(({ isChecked,Address,State1,referencecode, ...rest }) => rest);
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, { //popup
      disableClose: true,
      width: '500px',
      data: { header: "", message: "Migrate payers", alert:"You have chosen to associate the selected payers to the location level. On confirming this action, default payers will not be shown for this location.?", continue: "Confirm", cancel: "Cancel" }
    });
    return dialogRef.afterClosed().toPromise().then((result) => {
      if(result){        
        this.vitalHttpServices.migratePayers(this.tempExcelArr, this.deploymentkey).subscribe(
          result => {
            this.ngxService.stop();       
            if (!result.errors) {
              this._snackbar.open('Migrated successfully', 'Close');
              this.commonService.auditDetails('', '', [], this.tempExcelArr, 'Migrate', this.templateData, this.auditableColumns);
              this.isEditButton=true;
              this.masterLevel=false;
              this.migrateClicked = false; 
              this.gridShow = true;
          }
          this.refreshGrid();
        },
        error => {
           this.ngxService.stop();
          console.error(error);
        }
      );
    }
    });
  }  
}