import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs as importedSaveAs } from 'file-saver';
import { Validators } from 'ngx-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, lastValueFrom, map, Observable, startWith } from 'rxjs';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import * as models from '../../../labadmin/components/share/model/usermodels';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { MatIconButton } from '@angular/material/button';

export class Physician { physicianname?: string; userid?: string; }
export class OFLocation { accountname?: string; accountid?: string; organizationid?: any; }

@Component({
  selector: 'app-manage-organization',
  templateUrl: './manage-organization.component.html',
  styleUrls: ['./manage-organization.component.scss']
})

export class ManageOrganizationComponent {

  representativeUsersList: any = [];
  representativeRolesList: any = [];

  SubMenuCardModel: SubMenuCardModel;
  isLogoUploaded: boolean = false;
  SetupInformation: any;
  StateList = [];
  Timezones=[];
  UsernameWebFormat = [];
  UsernameReportFormat = [];
  fullToShortFormats = {};
  shortToFullFormats = {}
  dateFormat = [{ format: 'mm-dd-yyyy' }, { format: 'dd-mm-yyyy' }];
  unRevisedData: any;
  ref: any;
  isBtnDisabled: boolean = false;
  selectedRepresentatives: any;

  private OrganizationInput: any = {
    OrganizationName: "",
    OrganizationId: null,
    OrgType: "",
    OFAccountId: null,
    LabGroupId: null,
    UniqueID: null
  }

  patientPortalLocList = [];
  patientPortalPhysicianList = [];
  hideGoLiveInfo: boolean = false;
  files: File[] = [];
  revisedData: any;
  auditableColumns: any;
  @ViewChild('triggerFileInput', { static: false }) triggerFileInput: ElementRef;
  @ViewChild('closeModelBtn') closeModelBtn: MatIconButton;
  orgType: string;
  isP4Deployment: boolean = false;
  patientPortalLocOBJ: { accountname: string; accountid: string; organizationid: any; };
  patientPortalPhysicianOBJ: { physicianname: string; userid: string; };
  filteredOFLocation: Observable<any[]>;
  filteredOFPhysician: Observable<any[]>;
  showAssignCard = false;
  currentRole: any;
  selectedRepresentativesList: any = [];
  selectedRepresentativesClone: any;

  constructor(public ngxService: NgxUiLoaderService, public datePipe: DatePipe, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private labdaminService: LabadminService,
    private _snackBar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public activityService: ActivityTrackerService,
    private _dailogService: DialogService,public vaservices: VitalHttpServices) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
  }
  activityEntity: any;
  selectedImage: string | undefined = '';
  loginTypeList = [{ item: "Email" }, { item: "LoginName" }];
  OrgFormGrp = this._fb.group({
    frmLogo: [""]
    , frmGroupname: ["", Validators.required]
    , frmNpi: [""]
    , frmClia: [""]
    , frmMnemonic: ["", [Validators.required, Validators.maxLength(18)]]
    , frmStatelic: [""]
    , frmUrl: [""]
    , frmLabgroupid: [""]
    , frmUniqueid: [""]
    , frmUsernamedisplayformat: [""]
    , frmUsernamereportformat: [""]
    , frmTimezone: [""]
    , frmIsactive: true
    , frmAddress1: [""]
    , frmAddress2: [""]
    , frmCity: [""]
    , frmCountry: [""]
    , frmState: [""]
    , frmZip: [""]
    , frmPhone: [""]
    , frmEmail: [""]
    , frmFax: [""]
    , frmBillingphonenumber: [""]
    , frmLogintype: [""]
    , frmGuid: [""]
    , frmExternalorgguid: [""]
    , frmAppurl: [""]
    , frmPatientportalurl: [""]
    , frmVourl: [""]
    , frmPatientportalaccountid: [new OFLocation()]
    , frmPatientportalphysicianid: [new Physician()]
    , frmCoverpagetemplate: [""]
    , frmHoldreleaseduration: [""]
    , frmReferencelab: [""]
    , frmBatchingmnemonic: [""]
    , frmShowreviewpathsignature: false
    , frmAutopopulatediagicd9codes: false
    , frmIsvitalordersenabled: false
    , frmIstestorganization: false
    , frmShowonlyaccountdetails: false
    , frmIsorgsetuppending: false
    , frmAdtenabled: false
    , frmDiagicdcodereminder: false
    , frmSendoutdisclaimer: false
    , frmIsofallowfullfilltests: false
    , frmLoadbydefault: false
    , frmDefaultlab: false
    , frmIsadenabled: false
    , frmOrganizationdescription: [""]
    , frmOrgtype: [""]
    , frmInvoicecode: [""]
    , frmGolivedate: [""]
    , frmReleaseversion: [""]
    , frmCustomerstatus: [""]
    , frmIscustomer: false
    , frmOrganizationcode: [""]
    , frmStatedisplayname: [""]
    , frmJarlabeltemplate: [""]
    , frmStorageid: [""]
    , frmArchivedtilldate: [""]
    , frmExternalpracticeguid: [""]
    , frmOrgversion: [""]
    , frmSourceguid: [""]
    , frmUrltype: [""]
    , frmTaxonomycode: [""]
    , frmSendoutorderholdduration: [""]
    , frmCollectiondateformat: [""]
    , frmReceiveddateformat: [""]
    , frmPackagedateformat: [""]
    , frmEtadateformat: [""]
    , frmSupportid: [""]
    , frmTemplateorganizationid: [""]
    , frmSpawnholdduration: [""]
    , frmLastarchiveddate: [""]
  })

  async ngOnInit() {
    this.currentRole = localStorage.getItem('Homepage_role');
    this.showAssignCard = (sessionStorage.getItem('selectedContext') === 'Laboratory' || this.commonService.isPol);
    this.getListOfRepresentiveUsers();
    this.isP4Deployment = (this.VitalHttpServices.deploymentKey?.toString().match(/thx/i) ? true : false);
    await this.getOrgDetails();
    this.getAuditableDetails("Group Details");
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': '', 'context': [{ 'key': 'parentMenu', 'value': "Location" }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);

    this.activityEntity['entityid'] = this.commonService.getOrgid();
    this.activityService.setActivitySession(this.activityEntity);

    this.filteredOFLocation = this.OrgFormGrp.controls.frmPatientportalaccountid.valueChanges
        .pipe(
          startWith<any>(new OFLocation()),
          map(val => this.filterData(val, 'OFLoc', 'accountname','accountid'))
        );
    this.filteredOFPhysician = this.OrgFormGrp.controls.frmPatientportalphysicianid.valueChanges
        .pipe(
          startWith<any>(new Physician()),
          map(val => this.filterData(val, 'Physician', 'physicianname','userid'))
        );
  }

  //#region Filter function common for Source Group & Dest Entity
  filterData(filter: string, type: string, key: string, key1?: string): any[] {
    const dataArray = this.getMasterCopyForDropdown(type) ? this.getMasterCopyForDropdown(type) : [];
    if (dataArray && dataArray.length > 0) {
      filter = filter?.toString().trim();
      if (filter) {
        return dataArray.filter((option) => {
          return (
            key1 ? ((option[key]?.toString().toLowerCase().indexOf(filter?.toString().toLowerCase()) >= 0) ||
            (option[key1]?.toString() == filter?.toString()))
            : (option[key]?.toString().toLowerCase().indexOf(filter?.toString().toLowerCase()) >= 0)
          );
        }).splice(0, 25);
      } else {
        return dataArray.splice(0, 25);
      }
    } else {
      return [];
    }
  }
  //#endregion

  //#region master data
  getMasterCopyForDropdown(type: string) {
    switch (type) {
      case 'OFLoc': return (this.patientPortalLocList && this.patientPortalLocList.length > 0) ? structuredClone(this.patientPortalLocList) : [];
      case 'Physician': return (this.patientPortalPhysicianList && this.patientPortalPhysicianList.length > 0) ? structuredClone(this.patientPortalPhysicianList) : [];
    }
  }
  //#endregion

  async isOrganizationExists(Data) {
    let value = this.OrgFormGrp.controls.frmGroupname.value?.toString() ?? '';
    let labGroupID = this.OrgFormGrp.controls.frmLabgroupid.value ? Number(this.OrgFormGrp.controls.frmLabgroupid.value) : null;
    let uniqueID = this.OrgFormGrp.controls.frmUniqueid.value ?? null;
    let isValid = true;
    if (Data.frmGroupname?.toString().trim() && Data.frmMnemonic?.toString().trim()) {
      this.OrganizationInput.OrganizationName = value;
      this.OrganizationInput.OrganizationId = this.commonService.getOrgid();
      this.OrganizationInput.LabGroupId = labGroupID;
      this.OrganizationInput.UniqueID = uniqueID;
      let result = await this.isExists(this.OrganizationInput);
      if (result?.isExists && !result?.msg) {
        if (!this.isBtnDisabled) {
          this.ref = this._dailogService.openLabadminConfimationdialog(
            '',
            `There is already another Group with the same name. Do you wish to continue saving?`,
            'OK',
            'Cancel'
          );
        }
        await this.ref?.afterOpened().toPromise().then((res) => {
          isValid = false;
          this.isBtnDisabled = true;
        })
        await this.ref?.afterClosed().toPromise().then((res) => {
          this.isBtnDisabled = false;
          if (!res)
            {
              isValid = false;
              // this.OrgFormGrp.patchValue({
              //   frmGroupname: '',
              // });
            }
          else
          {
            isValid = true;
            return
          }
        });
      } else if (result?.msg) {
        isValid = false;
        this._snackBar.open(result.msg, "Close");
        return
      }
      if(!this.isBtnDisabled && isValid)
      {
        await this.SaveGroupData(Data);
      } else {
        return
      }
    } else {
      this._snackBar.open("Please enter mandatory field","Close");
      return
    }
  }

  async isExists(obj: any) {
    try {
      this.ngxService.start();
      let result: any = await lastValueFrom(
        this.VitalHttpServices.validateOrganization([obj],
          this.VitalHttpServices.deploymentKey
        )
      );
      this.ngxService.stop();
      if (result.status == 'Success') {
        if (result.content[0].notes?.toString().includes('Group name already exists')) {
          return { isExists: true, isInvalid: false};
        }
        else if (result.content[0].notes?.toString().includes('Unique Id already exists')) {
          return { isExists: true, isInvalid: false, msg: 'Unique Id already exists' };
        } else if (result.content[0].notes?.toString().includes('Invalid')) {
          return { isExists: false, isInvalid: true, msg: 'Please enter valid Lab Group Id' };
        }
        return { isExists: false, isInvalid: false };
      } else {
        return { isExists: false, isInvalid: false };
      }
    } catch (error) {
      this.ngxService.stop();
      console.error(error);
      return { isExists: false, isInvalid: false };
    }
  }

  getMnemonic(groupName) {
    if (groupName?.toString().trim()) {
      // Trim and remove any punctuation or extra spaces
      groupName = groupName.trim().replace(/[^\w\s]/g, '');
  
      // Split the name into words
      const words = groupName.split(' ');
      let mnemonic : any = '';
  
      if (words.length > 1) {
        const matches = groupName.match(/\b(\w)/g); // Get the first letter of each word
  
        // If we have at least two matches, take the first two letters
        if (matches && matches.length >= 2) {
          mnemonic = matches[0] + matches[1]; // First letter of first and second word
        } else if (matches && matches.length === 1) {
          mnemonic = matches[0]; // Only one initial if there is a single match
        }
      } else {
        // If there is only one word, take the first two letters
        mnemonic = groupName.trim().substring(0, 2).toUpperCase();
      }
  
      // Set the mnemonic value, and make sure it is uppercase
      if (mnemonic) {
        this.OrgFormGrp.controls['frmMnemonic'].setValue(mnemonic.trim().toUpperCase());
      } else {
        this.OrgFormGrp.patchValue({ frmMnemonic: null});
      }
    } else {
      this.OrgFormGrp.patchValue({frmMnemonic : null}); // Clear if groupName is empty
    }
  }

  onFormatSelection(event, type?: string) {
    if (type) {
      this.OrgFormGrp.patchValue({ frmUsernamedisplayformat: event['selectedObject'].item?.toString() });
    }  else {
      this.OrgFormGrp.patchValue({ frmUsernamereportformat: event['selectedObject'].item?.toString() });
    }
  }

  async SaveGroupData(Data) {
        let imageSrcString;
    if(this.selectedImage !="" )
    {
      imageSrcString = await this.toDataURL(this.selectedImage)
      imageSrcString = imageSrcString.toString().split(",")[1];
    }
    else{
      imageSrcString=null;
    }

    if(Data.frmTimezone){
      let frm_timezone = this.Timezones.find(va => va.item == Data.frmTimezone)?.item;
      if (!frm_timezone) {
        this._snackBar.open("Please Select Valid TimeZone", "Close");
        this.ngxService.stop()
        return;
      }
    }

      let modifiedBy = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : Number(sessionStorage.getItem("Userid"));
      let statedisplayname = Data.frmState ? (this.StateList.find(e => e.itemValue === ((Data.frmState?.toString().split('('))[1])?.toString().split(')')[0])) : null;
      let obj = {
        "organizationid": this.commonService.getOrgid()
        , "organizationname": Data.frmGroupname ? Data.frmGroupname : null
        , "adtenabled": Data.frmAdtenabled ? Data.frmAdtenabled : null
        , "usernamedisplayformat": Data.frmUsernamedisplayformat ? this.fullToShortFormats[Data.frmUsernamedisplayformat] : null
        , "usernamereportformat": Data.frmUsernamereportformat ? this.fullToShortFormats[Data.frmUsernamereportformat] : null
        , "state": (statedisplayname?.itemValue) ? statedisplayname.itemValue : null
        , "zip": Data.frmZip ? Data.frmZip : null
        , "status": Data.frmIsactive ? 'Active' : 'Inactive'
        , "ein": Data.frmEin ? Data.frmEin : null
        , "statelic": Data.frmStatelic ? Data.frmStatelic : null
        , "npi": Data.frmNpi ? Data.frmNpi : null
        , "taxonomycode": Data.frmTaxonomycode ? Data.frmTaxonomycode : null
        , "url": Data.frmUrl ? Data.frmUrl : null
        , "supportid": Data.frmSupportid ? Data.frmSupportid : null
        , "logo": imageSrcString
        , "phone": Data.frmPhone ? Data.frmPhone : null
        , "fax": Data.frmFax ? Data.frmFax : null
        , "mnemonic": Data.frmMnemonic ? Data.frmMnemonic?.toUpperCase() : null
        , "address1": Data.frmAddress1 ? Data.frmAddress1 : null
        , "address2": Data.frmAddress2 ? Data.frmAddress2 : null
        , "city": this.OrgFormGrp.controls.frmCity.value ? this.OrgFormGrp.controls.frmCity.value : null
        , "country": Data.frmCountry ? Data.frmCountry : null
        , "jarlabeltemplate": Data.frmJarlabeltemplate ? Data.frmJarlabeltemplate : null
        , "organizationcode": Data.frmOrganizationcode ? Data.frmOrganizationcode : null
        , "billingphonenumber": Data.frmBillingphonenumber ? Data.frmBillingphonenumber : null
        , "clia": Data.frmClia ? Data.frmClia : null
        , "templateorganizationid": Data.frmTemplateorganizationid ? Data.frmTemplateorganizationid : null
        , "holdreleaseduration": Data.frmHoldreleaseduration ? Data.frmHoldreleaseduration : null
        , "coverpagetemplate": Data.frmCoverpagetemplate ? Data.frmCoverpagetemplate : null
        , "loadbydefault": Data.frmLoadbydefault ? Data.frmLoadbydefault : null
        , "showreviewpathsignature": Data.frmShowreviewpathsignature ? Data.frmShowreviewpathsignature : null
        , "autopopulatediagicd9codes": Data.frmAutopopulatediagicd9codes ? Data.frmAutopopulatediagicd9codes : null
        , "collectiondateformat": Data.frmCollectiondateformat ? Data.frmCollectiondateformat : null
        , "receiveddateformat": Data.frmReceiveddateformat ? Data.frmReceiveddateformat : null
        , "packagedateformat": Data.frmPackagedateformat ? Data.frmPackagedateformat : null
        , "etadateformat": Data.frmEtadateformat ? Data.frmEtadateformat : null
        , "spawnholdduration": Data.frmSpawnholdduration ? Data.frmSpawnholdduration : null
        , "sendoutdisclaimer": Data.frmSendoutdisclaimer ? Data.frmSendoutdisclaimer : null
        , "sendoutorderholdduration": Data.frmSendoutorderholdduration ? Data.frmSendoutorderholdduration : null
        , "externalpracticeguid": Data.frmExternalpracticeguid ? Data.frmExternalpracticeguid : null
        , "lastarchiveddate": Data.frmLastarchiveddate ? Data.frmLastarchiveddate : null
        , "archivedtilldate": Data.frmArchivedtilldate ? Data.frmArchivedtilldate : null
        , "isofallowfullfilltests": Data.frmIsofallowfullfilltests ? Data.frmIsofallowfullfilltests : null
        , "diagicdcodereminder": Data.frmDiagicdcodereminder ? Data.frmDiagicdcodereminder : null
        , "iscustomer": Data.frmIscustomer ? Data.frmIscustomer : null
        , "invoicecode": Data.frmInvoicecode ? Data.frmInvoicecode : null
        , "customerstatus": Data.frmCustomerstatus ? Data.frmCustomerstatus : null
        , "clientnumber": Data.frmClientnumber ? Data.frmClientnumber : null
        , "isclientcodeenabled": Data.frmIsclientcodeenabled ? Data.frmIsclientcodeenabled : null
        , "statedisplayname": (statedisplayname?.formattedstate) ? statedisplayname.formattedstate : null
        , "externalorgguid": Data.frmExternalorgguid ? Data.frmExternalorgguid : null
        , "golivedate": Data.frmGolivedate ? Data.frmGolivedate : null
        , "isorgsetuppending": Data.frmIsorgsetuppending ? Data.frmIsorgsetuppending : null
        , "createdby": modifiedBy
        , "defaultlab": Data.frmDefaultlab ? Data.frmDefaultlab : null
        , "organizationdescription": Data.frmOrganizationdescription ? Data.frmOrganizationdescription : null
        , "releaseversion": Data.frmReleaseversion ? Data.frmReleaseversion : null
        , "orgtype": Data.frmOrgtype ? Data.frmOrgtype : null
        , "labgroupid": Data.frmLabgroupid ? Data.frmLabgroupid : null
        , "timezone": Data.frmTimezone ? Data.frmTimezone : null
        , "isvitalordersenabled": Data.frmIsvitalordersenabled ? Data.frmIsvitalordersenabled : null
        , "batchingmnemonic": Data.frmBatchingmnemonic ? Data.frmBatchingmnemonic : null
        , "referencelab": Data.frmReferencelab ? Data.frmReferencelab : null
        , "showonlyaccountdetails": Data.frmShowonlyaccountdetails ? Data.frmShowonlyaccountdetails : null
        , "patientportalurl": Data.frmPatientportalurl ? Data.frmPatientportalurl : null
        , "email": Data.frmEmail ? Data.frmEmail : null
        , "urltype": Data.frmUrltype ? Data.frmUrltype : null
        , "patientportalaccountid": Data.frmPatientportalaccountid?.accountid ? Data.frmPatientportalaccountid.accountid : null
        , "patientportalphysicianid": Data.frmPatientportalphysicianid?.userid ? Data.frmPatientportalphysicianid.userid : null
        , "isadenabled": Data.frmIsadenabled ? Data.frmIsadenabled : null
        , "storageid": Data.frmStorageid ? Data.frmStorageid : null
        , "logintype": Data.frmLogintype ? Data.frmLogintype : null
        , "appurl": Data.frmAppurl ? Data.frmAppurl : null
        , "vourl": Data.frmVourl ? Data.frmVourl : null
        , "uniqueid": Data.frmUniqueid ? Data.frmUniqueid : null
        , "orgversion": Data.frmOrgversion ? Data.frmOrgversion : null
        , "adminkey": Data.frmAdminkey ? Data.frmAdminkey : null
        , "istestorganization": Data.frmIstestorganization ? Data.frmIstestorganization : null
        , "sourceguid": Data.frmSourceguid ? Data.frmSourceguid : null
        , "representativedetails": this.selectedRepresentatives?.length ?  this.selectedRepresentatives : null
      }
      if(obj.email && !this.validateEmail(obj.email))
      {
        this._snackBar.open("Please enter valid Email","Close");
        return
      }


      const validateGUID = (guid, fieldName) => {
        if (guid && !this.isValidGUID(guid.toString())) {
          this._snackBar.open(`Please Enter valid ${fieldName}`, "Close");
          return false;
        }
        return true;
      };

      if (!validateGUID(obj.externalorgguid, "External Group GUID")) {
        return;
      }
      if (!validateGUID(obj.sourceguid, "Source GUID")) {
        return;
      }
      if (!validateGUID(obj.externalpracticeguid, "External Practice GUID")) {
        return;
      }

      this.VitalHttpServices.updateOrganization([obj], this.VitalHttpServices.deploymentKey).toPromise().then(res => {
        if (res.status == 'Success') {
          this.auditData(obj);
          this.unRevisedData = obj;
          this.refresh();
          this._snackBar.open("Group details updated successfully", "Close");
          this.closeModelBtn._elementRef.nativeElement.click();
        } else {
          this._snackBar.open("Failed to update Group details", "Close");
        }
      }, (error) => {
        this._snackBar.open("Error occured while processing your request", "Close");
        console.error(error);
      })
  }

  //#region returns blob
  toDataURL = async (url) => {
    let res = await fetch(url);
    let blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };
  //#endregion

  getStateAndCity() {
    const zipCode = this.OrgFormGrp.value.frmZip;
    this.disableCityAndStateFields(false);
    if (zipCode && [5, 10].includes(zipCode.length)) {
      const zipCode = this.OrgFormGrp.value.frmZip.substring(0, 5);
      this.disableCityAndStateFields(true);
      this.VitalHttpServices.getStateAndCity(zipCode, this.VitalHttpServices.deploymentKey)
        .subscribe((data: any) => {
          if (data && data.City && data.State) {
            const matchingState = this.StateList.find(state => state.item?.toString().includes('(' + data.State + ')'));
            if (matchingState) {
              this.OrgFormGrp.patchValue({ frmState: matchingState.item, frmCity: data.City });
              this.disableCityAndStateFields(true);
            } else {
              this.disableCityAndStateFields(false);
              this.OrgFormGrp.patchValue({ frmState: '', frmCity: '' });
            }
          }
          else {
            this.disableCityAndStateFields(false);
            console.error("No data or city found for the provided ZIP code.");
            this.OrgFormGrp.patchValue({ frmState: '', frmCity: '' });
          }
        }, (error) => {
          console.error("Error fetching state and city:", error);
          this.OrgFormGrp.patchValue({ frmState: '', frmCity: '' });
        });
    }
    else {
      this.disableCityAndStateFields(false);
      this.OrgFormGrp.patchValue({ frmState: '', frmCity: '' });
    }
  }

  disableCityAndStateFields(disable: boolean) {
    if (disable) {
      this.OrgFormGrp.get('frmCity').disable();
      this.OrgFormGrp.get('frmState').disable();
    } else {
      this.OrgFormGrp.get('frmCity').enable();
      this.OrgFormGrp.get('frmState').enable();
    }
  }

  onDrop(files: any) {
    this.AddLogo(files);
  }

  onFileSelected(event) {
    let tempArrData = event.target ? event.target.files[0] : event[0];
    if (tempArrData.type.toString().toLowerCase() == "image/png" ||
      tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
      event.addedFiles = [];
      event.addedFiles.push(tempArrData);
      this.files = [];
      this.files.push(...event.addedFiles);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logo_Upload] = this.files;
        reader.readAsDataURL(logo_Upload);
        reader.onload = () => {
          this.selectedImage = reader.result as string;
        };
      } else {
        this._snackBar.open("An error occurred while processing your request", "Failed");
      }
    } else {
      this._snackBar.open("Please upload image file only", "Failed");
    }
    this.triggerFileInput.nativeElement.value = "";
  }

  downloadImage(file) {
    importedSaveAs(file, `${this.OrgFormGrp.value.frmGroupname}_logo.jpg`);
  }

  private AddLogo(files: FileList | null) {
    if (!files || files.length === 0) {
      return;
    }
    if (files.length > 1) {
      this._dailogService.openLabadminConfimationdialog("", 'Please drag and drop single image to upload.', 'OK', 'dontshow')
      return;
    }
    const file = files[0];
    // this.selectedFile = files[0];
    const allowedExtensions = models.mediaConstants.logo.filetype.split(',');
    const fileExtension = file.name.split('.').pop()!.toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      if (file.size < Number(models.mediaConstants.logo.fileSize) * 1024 * 1024) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          this.selectedImage = e.target.result as string;
          this.OrgFormGrp.controls.frmLogo.patchValue(
            this.selectedImage.split('base64,')[1]
          );
          this.isLogoUploaded = true;
        };
      } else {
        this._dailogService.openLabadminConfimationdialog("", 'Please Upload the file below 5MB.', 'OK', 'dontshow')
      }
    } else {
      this._dailogService.openLabadminConfimationdialog("", `Please select a valid image file with the extensions i.e., ${models.mediaConstants.logo.filetype.split(',').join(', ')}.`, 'OK', 'dontshow')
    }
  }

  onDeleteLogo() {
    this.selectedImage = '';
    this.OrgFormGrp.controls.frmLogo.patchValue('');
  }

  async getlookupValues() {
    let queryVariable = { keyword: 'getDropDownValues' };
    let query = this.SubMenuCardModel.GetQuery('CategoryDropDown');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, "configdb").toPromise().then(async data => {
      this.SetupInformation = data.data.submenuData[0];
      let dropdowndata = JSON.parse(this.SetupInformation.Items_JSON);
      this.StateList = dropdowndata.States;
      this.StateList.forEach(e => {
        // Extract the state name and abbreviation
        const stateName = e.item.substring(0, e.item.lastIndexOf('(')).trim();
        const stateAbbreviation = e.item.substring(e.item.lastIndexOf('(') + 1, e.item.lastIndexOf(')')).trim();

        // Add new formatted key
        e['formattedstate'] = `${stateAbbreviation} (${stateName})`;
        e['itemValue'] = stateAbbreviation; // Assuming you still want itemValue as well
      });
      // this.Timezones = dropdowndata.TimeZone;

      this.UsernameWebFormat = [...dropdowndata.UserNameDisplayFormat];
      this.UsernameReportFormat = [...dropdowndata.UserNameReportFormat];
      let formats = [...this.UsernameWebFormat,...this.UsernameReportFormat];
      this.fullToShortFormats =  formats.reduce((acc, curr) => {
        acc[curr.item] = curr.value;
        return acc;
      }, {});
      this.shortToFullFormats =  formats.reduce((acc, curr) => {
        acc[curr.value] = curr.item;
        return acc;
      }, {});
      this.Timezones = [...dropdowndata.TimeZone]
    }, error => {
      console.error(error);
    })
  }

  async getOrgDetails() {
    try {
      this.ngxService.start('LoadOrgDetails');
      await this.getAccountType();
      await this.getlookupValues();
      let queryVariable = { Orgid: sessionStorage.getItem('org_id') }
      let dbName = sessionStorage.getItem('deploymentKey');
      let keyWord = this.isP4Deployment ? 'organizationdetailsp4' : 'organizationdetailsnonp4'
      let query = this.SubMenuCardModel.GetQuery(keyWord);
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      await this.VitalHttpServices.GetData(queryResult, dbName).toPromise().then(async data =>{
        if (data) {
          if (!data.errors) {
            if (data.data.submenuData) {
              this.unRevisedData = this.commonService.convertObjKeysToLower(data.data.submenuData)[0];
              await this.patchData();
            }
          }
        }
      })
    } catch (exception) {
      this._snackBar.open("Error occurred while processing your request", "Close");
      console.error(exception);
    }
    finally{
      this.ngxService.stop('LoadOrgDetails');
    }
  }

  openOrCloseDD(event: Event, trigger: MatAutocompleteTrigger, ref: any, control: string) {
    event.stopPropagation();
    trigger.panelOpen ? trigger.closePanel() : ref.focus();
    if (trigger.panelOpen){
      ref.click()
      this.onInputFocus(control)
    }
  }

  //#region to display value
  displayFnLoc(selectedoption) {
    return selectedoption ? (('accountname' in selectedoption) ? selectedoption.accountname : selectedoption.accountname) : undefined;
  }
  //#endregion

  //#region to display value
  displayFnPhy(selectedoption) {
    return selectedoption ? (('accountname' in selectedoption) ? selectedoption.accountname : selectedoption.physicianname) : undefined;
  }
  //#endregion

  onInputFocus(control: string) {
    if (!this.OrgFormGrp.value[control]) {
      this.OrgFormGrp.patchValue({
        [control]: ""
      })
      this.OrgFormGrp.controls[control].setValidators([Validators.required]);
      this.OrgFormGrp.controls[control].updateValueAndValidity();
    }
  }

  resetFormData()
  {
    this.OrgFormGrp.reset();
    this.OrgFormGrp.patchValue({
       frmUsernamedisplayformat: ""
      , frmUsernamereportformat: ""
      })
  }

  async patchData() {
    try{
      this.ngxService.start('patchData');
      let result = this.unRevisedData;
      let oFAccountName = result.patientportalaccountid ? (this.patientPortalLocList.find(x => x.accountid == result.patientportalaccountid)) : new OFLocation()
      let PhysicianName
      if(result.patientportalaccountid)
      {
        await this.getOFPhysicians(result.patientportalaccountid)
        PhysicianName = result.patientportalphysicianid ? (this.patientPortalPhysicianList.find(x => x.userid == result.patientportalphysicianid)) : PhysicianName
      }
      this.selectedImage = result.logo ? `data:image/jpg;base64,${result.logo}` : '';
      this.hideGoLiveInfo = (result.isorgsetuppending || result.istestorganization) ? true : false;
      if (result.logintype?.toString()?.toLowerCase()?.trim() == 'email') {
        this.OrgFormGrp.get('frmLogintype').disable();
      }
      this.OrgFormGrp.patchValue({
        frmLogo: result.logo
        , frmGroupname: result.organizationname
        , frmNpi: result.npi
        , frmClia: result.clia
        , frmMnemonic: result.mnemonic
        , frmStatelic: result.statelic
        , frmUrl: result.url
        , frmLabgroupid: result.labgroupid
        , frmUniqueid: result.uniqueid
        , frmUsernamedisplayformat: (result.usernamedisplayformat ? this.shortToFullFormats[result.usernamedisplayformat] : '')
        , frmUsernamereportformat: (result.usernamereportformat ? this.shortToFullFormats[result.usernamereportformat] : '')
        , frmTimezone: result.timezone
        , frmIsactive: result.status?.toString().toLowerCase() == 'active' ? true : false
        , frmAddress1: result.address1
        , frmAddress2: result.address2
        , frmCity: result.city
        , frmCountry: result.country
        , frmState: result.state
        , frmZip: result.zip
        , frmPhone: result.phone
        , frmEmail: result.email
        , frmFax: result.fax
        , frmBillingphonenumber: result.billingphonenumber
        , frmLogintype: result.logintype ? (result.logintype?.toString()?.toLowerCase()?.trim() == 'email') ? 'Email' : 'LoginName' : this.VitalHttpServices['OrganizationData'].logintype
        , frmGuid: result.guid?.toUpperCase()
        , frmExternalorgguid: result.externalorgguid?.toUpperCase()
        , frmAppurl: result.appurl
        , frmPatientportalurl: result.patientportalurl
        , frmVourl: result.vourl
        , frmPatientportalaccountid: (oFAccountName && Object.keys(oFAccountName).length > 0) ? oFAccountName : ""
        , frmPatientportalphysicianid: (PhysicianName && Object.keys(PhysicianName).length > 0) ? PhysicianName : ""
        , frmCoverpagetemplate: result.coverpagetemplate
        , frmHoldreleaseduration: result.holdreleaseduration
        , frmReferencelab: result.referencelab
        , frmBatchingmnemonic: result.batchingmnemonic
        , frmShowreviewpathsignature: result.showreviewpathsignature
        , frmAutopopulatediagicd9codes: result.autopopulatediagicd9codes
        , frmIsvitalordersenabled: result.isvitalordersenabled
        , frmIstestorganization: result.istestorganization
        , frmShowonlyaccountdetails: result.showonlyaccountdetails
        , frmIsorgsetuppending: result.isorgsetuppending
        , frmAdtenabled: result.adtenabled
        , frmDiagicdcodereminder: result.diagicdcodereminder
        , frmSendoutdisclaimer: result.sendoutdisclaimer
        , frmIsofallowfullfilltests: result.isofallowfullfilltests
        , frmLoadbydefault: result.loadbydefault
        , frmDefaultlab: result.defaultlab
        , frmIsadenabled: result.isadenabled
        , frmOrganizationdescription: result.organizationdescription
        , frmOrgtype: result.orgtype
        , frmInvoicecode: result.invoicecode
        , frmGolivedate: result.golivedate ? formatDate(new Date(result.golivedate), 'yyyy-MM-dd', 'en_US') : null
        , frmReleaseversion: result.releaseversion
        , frmCustomerstatus: result.customerstatus
        , frmIscustomer: result.iscustomer
        , frmOrganizationcode: result.organizationcode
        , frmStatedisplayname: result.statedisplayname
        , frmJarlabeltemplate: result.jarlabeltemplate
        , frmStorageid: result.storageid
        , frmArchivedtilldate: result.archivedtilldate ? formatDate(new Date(result.archivedtilldate), 'yyyy-MM-dd', 'en_US') : null
        , frmExternalpracticeguid: result.externalpracticeguid
        , frmOrgversion: result.orgversion
        , frmSourceguid: result.sourceguid
        , frmUrltype: result.urltype
        , frmTaxonomycode: result.taxonomycode
        , frmSendoutorderholdduration: result.sendoutorderholdduration
        , frmCollectiondateformat: result.collectiondateformat
        , frmReceiveddateformat: result.receiveddateformat
        , frmPackagedateformat: result.packagedateformat
        , frmEtadateformat: result.etadateformat
        , frmSupportid: result.supportid
        , frmTemplateorganizationid: result.templateorganizationid
        , frmSpawnholdduration: result.spawnholdduration
        , frmLastarchiveddate: result.lastarchiveddate ? formatDate(new Date(result.lastarchiveddate), 'yyyy-MM-dd', 'en_US') : null
      })
      this.selectedRepresentatives = structuredClone(this.selectedRepresentativesClone);
    }
    catch(error){
      console.error(error);
    }
    finally{
      this.ngxService.stop('patchData');
    }
  }

  async getAccountType() {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getassociationtypeforaccid');
    let Qstr = "";
    Qstr += "accountid = \"" + this.VitalHttpServices['OrganizationData'].accountId?.toString() + "\"";
    queryVariable = { "accid": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, this.VitalHttpServices.deploymentKey).toPromise().then(async res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          let orgType = (res.data.vwGetAssociationtypeByOrgID_list && res.data.vwGetAssociationtypeByOrgID_list.length > 0) ? res.data.vwGetAssociationtypeByOrgID_list[0].associationtype : 'lab';
          this.orgType = orgType?.toString().match(/facility/i) ? 'facility' : orgType
          this.hideGoLiveInfo = this.orgType == 'facility' ? false : true;
          await this.getOFLocationOnOrgType(this.orgType);
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  async getOFLocationOnOrgType(orgType: string) {
    this.OrganizationInput.OrgType = orgType;
    this.OrganizationInput.OFAccountId = this.VitalHttpServices['OrganizationData'].accountId;
    this.OrganizationInput.OrganizationId = this.commonService.getOrgid();
    await this.VitalHttpServices.GetOFLocationOnOrgtype(this.OrganizationInput, this.VitalHttpServices.deploymentKey).toPromise().then(res => {
      if (res.status == 'Success') {
        this.patientPortalLocList = (res.content && res.content.length > 0) ? res.content : [];
      }
    }, (error) => {
      console.error(error);
    })
  }

  async getOFPhysicians(accountid) {
    this.OrganizationInput.OFAccountId = accountid;
    this.OrganizationInput.OrganizationId = this.commonService.getOrgid();
    this.OrgFormGrp.patchValue({ frmPatientportalphysicianid: null });
    this.patientPortalPhysicianList = [];
    this.ngxService.start('getOFPhysicians');
    await this.VitalHttpServices.GetOFPhysician(this.OrganizationInput, this.VitalHttpServices.deploymentKey).toPromise().then(res => {
      this.ngxService.stop('getOFPhysicians');
      if (res.status == 'Success') {
        this.patientPortalPhysicianList = (res.content && res.content.length > 0) ? res.content : [];
      }
    }, (error) => {
      this.ngxService.stop('getOFPhysicians');
      console.error(error);
    })
  }

  isTestEnabled() {
    if (this.orgType != 'facility') {
      if (this.OrgFormGrp.value.frmIsorgsetuppending || this.OrgFormGrp.value.frmIstestorganization) {
        if (this.unRevisedData.iscustomer || this.unRevisedData.customerstatus || this.unRevisedData.releaseversion
          || this.unRevisedData.golivedate || this.unRevisedData.invoicecode || this.unRevisedData.orgtype || this.unRevisedData.organizationdescription) {
          if (!this.isBtnDisabled) {
            this.ref = this._dailogService.openLabadminConfimationdialog(
              '',
              `If you enable this flag, configured data from Go Live Information section will be lost. Do you wish to continue?`,
              'OK',
              'Cancel'
            );
          }
          this.ref.afterOpened().subscribe((res) => {
            this.isBtnDisabled = true;
          })
          this.ref.afterClosed().subscribe((res) => {
            this.isBtnDisabled = false;

            if (!res) {
              this.hideGoLiveInfo = false;
              this.OrgFormGrp.patchValue({
                frmIsorgsetuppending: false
                , frmIstestorganization: false
              });
            }
            else {
              this.hideGoLiveInfo = true;
              this.OrgFormGrp.patchValue({
                frmCustomerstatus: ''
                , frmIscustomer: false
                , frmReleaseversion: ''
                , frmGolivedate: ''
                , frmInvoicecode: ''
                , frmOrgtype: ''
                , frmOrganizationdescription: ''
              });
            }
          });
        } else {
          this.hideGoLiveInfo = true;
          this.OrgFormGrp.patchValue({
            frmCustomerstatus: ''
            , frmIscustomer: false
            , frmReleaseversion: ''
            , frmGolivedate: ''
            , frmInvoicecode: ''
            , frmOrgtype: ''
            , frmOrganizationdescription: ''
          });
        }
      } else {
        this.hideGoLiveInfo = false;
        this.OrgFormGrp.patchValue({
          frmCustomerstatus: this.unRevisedData.customerstatus ?? ''
          , frmReleaseversion: this.unRevisedData.releaseversion ?? ''
          , frmIscustomer: this.unRevisedData.iscustomer ? true : false
          , frmGolivedate: this.unRevisedData.golivedate ? formatDate(new Date(this.unRevisedData.golivedate), 'yyyy-MM-dd', 'en_US') : null
          , frmInvoicecode: this.unRevisedData.invoicecode ?? ''
          , frmOrgtype: this.unRevisedData.orgtype ?? ''
          , frmOrganizationdescription: this.unRevisedData.organizationdescription ?? ''
        });
      }
    }
  }

  async refresh() {
    try {
      let contextType = sessionStorage.getItem('search_context');

      let queryVariable = { Orgid: this.commonService.getOrgid()?.toString() }
      let dbName = sessionStorage.getItem('deploymentKey');
      let query = this.SubMenuCardModel.GetQuery(contextType);
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      let data = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise();
      if (data) {
        if (!data.errors) {
          if (data) {
            let obj = data.data.Card.find(x => x.Org_ID == this.commonService.getOrgid());
            let tabData = this.tabService.tabs.findIndex(va => va.tabData.mainCard.Org_ID === obj.Org_ID);
            if (tabData !== -1) {
              let accountType = this.commonService.isLiveAccount == true ? "Live" : "Test"
              delete obj['Group_ID']
              // delete obj['Org_ID']
              delete obj['Group_Code']
              obj['Group_Type'] = this.OrganizationInput.OrgType?.toString().toLowerCase() == 'both' ? ('POL' + ' (' + accountType + ')') :
                contextType.charAt(0).toUpperCase() + contextType.slice(1) + ' (' + accountType + ')'
              this.tabService.tabs[tabData].tabData.mainCard = obj
            }
          }
        }
      }
    } catch (exception) {
      this._snackBar.open("Error occurred while processing your request", "Close");
      console.error(exception);
    }
  }

  organizationDetails(newObj, oldobject) {
    let changedAttrValues = {}
    let jsonFinalData = {}
    for (let [key, value] of Object.entries(this.commonService.compare(newObj, oldobject))) {
      changedAttrValues['oldValue'] = (oldobject[key] != null) ? oldobject[key].toString() : "";
      changedAttrValues['newValue'] = (value != null) ? value.toString() : "";
      jsonFinalData[key] = {
        oldValue: changedAttrValues['oldValue'],
        newValue: changedAttrValues['newValue']
      };
      changedAttrValues = {};
    }
    return jsonFinalData;
  }

  representativeDetails(selectedRepresentatives, selectedRepresentativesClone) {
    let jsonFinalData = { "Associated": [], "De-Associated": [], "New Representative": [] }
    const toggleChanged = this.selectedRepresentatives.filter(item1 => {
      const cloneItem = this.selectedRepresentativesClone.find(item2 =>
        item2.RepresentativeId === item1.RepresentativeId
      );
      return cloneItem && cloneItem.IsAssociationActive !== item1.IsAssociationActive;
    });

    const newuser = selectedRepresentatives.filter(rep =>
      !selectedRepresentativesClone.some(clone => clone.RepresentativeId === rep.RepresentativeId)
    );

    // Function to create representative data structure
    const createRepData = (rep) => ({
      "Representative Name": { oldValue: rep.RepresentativeName, newValue: rep.RepresentativeName },
      "Role Name": { oldValue: rep.RoleName, newValue: rep.RoleName },
      "Employee Id": { oldValue: rep.EmployeeId, newValue: rep.EmployeeId }
    });

    toggleChanged?.forEach((rep: any) => {
      {
        // Process toggled representatives
          const repData = createRepData(rep);
          if (rep.IsAssociationActive) {
            jsonFinalData["Associated"].push(repData);
          } else {
            jsonFinalData["De-Associated"].push(repData);
          }
      }
    });

    newuser?.forEach((rep: any) => {
      jsonFinalData["New Representative"].push({
        "Representative Name": {
          oldValue: '',
          newValue: rep.RepresentativeName
        },
        "Role Name": {
          oldValue: '',
          newValue: rep.RoleName
        },
        "Employee Id": {
          oldValue: '',
          newValue: rep.EmployeeId
        }
      }
      );
    });

    // Remove keys with empty arrays
    Object.keys(jsonFinalData).forEach(key => {
      if (jsonFinalData[key].length === 0) {
        delete jsonFinalData[key];
      }
    });
    return jsonFinalData;
  }


  auditData(newObj) {
    newObj = this.getAuditObj(newObj);
    this.unRevisedData = this.getAuditObj(this.unRevisedData);
    let vitalAxisjsondata = this.organizationDetails(newObj, this.unRevisedData);
    let repCardData = this.representativeDetails(this.selectedRepresentatives, this.selectedRepresentativesClone);
    vitalAxisjsondata = { ...vitalAxisjsondata, ...repCardData };
    let templateObj = { menuURL: "Group Details" }
    let sessionId = this.commonService.generateGuid();
    this.commonService.createActivityObject(newObj["organizationid"], newObj["organizationname"], templateObj?.menuURL, 'Edit', '', '', sessionId, this.auditableColumns, '', vitalAxisjsondata);
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      if (res.content) {
        this.auditableColumns = JSON.parse(res.content.JsonData);
      }
    }, error => {
      console.error(error);
    })
  }

  isDisabledExtGUID() {
    if (!((this.OrgFormGrp.controls.frmIscustomer.value) && (this.OrgFormGrp.controls.frmInvoicecode.value?.toString().trim()))) {
      this.OrgFormGrp.patchValue({ frmExternalorgguid: this.unRevisedData?.externalorgguid?.toUpperCase() ?? '' })
    }
    return !((this.OrgFormGrp.controls.frmIscustomer.value) && (this.OrgFormGrp.controls.frmInvoicecode.value?.toString().trim()))
  }

  isValidGUID(guid) {
    var guidRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
    // Check if the provided guid matches the regex
    return guidRegex.test(guid);
  }

  validateEmail(Email) {
    if (Email && Email?.toString().trim() != '') {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return reg.test(Email) ? true : false;
    }
    return true;
  }

  //#region to return value
  encodeUsernameFormat(nameDisplayFormat) {
    switch (nameDisplayFormat) {
      case 'Mr. Lastname, Firstname': return 'S.L,F';
      case 'Lastname, Firstname Ini.': return 'L,F,I';
      case 'Firstname, Lastname': return 'F,L';
      case 'Mr. Firstname, Lastname': return 'S.F,L';
      case 'Firstname, Lastname MBBS': return 'F,L,D';
      case 'Mr. Lastname, Firstname MBBS': return 'S.L,F,D';
      case 'Mr. Firstname, Lastname MBBS': return 'S.F,L,D';
      case 'Lastname, Firstname': return 'L,F';
      case 'Mr. Lastname, Firstname Ini.': return 'S.L,F,I';
      default: nameDisplayFormat
    }
  }
  //#endregion

  decodeUsernameFormat(nameDisplayFormat) {
    switch (nameDisplayFormat) {
      case 'S.L,F': return 'Mr. Lastname, Firstname';
      case 'L,F,I': return 'Lastname, Firstname Ini.';
      case 'F,L': return 'Firstname, Lastname';
      case 'S.F,L': return 'Mr. Firstname, Lastname';
      case 'F,L,D': return 'Firstname, Lastname MBBS';
      case 'S.L,F,D': return 'Mr. Lastname, Firstname MBBS';
      case 'S.F,L,D': return 'Mr. Firstname, Lastname MBBS';
      case 'L,F': return 'Lastname, Firstname';
      case 'S.L,F,I': return 'Mr. Lastname, Firstname Ini.';
      default: nameDisplayFormat
    }
  }

  //#region converting date to mm/dd/yyyy
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/')
  }
  //#endregion

  getAuditObj(inputObj) {
    let valObj = inputObj['patientportalaccountid'] ? this.patientPortalLocList.find(x => x.accountid == inputObj['patientportalaccountid']) : null
    inputObj['patientportalaccount'] = valObj ? valObj.accountname : null
    valObj = inputObj['patientportalphysicianid'] ? this.patientPortalPhysicianList.find(x => x.userid == inputObj['patientportalphysicianid']) : null
    inputObj['patientportalphysician'] = valObj ? valObj.physicianname : null

    return {
      "organizationid": inputObj.organizationid?.toString()
      , "organizationname": inputObj.organizationname ? inputObj.organizationname : null
      , "adtenabled": inputObj.adtenabled ? 'Yes' : 'No'
      , "usernamedisplayformat": inputObj.usernamedisplayformat ? this.shortToFullFormats[inputObj.usernamedisplayformat] : null
      , "usernamereportformat": inputObj.usernamereportformat ? this.shortToFullFormats[inputObj.usernamereportformat] : null
      , "state": inputObj.state ? inputObj.state : null
      , "zip": inputObj.zip ? inputObj.zip.toString() : null
      , "status": (inputObj.status?.toString().toLowerCase() == 'active') ? 'Active' : 'Inactive'
      , "ein": inputObj.ein ? inputObj.ein.toString() : null
      , "statelic": inputObj.statelic ? inputObj.statelic.toString() : null
      , "npi": inputObj.npi ? inputObj.npi.toString() : null
      , "taxonomycode": inputObj.taxonomycode ? inputObj.taxonomycode.toString() : null
      , "url": inputObj.url ? inputObj.url.toString() : null
      , "supportid": inputObj.supportid ? inputObj.supportid.toString() : null
      , "phone": inputObj.phone ? inputObj.phone.toString() : null
      , "fax": inputObj.fax ? inputObj.fax.toString() : null
      , "mnemonic": inputObj.mnemonic ? inputObj.mnemonic.toString() : null
      , "address1": inputObj.address1 ? inputObj.address1.toString() : null
      , "address2": inputObj.address2 ? inputObj.address2.toString() : null
      , "city": inputObj.city ? inputObj.city.toString() : null
      , "country": inputObj.country ? inputObj.country.toString() : null
      , "jarlabeltemplate": inputObj.jarlabeltemplate ? inputObj.jarlabeltemplate.toString() : null
      , "organizationcode": inputObj.organizationcode ? inputObj.organizationcode.toString() : null
      , "billingphonenumber": inputObj.billingphonenumber ? inputObj.billingphonenumber.toString() : null
      , "clia": inputObj.clia ? inputObj.clia.toString() : null
      , "templateorganizationid": inputObj.templateorganizationid ? inputObj.templateorganizationid.toString() : null
      , "holdreleaseduration": inputObj.holdreleaseduration ? inputObj.holdreleaseduration.toString() : null
      , "coverpagetemplate": inputObj.coverpagetemplate ? inputObj.coverpagetemplate.toString() : null
      , "loadbydefault": inputObj.loadbydefault ? 'Yes' : 'No'
      , "showreviewpathsignature": inputObj.showreviewpathsignature ? 'Yes' : 'No'
      , "autopopulatediagicd9codes": inputObj.autopopulatediagicd9codes ? 'Yes' : 'No'
      , "collectiondateformat": inputObj.collectiondateformat ? inputObj.collectiondateformat : null
      , "receiveddateformat": inputObj.receiveddateformat ? inputObj.receiveddateformat : null
      , "packagedateformat": inputObj.packagedateformat ? inputObj.packagedateformat : null
      , "etadateformat": inputObj.etadateformat ? inputObj.etadateformat : null
      , "spawnholdduration": inputObj.spawnholdduration ? inputObj.spawnholdduration : null
      , "sendoutdisclaimer": inputObj.sendoutdisclaimer ? 'Yes' : 'No'
      , "sendoutorderholdduration": inputObj.sendoutorderholdduration ? inputObj.sendoutorderholdduration : null
      , "externalpracticeguid": inputObj.externalpracticeguid ? inputObj.externalpracticeguid : null
      , "lastarchiveddate": inputObj.lastarchiveddate ? this.convertDate(inputObj.lastarchiveddate) : null
      , "archivedtilldate": inputObj.archivedtilldate ? this.convertDate(inputObj.archivedtilldate) : null
      , "isofallowfullfilltests": inputObj.isofallowfullfilltests ? 'Yes' : 'No'
      , "diagicdcodereminder": inputObj.diagicdcodereminder ? 'Yes' : 'No'
      , "iscustomer": inputObj.iscustomer ? 'Yes' : 'No'
      , "invoicecode": inputObj.invoicecode ? inputObj.invoicecode : null
      , "customerstatus": inputObj.customerstatus ? inputObj.customerstatus : null
      , "statedisplayname": inputObj.statedisplayname ? inputObj.statedisplayname : null
      , "externalorgguid": inputObj.externalorgguid ? inputObj.externalorgguid : null
      , "golivedate": inputObj.golivedate ? this.convertDate(inputObj.golivedate) : null
      , "isorgsetuppending": inputObj.isorgsetuppending ? 'Yes' : 'No'
      , "defaultlab": inputObj.defaultlab ? 'Yes' : 'No'
      , "organizationdescription": inputObj.organizationdescription ? inputObj.organizationdescription : null
      , "releaseversion": inputObj.releaseversion ? inputObj.releaseversion : null
      , "orgtype": inputObj.orgtype ? inputObj.orgtype : null
      , "labgroupid": inputObj.labgroupid ? inputObj.labgroupid : null
      , "timezone": inputObj.timezone ? inputObj.timezone : null
      , "isvitalordersenabled": inputObj.isvitalordersenabled ? 'Yes' : 'No'
      , "batchingmnemonic": inputObj.batchingmnemonic ? inputObj.batchingmnemonic : null
      , "referencelab": inputObj.referencelab ? inputObj.referencelab : null
      , "showonlyaccountdetails": inputObj.showonlyaccountdetails ? 'Yes' : 'No'
      , "patientportalurl": inputObj.patientportalurl ? inputObj.patientportalurl : null
      , "email": inputObj.email ? inputObj.email : null
      , "urltype": inputObj.urltype ? inputObj.urltype : null
      , "patientportalaccount": inputObj.patientportalaccount ? inputObj.patientportalaccount : null
      , "patientportalphysician": inputObj.patientportalphysician ? inputObj.patientportalphysician : null
      , "isadenabled": inputObj.isadenabled ? 'Yes' : 'No'
      , "storageid": inputObj.storageid ? inputObj.storageid.toString() : null
      , "logintype": inputObj.logintype ? inputObj.logintype.toString() : null
      , "appurl": inputObj.appurl ? inputObj.appurl.toString() : null
      , "vourl": inputObj.vourl ? inputObj.vourl.toString() : null
      , "uniqueid": inputObj.uniqueid ? inputObj.uniqueid.toString() : null
      , "orgversion": inputObj.orgversion ? inputObj.orgversion.toString() : null
      , "adminkey": inputObj.adminkey ? inputObj.adminkey.toString() : null
      , "istestorganization": inputObj.istestorganization ? 'Yes' : 'No'
      , "sourceguid": inputObj.sourceguid ? inputObj.sourceguid.toString() : null
    }
  }

  // VitalAxis Representatives
  async getListOfRepresentiveUsers() {
    let query = this.vaservices?.GetQuery('vitalaxisrepresentative');
    let queryVariable: any;

    queryVariable = {
      "IsActive": true
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();

    await this.vaservices.GetData(queryResult, 'configdb').subscribe(res => {
      if (res.data.submenuData) {
        this.representativeUsersList = [...res.data.submenuData];
        this.getListOfRolesUsers();
      } else {
        this.representativeUsersList = [];
      }
      // this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  async getListOfRolesUsers() {
    this.ngxService.start();
    let query = this.vaservices.GetQuery('CategoryDropDown');
    let queryVariable = { context: 'VitalAxisRepresentative', Keyword: 'RepresentativeRole' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vaservices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
        this.representativeRolesList = data;
        this.selectedRepresentatives = this.commonService?.selectedRepresentatives;
        this.selectedRepresentativesClone = structuredClone(this.selectedRepresentatives);
      } else {
        this.ngxService.stop();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  selectedUsersFromFrameWork($event: any) {
    this.selectedRepresentatives = $event;
  }

}
