import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from "@angular/platform-browser";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-accountlogoupload',
  templateUrl: 'AccountLogoUpload.component.html',
  styleUrls: ['./AccountLogoUpload.component.scss']
})

export class AccountLogoUploadComponent implements OnChanges {
  @Input()
  templateData: any;
  @Input()
  mainCard: any;
  sign_UploadSRC = "";
  files: File[] = [];
  logoPath: boolean = true;
  imageSrc: string;
  filesData: any;
  filelist: any[];
  newLogoSrc: any;

  public hideUploadBtn: boolean = true;
  public hideRemoveBtn: boolean = true;
  // public actionButtonDetails: any[];
  public noLogo = true;
  public boolValue: boolean = true;

  constructor(private httpClient: HttpClient, private DomSanitizer: DomSanitizer, public _snackbar: MatSnackBar,
    private vitalHttpServices: VitalHttpServices, private dialog: MatDialog, public ngxService: NgxUiLoaderService, public DataShare: DataShareService) {
  }

  ngOnInit() {
    this.getexistinglogo();
    this.GetButtondetails();
  }

  ngOnChanges() {
  }

  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe(res => {
    //   if (!res.errors) {
    //     // this.actionButtonDetails = res;
    //     this.GetButtonAccess(res);
    //   }
    // });
  }

  GetButtonAccess(actionButtonDetails) {
    // if (sessionStorage.getItem('search_context').toLowerCase() == 'casetypetemplate' || sessionStorage.getItem('search_context').toLowerCase() == 'Homepage') {
    //   for (var i = 0; i < actionButtonDetails.length; i++) {
    //     if (actionButtonDetails[i].Button === "Upload") {
    //       this.hideUploadBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
    //     }
    //     else if (actionButtonDetails[i].Button === "Remove") {
    //       this.hideRemoveBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
    //     }
    //   }
    // }
    // else
    // {
    //   for (var i = 0; i < actionButtonDetails.length; i++) {
    //     if (actionButtonDetails[i].Button === "Upload") {
    //       this.hideUploadBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
    //     }
    //     else if (actionButtonDetails[i].Button === "Remove") {
    //       this.hideRemoveBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
    //     }
    //   }
    // }
    this.hideUploadBtn = false;
    this.hideRemoveBtn = false;
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  prepareFilesList(event) {
    let reader = new FileReader();
    reader.onload = function () {
      let output: any = document.getElementById('blah');
      output.src = reader.result;
    }
    this.logoPath = false;
    if (event[0]) {
      reader.readAsDataURL(event[0]);
    }
  }

  getexistinglogo() {
    let data = {
      accid: this.templateData.submenuData[0].accountid
    };
    this.vitalHttpServices.GetLogoFile(data).subscribe(res => {
      this.ngxService.start();
      if (res.Success) {
        if (res.Message != undefined && res.Message != "" && res.Message != null) {
          this.imageSrc = "data:image/jpg;base64," + res.Message;
          this.noLogo = false;
          this.logoPath = true;
        }
        else {
          this.imageSrc = "";
          this.noLogo = true;
        }
      }
      this.ngxService.stop();
    },error => {
        console.error(error);
        this.noLogo = true;
        this._snackbar.open("An error occurred while processing your request", "Failed");
      })
  }


  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);

  }

  onFileChange(event) {
    this.onRemove(event);
    // let tempArrData: any = event.addedFiles[0];
    let tempArrData: any
    if (event.target) {
      tempArrData = event.target.files[0];
    } else {
      tempArrData = event[0];
    }
    if (tempArrData.type.toString().toLowerCase() == "image/png" || tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
      event.addedFiles = [];
      event.addedFiles.push(tempArrData);
      this.files.push(...event.addedFiles);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logo_Upload] = this.files;
        reader.readAsDataURL(logo_Upload);
        reader.onload = () => {
          this.newLogoSrc = reader.result as string;
          this.logoPath = false;
        };
      } else {
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    } else {
      this._snackbar.open("Please upload image file only", "Failed");
    }
  }

  toDataURL = async (url) => {
    var res = await fetch(url);
    var blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };

  getLoggedInUserID() {
    //get logged-in user id
    let loggedIn_userID = sessionStorage.getItem('Userid');
    // for (let m = 0; m < environment.Vitaldxuserid.length; m++) {
    //   let env_depKey = environment.Vitaldxuserid[m];
    //   let env_depKeyName = env_depKey.substring(0, env_depKey.indexOf('-'));
    //   let  deploymentKey = this.vitalHttpServices.deploymentKey
    //   if ( deploymentKey.toLowerCase() == env_depKeyName.toLowerCase()) {
    //     loggedIn_userID = env_depKey.substring(env_depKey.indexOf('-') + 1);
    //     break;
    //   }
    // }
    return loggedIn_userID;
  }

  removeNewLogo() {
    this.newLogoSrc = "";
    this.logoPath = true;
  }

  removeExistingLogo() {
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '250px',
      data: { header: "Remove Logo", message: "Are you sure you want to remove the logo?", alert: "", continue: "yes", cancel: "no" }
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.ngxService.start();
        let loggedIn_userID: any = this.getLoggedInUserID();
        let data = {
          AccountID: this.templateData.submenuData[0].accountid.toString(),
          userObjectId: loggedIn_userID
        };
        this.vitalHttpServices.RemoveLogo(data).subscribe(res => {
          if (res.Success) {
            this._snackbar.open(res.Message, "Success");
            this.getexistinglogo();
            this.onRemove(event);
          } else {
            this._snackbar.open(res.Message, "Failed");
          }
          this.ngxService.stop();
        },error => {
            console.error(error);
            this._snackbar.open("An error occurred while processing your request", "Failed");
          });
      }
    })
  }

  async ConfirmUploadLogo() {
    if (this.newLogoSrc !== "") {
      this.ngxService.start();
      //get logged-in user id
      let loggedIn_userID: any = this.getLoggedInUserID();
      let imageSrcString = await this.toDataURL(this.newLogoSrc)
      imageSrcString = imageSrcString.toString().split(",")[1];
      let data = {
        Logo: imageSrcString,
        AccountID: this.templateData.submenuData[0].accountid.toString(),
        userObjectId: loggedIn_userID
      };
      this.vitalHttpServices.ConfirmLogoUpload(data).subscribe(res => {
        if (res.Success) {
          this._snackbar.open(res.Message, "Success");
          this.getexistinglogo();
          this.onRemove(event);
        } else {
          this._snackbar.open(res.Message, "Failed");
        }
        this.ngxService.stop();
      },error => {
          console.error(error);
          this._snackbar.open("An error occurred while processing your request", "Failed");
        })
    }
  }
}
