import { Component, Input, OnInit } from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CollectionView } from '@grapecity/wijmo';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { take } from 'rxjs';
import { Store } from '@ngrx/store';



@Component({
  selector: 'app-manage-custom-fields',
  templateUrl: './manage-custom-fields.component.html',
  styleUrls: ['./manage-custom-fields.component.scss']
})
export class ManageCustomFieldsComponent implements OnInit {
  isDataLoading: boolean = false;
  @Input() templateData: any;
  CreateForm: FormGroup;
  CustomFieldsGriddata: wjcCore.CollectionView<any>;
  subMenuCardModel: SubMenuCardModel;
  listedGridHeader: any;
  gridheader: string[];
  gridHeaderList: any;
  gridShow: boolean = true;
  createBtn: boolean = false;
  uploadBtn:boolean=false;
  exportBtn: boolean = false;
  deleteBtn: boolean = false;
  deploymentkey: string = '';
  accountid: string = '';
  orgid: string = '';
  destDeployment: string = '';
  create: boolean = false;
  savedata: any;
  auditableColumns: any;
  dbName: string;
  activityEntity: {};
  userSessionDetails: any;
  sourceApp: string;
  organizationid: string = '';
  uploadbtnClicked: boolean=false;
  cardContext: string = 'fromAccount'
  exportExcelFields = {
    Attribute: { displayName: "Attribute", isDate: false }
  }

  constructor(
    private ngxService: NgxUiLoaderService,
    public templateHeaders: TemplateHeadersService,
    private _snackbar: MatSnackBar,
    private commonService: CommonService,
    private datashare: DataShareService,
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private activityService: ActivityTrackerService,
    public labAdminService: LabadminService,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>,


    private vitalHttpServices: VitalHttpServices,
  ) {
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);

  }

  async ngOnInit() {
    // this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    //     this.accountid = sessionStorage.getItem('Locationid');
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    let _this=this
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      _this.sourceApp = 'VitalDx';
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async (session: any) => {
        await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
          let menuObj = result[result.length - 1];
          if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
            _this.userSessionDetails = session.userDetails
            await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
            _this.templateData = _this.labAdminService.templateData;
            _this.dbName = _this.labAdminService.deploymentKey;
            this.deploymentkey= _this.dbName;
            _this.organizationid = _this.labAdminService.organizationId;
            // this.accountid=this.labAdminService.accountId;
            sessionStorage.setItem('deploymentKey', _this.dbName);
            await _this.getData();
            _this.GetButtonAccess(_this.labAdminService.subMenuPermissions);
            _this.activityService.setActivitySession({ 'entityId': '', 'entityType': _this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
            _this.activityService.getActivitySession.subscribe(res => _this.activityEntity = res);
            _this.activityService.setActivitySession(_this.activityEntity);
            await _this.getAuditableDetails(_this.templateData.menuURL);
          }
        })
      })
    }
    //  this.getData();
    //  this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    //  this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    //  this.activityService.setActivitySession(this.activityEntity);
    //  this.getAuditableDetails(this.templateData.menuURL);
    //  console.log(this.auditableColumns);
  }

  getData() {
    let query = this.subMenuCardModel.GetQuery('getcustomfields');
    let queryVariable = {
      orgid: this.labAdminService.organizationId
      // orgid:this.orgid = sessionStorage.getItem('org_id')
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.isDataLoading = true;
    this.vitalHttpServices.GetData(queryResult,this.dbName).subscribe(
      data => {
        this.isDataLoading = false;
        if (!data.errors) {
          let templateData = data.data.submenuData;
          if (templateData.length > 0) {
            this.addDataToGrid(templateData);
          }
        }
      },
      error => {
        this.isDataLoading = false;
        console.error(error);
      }
    );
  }

  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName === 'Custom Fields') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
      }
    }
    this.gridheader = ['Attribute', 'Caid'];
    let gridarray = []
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j]) {
                if (value == null) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
      }
      this.CustomFieldsGriddata = new CollectionView(gridarray)
    }
  }

  createCustomFields() {
    this.create = true;
    this.createNewForm();

  }

  createNewForm() {
    this.CreateForm = this._fb.group({
      fieldname: ['', Validators.required]
    });
  }

  get getters() {
    return this.CreateForm.controls;
  }

  return() {
    this.gridShow = true;
    this.create = false;
  }

  refreshGrid() {
    this.CreateForm?.reset();
    this.getData();
    this.gridShow = true;
    this.create = false;
  }

  saveCustomField() {
    this.savedata = this.CreateForm.controls;
    if (!this.savedata.fieldname.value || this.savedata.fieldname.value.toString().trim() == '') {
      this._snackbar.open('Please provide field name', 'Close')
      return;
    }
    else {
      var saveobj = {
        "orgid": this.labAdminService.organizationId,
        // "orgid":this.orgid = sessionStorage.getItem('org_id'),
        "attribute": this.savedata.fieldname.value,
        "attributetype": 'Ordering Facility'
      }
      let tempobj: any;
      tempobj = saveobj;
      if (this.templateData.submenuData &&
        this.templateData.submenuData.some(va => va.Attribute === this.savedata.fieldname.value)) {
        this._snackbar.open('Field Name already exists', 'Close');
        return;
      }
      else {
        this.isDataLoading = true;
        this.vitalHttpServices.createCustomFields(tempobj, this.deploymentkey).subscribe(
          result => {
            this.isDataLoading = false;
            if (!result.errors) {
              this._snackbar.open('Custom Field Created successfully', 'Close');
              let activityTrackerObj = []
              activityTrackerObj = tempobj;
              this.commonService.auditDetails('OrgId', 'attribute', [result.content], [activityTrackerObj], "Create", this.templateData, this.auditableColumns);
              this.create = false;
            }
            this.refreshGrid();
          },
          error => {
            this.isDataLoading = false;
            console.error(error);
          }
        );
      }
    }
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = (JSON.parse(res.content.JsonData));
    })
  }

  deleteData(object: any) {
    let obj = {
      "caid": object.rowData.Caid
    }
    let query = this.subMenuCardModel.GetQuery('getcustomvalues');
    let queryVariable = {
      orgid: this.labAdminService.organizationId
      // orgid:this.orgid = sessionStorage.getItem('org_id')
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.isDataLoading = true;
    this.vitalHttpServices.GetData(queryResult,this.dbName).subscribe(
      data => {
        this.isDataLoading = false;
        if (!data.errors) {
          if(data.data.submenuData.some(va=> va.Attribute === object.rowData.Attribute && va.Caid === object.rowData.Caid)) {
            let dialogRef = this.dialog.open(ConfirmLabadminComponent, { //popup
              disableClose: true,
              autoFocus: false,
              width: '500px',
              data: { header: "", message: "Delete Custom Field", alert: "There are values configure for this attribute. Are you sure you want to delete?", continue: "Delete", cancel: "Cancel" }
            });
            return dialogRef.afterClosed().toPromise().then((result) => {
              if (result) {
                this.isDataLoading = true;
                this.vitalHttpServices.DeleteCustomFields(obj, this.deploymentkey).subscribe(
                  result => {
                    obj["attribute"] = object.rowData.Attribute;
                    obj["caid"] = object.rowData.Caid;
                    if (!result.errors) {
                      this.commonService.auditDetails('caid', 'attribute', [obj], [{}], 'Delete', this.templateData, this.auditableColumns);
                      this._snackbar.open('Custom Field Deleted successfully', 'Close');
                      this.isDataLoading = false;
                    }
                    this.refreshGrid();
                  },
                  error => {
                    this.isDataLoading = false;
                    console.error(error);
                  }
                );
              }
            });
          }
          else {
            let dialogRef = this.dialog.open(ConfirmLabadminComponent, { //popup
              disableClose: true,
              autoFocus: false,
              width: '500px',
              data: { header: "", message: "Delete Custom Field", alert: "This field will not appear under the facilities associated to this lab. Are you sure you want to delete?", continue: "Delete", cancel: "Cancel" }
            });
            return dialogRef.afterClosed().toPromise().then((result) => {
              if (result) {
                this.isDataLoading = true;
                this.vitalHttpServices.DeleteCustomFields(obj, this.deploymentkey).subscribe(
                  result => {
                    obj["attribute"] = object.rowData.Attribute;
                    obj["caid"] = object.rowData.Caid;
                    if (!result.errors) {
                      this.commonService.auditDetails('caid', 'attribute', [obj], [{}], 'Delete', this.templateData, this.auditableColumns);
                      this._snackbar.open('Custom Field Deleted successfully', 'Close');
                      this.isDataLoading = false;
                    }
                    this.refreshGrid();
                  },
                  error => {
                    this.isDataLoading = false;
                    console.error(error);
                  }
                );
              }
            });
          }
          
        }
      },
      error => {
        this.isDataLoading = false;
        console.error(error);
      }
    );
  }


  uploadCustomFields(){
   this.uploadbtnClicked=true;
   this.gridShow=false;
  }



  backClicked(event) {
    this.gridShow = true;
    this.uploadbtnClicked = false;
    this.refreshGrid();
  }


  private GetButtonAccess(actionButtonDetails: any) {
    let seletedMenuPermissions
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext  == this.templateData.menuURL)["ActionButton"]
    }
    // else {
    //   seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    // }
    // let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (const element of seletedMenuPermissions) {
      switch (element.Button) {
        case "Create":
          this.createBtn = element.IsPermitted === "true"  ? false : true;
          break;
        case "Upload":
          this.uploadBtn = element.IsPermitted === "true" ?   false : true;
          break;
        case "Delete":
          this.deleteBtn = element.IsPermitted === "true"  ? false : true;
          break;
      }
    }
  }
}
