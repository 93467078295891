<!-- <mat-spinner class="labadmin-spinner" *ngIf="sourceApp && !copyDataClicked && !(gridShowDiv && (!viewPage && templateData))"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp && !copyDataClicked && !(gridShowDiv && (!viewPage && templateData))">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>
<div *ngIf="gridShowDiv">
    <div *ngIf="!hideGrid">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="modal-header admin-model-header mt-3">
                    <h3 class="modal-title w-100 admin-model-header-txt">
                        <strong>Grossing Templates </strong>
                    </h3>
                </div>
            </div>
        </div> -->
        <!--<wj-flex-grid [headersVisibility]="'Column'" class="mt-2" [isReadOnly]="true" [itemsSource]="gridData"
            [allowDragging]="'Both'" [frozenColumns]="1" (initialized)="initGrid(grid)" #grid>


            <div>
                <wj-flex-grid-column [header]="'Actions'" align="center" [freeze]="true" [width]="80" [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <button (click)="operationTrigger('edit',grid,$event)" class="edit-delete-btn" [disabled]="editBtn">
                            <em id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em>
                        </button>
                        <button (click)="operationTrigger('delete',grid,$event)" class="edit-delete-btn" [disabled]="removeBtn">
                            <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                        </button>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column *ngFor="let columnname of gridHeader" [binding]="columnname" [allowDragging]="false"
                    [allowSorting]="false" [header]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
                    [visible]="!(columnname == 'Template_ID') && !(columnname == 'CreatedBy') && !(columnname == 'CreatedDate') && !(columnname == 'ModifiedBy') && !(columnname == 'ModifiedDate')" [allowResizing]="true" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid> -->


        <app-templates-listing *ngIf="!viewPage && templateData" [gridHeader]="ListedGridHeader" [gridData]="gridData"
            [context]="'Grossing Templates'" [hideEdit]="hideUpdateBtn" [hideCreate]="hideCreateBtn"
            [hideUpload]="sourceApp =='VitalDx' ? true :  hideuploadbtn" [hideExport]="hideExportBtn" [hideCopy]="sourceApp =='VitalDx' ? true :  hideCopyToOrgBtn"
            [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel" [hideDelete]="hideDeleteBtn"
            [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
            (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
            (deleteTemplateClicked)="newDeleteMethod($event)" (closingListTemplate)="handleListingScreen($event)"
            (copyTemplateEventTriggered)="copyOrg()" (uploadTemplateEventTriggered)="BulkUpload()" [isAdmin3]="sourceApp =='VitalDx'? false : true"
            (emitFilters)="emitFilters($event)" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null"
            (sortingChanged)="getUpdatedGrid()" (sortCompleted)="createActivityTrackerForSort($event)" [enableorDisableSeqOnDefault]="true">
        </app-templates-listing>


        <!-- <div class="align-center mt-2">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div> -->
        <!-- <div *ngIf="!ViewScreen" class="col-12 align-center mt-4">
            <div class="col-sm-4 button-align">
                <button mat-raised-button class="admin-btn-success" (click)="ExportExcel(gridData)" [disabled]="hideExportBtn"
                    type="button">Export</button>
                <button mat-raised-button class="admin-btn-success" [disabled]="hideCreateBtn"
                    (click)="editAddScreen('Add',grid, event)" type="button">
                    Create
                </button>
                <button mat-raised-button class="admin-btn-success" title="Copy from other Entity" type="button"
                    [disabled]="hideuploadbtn" (click)="BulkUpload()">
                    Upload
                </button>
                <button mat-raised-button class="admin-btn-success" type="button" title="Copy from other Entity"
                    [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">
                    Copy
                </button>
            </div>
        </div> -->

    </div>
    <!-- <div *ngIf="hideGrid">
        <div class="row">
            <div class="col-sm-12">
                <div class="modal-header admin-model-header mt-3">
                    <h3 class="modal-title w-100 admin-model-header-txt" *ngIf="editAction">
                        <strong>Edit Grossing Template</strong>
                    </h3>
                    <h3 class="modal-title w-100 admin-model-header-txt" *ngIf="!editAction">
                        <strong>Create Grossing Template</strong>
                    </h3>
                </div>
                <form [formGroup]="GrossTemplateForm" class="mt-1 form-height">
                    <div class="row px-3">
                        <div class="col-sm-6">
                            <div class="row mt-3 col-sm-12 p-0">
                                <div class="label-font col-sm-4 text-xs mr-1">
                                    <label class="label-font">Name<span class="error-msg">*</span></label>
                                </div>
                                <div class="col-sm-7 text-xs">
                                    <input title="{{ editableData.Template }}" formControlName="frmTemplateName"
                                        type="text" value="{{ editableData.Template}}" maxlength="50"
                                        class="form-control form-font" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <section class="example-section col-sm-4 mb-3 pl-4">
                                    <mat-checkbox title="{{ editableData.Active }}" [color]="task.color" id="active"
                                        class="mt-3 example-margin" formControlName="frmActive">Active
                                    </mat-checkbox>
                                </section>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row mt-3">
                                <div class="label-font col-sm-4 text-xs pr-0 mr-1">
                                    <label class="label-font">Site</label>
                                </div>
                                <div class="col-sm-7 text-xs">
                                    <select title="{{ editableData.Site }}" formControlName="frmSite" type="text"
                                        value="{{ GrossTemplateForm.value.frmSite }}" maxlength="100"
                                        class="form-control form-font">
                                        <option *ngFor="let site of bodySites" value="{{site}}">{{site}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <section class="example-section col-sm-4 mb-3 pl-4">
                                    <mat-checkbox [color]="task.color" class="mt-3 example-margin"
                                        formControlName="frmDefault">Default
                                    </mat-checkbox>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div class="row px-3 mt-3 description-area">
                        <div class="label-font col-sm-2 text-xs">
                            <label class="label-font">Description<span class="error-msg">*</span></label>
                        </div>
                        <div class="col-sm-10 text-xs ">
                            <textarea rows="5" title="{{ GrossTemplateForm.value.frmDescription }}"
                                value="GrossTemplateForm.value.frmDescription" type="text" maxlength="1000"
                                formControlName="frmDescription" class="form-control form-font"
                                #textareaElement></textarea>
                        </div>
                    </div>
                    <div *ngIf="Placeholders.length > 0" class="row px-3 mt-3 description-area">
                        <div class="label-font col-sm-2 text-xs">
                            <label class="label-font">Placeholders</label>
                        </div>
                        <div class="col-sm-10 text-xs ">
                            <select maxlength="100" (change)="appendToTextarea($event)" formControlName="frmPlaceholder"
                                value="GrossTemplateForm.value.frmPlaceholder" class="form-control form-font">
                                <option value="">Select</option>
                                <option *ngFor="let placeholder of Placeholders" value="{{placeholder}}">
                                    {{placeholder}}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="Placeholders.length > 0" class="row px-3 description-area">
                        <div class="label-font col-sm-2 text-xs">
                        </div>
                        <div class="col-sm-10 text-xs ">
                            <p>Select a placeholder to add to Description</p>
                        </div>
                    </div>
                </form> -->
    <!-- <div class="row">
                    <button mat-button class="btn btn-info" (click)="hideGrid = false">Back</button>
                    <div class="col-sm-6 mx-auto mt-2">
                        <button class="float-left btn btn-sm-info curser-pointer" (click)="hideGrid = false">
                          Back
                        </button>
                      </div>
                    <button mat-button id="Update" class="btn btn-info" *ngIf="editAction" (click)="Addedittemplate('Update')">Update</button>
                    <button mat-button id="Add" class="btn btn-info" *ngIf="!editAction" (click)="Addedittemplate('Add')">Add</button>
                </div> -->
    <!-- <span class="col-sm-12 align-center mt-3">
                    <div class="col-sm-4 button-align">
                        <button mat-raised-button class="admin-btn-success primaryButtonColor" (click)="backToGrid()"
                            type="button">Back</button>
                        <button *ngIf="!editAction" mat-raised-button class="admin-btn-success primaryButtonColor"
                            [disabled]="GrossTemplateForm.value.frmTemplateName.trim() == '' || GrossTemplateForm.value.frmDescription.trim() == ''"
                            (click)="isdefaultadd()" type="button">
                            Save
                        </button>
                        <button *ngIf="editAction" mat-raised-button class="admin-btn-success primaryButtonColor"
                            [disabled]="GrossTemplateForm.value.frmTemplateName.trim() == '' || GrossTemplateForm.value.frmDescription.trim() == ''"
                            (click)="isdefaultadd()" type="button">
                            Save
                        </button>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div> -->

    <!-- <div *ngIf="!shownodata">
    <div class="row">
        <div class="col-md-12">
            <div class="modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>Grossing Templates </strong>
                </h3>
            </div>
        </div>
    </div>

    <span class="col-sm-12 align-center mt-3">
        <div class="col-sm-4 button-align">
            <div class="nodata-wrap mt-4">
                <div class="nodata-design">No Data Found</div>
            </div>
        </div>
    </span>

    <span class="col-sm-12 align-center mt-3">
        <div class="col-sm-4 button-align">
            <button mat-raised-button class="admin-btn-success" (click)="editAddScreen('Add',grid, event)"
                [disabled]="hideCreateBtn">Create</button>
            <button mat-raised-button class="admin-btn-success" title="Copy from other Entity" type="button"
                [disabled]="hideuploadbtn" (click)="BulkUpload()">
                Upload
            </button>
            <button mat-raised-button class="admin-btn-success" title="Copy from other Entity" type="button"
                [disabled]="hideCopyToOrgBtn" (click)="copyOrg()">
                Copy
            </button>
        </div>
    </span>
</div> -->

    <!-- <button *ngIf="tableGrid" class="btn btn-info" (click)="addNewReport()">Create</button> -->
</div>
<!-- bulk upload and copy -->

<!-- upload -->
<div *ngIf="uploadClicked" class="col-sm-12 my-adequacy-form">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Upload Grossing Templates</strong>
        </h3>
    </div>

    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <i class="fa fa-chevron-down chevron-align"></i> -->
            <mat-select disableOptionCentering class="ml-2">
                <mat-option class="temp-font-size" (click)="downloadMadatoryFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal
                    Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadAllFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All
                    Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" class="mb-1 mr-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Grossing Template.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn btn-sm rounded admin-btn-success mt-2 browse ml-5 primaryButtonColor"
                for="fileDropRef">Browse file</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="BackUploadandcopy()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1 (initialized)="initLocationGrid(grid1)"
            [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)" [frozenColumns]="2">

            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [width]="'*'"
                    [header]="columnname" [visible]="!(columnname == 'Result')" [allowResizing]="true"
                    [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <!-- Status -->
            <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload" [allowResizing]="true"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: cell.item.result=='Success' ? 'green' : 'red'}">{{cell.item.result}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="140"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><i id="deleteButton"
                            [title]="'Delete'" class="cursor p-0 fa fa-trash"></i></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3 mt-4">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="admin-btn-success" (click)="removeGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-10 button-field-wrap p-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postDownload" class="admin-btn-success mr-4 primaryButtonColor"
                        (click)="BackUploadandcopy()">Finish</button>
                    <button mat-raised-button *ngIf="!postDownload" class="admin-btn-success mr-4"
                        (click)="removeGrid()">Cancel</button>
                    <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload"
                        class="admin-btn-success mr-4 primaryButtonColor" (click)="validateDefault()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success primaryButtonColor"
                        (click)="ExportExcelCopyandUpload(grid1)">Download
                        Result</button>
                </div>
            </div>
        </div>
    </div>




    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
<!-- copy -->
<div *ngIf="copyDataClicked" class="col-sm-12">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Grossing Templates</strong>
        </h3>
    </div>

    <!-- <div class="row" *ngIf="showHeaderModule && listScreen">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View"> {{ filename }} </strong>
            </h3>
        </div>
    </div>
</div> -->

    <form [formGroup]="copycollectionMethodForm" (keydown.enter)="$event.preventDefault()" class="copy-collection">
        <div class="row float-right mb-3">
            <div *ngIf="(!backBtn && !postUpload) || (backBtn)" class="dml-btn">
                <button mat-raised-button class="admin-btn-success mr-4" (click)="BackUploadandcopy()">
                    Return
                </button>
            </div>
            <div class="dml-btn">
                <button mat-raised-button class="admin-btn-success primaryButtonColor" (click)="getDataToCopy()" [disabled]="disableGetDataBtn() || postUpload">Get
                    Data</button>
            </div>
        </div>
        <div class="row col-sm-12 p-0 ml-0">
            <div class="row col-sm-12 mt-2 align-center">
                <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                    <mat-label>Deployment</mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                    <mat-label>Group </mat-label>
                    <i class="fa fa-chevron-down chevron-align space-wrap" (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"></i>
                    <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput"
                        [disabled]="disableOrgSearch" #searchbar
                        (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"
                        (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization); trimFieldValue('frmOrganization')"
                        [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                *ngIf="show.orgid.toString().includes(searchInput.toString()) || show.orgname.toLowerCase().includes(searchInput.toLowerCase())"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)"
                                value="{{ show.orgname }}"
                                title="{{ show.orgname }} ({{show.orgid}})">
                                <span>{{ show.orgname }} ({{show.orgid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="col-sm-4 pr-0 example-additional-selection" appearance="outline">
                    <mat-label>Site</mat-label>
                    <i class="fa fa-chevron-down chevron-align space-wrap" (click)="fetchSiteSeries(searchuserbar.value)"></i>
                    <input type="text" matInput formControlName="frmSite" [(ngModel)]="searchUserInput" #searchuserbar
                        (click)="fetchSiteSeries(searchuserbar.value)" [matAutocomplete]="userauto"
                        (keyup)="fetchSiteSeries(searchuserbar.value); trimSiteFieldValue('frmSite')"
                        placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #userauto="matAutocomplete">
                        <div *ngFor="let Site of searchuserlist">
                            <mat-option class="mat-opt-align" (onSelectionChange)="selectedCopyDataSite($event, Site)"
                                value="{{ Site }}" title="{{ Site}}">
                                <span>{{ Site}}</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="gridWidth > 0">
            <wj-flex-grid #flexgrid (initialized)="initializeGrid(flexgrid)" [isReadOnly]="true"
                [itemsSource]="gridData" style="margin-top: 17px;" (formatItem)="formatItem(flexgrid, $event)"
                [frozenColumns]="1">

                <div *ngFor="let columnname of sheetHeader">
                    <wj-flex-grid-column *ngIf="!userFlag" [binding]="columnname?.toString().toLowerCase()"
                        [header]="columnname" [width]="'*'" [allowResizing]="true" [allowDragging]="false"
                        [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="userFlag" [binding]="columnname?.toString().toLowerCase()"
                        [header]="columnname" [width]="'*'" [allowResizing]="true" [allowDragging]="false"
                        [allowSorting]="false" [format]="'d'">
                    </wj-flex-grid-column>
                </div>
                <!-- Status -->
                <wj-flex-grid-column [header]="'Result'" [binding]="result" *ngIf="postUpload" [allowResizing]="true"
                    [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: cell.item.result=='Success' ? 'green' : 'red'}">{{cell.item.result}}
                        </span>
                    </ng-template>
                </wj-flex-grid-column>

                <!-- Notes -->
                <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true"
                [width]="320" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: (cell.item.notes == 'Valid') || (cell.item.notes == 'Case comment creation successful.') ? 'green' : cell.item.notes=='Display Name already exist!' ? '#ff9800' : 'red'}">{{cell.item.notes}}
                    </span>
                </ng-template>
            </wj-flex-grid-column> -->
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
            <br />
            <div *ngIf="!postUpload" class="row pl-3 mt-2 mb-2 ml-5 align-center">
                <input type="checkbox" id="copyasinactive" [disabled]="selectedItems.length==0"
                    ng-init='checkStatus=false' [checked]='checkStatus' ng-model='checkStatus'
                    (change)='checkValue($event)' class="border-style">
                <label for="DKeys" class="font-style col-sm-2 m-0 p-0 ml-1">Copy as Inactive</label>
            </div>
            <div class="row align-center mt-2">
                <div *ngIf="!backBtn" class="dml-btn mr-4">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4 primaryButtonColor"
                        (click)="ExportExcelCopyandUpload(flexgrid)">Download
                        Result</button>
                    <button *ngIf="userFlag && postUpload" mat-raised-button
                        class="admin-btn-success primaryButtonColor" (click)="refreshUserGrid()">
                        Finish
                    </button>
                    <button *ngIf="!userFlag && postUpload" mat-raised-button
                        class="admin-btn-success primaryButtonColor" (click)="BackUploadandcopy()">
                        Finish
                    </button>
                </div>
                <div *ngIf="!postUpload" class="dml-btn">
                    <button mat-raised-button class="admin-btn-success primaryButtonColor"
                        [disabled]="selectedItems.length==0" (click)="uploadCopyData(flexgrid)">Approve</button>
                </div>
            </div>
        </div>

    </form>

    <div class="container create-btn col-sm-12 align-center mt-4" *ngIf="gridWidth == 0">
        <div class="col-sm-10 nodata-wrap">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>
</div>

<!-- warning isdefault popup -->

<div *ngIf="isdefaultconfirmupload || isdefaultconfirmaddedit" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header warning-model-header">
                    <h3 class="modal-title w-100 admin-model-header-txt">Confirmation</h3>
                    <span type="button" id="button_size" data-dismiss="modal" (click)="closeCasetypeModel()"
                        class="mr-2">
                        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close"
                            class="material-icons md-24 cursor" title="Close">
                    </span>
                </div>
                <div class="modal-body p-2 msg-wrap">
                    <div class="row col-sm-12 body-message">
                        <p id="deleteuser">
                            There is an existing template marked as default. Are you sure to unmark the existing
                            template and mark the new template as Default?
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm warning-model-header"
                        (click)="isdefaultconfirmaddedit ? addEditTemplate() : uploadData()" type="button">Yes</button>
                    <button class="btn btn-sm warning-model-header" (click)="closeCasetypeModel()"
                        type="button">No</button>

                </div>
            </div>
        </div>
    </div>
</div>
