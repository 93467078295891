<!-- <p>templates-listing works!</p> -->
<div class="row" *ngIf="filename == 'Sites' && isAdmin3 && showHeaderModule">
    <div class="col-md-12 p-0">
        <div class="modal-header admin-model-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View"> {{ filename }} </strong>
            </h3>
        </div>
    </div>
</div>
<div *ngIf="context!='Search Site Order'">
    <div class="col-sm-12 common-div d-flex" *ngIf="!dragDrop">
        <div *ngIf="!isGroupLevelTabs" class="col-sm-6 d-flex p-0">
            <button *ngIf="isAllTab" class="rounded-btns col-sm-2 filterButtons button" id="All" [class.selected]="selectedButton === 'All'"
                (click)="selectButton('All')" matTooltip="{{ ('All' + AllCount)?.length + 2 > 13 ? AllCount : ''  }}">All
                ({{AllCount}})</button>
            <div *ngIf="!hideActiveCount" class="seperator-filter">
            </div>
            <button *ngIf="!hideActiveCount" [ngClass]="(ActiveCount === 0) ? 'disable-btn' : 'rounded-btns'" id="Active" [class.selected]="selectedButton === 'Active'"
                [disabled]="ActiveCount == 0" (click)="selectButton('Active')" class="col-sm-2 filterButtons button" matTooltip="{{ ('Active ' + ActiveCount)?.length + 2 > 13 ? ActiveCount : '' }}">Active
                ({{ActiveCount}})</button>

            <button *ngIf="!hideActiveCount" [ngClass]="(InactiveCount === 0) ? 'disable-btn' : 'rounded-btns'" [class.selected]="selectedButton === 'InActive'"
                [disabled]="InactiveCount == 0" (click)="selectButton('InActive')" class="ml-0 col-sm-2 filterButtons button" matTooltip="{{ ('Inactive' + InactiveCount)?.length + 2 > 13 ? InactiveCount : ''  }}">Inactive
                ({{InactiveCount}})</button>
                <div *ngIf="!hideActiveCount" class="seperator-filter"></div>
                <div class="col-sm-5 p-0 m-0 d-flex" *ngIf="isSequenceType" style="height: 24px;">
                    <mat-form-field appearance="outline" class="pl-0 seq-bg w-100 bringToTop">
                        <em class="fa fa-chevron-down chevron-align em-LabAdmin"></em>
                        <input matInput trim type="text" maxlength="200"
                               [aria-label]="'SequenceType'"
                               [placeholder]="'Select Sequence Type'"
                               value="Accession" readonly>
                    </mat-form-field>
                </div>
            <div class="col-sm-6 p-0 m-0 ml-2 d-flex" *ngIf="!isAdmin3 || isSequenceType" style="height: 24px;">
                <div class="ml-2">
                    <mat-form-field appearance="outline" [ngClass]="isSequenceType ? 'seq-bg' : 'listFilterDropdownLabAdmin'" class="w-100 bringToTop" (click)="clearFilterClicked($event, autoComopleteText?.value)">
                        <em class="fa fa-chevron-down chevron-align em-LabAdmin"></em>
                        <input trim type="text"  maxlength="200" [(ngModel)]="searchCaseType"
                            matInput [aria-label]="'CaseType'" [matAutocomplete]="auto"
                            [ngModelOptions]="{standalone: true}"
                            [placeholder]="'Select Case Type'" #autoComopleteText
                            (focus)="clearFilterClicked($event, searchCaseType)">
                        <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel" (closed)="autoCompletePanelClosed(searchCaseType)">
                            <mat-option class="mat-opt-align pl-2"  [ngClass]="{'mdc-list-item--selected':  listBackupValue === caseTypes['Case_Type'] }"
                                *ngFor="let caseTypes of filterAutomCompleteOptions(searchCaseType, caseTypeList,'Case_Type')"
                                (click)="onAutoCompleteSelectionChange($event, caseTypes)">
                                <div class="truncate-text">{{caseTypes?.Case_Type}}</div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

        </div>
        <div *ngIf="isGroupLevelTabs" class="col-sm-6 d-flex p-0">
            <button class="rounded-btns" id="All" [class.selected]="selectedButton === 'All'"
                (click)="selectButton('All')">All
                ({{AllCount}})</button>
            <div class="seperator-filter">
            </div>
            <button [ngClass]="(ActiveCount === 0) ? 'disable-btn' : 'rounded-btns'" id="Org-Level" [class.selected]="selectedButton === 'Org-Level'"
                (click)="selectButton('Org-Level')" [disabled]="ActiveCount === 0">Group
                ({{ActiveCount}})</button>

            <button [ngClass]="(InactiveCount === 0) ? 'disable-btn' : 'rounded-btns'" id="Location-level" [class.selected]="selectedButton === 'Location-Level'"
                (click)="selectButton('Location-Level')" [disabled]="InactiveCount === 0">Location
                ({{InactiveCount}})</button>
        </div>
        <div class="d-flex col-sm-6 justify-content-end p-0">
            <div class="global-search-panel d-flex {{ searchBarWidth ? searchBarWidth : 'col-sm-7'  }} ">
                <div style="display: flex;
            margin-right: 6px;">
                    <mat-icon class="search-icon">search</mat-icon>
                </div>
                <div class="standing-line">
                </div>
                <input type="text" class="search-input" [placeholder]="context" [(ngModel)]="searchText"
                    (keyup)="handleSearch()">
                    <ng-container *ngIf="searchText">
                        <div class="standing-line">
                        </div>
                        <div  style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
                            <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
                        </div>
                    </ng-container>
            </div>
            <div *ngIf="commonService.showFlagIcon" class="ml-2">
                <span class="cpy-drg-Icon-globe" (click)="showMenuRelatedFlags()">
                    <img src="/assets/images/FlagIcon.svg" matTooltip="Flags" class="globe_blue" />
                    <img src="/assets/images/FlagIconOnHover.svg" matTooltip="Flags" class="globe_white" />
                </span>
            </div>
            <!-- <div *ngIf="context=='Search Sites' && isAdmin3" class="site-Order-button ml-2">
                <div class="create-only" (click)="manageClicked()">
                    Site Order
                </div>
            </div> -->
        <!-- <div class="col-sm-2 pr-0 d-flex" style="justify-content: end; padding-left: 5px;"> -->
        <!-- <span class="col-sm-12 pl-0 pr-0 d-flex"> -->
        <div *ngIf="!hideCopy" class="ml-2"><span class="cpy-drg-Icon-globe" title="Copy" (click)="copyFrmEntity()">
                <img src=".../../../../assets/icons/Globe_Blue.svg" class="globe_blue" />
                <img src=".../../../../assets/icons/Global_white.svg" class="globe_white" />
            </span></div>
        <div *ngIf="!hideEdit && !hideDragDrop && GridData.length > 1 && filename != 'Sites'" class="ml-1 p-0"><span class="cpy-drg-Icon_sort" title="Reorder"
                (click)="toggleDragDrop()">
                <img src=".../../../../assets/icons/Reorder Blue.svg" class="sort_blue" />
                <img src=".../../../../assets/icons/Reorder White.svg" class="sort_white" />
            </span>
        </div>
        <!-- <div *ngIf="!hideCreate" class="create-upload-btn d-flex">
            <div *ngIf="!hideCreate" class="create-only" (click)="create(0)" title="Create">Create
            </div>

            <div class="Standing-line-create">
            </div>

            <div *ngIf="!hideUpload" class="upload-icon" (click)="create(1)" title="Upload"><img
                    src=".../../../../assets/icons/Bulk Upload_White.svg" style="height: 15px; width: 15px;">
            </div>
        </div> -->
            <div *ngIf="!hideCreate" class="create-upload-btn d-flex ml-2 justify-content-center">
                <div *ngIf="!hideCreate" class="create-only" (click)="create(0)">
                    Create
                </div>

                <div class="Standing-line-create" *ngIf="!hideUpload">
                </div>

                <div *ngIf="!hideUpload" class="upload-icon" (click)="create(1)"><img
                        src=".../../../../assets/icons/Bulk Upload_White.svg" title="Upload"
                        style="height: 15px; width: 15px;">

                </div>
            </div>
            <!-- </span> -->
            <!-- </div> -->
        </div>
    </div>
    <div class="col-sm-12 common-div d-flex" *ngIf="dragDrop">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 d-flex p-0">
                <div class="col-sm-6 mt-3">
                </div>
                <!-- <div class="col-sm-6 button-wrap align-center mb-2 p-0">
                <a [ngClass]="checkListChanges() ? 'reset-btn' : 'disabled-reset-btn'" (click)="resetDraggedItems()">
                    Reset
                </a>
                <button mat-raised-button class="admin-btn-success ml-2 mr-2" (click)="returnToGrid()">
                    Return
                </button>
                <button mat-raised-button class="admin-btn-success" [disabled]="!checkListChanges()"
                    (click)="saveDragDrop()">
                    Save
                </button>
            </div> -->

                <div class="p-0 button-wrap col-sm-6">
                    <a [ngClass]="checkListChanges() ? 'reset-btn' : 'disabled-reset-btn'" class="reset-btn mr-3"
                        (click)="checkListChanges() ? resetDraggedItems() : null">Reset</a>
                    <button class="mr-3 align-center" (click)="returnToGrid()">Return</button>
                    <button class="align-center primaryButtonColor" [disabled]="!checkListChanges()"
                        (click)="saveDragDrop()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- site Order -->
<div class="col-sm-12 common-divs d-flex" *ngIf="context=='Search Site Order'">
    <div class="col-sm-3 d-flex p-0 toggle-switch-container">
        <label for="sequence-format-toggle" class="toggle-switch-slider-label-text" style="
                margin-top: 16px;">Sequence Format:</label>
        <div class="col-sm-4 filter-wrapper p-0">
            <span>
                <mat-button-toggle-group #toggleGroup="matButtonToggleGroup"
                    [value]="siteLabelAlphabetFlag ? 'numeric' : 'alphabetic'" class="mt-2"  [disabled]="hideEdit">
                    <mat-button-toggle value="alphabetic"
                        (click)="toggleChange($event,'alphabetic')">
                        Alphabetic
                    </mat-button-toggle>
                    <mat-button-toggle value="numeric"
                        (click)="toggleChange($event,'numeric')"><span> &nbsp;&nbsp;</span>
                        Numeric
                        <span> &nbsp;&nbsp;</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </span>
        </div>
    </div>
    <mat-hint class="col-sm-5 pt-2 ml-2" (mouseenter)="showMessage()" (mouseleave)="hideMessage()">
        <img src="../../../assets/icons/home_icons/help.svg" alt="Help" class="material-icons help-align" style="width: 15px;">
        <strong *ngIf="showHelpMessage" class="hint-size">Default: Site orders will create with alphabetical sequence</strong>
    </mat-hint>
    <div class="col-sm-4 d-flex button-wrap align-center pr-1">
        <div class="global-search-panel d-flex col-sm-6">
            <div style="display: flex;
            margin-right: 6px;">
                <mat-icon class="search-icon">search</mat-icon>
            </div>
            <div class="standing-line">
            </div>
            <input type="text" class="search-input" [placeholder]="context" [(ngModel)]="searchText"
                (keyup)="handleSearch()">
        </div>
        <!-- <div *ngIf="context=='Search Site Order'" class="create-upload-button ml-2">
            <div class="create-only" (click)="manageClicked()">
                Sites
            </div>
        </div> -->
        <div *ngIf="!hideCopy" class="ml-2"><span class="cpy-drg-Icon-globe mt-1" title="Copy"
                (click)="copyFrmEntity()">
                <img src=".../../../../assets/icons/Globe_Blue.svg" class="globe_blue" />
                <img src=".../../../../assets/icons/Global_white.svg" class="globe_white" />
            </span></div>
        <div *ngIf="!hideEdit && !hideDragDrop" class="ml-1 p-0"><span class="cpy-drg-Icon_sort" title="Drag and drop"
                (click)="toggleDragDrop()">
                <img src=".../../../../assets/icons/Reorder Blue.svg" class="sort_blue" />
                <img src=".../../../../assets/icons/Reorder White.svg" class="sort_white" />
            </span>
        </div>

        <div *ngIf="!hideCreate" class="create-upload-button ml-2">
            <div *ngIf="!hideCreate" class="create-only" (click)="create(0)">
                Create
            </div>

            <div class="Standing-line-create" *ngIf="!hideUpload">
            </div>

            <div *ngIf="!hideUpload" class="upload-icon" (click)="create(1)"><img
                    src=".../../../../assets/icons/Bulk Upload_White.svg" title="Upload"
                    style="height: 15px; width: 15px;">

            </div>
        </div>
    </div>
</div>

<hr class="separation-line m-0 p-0" *ngIf="!dragDrop">
<div *ngIf="showGrid" class="container-fluid list-container col-sm-12 p-0 mt-0">
    <div class="col-sm-12 header d-flex py-1 mb-1 list-align">
        <div class="col-sm-12 header-wrap">
            <span [ngClass]="context=='Search Site Order' ? 'col-sm-5' : header?.colWidth" *ngFor="let header of gridHeader">
                <div class="dataTable">{{header.Name}}
                    <img *ngIf="dragDrop && sortAZ" width="16" height="16"
                        src="https://img.icons8.com/small/16/alphabetical-sorting.png" alt="alphabetical-sorting"
                        (click)="saveDragDrop(sortAZ)" />
                    <img *ngIf="dragDrop && !sortAZ" width="16" height="16"
                        src="https://img.icons8.com/small/16/alphabetical-sorting-2.png" alt="alphabetical-sorting-2"
                        (click)="saveDragDrop(sortAZ)" />
                </div>
            </span>

            <div class="col-sm-1 pr-0 edit-wraps dataTable">
                <span *ngIf="!hideExport && GridData.length > 0 && !dragDrop" (click)="exportData()" title="Export"><img
                        src=".../../../../assets/icons/Excel_Download.svg" class="export-icon"></span>
            </div>
        </div>
    </div>
    <div [ngClass]="groupBy?.length ? 'col-sm-12 py-1 mb-1' : 'py-1 mb-1'">
        <span *ngIf="showGrid && GridData.length > 0" class="separation-line">
            <div *ngIf="groupBy?.length <= 1">
                <cdk-virtual-scroll-viewport [appendOnly]="true" itemSize="10"
                    [ngClass]="groupBy.length ? 'col-sm-12 tableScroll container-style' : 'container-style tableScroll'">
                    <div
                        *cdkVirtualFor="let parentItem of GridData | searchfiltersTemplate: templatefilter;let i = index ">
                        <div class="mt-2" cdkDropList cdkDropListOrientation="vertical"
                            *ngIf="(getDataList(parentItem) | searchfiltersTemplate: templatefilter).length"
                            [ngClass]="(groupBy?.length && (getDataList(parentItem)).length)? 'list-container-inner mt-2 p-0 main-list' : 'row container-fluid mt-2 mr-0 p-0 main-list' ">
                            <span *ngIf="(groupBy?.length && (getDataList(parentItem)).length)" (click)="toggle(i)"
                                class="col-sm-12 p-1 header header-wrap">
                                <span class="col-sm-8 align-items-center">
                                    {{groupBy[0]}} : <span class="text-admin mb-0">{{parentItem.Name}}</span>
                                </span>

                                <img *ngIf="expandCollapse[i] && !dragDrop"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQUlEQVR4nGNgoAWQYmBgUMYjrwxVw6DAwMBwnoGBQQOLIg2oHEgNGKhBBbSRFKljEcNQjFMRulW4nIIClKCYxgAAl0wJXaLiw14AAAAASUVORK5CYII=">
                                <img *ngIf="!expandCollapse[i] && !dragDrop"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAASUlEQVR4nM3OUQaAUBhE4a/aWiK5LafWkcilu9hED8n/0Et0nsY4zPAbGhTsVw6psKJHi4w6khYMt67D9pQnpGBlxPz297n2AQfjuQY9pqE0NQAAAABJRU5ErkJggg==">
                                <!-- <button>{{ !expandCollapse[i] ? 'collapse' : 'expand'}} </button> -->
                            </span>
                            <div class="col-sm-12"
                                *ngIf="!expandCollapse[i] && (getDataList(parentItem) | searchfiltersTemplate: templatefilter).length">
                                <div cdkDropList [cdkDropListData]="getDataList(parentItem)"
                                    (cdkDropListDropped)="dropItem($event)">
                                    <div cdkDrag [cdkDragDisabled]="hideEdit"
                                        [ngClass]="(item.IsActive === 0 || item.Active === 0 || item.Status === 0)?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                                        *ngFor="let item of getDataList(parentItem) | searchfiltersTemplate: templatefilter;let j = index;"
                                        cdkDragBoundary=".main-list" cdkDragLockAxis="y"
                                        [cdkDragDisabled]="(item.IsActive === 0 || item.Active === 0 || item.Status === 0) || hideEdit || !dragDrop">
                                        <div [ngClass]="header.colWidth" class="text-muted text-ellipsis dataTable"
                                            title="{{displayedColumns(item,header,true)}}" *ngFor="let header of gridHeader">
                                            <div *ngIf="header.Name.toLowerCase() !='default'"
                                                style="max-width: 100%; overflow: hidden;display: flex;">
                                                <div class="p-0 mr-2" *ngIf="item.Sequence">
                                                    {{item.Sequence}}
                                                </div>
                                                {{displayedColumns(item,header)}}
                                            </div>
                                            <mat-icon
                                                *ngIf="header.Name.toLowerCase() =='default' && displayedColumns(item,header)"
                                                style="position: relative;left: 35%;color: #0B9601; height: 17px;"
                                                (click)="edit()">check</mat-icon>

                                        </div>

                                        <!-- <div *ngIf="hideEdit" class="col-sm-1 pr-0 edit-wraps">
                                                <mat-icon class="actions">visibility</mat-icon>
                                            </div> -->

                                        <div class="col-sm-1 pr-0 edit-wraps" *ngIf="!dragDrop">
                                            <!-- <mat-icon class="actions mr-3">visibility</mat-icon> -->
                                            <mat-icon *ngIf="isTemplateFile(item)" class="actions"
                                                (click)="downloadMethod(item)" title="Download">download</mat-icon>
                                            <mat-icon *ngIf="!hideEdit" class="actions ml-1" (click)="editMethod(item)"
                                                title="Edit">create</mat-icon>
                                            <mat-icon *ngIf="!hideDelete && isDeletable(item)" class="actions" (click)="delete(item)"
                                                title="Delete">delete</mat-icon>
                                            <!-- <button class="actions edit-delete-btn" (click)="delete(item)"><em id="deleteButton"
                                    [title]="'Delete'" class="p-0 fa fa-trash"></em></button> -->
                                            <!-- <mat-icon cdkDragHandle>drag_indicator</mat-icon> -->
                                        </div>
                                    </div>

                                    <!-- </span> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <span *ngIf="!filteredDataPresent(GridData)" class="col-sm-12 nodata-header-wrap mt-4 mb-2">
                        <div class="col-sm-12 nodata-wrapper">No results</div>
                    </span>
                </cdk-virtual-scroll-viewport>
            </div>
            <div *ngIf="groupBy?.length > 1">
                <cdk-virtual-scroll-viewport [appendOnly]="true" itemSize="10"
                    [ngClass]="groupBy?.length ? 'col-sm-12 tableScroll container-style' : 'container-style tableScroll'">
                    <div *ngFor="let groupItem of GridData; let i = index">
                        <div class="mt-2 custom-border" style="margin-right: 7px;" *ngIf="( getGroupDataValue(groupItem.GroupedDataList | keyvalue,i) | searchfiltersTemplate: templatefilter).length">
                            <div class="p-1 Groupheader header-wrap" (click)="toggle(i)">
                                <span class="col-sm-8 align-items-center">
                                    {{groupBy[0]}} <span class="text-admin mb-0">{{ groupItem.Name }}</span>
                                </span>
                                <img *ngIf="expandCollapse[i]"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQUlEQVR4nGNgoAWQYmBgUMYjrwxVw6DAwMBwnoGBQQOLIg2oHEgNGKhBBbSRFKljEcNQjFMRulW4nIIClKCYxgAAl0wJXaLiw14AAAAASUVORK5CYII=">
                                <img *ngIf="!expandCollapse[i]"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAASUlEQVR4nM3OUQaAUBhE4a/aWiK5LafWkcilu9hED8n/0Et0nsY4zPAbGhTsVw6psKJHi4w6khYMt67D9pQnpGBlxPz297n2AQfjuQY9pqE0NQAAAABJRU5ErkJggg==">
                            </div>
                            <div *ngIf="!expandCollapse[i]">
                                <div *ngFor="let item of groupItem.GroupedDataList | keyvalue">
                                    <div class="mt-2" *ngIf="item.key !== 'null' && (item.value | searchfiltersTemplate: templatefilter).length">
                                        <span class="col-sm-12 p-1 header-wrap">
                                            <span class="col-sm-2 align-items-center" *ngIf="item.key !== 'Default'">
                                                {{groupBy[1]}} : <span class="text-group mb-0">{{ item.key }}</span>
                                            </span>
                                            <span class="col-sm-2 align-items-center" *ngIf="item.key == 'Default'">
                                                <span class="text-group mb-0">{{ item.key }}</span>
                                            </span>
                                            <div class="border-line"></div>
                                            <div class="edit-wraps pl-2 mr-1" *ngIf="!dragDrop">
                                                <mat-icon *ngIf="!hideEdit" class="actions" (click)="editMethod(item)"
                                                    title="Edit">create</mat-icon>
                                                <mat-icon *ngIf="!hideDelete" class="actions ml-2"
                                                    (click)="delete(item)" title="Delete">delete</mat-icon>
                                            </div>
                                        </span>
                                        <div class="col-sm-12">
                                            <div cdkDrag [cdkDragDisabled]="hideEdit"
                                                [ngClass]="(item.IsActive === 0 || item.Active === 0 || item.Status === 0)?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                                                *ngFor="let item of item.value | searchfiltersTemplate: templatefilter;let j = index;"
                                                cdkDragBoundary=".main-list" cdkDragLockAxis="y"
                                                [cdkDragDisabled]="(item.IsActive === 0 || item.Active === 0 || item.Status === 0) || hideEdit || !dragDrop">
                                                <div [ngClass]="header.colWidth"
                                                    class="text-muted text-ellipsis dataTable"
                                                    title="{{displayedColumns(item,header)}}"
                                                    *ngFor="let header of gridHeader">
                                                    <div *ngIf="header.Name.toLowerCase() !='default'"
                                                        style="max-width: 100%; overflow: hidden;display: flex;">
                                                        {{displayedColumns(item,header)}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </span>
        <span *ngIf="GridData.length === 0"  class="col-sm-12 nodata-header-wrap">
            <div class="col-sm-12 nodata-wrapper">No Data Available</div>
        </span>
    </div>
</div>
