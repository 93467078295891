import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportBuilderPopupComponent } from '../popup/report-builder-popup.component';
import { WindowRef } from 'src/app/core/services/NewWindowservices';
import { VitalAdminTabService } from '../../tab.service';

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss']
})
export class ReportBuilderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: any;
  payload: any;
  deployment: string;
  organizationName: string
  url: string;
  userEmail:string;
  organizationId:string;
  accountId:string;
  urlSafe: SafeResourceUrl;
  serverUrl = environment.baseUrl2;// not need
  errMessage = "";
  launchReportBuilder: boolean=false;
  hideReportBuilderBtn: boolean=true;
  endpoint: string;
  constructor(public sanitizer: DomSanitizer, private vitalHttpServices: VitalHttpServices, private ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar,
    private vitalcomponent: VitalMenuComponent,private winRef: WindowRef,public VitalAdminTabService: VitalAdminTabService,
  private modalService: NgbModal) { }

  async ngOnInit() {
    this.GetButtonDetails();
    if(this.hideReportBuilderBtn ==false) {
      this.organizationName=sessionStorage.getItem('Org_Name');
      await this.getLaunchLink()


      // let newWindow=this.window.open(reportbuilderurl)
      // let modelref= this.modalService.open(ReportBuilderPopupComponent,{
      //   windowClass:"agentRecipientModal1"
      // });
    // modelref.componentInstance.templateData = this.templateData;

      // this.deployment = sessionStorage.getItem('deploymentKey');
      // this.organizationName=sessionStorage.getItem('Org_Name');
      }
    }

    getLaunchLink(){
      let Encrypt = {
        "DeploymentKey": sessionStorage.getItem('DeploymentKey'),
        "UserEmail": localStorage.getItem('UserEmail'),
        "AccountIds": !sessionStorage.getItem('Locationid') ? this.VitalAdminTabService?.tabs[0]?.tabData?.mainCard?.id : sessionStorage.getItem('Locationid'),
        "ProductName": "VitalAdmin",
        "OrganizationId": !sessionStorage.getItem('org_id') ? this.VitalAdminTabService?.tabs[0]?.tabData?.mainCard?.Org_ID : sessionStorage.getItem('org_id'),
        "UserId": sessionStorage.getItem('Userid')
      }
      this.vitalHttpServices.EncryptUrlPayload(Encrypt).subscribe(result => {
        if(!result.error) {
          if (!this.vitalHttpServices.reporbuilderlaunch) {
            this._snackbar.open("Not able to launch ReportBuilder")
          }
          else {
            this.endpoint=this.vitalHttpServices.reporbuilderlaunch + 'sso?data=' + result;
            if (this.VitalAdminTabService.reportBuilderWindow && !this.VitalAdminTabService.reportBuilderWindow.closed) {
              this.VitalAdminTabService.reportBuilderWindow.location.href = this.endpoint;
              this.VitalAdminTabService.reportBuilderWindow.focus();
            }
            else{
              this.VitalAdminTabService.reportBuilderWindow = window.open(this.endpoint);
            }
          }
        }
      });
    }

  ngOnChanges(changes: SimpleChanges): void {
    this.deployment = sessionStorage.getItem('deploymentKey');
    this.organizationName=sessionStorage.getItem('Org_Name');
    this.ngxService.start();
    this.errMessage = "";
    this.vitalHttpServices.getIPAddress().subscribe(
      (ipData: any) => {
        let ipAddress = ipData.ip;
        this.vitalHttpServices.getReportBuilderPayload(this.deployment, sessionStorage.getItem('org_id'), ipAddress).subscribe(
          (result) => {
            this.ngxService.stop();
            if(!result || Object.keys(result).length === 0) {
              this._snackbar.open('An error occurred while processing your request', 'Failed');
            }
            else {
              this.payload = result;
              this.url = result.reports_url;
              delete this.payload["reports_url"];
              if(this.url && this.url.length > 7) { //http://
                this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
                // this.loadPage();
              }
              else {
                this.errMessage = "Coming soon ...";
              }
            }
          },
          (error) => {
            this.ngxService.stop();
            console.error(error);
            this._snackbar.open(error.error, 'Failed');
          });
      },
      (err) => {
        this.ngxService.stop();
        console.log(err);
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      }
    );
  }

  loadPage() {
    // create a form for the post request
    const form = window.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', this.url);
    // use _self to redirect in same tab, _blank to open in new tab
    // form.setAttribute('target', '_blank');
    form.setAttribute('target', 'frame-reportbuilder');

    // Add all the data to be posted as Hidden elements
    form.appendChild(this.createHiddenElement('jsonData', JSON.stringify(this.payload)));
    window.document.body.appendChild(form);
    form.submit();
  }

  ngAfterViewInit(): void {
    /*
    // create a form for the post request
    const form = window.document.createElement('form');
    form.setAttribute('method', 'get');
    form.setAttribute('action', this.url);
    // use _self to redirect in same tab, _blank to open in new tab
    // form.setAttribute('target', '_blank');
    form.setAttribute('target', 'frame-reportbuilder');

    // Add all the data to be posted as Hidden elements
    form.appendChild(this.createHiddenElement('jsonData', 'foo'));
    console.log(form);
    window.document.body.appendChild(form);
    form.submit();
    */
  }
  // close(){
  //   // this.activeModal.close();
  //   this.launchReportBuilder=true;
  // }
  Popup()
  {
    if(this.hideReportBuilderBtn==false) {
      //popup
      // let modelref= this.modalService.open(ReportBuilderPopupComponent,{
      //   windowClass:"agentRecipientModal1"
      // });
      // modelref.componentInstance.templateData = this.templateData;
     this.getLaunchLink()

    }
}
//Rbac
  GetButtonDetails() {

 this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Launch Report Builder':
          this.hideReportBuilderBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  private createHiddenElement(name: string, value: string): HTMLInputElement {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);
    hiddenField.setAttribute('type', 'hidden');
    return hiddenField;
  }

}
