import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';

declare var $: any;
import * as wjCore from '@grapecity/wijmo';

@Component({
  selector: 'app-manage-icd9codes',
  templateUrl: './manage-icd9codes.component.html',
  styleUrls: ['./manage-icd9codes.component.scss']
})

export class ManageIcd9codesComponent implements OnInit {
  SubMenuCardModel: SubMenuCardModel;
  // showPagination: boolean;
  gridwidth: number = 0;
  gridData: wjcCore.CollectionView<any>;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  search: any;
  @Input() templateData: any;
  gridShow: boolean;
  private _currentEditItem: any = null;
  collectionMethodGridData: wjcCore.CollectionView<any>;
  enableInsert: boolean;
  destDeployment: string = '';
  dragIndex: number;
  dragnDrop: boolean;
  orgList: any = [];
  organizationList: any;
  copyDataClicked: boolean;
  action: string;
  DeploymentKeys: string[] = [];
  searchResult: any[];
  allFields: any = [{}];
  mandatoryFields = [{}];
  updateFields = [{}];
  bulkUpload: boolean;
  excelDataArray: any;
  invalidColumns: any;
  sheetHeader: any;
  copyFlag: any;
  workBook: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('flexgrid') icdGrid: WjFlexGrid;
  sheetsToSelect: any;
  uploadClicked: boolean;
  selector: any;
  showDelete: boolean;
  postUpload: any = false;
  gridDisplay: boolean;
  noDataFound: boolean;
  showInvalidColumns: boolean;
  selectedData: any = [];
  selectedCopyData: any = [];
  addEditScreen: boolean;
  dataArray: any = [];
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  noData: boolean = false;
  btnDisable: boolean;
  showPaginationMainGrid: boolean = false;
  deleteBtn: boolean = true;
  filterColumns: string[] = ["ICD9Code", "ICD9CodeName", "Sequence"];
  currentEditedIcdCode : any;
  auditableColumns: any;
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;
  minFieldsFile: boolean = false;
  filterdata: any=[];

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: UntypedFormBuilder,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public commonService: CommonService
  ) {
    // super(injectorServiceGridContainer);
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  ngOnInit(): void {
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.noDataFound = true;
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    this.getAuditableDetails(this.templateData.menuURL);
    this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
  }

  //RBAC audit
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL.toString().toLowerCase().trim() == 'icd codes')['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  @ViewChild('flex', { static: true }) flex: wjcGrid.FlexGrid;

  //Wizmo grid inline edit
  initializeGrid(flex: wjcGrid.FlexGrid) {
    const tt = new wjcCore.Tooltip();
    flex.rows.defaultSize = 40;
    // custom formatter to paint buttons and editors
    flex.formatItem.addHandler(
      (s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
        if (e.panel == s.cells) {
          let col = s.columns[e.col],
            item = s.rows[e.row].dataItem;
          if (item == this._currentEditItem) {
            switch (col.binding) {
              case 'buttons':
                e.cell.innerHTML = document.getElementById(
                  'tplBtnEditMode'
                ).innerHTML;
                e.cell['dataItem'] = item;
                break;
              case 'ICD9Code':
              case 'ICD9CodeName':
                e.cell.innerHTML =
                  '<input class="form-control"' +
                  'id="' +
                  col.binding +
                  '" ' +
                  'value="' +
                  s.getCellData(e.row, e.col, true) +
                  '"/>';
                break;
              case 'Sequence':
                e.cell.innerHTML =
                  '<input class="form-control"  maxlength="4"' +
                  'id="' +
                  col.binding +
                  '" ' +
                  'value="' +
                  s.getCellData(e.row, e.col, true) +
                  '"/>';
                break;
            }
          } else {
            switch (col.binding) {
              case 'buttons':
                e.cell.innerHTML = document.getElementById(
                  'tplBtnViewMode'
                ).innerHTML;
                e.cell['dataItem'] = item;
                break;
            }
          }
        }
      }
    );

    flex.addEventListener(flex.hostElement, 'click', (e: MouseEvent) => {
      let targetBtn: HTMLButtonElement;
      if (e.target instanceof HTMLButtonElement) {
        targetBtn = e.target;
      } else if (
        e.target instanceof HTMLElement &&
        e.target.classList.contains('fa')
      ) {
        targetBtn = e.target.parentElement as HTMLButtonElement;
      }
      if (targetBtn) {
        let item = wjcCore.closest(targetBtn, '.wj-cell')['dataItem'];
        if(targetBtn.id == 'btnEdit'){
          this.storeEditedData(item);
        }
        // handle buttons
        switch (targetBtn.id) {
          // start editing this item
          case 'btnEdit':
            this.flex = flex;
            this._editItem(item);
            break;
          // remove this item from the collection
          case 'btnDelete':
            this.deleteICDcode(flex, item);
            break;
          // commit edits
          case 'btnOK':
            this._commitEdit();
            break;
          // cancel edits
          case 'btnCancel':
            this._cancelEdit();
            break;
        }
      }
    });
    flex.scrollPositionChanged.addHandler(this._cancelEdit.bind(this));
    flex.lostFocus.addHandler(this._cancelEdit.bind(this));
  }

  storeEditedData(oldData){
    this.currentEditedIcdCode = this.templateData.submenuData.filter(x => x.OrganizationICD9CodeId == oldData.OrganizationICD9CodeId);
  }
  //edit in grid
  private _editItem(item: any) {
    if(!this.editBtn){
      this._cancelEdit();
      this._currentEditItem = item;
      this.flex.invalidate();
    }
  }
  //commit in grid
  private _commitEdit() {
    if (this._currentEditItem) {
      this.flex.columns.forEach((col: wjcGrid.Column) => {
        let input = <HTMLInputElement>(
          this.flex.hostElement.querySelector('#' + col.binding)
        );
        if (input) {
          let value = wjcCore.changeType(input.value, col.dataType, col.format);
          // if (wjcCore.getType(value) == col.dataType) {
            this._currentEditItem[col.binding] = input.value.trim() == "" ? null : value;
          // }
        }
      });
      this.updateICDCode(this._currentEditItem, 'edit')
      this.flex.invalidate();
    }
  }
  //cancel in grid
  private _cancelEdit() {
    if (this._currentEditItem) {
      this._currentEditItem = null;
      this.flex.invalidate();
    }
  }
  // forms
  addIcdCode = this._fb.group({
    icdCode: ['', Validators.required],
    icdName: [''],
    sequence: ''
  });
  copyIcdMethodForm = this._fb.group({
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
    frmOrganization: [''],
    frmOrgID: [''],
    frmcpyasinactive: false
  });
  //Populate grid in the grid 1st
  getICDlist() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getOrganizationICD9Codes');
    queryVariable = {
      orgid: sessionStorage.getItem('org_id').toString(),
      casetype: this.templateData.secondarykeys.casetype.toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          let templateData = data.data.submenuData;
          if (templateData.length > 0) {
            this.addGridData(templateData);
            this.gridShow = true;
            this.noDataFound = false;
          } else {
            this.noDataFound = true;
            this.gridShow = false;
          }
        }
        this.ngxService.stop();
      },
      error => {
        this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  // to add data in the grid 1st
  addGridData(templateData: any) {
    let primary = {};
    let gridarray = [];
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'ICD9Code' || key == 'ICD9CodeName') {
          if (value == '' || value == null) {
          value = 'Not Specified';
          primary[key] = value;
        }
        primary[key] = value;
      }
      else{
        if(!value){
          value = 'Not Specified';
          primary[key] = value;
        }
      }
      primary[key] = value;
    }
      gridarray.push(primary);
    }
    // gridarray.sort((a, b) => (a.Sequence > b.Sequence ? 1 : -1));
    gridarray.sort((a, b) => a.ICD9Code < b.ICD9Code ? -1 : 1).sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
    // this.showPagination = gridarray.length > 10 ? true : false;
    this.gridData = new CollectionView(gridarray, { pageSize: 10 });
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    this.gridShow = true;
    this.noDataFound = false;

  }

  //Export the grid data
  ExportExcel(data, type?) {
    let excel = [];
    let flex = data ? data : this.icdGrid;
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = flex.rows;
    flex.rows.find((e: any): any => {
      delete e._data['Action'];
      delete e._data['organizationid']
      delete e._data['casetype']
      delete e._data['createdby']
      delete e._data['modifiedby']
      // delete e._data['icd9codedescription']
      // delete e._data['organizationicd9codeid']
      // delete e._data['icdstandard']
      delete e._data['slno']
      // delete e._data['OrganizationId']
      // delete e._data['CaseType']
      // delete e._data['ICD9CodeDescription']
      // delete e._data['OrganizationICD9CodeId']
      // delete e._data['OrganizationICD9CodeGUID']
      // delete e._data['organizationicd9codeguid']
      delete e._data['tablename']
      delete e._data['copyflag']
      delete e._data['Result']
      delete e._data['ResultMessage']
      // delete e._data['ICDStandard']
      let primary = {}
      for (let [key, value] of Object.entries(e._data)) {
        if (key == 'ICD9Code') {
          primary['ICD Code'] = value
        }
        else if (key == 'ICD9CodeName') {
          primary['ICD Code Name'] = value
        }
        else if (key == 'icd9code') {
          primary['ICD Code'] = value
        }
        else if (key == 'icd9codename') {
          primary['ICD Code Name'] = value
        }
        else if (key == 'result') {
          primary['Result'] = value
        }
        else if (key == 'resultmessage') {
          primary['ResultMessage'] = value
        }
        else if (key == 'sequence') {
          primary['Sequence'] = value
        }
        else {
          primary[key] = value;
        }
      }
      excel.push(primary);
    });
    let filename = 'ICD CODES_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'ICD Codes');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }
  //Flags while inserting
  insertFlag() {
    this.noDataFound = false;
    this.gridShow = false;
    this.enableInsert = true;
    this.addIcdCode.reset()
  }
  //Method used to insert icd code
  insertICDcode(data) {
    if (!this.createBtn) {
      var obj = {
        "icd9code": this.addIcdCode.value.icdCode,
        "icd9codename": this.addIcdCode.value.icdName ? this.addIcdCode.value.icdName : null,
        "icd9codedescription": this.addIcdCode.value.icdName ? this.addIcdCode.value.icdName : null,
        "organizationid": sessionStorage.getItem('org_id').toString(),
        "casetype": this.templateData.secondarykeys.casetype.toString(),
        "sequence": this.addIcdCode.value.sequence,
        "createdby": !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
      }
      if (this.templateData.submenuData &&
        this.templateData.submenuData.some(va => va.ICD9Code == this.addIcdCode.value.icdCode)
      ) {
        this._snackbar.open('ICD code already exists', 'Close')
        return;
      }
      if (!this.addIcdCode.value.icdCode) {
        this._snackbar.open('Please enter all the required fields', 'Close')
        return;
      }
      if(this.addIcdCode.value.sequence == 0){
        this._snackbar.open('Invalid! Please enter a number greater than 0', 'Close');
        return;
      }
      else {
        this.ngxService.start();
        this.VitalHttpServices.InsertICDCode(
          [obj],
          this.destDeployment
        ).subscribe(
          result => {
            if (!result.errors) {
              this.ngxService.stop();
              // this._snackbar.open('ICD code inserted successfully', 'Close');
              this._snackbar.open(result[0].NoteMessage, "Close");
              obj['sequence'] = result[0]['Sequence'];
              this.commonService.auditDetails('organizationicd9codeid','icd9code',result, [obj], 'Create',this.templateData,this.auditableColumns);
              if (result[0].NoteMessage.toString().match(/Duplicate values found/i)) {
                return;
              }
              // this.auditDetails({}, result[0].Data[0], 'Added');
              if (data == 'close') {
                this.enableInsert = false;
                this.gridShow = false;
                this.getICDlist();
                return
              }
              else {
                this.addIcdCode.reset()
                this.enableInsert = true;
                this.gridShow = false;
                this.resetFormErrors();
              }
            }
          },
          error => {
            this.ngxService.stop();
            console.error(error);
          }
        );
      }
    }
  }
  //Method used for editing icd code
  updateICDCode(obj, flag?: string) {
    if (flag == 'edit') {
      const { ICD9Code, ICD9CodeName, Sequence } = this._currentEditItem;
      let message = '';
      const missingFields = ['ICD Code'].filter((field, index) => !this._currentEditItem[['ICD9Code'][index]]?.toString().trim());
      if (missingFields.length) {
        message = `Please enter all required fields: ${missingFields.join(', ')}`;
      }
      if (!Sequence || Sequence == 0) {
        message += (message ? '; ' : '') + 'Invalid! Please enter a number greater than 0';
      }
      if (message) {
        this._snackbar.open(message, 'Close');
        this.getICDlist();
        return;
      }
      obj = {}
      obj.organizationicd9codeid = this._currentEditItem.OrganizationICD9CodeId;
      obj.icd9code = this._currentEditItem.ICD9Code == 'Not Specified' ? null : this._currentEditItem.ICD9Code?.toString().trim();
      obj.icd9codename = this._currentEditItem.ICD9CodeName == 'Not Specified' ? null : this._currentEditItem.ICD9CodeName?.toString().trim();
      obj.icd9codedescription = this._currentEditItem.ICD9CodeDescription;
      obj.organizationid = sessionStorage.getItem('org_id').toString();
      obj.casetype = this.templateData.secondarykeys.casetype?.toString();
      obj.sequence = this._currentEditItem.Sequence;
      obj.modifiedby = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid");


      if (this.templateData.submenuData &&
        this.templateData.submenuData.some(va =>((va.ICD9Code ? va.ICD9Code.toString().toLowerCase() ==  obj.icd9code.toString().toLowerCase():''
      ) && va.OrganizationICD9CodeId?.toString() != obj.organizationicd9codeid?.toString()))
      ) {
        this._snackbar.open('ICD Code already exists', 'Close')
        this.getICDlist();
        return;
      }
      else if (this._currentEditItem.Sequence < 0) {
        this._snackbar.open('Negative sequence not allowed', 'Close')
        this.getICDlist()
        return;
      }
      else if (this.templateData.submenuData.some(va => va.ICD9Code == this._currentEditItem.ICD9Code
      )) {
        //sequence validation code
        // let value = this.templateData.submenuData.find(va => va.ICD9Code === this._currentEditItem.ICD9Code && va.ICD9CodeName == this._currentEditItem.ICD9CodeName)
        // if (value.Sequence == this._currentEditItem.Sequence &&
        //   value.OrganizationICD9CodeId!= this._currentEditItem.OrganizationICD9CodeId) {
        //   this._snackbar.open('Duplicate values found', 'Close')
        //   this.getICDlist();
        //   return;
        // }
        {
          this.VitalHttpServices
            .UpdateICDCode([obj], this.destDeployment)
            .subscribe(
              result => {
                if (!result.errors) {
                  this._snackbar.open('Updated successfully', 'Close');
                  this.commonService.auditDetails('OrganizationICD9CodeId','ICD9Code',this.currentEditedIcdCode, [obj],'Edit',this.templateData,this.auditableColumns);
                  this._currentEditItem = null;
                  this.getICDlist();
                }
              },
              error => {
                console.error(error);
              }
            );
        }
      }
      else {
        this.VitalHttpServices
          .UpdateICDCode([obj], this.destDeployment)
          .subscribe(
            result => {
              if (!result.errors) {
                this._snackbar.open('Updated successfully', 'Close');
                this.commonService.auditDetails('OrganizationICD9CodeId','ICD9Code',this.currentEditedIcdCode, [obj], 'Edit',this.templateData,this.auditableColumns);
                this._currentEditItem = null;
                this.getICDlist();
              }
            },
            error => {
              console.error(error);
            }
          );
      }

    }
    else {
      this.VitalHttpServices
        .UpdateICDCode(obj, this.destDeployment)
        .subscribe(
          result => {
            if (!result.errors) {

              this._snackbar.open("Data updated successfully", "Close")
              for (let i = 0; i < this.excelDataArray.length; i++) {
                if(obj[i]["resultmessage"].toString().match(/approval/i)){
                    this.excelDataArray[i]["result"] = result[i]["status"];
                    this.excelDataArray[i]["resultmessage"] = result[i]["NoteMessage"];
                }
                else if (obj[i]["resultmessage"].toString().match(/not found in casetype/i)) {
                  this.excelDataArray[i]["result"] = 'Ignored'
                  this.excelDataArray[i]["resultmessage"] = 'Mandatory field is missing!'
                }
                else if(obj[i]["resultmessage"].toString().match(/already exists/i)){
                  this.excelDataArray[i]["result"] = 'Ignored'
                  this.excelDataArray[i]["resultmessage"] = 'Updated data already exists'
                }
                this.postUpload = true;
              }
              this.commonService.auditDetails('icd9code','icd9code',[],this.excelDataArray, 'Bulk Update', this.templateData, this.auditableColumns);
            }
          },
          error => {
            console.error(error);
          }
        );
    }
  }
  // button
  saveAndCreate() {
    this.insertICDcode('create');
  }
  // button
  saveAndClose() {
    this.insertICDcode('close');
  }
  // button
  backSelect() {
    this.showInvalidColumns = false;
    this.noData = false
    this.addIcdCode.patchValue({
      icdCode: '',
      icdName: '',
      sequence: ''
    });
    this.enableInsert = false;
    this.gridShow = false;
    this.getICDlist();
    this.copyDataClicked = false;
    this.copyIcdMethodForm.patchValue({
      frmOrganization: '',
      frmOrgID: '',
      frmcpyasinactive: false
    })
    this.gridwidth = 0;
    this.resetFormErrors();
  }
  // button
  refreshGrid(context?) {
    this.noData = false
    this.gridwidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.copyDataClicked = false;
    this.bulkUpload = false
    this.searchResult = [];
    this.addEditScreen = false;
    this.copyIcdMethodForm.controls.frmOrganization.enable()
    this.uploadClicked = false;
    this.selectedData = [];
    this.copyIcdMethodForm.reset()
    this.resetFormErrors();
    this.getICDlist();
  }
  // button
  resetFormErrors() {
    Object.keys(this.addIcdCode.controls).forEach(key => {
      this.addIcdCode.controls[key].setErrors(null)
    });
    this.copyIcdMethodForm.controls.frmOrganization.enable()
  }
  //flags handled in copy screen
  loadCopyScreen() {
    if (!this.copyBtn) {
      this.noDataFound = false;
      this.copyDataClicked = true;
      this.gridShow = false;
      this.gridwidth = 0;
      this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
      this.copyIcdMethodForm.patchValue({
        frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase()
      })
      this.getListOrg();
    }
  }
  //To delete icd code
  deleteICDcode(flex, value) {
    if (!this.deleteBtn) {
      let data = value.OrganizationICD9CodeId.toString();
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '430px',
        data: { header: "Alert!", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.DeleteICDCode({ organizationicd9codeid: data }, this.destDeployment).subscribe(
            () => {
              this._snackbar.open('Data deleted successfully', 'Close');
              (<wjcCore.CollectionView>flex.collectionView).remove(value)
              if(flex?.rows?.length == 0){
                this.noDataFound = true;
                this.gridShow = false;
              }
              this.commonService.auditDetails('OrganizationICD9CodeId','ICD9Code',[value], [{}], 'Delete',this.templateData,this.auditableColumns);
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }
  //get data in copy from Entity
  getListOrg() {
    let queryVariable = {status:'active'};
    let query = this.SubMenuCardModel.GetQuery("orgtype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.copyIcdMethodForm.value.frmDepKey).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        if (data.data.submenuData.length > 0) {
          this.filterdata = data.data.submenuData
          this.organizationList = this.filterdata.filter(o => o.orgtype.toLowerCase() != 'ordering facilities')
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }

  //filter org details based on casetype
  filterOrgName() {
    let orgSuggestion = [];
    this.orgList.foreach(va => va.orgSuggestion.push(va.organizationname));
    orgSuggestion = [...new Set(orgSuggestion)].sort();
    return orgSuggestion;
  }
  //Deployment details in the copy from Entity
  onChangeDeployment(e, depKey) {
    this.postUpload = false;
    this.selectedData.length = 0;
    this.approveDisable();
    this.searchResult = []
    this.copyIcdMethodForm.patchValue({
      frmOrganization: '',
      frmOrgID: '',
      frmcpyasinactive: false
    })
    this.gridwidth = 0;
    if (e.source.selected) {
      this.copyIcdMethodForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: '',
        frmOrgID: '',
        frmcpyasinactive: false
      });
      this.getListOrg();
      this.copyIcdMethodForm.controls.frmOrganization.enable()
    }
  }
  //Org data suggestion based on number or alphabet
  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = [];
    this.selectedData.length = 0;
    if (!value.trim()) {
      let copyOrgList=this.organizationList.slice()
      return (this.searchResult = copyOrgList.splice(0,25));
    }
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.orgname != null) {
            return series.orgname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.orgid != null) {
          return series.orgid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.copyIcdMethodForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.orgname != null) {
        return series.orgid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
    this.resetFormErrors()
  }
  //Upload screen
  loadUploadScreen() {
    if (!this.uploadBtn) {
      this.noDataFound = false;
      this.uploadClicked = true;
      this.copyDataClicked = false;
      this.enableInsert = false;
      this.gridShow = false;
      this.bulkUpload = true;
      this.gridwidth = 0
      let queryVariable = { tablename: 'OrganizationIcd9Codes', identity: "false", nullable: "false" };
      let query = this.SubMenuCardModel.GetQuery("getTableColumns");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.VitalHttpServices.GetData(queryResult).subscribe(data => {
        if (!data.errors) {
          if (data) {
            for (let item of data.data.allFields) {
              if (item.Column != 'OrganizationId' && item.Column != 'CaseType') {
                this.allFields[0][item.Column] = null;
              }
            }
            delete this.allFields[0]["OrganizationICD9CodeGUID"];
            delete this.allFields[0]["ICDStandard"];
            delete this.allFields[0]["CreatedDate"];
            delete this.allFields[0]["CreatedBy"];
            delete this.allFields[0]["ModifiedDate"];
            delete this.allFields[0]["ModifiedBy"];
            delete this.allFields[0]["SourceGUID"];
            this.mandatoryFields[0]["ICD9Code"] = null
            this.mandatoryFields[0]["ICD9CodeName"] = null
            this.updateFields[0]["OrganizationICD9CodeId"] = null
            this.updateFields[0]["ICD9Code"] = null
            this.updateFields[0]["ICD9CodeName"] = null
            this.updateFields[0]["ICD9CodeDescription"] = null
            this.updateFields[0]["ICDStandard"] = null
            this.updateFields[0]["Sequence"] = null
          }
        }
      }, error => {

        console.error(error);
      });
    }
  }
  //Upload file
  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.UpdateColumnMisMatch = false
    this.minFieldsFile = false
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulk update')){
     this.bulkUpdateFile = true
    }else if(filename.toLowerCase().includes('minimal fields')){
      this.minFieldsFile = true;
    }
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationicd9codes')
          this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  // validation in grid
  validateExcel(excelArr) {
    let arrayValue: any = [];
      for (let i = 0; i < excelArr.length; i++) {
        let primary = {}
        for (let [key, value] of Object.entries(excelArr[i])) {

            if((key && key.toString().toLowerCase() == 'icd9code' )){
              if(value){
                value = value && value.toString().trim();
              }
              else if(this.UpdateColumnMisMatch && this.missingColumn.length > 0){
                for(let i=0; i<this.missingColumn.length; i++){
                 if(this.missingColumn[i].toLowerCase() === key.toString().toLowerCase()){
                  value = '';
                 }
                }
              }
              else{
                value = null;
              }
            }
          primary[key] = value;
        }
        arrayValue.push(primary);
        // excelArr[i].icd9code = !excelArr[i].icd9code || excelArr[i].icd9code.toString().trim()
        // excelArr[i].icd9codename = !excelArr[i].icd9codename || excelArr[i].icd9codename.toString().trim()
        // excelArr[i].icd9code = temp
        // excelArr[i].icd9codename = temp1
        // temp = {}
        // temp1 = {}
      }
    excelArr = arrayValue;
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.VitalHttpServices.validateICDData(dataArray, this.destDeployment).subscribe(result => {
      if (!result.errors) {
        // for (let i = 0; i < dataArray.length; i++) {
        //   if (!this.copyDataClicked) {
        //     if (!dataArray[i].icd9code) {
        //       this._snackbar.open("Please provide valid data", 'Failed');
        //       this.ngxService.stop();
        //       return;
        //     }
        //   }
        // }
        this.noData = false
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.resultmessage = r.NoteMessage;
              }
            });
          });
          this.AddGridData(dataArray, 1);
          this.ngxService.stop();
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
      this.noData = true;
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //Append validation notes in the grid
  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
        this.uploadedHeaders = Object.keys(tempExcelArr[0]);
        this.expectedHeaders = this.bulkUpdateFile? Object.keys(this.updateFields[0]): (this.minFieldsFile ? Object.keys(this.mandatoryFields[0]) : Object.keys(this.allFields[0]));
        if(this.bulkUpdateFile){
           // Check if arrays are of equal length
           if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
             this.UpdateColumnMisMatch = true;
          }
          this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
        }else if(!this.bulkUpdateFile && !copyfrmorg){
          if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
            this._snackbar.open("Invalid excel", "Close")
            return;
          }
        }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value == '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
              else if (copyfrmorg && key == 'organizationicd9codeid') {
                value = null;
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    // if (tempExcelArr.length == 0) {
    //   this._snackbar.open("The uploaded excel does not contain any data", "Close")
    //   return;
    // }
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push('ResultMessage')
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push('ResultMessage')
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
     for(let i=0; i<tempExcelArr.length; i++){
      for(let j=0; j<this.missingColumn.length; j++){
        tempExcelArr[i][this.missingColumn[j]] = '';
      }
     }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, resultmessage: '', tablename: 'OrganizationIcd9Codes', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), casetype: this.templateData.cardtype.toString() });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        // if (inputArr[i].OrganizationICD9CodeId == null) {
        //   inputArr[i].remove()
        // }
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }

      array.push(newobj)
    }
    return array;
  }

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //append grid in the upload and copy
  AddGridData(data, hitCount) {
    this.ngxService.start()
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["result"] || this.excelDataArray[i]["result"] == '') {
        this.excelDataArray[i]["result"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (key == 'sequence') {
                if (value == 0) {
                  if (primary['resultmessage'] != 'Mandatory field is missing!' && primary['resultmessage'] != 'Null values exist!') {
                    primary['resultmessage'] = 'Mandatory field is missing!';
                  }
                }
                else {
                  let pattern = /[0-9]+/
                  if (primary['resultmessage'] != 'Mandatory field is missing!' && primary['resultmessage'] != 'Null values exist!') {
                    primary['resultmessage'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['resultmessage'];
                  }
                }
              }
              if (value && value.toString().match(/null/i) && key != 'organizationicd9codeid') {
                value = "NULL"
                primary['resultmessage'] = 'Null values exist!'
              }
              if (key == 'organizationicd9codeid' && (!value || value == '')) {
                primary['resultmessage'] = 'Mandatory field is missing!'
              }
              if (key == 'ICD9Code' && (!value || value.toString() == '')) {
                value = ''
                primary['resultmessage'] = 'Mandatory field is missing!'
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if (!this.copyDataClicked) {
          this.selectedData = gridArray;
        }
        this.gridData = new CollectionView(gridArray);
        this.gridwidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      this.ngxService.stop()
    }
  }
  //get data for the second time in copy from Entity (PATCHING)
  selectedCopyDataOrganization(event, data) {
    if (event.source.selected && Object.keys(data).length > 0) {
      setTimeout(() => {
        this.copyIcdMethodForm.patchValue({
          frmOrgID: data.orgid,
          frmOrganization: data.orgname
        })
        this.getDataToCopy();
      }, 500);
    }
  }
  //Grid in copy and upload
  initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //Copy data from one org to another
  getDataToCopy() {
    if (typeof (this.copyIcdMethodForm.value.frmOrgID) == 'object') {
      let frmorgid= null
      frmorgid = this.copyIcdMethodForm.value.frmOrgID
      this.copyIcdMethodForm.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.copyIcdMethodForm.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationICD9codes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.copyIcdMethodForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        data.data.submenuData.forEach(va => {
          delete va.OrganizationICD9CodeId
          delete va.OrganizationId
          delete va.CaseType
          delete va.ICD9CodeDescription
          delete va.ICDStandard
          delete va.OrganizationICD9CodeGUID
          delete va.CreatedBy
          delete va.CreatedDate
          delete va.ModifiedBy
          delete va.ModifiedDate
          if (this.copyDataClicked) {
            delete va.Sequence
          }
        })
        let copyData = data.data.submenuData;
        if (copyData.length == 0) {
          this.gridwidth = 0;
          this.noData = true;
          return
        }
        this.gridwidth = 1;
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }
  // validation while copying
  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      delete copyData[i]["CreatedBy"]
      delete copyData[i]["CreatedDate"]
      delete copyData[i]["ModifiedBy"]
      delete copyData[i]["ModifiedDate"]
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        primary[key] = value;
      }
      primary['resultmessage'] = "";
      primary['slno'] = i + 1;
      primary['tablename'] = "organizationicd9codes";
      primary['organizationid'] = this.templateData.secondarykeys.OrganizationId.toString();
      primary['casetype'] = this.templateData.cardtype.toString();
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }
  //Commit fro copy and upload
  approveICDCollection(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["resultmessage"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["resultmessage"].toString().match(/approval/i) && !this.excelDataArray[i]["resultmessage"].toString().match(/already exists/i)
            && !this.excelDataArray[i]["resultmessage"].toString().match(/NULL/i)) {
            errorcount++
          }
        }
      }

      if (errorcount > 0) {
        if (this.excelDataArray.some(va => va.organizationicd9codeid)) {
          this._snackbar.open("Record that you are updating in not in this case type", "Close");
        }
        else {
          this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        }
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == null) {
              this.excelDataArray[i][key] = null;
            }
            else if(value == "" ){
              this.excelDataArray[i][key] = '';
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            if(this.bulkUpdateFile){
              this.excelDataArray[i]["modifiedby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
            }else{
              this.excelDataArray[i]["createdby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
            }
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (selectedData.some(va => !this.UpdateColumnMisMatch && (!va.icd9code))) {
        this._snackbar.open('Some of the mandatory data is missing!', 'Close');
        return
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
        if (this.excelDataArray.every(va => va.resultmessage.toString().toLowerCase().match(/already exists/i))) {
          this._snackbar.open("All selected records already exist!", "Close");
          this.bulkUpload = true;
          return;
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray
      let context = copyData ? null : ''
      if (!sendjson[0].organizationicd9codeid || (copyData && this.bulkUpload)) {
        this.addICDMethods(dataArray, sendjson, this.bulkUpload, context)
      } else {
        this.updateICDCode(sendjson)
      }
    }
  }
  //Add updated data to the grid
  addICDMethods(dataArray: object[], sendjson, bulkUpload: boolean, context?) {
    this.ngxService.start();
    this.VitalHttpServices.InsertICDCode(sendjson, this.destDeployment).subscribe(result => {
      this.noDataFound = false;
      this.ngxService.stop();
      if (!result.errors) {
        this.noData = false;
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.SlNo.toString() === e.slno.toString()) {
              e.result = r.status
              e.resultmessage = r.NoteMessage;
            }
          });
        });
        dataArray.forEach((va: any) => {
          if (va.resultmessage.toString().toLowerCase().match(/already exists/i)) {
            va.result = 'Ignored'
          }
        })
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          this.showDelete = false;
          if(this.copyDataClicked && this.postUpload){
            this.copyIcdMethodForm.controls.frmOrganization.disable()
          }
          let uploadresult = this.convertObjKeysToLower(dataArray);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + '(s) already exist!', 'Close');
              this.AddGridData(uploadresult, 1);
            }
            else { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exists!', 'Close'); }
            return;
          }
          else {
            let message = bulkUpload ? 'Data uploaded successfully' : 'Copied successfully';
            this.AddGridData(uploadresult, 1);
            this.commonService.auditDetails('icd9code','icd9code',uploadresult,  bulkUpload ?this.excelDataArray:dataArray, bulkUpload ? 'Upload' : 'Copy', this.templateData, this.auditableColumns);
            this._snackbar.open(message, 'Close');
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //Upload templates
  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    if (type == 'allFieldsCheck') {
      filename = 'ICD CODES_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationICD9Codes');
      ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'mandatoryFieldsCheck') {
      filename = 'ICD CODES_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.mandatoryFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationICD9Codes');
      ws = XLSX.utils.json_to_sheet(this.sampleDataMinimumFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'updateCheck') {
      filename = 'ICD CODES_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx'
      excel = this.updateFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationICD9Codes');
      ws = XLSX.utils.json_to_sheet(this.sampleDataUpdateFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
  }
  sampleDataUpdateFields = [{
    OrganizationICD9CodeId: '123456'
    , ICD9Code: 'Code123'
    , ICD9CodeName: 'CodeName'
    , ICD9CodeDescription: 'CodeName Description'
    , ICDStandard: '9'
    , Sequence: '1'
  }]
  sampleDataMinimumFields = [{
    ICD9Code: 'Code123'
    , ICD9CodeName: 'CodeName'

  }]
  sampleDataAllFields = [{
    ICD9Code: 'Code123'
    , ICD9CodeName: 'CodeName'
    , ICD9CodeDescription: 'Code Description'
    , Sequence: '1'
  }]
  //button
  gridRemove() {
    this.postUpload = false;
    this.showInvalidColumns = false;
    this.noData = false;
    this.noDataFound = false;
    this.searchResult = []
    this.gridwidth = 0
    this.copyIcdMethodForm.controls.frmOrganization.enable()
    this.copyIcdMethodForm.patchValue({
      frmOrganization: ''
    })
    this.gridData = new CollectionView([]);
  }
  //delete row in the grid in upload
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }
  //sheet name

  //Flags fro approve button
  approveDisable() {
    let data;
    if (this.copyDataClicked && this.selectedData.length == 0) {
      return true
    }
    return data;
  }
  //#regin to show notes
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('Mandatory') || value.toString().includes('null') || value.toString().includes('Null') || value.toString().includes('exists') || value.toString().includes('values')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value == 'Success' || value.toString().includes('approval') || value.toString().toLowerCase().includes('successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Record not found in casetype')) || (value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }
  //allows only number
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyIcdMethodForm, controlName ,this.copyIcdMethodForm.value.frmOrganization)
    }

    modifyColumnName(columnName: string) {
        switch (columnName.trim().toLowerCase()) {
            case 'icd9code':
                return 'ICD Code';
            case 'icd9codename':
                return 'ICD Code Name';
            default:
                return columnName;
        }
    }
}
