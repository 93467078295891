import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControlName } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { HomeLayoutComponent } from '../../../common/layout/home-layout.component';
import { DialogService } from '../../../core/services/dialog.service';
import { environment } from 'src/environments/environment';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { cloneDeep } from 'lodash';

declare var $: any;
@Component({
  selector: 'template-setup-new-customer',
  templateUrl: './setup-new-customer.component.html',
  styleUrls: ['./setup-new-customer.component.scss']
})
export class SetupNewCustomerComponent implements OnInit {
  //public DeploymentKeys: any[] = environment.DeploymentKeys;
  @Input()
  selecteddefaultdeploymentkey : any;

  FormDataEntry: boolean = false;
  submitted: boolean = false;
  submitted1: boolean;
  nameinvalid: boolean;
  displaynameinvalid: boolean;
  SetupInformation: any;
  SubMenuCardModel: any;
  StateList: any;
  ClaimOptions: any;
  Timezones: any;
  UsernameWebFormat: any;
  UsernameReportFormat: any;
  files = [];
  newLogoSrc = '';
  Lab_Organization_ID: any;
  Lab_Organization_Name: any;
  Lab_Account_ID: any;
  Logo = '';
  LabMnemonic: any;
  OFMnemonic: any;
  Address1: any;
  Address2: any;
  City: any;
  State: any;
  Zip: any;
  Phone: any;
  DisplayName: any;
  Lab_Organization_GUID: any;
  LabOrgNPI: any;
  LabOrgClia: any;
  LabNo_of_Locations: any;
  LabNo_of_users: any;
  Lab_Account_GUID: any;
  Lab_Account_Name: any;
  OF_Account_ID: any;
  OF_Account_GUID: any;
  OF_Account_Name: any;
  Lab_UserName: any;
  Lab_UserRoles: string;
  Lab_UserID: any;
  Lab_UserEmail: string;
  Lab_UserPassword: any;
  OF_UserName: any;
  OF_UserRole: any;
  OF_UserID: any;
  OF_UserEmail: any;
  OF_UserPassword: any;
  CaseType: any;
  resultPage: boolean = false;
  UserEnteredDataDiv = false;
  showPassword: boolean = false;
  OFNo_of_users: any;
  OF_Organization_GUID: any;
  OFOrgClia: any;
  OFOrgNPI: any;
  OF_Organization_ID: any;
  OF_Organization_Name: any;
  passwordHidden = true;
  passwordShown: boolean;
  labpasswordHidden = true;
  emailValid: boolean = true;
  NPIWarning: boolean = false;
  zipValid: boolean = true;
  payZipValid: boolean;
  ZipValidation: boolean;
  payZipValidation: boolean;
  imageUploaded: boolean = false;
  imagePreview: string = '';
  NPI: any = '';
  npiValidated: boolean = false;
  validationWarning: boolean = false;
  dateFormat: string[] = ['mm-dd-yyyy', 'dd-mm-yyyy'];
  faxValid: boolean = true;
  LabType: any;
  selectLabType: any;
  public DeploymentKeys = [];
  allOrglist: any=[];
  mdmProviderId: number = 0;
  previousNPIValue: string = '';

  constructor(
    private ngxService: NgxUiLoaderService,
    public vaservices: VitalHttpServices,
    private _fb: FormBuilder,
    private clipboard: Clipboard,
    private commonService: CommonService,
    private _snackbar: MatSnackBar,
    private datashare: DataShareService,
    public datepipe: DatePipe,
    private homeLayoutComponent : HomeLayoutComponent,
    private opendialog: DialogService,
    private _labadminservice: LabadminService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vaservices,
      datashare
    );
    this.DeploymentKeys = this.vaservices.DeploymentKeys
  }

  SetupNewCustomerform = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    selectLabType: ['', Validators.required]
  });

  UserEnteredData = this._fb.group({
    name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    displayname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    shortname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    npi: '',
    clia: null,
    ein: null,
    primarycontactname: null,
    primarycontactnumber: ['', [Validators.pattern(/\d{3}[\-]?\d{3}[\-]?\d{4}(x\d{10}?)?$/)]],
    primarycontactemail: '',
    Status: 1,
    Address1: null,
    Address2: null,
    zip: '',
    phone: ['', [Validators.pattern(/\d{3}[\-]?\d{3}[\-]?\d{4}(x\d{10}?)?$/)]],
    state: '',
    city: null,
    country: null,
    fax: ['', [Validators.pattern(/(\d{3}[\.]?\d{3}[\.]?\d{4},?$){1,3}/)]],
    timezone: '',
    billingphonenumber: ['', [Validators.pattern('^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$')]],
    logo: null,
    mnemonic: ['', [Validators.required, Validators.maxLength(18)]],
    batchingmnemonic: null,
    taxonomycode: null,
    supportid: null,
    claimoptions: 'Track Claim',
    claimmnemonics: null,
    clpol: null,
    reportdata: '',
    enabledfax: 0,
    sharedsurgicalcenter: 0,
    enabledsendout: 0,
    statelicensenumber: null,
    usernameDisplayFormat: '',
    usernameReportDisplayFormat: '',
    collectiondateformat: '',
    packagedateformat: '',
    etadateformat: '',
    payaddress1: null,
    payaddress2: null,
    paycity: null,
    paystate: null,
    payzip: ''
  });

  async ngOnInit() {
    this.SetupNewCustomerform.patchValue({
      frmDeploymentKey: this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase().trim() != 'all' ? this.selecteddefaultdeploymentkey.toString().toUpperCase() : '',
      selectLabType: 'Commercial'
    });
    this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase().trim() != 'all' ? this.newCustomerSetupForm() : null;
  }

  //#region
  //update checkbox values on check or uncheck
  checkboxChange(formfield, checked) {
    if (checked) {
      formfield = 1;
    } else {
      formfield = 0;
    }
  }
  //#endregion

  //#region
  //On click of the 'X' button to close modal
  SetupNewCustomerClose() {
    this.imagePreview = '';
    this.imageUploaded = false;
    this.validationWarning = false;
    this.labpasswordHidden = true;
    this.passwordHidden = true;
    this.files = [];
    this.SetupNewCustomerform.patchValue({
      frmDeploymentKey: '',
      selectLabType: 'Commercial'
    });

    this.emailValid = true;
    this.newLogoSrc = '';
    this.UserEnteredData.reset();
    this.UserEnteredData.patchValue({
      logo: ''
    })
    this.LabType = '';
    this.Logo = '';
    // (document.getElementById('logo') as HTMLInputElement).value= null
    // this.UserEnteredData.patchValue({
    //   name: '',
    //   displayname: '',
    //   logo: '',
    //   shortname: '',
    //   npi: '',
    //   clia: null,
    //   ein: null,
    //   primarycontactname: null,
    //   primarycontactnumber: null,
    //   primarycontactemail: null,
    //   Status: 1,
    //   Address1: null,
    //   Address2: null,
    //   zip: null,
    //   phone: null,
    //   state: '',
    //   city: null,
    //   country: null,
    //   fax: null,
    //   timezone: '',
    //   billingphonenumber: null,
    //   mnemonic: '',
    //   batchingmnemonic: null,
    //   taxonomycode: null,
    //   supportid: null,
    //   claimoptions: 'Track Claim',
    //   claimmnemonics: null,
    //   clpol: null,
    //   reportdata: '',
    //   enabledfax: 0,
    //   sharedsurgicalcenter: 0,
    //   enabledsendout: 0,
    //   statelicensenumber: null,
    //   usernameDisplayFormat: '',
    //   usernameReportDisplayFormat: '',
    //   collectiondateformat: null,
    //   packagedateformat: null,
    //   etadateformat: null,
    //   payaddress1: null,
    //   payaddress2: null,
    //   paycity: null,
    //   paystate: null,
    //   payzip: null
    // });
    $('#genericModal').modal('hide');
    this.FormDataEntry = false;
    this.zipValid = true;
    this.payZipValid = true;
    this.homeLayoutComponent.SelectedMenuModel = false;
    this.submitted = false;
    this.nameinvalid = false;
    this.displaynameinvalid = false;
    this.resultPage = false;
    this.UserEnteredDataDiv = false;
  }
  //#endregion

  get formcontrol() {
    return this.UserEnteredData.controls;
  }

  onChangeDep(deviceValue) {
    this.SetupNewCustomerform.patchValue({
      frmDeploymentKey: deviceValue
    });
  }

  //#region
  //Enter the form and get the dropdown items
  newCustomerSetupForm() {
    this.FormDataEntry = true;
    this.UserEnteredDataDiv = true;
    this.selectLabType = this.SetupNewCustomerform.value.selectLabType;
    this.UserEnteredData.patchValue({
      name: '',
      displayname: '',
      shortname: '',
      npi: 'UNKNOWN',
      clia: null,
      ein: null,
      primarycontactname: null,
      primarycontactnumber: '',
      primarycontactemail: '',
      Status: 1,
      Address1: null,
      Address2: null,
      zip: '',
      phone: '',
      state: '',
      city: null,
      country: 'US',
      fax: '',
      timezone: '',
      billingphonenumber: '',
      logo: null,
      mnemonic: '',
      batchingmnemonic: null,
      taxonomycode: null,
      supportid: null,
      claimoptions: 'Track Claim',
      claimmnemonics: null,
      clpol: null,
      reportdata: '',
      enabledfax: 0,
      sharedsurgicalcenter: 0,
      enabledsendout: 0,
      statelicensenumber: null,
      usernameDisplayFormat: '',
      usernameReportDisplayFormat: '',
      collectiondateformat: 'mm-dd-yyyy',
      packagedateformat: '',
      etadateformat: '',
      payaddress1: null,
      payaddress2: null,
      paycity: null,
      paystate: null,
      payzip: ''
    });
    this.captureNPI(this.UserEnteredData.controls.npi)
    sessionStorage.setItem('deploymentKey', 'configdb');
    let queryVariable = { keyword: 'getDropDownValues' };
    let query = this.SubMenuCardModel.GetQuery('CategoryDropDown');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult).subscribe(async data => {
      this.SetupInformation = data.data.submenuData[0];
      let dropdowndata = JSON.parse(this.SetupInformation.Items_JSON);
      this.StateList = dropdowndata.States;
      this.StateList.forEach(e => {
        // Extract the state name and abbreviation
        const stateName = e.item.substring(0, e.item.lastIndexOf('(')).trim();
        const stateAbbreviation = e.item.substring(e.item.lastIndexOf('(') + 1, e.item.lastIndexOf(')')).trim();

        // Add new formatted key
        e['formattedstate'] = `${stateAbbreviation} (${stateName})`;
        e['itemValue'] = stateAbbreviation; // Assuming you still want itemValue as well
      });
      this.Timezones = dropdowndata.TimeZone;
      this.UsernameWebFormat = dropdowndata.UserNameDisplayFormat;
      this.UsernameReportFormat = dropdowndata.UserNameReportFormat;
      sessionStorage.setItem('deploymentKey', this.SetupNewCustomerform.value.frmDeploymentKey);
      this.allOrglist= await this.commonService.getAllOrganization();
      this.GetUserIdInfo();
    }, error => {
      console.error(error);
      sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
    }
    );
  }
  //#endregion

  GetUserIdInfo() {
    let query = this.vaservices.GetQuery('userroles');
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: { email: localStorage.getItem("UserEmail") },
    };
    this.ngxService.start();
    this.vaservices.GetData(queryRequest).subscribe((data) => {
      this.ngxService.stop();
      if (!data.errors && (data != undefined && data.data.submenuData.length > 0)) {
        let userid = data.data.submenuData[0].userid;
        sessionStorage.setItem('Userid', userid);
      }
      else {
        sessionStorage.setItem('Userid', '');
      }
    },(error) => {
        this.ngxService.stop();
        console.error(error);
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      }
    );
  }
  //#region
  //Generate mnemonic from name value of the form
  generateMnemonic(Name) {
    // Check if the name is not empty or just whitespace
    if (Name.length > 0 && Name.trim() != '') {
        // Remove any trailing punctuation or whitespace
        Name = Name.trim().replace(/[^\w\s]/g, '');
        // Split the name into words
        if (Name.trim().split(' ').length > 1) {
            var matches = Name.match(/\b(\w)/g);
            // Generate an acronym from the first letters of each word
            var acronym = matches[0] + matches[1];
            this.UserEnteredData.controls['mnemonic'].setValue(acronym.trim().toUpperCase());
            this.UserEnteredData.controls['shortname'].setValue(acronym.trim().toUpperCase());
        } else {
            // For a single word, use the first two characters
            this.UserEnteredData.controls['mnemonic'].setValue(Name.trim()
                .toUpperCase()
                .substring(0, 2));
            this.UserEnteredData.controls['shortname'].setValue(Name.trim()
                .toUpperCase()
                .substring(0, 2));
        }
        // Set the display name to the trimmed input
        this.UserEnteredData.controls['displayname'].setValue(Name.trim());
        // Update the value and validity of frm_mnemonic        return; // Exit if processing is complete
    }

    // Reset all fields if the input is empty or just whitespace
    this.UserEnteredData.patchValue({
        shortname: '',
        displayname: '',
        mnemonic: ''
    });
}
  //#endregion

  //#region
  //Validate image file and convert to base64
  getuploadedfile(event) {
    this.UserEnteredData.value.logo = event.target.files[0];
    this.imageupload(this.UserEnteredData.value.logo);
  }
  imageupload(file) {
    this.imageUploaded = false;
    let tempArrData: any;
    let addedFiles: any = [];
    if (file) {
      tempArrData = file;
    } else {
      tempArrData = event[0];
      this.imagePreview = '';
    }
    if (
      tempArrData.type.toLowerCase() == 'image/png' ||
      tempArrData.type.toLowerCase() == 'image/jpeg' ||
      tempArrData.type.toLowerCase() == 'image/jpg'
    ) {
      addedFiles = [];
      addedFiles.push(tempArrData);
      this.files = [];
      this.files.push(addedFiles[0]);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logo_Upload] = this.files;
        reader.readAsDataURL(logo_Upload);
        reader.onload = () => {
          let imagebyte = reader.result as string;
          this.newLogoSrc = imagebyte;
          this.newLogoSrc = this.newLogoSrc.toString().split(',')[1];
          this.imageUploaded = true;
          this.imagePreview = 'data:image/jpg;base64,' + this.newLogoSrc;
        };
      } else {
        this.clearImage();
        this._snackbar.open(
          'An error occurred while processing your request',
          'Close'
        );
      }
    } else {
      this.clearImage();
      this._snackbar.open('Please upload a valid image file.', 'Close');
    }
  }
  //#endregion

  //#region
  //Export Setup information
  downloadSetupDetails() {
    let organizationTab = [];
    let accountTab = [];
    let userTab = [];
    let filename =  'Lab Details_';
    if (this.Lab_Organization_Name) {
      filename = filename + this.Lab_Organization_Name.toString();
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
    let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = filename.split('_')[0].replace(' ','') + '_';
            filename = fileName + orgName + '_';
          }
          else {
            filename = fileName + orgName + '_';
          }
        }
        else {
          filename = filename + '_';
        }
      }
    var sheet = XLSX.utils.book_new();

    //#region
    //organization tab in the excel
    organizationTab.push({
      OrganizationID: this.Lab_Organization_ID,
      OrganizationName: this.Lab_Organization_Name,
      Mnemonic: this.LabMnemonic,
      DisplayName: this.DisplayName,
      GUID: this.Lab_Organization_GUID,
      NPI: this.LabOrgNPI,
      CLIA: this.LabOrgClia,
      Address1: this.Address1,
      Address2: this.Address2,
      City: this.City,
      State: this.State,
      Zip: this.Zip,
      Phone: this.commonService.changeAdminContactPhone(this.Phone),
      No_of_locations: this.LabNo_of_Locations,
      No_of_users: this.LabNo_of_users
    });

    organizationTab.push({
      OrganizationID: this.OF_Organization_ID,
      OrganizationName: this.OF_Organization_Name,
      Mnemonic: this.OFMnemonic,
      DisplayName: this.DisplayName,
      GUID: this.OF_Organization_GUID,
      NPI: this.OFOrgNPI,
      CLIA: this.OFOrgClia,
      Address1: this.Address1,
      Address2: this.Address2,
      City: this.City,
      State: this.State,
      Zip: this.Zip,
      Phone: this.commonService.changeAdminContactPhone(this.Phone),
      No_of_locations: this.LabNo_of_Locations,
      No_of_users: this.OFNo_of_users
    });

    var sheetData1 = XLSX.utils.json_to_sheet(organizationTab);
    XLSX.utils.book_append_sheet(sheet, sheetData1, 'organizations');
    //#endregion

    //#region
    //account tab in the excel
    accountTab.push({
      AccountID: this.Lab_Account_ID,
      AccountName: this.Lab_Account_Name,
      Mnemonic: this.OFMnemonic,
      DisplayName: this.DisplayName,
      GUID: this.Lab_Account_GUID,
      NPI: this.LabOrgNPI,
      CLIA: this.LabOrgClia,
      Address1: this.Address1,
      Address2: this.Address2,
      City: this.City,
      State: this.State,
      Zip: this.Zip,
      Phone: this.commonService.changeAdminContactPhone(this.Phone)
    });

    accountTab.push({
      AccountID: this.OF_Account_ID,
      AccountName: this.OF_Account_Name,
      Mnemonic: this.OFMnemonic,
      DisplayName: this.DisplayName,
      GUID: this.OF_Account_GUID,
      NPI: this.LabOrgNPI,
      CLIA: this.LabOrgClia,
      Address1: this.Address1,
      Address2: this.Address2,
      City: this.City,
      State: this.State,
      Zip: this.Zip,
      Phone: this.commonService.changeAdminContactPhone(this.Phone)
    });

    var sheetData2 = XLSX.utils.json_to_sheet(accountTab);
    XLSX.utils.book_append_sheet(sheet, sheetData2, 'locations');
    //#endregion

    //#region
    //user tab in the excel;
    userTab.push({
      UserId: this.Lab_UserID,
      UserName: this.Lab_UserName,
      Roles: this.Lab_UserRoles,
      Email: this.Lab_UserEmail
    });

    userTab.push({
      UserId: this.OF_UserID,
      UserName: this.OF_UserName,
      Roles: this.OF_UserRole,
      Email: this.OF_UserEmail
    });
    let name  = organizationTab[0].OrganizationID == organizationTab[0].OrganizationID ? organizationTab[0].OrganizationID : accountTab[0].AccountID;
     filename = filename + name+ '.xlsx' ;
     if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(name){
        filename = fileName  + name+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    var sheetData3 = XLSX.utils.json_to_sheet(userTab);
    XLSX.utils.book_append_sheet(sheet, sheetData3, 'users');
    //#endregion
    XLSX.writeFile(sheet, filename);
  }
  //#endregion

  //#region
  //Back to selecting deployment
  deploymentSelection() {
    this.imagePreview = '';
    this.validationWarning = false;
    this.zipValid = true;
    this.payZipValid = true;
    this.submitted = false;
    this.imageUploaded = false;
    this.UserEnteredData.reset();
    this.files = [];
    this.emailValid = true;
    this.FormDataEntry = false;
    this.UserEnteredDataDiv = false;
    // this.SetupNewCustomerform.controls['frmDeploymentKey'].setValue(
    //   sessionStorage.getItem( 'deploymentKey'),
    //   { onlySelf: true }
    // );
    // this.UserEnteredData.controls['claimoptions'].setValue('Track Claim', {onlySelf: true});
    // this.UserEnteredData.patchValue({
    //   name: '',
    //   displayname: '',
    //   shortname: '',
    //   npi: '',
    //   clia: null,
    //   ein: null,
    //   primarycontactname: null,
    //   primarycontactnumber: null,
    //   primarycontactemail: null,
    //   Status: 1,
    //   Address1: null,
    //   Address2: null,
    //   zip: '',
    //   phone: null,
    //   state: '',
    //   city: null,
    //   country: null,
    //   fax: null,
    //   timezone: '',
    //   billingphonenumber: null,
    //   logo: null,
    //   mnemonic: '',
    //   batchingmnemonic: null,
    //   taxonomycode: null,
    //   supportid: null,

    //   claimmnemonics: null,
    //   clpol: null,
    //   reportdata: '',
    //   enabledfax: 0,
    //   sharedsurgicalcenter: 0,
    //   enabledsendout: 0,
    //   statelicensenumber: null,
    //   usernameDisplayFormat: '',
    //   usernameReportDisplayFormat: '',
    //   collectiondateformat: null,
    //   packagedateformat: null,
    //   etadateformat: null,
    //   payaddress1: null,
    //   payaddress2: null,
    //   paycity: null,
    //   paystate: null,
    //   payzip: ''
    // });
    // this.UserEnteredData.reset();
  }
  //#endregion

  clearImage() {
    (document.getElementById('myFileInputID') as HTMLInputElement).value = null;
    this.imagePreview = '';
    this.imageUploaded = false;
    this.UserEnteredData.patchValue({
      logo: ''
    });
    this.files = [];
    // return false;
  }

  validateEmail(Email) {
    if (Email && Email.trim() != '') {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      // if (!reg.test(Email)) { //user ternary operator for this if condition.
      //   this.emailValid = false;
      // } else {
      //   this.emailValid = true;
      // }
      this.emailValid = !reg.test(Email) ? false : true;
    }
  }

  ValidateFax(fax : string) {
    if (fax && fax.trim() != '') {
      var regex = new RegExp(/\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$/);
      this.faxValid = regex.test(fax) ? true : false;
    }
  }

  zipCodeValidation(zip) {

    // if (zip != '' && zip.length >= 5) {
    //   this.ZipValidation = true;
    //   var zipValidator = /^\d{5}([\-]\d{4})?$/;
    //   if (zipValidator.test(zip)) { //ternary operator.
    //     this.zipValid = true;
    //   } else {
    //     this.zipValid = false;
    //   }
    // } else {
    //   this.zipValid = true;
    // }
    if (zip && zip.trim() != '') {
      this.ZipValidation = true;
      var zipValidator = /^\d{5}([\-]\d{4})?$/;
      this.zipValid = zipValidator.test(zip) ? true : false;
    }

  }
  payZipCodeValidation(zip) {

    if (zip && zip.trim() != '') {
      this.payZipValidation = true;
      var zipValidator = /^\d{5}([\-]\d{4})?$/;
      this.payZipValid = (zipValidator.test(zip)) ? true : false;
    }
    // var zipValidator = /^\d{5}([\-]\d{4})?$/;
    // this.payZipValidation = (zip != '' && zip.length >= 5) ?  true : false
    // this.payZipValid = this.payZipValidation == true ? (zipValidator.test(zip) ? true : false) : true;
  }

  captureNPI(NPI) {
    this.triggerNPIPopup();
    this.NPI = NPI;
    // if (this.NPI != '' && this.NPI.toLowerCase() != 'unknown') {
    //   this.npiValidated = false;
    // }
    this.validationWarning = false;
    this.NPIWarning = false;
    this.npiValidated = !(this.NPI && this.NPI.toString().toLowerCase() != 'unknown');

  }

  NPIValidation() {
    this.validationWarning = false;
    this.NPI = this.NPI.toString().trim();
    this.ngxService.start();
    if (this.NPI != '' && this.NPI.toLowerCase() != 'unknown') {
      if( this.NPI.length ==10){
        this.npiValidated = true;
        this.NPIWarning=false;
      }
      else{
        this.npiValidated = false;
        this.NPIWarning=true;
      }
      this.ngxService.stop();
      // var value = {
      //   npi: this.NPI
      // };
      // this.npiValidated = true;
      // this.vaservices.NpiValidation(value).subscribe(
      //   Info => {
      //     this.ngxService.stop();
      //     this.NPIWarning = Info.result_count == 0 ? true : false;
      //   },error => {

      //     this.ngxService.stop();
      //     this.NPIWarning = true;
      //   }
      // );
    } else {
      this.ngxService.stop();
      this.NPIWarning = false;
    }
  }

  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open('', 'Copied');
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /^[0-9*#+. -]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  //#region
  //Update form values and send them for insertion
  quicksetupnewlab(UserEnteredData,isSave?) {
    this.submitted = true;
    this.ngxService.start();
    // if (
    //   !sessionStorage.getItem('Userid') ||
    //   sessionStorage.getItem('Userid') == ''
    // ) {
    //   this._snackbar.open('You are not authorized for this action','Close')
    //   this.SetupNewCustomerClose();
    //   this.ngxService.stop();
    //   return
    // }
    UserEnteredData.primarycontactemail == '' ? true : this.validateEmail(UserEnteredData.primarycontactemail);
    (UserEnteredData.zip) == '' ? true : this.zipCodeValidation(UserEnteredData.zip);
    (UserEnteredData.payzip) == '' ? true : this.payZipCodeValidation(UserEnteredData.payzip)
    if (UserEnteredData.npi.toString() != '' && UserEnteredData.npi.toString().toLowerCase() != 'unknown') {
      this.validationWarning = this.npiValidated == true ? false : true;
    }
    else {
      this.validationWarning = false;
    }
    if (
      UserEnteredData.name.trim() == '' ||
      UserEnteredData.displayname.trim() == '' ||
      UserEnteredData.shortname.trim() == '' ||
      UserEnteredData.mnemonic.trim() == '' ||
      this.validationWarning == true ||
      this.emailValid == false ||
      this.NPIWarning == true ||
      this.zipValid == false ||
      this.payZipValid == false
    ) {
      if (
        UserEnteredData.name.trim() == '' ||
        UserEnteredData.displayname.trim() == '' ||
        UserEnteredData.shortname.trim() == '' ||
        UserEnteredData.mnemonic.trim() == ''
      ) {
        this._snackbar.open('Please enter required data!', 'Close');
      } else {
        this._snackbar.open('Please enter valid data!', 'Close');
      }
      this.ngxService.stop();
      return;
    }
    if(UserEnteredData.state){
      let temp_state = this.StateList.find(va => va.item == UserEnteredData.state || va.item?.includes('('+UserEnteredData.state+')'))?.item;
      if (!temp_state) {
        this._snackbar.open("Please Select Valid State", "Close");
        this.ngxService.stop()
        return;
      }
      else{
        UserEnteredData.state = temp_state;
      }
    }
    if (UserEnteredData.paystate) {
      let temp_paystate = this.StateList.find(va => va.item == UserEnteredData.frm_paystate || va.itemValue == UserEnteredData.frm_paystate)?.itemValue;
      if (!temp_paystate) {
        this._snackbar.open("Please Select Valid Payment State", "Close");
        this.ngxService.stop()
        return;
      }
      else {
        UserEnteredData.paystate = temp_paystate;
      }
    }
    if(UserEnteredData.timezone){
      let temp_timezone = this.Timezones.find(va => va.item == UserEnteredData.timezone)?.item;
      if (!temp_timezone) {
        this._snackbar.open("Please Select Valid TimeZone", "Close");
        this.ngxService.stop()
        return;
      }
    }

    if(this.UserEnteredData.controls.phone && this.UserEnteredData.controls.phone.status.toString().toLowerCase() == 'invalid'){
      this._snackbar.open("Please Enter Valid Phone.", "Close");
      this.ngxService.stop()
      return;
    }
    if(this.UserEnteredData.controls.primarycontactnumber && this.UserEnteredData.controls.primarycontactnumber.status.toString().toLowerCase() == 'invalid'){
      this._snackbar.open("Please Enter Valid Primary Contact Number.", "Close");
      this.ngxService.stop()
      return;
    }

    if(UserEnteredData.mnemonic && (this.formcontrol.mnemonic.errors?.noSpace || this.formcontrol.mnemonic.errors?.noNumber ||this.formcontrol.mnemonic.errors?.noSpecialChar)){
      this._snackbar.open("Please Enter Valid Mnemonic.", "Close");
      this.ngxService.stop()
      return;
    }
    if(this.UserEnteredData.controls.fax && this.UserEnteredData.controls.fax.status.toString().toLowerCase() == 'invalid'){
      this._snackbar.open("Please Enter Valid Fax.", "Close");
      this.ngxService.stop()
      return;
    }
    // UserEnteredData.usernameDisplayFormat = this.convertUsernameFormat(UserEnteredData.usernameDisplayFormat);
    // UserEnteredData.usernameReportDisplayFormat = this.convertUsernameFormat(UserEnteredData.usernameReportDisplayFormat);
    let statedisplayname = this.StateList.find(e => e.itemValue === ((this.UserEnteredData.value.state?.split('('))[1])?.split(')')[0])
    var obj = {
      Labuserloginname: UserEnteredData.name.trim(),
      Labuserfirstname: localStorage.getItem('user_name').toString().split(' ')[0],
      Labuserlastname: localStorage.getItem('user_name').toString().split(' ')[1],
      // Labuseremail: localStorage.getItem("UserEmail").toString(),
      Labuseremail: localStorage.getItem("UserEmail").toString(),
      usernpi: UserEnteredData.npi,
      Labuserstatus: 'Active',
      OFuserfirstname: 'Pending Physician',
      OFuserlastname: UserEnteredData.name.trim(),
      OFuseremail: localStorage.getItem("UserEmail").toString(),
      OFuserstatus: 'Active',
      OFuserloginname: '',
      accountype: null,
      name: UserEnteredData.name.trim(),
      displayname: UserEnteredData.displayname.trim(),
      shortname: UserEnteredData.shortname.trim(),
      NPI:
        UserEnteredData.npi.toString() == '' || UserEnteredData.npi == null
          ? 'UNKNOWN'
          : UserEnteredData.npi,
      CLIA: UserEnteredData.clia,
      EIN: UserEnteredData.ein,
      primarycontactname: UserEnteredData.primarycontactname,
      primarycontactnumber: this.commonService.changeAdminContactPhone(UserEnteredData?.primarycontactnumber),
      primarycontactemail: UserEnteredData.primarycontactemail,
      Status: UserEnteredData.Status == true ? 'Active' : 'Inactive',
      Address1: UserEnteredData.Address1,
      Address2: UserEnteredData.Address2,
      Zip: UserEnteredData.zip,
      Phone: this.commonService.changeAdminContactPhone(UserEnteredData?.phone),
      State: UserEnteredData.state != '' ? ((this.UserEnteredData.value.state.split('('))[1]).split(')')[0] : null,
      StateDisplayName: statedisplayname?.formattedstate,
      City: UserEnteredData.city,
      Country: UserEnteredData.country,
      Fax: this.commonService.changeAdminFaxNumber(UserEnteredData?.fax),
      timezone: UserEnteredData.timezone,
      Billingphonenumber: UserEnteredData.billingphonenumber,
      Logo: this.newLogoSrc == "" ? null : this.newLogoSrc,
      Mnemonic: UserEnteredData.mnemonic?.toUpperCase(),
      BatchingMnemonic: UserEnteredData.billingmnemonic,
      TaxonomyCode: UserEnteredData.taxonomycode,
      SupportID: UserEnteredData.supportid,
      ClaimOptions: UserEnteredData.claimoptions,
      ClaimMnemonic: UserEnteredData.claimmnemonics,
      CLPOL: UserEnteredData.clpol,
      ReportData: UserEnteredData.reportdata,
      EnabledFax: UserEnteredData.enabledfax == true ? 1 : 0,
      SharedSurgicalCenter:
        UserEnteredData.sharedsurgicalcenter == true ? 1 : 0,
      EnableSendOut: UserEnteredData.enabledsendout == true ? 1 : 0,
      StateLicenseNumber: UserEnteredData.statelicensenumber,
      UsernameDisplayWeb: UserEnteredData.usernameDisplayFormat ? UserEnteredData.usernameDisplayFormat : null,
      UsernameDisplayReports: UserEnteredData.usernameReportDisplayFormat ? UserEnteredData.usernameReportDisplayFormat : null,
      CollectionDateFormat: UserEnteredData.collectiondateformat,
      PackageDateFormat: UserEnteredData.packagedateformat,
      ETADateFormat: UserEnteredData.etadateformat,
      CreatedBy: sessionStorage.getItem('Userid'),
      PayAddress1: UserEnteredData.payaddress1,
      PayAddress2: UserEnteredData.payaddress2,
      PayCity: UserEnteredData.paycity,
      PayState: UserEnteredData.paystate,
      PayZip: UserEnteredData.payzip,
      mdmproviderid: this.mdmProviderId,
      sessiondata: {
        applicationdomain: environment.client,
        loggedinuserorgname: '',
        loggedinuserorgguid: '',
        loggedinuserordid: '',
        accountorgguid: '',
    }

    };

    if(!this.validateOrgName(UserEnteredData.name.trim()) || isSave){
      sessionStorage.setItem('deploymentKey', this.SetupNewCustomerform.value.frmDeploymentKey);
      this.vaservices.QuickCreateLab(obj, this.SetupNewCustomerform.value.frmDeploymentKey).subscribe(
        res => {
          sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
          if (!res.errors) {
            if (res[0].StatusMessage == 'Successful') {
              this.Lab_Organization_ID = res[0].Organization_ID;
              this.Lab_Organization_Name = res[0].Organization_Name;
              this.OF_Organization_ID = res[3].Organization_ID;
              this.OF_Organization_Name = res[3].Organization_Name;
              this.Lab_Account_ID = res[0].Account_ID;
              if (res[0].logo != '') {
                this.Logo = 'data:image/jpg;base64,' + res[0].logo;
              } else {
                this.Logo = '';
              }
              this.LabMnemonic = res[0].Mnemonic;
              this.OFMnemonic = res[3].Mnemonic;
              this.Address1 =
                res[0].Address1 == null || res[0].Address1 == ''
                  ? 'Not Specified'
                  : res[0].Address1;
              this.Address2 =
                res[0].Address2 == null || res[0].Address2 == ''
                  ? 'Not Specified'
                  : res[0].Address2;
              this.City =
                res[0].City == null || res[0].City == ''
                  ? 'Not Specified'
                  : res[0].City;
              this.State =
                res[0].State == null || res[0].State == ''
                  ? 'Not Specified'
                  : res[0].State;
              this.Zip =
                res[0].Zip == null || res[0].Zip == ''
                  ? 'Not Specified'
                  : res[0].Zip;
              this.Phone =
                res[0].Phone == null || res[0].Phone == ''
                  ? 'Not Specified'
                  : this.commonService.changeAdminContactPhone(res[0]?.Phone);
              this.DisplayName =
                res[0].DisplayName == null || res[0].DisplayName == ''
                  ? 'Not Specified'
                  : res[0].DisplayName;
              this.Lab_Organization_GUID =
                res[0].OrgGUID == null || res[0].OrgGUID == ''
                  ? 'Not Specified'
                  : res[0].OrgGUID;
              this.LabOrgNPI =
                res[0].NPI == null || res[0].NPI == ''
                  ? 'Not Specified'
                  : res[0].NPI;
              this.LabOrgClia =
                res[0].CLIA == null || res[0].CLIA == null
                  ? 'Not Specified'
                  : res[0].CLIA;
              this.LabNo_of_Locations =
                res[0].Number_of_locations == null
                  ? 'Not Specified'
                  : res[0].Number_of_locations;
              this.OF_Organization_GUID =
                res[0].OrgGUID == null ? 'Not Specified' : res[3].OrgGUID;
              this.OFOrgNPI =
                res[0].NPI == null || res[0].NPI == ''
                  ? 'Not Specified'
                  : res[0].NPI;
              this.OFOrgClia =
                res[0].CLIA == null || res[0].CLIA == ''
                  ? 'Not Specified'
                  : res[0].CLIA;
              this.OFNo_of_users = res[3].Number_of_users;
              this.LabNo_of_users = res[0].Number_of_users + 2;
              this.Lab_Account_GUID = res[0].Account_GUID;
              this.Lab_Account_Name = res[0].Account_Name;
              this.OF_Account_ID = res[3].Account_ID;
              this.OF_Account_GUID = res[3].Account_GUID;
              this.OF_Account_Name = res[3].Account_Name;
              this.Lab_UserName = res[1].UserName;
              this.Lab_UserRoles = res[0].Role + ',' + res[1].Role + ',' + res[2].Role;
              this.Lab_UserID = res[1].UserLoginName;
              this.Lab_UserEmail = localStorage.getItem("UserEmail").toString();
              this.Lab_UserPassword = res[4].LabuserPassword;
              this.OF_UserName = res[3].UserName;
              this.OF_UserRole = res[3].Role;
              this.OF_UserID = res[3].UserLoginName;
              this.OF_UserEmail = localStorage.getItem('UserEmail').toString();
              this.OF_UserPassword = res[4].OFuserPassword;
              this.CaseType = res[0].Case_Type;

              this.UserEnteredDataDiv = false;
              this.resultPage = true;
              this._snackbar.open('Successfully setup.', 'Close');
            } else {
              this._snackbar.open(
                res[0].StatusMessage + ' Please try again.',
                'Close'
              );
            }
          } else {
            this._snackbar.open(
              'Something went wrong. Please try again.',
              'Close'
            );
          }
          this.ngxService.stop();
        },error => {
          this.ngxService.stop();
          sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
          console.error(error);
          this._snackbar.open('Something went wrong. Please try again.', 'Close');
        }
      );
    }else{
      this.ngxService.stop();
      const popUpHeader="Confirmation";
      const popUpMessage = `There is already another 'Laboratory' with the same name. Do you wish to create again?`;
      const continueBtn="Yes"
      const dialogRef=this.opendialog.openDialog(popUpHeader,popUpMessage,'',continueBtn,"No")
      dialogRef.afterClosed().subscribe(res=>{
        if(res==true){
          this.quicksetupnewlab(UserEnteredData,res)
        }
      })
    }
    // sessionStorage.setItem( 'deploymentKey', this.vaservices.deploymentKey);

  }
  validateOrgName(tempOrgName) {
    let duplicateFound = false;
      duplicateFound = this.allOrglist.some(va => va.organizationname.trim().toLowerCase() === tempOrgName.trim().toLowerCase());
    return duplicateFound;
  }


// NPI popup
openSearchNPIPopup() {
  let labDepKey = this._labadminservice.deploymentKey;
  try{
    this._labadminservice.deploymentKey = this.SetupNewCustomerform.value.frmDeploymentKey.toString()
    let searchValues =  this.UserEnteredData.value.npi ? this.UserEnteredData.value.npi : this.UserEnteredData.value.name;
    if(searchValues != null){
      searchValues = searchValues.toString().trim();
    }
    this.opendialog
      .openNPIDailog('NPI Details', String(sessionStorage.getItem("org_id")), this.checkIsDataPopulated(), {searchValue:searchValues}
      )
      .afterClosed()
      .subscribe({
        next: (res) => {
          this._labadminservice.deploymentKey = labDepKey;
          if (res) {
            this.mdmProviderId = res.mdmId
            this.UserEnteredData.patchValue({
              npi: res.npi,
              name: res.accountName,
              city: res.city,
              zip: res.zip,
              Address1: res.address1,
              Address2:res.address2,
              phone: this.commonService.changeAdminContactPhone(res?.primaryContactPhone),
              fax:this.commonService.changeAdminFaxNumber(res?.fax)
            });
            this.NPI = res.npi;
            const selectedState = this.StateList?.find(state => state.item.includes(`(${res.state})`));
            if (selectedState) {
              this.UserEnteredData.get("state").setValue(selectedState.item);
            }
            else{
              this.UserEnteredData.get("state").setValue('');
            }
            this.generateMnemonic(this.UserEnteredData.value.name)
            this.NPIValidation()
          }
        },
        error: (err) => {
          this._labadminservice.deploymentKey = labDepKey;
          console.error(err);
        },
      });
  }
  catch(err){
    this._labadminservice.deploymentKey = labDepKey;
    console.error(err);
  }
}
checkIsDataPopulated() {
  return (
    (this.UserEnteredData.controls.city.value != '' && this.UserEnteredData.controls.city.value != null) ||
    (this.UserEnteredData.controls.state.value != '' && this.UserEnteredData.controls.state.value != null) ||
    (this.UserEnteredData.controls.Address1.value != '' && this.UserEnteredData.controls.Address1.value != null) ||
    (this.UserEnteredData.controls.Address2.value != '' && this.UserEnteredData.controls.Address2.value != null) ||
    (this.UserEnteredData.controls.phone.value != '' && this.UserEnteredData.controls.phone.value != null) ||
    (this.UserEnteredData.controls.zip.value != '' && this.UserEnteredData.controls.zip.value != null)
  );
}


triggerNPIPopup() {
  this.mdmProviderId = 0;
  const currentNPIValue = String(this.UserEnteredData.controls.npi.value);
  if(currentNPIValue == ''){
    this.previousNPIValue = '';
  }
  if (
    currentNPIValue.length === 10 &&
    currentNPIValue !== String(this.previousNPIValue)
  ) {
    this.previousNPIValue = currentNPIValue;
    this.openSearchNPIPopup();
  }


}

downloadLogo() {
  const link = document.createElement('a');
  link.href = this.Logo;
  link.download = 'logo.png';
  link.click();
}

  //#endregion
  getStatesList(state) {
    if (!state) {
      return this.StateList
    }
    return this.StateList.filter(va => va?.item && state && va.item?.toString()?.toLowerCase()?.includes(state?.toString()?.toLowerCase()));
  }

  filterState(account: string,col) {
    this.UserEnteredData.patchValue({
      [col]: account
    })
    this.StateList = cloneDeep(this.StateList)

    if (!account) {
      return this.StateList
    }

  }
  refreshStateSearch(col) {
    this.UserEnteredData.patchValue({
      [col]: ''
    })
    // this.StateList = cloneDeep(this.StateList)
  }

  getTimeZoneList(timezone) {
    if (!timezone) {
      return this.Timezones
    }
    return this.Timezones.filter(va => va?.item && timezone && va.item?.toString()?.toLowerCase()?.includes(timezone?.toString()?.toLowerCase()));
  }

  filterTimeZone(account: string,col) {
    this.UserEnteredData.patchValue({
      [col]: account
    })
    this.Timezones = cloneDeep(this.Timezones)

    if (!account) {
      return this.Timezones
    }

  }

  removeTrailCharacter(controlName: any) {
    this.commonService.removeTrailCharacter(controlName);
  }
}


