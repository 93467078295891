<h3 class="modal-title w-100 admin-model-header-txt" *ngIf="gridPage && sourceApp !=='VitalDx'">
  <strong class="header-View">Sites</strong>
</h3>
<mat-tab-group #tabGroup (selectedTabChange)="onTabChange($event)" class="pt-2 sites-tab"  [selectedIndex]="selectedTabIndex" [dynamicHeight]="'true'"
   animationDuration="0ms" *ngIf="gridPage && sourceApp !=='VitalDx'">
  <mat-tab label="Sites"></mat-tab>
  <mat-tab label="Site Format" [disabled] = "totalRecordCount == 0"></mat-tab>
  <mat-tab label="Site Order" [disabled] = "totalRecordCount == 0"></mat-tab>
</mat-tab-group>

<!-- Manage Sites -->
<ng-container *ngIf="selectedTabIndex === 0">
<app-spinner-loader *ngIf="sourceApp =='VitalDx' && isSaveDisabled" [isFromTemplateForm]="true"></app-spinner-loader>
<div class="px-4" *ngIf="sourceApp =='VitalDx' && showShimmer">
  <app-shimmerui [line] = 8></app-shimmerui>
</div>
<div *ngIf="gridPage && !showShimmer" class="col-sm-12 mt-1"  [class.padding-0]="sourceApp == 'VitalDx'">
    <!-- <div class="modal-header admin-model-header mb-2 mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong>Sites</strong>
      </h3>
    </div> -->
    <!-- <wj-flex-grid [ngStyle]="{ 'width.px': gridwidth }" class="align-center" #grid [isReadOnly]="true"
      [headersVisibility]="'Column'" (initialized)="initGrid(grid)" [itemsSource]="sitesGridData">

      <wj-flex-grid-column [header]="'Action'" align="center" [width]="111" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
          <button [disabled]="editBtn" class="edit-delete-btn" (click)="openEditScreen(grid, $event)">
            <em id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'OrganizationSiteID'" [visible]="false" [allowSorting]="true" [header]="'OrganizationSiteID'"
      [width]="'*'" [minWidth]="100" [format]="'d'"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'Siteid'" [visible]="false" [allowSorting]="true" [header]="'Siteid'"
        [width]="'*'" [minWidth]="100" [format]="'d'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 3" [binding]="'SubSite'" [allowSorting]="true" [header]="'Body Site'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>

      <wj-flex-grid-column [visible]="false" *ngIf="siteFormat == 1" [binding]="'EntityType'" [header]="'Site Type'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 1" [binding]="'EntityName'" [allowSorting]="true"
        [header]="'Site Name'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>

      <wj-flex-grid-column [visible]="false" *ngIf="siteFormat == 2" [binding]="'EntityName'" [allowSorting]="true"
        [header]="'Body Site'" [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'BodySite'" [allowSorting]="true" [header]="'Body Site'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column [visible]="false" [binding]="'SiteShortname'" [allowSorting]="true" [header]="'Short name'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'SubSite'" [allowSorting]="true" [header]="'Sub Site'"
        [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'SiteOrder'" [allowSorting]="true" [header]="'Site Order'" [isRequired]="true"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
        [binding]="'Type'" [allowSorting]="true" [header]="'Type'" [isRequired]="true" [width]="'*'">
      </wj-flex-grid-column>
      <wj-flex-grid-column *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
        [binding]="'MappingSite'" [allowSorting]="true" [header]="'Mapping Site'" [isRequired]="true" [width]="'*'"
        [minWidth]="100"></wj-flex-grid-column>
      <wj-flex-grid-column [binding]="'IsActive'" [allowSorting]="true" [header]="'Status'" [isRequired]="true"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'CreatedBy'" [visible]="false" [allowSorting]="true" [header]="'CreatedBy'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'CreatedDate'" [visible]="false" [allowSorting]="true" [header]="'CreatedDate'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'ModifiedBy'" [visible]="false" [allowSorting]="true" [header]="'ModifiedBy'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
        <wj-flex-grid-column [binding]="'ModifiedDate'" [visible]="false" [allowSorting]="true" [header]="'ModifiedDate'"
        [width]="'*'" [minWidth]="100"></wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid> -->
    <app-templates-listing *ngIf="siteFormat == 3"[gridHeader]="gridheaderList"
      [gridData]="sitesGridData" [context]="'Sites'" [hideCreate]="createBtn" [hideEdit]="editBtn"
      [hideUpload]="uploadBtn" [hideExport]="exportBtn" [hideCopy]="copyBtn"
      (CreateTemplateClicked)="getInputData('', [])" (editTemplateClicked)="openEditScreen($event)" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
      [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="false" (uploadTemplateEventTriggered)="loadUploadScreen()"
      [isAdmin3]="sourceApp =='VitalDx' ? false : true" [customListActiveTabs]=true (copyTemplateEventTriggered)=" loadCopyScreen()"
      (migrateEventTriggered)="OpenMangeSite()" (emitFilters)="emitFilters($event)" [selectedCaseType]="selectedCaseType"
      [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null" [searchBarWidth]="'col-sm-5'">
      //[exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null" [searchBarWidth]="'col-sm-5'">
    </app-templates-listing>
    <app-templates-listing2 *ngIf="siteFormat == 2 || siteFormat == 1" [gridHeader]="gridheaderList" [gridData]="sitesGridData"
      [groupBy]="groupBy" (createEmitter)="sitesCreation($event)" (copyEmitter)="loadCopyScreen()" [showHeaderModule]="false"
      (editEmitter)="openEditScreen($event)" [templateData]="templateData" (gridRefreshEmitter)="dragDrop = false;refreshGrid()"
      (dragDropEmitter)="dragDropItem($event)"  [context]="'Sites'" [isAdmin3]="sourceApp =='VitalDx' ? false : true"
      [dragDrop]="false" [sortAZ]="sortAZ" [hideEdit]="editBtn" [hideCreate]="createBtn"
      [hideUpload]="uploadBtn" [hideExport]="exportBtn" [hideCopy]="copyBtn" [hideDelete]="true"
      (manageSiteOrderEventTriggered)="OpenMangeSite()" [caseTypeList]="caselist" (emitFilters)="emitFilters($event)" [selectedCaseType]="selectedCaseType"
      [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null" [searchBarWidth]="'col-sm-5'">
    </app-templates-listing2>
</div>

<div *ngIf="uploadClicked" class="my-sites-form">
  <div class="col-md-12 p-0 row">
    <div class="modal-header admin-model-header mt-2 listingModalHeader col-sm-6">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong class="header-View">Upload Sites</strong>
      </h3>
    </div>
    <div class="searchbox-align col-sm-6 mt-2" *ngIf="gridWidth > 0">
      <div class="global-search-panel d-flex col-sm-8 p-0">
        <div style="display: flex; margin-right: 3px;margin-left: 4px;">
          <mat-icon class="search-icon">search</mat-icon>
        </div>
        <div class="standing-line">
        </div>
        <input type="text" class="search-input" [placeholder]="'Search Site'" [(ngModel)]="searchText"
          (keyup)="filtertemplates()" [ngModelOptions]="{standalone: true}">
        <ng-container *ngIf="searchText">
          <div class="standing-line">
          </div>
          <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
            <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="gridWidth == 0">
    <div class="button-field-wrap m-1 p-0" >
      <mat-form-field class="col-sm-3 example-additional-selection p-0 copyfrmOrg-dropdown" appearance="outline">
        <mat-label class="d-flex">
          Templates
        </mat-label>
        <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
        <mat-select isableOptionCentering class="ml-2 temp-mat-align">
          <mat-option class="temp-font-size" (click)="downloadFormatFields('min', true)">
            <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
          </mat-option>
          <mat-option class="temp-font-size" (click)="downloadFormatFields('all', true)">
            <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
          </mat-option>
          <mat-option class="temp-font-size" (click)="downloadFormatFields('update', true)">
            <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="button-field-wrap">
      <span class="instr-size mb-2">
        <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
        You can download the sample template file to upload the Sites.</span>
    </div>
  </div>
  <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
      <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
        <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
          hidden />
        <span class="align-center img-wrap">
          <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto" />
        </span>
        <span>Drag & Drop excel files here</span>
        <span class="align-center">Or</span>
        <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
      </div>
      <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
        Back
      </button>
  </div>

  <div *ngIf="gridWidth > 0">

    <div class="parent-div mt-2 table-align">
      <div class="header d-flex p-0 mb-1 table-content" *ngIf="excelDataArray?.length" >
        <div class="header-wrap" style="width: auto !important;">
          <span class="buttonClass" *ngIf="!postUpload">
            <div class="dataTable"></div>
          </span>
          <span class="widthClass" *ngFor="let header of sheetHeader">
            <div class="dataTable valueDiv">{{displayHeader(header) | stripHtml }}</div>
          </span>
        </div>
      </div>
      <div class="container-fluid header-wrap p-0">
        <div cdkDropList cdkDropListOrientation="vertical" class="tableScroll" style="width: fit-content !important;">
          <span *ngIf="DataArray?.length > 0">
            <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'"
              [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive')?'card-data mt-1 ml-0  mb-1 inactive-class':'card-data mt-1 ml-0  mb-1'"
              *ngFor="let item of DataArray | searchfiltersTemplate: templatefilter">
              <mat-icon *ngIf="!postUpload" class="actions buttonClass pl-2" matTooltip="Delete"
                (click)="deleteRow(item)">delete</mat-icon>
              <span class="text-muted text-ellipsis  widthClass" *ngFor="let header of sheetHeader">
                <div *ngIf="header != 'Notes' && header != 'Status'" class="valueDiv dataTable" [matTooltip]="toolTipText"
                  (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                  {{ displayedColumns(item,header?.toLowerCase()) | stripHtml }}</div>
                <div *ngIf="header == 'Notes' || header == 'Status'"
                  [ngClass]="item['isvalid']==1 || item['status']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||item['isvalid']==0 || item['status']== 'Ignored'  ? 'text-danger pl-1':null"
                  class="valueDiv dataTable" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                  {{ displayedColumns(item,header?.toLowerCase()) | stripHtml }}</div>
              </span>
            </div>
          </span>
          <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
            <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
          </div>
          <div class="col-sm-12 nodata-header-wrap"
            *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
            <span class="col-sm-12 nodata-wrapper"> No Results </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row pl-3">
      <div class="col-sm-2 p-0 mt-4">
        <button mat-raised-button class="admin-btn-success mr-4 mt-1" (click)="removeGrid()">
          Back
        </button>
      </div>
      <div class="col-sm-10 button-wrap p-0 mt-4">
        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="refreshGrid('list')">
            Finish
          </button>
          <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="refreshGrid('list')">
            Cancel
          </button>
          <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload" class="admin-btn-success mr-4"
            (click)="uploadSitesData()">
            Approve
          </button>
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportResult(grid)">
            Download
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">* Invalid Columns: </div>
    &nbsp;<span>{{ invalidColumns }} </span>
  </div>
</div>

<!--Copy from other entity-->
<div *ngIf="copyDataClicked">
  <div class="col-md-12 p-0">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong class="header-View">Copy Sites</strong>
      </h3>
    </div>
  </div>
  <form [formGroup]="copycollectionMethodForm" class="copy-collection">
    <div class="row col-sm-12  p-0 ml-0" >
      <mat-form-field class="col-sm-4 example-additional-selection copyfrmOrg-dropdown" appearance="outline">
        <mat-label>Deployment</mat-label>
        <mat-select disableOptionCentering type="text" matInput (selectionChange)="onChangeDeployment($event)"
          #searchbar formControlName="frmDepKey">
          <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{ i }}">
            <span>{{ i }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-sm-4 example-additional-selection copyfrmOrg-dropdown" appearance="outline">
        <mat-label>Group <span class="error-msg">*</span> </mat-label>
        <em class="fa fa-chevron-down chevron-align"
          (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"></em>
        <input [readonly]="postUpload" formControlName="frmOrganization" type="text" matInput #searchbar
          (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"
          (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization); trimFieldValue('frmOrganization')"
          [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;" />
        <mat-autocomplete #orgauto="matAutocomplete">
          <div *ngFor="let show of searchResult">
            <mat-option [disabled]="postUpload" class="mat-opt-align"
              title="{{ show.orgname }} ({{ show.orgid }})"
              (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
              <span>{{ show.orgname }} ({{ show.orgid }})</span>
            </mat-option>
          </div>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngIf="gridDisplay" class="col-sm-12 mt-2">
        <!-- <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid, 1)" #flexgrid
          [frozenColumns]="1" (formatItem)="formatItem(flexgrid, $event)">
          <wj-flex-grid-column *ngIf="postUpload" [binding]="'status'" [allowSorting]="true" [header]="'Status'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [binding]="'notes'" [allowSorting]="true" [header]="'Notes'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 3" [binding]="'subsite'" [allowSorting]="true" [header]="'Body Site'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [visible]="false" *ngIf="siteFormat == 1" [binding]="'sitetype'" [header]="'Site Type'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 1" [binding]="'sitename'" [allowSorting]="true" [header]="'Site Name'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'bodysite'" [allowSorting]="true" [header]="'Body Site'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="siteFormat == 2" [binding]="'subsite'" [allowSorting]="true" [header]="'Sub Site'"
            [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
            [binding]="'mappingsite'" [allowSorting]="true" [header]="'Mapping Site'" [isRequired]="true" [width]="'*'">
          </wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'"
            [binding]="'supportatlength'" [allowSorting]="true" [header]="'Support At Length'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [binding]="'siteorder'" [allowSorting]="true" [header]="'Site Order'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-column [binding]="'isactive'" [allowSorting]="true" [header]="'IsActive'" [isRequired]="true"
            [width]="'*'"></wj-flex-grid-column>
          <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid> -->
        <div class="searchbox-align">
          <div class="global-search-panel d-flex col-sm-4 p-0">
            <div style="display: flex; margin-right: 3px;margin-left: 4px;">
              <mat-icon class="search-icon">search</mat-icon>
            </div>
            <div class="standing-line">
            </div>
            <input type="text" class="search-input" [placeholder]="'Search Site'" [(ngModel)]="searchText"
              (keyup)="filtertemplates()" [ngModelOptions]="{standalone: true}">
            <ng-container *ngIf="searchText">
              <div class="standing-line">
              </div>
              <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
                <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="parent-div mt-2 table-align">
          <div class="header d-flex pl-1 mb-1 table-content" *ngIf="excelDataArray?.length" >
            <div class="header-wrap" style="width: auto !important;">
              <span class="d-flex pl-2">
                <div *ngIf="!postUpload" class="dataTable buttonClass"></div>
                <div class="dataTable widthClass valueDiv" *ngIf="postUpload">Status</div>
                <div class="dataTable widthClass valueDiv">Notes</div>
                <div class="dataTable widthClass valueDiv" *ngIf="siteFormat == 3">Body Site</div>
                <!-- <div class="dataTable widthClass valueDiv" *ngIf="siteFormat == 1">Site Type</div> -->
                <div class="dataTable widthClass valueDiv" *ngIf="siteFormat == 1">Site Type</div>
                <div class="dataTable widthClass valueDiv" *ngIf="siteFormat == 1">Site Name</div>
                <div class="dataTable widthClass valueDiv" *ngIf="siteFormat == 2">Body Site</div>
                <div class="dataTable widthClass valueDiv" *ngIf="siteFormat == 2">Sub Site</div>
                <div class="dataTable widthClass valueDiv"
                  *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'">Mapping Site</div>
                <div class="dataTable widthClass valueDiv"
                  *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'">Support At Length
                </div>
                <div class="dataTable widthClass  valueDiv">Site Order</div>
                <div class="dataTable widthClass valueDiv">Active</div>
              </span>
            </div>
          </div>
          <div class="container-fluid header-wrap p-0">
            <div cdkDropList cdkDropListOrientation="vertical" class="tableScroll " style="width:auto !important;">
              <span *ngIf="DataArray?.length > 0" class="">
                <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'" class="text-muted text-ellipsis dataTable widthClass"
                  [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive')?'card-data mt-1 ml-0  mb-1 inactive-class':'card-data mt-1 ml-0  mb-1'"
                  *ngFor="let item of DataArray | searchfiltersTemplate: templatefilter">
                  <!-- <span class="text-muted text-ellipsis dataTable widthClass" *ngFor="let header of sheetHeader"> -->
                    <input *ngIf="!postUpload" class="buttonClass" type="checkbox"
                    (change)="sitesDataCheckedForCopy($event,item)">
                  <div *ngIf="postUpload"
                    [ngClass]="item['isvalid']==1 || item['status']=='Success' ? 'text-success': item['isvalid']==-1 ||item['isvalid']==0  ||  item['status']== 'Ignored' ? 'text-danger':null"
                    class="valueDiv widthClass" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                    {{ displayedColumns(item,'status') | stripHtml }}</div>
                  <div class="valueDiv widthClass"
                    [ngClass]="item['isvalid']==1 || item['status']=='Success' ? 'text-success': item['isvalid']==-1 ||item['isvalid']==0 || item['status']== 'Ignored'  ? 'text-danger ':null"
                    [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed1)" #isElipsed1>
                    {{ displayedColumns(item,'notes') | stripHtml }}</div>
                  <div *ngIf="siteFormat == 3" class="valueDiv widthClass" [matTooltip]="toolTipText" >
                    {{ displayedColumns(item,'subsite') | stripHtml }}
                  </div>
                  <div *ngIf="siteFormat == 1" class="valueDiv widthClass" [matTooltip]="toolTipText">
                    {{ displayedColumns(item,'sitetype') | stripHtml }}
                  </div>
                  <div *ngIf="siteFormat == 1" class="valueDiv widthClass" [matTooltip]="toolTipText" >
                    {{ displayedColumns(item,'sitename') | stripHtml }}
                  </div>
                  <div *ngIf="siteFormat == 2" class="valueDiv widthClass" [matTooltip]="toolTipText" >
                    {{ displayedColumns(item,'bodysite') | stripHtml }}
                  </div>
                  <div *ngIf="siteFormat == 2" class="valueDiv widthClass" [matTooltip]="toolTipText" >
                    {{ displayedColumns(item,'subsite') | stripHtml }}
                  </div>
                  <div *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'" class="valueDiv widthClass"
                    [matTooltip]="toolTipText">
                    {{ displayedColumns(item,'mappingsite') | stripHtml }}
                  </div>
                  <div *ngIf="templateData.secondarykeys.casetype.toString().toLowerCase() == 'gi histology_plus'" class="valueDiv widthClass"
                    [matTooltip]="toolTipText" >
                    {{ displayedColumns(item,'supportatlength') | stripHtml }}
                  </div>
                  <div class="valueDiv widthClass" [matTooltip]="toolTipText" >
                    {{ displayedColumns(item,'siteorder') | stripHtml }}
                  </div>
                  <div class="valueDiv widthClass" [matTooltip]="toolTipText">
                    {{ displayedColumns(item,'isactive') | stripHtml }}
                  </div>
                </div>
              </span>
              <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
              </div>
              <div class="col-sm-12 nodata-header-wrap"
                *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
                <span class="col-sm-12 nodata-wrapper"> No Results </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <section *ngIf="!postUpload" class="example-section align-center row">
          <mat-checkbox [color]="task.color" id="copyasinactive" ng-init='checkStatus=false' ng-model='checkStatus'
            [checked]='checkStatus' (change)="triggerStatus($event,'inactive')" [ngModelOptions]="{standalone: true}">
            Copy as Inactive
          </mat-checkbox>
        </section> -->


        <div class="col-sm-12 pl-0 mt-4">
          <button mat-raised-button class="admin-btn-success mr-4" [disabled]=""
            *ngIf="!noDataFound && !postUpload && gridWidth > 0" (click)="removeGrid()">
            Back
          </button>
          <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload && gridWidth == 0"
            (click)="refreshGrid('list')">
            Back
          </button>
          <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload" (click)="removeGrid()">
            Back
          </button>

          <div style="float: right;">
            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="refreshGrid('list')">
              Cancel
            </button>
            <!-- in grid hide -->
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload" (click)="refreshGrid('list')">
              Finish
            </button>
            <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn() || (selectedItems && selectedItems.length == 0)"
              class="admin-btn-success mr-4" (click)="uploadCopyData(flexgrid)">
              Approve
            </button>
            <!-- in grid hide -->
            <button mat-raised-button class="admin-btn-success" *ngIf="postUpload" (click)="ExportResult(flexgrid)">
              Download
            </button>
          </div>

        </div>
      </div>

      <div class="col-sm-12 pl-0 mt-4" *ngIf="!gridDisplay && !noDataFound">
        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!noDataFound && !postUpload && gridWidth == 0"
          (click)="refreshGrid('list')">
          Back
        </button>
      </div>

      <div class="col-sm-12" *ngIf="noDataFound">
        <div class="container create-btn col-sm-12 align-center" style="height: 48vh !important;" *ngIf="noDataFound">
          <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
          </div>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">
          Back
        </button>
      </div>

    </div>
  </form>
</div>

<!-- Create Sites -->
<div *ngIf="addEditScreen && !showShimmer" class="row col-sm-12 mt-2 m-0 p-2">
  <div class="col-sm-6 mt-2 mb-2 m-0 p-0 d-flex justify-content-between">
      <span class="header-View">
          <strong>{{action}}</strong>
      </span>
  </div>
  <form class="float-right col-sm-6 p-0 mt-2 mb-2">
    <div class="p-0 button-wrap align-items-center">
      <a class="reset-btn mr-3" (click)="resetDynamicForm()" *ngIf="isFormChanged">Reset</a>
        <button class="mr-3 align-center buttonHover" (click)="refreshGridFromCreatePage('list',isFormChanged)">Return</button>
        <button class="align-center primaryButtonColor d-flex justify-content-space-evenly" (click)="saveSite('', sitesForm.value)">
            Save
        </button>
    </div>
</form>
  <form [formGroup]="sitesForm" class="form-wrap-view">
    <div class="row col-sm-12 p-0 m-0 justify-content-around">
      <div class="col-sm-12" class="col-sm-12 badgeContainer">
            <div class="d-flex align-items-center mr-2">
                <span class="mr-2"> Default: </span>
                <label class="ct-tag-toggle">
                    <input type="checkbox" [value]="sitesForm.value.frmdefault == ('Inactive' || 'No' || false || 0 || '') ? 0 : 1"  formControlName="frmdefault" (input)="isFormChanged = !isFormChanged" (change)="changeDefault()">
                    <span class="slider"></span>
                </label>
            </div>
            <div class="d-flex align-items-center">
                <span class="mr-2"> Status: </span>
                <label class="ct-tag-toggle" [ngClass]="{'disabledInput': action != 'Edit Site'}">
                    <input type="checkbox" [value]="sitesForm.value.frmactive == ('Inactive' || 'No' || false || 0 || '') ? 0 : 1" formControlName="frmactive" (input)="isFormChanged = true">
                    <span class="slider"></span>
                </label>
            </div>
      </div>
        <div class="row col-sm-12 mt-2 p-2">
         <!-- <div class="row col-sm-12"> -->
          <!-- Added Entity type dropdown -->
           <div class="viewSelectContainer col-md-3 mb-2"  *ngIf="siteFormat != 3">
            <mat-form-field  autoComplete="off" class="col-md-12 p-0" [ngClass]="{'disabledInput': action == 'Edit Site'}">
            <mat-label>Site Type</mat-label>
            <mat-select [disabled]="action == 'Edit Site'" disableOptionCentering formControlName="frmsitetype" (selectionChange)="isFormChanged = true">
              <mat-option *ngIf="siteFormat == 1" [value]="'Site Location'">Site Location</mat-option>
              <mat-option [value]="'Sub Site'">Sub Site</mat-option>
              <mat-option [value]="'Body Site'">Body Site</mat-option>
            </mat-select>
          </mat-form-field>
           </div>

           <div class="viewInputContainer col-md-3 mb-2" *ngIf="((siteFormat == 3) || (siteFormat == 2 && sitesForm.value.frmsitetype == 'Sub Site' && sitesForm.value.frmbodysite != null))">
            <mat-form-field class="col-md-12 p-0" [ngClass]="{'disabledInput': action == 'Edit Site' || (siteFormat == 2 && bodySiteReadOnly)}">
              <mat-label>
                  Body Site<span class="text-danger" *ngIf="siteFormat != 1">*</span>
              </mat-label>
              <input trim type="text" maxlength="255" *ngIf="siteFormat == 2" [readonly]="bodySiteReadOnly" (input)="patchShortName(sitesForm.value.frmbodysite); isFormChanged = true"
                formControlName="frmbodysite" matInput [matAutocomplete]="auto" aria-label="Bodysite" />
              <input trim type="text" [readonly]="action == 'Edit Site'" maxlength="255" *ngIf="siteFormat != 2" (input)="patchShortName(sitesForm.value.frmbodysite); isFormChanged = true"
                formControlName="frmbodysite" matInput autocomplete="off" aria-label="Bodysite" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option class="mat-opt-align" *ngFor="let bodysite of filterBodySites(sitesForm.value.frmbodysite)"
                  [value]="bodysite.BodySite" (onSelectionChange)="onChangeBodySite($event, bodysite); isFormChanged = true">
                  <span>{{ bodysite.BodySite }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

           <div class="viewInputContainer col-md-3 mb-2"  *ngIf="siteFormat != 3" >
            <mat-form-field class="col-md-12 p-0" [ngClass]="{'disabledInput': action == 'Edit Site'}">
              <mat-label *ngIf="siteFormat == 1"> Site Name</mat-label>
              <mat-label *ngIf="siteFormat == 2">{{(!sitesForm.value.frmsitetype) ? 'Site Name' : sitesForm.value.frmsitetype}}</mat-label>
              <input trim [readonly]="action == 'Edit Site'" type="text" maxlength="255" formControlName="frmsitename" (input)="patchShortName(sitesForm.value.frmsitename); isFormChanged = true"
                autoComplete="off" matInput aria-label="sitename"/>
            </mat-form-field>
          </div>

          <div class="viewInputContainer col-md-3 mb-2">
            <mat-form-field class="col-md-12 p-0">
              <mat-label>
                  Short Name
              </mat-label>
              <input trim maxLength = "100" type="text" formControlName="frmshortname"
                autoComplete="off" matInput aria-label="shortname" (input)="isFormChanged = true"/>
            </mat-form-field>
          </div>

          <div class="viewSelectContainer col-md-3 mb-2"
          *ngIf="sitesForm.value.frmsitetype == 'Body Site' && templateData.secondarykeys.casetype == 'GI Histology_PLUS'">
            <mat-form-field  autoComplete="off" class="col-md-12 p-0">
              <mat-label>
                  Mapping Site<span class="text-danger">*</span>
              </mat-label>

              <mat-select disableOptionCentering formControlName="frmmappingsite">
                <mat-option [value]="">-- Select --</mat-option>
                <mat-option *ngFor="let value of mappingSite" [value]="value">
                  {{ value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        <!-- </div> -->
          <!-- ends -->
          <div class="viewInputContainer col-md-3 mb-2">
            <mat-form-field class="col-md-12 p-0" >
              <mat-label>
                Site Order
              </mat-label>
              <input [readonly]="(sitesForm.controls.frmdefault.value == false) ? false : true" type="text" [validation]="false" mask="0000"
                formControlName="frmsiteorder" pattern="{{action == 'Edit Site'? '^-?[0-9]+$' : '[0-9]+'}}" (input)="clearZero($event)" 
                matInput aria-label="Type" (input)="isFormChanged = true"/>
            </mat-form-field>
          </div>

          <div *ngIf="sourceApp =='VitalDx'"  class="viewInputContainer col-md-3 mb-2">
            <mat-form-field class="col-md-12 p-0 disabledInput">
              <mat-label>Case Type<span class="text-danger">*</span>
              </mat-label>
              <input trim type="text" [(ngModel)]="selectedCaseType" [ngModelOptions]="{standalone: true}" maxlength="255" [disabled]="true"  matInput [matAutocomplete]="auto" aria-label="Casetype" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option class="mat-opt-align" [value]="selectedCaseType" (onSelectionChange)="isFormChanged = true">
                  <span>{{ selectedCaseType }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
        </div>

          <div
            *ngIf="sitesForm.value.frmsitetype == 'Sub Site' && templateData.secondarykeys.casetype == 'GI Histology_PLUS'"
            class="col-sm-2 set-margin example-section pl-4">
            <mat-checkbox [color]="task.color" class="col-sm-12 mt-1 mr-2 example-margin" formControlName="frmatlength">Support At
              length
            </mat-checkbox>
          </div>

          <!-- <div class="set-margin example-section">
            <mat-checkbox [color]="task.color" class="mt-1 mr-2 ml-3 example-margin" formControlName="frmdefault">Default
            </mat-checkbox>
          </div>

          <div class="set-margin example-section">
            <mat-checkbox [color]="task.color" class="mt-1 mr-2 ml-3 example-margin" formControlName="frmactive">Active
            </mat-checkbox>
          </div> -->
        </div>
    </div>

    <!-- <div class="row mt-4">
      <div class="col-sm-3">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="refreshGrid('list')">
          Back
        </button>
      </div>
      <div class="col-sm-9 button-wrap">

        <button mat-raised-button class="admin-btn-success mr-4" (click)="saveSite('', sitesForm.value)">
          Save & Close
        </button>
        <button mat-raised-button class="admin-btn-success" *ngIf="action == 'Create Site'"
          (click)="saveSite('new', sitesForm.value)">
          Save & Create
        </button>
      </div>
    </div> -->
  </form>
</div>

<div *ngIf="showConfirmPopupFlag && sourceApp != 'VitalDx'" class="col-sm-12">
  <div class="modal overlay" id="myModal1" role="dialog">
      <div class="modal-dialog" id="modaldialog1">
          <div class="modal-content">
            <div class="modal-header dialog-header p-0 pt-2">
              <img src="../../../../assets/icons/user_icons/warning.svg" alt="Warning" class="material-icons md-48">
              <h3 class="modal-title w-100 headerText">Confirmation</h3>
            </div>
              <div class="modal-body p-0 msg-wrap">
                  <div class="alert-text">
                    <ol>
                      <li *ngIf="ruleout">{{ PopUpmsgs(locations)?.[3]?.['message'] }}</li>
                      <li *ngIf="showConfirmPopupFlag==1 || showConfirmPopupFlag==2">{{ PopUpmsgs(locations)?.[showConfirmPopupFlag]?.['message'] }}</li>
                      <li *ngIf="caseProfileAlert">{{ PopUpmsgs(locations)?.[4]?.['message'] }}</li>
                    </ol>
                      </div>
              </div>
              <div class="modal-footer">
                <button mat-flat-button class="nextBtn"*ngIf="ruleout" (click)="redirectToOtherTabOnSave('Rule Outs')">
                    Redirect to Rule Outs
                  </button>
                  <button mat-flat-button class="nextBtn" *ngIf="showConfirmPopupFlag==1" (click)="redirectToOtherTabOnSave('Default Stains')">
                    Redirect to Default Stain
                  </button>
                  <button mat-flat-button class="nextBtn" *ngIf="caseProfileAlert" (click)="redirectToOtherTabOnSave('Case Profiles')">
                    Redirect to Case Profiles
                  </button>
                  <button mat-flat-button  class="returnBtn" (click)="showConfirmPopupFlag = undefined">
                      Close
                  </button>
            </div>
          </div>
      </div>
  </div>
</div>
</ng-container>

<!-- Manage Site Format -->
<ng-container *ngIf="selectedTabIndex === 1">
  <app-manage-site-format [siteFormat] = "siteFormat" [caseType] = "templateData?.cardtype" [createButtonEnabled] = "createBtn"
  [orgId] = "templateData?.secondarykeys?.OrganizationId?.toString()" (isFormChanged)="formChanged($event)"></app-manage-site-format>
</ng-container>

<!-- Send the manage site orders -->
 <ng-container *ngIf="selectedTabIndex === 2">
  <div class="row p-0 m-0">
    <div class="col-12 p-0 m-0">
      <app-manage-site-orders [templateData]="templateData" [buttonData]="buttonData" (showTab) = "showTab($event)">
      </app-manage-site-orders>
    </div>
  </div>
 </ng-container>


<!-- <div *ngIf="addEditScreen" class="col-sm-12">
  <div class="d-flex mt-2">

      <div class="col-sm-6 mb-3 d-flex justify-content-between">
          <span class="header-View">
              <strong>View Site </strong>
          </span>
      </div>

      <div class="col-sm-6">
          <button type="button" class="btn btn-return float-right" (click)="backView()">Return</button>
          <button type="button" class="btn btn-return float-right mr-3" (click)="editDynamicForm()"
              *ngIf="!hideEdit">Edit</button>
      </div>
  </div>
  <div class="row view-Card">
      <ng-container *ngFor="let key of temp">
          <div [ngClass]="key.viewClassName">
              <span class="key-headers">
                  {{key['displayName']}}
              </span>
              <div class="key-values">
                  {{key['value'] | stripHtml }}
              </div>
          </div>
      </ng-container>
  </div>
</div> -->
