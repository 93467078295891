<div class="float-card-wrap p-2 mt-4">
    <div class="d-flex justify-content-between">
        <div class="role-wrap" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(role, 'dropdown')" #role>{{ cardInfo.RoleName | titlecase }}</div>
        <div>
            <!-- {{ cardInfo.IsAssociationActive }} -->
            <em id="deleteButton" [title]="'Delete'" class="fa fa-trash cursor" style="float: inline-end;" *ngIf="!cardInfo?.AssociationId" (click)="deleteClicked()"></em>
            <!-- <app-toggle-switch type="secondary" style="float: inline-end;" *ngIf="cardInfo?.AssociationId" [(ngModel)]="cardInfo.IsAssociationActive" [ngModelOptions]="{standalone: true}">
            </app-toggle-switch> -->

            <div class="badgeAlignment" *ngIf="!['new customer','view']?.includes(openedLocation) && cardInfo?.AssociationId">
                <!-- <span class="mr-2"> Active: </span> -->
                <label class="ct-tag-toggle" [ngClass]="{ 'disabledBadge': doesRoleExists(cardInfo) && !cardInfo.IsAssociationActive }">
                    <input type="checkbox" [(ngModel)]="cardInfo.IsAssociationActive"
                        [value]="cardInfo.IsAssociationActive">
                    <span class="slider"></span>
                </label>
            </div>
        </div>
    </div>
    <!-- <br /> -->
    <div class="act-text-wrap" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(name, 'dropdown')" #name>{{ cardInfo.RepresentativeName | titlecase}}</div>
    <!-- <br /> -->
    <div class="detail-text-wrap">
        <mat-icon class="vdm_icon mr-1" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(phone, 'dropdown')" #phone>phone</mat-icon>{{ cardInfo.Phone || 'N/A'}}
    </div>
    <!-- <br /> -->
    <div class="d-flex detail-text-wrap"  [matTooltip]="toolTipText" (mouseenter)="enableToolTip(mail, 'dropdown')" #mail>
        <div> <mat-icon class="vdm_icon mt-1 mr-1">mail</mat-icon> </div>
        <div class="d-flex mail-decoration">
            <a href="mailto:{{ cardInfo.email }}" target="_blank" >{{ cardInfo.Email || 'N/A' }} </a>
        </div>
    </div>
</div>