import { CommonService } from 'src/app/core/services/commonservices';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { searchFilterPipetemplate } from 'src/app/core/utility/tripfilter.pipe';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { DataShareService } from 'src/app/core/services/datashare.service';
import * as XLSX from 'xlsx';
import {  MatDialog } from '@angular/material/dialog';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';

@Component({
  selector: 'app-new-accounts-association',
  templateUrl: './new-accounts-association.component.html',
  styleUrls: ['./new-accounts-association.component.scss'],
  providers: [searchFilterPipetemplate],
})
export class NewAccountsAssociationComponent implements OnInit {

  scrollHistory: number = 0;
  onScroll = (e: any) => this.scrollHistory = e.target.scrollTop;
  GridData: any;
  hideExport = true;
  selectedAssociationTab: any;
  displayTabs: any = [];
  templatefilter: object = {};
  toolTipText: any;
  hideEdit = false;
  btnCreateFacility = false;
  editMode = false;
  NewSendIn= false;
  ContextValue: any;
  labLocationList: any;
  serviceAndCourierUsersList: any;
  setupNewEntitiesBtnClick = false;
  setupNewFacilityBtnClick = false;
  StateList: any;
  ClaimOptions: any;
  Service: any;
  shareData: any
  liveUserCreate: any;
  showAssociationConfigScreen = false;
  dbName = sessionStorage.getItem('DeploymentKey');
  showCreateButtonIn  = ['Ordering Facilities', 'Send In Lab', 'Pathologists', 'Send-out Laboratories'];
  isDeAssociateClicked = false;
  itemsToDeAssociate: any = [];
  selectedTabIndex = 0;
  searchText: any = '';
  searchGridHeader = [
    'Account_Name',
    'Associated_Account_Name',
    'OrganizationName',
    'Services',
    'Is_Pc_Enabled',
  ];

  @Input() templateData: any;
  @Input() createButton = false;
  @Input() uploadButton = false;
  @Input() associateBtnCreate = false;
  @Input() btnRemove = false;
  @Input() exportButton = false;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport: CdkVirtualScrollViewport;
  @Output() createClickedEmit = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<any>();
  @Output() uploadClickedEmit = new EventEmitter<any>();


  associationGridHeaders = {
    'Ordering Facilities': [
      {key: 'Associated_Account_Name', Name: 'Associated Location', colWidth: '20%'},
      {key: 'Services', Name: 'Services', colWidth: '15%'},
      {key: 'OrganizationName', Name: 'Associated Name', colWidth: '20%'},
      {key: 'Is_Pc_Enabled', Name: 'PC Enabled', colWidth: '15%', textAlign: 'center'},
      {key: 'Allow_Report_Viewing', Name: 'View Report', colWidth: '15%', textAlign: 'center'},
    ],
    'Laboratories': [
      {key: 'Associated_Account_Name', Name: 'Associated Location', colWidth: '20%'},
      {key: 'Services', Name: 'Services', colWidth: '15%'},
      {key: 'OrganizationName', Name: 'Associated Name', colWidth: '20%'},
      {key: 'Is_Pc_Enabled', Name: 'PC Enabled', colWidth: '15%', textAlign: 'center'},
      {key: 'Allow_Report_Viewing', Name: 'View Report', colWidth: '15%', textAlign: 'center'},
    ],
    'Send In Lab': [
      {key: 'Account_Name', Name: 'Associated Location Name', colWidth: '45%'},
      {key: 'OrganizationName', Name: 'Associated Group', colWidth: '45%'},
    ],
    'Pathologists': [
      {key: 'Account_Name', Name: 'Associated Location Name', colWidth: '45%'},
      {key: 'OrganizationName', Name: 'Associated Group', colWidth: '45%'},
    ],
    'Send-out Laboratories': [
      {key: 'Account_Name', Name: 'Associated Location Name', colWidth: '45%'},
      {key: 'OrganizationName', Name: 'Associated Group', colWidth: '45%'},
    ]
  };

  panelMessages = {
    'Send In Lab': '"Send In Lab" are the list of locations associated as an Send In to the parent location.',
    'Send In Lab To': '"Send In Lab To" are the list of Locations to which the parent location is associated as an Send In Lab.',
    'EPG': '"EPG" are the list of locations associated as an EPG to the parent location.',
    'EPG To': '"EPG To" are the list of Locations to which the parent location is associated as an EPG.',
    'Send Out': '"Send Out" are the list of locations associated as an Send Out to the parent location.',
    'Send Out To': '"Send Out To" are the list of Locations to which the parent location is associated as an Send Out.',
    'Ordering Facilities': 'Ordering Facility locations associated to the Laboratory location opened are shown here.',
    'Laboratories': 'Laboratory locations associated to the Ordering Facility location opened are shown here.'
  }

  gridHeader: any;
  isOpenedIndex: any;
  groupedGridData: any;
  locationId: any;
  selectedContext: any;
  finalDisplayTabs: any;
  SetupInformation: any;
  SubMenuCardModel: any;
  exportData: any = [];
  activityEntity: any;

  constructor(private vitalHttpServices: VitalHttpServices,
              private commonService: CommonService,
              public snackbar: MatSnackBar,
              private vitalMenuComponent : VitalMenuComponent,
              public DataShare: DataShareService,
              private dialog: MatDialog,
              private activityService: ActivityTrackerService,
              private ngxService: NgxUiLoaderService) {
                this.locationId = sessionStorage.getItem('Locationid');
                this.SubMenuCardModel = new SubMenuCardModel(
                  commonService,
                  vitalHttpServices,
                  DataShare
                );
  }


  ngOnInit(): void {
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.ngxService.start();
    this.selectedTabIndex = 0;
    this.selectedContext = sessionStorage.getItem('selectedContext');
    this.getDisplayTabs();
  }

  getDisplayTabs() {
    this.getDisplayTabsBasedOnContext();
    // this.displayTabs = [
    //   { keyword: 'Ordering Facilities', displayName: 'Ordering Facilities' },
    //   { keyword: 'Laboratories', displayName: 'Laboratories' },
    //   { keyword: 'Pathologists', displayName: 'EPG' },
    //   { keyword: 'Send In Lab', displayName: 'Send In Lab' },
    //   { keyword: 'Send-out Laboratories', displayName: 'Send Out Lab' }
    // ];
    // this.getSelectedTabRecordsList(this.displayTabs[0]);
  }

  getDisplayTabsBasedOnContext() {
    let tabsForContext = {
      'Facility': ['Laboratories'],
      'Laboratory': ['Ordering Facilities', 'Pathologists', 'Send In Lab', 'Send-out Laboratories'],
      'EPG': ['Pathologists'],
      'Send In': ['Send In Lab'],
      'Send Out': ['Send-out Laboratories']
    }
    this.displayTabs = [
      { keyword: 'Ordering Facilities', displayName: 'Ordering Facilities' },
      { keyword: 'Laboratories', displayName: 'Laboratories' },
      { keyword: 'Pathologists', displayName: 'EPG' },
      { keyword: 'Send In Lab', displayName: 'Send In Lab' },
      { keyword: 'Send-out Laboratories', displayName: 'Send Out Lab' }
    ];
    
    let relevantKeywords = tabsForContext?.[this.selectedContext];
    this.finalDisplayTabs = relevantKeywords?.includes('All') 
    ? this.displayTabs 
    : this.displayTabs?.filter((tab: any) => relevantKeywords?.includes(tab.keyword));

    let storedTabIndex = this.commonService?.selectedClientTabIndex;
    this.selectedTabIndex = storedTabIndex ? storedTabIndex : 0;

    this.selectedAssociationTab = this.finalDisplayTabs[this.selectedTabIndex];
    this.gridHeader = this.associationGridHeaders?.[this.selectedAssociationTab?.['keyword']];
    this.getSelectedTabRecordsList(this.finalDisplayTabs?.[this.selectedTabIndex]);
  }

  onTabChange($event: any) {
    this.clearSearch();
    this.isDeAssociateClicked = false;
    this.itemsToDeAssociate = structuredClone([...[]]);
    this.GridData = undefined;
    this.gridHeader = undefined;
    this.ngxService.start();

    // setTimeout(() => {
      this.selectedTabIndex = $event?.['index'];
      this.commonService.selectedClientTabIndex = $event?.['index'];
      this.selectedAssociationTab = this.finalDisplayTabs?.[$event?.['index']];
      this.gridHeader = this.associationGridHeaders?.[this.selectedAssociationTab?.['keyword']];
      this.getSelectedTabRecordsList();
    // })
  }

  getSelectedTabRecordsList(selectedTab = this.selectedAssociationTab) {
    this.ngxService.start();
    let obj = {
      "associationType": selectedTab?.['keyword'],
      "accountid": sessionStorage.getItem("AccountID")
    };
    let ignoreTabs = ['Send In Lab', 'Send-out Laboratories', 'Pathologists'];
    this.vitalHttpServices.getAssociationsListing(obj, this.dbName).subscribe((data: any) => {
      if(data.status === 'Success' && !ignoreTabs.includes(this.selectedAssociationTab['keyword'])) {
        this.ngxService.stopAll();
        this.GridData = data?.content;
        this.exportData = structuredClone(data?.content);
        this.exportData.forEach(record => {
          delete record.Notes;
      });
      } else if(data.status === 'Success' && ignoreTabs.includes(this.selectedAssociationTab['keyword'])) {
        this.ngxService.stopAll();
        this.exportData = structuredClone(data?.content);
        this.exportData.forEach(record => {
          delete record.Notes;
      });
        this.getGroupedData(data?.content);
      } else {
        this.snackbar.open('Something went wrong, Please try again.', 'Close');
      }
    }, (error: any) => {
      this.ngxService.stopAll();
    })
  }

  RedirectToView() {

  }

  enableToolTip(e) {
    this.toolTipText=e.scrollWidth > e.clientWidth?e.textContent:'';
  }

  displayedColumns(item: any, header: any) {
    return item[header.key]
  }

  filtertemplates() {
    for (let i = 0; i < this.searchGridHeader?.length; i++) {
      this.templatefilter[this.searchGridHeader[i]] = this.searchText
    }
  }

  trackByHeader(index: number, header: any): any {
    return header.key; // Use a unique identifier for headers
  }
  
  trackByItem(index: number, item: any): any {
    return item.id; // Use a unique identifier for items
  }

  getGroupedData(actualData: any) {
    
    let finalGroupedObject = {};
    let mappedGroupedData = {
      "Send In Lab": {
        "Send In Lab": [],
        "Send In Lab To": []
      },
      "Pathologists": {
        "EPG": [],
        "EPG To": []
      },
      "Send-out Laboratories": {
        "Send Out": [],
        "Send Out To": []
      },
    }

    finalGroupedObject = {...mappedGroupedData?.[this.selectedAssociationTab?.['keyword']]};
    const keys = Object.keys(finalGroupedObject);
    
    if (actualData?.length) {
      actualData?.forEach((element) => {
        if (element?.accountid == this.locationId) {
          element['showAssociatedAccountName'] = true;
          finalGroupedObject?.[keys[0]]?.push(element);
        } else if(element?.Associated_Account_Id == this.locationId) {
          finalGroupedObject?.[keys[1]]?.push(element);
        }
      });
    }

    if (finalGroupedObject?.[keys[0]]?.length === 0 && finalGroupedObject?.[keys[1]]?.length === 0) {
      this.GridData = [];
    } else {
      this.GridData = [finalGroupedObject];
    }

    this.groupedGridData = Object.entries(finalGroupedObject);
  }

  togglePanel(index) {
    if(index === this.isOpenedIndex) {
      this.isOpenedIndex = undefined;
    } else {
      this.isOpenedIndex = index;
    }
  }

  createClicked() {
    this.createClickedEmit.emit(this.selectedAssociationTab?.['keyword'])
  }

  exportExcel() {
    let orgname = sessionStorage.getItem('Org_Name');
    let filename = 'Associated Locations_';
    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '');
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }

    filename = filename + this.templateData?.secondarykeys?.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_'
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData?.secondarykeys?.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    var ws = XLSX.utils.json_to_sheet(this.exportData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Associated Locations' );
    XLSX.writeFile(wb, filename);
  }

  editMethod(item: any) {
    this.editClicked.emit(item);
  }

  uploadClicked() {
    this.uploadClickedEmit.emit();
  }

  showAssociationConfig() {
    this.showAssociationConfigScreen = true;
  }

  onDeAssociateCheckboxChange(item: any, event: any) {
    if (event.target.checked) {
      this.addToDeAssociateItems(item);
    } else {
      this.removeFromToDeAssociateItems(item);
    }
  }

  addToDeAssociateItems(item: any) {
    if (!this.itemsToDeAssociate.includes(item.ID)) {
      this.itemsToDeAssociate.push(item.ID);
    }
  }

  removeFromToDeAssociateItems(item: any) {
    const index = this.itemsToDeAssociate.indexOf(item.ID);
    if (index > -1) {
      this.itemsToDeAssociate.splice(index, 1);
    }
  }

  resetDeAssociate() {
    this.itemsToDeAssociate = structuredClone([...[]]);
  };

  returnDeAssociate() {
    this.itemsToDeAssociate = structuredClone([...[]]);
    this.isDeAssociateClicked = false;
  }

  isItemSelected(item: any) {
    return this.itemsToDeAssociate.includes(item?.ID);
  }


  //#region Deassociating the accounts using the id
  deAssociateAccounts() {
      if (this.itemsToDeAssociate && this.itemsToDeAssociate.length >= 1) {
        let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          
          data: { header: "", message: "Are you sure to De-Associate the selected location(s)?", continue: "Yes", cancel: "No" }

        });
        return dialogRef.afterClosed().toPromise().then((result) => {
          this.ngxService.start();
          if (result) {
            let obj = {
              id: this.itemsToDeAssociate,
            };
            this.vitalHttpServices.deAssociateAccounts(obj).subscribe((res) => {
              if (res.Success) {

                if (this.GridData[0]['EPG'] || this.GridData[0]['EPG To']) {
                  const EPGItems = [
                    ...this.GridData[0]['EPG'],
                    ...this.GridData[0]['EPG To']
                  ];

                  let EPGDeAssociated = EPGItems.filter((item: any) => this.itemsToDeAssociate?.includes(item.ID));
                  this.commonService.auditDetails('', '', [], EPGDeAssociated, 'De-Associate', this.templateData, '');
                }
                else if (this.GridData[0]['Send Out To'] || this.GridData[0]['Send Out']) {

                  const sendOutItems = [
                    ...this.GridData[0]['Send Out'],
                    ...this.GridData[0]['Send Out To']
                  ];

                  let sendOutLabDeAssociated = sendOutItems.filter((item: any) => this.itemsToDeAssociate?.includes(item.ID));
                  this.commonService.auditDetails('', '', [], sendOutLabDeAssociated, 'De-Associate', this.templateData, '');
                }
                else if (this.GridData[0]['Send In Lab'] || this.GridData[0]['Send In Lab To']) {
                  const sendInItems = [
                    ...this.GridData[0]['Send In Lab'],
                    ...this.GridData[0]['Send In Lab To']
                  ];


                  let sendInLabDeAssociated = sendInItems.filter((item: any) => this.itemsToDeAssociate?.includes(item.ID));
                  this.commonService.auditDetails('', '', [], sendInLabDeAssociated, 'De-Associate', this.templateData, '');
                }
                else {
                  let objectDeAssociated = this.GridData.filter((item: any) => this.itemsToDeAssociate?.includes(item.ID));
                  this.commonService.auditDetails('', '', [], objectDeAssociated, 'De-Associate', this.templateData, '');
                }
                this.isDeAssociateClicked = false;
                this.itemsToDeAssociate = [];
                this.onTabChange({index: this.selectedTabIndex});
                this.snackbar.open(res.Message, 'Success');
                this.ngxService.stop();
              } else {
                this.snackbar.open(res.Message, 'Failed');
                this.ngxService.stop();
              }
            }, error => {
              console.error(error);
              this.snackbar.open("Something went wrong please try again!", 'Failed');
              this.ngxService.stop();
            });
          }
          this.ngxService.stop();
        });
      } else {
        this.snackbar.open('Please select an Location', 'Close');
        this.ngxService.stop();
      }
  }

  clearSearch(){
    this.searchText = '';
    this.filtertemplates();
  }

}
