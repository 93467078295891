import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shimmer-multiline-loader',
  templateUrl: './shimmer-multiline-loader.component.html',
  styleUrls: ['./shimmer-multiline-loader.component.scss']
})
export class ShimmerMultilineLoaderComponent implements OnInit {
  @Input() blocks:number=1
  @Input() lines:number=3
  ngOnInit(): void {
    
  }

}
