import { formatDate } from '@angular/common';
import { Component,  Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import {  MatTooltip } from '@angular/material/tooltip';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, EMPTY, Observable, combineLatest, of, throwError } from 'rxjs';
import { map, tap,filter, catchError } from 'rxjs/operators';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DialogService } from 'src/app/core/services/dialog.service';
import * as XLSX from 'xlsx';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { convertLocalDateTime } from 'src/app/labadmin/components/Utility Functions';
import { MenuFlagsConfigurationComponent } from 'src/app/commoncomponents/menu-flags-configuration/menu-flags-configuration.component';
@Component({
  selector: 'app-manage-reflex-rules',
  templateUrl: './manage-reflex-rules.component.html',
  styleUrls: ['./manage-reflex-rules.component.scss']
})
export class ManageReflexRulesComponent implements OnInit {
  page='list'
  listAll='All';
  searchText=''
  tooTipText=''
  ReflexRuleData : any = []
  orgId
  deployment=sessionStorage.getItem('deploymentKey').toUpperCase()
  filtereReflex=new BehaviorSubject({status:'all',searchText:''});
  filtereReflex$=this.filtereReflex.asObservable()
  reflexRules$:any;
  reflexRulesList$:any;
  ActiveBtn :boolean =false
  SaveBtn : boolean = false
  ExportBtn : boolean = false
  start_date = formatDate(new Date(), 'yyyy-MM-dd', 'en_US')
  min_expiry_date = formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 100)), 'yyyy-MM-dd', 'en_US') //for licensure
  end_date: string = formatDate(new Date(), 'yyyy-MM-dd', 'en_US') //'yyyy-MM-dd  dd-MM-yyy
  EditBtn:boolean=false;

  userSessionDetails: any;
  sourceApp: string;
  destDeployment: string;
  dbName: any;
  auditableColumns: any;
  oldObject: { EffectiveDate: any; ExpirationDate: any; Description: any; IsActive: any; };
  activityEntity: any;
  showLabAdminLoader:boolean = false


  @Input() templateData: any;
  constructor(
    public vitalHttpServices: VitalHttpServices,
    private commonService: CommonService,
    private _fb: FormBuilder,
    public _snackBar: MatSnackBar,
    private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,
    private dialog: DialogService,private ngxService: NgxUiLoaderService,
    public activityService: ActivityTrackerService,
    private matdialog: MatDialog) { }
    formEditRuleGrp = this._fb.group({
    EffectiveDate: ['', [Validators.required]],
    ExpirationDate: ['', [Validators.required]],
    Description: [''],
    message : [''],
    IsActive:[false]
  });

  // ngOnInit(): void
  //  {
  //   this.orgId= sessionStorage.getItem('org_id');
  //   this.getReflexRuleList();
  //   this.GetButtondetails();
  // }


  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.deployment = this.destDeployment;
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      await _this.labAdminSessionService.getLabAdminSession.subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").subscribe(async result => {
            menuObj = result[result.length - 1]
          })
          await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails, 'Admin3_vwReflexRulesDetails_list');
          _this.templateData = _this.labadminService.templateData;
          _this.dbName = _this.labadminService.deploymentKey;
          sessionStorage.setItem('deploymentKey', this.dbName);
          _this.orgId= _this.userSessionDetails?.organizationId;
          _this.getReflexRuleList();
          // _this.AddGridData();
          _this.activityService.setActivitySession({ 'entityId': '', 'entityType': _this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData.menuURL}] });
          _this.activityService.getActivitySession.subscribe(res=> _this.activityEntity = res);
          _this.getAuditableDetails(_this.templateData.menuURL);
          _this.GetButtondetails(_this.labadminService.subMenuPermissions);
        }
      })

    }
    else {
      this.orgId= sessionStorage.getItem('org_id');
      this.getReflexRuleList();
      this.GetButtondetails();
      this.commonService.createContext(this.templateData.secondarykeys, '',this.templateData.menuURL);
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL}] });
      this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
      this.getAuditableDetails(this.templateData.menuURL);
      this.destDeployment = sessionStorage?.getItem('DeploymentKey').toUpperCase();
      this.deployment = this.destDeployment;
    }
  }

  //To get the list of reflex rules for the organization
  getReflexRuleList(){
    this.page="list";
    let query = this.GetQuery(this.templateData.Keyword);
    let queryVariable = { "organizationId": this.orgId.toString() };

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.reflexRules$=this.vitalHttpServices.GetData(queryResult).pipe(
      tap(()=>this.ngxService.start()),
      map(d=>(d.data.Admin3_vwReflexRulesDetails_list),
      catchError(err=>{
        console.error(err);
        this.ngxService.stop()
        return EMPTY}
        )),
      tap(()=>this.ngxService.stop()),
      ) 
     
    this.reflexRulesList$=combineLatest(this.reflexRules$,this.filtereReflex$).pipe(
      map(([rules,filter]:any)=>{
        if(rules&&rules.length){
          let res=[]
          res=filter.searchText!=''? rules.filter(i=>i.CaseType?.toLowerCase().includes(filter.searchText?.toLowerCase())||i.RuleName?.toLowerCase().includes(filter.searchText.toLowerCase())):rules
         let totalCount=res.length;
         let activeCount=res.filter(i=>i.RuleStatus=='Active').length
          if(filter.status!='all')
            res=filter.status=="active"?res.filter(i=>i.RuleStatus=='Active'):res.filter(i=>i.RuleStatus!='Active')
         
          return {data:res,totalCount,activeCount,isNoDataPresent:false}
        }
        return {data:[],totalCount:0,activeCount:0,isNoDataPresent:true}

       }
    ))
  }
  toolTipshow(element){
    this.tooTipText=element.scrollWidth > element.clientWidth?element.textContent:'';
  }
  
  compareDate=(date:any)=> new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ;

  //Details to load on edit rules
  viewRule(data:any){
    this.page="viewRule";
    this.activityEntity.entityId = data.RuleId;
    this.activityService.setActivitySession(this.activityEntity);
    this.loadreflexrulesdetails(data.RuleId, this.orgId, this.deployment)
 }

 //Query to fetch the reflex rules for the organization
  public GetQuery(keyword: string="") {
     let query=''
    let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;

  }

// export the list of reflex rule data in Excel format
  exportexcel(reflexrules) {
      const fileName = this.sourceApp == 'VitalDx'?'Reflex Rules.xlsx':`Reflex Rules_${this.orgId}.xlsx`;
      let reflexRuleExportData=reflexrules.map((i:any)=>{
        let obj={
          'Rule ID':i.RuleId?.toString(),
          'Rule Name':i.RuleName,
          'Description':i.Description,
          'Speciality':i.Speciality,
          'Case Type':this.sourceApp != 'VitalDx'?i.CaseType:i.CaseTypeDisplayName,
          'Case Type Display Name':i.CaseTypeDisplayName,
          'Case Status':i.Status,
          'Services':i.Services,
          'Trigger On':i.TriggerOn,
          'Effective Date':i.EffectiveDate,
          'Expiration Date':i.ExpirationDate,
          'Rule Status':i.RuleStatus,
          'Created By':i.CreatedBy,
          'Created Date':convertLocalDateTime(i.CreatedDate),
          'Modified By':i.ModifiedBy,
          'Modified Date':convertLocalDateTime(i.ModifiedDate)
            }
  this.sourceApp == 'VitalDx'&& delete obj['Case Type Display Name']
    return obj

      })
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(reflexRuleExportData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Reflex Rules');
      XLSX.writeFile(wb, fileName);
  }
  toggleButton(updateValue: any){
    this.filtereReflex.next({...this.filtereReflex.value,...updateValue})
  }

  get getters() {
    return this.formEditRuleGrp.controls
  }

   message : string ='';

   //Load reflex rules data for the detailed page
  loadreflexrulesdetails(ruleId, organizationId, deploymentKey) {
    this.ngxService.start()
    this.vitalHttpServices.loadreflexrulesdetails(ruleId, organizationId, deploymentKey).subscribe({
      next: (data: any) => {

        this.ReflexRuleData = data;
        this.ReflexRuleData[0].EffectiveDate=formatDate(new Date(this.ReflexRuleData[0]?.EffectiveDate), 'yyyy-MM-dd', 'en_US');//new Date()
        this.ReflexRuleData[0].ExpirationDate=formatDate(new Date(this.ReflexRuleData[0]?.ExpirationDate), 'yyyy-MM-dd', 'en_US');//new Date()
      const Jsondata= JSON.parse(data[0].Action)
      const Details = Jsondata?.ActionDetails?.true;
      if(Details?.Information)
      {
        this.message=Details?.Information['@Message'];
      }
      else if(Details?.Confirmation){
        this.message=Details?.Confirmation['@Message'];
      }
      else{
        this.message=Details?.Balloon['@Message'];
      }
        this.formEditRuleGrp.patchValue({
          EffectiveDate: this.ReflexRuleData[0].EffectiveDate??"",
          ExpirationDate: this.ReflexRuleData[0].ExpirationDate??'',
          Description: this.ReflexRuleData[0].Description,
          IsActive:this.ReflexRuleData[0].IsActive
        })
        this.oldObject={
          EffectiveDate: this.ReflexRuleData[0].EffectiveDate,
          ExpirationDate: this.ReflexRuleData[0].ExpirationDate,
          Description: this.ReflexRuleData[0].Description,
          IsActive:this.ReflexRuleData[0].IsActive
        }

        this.ngxService.stop()
      },
      error: (err: any) => {
        console.error(err)
        this.ngxService.stop()
        this._snackBar.open('Something went wrong. Please try again', 'Close');
        this.page="list";
      },complete:()=>{
        this.ngxService.stop();
      }
    })
  }

  //Save the rule data pop up
  saveRule(){
    this.formEditRuleGrp.controls.Description.patchValue(this.formEditRuleGrp.controls.Description.value.trim());
    if(!this.getters.Description.value)
    {
      this._snackBar.open('Enter the Description','Close')
      return 
    }
    if(!this.formEditRuleGrp.controls.EffectiveDate.value)
    {
      this._snackBar.open("Effective Date can't be empty", "Close");
      return;
    }
    if(!this.formEditRuleGrp.controls.ExpirationDate.value)
    {
      this._snackBar.open("Expiry Date can't be empty", "Close");
      return;
    }
    if(new Date(this.formEditRuleGrp.controls.EffectiveDate.value)>new Date(this.formEditRuleGrp.controls.ExpirationDate.value))
    {
        this._snackBar.open("Enter valid Expiry or Effective Date", "Close");
      return;
    }
    if(new Date(this.formEditRuleGrp.controls.EffectiveDate.value) >new Date(this.formEditRuleGrp.controls.ExpirationDate.value))
    {
      this._snackBar.open("Enter valid Expiry or Effective Date", "Close");
      return;
    }
    this.UpdateReflexRules()
    // const confirmPopUp=this.dialog.openDialog("Update Rule!","Do you want to update & proceed?","","Yes","No");
    // confirmPopUp.afterClosed().subscribe(result=>{
    //   result&&
    // })
  }

  //Update or edit the rules
  UpdateReflexRules() {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    
    var modifiedField = '';
    if(this.ReflexRuleData[0].EffectiveDate != formatDate(this.formEditRuleGrp.controls.EffectiveDate.value, 'yyyy-MM-dd', 'en_US')){
      modifiedField += 'Description' + ',';
    }
    if(this.ReflexRuleData[0].ExpirationDate != formatDate(this.formEditRuleGrp.controls.ExpirationDate.value, 'yyyy-MM-dd', 'en_US')){
      modifiedField += 'EffectiveDate' + ',';
    }
    if(this.ReflexRuleData[0].Description != this.formEditRuleGrp.controls.Description.value){
      modifiedField += 'ExpirationDate' + ',';
    }
    if(this.ReflexRuleData[0].IsActive != this.formEditRuleGrp.controls.IsActive.value){
      modifiedField += 'IsActive';
    }

    var obj :{ [key: string]: any }  = {
      "RuleName" : this.ReflexRuleData[0].RuleName,
      "OrganizationId": this.sourceApp !== 'VitalDx' ? sessionStorage.getItem('org_id') : this.orgId,
      "RuleId": this.ReflexRuleData[0].RuleId,
      "EffectiveDate" : formatDate(this.formEditRuleGrp.controls.EffectiveDate.value, 'yyyy-MM-dd', 'en_US'),
      "ExpirationDate": formatDate(this.formEditRuleGrp.controls.ExpirationDate.value, 'yyyy-MM-dd', 'en_US') ,
      "Description" : this.formEditRuleGrp.controls.Description.value,
      "IsActive" : this.getters.IsActive.value, 
      "associationType": this.sourceApp == 'VitalDx' ? this.userSessionDetails.associationType : ''
    }
    var modifiedBy = sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid') : "-100"
    this.ngxService.start()
    this.showLabAdminLoader = true

    this.vitalHttpServices.UpdateReflexRuleDetails(modifiedBy,modifiedField,obj).subscribe({next:(result)=>{
      if (!result.errors) {
        this.showLabAdminLoader = false
        this._snackBar.open("Rule updated successfully.", "Close");
        this.getReflexRuleList();
        let sessionId = this.commonService.generateGuid();
        console.log(this.auditableColumns)
        obj.IsActive = obj.IsActive ? "Active":"In Active"
        this.oldObject.IsActive = this.oldObject.IsActive ? "Active":"In Active"

        this.commonService.createActivityObject(obj.RuleId, obj.RuleName, this.templateData.menuURL, 'Edit', obj, this.oldObject, sessionId,this.auditableColumns);
      }
      
    },error:(error)=>{
      this.ngxService.stop()
      this.showLabAdminLoader = false
      console.error(error);
      this._snackBar.open("Something went wrong.", 'Failed');

    },complete:()=>{
      this.showLabAdminLoader = false
      this.ngxService.stop();
    }
  })


  }

  //hide or show buttons based on the configuration
  private GetButtondetails(permissions: any = null) {
    this.GetButtonAccess(permissions ? permissions : this.vitalHttpServices.SubmenuAction);
  }


  private GetButtonAccess(actionButtonDetails) {


    let seletedMenuPermissions
    
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.menuURL)["ActionButton"];
    } else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }

    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Active":
          this.ActiveBtn = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
          case "Save":
            this.SaveBtn = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
            break;
          case "Export":
          this.ExportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
            break
          case "Edit":
            this.EditBtn= seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
          case "Create":
            this.SaveBtn = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
      }
    }
  }

  onBackClick(){
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    if(this.isFormChanged()){

      this.dialog.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel').afterClosed().subscribe(result => {
        if (result) {
          result && this.getReflexRuleList()
        }
      })
    }
    else this.getReflexRuleList()
  }

  private isFormChanged() : Boolean{
    return (this.formEditRuleGrp.value.EffectiveDate !== this.ReflexRuleData[0].EffectiveDate ||
      this.formEditRuleGrp.value.ExpirationDate !== this.ReflexRuleData[0].ExpirationDate || 
      this.formEditRuleGrp.value.Description.replaceAll('\r','') !== this.ReflexRuleData[0].Description.replaceAll('\r','')  ||
      this.formEditRuleGrp.value.IsActive !== this.ReflexRuleData[0].IsActive)
  }

  isFocused: boolean = false;

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }

  onReset(){
    this.formEditRuleGrp.patchValue({
      EffectiveDate: this.ReflexRuleData[0].EffectiveDate??"",
      ExpirationDate: this.ReflexRuleData[0].ExpirationDate??'',
      Description: this.ReflexRuleData[0].Description,
      IsActive:this.ReflexRuleData[0].IsActive
    })
  }

  showMenuRelatedFlags() {
    let dialogRef = this.matdialog.open(MenuFlagsConfigurationComponent, {
      disableClose: true,
      data: {
        header: 'Reflex Rules',
        message: '',
        alert: '',
        continue: 'yes',
        cancel: 'no',
        templateData: this.templateData
      },
    });
  }


  
}
