import { Component, Input } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { CommonService } from '../../../core/services/commonservices';
import { TemplateHeadersService } from '../../../core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { LabadminService } from '../../../labadmin/labadmin.service';
import { LabadminSessionService } from '../../../labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
// import { Subscription } from 'rxjs/dist/types/internal/Subscription';
import { CollectionView } from '@grapecity/wijmo';
import { take, Subscription } from 'rxjs';
import { ConfirmLabadminComponent } from '../../../base/popup/confirm-labadmin/confirm-labadmin.component';

@Component({
  selector: 'app-sendout-reasons',
  templateUrl: './sendout-reasons.component.html',
  styleUrls: ['./sendout-reasons.component.scss']
})
export class SendoutReasonsComponent {
  SubMenuCardModel: any;
  storeSub: Subscription = new Subscription();
  sourceApp: string='Admin';
  destDeployment: string;
  @Input() templateData: any;
  userSessionDetails: any;
  dbName:any;
  gridData: CollectionView<any>;
  gridShow: boolean;
  listedGridHeader: any = [];
  gridheader: string[];
  listHeaderbackup: any;
  gridHeaderList: { templateName: string; headers: { Name: string; type: string; key: string; colWidth: string; }[]; }[];
  activityEntity: any;
  deployment: string;
  showLabAdminLoader: string;
  isOnInitCalled: boolean;
  orgId: any;
  deleteBtn: any;
  saveOrEditCompleted: any;
  auditableColumns: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  exportExcelFields = {
    TemplateName: { displayName: "Template Name", isDate: false },
    Description: { displayName: "Description", isDate: false },
    Sequence: { displayName: "Sequence", isDate: false },
    IsActive: { displayName: "Status", isDate: false },
    CreatedBy: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    ModifiedBy: { displayName: "Modified By", isDate: false },
    ModifiedDate: { displayName: "Modified Date", isDate: true },
  };
  
  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    private activityService: ActivityTrackerService,
    private labAdminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.deployment = this.destDeployment;
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      
      let _this = this;
      _this.sourceApp = 'VitalDx'
      _this.showLabAdminLoader = 'show';
        await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
          if (Object.keys(session["userDetails"]).length > 1) {
            _this.userSessionDetails = session["userDetails"]
            _this.sourceApp = 'VitalDx';
            let menuObj = {}
            await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
                menuObj = result[result.length - 1];
            });
            await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
            _this.templateData = _this.labAdminService.templateData;
            _this.dbName = _this.labAdminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', _this.dbName);
            sessionStorage.setItem('org_id', _this.templateData.secondarykeys.OrganizationId);
            _this.orgId = _this.userSessionDetails?.organizationId;
            _this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
            _this.addGridData(_this.templateData.submenuData);
            _this.getAuditableDetails("Sendout Reasons");
            _this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
            _this.activityService.getActivitySession.subscribe(res => _this.activityEntity = res);
            _this.GetButtonAccess(_this.labAdminService.subMenuPermissions);
          }
        })
      
    }
    else {
      //  this.getAllNomenclatureTemplates()
      this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
      this.addGridData(this.templateData.submenuData);
      this.getAuditableDetails(this.templateData.menuURL)
      this.activityService.setActivitySession({'entityId': '','entityType': this.templateData.menuURL, 'context':[{'key':'parentMenu','value':this.templateData.menuURL}]})
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
  }

  GetButtonAccess(CasemenuAction) {
    
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labAdminService.templateData.menuURL)["ActionButton"];
    } else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL = this.templateData.menuURL)['ActionButton'];
    }

    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
      console.log( this.auditableColumns)
    })
  }


  addGridData(templateData: any) {
    if(templateData?.length>0){
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    this.listedGridHeader = [...[]];
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Sendout Reasons') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    let gridarray = [];
    this.gridheader = [
      'Id',
      'OrganizationId',
      'TemplateName',
      'Description',
      'IsActive',
      'Sequence',
      'CreatedBy',
      'CreatedDate',
      'ModifiedBy',
      'ModifiedDate'
    ]
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'TemplateName' || key == 'Description') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    gridarray?.sort((a, b) => {
      const seqA = isNaN(Number(a.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(a.Sequence);
      const seqB = isNaN(Number(b.Sequence)) ? Number.MAX_SAFE_INTEGER : Number(b.Sequence);
      return seqA - seqB;
    });

    // gridarray.sort((a, b) => a.TemplateName.localeCompare(b.TemplateName));
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.showLabAdminLoader = 'hide';
    return gridarray;
    }
   
      else{
        let gridarray = [];
        this.gridData = new CollectionView();
        this.gridShow = true;
        return gridarray;
      }
  }

 
  recieveObjFromListing(object: any) {
    delete object.newData.CaseType
    object.newData = { ...object.newData, ...{ 'Context': 'Sendout Reasons' } };
    if (object.newData.Action == 'Edit') {
      object.newData['Id'] = object.oldData['Id']
      this.insertOrEditSequesterTemplate(object)
    }
    else if (object.newData.Action != 'Create' && object.newData.Action != 'Edit') {
      this.deleteTemplate(object)
    }
    else {
      this.insertOrEditSequesterTemplate(object)
    }
  }


  insertOrEditSequesterTemplate(data) {
    this.ngxService.start();
    this.vitalHttpServices.templateManagementCE(data.newData,this.destDeployment).subscribe(response => {
      if (response.status == "Success") {
        if (response.content.message.toString().includes('already exist')) {
          this._snackbar.open(response.content.message, "Close");
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          return
        }
        else {
          if (data.newData.Action === 'Create') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            data.newData['IsActive'] = this.commonService.getStatus(data.newData['IsActive']);
            data.newData['Sequence'] = response.content['sequence'];
            response.content['isActive'] = this.commonService.getStatus(response.content['isActive']);
            this.commonService.auditDetails('id', 'TemplateName', [response.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
          }
          else if (data.newData.Action === 'Edit') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            data.newData['IsActive'] = this.commonService.getStatus(data.newData['IsActive'])
            this.commonService.auditDetails('Id', 'TemplateName', [data.oldData], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(response.content.message, "Close")
          }
          this.getAllSendoutReasons();
          // this.addGridData(this.templateData.submenuData);
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open("failed!", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  deleteTemplate(value) {
    if (!this.deleteBtn) {
      let deleteId;
      deleteId = { ...{ 'Context': 'Sendout Reasons', 'Action': 'Delete', 'Id': value.rowData.Id } };
      value.rowData = { ...value.rowData, ...{ 'Context': 'Sendout Reasons', 'Action': 'Delete' } };
      value.rowData.IsActive = value.rowData.IsActive == 'Active' ? 1 : 0;
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
          autoFocus: false,
        width: '400px',
        panelClass: 'admin-custom-popup',
        // data: { header: "", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.templateManagementCE(deleteId, this.destDeployment).subscribe(
            () => {
              value.rowData.IsActive = value.rowData.IsActive == 1 ? 'Active' : 'Inactive';
              this.commonService.auditDetails('Id', 'TemplateName', [value.rowData], [], 'Delete', this.templateData, this.auditableColumns);
              this._snackbar.open('Sendout reasons deleted successfully.', 'Close');
              //this.selectedData = { caseType: '', userId: '' };
              this.getAllSendoutReasons();
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }

  getAllSendoutReasons() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getallsendoutreasons');
    queryVariable = {
      "orgid": this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id')
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
        }
        this.ngxService.stop();
      },
      error => {
        // this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  createActivityTrackerForSort($event: any) {
    if(this.sourceApp !== 'VitalDx') {
      this.commonService.auditDetails('', '', [], $event, 'Re-Order', this.templateData, '');
    }
  }

}
