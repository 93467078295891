export class StaticMessages{
    public static readonly ON_SAVE_SUCCESS = "Data Saved Successfully."
    public static readonly ON_ERROR = "Something Went Wrong."
    public static readonly OK = "OK"
    public static readonly CANCEL = "Cancel"
    public static readonly ACTIVATE_USER = 'Activated User'
    public static readonly DEACTIVATE_USER = 'Deactivated User'
    public static readonly CLOSE = 'Close'
    public static readonly CONFIGURE_EMAIL_MESSAGE = 'Provide Email ID to send the invitation.'
    public static readonly AUTO_REGISTER_DOMAIN = 'Invitations can only be sent to an email address from the authorized domain.'
    public static readonly ALLOW_DOMAINS = 'Email domain not supported. Please use an email address from the authorized domain.'
    public static readonly LOGIN_THROUGH_DOMAIN_PASSWORD = 'This user should use their domain password to login.'
    public static readonly PROCEED = "Proceed"
}

//#region Popup
export function userStatusUpdateMessage(status: string, roleName: string, username: string){
    return `Are you sure you want to ${status} the user ${username} (${roleName})?`
}
//#endregion

//#region Snackbar
export function userStatusUpdateSnackbarMessage(status: string, roleName: string, isPrimary: boolean){

    return `${roleName}${isPrimary? ' (Primary)': ''} Role ${status} Successfully.`
}
//#endregion