import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { finalize, map, startWith, take } from 'rxjs/operators';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';

export interface Task {
  color: ThemePalette;
}

export class Assignee {
  UserId: number;
  FormattedDisplayName?: string;
}

@Component({
  selector: 'app-manage-auto-assignment-rules',
  templateUrl: './manage-auto-assignment-rules.component.html',
  styleUrls: ['./manage-auto-assignment-rules.component.scss']
})

export class ManageAutoAssignmentRulesComponent implements OnInit {
  @ViewChild("widgetEditorModal") public widgetEditorModal: TemplateRef<any>;
  private widgetEditorDialogRef: MatDialogRef<TemplateRef<any>>;

  name = "Angular";
  isModalOpen = false;

  selectedRow: {
    orderingFacilityAccountid: string;
    laboratoryAccountid: string;
    services: string;
    casetype: string;
    primaryAccountid: number;
    primaryUserid: number;
    secondaryAccountid: any;
    tertiaryAccountid: any;
    secondaryUserid: any;
    tertiaryUserid: any;
    oFOrgID: string;
    labOrgID: number;
    isActive: boolean;  // Rule Status
    isConfiguredAutoSentOut: any;  // Rule Auto Send Out
    effectiveDate: string;
    expiryDate: string;
    ruleID: number;
    OrderingPhysician: string;
    organizationID: number;
    isCytotechnologistRule: boolean;
    category: string;
    payerNameOrFamily: string;
    payerType: string;
    planType: string;
    billTo: string;
    notes: string
  };

  masterCopyOFPhysiciansList: any;
  masterCopyOFLocationList: any;
  activateOrDeactivate: boolean = false;
  confirmationMessage: string;
  statusBtnName: string;
  private pathObject: any = {
    "FormattedDisplayName": "Available to all Pathologist",
    "UserId": -100,
    "RoleName": "Pathologist",
    "AccountId": -1
  };
  newObj: {
    orderingFacilityAccountid: string;
    laboratoryAccountid: string;
    services: string;
    casetype: string;
    primaryAccountid: number;
    primaryUserid: number;
    secondaryAccountid: number;
    tertiaryAccountid: number;
    secondaryUserid: number;
    tertiaryUserid: number;
    oFOrgID: number;
    labOrgID: any;
    isActive: boolean;  // Rule Status
    isConfiguredAutoSentOut: any; // Rule Auto Send Out
    effectiveDate: string;
    expiryDate: string;
    ruleID: number;
    OrderingPhysician: string;
    organizationID: number;
    isCytotechnologistRule: boolean;
    category: string;
    actionPerformedBy: number;
    payerNameOrFamily: string;
    payerType: string;
    planType: string;
    billTo: string;
    notes: string;
  };
  auditableColumns: any;
  userSessionDetails: any;
  sourceApp: string;
  primaryPathLicenseInfo: any;
  isFacitlityContext: boolean = false;
  secondaryPathLicenseInfo: any;
  tertiaryPathLicenseInfo: any;
  changeStatus: Boolean;
  selectedData: { caseType: string; userId: string; };
  frmIsPayerFamily = new FormControl();
  enableInsurance: any;
  previousAutoSendvalue: boolean;

  constructor(public ngxService: NgxUiLoaderService, public datePipe: DatePipe, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private labadminService: LabadminService, private labAdminSessionService: LabadminSessionService, private store: Store<{ breadcrum: [] }>,
    private _snackBar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public activityService: ActivityTrackerService, public _snackbar: MatSnackBar,
    private templateListHeaders: TemplateHeadersService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
  }

  isDelete: boolean;
  listData: any;
  gridData: wjcCore.CollectionView<any>;
  deleteRuleID: any;
  isExport: boolean;
  isPathologistLicenseAlert: boolean = true;
  isUpload: boolean;
  isCopy: boolean;
  buttonFlags: any;
  ExportData: any;
  isCreate: boolean;
  i: number = 0;
  selectedContext: any;

  SubMenuCardModel: SubMenuCardModel;
  selectedLabLocations: any = [];
  selectedOFLocations: any = [];
  selectedOFPhysicians: any = [];
  selectedInsurance: any = [];
  selectedPayer: any = [];
  selectedPayerFamiliy: any = [];
  selectedInsurancePlanType: any = [];
  selectedBillTo: any = [];
  OFPhysiciansList = [];
  labLocationList = [];
  OFLocationList = [];
  servicesList = ["Global", "Professional Only", "Consult"];
  billToList = [];
  caseTypesList = [];
  insurancePlanTypeDataList = [];
  insuranceDataList = [];
  primaryAssigneeList = [];
  secondaryAssigneeList = [];
  tertiaryAssigneeList = [];
  readingLocationList = [];
  assigneeList = [];
  isExpandCategory: boolean[] = [];
  selectedCasetypes: any[] = [];
  filteredCasetypeList: any[];
  selectedServices: any = [];
  UploadGridData: any;
  uploadGridHeader: any;
  gridwidth: number = 0;
  UploadGridDataObj: any;
  isExportFlag: boolean = false;
  exportIDs: any = [];
  isEdit: boolean;
  isDeleteRule: boolean;
  isPrimaryAccountEPG = false;
  isSecondaryAccountEPG = false;
  isTertiaryAccountEPG = false;

  // toggle for Status & Auto Send Out
  isExternalOrgTab: boolean = false;
  // toggle values
  ruleStatus: boolean = false;
  autoSendoutStatus: boolean = false;

  isStatusChecked: boolean = true;
  isAutoSendChecked: boolean = false;

  task: any = { color: 'primary' };

  @Input() cardtype: any;
  @Input() templateData: any;
  @Input() public subMenudata: any;
  @Input() public cardIdentifier: number = -1;

  addEditScreen = false;
  gridScreen = true;
  allSelectedLabs = false;
  allSelectedOFs = false;
  allSelectedOFPhysicians = false;
  allSelectedCasetype = false;
  allSelectedInsurance = false;
  allSelectedInsurancePlanType = false;
  allSelectedBillTo = false;
  allSelectedServices = false;
  isPayerFamily = false;

  @ViewChild('matSelectOptionLabLocation') matSelectOptionLabLocation: MatSelect;
  @ViewChild('matSelectOptionOFLocation') matSelectOptionOFLocation: MatSelect;
  @ViewChild('matSelectOptionOFPhysician') matSelectOptionOFPhysician: MatSelect;

  filteredLabLocation: Observable<any[]>;
  filteredOFLocation: Observable<any[]>;
  filteredOFPhysician: Observable<any[]>;
  filteredInsurance: Observable<any[]>;
  filteredInsurancePlanType: Observable<any[]>;
  filteredBillTo: Observable<any[]>;
  selected: { [id: number]: boolean } = {};
  minFromDate = new Date();
  maxToDate = new Date().setDate(2);
  minToDate = new Date();
  isPathologistTab = true;
  primaryReadingLocationList = [];
  cytoTechnologistList = [];
  pathologistList = [];
  editClicked = false;
  createClicked = false;

  // Effective Date Current Date(Today's Date) Expiry Date (Current Date to Till 5 Years)
  todayDate = new Date();
  newDate: any = new Date(this.todayDate);
  futureDate = this.newDate.setFullYear(this.newDate.getFullYear() + 5);

  AARFormGrp = this._fb.group({
    frmLabLocations: [[], Validators.required],
    frmOFLocations: [[], Validators.required],
    frmOFPhysicians: [[], Validators.required],
    frmCasetypes: [[{}], Validators.required],
    frmInsurance: [[{}]],
    frmInsurancePlanType: [[{}]],
    frmServices: [[], Validators.required],
    frmBillto: [[{}]],
    frmEffectiveDate: [this.todayDate, Validators.required],
    frmExpiryDate: [this.futureDate, Validators.required],
    frmPrimaryReadingLocation: ["", Validators.required],
    frmPrimaryAssignee: [new Assignee(), Validators.required],
    frmSecondaryReadingLocation: [""],
    frmSecondaryAssignee: [new Assignee()],
    frmTertiaryReadingLocation: [""],
    frmTertiaryAssignee: [new Assignee()],
    frmIsPayerFamily: [""],
    frmNotes: [""]
  });

  frmStatus = new FormControl();
  AutoSendOutGrp = this._fb.group({
    frmAutoSendOut: false
  })

  lastOFFilter: string = '';
  lastOFPhycFilter: string = '';

  tabList: any = ['Pathologist', 'Cytotechnologist', 'External Organization'];
  selectedTab: string = this.tabList[0];

  gridheader: any = ["Name", "Ordering Facility", "Ordering Physician", "Casetype", "Effective Date", "Expiry Date"];
  activeTab = 0;
  searchText: string = '';
  dbName = sessionStorage.getItem('deploymentKey')?.toUpperCase() ? this.labadminService.deploymentKey : sessionStorage.getItem('deploymentKey').toUpperCase();
  OrganizationId = this.labadminService.organizationId! ? this.labadminService.organizationId : this.tabService.tabs[0].tabData.mainCard.Org_ID;
  defaultSelectedVal = 'Active';
  defaultHeader: string = '';
  accountType: any;
  primaryReadingLocation: boolean = false;
  secondaryReadingLocation: boolean = false;
  tertiaryReadingLocation: boolean = false;
  primaryAssignee: boolean = false;
  secondaryAssignee: boolean = false;
  tertiaryAssignee: boolean = false;
  ofPhysician: any = [];
  filteredOF: any = [];
  initialValue = {}
  activityEntity: any;
  saveOrEditCompleted: number = Math.floor(Math.random() * 500) + 1;
  stopLoaderDX: boolean = true;

  async ngOnInit() {
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      this.sourceApp = 'VitalDx';
      if (checkLaunchUrl.includes('clients')) {
        this.isFacitlityContext = true;
        this.OrganizationId = this.labadminService.clientOrgid;
      }
      else {
        this.isFacitlityContext = false;
      }
      await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async (session: any) => {
        await this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
          let menuObj = result[result.length - 1];
          if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
            this.userSessionDetails = session.userDetails
            await this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails);
            this.templateData = this.labadminService.templateData;
            this.dbName = this.labadminService.deploymentKey;
            sessionStorage.setItem('deploymentKey', this.dbName);
            if (checkLaunchUrl.includes('clients')) {
              this.isFacitlityContext = true;
              this.tabList = ["Pathologist"];
              this.selectedTab = this.tabList[0];
            } else {
              this.isFacitlityContext = false;
              await this.checkOrgtype()
            }
            this.getRuleList();
            this.getAuditableDetails(this.templateData.menuURL);
            this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' }, { 'key': 'Pathology Management', 'value': this.templateData['menuURL'] }] })
            this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
            this.GetButtondetails()
          }
        })
      })
    }
    else {
      this.isFacitlityContext = sessionStorage.getItem('selectedContext') === 'Facility' ? true : false;
      await this.checkOrgtype()
      await this.getRuleList();
      await this.getAuditableDetails(this.templateData.menuURL);
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      await this.GetButtondetails();
    }
    this.filteredLabLocation = this.AARFormGrp.controls.frmLabLocations.valueChanges.pipe(
      startWith<Array<object>>([{}]),
      map((value) => (typeof value === 'string' ? value : "")),
      map((filter) => this.filterFields(filter, 'Lab', 'LabName', 'LabAccountId'))
    );

    this.filteredOFLocation = this.AARFormGrp.controls.frmOFLocations.valueChanges.pipe(
      startWith<Array<object>>([{}]),
      map((value) => (typeof value === 'string' ? value : "")),
      map((filter) => this.filterOF(filter))
    );

    this.filteredOFPhysician = this.AARFormGrp.controls.frmOFPhysicians.valueChanges.pipe(
      startWith<Array<object>>([{}]),
      map((value) => (typeof value === 'string' ? value : "")),
      map((filter) => this.filterOFPhyician(filter))
    );

    this.filteredInsurance = this.AARFormGrp.controls.frmInsurance.valueChanges.pipe(
      startWith<Array<object>>([{}]),
      map((value) => (typeof value === 'string' ? value : "")),
      map((filter) => this.filterFields(filter, 'Insurance'))
    );

    this.filteredInsurancePlanType = this.AARFormGrp.controls.frmInsurancePlanType.valueChanges.pipe(
      startWith<Array<object>>([{}]),
      map((value) => (typeof value === 'string' ? value : "")),
      map((filter) => this.filterFields(filter, 'InsurancePlanType', 'AttributeName'))
    );

    this.filteredBillTo = this.AARFormGrp.controls.frmBillto.valueChanges.pipe(
      startWith<Array<object>>([{}]),
      map((value) => (typeof value === 'string' ? value : "")),
      map((filter) => this.filterFields(filter, 'BillTo', 'AttributeName'))
    );

    this.AARFormGrp.controls.frmLabLocations.valueChanges.subscribe((labLocations: any) => {
      const arrayData = cloneDeep(this.caseTypesList);

      if (labLocations && labLocations.some(v => v.LabAccountId === -1)) {
        this.filteredCasetypeList = this.getUniqueObjects(arrayData, "CaseType", true).filter(x => (x.Children.length > 0));
      } else {
        this.filteredCasetypeList = arrayData.map(ele => ({
          ...ele,
          Children: ele.Children.filter((subElement) => subElement.AccountId && labLocations?.some(a => a.LabAccountId?.toString() === subElement.AccountId?.toString()))
        }));
        this.filteredCasetypeList = this.getUniqueObjects(this.filteredCasetypeList, "CaseType", true).filter(x => (x.Children.length > 0));
      }
    });

    this.AARFormGrp.controls.frmEffectiveDate.valueChanges.subscribe(x => {
      this.setExpiryDate();
    })
    // this.GetButtondetails()
  }

  checkUplaodDataGrid() {
    if (this.UploadGridDataObj && this.UploadGridDataObj.uploadGridData.length > 0) {
      this.gridwidth = this.UploadGridDataObj.uploadGridHeader.length;
      this.uploadGridHeader = this.UploadGridDataObj.uploadGridHeader;
      this.UploadGridData = this.UploadGridDataObj.uploadGridData;
      return true
    } else {
      return false;
    }
  }

  displayGridData(data, header) {
    let tempval = data[header]
    return tempval
  }

  async receiveUploadObject(obj: any) {
    this.UploadGridDataObj = obj;
  }

  back() {
    this.getRuleList();
    this.resetFormData();
    Object.keys(this.AARFormGrp.controls).forEach(key => {
      this.AARFormGrp.controls[key].setErrors(null)
    });
    this.editClicked = false;
    this.createClicked = false;
  }
  //hide or show buttons based on the configuration
  private GetButtondetails() {
    if (this.sourceApp == 'VitalDx') {
      this.GetButtonAccess(this.labadminService.subMenuPermissions);
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
  }

  private GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Auto Assignment Rules")['ActionButton'];
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.isCreate = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
        case "Export":
          this.isExport = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
        case "Edit":
          this.isEdit = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
        case "Delete":
          this.isDeleteRule = seletedMenuPermissions[i].IsPermitted === "true" ? true : false;
          break;
      }
    }
    this.getButtonFlags();
  }

  getButtonFlags() {
    this.buttonFlags = []
    this.buttonFlags.push({ "btnName": "Create", "isBtnAccess": this.isCreate })
    this.buttonFlags = this.buttonFlags.filter(button => button.btnName !== "Export");
    if (this.exportIDs && this.exportIDs.length > 0 && this.isExport) {
      let exportObject = { "btnName": "Export", "isBtnAccess": this.isExport, "isIdAvailble": true, "filename": "AutoAssignmentRules" + this.OrganizationId };
      this.buttonFlags.push(exportObject);
    } else {
      let exportObject = { "btnName": "Export", "isBtnAccess": this.isExport, "isIdAvailble": false, "filename": "AutoAssignmentRules" + this.OrganizationId };
      this.buttonFlags.push(exportObject);
    }
  }

  displayColumns(data: any, header: string) {
    let tempVal;
    switch (header.toLowerCase()) {
      case "name": tempVal = data.Name; break;
      case "ordering facility": tempVal = data.OrderingFacility; break;
      case "ordering physician": tempVal = data.OrderingPhysician; break;
      case "casetype": tempVal = data.CaseType; break;
      case "effective date": tempVal = data.EffectiveDate; break;
      case "expiry date": tempVal = data.DisplayExpiryDate; break;
    }
    return tempVal;
  }

  checkIsactive(data) {
    return data ? true : false;
  }

  getRuleList() {
    const obj = {
      "orgId": this.OrganizationId,
      "accountId": this.sourceApp !== 'VitalDx' ? this.templateData.cardIdentifier.toString(): (this.isFacitlityContext ?this.labadminService.detailsData.ofAccountId.toString() : this.labadminService.accountId.toString()),
      "isPathologist": this.activeTab == 0 ? true : false,
      "isExternalOrg": this.isExternalOrgTab,
      "isOFOrg":this.isFacitlityContext
    }
    this.ngxService.start()
    this.VitalHttpServices.GetRulelist(obj, this.dbName).subscribe(data => {
      if (data.status == 'Success') {
        this.searchText = '';
        this.listData = data.content;
        if (this.templateListHeaders?.templateList) {
          this.templateListHeaders.templateList.forEach(template => {
            const { templateName, headers } = template;
            if (
              (templateName === 'AutoAssignmentRule-path' && this.isPathologistTab && !this.isExternalOrgTab) ||
              (templateName === 'AutoAssignmentRule-cyto' && !this.isPathologistTab && !this.isExternalOrgTab) ||
              (templateName === 'AutoAssignmentRule-externalOrg' && this.isExternalOrgTab)
            ) {
              this.gridheader = headers;
              return; // Early exit from the forEach
            }
          });
        }
        this.gridData = new CollectionView(this.listData);
        this.getButtonFlags();
        this.ngxService.stop()
      }
    }, error => {
      console.error(error);
    });
  }

  changeTab(tab, index) {
    this.activeTab = index;
    this.isPathologistTab = (index == 0) ? true : false;
    this.selectedTab = tab;
    this.isExternalOrgTab = tab == 'External Organization' ? true : false;
    this.servicesList = this.isPathologistTab ? ["Global", "Professional Only", "Consult"] : ["Global", "Professional Only"]
    this.exportIDs = [];
    this.getRuleList();
  }

  close() {
    this.isDelete = false;
    this.activateOrDeactivate = false;
  }

  async deleteData(rule) {
    try {
      if (this.isDeleteRule) {
        if (this.sourceApp == 'VitalDx') {
          let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
            disableClose: true,
            width: '360px',
            panelClass: 'admin-custom-popup',
            data: { header: "", message: "Are you sure you want to delete this Auto Assignment Rule", continue: "Delete", cancel: "Cancel" }
          })
          return dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
              this.deleteRuleID = rule.rowData.RuleId;
              this.deleteRuleList();
            }
          })

        }
        else {
          this.isDelete = true;
          this.deleteRuleID = rule.rowData.RuleId;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async deleteRuleList() {
    try {
      this.ngxService.start();
      this.stopLoaderDX = false;
      this.isDelete = false;
      let obj = { "RuleID": this.deleteRuleID };
      this.selectedRow = await this.getRuleDetails(this.deleteRuleID);
      await this.getLookupValues();
      this.stopLoaderDX = true;
      await this.VitalHttpServices.DeleteRulelist(obj, this.dbName).toPromise().then(data => {
        this.ngxService.stop();
        let result = data.content;
        if (data.status == 'Success') {
          this.auditDetails('Delete');
          this._snackBar.open("Auto Assignment Rule deleted successfully", 'Close');
          this.getRuleList();
        } else {
          this._snackBar.open("Auto Assignment Rule deletion failed", 'Close');
        }
      }, error => {
        console.error(error);
        this._snackBar.open("Something went wrong.Please try again", 'Close');
      });
    } catch (error) {
      this.stopLoaderDX = true;
      this.ngxService.stop();
      console.log(error);
    }
  }
  get filteredData() {
    if (!this.gridData || !Array.isArray(this.gridData || this.searchText.length < 3)) {
      return [];
    }
    let filteredData;

    if (this.searchText && this.searchText.length < 3) {
      filteredData = this.gridData;
    } else {
      filteredData = this.gridData.filter(item => {
        const nameValue = item['Name'] && item['Name'].toString().toLowerCase();
        const facilityValue = item['OrderingFacility'] && item['OrderingFacility'].toString().toLowerCase();
        const physicianValue = item['OrderingPhysician'] && item['OrderingPhysician'].toString().toLowerCase();
        const casetypeValue = item['CaseType'] && item['CaseType'].toString().toLowerCase();

        return (nameValue && nameValue.includes(this.searchText.toLowerCase())) ||
          (facilityValue && facilityValue.includes(this.searchText.toLowerCase())) ||
          (physicianValue && physicianValue.includes(this.searchText.toLowerCase())) ||
          (casetypeValue && casetypeValue.includes(this.searchText.toLowerCase()));
      });
    }
    this.exportIDs = [];
    filteredData.forEach(item => {
      if (item && item.RuleId !== undefined) {
        this.exportIDs.push(item.RuleId);
      }
    });
    this.ExportData = this.exportIDs.length > 0 ? this.exportIDs : [];
    this.getButtonFlags();
    return filteredData;
  }

  getExportData() {
    let obj = {
      "ruleId": this.exportIDs.toString()
    }
    this.ngxService.start()
    this.back()
    this.VitalHttpServices.GetRuleExportList(obj, this.dbName).subscribe(data => {
      if (data.status == 'Success') {
        let tempObj = {
          "filename": "AutoAssignmentRule",
          "exportData": data.content,
          "sheetname": this.activeTab == 0 ? "PathologistRule" : "CytotechnologistRule"
        }
        this.ExportGridToExcel(tempObj);
        this.ngxService.stop()
      }
      else {
        this._snackBar.open('Something went wrong.Please try again', 'Close');
        this.ngxService.stop()
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    })

  }

  ExportGridToExcel(data) {
    let filename = data.filename + '_' + this.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(data.exportData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + data.sheetname);
    XLSX.writeFile(wb, filename);
  }

  isExpiredToday(inputDate: Date, excludeToday?: boolean): boolean {
    const today = new Date();
    const inputDateWithoutTime = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    return excludeToday ? (inputDateWithoutTime < todayWithoutTime) : (inputDateWithoutTime <= todayWithoutTime);
  }

  // Create & Edit
  validateExpiryDate(control: AbstractControl): ValidationErrors | null {
    const effectiveDate: any = this.AARFormGrp?.get('frmEffectiveDate').value;
    const expiryDate = control.value;
    if (this.isExpiredToday(expiryDate)) {
      return { invalidExpiryDate: true };
    } else if (expiryDate > effectiveDate) {
      return null;
    } else if (expiryDate < effectiveDate) {
      return { invalidExpiryDate: true };
    }
    return null;
  }

  get formControl() {
    return this.AARFormGrp.controls;
  }

  getMasterCopyForDropdown(type: string) {
    switch (type) {
      case 'Lab': return (this.labLocationList && this.labLocationList.length > 0) ? this.labLocationList : []
      case 'OF': return (this.OFLocationList && this.OFLocationList.length > 0) ? this.OFLocationList : []
      case 'Physician': return (this.OFPhysiciansList && this.OFPhysiciansList.length > 0) ? this.OFPhysiciansList : []
      case 'Insurance': return (this.insuranceDataList && this.insuranceDataList.length > 0) ? this.insuranceDataList : []
      case 'InsurancePlanType': return (this.insurancePlanTypeDataList && this.insurancePlanTypeDataList.length > 0) ? this.insurancePlanTypeDataList : []
      case 'BillTo': return (this.billToList && this.billToList.length > 0) ? this.billToList : []
      case 'Services': return (this.servicesList && this.servicesList.length > 0) ? this.servicesList : []
      case 'ReadingLocation': return (this.readingLocationList && this.readingLocationList.length > 0) ? this.readingLocationList : []
      case 'Assignee': return (this.assigneeList && this.assigneeList.length > 0) ? this.assigneeList : []
      case 'Casetypes': return (this.caseTypesList && this.caseTypesList.length > 0) ? this.caseTypesList : []
    }
  }

  filterFields(filter: string, type: string, key1?: string, key2?: string): any[] {
    let dataArray = this.getMasterCopyForDropdown(type) ? this.getMasterCopyForDropdown(type) : [];
    if (dataArray && dataArray.length > 0) {
      if (filter) {
        return dataArray.filter((option) => {
          if (type == 'Insurance') {
            if (this.isPayerFamily ? option.PayerFamily : option.PayerName) {
              return (
                (this.isPayerFamily ? (option.PayerFamily.toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0) :
                  (option.PayerName.toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0))
              );
            }
          } else {
            return (
              key1 ? (option[key1].toString().toLowerCase().indexOf(filter.toString().toLowerCase()) >= 0) : (option.toString().toLowerCase().indexOf(filter.toString().toLowerCase()) >= 0) ||
                (option[key2]?.toString().toLowerCase().indexOf(filter.toString().toLowerCase()) >= 0)
            );
          }
        });
      } else if (type == 'Insurance') {
        if (this.isPayerFamily) {
          return this.getUniqueValues(dataArray.filter(x => x.PayerFamily), 'PayerFamily')
        } else {
          return this.getUniqueValues(dataArray.filter(x => x.PayerName), 'PayerName')
        }
      } else {
        return dataArray;
      }
    } else {
      return [];
    }
  }

  filterOF(filter: string): any[] {
    const array = this.selectedLabLocations.some(ele => ele.LabAccountId.toString() == '-1') ? this.getMasterCopyForDropdown('Lab') : this.selectedLabLocations;

    const filteredOF = this.getUniqueValues(this.getMasterCopyForDropdown('OF').filter(ele => array.some(el => el.LabAccountId == ele.LabAccountId)), 'OfAccountId');

    if (filter) {
      return filteredOF.filter(option =>
        option.OrderingFacilityName.toLowerCase().includes(filter.toLowerCase()) ||
        option.LabAccountId.toString().includes(filter.toString())
      );
    } else {
      if (this.selectedLabLocations.length > 1 || this.allSelectedLabs == true) {
        const combinedData = [...filteredOF];
        const uniqueData = combinedData.filter((value, index, self) =>
          self.findIndex(v => v.OrderingFacilityName === value.OrderingFacilityName && v.OfAccountId === value.OfAccountId) === index
        );
        return uniqueData;
      }
      return filteredOF.slice();
    }
  }

  filterOFPhyician(filter: string): any[] {
    const array = this.selectedOFLocations.some(el => el.OfAccountId == -1) ? this.getMasterCopyForDropdown('OF') : this.selectedOFLocations;

    const ofPhysician = this.getUniqueValues(this.getMasterCopyForDropdown('Physician').filter(ele => array.some(el => el.OfAccountId == ele.OfAccountId)), 'UserId');

    if (filter) {
      return ofPhysician.filter((option) => {
        return (
          option.OrderingPhysicianname.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          option.UserId.toString().toLowerCase().indexOf(filter.toString().toLowerCase()) >= 0
        );
      });
    } else {
      return ofPhysician;
    }
  }

  onReadingLocChange(loc, count, isSelected?: Boolean, e?: MatSelectChange) {
    if (isSelected || e?.source.selected) {
      const selectedValues = [
        this.AARFormGrp.controls.frmPrimaryAssignee.value?.UserId,
        this.AARFormGrp.controls.frmSecondaryAssignee.value?.UserId,
        this.AARFormGrp.controls.frmTertiaryAssignee.value?.UserId
      ];

      const sendToPrimary = [selectedValues[1], selectedValues[2]];
      const sendToSecondary = [selectedValues[0], selectedValues[2]];
      const sendToTertiary = [selectedValues[0], selectedValues[1]];

      const filterByAccountId = (element) => ({
        ...element,
        UserDetailsList: element.UserDetailsList.filter(subElement =>
          subElement.AccountId.toString() === loc.toString()
        )
      });

      const updateAssigneeList = (list, selectedAssignee, sendToList) => {
        if (this.AARFormGrp.controls[selectedAssignee].value) {
          const obj = this.AARFormGrp.controls[selectedAssignee].value || {};
          return (obj && Object.keys(obj).length > 0) ?
            this.filterAssigneeList(list, sendToList) : list;
        }
        return (list && list.length > 0) ? list.filter(x => (x.UserDetailsList.length > 0)) : [];
      };

      switch (count) {
        case 1:
          this.validateAssigneeSection();
          this.primaryAssigneeList = this.assigneeList?.map(filterByAccountId).filter(x => x.UserDetailsList.length > 0);
          this.primaryAssigneeList = updateAssigneeList(this.primaryAssigneeList, 'frmSecondaryAssignee', sendToPrimary);
          this.primaryAssigneeList = updateAssigneeList(this.primaryAssigneeList, 'frmTertiaryAssignee', sendToPrimary);
          if (this.createClicked && this.primaryAssigneeList?.length == 1) {
            if (this.primaryAssigneeList[0].UserDetailsList.length == 1) {
              const primaryAssignee = this.primaryAssigneeList[0].UserDetailsList[0].UserId ? (this.primaryAssigneeList[0].UserDetailsList[0].UserId == -100 ? (this.pathObject) : this.getAssigneeObj(Number(this.primaryAssigneeList[0].UserDetailsList[0].UserId), Number(this.primaryAssigneeList[0].UserDetailsList[0].AccountId))) : null;
              this.AARFormGrp.patchValue({
                frmPrimaryAssignee: primaryAssignee
              });
              // this.onAssigneeSelection(0);
              this.secondaryAssigneeList = this.filterAssigneeList(this.secondaryAssigneeList, sendToSecondary);
              this.tertiaryAssigneeList = this.filterAssigneeList(this.tertiaryAssigneeList, sendToTertiary);
              this.validateAssigneeSection();
            }
          }
          break;
        case 2:
          this.validateAssigneeSection();
          this.secondaryAssigneeList = this.assigneeList?.map(filterByAccountId).filter(x => x.UserDetailsList.length > 0);
          this.secondaryAssigneeList = updateAssigneeList(this.secondaryAssigneeList, 'frmPrimaryAssignee', sendToSecondary);
          this.secondaryAssigneeList = updateAssigneeList(this.secondaryAssigneeList, 'frmTertiaryAssignee', sendToSecondary);
          break;
        case 3:
          this.validateAssigneeSection();
          this.tertiaryAssigneeList = this.assigneeList?.map(filterByAccountId).filter(x => x.UserDetailsList.length > 0);
          this.tertiaryAssigneeList = updateAssigneeList(this.tertiaryAssigneeList, 'frmPrimaryAssignee', sendToTertiary);
          this.tertiaryAssigneeList = updateAssigneeList(this.tertiaryAssigneeList, 'frmSecondaryAssignee', sendToTertiary);
          break;
      }
    } else {
      this.validateAssigneeSection();
    }
  }

  onPayerChange(event) {
    this.isPayerFamily = (event.value == 'Payer') ? false : true;
    let str = (this.isPayerFamily ? 'PayerFamily' : 'PayerName')
    this.enableInsurance = this.getUniqueValues(this.insuranceDataList, str);
    this.selectedInsurance = []
    this.insuranceDataList = this.insuranceDataList.map(ele => ({ ...ele, selected: false }));
    this.allSelectedInsurance = false;
    this.formControl["frmInsurance"].patchValue(this.selectedInsurance);
    this.formControl["frmInsurance"].updateValueAndValidity();
    this.removeChip(this.selectedInsurance, 'Insurance')
  }

  optionClicked(event: Event, data, type?: string) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleSelection(data, type);
  }

  manageEnablingFields() {
    if (this.selectedLabLocations && this.selectedLabLocations.length > 0) {
      this.formControl["frmOFLocations"].enable();
      this.formControl["frmCasetypes"].enable()
    } else {
      this.allSelectedOFs = false;
      this.allSelectedOFPhysicians = false;
      this.allSelectedCasetype = false;
      this.selectedCasetypes = [];
      this.formControl["frmOFLocations"].disable()
      this.formControl["frmOFPhysicians"].disable()
      this.formControl["frmCasetypes"].disable()
      this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);
    }

    if (this.selectedOFLocations?.length > 0) {
      this.formControl["frmOFPhysicians"].enable()
    } else {
      this.allSelectedOFPhysicians = false;
      this.formControl["frmOFPhysicians"].disable()
    }
  }

  manageFieldsData(typeString: string) {
    const ofPhyArray = cloneDeep(this.masterCopyOFPhysiciansList)
    const ofArray = cloneDeep(this.masterCopyOFLocationList)

    switch (typeString) {
      case 'Lab':
        if (this.selectedLabLocations.length > 0) {
          this.labLocationList = this.labLocationList.map(ele => ({ ...ele, selected: (this.selectedLabLocations.some(x => x.LabAccountId === ele.LabAccountId)) ? true : ele.selected }));
          this.allSelectedLabs = (this.labLocationList.length > 0) ? (this.labLocationList.every(ele => ele.selected == true) ? true : false) : false;
          this.selectedLabLocations = this.allSelectedLabs ? [{ LabName: 'ALL', LabAccountId: -1, selected: true }] : this.selectedLabLocations;
        } else {
          this.labLocationList = this.labLocationList.map(ele => ({ ...ele, selected: false }))
          this.selectedOFPhysicians = [];
          this.selectedOFLocations = [];
          this.selectedCasetypes = [];
          this.allSelectedLabs = false
          this.allSelectedOFs = false
          this.allSelectedOFPhysicians = false
          this.allSelectedCasetype = false
          this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);
        }
        this.AARFormGrp.controls.frmLabLocations.patchValue(this.selectedLabLocations);
        this.AARFormGrp.controls.frmLabLocations.updateValueAndValidity();
        this.manageFieldsData('OF');
        // this.manageFieldsData('Physician');
        break;
      case 'OF':
        if (this.selectedLabLocations.length > 0) {
          // OF Filter
          let tempOFList = []
          if (!this.isFacitlityContext) {
            tempOFList = this.allSelectedLabs ? this.getUniqueValues(ofArray, 'OfAccountId') :
              ofArray.filter(ele => (this.selectedLabLocations.some(x => x.LabAccountId == ele.LabAccountId)))
          } else {
            tempOFList = this.allSelectedLabs ? this.getUniqueValues(ofArray, 'OfAccountId') :
              ofArray.filter(ele => (this.selectedLabLocations.some(x => x.LabAccountId == ele.LabAccountId && this.OrganizationId == ele.OrgId)))
          }

          if (this.selectedOFLocations.length > 0) {
            this.OFLocationList = this.getUniqueValues(tempOFList.map(ele => ({
              ...ele, selected: (
                ((this.selectedLabLocations.some(x => x.LabAccountId !== -1)) ? (this.selectedLabLocations.some(x => x.LabAccountId != -1 && x.LabAccountId == ele.LabAccountId)) : true)
                && (this.selectedOFLocations.some(y => y.OfAccountId != -1 && y.OfAccountId == ele.OfAccountId))) ? true : ele.selected
            })), 'OfAccountId');
          } else {
            this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(x => ({ ...x, selected: false }))

            this.OFLocationList = this.getUniqueValues(tempOFList.map(ele => ({
              ...ele, selected: false
            })), 'OfAccountId');
          }
          if (this.OFLocationList.length == 1 && this.createClicked) {
            this.allSelectedOFs = true;
            this.OFLocationList = this.OFLocationList.map(x => ({ ...x, selected: true }));
          }
          this.selectedOFLocations = this.OFLocationList.filter(ele => ele.selected == true)
          this.allSelectedOFs = (this.OFLocationList.length > 0) ? (this.OFLocationList.every(x => x.selected == true) ? true : false) : false;
          this.selectedOFLocations = this.allSelectedOFs ? [{ OrderingFacilityName: 'ALL', OfAccountId: -1, selected: true }] : this.selectedOFLocations;
        } else {
          this.OFPhysiciansList = this.allSelectedOFs ? this.getUniqueValues(ofPhyArray, 'UserId') :
            ofPhyArray.filter(ele => (this.selectedOFLocations.some(x => x.OfAccountId == ele.OfAccountId)))

          this.selectedOFPhysicians = [];
          this.selectedOFLocations = [];
          this.allSelectedOFPhysicians = false
          this.allSelectedOFs = false
        }
        this.AARFormGrp.controls.frmOFLocations.patchValue(this.selectedOFLocations);
        this.AARFormGrp.controls.frmOFLocations.updateValueAndValidity();
        this.manageFieldsData('Physician');
        break;
      case 'Physician':
        if (this.selectedLabLocations.length > 0 && this.selectedOFLocations.length > 0) {
          const tempOFPhyData = this.allSelectedOFs ? this.getUniqueValues(ofPhyArray, 'UserId') :
            ofPhyArray.filter(ele => (this.selectedOFLocations.some(x => x.OfAccountId == ele.OfAccountId)))

          if (this.selectedOFPhysicians.length > 0) {
            this.OFPhysiciansList = this.getUniqueValues(tempOFPhyData.map(ele => ({
              ...ele, selected: (((this.selectedOFLocations.some(x => x.OfAccountId !== -1)) ? (this.selectedOFLocations.some(x => x.OfAccountId == ele.OfAccountId)) : true)
                && (this.selectedOFPhysicians.some(y => y.UserId == ele.UserId))) ? true : ele.selected
            })), 'UserId')
          } else {
            this.OFPhysiciansList = this.getUniqueValues(tempOFPhyData.map(ele => ({
              ...ele, selected: false
            })), 'UserId')
          }
          if (this.OFPhysiciansList.length == 1 && this.createClicked) {
            this.allSelectedOFPhysicians = true;
            this.OFPhysiciansList = this.OFPhysiciansList.map(x => ({ ...x, selected: true }));
          }
          this.selectedOFPhysicians = this.OFPhysiciansList.filter(ele => ele.selected == true)
          this.allSelectedOFPhysicians = (this.OFPhysiciansList.length > 0) ? (this.OFPhysiciansList.every(x => x.selected == true) ? true : false) : false;
          this.selectedOFPhysicians = this.allSelectedOFPhysicians ? [{ OrderingPhysicianname: 'ALL', UserId: -1, selected: true }] : this.selectedOFPhysicians;
        } else {
          this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(ele => ({ ...ele, selected: false }))

          this.OFPhysiciansList = this.OFPhysiciansList.map(ele => ({ ...ele, selected: false }))
          this.selectedOFPhysicians = [];
          this.allSelectedOFPhysicians = false
        }
        this.formControl["frmOFPhysicians"].patchValue(this.selectedOFPhysicians);
        this.formControl["frmOFPhysicians"].updateValueAndValidity();
        break;
    }
  }

  toggleSelection(data, type: string) {
    try {
      data.selected = !data.selected;
      switch (type) {
        case 'Lab':
          if (data.selected) {
            this.selectedLabLocations.push(data);
          } else {
            if (this.selectedLabLocations.some(x => x.LabAccountId.toString() === '-1')) {
              this.selectedLabLocations = this.labLocationList.filter(x => x.LabAccountId !== data.LabAccountId)
            } else {
              const i = this.selectedLabLocations.findIndex(
                (value) =>
                  value.LabName === data.LabName && value.LabAccountId === data.LabAccountId
              );
              this.selectedLabLocations.splice(i, 1);
            }
            this.selectedOFLocations = this.selectedOFLocations.filter(e => e.LabAccountId != data.LabAccountId)

            const userids = this.selectedOFPhysicians.filter(x => !(this.selectedOFLocations.some(y => y.OfAccountId == x.OfAccountId)))
            this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: userids.some(d => d.UserId == x.UserId) ? false : x.selected }))
            this.selectedOFPhysicians = this.masterCopyOFPhysiciansList.filter(x => x.selected == true)
          }
          this.manageFieldsData(type);
          const arrayData = cloneDeep(this.caseTypesList);

          if (this.selectedLabLocations.length > 0) {
            if (this.selectedLabLocations.some(v => v.LabAccountId === -1)) {
              this.filteredCasetypeList = this.getUniqueObjects(arrayData, "CaseType", true).filter(x => (x.Children.length > 0));
            } else {
              this.filteredCasetypeList = arrayData.map(ele => ({
                ...ele,
                Children: ele.Children.filter(subElement =>
                  this.selectedLabLocations.some(a => a.LabAccountId.toString() === subElement.AccountId.toString())
                )
              }));
              this.filteredCasetypeList = this.getUniqueObjects(this.filteredCasetypeList, "CaseType", true).filter(x => (x.Children.length > 0));
            }
            if (this.filteredCasetypeList.length == 1 && this.createClicked) {
              let casetypeLength: boolean = false;
              this.filteredCasetypeList.forEach(x => {
                if (x.Children.length == 1) {
                  casetypeLength = true;
                } else if (x.Children.length > 1) {
                  casetypeLength = false;
                }
              })
              if (casetypeLength) {
                this.filteredCasetypeList.forEach(x => {
                  x.Children.forEach(subEle => {
                    subEle.selected = true;
                  })
                  x.selected = (x.Children.every(v => v.selected)) ? true : false
                })
              }
            }
          }

          this.selectedCasetypes = this.selectedCasetypes.filter(x => this.selectedLabLocations.some(v => (v.LabAccountId == x.AccountId)))
          if (this.selectedCasetypes.length > 0) {
            this.filteredCasetypeList.forEach(x => {
              x.Children.forEach(subEle => {
                if (subEle && (this.selectedCasetypes.some(v => (v.CaseType.toString() == subEle.CaseType.toString())))
                ) {
                  subEle.selected = true;
                } else {
                  subEle.selected = false;
                }
              })
              x.selected = (x.Children.every(v => v.selected)) ? true : false
            })
          }

          this.setSelectAllCasetype();
          this.manageEnablingFields();
          break;
        case 'OF':
          let flag = false;
          if (data.selected) {
            this.selectedOFLocations.push(data);
            flag = true;
          } else {
            if (this.selectedOFLocations.some(x => x.OfAccountId.toString() === '-1')) {
              this.selectedOFLocations = this.OFLocationList.filter(x => x.OfAccountId !== data.OfAccountId)
            } else {
              const i = this.selectedOFLocations.findIndex(
                (value) =>
                  value.OrderingFacilityName === data.OrderingFacilityName && value.OfAccountId === data.OfAccountId
              );
              this.selectedOFLocations.splice(i, 1);
            }
          }
          this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(x => ({ ...x, selected: (x.OrderingFacilityName === data.OrderingFacilityName && x.OfAccountId == data.OfAccountId) ? flag : x.selected }))
          this.manageFieldsData(type);
          this.manageEnablingFields();
          break;
        case 'Physician':
          let phyFlag = false;
          if (data.selected) {
            this.selectedOFPhysicians.push(data);
            phyFlag = true;
          } else {
            if (this.selectedOFPhysicians.some(x => x.UserId.toString() == '-1')) {
              this.selectedOFPhysicians = this.OFPhysiciansList.filter(x => x.UserId !== data.UserId)
            } else {
              const i = this.selectedOFPhysicians.findIndex(
                (value) =>
                  value.OrderingPhysicianname === data.OrderingPhysicianname && value.UserId === data.UserId
              );
              this.selectedOFPhysicians.splice(i, 1);
            }
          }
          this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: (x.OrderingPhysicianname === data.OrderingPhysicianname && x.UserId == data.UserId) ? phyFlag : x.selected }))
          this.manageFieldsData(type);
          this.manageEnablingFields();
          break;
        case 'Insurance':
          if (data.selected) {
            this.selectedInsurance.push(data);
          } else {
            const isAllSelected = this.selectedInsurance.some(x =>
              this.isPayerFamily ? x.PayerFamily === 'ALL' : x.PayerName === 'ALL'
            );

            if (isAllSelected) {
              this.selectedInsurance = this.insuranceDataList.filter(x =>
                this.isPayerFamily ? x.PayerFamily !== data.PayerFamily : x.PayerName !== data.PayerName
              );
            } else {
              const index = this.selectedInsurance.findIndex(value =>
                this.isPayerFamily ? value.PayerFamily === data.PayerFamily : value.PayerName === data.PayerName
              );

              if (index !== -1) {
                this.selectedInsurance.splice(index, 1);
              }
            }
          }
          // Update insuranceDataList based on selectedInsurance
          this.insuranceDataList = this.insuranceDataList.map(ele => ({
            ...ele,
            selected: this.selectedInsurance.some(x =>
              this.isPayerFamily ? x.PayerFamily === ele.PayerFamily : x.PayerName === ele.PayerName
            ) || ele.selected
          }));

          this.allSelectedInsurance = this.insuranceDataList.every(x => x.selected);

          if (this.allSelectedInsurance) {
            this.selectedInsurance = [{ PayerName: 'ALL', PayerFamily: 'ALL', selected: true }];
          }

          this.selectedInsurance = this.getUniqueObjects(this.selectedInsurance, this.isPayerFamily ? 'PayerFamily' : 'PayerName');
          this.formControl["frmInsurance"].patchValue(this.selectedInsurance);
          this.formControl["frmInsurance"].updateValueAndValidity();
          break;
        case 'InsurancePlanType':
          if (data.selected) {
            this.selectedInsurancePlanType.push(data);
          } else {
            if (this.selectedInsurancePlanType.some(x => x.AttributeName.toString().toLowerCase() === 'all')) {
              this.selectedInsurancePlanType = this.insurancePlanTypeDataList.filter(ele => ele.AttributeName != data.AttributeName);
            } else {
              const i = this.selectedInsurancePlanType.findIndex(
                (value) =>
                  value.AttributeName === data.AttributeName
              );
              this.selectedInsurancePlanType.splice(i, 1);
            }
          }
          this.insurancePlanTypeDataList = this.insurancePlanTypeDataList.map(ele => ({ ...ele, selected: (this.selectedInsurancePlanType.some(x => x.AttributeName == ele.AttributeName)) ? true : ele.selected }))
          this.allSelectedInsurancePlanType = this.insurancePlanTypeDataList.every(x => x.selected == true) ? true : false;

          this.selectedInsurancePlanType = this.allSelectedInsurancePlanType ? [{ AttributeName: 'ALL', selected: true }] : this.selectedInsurancePlanType;

          this.formControl["frmInsurancePlanType"].patchValue(this.selectedInsurancePlanType);
          this.formControl["frmInsurancePlanType"].updateValueAndValidity();
          break;
        case 'BillTo':
          if (data.selected) {
            this.selectedBillTo.push(data);
          } else {
            if (this.selectedBillTo.some(x => x.AttributeName.toString().toLowerCase() === 'all')) {
              this.selectedBillTo = this.billToList.filter(x => x.AttributeName != data.AttributeName);
            } else {
              const i = this.selectedBillTo.findIndex(
                (value) =>
                  value.AttributeName === data.AttributeName
              );
              this.selectedBillTo.splice(i, 1);
            }
          }
          this.billToList = this.billToList.map(ele => ({ ...ele, selected: (this.selectedBillTo.some(x => x.AttributeName == ele.AttributeName)) ? true : ele.selected }))
          this.allSelectedBillTo = this.billToList.every(x => x.selected == true) ? true : false;

          this.selectedBillTo = this.allSelectedBillTo ? [{ AttributeName: 'ALL', selected: true }] : this.selectedBillTo;

          this.formControl["frmBillto"].patchValue(this.selectedBillTo);
          this.formControl["frmBillto"].updateValueAndValidity();
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  toggleCasetype(name: { CaseType: String, Item: String, Category: String, AccountId: number, selected: Boolean }) {
    const setFlag = !name.selected;
    const caseTypeToRemove = name.CaseType;

    if (setFlag) {
      this.selectedCasetypes.push(name);
    } else {
      this.selectedCasetypes = this.selectedCasetypes.filter(x => x.CaseType !== caseTypeToRemove);
    }
    this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);

    this.filteredCasetypeList = this.filteredCasetypeList.map(ele => ({
      ...ele,
      Children: ele.Children.map(subElement => ({
        ...subElement,
        selected: subElement.CaseType === caseTypeToRemove ? setFlag : subElement.selected
      }))
    }));

    this.filteredCasetypeList = this.filteredCasetypeList.map(ele => ({
      ...ele,
      selected: ele.Children.every(x => x.selected)
    })).filter(x => (x.Children.length > 0));
    this.setSelectAllCasetype();
  }

  setSelectAllCasetype() {
    this.allSelectedCasetype = (this.filteredCasetypeList.length > 0) ? (this.filteredCasetypeList.every(element => element.Children.every(subElement => subElement.selected) ? true : false)) : false;
    if (this.allSelectedCasetype) {
      this.toggleAllSelection('Casetype');
    }
    else {
      if (!this.filteredCasetypeList.length) {
        this.selectedCasetypes = [];
      }
      this.filteredCasetypeList.forEach(ele => {
        ele.Children.forEach(subEle => {
          if (subEle.selected) {
            this.selectedCasetypes.push(subEle);
          }
        });
      });
      if (this.selectedCasetypes.length > 0) {
        this.selectedCasetypes = this.getUniqueObjects(this.selectedCasetypes, "CaseType")
          .filter((ele: any) => ele.CaseType.toString().toLowerCase() != 'all');
      }
      this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);
      this.AARFormGrp.controls.frmCasetypes.updateValueAndValidity();
    }
  }

  getUniqueObjects(inputArray: Array<{ parentName: string, Children: Array<object> }>, searchKey: string, isNested?: Boolean) {
    if (isNested) {
      inputArray.forEach(va => {
        va.Children = this.getUniqueValues(va.Children, searchKey);
      });
    } else {
      inputArray = this.getUniqueValues(inputArray, searchKey);
    }
    return inputArray;
  }

  checkPartialSelection(item) {
    if (item.Children.every(va => va.selected) || item.Children.every(va => !va.selected)) {
      return false;
    }
    return item.Children.some(va => va.selected);
  }

  expandDocumentTypes(group?: any) {
    if (group) {
      this.isExpandCategory[group.Item] = !this.isExpandCategory[group.Item];
    } else {
      this.isExpandCategory = [];
    }
  }

  displayFn() {
    return [];
  }

  async getOrgAttributes(attributeType: string) {
    let returnArray = [];
    const uniqueArray = new Set();
    let orgid = this.OrganizationId?.toString();
    let queryVariable = { orgid: orgid, attributeType: attributeType };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationAttributes");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult, this.VitalHttpServices.deploymentKey).toPromise().then(data => {
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          data.data.submenuData.forEach((value) => {
            if (value && value.AttributeName) {
              const trimmedArray = value.AttributeName.toString().trim();
              uniqueArray.add(trimmedArray);
              returnArray = Array.from(uniqueArray);
            }
          });
        }
      }
    }, error => {
      console.error(error);
    });

    if (!returnArray || returnArray?.length == 0) {
      let queryVariable1 = { orgid: "-1", attributeType: attributeType };
      let queryResult1 = this.commonService.GetCardRequest(queryVariable1, query);
      await this.VitalHttpServices.GetData(queryResult1, this.VitalHttpServices.deploymentKey).toPromise().then(res => {
        if (!res.errors) {
          res.data.submenuData.forEach((value) => {
            if (value && value.toString().toLowerCase().trim() != 'select' && value.toString().toLowerCase().trim() != '-- select --') {
              const trimmedArray = value.AttributeName.toString().trim();
              uniqueArray.add(trimmedArray);
              returnArray = Array.from(uniqueArray);
            }
          });
        }
      }, error => {
        console.error(error);
      });
    }
    return returnArray;
  }

  triggerEvent(frmControlName) {
    const control = this.AARFormGrp.get(frmControlName);
    control.updateValueAndValidity();
    this.expandDocumentTypes();
  }

  async getLookupValues(isCreate?: boolean) {
    let obj = {
      // orgId: this.templateData.secondarykeys.OrganizationId.toString()
      orgId: this.OrganizationId
    }
    this.billToList = await this.getOrgAttributes('BillTo');
    // this.servicesList = await this.getOrgAttributes('Services');
    this.billToList = this.billToList.map(ele => ({ AttributeName: ele, selected: false }))
      .filter(x => x.AttributeName != '-- Select --')

    if (!isCreate) {
      await this.getLookupValueForCasetypeAndInsurance();
      await this.getAARLookupValueForReadingLocandAssignees();
    }

    await this.VitalHttpServices.getAARLookupValueForLabandOF(obj, sessionStorage.getItem('deploymentKey').toUpperCase()).toPromise().then(res => {
      if (res.status == 'Success') {
        this.labLocationList = (res.content.LabLocations) ? res.content.LabLocations.map(ele => ({ ...ele, selected: false })) : [];
        this.OFLocationList = (res.content.OfAccountsList) ? res.content.OfAccountsList.map(ele => ({ ...ele, selected: false })) : [];
        this.OFPhysiciansList = (res.content.PhysiciansList) ? res.content.PhysiciansList.map(ele => ({ ...ele, selected: false })) : [];
        this.masterCopyOFPhysiciansList = (res.content.PhysiciansList) ? res.content.PhysiciansList.map(ele => ({ ...ele, selected: false })) : [];
        this.masterCopyOFLocationList = (res.content.OfAccountsList) ? res.content.OfAccountsList.map(ele => ({ ...ele, selected: false })) : [];
        if (this.labLocationList.length == 1 && this.createClicked) {
          this.allSelectedLabs = true;
          this.toggleAllSelection('Lab');
        }
      } else {
        console.error(res.statusmessage)
      }
    }, error => {
      console.error(error);
    })
  }

  async getLookupValueForCasetypeAndInsurance() {
    let obj = { orgId: this.OrganizationId, isPathologist: this.isPathologistTab }
    await this.VitalHttpServices.getAARLookupValueForCasetypeandInsurance(obj, sessionStorage.getItem('deploymentKey').toString().toUpperCase()).toPromise().then(result => {
      if (result.status == 'Success') {
        this.insuranceDataList = result.content.InsuranceDataList ? result.content.InsuranceDataList.map(ele => ({ ...ele, selected: false })) : [];
        this.insurancePlanTypeDataList = result.content.InsurancePlanTypeList ? this.getUniqueValues(result.content.InsurancePlanTypeList.map(ele => ({ ...ele, selected: false })), 'AttributeName') : [];
        this.caseTypesList = result.content.CaseTypesList ? (result.content.CaseTypesList.map(ele => {
          return { ...ele, selected: false, Children: ele.Children.map((subElement) => ({ ...subElement, selected: false })) }
        })) : []
      } else {
        console.error(result.statusmessage);
      }
    }, error => {
      console.error(error);
    })
  }

  //gets unique array of object based on searchKey
  getUniqueValues(elements, searchKey) {
    return elements.filter((value, index, self) => self.map(x => x[searchKey]).indexOf(value[searchKey]) == index);
  }

  async getAARLookupValueForReadingLocandAssignees() {
    let obj = { orgId: this.OrganizationId, isPathologist: this.isPathologistTab, isExternalOrg: this.isExternalOrgTab }
    await this.VitalHttpServices.getAARLookupValueForReadingLocandAssignees(obj, sessionStorage.getItem('deploymentKey').toString().toUpperCase()).toPromise().then(result => {
      if (result.status == 'Success') {
        this.readingLocationList = (this.createClicked && result.content.ReadingLocationList?.length == 1) ? result.content.ReadingLocationList.map(ele => ({ ...ele, selected: true })) : result.content.ReadingLocationList?.map(ele => ({ ...ele, selected: false }));
        if (!this.isExternalOrgTab) {
          this.assigneeList = this.isPathologistTab ? result.content.AssigneeList?.filter(ele => ele.Parent == 'Pathologist')
            : result.content.AssigneeList?.filter(ele => ele.Parent != 'Pathologist');
          this.assigneeList = this.assigneeList?.map(x => ({
            ...x, selected: false,
            UserDetailsList: x.UserDetailsList.map(y => ({ ...y, selected: false }))
          }));
        } else {
          this.assigneeList = []
        }

        if (this.createClicked && this.readingLocationList?.length == 1) {
          this.readingLocationList = this.readingLocationList.map(ele => ({ ...ele, selected: true }));
          this.isPrimaryAccountEPG = (this.readingLocationList[0].IsEPGAccount) ? true : false;

          this.AARFormGrp.patchValue({
            frmPrimaryReadingLocation: this.readingLocationList[0].AccountId.toString(),
          });
          (this.onReadingLocChange(this.readingLocationList[0].AccountId, 1, true, null));
        }

        if (this.isPathologistTab && this.assigneeList?.length > 0) {
          this.assigneeList[0]['UserDetailsList'].push({
            "FormattedDisplayName": "Available to all Pathologist",
            "UserId": "-100",
            "RoleName": "Pathologist",
            "AccountId": "-1"
          })
        }
      } else {
        console.error(result.statusmessage)
      }
    }, error => {
      console.error(error);
    })
  }

  setExpiryDate() {
    let currentDate = new Date();
    this.minToDate.setDate(currentDate.getDate() + 1);
  }

  async createRuleFn() {
    try {
      this.ngxService.start();
      this.stopLoaderDX = false;
      this.resetFormData();

      // toggle click
      this.isStatusChecked = true;
      this.isAutoSendChecked = false;

      this.ruleStatus
      this.autoSendoutStatus

      this.createClicked = true;
      this.editClicked = false;

      this.frmStatus.patchValue('Active');
      this.AutoSendOutGrp.patchValue({
        frmAutoSendOut: true
      })
      this.formControl["frmOFLocations"].disable()
      this.formControl["frmOFPhysicians"].disable()
      this.formControl["frmCasetypes"].disable()
      this.AARFormGrp.controls.frmPrimaryAssignee.disable();
      this.AARFormGrp.controls.frmSecondaryAssignee.disable();
      this.AARFormGrp.controls.frmTertiaryAssignee.disable();
      this.AARFormGrp.controls.frmSecondaryReadingLocation.disable();
      this.AARFormGrp.controls.frmTertiaryReadingLocation.disable();
      await this.getLookupValueForCasetypeAndInsurance();
      await this.getLookupValues(true);
      let str = (this.isPayerFamily ? 'PayerFamily' : 'PayerName')
      this.enableInsurance = this.getUniqueValues(this.insuranceDataList, str);
      this.stopLoaderDX = true;
      this.ngxService.stop();
      await this.getAARLookupValueForReadingLocandAssignees();
      this.newDate = new Date(this.todayDate);
      this.newDate.setFullYear(this.newDate.getFullYear() + 5);
      this.AARFormGrp.patchValue({
        frmEffectiveDate: this.todayDate,
        frmExpiryDate: this.newDate
      })
    } catch (error) {
      this.stopLoaderDX = true
      this.ngxService.stop();
      console.error(error);
    }
  }

  async editRuleFn(rule: any) {
    try {
      this.activityEntity.entityId = rule.rowData.RuleId;
      this.activityService.setActivitySession(this.activityEntity);
      this.ngxService.start();
      this.stopLoaderDX = false;
      this.editClicked = true;
      await this.getLookupValues();
      let str = (this.isPayerFamily ? 'PayerFamily' : 'PayerName')
      this.enableInsurance = this.getUniqueValues(this.insuranceDataList, str);
      this.selectedRow = await this.getRuleDetails(rule.rowData.RuleId)

      this.frmStatus.patchValue(this.selectedRow.isActive == true ? 'Active' : 'Inactive');

      this.ruleStatus = this.selectedRow.isActive == true ? true : false;
      this.autoSendoutStatus = this.selectedRow.isConfiguredAutoSentOut == true ? true : false;
      this.previousAutoSendvalue = this.autoSendoutStatus

      this.isStatusChecked = this.selectedRow.isActive == true ? true : false;
      this.isAutoSendChecked = this.selectedRow.isConfiguredAutoSentOut == true ? true : false;
      this.statusBtnName = this.selectedRow.isActive == true ? 'Deactivate' : 'Activate'
      let billToSet = this.selectedRow.billTo.toString().split(',').map((ele) => ({ AttributeName: ele }))
      this.isPayerFamily = this.selectedRow.payerType == "PayerFamily" ? true : false;
      let myOfLocSet = this.selectedRow.orderingFacilityAccountid.toString().split(',');
      let myOfPhysicianSet = this.selectedRow.OrderingPhysician.toString().split(',');
      let myLabLocSet = this.selectedRow.laboratoryAccountid.toString().split(',');
      let myCasetypeSet = this.selectedRow.casetype.toString().split(',');
      let myPayerNameOrFamiliySet = this.selectedRow.payerNameOrFamily.toString().split(',');
      let myplanTypeSet = this.selectedRow.planType.toString().split(',');

      this.labLocationList = this.labLocationList.map((x) => ({ ...x, selected: myLabLocSet.some(a => a?.toString() !== '-1') ? ((myLabLocSet.some(v => v && v.toString() === x.LabAccountId.toString())) ? true : false) : true }));

      this.insurancePlanTypeDataList = this.insurancePlanTypeDataList.map((x) => ({ ...x, selected: myplanTypeSet.some(z => z?.toString() == '-1') ? true : ((myplanTypeSet.some(z => z && z == x.AttributeName)) ? true : false) }));
      this.billToList = this.billToList.map(x => ({ ...x, selected: billToSet.some(r => r.AttributeName?.toString() == '-1') ? true : ((billToSet.some(r => r.AttributeName && r.AttributeName == x.AttributeName)) ? true : false) }))

      this.insuranceDataList = this.insuranceDataList.map((x) => ({
        ...x,
        selected: (myPayerNameOrFamiliySet.some(d => d?.toString() !== '-1') ?
          ((myPayerNameOrFamiliySet.some(d => (d && (d == (this.isPayerFamily ? x.PayerFamily : x.PayerName))))) ? true : false)
          : true)
      }));

      if (this.labLocationList && this.labLocationList.length > 0) {
        if (this.labLocationList.every(value => value.selected === true)) {
          this.allSelectedLabs = true;
          this.toggleAllSelection('Lab');
        } else {
          this.selectedLabLocations = this.getUniqueValues(this.labLocationList.filter((x) => x.selected == true), 'LabAccountId');
        }
      }

      if (this.OFLocationList && this.OFLocationList.length > 0) {
        this.OFLocationList = this.OFLocationList.map((x) => ({ ...x, selected: myOfLocSet.some(d => d.toString() == '-1') ? true : ((myOfLocSet.some(d => d && d.toString() === x.OfAccountId.toString())) ? true : false) }));

        if (this.OFLocationList.every(value => value.selected === true)) {
          this.allSelectedOFs = true;
          this.toggleAllSelection('OF');
        } else {
          this.selectedOFLocations = this.getUniqueValues(this.OFLocationList.filter((x) => x.selected == true), 'OfAccountId');
        }
      }

      // this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map((x) => ({ ...x, selected: myOfLocSet.some(d => d.toString() == '-1') ? true : ((myOfLocSet.some(d => d && d.toString() === x.OfAccountId.toString()) && myOfPhysicianSet.some(z => z && z.toString() === x.UserId.toString())) ? true : false) }));
      this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map((x) => ({ ...x, selected: myOfPhysicianSet.some(d => d.toString() == '-1') ? true : (myOfPhysicianSet.some(z => z && z.toString() === x.UserId.toString()) ? true : false) }));
      // this.OFPhysiciansList = this.OFPhysiciansList.map((x) => ({ ...x, selected: myOfLocSet.some(d => d.toString() == '-1') ? true : ((myOfLocSet.some(d => d && d.toString() === x.OfAccountId.toString()) && myOfPhysicianSet.some(z => z && z.toString() === x.UserId.toString())) ? true : false) }));
      if (this.OFPhysiciansList && this.OFPhysiciansList.length > 0) {
        this.OFPhysiciansList = this.OFPhysiciansList.map((x) => ({ ...x, selected: myOfPhysicianSet.some(d => d.toString() == '-1') ? true : ((myOfPhysicianSet.some(z => z && z.toString() === x.UserId.toString())) ? true : false) }));

        if (this.OFPhysiciansList.every(value => value.selected === true)) {
          this.allSelectedOFPhysicians = true;
          this.toggleAllSelection('Physician');
        } else {
          this.selectedOFPhysicians = this.getUniqueValues(this.OFPhysiciansList.filter((x) => x.selected == true), 'UserId');
        }
      }

      if (this.insuranceDataList && this.insuranceDataList.length > 0) {
        if (this.insuranceDataList.every(value => value.selected === true)) {
          this.allSelectedInsurance = true;
          this.toggleAllSelection('Insurance');
        } else {
          let str = (this.isPayerFamily ? 'PayerFamily' : 'PayerName')
          this.selectedInsurance = this.getUniqueValues(this.insuranceDataList.filter((x) => x.selected == true), str);
        }
      }

      if (this.billToList && this.billToList.length > 0) {
        if (this.billToList.every(value => value.selected === true)) {
          this.allSelectedBillTo = true;
          this.toggleAllSelection('BillTo');
        } else {
          this.selectedBillTo = this.billToList.filter((x) => x.selected == true);
        }
      }

      if (this.insurancePlanTypeDataList && this.insurancePlanTypeDataList.length > 0) {
        if (this.insurancePlanTypeDataList.every(value => value.selected === true)) {
          this.allSelectedInsurancePlanType = true;
          this.toggleAllSelection('InsurancePlanType');
        } else {
          this.selectedInsurancePlanType = this.getUniqueValues(this.insurancePlanTypeDataList.filter((x) => x.selected == true), 'AttributeName');
        }
      }

      const getDateObj: any = (dateString: string) => {
        const dateObject: Date = new Date(dateString);
        return dateObject;
      }

      if (this.selectedRow.services) {
        let services = this.selectedRow.services.toString().split(',');
        for (let i = 0; i < this.servicesList.length; i++) {
          if (services.some(x => x == this.servicesList[i] || x.toString() == '-1')) {
            this.onServiceChange(true, i);
          }
        }
      }

      const primaryAssignee = this.selectedRow.primaryUserid ? (this.selectedRow.primaryUserid == -100 ? (this.pathObject) : this.getAssigneeObj(this.selectedRow.primaryUserid, this.selectedRow.primaryAccountid)) : null;
      const secondaryAssignee = this.selectedRow.secondaryUserid ? (this.selectedRow.secondaryUserid == -100 ? (this.pathObject) : this.getAssigneeObj(this.selectedRow.secondaryUserid, this.selectedRow.secondaryAccountid)) : null;
      const tertiaryAssignee = this.selectedRow.tertiaryUserid ? (this.selectedRow.tertiaryUserid == -100 ? (this.pathObject) : this.getAssigneeObj(this.selectedRow.tertiaryUserid, this.selectedRow.tertiaryAccountid)) : null;
      this.ngxService.stop();
      this.isPrimaryAccountEPG = this.readingLocationList?.some(x => x.AccountId == this.selectedRow.primaryAccountid && x.IsEPGAccount) ? true : false;
      this.isSecondaryAccountEPG = this.readingLocationList?.some(x => x.AccountId == this.selectedRow.secondaryAccountid && x.IsEPGAccount) ? true : false;
      this.isTertiaryAccountEPG = this.readingLocationList?.some(x => x.AccountId == this.selectedRow.tertiaryAccountid && x.IsEPGAccount) ? true : false;

      this.AARFormGrp.patchValue({
        frmLabLocations: this.selectedLabLocations,
        frmOFLocations: this.selectedOFLocations,
        frmOFPhysicians: this.selectedOFPhysicians,
        frmInsurance: this.selectedInsurance,
        frmInsurancePlanType: this.selectedInsurancePlanType,
        frmServices: this.selectedServices,
        frmBillto: this.selectedBillTo,
        frmEffectiveDate: getDateObj(this.selectedRow.effectiveDate),
        frmExpiryDate: getDateObj(this.selectedRow.expiryDate),
        frmPrimaryReadingLocation: this.selectedRow.primaryAccountid ? this.selectedRow.primaryAccountid.toString() : null,
        frmPrimaryAssignee: primaryAssignee,
        frmSecondaryReadingLocation: this.selectedRow.secondaryAccountid ? this.selectedRow.secondaryAccountid.toString() : null,
        frmSecondaryAssignee: secondaryAssignee,
        frmTertiaryReadingLocation: this.selectedRow.tertiaryAccountid ? this.selectedRow.tertiaryAccountid.toString() : null,
        frmTertiaryAssignee: tertiaryAssignee,
        frmIsPayerFamily: this.isPayerFamily ? 'PayerFamily' : 'Payer',
        frmNotes: this.selectedRow.notes
      });

      const arrayData = cloneDeep(this.caseTypesList);

      if (this.selectedLabLocations && this.selectedLabLocations.some(v => v.LabAccountId === -1)) {
        this.filteredCasetypeList = this.getUniqueObjects(arrayData, "CaseType", true).filter(x => (x.Children.length > 0));
      } else {
        this.filteredCasetypeList = arrayData.map(ele => ({
          ...ele,
          Children: ele.Children.filter((subElement) => subElement.AccountId && this.selectedLabLocations.some(a => a.LabAccountId?.toString() === subElement.AccountId?.toString()))
        }));
        this.filteredCasetypeList = this.getUniqueObjects(this.filteredCasetypeList, "CaseType", true).filter(x => (x.Children.length > 0));
      }

      this.onReadingLocChange(this.selectedRow.primaryAccountid, 1, true, null);
      this.onReadingLocChange(this.selectedRow.secondaryAccountid, 2, true, null);
      this.onReadingLocChange(this.selectedRow.tertiaryAccountid, 3, true, null);

      this.filteredCasetypeList.forEach(x => {
        x.Children.forEach(subEle => {
          if (subEle && (myCasetypeSet.some(v => (v?.toString().toLowerCase().trim() == subEle.CaseType?.toString().toLowerCase().trim()) || (v.toString().toLowerCase() == 'all')))
          ) {
            subEle.selected = true;
            this.selectedCasetypes.push(subEle);
          }
        })
        x.selected = (x.Children.every(v => v.selected)) ? true : false
      })
      this.setSelectAllCasetype();
      this.initialValue = cloneDeep(this.AARFormGrp.value);
      this.manageEnablingFields();
      this.validateAssigneeSection();
      this.stopLoaderDX = true;
      this.ngxService.stop();
    } catch (error) {
      this.stopLoaderDX = true;
      this.ngxService.stop();
      console.error(error);
    }
  }

  getAssigneeObj(UserId: Number, accountId: Number) {
    for (const element of this.assigneeList) {
      const found = element.UserDetailsList.find(subElement =>
        UserId.toString() === subElement.UserId.toString() &&
        accountId.toString() === subElement.AccountId.toString()
      );
      if (found) {
        return found;
      }
    }
    return null;
  }

  async getRuleDetails(ruleId: number) {
    try {
      let paramObj = { ruleId: ruleId }
      let result = await this.VitalHttpServices.GetRuleExportList(paramObj, this.dbName.toString()).toPromise();
      if (result.status == "Success") {
        return result.content[0];
      } else {
        console.error(result.statusmessage)
        this._snackBar.open("Something went wrong.", "Close");
        return []
      }
    } catch (error) {
      this._snackBar.open("Something went wrong.", "Close");
      console.error(error);
    }
  }

  sortFunc(a, b) {
    return 1;
  }

  filterAssigneeList(assigneeArray, values) {
    let array = [];
    const hasUserIdMinus100 = assigneeArray.some(element =>
      element.UserDetailsList.some(subElement =>
        subElement && [-100, '-100'].includes(subElement.UserId)
      )
    );

    !hasUserIdMinus100 ? array.push(this.pathObject) : null;
    let arr = assigneeArray.map((element) => {
      return { ...element, UserDetailsList: [...array, ...(element.UserDetailsList.filter((subElement) => (subElement && subElement.FormattedDisplayName) && (!values.includes(subElement.UserId))))] }
    })
    return (arr && arr.length > 0) ? arr.filter(x => (x.UserDetailsList.length > 0)) : [];
  }

  validateAssigneeSection() {
    const primaryReadingLocation = this.AARFormGrp.controls.frmPrimaryReadingLocation.value;
    const secondaryReadingLocation = this.AARFormGrp.controls.frmSecondaryReadingLocation.value;
    const tertiaryReadingLocation = this.AARFormGrp.controls.frmTertiaryReadingLocation.value;
    const primaryAssignee = this.AARFormGrp.controls.frmPrimaryAssignee.value;
    const secondaryAssignee = this.AARFormGrp.controls.frmSecondaryAssignee.value;

    if (primaryReadingLocation) {
      this.AARFormGrp.controls.frmPrimaryAssignee.enable();
    } else {
      this.AARFormGrp.controls.frmPrimaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmSecondaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmTertiaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmSecondaryReadingLocation.patchValue('');
      this.AARFormGrp.controls.frmTertiaryReadingLocation.patchValue('');

      this.AARFormGrp.controls.frmPrimaryAssignee.disable();
      this.AARFormGrp.controls.frmSecondaryAssignee.disable();
      this.AARFormGrp.controls.frmTertiaryAssignee.disable();
      this.AARFormGrp.controls.frmSecondaryReadingLocation.disable();
      this.AARFormGrp.controls.frmTertiaryReadingLocation.disable();
    }

    if (secondaryReadingLocation) {
      this.AARFormGrp.controls.frmSecondaryAssignee.enable();
    } else {
      this.AARFormGrp.controls.frmSecondaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmTertiaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmTertiaryReadingLocation.patchValue('');

      this.AARFormGrp.controls.frmSecondaryAssignee.disable();
      this.AARFormGrp.controls.frmTertiaryAssignee.disable();
      this.AARFormGrp.controls.frmTertiaryReadingLocation.disable();
    }

    if (tertiaryReadingLocation) {
      this.AARFormGrp.controls.frmTertiaryAssignee.enable();
    } else {
      this.AARFormGrp.controls.frmTertiaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmTertiaryAssignee.disable();
    }

    if (primaryAssignee && Object.keys(primaryAssignee).length > 0) {
      this.AARFormGrp.controls.frmSecondaryReadingLocation.enable();
    } else {
      this.AARFormGrp.controls.frmSecondaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmTertiaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmSecondaryReadingLocation.patchValue('');
      this.AARFormGrp.controls.frmTertiaryReadingLocation.patchValue('');

      this.AARFormGrp.controls.frmSecondaryAssignee.disable();
      this.AARFormGrp.controls.frmTertiaryAssignee.disable();
      this.AARFormGrp.controls.frmSecondaryReadingLocation.disable();
      this.AARFormGrp.controls.frmTertiaryReadingLocation.disable();
    }

    if (secondaryAssignee && Object.keys(secondaryAssignee).length > 0) {
      this.AARFormGrp.controls.frmTertiaryReadingLocation.enable();
    } else {
      this.AARFormGrp.controls.frmTertiaryAssignee.patchValue(new Assignee());
      this.AARFormGrp.controls.frmTertiaryReadingLocation.patchValue('');

      this.AARFormGrp.controls.frmTertiaryAssignee.disable();
      this.AARFormGrp.controls.frmTertiaryReadingLocation.disable();
    }
  }

  onAssigneeSelection(index) {
    let selectedValues = [this.AARFormGrp.controls.frmPrimaryAssignee.value?.UserId, this.AARFormGrp.controls.frmSecondaryAssignee.value?.UserId, this.AARFormGrp.controls.frmTertiaryAssignee.value?.UserId];
    let sendToPrimary = [selectedValues[1], selectedValues[2]];
    let sendToSecondary = [selectedValues[0], selectedValues[2]];
    let sendToTertiary = [selectedValues[0], selectedValues[1]];

    // let primaryAssigneesList = this.assigneeList.filter(va => va.UserDetailsList.filter(val => val.AccountId == this.AARFormGrp.controls.frmPrimaryReadingLocation.value));
    // let secondaryAssigneesList = this.assigneeList.filter(va => va.UserDetailsList.filter(val => val.AccountId == this.AARFormGrp.controls.frmSecondaryReadingLocation.value));
    // let tertiaryAssigneesList = this.assigneeList.filter(va => va.UserDetailsList.filter(val => val.AccountId == this.AARFormGrp.controls.frmTertiaryReadingLocation.value));
    (this.onReadingLocChange(this.AARFormGrp.controls.frmPrimaryReadingLocation.value, 1, true, null));
    (this.onReadingLocChange(this.AARFormGrp.controls.frmSecondaryReadingLocation.value, 2, true, null));
    (this.onReadingLocChange(this.AARFormGrp.controls.frmTertiaryReadingLocation.value, 3, true, null));

    switch (index) {
      case 0:
        this.secondaryAssigneeList = this.filterAssigneeList(this.secondaryAssigneeList, sendToSecondary);
        this.tertiaryAssigneeList = this.filterAssigneeList(this.tertiaryAssigneeList, sendToTertiary);
        this.validateAssigneeSection();
        break;
      case 1:
        this.primaryAssigneeList = this.filterAssigneeList(this.primaryAssigneeList, sendToPrimary);
        this.tertiaryAssigneeList = this.filterAssigneeList(this.tertiaryAssigneeList, sendToTertiary);
        this.validateAssigneeSection();
        break;
      case 2:
        this.secondaryAssigneeList = this.filterAssigneeList(this.secondaryAssigneeList, sendToSecondary);
        this.primaryAssigneeList = this.filterAssigneeList(this.primaryAssigneeList, sendToPrimary);
        this.validateAssigneeSection();
        break;
    }
  }

  toggleParent(group: any) {
    group.selected = !group.selected;
    let casetypes: any[] = this.AARFormGrp.controls.frmCasetypes.value || [];

    casetypes = (casetypes.length > 0 && (casetypes.some(x => 'CaseType' in x))) ? casetypes : [];

    const updateFilteredCasetypes = (ele, isSelected) => ({
      ...ele,
      selected: group.Item === ele.Item ? isSelected : ele.selected,
      Children: ele.Children.map(subElement => ({
        ...subElement,
        selected: group.Children.some(x => x.CaseType === subElement.CaseType && x.Category === group.Item) ? isSelected : subElement.selected
      }))
    });

    if (group.selected) {
      casetypes.push(...group.Children);
      this.filteredCasetypeList = this.filteredCasetypeList.map(ele => updateFilteredCasetypes(ele, true)).filter(x => (x.Children.length > 0));
    } else {
      casetypes = casetypes.filter(x => !group.Children.some(d => d.CaseType === x.CaseType));
      this.filteredCasetypeList = this.filteredCasetypeList.map(ele => updateFilteredCasetypes(ele, false)).filter(x => (x.Children.length > 0));
    }

    this.AARFormGrp.controls.frmCasetypes.patchValue(casetypes);

    if (!group.selected) {
      this.AARFormGrp.controls.frmCasetypes.patchValue(this.AARFormGrp.controls.frmCasetypes.value.filter((x: any) => !group.Children.some(y => y.CaseType === x.CaseType)));
    }

    this.selectedCasetypes = this.AARFormGrp.controls.frmCasetypes.value;
    this.setSelectAllCasetype();
  }

  toggleAllSelection(dtype) {
    switch (dtype) {
      case 'Lab':
        if (this.allSelectedLabs) {
          this.selectedLabLocations = [];
          this.selectedLabLocations.push({ LabName: "ALL", LabAccountId: -1, selected: true });
          this.labLocationList = this.labLocationList.map(ele => ({ ...ele, selected: true }));
          this.filteredCasetypeList = cloneDeep(this.caseTypesList).filter(x => (x.Children.length > 0));
        } else {
          this.OFLocationList = [];
          this.OFPhysiciansList = [];
          this.filteredCasetypeList = [];
          this.selectedLabLocations = [];
          this.selectedOFLocations = [];
          this.selectedOFPhysicians = [];
          this.labLocationList = this.labLocationList.map(ele => ({ ...ele, selected: false }));
          this.caseTypesList = this.caseTypesList.map(ele => {
            return { ...ele, selected: false, Children: ele.Children.map((subElement) => ({ ...subElement, selected: false })) }
          })
          this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(x => ({ ...x, selected: false }))
          this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: false }))

          this.formControl["frmOFLocations"].patchValue(this.selectedOFLocations);
          this.formControl["frmOFPhysicians"].patchValue(this.selectedOFPhysicians);
        }
        this.formControl["frmLabLocations"].patchValue(this.selectedLabLocations);
        this.formControl["frmLabLocations"].updateValueAndValidity();
        this.manageFieldsData(dtype);
        const arrayData = cloneDeep(this.caseTypesList);
        this.filteredCasetypeList = this.getUniqueObjects(arrayData, "CaseType", true).filter(x => (x.Children.length > 0));
        this.setSelectAllCasetype();
        this.manageEnablingFields();
        break;
      case 'OF':
        if (this.allSelectedOFs) {
          this.selectedOFLocations = [];
          this.selectedOFLocations.push({ OrderingFacilityName: "ALL", OfAccountId: -1, selected: true });
          this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(x => ({ ...x, selected: true }))
        } else {
          this.selectedOFLocations = [];
          this.selectedOFPhysicians = [];
          this.OFPhysiciansList = [];
          this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(x => ({ ...x, selected: false }))
          this.OFLocationList = this.OFLocationList.map(ele => ({ ...ele, selected: false }))
          this.OFPhysiciansList = this.OFPhysiciansList.map(ele => ({ ...ele, selected: false }));
          this.formControl["frmOFPhysicians"].patchValue(this.selectedOFPhysicians);
        }
        this.AARFormGrp.controls.frmOFLocations.patchValue(this.selectedOFLocations);
        this.AARFormGrp.controls.frmOFLocations.updateValueAndValidity();
        this.manageFieldsData(dtype);

        this.manageEnablingFields();
        break;
      case 'Physician':
        this.selectedOFPhysicians = [];
        if (this.allSelectedOFPhysicians) {
          this.selectedOFPhysicians.push({ OrderingPhysicianname: "ALL", UserId: -1, selected: true });
          this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: true }))
        } else {
          this.OFPhysiciansList = this.OFPhysiciansList.map(ele => ({ ...ele, selected: false }))
          this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: false }))
        }
        this.formControl["frmOFPhysicians"].patchValue(this.selectedOFPhysicians);
        this.formControl["frmOFPhysicians"].updateValueAndValidity();
        this.manageFieldsData(dtype);

        this.manageEnablingFields();
        break;
      case 'Insurance':
        if (this.allSelectedInsurance) {
          this.insuranceDataList = this.insuranceDataList.map(ele => ({ ...ele, selected: true }))
          this.selectedInsurance = [];
          this.selectedInsurance.push({ PayerName: "ALL", PayerFamily: "ALL", PayerId: -1, selected: true });
          this.AARFormGrp.controls.frmInsurance.patchValue(this.selectedInsurance);
          this.AARFormGrp.controls.frmInsurance.updateValueAndValidity();
          this.formControl["frmInsurance"].setErrors(null)
        } else {
          this.selectedInsurance = [];
          this.insuranceDataList = this.insuranceDataList.map(ele => ({ ...ele, selected: false }))
          this.formControl["frmInsurance"].patchValue(this.selectedInsurance);
          this.formControl["frmInsurance"].updateValueAndValidity();
          this.formControl["frmInsurance"].setErrors(null)
        }
        break;
      case 'InsurancePlanType':
        this.AARFormGrp.controls.frmInsurancePlanType.patchValue([]);
        this.AARFormGrp.controls.frmInsurancePlanType.updateValueAndValidity();
        if (this.allSelectedInsurancePlanType) {
          this.selectedInsurancePlanType = [];
          this.selectedInsurancePlanType.push({ AttributeName: "ALL", selected: true });
          this.insurancePlanTypeDataList = this.insurancePlanTypeDataList.map(ele => ({ ...ele, selected: true }))
          this.AARFormGrp.controls.frmInsurancePlanType.patchValue(this.selectedInsurancePlanType);
          this.AARFormGrp.controls.frmInsurancePlanType.updateValueAndValidity();
          this.formControl["frmInsurancePlanType"].setErrors(null)
        } else {
          this.selectedInsurancePlanType = [];
          this.insurancePlanTypeDataList = this.insurancePlanTypeDataList.map(ele => ({ ...ele, selected: false }))
          this.formControl["frmInsurancePlanType"].patchValue(this.selectedInsurancePlanType);
          this.formControl["frmInsurancePlanType"].updateValueAndValidity();
          this.formControl["frmInsurancePlanType"].setErrors(null)
        }
        break;
      case 'BillTo':
        this.AARFormGrp.controls.frmBillto.patchValue([]);
        this.AARFormGrp.controls.frmBillto.updateValueAndValidity();
        if (this.allSelectedBillTo) {
          this.selectedBillTo = [];
          this.selectedBillTo.push({ AttributeName: "ALL", selected: true });
          this.billToList = this.billToList.map(ele => ({ ...ele, selected: true }));
          this.AARFormGrp.controls.frmBillto.patchValue(this.selectedBillTo);
          this.AARFormGrp.controls.frmBillto.updateValueAndValidity();
          this.formControl["frmBillto"].setErrors(null)
        } else {
          this.selectedBillTo = [];
          this.billToList = this.billToList.map(ele => ({ ...ele, selected: false }))
          this.formControl["frmBillto"].patchValue(this.selectedBillTo);
          this.formControl["frmBillto"].updateValueAndValidity();
          this.formControl["frmBillto"].setErrors(null)
        }
        break;
      case 'Casetype':
        if (this.allSelectedCasetype) {
          this.selectedCasetypes = [];
          this.selectedCasetypes.push({ CaseType: "ALL", Item: "ALL", Category: "ALL", AccountId: -1, selected: true });
          this.filteredCasetypeList = this.filteredCasetypeList.map(ele => {
            return { ...ele, selected: true, Children: ele.Children.map((subElement) => ({ ...subElement, selected: true })) }
          }).filter(x => (x.Children.length > 0))
          this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);
          this.AARFormGrp.controls.frmCasetypes.updateValueAndValidity();
        } else {
          this.selectedCasetypes = [];
          this.filteredCasetypeList = this.filteredCasetypeList.map(ele => {
            return { ...ele, selected: false, Children: ele.Children.map((subElement) => ({ ...subElement, selected: false })) }
          }).filter(x => (x.Children.length > 0))
          this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);
          this.AARFormGrp.controls.frmCasetypes.updateValueAndValidity();
        }
        break;
      case 'Services':
        let isSelected = (this.allSelectedServices) ? true : false;
        this.selectedServices = [];
        if (this.allSelectedServices) {
          this.selectedServices = [...this.servicesList];
        } else {
          this.selectedServices = [];
        }
        for (let i = 0; i < this.servicesList.length; i++) {
          this.selected[i] = isSelected;
        }
        this.AARFormGrp.controls.frmServices.patchValue(this.selectedServices);
        break;
    }
  }

  onServiceChange(value, i) {
    this.selected[i] = value;
    if (this.selected[i]) {
      this.selectedServices.push(this.servicesList[i]);
    } else {
      this.selectedServices = this.selectedServices.filter(x => x != this.servicesList[i])
    }
    this.AARFormGrp.controls.frmServices.patchValue(this.selectedServices);
    this.allSelectedServices = (this.selectedServices.length == this.servicesList.length) ? true : false;
  }

  removeChip(obj, type) {
    switch (type) {
      case 'Lab':
        this.labLocationList = this.labLocationList.map(ele => ({ ...ele, selected: obj.LabAccountId == ele.LabAccountId ? false : ele.selected }))
        this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(ele => ({ ...ele, selected: (obj.LabAccountId.toString() == '-1') ? false : ((obj.LabAccountId == ele.LabAccountId) ? false : ele.selected) }));

        const i = this.selectedLabLocations.findIndex(
          (value) =>
            value.LabName === obj.LabName && value.LabAccountId === obj.LabAccountId
        );
        this.selectedLabLocations.splice(i, 1);
        const userids = this.selectedOFPhysicians.filter(x => !(this.selectedOFLocations.some(y => y.OfAccountId == x.OfAccountId)))
        this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: userids.some(d => d.UserId == x.UserId) ? false : x.selected }))
        this.manageFieldsData(type);
        const arrayData = cloneDeep(this.caseTypesList);

        if (this.selectedLabLocations.length > 0) {
          if (this.selectedLabLocations.some(v => v.LabAccountId === -1)) {
            this.filteredCasetypeList = this.getUniqueObjects(arrayData, "CaseType", true).filter(x => (x.Children.length > 0));
          } else {
            this.filteredCasetypeList = arrayData.map(ele => ({
              ...ele,
              Children: ele.Children.filter(subElement =>
                this.selectedLabLocations.some(a => a.LabAccountId.toString() === subElement.AccountId.toString())
              )
            }));
            this.filteredCasetypeList = this.getUniqueObjects(this.filteredCasetypeList, "CaseType", true).filter(x => (x.Children.length > 0));
          }
        }
        let casetypeList = [];
        arrayData.forEach(x => {
          x.Children.forEach(y => {
            if (this.selectedLabLocations.some(v => (v.LabAccountId == y.AccountId))) {
              casetypeList.push(y);
            }
          })
        })
        this.selectedCasetypes = this.selectedCasetypes.filter(x => casetypeList.some(v => (v.CaseType == x.CaseType)))
        this.filteredCasetypeList.forEach(x => {
          x.Children.forEach(subEle => {
            if (subEle && (this.selectedCasetypes.some(v => (v.CaseType.toString() == subEle.CaseType.toString())))
            ) {
              subEle.selected = true;
            } else {
              subEle.selected = false;
            }
          })
          x.selected = (x.Children.every(v => v.selected)) ? true : false
        })
        this.setSelectAllCasetype();
        this.manageEnablingFields()
        break;
      case 'OF':
        const l = this.selectedOFLocations.findIndex(
          (value) =>
            value.OrderingFacilityName === obj.OrderingFacilityName && value.OfAccountId === obj.OfAccountId
        );
        this.selectedOFLocations.splice(l, 1);
        this.masterCopyOFLocationList = this.masterCopyOFLocationList.map(ele => ({ ...ele, selected: (obj.OfAccountId.toString() == '-1') ? false : ((obj.OfAccountId == ele.OfAccountId) ? false : ele.selected) }))
        this.OFLocationList = this.OFLocationList.map(ele => ({ ...ele, selected: (obj.OfAccountId.toString() == '-1') ? false : ((obj.OfAccountId == ele.OfAccountId) ? false : ele.selected) }))

        const useridsList = this.selectedOFPhysicians.filter(x => !(this.selectedOFLocations.some(y => y.OfAccountId == x.OfAccountId)))
        this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(x => ({ ...x, selected: useridsList.some(d => d.UserId == x.UserId) ? false : x.selected }))
        this.selectedOFPhysicians = this.masterCopyOFPhysiciansList.filter(x => x.selected == true)

        this.manageFieldsData(type);
        this.manageEnablingFields();
        break;
      case 'Physician':
        const phyIndex = this.selectedOFPhysicians.indexOf(obj);
        this.OFPhysiciansList = this.OFPhysiciansList.map(ele => ({ ...ele, selected: (obj.UserId.toString() == '-1') ? false : (obj.UserId == ele.UserId && obj.OfAccountId == ele.OfAccountId) ? false : ele.selected }))
        if (phyIndex >= 0) {
          this.selectedOFPhysicians.splice(phyIndex, 1);
        }
        this.masterCopyOFPhysiciansList = this.masterCopyOFPhysiciansList.map(ele => ({ ...ele, selected: (obj.UserId.toString() == '-1') ? false : ((obj.UserId == ele.UserId) ? false : ele.selected) }))

        this.manageFieldsData(type);
        this.manageEnablingFields();
        break;
      case 'Insurance':
        const insuranceIndex = this.selectedInsurance.indexOf(obj);
        this.insuranceDataList = this.insuranceDataList.map(ele => ({ ...ele, selected: (obj.PayerId == ele.PayerId) ? false : ele.selected }))
        if (insuranceIndex >= 0) {
          this.selectedInsurance.splice(insuranceIndex, 1);
        }
        if (obj.PayerId == -1) {
          this.insuranceDataList = this.insuranceDataList.map(ele => ({ ...ele, selected: false }))
        }
        this.formControl["frmInsurance"].patchValue(this.selectedInsurance);
        this.allSelectedInsurance = false;
        this.formControl["frmInsurance"].setErrors(null)
        break;
      case 'PlanType':
        const planIndex = this.selectedInsurancePlanType.indexOf(obj);
        this.insurancePlanTypeDataList = this.insurancePlanTypeDataList.map(ele => ({ ...ele, selected: (obj.AttributeName == ele.AttributeName) ? false : ele.selected }))
        if (planIndex >= 0) {
          this.selectedInsurancePlanType.splice(planIndex, 1);
        }
        if (obj.AttributeName == 'ALL') {
          this.insurancePlanTypeDataList = this.insurancePlanTypeDataList.map(ele => ({ ...ele, selected: false }))
        }
        this.formControl["frmInsurancePlanType"].patchValue(this.selectedInsurancePlanType);
        this.allSelectedInsurancePlanType = false;
        this.formControl["frmInsurancePlanType"].setErrors(null)
        break;
      case 'BillTo':
        const billToIndex = this.selectedBillTo.indexOf(obj);
        this.billToList = this.billToList.map(ele => ({ ...ele, selected: (obj.AttributeName == ele.AttributeName) ? false : ele.selected }))
        if (billToIndex >= 0) {
          this.selectedBillTo.splice(billToIndex, 1);
        }
        if (obj.AttributeName == 'ALL') {
          this.billToList = this.billToList.map(ele => ({ ...ele, selected: false }))
        }
        this.formControl["frmBillto"].patchValue(this.selectedBillTo);
        this.allSelectedBillTo = false;
        this.formControl["frmBillto"].setErrors(null);
        break;
      case 'Casetype':
        this.filteredCasetypeList.forEach(x => {
          x.Children.forEach(subEle => {
            if (subEle && (obj.CaseType.toString().toLowerCase().trim() == subEle.CaseType?.toString().toLowerCase().trim()) || (obj.CaseType.toString().toLowerCase() == 'all')
            ) {
              subEle.selected = false;
            }
          })
          x.selected = (x.Children.every(v => v.selected)) ? true : false
        })
        const itemIndex = this.selectedCasetypes.findIndex(va => va.Category == obj.Category && va.CaseType == obj.CaseType);
        if (itemIndex >= 0) {
          this.selectedCasetypes.splice(itemIndex, 1);
        }
        this.AARFormGrp.controls.frmCasetypes.patchValue(this.selectedCasetypes);
        this.setSelectAllCasetype();
        break;
    }
  }

  formHasChange() {
    return JSON.stringify(this.initialValue) != JSON.stringify(this.AARFormGrp.value)
  }

  showPop() {
    let isValid = this.validateFields();
    if (!isValid) {
      this._snackBar.open("Please provide mandatory values", "Close");
      return;
    }
    if (this.validateExpiryDate(this.AARFormGrp.get('frmExpiryDate'))) {
      this._snackBar.open("Expiry date should not be less than or equal to Effective date and Current date", "Close");
      return;
    }
    this.activateOrDeactivate = true;
    this.confirmationMessage = this.formHasChange() ? "Auto Assignment Rule will be " + (this.frmStatus.value == 'Active' ? "Deactivated" : "Activated") + ". Changes made to this rule will be updated. Do you wish to continue?"
      : "Do you want to " + (this.frmStatus.value == 'Active' ? "Deactivate" : "Activate") + " this Auto Assignment Rule?"
  }

  async saveData() {
    this.ngxService.start();
    await this.VitalHttpServices.createOrEditAutoAssgnmentRule(this.newObj, this.VitalHttpServices?.deploymentKey?.toString()).toPromise().then(
      result => {
        this.ngxService.stop();
        if (result.status == 'Success') {
          if (result.content?.RuleStatus?.toString().includes('exist')) {
            this._snackBar.open("Auto Assignment Rule already exists !", "Ignored");
            return
          } else {
            this.activityEntity.entityId = this.newObj.ruleID;
            this.activityService.setActivitySession(this.activityEntity);
            let str = this.newObj.ruleID ? 'updated' : 'created';
            this._snackBar.open("Auto Assignment Rule " + str + " successfully", "Close");
            str = this.newObj.ruleID ? 'Edit' : 'Create';
            this.newObj.ruleID = result?.content?.RuleId
            this.auditDetails(str);
            this.back();
          }
          this.activateOrDeactivate = false;
        } else {
          this._snackBar.open(result?.content || "Something went wrong", "Close");
          return
        }
      }, error => {
        this.ngxService.stop();
        console.error(error);
      })
  }

  validateFields() {
    let arrayControls
    let selectedDataArray
    let isValid = true;
    if (this.isFacitlityContext) {
      arrayControls = [
        "frmLabLocations",
        "frmOFLocations",
        "frmOFPhysicians",
        "frmCasetypes",
      ]
      selectedDataArray = [
        this.selectedLabLocations,
        this.selectedOFLocations,
        this.selectedOFPhysicians,
        this.selectedCasetypes
      ]
    } else {
      arrayControls = [
        "frmLabLocations",
        "frmOFLocations",
        "frmOFPhysicians",
        "frmServices",
        "frmCasetypes",
      ]
      selectedDataArray = [
        this.selectedLabLocations,
        this.selectedOFLocations,
        this.selectedOFPhysicians,
        this.selectedServices,
        this.selectedCasetypes
      ]
    }

    for (let i = 0; i < selectedDataArray.length; i++) {
      if (!(Array.isArray(selectedDataArray[i])) || (selectedDataArray[i].length == 0)) {
        this.AARFormGrp.controls[arrayControls[i]].patchValue(null);
        this.AARFormGrp.controls[arrayControls[i]].setValidators([Validators.required]);
        this.AARFormGrp.controls[arrayControls[i]].updateValueAndValidity();
        this.AARFormGrp.controls[arrayControls[i]].setErrors({ "incorrect": true })
        isValid = false;
        break;
      }
    }

    arrayControls = [
      "frmEffectiveDate",
      "frmExpiryDate"
    ]

    for (let i = 0; i < arrayControls.length; i++) {
      if (!(this.AARFormGrp.controls[arrayControls[i]].value)) {
        this.AARFormGrp.controls[arrayControls[i]].patchValue(null);
        this.AARFormGrp.controls[arrayControls[i]].setValidators([Validators.required]);
        this.AARFormGrp.controls[arrayControls[i]].updateValueAndValidity();
        this.AARFormGrp.controls[arrayControls[i]].setErrors({ "incorrect": true })
        isValid = false;
        break;
      }
    }

    if (this.isExternalOrgTab) {
      if (!this.AARFormGrp.controls.frmPrimaryReadingLocation.value) {
        this.AARFormGrp.controls["frmPrimaryReadingLocation"].setValidators([Validators.required]);
        this.AARFormGrp.controls["frmPrimaryReadingLocation"].updateValueAndValidity();
        isValid = false;
      }
    } else {

      if (!this.AARFormGrp.controls.frmPrimaryReadingLocation.value
        || (!this.AARFormGrp.controls.frmPrimaryAssignee.value
          || (this.AARFormGrp.controls.frmPrimaryAssignee.value && Object.keys(this.AARFormGrp.controls.frmPrimaryAssignee.value).length == 0))) {
        this.AARFormGrp.controls["frmPrimaryAssignee"].setValidators([Validators.required]);
        this.AARFormGrp.controls["frmPrimaryAssignee"].updateValueAndValidity();
        this.AARFormGrp.controls["frmPrimaryAssignee"].setErrors({ "incorrect": true });
        isValid = false;
      }

      if (!this.AARFormGrp.controls.frmPrimaryReadingLocation.value) {
        this.AARFormGrp.controls["frmPrimaryReadingLocation"].setValidators([Validators.required]);
        this.AARFormGrp.controls["frmPrimaryReadingLocation"].updateValueAndValidity();
        isValid = false;
      }

      if (this.AARFormGrp.controls.frmSecondaryReadingLocation.value
        && (!this.AARFormGrp.controls.frmSecondaryAssignee.value
          || (this.AARFormGrp.controls.frmSecondaryAssignee.value && Object.keys(this.AARFormGrp.controls.frmSecondaryAssignee.value).length == 0))) {
        this.AARFormGrp.controls["frmSecondaryAssignee"].setValidators([Validators.required]);
        this.AARFormGrp.controls["frmSecondaryAssignee"].updateValueAndValidity();
        this.AARFormGrp.controls["frmSecondaryAssignee"].setErrors({ "incorrect": true });
        isValid = false
      }

      if (this.AARFormGrp.controls.frmTertiaryReadingLocation.value
        && (!this.AARFormGrp.controls.frmTertiaryAssignee.value
          || (this.AARFormGrp.controls.frmTertiaryAssignee.value && Object.keys(this.AARFormGrp.controls.frmTertiaryAssignee.value).length == 0))) {
        this.AARFormGrp.controls["frmTertiaryAssignee"].setValidators([Validators.required]);
        this.AARFormGrp.controls["frmTertiaryAssignee"].updateValueAndValidity();
        this.AARFormGrp.controls["frmTertiaryAssignee"].setErrors({ "incorrect": true });
        isValid = false
      }
    }
    return isValid;
  }

  resetFormData() {
    this.frmStatus.patchValue('Active');
    this.isAutoSendChecked = false;
    this.AARFormGrp.reset();
    this.AARFormGrp.patchValue({
      frmLabLocations: <any>[],
      frmOFLocations: <any>[],
      frmOFPhysicians: <any>[],
      frmCasetypes: <any>[],
      frmInsurance: [],
      frmInsurancePlanType: [],
      frmServices: null,
      frmBillto: [],
      frmEffectiveDate: <any>this.todayDate,
      frmExpiryDate: <any>this.newDate,
      frmPrimaryReadingLocation: "",
      frmPrimaryAssignee: new Assignee(),
      frmSecondaryReadingLocation: "",
      frmSecondaryAssignee: new Assignee(),
      frmTertiaryReadingLocation: "",
      frmTertiaryAssignee: new Assignee(),
      frmIsPayerFamily: "Payer",
      frmNotes: ""
    })
    this.selected = {};
    this.labLocationList = [];
    this.OFLocationList = [];
    this.OFPhysiciansList = [];
    this.masterCopyOFPhysiciansList = [];
    this.masterCopyOFLocationList = [];
    this.insuranceDataList = [];
    this.insurancePlanTypeDataList = [];
    this.filteredCasetypeList = [];
    this.caseTypesList = [];
    this.assigneeList = [];
    this.billToList = [];
    this.selectedRow = null;
    this.selectedLabLocations = []
    this.selectedOFLocations = []
    this.selectedOFPhysicians = []
    this.selectedInsurance = []
    this.selectedInsurancePlanType = []
    this.selectedCasetypes = []
    this.selectedServices = []
    this.selectedBillTo = []
    this.allSelectedBillTo = false;
    this.allSelectedServices = false;
    this.allSelectedCasetype = false;
    this.allSelectedInsurancePlanType = false;
    this.allSelectedInsurance = false;
    this.allSelectedOFPhysicians = false;
    this.allSelectedOFs = false;
    this.allSelectedLabs = false;
  }

  resetSelectedValues(inputArray: any) {
    if (inputArray.some(x => 'Children' in x)) {
      return inputArray.map(ele => ({
        ...ele, selected: false,
        Children: ele.Children.map(subElement => ({
          ...subElement,
          selected: false
        }))
      }));
    }
    if (inputArray.some(x => 'UserDetailsList' in x)) {
      return inputArray.map(ele => ({
        ...ele, selected: false,
        UserDetailsList: ele.UserDetailsList.map(subElement => ({
          ...subElement,
          selected: false
        }))
      }));
    }
    return inputArray.map(x => x.selected = false)
  }

  filterCasetypes(caseTypeSearch: string): any[] {
    let casetypes = cloneDeep(this.filteredCasetypeList);
    if (caseTypeSearch) {
      casetypes.forEach(ele => {
        ele.Children = ele.Children.filter((subElement) => subElement.CaseType.toString().toLowerCase() == caseTypeSearch.toLowerCase())
      });
      //.filter(x => (x.Children.length > 0))

      // casetypes = this.filteredCasetypeList.map(ele => ({
      //   ...ele,
      //   Children: ele.Children.map(subElement => ({...subElement,
      //     CaseType: subElement.CaseType.toLowerCase().indexOf(caseTypeSearch.toLowerCase()) >= 0
      //     // subElement.CaseType.includes(caseTypeSearch)          
      //   }))
      // }));
      return casetypes
    } else {
      return this.filteredCasetypeList;
    }
  }

  getActivityTrackerObject(inputObj: any) {
    const laboratoryAccount = inputObj.laboratoryAccountid ? (this.getFieldArray(inputObj.laboratoryAccountid.toString(), 'LabAccountId', 'LabName', this.labLocationList)) : [];
    const orderingFacilityAccount = inputObj.orderingFacilityAccountid ? (this.getFieldArray(inputObj.orderingFacilityAccountid.toString(), 'OfAccountId', 'OrderingFacilityName', this.masterCopyOFLocationList)) : [];
    const OFPhysician = inputObj.OrderingPhysician ? (this.getFieldArray(inputObj.OrderingPhysician.toString(), 'UserId', 'OrderingPhysicianname', this.masterCopyOFPhysiciansList)) : [];
    const primaryAccount = inputObj.primaryAccountid ? (this.getFieldArray(inputObj.primaryAccountid.toString(), 'AccountId', 'LocationName', this.readingLocationList)) : [];
    const secondaryAccount = inputObj.secondaryAccountid ? (this.getFieldArray(inputObj.secondaryAccountid.toString(), 'AccountId', 'LocationName', this.readingLocationList)) : [];
    const tertiaryAccount = inputObj.tertiaryAccountid ? (this.getFieldArray(inputObj.tertiaryAccountid.toString(), 'AccountId', 'LocationName', this.readingLocationList)) : [];
    const primaryUser = inputObj.primaryUserid ? (inputObj.primaryUserid == -100 ? (this.pathObject) : this.getAssigneeObj(inputObj.primaryUserid, inputObj.primaryAccountid)) : null;
    const secondaryUser = inputObj.secondaryUserid ? (inputObj.secondaryUserid == -100 ? (this.pathObject) : this.getAssigneeObj(inputObj.secondaryUserid, inputObj.secondaryAccountid)) : null;
    const tertiaryUser = inputObj.tertiaryUserid ? (inputObj.tertiaryUserid == -100 ? (this.pathObject) : this.getAssigneeObj(inputObj.tertiaryUserid, inputObj.tertiaryAccountid)) : null;

    return {
      "orderingfacilityaccount": orderingFacilityAccount ? (orderingFacilityAccount.some(x => x == 'ALL') ? 'ALL' : orderingFacilityAccount.toString()) : '',
      "laboratoryaccount": laboratoryAccount ? (laboratoryAccount.some(x => x == 'ALL') ? 'ALL' : laboratoryAccount.toString()) : '',
      "services": inputObj.services == '-1' ? 'ALL' : inputObj.services,
      "casetype": inputObj.casetype,
      "primaryaccount": primaryAccount.toString(),
      "primaryuser": primaryUser ? primaryUser.FormattedDisplayName : '',
      "secondaryaccount": secondaryAccount ? secondaryAccount.toString() : '',
      "tertiaryaccount": tertiaryAccount ? tertiaryAccount.toString() : '',
      "secondaryuser": secondaryUser ? secondaryUser.FormattedDisplayName : '',
      "tertiaryuser": tertiaryUser ? tertiaryUser.FormattedDisplayName : '',
      "isactive": inputObj.isActive ? 'Active' : 'Inactive',
      "effectivedate": inputObj.effectiveDate ? this.convertDate(inputObj.effectiveDate) : '',
      "expirydate": inputObj.expiryDate ? this.convertDate(inputObj.expiryDate) : '',
      "orderingphysician": OFPhysician ? (OFPhysician.some(x => x == 'ALL') ? 'ALL' : OFPhysician.toString()) : '',
      "iscytotechnologistrule": inputObj.isCytotechnologistRule ? 'Yes' : 'No',
      "isconfiguredautosentout": inputObj.isConfiguredAutoSentOut ? 'Active' : 'InActive',
      "category": inputObj.category,
      "payernameorfamily": inputObj.payerNameOrFamily?.toString() == '-1' ? 'ALL' : inputObj.payerNameOrFamily,
      "payertype": inputObj.payerType,
      "plantype": inputObj.planType?.toString() == '-1' ? 'ALL' : inputObj.planType,
      "billto": inputObj.billTo == '-1' ? 'ALL' : inputObj.billTo,
      "notes": inputObj.notes,
      "ruleid": inputObj?.ruleID ?? null
    }
  }

  auditDetails(action: string) {
    if (this.isExternalOrgTab) {
      this.auditableColumns.primaryAccount = 'Send Out Reading Location';
      delete this.auditableColumns?.primaryuser
      delete this.auditableColumns?.primaryaccount
      delete this.auditableColumns?.secondaryuser
      delete this.auditableColumns?.secondaryaccount
      delete this.auditableColumns?.tertiaryuser
      delete this.auditableColumns?.tertiaryaccount
    } else {
      delete this.newObj?.isConfiguredAutoSentOut
      delete this.auditableColumns?.isconfiguredautosentout
    }
    let modifiedData = structuredClone(this.selectedRow)
    let oldObj = (action == 'Edit' || action == 'Delete') ? this.getActivityTrackerObject(modifiedData) : this.getActivityTrackerObject(this.newObj);
    let newObj = this.newObj ? this.getActivityTrackerObject(this.newObj) : {};
    this.commonService.auditDetails('ruleid', 'primaryuser', [oldObj], [newObj], action, this.templateData, this.auditableColumns, `${action === 'Edit' ? 'Modified ' : action === 'Create' ? 'Created ' : 'Deleted '} Auto Assignment Rules For ${this.selectedTab}`);
  }

  getFieldArray(inputString: string, filterByKey: string, returnByKey: string, inputArray: any) {
    let returnArray = [];
    let temp = inputString.split(',');
    if (temp.length > 0) {
      inputArray.forEach(x => {
        if (temp.some(y => y == '-1')) {
          returnArray.push('ALL');
        }
        else if (temp.some(y => y == x[filterByKey].toString()) && !(returnArray.some(z => z == x[returnByKey].toString()))) {
          returnArray.push(x[returnByKey].toString());
        }
      })
    }
    return returnArray;
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      if (res.content) {
        this.auditableColumns = JSON.parse(res.content.JsonData);
      }
    }, error => {
      console.error(error);
    })
  }

  datePickerOnClick() {
    var event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.dispatchEvent(event);
  }

  //#region converting date to mm/dd/yyyy
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
  }
  //#endregion

  async getPathologistLicensureInfo() {
    try {
      let obj = {
        "primaryUserId": this.newObj.primaryUserid || null,
        "secondaryUserId": this.newObj.secondaryUserid || null,
        "tertiaryUserId": this.newObj.tertiaryUserid || null,
        "ofAccountId": this.newObj.orderingFacilityAccountid || null,
        "type": "labassignrule",
        "primaryAssociationType": "",
        "secondaryAssociationType": "",
        "tertiaryAssociationType": ""
      }
      await this.VitalHttpServices.getPathologistLicensureInfo(obj, this.dbName).toPromise().then(res => {
        if (res.status == 'Success') {
          this.primaryPathLicenseInfo = res.content.filter(x => x.TypeId == 1).map(y => ({ ...y, isExpired: y.ExpiryDate ? this.isExpiredToday(new Date(y.ExpiryDate), true) : false })).sort((a, b) => (a.OfState - b.OfState))
          this.secondaryPathLicenseInfo = res.content.filter(x => x.TypeId == 2).map(y => ({ ...y, isExpired: y.ExpiryDate ? this.isExpiredToday(new Date(y.ExpiryDate), true) : false })).sort((a, b) => (a.OfState - b.OfState))
          this.tertiaryPathLicenseInfo = res.content.filter(x => x.TypeId == 3).map(y => ({ ...y, isExpired: y.ExpiryDate ? this.isExpiredToday(new Date(y.ExpiryDate), true) : false })).sort((a, b) => (a.OfState - b.OfState))
        } else {
          console.error(res.statusmessage);
        }
      })
    } catch (Exception) {
      console.error(Exception);
    }
  }

  openWidgetEditorDialog() {
    if (this.widgetEditorDialogRef) { return; }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.role = "dialog";
    dialogConfig.width = "calc(100vw - 100px)";
    // dialogConfig.minHeight = "500px";
    dialogConfig.maxHeight = "calc(100vh - 30px)";
    // dialogConfig.maxHeight = "86vh";
    dialogConfig.position = { top: "7vh" };
    dialogConfig.autoFocus = false;

    this.widgetEditorDialogRef = this.dialog.open(
      this.widgetEditorModal,
      dialogConfig
    );

    this.widgetEditorDialogRef.afterOpened().subscribe(result => {
      this.isModalOpen = true;
    });

    this.widgetEditorDialogRef.afterClosed()
      .pipe(finalize(() => (this.widgetEditorDialogRef = undefined)))
      .subscribe(result => {
        this.isModalOpen = false;
        this.activateOrDeactivate ? this.close() : null;
      });
  }

  async validateRule(changeStatus?: Boolean) {
    if (!changeStatus) {
      let isValid = this.validateFields();
      if (!isValid) {
        this._snackBar.open("Please provide mandatory values", "Close");
        return;
      }
      if (this.validateExpiryDate(this.AARFormGrp.get('frmExpiryDate'))) {
        this._snackBar.open("Expiry date should not be less than or equal to Effective date and Current date", "Close");
        return;
      }
    }

    const noChanges = !this.formHasChange() && !changeStatus;
    const isExternalOrg = this.isExternalOrgTab;
    const isPathologist = this.isPathologistTab;

    if (!isPathologist && !isExternalOrg) {
      if (noChanges) {
        this._snackBar.open('There are no changes made to this Auto Assignment Rule !', 'Close');
        return;
      }
    } else if (isExternalOrg && noChanges && this.selectedRow.isConfiguredAutoSentOut === this.isAutoSendChecked) {
      this._snackBar.open('There are no changes made to this Auto Assignment Rule !', 'Close');
      return;
    }

    let labLocations = [];
    let services = [];
    let OFPhysicians = [];
    let billTo = [];
    let oFLocations = [];
    let planType = [];
    let payerOrPayerFamily = [];
    let casetypes = [];
    let oFOrgIds = [];

    this.selectedCasetypes?.map((x: any) => {
      casetypes.push(x.CaseType);
    })

    let valueData = this.selectedLabLocations || []
    valueData.forEach((ele: any) => { labLocations.push(ele.LabAccountId); });

    let physicianData = this.selectedOFPhysicians || [];
    physicianData.forEach((element: any) => {
      OFPhysicians.push(element.UserId);
    });

    if (this.selectedBillTo && this.selectedBillTo.some(x => 'AttributeName' in x)) {
      this.selectedBillTo.forEach((element: any) => {
        billTo.push(element.AttributeName.toString().trim());
      });
    }

    let ofData = this.selectedOFLocations || []
    if (ofData && ofData.some(x => 'OfAccountId' in x)) {
      ofData.forEach((element: any) => {
        oFLocations.push(element.OfAccountId);
        oFOrgIds.push(element.OrgId);
      });
    }

    if (this.selectedInsurancePlanType && this.selectedInsurancePlanType.some(x => 'AttributeName' in x)) {
      this.selectedInsurancePlanType.forEach((element: any) => {
        planType.push(element.AttributeName.toString().trim());
      });
    }

    if (this.selectedInsurance && this.selectedInsurance.some(x => 'PayerFamily' in x || 'PayerName' in x)) {
      this.selectedInsurance.forEach((element: any) => {
        if (this.isPayerFamily) {
          payerOrPayerFamily.push(element.PayerFamily);
        } else {
          payerOrPayerFamily.push(element.PayerName);
        }
      });
    }

    for (let i = 0; i < this.servicesList.length; i++) {
      if (this.selected[i] == true) {
        services.push(this.servicesList[i])
      }
    }

    this.changeStatus = changeStatus;

    let createdby = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : Number(sessionStorage.getItem("Userid"));
    let isActive;
    isActive = (this.frmStatus.value && this.frmStatus.value == 'Active') ? true : false

    if (this.selectedRow && this.selectedRow?.ruleID) {
      isActive = this.changeStatus ? !isActive : isActive
    } else {
      isActive = true
    }
    let inputObj = {
      "orderingFacilityAccountid": oFLocations?.toString(),
      "laboratoryAccountid": labLocations?.toString(),
      "services": this.isFacitlityContext ? 'Technical Only' : services?.length === this.servicesList.length ? '-1' : services?.toString(),
      "casetype": casetypes?.toString(),
      "primaryAccountid": this.AARFormGrp.controls.frmPrimaryReadingLocation.value ? Number(this.AARFormGrp.controls.frmPrimaryReadingLocation.value) : null,
      "primaryUserid": this.AARFormGrp.controls.frmPrimaryAssignee.value ? Number(this.AARFormGrp.controls.frmPrimaryAssignee.value.UserId) : null,
      "secondaryAccountid": this.AARFormGrp.controls.frmSecondaryReadingLocation.value ? Number(this.AARFormGrp.controls.frmSecondaryReadingLocation.value) : null,
      "tertiaryAccountid": this.AARFormGrp.controls.frmTertiaryReadingLocation.value ? Number(this.AARFormGrp.controls.frmTertiaryReadingLocation.value) : null,
      "secondaryUserid": this.AARFormGrp.controls.frmSecondaryAssignee.value ? Number(this.AARFormGrp.controls.frmSecondaryAssignee.value.UserId) : null,
      "tertiaryUserid": this.AARFormGrp.controls.frmTertiaryAssignee.value ? Number(this.AARFormGrp.controls.frmTertiaryAssignee.value.UserId) : null,
      "oFOrgID": this.sourceApp !== 'VitalDx' ? (this.isFacitlityContext ? this.OrganizationId : 0) : (this.isFacitlityContext ? this.labadminService?.clientOrgid : 0),
      "labOrgID": this.sourceApp !== 'VitalDx' ? this.OrganizationId : this.labadminService.organizationId,
      "isActive": isActive,
      "effectiveDate": this.AARFormGrp.controls.frmEffectiveDate.value ? (this.datePipe.transform(this.AARFormGrp.controls.frmEffectiveDate.value, 'MM/dd/yyyy HH:mm:ss')) : null,
      "expiryDate": this.AARFormGrp.controls.frmExpiryDate.value ? (this.datePipe.transform(this.AARFormGrp.controls.frmExpiryDate.value, 'MM/dd/yyyy HH:mm:ss')) : null,
      "ruleID": (this.selectedRow && this.selectedRow?.ruleID) ? Number(this.selectedRow.ruleID) : null,
      "OrderingPhysician": OFPhysicians.toString(),
      "organizationID": Number(this.OrganizationId),
      "isCytotechnologistRule": this.isFacitlityContext ? false : this.isPathologistTab ? false : true,
      "category": !this.isExternalOrgTab ? "AutoAssignment" : "AutoSendOut",
      "actionPerformedBy": createdby,
      "isConfiguredAutoSentOut": this.isAutoSendChecked,
      "payerNameOrFamily": payerOrPayerFamily.toString().toString().toLowerCase() == 'all' ? '-1' : payerOrPayerFamily.toString(),
      "payerType": this.isPayerFamily ? 'PayerFamily' : 'Payer',
      "planType": planType.toString().toLowerCase() == 'all' ? '-1' : planType.toString(),
      "billTo": this.allSelectedBillTo ? '-1' : billTo.toString(),
      "notes": this.AARFormGrp.controls.frmNotes.value
    }

    this.newObj = inputObj;
    if (this.validateValueLength()) {
      return
    }
    if (this.isPathologistTab && inputObj.isActive) {
      await this.getPathologistLicensureInfo();
      if ((this.primaryPathLicenseInfo?.length > 0) || (this.secondaryPathLicenseInfo?.length > 0) || (this.tertiaryPathLicenseInfo?.length > 0)) {
        this.openWidgetEditorDialog();
      } else {
        this.saveData();
      }
    } else {
      this.saveData();
    }
  }

  validateValueLength() {
    if (this.newObj.laboratoryAccountid?.toString().length > 1000) {
      this._snackBar.open("Kindly remove Lab Location(s) from the dropdown as the maximum length has been reached", "Close");
      return true
    }
    else if (this.newObj.orderingFacilityAccountid?.toString().length > 4000) {
      this._snackBar.open("Kindly remove some Ordering Facility(s) from the dropdown as the maximum length has been reached", "Close");
      return true
    }
    else if (this.newObj.OrderingPhysician?.toString().length > 4000) {
      this._snackBar.open("Kindly remove some Ordering Physician(s) from the dropdown as the maximum length has been reached", "Close");
      return true
    }
    else if (this.newObj.casetype?.toString().length > 4000) {
      this._snackBar.open("Kindly remove some Case Type(s) from the dropdown as the maximum length has been reached", "Close");
      return true
    }
    else if (this.newObj.billTo?.toString().length > 500) {
      this._snackBar.open("Kindly remove some Bill To(s) from the dropdown as the maximum length has been reached", "Close");
      return true
    } else {
      return false
    }
  }

  // toggle 

  toggleChangeAutoSend(event: any) {
    this.isAutoSendChecked = event;
  }

  checkOrgtype() {
    let orgid = this.templateData?.secondarykeys?.OrganizationId ? this.templateData.secondarykeys.OrganizationId : this.OrganizationId;
    this.dbName = sessionStorage.getItem('deploymentKey')?.toUpperCase()
    this.VitalHttpServices.validateTabIndexOrgType(orgid, this.dbName).subscribe(async result => {
      if (!result.errors) {
        if (result[0].status) {
          this.accountType = result[0].result;
          if (result[0].result !== "Laboratories" && result[0].result !== "Both") {
            this.tabList = ["Pathologist"];
            this.selectedTab = this.tabList[0];
          }
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }

}
