<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned" *ngIf="showLoader || componentLoader" [diameter]="50">
  </mat-spinner>
  <!-- <app-shimmer-multiline-loader [blocks]="5"  *ngIf="showLoader || componentLoader"></app-shimmer-multiline-loader> -->


  <div class=" col-sm-12 row" *ngIf="!(showLoader || componentLoader)" style="font-size: 12px;">
    <div class="col-sm-7 font-size-color">
      <div class="row mb-2">
        <span class=" col-sm-6 ">NPI </span>
        <span class=" col-sm-1">: </span>
        <strong  class=" col-sm-5 pl-0">{{userDetials?.['NPI']}}
          <span *ngIf="userDetials?.['viewNPIStatus']" [class]="userDetials?.['viewNPIStatus']?.toLowerCase()+'_label'">
            {{userDetials?.['viewNPIStatus'] | uppercase}}
          </span>
        </strong>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Login ID </span>
        <span class=" col-sm-1 ">: </span>
        <div class="col-sm-5 pl-0 d-flex">
          <strong appTooltipEllipsis >{{userDetials?.['DisplayLoginName'] || '-' }}
            </strong> 
            <img *ngIf="userDetials?.['DisplayLoginName']" class="ml-2 cursor-pointer copy-img cursor-pointer copy-img "
              src="/assets/images/copy.svg" matRipple [matTooltip]="copyStatus" #tooltip2="matTooltip"
              (click)="copyContent(userDetials?.['DisplayLoginName'],tooltip2)">

        </div>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Middle Name </span>
        <span class=" col-sm-1  ">: </span>
        <strong appTooltipEllipsis class=" col-sm-5 pl-0">{{userDetials?.['MiddleInitial'] || '-'}}</strong>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Gender </span>
        <span class=" col-sm-1 ">: </span>
        <strong class=" col-sm-5 pl-0">{{userDetials?.['Gender'] || '-'}}</strong>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Alias Name </span>
        <span class=" col-sm-1 ">: </span>
        <strong appTooltipEllipsis class=" col-sm-5 pl-0">{{userDetials?.['AliasName'] || '-'}}</strong>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Educational Qualification </span>
        <span class=" col-sm-1 ">: </span>
        <strong appTooltipEllipsis class=" col-sm-5 pl-0">{{userDetials?.['EducationQualification'] || '-' }}</strong>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6 ">User Code </span>
        <span class=" col-sm-1 ">: </span>
        <div class="col-sm-5 pl-0 d-flex">
          <strong appTooltipEllipsis >{{userDetials?.['UserCode'] || '-' }}
            </strong> 
            <img *ngIf="userDetials?.['UserCode']" class="ml-2 cursor-pointer copy-img cursor-pointer copy-img "
              src="/assets/images/copy.svg" matRipple [matTooltip]="copyStatus" #tooltip2="matTooltip"
              (click)="copyContent(userDetials?.['UserCode'],tooltip2)">

        </div>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Display Name in VitalDx Application </span>
        <span class=" col-sm-1 ">: </span>
        <strong appTooltipEllipsis class=" col-sm-5 pl-0">{{userDetials?.['UserDisplayFormat'] ? userDetials?.['FormattedDisplayName'] ||
          '-' : "-"}}</strong>
      </div>
      <div class="row mb-2">
        <span class=" col-sm-6  ">Display Name in Gross Description </span>
        <span class=" col-sm-1 ">: </span>
        <strong appTooltipEllipsis class=" col-sm-5 pl-0">{{ userDetials?.['GrosserDisplayFormat'] ?
          userDetials?.['GrossFormattedDisplayName'] || '-' : "-"}}</strong>
      </div>
      <div class="row mb-1">
        <span class=" col-sm-6  ">Name Printed on Reports</span>
        <span class=" col-sm-1 ">: </span>
        <strong appTooltipEllipsis class=" col-sm-5 pl-0">{{ userDetials?.['ReportDisplayFormat'] ?
          userDetials?.['ReportFormattedDisplayName'] || '-' : "-"}}</strong>
      </div>

      <div class="row mb-2" *ngIf="signatureEnabled" >
        <span class=" col-sm-6  ">Signature</span>
        <span class=" col-sm-1">: </span>
        <!-- Signature -->
        <div class="col-sm-5 p-0">
          <div class="row">
            <div *ngIf="sign!=''" class="col-sm-12">
              <img [src]="sign" alt="" width="100%" height="70">
            </div>
            <div class="col-sm-12" *ngIf="sign==''">
              <strong>-</strong>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-5">
      <div class="row">
        <div class="col-sm-2 px-0">Role(s) :</div>
        <div class="col-sm-10 pl-0 pr-0 font-size-color">
          <!-- <div [ngClass]="item.AllowPathAsMedicalDirector ?' role_highlight':'' " class="mb-2" *ngFor="let item of userRoles"> -->
          <div class="mb-2" *ngFor="let item of userRoles">
            <div class="d-flex">
              <span class="mr-2 cursor-pointer "
                [matTooltip]="item.Status ? staticMessages.ACTIVATE_USER : staticMessages.DEACTIVATE_USER">
                <img (click)="ActivateOrInactivateUsers(item.RoleDisplayName)" matRipple
                  [src]="item.Usrstatus.toLowerCase() ==='active'?ActiveImage:InactiveImage">
              </span>
              {{item.RoleDisplayName}}
              <div *ngIf="item.AllowPathAsMedicalDirector && !isManagePathologistPrivilegesForLabAdmin"
                class="ml-2 medical_director">
                Medical Director
              </div><span *ngIf="item.IsPrimary" class="primary_role ml-2"> <i>Primary</i> </span>

            </div>
            <!-- <div *ngIf="item.AllowPathAsMedicalDirector" class="ml-2"> -->

            <div *ngIf="item.Role == 'Technician' && item.IsGrosser && EnableGrossingRoles">
              <strong class="technician-Style"> Grosser </strong>
            </div>
            <div *ngIf="item.Role == 'Technician' && item.IsGrossingTranscriptionist && EnableGrossingRoles">
              <strong class="technician-Style"> Grosser Transcriptionist</strong>
            </div>

          </div>

          <!-- <div class="d-flex"><mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> Customer Service</div>
          <div class="d-flex"><mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> Cytotechnologist</div>
          <div class="d-flex"><mat-icon class=" mr-1 checkbox_checked ">check_box</mat-icon> Cytotechnologist Supervisor
          </div>
          <div class="d-flex"><mat-icon class="  mr-1 checkbox_checked ">check_box</mat-icon> Lab Administator</div>
          <div class="d-flex"><mat-icon class="  mr-1 checkbox_checked ">check_box</mat-icon> Lab Director</div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag">
  <span class="col-sm-12 nodata-wrapper">No Data Found</span>
</div> -->
</div>
