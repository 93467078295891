import { formatDate } from "@angular/common";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
}

export function changeZipValue(zip: string) {
  
    if (zip.length > 5 && !(zip[5]=='-')) return `${zip.slice(0, 5)}-${zip.slice(5)}`      
    else return zip
}

export function changeContactPhone(phone: string) {
    let ph=phone.replace(/[^0-9]/g, '')
    if(ph.length === 10){
        return `${ph.slice(0,3)}.${ph.slice(3,6)}.${ph.slice(6)}`
    }
    else if(phone.length >10){
        
        return `${ph.slice(0,3)}.${ph.slice(3,6)}.${ph.slice(6,10)}x${ph.slice(10)}`
    }
    else return phone
}
 export function changeFaxNumber(fax:string)
 {
    let fx=fax.replace(/[^0-9]/g, '')
    let loop=Math.ceil(fx.length/10);
    let rectifiedFax=[]
    for(let i=0;i<loop;i++)
    {   i=i*10
        if(fx[i+0]&&fx[i+9])
            rectifiedFax.push(`${fx.slice(i+0,i+3)}.${fx.slice(i+3,i+6)}.${fx.slice(i+6,i+10)}`)
        else
            rectifiedFax.push(fx.slice(i+0))
        i=i/10
    }
    return rectifiedFax.join(',')

 }
  
export function notValidatedMessage(msg: any): "Not Validated" | "Validated" {
    if(_normalizeValue(msg) === 'notvalidated') return 'Not Validated'
    return msg
  }

export function isAllowORAutoRegisterDomain(email: string, allowDomains: string, autoRegisterDomain: string){
    if(email){        
        const domain = email.split('@')[1] ?? ''
        return {
            autoRegisterDomain : autoRegisterDomain !== '' && autoRegisterDomain.split(';').includes(domain),
            allowDomains: !allowDomains || allowDomains.split(';').includes(domain)
        }
    }
    else return {
        autoRegisterDomain : false,
        allowDomains: true
    }
}

export function allowdomains(domains: {allowdomains: string, autoregisterdomains: string}): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const res = isAllowORAutoRegisterDomain(control.value ?? '', domains.allowdomains, domains.autoregisterdomains)
      return res.allowDomains ? null : { 'allowDomain': true };
    };
}
export function convertLocalDateTime(dateTime:string|null)
{
    if(typeof(dateTime)=='string')
        return dateTime.trim()==''?'':formatDate(new Date(dateTime), 'MM/dd/yyyy hh:mm a', 'en-US')
    return dateTime
}
export const toPascalCase = (text:string, trimSpace=false) => text.trim()!=''?text.split(' ')?.map((t) => t[0]?.toUpperCase() + t?.slice(1)?.toLowerCase())?.join(trimSpace?'':' '):''

export const ConvertStatus=(text)=>{
    if(typeof(text)=='string')
        return text.trim().toLowerCase()=='active'?'Active':'InActive'
    return Boolean(text)?'Active':'InActive';
}

export const toYesNo=(text:any)=>text?'Yes':'No'

export function menuUrl(type: string){
    switch (type?.toLowerCase()) {
      case 'labusers': return 'Lab Users List'
      case 'users': return 'Client Users List'
      case 'providers': return 'Providers List'
      case 'referring physicians': return 'Referring Physicians List'
      case 'clients': return 'Clients List'
      default: return ''
        break;
    }
    
}

export function getStateName(state:string|null)
{
    if(typeof(state)=='string')
    {
        return state.indexOf('(')>=0?state.substring(state.indexOf('(')+1,state.indexOf(')')):state
    }
        return state
}

export function enableView(contextType: string) {
    return ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg', 'text/plain', 'application/octet-stream'].includes(contextType)
}

  export function showLoader(counter:number):number{
    return (++counter)
  }
  export function hideLoader(counter:number)
  {
    if(counter)
        return (--counter) 
    return 0
  }

export function gettingRoles(gridData: any) {
  let tempRoles = []
  gridData.map((obj) =>{
    tempRoles.push(...obj.roles.split(',').map((j) => j.trim()))
  });
  let roleList = new Set<string>(tempRoles)
  return [...roleList].sort()
}

export const convertToNumber = (value : number) => isNaN(Number(value)) ? 0.00 : Number(Number(value).toFixed(2))