import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lab-admin-popups',
  templateUrl: './lab-admin-popups.component.html',
  styleUrls: ['./lab-admin-popups.component.scss']
})
export class LabAdminPopupsComponent implements OnInit{
  icon : string = '/assets/icons/Close_black.svg'
  primaryBtnClass : string = 'primary'

  constructor(
    public dialogRef: MatDialogRef<LabAdminPopupsComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) {}

  ngOnInit(): void {
    if(this.config.action == 'Delete'){ 
      this.icon = '/assets/icons/delete-red.svg' 
      this.primaryBtnClass = 'danger-secondary'
    }
    else if(this.config.action == 'Warning'){
      this.icon = '/assets/images_vla/Icon_Warning.svg'
    }
  }

  onPrimaryClick(){
    this.dialogRef.close(true)
  }
}
