import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Injectable, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ThemePalette, _MatOptionBase } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
// import { TreeviewItem } from 'ngx-treeview';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BehaviorSubject } from 'rxjs';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { DataShareService } from '../../../core/services/datashare.service';
import { PdfServiceService } from '../../../core/services/pdfservices.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { MatDialog } from '@angular/material/dialog';
import { HomeLayoutComponent } from '../../../common/layout/home-layout.component';
import { DialogService } from '../../../core/services/dialog.service';
import { environment } from 'src/environments/environment';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { FormControlName } from '@angular/forms';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var jQuery: any;

export interface Task {
  color: ThemePalette;
}
/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  casetype: string;
  ChildOrderName: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  selected: boolean;
  casetype: string;
  ChildOrderName: string;
}
interface OrderCodes {
  name: string;
  parentName?: string;
  selected?: boolean;
  parent: any;
  grandParentCodeName?: string;
  indeterminate?: boolean;
  children?: OrderCodes[];
}
@Component({
  selector: 'template-setup-customer-enable-casetype',
  templateUrl: './setup-customer-enable-casetype.component.html',
  styleUrls: ['./setup-customer-enable-casetype.component.scss']
})

export class SetupCustomerEnableCasetypeComponent implements OnInit {
  @Input() selecteddefaultdeploymentkey: any;
  task: Task = {
    color: 'primary',
  };
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  @ViewChild('searchInput') search: ElementRef;
  @ViewChild('TabGroup') compendiumGroup: MatTabGroup;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;


  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl = new NestedTreeControl<OrderCodes>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<OrderCodes>();

  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  arrowIconSubject = new BehaviorSubject('arrow_drop_down');

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  selectedTab = new FormControl(0);
  ref: SetupCustomerEnableCasetypeComponent;
  FormDataEntry: boolean = false;
  submitted: boolean = false;
  submitted1: boolean;
  closedModal: boolean = false;
  nameinvalid: boolean;
  displaynameinvalid: boolean;
  SetupInformation: any;
  SubMenuCardModel: any;
  StateList: any;
  ClaimOptions: any;
  Timezones: any;
  UsernameWebFormat: any;
  UsernameReportFormat: any;
  files = [];
  newLogoSrc = '';
  Lab_Organization_ID: any;
  Lab_Organization_Name: any;
  Lab_Account_ID: any;
  Logo = '';
  LabMnemonic: any;
  OFMnemonic: any;
  Address1: any;
  Address2: any;
  City: any;
  State: any;
  Zip: any;
  Phone: any;
  DisplayName: any;
  Lab_Organization_GUID: any;
  LabOrgNPI: any;
  LabOrgClia: any;
  LabNo_of_Locations: any;
  LabNo_of_users: any;
  Lab_Account_GUID: any;
  Lab_Account_Name: any;
  OF_Account_ID: any;
  OF_Account_GUID: any;
  OF_Account_Name: any;
  Lab_UserName: any;
  Lab_UserRoles: string;
  Lab_UserID: any;
  Lab_UserEmail: string;
  Lab_UserPassword: any;
  OF_UserName: any;
  OF_UserRole: any;
  OF_UserID: any;
  OF_UserEmail: any;
  OF_UserPassword: any;
  CaseType: any;
  resultPage: boolean = false;
  UserEnteredDataDiv = false;
  showPassword: boolean = false;
  OFNo_of_users: any;
  OF_Organization_GUID: any;
  OFOrgClia: any;
  OFOrgNPI: any;
  OF_Organization_ID: any;
  OF_Organization_Name: any;
  passwordHidden = true;
  passwordShown: boolean;
  labpasswordHidden = true;
  emailValid: boolean = true;
  NPIWarning: boolean = false;
  zipValid: boolean = true;
  payZipValid: boolean = true;
  imageUploaded: boolean = false;
  imagePreview: string = '';
  NPI: any = '';
  npiValidated: boolean = false;
  validationWarning: boolean = false;
  dateFormat: string[] = ['mm-dd-yyyy', 'dd-mm-yyyy'];
  faxValid: boolean = true;
  LabType: any;
  selectLabType: any;
  public DeploymentKeys = [];
  defaultOrgId: number = 190996;
  defaultAccId: number = 60006;
  representativeUsersList: any = [];
  representativeRolesList: any = [];
  selectedRepresentativesList: any = [];
  saveClicked: any;


  frm_UserEnteredData = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    selectLabType: ['', Validators.required],
    frm_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_displayname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_shortname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    frm_npi: ['', Validators.required],
    frm_clia: '',
    frm_ein: '',
    frm_Address1: null,
    frm_Address2: null,
    frm_zip: '',//[Validators.pattern('\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$')]
    frm_phone: ['', [Validators.pattern(/\d{3}[\-]?\d{3}[\-]?\d{4}(x\d{10}?)?$/)]], // ['', [Validators.pattern('^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$')]],
    frm_state: '',
    frm_city: null,
    frm_country: null,
    frm_fax: ['', [Validators.pattern(/(\d{3}[\.]?\d{3}[\.]?\d{4},?$){1,3}/)]],
    frm_mnemonic: ['', [Validators.required, Validators.maxLength(18)]],
    frm_batchingmnemonic: null,
    frm_taxonomycode: null,
    frm_supportid: null,
    frm_claimoptions: 'Track Claim',
    frm_claimmnemonics: null,
    frm_clpol: null,
    frm_reportdata: '',
    frm_enabledfax: 0,
    frm_sharedsurgicalcenter: 0,
    frm_enabledsendout: 0,
    frm_statelicensenumber: null,
    frm_usernameDisplayFormat: '',
    frm_usernameReportDisplayFormat: '',
    frm_collectiondateformat: 'mm-dd-yyyy',
    frm_packagedateformat: '',
    frm_etadateformat: '',
    frm_payaddress1: null,
    frm_payaddress2: null,
    frm_paycity: null,
    frm_paystate: '',
    frm_payzip: '',
    frm_timezone: ''
  });
  @ViewChild('labReportDetails') labReportDetails: ElementRef;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  checkAllPanel: boolean = false;
  frm_NPI = '';
  frm_logo = null;
  disbaleCreateBtn = false;
  checkAllCaseType = false;
  selectedCasetypesList = [];
  categoryList: any[];
  showCasetype: boolean = false;
  errorAlertDiv: any;
  errorAlertSpan: any = "";
  imagePath: boolean = false;
  summaryData: any = [];
  downloadReportClicked: boolean = false;
  selectedPdfFacilityDetails: any = {};
  selectedPdfFacilityDetails1: any = [];
  selectedPdfCasetypeDetails: any = [];
  downloadReportLab: boolean = false;
  downloadReportFacility: boolean = false;
  downloadReportAllFacility: boolean = false;
  downloadReportCasetype: boolean = false;
  deploymentKey: any = '';
  showComponent: boolean;
  hideLabDetailsTab: boolean;
  hideCaseTypeTab: boolean;
  hideSummaryTab: boolean;
  hidePopUp: boolean = false;
  alertPopUp: boolean;
  saveAndProceed: boolean;
  proceedWithoutSaving: boolean;
  tabChangeDirection: any;
  labDetails: any = {};
  filterCaseTypeData: any[];
  searchCaseType: string = "";
  orderCodeList: any = [];
  resultList: any[];
  orderPopup: boolean = false;
  categoryListCopy: any[];
  orderCodeListCopy: any[];
  orderCodeSavedList: any = [];
  caseType: any;
  resultSetList: any = [];
  resultSetListCopy: any[];
  resultSetSavedList: any = [];
  modalCustomerDefinitionLoaded: boolean = true;
  instrumentsList: any = [];
  instrumentsListCopy: any = [];
  instrumentsListSavedList: any = [];

  checkAllInstruments: boolean = false;
  disableDeployment: boolean = false;
  savedOrderCodesCasetype: any = [];
  orderCodeData: any;
  searchString: string = '';
  defaultdeploymentkey: string;
  selectedData: boolean = false;
  popupReopened: boolean;
  treeStructures: any = {};
  savedResults: any = {};
  savedInstruments: any = {};
  allOrglist: any = [];
  previousNPIValue: string = '';
  mdmProviderId: number = 0;
  selectedAccount: string = 'Default Configuration';
  labList: any = [];
  selectedAccCopy: string;
  savedCaseTypes: any = [];
  selectedCaseTypes: any = [];
  organzationid: any;
  currentAccountid: number;
  currentOrgID: number;
  configuredCaseTypes: any;
  selectedDeployment: any;
  organizationList: any;
  showAssignCard = false;
  currentRole: any;
  selectedRepresentativesListClone: any;

  //copy across deplyment
  enableDeploymentlist: boolean = false;
  headerDeploymentKey: any;
  sourceDeploymentOrgList: any;
  caseTypeNames: any[];
  commaSeparatedString: any;
  disableCaseType: boolean = false;
  CopyAcrossDeploymentKey: any;
  copyAllConfig: number=0;

  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      let value
      let node = new TodoItemNode();
      value = obj[key];
      node.item = key;
      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  constructor(
    private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder, private homeLayout: HomeLayoutComponent,
    private clipboard: Clipboard, private commonService: CommonService, public _snackBar: MatSnackBar, private opendialog: DialogService,
    private datashare: DataShareService, public datepipe: DatePipe, private changeDetectorRef: ChangeDetectorRef, private getpdfservice: PdfServiceService, private dialog: MatDialog,
    private _labadminservice: LabadminService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vaservices, datashare);
    this.DeploymentKeys = this.vaservices.DeploymentKeys;
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, node: OrderCodes) =>
    !!node.children && node.children.length > 0;

  setParent(data, parent) {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x) => {
        this.setParent(x, data);
      });
    }
  }

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  closeM() {
    $(document).on("click", ".remove-modal", function () {
      $(".modal-dialog").remove();
    });
  }

  checkAllParents(node) {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected = descendants.every((child) => child.selected);
      node.parent.indeterminate = descendants.some((child) => child.selected);
      this.checkAllParents(node.parent);
    }
    this.checkAllPanel = this.dataSource.data.every(va => va.selected) ? true : false
  }

  removeTrailCharacter(controlName: any) {
    this.commonService.removeTrailCharacter(controlName);
  }

  todoItemSelectionToggle(checked, node) {
    node.selected = checked;
    if (node.children) {
      node.children.forEach((x) => {
        this.todoItemSelectionToggle(checked, x);
      });
    }
    this.checkAllParents(node);
  }

  async ngOnInit() {
    this.currentRole = localStorage.getItem('Homepage_role');
    this.hidePopUp = false
    this.selectedData = true
    this.modalCustomerDefinitionLoaded = true
    $('#manageOrderCode').modal('hide');
    this.frm_UserEnteredData.patchValue({
      frmDeploymentKey: '',
      selectLabType: 'Commercial'
    });
    //this.defaultdeploymentkey = this.commonService.selectedDefaultDeployment()
    if (this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase() != 'all') {
      this.popupReopened = false
      this.onChangeDep(undefined, this.selecteddefaultdeploymentkey)
    }
    this.selectedRepresentativesListClone = structuredClone(this.selectedRepresentativesList);
    this.enableTab(true, false, false);
    this.getListOfRepresentiveUsers();
  }


  ngOnChanges() {
    this.selectedData = true;
    this.modalCustomerDefinitionLoaded = true
    if (this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase() != 'all' && this.popupReopened) {
      this.popupReopened = false
      // this.onChangeDep(undefined, this.selecteddefaultdeploymentkey)
      this.onChangeDep(undefined, this.selectedDeployment)
    }
  }

  // getItems(parentChildObj) {
  //   let itemsArray = [];
  //   parentChildObj.forEach(set => {
  //     itemsArray.push(new TreeviewItem(set))
  //   });
  //   return itemsArray;
  // }

  configureOrderCodes() {
    $('#manageOrderCode').modal('show');
  }

  manageOrderCodesOpen(casetype) {
    this.getOrderCodes(casetype);
  }
  //#region Deployment selection and Next Button click
  setInitialFormValues() {
    this.selectedData = false
    this.frm_UserEnteredData.patchValue({
      frm_npi: 'UNKNOWN',
      frm_clia: '',
      frm_ein: '',
      frm_Address1: null,
      frm_Address2: null,
      frm_zip: '',
      frm_phone: '',
      frm_state: '',
      frm_city: null,
      frm_country: 'US',
      frm_fax: '',
      frm_mnemonic: '',
      frm_batchingmnemonic: null,
      frm_taxonomycode: null,
      frm_supportid: null,
      frm_claimoptions: 'Track Claim',
      frm_claimmnemonics: null,
      frm_clpol: null,
      frm_reportdata: '',
      frm_enabledfax: 0,
      frm_sharedsurgicalcenter: 0,
      frm_enabledsendout: 0,
      frm_statelicensenumber: null,
      frm_usernameDisplayFormat: '',
      frm_usernameReportDisplayFormat: '',
      frm_collectiondateformat: 'mm-dd-yyyy',
      frm_packagedateformat: '',
      frm_etadateformat: '',
      frm_payaddress1: null,
      frm_payaddress2: null,
      frm_paycity: null,
      frm_paystate: '',
      frm_payzip: '',
      frm_timezone: ''
    });
    this.captureNPI(this.frm_UserEnteredData.value.frm_npi)
    this.imagePath = false;
    this.frm_logo = null;
  }

  newCustomerSetupForm(deviceValue) {
    this.FormDataEntry = false;
    this.selectedData = true;
    this.UserEnteredDataDiv = true;
    this.selectLabType = this.frm_UserEnteredData.value.selectLabType;
    this.setInitialFormValues();
    this.checkAllCaseType = false;
    this.checkAllInstruments = true;
    this.categoryList = [];
    this.showCasetype = false;
    this.errorAlertDiv = "";
    this.errorAlertSpan = "";
    this.imagePath = false;
    this.summaryData = [];
    this.frm_NPI = '';
    this.frm_logo = null;
    this.disbaleCreateBtn = false;
    this.downloadReportClicked = false;
    this.selectedCasetypesList = [];
    this.selectedPdfFacilityDetails = {};
    this.selectedPdfFacilityDetails1 = [];
    this.downloadReportLab = false;
    this.downloadReportFacility = false;
    this.downloadReportAllFacility = false;
    this.downloadReportCasetype = false;
    this.selectedPdfCasetypeDetails = [];
    let queryVariable = { keyword: 'getDropDownValues' };
    let query = this.SubMenuCardModel.GetQuery('CategoryDropDown');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vaservices.GetData(queryResult, 'configdb').subscribe(data => {
      this.SetupInformation = data.data.submenuData[0];
      let dropdowndata = JSON.parse(this.SetupInformation.Items_JSON);
      this.StateList = dropdowndata.States;
      this.StateList.forEach(e => {
          // Extract the state name and abbreviation
          const stateName = e.item.substring(0, e.item.lastIndexOf('(')).trim();
          const stateAbbreviation = e.item.substring(e.item.lastIndexOf('(') + 1, e.item.lastIndexOf(')')).trim();

          // Add new formatted key
          e['formattedstate'] = `${stateAbbreviation} (${stateName})`;
          e['itemValue'] = stateAbbreviation; // Assuming you still want itemValue as well
        });
      this.Timezones = dropdowndata.TimeZone;
      this.UsernameWebFormat = dropdowndata.UserNameDisplayFormat;
      this.UsernameReportFormat = dropdowndata.UserNameReportFormat;
      this.GetUserIdInfo(deviceValue);
    }, error => {
      console.error(error);
    }
    );
  }
  //#endregion

  //#region NPI validation
  captureNPI(NPI) {
    this.triggerNPIPopup();
    this.NPI = NPI.toString().trim();
    this.validationWarning = false;
    this.NPIWarning = false;
    this.npiValidated = false;
    if (this.NPI.toLowerCase() == 'unknown') {
      this.npiValidated = true
    }
  }

  ValidateNPIandgetData(NPI) {
    if (NPI.toString() != '' && NPI.toString().toLowerCase() != 'unknown') {
      this.validationWarning = false;
      this.NPIWarning = false;
      this.ngxService.start();
      if (NPI.toString().length == 10) {
        this.npiValidated = true;
        this.NPIWarning = false;
      }
      else {
        this.npiValidated = false;
        this.NPIWarning = true;
      }
      this.ngxService.stop();
      // this.vaservices.NpiValidation({ npi: NPI }).subscribe(
      //   Info => {
      //     this.ngxService.stop();
      //     this.NPIWarning = Info.result_count ? (Info.result_count == 0 ? true : false) : true;
      //     if (Info.results && Info.results.length > 0) {
      //       //this is to get only Group level NPI details and not individual(user) level details
      //       if (Info.results[0].enumeration_type == "NPI-2") {
      //         this.npiValidated = true;
      //         this.populateNPIDetails(Info.results[0]);
      //       }
      //       else {
      //         let dialogRef = this.dialog.open(ConfirmComponent, {
      //           disableClose: true,
      //           width: '360px',
      //           data: { header: "", message: "This is not a valid NPI for the location.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
      //         })
      //         return dialogRef.afterClosed().toPromise().then(result => {
      //           if (result) {
      //             this.npiValidated = true;
      //             if (!this.frm_UserEnteredData.controls.frm_name) {
      //               this.populateNPIDetails(Info.results[0]);
      //             }
      //           }
      //           else {
      //             this.NPIWarning = true;
      //              this.formcontrol['frm_npi'].setErrors({ 'incorrect': true });
      //             this.npiValidated = false;
      //           }
      //         })
      //       }
      //     }
      //     else {
      //       this.formcontrol['frm_npi'].setErrors({ 'incorrect': true });
      //       this.NPIWarning = true;
      //       this.npiValidated = false;
      //     }
      //   },
      //   error => {
      //     this.ngxService.stop();
      //     console.error(error);
      //     this.NPIWarning = true;
      //     this.formcontrol['frm_npi'].setErrors({ 'incorrect': true });
      //     this.npiValidated = !this.NPIWarning;
      //   });
      return;
    }
    if (NPI.toString() != 'unknown') this.validationWarning = true;
  }

  populateNPIDetails(result) {
    let addressObj: any;
    if (result.addresses && result.addresses.length > 0)
      addressObj = result.addresses.find(e => e.address_purpose.toUpperCase() == "LOCATION");
    if (addressObj) {
      this.frm_UserEnteredData.patchValue({
        frm_name: result.basic.organization_name ?? '',
        frm_country: addressObj.country_name ?? '',
        frm_Address1: addressObj.address_1 ?? '',
        frm_Address2: addressObj.address_2 ?? '',
        frm_city: addressObj.city ?? '',
        frm_state: addressObj.state ?? '',
        frm_zip: addressObj.postal_code ?? '',
        frm_phone: this.commonService.changeAdminContactPhone(addressObj?.telephone_number) ?? '',
        frm_fax: this.commonService.changeAdminFaxNumber(addressObj.fax_number) ?? ''
      });
      if (this.frm_UserEnteredData.value.frm_name && this.frm_UserEnteredData.value.frm_name != "")
        this.generateNameAndMnemonic(this.frm_UserEnteredData.value.frm_name)
      else
        this.frm_UserEnteredData.controls['frm_mnemonic'].setValue('');
    }
  }
  //#endregion NPI validation

  //#region Casetype details
  getCasetypeDetails() {
    let dbName = this.frm_UserEnteredData.value.frmDeploymentKey;
    this.ngxService.start();
    this.vaservices.getCasetypeDetails(this.defaultOrgId, this.defaultAccId, dbName).subscribe(
      res => {
        this.categoryList = [];
        this.categoryListCopy = [];
        if (res && res != "" && res.length > 0) {
          res.forEach(e => {
            if (!this.categoryList.find(element => element.Category == e.Category))
              this.categoryList.push({ Category: e.Category, caseTypes: [], checked: false })

            for (let i = 0; i < this.categoryList.length; i++) {
              if (this.categoryList[i].Category == e.Category) {
                this.categoryList[i].caseTypes.push({ casetype: e.CaseType, checked: false, Iscompendium: e.Iscompendium ? e.Iscompendium : false });
                break;
              }
            }
          });

          this.filterCaseTypeData = cloneDeep(this.categoryList);
          this.categoryListCopy = cloneDeep(this.categoryList);
          this.showCasetype = true;
        }
        else {
          this.showCasetype = false;
          this._snackBar.open("Error found while fetching casetypes", "Close");
          this.deploymentSelection();
        }
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
        this.categoryList = [];
        this.showCasetype = false;
        console.error(error);
        sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey)
        this._snackBar.open("Error found while fetching casetypes", "Close");
        this.deploymentSelection();
      });
  }

  checkAllCasetypes(event) {
    //Checks all Category
    this.checkAllCaseType = !this.checkAllCaseType;
    this.categoryList.forEach(element => {
      element.checked = event.checked;
      this.checkOnlyCategory(event, element.Category);
    });
    this.equateCaseTypeValues();
  }

  checkCategory(event, category) {
    //Checks all Casetypes
    this.checkOnlyCategory(event, category);
    this.categoryAllChecked();
    this.equateCaseTypeValues();
  }

  checkOnlyCategory(event, category) {
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].Category == category) {
        this.categoryList[i].checked = event.checked;
        let checkCategory = this.categoryList[i].checked;
        this.categoryList[i].caseTypes.forEach(element => {
          element.checked = checkCategory;
        });
        break;
      }
    }
  }

  checkCasetypes(category, casetype) {
    //Checks seleted Casetype
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].Category == category) {
        for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
          if (this.categoryList[i].caseTypes[j].casetype == casetype) {
            if (!this.categoryList[i].caseTypes[j].checked) {
              //when casetype is selected
              this.categoryList[i].caseTypes[j].checked = !this.categoryList[i].caseTypes[j].checked;
              this.categoryList[i].checked = true;
            }
            else {
              //when casetype is deselected
              this.categoryList[i].caseTypes[j].checked = !this.categoryList[i].caseTypes[j].checked;
              //check if other casetypes in the same category are selected
              this.categoryList[i].checked = false;
              for (let k = 0; k < this.categoryList[i].caseTypes.length; k++) {
                if (this.categoryList[i].caseTypes[k].checked) {
                  this.categoryList[i].checked = true;
                  break;
                }
              }
            }
            break;
          }
        }
        break;
      }
    }
    //this.casetypesAllChecked(category);
    this.categoryAllChecked();
    this.equateCaseTypeValues();
  }

  categoryAllChecked() {
    let checkFlag = true;
    //Find if all Categories are checked
    for (let i = 0; i < this.categoryList.length; i++) {
      for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
        if (!this.categoryList[i].caseTypes[j].checked) {
          checkFlag = false
        }
      }
    }
    this.checkAllCaseType = checkFlag
  }

  casetypesAllChecked(category) {
    //Find if all Casetypes are checked for a category
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].Category == category) {
        for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
          if (!this.categoryList[i].caseTypes[j].checked) {
            this.checkAllCaseType = false;
            this.categoryList[i].checked = false;
            break;
          }
          else if (this.categoryList[i].caseTypes.length - 1 == j && !this.checkAllCaseType) {
            this.categoryList[i].checked = true;
          }
        }
        break;
      }
    }
    this.categoryAllChecked();
    this.equateCaseTypeValues();
  }
  //#endregion

  //#region On click of the 'X' button to close modal
  setupNewCustomerClose(tabGroup: MatTabGroup) {
    this.selectedData = true;
    this.disableCaseType = false;
    this.enableDeploymentlist = false;
    if (tabGroup)
      tabGroup.selectedIndex = 0;
    this.closedModal = true;
    this.imagePreview = '';
    this.imageUploaded = false;
    this.savedOrderCodesCasetype = [];
    this.disableDeployment = false;
    this.validationWarning = false;
    this.labpasswordHidden = true;
    this.passwordHidden = true;
    this.files = [];
    this.frm_NPI = "";
    this.npiValidated = false;
    this.searchCaseType = "";
    this.newLogoSrc = '';
    this.LabType = '';
    this.Logo = '';
    this.onRemove()
    sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
    $('#manageOrderCode').modal('hide');
    $('#LabSetup').modal('show');
    $('#genericModal').modal('hide');
    this.FormDataEntry = false;
    this.zipValid = true;
    this.payZipValid = true;
    this.submitted = false;
    this.nameinvalid = false;
    this.displaynameinvalid = false;
    this.resultPage = false;
    this.UserEnteredDataDiv = false;
    this.frm_UserEnteredData.reset();
    this.labDetails = {}
    this.frm_UserEnteredData.patchValue({
      selectLabType: 'Commercial',
    });

    this.homeLayout.SelectedMenuModel = false;

    this.formcontrol['frmDeploymentKey'].setErrors(null);
    this.formcontrol['frm_name'].setErrors(null);
    this.formcontrol['frm_displayname'].setErrors(null);
    this.formcontrol['frm_shortname'].setErrors(null);
    this.formcontrol['frm_mnemonic'].setErrors(null);
  }
  //#endregion On click of the 'X' button to close modal

  get formcontrol() {
    return this.frm_UserEnteredData.controls;
  }

  async onChangeDep(tabGroup: MatTabGroup | undefined, deviceValue) {
    this.selectedData = false;
    this.deploymentKey = deviceValue;
    this.submitted = false;
    this.headerDeploymentKey = deviceValue;
    this.getLabsUnderOrganization(deviceValue);
    this.newCustomerSetupForm(deviceValue);
    if (tabGroup !== undefined) {
      tabGroup.selectedIndex = 0;
    }

    this.frm_UserEnteredData = this._fb.group({
      frmDeploymentKey: [deviceValue, Validators.required],
      selectLabType: ['Commercial', Validators.required],
      frm_name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      frm_displayname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      frm_shortname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      frm_npi: '',
      frm_clia: '',
      frm_ein: '',
      frm_Address1: null,
      frm_Address2: null,
      frm_zip: '',//[Validators.pattern('\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$')]
      frm_phone: ['', [Validators.pattern(/\d{3}[\-]?\d{3}[\-]?\d{4}(x\d{10}?)?$/)]], // ['', [Validators.pattern('^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$')]],
      frm_state: '',
      frm_city: null,
      frm_country: null,
      frm_fax: ['',[Validators.pattern(/(\d{3}[\.]?\d{3}[\.]?\d{4}[,]?$){1,3}/)]],
      frm_mnemonic: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      frm_batchingmnemonic: null,
      frm_taxonomycode: null,
      frm_supportid: null,
      frm_claimoptions: 'Track Claim',
      frm_claimmnemonics: null,
      frm_clpol: null,
      frm_reportdata: '',
      frm_enabledfax: 0,
      frm_sharedsurgicalcenter: 0,
      frm_enabledsendout: 0,
      frm_statelicensenumber: null,
      frm_usernameDisplayFormat: '',
      frm_usernameReportDisplayFormat: '',
      frm_collectiondateformat: 'mm-dd-yyyy',
      frm_packagedateformat: '',
      frm_etadateformat: '',
      frm_payaddress1: null,
      frm_payaddress2: null,
      frm_paycity: null,
      frm_paystate: '',
      frm_payzip: '',
      frm_timezone: ''
    });
    this.orderCodeSavedList = [];
    sessionStorage.setItem('deploymentKey', this.deploymentKey);
    //for Accross deploymnet check that p4 to p4 only Non-p4 to non p4 only
    if (this.headerDeploymentKey.toLowerCase().match(/jthx|thx|thxvar|jthxar/)) {
      this.CopyAcrossDeploymentKey=["THX","THXVAR"]
  } else {
    const regex = /jthx|thx|thxvar|jthxar/i; // Case-insensitive regex
    const filteredItems = this.DeploymentKeys.filter(item => !regex.test(item));
    this.CopyAcrossDeploymentKey=filteredItems
  }
    this.allOrglist = await this.commonService.getAllOrganization();
  }

  ngOnDestroy() {
  }
  GetUserIdInfo(deviceValue) {
    let query = this.vaservices.GetQuery('userroles');
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: { email: localStorage.getItem("UserEmail") },
    };
    if (!this.popupReopened) {
      this.ngxService.start();
    }
    this.vaservices.GetData(queryRequest, this.frm_UserEnteredData.value.frmDeploymentKey.toString()).subscribe((data) => {
      if (!this.popupReopened) {
        this.ngxService.stop();
      }
      if (!data.errors && (data != undefined && data.data.submenuData.length > 0)) {
        let userid = data.data.submenuData[0].userid;
        sessionStorage.setItem('Userid', userid);
      }
      else {
        sessionStorage.setItem('Userid', '');
      }
      setTimeout(() => {
        this.formGroupDirective.resetForm();
        this.frm_UserEnteredData.patchValue({
          frmDeploymentKey: deviceValue,
          selectLabType: 'Commercial',
          frm_npi: 'UNKNOWN',
          frm_country: 'US',
          frm_claimoptions: 'Track Claim',
           frm_collectiondateformat : 'mm-dd-yyyy'
        });
        this.getCasetypeDetails();
      });
    },
      (error) => {
        if (!this.popupReopened) {
          this.ngxService.stop();
        }
        console.error(error);
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      }
    );
  }

  //#region Generate mnemonic from name value of the form
  generateNameAndMnemonic(Name) {
    if (Name.length > 0 && Name.trim() !== '') {
      // Remove any trailing punctuation or whitespace
      Name = Name.trim().replace(/[^\w\s]/g, '');

      if (Name.split(' ').length > 1) {
        const matches = Name.match(/\b(\w)/g);

        // Check if matches are found before accessing them
        if (matches && matches.length >= 2) {
          const acronym = matches[0] + matches[1];
          this.frm_UserEnteredData.controls['frm_mnemonic'].setValue(acronym.trim().toUpperCase());
          this.frm_UserEnteredData.controls['frm_shortname'].setValue(acronym.trim().toUpperCase());
        } else if (matches && matches.length === 1) {
          const acronym = matches[0]; // If only one initial is found
          this.frm_UserEnteredData.controls['frm_mnemonic'].setValue(acronym.trim().toUpperCase());
          this.frm_UserEnteredData.controls['frm_shortname'].setValue(acronym.trim().toUpperCase());
        }
      } else {
        this.frm_UserEnteredData.controls['frm_mnemonic'].setValue(Name.trim().toUpperCase().substring(0, 2));
        this.frm_UserEnteredData.controls['frm_shortname'].setValue(Name.trim().toUpperCase().substring(0, 2));
      }
      this.frm_UserEnteredData.controls['frm_displayname'].setValue(Name);
      // Update the value and validity of frm_mnemonic
      return;
    }
    this.frm_UserEnteredData.controls['frm_shortname'].setValue('');
    this.frm_UserEnteredData.controls['frm_displayname'].setValue('');
    this.frm_UserEnteredData.controls['frm_mnemonic'].setValue('');
  }
  //#endregion

  //#region Validate image file and convert to base64
  getuploadedfile(event) {
    this.imageupload(event.target.files[0]);
  }
  imageupload(file) {
    this.imageUploaded = false;
    let tempArrData: any;
    let addedFiles: any = [];
    if (file) {
      tempArrData = file;
    } else {
      tempArrData = event[0];
      this.imagePreview = '';
    }
    if (
      tempArrData.type.toLowerCase() == 'image/png' ||
      tempArrData.type.toLowerCase() == 'image/jpeg' ||
      tempArrData.type.toLowerCase() == 'image/jpg'
    ) {
      addedFiles = [];
      addedFiles.push(tempArrData);
      this.files = [];
      this.files.push(addedFiles[0]);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logo_Upload] = this.files;
        reader.readAsDataURL(logo_Upload);
        reader.onload = () => {
          let imagebyte = reader.result as string;
          this.newLogoSrc = imagebyte;
          this.newLogoSrc = this.newLogoSrc.toString().split(',')[1];
          this.imageUploaded = true;
          this.imagePreview = 'data:image/jpg;base64,' + this.newLogoSrc;
        };
      } else {
        this.clearImage();
        this._snackBar.open(
          'An error occurred while processing your request',
          'Close'
        );
      }
    } else {
      this.clearImage();
      this._snackBar.open('Please upload a valid image file.', 'Close');
    }
  }
  //#endregion

  //#region Export Setup information
  downloadSetupDetails(context?) {
    let organizationTab = [];
    let accountTab = [];
    let userTab = [];
    let casetypeTab = [];
    let representativeDetailsTab = [];

    // let filename =''
    //   // this.summaryData.OrgLabAccData.OrganizationName.toString().replace(' ', '') + '_Setup.xlsx';

    // if (filename.length > 32) {
    //   filename = 'Lab_Setup.xlsx';
    // }
    let filename = 'Lab Details_';
    if (this.summaryData.OrgLabAccData.OrganizationName) {
      filename = filename + this.summaryData.OrgLabAccData.OrganizationName.toString();
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let summarydata = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (summarydata.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + summarydata + '_';
        }
        else {
          filename = fileName + summarydata + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    var sheet = XLSX.utils.book_new();

    //Group tab in the excel
    //lab
    organizationTab.push({
      OrganizationID: this.summaryData.OrgLabAccData.OrganizationID,
      OrganizationName: this.summaryData.OrgLabAccData.OrganizationName,
      Mnemonic: this.summaryData.OrgLabAccData.Orgnaizationmnemonic,
      NPI: this.summaryData.OrgLabAccData.NPI,
      CLIA: this.summaryData.OrgLabAccData.Clia,
    });
    //facilities
    // this.summaryData.FacAccData.forEach(e => {
    //   let obj = {
    //     OrganizationID: e.FacOrgDetails.OrganizationID,
    //     OrganizationName: e.FacOrgDetails.FacilityOrgName,
    //     Mnemonic: e.FacOrgDetails.Facilitymnemonic,
    //     NPI: e.FacilityNPI,
    //     CLIA: e.FacilityClia,
    //   }
    //   organizationTab.push(obj);
    // });

    var sheetData1 = XLSX.utils.json_to_sheet(organizationTab);
    XLSX.utils.book_append_sheet(sheet, sheetData1, 'Organizations');

    // account tab in the excel
    accountTab.push({
      OrganizationID: this.summaryData.OrgLabAccData.OrganizationID,
      AccountID: this.summaryData.OrgLabAccData.AccountID,
      AccountName: this.summaryData.OrgLabAccData.AccountName,
      Mnemonic: this.summaryData.OrgLabAccData.Mnemonic,
      DisplayName: this.summaryData.OrgLabAccData.DisplayName,
      GUID: this.summaryData.OrgLabAccData.AccountGUID,
      NPI: this.summaryData.OrgLabAccData.NPI,
      CLIA: this.summaryData.OrgLabAccData.Clia,
      Address1: this.summaryData.OrgLabAccData.Address1,
      Address2: this.summaryData.OrgLabAccData.Address2,
      City: this.summaryData.OrgLabAccData.City,
      State: this.summaryData.OrgLabAccData.State,
      Zip: this.summaryData.OrgLabAccData.Zip,
      Phone: this.commonService.changeAdminContactPhone(this.summaryData?.OrgLabAccData?.Phone)
    });

    // this.summaryData.FacAccData.forEach(e => {
    //   let obj = {
    //     OrganizationID: e.OrganizationID,
    //     AccountID: e.FacilityAccountID,
    //     AccountName: e.FacilityAccountName,
    //     Mnemonic: e.FacilityMnemonic,
    //     DisplayName: e.FacilityDisplayName,
    //     GUID: e.FacilityAccountGUID,
    //     NPI: e.FacilityNPI,
    //     CLIA: e.FacilityClia,
    //     Address1: e.FacilityAddress1,
    //     Address2: e.FacilityAddress2,
    //     City: e.FacilityCity,
    //     State: e.FacilityState,
    //     Zip: e.FacilityZip,
    //     Phone: e.FacilityPhone
    //   }
    //   accountTab.push(obj);
    // });

    var sheetData2 = XLSX.utils.json_to_sheet(accountTab);
    XLSX.utils.book_append_sheet(sheet, sheetData2, 'Locations');


    // user tab in the excel;
    this.summaryData.UsersData.forEach(e => {
      if (e.UserAccountID == accountTab[0].AccountID) {
        userTab.push({
          UserId: e.UserID,
          UserName: e.Users,
          Roles: e.RoleName,
          Email: e.Email,
          AccountId: e.UserAccountID
        });
      }
    });

    var sheetData3 = XLSX.utils.json_to_sheet(userTab);
    XLSX.utils.book_append_sheet(sheet, sheetData3, 'Users');


    // casetypes tab in the excel;
    this.summaryData.CasetypeData.forEach(e => {
      if (e.CaseTypeAccountID == accountTab[0].AccountID) {
        casetypeTab.push({
          CaseTypeName: e.CaseType,
          CaseTypeDisplayname: e.CaseTypeDisplayname,
          Category: e.Category,
          CaseTypeAccountID: e.CaseTypeAccountID
        });
      }
    });

    var sheetData4 = XLSX.utils.json_to_sheet(casetypeTab);
    XLSX.utils.book_append_sheet(sheet, sheetData4, 'Casetypes');
    // let Id  = organizationTab[0].OrganizationID == organizationTab[0].OrganizationID ? organizationTab[0].OrganizationID : accountTab[0].AccountID;
    let Id = this.summaryData.OrgLabAccData.AccountID + '.xlsx';
    filename = filename + Id;
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgid = filename.split('_')[2];
      filename = fileName + orgid;
    }


    if (this.summaryData?.assinedUsers?.length && context) {
      // Representatives tab in the excel;
      this.summaryData?.assinedUsers?.forEach(e => {
        representativeDetailsTab.push({
          RepresentativeId: e?.RepresentativeId,
          RepresentativeName: e?.RepresentativeName,
          RoleName: e?.RoleName,
          Email: e?.Email,
          EmployeeId: e?.EmployeeId,
          Phone: this.commonService.changeAdminContactPhone(e?.Phone),
          WorkLocation: e?.WorkLocation,
          Department: e?.Department,
        });
      });

      var sheetData3 = XLSX.utils.json_to_sheet(representativeDetailsTab);
      XLSX.utils.book_append_sheet(sheet, sheetData3, 'Representative Details');
    }


    //when downloading only lab details
    if (context) {
      XLSX.writeFile(sheet, filename);
    }
    else {
      //downloading casetype details
      return sheet;
    }
  }
  //#endregion

  //#region Back to selecting deployment
  deploymentSelection(compendiumGroup?) {
    this.imagePreview = '';
    this.validationWarning = false;
    this.zipValid = true;
    this.payZipValid = true;
    this.submitted = false;
    this.imageUploaded = false;
    this.frm_UserEnteredData.reset();
    this.files = [];
    this.emailValid = true;
    this.FormDataEntry = false;
    this.UserEnteredDataDiv = false;
  }
  //#endregion

  //#region Logo
  clearImage() {
    (document.getElementById('myFileInputID') as HTMLInputElement).value = null;
    this.imagePreview = '';
    this.imageUploaded = false;
    this.files = [];
  }

  onRemove() {
    this.files = []
    this.frm_logo = null;
    this.imagePath = false;
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(event) {
    let reader = new FileReader();
    reader.onload = function () {
      let output: any = document.getElementById('blah');
      output.src = reader.result;
    }
    this.imagePath = false;
    if (event[0]) {
      reader.readAsDataURL(event[0]);
    }
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  onFileChange(event) {
    this.onRemove();
    let tempArrData: any
    if (event.target) {
      tempArrData = event.target.files[0];
    } else {
      tempArrData = event[0];
    }
    if (tempArrData.type.toString().toLowerCase() == "image/png" || tempArrData.type == "image/jpeg" || tempArrData.type == "image/jpg") {
      event.addedFiles = [];
      event.addedFiles.push(tempArrData);
      this.files.push(...event.addedFiles);
      const reader = new FileReader();
      if (this.files && this.files.length) {
        const [logoUpload] = this.files;
        reader.readAsDataURL(logoUpload);
        reader.onload = () => {
          this.frm_logo = reader.result as string;
          this.imagePath = true;
        };
      } else {
        this._snackBar.open("An error occurred while processing your request", "Failed");
      }
    } else {
      this._snackBar.open("Please upload image file only", "Failed");
      this.imagePath = false;
    }
    this.fileDropRef.nativeElement.value = "";
  }

  toDataURL = async (url) => {
    var res = await fetch(url);
    var blob = await res.blob();
    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  };
  //#endregion

  copy(value: string) {
    this.clipboard.copy(value);
    this._snackBar.open('', 'Copied');
  }

  //#region Validation
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  AllowOnlyNumberandSpclChar(event: KeyboardEvent) {
    const pattern = /^[0-9*#+. -]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateEmail(Email) {
    if (Email && Email.trim() != '') {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      this.emailValid = !reg.test(Email) ? false : true;
    }
  }

  zipCodeValidation(zip, from) {
    if (zip && zip.trim() != '') {
      let tempValue
      if (from == 'zip') {
        const zipValidator = /^(\d{5}([\-\ ]\d{4})?)$/;
        tempValue = zipValidator.test(zip) ? true : false;
        this.zipValid = tempValue;
      }
      else if (from == 'payzip') {
        const newzipValidator = /^[0-9]{5}([0-9]{4})?$/;
        tempValue = newzipValidator.test(zip) ? true : false;
        this.payZipValid = tempValue;
      }
    }
    else {
      if (from == 'zip')
        this.zipValid = true;
      else if (from == 'payzip')
        this.payZipValid = true;
    }
  }

  validateFax(value) {
    if (value.length > 0) {
      let faxRegEx = /\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$/;
      if (!faxRegEx.test(value)) {
        this.faxValid = false;
        return;
      }
    }
    this.faxValid = true;
  }
  //#endregion

  //#region Update form values and send them for insertion
  async quicksetupnewlab(tabGroup, UserEnteredData) {
    {
      this.errorAlertDiv = "";
      this.errorAlertSpan = "";
      this.submitted = true;
      this.tabChangeDirection = 'right'
      this.validationWarning = false;
      this.NPIWarning = !this.frm_UserEnteredData.value.frm_npi ? true : false

      if (Object.keys(this.labDetails).length == 0) {
        this.tabChangeDirection = 'left';
        this._snackBar.open('Save Lab Details', 'Close');
        this.tabChange(tabGroup);
        return;
      }

      if (this.frm_UserEnteredData.value.frm_npi != null && this.frm_UserEnteredData.value.frm_npi != undefined) {
        if (this.frm_UserEnteredData.value.frm_npi != '' && this.frm_UserEnteredData.value.frm_npi.toString().toLowerCase() != 'unknown') {
          this.validationWarning = !this.npiValidated;
        }
        else {
          this.validationWarning = false;
        }
      }
      //to validate
      this.zipCodeValidation(UserEnteredData.frm_zip, 'zip');
      //to validate payzip
      this.zipCodeValidation(UserEnteredData.frm_payzip, 'payzip');

      if (!this.zipValid || !this.payZipValid) {
        this.tabChangeDirection = 'left';
        this.tabChange(tabGroup);
        return;
      }

      //change the names format here
      // this.labDetails.frm_usernameDisplayFormat = this.convertUsernameFormat(this.labDetails.frm_usernameDisplayFormat);
      // this.labDetails.frm_usernameReportDisplayFormat = this.convertUsernameFormat(this.labDetails.frm_usernameReportDisplayFormat);
      //forming casetype data to send to api
      let casetypeObj = [];
      let orderCodeObj = []

      this.filterCaseTypeData.forEach(e => {
        e.caseTypes.forEach(ele => {
          if (ele.checked) {
            if(this.selectedAccount == 'Default Configuration')
            {
              casetypeObj.push(
                {
                  ID: casetypeObj.length + 1, CaseTypeName: ele.casetype,
                  AccessionFormat: "", AlternateFormat: "", Category: e.Category
                });
            }
            else{
              casetypeObj.push(
                {
                  ID: casetypeObj.length + 1, CaseTypeName: ele.casetype,
                  AccessionFormat: "", AlternateFormat: "", Category: e.Category,SourceLabAccountID:this.sourceDeploymentOrgList.LabAccountId,SourceLabOrgID:this.sourceDeploymentOrgList.OrgId
                });
            }
          }
        });

      });
      //return errors

      // Panel validation removed
      // if (this.checkOrderCodeConfig()) {
      //   this.errorAlertSpan = "Please select the Order code and Result code mapping for the case types selected for association. "
      //   return
      // }
      let resultsetID = []
      this.resultSetSavedList.forEach(value => {
        value.forEach(e => {
          if (e.checked && casetypeObj.some(ele => ele.CaseTypeName == e.Casetype)) {
            resultsetID.push({ casetype: e.Casetype, ID: e.ID });
          }
        })
      });
      this.orderCodeSavedList.forEach(value => {
        value.forEach(e => {
          e.ChildCodes.forEach(ele => {
            let childCodeOnly = true;
            ele.ResultCodes.forEach(c => {
              if (c) {
                childCodeOnly = false
                if (e.checked && ele.checked && c.checked && casetypeObj.some(element => element.CaseTypeName == e.Casetype)) {
                  let setID = resultsetID.filter(va => va.casetype == e.Casetype)
                  orderCodeObj.push(
                    {
                      ID: orderCodeObj.length + 1,
                      CaseTypeName: e.Casetype,
                      OrderCodeID: ele.ChildOrderCodeID,
                      ParentOrderCodeID: e.ParentOrderCodeID,
                      ResultCodeID: c.ResultCodeID,
                      CustomHTMLID: setID[0] ? setID[0].ID : null
                    });
                }
              }
            })
            if (childCodeOnly) {
              if (e.checked && ele.checked && casetypeObj.some(element => element.CaseTypeName == e.Casetype)) {
                let setID = resultsetID.filter(va => va.casetype == e.Casetype)
                orderCodeObj.push(
                  {
                    ID: orderCodeObj.length + 1,
                    CaseTypeName: e.Casetype,
                    OrderCodeID: ele.ChildOrderCodeID,
                    ParentOrderCodeID: e.ParentOrderCodeID,
                    ResultCodeID: null,
                    CustomHTMLID: setID[0] ? setID[0].ID : null
                  });
              }
            }
          });
        });
      })
      // this.ngxService.stop();
      let caseTypeInstrumentsArr = [];
      this.instrumentsListSavedList.forEach(va => {
        va.forEach(value => {
          if (casetypeObj.some(element => element.CaseTypeName == value.Casetype) && value.checked) {
            caseTypeInstrumentsArr.push({ ID: caseTypeInstrumentsArr.length + 1, CaseTypeName: value.Casetype, InstrumentName: value.InstrumentName });
          }
        })
      })

      //check if phone and fax are numbers
      //check if atleast one casetype is selected
      if (!(casetypeObj.length > 0)) {
        this.errorAlertSpan = 'Select alteast one casetype';
        this.errorAlertDiv = 'Select alteast one casetype';
        return;
      }
      this.disbaleCreateBtn = true;
      this.selectedCasetypesList = casetypeObj;
      let logoValue = null; // this.logoExists();
      if (this.frm_logo && this.frm_logo != "") {
        let imageSrcString = await this.toDataURL(this.frm_logo)
        imageSrcString = imageSrcString.toString().split(",")[1];
        logoValue = imageSrcString;
      }


      if (!this.enableDeploymentlist) {
        this.labDetails.CaseTypes = casetypeObj
        this.labDetails.CaseTypeOrderCode = orderCodeObj
        this.labDetails.Instruments = caseTypeInstrumentsArr
      }
      else {
          this.labDetails.CaseTypes = []; //passing case type as null for the deployment level
          this.labDetails.CaseTypeOrderCode = []; //passing case type as null for the deployment level
          this.labDetails.Instruments = []; //passing case type as null for the deployment level
          this.caseTypeNames = casetypeObj.map(obj => obj.CaseTypeName);  // Extract CaseTypeName values
          this.commaSeparatedString = this.caseTypeNames.join(', ');// Create a comma-separated string
      }
      this.labDetails.IsSharedSurgicenter = this.labDetails.IsSharedSurgicenter == true ? 1 : 0;
      this.labDetails.FaxEnable = this.labDetails.FaxEnable == true ? 1 : 0;
      this.labDetails.EnableSendOut = this.labDetails.EnableSendOut == true ? 1 : 0;

      if (!this.enableDeploymentlist) {
        if (this.selectedAccount === 'Default Configuration') {
          let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
            disableClose: true,
            autoFocus:false,
            width: '400px',
            data: { header: "", message: "Do you want to copy the selected casetypes related configurations?", notes: "Note- On Click of ''No'', case types will be enabled without configurations.", continue: "Yes", cancel: "No" }
          })
          return dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
              this.labDetails = { ...this.labDetails, ...{ 'CopyOnlyCasetype': 0, 'copyAllConfig':false ,"selectedAccount":this.selectedAccount} }
            } else {
              this.labDetails = { ...this.labDetails, ...{ 'CopyOnlyCasetype': 1 ,'copyAllConfig':false ,"selectedAccount":this.selectedAccount} }
            }
            this.APIMethodToSave(this.labDetails, tabGroup);
          })
        }
        else{
          let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
            disableClose: true,
            autoFocus:false,
            width: '400px',
            data: { header: "", message: "Do you want to copy the selected casetypes related configurations?", notes: "Note- On Click of ''No'', case types will be enabled without configurations.", continue: "Yes", cancel: "No" }
          })
          return dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
              this.labDetails = { ...this.labDetails, ...{ 'CopyOnlyCasetype': 1, 'copyAllConfig':true ,"selectedAccount":this.selectedAccount } }
            } else {
              this.labDetails = { ...this.labDetails, ...{ 'CopyOnlyCasetype': 0 ,'copyAllConfig':false ,"selectedAccount":this.selectedAccount} }
            }
            this.APIMethodToSave(this.labDetails, tabGroup);
          })
        }
      }
      else {
          let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
            disableClose: true,
            width: '400px',
            data: { header: "", message: "Enabling Case Types and copying the configuration for this entity will take a while. You will be notified by email once the process is completed with the respective status.", notes: "", continue: "OK", cancel: "dontshow" }
          })
          return dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
              let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
                disableClose: true,
                autoFocus:false,
                width: '400px',
                data: { header: "", message: "Do you want to copy the selected casetypes related configurations?", notes: "Note- On Click of ''No'', case types will be enabled without configurations.", continue: "Yes", cancel: "No" }
              })
              return dialogRef.afterClosed().toPromise().then(result => {
                if (result) {
                  this.labDetails = { ...this.labDetails, ...{ 'CopyOnlyCasetype': 1 , 'copyAllConfig':false ,"selectedAccount":'Default Configuration' } }
                  this.copyAllConfig=1;
                } else {
                  this.labDetails = { ...this.labDetails, ...{ 'CopyOnlyCasetype': 1 , 'copyAllConfig':false ,"selectedAccount":'Default Configuration' } }
                  this.copyAllConfig=0;
                }
                this.APIMethodToSave(this.labDetails, tabGroup);
              })
            }
          })

      }
    }
  }

  APIMethodToSave(dataToSave, tabGroup) {
    this.ngxService.start();
    this.vaservices.setupNewLab(dataToSave, this.headerDeploymentKey).subscribe(
      async (res: any) => {
        this.ngxService.stop();
        sessionStorage.setItem('deploymentKey', this.headerDeploymentKey)
        if (!res.errors) {
          if (res && res.length > 0) {
            this.UserEnteredDataDiv = true;
            this.resultPage = true;
            // this.frm_UserEnteredData.controls[''].disabled();
            this.disableDeployment = true;
            this._snackBar.open('Lab setup is successful', 'Close');
            this.summaryData.OrgLabAccData = res[0];
            this.summaryData.CasetypeData = res[1].CaseTypeDetails ?? [];
            this.summaryData.UsersData = res[1].UserDetails ?? [];

            if (this.labDetails?.representativedetails) {
              if (res?.[2]?.RepresentativeDetails) {
                this.summaryData['assinedUsers'] = res?.[2]?.RepresentativeDetails;
              } else {
                this.summaryData['assinedUsers'] = 'error'
              }
            }

            if (res[1].FacilityDetails && res[1].FacilityDetails.length > 0) {
              res[1].FacilityDetails.forEach(e => {
                res[1].FacilityOrgDetails.forEach(ele => {
                  if (e.OrganizationID == ele.OrganizationID) {
                    e.FacOrgDetails = ele;
                  }
                });
              });
              // console.log('previewfacdata:' + this.summaryData.FacAccData);
            }
            this.summaryData.FacAccData = res[1].FacilityDetails ?? [];
            this.disbaleCreateBtn = false;
            this.tabChangeDirection = 'right'
            this.tabChange(tabGroup);
            if(this.enableDeploymentlist){
              this.disableCaseType=true;
              let inputParameters = {
                 "SourceAccountId": this.selectedAccount == 'Default Configuration' ? 60006 : this.sourceDeploymentOrgList.LabAccountId,
                 "SourceOrgId": this.selectedAccount == 'Default Configuration' ? 190996 : this.sourceDeploymentOrgList.OrgId,
                 "SourceDeploymentKey":this.frm_UserEnteredData.value.frmDeploymentKey ,
                 "CaseType": this.commaSeparatedString,
                 "DestAccountId": this.summaryData.OrgLabAccData.AccountID,
                 "DestOrgId": this.summaryData.OrgLabAccData.OrganizationID,
                 "CreadtedBy": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid') : -100,
                 "DestAccountName": this.summaryData.OrgLabAccData.AccountName,
                 "DestOrgName": this.summaryData.OrgLabAccData.OrganizationName,
                 "copyAllConfig":this.copyAllConfig==0 ?false :true
              }
            let inputJson =
            {
              "deploymentKey" : this.headerDeploymentKey,
              "jobType": "copycasetypeacrossdeployment",
              "inputParameters": JSON.stringify(inputParameters),
              "emailTo" : localStorage.getItem("UserEmail"),
              "createdBy": localStorage.getItem("UserEmail")
            }
      await this.vaservices.saveVitalAdminScheduledJobDataRepo(inputJson,'configdb').toPromise().then(response=> {
              if(response.content){
                if(response.content.status)
                {
                  this._snackBar.open('Request to Copy CaseType  has been initiated.','Close');
                }
                else {
                  if(response.content.errorReason.toString().toLowerCase().includes('exists'))
                     this._snackBar.open('Request to Copy CaseType  is in the queue');
                  else if (response.content.errorReason.toString().toLowerCase().includes('progress'))
                    this._snackBar.open('Request to Copy CaseType  is being processed');
                  else
                    {
                      this._snackBar.open('Replica to Copy CaseType is already available, it cannot be replicated more than once.');
                    }
                }
              }
              else {
                this._snackBar.open('Request to Copy CaseType failed','Close');
              }
            });
            }
          }
          else {
            this._snackBar.open('Something went wrong. Please try again.', 'Close');
            this.disbaleCreateBtn = false;
          }
        } else {
          // console.log(res.Message);
          this._snackBar.open('Something went wrong. Please try again.', 'Close');
          this.disbaleCreateBtn = false;
        }
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
        this.disbaleCreateBtn = false;
        sessionStorage.setItem('deploymentKey', this.headerDeploymentKey)
        console.error(error);
        this._snackBar.open('Something went wrong. Please try again.', 'Close');
      });
  }
  //#endregion

  //#region to download pdf
  download(fromValue, orgId, casetype) {
    this.downloadReportClicked = true;
    this.downloadReportAllFacility = false;
    if (fromValue == "labDetails") {
      if (orgId && orgId != "") {
        //to download lab data only
        if (casetype == "") {
          this.ngxService.start();
          //if lab data
          this.downloadReportLab = true;
          this.downloadReportFacility = false;
          this.downloadReportCasetype = false;
          let pdf;
          setTimeout(() => {
            let data = document.getElementById('labReportDetails1');
            html2canvas(data).then(canvas => {
              // Few necessary setting options
              let imgWidth = 208;
              let pageHeight = 295;
              let imgHeight = canvas.height * imgWidth / canvas.width;
              let heightLeft = imgHeight;
              pdf = new jsPDF('p', 'mm', 'a4'); //potrait mode,  A4 size page of PDF 210*297mm
              let position = 0;
              //first page image generation
              const contentDataURL1 = canvas.toDataURL('image/png')
              pdf.addImage(contentDataURL1, 'PNG', 0, position, imgWidth, imgHeight);
              //second page image generation
              pdf.addPage();
              let data1 = document.getElementById('labReportDetails2');
              html2canvas(data1).then(canvas1 => {
                // Few necessary setting options
                let imgHeight = canvas1.height * imgWidth / canvas1.width;
                let heightLeft = imgHeight;
                //second page image generation
                const contentDataURL2 = canvas1.toDataURL('image/png');
                // pdf.addPage();
                pdf.addImage(contentDataURL2, 'PNG', 0, position, imgWidth, imgHeight)
                // Generate PDF   and save
                pdf.save('labData.pdf');

                let blob = pdf.output("blob");
                //Getting URL of blob object
                let blobURL = URL.createObjectURL(blob);
                //to open in new window
                this.createNewWindow(blobURL)

                this.downloadReportClicked = false;
                this.ngxService.stop();
              });
            });
          }, 5000);
        }
        //to download individual casetype data associated to the lab
        else {
          //if casetype data
          if (orgId && orgId != "") {
            if (casetype && casetype != '') {
              this.selectedPdfCasetypeDetails = [];
              this.ngxService.start();
              //get casetype data
              sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey);
              this.vaservices.getCasetypePDFData(orgId, this.summaryData.OrgLabAccData.AccountID, casetype).subscribe(res => {
                if (!res.errors) {
                  if (res) {
                    let tempdata: any = [];
                    tempdata.push(res);
                    if (tempdata && tempdata.length > 0) {
                      this.selectedPdfCasetypeDetails = JSON.parse(JSON.stringify(tempdata[0]));
                      //add basic details
                      let basicDetails = {
                        Casetype: casetype,
                        Category: this.selectedCasetypesList.find(e => e.CaseTypeName == casetype).Category
                      }

                      this.selectedPdfCasetypeDetails['basicDetails'] = (basicDetails);

                      //show casetype data
                      this.downloadReportFacility = false
                      this.downloadReportLab = false;
                      this.downloadReportCasetype = true;
                      //code to generate pdf pages
                      let pdf;
                      setTimeout(() => {
                        let data1 = document.getElementById('casetypeReportDetails1');
                        html2canvas(data1).then(canvas1 => {
                          // Few necessary setting options
                          let imgWidth = 208;
                          let pageHeight = 295;
                          let imgHeight = canvas1.height * imgWidth / canvas1.width;
                          let heightLeft = imgHeight;
                          pdf = new jsPDF('p', 'mm', 'a4'); //potrait mode,  A4 size page of PDF 210*297mm
                          let position = 0;
                          //first page image generation
                          const contentDataURL1 = canvas1.toDataURL('image/png')
                          pdf.addImage(contentDataURL1, 'PNG', 0, position, imgWidth, imgHeight);

                          //second page image generation
                          pdf.addPage();
                          let data2 = document.getElementById('casetypeReportDetails2');
                          html2canvas(data2).then(canvas2 => {
                            // Few necessary setting options
                            let imgHeight = canvas2.height * imgWidth / canvas2.width;
                            let heightLeft = imgHeight;
                            //second page image generation
                            const contentDataURL2 = canvas2.toDataURL('image/png');
                            pdf.addImage(contentDataURL2, 'PNG', 0, position, imgWidth, imgHeight);

                            //third page image generation
                            pdf.addPage();
                            let data3 = document.getElementById('casetypeReportDetails3');
                            html2canvas(data3).then(canvas3 => {
                              // Few necessary setting options
                              let imgHeight = canvas3.height * imgWidth / canvas3.width;
                              let heightLeft = imgHeight;
                              //second page image generation
                              const contentDataURL3 = canvas3.toDataURL('image/png');
                              // pdf.addPage();
                              pdf.addImage(contentDataURL3, 'PNG', 0, position, imgWidth, imgHeight);

                              //fourth page image generation
                              pdf.addPage();
                              let data4 = document.getElementById('casetypeReportDetails4');
                              html2canvas(data4).then(canvas4 => {
                                // Few necessary setting options
                                let imgHeight = canvas4.height * imgWidth / canvas4.width;
                                let heightLeft = imgHeight;
                                //second page image generation
                                const contentDataURL4 = canvas4.toDataURL('image/png');
                                // pdf.addPage();
                                pdf.addImage(contentDataURL4, 'PNG', 0, position, imgWidth, imgHeight);

                                //fifth page image generation
                                pdf.addPage();
                                let data5 = document.getElementById('casetypeReportDetails5');
                                html2canvas(data5).then(canvas5 => {
                                  // Few necessary setting options
                                  let imgHeight = canvas5.height * imgWidth / canvas5.width;
                                  let heightLeft = imgHeight;
                                  //second page image generation
                                  const contentDataURL5 = canvas5.toDataURL('image/png');
                                  // pdf.addPage();
                                  pdf.addImage(contentDataURL5, 'PNG', 0, position, imgWidth, imgHeight)

                                  // Generate PDF   and save
                                  pdf.save('casetypelabData.pdf');

                                  let blob = pdf.output("blob");
                                  //Getting URL of blob object
                                  let blobURL = URL.createObjectURL(blob);
                                  //to open in new window
                                  this.createNewWindow(blobURL)

                                  this.downloadReportClicked = false;
                                  this.ngxService.stop();
                                });
                              });
                            });
                          });
                        });
                      }, 5000);
                    }
                    else {
                      this.ngxService.stop();
                      this.downloadReportClicked = false;
                    }
                  } else {
                    this.ngxService.stop();
                    this.downloadReportClicked = false;
                  }
                }
                else {
                  this.ngxService.stop();
                  this.downloadReportClicked = false;
                }
                sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey)
              }, error => {
                this.downloadReportClicked = false;
                this.ngxService.stop();
                sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey)
                console.error(error);
              });

            }
          }
        }
      }
    }
    else if (fromValue == 'facilityDetails') {
      if (orgId && orgId != "") {
        if (this.summaryData.FacAccData && this.summaryData.FacAccData.length > 0) {
          this.selectedPdfFacilityDetails = [];
          this.selectedPdfFacilityDetails1 = [];

          //to download facility data only
          if (casetype == "") {
            this.selectedPdfFacilityDetails = this.summaryData.FacAccData.find(e => e.OrganizationID == orgId);
            //if facility data
            this.downloadReportFacility = true
            this.downloadReportLab = false;
            this.downloadReportCasetype = false;
            this.ngxService.start();
            let pdf;
            setTimeout(() => {
              let imgWidth = 208;
              let pageHeight = 295;
              let position = 0;
              let data = document.getElementById('facilityReportDetails1');
              html2canvas(data).then(canvas => {
                // Few necessary setting options
                let imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;
                pdf = new jsPDF('p', 'mm', 'a4'); //potrait mode,  A4 size page of PDF 210*297mm
                //first page image generation
                const contentDataURL1 = canvas.toDataURL('image/png')
                pdf.addImage(contentDataURL1, 'PNG', 0, position, imgWidth, imgHeight)
                pdf.addPage();
                let data1 = document.getElementById('facilityReportDetails2');
                html2canvas(data1).then(canvas1 => {
                  // Few necessary setting options
                  let imgHeight = canvas1.height * imgWidth / canvas1.width;
                  let heightLeft = imgHeight;
                  //second page image generation
                  const contentDataURL2 = canvas1.toDataURL('image/png');
                  // pdf.addPage();
                  pdf.addImage(contentDataURL2, 'PNG', 0, position, imgWidth, imgHeight)
                  // Generate PDF   and save
                  pdf.save('facilityData.pdf');

                  let blob = pdf.output("blob");
                  //Getting URL of blob object
                  let blobURL = URL.createObjectURL(blob);
                  //to open in new window
                  this.createNewWindow(blobURL)

                  this.downloadReportClicked = false;
                  this.ngxService.stop();
                });
              });
            }, 5000);
          }
          //to download individual casetype data associated to the facility
          else {
            //if casetype data
            if (orgId && orgId != "") {
              if (casetype && casetype != '') {
                this.selectedPdfCasetypeDetails = [];
                this.selectedPdfFacilityDetails1 = [];

                this.selectedPdfFacilityDetails = this.summaryData.FacAccData.find(e => e.OrganizationID == orgId);
                this.ngxService.start();
                //get casetype data
                sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey);
                this.vaservices.getCasetypePDFData(orgId, this.selectedPdfFacilityDetails.FacilityAccountID, casetype).subscribe(res => {
                  if (!res.errors) {
                    if (res) {
                      let tempdata: any = [];
                      tempdata.push(res);
                      if (tempdata && tempdata.length > 0) {
                        this.selectedPdfCasetypeDetails = JSON.parse(JSON.stringify(tempdata[0]));
                        //add basic details
                        let basicDetails = {
                          Casetype: casetype,
                          Category: this.selectedCasetypesList.find(e => e.CaseTypeName == casetype).Category
                        }

                        this.selectedPdfCasetypeDetails['basicDetails'] = (basicDetails);

                        //show casetype data
                        this.downloadReportFacility = false
                        this.downloadReportLab = false;
                        this.downloadReportCasetype = true;
                        //code to generate pdf pages
                        let pdf;
                        setTimeout(() => {
                          let data1 = document.getElementById('casetypeReportDetails1');
                          html2canvas(data1).then(canvas1 => {
                            // Few necessary setting options
                            let imgWidth = 208;
                            let pageHeight = 295;
                            let imgHeight = canvas1.height * imgWidth / canvas1.width;
                            let heightLeft = imgHeight;
                            pdf = new jsPDF('p', 'mm', 'a4'); //potrait mode,  A4 size page of PDF 210*297mm
                            let position = 0;
                            //first page image generation
                            const contentDataURL1 = canvas1.toDataURL('image/png')
                            pdf.addImage(contentDataURL1, 'PNG', 0, position, imgWidth, imgHeight);

                            //second page image generation
                            pdf.addPage();
                            let data2 = document.getElementById('casetypeReportDetails2');
                            html2canvas(data2).then(canvas2 => {
                              // Few necessary setting options
                              let imgHeight = canvas2.height * imgWidth / canvas2.width;
                              let heightLeft = imgHeight;
                              //second page image generation
                              const contentDataURL2 = canvas2.toDataURL('image/png');
                              pdf.addImage(contentDataURL2, 'PNG', 0, position, imgWidth, imgHeight);

                              //third page image generation
                              pdf.addPage();
                              let data3 = document.getElementById('casetypeReportDetails3');
                              html2canvas(data3).then(canvas3 => {
                                // Few necessary setting options
                                let imgHeight = canvas3.height * imgWidth / canvas3.width;
                                let heightLeft = imgHeight;
                                //second page image generation
                                const contentDataURL3 = canvas3.toDataURL('image/png');
                                // pdf.addPage();
                                pdf.addImage(contentDataURL3, 'PNG', 0, position, imgWidth, imgHeight);

                                //fourth page image generation
                                pdf.addPage();
                                let data4 = document.getElementById('casetypeReportDetails4');
                                html2canvas(data4).then(canvas4 => {
                                  // Few necessary setting options
                                  let imgHeight = canvas4.height * imgWidth / canvas4.width;
                                  let heightLeft = imgHeight;
                                  //second page image generation
                                  const contentDataURL4 = canvas4.toDataURL('image/png');
                                  // pdf.addPage();
                                  pdf.addImage(contentDataURL4, 'PNG', 0, position, imgWidth, imgHeight);

                                  //fifth page image generation
                                  pdf.addPage();
                                  let data5 = document.getElementById('casetypeReportDetails5');
                                  html2canvas(data5).then(canvas5 => {
                                    // Few necessary setting options
                                    let imgHeight = canvas5.height * imgWidth / canvas5.width;
                                    let heightLeft = imgHeight;
                                    //second page image generation
                                    const contentDataURL5 = canvas5.toDataURL('image/png');
                                    // pdf.addPage();
                                    pdf.addImage(contentDataURL5, 'PNG', 0, position, imgWidth, imgHeight)

                                    // Generate PDF   and save
                                    pdf.save('casetypelabData.pdf');

                                    let blob = pdf.output("blob");
                                    //Getting URL of blob object
                                    let blobURL = URL.createObjectURL(blob);
                                    //to open in new window
                                    this.createNewWindow(blobURL);

                                    this.downloadReportClicked = false;
                                    this.ngxService.stop();
                                  });
                                });
                              });
                            });
                          });
                        }, 5000);
                      }
                      else {
                        this.ngxService.stop();
                        this.downloadReportClicked = false;
                      }
                    } else {
                      this.ngxService.stop();
                      this.downloadReportClicked = false;
                    }
                  }
                  else {
                    this.ngxService.stop();
                    this.downloadReportClicked = false;
                  }
                  sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey)
                }, error => {
                  this.downloadReportClicked = false;
                  this.ngxService.stop();
                  sessionStorage.setItem('deploymentKey', this.frm_UserEnteredData.value.frmDeploymentKey)
                  console.error(error);
                });

              }
            }
          }
        }
      }
    }
    else if (fromValue == 'allDetails') {
      //Excel Download

      //Lab data
      //to download lab data only
      this.ngxService.start();
      //if lab data
      this.downloadReportLab = true;
      this.downloadReportFacility = false;
      this.downloadReportCasetype = false;
      this.selectedPdfFacilityDetails = [];
      this.selectedPdfFacilityDetails1 = [];
      let pdf;
      this.selectedPdfFacilityDetails = this.summaryData.FacAccData[0];
      this.selectedPdfFacilityDetails1 = this.summaryData.FacAccData[1];
      this.downloadReportFacility = true;
      this.downloadReportAllFacility = true;
      setTimeout(() => {
        //lab
        let data1 = document.getElementById('labReportDetails1');
        html2canvas(data1).then(canvas1 => {
          // Few necessary setting options
          let imgWidth = 208;
          let pageHeight = 295;
          let imgHeight = canvas1.height * imgWidth / canvas1.width;
          let heightLeft = imgHeight;
          pdf = new jsPDF('p', 'mm', 'a4'); //potrait mode,  A4 size page of PDF 210*297mm
          let position = 0;
          //first page image generation
          const contentDataURL1 = canvas1.toDataURL('image/png')
          pdf.addImage(contentDataURL1, 'PNG', 0, position, imgWidth, imgHeight);
          //second page image generation
          pdf.addPage();
          let data2 = document.getElementById('labReportDetails2');
          html2canvas(data2).then(canvas2 => {
            // Few necessary setting options
            let imgHeight = canvas2.height * imgWidth / canvas2.width;
            let heightLeft = imgHeight;

            const contentDataURL2 = canvas2.toDataURL('image/png');
            pdf.addImage(contentDataURL2, 'PNG', 0, position, imgWidth, imgHeight)
            //third page generation
            pdf.addPage();
            //to download facility data only
            // this.summaryData.FacAccData.forEach(element => {

            let data3 = document.getElementById('facilityReportDetails1');
            html2canvas(data3).then(canvas3 => {
              // Few necessary setting options
              let imgHeight = canvas3.height * imgWidth / canvas3.width;
              let heightLeft = imgHeight;

              const contentDataURL3 = canvas3.toDataURL('image/png')
              pdf.addImage(contentDataURL3, 'PNG', 0, position, imgWidth, imgHeight)
              pdf.addPage();
              let data4 = document.getElementById('facilityReportDetails2');
              html2canvas(data4).then(canvas4 => {
                // Few necessary setting options
                let imgHeight = canvas4.height * imgWidth / canvas4.width;
                let heightLeft = imgHeight;

                const contentDataURL4 = canvas4.toDataURL('image/png')
                pdf.addImage(contentDataURL4, 'PNG', 0, position, imgWidth, imgHeight)
                pdf.addPage();
                let data5 = document.getElementById('facilityReportDetails3');
                html2canvas(data5).then(canvas5 => {
                  // Few necessary setting options
                  let imgHeight = canvas5.height * imgWidth / canvas5.width;
                  let heightLeft = imgHeight;

                  const contentDataURL5 = canvas5.toDataURL('image/png')
                  pdf.addImage(contentDataURL5, 'PNG', 0, position, imgWidth, imgHeight);

                  pdf.addPage();
                  let data6 = document.getElementById('facilityReportDetails4');
                  html2canvas(data6).then(canvas6 => {
                    // Few necessary setting options
                    let imgHeight = canvas6.height * imgWidth / canvas6.width;
                    let heightLeft = imgHeight;

                    const contentDataURL6 = canvas6.toDataURL('image/png');
                    pdf.addImage(contentDataURL6, 'PNG', 0, position, imgWidth, imgHeight);

                    // Generate PDF   and save
                    pdf.save('AllData.pdf');

                    let blob = pdf.output("blob");
                    //Getting URL of blob object
                    let blobURL = URL.createObjectURL(blob);
                    //to open in new window
                    this.createNewWindow(blobURL);

                    this.downloadReportClicked = false;
                    this.ngxService.stop();
                  });
                });
              });
            });
          });
        });
      }, 10000);
    }
  }

  //to crop the pdf sized image
  resizeAndSquareImage(imageUrl: string, targetCanvas: HTMLCanvasElement) {
    // this image will hold our source image data
    const inputImage: HTMLImageElement = new Image();
    // we want to wait for our image to load
    let result = inputImage.onload = () => {
      const imgSize: number = Math.min(inputImage.width, inputImage.height);
      const left: number = (inputImage.width - imgSize) / 2;
      const top: number = (inputImage.height - imgSize) / 2;
      const ctx: CanvasRenderingContext2D = targetCanvas.getContext("2d");
      ctx.drawImage(inputImage, left, top, imgSize, imgSize, 0, 0, targetCanvas.width, targetCanvas.height);
      return targetCanvas;
    };
    inputImage.src = imageUrl;
    return result;
  }

  //to open pdf in new windown
  createNewWindow(url) {
    const finalURL = url;
    window.open(finalURL, '_blank',
      'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 '
    );
  }
  //#endregion

  public tabChange(tabGroup: MatTabGroup) {
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    // direction = 'right';
    const tabCount = tabGroup._tabs.length;
    // $('#setupNewFacilityModal').appendTo("body").modal('show');
    if (this.tabChangeDirection == 'left' && this.submitted == false) { this.patchLabDetails() }
    tabGroup.selectedIndex = this.tabChangeDirection == 'right' ? (tabGroup.selectedIndex + 1) % tabCount : (tabGroup.selectedIndex - 1) % tabCount
  }

  enableTab(labDetailsTab: boolean, caseTypeTab: boolean, summaryTab: boolean) {
    this.hideLabDetailsTab = !labDetailsTab;
    this.hideCaseTypeTab = !caseTypeTab;
    this.hideSummaryTab = !summaryTab;
  }

  async saveLabDetails(compendiumGroup: MatTabGroup, labDetails, stayInTab?, isSave?) {
    this.saveClicked = new Date();
    this.ValidateNPIandgetData(this.frm_UserEnteredData.value.frm_npi)
    this.errorAlertDiv = "";
    this.errorAlertSpan = "";
    //for fields validation check before submit
    this.submitted = true;
    this.selectedData = false;
    //NPI validation
    if (!this.frm_UserEnteredData.value.frmDeploymentKey || this.frm_UserEnteredData.value.frmDeploymentKey == 'none') {
      this.selectedData = true;
      this.formcontrol['frmDeploymentKey'].setErrors({ 'incorrect': true });
      this._snackBar.open('Please select a deployment!', 'Close')
      return
    }

    this.validationWarning = false;
    this.NPIWarning = !this.frm_UserEnteredData.value.frm_npi ? true : false

    if (this.frm_UserEnteredData.value.frm_npi != null && this.frm_UserEnteredData.value.frm_npi != undefined) {
      if (this.frm_UserEnteredData.value.frm_npi != '' && this.frm_UserEnteredData.value.frm_npi.toString().toLowerCase() != 'unknown') {
        this.validationWarning = !this.npiValidated;
      }
      else {
        this.validationWarning = false;
      }
    }

    //to validate zipcode
    this.zipCodeValidation(labDetails.frm_zip, 'zip');
    //to validate payzip
    this.zipCodeValidation(labDetails.frm_payzip, 'payzip');


    if (!this.zipValid || !this.payZipValid) {
      return;
    }

    //* fields validation
    if (!labDetails.frm_name || labDetails.frm_name.trim() == "" ||
      !labDetails.frm_displayname || labDetails.frm_displayname.trim() == "" ||
      !labDetails.frm_shortname || labDetails.frm_shortname.trim() == "" ||
      !labDetails.frm_mnemonic || labDetails.frm_mnemonic.trim() == "") {
      this._snackBar.open('Please enter mandatory fields', 'Close');
      return;
    }

    if (this.validationWarning || this.NPIWarning) {
      this._snackBar.open('Enter Valid NPI details', 'Close');
      return;
    }
    //check if phone and fax are numbers
    else if ((this.frm_UserEnteredData.controls.frm_fax && this.frm_UserEnteredData.controls.frm_fax.value != "") || (this.frm_UserEnteredData.controls.frm_phone && this.frm_UserEnteredData.controls.frm_phone.value != "")) {
      if (this.frm_UserEnteredData.controls.frm_fax.status.toString().toLowerCase() == 'invalid') {
        this._snackBar.open('Enter Valid Fax', 'Close');
        return;
      }
      if (this.frm_UserEnteredData.controls.frm_phone.status.toString().toLowerCase() == 'invalid') {
        this._snackBar.open('Enter Valid Phone', 'Close');
        return;
      }
    }
    //zip field validation
    else if (!this.zipValid || !this.payZipValid) {
      this._snackBar.open('Enter valid zip / payment zip details', 'Close');
      return;
    }

    if (this.NPIWarning) {
      this.formcontrol['frm_npi'].setErrors({ 'incorrect': true });
      this._snackBar.open('Please enter valid NPI!', 'Close');
      return;
    }
    else if (this.validationWarning) {
      this.formcontrol['frm_npi'].setErrors({ 'incorrect': true });
      this._snackBar.open('Please Validate the NPI', 'Close');
      return;
    }
    if(labDetails.frm_state){
      let temp_frm_state = this.StateList.find(va => va.item == labDetails.frm_state || va.itemValue == labDetails.frm_state )?.itemValue;
      if (!temp_frm_state) {
        this._snackBar.open("Please Select Valid State", "Close");
        this.ngxService.stop()
        return;
      }
      else{
        labDetails.frm_state = temp_frm_state;
      }
    }
    if(labDetails.frm_paystate){
      let temp_Frm_paystate = this.StateList.find(va => va.item == labDetails.frm_paystate || va.itemValue == labDetails.frm_paystate )?.itemValue;
      if (!temp_Frm_paystate) {
        this._snackBar.open("Please Select Valid Payment State", "Close");
        this.ngxService.stop()
        return;
      }
      else{
        labDetails.frm_paystate = temp_Frm_paystate;
      }
    }

    if(labDetails.frm_timezone){
      let frm_timezone = this.Timezones.find(va => va.item == labDetails.frm_timezone)?.item;
      if (!frm_timezone) {
        this._snackBar.open("Please Select Valid TimeZone", "Close");
        this.ngxService.stop()
        return;
      }
    }
    this.ngxService.start();
    this.disbaleCreateBtn = true;

    let logoValue = null; // this.logoExists();
    if (this.frm_logo && this.frm_logo != "") {
      let imageSrcString = await this.toDataURL(this.frm_logo)
      imageSrcString = imageSrcString.toString().split(",")[1];
      logoValue = imageSrcString;
    }
    let statedisplayname = this.StateList.find(e => e.itemValue === labDetails.frm_state)
    if (!this.validateOrgName(labDetails.frm_name) || isSave) {
      this.labDetails = {
        OrganizationName: labDetails.frm_name.trim(),
        DisplayName: labDetails.frm_displayname.trim(),
        ShortName: labDetails.frm_shortname.trim(),
        Address1: labDetails.frm_Address1,
        Address2: labDetails.frm_Address2,
        City: labDetails.frm_city,
        State: labDetails.frm_state != '' ? labDetails.frm_state : null,
        Zip: labDetails.frm_zip,
        Country: labDetails.frm_country,
        npi: labDetails.frm_npi && labDetails.frm_npi != '' ? labDetails.frm_npi : 'UNKNOWN',
        phone: this.commonService.changeAdminContactPhone(labDetails?.frm_phone),
        Fax: this.commonService.changeAdminFaxNumber(labDetails.frm_fax),
        clia: labDetails.frm_clia,
        EIN: labDetails.frm_ein,
        Mnemonic: labDetails.frm_mnemonic,
        BatchingMnemonic: labDetails.frm_batchingmnemonic,
        TaxonomyCode: labDetails.frm_taxonomycode,
        SupportID: labDetails.frm_supportid,
        ClaimOption: labDetails.frm_claimoptions,
        Claimmnemonic: labDetails.frm_claimmnemonics,
        CLPOL: labDetails.frm_clpol,
        ReportData: labDetails.frm_reportdata,
        StateLicenseNumber: labDetails.frm_statelicensenumber,
        UsernameDisplayFormat: labDetails.frm_usernameDisplayFormat ? labDetails.frm_usernameDisplayFormat : null,
        UsernameReportFormat: labDetails.frm_usernameReportDisplayFormat ? labDetails.frm_usernameReportDisplayFormat : null,
        CollectionDateFormat: labDetails.frm_collectiondateformat,
        PackageDateFormat: labDetails.frm_packagedateformat,
        ETADateFormat: labDetails.frm_etadateformat,
        IsSharedSurgicenter: labDetails.frm_sharedsurgicalcenter,
        FaxEnable: labDetails.frm_enabledfax,
        EnableSendOut: labDetails.frm_enabledsendout,
        PayAddress1: labDetails.frm_payaddress1,
        PayAddress2: labDetails.frm_payaddress2,
        PayCity: labDetails.frm_paycity,
        PayState: labDetails.frm_paystate,
        PayZip: labDetails.frm_payzip,
        IsCommercialLab: this.frm_UserEnteredData.value.selectLabType,
        AccountLevelAccessionFormat: null,
        AccountLevelAlternateFormat: null,
        Logo: logoValue ?? null,
        CreatedBy: sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid') : -100,
        mdmproviderid: this.mdmProviderId,
        sessiondata: {
          applicationdomain: environment.client,
          loggedinuserorgname: '',
          loggedinuserorgguid: '',
          loggedinuserordid: '',
          accountorgguid: '',
        },
        representativedetails: this.selectedRepresentativesList?.length ? this.selectedRepresentativesList : null,
        StateDisplayName : statedisplayname ? statedisplayname.formattedstate : null,
        Timezone: labDetails.frm_timezone
      }
      // let deployment = this.frm_UserEnteredData.value.frmDeploymentKey
      // // this.frm_UserEnteredData.get('frmDeploymentKey').disable();
      // this.frm_UserEnteredData.patchValue({
      //   frmDeploymentKey : deployment
      // })
      this.disableDeployment = true;
      this.selectedRepresentativesListClone = structuredClone(this.selectedRepresentativesList)
      this.ngxService.stop();
      this._snackBar.open('Lab details saved successfully.', 'Close');
      this.submitted = false;
      stayInTab ? null : this.tabChange(compendiumGroup);
    } else {
      this.ngxService.stop();
      const popUpHeader = "Confirmation";
      const popUpMessage = `There is already another 'Laboratory' with the same name. Do you wish to create again?`;
      const continueBtn = "Yes"
      const dialogRef = this.opendialog.openDialog(popUpHeader, popUpMessage, '', continueBtn, "No")
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.saveLabDetails(compendiumGroup, labDetails, stayInTab, res)
        }
      })

    }

  }
  openAlertModal(tabGroup, direction) {
    this.tabChangeDirection = direction
    if ((direction == 'right' && this.checkSavedLabData() && this.checkRepresentativeData()) || (direction == 'left' && this.checkCaseTypeData())) {
      this.tabChange(tabGroup);
      return;
    }
    this.saveAndProceed = false
    this.proceedWithoutSaving = false;
    this.alertPopUp = true
  }

  saveAndOrProceed(action: string, compendiumGroup: MatTabGroup, isSave?) {
    this.alertPopUp = false;
    if (this.frm_UserEnteredData.value.frmDeploymentKey) {
      if (!action.match(/dont/i)) {
        if (compendiumGroup.selectedIndex == 0) {
          this.saveLabDetails(compendiumGroup, this.frm_UserEnteredData.value);
        }
        else {
          if (this.checkOrderCodeConfig()) {
            this._snackBar.open("Please manage panels for selected casetypes before saving!", "Close");
            return
          }
          this.categoryListCopy = cloneDeep(this.categoryList);
          this.tabChange(compendiumGroup)
        }
      }
      else {
        if (compendiumGroup.selectedIndex == 1) {
          this.categoryList = cloneDeep(this.categoryListCopy);
        }
        this.tabChange(compendiumGroup)
      }
    }
    else {
      this._snackBar.open('Please select a deployment!', 'Close')
    }
  }

  validateOrgName(tempOrgName) {
    let duplicateFound = false;
    duplicateFound = this.allOrglist?.some(va => va.LabName?.trim().toLowerCase() === tempOrgName?.trim().toLowerCase());
    return duplicateFound;
  }

  patchLabDetails() {
    if (Object.keys(this.labDetails).length == 0) {
      this.frm_UserEnteredData.patchValue({
        frm_name: '',
        frm_displayname: '',
        frm_shortname: '',
        frm_npi: 'UNKNOWN',
        frm_clia: '',
        frm_ein: '',
        frm_Address1: '',
        frm_Address2: '',
        frm_zip: '',//[Validators.pattern('\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$')]
        frm_phone: '', // ['', [Validators.pattern('^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$')]],
        frm_state: '',
        frm_city: '',
        frm_country: '',
        frm_fax: '',
        frm_mnemonic: '',
        frm_batchingmnemonic: '',
        frm_taxonomycode: '',
        frm_supportid: '',
        frm_claimoptions: 'Track Claim',
        frm_claimmnemonics: '',
        frm_clpol: '',
        frm_reportdata: '',
        frm_enabledfax: 0,
        frm_sharedsurgicalcenter: 0,
        frm_enabledsendout: 0,
        frm_statelicensenumber: '',
        frm_usernameDisplayFormat: '',
        frm_usernameReportDisplayFormat: '',
        frm_collectiondateformat: 'mm-dd-yyyy',
        frm_packagedateformat: '',
        frm_etadateformat: '',
        frm_payaddress1: '',
        frm_payaddress2: '',
        frm_paycity: '',
        frm_paystate: '',
        frm_payzip: '',
        frm_timezone: ''
      });
      this.onRemove();
    }
    else {
      const { OrganizationName, DisplayName, ShortName, Address1, Address2, City, State, Zip, Country, npi, phone, Fax, clia, EIN, Mnemonic, BatchingMnemonic, TaxonomyCode, SupportID, ClaimOption, Claimmnemonic, CLPOL, ReportData, StateLicenseNumber, UsernameDisplayFormat, UsernameReportFormat, CollectionDateFormat, PackageDateFormat, ETADateFormat, IsSharedSurgicenter, FaxEnable, EnableSendOut, PayAddress1, PayAddress2, PayCity, PayState, PayZip, IsCommercialLab, AccountLevelAccessionFormat, AccountLevelAlternateFormat, Logo, CreatedBy, TimeZone } = this.labDetails
      this.frm_UserEnteredData.patchValue({
        frm_name: OrganizationName ? OrganizationName : '',
        frm_displayname: DisplayName ? DisplayName : '',
        frm_shortname: ShortName ? ShortName : '',
        frm_npi: npi ? npi : 'UNKNOWN',
        frm_clia: clia ? clia : '',
        frm_ein: EIN ? EIN : '',
        frm_Address1: Address1 ? Address1 : "",
        frm_Address2: Address2 ? Address2 : "",
        frm_zip: Zip ? Zip : '',//[Validators.pattern('\d{3}[\ \.\-]?\d{3}[\ \.\-]?\d{4}$')]
        frm_phone: phone ? this.commonService.changeAdminContactPhone(phone) : '', // ['', [Validators.pattern('^[2-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$')]],
        frm_state: this.StateList.find(va=> va.itemValue == State )?.item ?? '',
        frm_city: City ? City : "",
        frm_country: Country ? Country : "",
        frm_fax: Fax ? this.commonService.changeAdminFaxNumber(Fax) : '',
        frm_mnemonic: Mnemonic ? Mnemonic.toUpperCase() : '',
        frm_batchingmnemonic: BatchingMnemonic ? BatchingMnemonic : "",
        frm_taxonomycode: TaxonomyCode ? TaxonomyCode : "",
        frm_supportid: SupportID ? SupportID : "",
        frm_claimoptions: ClaimOption ? ClaimOption : 'Track Claim',
        frm_claimmnemonics: Claimmnemonic ? Claimmnemonic : "",
        frm_clpol: CLPOL ? CLPOL : "",
        frm_reportdata: ReportData ? ReportData : '',
        frm_enabledfax: FaxEnable ? FaxEnable : 0,
        frm_sharedsurgicalcenter: IsSharedSurgicenter ? IsSharedSurgicenter : 0,
        frm_enabledsendout: EnableSendOut ? EnableSendOut : 0,
        frm_statelicensenumber: StateLicenseNumber ? StateLicenseNumber : "",
        frm_usernameDisplayFormat: UsernameDisplayFormat ? UsernameDisplayFormat : '',
        frm_usernameReportDisplayFormat: UsernameReportFormat ? UsernameReportFormat : '',
        frm_collectiondateformat: CollectionDateFormat ? CollectionDateFormat : 'mm-dd-yyyy',
        frm_packagedateformat: PackageDateFormat ? PackageDateFormat : '',
        frm_etadateformat: ETADateFormat ? ETADateFormat : '',
        frm_payaddress1: PayAddress1 ? PayAddress1 : "",
        frm_payaddress2: PayAddress2 ? PayAddress2 : "",
        frm_paycity: PayCity ? PayCity : "",
        frm_paystate: PayState ? PayState : '',
        frm_payzip: PayZip ? PayZip : '',
        frm_timezone: TimeZone ? TimeZone : ''
      });
    }
    this.selectedRepresentativesList = structuredClone(this.selectedRepresentativesListClone)
    this.captureNPI(this.frm_UserEnteredData.value.frm_npi)

  }

  filterCasetypes(caseType: string) {
    this.searchCaseType = caseType;
    this.categoryList = cloneDeep(this.filterCaseTypeData)

    if (!caseType) {
      return this.categoryList
    }
    this.categoryList.forEach((item: any) => {
      item.caseTypes = item.caseTypes.filter((res: any) => {
        return res.casetype.toString().toLowerCase().includes(caseType.toString().toLowerCase());
      })
    })

    this.categoryList = this.categoryList.filter((item) => {
      return item.caseTypes.length > 0
    })
    return this.categoryList;
  }

  showNoCaseTypeSelected() {
    let casetypeChecked = true
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].checked) {
        casetypeChecked = false;
      }
    }
    return casetypeChecked
  }

  checkSavedLabData() {
    this.convertValuesToEmpty(this.labDetails)
    this.convertValuesToEmpty(this.frm_UserEnteredData.value)
    this.frm_UserEnteredData.patchValue(this.frm_UserEnteredData.value)

    let data = [];
    data.push(this.labDetails);
    data.push(this.frm_UserEnteredData.value);

    const { OrganizationName, DisplayName, ShortName, Address1, Address2, City, State, Zip, Country, npi, phone, Fax, clia, EIN, Mnemonic, BatchingMnemonic, TaxonomyCode, SupportID, ClaimOption, Claimmnemonic, CLPOL, ReportData, StateLicenseNumber, UsernameDisplayFormat,
      UsernameReportFormat, CollectionDateFormat, PackageDateFormat, ETADateFormat, IsSharedSurgicenter, FaxEnable, EnableSendOut, PayAddress1, PayAddress2, PayCity, PayState, PayZip, TimeZone } = this.labDetails
    const {
      frmDeploymentKey,
      selectLabType,
      frm_name,
      frm_displayname,
      frm_shortname,
      frm_npi,
      frm_clia,
      frm_ein,
      frm_Address1,
      frm_Address2,
      frm_zip,
      frm_phone,
      frm_state,
      frm_city,
      frm_country,
      frm_fax,
      frm_mnemonic,
      frm_batchingmnemonic,
      frm_taxonomycode,
      frm_supportid,
      frm_claimoptions,
      frm_claimmnemonics,
      frm_clpol,
      frm_reportdata,
      frm_enabledfax,
      frm_sharedsurgicalcenter,
      frm_enabledsendout,
      frm_statelicensenumber,
      frm_usernameDisplayFormat,
      frm_usernameReportDisplayFormat,
      frm_collectiondateformat,
      frm_packagedateformat,
      frm_etadateformat,
      frm_payaddress1,
      frm_payaddress2,
      frm_paycity,
      frm_paystate,
      frm_payzip,
      frm_timezone
    } = this.frm_UserEnteredData.value

    if (OrganizationName == frm_name && DisplayName == frm_displayname && ShortName == frm_shortname && Address1 == frm_Address1 && Address2 == frm_Address2 && City == frm_city && State == frm_state &&
      Zip == frm_zip && Country == frm_country && City == frm_city && npi == frm_npi && phone == frm_phone && Fax == frm_fax && clia == frm_clia && EIN == frm_ein && Mnemonic == frm_mnemonic && BatchingMnemonic == frm_batchingmnemonic &&
      TaxonomyCode == frm_taxonomycode && SupportID == frm_supportid && ClaimOption == frm_claimoptions && Claimmnemonic == frm_claimmnemonics && CLPOL == frm_clpol && ReportData == frm_reportdata && StateLicenseNumber == frm_statelicensenumber && UsernameDisplayFormat == frm_usernameDisplayFormat
      && UsernameReportFormat == frm_usernameReportDisplayFormat && CollectionDateFormat == frm_collectiondateformat && PackageDateFormat == frm_packagedateformat && ETADateFormat == frm_etadateformat && PayAddress1 == frm_payaddress1 && PayAddress2 == frm_payaddress2 && PayCity == frm_paycity && PayState == frm_paystate && PayZip == frm_payzip
      && IsSharedSurgicenter == frm_sharedsurgicalcenter && FaxEnable == frm_enabledfax && EnableSendOut == frm_enabledsendout && TimeZone == frm_timezone
    ) {
      return true
    }
    else {
      return false;
    }
  }

  convertValuesToEmpty(object) {
    for (let [key, value] of Object.entries(object)) {
      if (value == null || value == undefined) {
        value = ""
      }
      object[key] = value
    }
  }

  checkCasetypeEnabled(caseTypes) {
    for (let i = 0; i < caseTypes.length; i++) {
      if (caseTypes[i].checked) {
        return true
      }
    }
    return false;
  }

  filterLeafNode(node: OrderCodes): boolean {
    if (!this.searchString) {
      return false
    }
    return node.name.toLowerCase()
      .indexOf(this.searchString?.toLowerCase()) === -1
  }

  filterParentNode(node: OrderCodes): boolean {

    if (
      !this.searchString ||
      node.name.toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
      -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.name
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }
  filterChanged(filterText: string) {
    // let treeArr =
    // this.filterOrderCodes(filterText);
    // let  = this.createTreeStructure();
    // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    // this.dataSource.data = this.buildFileTree(treeArr, 0);
    // this.selectReleventData(this.treeControl.dataNodes)
    // this.orderCodeData = treeArr
    // this.dataSource.data = treeArr;
    // if(filterText)
    // {
    //   this.treeControl.expandAll();
    // } else {
    //   this.treeControl.collapseAll();
    // this.treeControl.expandAll();
    // }
  }

  getOrderCodes(value) {
    this.caseType = value
    let constraints = {
      casetype: value,
      dbname: this.frm_UserEnteredData.value.frmDeploymentKey,
      LabAccountID: this.defaultAccId,
      LabOrgID: this.defaultOrgId
    }

    if (this.treeStructures[this.caseType] && this.treeStructures[this.caseType].length > 0) {
      this.ngxService.start('selected panels');

      //Patch existing case type data for the casetype
      this.dataSource.data = this.treeStructures[this.caseType];

      //Patch existing result set data for the casetype
      this.resultSetList = this.savedResults[this.caseType] ? this.savedResults[this.caseType] : [];

      //Patch existing instruments data for the casetype
      this.instrumentsList = this.savedInstruments ? this.savedInstruments[this.caseType] : [];

      $('#manageOrderCode').modal('show');
      this.ngxService.stop('selected panels');

    }
    else {
      this.orderCodeListCopy = [];
      this.orderCodeList = [];
      this.resultList = [];
      this.resultSetList = [];
      this.resultSetListCopy = [];
      this.instrumentsList = [];
      this.instrumentsListCopy = []
      this.ngxService.start();
      this.vaservices.getOrderCodes(constraints).subscribe(res => {
        this.ngxService.stop();
        if (res) {
          res.Table.forEach(e => {
            if (!this.orderCodeList.some(element => element.ParentOrderCodeName == e.ParentOrderCodename)) {
              this.orderCodeList.push({ ParentOrderCodeName: e.ParentOrderCodename, Casetype: value, ParentOrderCodeID: e.ParentOrderCodeID, ChildCodes: [], checked: true })
            }

            for (let i = 0; i < this.orderCodeList.length; i++) {
              if (!this.orderCodeList[i].ChildCodes.some(element => element.ChildCodeName == e.ChildOrderCodeName)) {
                if (this.orderCodeList[i].ParentOrderCodeName == e.ParentOrderCodename) {
                  this.orderCodeList[i].ChildCodes.push({ ChildCodeName: e.ChildOrderCodeName, ChildOrderCodeID: e.OrderCodeID, ResultCodes: [], checked: true });
                  break;
                }
              }
            }

            for (let i = 0; i < this.orderCodeList.length; i++) {
              for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++)
                if (this.orderCodeList[i].ParentOrderCodeName == e.ParentOrderCodename && this.orderCodeList[i].ChildCodes[j].ChildCodeName == e.ChildOrderCodeName && e.ResultName) {
                  this.orderCodeList[i].ChildCodes[j].ResultCodes.push({ ResultName: e.ResultName, ResultCode: e.ResultName, ParentOrderCodeName: e.ParentOrderCodename, ResultCodeID: e.Resultcodeid, checked: true });
                  break;
                }
            }
          });

          res.Table1.forEach(e => {
            if (e.ID) {
              this.resultSetList.push({ Casetype: value, ResultObjectName: e.ObjectName, ID: e.ID, checked: false })
            }
          })

          res.Table2.forEach(e => {
            this.instrumentsList.push({ InstrumentName: e.InstrumentName, Casetype: value, checked: true })
          })

          if (this.resultSetList.length > 0) {
            this.resultSetList[0].checked = true;
          }
          this.checkAllInstruments = true;
          let treeArr = this.createTreeStructure();
          // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
          // this.dataSource.data = this.buildFileTree(treeArr, 0);
          this.orderCodeData = treeArr
          this.dataSource.data = treeArr
          Object.keys(this.dataSource.data).forEach((x) => {
            this.setParent(this.dataSource.data[x], null);
          });
          let event = new MatCheckboxChange()
          this.dataSource.data.forEach((nodes) => {
            this.todoItemSelectionToggle(true, nodes);
          })
          // this.checkAllNodes(event, true);
          this.orderCodeListCopy = cloneDeep(this.orderCodeList);
          this.resultSetListCopy = cloneDeep(this.resultSetList);
          this.instrumentsListCopy = cloneDeep(this.instrumentsList);
          $('#manageOrderCode').modal('show');
        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }

  createTreeStructure(): any {
    let treeArray = []
    if (!this.orderCodeSavedList.some(va => va.some(value => value.Casetype == this.caseType))) {
      for (let i = 0; i < this.orderCodeList.length; i++) {
        let treeObj = {};
        if (!Object.keys(treeObj).includes(this.orderCodeList[i].ParentOrderCodeName)) {
          var value = this.orderCodeList[i].ParentOrderCodeName;
          treeArray.push({ name: value, children: [] });
        }
      }
      for (let k = 0; k < treeArray.length; k++) {
        let childArray = [];
        for (let i = 0; i < this.orderCodeList.length; i++) {
          for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++) {
            if (treeArray[k].name == this.orderCodeList[i].ParentOrderCodeName) {
              var value = this.orderCodeList[i].ChildCodes[j].ChildCodeName
              childArray.push({ name: value, parentName: this.orderCodeList[i].ParentOrderCodeName, children: [] });
            }
          }
        }
        treeArray[k]["children"] = childArray
      }

      for (let i = 0; i < treeArray.length; i++) {
        for (let j = 0; j < treeArray[i].children.length; j++) {
          let childArray = [];

          for (let k = 0; k < this.orderCodeList.length; k++) {
            for (let l = 0; l < this.orderCodeList[k].ChildCodes.length; l++) {
              for (let m = 0; m < this.orderCodeList[k].ChildCodes[l].ResultCodes.length; m++)
                if (treeArray[i].name == this.orderCodeList[k].ParentOrderCodeName && treeArray[i].children[j].name == this.orderCodeList[k].ChildCodes[l].ChildCodeName) {
                  var value = this.orderCodeList[k].ChildCodes[l].ResultCodes[m].ResultName
                  childArray.push({ name: value, parentName: this.orderCodeList[i].ParentOrderCodeName, grandParentCodeName: this.orderCodeList[k].ChildCodes[l].ChildCodeName, children: [] });
                }
            }
            // treeArray[i].children[k].children = []
            treeArray[i].children[j].children = childArray;
          }

        }
      }
    }
    else {
      for (let j = 0; j < this.orderCodeSavedList.length; j++) {
        for (let i = 0; i < this.orderCodeSavedList[j].length; i++) {
          if (this.orderCodeSavedList[j].some(va => va.Casetype == this.caseType)) {
            let treeObj = {};
            if (!Object.keys(treeObj).includes(this.orderCodeSavedList[j][i].ParentOrderCodeName)) {
              var value = this.orderCodeSavedList[j][i].ParentOrderCodeName;
              treeArray.push({ name: value, children: [] });
            }
          }
        }
      }
      for (let k = 0; k < treeArray.length; k++) {
        let childArray = [];
        for (let i = 0; i < this.orderCodeSavedList.length; i++) {
          for (let j = 0; j < this.orderCodeSavedList[i].length; j++) {
            if (this.orderCodeSavedList[i].some(va => va.Casetype == this.caseType)) {
              if (treeArray[k].name == this.orderCodeSavedList[i][j].ParentOrderCodeName) {
                for (let k = 0; k < this.orderCodeSavedList[i][j].ChildCodes.length; k++) {
                  var value = this.orderCodeSavedList[i][j].ChildCodes[k].ChildCodeName
                  childArray.push({ name: value, parentName: this.orderCodeSavedList[i][j].ParentOrderCodeName, children: [] });
                }
              }
            }
          }
        }
        treeArray[k]["children"] = childArray
      }

      for (let i = 0; i < treeArray.length; i++) {
        for (let j = 0; j < treeArray[i].children.length; j++) {
          let childArray = [];

          for (let k = 0; k < this.orderCodeSavedList.length; k++) {
            if (this.orderCodeSavedList.some(va => va.some(value => value.Casetype == this.caseType))) {
              for (let l = 0; l < this.orderCodeSavedList[k].length; l++) {
                for (let m = 0; m < this.orderCodeSavedList[k][l].ChildCodes.length; m++)
                  if (treeArray[i].name == this.orderCodeSavedList[k][l].ParentOrderCodeName && treeArray[i].children[j].name == this.orderCodeSavedList[k][l].ChildCodes[m].ChildCodeName) {
                    for (let n = 0; n < this.orderCodeSavedList[k][l].ChildCodes[m].ResultCodes.length; n++) {
                      var value = this.orderCodeSavedList[k][l].ChildCodes[m].ResultCodes[n].ResultName
                      childArray.push({ name: value, parentName: this.orderCodeSavedList[k][l].ParentOrderCodeName, grandParentCodeName: this.orderCodeSavedList[k][l].ChildCodes[m].ChildCodeName, children: [] });
                    }
                  }
              }
              // treeArray[i].children[k].children = []
              treeArray[i].children[j].children = childArray;
            }
          }

        }
      }
    }
    return treeArray;
  }

  checkCaseTypeData() {
    return (JSON.stringify(this.categoryList) === JSON.stringify(this.categoryListCopy))
  }

  checkRepresentativeData() {
    return (JSON.stringify(this.selectedRepresentativesList) === JSON.stringify(this.selectedRepresentativesListClone))
  }

  checkOrderCodeConfig() {
    let count = 0
    for (let i = 0; i < this.filterCaseTypeData.length; i++) {
      if (this.filterCaseTypeData[i].checked) {
        for (let j = 0; j < this.filterCaseTypeData[i].caseTypes.length; j++) {
          if (this.filterCaseTypeData[i].caseTypes[j].Iscompendium && this.filterCaseTypeData[i].caseTypes[j].checked) {
            if (this.filterCaseTypeData[i].caseTypes[j].checked) {
              let casetype = this.filterCaseTypeData[i].caseTypes[j].casetype;
              !this.orderCodeSavedList.some(va => va.some(value => value.Casetype == casetype)) ? count++ : null
            }
          }
        }
      }
    }
    return count > 0
  }

  getCaseTypeList(value: String) {
    let casetypeList = [];
    for (let i = 0; i < this.categoryList.length; i++) {
      for (let j = 0; j < this.categoryList[i].caseTypes.length; j++) {
        casetypeList.push(this.categoryList[i].caseTypes[j].casetype);
      }
    }
    if (!value) {
      return casetypeList;
    }
    return casetypeList.filter(va => va.toString().toLowerCase().includes(value.toLowerCase()))
  }


  equateCaseTypeValues() {
    for (let i = 0; i < this.filterCaseTypeData.length; i++) {
      for (let j = 0; j < this.categoryList.length; j++) {
        if (this.filterCaseTypeData[i].Category == this.categoryList[j].Category) {
          if (this.categoryList[j].checked) {
            this.filterCaseTypeData[i].checked = true;
          }
          else {
            this.filterCaseTypeData[i].checked = false;
          }
        }
        for (let k = 0; k < this.filterCaseTypeData[i].caseTypes.length; k++) {
          for (let l = 0; l < this.categoryList[j].caseTypes.length; l++) {
            if (this.filterCaseTypeData[i].caseTypes[k].casetype == this.categoryList[j].caseTypes[l].casetype) {
              if (this.categoryList[j].caseTypes[l].checked) {
                this.filterCaseTypeData[i].caseTypes[k].checked = true;
              }
              else {
                this.filterCaseTypeData[i].caseTypes[k].checked = false;
              }
            }
          }
        }
      }
    }
  }

  orderCodePopupClose() {
    $('#manageOrderCode').modal('hide');
    this.orderCodeList = cloneDeep(this.orderCodeListCopy);
    this.resultSetList = cloneDeep(this.resultSetListCopy);
    this.instrumentsList = cloneDeep(this.instrumentsListCopy);
    this.orderPopup = false
    this.searchString = ''
    this.closedModal = true;
    $('#genericModal').modal('show');
  }


  checkResultCode(ParentOrderCodeName, ChildCodeName, ResultName) {
    for (let i = 0; i < this.orderCodeList.length; i++) {
      if (this.orderCodeList[i].ParentOrderCodeName == ParentOrderCodeName) {
        for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++) {
          if (this.orderCodeList[i].ChildCodes[j].ChildCodeName == ChildCodeName) {
            for (let k = 0; k < this.orderCodeList[i].ChildCodes[j].ResultCodes.length; k++) {
              if (this.orderCodeList[i].ChildCodes[j].ResultCodes[k].ResultName == ResultName) {
                if (!this.orderCodeList[i].ChildCodes[j].ResultCodes[k].checked) {
                  this.orderCodeList[i].ChildCodes[j].ResultCodes[k].checked = !this.orderCodeList[i].ChildCodes[j].ResultCodes[k].checked;
                }
                else {
                  this.orderCodeList[i].ChildCodes[j].ResultCodes[k].checked = !this.orderCodeList[i].ChildCodes[j].ResultCodes[k].checked;
                }
              }
            }
            break;
          }
        }
        break;
      }
    }
  }

  getSelectedData(result) {
    this.dataSource.data.forEach((node) => {
      let dataobject = {};
      let selectedValue = this.treeControl.getDescendants(node).filter((x) => x.selected)
      dataobject[this.caseType] = selectedValue

      let array = this.treeControl
        .getDescendants(node)
        .filter((x) => x.selected)
      array.length > 0 ? dataobject[this.caseType].length > 0 ? result.push(dataobject) : null : null;

    });
    return result
  }

  orderCodeSave() {
    let result = [];
    this.savedOrderCodesCasetype = this.savedOrderCodesCasetype.filter(va => va.some(value => !Object.keys(value).includes(this.caseType)))
    result = this.getSelectedData(result);
    this.searchString = ''
    if (!result.length) {
      this._snackBar.open("Please select at least one panel!", "Close");
      return;
    }
    $('#manageOrderCode').modal('hide');
    result.length > 0 ? this.savedOrderCodesCasetype.push(result) : null;
    for (let i = 0; i < this.orderCodeSavedList.length; i++) {
      for (let j = 0; j < this.orderCodeSavedList[i].length; j++) {

      }
    }
    this.captureOrderCodes();
    this.orderCodeListCopy = cloneDeep(this.orderCodeList);
    this.resultSetListCopy = cloneDeep(this.resultSetList);
    this.instrumentsListCopy = cloneDeep(this.instrumentsList);

    let index = null
    this.orderPopup = false
    this.closedModal = false;
    if (this.orderCodeList[0]) {
      index = this.orderCodeSavedList ? this.orderCodeSavedList.findIndex(va => va[0].Casetype == this.orderCodeList[0].Casetype) : -1
      !(index == -1) ? this.orderCodeSavedList[index] = this.orderCodeList : this.orderCodeSavedList.push(this.orderCodeList);
    }
    if (this.resultSetList[0]) {
      index = this.resultSetSavedList ? this.resultSetSavedList.findIndex(va => va[0].Casetype == this.resultSetList[0].Casetype) : -1
      !(index == -1) ? this.resultSetSavedList[index] = this.resultSetList : this.resultSetSavedList.push(this.resultSetList);
    }
    if (this.instrumentsList[0]) {
      index = this.instrumentsListSavedList ? this.instrumentsListSavedList.findIndex(va => va[0].Casetype == this.instrumentsList[0].Casetype) : -1
      !(index == -1) ? this.instrumentsListSavedList[index] = this.instrumentsList : this.instrumentsListSavedList.push(this.instrumentsList);
    }
    this.checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    for (let i = 0; i < this.checklistSelection.selected.length; i++) {
      this.checklistSelection.deselect(this.checklistSelection.selected[i])
    }
    this._snackBar.open("Panels saved successfully", "Close");
    this.treeStructures[this.caseType] = this.dataSource.data
    this.savedResults[this.caseType] = this.resultSetList
    this.savedInstruments[this.caseType] = this.instrumentsList
    $('#LabSetup').modal('show');
  }

  captureOrderCodes() {
    for (let i = 0; i < this.savedOrderCodesCasetype.length; i++) {
      if (Object.keys(this.savedOrderCodesCasetype[i][0]).includes(this.caseType)) {
        for (let j = 0; j < this.savedOrderCodesCasetype[i].length; j++) {
          for (let m = 0; m < this.savedOrderCodesCasetype[i][j][this.caseType].length; m++) {
            for (let k = 0; k < this.orderCodeList.length; k++) {
              if (this.orderCodeList[k].Casetype == this.caseType) {
                if (this.savedOrderCodesCasetype[i][j][this.caseType][m].children.length == 0) {
                  if (!this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) {
                    let checked = this.orderCodeList[k].ChildCodes.length > 0 && this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name) ? true : false
                    if (this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name)) {
                      if (checked) {
                        let codeFound = false
                        this.orderCodeList[k].checked = checked
                        this.orderCodeList[k].seen = true
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          if (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name) {
                            codeFound = true
                            va.checked = true;
                          }
                          va.seen = true
                          !codeFound ? va.checked = false : null;
                        });
                      }
                      else {
                        this.orderCodeList[k].seen = false
                        this.orderCodeList[k].checked = checked
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          va.checked = false;
                        });
                      }
                    }
                  }
                  else {
                    let checked = this.orderCodeList[k].ChildCodes.length > 0 && this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName || va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) && va.ResultCodes.some(value => value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name))
                    if (this.orderCodeList[k].ParentOrderCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName && this.orderCodeList[k].ChildCodes.some(va => (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) && va.ResultCodes.some(value => value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name))) {
                      if (checked) {
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          if (va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].parentName || va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].grandParentCodeName) {
                            va.ResultCodes.forEach(value => {
                              if (value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].name) {
                                va.checked = true;
                                this.orderCodeList[k].checked = true;
                                this.orderCodeList[k].seen = true;
                                value.seen = true
                                value.checked = true
                                va.seen = true
                              }
                            })
                          }
                        });
                      }
                      else {
                        this.orderCodeList[k].seen = false
                        this.orderCodeList[k].checked = false;
                        this.orderCodeList[k].ChildCodes.forEach(va => {
                          va.checked = false;
                          va.ResultCodes.forEach(value => {
                            value.seen = false
                            value.checked = false
                          })
                          va.seen = false
                        });
                      }
                    }
                  }
                }
                else {
                  for (let n = 0; n < this.savedOrderCodesCasetype[i][j][this.caseType][m].children.length; n++) {
                    if (this.savedOrderCodesCasetype[i][j][this.caseType][m].children[n].parentName == this.orderCodeList[k].ParentOrderCodeName && this.orderCodeList[k].ChildCodes.some(va => va.ChildCodeName == this.savedOrderCodesCasetype[i][j][this.caseType][m].children[n].grandParentCodeName)) {
                      this.orderCodeList[k].ChildCodes.forEach(va => va.ResultCodes.forEach(value => {
                        let codeFound = false;
                        if (value.ResultName == this.savedOrderCodesCasetype[i][j][this.caseType][m].children[n].name) {
                          codeFound = true;
                          value.checked = true;
                        }
                        !codeFound ? value.checked = false : null;
                      }));
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    for (let i = 0; i < this.orderCodeList.length; i++) {
      if (!this.orderCodeList[i].checked) {
        this.orderCodeList[i].ChildCodes.forEach(va => va.checked = false);
      }
      if (this.orderCodeList[i].seen == null || this.orderCodeList[i].seen == undefined) {
        this.orderCodeList[i].checked = false
        this.orderCodeList[i].ChildCodes.forEach(va => va.checked = false);
      }
      if (this.orderCodeList[i].seen != null && this.orderCodeList[i].seen != undefined) {
        this.orderCodeList[i].checked = this.orderCodeList[i].seen;
        for (let j = 0; j < this.orderCodeList[i].ChildCodes.length; j++) {
          if (this.orderCodeList[i].ChildCodes[j].seen == true) {
            this.orderCodeList[i].ChildCodes[j].checked = true
          }
          else {
            this.orderCodeList[i].ChildCodes[j].checked = false
          }
          this.orderCodeList[i].ChildCodes[j].ResultCodes.forEach(va => {
            if (va.seen == null || va.seen == undefined) {
              va.checked = false;
            }
            else {
              va.checked = va.seen;
            }
          })
        }
      }
    }
  }

  checkAllPanels(checked) {
    this.dataSource.data.forEach(nodes => {
      this.todoItemSelectionToggle(checked, nodes);
    })
  }

  checkResultSet(event) {
    for (let i = 0; i < this.resultSetList.length; i++) {
      if (this.resultSetList[i].ID == event.value) {
        this.resultSetList[i].checked = true
      }
      else {
        this.resultSetList[i].checked = false
      }
    }
  }

  selectAllInstruments(event: MatCheckboxChange) {
    this.checkAllInstruments = !this.checkAllInstruments;
    this.instrumentsList.forEach(element => {
      element.checked = event.checked
    });
  }

  checkInstrumentValue(event: MatCheckboxChange, value) {
    this.instrumentsList.forEach(element => {
      if (element.InstrumentName == value) {
        element.checked = event.checked
      }
    });
    let allChecked = true;
    this.instrumentsList.forEach(element => {
      if (element.checked == false) {
        allChecked = false;
        return;
      }
    });
    this.checkAllInstruments = allChecked;
  }

  clearResults(evt: any, trigger: MatAutocompleteTrigger) {
    this.searchCaseType = '';
    // this.search.nativeElement.input();
    this.categoryList = cloneDeep(this.filterCaseTypeData);
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  //pdf Genarate
  pdfUrl: any;
  orgid: any;
  accid: any;
  casetype: any;
  detailtype: any;

  GetAllDataReport() {
    this.orgid = this.summaryData.OrgLabAccData.OrganizationID;
    this.accid = null;
    this.casetype = this.summaryData.CasetypeData.CaseType;
    this.ngxService.start();
    return this.getpdfservice.getPdfReportdata(this.orgid, this.accid, this.casetype).subscribe(
      (response: Blob) => {
        const fileName = 'AllCasetypeDetails.pdf';
        saveAs(response, fileName);
        this.ngxService.stop();
      });
  }
  GetCasetypeReport(orgid: any, acid: any, casetype: any) {
    this.orgid = orgid;
    this.accid = acid;
    this.casetype = casetype;
    this.ngxService.start();
    return this.getpdfservice.getPdfReportdata(this.orgid, this.accid, this.casetype).subscribe(
      (response: Blob) => {
        const fileName = 'casetype.pdf';
        saveAs(response, fileName);
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
      });
  }

  GetLabDetails(dtype: any) {
    this.orgid = this.summaryData.OrgLabAccData.OrganizationID;
    this.accid = null;
    this.casetype = this.summaryData.CasetypeData.CaseType;
    this.detailtype = dtype;
    this.ngxService.start();
    return this.getpdfservice.getLabdata(this.orgid, this.accid, this.casetype, this.detailtype).subscribe(
      (response: Blob) => {
        const fileName = 'LabDetailsOnly.pdf';
        saveAs(response, fileName);
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
      });
  }

  GetAllDataReport1() {
    var obj = {
      "OrganizationID": this.summaryData.OrgLabAccData.OrganizationID,
      "AccountId": null,
      "Casetypes": this.summaryData.CasetypeData
    }

    this.ngxService.start();
    return this.getpdfservice.getPdfReportdata2(obj).subscribe(
      (response: Blob) => {
        const fileName = 'AllCasetypeDetails.pdf';
        saveAs(response, fileName);
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
      });
  }

  async downloadCaseTypeDetails() {
    let caseTypesSelected = this.summaryData.CasetypeData.filter(va => va.CaseTypeAccountID == this.summaryData.OrgLabAccData.AccountID);
    let excelData = this.downloadSetupDetails();
    let casetypeAttributeDetails = [];
    let casetypediagnosisdetails = [];
    let casetypeicd9details = [];
    let casetypequalityattributedetails = [];
    let casetyperuleoutdetails = [];
    let casetypesitedetails = [];
    let casetypetemplatedetails = [];
    let casetypetestextractiondetails = [];
    this.ngxService.start();

    for (let i = 0; i < caseTypesSelected.length; i++) {
      await this.vaservices.getCasetypePDFData(this.summaryData.OrgLabAccData.OrganizationID, this.summaryData.OrgLabAccData.AccountID, caseTypesSelected[i].CaseType).toPromise().then(result => {
        if (!result.errors) {

          //#region - Attributes details
          result.CaseTypeAttributeDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypeAttributeDetails.push(va);
          });

          //#endregion

          //#region - diagnosis details
          result.CaseTypeDiagnosisDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypediagnosisdetails.push(va);
          });

          //#endregion


          //#region - ICD9 details
          result.CaseTypeICD9Details.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypeicd9details.push(va);
          });

          //#endregion

          //#region - Quality Attibutes
          result.CaseTypeQualityAttributeDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypequalityattributedetails.push(va);
          });

          //#endregion

          //#region - Rule Outs
          result.CaseTypeRuleOutDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetyperuleoutdetails.push(va);
          });

          //#endregion


          //#region - Sites
          result.CaseTypeSiteDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypesitedetails.push(va);
          });


          //#endregion

          //#region - Templates
          result.CaseTypeTemplateDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypetemplatedetails.push(va);
          });

          //#endregion

          //#region - Templates
          result.CaseTypeTestExtractionDetails.forEach(va => {
            va.CaseType = caseTypesSelected[i].CaseTypeDisplayname;
            casetypetestextractiondetails.push(va);
          });

          //#endregion
        }


      });
    }
    this.ngxService.stop();
    var sheetData = XLSX.utils.json_to_sheet(casetypeAttributeDetails);
    casetypeAttributeDetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'AttributeDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetypediagnosisdetails);
    casetypediagnosisdetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'DiagnosisDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetypeicd9details);
    casetypeicd9details.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'ICDDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetypequalityattributedetails);
    casetypequalityattributedetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'QualityAttributeDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetyperuleoutdetails);
    casetyperuleoutdetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'RuleOutDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetypesitedetails);
    casetypesitedetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'SiteDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetypetemplatedetails);
    casetypetemplatedetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'TemplateDetails') : null;

    sheetData = XLSX.utils.json_to_sheet(casetypetestextractiondetails);
    casetypetestextractiondetails.length > 0 ? XLSX.utils.book_append_sheet(excelData, sheetData, 'ExtractionDetails') : null;

    let filename = 'All Casetypes Details_';

    if (this.summaryData.OrgLabAccData.OrganizationName) {
      filename = filename + this.summaryData.OrgLabAccData.OrganizationName.toString();
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let name = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (name.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + name + '_';
        }
        else {
          filename = fileName + name + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    filename = filename + this.summaryData.OrgLabAccData.AccountID + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgid = filename.split('_')[2];
      filename = fileName + orgid;
    }
    XLSX.writeFile(excelData, filename);

  }


  checkPartialSelection(item) {
    if (item.caseTypes.every(va => va.checked) || item.caseTypes.every(va => !va.checked)) {
      return false;
    }

    return item.caseTypes.some(va => va.checked);
  }


  checkAllSelection(item) {
    return item.caseTypes.every(va => va.checked);
  }


  checkPanelSelected(casetype) {
    return this.savedOrderCodesCasetype && this.savedOrderCodesCasetype.length > 0 && this.savedOrderCodesCasetype.some(va => va.some(val => Object.keys(val).includes(casetype)));
  }


  // NPI popup
  openSearchNPIPopup() {
    let labDepKey = this._labadminservice.deploymentKey;
    try{
      this._labadminservice.deploymentKey = this.deploymentKey;
      let searchValues = this.frm_UserEnteredData.value.frm_npi ? this.frm_UserEnteredData.value.frm_npi : this.frm_UserEnteredData.value.frm_name;
      if (searchValues != null) {
        searchValues = searchValues.toString().trim();
      }
      this.opendialog
        .openNPIDailog('NPI Details', "0", this.checkIsDataPopulated(), { searchValue: searchValues }
        )
        .afterClosed()
        .subscribe({
          next: (res) => {
            this._labadminservice.deploymentKey = labDepKey;
            if (res) {
              this.mdmProviderId = res.mdmId
              this.frm_UserEnteredData.patchValue({
                frm_npi: res.npi,
                frm_name: res.accountName,
                frm_city: res.city,
                frm_state: this.StateList.find(va=> va.itemValue == res.state )?.item ?? '',
                frm_zip: res.zip,
                frm_Address1: res.address1,
                frm_Address2: res.address2,
                frm_phone: this.commonService.changeAdminContactPhone(res?.primaryContactPhone),
                frm_fax: this.commonService.changeAdminFaxNumber(res?.fax)
              });
              this.generateNameAndMnemonic(this.frm_UserEnteredData.value.frm_name)
              this.previousNPIValue = this.frm_UserEnteredData.value.frm_npi;
            }
          },
          error: (err) => {
            this._labadminservice.deploymentKey = labDepKey;
            console.error(err);
          },

        });
    }
    catch(err) {
      this._labadminservice.deploymentKey = labDepKey;
      console.error(err);
    }
  }
  checkIsDataPopulated() {
    return (
      (this.frm_UserEnteredData.value.frm_city !== '' && this.frm_UserEnteredData.value.frm_city !== null) ||
      (this.frm_UserEnteredData.value.frm_state !== '' && this.frm_UserEnteredData.value.frm_state !== null) ||
      (this.frm_UserEnteredData.value.frm_Address1 !== '' && this.frm_UserEnteredData.value.frm_Address1 !== null) ||
      (this.frm_UserEnteredData.value.frm_Address2 !== '' && this.frm_UserEnteredData.value.frm_Address2 !== null) ||
      (this.frm_UserEnteredData.value.frm_phone !== '' && this.frm_UserEnteredData.value.frm_phone !== null) ||
      (this.frm_UserEnteredData.value.frm_zip !== '' && this.frm_UserEnteredData.value.frm_zip !== null)
    );
  }

  triggerNPIPopup() {
    this.mdmProviderId = 0;
    const currentNPIValue = String(this.frm_UserEnteredData.controls.frm_npi.value);
    if (currentNPIValue == '') {
      this.previousNPIValue = '';
    }
    if (
      currentNPIValue.length === 10 &&
      currentNPIValue !== String(this.previousNPIValue)
    ) {
      this.previousNPIValue = currentNPIValue;
      this.openSearchNPIPopup();
    }
  }

  filteredlabList() {
    let filteredList;
    if (!this.selectedAccount || this.selectedAccount === 'Default Configuration') {
      filteredList = this.labList;
    } else {
      const isNumericAccount = /^\d+$/.test(this.selectedAccount);
      filteredList = this.labList?.filter(va => {
        const filterField = isNumericAccount ? va?.LabAccountId : va?.LabName;
        return filterField?.toString().toLowerCase().includes(this.selectedAccount?.toLowerCase());
      });
    }

    return filteredList?.slice(0, 25);
  }

  setCaseType(event, data?: any) {
    if (event.source.selected) {
      if (this.categoryList?.every(va => va.caseTypes?.every(v => !v.checked))) {
        if(data?.LabName)
          {
            this.sourceDeploymentOrgList=data;
            this.selectedAccount = data?.LabAccountId
          }
          else{
            this.selectedAccount='Default Configuration'
          }
          let id = this.selectedAccount == 'Default Configuration' ? this.defaultAccId : this.selectedAccount;
        this.savedCaseTypes = this.savedCaseTypes?.filter(va => va.SourceLabAccountID != id);
        this.selectedCaseTypes = this.selectedCaseTypes?.filter(c => c.caseTypes?.filter(va => va.SourceLabAccountID != id));
      }
      this.switchSource(data);
    }
  }

  switchSource(data) {
    this.checkAllCaseType = false;
    if (!data) {
      this.selectedAccount = 'Default Configuration'
      this.selectedAccCopy = cloneDeep(this.selectedAccount);
      this.currentAccountid = this.defaultAccId
      this.currentOrgID = this.defaultOrgId
      this.fetchCaseTypes(this.defaultOrgId, this.defaultAccId);
    }
    else {
      this.selectedAccount = data?.LabName + ' (' + data?.LabAccountId + ')'
      this.selectedAccCopy = cloneDeep(this.selectedAccount);
      this.currentAccountid = data?.LabAccountId
      this.currentOrgID = data?.OrgId
      this.fetchCaseTypes(data?.OrgId, data?.LabAccountId);
    }
  }

  fetchCaseTypes(organizationid, locationid): any {
    let dbName = this.frm_UserEnteredData.value.frmDeploymentKey
    this.ngxService.start();
    this.vaservices.getCasetypeDetails(organizationid, locationid, dbName).subscribe(
      res => {
        this.categoryList = [];
        if (res && res != "" && res?.length > 0) {
          res.forEach(e => {
            if (!this.categoryList?.find(element => element?.Category == e.Category))
              e.Category = e.Category ? e.Category?.toString().trim() : 'Not Specified';
            this.categoryList?.push({ Category: e.Category, caseTypes: [], checked: false })

            for (let i = 0; i < this.categoryList?.length; i++) {
              if (this.categoryList[i]?.Category == e.Category) {
                let checked = false
                let disabled = false;
                if (this.savedCaseTypes.some(va => va.casetype == e.CaseType)) {
                  disabled = true;
                  if (this.savedCaseTypes.some(va => va.SourceLabAccountID == locationid)) {
                    checked = true;
                    disabled = false;
                  }
                }
                if (!this.configuredCaseTypes?.some(va => va.casetype == e.CaseType)) {
                  this.categoryList[i].caseTypes?.push({ casetype: e.CaseType, SourceLabAccountID: locationid, checked: checked, Iscompendium: e.Iscompendium ? e.Iscompendium : false, disabled: disabled });
                }
                break;
              }
            }
          });
          this.categoryList = this.categoryList?.filter(va => va.caseTypes.length > 0);
          this.filterCaseTypeData = cloneDeep(this.categoryList);
          this.categoryListCopy = cloneDeep(this.categoryList);
          this.showCasetype = this.categoryList?.length > 0 ? true : false;
        }
        else {
          this.showCasetype = false;
        }
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
        this.categoryList = [];
        this.showCasetype = false;
        console.error(error);
        this._snackBar.open("Error found while fetching casetypes", "Close");
      });
  }

  clearAccounts(event: any, trigger: MatAutocompleteTrigger, matAuto: MatAutocomplete) {
    this.selectedAccount = '';
    this.clearPreviousSelectedOption(matAuto, null, false);
    event.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }

  clearPreviousSelectedOption(autoComplete: MatAutocomplete, skip: _MatOptionBase | null, emitEvent?: boolean) {
    autoComplete.options.forEach(option => {
      if (option !== skip && option?.selected) {
        option?.deselect(emitEvent);
      }
    });
  }

  async getLabsUnderOrganization(organizationID) {
    let obj = {
      orgId: null,
    };
    let labList = []
    await this.vaservices.getAARLookupValueForLabandOF(obj, organizationID).toPromise().then(res => {
      if (res.status == 'Success') {
        this.selectedAccount = 'Default Configuration';
        labList = (res.content.LabLocations) ? res.content.LabLocations : [];
      } else {
        console.error(res.statusmessage)
      }
    }, error => {
      console.error(error);
    })
    this.labList = labList;
    this.ngxService.stop();
    if (this.selectedAccount == 'Default Configuration') {
      if(!this.enableDeploymentlist)
      {
        this.currentAccountid = this.defaultAccId
        this.currentOrgID = this.defaultOrgId
        this.fetchCaseTypes(this.defaultOrgId, this.defaultAccId);
      }
      else{
        this.selectedAccount='';
        this.currentAccountid = 0;
        this.currentOrgID = 0;
        this.categoryList = [];
        this.showCasetype = false;
      }
    }
    else {
      this.currentAccountid = this.accid
      this.currentOrgID = this.orgid
      this.fetchCaseTypes(this.orgid, this.accid);
    }
  }

  getLogoUrl(): string {
    if (this.summaryData && this.summaryData.OrgLabAccData && this.summaryData.OrgLabAccData.Logo) {
      return 'data:image/jpg;base64,' + this.summaryData.OrgLabAccData.Logo;
    }
    return '';
  }

  downloadLogo(): void {
    if (this.summaryData && this.summaryData?.OrgLabAccData && this.summaryData?.OrgLabAccData?.Logo) {
      const a = document.createElement('a');
      a.href = 'data:image/jpg;base64,' + this.summaryData?.OrgLabAccData?.Logo;
      a.download = 'logo.jpg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  //Deployment details in the copy from Entity
  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      if(this.headerDeploymentKey==depKey)
        {
          this.enableDeploymentlist=false;
        }
        else{
          this.enableDeploymentlist=true;
        }
      this.getLabsUnderOrganization(depKey);
      this.deploymentKey = depKey;
    }
  }

  async getListOfRepresentiveUsers() {
    let query = this.vaservices?.GetQuery('vitalaxisrepresentative');
    let queryVariable: any;

    queryVariable = {
      "IsActive": true
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();

    await this.vaservices.GetData(queryResult, 'configdb').subscribe(res => {
      if (res.data.submenuData) {
        this.representativeUsersList = [...res.data.submenuData];
        this.getListOfRolesUsers();
      } else {
        this.representativeUsersList = [];
      }
      // this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  async getListOfRolesUsers() {
    this.ngxService.start();
    let query = this.vaservices.GetQuery('CategoryDropDown');
    let queryVariable = { context: 'VitalAxisRepresentative', Keyword: 'RepresentativeRole' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vaservices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
        this.representativeRolesList = data;
      } else {
        this.ngxService.stop();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  selectedUsersFromFrameWork($event: any) {
    this.selectedRepresentativesList = $event;
  }
  getStatesList(state) {
    if (!state) {
      return this.StateList
    }
    return this.StateList.filter(va => va?.item && state && va.item?.toString()?.toLowerCase()?.includes(state?.toString()?.toLowerCase()));
  }

  filterState(account: string,colname) {
    this.frm_UserEnteredData.patchValue({
      [colname]: account
    })
    this.StateList = cloneDeep(this.StateList)

    if (!account) {
      return this.StateList
    }

  }
  refreshStateSearch(colname) {
    this.frm_UserEnteredData.patchValue({
      [colname]: ''
    })
    // this.StateList = cloneDeep(this.StateList)
  }

  getTimeZoneList(timezone){
    if (!timezone) {
      return this.Timezones
    }
    return this.Timezones.filter(va => va?.item && timezone && va?.item?.toString()?.toLowerCase()?.includes(timezone?.toString()?.toLowerCase()));
  }

  filterTimeZone(account: string,colname) {
    this.frm_UserEnteredData.patchValue({
      [colname]: account
    })
    this.Timezones = cloneDeep(this.Timezones)

    if (!account) {
      return this.Timezones
    }

  }
}
