import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from '../../../core/services/datashare.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { VitalAdminTabService } from '../../tab.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import {  MatSnackBar } from '@angular/material/snack-bar';
import {  MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../core/services/commonservices';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as XLSX from 'xlsx';
import cloneDeep from 'lodash';

declare var $: any;
import { ThemePalette } from '@angular/material/core';
import { any, string } from 'prop-types';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-requisition-profiles',
  templateUrl: './requisition-profiles.component.html',
  styleUrls: ['./requisition-profiles.component.scss']
})

export class RequisitionProfilesComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  SubMenuCardModel: SubMenuCardModel;
  gridShow: boolean;
  private _currentEditItem: any = null;
  @Input() templateData: any;
  @ViewChild('txtInput') txtInput: ElementRef;
  @ViewChild('flex', { static: true }) flex: wjcGrid.FlexGrid;
  gridwidth: number;
  gridData: wjcCore.CollectionView<any>;
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean = false;
  destDeployment: string = '';
  enableInsert: boolean;
  destOrgid: string;
  ofOrgList: any = [];
  ofLocationList: any = [];
  ofPhysician: any = [];
  OrderCodeList: any = [];
  OrderCodeListName: any = [];
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  ordercodeDropdownList: any = [];
  filteredAccounts: any = [];
  filteredOrg: any = [];
  filteredPhysician: any = [];
  ordercodeidlist: any = [];
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  deleteBtn: boolean = false;
  destAccID: string;
  requisitionprofilename = new FormControl();

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: FormBuilder,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public commonService: CommonService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
    this.destDeployment = this.VitalHttpServices.DeploymentKeys;
  }

  addRequisitonProfile = this._fb.group({
    OrderCode:[],
    OrderingFacility: '',
    OrderingFacilityLocation: '',
    OrganizationID: '',
    Physician: '',
    RequisitionProfileID: '',
    scope: '',
    Status: true
  });

  ngOnInit(): void {
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.destOrgid = (!sessionStorage.getItem('org_id')) ? '' : sessionStorage.getItem('org_id');
    this.destAccID = (!sessionStorage.getItem('AccountID')) ? '' : sessionStorage.getItem('AccountID');
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.noDataFound = true;
    this.GetButtondetails();
  }

  /* RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
  }

  //#region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  //#endregion


  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = '';
    msgActivityTracker = (info == 'updated') ? 'Updated' : 'Added';

    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj.RequisitionProfileID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
      this.ngxService.stop();
    } else {
      /*New records*/
      this.ngxService.start();
      for (let i = 0; i < newObjArray.length; i++) {
        this.commonService.createActivityTracker(info, newObjArray[i].ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[i], {});
      }
      this.ngxService.stop();
    }
  }

  //Wizmo grid inline edit
  initializeGrid(flex) {
    const tt = new wjcCore.Tooltip();
    flex.rows.defaultSize = 40;
    // custom formatter to paint buttons and editors
    flex.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {
      if (e.panel == s.cells) {
        let col = s.columns[e.col],
          item = s.rows[e.row].dataItem;
        if (item == this._currentEditItem) {
          switch (col.binding) {
            case 'buttons':
              e.cell.innerHTML = document.getElementById(
                'tplBtnViewMode'
              ).innerHTML;
              e.cell['dataItem'] = item;
              break;
          }
        }
      }
    });
    flex.cellEditEnded.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.CellEditEndingEventArgs) => {
      let oldValue = e.data;
      let newValue = s.getCellData(e.row, e.col, true);
      if (oldValue !== newValue) {
        if (!this.editBtn) {
          let col = s.columns[e.col];
          if (col.binding == 'Status') {
            let statusvalue = e._p.rows[e.row].dataItem.Status ? true : false;
            if (this.templateData.submenuData &&
              this.templateData.submenuData.some(va => va.RequisitionProfileID != e._p.rows[e.row].dataItem.RequisitionProfileID && va.OrderingFacility.toString().toLowerCase() == e._p.rows[e.row].dataItem.OrderingFacility.toString().toLowerCase()
                && va.OrderingFacilityLocation.toString().toLowerCase() == e._p.rows[e.row].dataItem.OrderingFacilityLocation.toString().toLowerCase() && va.Physician.toString().toLowerCase() == e._p.rows[e.row].dataItem.Physician.toString().toLowerCase() && va.Status == statusvalue)
            ) {
              let status = statusvalue ? 'active' : 'inactive'
              this._snackbar.open('An ' + status + ' requisition profile with the same criteria already exists', 'Close');
              e._p.rows[0].dataItem.Status = !statusvalue;
              this.getRequisitionProfilelist()
            }
            else {
              let obj = [{
                RequisitionProfileID: s.rows[e.row].dataItem.RequisitionProfileID,
                Status: newValue == "true" ? 'Active' : 'Inactive',
                modifiedby: sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100'
              }]
              this.ngxService.start();
              this.VitalHttpServices.UpdateRequisitionProfile(obj, this.destDeployment).subscribe(res => {
                this.ngxService.stop();
                if (res[0].Success) {
                  let oldData = cloneDeep(e._p.rows[e.row].dataItem)
                  oldData.__wrapped__.Status = !statusvalue;
                  this.auditDetails(oldData.__wrapped__, [e._p.rows[e.row].dataItem], 'updated');
                  this._snackbar.open('Requisition profile updated successfully', 'Close');
                  this.refreshGrid();
                }
                else {
                  this._snackbar.open('Requisition profile updation failed!', 'Close');
                  e._p.rows[0].dataItem.Status = !statusvalue;
                  this.getRequisitionProfilelist()
                }
              });
            }
          }
        }
      }
    });
    flex.addEventListener(flex.hostElement, 'click', (e: MouseEvent) => {
      let targetBtn: HTMLButtonElement;
      if (e.target instanceof HTMLButtonElement) {
        targetBtn = e.target;
      } else if (
        e.target instanceof HTMLElement &&
        e.target.classList.contains('fa')
      ) {
        targetBtn = e.target.parentElement as HTMLButtonElement;
      }
      if (targetBtn) {
        let item = wjcCore.closest(targetBtn, '.wj-cell')['dataItem'];
        // handle buttons
        switch (targetBtn.id) {
          // remove this item from the collection
          case 'btnDelete':
            this.deleteRequisitionProfile(flex, item);
            break;
        }
      }
    });
    flex.scrollPositionChanged.addHandler(this._cancelEdit.bind(this));
    flex.lostFocus.addHandler(this._cancelEdit.bind(this));

    flex.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        let data = s.getCellData(e.row, e.col)
        if (data == true) {
          data = 'Active'
        }
        else if (data == false) {
          data = 'Inactive'
        }

        tt.setTooltip(e.cell, `${data} `);
      }
    });
  }

  updateRequisitionProfile(data, flag?: string) {
    if (flag == 'edit') {
      data = {}
      data.RequisitionProfileID = this._currentEditItem.RequisitionProfileID;
      data.Status = this._currentEditItem.Status
      data.modifiedby = sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100'
      if (!this._currentEditItem.Status) {
        this._snackbar.open('Insert Values', 'Close')
        return;
      }
      else {
        this.ngxService.start();
        this.VitalHttpServices
          .UpdateRequisitionProfile([data], this.destDeployment)
          .subscribe(
            result => {
              this.ngxService.stop();
              if (!result.errors) {
                this.auditDetails(this._currentEditItem, [data], 'updated');
                this._snackbar.open('Requisition Profile updated successfully', 'Close');
                this._currentEditItem = null;
                this.getRequisitionProfilelist();
                return;
              }
            },
            error => {
              console.error(error);
            });
      }
    }
  }

  //cancel in grid
  private _cancelEdit() {
    if (this._currentEditItem) {
      this._currentEditItem = null;
      this.flex.invalidate();
    }
  }
  //To delete Requisition profile
  deleteRequisitionProfile(flex, value) {
    let data = value._data.RequisitionProfileID.toString();
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '360px',
      data: { header: "Alert!", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
    })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.ngxService.start();
        this.VitalHttpServices.DeleteRequisitionProfile({ RequisitionProfileID: data }, this.destDeployment).subscribe(
          (res) => {
            this.ngxService.stop();
            if (res.Success) {
              this._snackbar.open(res.Message, 'Close');
              this.refreshGrid('list');
              (<wjcCore.CollectionView>flex.collectionView).remove(value)
              let index = this.templateData.submenuData.findIndex(va => va.RequisitionProfileID == data)
              this.templateData.submenuData.splice(index, 1)
              flex.rows.length == 0 ? this.refreshGrid('list') : null;
            }
            else {
              console.error(res.Message);
            }
          },
          error => {
            console.error('Error deleting item:', error);
          }
        );

      }
    })
  }

  // button
  refreshGrid(context?) {
    this.resetFormErrors();
    this.getRequisitionProfilelist();
  }

  resetFormErrors(): any {
    Object.keys(this.addRequisitonProfile.controls).forEach(key => {
      this.addRequisitonProfile.controls[key].setErrors(null)
    });
  }

  //Populate grid in the grid
  getRequisitionProfilelist(context?) {
    let query = this.SubMenuCardModel.GetQuery('getrequisitionprofiles');
    let queryVariable = { orgid: this.destOrgid.toString(), casetype: this.templateData.secondarykeys.casetype.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        this.ngxService.stop();
        if (!data.errors) {
          data.data.submenuData.forEach(va => {
            for (let [key, value] of Object.entries(va)) {
              if (value == null || value == undefined) {
                value = 'Not Specified';
              }
              else if (key == 'Status') {
                value = (value == 1 || value == true) ? true : false;
              }
            }
          })
          this.templateData.submenuData = data.data.submenuData;
          let templateData = data.data.submenuData;
          if (templateData && templateData.length > 0) {
            if (!context) {
              this.addGridData(templateData);
              this.gridShow = true;
            }
            this.noDataFound = false;
          } else {
            this.noDataFound = true;
            this.gridShow = false;
          }
        }
      },
      error => {
        this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  // to add data in the grid 1st
  addGridData(templateData: any) {
    let primary = {};
    let gridarray = [];
    if (templateData) {
      if (templateData.length > 0) {
        for (let i = 0; i < templateData.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(templateData[i])) {
            if (!value) {
              value = 'Not Specified';
            }
            if (key == 'Status') {
              value = (value == 1 || value == true) ? true : false;
              primary["Status"] = value;
            }
            else {
              primary[key] = value;
            }
          }
          gridarray.push(primary);
        }
        this.templateData.submenuData = gridarray;
        this.gridData = new CollectionView(gridarray,
          { pageSize: 10 });
        this.gridwidth = (170 * Object.keys(templateData[0]).length) + 37;
        this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
        this.gridShow = true;
        this.noDataFound = false;
      }
      else {
        this.gridwidth = 0;
      }
    }
    else {
      this.gridwidth = 0;
    }
  }

  //Opening Requisition Profile create screen
  openCreateScreen() {
    this.noDataFound = false;
    this.gridShow = false;
    this.enableInsert = true;
    this.addRequisitonProfile.reset()
    this.addRequisitonProfile.patchValue({
      Status: true
    })
    this.getAllOrderCode();
  }

  // button
  backSelect() {
    this.addRequisitonProfile.patchValue({
      OrderCode: '',
      OrderingFacility: '',
      OrderingFacilityLocation: '',
      OrganizationID: '',
      Physician: ''
    });
    this.enableInsert = false;
    this.gridShow = false;
    this.getRequisitionProfilelist();
    this.gridwidth = 0;
    this.resetFormErrors();
  }

  // button
  saveAndClose() {
    this.insertRequisitionProfile('close');
  }

  // button
  saveAndCreate() {
    this.insertRequisitionProfile('create');
  }

  async insertRequisitionProfile(obj) {
    let orderCodes = [];
    if (this.addRequisitonProfile.value.OrderCode && this.addRequisitonProfile.value.OrderCode.length > 0) {
      for (let i = 0; i < this.addRequisitonProfile.value.OrderCode.length; i++) {
        orderCodes.push(this.addRequisitonProfile.value.OrderCode[i].ordercode_id);
      }
    }
    else {
      this._snackbar.open('Please select at least one order code', 'Close');
      return;
    }

    var data = {
      "ordercodeid": orderCodes.toString(),
      "parentorganization": Number(this.destOrgid),
      "OrganizationID": this.addRequisitonProfile.value.OrderingFacility ? this.addRequisitonProfile.value.OrderingFacility.split('(')[1].split(')')[0] : null,
      "accountid": this.addRequisitonProfile.value.OrderingFacilityLocation ? this.addRequisitonProfile.value.OrderingFacilityLocation.split('(')[1].split(')')[0] : null,
      "userid": this.addRequisitonProfile.value.Physician ? this.addRequisitonProfile.value.Physician.split('(')[1].split(')')[0] : null,
      "Scope": this.addRequisitonProfile.value.scope ? this.addRequisitonProfile.value.scope : null,
      "Status": this.addRequisitonProfile.value.Status ? 'Active' : 'Inactive',
      "createdby": sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100',
    }

    let org = this.addRequisitonProfile.value.OrderingFacility ? this.addRequisitonProfile.value.OrderingFacility : 'Not Specified'
    let loc = this.addRequisitonProfile.value.OrderingFacilityLocation ? this.addRequisitonProfile.value.OrderingFacilityLocation : 'Not Specified'
    let phys = this.addRequisitonProfile.value.Physician ? this.addRequisitonProfile.value.Physician : 'Not Specified'

    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      let existingData = this.templateData.submenuData.filter(va => va.OrderingFacility.toString().toLowerCase() == org.toString().toLowerCase()
        && va.OrderingFacilityLocation.toString().toLowerCase() == loc.toString().toLowerCase() && va.Physician.toString().toLowerCase().split('(')[0].trim() == phys.toString().toLowerCase().split('(')[0].trim() && va.Status == (this.addRequisitonProfile.value.Status ? true : false))
      if (existingData && existingData.length > 0) {
        let existingOrderCodes = existingData[0].OrderCode.split(',');
        for (let i = 0; i < existingOrderCodes.length; i++) {
          let index = existingOrderCodes[i].toString().lastIndexOf('(')
          existingOrderCodes[i] = Number(existingOrderCodes[i].toString().slice(index + 1).split(')')[0]);
        }
        let codeDifference = orderCodes.filter(x => !existingOrderCodes.includes(x))
        if (codeDifference.length == 0) {
          this._snackbar.open('Requisition Profile with the same order codes already exists', 'Close');
          return;
        }
        else {
          data.ordercodeid = codeDifference.join(',');
          data["requisitionid"] = existingData[0].RequisitionProfileID;
        }
      }
    }
    await this.saveRequisitionProfile(data, obj);
  }

  async saveRequisitionProfile(data, obj, editExisting?: boolean, existingData?) {
    this.ngxService.start();
    // if (editExisting) {
    //   let response = await this.VitalHttpServices.UpdateRequisitionProfile([{ RequisitionProfileID: existingData[0].RequisitionProfileID, Status: 'Inactive', modifiedby: sessionStorage.getItem('Userid') && sessionStorage.getItem('Userid') != "" ? sessionStorage.getItem('Userid').toString() : '-100' }], this.destDeployment).toPromise();
    //   if (!response[0].Success) {
    //     let oldjson = this.templateData.submenuData.filter(va => va.RequisitionProfileID == existingData[0].RequisitionProfileID)
    //     let newjson = cloneDeep(oldjson[0])
    //     newjson.Status = 'Inactive';
    //     this.auditDetails(oldjson[0], newjson, 'updated');
    //     this.ngxService.stop();
    //     this._snackbar.open('Requisition Profile creation failed!', 'Close');
    //     return;
    //   }
    // }
    await this.VitalHttpServices.InsertRequisitionProfile([data], this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          this.auditDetails({}, result[0].Data, 'added');
          this.ngxService.stop();
          this._snackbar.open('Requisition profile created successfully', 'Close');
          if (obj == 'close') {
            this.enableInsert = false;
            this.gridShow = false;
            this.getRequisitionProfilelist();
            return;
          }
          else {
            let status = this.addRequisitonProfile.value.Status;
            this.addRequisitonProfile.reset()
            this.addRequisitonProfile.patchValue({
              Status: status
            })
            this.getRequisitionProfilelist(true);
            this.enableInsert = true;
            this.gridShow = false;
            this.resetFormErrors();
          }
        }
      },
      error => {
        this.ngxService.stop();
        this._snackbar.open('Requisition Profile creation failed!', 'Close')
        console.error(error);
      });
  }


  getAllOrderCode() {
    let queryVariable = { orgid: this.destOrgid.toString(), casetype: this.templateData.secondarykeys.casetype.toString() };
    let query = this.SubMenuCardModel.GetQuery("getordercode");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.destDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          this.OrderCodeList = data.data.submenuData
        }
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'ordercode_id',
          textField: 'OrderCode_Name',
          enableCheckAll: true,
          allowSearchFilter: true,
          limitSelection: -1,
          clearSearchFilter: true,
          maxHeight: 150,
          itemsShowLimit: 3,
          closeDropDownOnSelection: false,
          showSelectedItemsAtTop: false,
          defaultOpen: false
        };
        this.ordercodeDropdownList = [];
        this.OrderCodeList.forEach(element => {
          const temp = {
            'OrderCode_Name': element.OrderCodeName + '(' + element.ordercodeid.toString() + ')', 'ordercode_id': element.ordercodeid
          }
          this.ordercodeDropdownList.push(temp);
        });
        this.getAllOrderingFacility();
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }

  //#region
  onCheckChange(item: any) {
    this.OrderCodeListName.push(item.OrderCode_Name);
  }

  onSelectAll(items: any) {
    this.OrderCodeListName = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.OrderCodeListName.push(items[itr].OrderCode_Name);
    }
  }

  onItemDeSelect(items: any) {
    const index: number = this.OrderCodeListName.indexOf(items.OrderCode_Name);
    if (index !== -1) {
      this.OrderCodeListName.splice(index, 1);
    }
  }

  public onDeSelectAll() {
    this.OrderCodeListName = [];
  }
  //#endregion

  async getAllOrderingFacility() {
    let queryVariable = { orgid: this.destOrgid.toString(), accid: this.destAccID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getalldropdown");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let data = await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise();
    this.ngxService.stop();
    if (!data.errors) {
      if (data.data.submenuData && data.data.submenuData.length > 0) {
        this.ofPhysician = data.data.submenuData
        this.ofLocationList = [...new Map(this.ofPhysician.map(item => [item["accountid"], item])).values()];
        this.ofOrgList = [...new Map(this.ofLocationList.map(item => [item["associatedOrg"], item])).values()];
      }
    }
  }

  fetchOfOrgName(value: string) {
    this.filteredOrg = this.ofOrgList;
    if (!value) {
      return this.filteredOrg.slice(0, 25);
    }
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      this.filteredOrg = this.filteredOrg.filter(va =>
        va.organizationname && va.organizationname.toString().toLowerCase().includes(value.toString().toLowerCase()))
    }
    else {
      this.filteredOrg = this.filteredOrg.filter(va =>
        va.organizationname && va.organizationname.toString().toLowerCase().includes(value.toString().toLowerCase()))
    }
    this.filteredOrg = this.filteredOrg.slice(0, 25);
    return this.filteredOrg
  }


  setOrganization(event, item) {
    if (event.source.selected) {
      this.addRequisitonProfile.patchValue({
        OrderingFacility: item.organizationname + '(' + item.associatedOrg + ')',
        OrderingFacilityLocation: '',
        Physician: ''
      })
    }
  }

  filterOFLocations(value: string) {
    this.filteredAccounts = this.addRequisitonProfile.value.OrderingFacility ? this.ofLocationList.filter(va => this.addRequisitonProfile.value.OrderingFacility.toString().includes('(') && this.addRequisitonProfile.value.OrderingFacility.toString().includes(')') && va.associatedOrg.toString().toLowerCase() == this.addRequisitonProfile.value.OrderingFacility.toString().toLowerCase().split('(')[1].split(')')[0]) : this.ofLocationList;
    if (!value) {
      return this.filteredAccounts.slice(0, 25)
    }
    var idValidate = /^[0-9]*$/;
    if (idValidate.test(value)) {
      return this.filteredAccounts.filter(va => va.accountname.toString().toLowerCase().includes(value.toString().toLowerCase())).slice(0, 25);
    }
    return this.filteredAccounts.filter(va => va.accountname.toString().toLowerCase().includes(value.toString().toLowerCase())).slice(0, 25);
  }

  setOF(event, item) {
    if (event.source.selected) {
      this.addRequisitonProfile.patchValue({
        OrderingFacility: item.organizationname + '(' + item.associatedOrg + ')',
        OrderingFacilityLocation: item.accountname + '(' + item.accountid + ')',
        Physician: ''
      })
    }
  }

  filterPhysician(value: string) {
    this.filteredPhysician = this.addRequisitonProfile.value.OrderingFacility ? this.ofPhysician.filter(va => this.addRequisitonProfile.value.OrderingFacility.toString().includes('(') && this.addRequisitonProfile.value.OrderingFacility.toString().includes(')') && va.associatedOrg.toString().toLowerCase() == this.addRequisitonProfile.value.OrderingFacility.toString().toLowerCase().split('(')[1].split(')')[0]) : this.ofPhysician;


    this.filteredPhysician = this.addRequisitonProfile.value.OrderingFacilityLocation ? this.ofPhysician.filter(va => this.addRequisitonProfile.value.OrderingFacilityLocation.toString().includes('(') && this.addRequisitonProfile.value.OrderingFacilityLocation.toString().includes(')') && va.accountid.toString().toLowerCase().trim() == this.addRequisitonProfile.value.OrderingFacilityLocation.toString().toLowerCase().split('(')[1].split(')')[0]) : this.filteredPhysician;

    this.filteredPhysician = this.filteredPhysician ? this.filteredPhysician : []
    let data: any = []

    if (!value) {
      data = this.filteredPhysician.slice(0, 25);
      data = [...new Map(data.map(item => [item["physicianid"], item])).values()];
      return data;
    }
    var idValidate = /^[0-9]*$/;
    if (idValidate.test(value)) {
      data = this.filteredPhysician.filter(va => va.physicianname.toString().toLowerCase().includes(value.toString().toLowerCase())).slice(0, 25);
    }
    data = this.filteredPhysician.filter(va => va.physicianname.toString().toLowerCase().includes(value.toString().toLowerCase())).slice(0, 25);
    data = [...new Map(data.map(item => [item["physicianid"], item])).values()];
    return data;
  }

  setPhysician(event, item) {
    if (event.source.selected) {
      this.addRequisitonProfile.patchValue({
        OrderingFacility: item.organizationname + '(' + item.associatedOrg + ')',
        OrderingFacilityLocation: item.accountname + '(' + item.accountid + ')',
        Physician: item.physicianname
      })
    }
  }

  //#region Export Grid data
  ExportExcel() {
    let excel = [];

    for (let i = 0; i < this.templateData.submenuData.length; i++) {
      delete this.templateData.submenuData[i]["RequisitionProfileID"]
      this.templateData.submenuData[i]["Status"] = this.templateData.submenuData[i]["Status"] == true ? 'Active' : 'Inactive'
      excel.push(this.templateData.submenuData[i]);
    }

    let filename = 'Requisition Profiles_' + this.destOrgid + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel.reverse());
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Requisition Profile');
    XLSX.writeFile(wb, filename);
  }
  //#endregion


  clearOrderingFacility(evt: any): void {
    evt.stopPropagation();
    this.addRequisitonProfile.patchValue({
      OrderingFacility: "",
      OrderingFacilityLocation: "",
      Physician: ""
    })
    this.txtInput.nativeElement.focus();
  }
  clearOrderingFacilityLocation(evt: any): void {
    evt.stopPropagation();
    this.addRequisitonProfile.patchValue({
      OrderingFacilityLocation: "",
      Physician: ""
    })
    this.txtInput.nativeElement.focus();
  }
  clearPhysician(evt: any): void {
    evt.stopPropagation();
    this.addRequisitonProfile.patchValue({
      Physician: ""
    })
    this.txtInput.nativeElement.focus();
  }
}
