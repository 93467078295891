import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { CommonService } from 'src/app/core/services/commonservices';

import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-deletion-templates',
  templateUrl: './deletion-templates.component.html',
  styleUrls: ['./deletion-templates.component.scss']
})
export class DeletionTemplatesComponent implements OnInit {
  SubMenuCardModel: SubMenuCardModel;
  @Input() templateData: any;
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  gridHeaderList: any;
  listHeaderbackup: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  receivedObject: any;
  saveOrEditCompleted: any;
  auditableColumns: any;
  deleteBtn: any;
  userSessionDetails: any;
  sourceApp: string;
  destDeployment: string;
  
  activityEntity: any;
  dbName: string;
  storesub:Subscription = new Subscription();
  exportExcelFields = {
    TemplateName: { displayName: "Template Name", isDate: false },
    Description: { displayName: "Description", isDate: false },
    CreatedByName: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    ModifiedByName: { displayName: "Modified By", isDate: false },
    ModifiedDate: { displayName: "Modified Date", isDate: true },
  } 

  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    private labadminService: LabadminService,
    private labAdminSessionService : LabadminSessionService,
    private store: Store<{ breadcrum : [] }>,
    private activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }
  async ngOnInit() {
    this.destDeployment=sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
     let  _this = this;
     await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
       if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
        _this.userSessionDetails = session["userDetails"]
        _this.sourceApp = 'VitalDx'
        let menuObj={}
        this.storesub = this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
          menuObj = result[result.length -1]})
          this.storesub.unsubscribe();
          await _this.commonService.getCommmonTemplateData(menuObj,_this.userSessionDetails);
         _this.templateData=_this.labadminService.templateData;
         _this.dbName = _this.labadminService.deploymentKey;
         sessionStorage.setItem('deploymentKey', this.dbName);
         this.addGridData(this.templateData.submenuData);
         this.GetButtonAccess(this.labadminService.subMenuPermissions)
         this.getAuditableDetails(this.templateData.menuURL)
          this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
          this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          this.activityEntity.entityId = '';
          this.activityService.setActivitySession(this.activityEntity);
        //  this.activityService.setActivitySession(this.activityEntity);

         // this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
       }})
     
    }
    else {      
      //this.getAllDeletionTemplates();
      // this.templateData.submenuData = this.templateData?.subMenuData && this.templateData.subMenuData?.length>0 ? this.templateData.submenuData : this.labdaminService.templateData 
      this.addGridData(this.templateData.submenuData);
      this.getAuditableDetails(this.templateData.menuURL)
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.activityEntity.entityId = '';
      this.activityService.setActivitySession(this.activityEntity);
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
    }
  }

  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    } 
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getAllDeletionTemplates() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getorganizationdeletetemplates');
    queryVariable = {
      "orgid": sessionStorage.getItem('org_id') && sessionStorage.getItem('org_id').toString() !=''? sessionStorage.getItem('org_id').toString() : this.labadminService.organizationId.toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult,this.destDeployment).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView([]);
        }
        this.ngxService.stop();
      },
      error => {
        // this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'DeletionReasons') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    let gridarray = [];
    this.gridheader = ['OrgTemplateID', 'OrganizationID', 'TemplateName', 'Description']
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'TemplateName' || key == 'Description') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    // gridarray.sort((a, b) => a.Name < b.Name ? -1 : 1).sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
    // this.gridData = new CollectionView(gridarray, { pageSize: 10 });
    gridarray.sort((a, b) => a.TemplateName.localeCompare(b.TemplateName))
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
  }


  recieveObjFromListing(object: any) {
    delete object.newData.CaseType
    object.newData = { ...object.newData, ...{ 'Context': 'Deletion Templates' } };
    
    if (object.newData.Action == 'Edit') {
      object.newData['Id'] = object.oldData['OrgTemplateID']
    }
    this.insertOrEditDeletionTemplate(object)
  }


  insertOrEditDeletionTemplate(data) {
    this.ngxService.start();
    this.vitalHttpServices.templateManagementCE(data.newData,this.destDeployment).subscribe(response => {
      if (response.status == "Success") {
        if (response.content.message.toString().includes('already exist')) {
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this._snackbar.open('Deletion Reasons Already Exists', "Close")
          this.ngxService.stop();
          return
        }
        else {
          if (data.newData.Action === 'Create') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            // response.content['CategoryID'] = response.content['userId']
            this.commonService.auditDetails('id', 'TemplateName', [response.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open('Deletion Reasons Created Successfully', "Close")
            this.getAllDeletionTemplates();
          }
          else if (data.newData.Action === 'Edit') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('OrgTemplateID', 'TemplateName', [data.oldData], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open('Deletion Reasons Updated Successfully', "Close")
            this.getAllDeletionTemplates();
          }
          else {
            this._snackbar.open('Error Occured WHile Processing', "Close")
          }
          // this.addGridData(this.templateData.submenuData);
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this.ngxService.stop();
        this._snackbar.open("failed!", "Close")
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  deleteTemplate(value) {
    if (!this.deleteBtn) {
      value.rowData = { ...value.rowData, ...{ 'Context': 'Deletion Templates', 'Action': 'Delete' } };
      value.rowData['Id'] = value.rowData['OrgTemplateID']
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
          autoFocus: false,
        width: '400px',
        panelClass: 'admin-custom-popup',
        // data: { header: "", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.templateManagementCE(value.rowData,this.destDeployment).subscribe(
            () => {
              this.commonService.auditDetails('OrgTemplateID', 'TemplateName', [value.rowData], [], 'Delete', this.templateData, this.auditableColumns);
              this._snackbar.open('Deletion Reasons Deleted Successfully', 'Close');
              this.getAllDeletionTemplates();
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      if(res.status && res.statuscode){
        this.auditableColumns = JSON.parse(res.content.JsonData);
      }
    })
  }


}
