import { Component, EventEmitter, Output } from '@angular/core';
import {decrement, addNabvTab } from '../../store/actions/navbar.actions';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { LabadminService } from '../../labadmin.service';
import { SubsidemenuComponent } from '../../components/subsidemenu/subsidemenu.component'
import { disableCreationMode } from '../../store/actions/creation.actions';
@Component({
  selector: 'app-tabnav',
  templateUrl: './tabnav.component.html',
  styleUrls: ['./tabnav.component.scss']
})
export class TabnavComponent {

  // navigationList=['Lab','Lab Management','Flags']

  count$: Observable<[]>;
  navigationList$: Observable<[]>;
  fullScreenMode$: Observable<boolean>;
  newValue: any;
  @Output() breadcrumbClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store<{ breadcrum: [], fullScreenMode : boolean }>,private router: Router,private labadminService:LabadminService,private Subsidemenu:SubsidemenuComponent) {
    this.navigationList$ = store.select('breadcrum');
    this.fullScreenMode$ = this.store.select('fullScreenMode')
    // let navigation = window.location.href.split("#")[1]
    // navigation.slice(0,1)
    // this.navigationList$ = navigation
    // console.log(navigation)
  }

  changeRoute(route,index:number){
    if(route.Htext == "Lab Management" ||route.Htext == "Pathology Management" || route.Htext =="Settings"){
      this.newValue = this.labadminService.subSideMenu[0];
      this.Subsidemenu.sideMenuSelected(this.newValue);
    }
    else if(['Create User', 'Edit User', 'Create Client', 'Edit Client', 'Create Provider', 'Edit Provider', 'Create Location', 'Edit Location', 'Edit Referring Physician', 'Create Referring Physician'].includes(route.Htext)) return
    
    else {
      this.store.dispatch(disableCreationMode())
      if(['Users','Providers','Referring Physicians','Locations'].includes(route.Htext)){
          route={...route,showListPage:true,reload:true}
      }
      route=route.Htext=="Clients"?{...route,Level:index,showDetails:false,showListPage:true}:{...route,Level:index}
      this.store.dispatch(addNabvTab({value: route}));
      this.breadcrumbClicked.emit(route);
      this.router.navigate([`${route.routerLink}`])
    }
    

  }


}
