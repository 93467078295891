<app-templates-listing *ngIf="templateData && gridShow && !create && !uploadbtnClicked" [gridHeader]="listedGridHeader"
    [gridData]="PayersGridData" [context]="'Payers'" [hideCreate]="createBtn" [hideEdit]="isEditButton ? editBtn : true"
    [hideUpload]="uploadBtn" [hideExport]="exportBtn" [hideCopy]="true" [hideDelete]="!deleteBtn"
    (CreateTemplateClicked)="createPayer()" [templateData]="templateData" (copyTemplateEventTriggered)="copyFromOrg()"
    (editTemplateClicked)="editPayers($event)" [hideMigrate]="migrateBtn" (migrateEventTriggered)="MigrateGLdata()"
    [enableMigrate]="migrateenable" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel"
    [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
    (uploadTemplateEventTriggered)="uploadPayers()" [isMigrateForPayers]="'Payers'" [gridwidth]="0"
    [isAdmin3]="sourceApp =='VitalDx' ? false : true" [note]="getNote()" [customListActiveTabs]=true>
</app-templates-listing>

<div class="d-flex mt-3" *ngIf="create">
    <div class="col-sm-2 vdm-heading"  *ngIf="!editclicked && create">
        <span>Create Payer</span>
    </div>
    <div class="col-sm-2 vdm-heading"  *ngIf="editclicked">
        <span>Edit Payer</span>
    </div>
    <div class="col-sm-10 button-wrap p-0 mb-2">
        <button class="col-sm-1 btn secondary mr-2" type="button" (click)="returnbutton()">Return</button>
        <button class="col-sm-1 btn primary" (click)="save(newAction,CreateForm)"
            [disabled]="!CreateForm?.valid">Save</button>
    </div>
</div>

<section [formGroup]="CreateForm" class="col-sm-12 p-2 create-wrap" *ngIf="create">
    <div *ngIf="create">
        <div class="col-sm-12 p-0">
            <div class="row">
                <app-custom-input class="col-sm-3 ml-2" required="true" formcontrolname="payerName"
                    labelValue="Payer Name"></app-custom-input>

                <app-custom-input class="col-sm-3" formcontrolname="electronicPayerId"
                    labelValue="Electronic Payer ID"></app-custom-input>

                <div class="col-sm-2 p-0" *ngIf="!editclicked">
                    <button class="col-sm-5 btn primaryBtnColor" (click)="lookup()">
                        Lookup
                    </button>
                </div>

                <div class="col-sm-3 btn-wrap p-0">
                    <div class="d-flex pr-3">
                        <span class="toggle-font">Electronic Payer:</span>
                        <div *ngIf="editclicked">
                            <app-toggle-switch type="secondary" formcontrolname="iselectronicpayer" isDisable=true
                                class="pl-2">
                            </app-toggle-switch>
                        </div>

                        <div *ngIf="!editclicked">
                            <app-toggle-switch type="secondary" formcontrolname="iselectronicpayer" class="pl-2">
                            </app-toggle-switch>
                        </div>
                    </div>

                    <div class="d-flex">
                        <span class="toggle-font">Active:</span>
                        <app-toggle-switch type="secondary" formcontrolname="status" class="pl-2">
                        </app-toggle-switch>
                    </div>
                </div>
            </div>
            <hr class="custom-hr">
        </div>

        <div class="col-sm-12">
            <div class="row">
                <app-custom-input class="col-sm-3 pr-2 pb-2 pl-2 input-elements" formcontrolname="address1"
                    labelValue="Address Line 1"></app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" formcontrolname="address2"
                    labelValue="Address Line 2"></app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" formcontrolname="city" labelValue="City">
                </app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" formcontrolname="state" labelValue="State">
                </app-custom-input>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <app-custom-input class="col-sm-3 pr-2 pb-2 pl-2 input-elements" mask="000000000000000"
                    formcontrolname="zip" labelValue="Zip code"></app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" mask="000.000.0000,0000000000,000.00"
                    formcontrolname="phone" labelValue="Phone">
                </app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" mask="000.000.0000,000.000.0000,000.00"
                    formcontrolname="fax" labelValue="Fax">
                </app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 input-elements" formcontrolname="email" labelValue="Email">
                </app-custom-input>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <app-input-auto-complete labelValue="Billing Type" class="col-sm-3 pr-2 pb-2 pl-2 "
                    [dropDownValue]="billingtypedropdown" [isReadOnly]='editclicked' [value]="getters.billingtype.value"
                    placeholder="Billing Type" (onKeyUpChanges)="billingtypePayerfamily($event, 'billingtype')"
                    value="{{CreateForm.value.billingtype}}"></app-input-auto-complete>
                <app-input-auto-complete labelValue="Payer Family" class="col-sm-3 pr-2 pb-2 "
                    [dropDownValue]="payerfamilydropdown" [isReadOnly]='editclicked' [value]="getters.payerfamily.value"
                    placeholder="Payer Family" (onKeyUpChanges)="billingtypePayerfamily($event, 'payerfamily')"
                    value="{{CreateForm.value.payerfamily}}"></app-input-auto-complete>
            </div>
        </div>


        <div class="col-sm-12 additional-details-container">
            <span>Additional Details</span>
            <div class="horizontal-line">

            </div>
        </div>

        <div class="col-sm-12">
            <div class="row">
                <app-custom-input class="col-sm-3 pr-2 pb-2 pt-2 input-elements" formcontrolname="participatinginfo"
                    labelValue="Participating Information"></app-custom-input>
                <app-custom-input class="col-sm-3 pr-2 pb-2 pt-2 input-elements" formcontrolname="payersguid"
                    labelValue="Payers GUID"></app-custom-input>
            </div>
        </div>
    </div>
</section>

<!-- bulk upload -->
<app-common-bulk-functions *ngIf="uploadbtnClicked" [templateData]="templateData" [uploadClicked]="uploadbtnClicked"
    [selectedUser]="selectedUser" (uploadBack)=backClicked($event) [fromUserCard]="fromUserCard"
    [cardContext]="cardContext"
    [columnsToRemove]="['OrganizationId','AccountId','payername']"></app-common-bulk-functions>


<!-- copy -->
<div *ngIf="copyClicked">

    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Payers</strong>
        </h3>
    </div>

    <form [formGroup]="copyFromOrgFrm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDeplyment">
                    <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-3 example-additional-selection d-flex" appearance="outline">
                <mat-label>Entity<span class="error-msg">*</span></mat-label>
                <input type="text" matInput formControlName="frmOrganization" [(ngModel)]="searchInput1" #searchbar1
                    (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;" [ngModelOptions]="{standalone: true}">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let shows of searchResult">
                        <mat-option class="mat-opt-align"
                            title="{{ shows.organizationname }} ({{shows.organizationid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, shows)"
                            value="{{ shows.organizationname }}">
                            <span>{{ shows.organizationname }} ({{shows.organizationid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchOrgSeries(copyFromOrgFrm.value.frmOrganization)"><em
                        class="fa fa-chevron-down chevron-align"></em></button>
                <button *ngIf="copyFromOrgFrm.value.frmOrganization" matSuffix mat-icon-button aria-label="Clear"
                    title="Reset Search" (click)="clearEntity()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="col-sm-3 example-additional-selection" appearance="outline">
                <mat-label>Locations<span class="error-msg">*</span></mat-label>
                <input type="text" matInput formControlName="frmAccountId" [(ngModel)]="searchInput" #searchbar
                    (click)="fetchLoactionSeries(copyFromOrgFrm.value.frmAccountId)"
                    (keyup)="fetchLoactionSeries(copyFromOrgFrm.value.frmAccountId)" [matAutocomplete]="accauto"
                    placeholder="Search here ..." style="width: 95%;" [ngModelOptions]="{standalone: true}">
                <mat-autocomplete #accauto="matAutocomplete">
                    <div *ngFor="let items of searchResultaccount">
                        <mat-option class="mat-opt-align" title="{{ items.accountname }} ({{items.accountid}})"
                            (onSelectionChange)="selectedCopyDataLocation($event, items)"
                            value="{{ items.accountname }}">
                            <span>{{ items.accountname }} ({{items.accountid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchLoactionSeries(copyFromOrgFrm.value.frmAccountId)"><em
                        class="fa fa-chevron-down chevron-align"></em></button>
                <button *ngIf="copyFromOrgFrm.value.frmAccountId" matSuffix mat-icon-button aria-label="Clear"
                    title="Reset Search" (click)="clearLocation()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="align-center ml-2">
                <button mat-raised-button class="admin-btn-success" (click)="getdefaultpayers()">Get
                    default payers</button>
            </div>
        </div>
        <!-- <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4"> -->
        <div class="col-sm-12 parent-div mt-2 table-align">
            <div class="header d-flex p-0 table-content" *ngIf="gridWidth>0">
                <div class="header-wrap">
                    <span class="buttonClass">
                    </span>
                    <span class="widthClass pl-1" *ngFor="let header of sheetHeader">
                        <div class="dataTable valueDiv">{{header}}</div>
                    </span>
                </div>
            </div>
            <div class="row p-0" style="width: fit-content !important;">
                <div cdkDropList cdkDropListOrientation="vertical" class="col-sm-12 tableScroll" *ngIf="!Gridata">
                    <span *ngIf="gridWidth > 0">
                        <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'"
                            [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive')?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                            *ngFor="let item of griddata">
                            <input class="buttonClass" *ngIf="!postUpload" type="checkbox"
                                (change)="payerChecked($event,item)">
                            <div class="text-muted text-ellipsis dataTable widthClass"
                                *ngFor="let header of sheetHeader">
                                <div *ngIf="header != 'ResultMessage' && header != 'Result'" class="valueDiv"
                                    [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                                    {{ displayedColumns(item,header.toLowerCase()) | stripHtml }}</div>
                                <div *ngIf="header == 'ResultMessage' || header == 'Result'"
                                    [ngClass]="item['isvalid']==1 || item['result']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||['isvalid']==0 ? 'text-danger pl-1':null"
                                    class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)"
                                    #isElipsed>
                                    {{ displayedColumns(item,header.toLowerCase()) | stripHtml }}</div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="col-sm-12 nodata-header-wrap align-center" *ngIf="noDataFound">
                <span class="col-sm-12 nodata-wrapper"> No Data Available</span>
            </div>
        </div>
        <div class="col-sm-12 pl-0 mt-4">


            <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
            <span style="float: right;">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload && gridWidth > 0"
                    (click)="removegrid()">Cancel</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload"
                    (click)="refreshGrid()">Finish</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload && gridWidth > 0"
                    (click)="uploadBulk()">Approve</button>
                <button mat-raised-button class="admin-btn-success" *ngIf="postUpload"
                    (click)="ExportExcel(flexgrid)">Download Result</button>
            </span>
        </div>

    </form>
</div>

<div *ngIf="migrateClicked">

    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Migrate Payers</strong>
        </h3>
    </div>
    <div class="col-sm-12 p-0 serach-btn-wrap">
        <div class="global-search-panel d-flex col-sm-4 mr-3 p-0">
            <!-- <div style="display: flex; margin-right: 3px;margin-left: 4px;"> -->
                <div class="d-flex mr-1 ml-1">
                <mat-icon class="search-icon">search</mat-icon>
            </div>
            <div class="standing-line">
            </div>
            <input type="text" class="search-input" [placeholder]="'Search ' + templateData.menuURL"
                [(ngModel)]="searchText" (keyup)="filtertemplates()">
            <ng-container *ngIf="searchText">
                <div class="standing-line">
                </div>
                <!-- <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;"> -->
                    <div  class="d-flex mr-3 ml-4 cursor">
                    <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
                </div>
            </ng-container>
        </div>

        <div class="button-wrap p-0">
            <app-button mat-dialog-close class="mr-3" buttonclass="secondary"  *ngIf="!postMigrate" customClass="admin"
                (click)="back()">Return</app-button>
            <app-button mat-dialog-close class="mr-3" buttonclass="secondary"  *ngIf="postMigrate" customClass="admin"
                (click)="backafterapprove()">Back</app-button>
            <app-button mat-dialog-close buttonclass="secondary" *ngIf="!postMigrate" customClass="admin"
                (click)="getCheckMigrateData()">Proceed</app-button>

            <app-button mat-dialog-close buttonclass="secondary" *ngIf="postMigrate" customClass="admin"
                (click)="approveMigrate()">Approve</app-button>
        </div>
    </div>

    <div class="col-sm-12 mt-2">
        <div class="header d-flex">
            <div class="header-wrap">
                <span class="buttonClass">
                </span>
                <span class="widthClass pr-1" *ngFor="let header of migrateHeaders">
                    <div class="dataTable valueDiv">{{header}}</div>
                </span>
            </div>
        </div>
        <div class="container-fluid col-sm-12 header-wrap p-0">
            <div class="col-sm-12 p-0">
                <div class="col-sm-12 p-0">
                    <cdk-virtual-scroll-viewport itemSize="15" class="tableScroll cstcdkscroll">
                        <div [ngClass]="(item.IsActive === 'Inactive' || item.isActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive' || item.payersstatus === 'Inactive')?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                            *cdkVirtualFor="let item of filterdData | searchfiltersTemplate: templatefilter">
                            <input class="buttonClass" (change)="migratePayerChecked($event,item)" type="checkbox"
                                [(ngModel)]="item['isChecked']" [checked]="item['isChecked']">
                            <div appTooltipEllipsis class="text-muted text-ellipsis dataTable widthClass pl-1" #isElipsed
                                *ngFor="let header of migrateHeaders">
                                {{ displayedColumns(item,header) | stripHtml }}
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                    <div class="col-sm-12 nodata-header-wrap" *ngIf="(filterdData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && filterdData?.length !== 0">
                        <span class="col-sm-12 nodata-wrapper"> No Results </span>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>