import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { ManageSitesComponent } from '../manage-sites/manage-sites.component';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CreateActivityTrackerInput, Attachments, Operations } from 'src/app/model/activity-tracker';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { DatePipe } from '@angular/common';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-manage-site-orders',
  templateUrl: './manage-site-orders.component.html',
  styleUrls: ['./manage-site-orders.component.scss']
})
export class ManageSiteOrdersComponent implements OnInit {
  SubMenuCardModel: any;
  @Input() public templateData: any;
  @Input() public buttonData: any;
  @Input() showHeaderModule = true;
  public orgid: string;
  columns: ColDef[];
  columnssite: ColDef[];
  noDataFound: boolean;
  gridheader: string[];
  gridData: wjcCore.CollectionView<any>;
  copygridData: wjcCore.CollectionView<any>;
  gridShow1: boolean;
  showPaginationMainGrid: boolean;
  createdpage: boolean = false;
  action: string;
  openmangesite: boolean = false;
  Jarsvalue: any = [];
  copyJarsdataschema: any =[];
  sitenamedistinct: any;
  siteschemadistinct: any;
  @ViewChild('txtInput') txtInput: ElementRef;
  sitesequenceleableshow: boolean = false;
  siteordersitechemaflag: boolean = true;
  sitelebelvalueshow: any = [];
  uniqueData: any = [];
  siteorderuniqueData:any;
  siteschemavalues: any = [];
  Bodysitenames: any = [];
  sitelebelaphabetflag: boolean = false;
  allFields: any = [{}];
  uploadClicked: boolean = false;
  bulkUpload: boolean;
  showInvalidColumns: boolean;
  postUpload: boolean = false;
  excelDataArray: any[];
  destDeployment: string = '';
  sheetsToSelect: any[];
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  workBook: any;
  invalidColumns: any;
  sheetHeader: any;
  selector: Selector;
  selectedData: any = [];
  gridwidth: number = 0;
  showDelete: boolean = false;
  item: any;
  numberOfJarsValue: string;
  editTemplateData: any;
  editvalueforsitename: any = [];
  oldeditvalueforsiteorder: any = [];
  siteSchemaname: any;
  distinctStiteNamelist: any;
  copyDataClicked: boolean=false;
  organizationList: any;
  DeploymentKeys: string[] = [];
  searchResult: any[];
  copysechmaflag: boolean;
  searchJarResult: any[];
  jarList: any = [];
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  dataForValidation: any[] = [];
  selectedCopyData: any = [];
  noDataFoundCopy: boolean = false;
  siteFormat: number = 2;
  deletBtn: boolean;
  copyflagsequence: boolean;
  auditableColumns: any;
  oldEditdata: any;
  number: any;
  acitivity:{};
  createActivityInput = new CreateActivityTrackerInput();
  createActivityOperations = new Operations();
  createActivityAttachments = new Attachments();
  oldsitelebelaphabetflag: boolean;
  activityEntity:any;
  groupBy: string[] = [];
  listGridheaders: { "Name": string; "type": string; "key": string; "colWidth": string; }[];
  showHelpMessage: boolean = false;
  FinalgroupedData: any[] = []; // Define groupedData array
  JarData:any[] = [];
  editNumberOfJars: any;

  @Output() showTab: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public _snackbar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private ManageSitesComponent: ManageSitesComponent,
    private dialog: MatDialog,
    private datashare: DataShareService,
    private commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    public ngxService: NgxUiLoaderService,
    public activityService: ActivityTrackerService,
    public datePipe: DatePipe
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare
    );
    this.orgid = sessionStorage.getItem('org_id').toString();
    this.columns = [
      new ColDef('Sequence', 'Sequence'),
      new ColDef('Numberofjars', 'Number Of Jars')
   ];
  }

  frm_SiteOrder = this._fb.group({
    frmNumberOfJars: [''],
    frmSechmaSite: [''],
    frmSitelebel: '',
    frmBodySite: this._fb.array([]),
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase()],
    frmOrganization: [''],
    frmOrgID: [''],
    frmJars: [''],
  });



  ngOnInit(): void {

    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.getSiteFormat();
    this.getAuditableDetails('SiteOrder');
    // this.commonService.createContext(this.templateData.secondarykeys, '', 'Site Order');
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    this.listGridheaders  = this.getDataHeaders(this.gridData);
  }

  getDataHeaders(gridData){
    let valueObj = [{
              "Name": "Site Name",
              "type": "text",
              "key": "SiteName",
              "colWidth": "col-sm-4"
            },
            {
              "Name": "Sequence",
              "type": "text",
              "key": "Sequence",
              "colWidth": "col-sm-6"
            }]
    return valueObj;
  }
  // Get siteFormat type based on the casetype
  getSiteFormat() {
    let site = '';
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype };
    let query = this.SubMenuCardModel.GetQuery('casetypesiteformat');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices
      .GetData(queryResult)
      .toPromise()
      .then(
        data => {
          if (!data.errors) {
            if (data) {
              this.siteFormat = data.data.submenuData[0].SiteFormat ? data.data.submenuData[0].SiteFormat : 2;
              this.checktheSiteSchemaFlag();
              this.GetButtonAccess();
            }
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  //button controller
  GetButtonAccess() {
    let seletedMenuPermissions = this.buttonData;
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'UploadSiteorder':
          this.uploadBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'CreateSiteorder':
          this.createBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'EditSiteorder':
          this.editBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'ExportSiteorder':
          this.exportBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'CopyToOrgSiteorder':
          this.copyBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'DeleteSiteorder':
          this.deletBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }


  //--get the list of data from the server
  getListToFetchSiteOrders() {
    let query;
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id').toString(), "Casetype": this.templateData.secondarykeys.casetype.toString() };
    if (this.siteFormat != 2) {
      if (this.siteordersitechemaflag == true) {
        query = this.SubMenuCardModel.GetQuery('mangesiteorderbasedonschema');
      }
      else {
        query = this.SubMenuCardModel.GetQuery('mangesiteorder');
      }
    }
    else {
      if (this.siteordersitechemaflag == true) {
        query = this.SubMenuCardModel.GetQuery('mangesiteorderbasedonschemaformatetwo');
      }
      else {
        query = this.SubMenuCardModel.GetQuery('mangesiteorderformatetwo');
      }
    }


    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(siteorder => {
      this.ngxService.stop();
      if (!siteorder.errors) {
        this.ngxService.start();
        this.templateData.submenuData = siteorder.data.submenuData;
        this.distinctStiteNamelist = siteorder.data.submenuData1;
        if (this.createdpage == true && this.action != "Edit Site Orders") {
          this.editTemplateData = this.templateData.submenuData;
          this.ngxService.stop();
          return;
        }
        if (this.action != "Edit Site Orders") {
           this.addGridData(this.templateData.submenuData);
        }
        if (this.noDataFound == true) {
          this.editTemplateData = this.templateData.submenuData;
        }
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
    this.ngxService.stop();
  }

  addGridData(templateData: any) {
    this.editTemplateData = templateData;
    this.templateData.menuURL = 'Site Order'
    let primary = {};
    let gridarray = [];
    this.gridheader = ['Numberofjars', 'Sitescheme', 'Sequence', 'SiteName', 'OrganizationId'];
    if(templateData?.length>0)
      {
    for (let i = 0; i < templateData.length; i++) {
        primary = {};
        for (let [key, value] of Object.entries(templateData[i])) {
            if (key == 'SiteName') {
                if (value == '' || value == null) {
                    value = 'Not Specified';
                    primary[key] = value;
                }
                primary[key] = value;
            }
            for (let j = 0; j < this.gridheader.length; j++) {
                if (key === this.gridheader[j]) {
                    primary[key] = value;
                }
            }
        }
        gridarray.push(primary);
    }
    if (this.sitelebelaphabetflag) {
        // Sort by Numberofjars in ascending order, then by Sequence in ascending order
        gridarray.sort((a, b) => {
            if (a.Numberofjars === b.Numberofjars) {
                return a.Sequence - b.Sequence;
            }
            return a.Numberofjars - b.Numberofjars;
        });
    } else {
        // Sort by Numberofjars in ascending order, then by Sequence in descending order
        gridarray.sort((a, b) => {
          if (a.Numberofjars === b.Numberofjars) {
              // Compare Sequence as strings
              if (a.Sequence < b.Sequence) {
                  return -1; // a should come before b
              } else if (a.Sequence > b.Sequence) {
                  return 1; // b should come before a
              } else {
                  return 0; // sequences are equal
              }
          } else {
              return a.Numberofjars - b.Numberofjars;
          }
      });
    }
    gridarray = this.getGroupedData(gridarray); // Modify to pass two groupBy keys
    this.groupBy = ['Number of Jars', 'Site Scheme']; // Update groupBy assignment
    this.gridData = new CollectionView(gridarray);
    this.gridShow1 = true;
    this.showTab.emit(true);
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
    }
    else{
      this.groupBy = []; // for no data
      this.gridData = new CollectionView();
      this.gridShow1 = true;
      this.showTab.emit(true);
      this.noDataFound = false;
      this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
      return gridarray;
    }
}
  //#region Tooltip for Grid
  initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
    grid.groupHeaderFormat = '{name}: <b>{value}</b>'
  }
  //#endregion

 getGroupedData(arrayData) {
    const hierData = new Map(); // Using a Map to store grouped data

    arrayData.forEach(item => {
        const key = item.Numberofjars; // Key will be Numberofjars

        if (!hierData.has(key)) {
            hierData.set(key, {}); // Setting key as Numberofjars and creating an object for Sitescheme
        }
        const schemaMap = hierData.get(key);
        if (!schemaMap[item.Sitescheme]) {
            schemaMap[item.Sitescheme] = []; // Setting key as Sitescheme
        }
        schemaMap[item.Sitescheme].push(item); // Adding data matching the key set previously
    });
    const groupedData = [];
    hierData.forEach((schemaMap, numberOfJars) => {
      const dataList = {
          Name: numberOfJars,
          DataList: []
      };

      Object.values(schemaMap).forEach((schemaData: any[]) => {
          schemaData.forEach(data => {
              dataList.DataList.push(data);
          });
      });
      groupedData.push(dataList);
  });
    return groupedData;
}



  //Add page
  createdSiteOrders(event) {

    if(!event.bulkUpload){
    if(!this.createBtn){
    this.frm_SiteOrder.reset();
    this.resetFormErrors();
    this.noDataFound = false;
    this.createdpage = true;
    this.gridShow1 = false;
    this.showTab.emit(false);
    this.openmangesite = false;
    this.action = 'Create Site Orders';
    this.uploadClicked = false;
    this.frm_SiteOrder.controls.frmOrganization.enable();
    this.frm_SiteOrder.controls.frmDepKey.enable();
    this.frm_SiteOrder.controls.frmNumberOfJars.enable();
    this.activityEntity.entityId ='';
    this.activityService.setActivitySession(this.activityEntity);
    }}
    else{
      this.loadUploadScreen();
    }
  }

  //edit page
  editClicked(value) {
    value=value.value;
    if(!this.editBtn){
    this.createdpage = true;
    this.gridShow1 = false;
    this.showTab.emit(false);
    this.openmangesite = false;
    this.action = 'Edit Site Orders';
    this.uploadClicked = false;
    let data = value[0];
    this.oldEditdata = data;
    this.editNumberOfJars=data.Numberofjars
    this.siteSchemaname = data.Sitescheme;
    this.frm_SiteOrder.controls['frmSechmaSite'].setValue(data.Sitescheme);
    this.filterNumberofJars(data.Numberofjars);
    this.activityEntity.entityId = data.Numberofjars;
    this.activityService.setActivitySession(this.activityEntity);
    }

  }
  // edit part filters based on the jars

  //saveAndCreate
  saveAndCreate(value, datavalue) {
    this.CreatedSiteOrder(value, datavalue);
  }

  //#region to Edit Site Order
  editSiteOrder(value, datavalue) {
    let data = [];
    let k = 1;
   let oldeditdata= this.editTemplateData.filter(x => x.Numberofjars == this.numberOfJarsValue && x.Sitescheme == value.frmSechmaSite)
   oldeditdata= oldeditdata.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
    let acitidata={
      "Jars":{"oldValue":oldeditdata[0].Numberofjars,"newValue":this.numberOfJarsValue},
      "Site Scheme":{"oldValue":oldeditdata[0].Sitescheme == null ? '' : oldeditdata[0].Sitescheme,"newValue":value.frmSechmaSite== null ? '' : value.frmSechmaSite},
   }
   for(let m = 0; m < this.sitelebelvalueshow.length; m++)
   {
    if(this.editvalueforsitename[m]!=oldeditdata[m].SiteName)
    {

      acitidata["Site Name"+ ' '+(m+1)+""]={};
     acitidata["Site Name"+' '+(m+1)+""]["oldValue"]=oldeditdata[m].SiteName;
     acitidata["Site Name"+' '+(m+1)+""]["newValue"]= this.editvalueforsitename[m];

    }
   }
   let sitenamecheck= this.editvalueforsitename.filter(value => value!=null || value!=undefined)
   let isEmptyStringPresent = sitenamecheck.includes('');
   if(sitenamecheck.length==Number(this.numberOfJarsValue) && !isEmptyStringPresent)
    {
      let firstvalue=this.uniqueData.find(value => value.BodyName==sitenamecheck[0]);
      if(firstvalue){
        for (var i = 0; i < this.sitelebelvalueshow.length; i++) {
      var obj = {
        "organizationid": this.orgid,
        "casetype": this.templateData.secondarykeys.casetype.toString(),
        "jars": this.numberOfJarsValue,
        "sitescheme": value.frmSechmaSite,
        "sequence": k,
        "sequencelabel": this.sitelebelvalueshow[i],
        "sitename": this.editvalueforsitename[i],
        "type": "Edit",
      }
      k++;
      data.push(obj);
       }
       if (this.numberOfJarsValue <= this.editvalueforsitename.length){
    this.vitalHttpServices.updateSiteOrder(data, this.destDeployment).subscribe(result => {
      this.noDataFound = false;
      this.ngxService.stop();
      if (!result.errors || result.content.length > 0) {
        this.sitesequenceleableshow = false;
        if(Object.keys(acitidata).length>2)
        {
          this.createActivityObject(this.numberOfJarsValue,'Site Order','Edit',acitidata)
        }
        if (result.content[0].NoteMessage == 'Record already exists' || result.content[0].NoteMessage == 'Updated Successfully') {
          this._snackbar.open("Updated Successfully", 'Close');
        }
        else {
          this._snackbar.open(result.content[0].NoteMessage, 'Close');
        }
        //this.auditDetails([], data, 'saved');
        if (datavalue == 'close') {
          this.createdpage = false;
          this.resetFormErrors();
          this.frm_SiteOrder.reset();
          this.action = '';
          this.sitelebelvalueshow = [];
          this.Bodysitenames = [];
          this.siteordersitechemaflag = true;
          this.sitesequenceleableshow = false;
          this.gridShow1 = true;
          this.showTab.emit(true);
          this.checktheSiteSchemaFlag();
          return;
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    });
      }
      else {
    this._snackbar.open('Please Select Site', 'Close');
      }
    }
    else {
      sitenamecheck='';
      this._snackbar.open('Please Select Site', 'Close');
    }
    }
    else {
      sitenamecheck='';
      this._snackbar.open('Please Select Site', 'Close');
    }
  }
  //#endregion
  //form reset method
  resetFormErrors() {
    Object.keys(this.frm_SiteOrder.controls).forEach(key => {
      this.frm_SiteOrder.controls[key].setErrors(null)
    });
  }

  findNumberOfJars() {
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id').toString(), "Casetype": this.templateData.secondarykeys.casetype.toString() };
    let query;
    this.Jarsvalue=[];
     this.copyJarsdataschema= [];
    if (this.siteordersitechemaflag == true) {
      query = this.SubMenuCardModel.GetQuery('mangesiteorderbasedonschema');
    }
    else {
      query = this.SubMenuCardModel.GetQuery('mangesiteorderbasedonjar');
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(siteorder => {
      this.ngxService.stop();
      if (!siteorder.errors) {
        this.ngxService.start();
        if (this.siteordersitechemaflag == true) {
          this.siteschemadistinct = siteorder.data.submenuData;
          this.siteschemavalues = [...new Map(this.siteschemadistinct.map(item => [item['Siteschema'], item])).values()];
        }
        this.sitenamedistinct = siteorder.data.submenuData;
        for (var i = 0; i < siteorder.data.submenuData.length; i++) {
          if(siteorder.data.submenuData[i].Sitescheme==null)
          {
            this.copyJarsdataschema.push(siteorder.data.submenuData[i].Numberofjars);
          }
          else{
          this.copyJarsdataschema.push(siteorder.data.submenuData[i].Numberofjars + '('+ siteorder.data.submenuData[i].Sitescheme + ')');
          }
          this.copyJarsdataschema=[... new Set( this.copyJarsdataschema)]
          if (this.Jarsvalue.length == 0) {
            this.Jarsvalue.push(siteorder.data.submenuData[i].Numberofjars);
          }
          else {
            let value = this.Jarsvalue.filter(x => x == siteorder.data.submenuData[i].Numberofjars)
            if (value.length == 0) {
              this.Jarsvalue.push(siteorder.data.submenuData[i].Numberofjars);
            }
          }
        }
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
    this.ngxService.stop();

  }
  checktheSiteSchemaFlag() {
    let queryVariable = { "orgid": sessionStorage.getItem('org_id').toString(), "extrname": "enable_site_scheme" };
    let query = this.SubMenuCardModel.GetQuery('getentityextattributesFlag');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(siteschemaflag => {
      this.ngxService.stop();
      if (!siteschemaflag.errors) {
        let queryVariable = { "orgid": sessionStorage.getItem('org_id').toString(), "extrname": "Sites.SequenceLabelInNumberFormat" };
        let query = this.SubMenuCardModel.GetQuery('getentityextattributesFlag');
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.ngxService.start();
        this.vitalHttpServices.GetData(queryResult).subscribe(siteschemaflagsvalue => {
          this.ngxService.stop();
          if (!siteschemaflagsvalue.errors) {
            this.ngxService.stop();
            if (siteschemaflag.data.submenuData.length > 0) {
              this.siteordersitechemaflag =  true;
            }
            else {
              this.siteordersitechemaflag = true;
            }
            if (siteschemaflagsvalue.data.submenuData.length > 0) {
              this.sitelebelaphabetflag = siteschemaflagsvalue.data.submenuData[0].ExtAttrValue.toLowerCase() == 'true' ? true : false;

            }
            else {
              this.sitelebelaphabetflag = false;
            }
            this.oldsitelebelaphabetflag=this.sitelebelaphabetflag;
            if (this.createdpage == true) {
              this.getListToFetchSiteOrders();
              this.findNumberOfJars();
              this.ngxService.stop();
            }
            else {
              this.getListToFetchSiteOrders();
              this.findNumberOfJars();
              this.ngxService.stop();
            }
          }
        }, error => {
          console.error(error);
          this.ngxService.stop();
        });
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
    this.ngxService.stop();
  }
  //#region Filter users
  filterNumberofJars(value) {
    this.Bodysitenames = [];
    this.numberOfJarsValue = value;
    let editMode = this.action.toString().match(/Edit/i) ? true : false;
    if (editMode) {
      this.getListToFetchSiteOrders();

      this.frm_SiteOrder.controls['frmNumberOfJars'].setValue(value)
    }
    if(!editMode)
      {
        this.frm_SiteOrder.controls['frmNumberOfJars'].setValue(value);
      }

    //based on the falg it will worked
    this.sitelebelvalueshow = [];
    this.editvalueforsitename = [];
    let sitesequenceleable;
    let editsiteordervalue;
    if (editMode) {
      editsiteordervalue = this.editTemplateData.filter(x => x.Numberofjars == value && x.Sitescheme == this.siteSchemaname);
      editsiteordervalue = editsiteordervalue.sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
    }

    sitesequenceleable = this.sitenamedistinct.filter(x => x.Numberofjars == Number(value));
    if (!editMode && sitesequenceleable.length == 0) {
      sitesequenceleable = Number(value)
    }

    if (this.siteordersitechemaflag) {
      if (!sitesequenceleable[0]) {
        this.siteschemavalues = null;

      } else {
        this.siteschemavalues = [...new Map(sitesequenceleable.map(item => [item['siteScheme'], item])).values()];
      }
    }

    this.uniqueData = [...new Map(this.distinctStiteNamelist.map(item => [item['BodyName'], item])).values()];
    this.siteorderuniqueData=this.uniqueData.map((i)=>i.BodyName);

    if (editMode) {
      for (let k = 0; k < editsiteordervalue.length; k++) {
        for (let v = 0; v < this.uniqueData.length; v++) {
          if (this.uniqueData[v].BodyName == editsiteordervalue[k].SiteName) {
              this.editvalueforsitename.push(this.uniqueData[v].BodyName);
           // this.Bodysitenames.push(this.uniqueData[v].BodyName);
          }
        }
      }
      for (let v = 0; v < this.uniqueData.length; v++) {
        this.Bodysitenames.push(this.uniqueData[v].BodyName);
      }
    }
    else {
      for (let v = 0; v < this.uniqueData.length; v++) {
        this.Bodysitenames.push(this.uniqueData[v].BodyName);
      }
      if(this.Jarsvalue.length>0){
      let maxnumber=Math.max(...this.Jarsvalue)
       if(value < maxnumber) {
        let copy;
        this.number=maxnumber;
        copy = this.editTemplateData.filter(x => x.Numberofjars ==maxnumber);
        let siteschema=copy[copy.length - 1].Sitescheme
        if(siteschema!=null && siteschema!)
        {
          copy = this.editTemplateData.filter(x => x.Numberofjars == this.number && x.Sitescheme==siteschema);
         this.number=copy[0].Numberofjars + '('+ copy[0].Sitescheme + ')';
        }
        copy= copy.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
        for (let i=0; i<copy.length; i++) {
         this.editvalueforsitename.push(copy[i].SiteName);
        }
       }
       else{
        this.number= this.Jarsvalue.find((element) => element ==value-1);
         let copy;
         if(!this.number)
         {
            let data=[];
            this.number=this.findClosest(this.Jarsvalue,this.Jarsvalue.length,value-1);
            copy = this.editTemplateData.filter(x => x.Numberofjars == this.number);
            let siteschema=copy[copy.length - 1].Sitescheme
            if(siteschema!)
            {
              copy = this.editTemplateData.filter(x => x.Numberofjars == this.number && x.Sitescheme==siteschema);
              this.number=copy[0].Numberofjars + '('+ copy[0].Sitescheme + ')';
            }
            copy= copy.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
            for (let i=0; i<copy.length; i++) {
             this.editvalueforsitename.push(copy[i].SiteName);
            }
         }
         else{
          copy = this.editTemplateData.filter(x => x.Numberofjars == this.number);
          let siteschema=copy[copy.length - 1].Sitescheme
          if(siteschema!=null && siteschema!)
          {
            copy = this.editTemplateData.filter(x => x.Numberofjars == this.number && x.Sitescheme==siteschema);
            this.number=copy[0].Numberofjars + '('+ copy[0].Sitescheme + ')';
          }
          copy= copy.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
          for (let i=0; i<copy.length; i++) {
           this.editvalueforsitename.push(copy[i].SiteName);
          }
         }
         for(let p=0; p<value;p++) {
         if(this.editvalueforsitename.length !=value){
          this.editvalueforsitename.push(this.Bodysitenames.find((e) => e.toLowerCase()=='select'))
         }
        }

       }
      }
      else{
         for(let p=0; p<value;p++) {
         if(this.editvalueforsitename.length !=value){
          this.editvalueforsitename.push(this.Bodysitenames.find((e) => e.toLowerCase()=='select'))
         }
        }
      }
    }
    if ((sitesequenceleable.length > 0 || sitesequenceleable > 0) && this.Bodysitenames.length > 0) {
      this.sitesequenceleableshow = true;
      if (this.sitelebelaphabetflag == true) {
        var lastcharnumber;
        lastcharnumber = value;
        for (let i = 1; i <= lastcharnumber; i++) {
          this.sitelebelvalueshow.push(i);
        }
      }
      else {
        var charnumber = 65;
        var lastcharnumber;
        lastcharnumber = charnumber + Number(value);
        for (var i = charnumber; i < lastcharnumber; i++) {
          this.sitelebelvalueshow.push(String.fromCharCode(i));
        }
      }
    }
    else {
      if (this.Bodysitenames.length > 0) {
        this.sitesequenceleableshow = false;
      }
      else {
        this._snackbar.open('Site Is Not Present', 'Close');
      }
    }
    //this.setBodySites()
  }
  //
  initializedGrid(flexgrid) {
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
    flexgrid.groupHeaderFormat = '{name}: <b>{value}</b>'
  }
  //#region to save the bodysite data
  SaveBodySitename(data, index) {
    let count = index;
    if (this.editvalueforsitename.length == 0) {
      this.Bodysitenames.push(data);
    }
    else {
      this.editvalueforsitename[count] = data;
    }

    this.frm_SiteOrder.patchValue({
      frmLblBodySite: data
    })

  }
  //#endregin

  //#region to create a bodysite
  CreatedSiteOrder(value, datavalue) {
    if (this.action == 'Edit Site Orders') {
      this.editSiteOrder(value, datavalue);
    }
    else {
      if (value.frmNumberOfJars != null && value.frmNumberOfJars!='') {
       let sitenamecheck= this.editvalueforsitename.filter(value => value!=null || value!=undefined)
       let isEmptyStringPresent = sitenamecheck.includes('');
       if(sitenamecheck.length>=1 && !isEmptyStringPresent)
        {
          let firstvalue=this.uniqueData.find(value => value.BodyName==sitenamecheck[0]);
          if(firstvalue){
            if(sitenamecheck.length==this.editvalueforsitename.length){
                sitenamecheck='';
                if (this.numberOfJarsValue <= this.editvalueforsitename.length ) {
                  let data = [];
                  let k = 1;
                  let duplicatedcount = 0;
                  let acitidata={
                     "Jars":{"oldValue":'',"newValue":this.numberOfJarsValue},
                     "Site Scheme":{"oldValue":'',"newValue":value.frmSechmaSite == null ? '' : value.frmSechmaSite},
                  }

                  for (var i = 0; i < this.sitelebelvalueshow.length; i++) {
                    var obj = {
                      "organizationid": this.orgid,
                      "casetype": this.templateData.secondarykeys.casetype.toString(),
                      "jars": this.numberOfJarsValue,
                      "sitescheme": value.frmSechmaSite == null ? null : value.frmSechmaSite,
                      "sequence": k,
                      "sequencelabel": this.sitelebelvalueshow[i],
                      "sitename": this.editvalueforsitename[i],
                      "type": "Create",
                     }
                    // acitidata["sequence"+' '+(i+1)+""]={};
                     acitidata["Site Name"+ ' '+(i+1)+""]={};
                    //  acitidata["sequence"+' '+(i+1)+""]["oldvalue"]='';
                    // acitidata["sequence"+' '+(i+1)+""]["newvalue"]= this.sitelebelvalueshow[i].toString();
                    acitidata["Site Name"+' '+(i+1)+""]["oldValue"]='';
                    acitidata["Site Name"+' '+(i+1)+""]["newValue"]= this.editvalueforsitename[i];
                    k++;
                    data.push(obj);
                  }

                  for (let check = 0; check < data.length; check++) {
                    if (this.editTemplateData != undefined) {
                      let duplicatecheck;
                      if (this.siteordersitechemaflag) {
                        duplicatecheck = this.editTemplateData.some(x => x.Numberofjars == data[check].jars && x.Sitescheme == data[check].sitescheme);
                      }
                      else {
                        duplicatecheck = this.editTemplateData.some(x => x.Numberofjars == data[check].jars);
                      }

                      if (duplicatecheck) {
                        duplicatedcount++;
                      }
                    }
                    else {
                      duplicatedcount = 0;
                    }
                  }
                  if (duplicatedcount == 0)
                    this.vitalHttpServices.updateSiteOrder(data, this.destDeployment).subscribe(result => {
                      this.noDataFound = false;
                      this.ngxService.stop();
                      if (!result.errors && result.content.length > 0) {
                        this._snackbar.open(result.content[0].NoteMessage, 'Close');
                           this.createActivityObject(this.numberOfJarsValue,'Site Order','Create',acitidata)
                        //  this.auditDetails([], data, 'saved');
                        if (datavalue == 'close') {
                          this.createdpage = false;
                          this.resetFormErrors();
                          this.Bodysitenames = [];
                          this.sitelebelvalueshow = [];
                          this.siteordersitechemaflag = true;
                          this.sitesequenceleableshow = false;
                          this.gridShow1 = true;
                          this.showTab.emit(true);
                          this.checktheSiteSchemaFlag();
                          return
                        }
                        else if (datavalue == 'Add') {
                          this.frm_SiteOrder.reset();
                          this.createdpage = true;
                          this.gridShow1 = false;
                          this.showTab.emit(false);
                          this.sitelebelvalueshow = [];
                          this.Bodysitenames = [];
                          this.siteordersitechemaflag = true;
                          this.sitesequenceleableshow = false;
                          this.checktheSiteSchemaFlag();
                          this.resetFormErrors();

                        }
                      }
                      else {
                        this.ngxService.stop();
                        this._snackbar.open('Something went wrong.Please try again', 'Close');
                      }
                    }, error => {
                      this.ngxService.stop();
                      this._snackbar.open('Something went wrong.Please try again', 'Close');
                      console.error(error)
                    })
                  else {
                    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
                      disableClose: true,
                      width: '360px',
                      data: { header: "", message: "Site Order with same Jar count exists. Still want to proceed with the selected new sites ?",alert: "Do you want to continue?", continue: "yes", cancel: "no" }
                    })
                    return dialogRef.afterClosed().toPromise().then(result => {
                      if (!result.errors) {
                        this.vitalHttpServices.DeleteSiteOrder(obj, this.destDeployment).subscribe(
                          (deletevalue) => {
                            if (!deletevalue.error && !deletevalue.content[0].NoteMessage || deletevalue.content.length > 0) {
                              this.vitalHttpServices.updateSiteOrder(data, this.destDeployment).subscribe(result => {
                                this.noDataFound = false;
                                this.ngxService.stop();
                                if (!result.errors && result.content.length > 0) {
                                  this._snackbar.open(result.content[0].NoteMessage, 'Close');
                                  this.createActivityObject(this.numberOfJarsValue,'Site Order','Create',acitidata)
                                  //  this.auditDetails([], data, 'saved');
                                  if (datavalue == 'close') {
                                    this.createdpage = false;
                                    this.Bodysitenames = [];
                                    this.siteordersitechemaflag = true;
                                    this.sitesequenceleableshow = false;
                                    this.gridShow1 = true;
                                    this.showTab.emit(true);
                                    this.checktheSiteSchemaFlag();
                                    return
                                  }
                                  else if (datavalue == 'Add') {
                                    this.frm_SiteOrder.reset();
                                    this.createdpage = true;
                                    this.gridShow1 = false;
                                    this.showTab.emit(false);
                                    this.Bodysitenames = [];
                                    this.siteordersitechemaflag = true;
                                    this.sitesequenceleableshow = false;
                                    this.checktheSiteSchemaFlag();
                                    this.resetFormErrors();
                                    this.getListToFetchSiteOrders();
                                  }
                                }
                                else {
                                  this.ngxService.stop();
                                  this._snackbar.open('Something went wrong.Please try again', 'Close');
                                }
                              }, error => {
                                this.ngxService.stop();
                                this._snackbar.open('Something went wrong.Please try again', 'Close');
                                console.error(error)
                              })
                            }
                            else {
                              this.ngxService.stop();
                              this._snackbar.open('Something went wrong.Please try again', 'Close');
                            }
                          },
                          error => {
                            console.error('Error deleting item:', error);
                          }
                        );
                      }

                    })
                  }
                }
                else {
                  this._snackbar.open('Please Select Site', 'Close');
                }
              }
              else {
                sitenamecheck='';
                this._snackbar.open('Please Select Site', 'Close');
              }
          }
          else{
            sitenamecheck='';
            this._snackbar.open('Please Select Site', 'Close');
          }
        }
        else{
          sitenamecheck='';
                this._snackbar.open('Please Select Site', 'Close');
        }
      }
      else {
        this._snackbar.open('Please Provide No of Jars', 'Close');
      }
    }
  }
  //#endregion

  //#region for back or refersh
  Refershback() {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.ngxService.start("Refershback");
    this.createdpage = false;
    this.uploadClicked = false;
    this.uniqueData = [];
    this.action = '';
    this.siteordersitechemaflag = true;
    this.sitesequenceleableshow = false;
    this.sitelebelvalueshow = [];
    this.frm_SiteOrder.reset();
    this.resetFormErrors();
    this.Bodysitenames = [];
    this.checktheSiteSchemaFlag();
    this.frm_SiteOrder.controls.frmOrganization.enable();
    this.frm_SiteOrder.controls.frmDepKey.enable();
    this.frm_SiteOrder.controls.frmNumberOfJars.enable();
    this.ngxService.stop("Refershback");
  }

  //#endregion
  //re-direct to the previous page for site  pages
  OpenMangeSite() {
    this.openmangesite = true;
    this.noDataFound = false;
    this.createdpage = false;
    this.uploadClicked = false;
    this.uniqueData = [];
    this.siteordersitechemaflag = true;
    this.sitesequenceleableshow = false;
    this.sitelebelvalueshow = [];
    this.siteschemavalues = [];
    this.Bodysitenames = [];
    this.editvalueforsitename = [];
    this.frm_SiteOrder.patchValue({
      frmNumberOfJars: '',
      frmSechmaSite: '',
      frmSitelebel: '',
      frmBodySite: [],
    })
    this.gridShow1 = false;
    this.showTab.emit(false);
    this.templateData.menuURL = 'Sites'
    this.ManageSitesComponent.templateData = this.templateData;
    this.commonService.createContext(this.templateData.secondarykeys, '', 'Sites');
    this.ManageSitesComponent.openmangesiteorders = false;
    this.ManageSitesComponent.refreshGrid('list');
  }
  //
  removeSiteOrder(flex) {
    let data = flex.value[0];
    var obj = {
      "organizationid": this.orgid,
      "casetype": this.templateData.secondarykeys.casetype.toString(),
      "jars": data.Numberofjars,
      "sitescheme": data.Sitescheme == null ? "" : data.Sitescheme
    }
    let oldeditdata= this.editTemplateData.filter(x => x.Numberofjars == data.Numberofjars && x.Sitescheme == data.Sitescheme)
    oldeditdata= oldeditdata.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
     let acitidata={
       "Jars":{"oldValue":oldeditdata[0].Numberofjars,"newValue":''},
       "Site Scheme":{"oldValue":oldeditdata[0].Sitescheme == null ? '' : oldeditdata[0].Sitescheme,"newValue":''},
    }
     for(let deletesiteorder = 0; deletesiteorder <oldeditdata.length; deletesiteorder++)
      {
        acitidata["Site Name"+ ' '+(deletesiteorder+1)+""]={};
       acitidata["Site Name"+' '+(deletesiteorder+1)+""]["oldValue"]=oldeditdata[deletesiteorder].SiteName;
       acitidata["Site Name"+' '+(deletesiteorder+1)+""]["newValue"]= "";
      }
    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      width: '360px',
      data: { header: "", message: "This action will permanently delete the selected data.", alert: "Do you want to continue?", continue: "Delete", cancel: "Cancel" }
    })
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.vitalHttpServices.DeleteSiteOrder(obj, this.destDeployment).subscribe(
          (deletevalue) => {
            if (!deletevalue.error && deletevalue.content.length > 0) {
         //     this.commonService.auditDetails('', 'jars', [obj], [{}], 'Delete', this.templateData, this.auditableColumns);
         this.createActivityObject(data.Numberofjars,'Site Order','Delete',acitidata)
              this._snackbar.open('Data deleted successfully', 'Close');
              this.checktheSiteSchemaFlag()
            }
            else {
              this.ngxService.stop();
              this._snackbar.open('Something went wrong.Please try again', 'Close');
            }
          },
          error => {
            console.error('Error deleting item:', error);
          }
        );
      }
    })
  }

  //Upload screen
  loadUploadScreen() {
    // if (!this.uploadBtn) {
    this.gridShow1 = false
    this.showTab.emit(false);
    this.noDataFound = false;
    this.createdpage = false;
    this.uploadClicked = true;
    // this.gridShow = false;
    this.bulkUpload = true;
    this.gridwidth = 0
    let queryVariable = { tablename: 'organizationsitesorder', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let allFields = {}
          for (let i = 0; i < data.data.allFields.length; i++) {
            allFields[data.data.allFields[i]['Column']] = ''
          }
          this.allFields[0] = allFields
          delete this.allFields[0]["OrganizationSiteID"];
          delete this.allFields[0]["OrganizationID"];
          delete this.allFields[0]["CaseType"];
          delete this.allFields[0]["OrganizationSitesOrderGUID"];

          if (!this.siteordersitechemaflag) {
            delete this.allFields[0]["siteScheme"]
          }
        }
      }
    }, error => {
      console.error(error);
    });
    // }
  }

  //Upload templates
  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    if (type == 'allFieldsCheck') {
      filename = 'SiteOrder_' + 'AllFields_' + sessionStorage.getItem('org_id').toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationSitesOrder');
      if (!this.siteordersitechemaflag) {
        ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields1)
        XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      }
      else {
        ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
        XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      }

      XLSX.writeFile(wb, filename);
    }
  }

  //sample data for excel with the Schema
  sampleDataAllFields = [{
    Jars: '1',
    siteScheme: '1a',
    sequence: '1',
    sequencelabel: 'A/1',
    siteid: '12345',
    materialtype: 'Additional BxBoard'
  }, {
    Jars: '2',
    siteScheme: '2a',
    sequence: '1',
    sequencelabel: 'A/1',
    siteid: '12345',
    materialtype: 'Right BxBoard'
  },
  {
    Jars: '2',
    siteScheme: '2a',
    sequence: '2',
    sequencelabel: 'B/2',
    siteid: '12345',
    materialtype: 'Left BxBoard'
  }
  ]

  //sample data for excel with-out the schema
  sampleDataAllFields1 = [{
    Jars: '1',
    sequence: '1',
    sequencelabel: 'A/1',
    siteid: '12345',
    materialtype: 'Additional BxBoard'
  }, {
    Jars: '2',
    sequence: '1',
    sequencelabel: 'A/1',
    siteid: '12345',
    materialtype: 'Right BxBoard'
  },
  {
    Jars: '2',
    sequence: '2',
    sequencelabel: 'B/2',
    siteid: '12345',
    materialtype: 'Left BxBoard'
  }
  ]

  // grid remove button
  gridRemove() {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    if (!this.copyDataClicked) {
      this.uploadClicked = true;
      this.postUpload = false;
      this.copyDataClicked = false;
      this.showInvalidColumns = false;
      this.noDataFound = false;
      this.gridwidth = 0;
      this.showInvalidColumns = false;
      this.gridData = new CollectionView([]);
      this.frm_SiteOrder.controls.frmOrganization.enable();
      this.frm_SiteOrder.controls.frmDepKey.enable();
      this.frm_SiteOrder.controls.frmNumberOfJars.enable();
      // this.approveDisable();
    }
    else {
      this.copyDataClicked = true;
      this.postUpload = false;
      this.uploadClicked = false;
      this.showInvalidColumns = false;
      this.noDataFound = false;
      this.gridwidth = 0;
      this.noDataFoundCopy = false;
      this.frm_SiteOrder.controls.frmOrganization.enable()
      this.frm_SiteOrder.patchValue({
        frmOrganization: '',
        frmNumberOfJars: ''
      });
      this.frm_SiteOrder.controls.frmOrganization.enable();
      this.frm_SiteOrder.controls.frmDepKey.enable();
      this.frm_SiteOrder.controls.frmNumberOfJars.enable();
      this.selectedData = [];
      this.searchJarResult = [];
      this.gridData = new CollectionView([]);
    }
  }

  //message validation color
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('exists') || value.toString().includes('Mandatory') || value.toString().includes('null')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value.toString().includes('Valid') || value.toString().includes('Successfully') || value.toString().includes('success') || value.toString().includes('Success') || value.toString().includes('approval')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }
  //Export grid data as excel
  ExportExcelUpload(flex) {
    let tempHeader = flex.columns[0]._hdr;
    let filename = 'SiteOrder_' + this.templateData.secondarykeys.OrganizationId.toString() +
      '(' + this.templateData.secondarykeys.casetype + ')' +
      '.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    flex.columns.forEach(element => {
      if (element._hdr == 'Action') {
        element._hdr = ''
      }
    });

    let wb = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true,
      },
      null,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
    flex.columns[0]._hdr = tempHeader
    wb.sheets[0].name = 'Site Order';
    wb.saveAsync(filename);
  }
  //#endregion

  ExportExcel(data) {
    let excel = [];
    let flex = data
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = flex.rows;
    flex.rows.find((e: any): any => {
      if (!e._data._gd) {
        delete e._data['Action'];
        delete e._data['type'];
        delete e._data['tablename'];
        delete e._data['organizationid'];
        delete e._data['copyflag'];
        delete e._data['slno'];
        delete e._data['_gd'];
        delete e._data['_groups'];
        delete e._data['_items'];
        delete e._data['_name'];
        delete e._data['_level'];
        delete e._data['_isBottomLevel'];
        delete e._data['_path'];
        let primary = {}
        for (let [key, value] of Object.entries(e._data)) {
          if (key == 'status') {
            primary['Status'] = value
          }
          else {
            primary[key] = value;
          }
        }
        excel.push(primary);
      }
    });
    let filename = 'SiteOrder_' + sessionStorage.getItem('org_id').toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Site Order');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }

  //approve button
  approveSiteOrder(copyData?) {
    this.postUpload = false;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (copyData) {
        for (let i = 0; i < this.selectedData.length; i++) {
          if (this.selectedData[i]._data["notes"] == "" || this.selectedData[i]._data["notes"].toString().match(/already exists/i) || this.selectedData[i]._data["notes"] == "Site Not exists" || this.selectedData[i]._data["notes"] == "Mandatory field(s) is missing" || this.selectedData[i]._data["notes"] == "Mandatory field is missing!") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.selectedData[i]._data["notes"].toString().match(/approval/i) && this.selectedData[i]._data["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "" || this.excelDataArray[i]["notes"].toString().match(/already exists/i) || this.excelDataArray[i]["notes"] == "Site Not exists" || this.excelDataArray[i]["notes"] == "Mandatory field(s) is missing" || this.excelDataArray[i]["notes"] == "Mandatory field is missing!") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
        if (this.excelDataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i))) {
          this._snackbar.open("All selected records already exist!", "Close");
          this.bulkUpload = true;
          return;
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray
      let context = copyData ? null : ''
      this.addUploadSiteOrder(dataArray, sendjson, this.bulkUpload, context)
    }
  }

  //method for bulk upload
  addUploadSiteOrder(dataArray: object[], sendjson, bulkUpload: boolean, context?) {
    if (!this.copyDataClicked) {
      this.ngxService.start();
      for (let i = 0; i < sendjson.length; i++) {
        var uploadobjetforduplicate = {
          "organizationid": sendjson[i].organizationid,
          "casetype": sendjson[i].casetype.toString(),
          "jars": sendjson[i].jars,
          "sitescheme": (sendjson[i].sitescheme == null || !sendjson[i].sitescheme) ? null : sendjson[i].sitescheme
        }
      }
      this.vitalHttpServices.DeleteSiteOrder(uploadobjetforduplicate, this.destDeployment).subscribe(
        () => {
          this.vitalHttpServices.updateSiteOrder(sendjson, this.destDeployment).subscribe(result => {
            this.noDataFound = false;
            this.ngxService.stop();
            this.showDelete = false;
            if (!result.errors) {
              dataArray.filter((e: any) => {
                result.content.filter(r => {
                  if (r.SlNo.toString() === e.slno.toString()) {
                    e.status = r.status
                    e.notes = r.NoteMessage;
                  }
                });
              });
              dataArray.forEach((va: any) => {
                if (va.notes.toString().toLowerCase().match(/already exists/i)) {
                  va.status = 'Ignored'
                }
              })
              if (result.content.length > 0) {
                this.postUpload = true;
                let uploadresult = this.convertObjKeysToLower(dataArray);
                let dataExistsCount = 0;
                for (let i = 0; i < result.length; i++) {
                  if (Object.values(result).every(function (item: any) {
                    return item.status == "Ignored" ? true : false
                  })) {
                    dataExistsCount++;
                  }
                }
                this.commonService.auditDetails('', '', [], uploadresult, 'Upload', this.templateData, this.auditableColumns);
                this.AddGridData(uploadresult, 1);
              }
              else {
                bulkUpload ? '': this._snackbar.open('Site order creation failed Please try again.', 'Close');
              }
            }
          }, error => {
            this.ngxService.stop();
            this._snackbar.open('Something went wrong.Please try again', 'Close');
            console.error(error)
          })
        },
        error => {
          console.error('Error Upload item:', error);
        }
      );
    }
    else {
      let lastcharnumber;
      let lastnumberjars;
      let siteschemevalue;
      let j = 1
      let charnumber = 66;
      for (let i = 0; i < sendjson.length; i++) {
        if (this.sitelebelaphabetflag == true) {
          if (Number(sendjson[i].jars) == Number(lastcharnumber) && sendjson[i].sitescheme==siteschemevalue) {
            lastcharnumber = sendjson[i].jars;
            sendjson[i].sequencelabel = j + 1;
            sendjson[i]['sequence'] = j + 1;
            j += 1;
          }
          else {
            lastcharnumber = sendjson[i].jars;
            siteschemevalue =sendjson[i].sitescheme
            sendjson[i].sequencelabel = 1;
            sendjson[i]['sequence'] = 1;
            j = 1
          }
        }
        else {
          if (Number(sendjson[i].jars) == Number(lastnumberjars) && sendjson[i].sitescheme==siteschemevalue) {
            sendjson[i].sequencelabel = String.fromCharCode(charnumber);
            sendjson[i]['sequence'] = j + 1;
            j += 1;
            charnumber += 1
          }
          else {
            let charvalue = 65;
            lastnumberjars = Number(sendjson[i].jars)
            siteschemevalue =sendjson[i].sitescheme
            sendjson[i].sequencelabel = String.fromCharCode(charvalue);
            sendjson[i]['sequence'] = 1;
            j = 1;
            charnumber = 66;
          }
        }
      }
      this.vitalHttpServices.updateSiteOrder(sendjson, this.destDeployment).subscribe(result => {
        this.noDataFound = false;
        this.ngxService.stop();
        this.showDelete = false;
        this.frm_SiteOrder.controls.frmOrganization.disable();
        this.frm_SiteOrder.controls.frmDepKey.disable()
        this.frm_SiteOrder.controls.frmNumberOfJars.disable();
        if (!result.errors) {
          //  this.auditDetails([], sendjson, 'saved');
          dataArray.filter((e: any) => {
            result.content.filter(r => {
              if (r.SlNo.toString() === e.slno.toString()) {
                e.status = r.status
                e.notes = r.NoteMessage;
              }
            });
          });
          dataArray.forEach((va: any) => {
            if (va.notes.toString().toLowerCase().match(/already exists/i)) {
              va.status = 'Ignored'
            }
          })
          if (result.content.length > 0) {
            this.postUpload = true;
            let uploadresult = this.convertObjKeysToLower(dataArray);
            let dataExistsCount = 0;
            for (let i = 0; i < result.length; i++) {
              if (Object.values(result).every(function (item: any) {
                return item.status == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
           this.commonService.auditDetails('', '', [], uploadresult, 'Copy', this.templateData, this.auditableColumns);
            this.AddGridData(uploadresult, 1);
          }
          else {
            bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Site order creation failed Please try again.', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error)
      });
    }
  }

  //delete row in the grid in upload
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }

  //Upload file
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please provide valid data', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationsitesorder')
            validSheet.length > 0 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  // validation in grid
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    if (this.bulkUpload) {
      for (let i = 0; i < dataArray.length; i++) {
        if (this.siteordersitechemaflag) {
          if (!dataArray[i].jars && !dataArray[i].sequence && !dataArray[i].sitescheme && !dataArray[i].sequencelabel && !dataArray[i].siteid) {
            this._snackbar.open('Please provide valid data', 'Close');
            this.ngxService.stop();
            return;
          }
        }
        else {
          if (!dataArray[i].jars && !dataArray[i].sequence && !dataArray[i].sitescheme && !dataArray[i].sequencelabel && !dataArray[i].siteid) {
            this._snackbar.open('Please provide valid data', 'Close');
            this.ngxService.stop();
            return;
          }
        }
        if (this.sitelebelaphabetflag == true) {
          if (isNaN(dataArray[i].sequencelabel) == true) {
            this._snackbar.open('Enter Valid Sequencelabel', 'Close');
            this.ngxService.stop();
            return;
          }
          else {

          }
        }
        else {
          if (isNaN(dataArray[i].sequencelabel) == true) {

          }
          else {
            this._snackbar.open('Enter Valid Sequencelabel', 'Close');
            this.ngxService.stop();
            return;
          }
        }

      }
    }
    this.vitalHttpServices.validateSiteOrder(dataArray, this.destDeployment).subscribe(result => {
      if (!result.errors) {
        if (result.content.length > 0) {
          if (result.content[0]["InvalidColumns"] && result.content[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.content.filter(r => {
              if (r.slno === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddGridData(dataArray, 1);
          this.ngxService.stop();
        }
        else {
          this.ngxService.stop();
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }

  //Append validation notes in the grid
  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;

    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("Please provide valid data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!copyfrmorg) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }

    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);

    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    if (this.sheetHeader.filter(element => element == 'Jars').length > 0) {
      this.bulkUpload = true;
    }
    else {
      this.bulkUpload = false;
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    if (!this.copyDataClicked) {
      this.excelDataArray.find(d => {
        Object.assign(d, { slno: i, notes: '', tablename: 'organizationsitesorder', organizationid: sessionStorage.getItem('org_id').toString(), casetype: this.templateData.secondarykeys.casetype.toString(), type: 'Upload' });
        i++;
      });
    }
    else {
      this.excelDataArray.find(d => {
        Object.assign(d, { slno: i, notes: '', tablename: 'organizationsitesorder', organizationid: sessionStorage.getItem('org_id').toString(), casetype: this.templateData.secondarykeys.casetype.toString(), type: 'Copy' });
        i++;
      });
      this.bulkUpload = false;
    }
    this.validateExcel(this.excelDataArray);
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  // Close Modal
  closeModal() {
    $('#selectSheetModal').modal('hide');
  }
  // Open Modal
  openModal() {
    $('#selectSheetModal').appendTo("body").modal('show');
  }

  backSelect() {
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.copyDataClicked=false;
    this.ngxService.start("refersh");
    this.showInvalidColumns = false;
    this.findNumberOfJars();
    this.uploadClicked = false
    this.gridShow1 = true;
    this.showTab.emit(true);
    this.createdpage = false;
    this.postUpload = false;
    this.noDataFoundCopy = false;
    this.postUpload = false;
    this.uploadClicked = false;
    this.showInvalidColumns = false;
    this.noDataFound = false;
    this.gridwidth = 0;
    this.noDataFoundCopy = false;
    this.frm_SiteOrder.controls.frmOrganization.enable()
    this.frm_SiteOrder.patchValue({
      frmOrganization: '',
      frmNumberOfJars: ''
    });
    this.frm_SiteOrder.controls.frmOrganization.enable();
    this.frm_SiteOrder.controls.frmDepKey.enable();
    this.frm_SiteOrder.controls.frmNumberOfJars.enable();
    this.selectedData = [];
    this.searchJarResult = [];
    this.gridData = new CollectionView([]);
    this.copygridData=new CollectionView([]);
    this.getListToFetchSiteOrders();
    this.resetFormErrors();
    this.ngxService.stop("refersh");
  }

  //add grid in upload and copy screen
  AddGridData(data, hitCount) {
    this.closeModal();
    this.ngxService.start()
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    let gridArray = [];
    this.gridData = new CollectionView([]);
    this.copygridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (key == 'sequence') {
                if (value == 0) {
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = 'Zero is not allowed for Sequence';
                  }
                }
                else {
                  let pattern = /[0-9]+/
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['notes'];
                  }
                }
              }
              if (value && value.toString().match(/null/i) && key != 'Jars') {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              if ((key == 'sequence' || key == 'jars' || key == 'siteScheme' || key == 'sequencelabel' || key == 'siteid') && (!value || value.toString() == '')) {
                value = ''
                primary['notes'] = 'Mandatory field is missing!'
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        if (!this.postUpload) {
          this.showDelete = hitCount == 1 ? true : false;
        }

        if (this.postUpload) {
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if (!this.copyDataClicked) {
          this.selectedData = gridArray;
          this.copygridData = new CollectionView(gridArray);
          this.gridwidth = (170 * this.sheetHeader.length) + 37;
          if (this.gridwidth > 1300) {
            this.gridwidth = 1300;
          }
        }
        else {
          if (this.copyflagsequence == true) {
            gridArray.sort((a, b) => a.jars < b.jars ? -1 : a.jars > b.jars ? 1 : 0).sort((a, b) => a.sequencelabel - b.sequencelabel );
          }
          else {
            gridArray.sort((a, b) => a.jars < b.jars ? -1 : a.jars > b.jars ? 1 : 0).sort((a, b) => a.sequencelabel < b.sequencelabel ? -1 : a.sequencelabel > b.sequencelabel ? 1 : -1);
          }
          if (this.siteordersitechemaflag && this.copysechmaflag) {
            this.copygridData = new CollectionView(gridArray, { groupDescriptions: ['jars', 'sitescheme'] });
          }
          else {
            this.copygridData = new CollectionView(gridArray, { groupDescriptions: ['jars'] });
          }

        }
      }
      this.ngxService.stop()
    }
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  filteredJarsvalue(value) {
    if (!value) {
      return this.Jarsvalue;
    }
    return this.Jarsvalue.filter(va => va == value)
  }

  loadCopyScreen() {
    this.resetFormErrors()
    this.noDataFound = false;
    this.copyDataClicked = true;
    this.gridShow1 = false;
    this.showTab.emit(false);
    this.gridwidth = 0;
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
    this.frm_SiteOrder.patchValue({
      frmNumberOfJars: '',
      frmSechmaSite: '',
      frmSitelebel: '',
      frmBodySite: [],
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase()
    })
    this.frm_SiteOrder.controls.frmOrganization.enable();
    this.frm_SiteOrder.controls.frmDepKey.enable();
    this.frm_SiteOrder.controls.frmNumberOfJars.enable();
    this.getListOrg();
  }
  //Deployment details in the copy from Entity
  onChangeDeployment(e, depKey) {
    this.organizationList = [];
    this.postUpload = false;
    this.noDataFoundCopy = false;
    this.searchResult = []
    this.searchJarResult = [];
    this.frm_SiteOrder.patchValue({
      frmOrgID: '',
      frmOrganization: '',
      frmNumberOfJars: ''
    });
    this.gridwidth = 0;
    if (e.source.selected) {
      this.frm_SiteOrder.patchValue({
        frmDepKey: depKey,
        frmOrgID: '',
        frmOrganization: '',
        frmNumberOfJars: ''
      });
      this.getListOrg();
    }
  }
  //get data in copy from Entity
  getListOrg() {
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.frm_SiteOrder.value.frmDepKey).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        if (data.data.Organizations_list && data.data.Organizations_list.length > 0) {
          this.organizationList = data.data.Organizations_list;
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }
  //Org data suggestion based on number or alphabet
  fetchOrgSeries(value: string) {
    this.searchResult = [];
    if (!value.trim()) {
      let copyOrgList=this.organizationList.slice()
      return (this.searchResult = copyOrgList.splice(0,25));
    }
    this.selectedData.length = 0;
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.frm_SiteOrder.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.organizationname != null) {
        return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
    this.resetFormErrors()
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected && Object.keys(data).length > 0) {
      this.frm_SiteOrder.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname,
      });
      this.copyscreenJars();
    }
  }

  selectedCopyDatajars(event, data) {
    if (event.source.selected) {
      this.frm_SiteOrder.patchValue({
        frmNumberOfJars: event.source.value
      });
      this.showPopUp();
    }
  }

  getcopyflagcheck() {
    if (typeof (this.frm_SiteOrder.value.frmOrgID) == 'object') {
      let frmorgid = this.frm_SiteOrder.value.frmOrgID
      this.frm_SiteOrder.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { "orgid": this.frm_SiteOrder.value.frmOrgID.toString(), "extrname": "enable_site_scheme" };
    let query = this.SubMenuCardModel.GetQuery('getentityextattributesFlag');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.frm_SiteOrder.value.frmDepKey).subscribe(copysitesechmaflag => {
      this.ngxService.stop();
      if (!copysitesechmaflag.errors) {
        let queryVariable = { "orgid": this.frm_SiteOrder.value.frmOrgID.toString(), "extrname": "Sites.SequenceLabelInNumberFormat" };
        let query = this.SubMenuCardModel.GetQuery('getentityextattributesFlag');
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.vitalHttpServices.GetData(queryResult, this.frm_SiteOrder.value.frmDepKey).subscribe(copysitesechmaflagsequence => {
          this.ngxService.start("flags");
          if (copysitesechmaflag.data.submenuData.length > 0) {
            this.copysechmaflag = true;
          }
          else {
            this.copysechmaflag = true;
          }
          if (copysitesechmaflagsequence.data.submenuData.length > 0) {
            this.copyflagsequence = copysitesechmaflagsequence.data.submenuData[0].ExtAttrValue.toLowerCase() == 'true' ? true : false;
          }
          else {
            this.copyflagsequence = false;
          }
          this.ngxService.stop("flags");
          this.showPopUp();

        });
      }

    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  copyscreenJars() {
    if (typeof (this.frm_SiteOrder.value.frmOrgID) == 'object') {
      let frmorgid = this.frm_SiteOrder.value.frmOrgID
      this.frm_SiteOrder.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { "Orgid": this.frm_SiteOrder.value.frmOrgID.toString(), "Casetype": this.templateData.secondarykeys.casetype.toString() };
    let query = this.SubMenuCardModel.GetQuery('copysiteorderjarslist');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.jarList = [];
    this.vitalHttpServices.GetData(queryResult, this.frm_SiteOrder.value.frmDepKey).subscribe(copyJarsList => {
      this.ngxService.stop();
      if (!copyJarsList.errors) {
        for (let i = 0; i < copyJarsList.data.submenuData.length; i++) {
          if (copyJarsList.data.submenuData.length > 0) {
            this.jarList.push(copyJarsList.data.submenuData[i].Numberofjars)
          }
        }
        this.getcopyflagcheck();
      }

    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  fetchJarCount(value: string) {
    this.searchJarResult = [];
    if (value === '') {
      return (this.searchJarResult = []);
    }
    else {
      this.searchJarResult = this.jarList.filter(va => va == value)
    }
    this.resetFormErrors()
  }

  getDataToCopy() {
    if (typeof (this.frm_SiteOrder.value.frmOrgID) == 'object') {
      let frmorgid = this.frm_SiteOrder.value.frmOrgID
      this.frm_SiteOrder.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { "Casetype": this.templateData.secondarykeys.casetype.toString(), "Orgid": this.frm_SiteOrder.value.frmOrgID.toString() };
    let query;
    if (this.siteFormat != 2) {
      if (this.copysechmaflag == true && this.siteordersitechemaflag == true) {
        query = this.SubMenuCardModel.GetQuery('mangesiteorderbasedonschema');
      }
      else {
        query = this.SubMenuCardModel.GetQuery('mangesiteorder');
      }
    }
    else {
      if (this.copysechmaflag == true && this.siteordersitechemaflag == true) {
        query = this.SubMenuCardModel.GetQuery('mangesiteorderbasedonschemaformatetwo');
      }
      else {
        query = this.SubMenuCardModel.GetQuery('mangesiteorderformatetwo');
      }
    }

    let copyData;
    copyData = '';
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.frm_SiteOrder.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        data.data.submenuData.forEach(va => {
          delete va.OrganizationID
          delete va.CaseType
          delete va.BodyName

        })

        copyData = data.data.submenuData;
        if (copyData.length == 0) {
          this.gridwidth = 0;
          this.noDataFoundCopy = true;
          return
        }
        this.gridwidth = 1;
        if (!this.frm_SiteOrder.value.frmNumberOfJars || this.frm_SiteOrder.value.frmNumberOfJars == 0) {
          this.copyDataProcess(copyData);
        }
        else {
          copyData = '';
          copyData = data.data.submenuData.filter(c => c.Numberofjars == Number(this.frm_SiteOrder.value.frmNumberOfJars));
          if (copyData.length == 0) {
            this.gridwidth = 0;
            this.noDataFoundCopy = true;
            return
          }
          else {
            this.copyDataProcess(copyData);
          }

        }


      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  // validation while copying
  copyDataProcess(copyData: []) {
    this.dataForValidation = [];
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key == 'Numberofjars') {
          primary['Jars'] = value;
        }
        else if (key == 'Sequence') {
          primary['SequenceLabel'] = value;
        }
        else if (key == 'Siteschema') {
          primary['Sitescheme'] = value;
        }
        else {
          primary[key] = value;
        }
      }
      primary['notes'] = "";
      primary['slno'] = i + 1;
      primary['tablename'] = "Organizationsitesorder";
      primary['organizationid'] = this.templateData.secondarykeys.OrganizationId.toString();
      primary['casetype'] = this.templateData.cardtype.toString();
      this.dataForValidation.push(primary);
    }
    this.convertToJson("", true, this.dataForValidation);
  }

  showPopUp() {
    if ((this.siteordersitechemaflag && this.copysechmaflag) || (!this.siteordersitechemaflag && !this.copysechmaflag)) {
      this.fetchOrgSeries(this.frm_SiteOrder.value.frmOrganization);
      this.getDataToCopy();
    }
    else if ((!this.siteordersitechemaflag && this.copysechmaflag) || ((this.siteordersitechemaflag && !this.copysechmaflag))) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '360px',
        data: { header: "Alert", message: "Schema is false for destination", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.fetchOrgSeries(this.frm_SiteOrder.value.frmOrganization);
          this.getDataToCopy();
        }
      })
    }
  }

  //Flags fro approve button
  approveDisable() {
    let data;
    if (this.copyDataClicked && this.selectedData.length == 0) {
      return true
    }
    return data;
  }

  //Activity  tracker
  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    });
  }
  //Update the flag
  UpdateVAEntityAttribute(name) {
    if(!this.editBtn){
    let value: boolean=true;
    let data = [];
    let editolddata=[];
    let   attrname = 'Sites.SequenceLabelInNumberFormat'
    if(name =='numeric')
      {
           value=true
      }
      else{
         value=false
      }
    let obj = {
      entityid: sessionStorage.getItem('org_id'),
      entitytype: 2,
      AttributeName: attrname,
      ExtAttrValue: value
    }
    let editobj= {
      entityid: sessionStorage.getItem('org_id'),
      entitytype: 2,
      AttributeName: attrname,
      ExtAttrValue: value==true ? false : true
    }
    data.push(obj);
    editolddata.push(editobj);
    this.templateData.menuURL = 'Site Order'
    this.ngxService.start();
    this.vitalHttpServices.ManageVAEntityAttribute(data, sessionStorage.getItem('deploymentKey')).subscribe((res) => {
      this.ngxService.stop();
      if (!res.error) {
        if (res[0].NoteMessage == "Updated Successfully") {

         this.commonService.auditDetails('', 'AttributeName',editolddata , data, 'Edit', this.templateData, this.auditableColumns);
        }
        else if (res[0].NoteMessage == "Created Successfully") {
          this.commonService.auditDetails('', 'AttributeName', data, data, 'Create', this.templateData, this.auditableColumns)
        }
        this.Refershback();
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      this.siteordersitechemaflag = !this.siteordersitechemaflag
    });
   }
   else{
    this._snackbar.open("User is not authorized for this operation", 'Failed')
   }
}

  //copy number jars
  //#region
  copyNumberJars(value) {
    if(isNaN(value)){
      let newvalue = value.split('(');
      if(newvalue.length>0)
      {
        let number=Number(newvalue[0]);
        let sechema=newvalue[1].split(')');
        sechema=sechema[0];
        this.editvalueforsitename=[];
        let copy;
        copy = this.editTemplateData.filter(x => x.Numberofjars == number && x.Sitescheme==sechema);
        copy= copy.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
         for (let i=0; i<copy.length; i++) {
          this.editvalueforsitename.push(copy[i].SiteName);
         }
         for(let p=0; p<Number(this.numberOfJarsValue);p++) {
          if(this.editvalueforsitename.length !=Number(this.numberOfJarsValue)){
           this.editvalueforsitename.push(this.Bodysitenames.find((e) => e.toLowerCase()=='select'))
          }
         }
      }
    }
    else{
    this.editvalueforsitename=[];
    let copy;
    copy = this.editTemplateData.filter(x => x.Numberofjars == value &&  x.Sitescheme==null);
    copy= copy.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : -1);
     for (let i=0; i<copy.length; i++) {
      this.editvalueforsitename.push(copy[i].SiteName);
     }
     for(let p=0; p<Number(this.numberOfJarsValue);p++) {
      if(this.editvalueforsitename.length !=Number(this.numberOfJarsValue)){
       this.editvalueforsitename.push(this.Bodysitenames.find((e) => e.toLowerCase()=='select'))
      }
     }
    }
  }
  //#endregion

  findClosest(arr, n, target) {
    let left = 0, right = n-1;
    while (left < right) {
      if (Math.abs(arr[left] - target) <= Math.abs(arr[right] - target)) {
        right--;
      } else {
        left++;
      }
    }
    return arr[left];
  }

  // activity tracker to create
  createActivityObject(entityid,entityType, actionType,changevalue) {
    try{
    let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
    let date = new Date();
    let entityTypeContext : any
    let deployid = sessionStorage.getItem('DeploymentKey')
    this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);

    this.createActivityInput.productId = this.vitalHttpServices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, '');
    this.createActivityInput.product = 'VitalDx';
    this.createActivityInput.customerId = sessionStorage.getItem('org_id');
    this.createActivityInput.customerName = sessionStorage.getItem('Org_Name');
    this.createActivityInput.entityType = entityType;
    this.createActivityInput.actionType = 'audit';
    this.createActivityInput.userName = localStorage.getItem('user_name');
    this.createActivityInput.userId = localStorage.getItem('UserEmail');
    this.createActivityInput.locationId = sessionStorage.getItem('Locationid');
    this.createActivityInput.locationName = sessionStorage.getItem('locationName');
    this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
    this.createActivityInput.transactionId = this.generateGuid();;
    this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
    this.createActivityInput.ticketInfo = actionType + " " + entityType;
    this.createActivityInput.entityId=entityid;
    this.createActivityOperations.info = this.getActivityInfo(actionType, entityType, entityid);
    this.createActivityOperations.extendedAttributes=changevalue;
    this.createActivityOperations.attachments = null;
    this.createActivityInput.operation = this.createActivityOperations;
    this.createActivityInput.context = entityTypeContext.context;
      this.vitalHttpServices.CreateActivity(this.createActivityInput)
        .subscribe((res) => { });
    }catch(error){
      this._snackbar.open("Error occured while creating activity", 'Close');
    }
  }

  // generate  transitionid
  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  //Activity Info for the pass
  getActivityInfo(actionType: any, entityType: any, changedValue: any) {
    let activityTypeList = {
      'Edit': 'Modified ',
      'Delete': 'Deleted ',
      'Upload': 'Uploaded ',
      'Create': 'Created new ',
      'Add': 'Added ',
      'Copy': 'Copied ',
      'Export': 'Exported '
    }
    return activityTypeList[actionType] + 'site order' + ' "' + changedValue + '" ';
  }
  //re-set options
  resetOption(value){
    let data=Number(value.frmNumberOfJars);
    this.editvalueforsitename=[];
    this.number='';
    for(let p=0; p<data;p++) {
      if(this.editvalueforsitename.length !=value){
       this.editvalueforsitename.push(this.Bodysitenames.find((e) => e.toLowerCase()=='select'))
      }
     }

     for(let j=0;j<this.editvalueforsitename.length;j++) {
      if(this.editvalueforsitename[j]!){

      }
      else{
        this.editvalueforsitename=this.editvalueforsitename.slice(j, 1);
      }
     }

  }
}



class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
